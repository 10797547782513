// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "/* make sure to keep these in sync with other constants,\ne.g. STAGE_DIMENSION_DEFAULTS in lib/screen-utils.js */\n\n/* layout contants from `layout-constants.js` */\n\n/* #E5F0FF */\n\n/* #E9F1FC */\n\n/* #D9E3F2 */\n\n/* $ui-modal-overlay: hsla(215, 100%, 65%, 0.9);  90% transparent version of motion-primary orjinal */\n\n/* 90% transparent version of motion-primary */\n\n/* #FFFFFF */\n\n/* 25% transparent version of ui-white */\n\n/* 25% transparent version of ui-white */\n\n/* 25% transparent version of ui-white */\n\n/* 15% transparent version of black */\n\n/* #575E75 */\n\n/* #4C97FF */\n\n/* #3373CC */\n\n/* 35% transparent version of motion-primary */\n\n/* 15% transparent version of motion-primary */\n\n/* #FF661A */\n\n/* #E64D00 */\n\n/* #CF63CF */\n\n/* #BD42BD */\n\n/* #FFAB19 */\n\n/* #FF8C1A */\n\n/* #0FBD8C */\n\n/* #0FBD8C */\n\n/* #FF8C1A */\n\n/* #FFB366 */\n\n/* #FF8C1A */\n\n/* #0FBD8C */\n\n/* #0B8E69 */\n\n/* 35% transparent version of extensions-primary */\n\n/* opaque version of extensions-transparent, on white bg */\n\n/* lighter than motion-primary */\n\n.computerless-coding_img_2OzZ2 {\n    height: 150px;\n    width: 150px;\n    display: block;\n    margin-left: auto;\n    margin-right: auto;\n}\n\n.computerless-coding_text_3WCbZ {\n   font-size: 20px;\n   text-align: center;\n   font-weight: bold;\n}\n\n.computerless-coding_element_38bSV {\n    float: left;\n    width: 33.3%;\n    margin-top: 50px;\n }\n\n.computerless-coding_content_3sduH{\n    margin: 0;\n  position: absolute;\n  top: 50%;\n  -ms-transform: translateY(-50%);\n  -webkit-transform: translateY(-50%);\n          transform: translateY(-50%);\n  \n }\n\n.computerless-coding_title_1Dzsa{\n    font-weight: bold;\n    font-size: 40;\n }", ""]);
// Exports
exports.locals = {
	"img": "computerless-coding_img_2OzZ2",
	"text": "computerless-coding_text_3WCbZ",
	"element": "computerless-coding_element_38bSV",
	"content": "computerless-coding_content_3sduH",
	"title": "computerless-coding_title_1Dzsa"
};
module.exports = exports;
