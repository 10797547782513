const blockSeparator = '<sep gap="36"/>'; // At default scale, about 28px
const categorySeparator = '<sep gap="36"/>';

export const ztech = function (isInitialSetup, isStage, targetId, soundName) {
    return `
    <category name="ztech" id="ztech" colour="#D65CD6" secondaryColour="#BD42BD">
        
        <block type="ztech_deneme_1"></block>
        <block type="ztech_deneme_2"></block>
        <block type="ztech_deneme_3"></block>
        <block type="ztech_deneme_4"></block>
       



        ${categorySeparator}
        </category>
        `;
    };
    