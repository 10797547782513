const blockSeparator = '<sep gap="36"/>'; // At default scale, about 28px
const categorySeparator = '<sep gap="36"/>';
import ScratchBlocks from '../../../../mymodule/ztech-blocks/dist/horizontal.js';


export const lights = function (isInitialSetup, isStage, targetId) {
    const stageSelected = ScratchBlocks.ScratchMsgs.translate(
        'LIGHTS_STAGE_SELECTED',
        'Stage selected: no light blocks'
    );
    return `
    <category name="%{BKY_CATEGORY_LIGHTING}" id="lights" colour="#4C97FF" secondaryColour="#3373CC">
    ${isStage ? `
    <label text="${stageSelected}"></label>
    ` : `
    <block type="light_onoff">
    <value name="ICON">
        <shadow type="dropdown_onoff">
            <field name="ICON">on</field>
        </shadow>
    </value>
</block>

<block type="lights_off">
</block>

<block type="rgb_lights">
    <value name="COLOR">
        <shadow type="dropdown_rgb">
            <field name="COLOR">green</field>
        </shadow>
    </value>
</block>
`}
        ${categorySeparator}
    </category>
    `;
};