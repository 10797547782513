import bindAll from 'lodash.bindall';
import PropTypes from 'prop-types';
import React from 'react';
import VM from '../../mymodule/ztech-vm';
import {connect} from 'react-redux';
import {compose} from 'redux';
import {defineMessages, injectIntl, intlShape} from 'react-intl';

import analytics from '../lib/analytics';

import VerticalOrHorizontalComponent from '../components/verticalorhorizontal/verticalorhorizontal.jsx';


const messages = defineMessages({
    deviceTitle: {
        defaultMessage: 'Choose an Device',
        description: 'Heading for the device library',
        id: 'gui.deviceLibrary.chooseADevice'
    },
    deviceUrl: {
        defaultMessage: 'Enter the URL of the device',
        description: 'Prompt for unoffical device url',
        id: 'gui.deviceLibrary.deviceUrl'
    },
    arduinoTag: {
        defaultMessage: 'Arduino',
        description: 'Arduino tag to filter all arduino devices.',
        id: 'gui.deviceLibrary.arduinoTag'
    },
    microPythonTag: {
        defaultMessage: 'MicroPython',
        description: 'Micro python tag to filter all micro python devices.',
        id: 'gui.deviceLibrary.microPythonTag'
    }
});


class VerticalOrHorizontalModal extends React.PureComponent {
    constructor (props) {
        super(props);
        bindAll(this, [
            'handleItemSelect',
        ]);
    }
    componentDidMount () {
    
    }

    handleItemSelect (item) {
        this.props.onRequestClose();
    }

    render () {
      
        return (
            <VerticalOrHorizontalComponent
            id="verticalOrHorizontal"
            title={this.props.intl.formatMessage(messages.deviceTitle)}
            onRequestClose={this.props.onRequestClose}
            onSetNormalchange={this.props.onSetNormalchange}
            gameDestroy={this.props.gameDestroy}
            onUpdateToolboxState={this.props.onUpdateToolboxState}
            vm={this.props.vm}
            deviceData={this.props.deviceData}
            onDeviceSelected={this.props.onDeviceSelected}


        />
        );
    }
}

VerticalOrHorizontalModal.propTypes = {
    deviceData: PropTypes.instanceOf(Array).isRequired,
    intl: intlShape.isRequired,
    onDeviceSelected: PropTypes.func,
    onRequestClose: PropTypes.func,
    vm: PropTypes.instanceOf(VM).isRequired // eslint-disable-line react/no-unused-prop-types
};

const mapStateToProps = state => ({
    deviceData: state.scratchGui.deviceData.deviceData
});

const mapDispatchToProps = dispatch => ({
    onSetJrchange: () => dispatch(setjrMode()),
    onSetNormalchange: () => dispatch(setNormalMode()),
    onUpdateToolboxState: toolboxXML => {
      
        dispatch(updateToolbox(toolboxXML));
    },
});

export default compose(
    injectIntl,
    connect(
        mapStateToProps,
        mapDispatchToProps
    )
)(VerticalOrHorizontalModal);
