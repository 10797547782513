import bindAll from 'lodash.bindall';
import PropTypes from 'prop-types';
import React from 'react';
import {connect} from 'react-redux';
import {projectTitleInitialState} from '../reducers/project-title';
import downloadBlob from '../lib/download-blob';
/**
 * Project saver component passes a downloadProject function to its child.
 * It expects this child to be a function with the signature
 *     function (downloadProject, props) {}
 * The component can then be used to attach project saving functionality
 * to any other component:
 *
 * <SB3ServerDownloader>{(downloadProject, props) => (
 *     <MyCoolComponent
 *         onClick={downloadProject}
 *         {...props}
 *     />
 * )}</SB3ServerDownloader>
 */
class SB3ServerDownloader extends React.Component {
    constructor (props) {
        super(props);
        bindAll(this, [
            'downloadProject'
        ]);
    }
    downloadProject () {
        this.props.saveProjectSb3().then(content => {
            if (this.props.onSaveFinished) {
                this.props.onSaveFinished();
            }
            //downloadBlob(this.props.projectFilename, content);
            let formData = new FormData();
            formData.append("file", content, this.props.projectFilename);

            fetch(`https://api.ztech.ist/z-code/file/${this.props.userData.id}`, {
                method: 'POST',
                headers: {
                    'x-auth': this.props.userData.token,
                },
                body: formData
            }).then(res => res.json()).then(res => {
                console.log("responsss",res);
            }
            ).catch(err => {
                console.log("responsss",err);
            }
            );
        });
    }
    // {
    //     "userId": "64edf9c8ea31c70df2838351",
    //     "file": {
    //       "url": "https://lms-ztech.s3.eu-central-1.amazonaws.com/z-code/1693342186460_ZTech%20Proje%20%2810%29.zcode",
    //       "key": "z-code/1693342186460_ZTech Proje (10).zcode"
    //     },
    //     "_id": "64ee59eaea31c70df283867b",
    //     "createdAt": "2023-08-29T20:49:46.752Z",
    //     "updatedAt": "2023-08-29T20:49:46.752Z",
    //     "__v": 0
    //   }
    render () {
        const {
            children
        } = this.props;
        return children(
            this.props.className,
            this.downloadProject
        );
    }
}

const getProjectFilename = (curTitle, defaultTitle) => {
    let filenameTitle = curTitle;
    if (!filenameTitle || filenameTitle.length === 0) {
        filenameTitle = defaultTitle;
    }
    return `${filenameTitle.substring(0, 100)}.zcode`;
};

SB3ServerDownloader.propTypes = {
    children: PropTypes.func,
    className: PropTypes.string,
    onSaveFinished: PropTypes.func,
    projectFilename: PropTypes.string,
    saveProjectSb3: PropTypes.func,
    userData: PropTypes.object
};
SB3ServerDownloader.defaultProps = {
    className: ''
};

const mapStateToProps = state => ({
    saveProjectSb3: state.scratchGui.vm.saveProjectSb3.bind(state.scratchGui.vm),
    projectFilename: getProjectFilename(state.scratchGui.projectTitle, projectTitleInitialState),
    userData: state.scratchGui.userData
});

export default connect(
    mapStateToProps,
    () => ({}) // omit dispatch prop
)(SB3ServerDownloader);
