
let engine = null;

class ConnectedAudioEngine {
   
    static setAudio(connectaudio) {
        engine = connectaudio;
        }
    
    static getAudio () {
            return engine; 
    }

}

module.exports = ConnectedAudioEngine;
