const OPEN_MODAL = 'scratch-gui/modals/OPEN_MODAL';
const CLOSE_MODAL = 'scratch-gui/modals/CLOSE_MODAL';

const MODAL_BACKDROP_LIBRARY = 'backdropLibrary';
const MODAL_COSTUME_LIBRARY = 'costumeLibrary';
const MODAL_EXTENSION_LIBRARY = 'extensionLibrary';
const MODAL_LOADING_PROJECT = 'loadingProject';
const MODAL_TELEMETRY = 'telemetryModal';
const MODAL_SOUND_LIBRARY = 'soundLibrary';
const MODAL_SPRITE_LIBRARY = 'spriteLibrary';
const MODAL_SOUND_RECORDER = 'soundRecorder';
const MODAL_CONNECTION = 'connectionModal';
const MODAL_TIPS_LIBRARY = 'tipsLibrary';
const MODAL_UPLOAD_PROGRESS = 'uploadProgress';
const MODAL_DEVICE_LIBRARY = 'deviceLibrary';
const MODAL_VERTICAL_HORIZANTAL = 'verticalOrHorizontal';
const MODAL_COMPUTERLESS_CODING = 'computerlessCoding';
const MODAL_UPDATE = 'updateModal';

const initialState = {
    [MODAL_BACKDROP_LIBRARY]: false,
    [MODAL_COSTUME_LIBRARY]: false,
    [MODAL_EXTENSION_LIBRARY]: false,
    [MODAL_LOADING_PROJECT]: false,
    [MODAL_TELEMETRY]: false,
    [MODAL_SOUND_LIBRARY]: false,
    [MODAL_SPRITE_LIBRARY]: false,
    [MODAL_SOUND_RECORDER]: false,
    [MODAL_CONNECTION]: false,
    [MODAL_UPLOAD_PROGRESS]: false,
    [MODAL_DEVICE_LIBRARY]: false,
    [MODAL_VERTICAL_HORIZANTAL]: true,
    [MODAL_COMPUTERLESS_CODING]: false,
    [MODAL_TIPS_LIBRARY]: false,
    [MODAL_UPDATE]: false
};

const reducer = function (state, action) {

    if (typeof state === 'undefined') state = initialState;
    switch (action.type) {
    case OPEN_MODAL:
        return Object.assign({}, state, {
            [action.modal]: true
        });
    case CLOSE_MODAL:
        return Object.assign({}, state, {
            [action.modal]: false
        });
    default:
        return state;
    }
};
const openModal = function (modal) {
    return {
        type: OPEN_MODAL,
        modal: modal
    };
};
const closeModal = function (modal) {
    return {
        type: CLOSE_MODAL,
        modal: modal
    };
};
const openBackdropLibrary = function () {
    return openModal(MODAL_BACKDROP_LIBRARY);
};
const openCostumeLibrary = function () {
    return openModal(MODAL_COSTUME_LIBRARY);
};
const openExtensionLibrary = function () {
    return openModal(MODAL_EXTENSION_LIBRARY);
};
const openLoadingProject = function () {
    return openModal(MODAL_LOADING_PROJECT);
};
const openTelemetryModal = function () {
    return openModal(MODAL_TELEMETRY);
};
const openSoundLibrary = function () {
    return openModal(MODAL_SOUND_LIBRARY);
};
const openSpriteLibrary = function () {
    return openModal(MODAL_SPRITE_LIBRARY);
};
const openSoundRecorder = function () {
    return openModal(MODAL_SOUND_RECORDER);
};
const openConnectionModal = function () {
    return openModal(MODAL_CONNECTION);
};
const openUploadProgress = function () {
    return openModal(MODAL_UPLOAD_PROGRESS);
};
const openDeviceLibrary = function () {
    return openModal(MODAL_DEVICE_LIBRARY);
};
const openVerticalOrHorizontal = function () {
    return openModal(MODAL_VERTICAL_HORIZANTAL);
};
const openComputerlessCoding = function () {
    return openModal(MODAL_COMPUTERLESS_CODING);
};
const openTipsLibrary = function () {
    return openModal(MODAL_TIPS_LIBRARY);
};
const openUpdateModal = function () {
    return openModal(MODAL_UPDATE);
};
const closeBackdropLibrary = function () {
    return closeModal(MODAL_BACKDROP_LIBRARY);
};
const closeCostumeLibrary = function () {
    return closeModal(MODAL_COSTUME_LIBRARY);
};
const closeExtensionLibrary = function () {
    return closeModal(MODAL_EXTENSION_LIBRARY);
};
const closeLoadingProject = function () {
    return closeModal(MODAL_LOADING_PROJECT);
};
const closeTelemetryModal = function () {
    return closeModal(MODAL_TELEMETRY);
};
const closeSpriteLibrary = function () {
    return closeModal(MODAL_SPRITE_LIBRARY);
};
const closeSoundLibrary = function () {
    return closeModal(MODAL_SOUND_LIBRARY);
};
const closeSoundRecorder = function () {
    return closeModal(MODAL_SOUND_RECORDER);
};
const closeTipsLibrary = function () {
    return closeModal(MODAL_TIPS_LIBRARY);
};
const closeConnectionModal = function () {
    return closeModal(MODAL_CONNECTION);
};
const closeUploadProgress = function () {
    return closeModal(MODAL_UPLOAD_PROGRESS);
};
const closeDeviceLibrary = function () {
    return closeModal(MODAL_DEVICE_LIBRARY);
};
const closeVerticalOrHorizontal = function () {
    return closeModal(MODAL_VERTICAL_HORIZANTAL);
};
const closeComputerlessCoding = function () {
    return closeModal(MODAL_COMPUTERLESS_CODING);
};
const closeUpdateModal = function () {
    return closeModal(MODAL_UPDATE);
};
export {
    reducer as default,
    initialState as modalsInitialState,
    openBackdropLibrary,
    openConnectionModal,
    openCostumeLibrary,
    openDeviceLibrary,
    openVerticalOrHorizontal,
    openComputerlessCoding,
    openExtensionLibrary,
    openLoadingProject,
    openSoundLibrary,
    openSpriteLibrary,
    openSoundRecorder,
    openTelemetryModal,
    openTipsLibrary,
    openUploadProgress,
    openUpdateModal,
    closeBackdropLibrary,
    closeConnectionModal,
    closeCostumeLibrary,
    closeDeviceLibrary,
    closeVerticalOrHorizontal,
    closeComputerlessCoding,
    closeExtensionLibrary,
    closeLoadingProject,
    closeSpriteLibrary,
    closeSoundLibrary,
    closeSoundRecorder,
    closeTelemetryModal,
    closeTipsLibrary,
    closeUploadProgress,
    closeUpdateModal
};
