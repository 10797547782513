import React, { useEffect, useRef, useState } from "react";
import Phaser from "phaser";
import { IonPhaser } from "@ion-phaser/react";
import { GridEngine } from "grid-engine";
import ResizeIcon from "./assets/button-img/vertical-resize.svg";
// import isochar2 from "./assets/iso_char_light.png";
import robotNew from "./assets/new-robot.png";
import collisionSet from "./assets/collision-set.png";
import emptySetJson from "./assets/empty-map/deneme.json";
import crash from "./assets/sounds/crash-can.mp3";
import successSound from "./assets/sounds/success.mp3";
import robotStopSound from "./assets/sounds/robot-stop.mp3";
import robotMoveSound from "./assets/sounds/robot-move.mp3";
import coinSpriteSheet from "./assets/empty-map/spinning-coin-spritesheet.svg";
// import emptySet from "./assets/empty-map/125_63.png";
// import testset from "./assets/empty-map/128x128-png.png";
// import testsetJson from "./assets/empty-map/test.json";
// import waterMapJson from "./assets/water-map/water-map.json";
// import waterMapTileset from "./assets/water-map/deniz-block.png";
// import desertMapJson from "./assets/desert/desert1.json";
// import desertMapTileset from "./assets/desert/desert-block.png";

// robot parts
import robotScreenL from "./assets/robot-parts/robot-screen.png";
import robotScreenR from "./assets/robot-parts/robot-screen-r.png";
import robotLights from "./assets/robot-parts/lights_all_sm.png";


import emptyBg from "./assets/background/BosGridBg.png";
import renklerBg from "./assets/background/renkler.png";
import hayvanlarBg from "./assets/background/hayvanlar.png";
import sekillerBg from "./assets/background/sekiller.png";
import uzayBg from "./assets/background/uzay.png";
// import nextLevelBtn from "./assets/next-level.png";

//? real map imports
import kidsHayvanlar1 from "./assets/kids/hayvanlar/kids-hayvanlar-1.json";
import kidsHayvanlar2 from "./assets/kids/hayvanlar/kids-hayvanlar-2.json";
import kidsHayvanlar3 from "./assets/kids/hayvanlar/kids-hayvanlar-3.json";
import kidsHayvanlar4 from "./assets/kids/hayvanlar/kids-hayvanlar-4.json";
import kidsHayvanlar5 from "./assets/kids/hayvanlar/kids-hayvanlar-5.json";
import kidsHayvanlar6 from "./assets/kids/hayvanlar/kids-hayvanlar-6.json";
import kidsHayvanlar7 from "./assets/kids/hayvanlar/kids-hayvanlar-7.json";
import kidsHayvanlar8 from "./assets/kids/hayvanlar/kids-hayvanlar-8.json";
import kidsHayvanlar9 from "./assets/kids/hayvanlar/kids-hayvanlar-9.json";
import kidsHayvanlar10 from "./assets/kids/hayvanlar/kids-hayvanlar-10.json";

import kidsRenk1 from "./assets/kids/renk/kids-renk-1.json";
import kidsRenk2 from "./assets/kids/renk/kids-renk-2.json";
import kidsRenk3 from "./assets/kids/renk/kids-renk-3.json";
import kidsRenk4 from "./assets/kids/renk/kids-renk-4.json";
import kidsRenk5 from "./assets/kids/renk/kids-renk-5.json";

import kidsSekil1 from "./assets/kids/sekil/kids-sekil-1.json";
import kidsSekil2 from "./assets/kids/sekil/kids-sekil-2.json";
import kidsSekil3 from "./assets/kids/sekil/kids-sekil-3.json";
import kidsSekil4 from "./assets/kids/sekil/kids-sekil-4.json";
import kidsSekil5 from "./assets/kids/sekil/kids-sekil-5.json";

import kidsUzay1 from "./assets/kids/uzay/kids-uzay-1.json";
import kidsUzay2 from "./assets/kids/uzay/kids-uzay-2.json";
import kidsUzay3 from "./assets/kids/uzay/kids-uzay-3.json";
import kidsUzay4 from "./assets/kids/uzay/kids-uzay-4.json";
import kidsUzay5 from "./assets/kids/uzay/kids-uzay-5.json";

import kidsPortal1 from "./assets/kids/portal/ileri-portal-1.json";
import kidsPortal2 from "./assets/kids/portal/ileri-portal-2.json";
import kidsPortal3 from "./assets/kids/portal/ileri-portal-3.json";
import kidsPortal4 from "./assets/kids/portal/ileri-portal-4.json";
import kidsPortal5 from "./assets/kids/portal/ileri-portal-5.json";
import kidsPortal6 from "./assets/kids/portal/ileri-portal-6.json";
import kidsPortal7 from "./assets/kids/portal/ileri-portal-7.json";
import kidsPortal8 from "./assets/kids/portal/ileri-portal-8.json";
import kidsPortal9 from "./assets/kids/portal/ileri-portal-9.json";
import kidsPortal10 from "./assets/kids/portal/ileri-portal-10.json";

import kidsMuzik1 from "./assets/kids/muzik/kids-muzik-1.json";
import kidsMuzik2 from "./assets/kids/muzik/kids-muzik-2.json";
import kidsMuzik3 from "./assets/kids/muzik/kids-muzik-3.json";
import kidsMuzik4 from "./assets/kids/muzik/kids-muzik-4.json";
import kidsMuzik5 from "./assets/kids/muzik/kids-muzik-5.json";

import kidsEngel1 from "./assets/kids/engel/kids-engel-1.json";
import kidsEngel2 from "./assets/kids/engel/kids-engel-2.json";
import kidsEngel3 from "./assets/kids/engel/kids-engel-3.json";
import kidsEngel4 from "./assets/kids/engel/kids-engel-4.json";
import kidsEngel5 from "./assets/kids/engel/kids-engel-5.json";

import kidsMapTileset from "./assets/new-tileset.png";

import StageModalComponent from "../stage-modal/stage-modal.jsx";
import GameMethods from "./gameMethods";
import DeviceSensorData from "../../../mymodule/ztech-vm/src/util/deviceSensorData";

import { Resizable } from "re-resizable";

import useGameSelection from "./useGameSelection";

// finish imgs
import finishBg from "./assets/finish/f.png";
import finishNext from "./assets/finish/forward.png";
import finishRestart from "./assets/finish/retry.png";
import finishHome from "./assets/finish/main-page.png";
import finishStar from "./assets/finish/s1.png";
import finishStarEmpty from "./assets/finish/s2.png";
import finishTextBg from "./assets/finish/field.png";
import workspace from "../../../mymodule/ztech-vm/src/util/myWorkspaceClass";
import styles from "./oyun.css";
import classNames from "classnames";
// language 
import { FormattedMessage } from "react-intl";

// burada oyun alanı içindeki currentGame isimli değişkeni VM e göndermek için değişken oluşturdum.
var exportCurrentGameArea = null;
var playbutton_pressed = false;
var ilkhareketbasladimi = false;

var moveList = [];
var moveStepsList = [];
var gm;
var isMovePermissionBlocks=true;


let completedLevels = JSON.parse(localStorage.getItem("completedLevels"));
const initCompLev = {
    "kids_renk": [],
    "kids_sekil": [],
    "kids_hayvanlar": [],
    "kids_uzay": [],
    "ileri_portal": [],
    "ileri_muzik": [],
    "ileri_engel": [],
}
if (completedLevels) {
    // console.log("raw",completedLevels);
    // if ("kids-renk" in completedLevels) { //! silinecek
    //     localStorage.removeItem("completedLevels");
    //     completedLevels = initCompLev;
    if (!("ileri_portal" in completedLevels))  completedLevels.ileri_portal = [];      
    if (!("ileri_muzik" in completedLevels))  completedLevels.ileri_muzik = [];
    if (!("ileri_engel" in completedLevels))  completedLevels.ileri_engel = [];
} else {
    completedLevels = initCompLev;
}
console.log("init",completedLevels);


export default function Oyun({
    vm,
    setCurrentGame,
    currentGame,
    stageWidth,
    setStageWidth,
    initialize,
    setInitialize,
    // destroyTrigger,
    mapModalTrigger,
    setMapModalTrigger,
    isFullScreen,
    isjr,
    gameDestroy,
    initialStageSize,
    device,
    // deviceChange,
    lightLevel,
    showStage,
    // setShowStage,
}) {
    
    console.log("active lang",document.documentElement.lang)

    const ws = workspace.getWorkspace();
    useEffect(() => {
        // ikinci useeffect taşındı
        if (!gameRef) {
            destroy();
        }
        return async () => {
            if (gameRef) {
                gameRef.current.destroy();
            }
        };
    }, [gameRef]);

    useEffect(() => {
  setTimeout(() => {setInitialize(true)})
   }, [document.documentElement.lang])
    

    useEffect(() => {
        return async () => {
            if (currentGame) {
                await currentGame.plugins.removeScenePlugin("GridEngine");
                currentGame?.events.emit("restartEvent");
            }
        };
    }, [currentGame]);

    useEffect(() => {
        return async () => {
            if (currentGame) {
                if (gameRef) {
                    currentGame?.events.emit("restartEvent");
                }
            }
        };
    }, [gameDestroy]);

    // useEffect(() => {
    //     if (destroyTrigger) {
    //         destroy();
    //     }
    // }, [destroyTrigger]);

    // useEffect(() => {
    //     if (gameRef == null) {
    //         destroy();
    //     }
    // }, [gameRef]);

    useEffect(() => {
        if (mapModalTrigger) {
            setIsOpen(true);
        } else {
            setIsOpen(false);
        }
    }, [mapModalTrigger]);

    let upperText = "";
    let levelCounts = [
        { category: "kids_renk", count: 5 },
        { category: "kids_sekil", count: 5 },
        { category: "kids_hayvanlar", count: 10 },
        { category: "kids_uzay", count: 5 },
        { category: "ileri_portal", count: 10 },
        { category: "ileri_muzik", count: 5 },
        { category: "ileri_engel", count: 5 },
    ];

    // let activeLevel = "kids_map_1";
    function preload() {
        this.load.audio("crash", crash);
        this.load.audio("success", successSound);
        this.load.audio("stop", robotStopSound);
        this.load.audio("move", robotMoveSound);
        this.load.image("collision-set", collisionSet);
        // this.load.image("empty-set", emptySet);
        // this.load.image("first-set", testset);
        // this.load.tilemapTiledJSON("first-map", testsetJson);
        // this.load.image("water-set", waterMapTileset);
        // this.load.tilemapTiledJSON("water-map", waterMapJson);
        // this.load.image("desert-set", desertMapTileset);
        // this.load.tilemapTiledJSON("desert-map", desertMapJson);
        this.load.tilemapTiledJSON("oyun_alanı", emptySetJson);
        this.load.image("kids-set", kidsMapTileset);

        this.load.tilemapTiledJSON("kids_hayvanlar_1", kidsHayvanlar1);
        this.load.tilemapTiledJSON("kids_hayvanlar_2", kidsHayvanlar2);
        this.load.tilemapTiledJSON("kids_hayvanlar_3", kidsHayvanlar3);
        this.load.tilemapTiledJSON("kids_hayvanlar_4", kidsHayvanlar4);
        this.load.tilemapTiledJSON("kids_hayvanlar_5", kidsHayvanlar5);
        this.load.tilemapTiledJSON("kids_hayvanlar_6", kidsHayvanlar6);
        this.load.tilemapTiledJSON("kids_hayvanlar_7", kidsHayvanlar7);
        this.load.tilemapTiledJSON("kids_hayvanlar_8", kidsHayvanlar8);
        this.load.tilemapTiledJSON("kids_hayvanlar_9", kidsHayvanlar9);
        this.load.tilemapTiledJSON("kids_hayvanlar_10", kidsHayvanlar10);

        this.load.tilemapTiledJSON("kids_renk_1", kidsRenk1);
        this.load.tilemapTiledJSON("kids_renk_2", kidsRenk2);
        this.load.tilemapTiledJSON("kids_renk_3", kidsRenk3);
        this.load.tilemapTiledJSON("kids_renk_4", kidsRenk4);
        this.load.tilemapTiledJSON("kids_renk_5", kidsRenk5);

        this.load.tilemapTiledJSON("kids_sekil_1", kidsSekil1);
        this.load.tilemapTiledJSON("kids_sekil_2", kidsSekil2);
        this.load.tilemapTiledJSON("kids_sekil_3", kidsSekil3);
        this.load.tilemapTiledJSON("kids_sekil_4", kidsSekil4);
        this.load.tilemapTiledJSON("kids_sekil_5", kidsSekil5);

        this.load.tilemapTiledJSON("kids_uzay_1", kidsUzay1);
        this.load.tilemapTiledJSON("kids_uzay_2", kidsUzay2);
        this.load.tilemapTiledJSON("kids_uzay_3", kidsUzay3);
        this.load.tilemapTiledJSON("kids_uzay_4", kidsUzay4);
        this.load.tilemapTiledJSON("kids_uzay_5", kidsUzay5);

        this.load.tilemapTiledJSON("ileri_portal_1", kidsPortal1);
        this.load.tilemapTiledJSON("ileri_portal_2", kidsPortal2);
        this.load.tilemapTiledJSON("ileri_portal_3", kidsPortal3);
        this.load.tilemapTiledJSON("ileri_portal_4", kidsPortal4);
        this.load.tilemapTiledJSON("ileri_portal_5", kidsPortal5);
        this.load.tilemapTiledJSON("ileri_portal_6", kidsPortal6);
        this.load.tilemapTiledJSON("ileri_portal_7", kidsPortal7);
        this.load.tilemapTiledJSON("ileri_portal_8", kidsPortal8);
        this.load.tilemapTiledJSON("ileri_portal_9", kidsPortal9);
        this.load.tilemapTiledJSON("ileri_portal_10", kidsPortal10);

        this.load.tilemapTiledJSON("ileri_muzik_1", kidsMuzik1);
        this.load.tilemapTiledJSON("ileri_muzik_2", kidsMuzik2);
        this.load.tilemapTiledJSON("ileri_muzik_3", kidsMuzik3);
        this.load.tilemapTiledJSON("ileri_muzik_4", kidsMuzik4);
        this.load.tilemapTiledJSON("ileri_muzik_5", kidsMuzik5);

        this.load.tilemapTiledJSON("ileri_engel_1", kidsEngel1);
        this.load.tilemapTiledJSON("ileri_engel_2", kidsEngel2);
        this.load.tilemapTiledJSON("ileri_engel_3", kidsEngel3);
        this.load.tilemapTiledJSON("ileri_engel_4", kidsEngel4);
        this.load.tilemapTiledJSON("ileri_engel_5", kidsEngel5);

        this.load.spritesheet("coin", coinSpriteSheet, {
            frameWidth: 100,
            frameHeight: 100,
        });
        this.load.spritesheet("player", robotNew, {
            // frameWidth: 535,
            // frameHeight: 534,
            frameWidth: 267,
            frameHeight: 267,
        });

        // this.load.spritesheet("player", isochar, {
        //     frameWidth: 15,
        //     frameHeight: 32,
        // });
        // this.load.spritesheet("player2", isochar2, {
        //     frameWidth: 15,
        //     frameHeight: 32,
        // });

        // this.load.image("next", nextLevelBtn);
        this.load.image("bg", finishBg);
        this.load.image("restart", finishRestart);
        this.load.image("home", finishHome);
        this.load.image("next", finishNext);
        this.load.image("star", finishStar);
        this.load.image("star-empty", finishStarEmpty);
        this.load.image("text-bg", finishTextBg);

        // robot parts
        this.load.image("robot-screen-l", robotScreenL);
        this.load.image("robot-screen-r", robotScreenR);
        this.load.spritesheet("robot-lights", robotLights, { frameWidth: 535, frameHeight: 534 });
    }

    function update() {
        // Burada vm içerisindeki virtual-machine.js dosyasındaki postGame() adlı methoda aktif oyun alanına gönderildi.

        vm.postGame(exportCurrentGameArea);
        vm.postClass(gm);

        if (moveList.length != 0) {
            if (!ilkhareketbasladimi) {
                blockMoveSelected(moveList[0], moveStepsList[0]);
                ilkhareketbasladimi = true;
            }
        }

        const _this = this;
        //* move the player to the next tile
        
        this.game.events.on("moveEvent", function (data) {
            if (data === "down-left") {
                _this.gridEngine.move("player", "down-left");
            } else if (data === "down-right") {
                _this.gridEngine.move("player", "down-right");
            } else if (data === "up-left") {
                _this.gridEngine.move("player", "up-left");
            } else if (data === "up-right") {
                _this.gridEngine.move("player", "up-right");
            }
        });
        
     

        //* bloks event function
        this.game.events.on("startEvent", function (data) {
            if (data === "play_button") {
                playbutton_pressed = true;
            } else if (data === "down-right") {
            }
        });

        //* change speed function
        this.game.events.on("speedEvent", function (data) {
            _this.gridEngine.setSpeed("player", data);
        });

        // * change rotation function
        this.game.events.on("turnEvent", function (data) {
            if (data === "up-left") {
                _this.gridEngine.turnTowards("player", "up-left");
                _this.gridEngine.getSprite("player").setFrame(15);
            } else if (data === "down-right") {
                _this.gridEngine.turnTowards("player", "down-right");
                _this.gridEngine.getSprite("player").setFrame(35);
            } else if (data === "up-right") {
                _this.gridEngine.turnTowards("player", "up-right");
                _this.gridEngine.getSprite("player").setFrame(25);
            } else if (data === "down-left") {
                _this.gridEngine.turnTowards("player", "down-left");
                _this.gridEngine.getSprite("player").setFrame(5);
            } 
        });

        this.game.events.on("colorEvent", function (data) {
            _this.detectColor = data;
        });

        this.game.events.on("setBackground", function (data) {
            upperText.setStyle({
                color: data.color,
            });
            // _this.cameras.main.setBackgroundColor(data.bgColor);
        });

        this.game.events.on("moveToEvent", function (data) {
            const position = _this.gridEngine.getPosition("player");

            if (data.dir === "down-left") {
                _this.gridEngine.moveTo(
                    "player",
                    { x: position.x, y: position.y + data.step },
                    {
                        noPathFoundStrategy: "CLOSEST_REACHABLE",
                        pathBlockedStrategy: "STOP",
                        isPositionAllowedFn: (pos) => {
                            return pos.x === position.x;
                        },
                    }
                );
            } else if (data.dir === "down-right") {
                _this.gridEngine.moveTo(
                    "player",
                    { x: position.x + data.step, y: position.y },
                    {
                        noPathFoundStrategy: "CLOSEST_REACHABLE",
                        pathBlockedStrategy: "STOP",
                        isPositionAllowedFn: (pos) => {
                            return pos.y === position.y;
                        },
                    }
                );
            } else if (data.dir === "up-left") {
                _this.gridEngine.moveTo(
                    "player",
                    { x: position.x - data.step, y: position.y },
                    {
                        noPathFoundStrategy: "CLOSEST_REACHABLE",
                        pathBlockedStrategy: "STOP",
                        isPositionAllowedFn: (pos) => {
                            return pos.y === position.y;
                        },
                    }
                );
            } else if (data.dir === "up-right") {
                _this.gridEngine.moveTo(
                    "player",
                    { x: position.x, y: position.y - data.step },
                    {
                        noPathFoundStrategy: "CLOSEST_REACHABLE",
                        pathBlockedStrategy: "STOP",
                        isPositionAllowedFn: (pos) => {
                            return pos.x === position.x;
                        },
                    }
                );
            }
        });

        if (
            !this.gridEngine.isMoving("player") &&
            ilkhareketbasladimi == true
        ) {
            //Burada hareket bitmiştir.

            gm.moveListShift();
            if (moveList.length != 0) {
                blockMoveSelected(moveList[0], moveStepsList[0]);
                if (moveList.length <= 1) {
                    gm.actionPermissionTrue();
                }
            } else {
                ilkhareketbasladimi = false;
                playbutton_pressed = false;
            }
        }
    }

    async function blockMoveSelected(data, steps) {
        if (data == "downRight") {
            gm.moveDownRight();
        } else if (data == "downLeft") {
            gm.moveDownLeft();
        } else if (data == "upLeft") {
            gm.moveUpLeft();
        } else if (data == "upRight") {
            gm.moveUpRight();
        } else if (data == "moveForwordForever") {
            gm.moveForwordForever();
        } else if (data == "moveBackwardForever") {
            gm.moveBackwardForever();
        }
    }

    function hasColorTrigger(tilemap, position, colorProps, direction) {
        return tilemap.layers.some((layer) => {
            let tile;
            if (direction === "up-right") {
                tile = tilemap.getTileAt(
                    position.x,
                    position.y - parseInt(colorProps.range),
                    false,
                    layer.name
                );
            } else if (direction === "down-right") {
                tile = tilemap.getTileAt(
                    position.x + parseInt(colorProps.range),
                    position.y,
                    false,
                    layer.name
                );
            } else if (direction === "down-left") {
                tile = tilemap.getTileAt(
                    position.x,
                    position.y + parseInt(colorProps.range),
                    false,
                    layer.name
                );
            } else if (direction === "up-left") {
                tile = tilemap.getTileAt(
                    position.x - parseInt(colorProps.range),
                    position.y,
                    false,
                    layer.name
                );
            }
            return tile?.properties?.color === colorProps.color;
        });
    }

    function hasColorTriggerOnTile(tilemap, position) {
        return tilemap.layers.some((layer) => {
            let tile = tilemap.getTileAt(
                position.x,
                position.y,
                false,
                layer.name
            );
            console.log("colort",tile?.properties?.color)
            const color = tile?.properties?.color;
            return color;
        });
    }
        /// BUrası engel için yapılan fonksiyon
    function hasCollide(tilemap, position, direction) {
        return tilemap.layers.some((layer) => {
            let tile;
            let outOfStage = false;
            if (direction === "up-right") {
                outOfStage = position.y - 1 < 0;
                tile = tilemap.getTileAt(
                    position.x,
                    position.y - 1,
                    false,
                    layer.name
                );
            } else if (direction === "down-right") {
                outOfStage = position.x + 1 > 7;
                tile = tilemap.getTileAt(
                    position.x + 1,
                    position.y,
                    false,
                    layer.name
                );
            } else if (direction === "down-left") {
                outOfStage = position.y + 1 > 7;
                tile = tilemap.getTileAt(
                    position.x,
                    position.y + 1,
                    false,
                    layer.name
                );
            } else if (direction === "up-left") {
                outOfStage = position.x - 1 < 0;
                tile = tilemap.getTileAt(
                    position.x - 1,
                    position.y,
                    false,
                    layer.name
                );
            }
            //alert(tile);
            console.log(tile);
            console.log(tile?.properties?.ge_collide);  //buradan geliyor
            console.log(outOfStage);
            if(tile?.properties?.ge_collide == true){
                vm.runtime.startHats('game_when_detect_barrier', {
                    detect_barrier: 'no_barrier'
                });
            }else{

                vm.runtime.startHats('game_when_detect_barrier', {
                    detect_barrier: 'barrier'
                });
            }
            return tile?.properties?.ge_collide || outOfStage;
        });
    }

    function hasTrigger(tilemap, position, propertyName) {
        let tileProperty;
       const trigger = tilemap.layers.some((layer) => {
            const tile = tilemap.getTileAt(
                position.x,
                position.y,
                false,
                layer.name
            );
            tileProperty = tile?.properties[propertyName];
            console.log("color",tileProperty)
            return tileProperty;
        });
        console.log("color2",trigger);
        console.log(vm);
        vm.runtime.startHats('game_when_detect_color', {
            detect_color: tileProperty
        });
        if (trigger) {
            return tileProperty;
        }
        
    }

    const getActiveMapColors = (tilemap) => {
        const colors = [];
        tilemap.layers.forEach((layer) => {
            layer.data.forEach((row) => {
                row.forEach((tile) => {
                    if (tile.properties.color) {
                        if (tile.properties.color !== "black" && tile.properties.color !== "white") {
                            colors.push(tile.properties.color);
                        }
                    }
                });
            });
        });
        return colors;
    };

    //* Tint tiles with given color for coming road 
    function tintTile(tilemap, row, col, color) {
        for (let i = 0; i < tilemap.layers.length; i++) {
            tilemap.layers[i].tilemapLayer.layer.data[row][col].tint = color;
        }
    }

    function createPlayerAnimation(
        name,
        startFrame,
        endFrame,
        texture,
        frameRate = 10,
        repeat = -1,
        yoyo = false
    ) {
        this.anims.create({
            key: name,
            frames: this.anims.generateFrameNumbers(texture, {
                start: startFrame,
                end: endFrame,
            }),
            frameRate: frameRate,
            repeat: repeat,
            yoyo: yoyo,
        });
    }

    function getStopFrame(direction, isForward) {
        if (isForward) {
            switch (direction) {
                case "up-right":
                    return 17;
                case "down-right":
                    return 11;
                case "down-left":
                    return 5;
                case "up-left":
                    return 23;
            }
        } else {
            switch (direction) {
                case "up-right":
                    return 5;
                case "down-right":
                    return 23;
                case "down-left":
                    return 17;
                case "up-left":
                    return 11;
            }
        }
    }

    const getRobotPart = (part, dir, that) => {
        if (part === "screen") {
            if (dir === "down-left") {
                const screen = that.add.image(85, 120, "robot-screen-l");
                screen.scale = 0.4;
                return screen;
            } else if (dir === "down-right") {
                const screen = that.add.image(135, 120, "robot-screen-r");
                screen.scale = 0.4;
                return screen;
            }
        } else if (part === "robotLightYellow") {
            if (dir === "down-left") {
                const robotLight = that.add.image(90, 110, "robot-lights", 0);
                robotLight.scale = 0.4;
                return robotLight;
            } else if (dir === "down-right") {
                const robotLight = that.add.image(90, 110, "robot-lights", 1);
                robotLight.scale = 0.4;
                return robotLight;
            } else if (dir === "up-left") {
                const robotLight = that.add.image(90, 110, "robot-lights", 2);
                robotLight.scale = 0.4;
                return robotLight;
            } else if (dir === "up-right") {
                const robotLight = that.add.image(90, 110, "robot-lights", 3);
                robotLight.scale = 0.4;
                return robotLight;
            }
        } else if (part === "robotLightGreen") {
            if (dir === "down-left") {
                const robotLight = that.add.image(90, 110, "robot-lights", 4);
                robotLight.scale = 0.4;
                return robotLight;
            } else if (dir === "down-right") {
                const robotLight = that.add.image(90, 110, "robot-lights", 5);
                robotLight.scale = 0.4;
                return robotLight;
            } else if (dir === "up-left") {
                const robotLight = that.add.image(90, 110, "robot-lights", 6);
                robotLight.scale = 0.4;
                return robotLight;
            } else if (dir === "up-right") {
                const robotLight = that.add.image(90, 110, "robot-lights", 7);
                robotLight.scale = 0.4;
                return robotLight;
            }
        } else if (part === "robotLightBlue") {
            if (dir === "down-left") {
                const robotLight = that.add.image(90, 110, "robot-lights", 8);
                robotLight.scale = 0.4;
                return robotLight;
            } else if (dir === "down-right") {
                const robotLight = that.add.image(90, 110, "robot-lights", 9);
                robotLight.scale = 0.4;
                return robotLight;
            } else if (dir === "up-left") {
                const robotLight = that.add.image(90, 110, "robot-lights", 10);
                robotLight.scale = 0.4;
                return robotLight;
            } else if (dir === "up-right") {
                const robotLight = that.add.image(90, 110, "robot-lights", 11);
                robotLight.scale = 0.4;
                return robotLight;
            }
        } else if (part === "robotLightCyan") {
            if (dir === "down-left") {
                const robotLight = that.add.image(90, 110, "robot-lights", 12);
                robotLight.scale = 0.4;
                return robotLight;
            } else if (dir === "down-right") {
                const robotLight = that.add.image(90, 110, "robot-lights", 13);
                robotLight.scale = 0.4;
                return robotLight;
            } else if (dir === "up-left") {
                const robotLight = that.add.image(90, 110, "robot-lights", 14);
                robotLight.scale = 0.4;
                return robotLight;
            } else if (dir === "up-right") {
                const robotLight = that.add.image(90, 110, "robot-lights", 15);
                robotLight.scale = 0.4;
                return robotLight;
            }
        } else if (part === "robotLightMagenta") {
            if (dir === "down-left") {
                const robotLight = that.add.image(90, 110, "robot-lights", 16);
                robotLight.scale = 0.4;
                return robotLight;
            } else if (dir === "down-right") {
                const robotLight = that.add.image(90, 110, "robot-lights", 17);
                robotLight.scale = 0.4;
                return robotLight;
            } else if (dir === "up-left") {
                const robotLight = that.add.image(90, 110, "robot-lights", 18);
                robotLight.scale = 0.4;
                return robotLight;
            } else if (dir === "up-right") {
                const robotLight = that.add.image(90, 110, "robot-lights", 19);
                robotLight.scale = 0.4;
                return robotLight;
            }
        } else if (part === "robotLightRed") {
            if (dir === "down-left") {
                const robotLight = that.add.image(90, 110, "robot-lights", 20);
                robotLight.scale = 0.4;
                return robotLight;
            } else if (dir === "down-right") {
                const robotLight = that.add.image(90, 110, "robot-lights", 21);
                robotLight.scale = 0.4;
                return robotLight;
            } else if (dir === "up-left") {
                const robotLight = that.add.image(90, 110, "robot-lights", 22);
                robotLight.scale = 0.4;
                return robotLight;
            } else if (dir === "up-right") {
                const robotLight = that.add.image(90, 110, "robot-lights", 23);
                robotLight.scale = 0.4;
                return robotLight;
            }
        } else if (part === "robotLightWhite") {
            if (dir === "down-left") {
                const robotLight = that.add.image(90, 110, "robot-lights", 24);
                robotLight.scale = 0.4;
                return robotLight;
            } else if (dir === "down-right") {
                const robotLight = that.add.image(90, 110, "robot-lights", 25);
                robotLight.scale = 0.4;
                return robotLight;
            } else if (dir === "up-left") {
                const robotLight = that.add.image(90, 110, "robot-lights", 26);
                robotLight.scale = 0.4;
                return robotLight;
            } else if (dir === "up-right") {
                const robotLight = that.add.image(90, 110, "robot-lights", 27);
                robotLight.scale = 0.4;
                return robotLight;
            }
        }
    };

    const changedDir = (dir)=> {
        switch (dir) {
            case "up-left":
                dir = "down-right";
                break;
            case "up-right":
                dir = "down-left";
                break;
            case "down-left":
                dir = "up-right";
                break;
            case "down-right":
                dir = "up-left";
                break;
        }
        return dir
    }


    const mapGenerator = (
        tileSet,
        mapTitle,
        mapScale,
        coins,
        startPos,
        facingDirection,
        finishPos = { x: -1, y: -1 },
        teleportCoords
    ) => {
        const scene = {
            key: mapTitle,
            preload,
            create: function () {
                const ztechTilemap = this.make.tilemap({
                    key: mapTitle,
                });
                ztechTilemap.addTilesetImage(tileSet, tileSet);
                ztechTilemap.addTilesetImage("collision-set", "collision-set");
                //* offset config for diiferent layers
                for (let i = 0; i < ztechTilemap.layers.length; i++) {
                    const layer = ztechTilemap.createLayer(
                        i,
                        ztechTilemap.layers[i].name === "collides"
                            ? "collision-set"
                            : tileSet,
                        0,
                        // ztechTilemap.layers[i].offsety
                        (ztechTilemap.layers[i].name === "ground" || ztechTilemap.layers[i].name === "collides") ? 0 : ztechTilemap.layers[i].name === "splitter" ? -5 : -80
                    );
                    layer.scale = 0.38;
                }
                const playerSprite = this.add.sprite(0, 20, "player");
                playerSprite.scale = 0.7;
                playerSprite.setFrame(getStopFrame(facingDirection, true));
                upperText = this.add.text(0, -50, "", {
                    fontSize: "50px",
                    color: "#fff",
                });
                //! image overhaed
                let screen;
                let robotLight;
                let parts = [];
                let isScreen = false;
                let isRobotLight = "";
                //! text overhaed
                upperText.text = "";
                const container = this.add.container(0, 0, [
                    playerSprite,
                    upperText,
                    // screen,
                ]);

                this.game.events.on(
                    "changePartEvent",
                    function (data) {
                        const dir = this.gridEngine.getFacingDirection("player");
                        if (isForward) {
                            switch (data) {
                                case "screen":
                                    if (!isScreen && (dir === "down-right" || dir === "down-left")) {
                                    screen = getRobotPart(data, dir, this).setName(data);
                                    container.add(screen);
                                    isScreen = true;
                                } else {
                                    isScreen = true;
                                }
                                    break;
                                case "robotLightYellow":
                                    if (!(isRobotLight==="yellow")) {
                                        container.remove(robotLight, true);
                                        robotLight = getRobotPart(data, dir, this).setName(data);
                                        container.addAt(robotLight);
                                        isRobotLight = "yellow";
                                    }
                                    break;
                                case "robotLightGreen":
                                    if (!(isRobotLight==="green")) {
                                        container.remove(robotLight, true);
                                        robotLight = getRobotPart(data, dir, this).setName(data);
                                        container.addAt(robotLight);
                                        isRobotLight = "green";
                                    }
                                    break;
                                case "robotLightBlue":
                                    if (!(isRobotLight==="blue")) {
                                        container.remove(robotLight, true);
                                        robotLight = getRobotPart(data, dir, this).setName(data);
                                        container.addAt(robotLight);
                                        isRobotLight = "blue";
                                    }
                                    break;
                                case "robotLightCyan":
                                    if (!(isRobotLight==="cyan")) {
                                        container.remove(robotLight, true);
                                        robotLight = getRobotPart(data, dir, this).setName(data);
                                        container.addAt(robotLight);
                                        isRobotLight = "cyan";
                                    }
                                    break;
                                case "robotLightMagenta":
                                    if (!(isRobotLight==="magenta")) {
                                        container.remove(robotLight, true);
                                        robotLight = getRobotPart(data, dir, this).setName(data);
                                        container.addAt(robotLight);
                                        isRobotLight = "magenta";
                                    }
                                    break;
                                case "robotLightRed":
                                    if (!(isRobotLight==="red")) {
                                        container.remove(robotLight, true);
                                        robotLight = getRobotPart(data, dir, this).setName(data);
                                        container.addAt(robotLight);
                                        isRobotLight = "red";
                                    }
                                    break;
                                case "robotLightWhite":
                                    if (!(isRobotLight==="white")) {
                                        container.remove(robotLight, true);
                                        robotLight = getRobotPart(data, dir, this).setName(data);
                                        container.addAt(robotLight);
                                        isRobotLight = "white";
                                    }
                                    break;
                            }
                            
                        } else {
                            switch (data) {
                                case "screen":
                                    if (!isScreen) {
                                    screen = getRobotPart(data, changedDir(dir), this).setName(data);
                                    container.add(screen);
                                    isScreen = true;
                                }
                                    break;
                                case "robotLightYellow":
                                    if (!(isRobotLight==="yellow")) {
                                        container.remove(robotLight, true);
                                        robotLight = getRobotPart(data, changedDir(dir), this).setName(data);
                                        container.addAt(robotLight);
                                        isRobotLight = "yellow";
                                    }
                                    break;
                                case "robotLightGreen":
                                    if (!(isRobotLight==="green")) {
                                        container.remove(robotLight, true);
                                        robotLight = getRobotPart(data, changedDir(dir), this).setName(data);
                                        container.addAt(robotLight);
                                        isRobotLight = "green";
                                    }
                                    break;
                                case "robotLightBlue":
                                    if (!(isRobotLight==="blue")) {
                                        container.remove(robotLight, true);
                                        robotLight = getRobotPart(data, changedDir(dir), this).setName(data);
                                        container.addAt(robotLight);
                                        isRobotLight = "blue";
                                    }
                                    break;
                                case "robotLightCyan":
                                    if (!(isRobotLight==="cyan")) {
                                        container.remove(robotLight, true);
                                        robotLight = getRobotPart(data, changedDir(dir), this).setName(data);
                                        container.addAt(robotLight);
                                        isRobotLight = "cyan";
                                    }
                                    break;
                                case "robotLightMagenta":
                                    if (!(isRobotLight==="magenta")) {
                                        container.remove(robotLight, true);
                                        robotLight = getRobotPart(data, changedDir(dir), this).setName(data);
                                        container.addAt(robotLight);
                                        isRobotLight = "magenta";
                                    }
                                    break;
                                case "robotLightRed":
                                    if (!(isRobotLight==="red")) {
                                        container.remove(robotLight, true);
                                        robotLight = getRobotPart(data, changedDir(dir), this).setName(data);
                                        container.addAt(robotLight);
                                        isRobotLight = "red";
                                    }
                                    break;
                                case "robotLightWhite":
                                    if (!(isRobotLight==="white")) {
                                        container.remove(robotLight, true);
                                        robotLight = getRobotPart(data, changedDir(dir), this).setName(data);
                                        container.addAt(robotLight);
                                        isRobotLight = "white";
                                    }
                                    break;
                            }
                        }
                    },
                    this
                );

                this.game.events.on(
                    "removePartEvent",
                    function (data) {
                        switch (data) {
                            case "screen":
                                container.remove(screen, true);
                                isScreen = false;
                                break;
                            case "robotLight":
                                container.remove(robotLight, true);
                                isRobotLight = "";
                                break;
                        }
                    },
                    this
                );

                // const coinSprite = this.add.sprite(0, 0, "coin");
                // const coinSprite2 = this.add.sprite(0, 0, "coin");
                // this.anims.create({
                //     key: "coin",
                //     frames: this.anims.generateFrameNumbers("coin", {
                //         start: 0,
                //         end: 13,
                //     }),
                //     frameRate: 10,
                //     repeat: -1,
                //     yoyo: true,
                // });
                const coinSprites = [];
                for (let i = 0; i < coins?.length; i++) {
                    const coinSprite = this.add.sprite(0, 0, "coin");
                    coinSprite.name = "coin_" + i;
                    coinSprite.position = { x: coins[i].x, y: coins[i].y };
                    coinSprites.push(coinSprite);
                }

                this.anims.staggerPlay("coin", coinSprites, 100);
                // this.anims.staggerPlay("coin", coinSprite2, 100);

                this.crashSound = this.sound.add("crash");
                this.crashSound.setVolume(0.5);
                this.successSound = this.sound.add("success");
                this.successSound.setVolume(0.5);
                this.stopSound = this.sound.add("stop");
                this.stopSound.setVolume(0.5);
                this.moveSound = this.sound.add("move");
                this.moveSound.setVolume(0.5);

                this.cameras.main.setZoom(0.6);
                this.cameras.main.centerOn(110, 400);
                this.cameras.roundPixels = true; // avoid tile bleed
                this.input.on(
                    "wheel",
                    function (pointer, gameObject, deltaX, deltaY) {
                        if (
                            _this.cameras.main.zoom - deltaY * 0.005 > 0.6 &&
                            _this.cameras.main.zoom - deltaY * 0.005 < 2
                        ) {
                            _this.cameras.main.zoom -= deltaY * 0.005;
                            this.cameras.main.startFollow(container, true);
                            this.cameras.main.setFollowOffset(
                                -playerSprite.width + 180,
                                -playerSprite.height + 120
                            );
                        }
                        if (_this.cameras.main.zoom - deltaY * 0.005 <= 0.6) {
                            this.cameras.main.stopFollow();
                            this.cameras.main.centerOn(110, 400);
                        }
                    }
                );

                const gridEngineConfig = {
                    characters: [
                        {
                            id: "player",
                            sprite: playerSprite,
                            startPosition: {
                                x: startPos.x,
                                y: startPos.y,
                            },
                            facingDirection: facingDirection,
                            walkingAnimationEnabled: false,
                            offsetY: -20,
                            speed: 1,
                            container,
                        },
                        ...coinSprites?.map((sprite, index) => ({
                            id: "coin_" + index,
                            sprite,
                            startPosition: {
                                x: coins[index].x,
                                y: coins[index].y,
                            },
                            collides: false,
                        })),
                    ],
                };
                let isForward = true;
                let playerTexture = "";
                this.gridEngine.create(ztechTilemap, gridEngineConfig);
                this.gridEngine.movementStarted().subscribe(({ direction }) => {
                    if (hasCollide)
                    this.moveSound.play();
                    if (isForward) {
                        playerSprite.anims.play(direction + playerTexture);
                    } else {
                        playerSprite.anims.play(
                            direction + "-back" + playerTexture
                        );
                    }
                });

                this.gridEngine.movementStopped().subscribe(({ direction }) => {
                    this.moveSound.stop();
                    // this.stopSound.play();
                    playerSprite.anims.stop();
                    playerSprite.setFrame(getStopFrame(direction, isForward));
                });

                this.gridEngine
                    .directionChanged()
                    .subscribe(({ direction }) => {
                        playerSprite.setFrame(
                            getStopFrame(direction, isForward)
                        );
                        container.remove(screen, true);
                        container.remove(robotLight, true);
                        if (isScreen) {
                            if (isForward) {
                        if (
                            (direction === "down-right" || direction === "down-left") 
                        ) {
                            screen = getRobotPart("screen", direction, this);
                            container.add(screen);
                        }} else {
                            if (
                                (direction === "up-right" || direction === "up-left") 
                            ) {
                                screen = getRobotPart("screen", changedDir(direction), this);
                                container.add(screen);
                            }
                        }
                    }
                    switch (isRobotLight) {
                        case "yellow":
                            if (isForward) {
                                robotLight = getRobotPart("robotLightYellow", direction, this);
                                container.addAt(robotLight, 0);
                            } else {
                                robotLight = getRobotPart("robotLightYellow", changedDir(direction), this);
                                    container.addAt(robotLight, 0);
                            }                            
                            break;
                        case "green":
                            if (isForward) {
                                robotLight = getRobotPart("robotLightGreen", direction, this);
                                container.addAt(robotLight, 0);
                            } else {
                                robotLight = getRobotPart("robotLightGreen", changedDir(direction), this);
                                    container.addAt(robotLight, 0);
                            }                            
                            break;
                        case "blue":
                            if (isForward) {
                                robotLight = getRobotPart("robotLightBlue", direction, this);
                                container.addAt(robotLight, 0);
                            } else {
                                robotLight = getRobotPart("robotLightBlue", changedDir(direction), this);
                                    container.addAt(robotLight, 0);
                            }                            
                            break;
                        case "cyan":
                            if (isForward) {
                                robotLight = getRobotPart("robotLightCyan", direction, this);
                                container.addAt(robotLight, 0);
                            } else {
                                robotLight = getRobotPart("robotLightCyan", changedDir(direction), this);
                                    container.addAt(robotLight, 0);
                            }                            
                            break;
                        case "magenta":
                            if (isForward) {
                                robotLight = getRobotPart("robotLightMagenta", direction, this);
                                container.addAt(robotLight, 0);
                            } else {
                                robotLight = getRobotPart("robotLightMagenta", changedDir(direction), this);
                                    container.addAt(robotLight, 0);
                            }                            
                            break;
                        case "red":
                            if (isForward) {
                                robotLight = getRobotPart("robotLightRed", direction, this);
                                container.addAt(robotLight, 0);
                            } else {
                                robotLight = getRobotPart("robotLightRed", changedDir(direction), this);
                                    container.addAt(robotLight, 0);
                            }                            
                            break;
                        case "white":
                            if (isForward) {
                                robotLight = getRobotPart("robotLightWhite", direction, this);
                                container.addAt(robotLight, 0);
                            } else {
                                robotLight = getRobotPart("robotLightWhite", changedDir(direction), this);
                                    container.addAt(robotLight, 0);
                            }                            
                            break;
                    
                        default:
                            break;
                    }
                });
                // playerSprite.anims.play(direction + "-turn", false);
                // playerSprite.on("animationcomplete", () => {
                //     console.log("animationcomplete", direction)
                //   playerSprite.setFrame(getStopFrame(direction, isForward));
                // }
                // );
                const activeMapColors = getActiveMapColors(
                    ztechTilemap
                );

                this.gridEngine
                    .positionChangeStarted()
                    .subscribe(({ charId, exitTile, enterTile }) => {
                        console.log("positionChangeStarted", charId, exitTile, enterTile)
                        this.gridEngine.stopMovement("player");
                        this.gridEngine.turnTowards(
                            "player",
                            "down-right"
                        );
                        }, this);

                const _this = this;
                this.detectColor = { color: "blue", range: 1 };
                this.gridEngine
                    .positionChangeFinished()
                    .subscribe(({ charId, exitTile, enterTile }) => {
                        // setLastPosition(enterTile); //! for set last position
                        const parts = getGameTitle(this.game.config.gameTitle);
                        if (parts[0] === "ileri" && hasTrigger(ztechTilemap, enterTile, "color")) {
                            const color = hasTrigger(ztechTilemap, enterTile, "color");
                            DeviceSensorData.setDetectedColor(color);
                            this.game.events.once("isBlockRunEvent", () => {
                                if (activeMapColors.indexOf(color) !== -1) {
                                activeMapColors.splice(
                                    activeMapColors.indexOf(color),
                                    1
                                );
                                }
                            }, this);
                            console.log("activeMapColors", activeMapColors);
                        }
                        if (
                            enterTile.x === finishPos.x &&
                            enterTile.y === finishPos.y &&
                            activeMapColors.length === 0
                        ) {
                            // const currentGameTitleArr = _this.game.config.gameTitle.split("_");
                            // if (currentGameTitleArr[2]) {
                            //     completedLevels[currentGameTitleArr.slice(0, -1).join("_")].push(currentGameTitleArr[2]);
                            // }
                            // localStorage.setItem("completedLevels", JSON.stringify(completedLevels));

                            this.successSound.play();
                            this.gridEngine.stopMovement("player");
                            // console.log(this.game.config.gameTitle, parts.level, 
                            //     parts.title + "_" + parts.subtitle, completedLevels)
                            if (this.game.config.gameTitle !== "oyun_alanı" && !(parts.level in completedLevels[
                                    parts.title + "_" + parts.subtitle
                                ])                                
                            ) {
                                // console.log("set",completedLevels);
                                completedLevels[
                                    parts.title + "_" + parts.subtitle
                                ].push(parts.level);
                                localStorage.setItem(
                                    "completedLevels",
                                    JSON.stringify(completedLevels)
                                );
                            }
                            setTimeout(() => {
                                setIsFinish("success");
                                
                            }, 1000);

                        }
                        if (teleportCoords) {
                        for (let coord of teleportCoords) {
                            if (
                                enterTile.x === coord?.enter.x &&
                                enterTile.y === coord?.enter.y
                            ) {
                                // console.log("teleport", coord);
                                this.gridEngine.setPosition("player", {
                                    x: coord.exit.x,
                                    y: coord.exit.y,
                                });
                            }
                        }
                        }    

                        const direction =
                            this.gridEngine.getFacingDirection("player");
                        if (
                            hasColorTrigger(
                                ztechTilemap,
                                enterTile,
                                this.detectColor,
                                direction
                            )
                        ) {
                            upperText.text =
                                "You found a " +
                                this.detectColor.color +
                                " cube";
                        } else {
                            // upperText.text = "";
                        }


                        // console.log("parts", parts);
                        //! for change color of exit tile
                        // if (parts.title !== "oyun") {
                        //     tintTile(
                        //         ztechTilemap,
                        //         exitTile.y,
                        //         exitTile.x,
                        //         0x00ff00
                        //     );
                        // }
                        const findCoin = (coin) => {
                            return (
                                coin.position.x === enterTile.x &&
                                coin.position.y === enterTile.y
                            );
                        };
                        if (coinSprites[coinSprites.findIndex(findCoin)]) {
                            coinSprites[
                                coinSprites.findIndex(findCoin)
                            ].destroy(true);
                            this.successSound.play();
                        }

                        // const line1 = this.add.line(0, 0, 0, 0, 100, 100, 0xff0000).setOrigin(0);
                        // line1.setLineWidth(5);
                        // line1.setVisible(false);
                        // line1.setVisible(true);
                        // line1.setTo(exitTile.x*100, exitTile.y*100, enterTile.x*100, enterTile.y*100);
                    }, this);

                this.game.events.on(
                    "changeCharEvent",
                    function (data) {
                        const dir =
                            _this.gridEngine.getFacingDirection("player");
                        if (dir === "down-right") {
                            playerSprite.setTexture(data, 35);
                        } else if (dir === "down-left") {
                            playerSprite.setTexture(data, 5);
                        } else if (dir === "up-right") {
                            playerSprite.setTexture(data, 25);
                        } else if (dir === "up-left") {
                            playerSprite.setTexture(data, 15);
                        } else {
                            playerSprite.setTexture(data, 0);
                        }

                        playerTexture = "-" + data;
                    },
                    this
                );

                //* turn left-right function
                this.game.events.on(
                    "turnClockwiseEvent",
                    function (data) {
                        if(isMovePermissionBlocks){
                        const dir =
                            this.gridEngine.getFacingDirection("player");
                        if (data === "turnClockwise") {
                            if (dir === "down-right") {
                                this.gridEngine.turnTowards(
                                    "player",
                                    "down-left"
                                );
                            } else if (dir === "down-left") {
                                this.gridEngine.turnTowards(
                                    "player",
                                    "up-left"
                                );
                            } else if (dir === "up-right") {
                                this.gridEngine.turnTowards(
                                    "player",
                                    "down-right"
                                );
                            } else if (dir === "up-left") {
                                this.gridEngine.turnTowards(
                                    "player",
                                    "up-right"
                                );
                            }
                        } else {
                            if (dir === "down-right") {
                                this.gridEngine.turnTowards(
                                    "player",
                                    "up-right"
                                );
                            } else if (dir === "down-left") {
                                this.gridEngine.turnTowards(
                                    "player",
                                    "down-right"
                                );
                            } else if (dir === "up-right") {
                                this.gridEngine.turnTowards(
                                    "player",
                                    "up-left"
                                );
                            } else if (dir === "up-left") {
                                this.gridEngine.turnTowards(
                                    "player",
                                    "down-left"
                                );
                            }
                        }
                    }
                    },
                    this
                );
                createPlayerAnimation.call(this, "up-right", 14, 15, "player");
                createPlayerAnimation.call(this, "down-right", 8, 9, "player");
                createPlayerAnimation.call(this, "down-left", 2, 3, "player");
                createPlayerAnimation.call(this, "up-left", 20, 21, "player");
                createPlayerAnimation.call(
                    this,
                    "up-right-back",
                    0,
                    1,
                    "player"
                );
                createPlayerAnimation.call(
                    this,
                    "down-right-back",
                    18,
                    19,
                    "player"
                );
                createPlayerAnimation.call(
                    this,
                    "down-left-back",
                    12,
                    13,
                    "player"
                );
                createPlayerAnimation.call(
                    this,
                    "up-left-back",
                    6,
                    7,
                    "player"
                );
                // createPlayerAnimation.call(
                //     this,
                //     "up-right-player2",
                //     26,
                //     29,
                //     "player2"
                // );
                // createPlayerAnimation.call(
                //     this,
                //     "down-right-player2",
                //     36,
                //     39,
                //     "player2"
                // );
                // createPlayerAnimation.call(
                //     this,
                //     "down-left-player2",
                //     6,
                //     9,
                //     "player2"
                // );
                // createPlayerAnimation.call(
                //     this,
                //     "up-left-player2",
                //     16,
                //     19,
                //     "player2"
                // );
                // createPlayerAnimation.call(
                //     this,
                //     "up-right-back-player2",
                //     6,
                //     9,
                //     "player2"
                // );
                // createPlayerAnimation.call(
                //     this,
                //     "down-right-back-player2",
                //     16,
                //     19,
                //     "player2"
                // );
                // createPlayerAnimation.call(
                //     this,
                //     "down-left-back-player2",
                //     26,
                //     29,
                //     "player2"
                // );
                // createPlayerAnimation.call(
                //     this,
                //     "up-left-back-player2",
                //     36,
                //     39,
                //     "player2"
                // );
                createPlayerAnimation.call(
                    this,
                    "down-right-turn",
                    1,
                    2,
                    "player",
                    6,
                    0
                );
                createPlayerAnimation.call(
                    this,
                    "down-left-turn",
                    2,
                    1,
                    "player",
                    6,
                    0
                );
                createPlayerAnimation.call(this, "coin", 0, 13, "coin", 10);

                this.game.events.on(
                    "pauseEvent", 
                    function () {

                        this.scene.pause();
                    }, this);
                this.game.events.on(
                    "resumeEvent", 
                    function () {

                        this.scene.resume();
                    }, this);
                
                    this.game.events.on("moveStopEvent", function (data) {
                        isMovePermissionBlocks=false;
                      //  alert('gui stop');
            
                    });

                    this.game.events.on("moveStartEvent", function (data) {
                        isMovePermissionBlocks=true;
                        //alert('gui stop');
            
                    });

                if(isMovePermissionBlocks){
                 
                    
                this.game.events.on(
                    "moveForwardEvent",
                    function (continueMove) {
                       // alert('ileri gidiyor :'+isMovePermissionBlocks);
                        if(isMovePermissionBlocks){
                        let dir = this.gridEngine.getFacingDirection("player");
                        if (isForward) {
                            this.gridEngine.move("player", dir);
                        } else {
                            isForward = true;
                            switch (dir) {
                                case "up-left":
                                    dir = "down-right";
                                    break;
                                case "up-right":
                                    dir = "down-left";
                                    break;
                                case "down-left":
                                    dir = "up-right";
                                    break;
                                case "down-right":
                                    dir = "up-left";
                                    break;
                            }
                            this.gridEngine.move("player", dir);
                        }
                    
                        this.gridEngine.move("player", dir);

                        if (
                            hasCollide(
                                ztechTilemap,
                                this.gridEngine.getPosition("player"),
                                dir
                            )
                        ) {
                            this.cameras.main.shake(400, 0.02);
                            playerSprite.setTint(
                                0xff9191,
                                0xffff00,
                                0xff00ff,
                                0xffffff
                            );
                            this.crashSound.play();

                            setTimeout(() => {
                                playerSprite.clearTint();
                                this.game.config.gameTitle !== "oyun_alanı" &&
                                    setIsFinish("error");
                            }, 400);
                        } else if (continueMove) {
                            if (
                                !hasCollide(
                                    ztechTilemap,
                                    this.gridEngine.getPosition("player"),
                                    dir
                                    )
                                    ) {
                                        this.game.events.once("moveForwardContEvent", function () {
                                            this.game.events.emit("moveForwardEvent", true);
                                        },this);
                                    }
                            
                            this.gridEngine
                            .movementStopped()
                            .subscribe(() => {
                                this.game.events.emit("moveForwardContEvent");
                            })
                        }
                    }
                    },
                    this
                );

                this.game.events.on(
                    "moveBackwardEvent",
                    function (continueMove) {
                        if(isMovePermissionBlocks){
                        let dir = this.gridEngine.getFacingDirection("player");
                        if (isForward) {
                            isForward = false;
                            switch (dir) {
                                case "up-left":
                                    dir = "down-right";
                                    break;
                                case "up-right":
                                    dir = "down-left";
                                    break;
                                case "down-left":
                                    dir = "up-right";
                                    break;
                                case "down-right":
                                    dir = "up-left";
                                    break;
                            }
                            this.gridEngine.move("player", dir);
                        } else {
                            this.gridEngine.move("player", dir);
                        }
                    

                        if (
                            hasCollide(
                                ztechTilemap,
                                this.gridEngine.getPosition("player"),
                                dir
                            )
                        ) {
                            this.cameras.main.shake(400, 0.02);
                            playerSprite.setTint(
                                0xff9191,
                                0xffff00,
                                0xff00ff,
                                0xffffff
                            );
                            this.crashSound.play();

                            setTimeout(() => {
                                playerSprite.clearTint();
                                this.game.config.gameTitle !== "oyun_alanı" &&
                                    setIsFinish("error");
                            }, 400);
                        } else if (continueMove) {
                            if (
                                !hasCollide(
                                    ztechTilemap,
                                    this.gridEngine.getPosition("player"),
                                    dir
                                    )
                                    ) {
                                        this.game.events.once("moveBackwardContEvent", function () {
                                            this.game.events.emit("moveBackwardEvent", true);
                                        },this);
                                    }
                            
                            this.gridEngine
                            .movementStopped()
                            .subscribe(() => {
                                this.game.events.emit("moveBackwardContEvent");
                            })
                        }
                    }
                    },
                    this
                );
                }else{
                    //alert('isMovePermissionBlocks stop');
                }

                this.game.events.on("shakeChar", function (data) {
                    if (data > 0) {
                        _this.cameras.main.shake(400, 0.02);
                        playerSprite.setTint(
                            0xff9191,
                            0xffff00,
                            0xff00ff,
                            0xffffff
                        );

                        _this.crashSound.play();
                        setTimeout(() => {
                            playerSprite.clearTint();
                        }, 400);
                        setTimeout(() => {
                            this.game.config.gameTitle !== "oyun_alanı" &&
                                setIsFinish("error");
                        }, 1200);
                    }
                    DeviceSensorData.setIsShake(true);
                });
                // this.game.events.on("lightEvent", function (data) { //! oyuna sonradan karartma eklenmesi için
                //     _this.cameras.main.setBackgroundColor(
                //         `rgba(0,0,50, ${data / 200})`
                //     );
                // });
                // change background image
                this.game.events.on("changeBgEvent", function (data) {
                    switch (data) {
                        case "kids_sekil":
                            setBgImg(sekillerBg);
                            break;
                        case "kids_renk":
                            setBgImg(renklerBg);
                            break;
                        case "kids_uzay":
                            setBgImg(uzayBg);
                            break;
                        case "oyun_alanı":
                            setBgImg(emptyBg);
                            break;
                        case "kids_hayvanlar":
                            setBgImg(hayvanlarBg);
                            break;
                        default:
                            setBgImg(emptyBg);
                            break;
                    }
                });

                this.game.events.on("teleportEvent", function (data) {
                    _this.gridEngine.setPosition("player", data);
                });
                // restart game
                this.game.events.on(
                    "restartEvent",
                    function () {
                        vm.stopAll();
                        // ws?.clear();
                        console.log(this.game.events.eventNames());
                        this.game.events.removeAllListeners("moveForwardEvent");
                        this.game.events.removeAllListeners(
                            "moveBackwardEvent"
                        );
                        this.game.events.removeAllListeners("shakeChar");
                        this.game.events.removeAllListeners("lightEvent");
                        this.game.events.removeAllListeners("teleportEvent");
                        this.game.events.removeAllListeners("changeCharEvent");
                        this.game.events.removeAllListeners("moveEvent");
                        this.game.events.removeAllListeners("startEvent");
                        this.game.events.removeAllListeners("speedEvent");
                        this.game.events.removeAllListeners("turnEvent");
                        this.game.events.removeAllListeners("colorEvent");
                        this.game.events.removeAllListeners("setBackgroud");
                        this.game.events.removeAllListeners("moveToEvent");
                        this.game.events.removeAllListeners(
                            "turnClockwiseEvent"
                        );
                        this.game.events.removeAllListeners("changeBgEvent");
                        this.game.events.removeAllListeners("changePartEvent");
                        this.game.events.removeAllListeners("removePartEvent");
                        this.scene.restart();
                        this.game.events.removeAllListeners("restartEvent");
                        // getBgImage();
                    },
                    this
                );
            },

            update,
        };
        const config = {
            scale: {
                mode: Phaser.Scale.FIT,
                autoCenter: Phaser.Scale.CENTER_BOTH,
                // autoCenter: Phaser.Scale.CENTER_VERTICALLY,
            },
            // width: 480,
            height: "100%",
            transparent: true,
            type: Phaser.AUTO,
            scene: [scene],
            title: mapTitle,
            plugins: {
                scene: [
                    {
                        key: "GridEngine",
                        plugin: GridEngine,
                        mapping: "gridEngine",
                    },
                ],
            },
        };
        return config;
    };

    const [game, setGame] = useState(
        mapGenerator(
            "kids-set",
            "oyun_alanı",
            0.38,
            [],
            { x: 4, y: 7 },
            "up-right",
            { x: 4, y: 0 },
            [{
                enter: { x: 6, y: 6 },
                exit: { x: 2, y: 4 },
            }]
        )
    );
    // const [lastPosition, setLastPosition] = useState({ x: 0, y: 7 });
    const [modalIsOpen, setIsOpen] = useState(false);
    const [isFinish, setIsFinish] = useState(null);
    const [activeLevel, setActiveLevel] = useState("oyun_alanı");
    const [bgImg, setBgImg] = useState(null);
    const gameRef = useRef(null);
    const getCurrentGame = async () => {
        await gameRef?.current?.getInstance().then((instance) => {
            exportCurrentGameArea = instance;
            gm = new GameMethods(
                moveList,
                moveStepsList,
                exportCurrentGameArea
            );
            setCurrentGame(instance);
        });
    };

    const changeStage = async (selectedGame) => {
        console.log(selectedGame);
        vm.stopAll();
        // ws?.clear();
        const ok = await destroy();

        if (ok) {
            const newGame = useGameSelection(selectedGame);
            setGame(
                mapGenerator(
                    newGame.set,
                    newGame.map,
                    newGame.scale,
                    newGame.coins,
                    newGame.start,
                    newGame.facing,
                    newGame.finish || { x: -1, y: -1 },
                    newGame.teleportCoords,
                )
            );
            setTimeout(() => {
                setInitialize(true);
            }, 100);
            setActiveLevel(selectedGame);
        }
        return;
    };

    useEffect(() => {
        if (!initialize) {
            if (gameRef?.current?.className === "hydrated") {
                if (device.deviceId) {
                    setInitialize(true);
                    currentGame?.events.emit("restartEvent");
                }
            }
        }
    }, [gameRef?.current?.className]);

    useEffect(() => {
        setInitialize(false);
        currentGame?.events.emit("restartEvent");
    }, [isjr]);

    useEffect(() => {
        if (initialize) {
            getCurrentGame();
            currentGame?.events.emit("restartEvent");
        }
    }, [initialize]);

    useEffect(() => {
        if (isFullScreen) {
            setStageWidth(window.innerWidth);
        } else {
            setStageWidth(initialStageSize);
        }
    }, [isFullScreen]);

    // Call `setInitialize` when you want to initialize your game! :)
    // getCurrentGame();
    const destroy = async () => {
        setInitialize(false);
        gameRef?.current?.getInstance().then((instance) => {
            exportCurrentGameArea = instance;
            gm = new GameMethods(
                moveList,
                moveStepsList,
                exportCurrentGameArea
            );
            instance.plugins.removeScenePlugin("GridEngine");
        });

        if (gameRef.current) {
            if (currentGame) {
                currentGame.plugins.removeScenePlugin("GridEngine");
            }
            gameRef.current.destroy();
        }
        return true;
    };

    // const getBgImage = () => {
    //     const currentGameTitle = currentGame?.config.gameTitle
    //         .split("_")
    //         .slice(0, 2)
    //         .join("_");
    //     switch (currentGameTitle) {
    //         case "kids_sekil":
    //             setBgImg(sekillerBg);
    //         case "kids_renk":
    //             return renklerBg;
    //         case "kids_uzay":
    //             return uzayBg;
    //         case "oyun_alanı":
    //             setBgImg(emptyBg);
    //         case "kids_hayvanlar":
    //             return hayvanlarBg;
    //         default:
    //             return emptyBg;
    //     }
    // };

    const getBgImage = () => {
        console.log(currentGame?.config.gameTitle)
        const currentGameTitle = currentGame?.config.gameTitle
            .split("_")
            .slice(0, 2)
            .join("_");
        console.log(currentGameTitle)
        switch (currentGameTitle) {
            case "kids_sekil":
                setBgImg(sekillerBg);
                break;
            case "kids_renk":
                setBgImg(renklerBg);
                break;
            case "kids_uzay":
                setBgImg(uzayBg);
                break;
            case "oyun_alanı":
                setBgImg(emptyBg);
                break;
            case "kids_hayvanlar":
                setBgImg(hayvanlarBg);
                break;
            case "ileri_portal":
                setBgImg(uzayBg);
                break;
            case "ileri_muzik":
                setBgImg(renklerBg);
                break;
            case "ileri_engel":
                setBgImg(sekillerBg);
                break;
            default:
                setBgImg(emptyBg);
                break;
        }
    };

    useEffect(() => {
        console.log(currentGame?.config.gameTitle)
        if (currentGame) {
            getBgImage();
        }
    }, [currentGame?.config.gameTitle]);

    useEffect(() => {
        setTimeout(() => {
            window.dispatchEvent(new Event("resize"));
        }, 300);
    }, [showStage, isFullScreen, stageWidth]);

    
    const getNextCategory = (activeLevel) => {
        switch (activeLevel) {
            case "oyun_alanı":
                return "water-map";
            case "kids_renk":
                return "kids_sekil";
            case "kids_sekil":
                return "kids_hayvanlar";
            case "kids_hayvanlar":
                return "kids_uzay";
            case "kids_uzay":
                return "ilerleme-portal";
            default:
                return "oyun_alanı";
        }
    };

    console.log(currentGame?.config.gameTitle);

    const getGameTitle = (activeLevel) => {
        const parts = activeLevel.split("_");
        return {
            title: parts[0],
            subtitle: parts[1],
            level: parts[2],
        };
    };

    return (
        <>
            <Resizable
                style={{
                    backgroundImage: `linear-gradient(rgba(0,0,50, ${
                        (100 - lightLevel) / 100
                    }), rgba(0,0,50, ${(100 - lightLevel) / 200})), 
                    url(${bgImg})`,
                    backgroundPosition: "right -4px bottom",
                    backgroundSize: "cover",
                    backgroundRepeat: "no-repeat",
                    overflow: "hidden",
                    borderRadius: "8px",
                    backgroundColor: "#fff",
                    transition: "all 0.3s ease-in-out",
                }}
                enable={{
                    top: false,
                    right: false,
                    bottom: false,
                    left: true,
                    topRight: false,
                    bottomRight: false,
                    bottomLeft: false,
                    topLeft: false,
                }}
                snap={{
                    x: [550, 800, 1100, 1400],
                }}
                minWidth={showStage ? 550 : 0}
                maxWidth={isFullScreen?"100vw":"60vw"}
                size={{
                    width: showStage || isFullScreen ? stageWidth : 0,
                    height: isFullScreen
                        ? window.innerHeight - 20
                        : window.innerHeight - 90,
                }}
                // maxWidth={isFullScreen ? window.innerWidth : window.innerWidth * 0.6}
                // maxHeight={isFullScreen ? window.innerHeight - 20 : window.innerHeight-100}
                lockAspectRatio={false}
                onResizeStart={(e, direction, ref, d) => {
                    ref.style.opacity = 0.1;
                }}
                onResizeStop={(e, direction, ref, d) => {
                    setStageWidth(stageWidth + d.width);
                    window.dispatchEvent(new Event("resize"));
                    ref.style.opacity = 1;
                }}
                handleComponent={{
                    left: (
                        <div
                            style={{
                                display: `${isFullScreen ? "none": "flex"}`,
                                justifyContent: "center",
                                alignItems: "center",
                                width: "18px",
                                height: "100%",
                                backgroundColor: "rgba(255,255,255,0.01)",
                                borderRadius: "0 50% 50% 0",
                                transition: "all 0.3s ease-in-out",
                                // border: "3px double rgba(255,255,255,0.01)",
                            }}
                            onMouseOver={(e) => {
                                e.target.style.backgroundColor =
                                    "rgba(255,255,255,0.3)";
                                e.target.style.width = "22px";
                                // e.target.style.border =
                                // "3px double rgba(255,255,255,0.5)";
                            }}
                            onMouseLeave={(e) => {
                                e.target.style.backgroundColor =
                                    "rgba(255,255,255,0.01)";
                                e.target.style.width = "18px";
                                // e.target.style.border =
                                // "3px double rgba(255,255,255,0.05)";
                            }}
                        >
                            <img
                                src={ResizeIcon}
                                alt=""
                                style={{
                                    paddingLeft: "5px",
                                }}
                            />
                        </div>
                    ),
                }}
            >
                <IonPhaser
                    game={game}
                    ref={gameRef}
                    initialize={initialize}
                    style={{ width: "100%", paddingLeft: "0px" }}
                />
                {isFinish ? (
                    <div
                        className={classNames(styles.finishOverlay, {
                            "finish--active": isFinish,
                        })}
                    >
                        <div
                            className={classNames(styles.finishPanel)}
                            style={{
                                fontSize: stageWidth / 30,
                            }}
                        >
                            <div
                                className={classNames(
                                    styles.upperTextContainer
                                )}
                            >
                                {isFinish !== "error" ? (
                                    <div
                                        className={classNames(
                                            styles.starContainer
                                        )}
                                    >
                                        <img
                                            src={finishStar}
                                            alt=""
                                            className={classNames(styles.star)}
                                        />
                                        <img
                                            src={finishStar}
                                            alt=""
                                            className={classNames(
                                                styles.star,
                                                styles.middle
                                            )}
                                        />

                                        <img
                                            src={finishStar}
                                            alt=""
                                            className={classNames(styles.star)}
                                        />
                                    </div>
                                ) : null}
                                <h1
                                    className={classNames(styles.finishTitle, {
                                        [styles.finishError]:
                                            isFinish === "error",
                                    })}
                                >
                                    {isFinish === "success"
                                        ? <FormattedMessage
                                        defaultMessage="Congratulations"
                                        description="Successful finish header"
                                        id="gui.game.congratulations"
                                    />
                                        : <FormattedMessage
                                        defaultMessage="Try Again"
                                        description="Unsuccessful finish header"
                                        id="gui.game.tryAgain"
                                    />}
                                </h1>
                            </div>
                            <p className={classNames(styles.finishText)}>
                                {isFinish === "success"
                                    ? <FormattedMessage
                                    defaultMessage="You Have Successfully Completed This Stage"
                                    description="Successful finish description"
                                    id="gui.game.successDesc"
                                />
                                    : <FormattedMessage
                                    defaultMessage="You Must Replay This Stage"
                                    description="Unsuccessful finish description"
                                    id="gui.game.errorDesc"
                                />}
                            </p>
                            <div
                                className={classNames(
                                    styles.finishBtnContainer
                                )}
                                style={{}}
                            >
                                {currentGame?.config.gameTitle !== "oyun_alanı" && <img
                                    src={finishHome}
                                    alt=""
                                    className={classNames(styles.finishBtn)}
                                    onClick={() => {
                                        setIsFinish(null);
                                        // ws.clear();
                                        changeStage("oyun_alanı");
                                    }}
                                />}
                                <img
                                    src={finishRestart}
                                    alt=""
                                    className={classNames(styles.finishBtn)}
                                    onClick={() => {
                                        setIsFinish(null);
                                        // ws.clear();
                                        currentGame?.events.emit(
                                            "restartEvent"
                                        );
                                    }}
                                />
                                {(currentGame?.config.gameTitle !== "oyun_alanı" && isFinish !== "error") ? (
                                    <img
                                        src={finishNext}
                                        alt=""
                                        className={classNames(styles.finishBtn)}
                                        onClick={() => {
                                            const category = activeLevel
                                                .split("_")
                                                .splice(0, 2)
                                                .join("_");
                                            const categoryLevelCount =
                                                levelCounts.find(
                                                    (levelCount) =>
                                                        levelCount.category ===
                                                        category
                                                )?.count;
                                            setIsFinish(null);
                                            // ws.clear();
                                            setTimeout(() => {
                                                let levelNumber = parseInt(
                                                    activeLevel.match(/\d+/)[0]
                                                );
                                                if (
                                                    levelNumber ===
                                                    categoryLevelCount
                                                ) {
                                                    const nextLevel =
                                                        getNextCategory(
                                                            category
                                                        );
                                                    changeStage(
                                                        nextLevel + "_1"
                                                    );
                                                    return;
                                                }
                                                const nextLevel =
                                                    activeLevel.replace(
                                                        levelNumber,
                                                        levelNumber + 1
                                                    );
                                                setActiveLevel(nextLevel);
                                                changeStage(nextLevel);
                                            }, 100);
                                        }}
                                    />
                                ) : null}
                            </div>
                        </div>
                    </div>
                ) : null}
            </Resizable>
            <StageModalComponent
                changeStage={changeStage}
                setIsOpen={setIsOpen}
                modalIsOpen={modalIsOpen}
                setMapModalTrigger={setMapModalTrigger}
                activeLevel={activeLevel}
                completedLevels={completedLevels}
                levelCounts={levelCounts}
                isjr={isjr}
            />
        </>
    );
}
