const SET_JR_MODE = 'scratch-gui/SET_JR_MODE';
const SET_NORMAL_MODE = 'scratch-gui/SET_NORMAL_MODE';


const initialState = false;

const reducer = function (state, action) {
    

    if (typeof state === 'undefined') state = initialState;
    switch (action.type) {
    case SET_JR_MODE:
        return true;
    case SET_NORMAL_MODE:
        return false;
    default:
        return state;
    }
};

const setjrMode = () => ({
    type: SET_JR_MODE
});

const setNormalMode = () => ({
    type: SET_NORMAL_MODE
});



export {
    reducer as default,
    initialState as jrInitialState,
    setjrMode,
    setNormalMode
};
