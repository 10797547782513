module.exports = {
    'drums/1-snare.mp3': require('!arraybuffer-loader!./assets/drums/1-snare.mp3'),
    'drums/2-bass-drum.mp3': require('!arraybuffer-loader!./assets/drums/2-bass-drum.mp3'),
    'drums/3-side-stick.mp3': require('!arraybuffer-loader!./assets/drums/3-side-stick.mp3'),
    'drums/4-crash-cymbal.mp3': require('!arraybuffer-loader!./assets/drums/4-crash-cymbal.mp3'),
    'drums/5-open-hi-hat.mp3': require('!arraybuffer-loader!./assets/drums/5-open-hi-hat.mp3'),
    'drums/6-closed-hi-hat.mp3': require('!arraybuffer-loader!./assets/drums/6-closed-hi-hat.mp3'),
    'drums/7-tambourine.mp3': require('!arraybuffer-loader!./assets/drums/7-tambourine.mp3'),
    'drums/8-hand-clap.mp3': require('!arraybuffer-loader!./assets/drums/8-hand-clap.mp3'),
    'drums/9-claves.mp3': require('!arraybuffer-loader!./assets/drums/9-claves.mp3'),
    'drums/10-wood-block.mp3': require('!arraybuffer-loader!./assets/drums/10-wood-block.mp3'),
    'drums/11-cowbell.mp3': require('!arraybuffer-loader!./assets/drums/11-cowbell.mp3'),
    'drums/12-triangle.mp3': require('!arraybuffer-loader!./assets/drums/12-triangle.mp3'),
    'drums/13-bongo.mp3': require('!arraybuffer-loader!./assets/drums/13-bongo.mp3'),
    'drums/14-conga.mp3': require('!arraybuffer-loader!./assets/drums/14-conga.mp3'),
    'drums/15-cabasa.mp3': require('!arraybuffer-loader!./assets/drums/15-cabasa.mp3'),
    'drums/16-guiro.mp3': require('!arraybuffer-loader!./assets/drums/16-guiro.mp3'),
    'drums/17-vibraslap.mp3': require('!arraybuffer-loader!./assets/drums/17-vibraslap.mp3'),
    'drums/18-cuica.mp3': require('!arraybuffer-loader!./assets/drums/18-cuica.mp3'),
    'instruments/1-piano/24.mp3': require('!arraybuffer-loader!./assets/instruments/1-piano/24.mp3'),
    'instruments/1-piano/36.mp3': require('!arraybuffer-loader!./assets/instruments/1-piano/36.mp3'),
    'instruments/1-piano/48.mp3': require('!arraybuffer-loader!./assets/instruments/1-piano/48.mp3'),
    'instruments/1-piano/60.mp3': require('!arraybuffer-loader!./assets/instruments/1-piano/60.mp3'),
    'instruments/1-piano/72.mp3': require('!arraybuffer-loader!./assets/instruments/1-piano/72.mp3'),
    'instruments/1-piano/84.mp3': require('!arraybuffer-loader!./assets/instruments/1-piano/84.mp3'),
    'instruments/1-piano/96.mp3': require('!arraybuffer-loader!./assets/instruments/1-piano/96.mp3'),
    'instruments/1-piano/108.mp3': require('!arraybuffer-loader!./assets/instruments/1-piano/108.mp3'),
    'instruments/2-electric-piano/60.mp3': require('!arraybuffer-loader!./assets/instruments/2-electric-piano/60.mp3'),
    'instruments/3-organ/60.mp3': require('!arraybuffer-loader!./assets/instruments/3-organ/60.mp3'),
    'instruments/4-guitar/60.mp3': require('!arraybuffer-loader!./assets/instruments/4-guitar/60.mp3'),
    'instruments/5-electric-guitar/60.mp3': require(
        '!arraybuffer-loader!./assets/instruments/5-electric-guitar/60.mp3'
    ),
    'instruments/6-bass/36.mp3': require('!arraybuffer-loader!./assets/instruments/6-bass/36.mp3'),
    'instruments/6-bass/48.mp3': require('!arraybuffer-loader!./assets/instruments/6-bass/48.mp3'),
    'instruments/7-pizzicato/60.mp3': require('!arraybuffer-loader!./assets/instruments/7-pizzicato/60.mp3'),
    'instruments/8-cello/36.mp3': require('!arraybuffer-loader!./assets/instruments/8-cello/36.mp3'),
    'instruments/8-cello/48.mp3': require('!arraybuffer-loader!./assets/instruments/8-cello/48.mp3'),
    'instruments/8-cello/60.mp3': require('!arraybuffer-loader!./assets/instruments/8-cello/60.mp3'),
    'instruments/9-trombone/36.mp3': require('!arraybuffer-loader!./assets/instruments/9-trombone/36.mp3'),
    'instruments/9-trombone/48.mp3': require('!arraybuffer-loader!./assets/instruments/9-trombone/48.mp3'),
    'instruments/9-trombone/60.mp3': require('!arraybuffer-loader!./assets/instruments/9-trombone/60.mp3'),
    'instruments/10-clarinet/48.mp3': require('!arraybuffer-loader!./assets/instruments/10-clarinet/48.mp3'),
    'instruments/10-clarinet/60.mp3': require('!arraybuffer-loader!./assets/instruments/10-clarinet/60.mp3'),
    'instruments/11-saxophone/36.mp3': require('!arraybuffer-loader!./assets/instruments/11-saxophone/36.mp3'),
    'instruments/11-saxophone/60.mp3': require('!arraybuffer-loader!./assets/instruments/11-saxophone/60.mp3'),
    'instruments/11-saxophone/84.mp3': require('!arraybuffer-loader!./assets/instruments/11-saxophone/84.mp3'),
    'instruments/12-flute/60.mp3': require('!arraybuffer-loader!./assets/instruments/12-flute/60.mp3'),
    'instruments/12-flute/72.mp3': require('!arraybuffer-loader!./assets/instruments/12-flute/72.mp3'),
    'instruments/13-wooden-flute/60.mp3': require('!arraybuffer-loader!./assets/instruments/13-wooden-flute/60.mp3'),
    'instruments/13-wooden-flute/72.mp3': require('!arraybuffer-loader!./assets/instruments/13-wooden-flute/72.mp3'),
    'instruments/14-bassoon/36.mp3': require('!arraybuffer-loader!./assets/instruments/14-bassoon/36.mp3'),
    'instruments/14-bassoon/48.mp3': require('!arraybuffer-loader!./assets/instruments/14-bassoon/48.mp3'),
    'instruments/14-bassoon/60.mp3': require('!arraybuffer-loader!./assets/instruments/14-bassoon/60.mp3'),
    'instruments/15-choir/48.mp3': require('!arraybuffer-loader!./assets/instruments/15-choir/48.mp3'),
    'instruments/15-choir/60.mp3': require('!arraybuffer-loader!./assets/instruments/15-choir/60.mp3'),
    'instruments/15-choir/72.mp3': require('!arraybuffer-loader!./assets/instruments/15-choir/72.mp3'),
    'instruments/16-vibraphone/60.mp3': require('!arraybuffer-loader!./assets/instruments/16-vibraphone/60.mp3'),
    'instruments/16-vibraphone/72.mp3': require('!arraybuffer-loader!./assets/instruments/16-vibraphone/72.mp3'),
    'instruments/17-music-box/60.mp3': require('!arraybuffer-loader!./assets/instruments/17-music-box/60.mp3'),
    'instruments/18-steel-drum/60.mp3': require('!arraybuffer-loader!./assets/instruments/18-steel-drum/60.mp3'),
    'instruments/19-marimba/60.mp3': require('!arraybuffer-loader!./assets/instruments/19-marimba/60.mp3'),
    'instruments/20-synth-lead/60.mp3': require('!arraybuffer-loader!./assets/instruments/20-synth-lead/60.mp3'),
    'instruments/21-synth-pad/60.mp3': require('!arraybuffer-loader!./assets/instruments/21-synth-pad/60.mp3')
};
