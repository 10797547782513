let oyun = null;
let myclass = null;

class GameArea {
   

    static setGame (currentGameArea) {
    //    console.log('set game oldu');
        oyun = currentGameArea;
    }

    static getGame () {
       // console.log('get game oldu');
        return oyun; 
    }

    static setClass (myclassparams) {
        //    console.log('set game oldu');
            myclass = myclassparams;
    }
    
    static getClass () {
           // console.log('get game oldu');
            return myclass; 
    }
}

module.exports = GameArea;
