
let device = null;
const ReadDeviceData = require('../util/deviceDataRead');

class ConnectedDevice {
   
    static setDevice (connectdevice) {
            device = connectdevice;
        }
    
    static getDevice () {
       console.log(device);
    if(device._serialport==null){
        if(device._ble){

            if(device._ble._connected== true){
                ReadDeviceData.setRunStep(true);
                return device; 
            }
        }else{
            return device; 
        }
    }
        else {

            if(device._serialport._connected== true){

                ReadDeviceData.setRunStep(true);
            }
            return device; 
        }
    
       

           
    }

    static getdev(){
        return device;
    }

}

module.exports = ConnectedDevice;
