/*** IMPORTS FROM imports-loader ***/
var Blockly = require("../shim/blocks_compressed_vertical-blockly_compressed_vertical-messages");
var goog = require("../shim/blockly_compressed_vertical.goog");

// This file was automatically generated.  Do not modify.

'use strict';

goog.provide('Blockly.ScratchMsgs.allLocales');

goog.require('Blockly.ScratchMsgs');

Blockly.ScratchMsgs.locales["tr"] =
{
    "CONTROL_FOREVER": "sürekli tekrarla",
    "CONTROL_REPEAT": "%1 defa tekrarla",
    "CONTROL_IF": "eğer %1 ise",
    "CONTROL_ELSE": "değilse",
    "CONTROL_STOP": "durdur",
    "CONTROL_STOP_ALL": "tümü",
    "CONTROL_STOP_THIS": "bu komut dosyası",
    "CONTROL_STOP_OTHER": "sprite'daki diğer sprite dosyaları",
    "CONTROL_WAIT": "%1 saniye bekle",
    "CONTROL_WAITUNTIL": "%1 olana kadar bekle",
    "CONTROL_REPEATUNTIL": "%1' olana kadar tekrarla",
    "CONTROL_WHILE": "%1 olana kadar",
    "CONTROL_FOREACH": "%2 içindeki her %1 için",
    "CONTROL_STARTASCLONE": "ikiz olarak başladığımda",
    "CONTROL_CREATECLONEOF": "%1 in ikizini yarat",
    "CONTROL_CREATECLONEOF_MYSELF": "kendim",
    "CONTROL_DELETETHISCLONE": "bu ikizi sil",
    "CONTROL_COUNTER": "sayıcı",
    "CONTROL_INCRCOUNTER": "artış sayacı",
    "CONTROL_CLEARCOUNTER": "sayacı temizle",
    "CONTROL_ALLATONCE": "hepsi birden",
    "DATA_SETVARIABLETO": "%1 değişkenini %2 yap",
    "DATA_CHANGEVARIABLEBY": "%1 i %2 kadar değiştir",
    "DATA_SHOWVARIABLE": "%1 değişkenini göster",
    "DATA_HIDEVARIABLE": "%1 değişkenini gizle",
    "DATA_ADDTOLIST": "%1 i %2 ye ekle",
    "DATA_DELETEOFLIST": "%2 nin %1 ini sil",
    "DATA_DELETEALLOFLIST": "%1 in tümünü sil",
    "DATA_INSERTATLIST": "%3 ün %2 sine %1 ekle",
    "DATA_REPLACEITEMOFLIST": "%2 öğesinin %1 öğesini %3 ile değiştir",
    "DATA_ITEMOFLIST": "öğe %1 / %2",
    "DATA_ITEMNUMOFLIST": "%2 içindeki %1 öğesi #",
    "DATA_LENGTHOFLIST": "%1 uzunluğu",
    "DATA_LISTCONTAINSITEM": "%1 %2 içeriyor mu?",
    "DATA_SHOWLIST": "%1 listesini göster",
    "DATA_HIDELIST": "%1 listesini gizle",
    "DATA_INDEX_ALL": "tüm",
    "DATA_INDEX_LAST": "en son",
    "DATA_INDEX_RANDOM": "rastgele",
    "EVENT_WHENARDUINOBEGIN": "Pybot başladığında",
    "EVENT_WHENMICROBITBEGIN": "Mikrobit başladığında",
    "EVENT_WHENMICROBITBUTTONPRESSED": "%1 düğmesine basıldığında",
    "EVENT_WHENMICROBITPINBEINGTOUCHED": "%1 pimine dokunulduğunda",
    "EVENT_WHENMICROBITGESTURE": "%1 hareketi yapıldığında",
    "EVENT_WHENFLAGCLICKED": "%1 tıkladığında",
    "EVENT_WHENTHISSPRITECLICKED": "bu kuklaya tıklandığında",
    "EVENT_WHENSTAGECLICKED": "sahne tıklandığında",
    "EVENT_WHENTOUCHINGOBJECT": "bu hareketli grafik %1'e dokunduğunda",
    "EVENT_WHENBROADCASTRECEIVED": "%1 haberini aldığımda",
    "EVENT_WHENBACKDROPSWITCHESTO": "dekor %1 olduğunda",
    "EVENT_BACKDROP1": "dekor 1",
    "EVENT_WHENGREATERTHAN": "%1 > %2 olduğunda",
    "EVENT_WHENGREATERTHAN_TIMER": "zamanlayıcı",
    "EVENT_WHENGREATERTHAN_LOUDNESS": "ses yüksekliği",
    "EVENT_BROADCAST": "%1 haberini sal",
    "EVENT_BROADCASTANDWAIT": "%1 haberini sal ve bekle",
    "EVENT_WHENKEYPRESSED": "%1 tuşuna basıldığında",
    "EVENT_WHENMICROBITGESTURE_SHAKEN": "sarsılmış",
    "EVENT_WHENMICROBITGESTURE_TILTEDUPWARD": "yukarı doğru eğildi",
    "EVENT_WHENMICROBITGESTURE_TILTEDDOWNWARD": "aşağı doğru eğilmiş",
    "EVENT_WHENMICROBITGESTURE_TILTEDDLEFTWARD": "sola eğik",
    "EVENT_WHENMICROBITGESTURE_TILTEDDRIGHTWARD": "sağa eğik",
    "EVENT_WHENMICROBITGESTURE_FACEUP": "yüz yukarı",
    "EVENT_WHENMICROBITGESTURE_FACEDOWN": "yüzü aşağı dönük",
    "EVENT_WHENMICROBITGESTURE_FREEFALL": "serbest düşüş",
    "EVENT_WHENKEYPRESSED_SPACE": "boşluk",
    "EVENT_WHENKEYPRESSED_LEFT": "sol ok",
    "EVENT_WHENKEYPRESSED_RIGHT": "sağ ok",
    "EVENT_WHENKEYPRESSED_DOWN": "aşağı ok",
    "EVENT_WHENKEYPRESSED_UP": "yukarı ok",
    "EVENT_WHENKEYPRESSED_ANY": "herhangi",
    "LOOKS_SAYFORSECS": "%2 saniye boyunca %1 de",
    "LOOKS_SAY": "%1 de",
    "LOOKS_HELLO": "Merhaba!",
    "LOOKS_THINKFORSECS": "%2 saniye boyunca %1 diye düşün",
    "LOOKS_THINK": "%1 diye düşün",
    "LOOKS_HMM": "Hmm...",
    "LOOKS_SHOW": "göster",
    "LOOKS_HIDE": "gizle",
    "LOOKS_HIDEALLSPRITES": "tüm spriteları gizle",
    "LOOKS_EFFECT_COLOR": "renk",
    "LOOKS_EFFECT_FISHEYE": "balık gözü",
    "LOOKS_EFFECT_WHIRL": "girdap",
    "LOOKS_EFFECT_PIXELATE": "pikselleştirmek",
    "LOOKS_EFFECT_MOSAIC": "mozaik",
    "LOOKS_EFFECT_BRIGHTNESS": "parlaklık",
    "LOOKS_EFFECT_GHOST": "hayalet",
    "LOOKS_CHANGEEFFECTBY": "%1 etkisini %2 değiştir",
    "LOOKS_SETEFFECTTO": "%1 etkisini %2 yap",
    "LOOKS_CLEARGRAPHICEFFECTS": " görsel etkileri temizle ",
    "LOOKS_CHANGESIZEBY": "boyutu %1 birim değiştir",
    "LOOKS_SETSIZETO": "boyutu % %1 yap",
    "LOOKS_SIZE": "büyüklük",
    "LOOKS_CHANGESTRETCHBY": "uzatmayı %1 ile değiştir",
    "LOOKS_SETSTRETCHTO": "uzatmayı %1 % olarak ayarla",
    "LOOKS_SWITCHCOSTUMETO": "%1 kılığına geç",
    "LOOKS_NEXTCOSTUME": "sonraki kostüm",
    "LOOKS_SWITCHBACKDROPTO": "%1 dekoruna geç",
    "LOOKS_GOTOFRONTBACK": "%1 katmanına git",
    "LOOKS_GOTOFRONTBACK_FRONT": "ön",
    "LOOKS_GOTOFRONTBACK_BACK": "arka",
    "LOOKS_GOFORWARDBACKWARDLAYERS": "%2 katman %1 git",
    "LOOKS_GOFORWARDBACKWARDLAYERS_FORWARD": "ileri",
    "LOOKS_GOFORWARDBACKWARDLAYERS_BACKWARD": "geri",
    "LOOKS_BACKDROPNUMBERNAME": "dekor %1",
    "LOOKS_COSTUMENUMBERNAME": "kostüm %1",
    "LOOKS_NUMBERNAME_NUMBER": "sayı",
    "LOOKS_NUMBERNAME_NAME": "isim",
    "LOOKS_SWITCHBACKDROPTOANDWAIT": "arka planı %1 olarak değiştir ve bekle",
    "LOOKS_NEXTBACKDROP_BLOCK": "sonraki dekor",
    "LOOKS_NEXTBACKDROP": "sonraki dekor",
    "LOOKS_PREVIOUSBACKDROP": "önceki dekor",
    "LOOKS_RANDOMBACKDROP": "rastgele dekor",
    "MOTION_MOVESTEPS": "%1 adım git",
    "MOTION_TURNLEFT": "%1 %2 derece dön",
    "MOTION_TURNRIGHT": "%1 %2 derece dön",
    "MOTION_POINTINDIRECTION": "%1 yönüne dön",
    "MOTION_POINTTOWARDS": "%1 e doğru yönel",
    "MOTION_POINTTOWARDS_POINTER": "fare imleci",
    "MOTION_POINTTOWARDS_RANDOM": "rastgele yön",
    "MOTION_GOTO": "%1 e git",
    "MOTION_GOTO_POINTER": "fare imleci",
    "MOTION_GOTO_RANDOM": "rastgele konum",
    "MOTION_GOTOXY": "x: %1 y: %2 konumuna git",
    "MOTION_GLIDESECSTOXY": "%1 saniyede x: %2 y: %3 konumuna git",
    "MOTION_GLIDETO": "%1 saniyede %2 git",
    "MOTION_GLIDETO_POINTER": "fare imleci",
    "MOTION_GLIDETO_RANDOM": "rastgele konum",
    "MOTION_CHANGEXBY": "x konumunu %1 değiştir",
    "MOTION_SETX": "x konumunu %1 yap",
    "MOTION_CHANGEYBY": "y konumunu %1 değiştir",
    "MOTION_SETY": "y konumunu %1 yap",
    "MOTION_IFONEDGEBOUNCE": "kenara geldiyse sek",
    "MOTION_SETROTATIONSTYLE": "dönüş stilini %1 yap",
    "MOTION_SETROTATIONSTYLE_LEFTRIGHT": "sol-sağ",
    "MOTION_SETROTATIONSTYLE_DONTROTATE": "döndürme",
    "MOTION_SETROTATIONSTYLE_ALLAROUND": "her yerde",
    "MOTION_XPOSITION": "x konumu",
    "MOTION_YPOSITION": "y konumu",
    "MOTION_DIRECTION": "yön",
    "MOTION_SCROLLRIGHT": "sağa kaydır %1",
    "MOTION_SCROLLUP": "%1 yukarı kaydır",
    "MOTION_ALIGNSCENE": "sahneyi hizala %1",
    "MOTION_ALIGNSCENE_BOTTOMLEFT": "sol alt",
    "MOTION_ALIGNSCENE_BOTTOMRIGHT": "sağ alt",
    "MOTION_ALIGNSCENE_MIDDLE": "orta",
    "MOTION_ALIGNSCENE_TOPLEFT": "Sol üst",
    "MOTION_ALIGNSCENE_TOPRIGHT": "sağ üst",
    "MOTION_XSCROLL": "x kaydır",
    "MOTION_YSCROLL": "y kaydır",
    "MOTION_STAGE_SELECTED": "Aşama seçildi: hareket bloğu yok",
    "OPERATORS_ADD": "%1 + %2",
    "OPERATORS_SUBTRACT": "%1 - %2",
    "OPERATORS_MULTIPLY": "%1 * %2",
    "OPERATORS_DIVIDE": "%1 / %2",
    "OPERATORS_RANDOM": "%1 ile %2  arasında rastgele bir sayı seç",
    "OPERATORS_GT": "%1 > %2",
    "OPERATORS_LT": "%1 < %2",
    "OPERATORS_EQUALS": "%1 = %2",
    "OPERATORS_AND": "%1 ve %2",
    "OPERATORS_OR": "%1 veya %2",
    "OPERATORS_NOT": "%1 değil",
    "OPERATORS_JOIN": "%1 i ve %2 ile birleştir",
    "OPERATORS_JOIN_APPLE": "elma",
    "OPERATORS_JOIN_BANANA": "muz",
    "OPERATORS_LETTEROF": " %2 in %1 .harfi",
    "OPERATORS_LETTEROF_APPLE": "a",
    "OPERATORS_LENGTH": "%1 in uzunluğu",
    "OPERATORS_CONTAINS": "%1 %2 içeriyor mu?",
    "OPERATORS_MOD": "%1 mod %2",
    "OPERATORS_ROUND": "%1 i yuvarla",
    "OPERATORS_MATHOP": "%2 in %1 i",
    "OPERATORS_MATHOP_ABS": "mutlak değer",
    "OPERATORS_MATHOP_FLOOR": "bir alt sayıya yuvarla",
    "OPERATORS_MATHOP_CEILING": "bir üst sayıya yuvarla",
    "OPERATORS_MATHOP_SQRT": "karekök al",
    "OPERATORS_MATHOP_SIN": "sin",
    "OPERATORS_MATHOP_COS": "cos",
    "OPERATORS_MATHOP_TAN": "tan",
    "OPERATORS_MATHOP_ASIN": "asin",
    "OPERATORS_MATHOP_ACOS": "acos",
    "OPERATORS_MATHOP_ATAN": "atan",
    "OPERATORS_MATHOP_LN": "ln",
    "OPERATORS_MATHOP_LOG": "log",
    "OPERATORS_MATHOP_EEXP": "e ^",
    "OPERATORS_MATHOP_10EXP": "10 ^",
    "PROCEDURES_DEFINITION": "tanımla %1",
    "SENSING_TOUCHINGOBJECT": "%1 e değiyor mu?",
    "SENSING_TOUCHINGOBJECT_POINTER": "fare imleci",
    "SENSING_TOUCHINGOBJECT_EDGE": "kenar",
    "SENSING_TOUCHINGCOLOR": "%1 rengine değiyor mu?",
    "SENSING_COLORISTOUCHINGCOLOR": "%1 rengi %2 rengine değiyor mu?",
    "SENSING_DISTANCETO": "%1 e mesafe",
    "SENSING_DISTANCETO_POINTER": "fare imleci",
    "SENSING_ASKANDWAIT": "%1  diye sor ve bekle",
    "SENSING_ASK_TEXT": "Adın ne?",
    "SENSING_ANSWER": "Yanıt",
    "SENSING_KEYPRESSED": "%1  tuşuna basılı mı?",
    "SENSING_MOUSEDOWN": "fareye basıldı mı?",
    "SENSING_MOUSEX": "farenin x i",
    "SENSING_MOUSEY": "farenin y si",
    "SENSING_SETDRAGMODE": "sürükleme modunu %1 yap",
    "SENSING_SETDRAGMODE_DRAGGABLE": "sürüklenebilir",
    "SENSING_SETDRAGMODE_NOTDRAGGABLE": "sürüklenemez",
    "SENSING_LOUDNESS": "ses yüksekliği",
    "SENSING_LOUD": "yüksek sesle?",
    "SENSING_TIMER": "zamanlayıcı",
    "SENSING_RESETTIMER": "zamanlayıcıyı sıfırla",
    "SENSING_OF": "%1 of %2",
    "SENSING_OF_XPOSITION": "x position",
    "SENSING_OF_YPOSITION": "y position",
    "SENSING_OF_DIRECTION": "direction",
    "SENSING_OF_COSTUMENUMBER": "costume #",
    "SENSING_OF_COSTUMENAME": "costume name",
    "SENSING_OF_SIZE": "size",
    "SENSING_OF_VOLUME": "volume",
    "SENSING_OF_BACKDROPNUMBER": "backdrop #",
    "SENSING_OF_BACKDROPNAME": "backdrop name",
    "SENSING_OF_STAGE": "Stage",
    "SENSING_CURRENT": "şuanki %1",
    "SENSING_CURRENT_YEAR": "yıl",
    "SENSING_CURRENT_MONTH": "ay",
    "SENSING_CURRENT_DATE": "tarih",
    "SENSING_CURRENT_DAYOFWEEK": "haftanın günü",
    "SENSING_CURRENT_HOUR": "saat",
    "SENSING_CURRENT_MINUTE": "dakika",
    "SENSING_CURRENT_SECOND": "Saniye",
    "SENSING_DAYSSINCE2000": "2000 den beri geçen gün",
    "SENSING_USERNAME": "Kullanıcı adı",
    "SENSING_USERID": "kullanıcı id",
    "SOUND_PLAY": "%1 sesini başlat",
    "SOUND_PLAYUNTILDONE": `%1 sesi bitene kadar çal`,
    "SOUND_STOPALLSOUNDS": "tüm sesleri durdur",
    "SOUND_SETEFFECTO": "%1 etkisini %2 yap",
    "SOUND_CHANGEEFFECTBY": "%1 etkisini %2 değiştir",
    "SOUND_CLEAREFFECTS": " ses etkilerini temizle ",
    "SOUND_EFFECTS_PITCH": "ses perdesi",
    "SOUND_EFFECTS_PAN": "sola/sağa kaydır",
    "SOUND_CHANGEVOLUMEBY": "ses seviyesini %1 değiştir",
    "SOUND_SETVOLUMETO": "ses seviyesini %1% yap",
    "SOUND_VOLUME": "Ses düzeyi",
    "SOUND_RECORD": "kayıt...",
    "CATEGORY_MOTION": "Hareket",
    "CATEGORY_LOOKS": "Görünüm",
    "CATEGORY_SOUND": "Ses",
    "CATEGORY_EVENTS": "Olaylar",
    "CATEGORY_ENTERTAINMENT": "Eğlence",
    "CATEGORY_CONTROL": "Kontrol",
    "CATEGORY_SENSING": "Algılama",
    "CATEGORY_LIGHTING": "Aydınlatma",
    "CATEGORY_SPEAKER": "Hoparlör",
    "CATEGORY_NETWORK": "Ağ",
    "CATEGORY_OPERATORS": "Operatörler",
    "CATEGORY_VARIABLES": "Değişkenler",
    "CATEGORY_MYBLOCKS": "Bloklarım",
    "CATEGORY_LIGHTS": "Lights",

    "DUPLICATE": "Kopyalamak",
    "DELETE": "Silmek",
    "ADD_COMMENT": "Yorum ekle",
    "REMOVE_COMMENT": "Yorumu Kaldır",
    "DELETE_BLOCK": "Blok Sil",
    "DELETE_X_BLOCKS": "%1 Blok Sil",
    "DELETE_ALL_BLOCKS": "Tüm %1 blok silinsin mi?",
    "CLEAN_UP": "Blokları Temizle",
    "HELP": "Yardım",
    "UNDO": "Geri alma",
    "REDO": "Yeniden yap",
    "EDIT_PROCEDURE": "Düzenlemek",
    "SHOW_PROCEDURE_DEFINITION": "Tanıma git",
    "WORKSPACE_COMMENT_DEFAULT_TEXT": "Bir şey söyle...",
    "COLOUR_HUE_LABEL": "Renk",
    "COLOUR_SATURATION_LABEL": "Doyma",
    "COLOUR_BRIGHTNESS_LABEL": "Parlaklık",
    "CHANGE_VALUE_TITLE": "Değeri değiştir:",
    "RENAME_VARIABLE": "Değişkeni yeniden adlandır",
    "RENAME_VARIABLE_TITLE": "Tüm \"%1\" değişkenlerini şu şekilde yeniden adlandırın::",
    "RENAME_VARIABLE_MODAL_TITLE": "Değişkeni Yeniden Adlandır",
    "NEW_VARIABLE": "Bir Değişken Oluştur",
    "NEW_VARIABLE_TITLE": "Yeni değişken adı:",
    "VARIABLE_MODAL_TITLE": "Yeni Değişken",
    "VARIABLE_ALREADY_EXISTS": "\"%1\" adlı bir değişken zaten var.",
    "VARIABLE_ALREADY_EXISTS_FOR_ANOTHER_TYPE": "\"%2\" türünde başka bir değişken için \"%1\" adlı bir değişken zaten var.",
    "DELETE_VARIABLE_CONFIRMATION": "\"%2\" değişkeninin %1 kullanımı silinsin mi?",
    "CANNOT_DELETE_VARIABLE_PROCEDURE": "\"%1\" değişkeni, \"%2\ işlevinin tanımının bir parçası olduğu için silinemiyor",
    "DELETE_VARIABLE": "\"%1\" değişkenini silin",
    "NEW_PROCEDURE": "Bir Blok Oluştur",
    "PROCEDURE_ALREADY_EXISTS": "\"%1\" adlı bir prosedür zaten var.",
    "PROCEDURE_DEFAULT_NAME": "blok adı",
    "PROCEDURE_USED": "Bir blok tanımını silmek için önce bloğun tüm kullanımlarını kaldırın",
    "NEW_LIST": "Bir Liste Oluştur",
    "NEW_LIST_TITLE": "Yeni liste adı:",
    "LIST_MODAL_TITLE": "Yeni liste",
    "LIST_ALREADY_EXISTS": "\"%1\" adlı bir liste zaten var.",
    "RENAME_LIST_TITLE": "Tüm \"%1\" listelerini şu şekilde yeniden adlandırın::",
    "RENAME_LIST_MODAL_TITLE": "Listeyi Yeniden Adlandır",
    "DEFAULT_LIST_ITEM": "şey",
    "DELETE_LIST": "\"%1\" listesini silin",
    "RENAME_LIST": "Listeyi yeniden adlandır",
    "NEW_BROADCAST_MESSAGE": "Yeni Mesaj",
    "NEW_BROADCAST_MESSAGE_TITLE": "Yeni mesaj adı:",
    "BROADCAST_MODAL_TITLE": "Yeni Mesaj",
    "DEFAULT_BROADCAST_MESSAGE_NAME": "haber1",
    "OLED_CATEGORY":"OLED",
    "OLED_INIT":"\%1\ \%2\ oled başlat",
    "OLED_DRAWLINE":"\%1\ \%2\ çizgi çiz x0: \%3\ y0: \%4\, x1: \%5\ y1：\%6\ renk \%7\ ",
    "OLED_COLOR_WHITE":"beyaz",
    "OLED_COLOR_BLACK":"siyah",
    "OLED_COLOR_INVERSE":"ters renkler",
    "OLED_DRAWRECT":"\%1\ \%2\ doğru çiz x: \%3\ y: \%4\ genişlik \%5\ yükseklik \%6\ renk \%7\ ",
    "OLED_FILLRECT":"\%1\ \%2\ doğruyu doldur x: \%3\ y: \%4\ genişlik \%5\ yükseklik \%6\ renk \%7\ ",
    "OLED_DRAWCIRCLE":"\%1\ \%2\ daire çiz x: \%3\ y: \%4\ yarıçap \%5\ renk \%6\ ",
    "OLED_FILLCIRCLE":"\%1\ \%2\ daire doldur x: \%3\ y: \%4\ yarıçap \%5\ renk \%6\ ",
    "OLED_DRAWROUNDRECT":"\%1\ \%2\ yuvarlak doğru çiz x: \%3\ y: \%4\ genişlik \%5\ yükseklik \%6\ yarıçap \%7\ renk \%8\ ",
    "OLED_FILLROUNDRECT":"\%1\ \%2\ yuvarlak dikdörtgeni doldur x: \%3\ y: \%4\ genişlik \%5\ yükseklik \%6\ yarıçap \%7\ renk \%8\ ",
    "OLED_DRAWTRIANGLE":"\%1\ \%2\ üçgen çiz x0: \%3\ y0: \%4\, x1: \%5\ y1: \%6\, x2: \%7\ y2: \%8\ renk \%9\ ",
    "OLED_FILLTRIANGLE":"\%1\ \%2\ içi dolu üçgen çiz x0: \%3\ y0: \%4\, x1: \%5\ y1: \%6\, x2: \%7\ y2: \%8\ renk \%9\ ",
    "OLED_SETTEXT":"\%1\ \%2\ metin boyutunu ayarla \%3\ renk \%4\ arka plan rengi \%5\ ",
    "OLED_SETCURSOR":"\%1\ \%2\ imleci ayarla x: \%3\ y: \%4\ ",
    "OLED_PRINT":"\%1\ \%2\ yazdır \%3\ \%4\ ",
    "OLED_EOL_WARP":"yeni satır",
    "OLED_EOL_NOWARP":"aynı satır",
    "OLED_CLEAR":"\%1\ \%2\ oled temizle",
    "OLED_REFRESH":"\%1\ \%2\ oled yenile",
    "OLED_STARTSCROLL":"\%1\ \%2\ kaydırmayı başlat \%3\ y0: \%4\ y1: \%5\ ",
    "OLED_SCROLL_RIGHT":"sağ",
    "OLED_SCROLL_LEFT":"sol",
    "OLED_SCROLL_DIAGRIGHT":"sol diag",
    "OLED_SCROLL_DIAGLEFT":"sol diag",
    "OLED_STOPSCROLL":"\%1\ \%2\ kaydırmayı durdur",
    "RGBLEDSTRIP_INIT":"RGB LED strip başlat uzunluk \%1\ pin \%2\ ",
    "RGBLEDSTRIP_SETPIXELCOLOR":"\%1\ \%2\ pikselleri ayarla \%3\ renk \%4\ ",
    "RGBLED_LIGHTSUP":"\%1\ \%2\ RGB LED ışıkları aç \%3\ ",
    "RGBLEDSTRIP_FILL":"\%1\ \%2\ pikselden doldur \%3\ adet \%4\ renginde \%5\ ",
    "RGBLEDSTRIP_COLOR":"\%1\ \%2\ RGB LED strip renk R \%3\ G \%4\ B \%5\ ",
    "RGBLEDSTRIP_SETBRIGHTNESS":"\%1\ \%2\ parlaklık ayarla \%3\ ",
    "RGBLEDSTRIP_CLEAR":"\%1\ \%2\ hepsini temizle",
    "RGBLEDSTRIP_SHOW":"yenile",
    "RGBLED_YAK" : '\%1\ \%2\ RGB Led Yak \%3\ \%4\ \%5\ ',
    "RGBLED_SONDUR": '\%1\ \%2\ Tüm ledleri kapat',
    "RGB_LED_YAK_SURELI_PYBOARD " : '\%1\ \%2\ Led \%3\ renk \%4\ saniye \%5\ boyunca yak soket \%6\ ',
    "RGB_LED_YAK_SURESIZ_PYBOARD" : '\%1\ \%2\ Led \%3\ renk \%4\  yak soket \%5\ ',
    "RGB_LED_KAPAT_PYBOARD" : '\%1\ \%2\ Led \%3\  kapat soket \%4\ ',
    "RGBLED_YAK_PYBOARD" : '\%1\ \%2\ RGB Led Light \%3\  \%4\  \%5\ soket \%6\ ',
    "RGBLED_SONDUR_PYBOARD" : '\%1\ \%2\ Turn off all leds soket \%3\ ',

    "SES_CAL":"\%1\ \%2\ ses çal \%3\ ",
    "SES_CAL_BITENE_KADAR":"\%1\ \%2\ sel çal \%3\ bitene kadar",
    "TUM_SESLERI_DURDUR":"\%1\ \%2\ tüm sesleri durdur",
    "NOTA_CAL":"\%1\ \%2\ nota \%3\ çal \%4\ vuruş",
    "SUS_VURUS":"\%1\ \%2\ sus \%3\ vuruş",
    "FREKANSDA_CAL":"\%1\ \%2\ \%3\ Hz frekansında ses çal \%4\ saniye",
    "SES_SEVIYESINI_DEGİSTİR":"\%1\ \%2\ ses seviyesini değiştir \%3\ ",
    "SES_SEVIYESI_YUZDE":"\%1\ \%2\ ses seviyesi \%3\ %",
    "SES_SEVIYESI":"\%1\ \%2\ ses seviyesi",

    "ILERI_GIT":"\%1\ \%2\ ileri git \%3\ % güçte \%4\ saniye",
    "GERI_GIT":"geri git \%1\ % güçte \%2\ saniye",
    "SOLA_GIT":"\%1\ \%2\ sola dön \%3\ % güçte \%4\ saniye",
    "SAGA_GIT":"\%1\ \%2\ sağa dön \%3\ % güçte \%4\ saniye",
    "GERIYE_GIT":"\%1\ \%2\ geriye git \%3\ % güçte \%4\ saniye ",
    "SOLA_DON_DERECE_BITIMI":"\%1\ \%2\ sola dön \%3\ derece bitene kadar",
    "SAGA_DON_DERECE_BITIMI":"\%1\ \%2\ saga dön \%3\ derece bitene kadar",
    "SEC_GIT":"\%1\ \%2\ \%3\ \%4\ % güçte",
    "SEC_GIT_RPM":"\%1\ \%2\ \%3\ \%4\ % rpm",
    "SOLA_SAGA_DERECELI_GIT_RPM":"\%1\ \%2\ sol tekerlik \%3\ % rpm sağ tekerlik \%4\ % rpm",
    "SOLA_SAGA_DERECELI_GIT_POWER":"\%1\ \%2\ sol tekerlik \%3\ % güçte sağ tekerlik \%4\ % güçte",
    "HAREKETI_DURDUR":"\%1\ \%2\ hareketi durdur \%3\ ",
    "RESET_ENCODER_MOTOR":"\%1\ \%2\ \%3\ encoder motoru sıfırla",
    "MOTOR_MOVES_CM":"\%1\ \%2\ motorlar \%3\ yönünde \%4\ cm kadar git",
    "MOTOR_ROTATES_AT_TIME":"\%1\ \%2\ encoder motor \%3\ gücünde \%4\ \%5\ \%6\ saniye git",
    "MOTOR_ACIYA_GORE_DON":"\%1\ \%2\ encoder motor \%3\  \%4\ derece dön",
    "MOTOR_ROTATED_ANGLE":"\%1\ \%2\ encoder motor \%3\ \%4\ açıyla git",
    "MOTOR_ACI":"\%1\ \%2\ encoder motor \%3\  \%4\ saniye",
    "RPM_SANIYE_YON":"\%1\ \%2\ encoder motor \%3\ yönünde \%4\ rpm \%5\ saniye git",
    "GUC_SANIYE_YON":"\%1\ \%2\ encoder motor \%3\ yönünde \%4\ güc \%5\ saniye git",
    "MOTOR_ROTATES_AT":"\%1\ \%2\ encoder motor \%3\ dön \%4\ \%5\ ",
    "TEMPATURE_DHT": '\%1\ \%2\ Sıcaklık ve Bağıl nem sensörünü başlat block \%3\ ',
    "TEMPATURE_READ_DHT": '\%1\ \%2\ Sıcaklık oku block \%3\ ',
    "DUGME_BASILDIMI":"\%1\ \%2\ düğmeye basıldı mı?",
    "CIHAZ_BAGLANDIMI":"\%1\ \%2\ cihaz bağlandı mı?",
    "POTANSIYOMETRE_DEGERI":"\%1\ \%2\ dişli potansiyometre değeri",
    "PIL_SEVIYESI":"pil seviyesi",
    "CIHAZ_SALLANIYORMU":"zBot sallandı?",
    "SALLANMA_SIDDETI":"sallanma şiddeti",
    "CIHAZ_EGIKMI":"\%1\ \%2\ cihaz \%3\ eğik mi?",
    "CIHAZ_KONUMU":"\%1\ \%2\ cihaz \%3\ konumlandırılması",
    "YATIS_ACISI":"yatış açısı",
    "EGIM_ACISI":"eğim açısı",
    "X_ETRAFINDA_DONUS_ACISI":"\%1\ \%2\ x etrafında dönüş açısı",
    "Y_ETRAFINDA_DONUS_ACISI":"\%1\ \%2\ y etrafında dönüş açısı",
    "Z_ETRAFINDA_DONUS_ACISI":"\%1\ \%2\ z etrafında dönüş açısı",
    "SIFIRLA_DONDURME_ACISI":"\%1\ \%2\ sıfırla \%3\ döndürme açısı",
    "ZAMANLAYICI":"\%1\ \%2\ zamanlayıcı",
    "ZAMANLAYICI_SIFIRLA":"\%1\ \%2\ zamanlayıcıyı sıfırla",
    "ONUNDEKI_ENGELLER":"önündeki engeller",
    "ALGILANAN_RENK":"\%1\ renk algılandı?",
    "RENK_DEGER_TESPITI":"\%1\ \%2\  \%3\ renk değeri tespit edildi",
    "RENK_ALGILAYICI_ORTAM_ISIK_YOGUNLUGU":"\%1\ \%2\ renk algılayıcı ortam ışık yoğunluğu",
    "RENK_ALGILAYICI_YANSIYAN_ISIK_YOGUNLUGU":"\%1\ \%2\ renk algılayıcı yansıyan ışık yoğunluğu",
    "RENK_ALGILAYICI_YANSIYAN_KIZILOTESI_ISIK_YOGUNLUGU":"\%1\ \%2\ renk algılayıcı yansıyan kızılötesi ışık yoğunluğu",
    "RENK_ALGILAYICI_GRI_OLCEK":"\%1\ \%2\ renk algılayıcı gri-ölçek değeri",
    "WIFI_CONNECT_SSID":"\%1\ \%2\ Wİ-Fİ ye bağlan \%3\ şifre \%4\ ",
    "WIFI_BAGLANDIMI":"\%1\ \%2\ Wİ-Fİ bağlımı?",
    "CONNECTED_WIFI_AGAIN":"\%1\ \%2\ Wİ-Fİ ye tekrar bağlan",
    "DISCONNECTED_WIFI_AGAIN":"\%1\ \%2\ Wİ-Fİ bağlantısını kes",
    "BUGUNUN_TARIHINI_GETIR":"\%1\ \%2\ \%3\ zaman dilimindeki tarihi getir \%4\ ",
    "RGB_LED_YAK_SURELI": "\%1\ \%2\ Led  \%3\ renk  \%4\ saniye  \%5\ boyunca yak",
    "RGB_LED_YAK_SURESIZ":"\%1\ \%2\ Led  \%3\ renk  \%4\ yak",
    "RGB_LED_KAPAT" : "\%1\ \%2\ Led  \%3\ kapat",
    "RGB_LED_BASLAT": "\%1\ \%2\ Led başlat",
    "MESAFE_VE_ISIK": "\%1\ \%2\ Mesafe ve ışık siddeti başlat",
    "PROMIXITY_UZAKLIK_SENSORU":"\%1\ \%2\ uzaklık değeri",
    "DISLI_POTANSIYOMETRI_DEGERI": "\%1\ \%2\ dişli potansiyometre değeri \%3\ ",
    "ORTAM_ISIK_SIDDETI":"ışık şiddeti",
    "YESIL_ISIK_SIDDETI_UPLOAD": "\%1\ \%2\ yeşil ışık şiddeti",
    "MAVI_ISIK_SIDDETI_UPLOAD": "\%1\ \%2\ mavi ışık şiddeti",
    "KIRMIZI_ISIK_SIDDETI_UPLOAD": "\%1\ \%2\ kırmızı ışık şiddeti",
    "START_MOTION_SENSOR": "\%1\ \%2\ Hareket sensörünü başlat",
    "START_LINE_FOLLOWER": "\%1\ \%2\ Çizgi izleme sensörünü başlat",
    "START_LINE_FONKS": "\%1\ \%2\ Çizgi İzleme fonksiyonlarını oku \%3\ ",
    "READ_LINE_NEW": "\%1\ \%2\ çizgi izle \%3\ \%4\ ",
    "EGIM_ACISI_DURUMLU": "\%1\ \%2\  \%3\  eğim açısı",
    "X_Y_Z_ACISI_DURUMLU": "\%1\ \%2\  \%3\ eğim açısı",
    "X_Y_Z_HIZ_DURUMLU": "\%1\ \%2\  \%3\ hızı m/s^2",
    "BUZER_AC": "\%1\ \%2\ Buzzer Aç",
    "BUZER_KAPAT": "\%1\ \%2\ Buzzer Kapat",
    "FREKANSDA_SES_CAL":"\%1\ \%2\  \%3\ Hz frekansında ses çal \%4\ vuruş",
    "ILERI_GIT_TEXT":"ileri git",
    "GERI_GIT_TEXT":"geri git",
    "SOLA_GIT_TEXT":"sola dön",
    "SAGA_GIT_TEXT":"sağa dön",
    "LEFT_WHELL":"sol tekerlik EM(1)",
    "RIGHT_WHELL":"sağ tekerlik EM(2)",
    "ALL_WHELL":"hepsi",
    "RPM_TEXT":"dönme hızı(RPM)",
    "POWER_TEXT":"güç(%)",
    "SAG_TEXT":"sağ",
    "SOL_TEXT":"sol",
    "HEPSI_TEXT":"hepsi",
    "KIRMIZI":"kırmızı",
    "CAMGOBEGI":"camgöbeği",
    "MAVI":"mavi",
    "YESIL":"yeşil",
    "SARI":"sarı",
    "BEYAZ":"beyaz",
    "SIYAH":"siyah",
    "GALIBARDA":"galibarda",
    "ONE_EGIK":"öne eğik",
    "GERIYE_EGIK":"geriye doğru eğik",
    "SOLA_EGIK":"sola eğik",
    "SAGA_EGIK":"sağa eğik",
    "YUZU_YUKARI_DONUK":"yüzü yukarı dönük",
    "YUZU_ASAGI_DONUK":"yüzü aşağı dönük",
    "ILERI_EGIK_TEXT":"ileri eğik",
    "GERI_EGIK_TEXT":"geri eğik",
    "SOLA_EGIK_TEXT":"sola eğik",
    "SAGA_EGIK_TEXT":"sağa eğik",
    "SENSOR1":"sensör-1",
    "SENSOR2":"sensör-2",
    "SENSOR3":"sensör-3",
    "SENSOR4":"sensör-4",
    "SENSOR5":"sensör-5",
    "ORTAM_ISIK_SIDDETI_PYBOARD":"\%1\ \%2\ ortam ışık şiddeti soket \%3\ ",
    "YESIL_ISIK_SIDDETI_UPLOAD_PYBOARD": "\%1\ \%2\ yeşil ışık şiddeti soket \%3\ ",
    "MAVI_ISIK_SIDDETI_UPLOAD_PYBOARD": "\%1\ \%2\ mavi ışık şiddeti soket \%3\ ",
    "KIRMIZI_ISIK_SIDDETI_UPLOAD_PYBOARD": "\%1\ \%2\ kırmızı ışık şiddeti soket \%3\ ",

    "ULTRASONIKINITPYBOARD" : "\%1\ \%2\ ultrasonik sensör başlat \%3\ ",
    "DISTANCEVALUEULTRASONIK" : "\%1\ \%2\ ultrasonik sensör ölçülen mesafe",
    "GETTIMEPYBOARD" : "\%1\ \%2\ \%3\ zaman dilimindeki tarihi getir  \%4\ ",

    "TEMPATURE_READ_PYBOT" : "\%1\ \%2\ sıcaklık oku \%3\ ",
    "RELATIVE_HUMIDITY_READ_PYBOT" : "\%1\ \%2\ bağıl nem oku \%3\ ",
    "WATERPROOF_TEMPATURE_INIT_PYBOARD" : "\%1\ \%2\ Su geçirmez sıcaklık sensörünü başlat  \%3\ ",
    "WATERPROOF_TEMPATURE_READ_PYBOARD" : "\%1\ \%2\ Su geçirmez sensörden sıcaklık oku \%3\ ",

    "SOUNDINTENSITY" : "\%1\ \%2\ ses şiddetini ölç \%3\ ",

    "BKY_CATEGORY_LIGHTS" : "Işık",
    "CLOCKWISE": " saat yönününde",
    "COUNTERCLOCKWISE": "saat yönünün tersinde",
    "LEFTTITLTED": "sola yatık",
    "RIGHTTITLTED": "sağa yatık",
    "FRONTTITLTED": "öne yatık",
    "BACKTITLTED": "arkaya yatık ",

};

Blockly.ScratchMsgs.locales["en"] =
{
    "CONTROL_FOREVER": "forever",
    "CONTROL_REPEAT": "repeat %1",
    "CONTROL_IF": "if %1 then",
    "CONTROL_ELSE": "else",
    "CONTROL_STOP": "stop",
    "CONTROL_STOP_ALL": "all",
    "CONTROL_STOP_THIS": "this script",
    "CONTROL_STOP_OTHER": "other scripts in sprite",
    "CONTROL_WAIT": "wait %1 seconds",
    "CONTROL_WAITUNTIL": "wait until %1",
    "CONTROL_REPEATUNTIL": "repeat until %1",
    "CONTROL_WHILE": "while %1",
    "CONTROL_FOREACH": "for each %1 in %2",
    "CONTROL_STARTASCLONE": "when I start as a clone",
    "CONTROL_CREATECLONEOF": "create clone of %1",
    "CONTROL_CREATECLONEOF_MYSELF": "myself",
    "CONTROL_DELETETHISCLONE": "delete this clone",
    "CONTROL_COUNTER": "counter",
    "CONTROL_INCRCOUNTER": "increment counter",
    "CONTROL_CLEARCOUNTER": "clear counter",
    "CONTROL_ALLATONCE": "all at once",
    "DATA_SETVARIABLETO": "set %1 to %2",
    "DATA_CHANGEVARIABLEBY": "change %1 by %2",
    "DATA_SHOWVARIABLE": "show variable %1",
    "DATA_HIDEVARIABLE": "hide variable %1",
    "DATA_ADDTOLIST": "add %1 to %2",
    "DATA_DELETEOFLIST": "delete %1 of %2",
    "DATA_DELETEALLOFLIST": "delete all of %1",
    "DATA_INSERTATLIST": "insert %1 at %2 of %3",
    "DATA_REPLACEITEMOFLIST": "replace item %1 of %2 with %3",
    "DATA_ITEMOFLIST": "item %1 of %2",
    "DATA_ITEMNUMOFLIST": "item # of %1 in %2",
    "DATA_LENGTHOFLIST": "length of %1",
    "DATA_LISTCONTAINSITEM": "%1 contains %2?",
    "DATA_SHOWLIST": "show list %1",
    "DATA_HIDELIST": "hide list %1",
    "DATA_INDEX_ALL": "all",
    "DATA_INDEX_LAST": "last",
    "DATA_INDEX_RANDOM": "random",
    "EVENT_WHENARDUINOBEGIN": "when Pybot begin",
    "EVENT_WHENMICROBITBEGIN": "when Microbit begin",
    "EVENT_WHENMICROBITBUTTONPRESSED": "when button %1 pressed",
    "EVENT_WHENMICROBITPINBEINGTOUCHED": "when pin %1 being touched",
    "EVENT_WHENMICROBITGESTURE": "when the gestrue %1",
    "EVENT_WHENFLAGCLICKED": "when %1 clicked",
    "EVENT_WHENTHISSPRITECLICKED": "when this sprite clicked",
    "EVENT_WHENSTAGECLICKED": "when stage clicked",
    "EVENT_WHENTOUCHINGOBJECT": "when this sprite touches %1",
    "EVENT_WHENBROADCASTRECEIVED": "when I receive %1",
    "EVENT_WHENBACKDROPSWITCHESTO": "when backdrop switches to %1",
    "EVENT_BACKDROP1": "dekor 1",
    "EVENT_WHENGREATERTHAN": "when %1 > %2",
    "EVENT_WHENGREATERTHAN_TIMER": "timer",
    "EVENT_WHENGREATERTHAN_LOUDNESS": "loudness",
    "EVENT_BROADCAST": "broadcast %1",
    "EVENT_BROADCASTANDWAIT": "broadcast %1 and wait",
    "EVENT_WHENKEYPRESSED": "when %1 key pressed",
    "EVENT_WHENMICROBITGESTURE_SHAKEN": "shaken",
    "EVENT_WHENMICROBITGESTURE_TILTEDUPWARD": "tilted upward",
    "EVENT_WHENMICROBITGESTURE_TILTEDDOWNWARD": "tilted downward",
    "EVENT_WHENMICROBITGESTURE_TILTEDDLEFTWARD": "tilted leftward",
    "EVENT_WHENMICROBITGESTURE_TILTEDDRIGHTWARD": "tilted rightward",
    "EVENT_WHENMICROBITGESTURE_FACEUP": "face up",
    "EVENT_WHENMICROBITGESTURE_FACEDOWN": "face down",
    "EVENT_WHENMICROBITGESTURE_FREEFALL": "freefall",
    "EVENT_WHENKEYPRESSED_SPACE": "space",
    "EVENT_WHENKEYPRESSED_LEFT": "left arrow",
    "EVENT_WHENKEYPRESSED_RIGHT": "right arrow",
    "EVENT_WHENKEYPRESSED_DOWN": "down arrow",
    "EVENT_WHENKEYPRESSED_UP": "up arrow",
    "EVENT_WHENKEYPRESSED_ANY": "any",
    "LOOKS_SAYFORSECS": "say %1 for %2 seconds",
    "LOOKS_SAY": "say %1",
    "LOOKS_HELLO": "Hello!",
    "LOOKS_THINKFORSECS": "think %1 for %2 seconds",
    "LOOKS_THINK": "think %1",
    "LOOKS_HMM": "Hmm...",
    "LOOKS_SHOW": "show",
    "LOOKS_HIDE": "hide",
    "LOOKS_HIDEALLSPRITES": "hide all sprites",
    "LOOKS_EFFECT_COLOR": "color",
    "LOOKS_EFFECT_FISHEYE": "fisheye",
    "LOOKS_EFFECT_WHIRL": "whirl",
    "LOOKS_EFFECT_PIXELATE": "pixelate",
    "LOOKS_EFFECT_MOSAIC": "mosaic",
    "LOOKS_EFFECT_BRIGHTNESS": "brightness",
    "LOOKS_EFFECT_GHOST": "ghost",
    "LOOKS_CHANGEEFFECTBY": "change %1 effect by %2",
    "LOOKS_SETEFFECTTO": "set %1 effect to %2",
    "LOOKS_CLEARGRAPHICEFFECTS": "clear graphic effects",
    "LOOKS_CHANGESIZEBY": "change size by %1",
    "LOOKS_SETSIZETO": "set size to %1 %",
    "LOOKS_SIZE": "size",
    "LOOKS_CHANGESTRETCHBY": "change stretch by %1",
    "LOOKS_SETSTRETCHTO": "set stretch to %1 %",
    "LOOKS_SWITCHCOSTUMETO": "switch costume to %1",
    "LOOKS_NEXTCOSTUME": "next costume",
    "LOOKS_SWITCHBACKDROPTO": "switch backdrop to %1",
    "LOOKS_GOTOFRONTBACK": "go to %1 layer",
    "LOOKS_GOTOFRONTBACK_FRONT": "front",
    "LOOKS_GOTOFRONTBACK_BACK": "back",
    "LOOKS_GOFORWARDBACKWARDLAYERS": "go %1 %2 layers",
    "LOOKS_GOFORWARDBACKWARDLAYERS_FORWARD": "forward",
    "LOOKS_GOFORWARDBACKWARDLAYERS_BACKWARD": "backward",
    "LOOKS_BACKDROPNUMBERNAME": "backdrop %1",
    "LOOKS_COSTUMENUMBERNAME": "costume %1",
    "LOOKS_NUMBERNAME_NUMBER": "number",
    "LOOKS_NUMBERNAME_NAME": "name",
    "LOOKS_SWITCHBACKDROPTOANDWAIT": "switch backdrop to %1 and wait",
    "LOOKS_NEXTBACKDROP_BLOCK": "next backdrop",
    "LOOKS_NEXTBACKDROP": "next backdrop",
    "LOOKS_PREVIOUSBACKDROP": "previous backdrop",
    "LOOKS_RANDOMBACKDROP": "random backdrop",
    "MOTION_MOVESTEPS": "move %1 steps",
    "MOTION_TURNLEFT": "turn %1 %2 degrees",
    "MOTION_TURNRIGHT": "turn %1 %2 degrees",
    "MOTION_POINTINDIRECTION": "point in direction %1",
    "MOTION_POINTTOWARDS": "point towards %1",
    "MOTION_POINTTOWARDS_POINTER": "mouse-pointer",
    "MOTION_POINTTOWARDS_RANDOM": "random direction",
    "MOTION_GOTO": "go to %1",
    "MOTION_GOTO_POINTER": "mouse-pointer",
    "MOTION_GOTO_RANDOM": "random position",
    "MOTION_GOTOXY": "x: %1 y: %2 konumuna ilerle",
    "MOTION_GLIDESECSTOXY": "glide %1 secs to x: %2 y: %3",
    "MOTION_GLIDETO": "glide %1 secs to %2",
    "MOTION_GLIDETO_POINTER": "mouse-pointer",
    "MOTION_GLIDETO_RANDOM": "random position",
    "MOTION_CHANGEXBY": "change x by %1",
    "MOTION_SETX": "set x to %1",
    "MOTION_CHANGEYBY": "change y by %1",
    "MOTION_SETY": "set y to %1",
    "MOTION_IFONEDGEBOUNCE": "if on edge, bounce",
    "MOTION_SETROTATIONSTYLE": "set rotation style %1",
    "MOTION_SETROTATIONSTYLE_LEFTRIGHT": "left-right",
    "MOTION_SETROTATIONSTYLE_DONTROTATE": "don't rotate",
    "MOTION_SETROTATIONSTYLE_ALLAROUND": "all around",
    "MOTION_XPOSITION": "x position",
    "MOTION_YPOSITION": "y position",
    "MOTION_DIRECTION": "direction",
    "MOTION_SCROLLRIGHT": "scroll right %1",
    "MOTION_SCROLLUP": "scroll up %1",
    "MOTION_ALIGNSCENE": "align scene %1",
    "MOTION_ALIGNSCENE_BOTTOMLEFT": "bottom-left",
    "MOTION_ALIGNSCENE_BOTTOMRIGHT": "bottom-right",
    "MOTION_ALIGNSCENE_MIDDLE": "middle",
    "MOTION_ALIGNSCENE_TOPLEFT": "top-left",
    "MOTION_ALIGNSCENE_TOPRIGHT": "top-right",
    "MOTION_XSCROLL": "x scroll",
    "MOTION_YSCROLL": "y scroll",
    "MOTION_STAGE_SELECTED": "Stage selected: no motion blocks",
    "OPERATORS_ADD": "%1 + %2",
    "OPERATORS_SUBTRACT": "%1 - %2",
    "OPERATORS_MULTIPLY": "%1 * %2",
    "OPERATORS_DIVIDE": "%1 / %2",
    "OPERATORS_RANDOM": "pick random %1 to %2",
    "OPERATORS_GT": "%1 > %2",
    "OPERATORS_LT": "%1 < %2",
    "OPERATORS_EQUALS": "%1 = %2",
    "OPERATORS_AND": "%1 and %2",
    "OPERATORS_OR": "%1 or %2",
    "OPERATORS_NOT": "not %1",
    "OPERATORS_JOIN": "join %1 %2",
    "OPERATORS_JOIN_APPLE": "apple",
    "OPERATORS_JOIN_BANANA": "banana",
    "OPERATORS_LETTEROF": "letter %1 of %2",
    "OPERATORS_LETTEROF_APPLE": "a",
    "OPERATORS_LENGTH": "length of %1",
    "OPERATORS_CONTAINS": "%1 contains %2?",
    "OPERATORS_MOD": "%1 mod %2",
    "OPERATORS_ROUND": "round %1",
    "OPERATORS_MATHOP": "%1 of %2",
    "OPERATORS_MATHOP_ABS": "abs",
    "OPERATORS_MATHOP_FLOOR": "floor",
    "OPERATORS_MATHOP_CEILING": "ceiling",
    "OPERATORS_MATHOP_SQRT": "sqrt",
    "OPERATORS_MATHOP_SIN": "sin",
    "OPERATORS_MATHOP_COS": "cos",
    "OPERATORS_MATHOP_TAN": "tan",
    "OPERATORS_MATHOP_ASIN": "asin",
    "OPERATORS_MATHOP_ACOS": "acos",
    "OPERATORS_MATHOP_ATAN": "atan",
    "OPERATORS_MATHOP_LN": "ln",
    "OPERATORS_MATHOP_LOG": "log",
    "OPERATORS_MATHOP_EEXP": "e ^",
    "OPERATORS_MATHOP_10EXP": "10 ^",
    "PROCEDURES_DEFINITION": "define %1",
    "SENSING_TOUCHINGOBJECT": "touching %1?",
    "SENSING_TOUCHINGOBJECT_POINTER": "mouse-pointer",
    "SENSING_TOUCHINGOBJECT_EDGE": "edge",
    "SENSING_TOUCHINGCOLOR": "touching color %1?",
    "SENSING_COLORISTOUCHINGCOLOR": "color %1 is touching %2?",
    "SENSING_DISTANCETO": "distance to %1",
    "SENSING_DISTANCETO_POINTER": "mouse-pointer",
    "SENSING_ASKANDWAIT": "ask %1 and wait",
    "SENSING_ASK_TEXT": "What's your name?",
    "SENSING_ANSWER": "answer",
    "SENSING_KEYPRESSED": "key %1 pressed?",
    "SENSING_MOUSEDOWN": "mouse down?",
    "SENSING_MOUSEX": "mouse x",
    "SENSING_MOUSEY": "mouse y",
    "SENSING_SETDRAGMODE": "set drag mode %1",
    "SENSING_SETDRAGMODE_DRAGGABLE": "draggable",
    "SENSING_SETDRAGMODE_NOTDRAGGABLE": "not draggable",
    "SENSING_LOUDNESS": "loudness",
    "SENSING_LOUD": "loud?",
    "SENSING_TIMER": "timer",
    "SENSING_RESETTIMER": "reset timer",
    "SENSING_OF": "%1 of %2",
    "SENSING_OF_XPOSITION": "x position",
    "SENSING_OF_YPOSITION": "y position",
    "SENSING_OF_DIRECTION": "direction",
    "SENSING_OF_COSTUMENUMBER": "costume #",
    "SENSING_OF_COSTUMENAME": "costume name",
    "SENSING_OF_SIZE": "size",
    "SENSING_OF_VOLUME": "volume",
    "SENSING_OF_BACKDROPNUMBER": "backdrop #",
    "SENSING_OF_BACKDROPNAME": "backdrop name",
    "SENSING_OF_STAGE": "Stage",
    "SENSING_CURRENT": "current %1",
    "SENSING_CURRENT_YEAR": "year",
    "SENSING_CURRENT_MONTH": "month",
    "SENSING_CURRENT_DATE": "date",
    "SENSING_CURRENT_DAYOFWEEK": "day of week",
    "SENSING_CURRENT_HOUR": "hour",
    "SENSING_CURRENT_MINUTE": "minute",
    "SENSING_CURRENT_SECOND": "second",
    "SENSING_DAYSSINCE2000": "days since 2000",
    "SENSING_USERNAME": "username",
    "SENSING_USERID": "user id",
    "SOUND_PLAY": "start sound %1",
    "SOUND_PLAYUNTILDONE": "play sound %1 until done",
    "SOUND_STOPALLSOUNDS": "stop all sounds",
    "SOUND_SETEFFECTO": "set %1 effect to %2",
    "SOUND_CHANGEEFFECTBY": "change %1 effect by %2",
    "SOUND_CLEAREFFECTS": "clear sound effects",
    "SOUND_EFFECTS_PITCH": "pitch",
    "SOUND_EFFECTS_PAN": "pan left/right",
    "SOUND_CHANGEVOLUMEBY": "change volume by %1",
    "SOUND_SETVOLUMETO": "set volume to %1%",
    "SOUND_VOLUME": "volume",
    "SOUND_RECORD": "record...",
    "CATEGORY_MOTION": "Motion",
    "CATEGORY_LOOKS": "Looks",
    "CATEGORY_SOUND": "Sound",
    "CATEGORY_EVENTS": "Events",
    "CATEGORY_ENTERTAINMENT": "Entertainment",
    "CATEGORY_CONTROL": "Control",
    "CATEGORY_SENSING": "Sensing",
    "CATEGORY_OPERATORS": "Operators",
    "CATEGORY_VARIABLES": "Variables",
    "CATEGORY_MYBLOCKS": "My Blocks",
    "CATEGORY_LIGHTING": "Lighting",
    "CATEGORY_SPEAKER": "Speaker",
    "CATEGORY_NETWORK": "Network",
    "DUPLICATE": "Duplicate",
    "DELETE": "Delete",
    "ADD_COMMENT": "Add Comment",
    "REMOVE_COMMENT": "Remove Comment",
    "DELETE_BLOCK": "Delete Block",
    "DELETE_X_BLOCKS": "Delete %1 Blocks",
    "DELETE_ALL_BLOCKS": "Delete all %1 blocks?",
    "CLEAN_UP": "Clean up Blocks",
    "HELP": "Help",
    "UNDO": "Undo",
    "REDO": "Redo",
    "EDIT_PROCEDURE": "Edit",
    "SHOW_PROCEDURE_DEFINITION": "Go to definition",
    "WORKSPACE_COMMENT_DEFAULT_TEXT": "Say something...",
    "COLOUR_HUE_LABEL": "Color",
    "COLOUR_SATURATION_LABEL": "Saturation",
    "COLOUR_BRIGHTNESS_LABEL": "Brightness",
    "CHANGE_VALUE_TITLE": "Change value:",
    "RENAME_VARIABLE": "Rename variable",
    "RENAME_VARIABLE_TITLE": "Rename all \"%1\" variables to:",
    "RENAME_VARIABLE_MODAL_TITLE": "Rename Variable",
    "NEW_VARIABLE": "Make a Variable",
    "NEW_VARIABLE_TITLE": "New variable name:",
    "VARIABLE_MODAL_TITLE": "New Variable",
    "VARIABLE_ALREADY_EXISTS": "A variable named \"%1\" already exists.",
    "VARIABLE_ALREADY_EXISTS_FOR_ANOTHER_TYPE": "A variable named \"%1\" already exists for another variable of type \"%2\".",
    "DELETE_VARIABLE_CONFIRMATION": "Delete %1 uses of the \"%2\" variable?",
    "CANNOT_DELETE_VARIABLE_PROCEDURE": "Can't delete the variable \"%1\" because it's part of the definition of the function \"%2\"",
    "DELETE_VARIABLE": "Delete the \"%1\" variable",
    "NEW_PROCEDURE": "Make a Block",
    "PROCEDURE_ALREADY_EXISTS": "A procedure named \"%1\" already exists.",
    "PROCEDURE_DEFAULT_NAME": "block name",
    "PROCEDURE_USED": "To delete a block definition, first remove all uses of the block",
    "NEW_LIST": "Make a List",
    "NEW_LIST_TITLE": "New list name:",
    "LIST_MODAL_TITLE": "New List",
    "LIST_ALREADY_EXISTS": "A list named \"%1\" already exists.",
    "RENAME_LIST_TITLE": "Rename all \"%1\" lists to:",
    "RENAME_LIST_MODAL_TITLE": "Rename List",
    "DEFAULT_LIST_ITEM": "thing",
    "DELETE_LIST": "Delete the \"%1\" list",
    "RENAME_LIST": "Rename list",
    "NEW_BROADCAST_MESSAGE": "New message",
    "NEW_BROADCAST_MESSAGE_TITLE": "New message name:",
    "BROADCAST_MODAL_TITLE": "New Message",
    "DEFAULT_BROADCAST_MESSAGE_NAME": "message1",
    "OLED_CATEGORY":"OLED",
    "OLED_INIT":"\%1\ \%2\ init oled",
    "OLED_DRAWLINE":"\%1\ \%2\ draw line x0: \%3\ y0: \%4\, x1: \%5\ y1：\%6\ color \%7\ ",
    "OLED_COLOR_WHITE":"white",
    "OLED_COLOR_BLACK":"black",
    "OLED_COLOR_INVERSE":"inverse",
    "OLED_DRAWRECT":"\%1\ \%2\ draw rect x: \%3\ y: \%4\ width \%5\ height \%6\ color \%7\ ",
    "OLED_FILLRECT":"\%1\ \%2\ fill rect x: \%3\ y: \%4\ width \%5\ height \%6\ color \%7\ ",
    "OLED_DRAWCIRCLE":"\%1\ \%2\ draw circle x: \%3\ y: \%4\ radius \%5\ color \%6\ ",
    "OLED_FILLCIRCLE":"\%1\ \%2\ fill circle x: \%3\ y: \%4\ radius \%5\ color \%6\ ",
    "OLED_DRAWROUNDRECT":"\%1\ \%2\ draw round rect x: \%3\ y: \%4\ width \%5\ height \%6\ radius \%7\ color \%8\ ",
    "OLED_FILLROUNDRECT":"\%1\ \%2\ fill round rect x: \%3\ y: \%4\ width \%5\ height \%6\ radius \%7\ color \%8\ ",
    "OLED_DRAWTRIANGLE":"\%1\ \%2\ draw triangle x0: \%3\ y0: \%4\, x1: \%5\ y1: \%6\, x2: \%7\ y2: \%8\ color \%9\ ",
    "OLED_FILLTRIANGLE":"\%1\ \%2\ fill triangle x0: \%3\ y0: \%4\, x1: \%5\ y1: \%6\, x2: \%7\ y2: \%8\ color \%9\ ",
    "OLED_SETTEXT":"\%1\ \%2\ set text size \%3\ color \%4\ background color \%5\ ",
    "OLED_SETCURSOR":"\%1\ \%2\ set cursor x: \%3\ y: \%4\ ",
    "OLED_PRINT":"\%1\ \%2\ print \%3\ \%4\ ",
    "OLED_EOL_WARP":"warp",
    "OLED_EOL_NOWARP":"no-warp",
    "OLED_CLEAR":"\%1\ \%2\ clear oled",
    "OLED_REFRESH":"\%1\ \%2\ refresh oled",
    "OLED_STARTSCROLL":"\%1\ \%2\ start scroll \%3\ y0: \%4\ y1: \%5\ ",
    "OLED_SCROLL_RIGHT":"right",
    "OLED_SCROLL_LEFT":"left",
    "OLED_SCROLL_DIAGRIGHT":"diag right",
    "OLED_SCROLL_DIAGLEFT":"diag left",
    "OLED_STOPSCROLL":"\%1\ \%2\ stop scroll",
    "RGBLEDSTRIP_INIT":"init RGB LED strip length \%1\ pin \%2\ ",
    "RGBLEDSTRIP_SETPIXELCOLOR":"\%1\ \%2\ set pixel \%3\ color \%4\ ",
    "RGBLED_LIGHTSUP":"\%1\ \%2\ RGB LED lights up \%3\ ",
    "RGBLEDSTRIP_FILL":"\%1\ \%2\ fill from pixel \%3\ count \%4\ with color \%5\ ",
    "RGBLEDSTRIP_COLOR":"\%1\ \%2\ RGB LED strip color R \%3\ G \%4\ B \%5\ ",
    "RGBLEDSTRIP_SETBRIGHTNESS":"\%1\ \%2\ set brightness \%3\ ",
    "RGBLEDSTRIP_CLEAR":"\%1\ \%2\ clear all",
    "RGBLEDSTRIP_SHOW":"refresh",
    "RGBLED_YAK" : '\%1\ \%2\ RGB Led Light \%3\ \%4\ \%5\ ',
    "RGBLED_SONDUR": '\%1\ \%2\ Turn off all leds',

    "RGB_LED_YAK_SURELI_PYBOARD" : '\%1\ \%2\ Led lights up \%3\ color \%4\ seconds \%5\ socket \%6\ ',
    "RGB_LED_YAK_SURESIZ_PYBOARD" : '\%1\ \%2\ Led \%3\ color \%4\ lights up socket \%5\ ',
    "RGB_LED_KAPAT_PYBOARD" : "\%1\ \%2\ Led \%3\ led turn off socket \%4\ ",
    "RGBLED_YAK_PYBOARD" : '\%1\ \%2\ RGB Led Light \%3\  \%4\  \%5\  socket \%6\ ',
    "RGBLED_SONDUR_PYBOARD" : '\%1\ \%2\ Turn off all leds socket \%3\ ',




    "SES_CAL":"\%1\ \%2\ play sound \%3\ ",
    "SES_CAL_BITENE_KADAR":"\%1\ \%2\ play sound \%3\ until",
    "TUM_SESLERI_DURDUR":"\%1\ \%2\ stop all sounds",
    "NOTA_CAL":"\%1\ \%2\ play note \%3\ for \%4\ beats",
    "SUS_VURUS":"\%1\ \%2\ rest for \%3\ vuruş",
    "FREKANSDA_CAL":"\%1\ \%2\  play sound at frequency of \%3\ HZ for \%4\ secs",
    "SES_SEVIYESINI_DEGİSTİR":"\%1\ \%2\ change volume by \%3\ ",
    "SES_SEVIYESI_YUZDE":"\%1\ \%2\ set volume \%3\ %",
    "SES_SEVIYESI":"\%1\ \%2\ volume",

    "ILERI_GIT":"\%1\ \%2\ Go forward \%3\ % power \%4\ secs",
    "GERI_GIT":"go backward \%1\ % power \%2\ secs",
    "SOLA_GIT":"\%1\ \%2\ turn left \%3\ % power \%4\ secs",
    "SAGA_GIT":"\%1\ \%2\ turn right \%3\ % power \%4\ secs",
    "GERIYE_GIT":"\%1\ \%2\ go backward \%3\ % power \%4\ secs",
    "SOLA_DON_DERECE_BITIMI":"\%1\ \%2\ turn left  \%3\ degrees until done",
    "SAGA_DON_DERECE_BITIMI":"\%1\ \%2\ turn right \%3\ degrees until done",
    "SEC_GIT":"\%1\ \%2\ \%3\ \%4\ % power",
    "SEC_GIT_RPM":"\%1\ \%2\ \%3\ \%4\ % rpm",
    "SOLA_SAGA_DERECELI_GIT_RPM":"\%1\ \%2\ left wheels turns at  rpm \%3\   right wheels turns at  rpm % \%4\ ",
    "SOLA_SAGA_DERECELI_GIT_POWER":"\%1\ \%2\ left wheels turns at power \%3\ %  right wheels turns at power \%4\ ",
    "HAREKETI_DURDUR":"\%1\ \%2\ stop moving \%3\ ",
    "RESET_ENCODER_MOTOR":"\%1\ \%2\ \%3\ reset motors",
    "MOTOR_MOVES_CM":"\%1\ \%2\ hareket \%3\ rotates at \%4\ go up cm",
    "MOTOR_ROTATES_AT_TIME":"\%1\ \%2\ encoder motor \%3\" rotates at \%4\ \%5\ rpm \%6\ secs",
    "MOTOR_ACIYA_GORE_DON":"\%1\ \%2\ encoder motor \%3\  rotates at \%4\ angle",
    "MOTOR_ROTATED_ANGLE":"\%1\ \%2\ encoder motor \%3\ at \%4\ angle",
    "MOTOR_ACI":"\%1\ \%2\ encoder motor \%3\ at \%4\ secs",
    "RPM_SANIYE_YON":"\%1\ \%2\ encoder motor \%3\ at \%4\ rpm \%5\ secs",
    "GUC_SANIYE_YON":"\%1\ \%2\ encoder motor \%3\ at \%4\ power \%5\ secs",
    "MOTOR_ROTATES_AT":"\%1\ \%2\ encoder motor \%3\ rotates at \%4\ \%5\ ",

    "DUGME_BASILDIMI":"\%1\ \%2\ buton is pressed?",
    "CIHAZ_BAGLANDIMI":"\%1\ \%2\ when device connected?",
    "POTANSIYOMETRE_DEGERI":"\%1\ \%2\ gear potentiometer value",
    "PIL_SEVIYESI":"battery level",
    "CIHAZ_SALLANIYORMU":"shaken?",
    "SALLANMA_SIDDETI":"shaking strength",
    "CIHAZ_EGIKMI":"\%1\ \%2\ device \%3\ tilted?",
    "CIHAZ_KONUMU":"\%1\ \%2\ device \%3\ positioning",
    "YATIS_ACISI":"rool angle",
    "EGIM_ACISI":"pitch angle",
    "X_ETRAFINDA_DONUS_ACISI":"\%1\ \%2\ rotation angle around x",
    "Y_ETRAFINDA_DONUS_ACISI":"\%1\ \%2\ rotation angle around y",
    "Z_ETRAFINDA_DONUS_ACISI":"\%1\ \%2\ rotation angle around z",
    "SIFIRLA_DONDURME_ACISI":"\%1\ \%2\ reset \%3\ rotation angle",
    "ZAMANLAYICI":"\%1\ \%2\ timer",
    "ZAMANLAYICI_SIFIRLA":"\%1\ \%2\ reset timer",
    "ONUNDEKI_ENGELLER":"obstacles ahead",
    "ALGILANAN_RENK":"color value detected \%1\ ?",
    "RENK_DEGER_TESPITI":"\%1\ \%2\  \%3\ color value detected",
    "RENK_ALGILAYICI_ORTAM_ISIK_YOGUNLUGU":"\%1\ \%2\ color sensor ambient light intensity",
    "RENK_ALGILAYICI_YANSIYAN_ISIK_YOGUNLUGU":"\%1\ \%2\ color sensor reflected  light intensity",
    "RENK_ALGILAYICI_YANSIYAN_KIZILOTESI_ISIK_YOGUNLUGU":"\%1\ \%2\ color sensor reflected infrared light intensity",
    "RENK_ALGILAYICI_GRI_OLCEK":"\%1\ \%2\ color sensor grey-scale light intensity",
    "WIFI_CONNECT_SSID":"\%1\ \%2\ connected to  Wİ-Fİ  \%3\ password \%4\ ",
    "WIFI_BAGLANDIMI":"\%1\ \%2\ Wİ-Fİ connected?",
    "CONNECTED_WIFI_AGAIN":"\%1\ \%2\ connected to  Wİ-Fİ again ",
    "DISCONNECTED_WIFI_AGAIN":"\%1\ \%2\ disconnect from Wİ-Fİ ",
    "BUGUNUN_TARIHINI_GETIR":"\%1\ \%2\ \%3\ get  \%4\  time ",
    "RGB_LED_YAK_SURELI": "\%1\ \%2\ Led lights up \%3\ color \%4\ seconds \%5\ ",
    "RGB_LED_YAK_SURESIZ":"\%1\ \%2\ Led \%3\ color \%4\ lights up",
    "RGB_LED_KAPAT" : "\%1\ \%2\ Led \%3\ led turn off",
    "RGB_LED_BASLAT": "\%1\ \%2\ Led init",
    "TEMPATURE_DHT": '\%1\ \%2\ Sıcaklık ve Bağıl nem sensörünü başlat block \%3\ ',
    "MESAFE_VE_ISIK": "\%1\ \%2\ Start distance and light intensity",
    "PROMIXITY_UZAKLIK_SENSORU":"\%1\ \%2\ distance value",
    "DISLI_POTANSIYOMETRI_DEGERI": "\%1\ \%2\ gear potentiometer value \%3\ ",
    "ORTAM_ISIK_SIDDETI":"ambient light intensity",
    "YESIL_ISIK_SIDDETI_UPLOAD": "\%1\ \%2\ green light intensity",
    "MAVI_ISIK_SIDDETI_UPLOAD": "\%1\ \%2\ blue light intensity",
    "KIRMIZI_ISIK_SIDDETI_UPLOAD": "\%1\ \%2\ red light intensity",
    "START_MOTION_SENSOR": "\%1\ \%2\ start motion sensor",
    "START_LINE_FOLLOWER": "\%1\ \%2\ Initialize line tracking sensor",
    "START_LINE_FONKS": "\%1\ \%2\ Read line trace functions \%3\ ",
    "READ_LINE_NEW": "\%1\ \%2\ read line \%3\ \%4\ ",
    "EGIM_ACISI_DURUMLU": "\%1\ \%2\  \%3\  tilt angle",
    "X_Y_Z_ACISI_DURUMLU": "\%1\ \%2\  \%3\ tilt angle",
    "X_Y_Z_HIZ_DURUMLU": "\%1\ \%2\  \%3\ speed m/s^2",
    "BUZER_AC": "\%1\ \%2\ Open Buzzer",
    "BUZER_KAPAT": "\%1\ \%2\ Buzzer Close",
    "FREKANSDA_SES_CAL":"\%1\ \%2\  \%3\ Play audio in Hz frequency \%4\ beats",
    "ILERI_GIT_TEXT":"Go forward",
    "GERI_GIT_TEXT":"go back",
    "SOLA_GIT_TEXT":"turn left",
    "SAGA_GIT_TEXT":"turn right",
    "LEFT_WHELL":"left wheel EM(1)",
    "RIGHT_WHELL":"right wheel EM(2)",
    "ALL_WHELL":"all",
    "RPM_TEXT":"rotationel speed(RPM)",
    "POWER_TEXT":"power(%)",
    "SAG_TEXT":"right",
    "SOL_TEXT":"left",
    "HEPSI_TEXT":"all",
    "KIRMIZI":"red",
    "CAMGOBEGI":"cyan",
    "MAVI":"blue",
    "YESIL":"green",
    "SARI":"yellow",
    "BEYAZ":"white",
    "SIYAH":"black",
    "GALIBARDA":"galibarda",
    "ONE_EGIK":"leaning forward",
    "GERIYE_EGIK":"tilted backwards",
    "SOLA_EGIK":"tilted left",
    "SAGA_EGIK":"tilted right",
    "YUZU_YUKARI_DONUK":"face up",
    "YUZU_ASAGI_DONUK":"face down",
    "ILERI_EGIK_TEXT":"leaning forward",
    "GERI_EGIK_TEXT":"curved back",
    "SOLA_EGIK_TEXT":"tilted left",
    "SAGA_EGIK_TEXT":"tilted right",
    "SENSOR1":"sensor-1",
    "SENSOR2":"sensor-2",
    "SENSOR3":"sensor-3",
    "SENSOR4":"sensor-4",
    "SENSOR5":"sensor-5",
    "ORTAM_ISIK_SIDDETI_PYBOARD":"\%1\ \%2\  ambient light intensity socket \%3\ ",
    "YESIL_ISIK_SIDDETI_UPLOAD_PYBOARD": "\%1\ \%2\ green light intensity socket \%3\ ",
    "MAVI_ISIK_SIDDETI_UPLOAD_PYBOARD": "\%1\ \%2\ blue light intensity socket \%3\ ",
    "KIRMIZI_ISIK_SIDDETI_UPLOAD_PYBOARD": "\%1\ \%2\ red light intensity socket \%3\ ",

    "ULTRASONIKINITPYBOARD" : "\%1\ \%2\ ultrasonic sensor initialize \%3\ ",
    "DISTANCEVALUEULTRASONIK" : "\%1\ \%2\ ultrasonic measured distance",
    "GETTIMEPYBOARD" : "\%1\ \%2\ \%2\ get  \%4\ time",

    
    "TEMPATURE_READ_PYBOT" : "\%1\ \%2\ temperature read \%3\ ",
    "RELATIVE_HUMIDITY_READ_PYBOT" : "\%1\ \%2\ read relative humidity \%3\ ",
    "WATERPROOF_TEMPATURE_INIT_PYBOARD" : "\%1\ \%2\ Initialize waterproof temperature sensor  \%3\ ",
    "WATERPROOF_TEMPATURE_READ_PYBOARD" : "\%1\ \%2\ Read temperature from waterproof sensor \%3\ ",
    "SOUNDINTENSITY" : "\%1\ \%2\ measure sound intensity \%3\ ",

    "BKY_CATEGORY_LIGHTS" : "Işık",
    "CLOCKWISE": "clockwise",
    "COUNTERCLOCKWISE": "counter clockwise",
};



Blockly.ScratchMsgs.locales["zh-cn"] =
{
    "CONTROL_FOREVER": "重复执行",
    "CONTROL_REPEAT": "重复执行 %1 次",
    "CONTROL_IF": "如果 %1 那么",
    "CONTROL_ELSE": "否则",
    "CONTROL_STOP": "停止",
    "CONTROL_STOP_ALL": "全部脚本",
    "CONTROL_STOP_THIS": "这个脚本",
    "CONTROL_STOP_OTHER": "该角色的其他脚本",
    "CONTROL_WAIT": "等待 %1 秒",
    "CONTROL_WAITUNTIL": "等待 %1",
    "CONTROL_REPEATUNTIL": "重复执行直到 %1",
    "CONTROL_WHILE": "当 %1 重复执行",
    "CONTROL_FOREACH": "对于 %2 中的每个 %1",
    "CONTROL_STARTASCLONE": "当作为克隆体启动时",
    "CONTROL_CREATECLONEOF": "克隆 %1",
    "CONTROL_CREATECLONEOF_MYSELF": "自己",
    "CONTROL_DELETETHISCLONE": "删除此克隆体",
    "CONTROL_COUNTER": "计数器",
    "CONTROL_INCRCOUNTER": "计数器加一",
    "CONTROL_CLEARCOUNTER": "计数器归零",
    "CONTROL_ALLATONCE": "所有脚本",
    "DATA_SETVARIABLETO": "将 %1 设为 %2",
    "DATA_CHANGEVARIABLEBY": "将 %1 增加 %2",
    "DATA_SHOWVARIABLE": "显示变量 %1",
    "DATA_HIDEVARIABLE": "隐藏变量 %1",
    "DATA_ADDTOLIST": "将 %1 加入 %2",
    "DATA_DELETEOFLIST": "删除 %2 的第 %1 项",
    "DATA_DELETEALLOFLIST": "删除 %1 的全部项目",
    "DATA_INSERTATLIST": "在 %3 的第 %2 项前插入 %1",
    "DATA_REPLACEITEMOFLIST": "将 %2 的第 %1 项替换为 %3",
    "DATA_ITEMOFLIST": "%2 的第 %1 项",
    "DATA_ITEMNUMOFLIST": "%2 中第一个 %1 的编号",
    "DATA_LENGTHOFLIST": "%1 的项目数",
    "DATA_LISTCONTAINSITEM": "%1 包含 %2 ?",
    "DATA_SHOWLIST": "显示列表 %1",
    "DATA_HIDELIST": "隐藏列表 %1",
    "DATA_INDEX_ALL": "全部",
    "DATA_INDEX_LAST": "末尾",
    "DATA_INDEX_RANDOM": "随机",
    "EVENT_WHENARDUINOBEGIN": "当Arduino启动",
    "EVENT_WHENMICROBITBEGIN": "当Microbit启动",
    "EVENT_WHENMICROBITBUTTONPRESSED": "当按键 %1 被按下",
    "EVENT_WHENMICROBITPINBEINGTOUCHED": "当引脚 %1 被触摸",
    "EVENT_WHENMICROBITGESTURE": "当姿态 %1",
    "EVENT_WHENFLAGCLICKED": "当 %1 被点击",
    "EVENT_WHENTHISSPRITECLICKED": "当角色被点击",
    "EVENT_WHENSTAGECLICKED": "当舞台被点击",
    "EVENT_WHENTOUCHINGOBJECT": "当该角色碰到 %1",
    "EVENT_WHENBROADCASTRECEIVED": "当接收到 %1",
    "EVENT_WHENBACKDROPSWITCHESTO": "当背景换成 %1",
    "EVENT_BACKDROP1": "dekor 1",
    "EVENT_WHENGREATERTHAN": "当 %1 > %2",
    "EVENT_WHENGREATERTHAN_TIMER": "计时器",
    "EVENT_WHENGREATERTHAN_LOUDNESS": "响度",
    "EVENT_BROADCAST": "广播 %1",
    "EVENT_BROADCASTANDWAIT": "广播 %1 并等待",
    "EVENT_WHENKEYPRESSED": "当按下 %1 键",
    "EVENT_WHENMICROBITGESTURE_SHAKEN": "摇晃",
    "EVENT_WHENMICROBITGESTURE_TILTEDUPWARD": "向上倾斜",
    "EVENT_WHENMICROBITGESTURE_TILTEDDOWNWARD": "向下倾斜",
    "EVENT_WHENMICROBITGESTURE_TILTEDDLEFTWARD": "向左倾斜",
    "EVENT_WHENMICROBITGESTURE_TILTEDDRIGHTWARD": "向右倾斜",
    "EVENT_WHENMICROBITGESTURE_FACEUP": "正面朝上",
    "EVENT_WHENMICROBITGESTURE_FACEDOWN": "正面朝下",
    "EVENT_WHENMICROBITGESTURE_FREEFALL": "自由落体",
    "EVENT_WHENKEYPRESSED_SPACE": "空格",
    "EVENT_WHENKEYPRESSED_LEFT": "←",
    "EVENT_WHENKEYPRESSED_RIGHT": "→",
    "EVENT_WHENKEYPRESSED_DOWN": "↓",
    "EVENT_WHENKEYPRESSED_UP": "↑",
    "EVENT_WHENKEYPRESSED_ANY": "任意",
    "LOOKS_SAYFORSECS": "说 %1 %2 秒",
    "LOOKS_SAY": "说 %1",
    "LOOKS_HELLO": "你好！",
    "LOOKS_THINKFORSECS": "思考 %1 %2 秒",
    "LOOKS_THINK": "思考 %1",
    "LOOKS_HMM": "嗯……",
    "LOOKS_SHOW": "显示",
    "LOOKS_HIDE": "隐藏",
    "LOOKS_HIDEALLSPRITES": "隐藏所有角色",
    "LOOKS_EFFECT_COLOR": "颜色",
    "LOOKS_EFFECT_FISHEYE": "鱼眼",
    "LOOKS_EFFECT_WHIRL": "漩涡",
    "LOOKS_EFFECT_PIXELATE": "像素化",
    "LOOKS_EFFECT_MOSAIC": "马赛克",
    "LOOKS_EFFECT_BRIGHTNESS": "亮度",
    "LOOKS_EFFECT_GHOST": "虚像",
    "LOOKS_CHANGEEFFECTBY": "将 %1 特效增加 %2",
    "LOOKS_SETEFFECTTO": "将 %1 特效设定为 %2",
    "LOOKS_CLEARGRAPHICEFFECTS": "清除图形特效",
    "LOOKS_CHANGESIZEBY": "将大小增加 %1",
    "LOOKS_SETSIZETO": "将大小设为 %1",
    "LOOKS_SIZE": "大小",
    "LOOKS_CHANGESTRETCHBY": "伸缩%1",
    "LOOKS_SETSTRETCHTO": "设置伸缩为%1 %",
    "LOOKS_SWITCHCOSTUMETO": "换成 %1 造型",
    "LOOKS_NEXTCOSTUME": "下一个造型",
    "LOOKS_SWITCHBACKDROPTO": "换成 %1 背景",
    "LOOKS_GOTOFRONTBACK": "移到最 %1 ",
    "LOOKS_GOTOFRONTBACK_FRONT": "前面",
    "LOOKS_GOTOFRONTBACK_BACK": "后面",
    "LOOKS_GOFORWARDBACKWARDLAYERS": "%1 %2 层",
    "LOOKS_GOFORWARDBACKWARDLAYERS_FORWARD": "前移",
    "LOOKS_GOFORWARDBACKWARDLAYERS_BACKWARD": "后移",
    "LOOKS_BACKDROPNUMBERNAME": "背景 %1",
    "LOOKS_COSTUMENUMBERNAME": "造型 %1",
    "LOOKS_NUMBERNAME_NUMBER": "编号",
    "LOOKS_NUMBERNAME_NAME": "名称",
    "LOOKS_SWITCHBACKDROPTOANDWAIT": "换成 %1 背景并等待",
    "LOOKS_NEXTBACKDROP_BLOCK": "下一个背景",
    "LOOKS_NEXTBACKDROP": "下一个背景",
    "LOOKS_PREVIOUSBACKDROP": "上一个背景",
    "LOOKS_RANDOMBACKDROP": "随机背景",
    "MOTION_MOVESTEPS": "移动 %1 步",
    "MOTION_TURNLEFT": "左转 %1 %2 度",
    "MOTION_TURNRIGHT": "右转 %1 %2 度",
    "MOTION_POINTINDIRECTION": "面向 %1 方向",
    "MOTION_POINTTOWARDS": "面向 %1",
    "MOTION_POINTTOWARDS_POINTER": "鼠标指针",
    "MOTION_POINTTOWARDS_RANDOM": "随机方向",
    "MOTION_GOTO": "移到 %1",
    "MOTION_GOTO_POINTER": "鼠标指针",
    "MOTION_GOTO_RANDOM": "随机位置",
    "MOTION_GOTOXY": "移到 x: %1 y: %2",
    "MOTION_GLIDESECSTOXY": "在 %1 秒内滑行到 x: %2 y: %3",
    "MOTION_GLIDETO": "在 %1 秒内滑行到 %2",
    "MOTION_GLIDETO_POINTER": "鼠标指针",
    "MOTION_GLIDETO_RANDOM": "随机位置",
    "MOTION_CHANGEXBY": "将x坐标增加 %1",
    "MOTION_SETX": "将x坐标设为 %1",
    "MOTION_CHANGEYBY": "将y坐标增加 %1",
    "MOTION_SETY": "将y坐标设为 %1",
    "MOTION_IFONEDGEBOUNCE": "碰到边缘就反弹",
    "MOTION_SETROTATIONSTYLE": "将旋转方式设为 %1",
    "MOTION_SETROTATIONSTYLE_LEFTRIGHT": "左右翻转",
    "MOTION_SETROTATIONSTYLE_DONTROTATE": "不可旋转",
    "MOTION_SETROTATIONSTYLE_ALLAROUND": "任意旋转",
    "MOTION_XPOSITION": "x 坐标",
    "MOTION_YPOSITION": "y 坐标",
    "MOTION_DIRECTION": "方向",
    "MOTION_SCROLLRIGHT": "向右滚动 %1",
    "MOTION_SCROLLUP": "向上滚动 %1",
    "MOTION_ALIGNSCENE": "和场景 %1 对齐",
    "MOTION_ALIGNSCENE_BOTTOMLEFT": "左下角",
    "MOTION_ALIGNSCENE_BOTTOMRIGHT": "右下角",
    "MOTION_ALIGNSCENE_MIDDLE": "中间",
    "MOTION_ALIGNSCENE_TOPLEFT": "左上角",
    "MOTION_ALIGNSCENE_TOPRIGHT": "右上角",
    "MOTION_XSCROLL": "x滚动位置",
    "MOTION_YSCROLL": "y滚动位置",
    "MOTION_STAGE_SELECTED": "选中了舞台：不可使用运动类积木",
    "OPERATORS_ADD": "%1 + %2",
    "OPERATORS_SUBTRACT": "%1 - %2",
    "OPERATORS_MULTIPLY": "%1 * %2",
    "OPERATORS_DIVIDE": "%1 / %2",
    "OPERATORS_RANDOM": "在 %1 和 %2 之间取随机数",
    "OPERATORS_GT": "%1 > %2",
    "OPERATORS_LT": "%1 < %2",
    "OPERATORS_EQUALS": "%1 = %2",
    "OPERATORS_AND": "%1 与 %2",
    "OPERATORS_OR": "%1 或 %2",
    "OPERATORS_NOT": "%1 不成立",
    "OPERATORS_JOIN": "连接 %1 和 %2",
    "OPERATORS_JOIN_APPLE": "苹果",
    "OPERATORS_JOIN_BANANA": "香蕉",
    "OPERATORS_LETTEROF": "%2 的第 %1 个字符",
    "OPERATORS_LETTEROF_APPLE": "果",
    "OPERATORS_LENGTH": "%1 的字符数",
    "OPERATORS_CONTAINS": "%1 包含 %2 ?",
    "OPERATORS_MOD": "%1 除以 %2 的余数",
    "OPERATORS_ROUND": "四舍五入 %1",
    "OPERATORS_MATHOP": "%1 %2",
    "OPERATORS_MATHOP_ABS": "绝对值",
    "OPERATORS_MATHOP_FLOOR": "向下取整",
    "OPERATORS_MATHOP_CEILING": "向上取整",
    "OPERATORS_MATHOP_SQRT": "平方根",
    "OPERATORS_MATHOP_SIN": "sin",
    "OPERATORS_MATHOP_COS": "cos",
    "OPERATORS_MATHOP_TAN": "tan",
    "OPERATORS_MATHOP_ASIN": "asin",
    "OPERATORS_MATHOP_ACOS": "acos",
    "OPERATORS_MATHOP_ATAN": "atan",
    "OPERATORS_MATHOP_LN": "ln",
    "OPERATORS_MATHOP_LOG": "log",
    "OPERATORS_MATHOP_EEXP": "e ^",
    "OPERATORS_MATHOP_10EXP": "10 ^",
    "PROCEDURES_DEFINITION": "定义 %1",
    "SENSING_TOUCHINGOBJECT": "碰到 %1 ?",
    "SENSING_TOUCHINGOBJECT_POINTER": "鼠标指针",
    "SENSING_TOUCHINGOBJECT_EDGE": "舞台边缘",
    "SENSING_TOUCHINGCOLOR": "碰到颜色 %1 ?",
    "SENSING_COLORISTOUCHINGCOLOR": "颜色 %1 碰到 %2 ?",
    "SENSING_DISTANCETO": "到 %1 的距离",
    "SENSING_DISTANCETO_POINTER": "鼠标指针",
    "SENSING_ASKANDWAIT": "询问 %1 并等待",
    "SENSING_ASK_TEXT": "你叫什么名字？",
    "SENSING_ANSWER": "回答",
    "SENSING_KEYPRESSED": "按下 %1 键?",
    "SENSING_MOUSEDOWN": "按下鼠标?",
    "SENSING_MOUSEX": "鼠标的x坐标",
    "SENSING_MOUSEY": "鼠标的y坐标",
    "SENSING_SETDRAGMODE": "将拖动模式设为 %1",
    "SENSING_SETDRAGMODE_DRAGGABLE": "可拖动",
    "SENSING_SETDRAGMODE_NOTDRAGGABLE": "不可拖动",
    "SENSING_LOUDNESS": "响度",
    "SENSING_LOUD": "响声？",
    "SENSING_TIMER": "计时器",
    "SENSING_RESETTIMER": "计时器归零",
    "SENSING_OF": "%2 的 %1",
    "SENSING_OF_XPOSITION": "x 坐标",
    "SENSING_OF_YPOSITION": "y 坐标",
    "SENSING_OF_DIRECTION": "方向",
    "SENSING_OF_COSTUMENUMBER": "造型编号",
    "SENSING_OF_COSTUMENAME": "造型名称",
    "SENSING_OF_SIZE": "大小",
    "SENSING_OF_VOLUME": "音量",
    "SENSING_OF_BACKDROPNUMBER": "背景编号",
    "SENSING_OF_BACKDROPNAME": "背景名称",
    "SENSING_OF_STAGE": "舞台",
    "SENSING_CURRENT": "当前时间的 %1",
    "SENSING_CURRENT_YEAR": "年",
    "SENSING_CURRENT_MONTH": "月",
    "SENSING_CURRENT_DATE": "日",
    "SENSING_CURRENT_DAYOFWEEK": "星期",
    "SENSING_CURRENT_HOUR": "时",
    "SENSING_CURRENT_MINUTE": "分",
    "SENSING_CURRENT_SECOND": "秒",
    "SENSING_DAYSSINCE2000": "2000年至今的天数",
    "SENSING_USERNAME": "用户名",
    "SENSING_USERID": "用户id",
    "SOUND_PLAY": "播放声音 %1",
    "SOUND_PLAYUNTILDONE": "播放声音 %1 等待播完",
    "SOUND_STOPALLSOUNDS": "停止所有声音",
    "SOUND_SETEFFECTO": "将 %1 音效设为 %2",
    "SOUND_CHANGEEFFECTBY": "将 %1 音效增加 %2",
    "SOUND_CLEAREFFECTS": "清除音效",
    "SOUND_EFFECTS_PITCH": "音调",
    "SOUND_EFFECTS_PAN": "左右平衡",
    "SOUND_CHANGEVOLUMEBY": "将音量增加 %1",
    "SOUND_SETVOLUMETO": "将音量设为 %1%",
    "SOUND_VOLUME": "音量",
    "SOUND_RECORD": "录制…",
    "CATEGORY_MOTION": "运动",
    "CATEGORY_LOOKS": "外观",
    "CATEGORY_SOUND": "声音",
    "CATEGORY_EVENTS": "事件",
    "CATEGORY_CONTROL": "控制",
    "CATEGORY_SENSING": "侦测",
    "CATEGORY_OPERATORS": "运算",
    "CATEGORY_VARIABLES": "变量",
    "CATEGORY_MYBLOCKS": "自制积木",
    "DUPLICATE": "复制",
    "DELETE": "删除",
    "ADD_COMMENT": "添加注释",
    "REMOVE_COMMENT": "删除注释",
    "DELETE_BLOCK": "删除",
    "DELETE_X_BLOCKS": "删除 %1 积木",
    "DELETE_ALL_BLOCKS": "删除全部 %1 积木？",
    "CLEAN_UP": "整理积木",
    "HELP": "帮助",
    "UNDO": "撤销",
    "REDO": "重做",
    "EDIT_PROCEDURE": "编辑",
    "SHOW_PROCEDURE_DEFINITION": "查看定义",
    "WORKSPACE_COMMENT_DEFAULT_TEXT": "说些什么……",
    "COLOUR_HUE_LABEL": "颜色",
    "COLOUR_SATURATION_LABEL": "饱和度",
    "COLOUR_BRIGHTNESS_LABEL": "亮度",
    "CHANGE_VALUE_TITLE": "更改变量：",
    "RENAME_VARIABLE": "修改变量名",
    "RENAME_VARIABLE_TITLE": "将所有的「%1」变量名改为：",
    "RENAME_VARIABLE_MODAL_TITLE": "修改变量名",
    "NEW_VARIABLE": "建立一个变量",
    "NEW_VARIABLE_TITLE": "新变量名：",
    "VARIABLE_MODAL_TITLE": "新建变量",
    "VARIABLE_ALREADY_EXISTS": "已经存在名为「%1」的变量。",
    "VARIABLE_ALREADY_EXISTS_FOR_ANOTHER_TYPE": "已经存在一个名为「%1」的变量，其类型为「%2」。",
    "DELETE_VARIABLE_CONFIRMATION": "删除%1处「%2」变量吗？",
    "CANNOT_DELETE_VARIABLE_PROCEDURE": "无法删除变量「%1」，因为函数「%2」的定义中用到了它",
    "DELETE_VARIABLE": "删除变量「%1」",
    "NEW_PROCEDURE": "制作新的积木",
    "PROCEDURE_ALREADY_EXISTS": "已经存在名为「%1」的程序。",
    "PROCEDURE_DEFAULT_NAME": "积木名称",
    "PROCEDURE_USED": "To delete a block definition, first remove all uses of the block",
    "NEW_LIST": "建立一个列表",
    "NEW_LIST_TITLE": "新的列表名：",
    "LIST_MODAL_TITLE": "新建列表",
    "LIST_ALREADY_EXISTS": "名为 「%1」 的列表已存在。",
    "RENAME_LIST_TITLE": "将所有的「%1」列表改名为：",
    "RENAME_LIST_MODAL_TITLE": "修改列表名",
    "DEFAULT_LIST_ITEM": "东西",
    "DELETE_LIST": "删除「%1」列表",
    "RENAME_LIST": "修改列表名",
    "NEW_BROADCAST_MESSAGE": "新消息",
    "NEW_BROADCAST_MESSAGE_TITLE": "新消息的名称：",
    "BROADCAST_MODAL_TITLE": "新消息",
    "DEFAULT_BROADCAST_MESSAGE_NAME": "消息1"
};
// End of combined translations


/*** EXPORTS FROM exports-loader ***/
module.exports = Blockly;
