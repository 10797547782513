const Cast = require('../util/cast');
const MathUtil = require('../util/math-util');
const Timer = require('../util/timer');
const Oyun = require('../util/gameArea');
const ConnectedDeviceClass = require('../util/connectedDevice');
const DeviceSensorData = require('../util/deviceSensorData');


/*
Bu sayfada  GameEntertainmentsBlocks oluşturdum. 
Burada yapmak istediğim bloklar ile oyun simülasyonunu hareket ettirmek.
Burada hareket fonksiyonlarını yazdım.

*/

class GameEntertainmentsBlocks {
    constructor (runtime) {
        /**
         * The runtime instantiating this block package.
         * @type {Runtime}
         */
        this.runtime = runtime;
    }

    /**
     * Retrieve the block primitives implemented by this package.
     * @return {object.<string, Function>} Mapping of opcode to Function.
     */
    getPrimitives () {
        return {
            feeling:  this.feeling,
            dance:this.dance,
            songshow:this.songshow,
            lightshow:this.lightshow
        };
    }

    async feeling (args,util) {
        console.log(args);
        var data = args.VALUE;
        var device = ConnectedDeviceClass.getDevice();  
        if(data == "bilgin"){
            await device.write("<< 17 d show_emotions bilgin >>\n");
        }   
        if(data == "havali"){
            await device.write("<< 17 d show_emotions havali >>\n");
        }   
        if(data == "merakli"){
            await device.write("<< 17 d show_emotions merakli >>\n");
        }   
        if(data == "mutlu"){
            await device.write("<< 17 d show_emotions mutlu >>\n");
        }   
        if(data == "sevgi"){
            await device.write("<< 17 d show_emotions sevgi >>\n");
        }   
        if(data == "uykulu"){
            await device.write("<< 17 d show_emotions uykulu >>\n");
        }   
        if(data == "kizgin"){
            await device.write("<< 17 d show_emotions kizgin >>\n");
        }   
        if(data == "korku"){
            await device.write("<< 17 d show_emotions korku >>\n");
        } 
        if(data == "neseli"){
            await device.write("<< 17 d show_emotions neseli >>\n");
        } if(data == "saskin"){
            await device.write("<< 17 d show_emotions saskin >>\n");
        } if(data == "utangac"){
            await device.write("<< 17 d show_emotions utangac >>\n");
        } 
        if(data == "üzgün"){
            await device.write("<< 17 d show_emotions üzgün >>\n");
        }   
       
     }

     async dance (args,util) {
        console.log(args);
        var data = args.VALUE;
        var device = ConnectedDeviceClass.getDevice();  
        if(data == "tango"){
            await device.write("<< 15 d tango >>\n");
        }   
        if(data == "samba"){
            await device.write("<< 15 d samba >>\n");
        }  
        if(data == "hip_hop"){
            await device.write("<< 15 d hip_hop >>\n");
        }   
        if(data == "slow"){
            await device.write("<< 15 d slow >>\n");
        }   
       
       
     }

     async songshow (args,util) {
        console.log(args);
        var data = args.VALUE;
        var device = ConnectedDeviceClass.getDevice();  
        if(args.ADDR=="hiphop")  {
            await device.write("<< 13 d play_wav hiphop >>\n");
        }
        if(args.ADDR=="at")  {
            await device.write("<< 13 d play_wav at >>\n");
        }
        if(args.ADDR=="ayak_sesi")  {
            await device.write("<< 13 d play_wav ayak_sesi >>\n");
        }
        if(args.ADDR=="bozuk_para")  {
            await device.write("<< 13 d play_wav bozuk_para >>\n");
        }
        if(args.ADDR=="hav")  {
            await device.write("<< 13 d play_wav hav >>\n");
        }
        if(args.ADDR=="oyuncak")  {
            await device.write("<< 13 d play_wav oyuncak >>\n");
        }
        if(args.ADDR=="puan")  {
            await device.write("<< 13 d play_wav puan >>\n");
        }
        if(args.ADDR=="miyav")  {
            await device.write("<< 13 d play_wav miyav >>\n");
        }
        if(args.ADDR=="siren")  {
            await device.write("<< 13 d play_wav siren >>\n");
        }
        
     }

     async lightshow (args,util) {
        console.log(args);
        var data = args.VALUE;
        var device = ConnectedDeviceClass.getDevice();  
        if(data == "rainbow"){
            await device.write("<< 16 d rainbow >>\n");
        }   
        if(data == "northern_lights"){
            await device.write("<< 16 d northern_lights >>\n");
        }  
        if(data == "comet"){
            await device.write("<< 16 d comet >>\n");
        }   
        if(data == "meteor_shower"){
            await device.write("<< 16 d meteor_shower >>\n");
        }   
        if(data == "police_car"){
            await device.write("<< 16 d police_car >>\n");
        }   
        if(data == "traffic_light"){
            await device.write("<< 16 d traffic_light >>\n");
        }  
        if(data == "show_light"){
            await device.write("<< 16 d show_light >>\n");
        }   
        if(data == "camp_fire"){
            await device.write("<< 16 d camp_fire 3 >>\n");
        }   
        
       
       
     }
  
   
}

module.exports = GameEntertainmentsBlocks;
