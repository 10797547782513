const Cast = require('../util/cast');
const MathUtil = require('../util/math-util');
const Timer = require('../util/timer');
const Oyun = require('../util/gameArea');
const ConnectedDeviceClass = require('../util/connectedDevice');
const DeviceSensorData = require('../util/deviceSensorData');
const ReadDeviceData = require('../util/deviceDataRead');


/*
Bu sayfada  GameLedBlocks oluşturdum. 
Burada yapmak istediğim bloklar ile oyun simülasyonunu hareket ettirmek.
Burada hareket fonksiyonlarını yazdım.

*/

class GameMusicBlocks {
    constructor (runtime) {
        /**
         * The runtime instantiating this block package.
         * @type {Runtime}
         */
        this.runtime = runtime;
    }

    /**
     * Retrieve the block primitives implemented by this package.
     * @return {object.<string, Function>} Mapping of opcode to Function.
     */
    getPrimitives () {
        return {
            sound_melodi_list:this.sound_melodi_list,
            sound_off:this.sound_off,
            custom_sound:this.custom_sound,
            play_sound:this.play_sound,
            zbot_ses_cal:this.zbot_ses_cal,
            ses_cal_bitene_kadar:this.ses_cal_bitene_kadar,
            zbot_ses_seviyesi:this.zbot_ses_seviyesi,
            ses_seviyesi:this.ses_seviyesi,
            zbot_ses_seviyesi_degistir:this.zbot_ses_seviyesi_degistir,
            nota_cal:this.nota_cal,
            tum_sesleri_durdur:this.tum_sesleri_durdur
        };
    }

    async sound_melodi_list(args,util){
        console.log(args);
        var device = ConnectedDeviceClass.getDevice();   
        if(args.data=="")  {
            await device.write("<< 14 d play_note_for_time 440 250 >>\n");
        }
        if(args.data=="do")  {
            await device.write("<< 14 d play_note_for_time 440 450 >>\n");
        }
        if(args.data=="re")  {
            await device.write("<< 14 d play_note_for_time 440 650 >>\n");
        }
        if(args.data=="mi")  {
            await device.write("<< 14 d play_note_for_time 440 1500 >>\n");
        }
        if(args.data=="fa")  {
            await device.write("<< 14 d play_note_for_time 440 2000 >>\n");
        }
        if(args.data=="so")  {
            await device.write("<< 14 d play_note_for_time 440 2500 >>\n");
        }
        if(args.data=="la")  {
            await device.write("<< 14 d play_note_for_time 440 3000 >>\n");
        }
        if(args.data=="si")  {
            await device.write("<< 14 d play_note_for_time 440 4090 >>\n");
        }
      
    }

    async play_sound(args,util){
        console.log(args);
        var device = ConnectedDeviceClass.getDevice();   
        if(args.SOUND=="")  {
            await device.write(`<< 13 d level 35 >>\n`);
        }
        if(args.SOUND=="vol1")  {
            await device.write(`<< 13 d level 35 >>\n`);
        }
        if(args.SOUND=="vol2")  {
            await device.write(`<< 13 d level 65 >>\n`);
        }
        if(args.SOUND=="vol3")  {
            await device.write(`<< 13 d level 100 >>\n`);
        }
    }

    async sound_off(args,util){
        console.log(args);
        var device = ConnectedDeviceClass.getDevice();     
        await device.write("<< 14 d stop_note >>\n");
    }

    async custom_sound(args,util){
        console.log(args.value);
        var data = args.value;
        var device = ConnectedDeviceClass.getDevice();   
        if(data=="hiphop")  {
            await device.write("<< 13 d play_wav hiphop >>\n");
        }
        if(data=="at")  {
            await device.write("<< 13 d play_wav at >>\n");
        }
        if(data=="ayak_sesi")  {
            await device.write("<< 13 d play_wav ayak_sesi >>\n");
        }
        if(data=="bozuk_para")  {
            await device.write("<< 13 d play_wav bozuk_para >>\n");
        }
        if(data=="hav")  {
            await device.write("<< 13 d play_wav hav >>\n");
        }
        if(data=="oyuncak")  {
            await device.write("<< 13 d play_wav oyuncak >>\n");
        }
        if(data=="puan")  {
            await device.write("<< 13 d play_wav puan >>\n");
        }
        if(data=="miyav")  {
            await device.write("<< 13 d play_wav miyav >>\n");
        }
        if(data=="siren")  {
            await device.write("<< 13 d play_wav siren >>\n");
        }
    }

    async zbot_ses_cal(args,util){
        console.log(args);
        var device = ConnectedDeviceClass.getdev();   
        if(args.ADDR=="hiphop")  {
            await device.write("<< 13 d play_wav_not_until hiphop >>\n");
        }
        if(args.ADDR=="at")  {
            await device.write("<< 13 d play_wav_not_until at >>\n");
        }
        if(args.ADDR=="ayak_sesi")  {
            await device.write("<< 13 d play_wav_not_until ayak_sesi >>\n");
        }
        if(args.ADDR=="bozuk_para")  {
            await device.write("<< 13 d play_wav_not_until bozuk_para >>\n");
        }
        if(args.ADDR=="hav")  {
            await device.write("<< 13 d play_wav_not_until hav >>\n");
        }
        if(args.ADDR=="oyuncak")  {
            await device.write("<< 13 d play_wav_not_until oyuncak >>\n");
        }
        if(args.ADDR=="puan")  {
            await device.write("<< 13 d play_wav_not_until puan >>\n");
        }
        if(args.ADDR=="miyav")  {
            await device.write("<< 13 d play_wav_not_until miyav >>\n");
        }
        if(args.ADDR=="siren")  {
            await device.write("<< 13 d play_wav_not_until siren >>\n");
        }
        if(args.ADDR=="tango")  {
            await device.write("<< 15 d tango >>\n");        
        }
        
    }

    async ses_cal_bitene_kadar(args,util){
        console.log(args);
        var device = ConnectedDeviceClass.getDevice();   
        if(args.ADDR=="hiphop")  {
            await device.write("<< 13 d play_wav hiphop >>\n");
        }
        if(args.ADDR=="at")  {
            await device.write("<< 13 d play_wav at >>\n");
        }
        if(args.ADDR=="ayak_sesi")  {
            await device.write("<< 13 d play_wav ayak_sesi >>\n");
        }
        if(args.ADDR=="bozuk_para")  {
            await device.write("<< 13 d play_wav bozuk_para >>\n");
        }
        if(args.ADDR=="hav")  {
            await device.write("<< 13 d play_wav hav >>\n");
        }
        if(args.ADDR=="oyuncak")  {
            await device.write("<< 13 d play_wav oyuncak >>\n");
        }
        if(args.ADDR=="puan")  {
            await device.write("<< 13 d play_wav puan >>\n");
        }
        if(args.ADDR=="miyav")  {
            await device.write("<< 13 d play_wav miyav >>\n");
        }
        if(args.ADDR=="siren")  {
            await device.write("<< 13 d play_wav siren >>\n");
        }
    }

    async zbot_ses_seviyesi(args,util){
        console.log(args);
        var device = ConnectedDeviceClass.getDevice();     
        await device.write(`<< 13 d level ${args.X} >>\n`);
    }

    async ses_seviyesi(args,util){
       // console.log(args);
        var device = ConnectedDeviceClass.getDevice();     
        return ReadDeviceData.getSoundLevel();
    }

    async zbot_ses_seviyesi_degistir(args,util){
        console.log(args);
        var device = ConnectedDeviceClass.getDevice();     
        await device.write(`<< 13 d change_level ${args.X} >>\n`);
    }

    async nota_cal(args,util){
        console.log(args);
        var note= args.PIN;
        var vurus = args.X;
        var device = ConnectedDeviceClass.getDevice();     
   
      

 
        if(note == 'B0'){
            await device.write("<< 14 d play_note_for_time 440 31 >>\n");
        }
        if(note == 'C1'){
            await device.write("<< 14 d play_note_for_time 440 33 >>\n");
        }
        if(note == 'D1'){
            await device.write("<< 14 d play_note_for_time 440 37 >>\n");
        }
        if(note == 'E1'){
            await device.write("<< 14 d play_note_for_time 440 41 >>\n");
        }
        if(note == 'F1'){
            await device.write("<< 14 d play_note_for_time 440 44 >>\n");
        }
        if(note == 'G1'){
            await device.write("<< 14 d play_note_for_time 440 49 >>\n");
        }
        if(note == 'A1'){
            await device.write("<< 14 d play_note_for_time 440 55 >>\n");
        }
        if(note == 'B1'){
            await device.write("<< 14 d play_note_for_time 440 62 >>\n");
        }
        if(note == 'C2'){
            await device.write("<< 14 d play_note_for_time 440 65 >>\n");
        }
        if(note == 'D2'){
            await device.write("<< 14 d play_note_for_time 440 73 >>\n");
        }
        if(note == 'E2'){
            await device.write("<< 14 d play_note_for_time 440 82 >>\n");
        }
        if(note == 'F2'){
            await device.write("<< 14 d play_note_for_time 440 87 >>\n");
        }
        if(note == 'G2'){
            await device.write("<< 14 d play_note_for_time 440 98 >>\n");
        }
        if(note == 'A2'){
            await device.write("<< 14 d play_note_for_time 440 110 >>\n");
        }
        if(note == 'B2'){
            await device.write("<< 14 d play_note_for_time 440 123 >>\n");
        }
        if(note == 'C3'){
            await device.write("<< 14 d play_note_for_time 440 131 >>\n");
        }
        if(note == 'D3'){
            await device.write("<< 14 d play_note_for_time 440 147 >>\n");
        } if(note == 'E3'){
            await device.write("<< 14 d play_note_for_time 440 165 >>\n");
        } if(note == 'F3'){
            await device.write("<< 14 d play_note_for_time 440 175 >>\n");
        } if(note == 'G3'){
            await device.write("<< 14 d play_note_for_time 440 196 >>\n");
        } if(note == 'A3'){
            await device.write("<< 14 d play_note_for_time 440 220 >>\n");
        } if(note == 'B3'){
            await device.write("<< 14 d play_note_for_time 440 247 >>\n");
        } if(note == 'C4'){
            await device.write("<< 14 d play_note_for_time 440 262 >>\n");
        }
        if(note == 'D4'){
            await device.write("<< 14 d play_note_for_time 440 294 >>\n");
        }
        if(note == 'E4'){
            await device.write("<< 14 d play_note_for_time 440 330 >>\n");
        }
        if(note == 'F4'){
            await device.write("<< 14 d play_note_for_time 440 349 >>\n");
        }
        if(note == 'G4'){
            await device.write("<< 14 d play_note_for_time 440 392 >>\n");
        }
        if(note == 'A4'){
            await device.write("<< 14 d play_note_for_time 440 440 >>\n");
        }
        if(note == 'B4'){
            await device.write("<< 14 d play_note_for_time 440 494 >>\n");
        }
        if(note == 'C5'){
            await device.write("<< 14 d play_note_for_time 440 523 >>\n");
        }
        if(note == 'D5'){
            await device.write("<< 14 d play_note_for_time 440 587 >>\n");
        }
        if(note == 'E5'){
            await device.write("<< 14 d play_note_for_time 440 659 >>\n");
        }
        if(note == 'F5'){
            await device.write("<< 14 d play_note_for_time 440 698 >>\n");
        }
        if(note == 'G5'){
            await device.write("<< 14 d play_note_for_time 440 784 >>\n");
        }
        if(note == 'A5'){
            await device.write("<< 14 d play_note_for_time 440 880 >>\n");
        }
        if(note == 'B5'){
            await device.write("<< 14 d play_note_for_time 440 988 >>\n");
        }
        if(note == 'C6'){
            await device.write("<< 14 d play_note_for_time 440 1047 >>\n");
        }
        if(note == 'D6'){
            await device.write("<< 14 d play_note_for_time 440 1175 >>\n");
        }
        if(note == 'E6'){
            await device.write("<< 14 d play_note_for_time 440 1319 >>\n");
        }
        if(note == 'F6'){
            await device.write("<< 14 d play_note_for_time 440 1397 >>\n");
        } if(note == 'G6'){
            await device.write("<< 14 d play_note_for_time 440 1568 >>\n");
        } if(note == 'A6'){
            await device.write("<< 14 d play_note_for_time 440 1760 >>\n");
        } if(note == 'B6'){
            await device.write("<< 14 d play_note_for_time 440 1976 >>\n");
        } if(note == 'C7'){
            await device.write("<< 14 d play_note_for_time 440 2093 >>\n");
        } if(note == 'D7'){
            await device.write("<< 14 d play_note_for_time 440 2349 >>\n");
        } if(note == 'E7'){
            await device.write("<< 14 d play_note_for_time 440 2637 >>\n");
        }
        if(note == 'F7'){
            await device.write("<< 14 d play_note_for_time 440 2794 >>\n");
        }
        if(note == 'G7'){
            await device.write("<< 14 d play_note_for_time 440 3136 >>\n");
        }
        if(note == 'A7'){
            await device.write("<< 14 d play_note_for_time 440 3520 >>\n");
        }
        if(note == 'B7'){
            await device.write("<< 14 d play_note_for_time 440 3951 >>\n");
        }
        if(note == 'C8'){
            await device.write("<< 14 d play_note_for_time 440 4186 >>\n");
        }
        if(note == 'D8'){
            await device.write("<< 14 d play_note_for_time 440 4699 >>\n");
        }
    }

    async tum_sesleri_durdur(args,util){
        console.log(args);
        var device = ConnectedDeviceClass.getDevice();     
        await device.write("<< 14 d stop_note >>\n");
    }
   
   
}

module.exports = GameMusicBlocks;
