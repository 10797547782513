import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import classNames from "classnames";
import VM from "../../../mymodule/ztech-vm";

import Box from "../box/box.jsx";
import { STAGE_DISPLAY_SIZES } from "../../lib/layout-constants.js";
import Oyun from "../oyun/oyun.jsx";
//import GameStage from "../oyun/game.jsx";

import OyunHeader from "../../containers/oyun-header.jsx";
import Loader from "../loader/loader.jsx";

import styles from "./stage-wrapper.css";
import Triggers from "../oyun/Triggers.jsx";

import ExpandIcon from "../oyun/assets/button-img/show-stage.svg";

const StageWrapperComponent = function (props) {
    const {
        isFullScreen,
        isRtl,
        isRendererSupported,
        loading,
        stageSize,
        vm,
        stageWidth, 
        setStageWidth,
        isjr,
        gameDestroy,
        initialStageSize,
        device,
        deviceChange,
      
    } = props;
    const [currentGame, setCurrentGame] = useState(null);
    const [lightLevel, setLightLevel] = useState(100);
    const [initialize, setInitialize] = useState(false);
    // const [destroyTrigger, setDestroyTrigger] = useState(false);
    const [mapModalTrigger, setMapModalTrigger] = useState(false);
    const [showStage, setShowStage] = useState(true)


    useEffect(() => {
        currentGame?.events.emit("lightEvent", lightLevel);
    }, [lightLevel]);
    
    //! use for destroy game out of component
    // useEffect(() => {
    //     return () => {
    //         setDestroyTrigger(true);
    //         setTimeout(() => {
    //             setDestroyTrigger(false);
    //         }, 100);
    //     }
    // }, []);

    return (
        <Box
            className={classNames(styles.stageWrapper, {
                [styles.fullScreen]: isFullScreen,
            })}
            dir={isRtl ? "rtl" : "ltr"}
            >
            <OyunHeader 
            stageSize={stageSize} 
            vm={vm}
            showStage={showStage}
            isjr={isjr}
            />
            <Box className={styles.stageCanvasWrapper}>
                {isRendererSupported ? (
                    <>
                    {!showStage && !isFullScreen?
                    <div 
                    className={classNames(styles.expandBtn)}
                    onClick={()=>setShowStage(true)}
                    >
                     <img src={ExpandIcon} alt="expand" 
                        className={classNames(styles.expandIcon)}
                     />   
                    </div>
                    :null
                    }
                    <Oyun
                        vm={vm}
                        setCurrentGame={setCurrentGame}
                        currentGame={currentGame}
                        lightLevel={lightLevel}
                        stageWidth ={stageWidth}
                        setStageWidth={setStageWidth}
                        style={{ width: stageWidth, zIndex: 10000000000 }}
                        initialize={initialize}
                        setInitialize={setInitialize}
                        mapModalTrigger={mapModalTrigger}
                        setMapModalTrigger={setMapModalTrigger}
                        isFullScreen={isFullScreen}
                        isjr={isjr}
                        gameDestroy={gameDestroy}
                        // destroyTrigger={destroyTrigger}
                        deviceChange={deviceChange}
                        initialStageSize={initialStageSize}
                        device={device}
                        showStage={showStage}
                        setShowStage={setShowStage}
                    />
                    </>
                ) : null}
                {/* {showStage || isFullScreen? */}
                <div
                className={classNames(styles.triggerContainer,{[styles.hideBtns]: !showStage})}
                style={{width: showStage || isFullScreen ? "auto" : 0, transition: "all 0.5s", opacity: showStage || isFullScreen ? 1 : 0}}
                >
                <Triggers
                    currentGame={currentGame}
                    lightLevel={lightLevel}
                    setLightLevel={setLightLevel}
                    // initialize={initialize}
                    // setInitialize={setInitialize}
                    // setDestroyTrigger={setDestroyTrigger}
                    setMapModalTrigger={setMapModalTrigger}
                    setShowStage={setShowStage}
                    isFullScreen={isFullScreen}
                /></div>
                {/* :null} */}
            </Box>
            {loading ? <Loader isFullScreen={isFullScreen} /> : null}
        </Box>
    );
};

StageWrapperComponent.propTypes = {
    isFullScreen: PropTypes.bool,
    isRendererSupported: PropTypes.bool.isRequired,
    isRtl: PropTypes.bool.isRequired,
    loading: PropTypes.bool,
    stageSize: PropTypes.oneOf(Object.keys(STAGE_DISPLAY_SIZES)).isRequired,
    vm: PropTypes.instanceOf(VM).isRequired,
};

export default StageWrapperComponent;
