import React, { useState } from "react";
import Slider from "./Slider.jsx";

import classNames from "classnames";
import styles from "./oyun.css";
import DeviceSensorData from "../../../mymodule/ztech-vm/src/util/deviceSensorData";
import LightBulb from "./assets/button-img/light.svg";
import LightBulbOff from "./assets/button-img/light-off.svg";
import ShakeIcon from "./assets/button-img/shake.svg";
import SoundIcon from "./assets/button-img/sound.svg";
import SoundIconOff from "./assets/button-img/sound-off.svg";
import RestartIcon from "./assets/button-img/restart.svg";
import HideIcon from "./assets/button-img/hide-stage.svg";
import MapIcon from "./assets/button-img/map.svg";

// import circleBtn from "./assets/button-img/bg/circle-sm.png";

const Triggers = ({
    currentGame,
    lightLevel,
    setLightLevel,
    // setInitialize,
    setShowStage,
    // setDestroyTrigger,
    setMapModalTrigger,
    isFullScreen,
}) => {
    const [soundLevel, setSoundLevel] = useState(0);
    const [isShaking, setIsShaking] = useState(false);
    // const [is3d, setIs3d] = useState(true);
    // const [isPlaying, setIsPlaying] = useState(false);

    console.log("Triggers", currentGame?.config.gameTitle)
    // const gameTitle = currentGame?.config.gameTitle || "";
    // let btnBg = circleBtn;
    // if (gameTitle.includes("kids")) {
    //     btnBg = BtnBgSpace;
    // } else if (gameTitle.includes("empty")) {
    //     btnBg = BtnBgLava;
    // } else {
    //     btnBg = BtnBgGreen;
    // }
    const onLightChange = (value) => {
        DeviceSensorData.setLightLevel(value);
        setLightLevel(value);
    };

    const onSoundChange = (value) => {
        if (value > 0) {
            DeviceSensorData.setSoundLevel(100);
            setSoundLevel(100);
        } else {
            DeviceSensorData.setSoundLevel(0);
            setSoundLevel(0);
        }
    };

    const onShakeChange = (value) => {
        if (!isShaking) {
            currentGame?.events.emit("shakeChar", 40);
            setIsShaking(true);
            setTimeout(() => {
                setIsShaking(false);
            }, 500);
        }
    };

    // const onRestartHandler = (value) => {
    //     if (value) {
    //         setInitialize(true);
    //         setDestroyTrigger(false);
    //     } else {
    //         setDestroyTrigger(true);
    //         setInitialize(false);
    //     }
    // };

    const gameTitleArr = currentGame?.config.gameTitle.split("_") || [];
    if (gameTitleArr[1] === "sekil") {
        gameTitleArr[1] = "ŞEKİL";
    } else if (gameTitleArr[1] === "muzik") {
        gameTitleArr[1] = "MÜZİK";
    }

    console.log("gameTitleArr", gameTitleArr)

    return (
        <div
            className="triggers"
            style={{
                display: "flex",
                flexDirection: "column",
            }}
        >
            <div
                className={classNames(styles.btnContainer)}
            >
                <div
                className={classNames(styles.verticalBtnContainer)}
                >
                <div
                    itemType="button"
                    className={classNames(styles.stageBtn,{[styles.hideBtn]: isFullScreen})}
                    onClick={() => {
                        console.log("open modal");
                        setMapModalTrigger(true);
                    }}
                >
                    <img
                        src={MapIcon}
                        alt="map"
                        className={classNames(styles.stageBtnImg)}
                    />
                </div>
                <div
                    itemType="button"
                    className={classNames(styles.stageBtn,{[styles.hideBtn]: isFullScreen})}
                    onClick={() => {
                        setShowStage(false);
                        // if (isPlaying) {
                        //     currentGame?.events.emit("pauseEvent", "pause");
                        //     setIsPlaying(false);
                        //     onRestartHandler(true);
                        // } else {
                        //     currentGame?.events.emit("playEvent", "play");
                        //     setIsPlaying(true);
                        //     onRestartHandler(false);
                        // }
                    }}
                >
                    <img
                        src={HideIcon}
                        alt="play"
                        className={classNames(styles.stageBtnImg)}
                    />
                </div>
                <div
                    itemType="button"
                    className={classNames(styles.stageBtn)}
                    onClick={() => {
                        console.log("restart");

                        currentGame?.events.emit("restartEvent");
                        // setDestroyTrigger(true);
                        // // setDestroyTrigger(false);
                        // setTimeout(() => {
                        //     setInitialize(true);
                        //     setDestroyTrigger(false);
                        // }, 50);
                    }}
                >
                    <img
                        src={RestartIcon}
                        alt="restart"
                        className={classNames(styles.stageBtnImg)}
                    />
                </div>
                </div>
                <div>
                    {<div
                    className={classNames(styles.gameTitleContainer)}
                    style={{
                        color:  gameTitleArr[1] === "uzay" ? "white" : `rgb(${(100 - lightLevel) * 2.5}, ${(100 - lightLevel) * 2.5}, ${(100 - lightLevel)* 2.5})`,
                    }}
                    >
                        <h2>
                            {gameTitleArr[0]?.toLocaleUpperCase("tr-TR")}
                        </h2>
                        <h2>
                            {gameTitleArr[1]?.toLocaleUpperCase("tr-TR")}
                        </h2>
                        {gameTitleArr[2] && 
                        <p>
                            {gameTitleArr[2]}
                        </p>}
                            
                        
                    </div>}
                </div>
                <div
                    className={classNames(styles.verticalBtnContainer)}
                >
                <div className={classNames(styles.sliderBtnContainer)}>
                    <div
                        itemType="button"
                        className={classNames(styles.stageBtn)}
                        onClick={() => {
                            lightLevel > 0
                                ? onLightChange(0)
                                : onLightChange(100);
                        }}
                    >
                        <img
                            src={lightLevel > 0 ? LightBulb : LightBulbOff}
                            alt="light"
                            className={classNames(styles.stageBtnImg)}
                        />
                    </div>
                    <Slider
                        range={lightLevel}
                        setRange={setLightLevel}
                        title={"Light Level"}
                    />
                </div>
                <input
                    type="text"
                    min="0"
                    max="100"
                    value={lightLevel}
                    disabled
                    className={classNames(
                        styles.lightInput,
                        styles.sliderPanel
                    )}
                />
                <div className={classNames(styles.sliderBtnContainer)}>
                    <div
                        itemType="button"
                        className={classNames(styles.stageBtn)}
                        onClick={() => {
                            soundLevel > 0
                                ? onSoundChange(0)
                                : onSoundChange(100);
                        }}
                    >
                        <img
                            src={soundLevel > 0 ? SoundIcon : SoundIconOff}
                            alt="sound"
                            className={classNames(styles.stageBtnImg)}
                        />
                    </div>
                    <Slider
                        range={soundLevel}
                        setRange={setSoundLevel}
                        title={"Sound Level"}
                    />
                </div>
                <input
                    type="text"
                    min="0"
                    max="100"
                    value={soundLevel}
                    disabled
                    className={classNames(
                        styles.soundInput,
                        styles.sliderPanel
                    )}
                />
                <div
                    itemType="button"
                    className={classNames(styles.stageBtn)}
                    onClick={() => {
                        onShakeChange();
                    }}
                >
                    <img
                        src={ShakeIcon}
                        alt="shake"
                        className={classNames(styles.stageBtnImg)}
                    />
                </div>
                </div>
               </div>
            {/* <div className={classNames(styles.btnGroup)}>
                <button
                    className={classNames(styles.btnItem)}
                    onClick={() =>
                        currentGame?.events.emit(
                            "turnClockwiseEvent",
                            "turnClockwise"
                        )
                    }
                >
                    Turn Clockwise
                </button>
                <button
                    className={classNames(styles.btnItem)}
                    onClick={() =>
                        currentGame?.events.emit(
                            "turnClockwiseEvent",
                            "turnCounterClockwise"
                        )
                    }
                >
                    Turn Counter Clockwise
                </button>
                <button
                    className={classNames(styles.btnItem)}
                    onClick={() => currentGame?.events.emit("moveForwardEvent", true)}
                >
                    Move Forward
                </button>
                <button
                    className={classNames(styles.btnItem)}
                    onClick={() =>
                        currentGame?.events.emit("moveBackwardEvent", true)
                    }
                >
                    Move Backward
                </button>
                <button
                    className={classNames(styles.btnItem)}
                    onClick={() =>
                        currentGame?.events.emit("changeBgEvent", "kids-uzay")
                    }
                >
                    Change Background
                </button>
                <button
                    className={classNames(styles.btnItem)}
                    onClick={() =>
                        currentGame?.events.emit("changePartEvent", "screen")
                    }
                >
                    Change screen
                </button>
                <button
                    className={classNames(styles.btnItem)}
                    onClick={() =>
                        currentGame?.events.emit("changePartEvent", "robotLightYellow")
                    }
                >
                    Light on
                </button>
                <button
                    className={classNames(styles.btnItem)}
                    onClick={() =>
                        currentGame?.events.emit("removePartEvent", "robotLight")
                    }
                >
                    Light off
                </button>
                <button
                    className={classNames(styles.btnItem)}
                    onClick={() =>
                        currentGame?.events.emit("changePartEvent", "robotLightCyan")
                    }
                >
                    Light on pink
                </button>
                <button
                    className={classNames(styles.btnItem)}
                    onClick={() =>
                        currentGame?.events.emit("removePartEvent", "screen")
                    }
                >
                    Remove screen
                </button>
                <button
                    className={classNames(styles.btnItem)}
                    onClick={() =>
                        currentGame?.events.emit("pauseEvent")
                    }
                >
                    pause
                </button>
                <button
                    className={classNames(styles.btnItem)}
                    onClick={() =>
                        currentGame?.events.emit("resumeEvent")
                    }
                >
                    resume
                </button>
            </div> */}
        </div>
    );
};

export default Triggers;

