// GENERATED FILE:
export default {
  "en": {
    "microfon.name":"Mikrofon name ingilizce",
    "gui.alerts.lostPeripheralConnection": "ZTech lost connection to {extensionName}.",
    "gui.alerts.tryAgain": "Try Again",
    "gui.alerts.download": "Download",
    "gui.connection.reconnect": "Reconnect",
    "gui.alerts.downloadFirmware": "Download firmware",
    "gui.backpack.costumeLabel": "costume",
    "gui.backpack.soundLabel": "sound",
    "gui.backpack.scriptLabel": "script",
    "gui.backpack.spriteLabel": "sprite",
    "gui.backpack.header": "Backpack",
    "gui.backpack.errorBackpack": "Error loading backpack",
    "gui.backpack.loadingBackpack": "Loading...",
    "gui.backpack.more": "More",
    "gui.backpack.emptyBackpack": "Backpack is empty",
    "gui.unsupportedBrowser.label": "Browser is not supported",
    "gui.unsupportedBrowser.errorLabel": "An Error Occurred",
    "gui.unsupportedBrowser.notRecommended": "We are very sorry, but it looks like you are using a browser version that ZTech does not support. We recommend updating to the latest version of a supported browser such as Google Chrome, Mozilla Firefox, Microsoft Edge, or Apple Safari.",
    "gui.unsupportedBrowser.description": "We are very sorry, but ZTech does not support this browser. We recommend updating to the latest version of a supported browser such as Google Chrome, Mozilla Firefox, Microsoft Edge, or Apple Safari.",
    "gui.unsupportedBrowser.back": "Back",
    "gui.unsupportedBrowser.previewfaq": "To learn more, go to the {previewFaqLink}.",
    "gui.unsupportedBrowser.previewfaqlinktext": "FAQ",
    "gui.cards.all-tutorials": "Tutorials",
    "gui.cards.shrink": "Shrink",
    "gui.cards.expand": "Expand",
    "gui.cards.close": "Close",
    "gui.cards.more-things-to-try": "More things to try!",
    "gui.cards.see-more": "See more",
    "gui.comingSoon.message1": "Don't worry, we're on it {emoji}",
    "gui.comingSoon.message2": "Coming Soon...",
    "gui.comingSoon.message3": "We're working on it {emoji}",
    "gui.connection.auto-scanning.noPeripheralsFound": "No devices found",
    "gui.connection.auto-scanning.prescan": "Have your device nearby, then begin searching.",
    "gui.connection.auto-scanning.pressbutton": "Press the button on your device.",
    "gui.connection.auto-scanning.start-search": "Start Searching",
    "gui.connection.connecting-searchbutton": "Searching...",
    "gui.connection.auto-scanning.try-again": "Try again",
    "gui.connection.connected": "USB Connected",
    "gui.connection.connectedSerial": "USB Connected",
    "gui.connection.connectedBle": "Bluetooth Connected",
    "gui.connection.disconnect": "Disconnect",
    "gui.connection.go-to-editor": "Go to Editor",
    "gui.connection.connecting-cancelbutton": "Connecting...",
    "gui.connection.error.errorMessage": "Oops, looks like something went wrong.",
    "gui.connection.error.tryagainbutton": "Try again",
    "gui.connection.error.helpbutton": "Help",
    "gui.connection.peripheral-name-label": "Device name",
    "gui.connection.connect": "Connect",
    "gui.connection.scanning.listAll": "Show all connectable devices",
    "gui.connection.scanning.lookingforperipherals": "Looking for devices",
    "gui.connection.scanning.noPeripheralsFound": "No devices found",
    "gui.connection.scanning.instructions": "Select your device in the list above.",
    "gui.connection.search": "Refresh",
    "gui.connection.unavailable.installscratchlink": "Make sure you have ZTech Link installed and running",
    "gui.connection.unavailable.connectUsbEnableBluetooth": "Check that USB cable is connected",
    "gui.connection.unavailable.tryagainbutton": "Try again",
    "gui.connection.unavailable.helpbutton": "Help",
    "gui.controls.go": "Go",
    "gui.controls.stop": "Stop",
    "gui.crashMessage.label": "Oops! Something went wrong.",
    "gui.crashMessage.description": "We are so sorry, but it looks like ZTech has crashed. This bug has been automatically reported to the ZTech Team. Please refresh your page to try again.",
    "gui.crashMessage.errorNumber": "Your error was logged with id {errorId}",
    "gui.crashMessage.reload": "Reload",
    "gui.customProcedures.myblockModalTitle": "Make a Block",
    "gui.customProcedures.addAnInputNumber": "Add an input",
    "gui.customProcedures.numberType": "number",
    "gui.customProcedures.addAnInputText": "Add an input",
    "gui.customProcedures.TextType": "text",
    "gui.customProcedures.addAnInputBoolean": "Add an input",
    "gui.customProcedures.booleanType": "boolean",
    "gui.customProcedures.addALabel": "Add a label",
    "gui.customProcedures.runWithoutScreenRefresh": "Run without screen refresh",
    "gui.customProcedures.cancel": "Cancel",
    "gui.customProcedures.ok": "OK",
    "gui.SpriteInfo.direction": "Direction",
    "gui.directionPicker.rotationStyles.allAround": "All Around",
    "gui.directionPicker.rotationStyles.leftRight": "Left/Right",
    "gui.directionPicker.rotationStyles.dontRotate": "Do not rotate",
    "gui.gui.addExtension": "Add Extension",
    "gui.gui.codeTab": "Code",
    "gui.gui.backdropsTab": "Backdrops",
    "gui.gui.costumesTab": "Costumes",
    "gui.gui.soundsTab": "Sounds",
    "gui.hardwareConsole.send": "Send",
    "gui.hardwareConsole.buadrate": "Buadrate",
    "gui.hardwareConsole.endOfLine": "End of line",
    "gui.hardwareConsole.hexform": "Hex form",
    "gui.hardwareConsole.autoScroll": "Auto scroll",
    "gui.hardwareHeader.upload": "Upload",
    "gui.stageHeader.codeStageSizeLarge": "Switch to large stage",
    "gui.stageHeader.codeStageSizeSmall": "Switch to small stage",
    "gui.stageHeader.hideStage": "Hide stage",
    "gui.extensionLibrary.comingSoon": "Coming Soon",
    "gui.extensionLibrary.requires": "Requires",
    "gui.extensionLibrary.collaboration": "Collaboration with",
    "gui.extensionLibrary.manufactor": "Manufactor",
    "gui.extensionLibrary.version": "Version",
    "gui.extensionLibrary.author": "Author",
    "gui.extensionLibrary.programMode": "Program mode",
    "gui.extensionLibrary.programLanguage": "Program language",
    "gui.extensionLibrary.deviceLearnMore": "Learn more",
    "gui.extensionLibrary.extensionHelp": "Help",
    "gui.extensionLibrary.Processing": "Processing...",
    "gui.extensionLibrary.loaded": "Loaded",
    "gui.extensionLibrary.notLoaded": "Not loaded",
    "gui.library.filterPlaceholder": "Search",
    "gui.library.allTag": "All",
    "gui.loader.message1": "Creating blocks …",
    "gui.loader.message2": "Loading sprites …",
    "gui.loader.message3": "Loading sounds …",
    "gui.loader.message4": "Loading extensions …",
    "gui.loader.message5": "Herding cats …",
    "gui.loader.message6": "Transmitting nanos …",
    "gui.loader.message7": "Inflating gobos …",
    "gui.loader.message8": "Preparing emojis …",
    "gui.loader.headline": "Loading Project",
    "gui.loader.creating": "Creating Project",
    "gui.accountMenu.profile": "Profile",
    "gui.accountMenu.myStuff": "My Stuff",
    "gui.accountMenu.myClasses": "My Classes",
    "gui.accountMenu.myClass": "My Class",
    "gui.accountMenu.accountSettings": "Account settings",
    "gui.accountMenu.signOut": "Sign out",
    "gui.authorInfo.byUser": "by {username}",
    "gui.menuBar.seeProjectPage": "See Project Page",
    "general.username": "Username",
    "general.password": "Password",
    "general.signIn": "Sign in",
    "login.needHelp": "Need Help?",
    "form.validationRequired": "This field is required",
    "gui.menuBar.LanguageSelector": "language selector",
    "gui.menuBar.uploadBilgisayarsızKodlama": "Computerless Coding",
    "gui.menuBar.tutorialsLibrary": "Tutorials",
    "gui.menuBar.restoreSprite": "Restore Sprite",
    "gui.menuBar.restoreSound": "Restore Sound",
    "gui.menuBar.restoreCostume": "Restore Costume",
    "gui.menuBar.restore": "Restore",
    "gui.menuBar.saveNow": "Save now",
    "gui.menuBar.saveAsCopy": "Save as a copy",
    "gui.menuBar.remix": "Remix",
    "gui.menuBar.new": "New",
    "gui.menuBar.checkUpdate": "Check update",
    "gui.menuBar.firmwareUpload": "Firmware Upload",
    "gui.menuBar.CalibarateDevice": "Calibarate Device",
    "gui.menuBar.DownloadZconnect": "Download Zconnect",
    "gui.menuBar.DeviceReset": "Reset Device",
    "gui.menuBar.installDriver": "Install driver",
    "gui.menuBar.clearCacheAndRestart": "Clear cache and restart",
    "gui.menuBar.edit": "Edit",
    "gui.menuBar.turboModeOff": "Turn off Turbo Mode",
    "gui.menuBar.turboModeOn": "Turn on Turbo Mode",
    "gui.menuBar.noDeviceSelected": "No device selected",
    "gui.menuBar.noConnection": "Unconnected",
    "gui.menuBar.file": "File",
    "gui.menuBar.downloadToComputer": "Save to your computer",
    "gui.menuBar.downloadFirmware": "Download firmware",
    "gui.menu-bar.modeSwitchRealtime": "Realtime",
    "gui.menu-bar.modeSwitchRealtimeUpload": "Upload",
    "gui.gui.projectTitlePlaceholder": "Project title here",
    "gui.menuBar.saveNowLink": "Save Now",
    "gui.menuBar.isShared": "Shared",
    "gui.menuBar.share": "Share",
    "gui.modal.help": "Help",
    "gui.modal.back": "Back",
    "gui.modal.delete": "Delete",
    "gui.monitor.listMonitor.empty": "(empty)",
    "gui.monitor.listMonitor.listLength": "length {length}",
    "gui.monitor.contextMenu.default": "normal readout",
    "gui.monitor.contextMenu.large": "large readout",
    "gui.monitor.contextMenu.slider": "slider",
    "gui.monitor.contextMenu.sliderRange": "change slider range",
    "gui.monitor.contextMenu.import": "import",
    "gui.monitor.contextMenu.export": "export",
    "gui.playButton.play": "Play",
    "gui.playButton.stop": "Stop",
    "gui.gui.variableScopeOptionAllSprites": "For all sprites",
    "gui.gui.variableScopeOptionSpriteOnly": "For this sprite only",
    "gui.gui.cloudVariableOption": "Cloud variable (stored on server)",
    "gui.gui.variablePromptAllSpritesMessage": "This variable will be available to all sprites.",
    "gui.prompt.cancel": "Cancel",
    "gui.prompt.ok": "OK",
    "gui.playbackStep.stopMsg": "Stop",
    "gui.playbackStep.playMsg": "Play",
    "gui.playbackStep.loadingMsg": "Loading...",
    "gui.playbackStep.saveMsg": "Save",
    "gui.playbackStep.reRecordMsg": "Re-record",
    "gui.recordModal.title": "Record Sound",
    "gui.recordingStep.beginRecord": "Begin recording by clicking the button below",
    "gui.recordingStep.permission": "{arrow}We need your permission to use your microphone",
    "gui.recordingStep.stop": "Stop recording",
    "gui.recordingStep.record": "Record",
    "gui.sliderModal.min": "Minimum value",
    "gui.sliderModal.max": "Maximum value",
    "gui.sliderModal.title": "Change slider range",
    "gui.sliderPrompt.cancel": "Cancel",
    "gui.sliderPrompt.ok": "OK",
    "gui.soundEditor.sound": "Sound",
    "gui.soundEditor.play": "Play",
    "gui.soundEditor.stop": "Stop",
    "gui.soundEditor.copy": "Copy",
    "gui.soundEditor.paste": "Paste",
    "gui.soundEditor.copyToNew": "Copy to New",
    "gui.soundEditor.delete": "Delete",
    "gui.soundEditor.save": "Save",
    "gui.soundEditor.undo": "Undo",
    "gui.soundEditor.redo": "Redo",
    "gui.soundEditor.faster": "Faster",
    "gui.soundEditor.slower": "Slower",
    "gui.soundEditor.echo": "Echo",
    "gui.soundEditor.robot": "Robot",
    "gui.soundEditor.louder": "Louder",
    "gui.soundEditor.softer": "Softer",
    "gui.soundEditor.reverse": "Reverse",
    "gui.soundEditor.fadeOut": "Fade out",
    "gui.soundEditor.fadeIn": "Fade in",
    "gui.soundEditor.mute": "Mute",
    "gui.SpriteInfo.spritePlaceholder": "Name",
    "gui.SpriteInfo.sprite": "Sprite",
    "gui.SpriteInfo.show": "Show",
    "gui.SpriteInfo.size": "Size",
    "gui.spriteSelectorItem.contextMenuDuplicate": "duplicate",
    "gui.spriteSelectorItem.contextMenuExport": "export",
    "gui.spriteSelectorItem.contextMenuDelete": "delete",
    "gui.spriteSelector.addSpriteFromLibrary": "Choose a Sprite",
    "gui.spriteSelector.addSpriteFromPaint": "Paint",
    "gui.spriteSelector.addSpriteFromSurprise": "Surprise",
    "gui.spriteSelector.addSpriteFromFile": "Upload Sprite",
    "gui.stageHeader.stageSizeLarge": "Switch to large stage",
    "gui.stageHeader.stageSizeSmall": "Switch to small stage",
    "gui.stageHeader.stageSizeFull": "Enter full screen mode",
    "gui.stageHeader.stageSizeUnFull": "Exit full screen mode",
    "gui.stageHeader.fullscreenControl": "Full Screen Control",
    "gui.spriteSelector.addBackdropFromLibrary": "Choose a Backdrop",
    "gui.stageSelector.addBackdropFromPaint": "Paint",
    "gui.stageSelector.addBackdropFromSurprise": "Surprise",
    "gui.stageSelector.addBackdropFromFile": "Upload Backdrop",
    "gui.stageSelector.stage": "Stage",
    "gui.stageSelector.backdrops": "Backdrops",
    "gui.telemetryOptIn.label": "Report statistics to improve ZTech",
    "gui.telemetryOptIn.body1": "The ZTech Team is always looking to better understand how ZTech is used around the world. To help support this effort, you can allow ZTech to automatically send usage information to the ZTech Team.",
    "gui.telemetryOptIn.body2": "The information we collect includes language selection, blocks usage, and some events like saving, loading, and uploading a project. We DO NOT collect any personal information. Please see our {privacyPolicyLink} for more information.",
    "gui.telemetryOptIn.privacyPolicyLink": "Privacy Policy",
    "gui.telemetryOptIn.optInText": "Share my usage data with the ZTech Team",
    "gui.telemetryOptIn.optInTooltip": "Enable telemetry",
    "gui.telemetryOptIn.optOutText": "Do not share my usage data with the ZTech Team",
    "gui.telemetryOptIn.optOutTooltip": "Disable telemetry",
    "gui.telemetryOptIn.settingWasUpdated": "Your setting was updated.",
    "gui.telemetryOptIn.buttonClose": "Close",
    "gui.turboMode.active": "Turbo Mode",
    "gui.updateModel.tileUpdate": "New external resource version detected",
    "gui.upgradeModal.upgradeLater": "Upgrade later",
    "gui.upgradeModal.upgradeAndRestart": "Upgrade and restart",
    "gui.updateModel.tileChecking": "Checking",
    "gui.updateModel.checkingTips": "This step will take a few seconds, please wait.",
    "gui.upgradeModal.closeChecking": "Close",
    "gui.updateModel.tileAlreadyLatest": "Already latest",
    "gui.updateModel.alreadyLatestTips": "External source is already latest.",
    "gui.updateModel.upgrading": "Upgrading",
    "gui.upgradeModal.downloading": "Downloading",
    "gui.upgradeModal.covering": "Covering",
    "gui.updateModel.tileError": "Operation failed",
    "gui.uploadProgress.uploading": "Uploading",
    "gui.uploadProgress.uploadSuccess": "Upload success",
    "gui.uploadProgress.uploadError": "Upload error",
    "gui.uploadProgress.uploadTimeout": "Upload timeout",
    "gui.uploadProgress.close": "Close",
    "gui.webglModal.label": "Your Browser Does Not Support WebGL",
    "gui.webglModal.description": "Unfortunately it looks like your browser or computer {webGlLink}. This technology is needed for ZTech to run.",
    "gui.webglModal.webgllink": "does not support WebGL",
    "gui.webglModal.back": "Back",
    "gui.webglModal.previewfaq": "To learn more, go to the {previewFaqLink}.",
    "gui.webglModal.previewfaqlinktext": "FAQ",
    "gui.costumeLibrary.chooseABackdrop": "Choose a Backdrop",
    "gui.costumeLibrary.chooseACostume": "Choose a Costume",
    "gui.costumeTab.addBackdropFromLibrary": "Choose a Backdrop",
    "gui.costumeTab.addCostumeFromLibrary": "Choose a Costume",
    "gui.costumeTab.addBlankCostume": "Paint",
    "gui.costumeTab.addSurpriseCostume": "Surprise",
    "gui.costumeTab.addFileBackdrop": "Upload Backdrop",
    "gui.costumeTab.addFileCostume": "Upload Costume",
    "gui.deviceLibrary.chooseADevice": "Choose an Device",
    "gui.deviceLibrary.deviceUrl": "Enter the URL of the device",
    "gui.deviceLibrary.arduinoTag": "Arduino",
    "gui.deviceLibrary.microPythonTag": "MicroPython",
    "gui.deviceLibrary.kitTag": "Kit",
    "gui.extensionLibrary.chooseAnExtension": "Choose an Extension",
    "gui.extensionLibrary.extensionUrl": "Enter the URL of the extension",
    "gui.library.shieldTag": "Shield",
    "gui.library.actuatorTag": "Actuator",
    "gui.library.sensorTag": "Sensor",
    "gui.library.displayTag": "Display",
    "gui.library.communicationTag": "Communication",
    "gui.library.otherTag": "Other",
    "gui.hardwareConsole.noLineTerminators": "No line terminators",
    "gui.hardwareConsole.lineFeed": "Line feed",
    "gui.hardwareConsole.carriageReturn": "Carriage return",
    "gui.hardwareConsole.lfAndCr": "LF & CR",
    "gui.monitors.importListColumnPrompt": "Which column should be used (1-{numberOfColumns})?",
    "gui.recordingStep.alertMsg": "Could not start recording",
    "gui.soundLibrary.chooseASound": "Choose a Sound",
    "gui.soundTab.fileUploadSound": "Upload Sound",
    "gui.soundTab.surpriseSound": "Surprise",
    "gui.soundTab.recordSound": "Record",
    "gui.soundTab.addSoundFromLibrary": "Choose a Sound",
    "gui.spriteLibrary.chooseASprite": "Choose a Sprite",
    "gui.tipsLibrary.tutorials": "Choose a Tutorial",
    "gui.updateModal.upgradeWarning": "Currently unsaved projects will be lost, continue upgrade and restart?",
    "gui.uploadProgress.uploadErrorMessage": "Upload error",
    "gui.uploadProgress.uploadTimeoutMessage": "Upload timeout",
    "gui.alerts.createsuccess": "New project created.",
    "gui.alerts.createcopysuccess": "Project saved as a copy.",
    "gui.alerts.createremixsuccess": "Project saved as a remix.",
    "gui.alerts.creating": "Creating new…",
    "gui.alerts.creatingCopy": "Copying project…",
    "gui.alerts.creatingRemix": "Remixing project…",
    "gui.alerts.creatingError": "Could not create the project. Please try again!",
    "gui.alerts.savingError": "Project could not save.",
    "gui.alerts.savesuccess": "Project saved.",
    "gui.alerts.saving": "Saving project…",
    "gui.alerts.cloudInfo": "Please note, cloud variables only support numbers, not letters or symbols. {learnMoreLink}",
    "gui.alerts.cloudInfoLearnMore": "Learn more.",
    "gui.alerts.importing": "Importing…",
    "gui.alerts.workspaceIsEmpty": "Workspace is empty",
    "gui.alerts.workspaceIsNotEmpty": "Workspace is not empty, plese clean you workspace first",
    "gui.alerts.uploadError": "Upload error",
    "gui.alerts.uploadSuccess": "Upload success",
    "gui.alerts.selectADeviceFirst": "Plese select a device first",
    "gui.alerts.connectAPeripheralFirst": "Plese connect a peripheral first",
    "gui.defaultProject.chirp": "Chirp",
    "gui.defaultProject.variable": "my variable",
    "gui.howtos.intro-move-sayhello-hat.name": "Getting Started",
    "gui.howtos.intro-move.step_stepMove": "Add a move block",
    "gui.howtos.add-a-move-block.step_stepSay": "Add a say block",
    "gui.howtos.add-a-move-block.step_stepGreenFlag": "Click the green flag to start",
    "gui.howtos.animate-a-name.name": "Animate a Name",
    "gui.howtos.animate-a-name.step_AnimatePickLetter": "Pick a Letter Sprite",
    "gui.howtos.animate-a-name.step_AnimatePlaySound": "Play a Sound When Clicked",
    "gui.howtos.animate-a-name.step_AnimatePickLetter2": "Pick Another Letter Sprite",
    "gui.howtos.animate-a-name.step_AnimateChangeColor": "Change color",
    "gui.howtos.animate-a-name.step_AnimateSpin": "Pick Another Letter Sprite & Make It Spin",
    "gui.howtos.animate-a-name.step_AnimateGrow": "Pick Another Letter Sprite & Make It Grow",
    "gui.howtos.imagine": "Imagine a World",
    "gui.howtos.imagine.step_imagineTypeWhatYouWant": "Type What You Want to Say",
    "gui.howtos.imagine.step_imagineClickGreenFlag": "Click the Green Flag to Start",
    "gui.howtos.imagine.step_imagineChooseBackdrop": "Choose Any Backdrop",
    "gui.howtos.imagine.step_imagineChooseSprite": "Choose Any Sprite",
    "gui.howtos.imagine.step_imagineFlyAround": "Press the Space Key to Glide",
    "gui.howtos.imagine.step_imagineChooseAnotherSprite": "Choose Another Sprite",
    "gui.howtos.imagine.step_imagineLeftRight": "Move Left-Right",
    "gui.howtos.imagine.step_imagineUpDown": "Move Up-Down",
    "gui.howtos.imagine.step_imagineChangeCostumes": "Change Costumes",
    "gui.howtos.imagine.step_imagineGlideToPoint": "Glide to a Point",
    "gui.howtos.imagine.step_imagineGrowShrink": "Grow and Shrink",
    "gui.howtos.imagine.step_imagineChooseAnotherBackdrop": "Choose Another Backdrop",
    "gui.howtos.imagine.step_imagineSwitchBackdrops": "Switch Backdrops",
    "gui.howtos.imagine.step_imagineRecordASound": "Add a Sound",
    "gui.howtos.imagine.step_imagineChooseSound": "Choose Your Sound",
    "gui.howtos.make-music.name": "Make Music",
    "gui.howtos.Make-Music.step_PickInstrument": "Pick an Instrument Sprite",
    "gui.howtos.Make-Music.step_PlaySoundClick": "Play Sound When Clicked",
    "gui.howtos.Make-Music.step_MakeSong": "Create a Song",
    "gui.howtos.make-music.step_MakeBeat": "Choose a Drum & Make a Beat",
    "gui.howtos.make-music.step_MakeBeatBox": "Choose the Microphone Sprite & Surprise Beatbox",
    "gui.howtos.story.name": "Create A Story",
    "gui.howtos.story.step_addbg": "Add a Backdrop",
    "gui.howtos.story.step_addsprite": "Add a Character",
    "gui.howtos.story.step_saysomething": "Say Something",
    "gui.howtos.story.step_addanothersprite": "Add Another Character",
    "gui.howtos.story.step_flip": "Flip Direction",
    "gui.howtos.story.step_conversation": "Have A Conversation",
    "gui.howtos.story.addanotherbg": "Add Another Backdrop",
    "gui.howtos.story.step_swithbg": "Switch Backdrops",
    "gui.howtos.story.step_hidewizard": "Hide a Character",
    "gui.howtos.story.step_showwizard": "Show a Character",
    "gui.howtos.make-a-chase-game.name": "Make a Chase Game",
    "gui.howtos.Chase-Game.step_BG": "Add a Backdrop",
    "gui.howtos.chase-game.step_AddOcto": "Add a Sprite",
    "gui.howtos.make-music.step_LeftRight": "Move Right & Left With Arrow Keys",
    "gui.howtos.Chase-Game.step_UpDown": "Move Up & Down With Arrow Keys",
    "gui.howtos.Chase-Game.step_AddStar": "Add Another Sprite",
    "gui.howtos.Chase-Game.step_MoveRandom": "Move Randomly",
    "gui.howtos.Chase-Game.step_WhenTouch": "In Octopus Sprite, When Touching Play Sound",
    "gui.howtos.Chase-Game.step_ScoreVariable": "Create Score Variable",
    "gui.howtos.Chase-Game.ScoreWhenTouch": "In Octopus Sprite, When Touching Add Score",
    "gui.howtos.animate-char.name": "Animate A Character",
    "gui.howtos.animate-char.step_addbg": "Add a Backdrop",
    "gui.howtos.animate-char.step_addsprite": "Add a Sprite",
    "gui.howtos.animate-char.step_saysomething": "Say Something",
    "gui.howtos.animate-char.step_addsound": "Add Sound",
    "gui.howtos.animate-char.step_animatetalking": "Animate Talking",
    "gui.howtos.animate-char.step_arrowkeys": "Move Using Arrow Keys",
    "gui.howtos.animate-char.step_jump": "Jump",
    "gui.howtos.animate-char.step_changecolor": "Change Color",
    "gui.howtos.make-a-game.name": "Make a Clicker Game",
    "gui.howtos.Make-A-Game.step_GamePickSprite": "Pick A Sprite",
    "gui.howtos.make-a-game.step_GamePlaySound": "Play Sound When Clicked",
    "gui.howtos.make-a-game.step_GameAddScore": "Create Score Variable",
    "gui.howtos.make-a-game.step_GameChangeScore": "When Clicked Increase Score",
    "gui.howtos.make-a-game.step_Random": "Go to a random position",
    "gui.howtos.make-music.step_GameChangeColor": "Change Color",
    "gui.howtos.make-music.step_ResetScore": "Reset Score",
    "gui.howtos.make-it-fly.name": "Make it Fly",
    "gui.howtos.fly.step_stepflyChooseBackdrop": "Choose a Sky Background",
    "gui.howtos.add-a-move-block.step_stepflyChooseCharacter": "Choose a Character",
    "gui.howtos.fly.step_stepflySaySomething": "Say Something",
    "gui.howtos.add-a-move-block.step_stepflyMoveArrows": "Move With Arrow Keys",
    "gui.howtos.fly.step_stepflyChooseObject": "Choose an Object to Collect",
    "gui.howtos.add-a-move-block.step_stepflyFlyingObject": "Make the Object Move",
    "gui.howtos.add-a-move-block.step_stepflySelectFlyingSprite": "Select Your Character",
    "gui.howtos.add-a-move-block.step_stepflyAddScore": "Add a Score",
    "gui.howtos.add-a-move-block.step_stepflyKeepScore": "Keep Score",
    "gui.howtos.add-a-move-block.step_stepflyAddScenery": "Add Scenery",
    "gui.howtos.add-a-move-block.step_stepflyMoveScenery": "Move the Scenery",
    "gui.howtos.add-a-move-block.step_stepflySwitchLooks": "Next Costume",
    "gui.howtos.pong": "Pong Game",
    "gui.howtos.pong.step_pongAddBackdrop": "Add a Backdrop",
    "gui.howtos.pong.step_pongAddBallSprite": "Add a Ball Sprite",
    "gui.howtos.pong.step_pongBounceAround": "Bounce Around",
    "gui.howtos.pong.step_pongAddPaddle": "Add a Paddle",
    "gui.howtos.pong.step_pongMoveThePaddle": "Move the Paddle",
    "gui.howtos.pong.step_pongSelectBallSprite": "Select the Ball Sprite",
    "gui.howtos.pong.step_pongAddMoreCodeToBall": "Add Code to Bounce the Ball Off the Paddle",
    "gui.howtos.pong.step_pongAddAScore": "Add a Score",
    "gui.howtos.pong.step_pongChooseScoreFromMenu": "Choose 'Score' from the Menu",
    "gui.howtos.pong.step_pongInsertChangeScoreBlock": "Insert the 'Change Score' Block",
    "gui.howtos.pong.step_pongResetScore": "Reset Score",
    "gui.howtos.pong.step_pongAddLineSprite": "Add the Line Sprite",
    "gui.howtos.pong.step_pongGameOver": "Game Over",
    "gui.howtos.code-cartoon": "Code a Cartoon",
    "gui.howtos.code-cartoon.step_codeCartoonSaySomething": "Say Something When You Click the Green Flag",
    "gui.howtos.code-cartoon.step_codeCartoonAnimate": "Animate a Character When You Click It",
    "gui.howtos.code-cartoon.step_codeCartoonSelectDifferentCharacter": "Select a Different Character",
    "gui.howtos.code-cartoon.step_codeCartoonUseMinusSign": "Use a Minus Sign to Get Smaller",
    "gui.howtos.code-cartoon.step_codeCartoonGrowShrink": "Make a Character Grow and Shrink",
    "gui.howtos.code-cartoon.step_codeCartoonSelectDifferentCharacter2": "Select a Different Character",
    "gui.howtos.code-cartoon.step_codeCartoonJump": "Jump Up and Down",
    "gui.howtos.code-cartoon.step_codeCartoonChangeScenes": "Click a Character to Change Scenes",
    "gui.howtos.code-cartoon.step_codeCartoonGlideAround": "Glide Around",
    "gui.howtos.code-cartoon.step_codeCartoonChangeCostumes": "Change Costumes",
    "gui.howtos.code-cartoon.step_codeCartoonChooseMoreCharacters": "Choose More Characters to Add to Your Cartoon",
    "gui.howtos.cartoon-network": "Animate an Adventure Game",
    "gui.howtos.cartoon-network.step_CNcharacter": "Choose a Character to Show",
    "gui.howtos.cartoon-network.step_CNsay": "Say Something",
    "gui.howtos.cartoon-network.step_CNglide": "Glide Around",
    "gui.howtos.cartoon-network.step_CNpicksprite": "Choose an Object to Chase",
    "gui.howtos.cartoon-network.step_CNcollect": "Collect Objects",
    "gui.howtos.cartoon-network.step_CNvariable": "Make a Score Variable",
    "gui.howtos.cartoon-network.step_CNscore": "Keep Score",
    "gui.howtos.cartoon-network.step_CNbackdrop": "Level Up: Change Backdrop",
    "gui.howtos.videosens.name": "Video Sensing",
    "gui.howtos.videosens.step_addextension": "Add Extension",
    "gui.howtos.videosens.step_pet": "Pet the Cat",
    "gui.howtos.videosens.step_animate": "Animate",
    "gui.howtos.videosens.step_pop": "Pop a Balloon",
    "gui.howtos.say-it-out-loud": "Create Animations That Talk",
    "gui.howtos.say-it-out-loud.step_AddTXTextension": "Add the Text to Speech blocks",
    "gui.howtos.say-it-out-loud.step_TXTSpeech": "Say Something",
    "gui.howtos.say-it-out-loud_TXTSetVoice": "Set a Voice",
    "gui.howtos.say-it-out-loud.step_TXTMove": "Move Around",
    "gui.howtos.say-it-out-loud.step_TXTBackdrop": "Add a Backdrop",
    "gui.howtos.say-it-out-loud.step_TXTAddSprite": "Add Another Character",
    "gui.howtos.say-it-out-loud.step_TXTSong": "Perform a Song",
    "gui.howtos.say-it-out-loud.step_TXTColor": "Change Color",
    "gui.howtos.say-it-out-loud.step_TXTSpin": "Spin Around",
    "gui.howtos.say-it-out-loud.step_TXTGrow": "Grow and Shrink",
    "gui.howtos.talking": "Talking Tales",
    "gui.howtos.talking.step_talesAddExtension": "Click to Add the Text-to-Speech Blocks",
    "gui.howtos.talking.step_talesChooseSprite": "Choose a Sprite",
    "gui.howtos.talking.step_talesSaySomething": "Make a Character Speak",
    "gui.howtos.talking.step_talesChooseBackdrop": "Choose a Backdrop",
    "gui.howtos.talking.step_talesSwitchBackdrop": "Click a Character to Go to the Next Backdrop",
    "gui.howtos.talking.step_talesChooseAnotherSprite": "Choose Another Sprite",
    "gui.howtos.talking.step_talesMoveAround": "Move Around",
    "gui.howtos.talking.step_talesChooseAnotherBackdrop": "Choose Another Backdrop",
    "gui.howtos.talking.step_talesAnimateTalking": "Animate Talking",
    "gui.howtos.talking.step_talesChooseThirdBackdrop": "Choose Another Backdrop",
    "gui.howtos.talking.step_talesChooseSound": "Choose a Song to Dance To",
    "gui.howtos.talking.step_talesDanceMoves": "Dance Moves",
    "gui.howtos.talking.step_talesAskAnswer": "Get the Ask and Answer Blocks from the Sensing Category",
    "gui.howtos.add-sprite.name": "Add a Sprite",
    "gui.howtos.add-sprite.step_addSprite": "Add a Sprite",
    "gui.howtos.add-a-backdrop.name": "Add a Backdrop",
    "gui.howtos.add-a-backdrop.step_addBackdrop": "Add a Backdrop",
    "gui.howtos.change-size.name": "Change Size",
    "gui.howtos.change-size.step_changeSize": "Change Size",
    "gui.howtos.glide-around.name": "Glide Around",
    "gui.howtos.change-size.step_glideAroundBackAndForth": "Glide Around",
    "gui.howtos.change-size.step_glideAroundPoint": "Glide to a Point",
    "gui.howtos.record-a-sound.name": "Record a Sound",
    "gui.howtos.change-size.step_recordASoundSoundsTab": "Click on the 'Sounds' Tab",
    "gui.howtos.change-size.step_recordASoundClickRecord": "Click 'Record'",
    "gui.howtos.change-size.step_recordASoundPressRecordButton": "Press the Record Button",
    "gui.howtos.change-size.step_recordASoundChooseSound": "Choose Your Sound",
    "gui.howtos.change-size.step_recordASoundPlayYourSound": "Play Your Sound",
    "gui.howtos.spin-video.name": "Make It Spin",
    "gui.howtos.change-size.step_spinTurn": "Turn",
    "gui.howtos.change-size.step_spinPointInDirection": "Set Direction",
    "gui.howtos.hide-and-show.name": "Hide and Show",
    "gui.howtos.change-size.step_hideAndShow": "Hide and Show",
    "gui.howtos.switch-costume.name": "Animate a Sprite",
    "gui.howtos.change-size.step_switchCostumes": "Animate a Sprite",
    "gui.howtos.move-around-with-arrow-keys.name": "Use Arrow Keys",
    "gui.howtos.add-a-backdrop.step_moveArrowKeysLeftRight": "Move Left and Right",
    "gui.howtos.add-a-backdrop.step_moveArrowKeysUpDown": "Move Up and Down",
    "gui.howtos.add-effects.name": "Add Effects",
    "gui.howtos.videosens.step_addEffects": "Add Effects",
    "gui.device.unselectDevice.name": "Sprites",
    "gui.device.unselectDevice.description": "Unselect the device, just go back to programming mode sprites.",
    "gui.device.arduinoUno.description": "A great board to get started with electronics and coding.",
    "gui.device.arduino.connectingMessage": "Connecting",
    "gui.device.arduinoNano.description": "The Arduino Nano is a classic small board to build your projects with.",
    "gui.device.arduinoMini.description": "The Arduino Mini a classic smaller board to build your projects with.",
    "gui.device.arduinoLeonardo.description": "The classic Arduino board that can act as a mouse or keyboard.",
    "gui.device.arduinoMega2560.description": "The 8-bit board with 54 digital pins, 16 analog inputs, and 4 serial ports.",
    "gui.device.arduinoEsp32.description": "Wi-Fi & Bluetooth control board with rich functions.",
    "gui.device.arduinoEsp32.connectingMessage": "Connecting",
    "gui.device.arduinoEsp8266.description": "Low-cost Wi-Fi SOC control board.",
    "gui.device.arduinoEsp8266.connectingMessage": "Connecting",
    "gui.device.microbit.description": "The pocket-sized computer transforming digital skills learning.",
    "gui.device.microbit.connectingMessage": "Connecting",
    "gui.device.microbitV2.description": "Upgraded processor, built-In speaker and microphone, touch sensitive logo.",
    "gui.device.makeymakey.description": "Make anything into a key.",
    "gui.extension.music.name": "Music",
    "gui.extension.music.description": "Play instruments and drums.",
    "gui.extension.pen.name": "Pen",
    "gui.extension.pen.description": "Draw with your sprites.",
    "gui.extension.videosensing.name": "Video Sensing",
    "gui.extension.videosensing.description": "Sense motion with the camera.",
    "gui.extension.text2speech.name": "Text to Speech",
    "gui.extension.text2speech.description": "Make your projects talk.",
    "gui.extension.translate.name": "Translate",
    "gui.extension.translate.description": "Translate text into many languages.",
    "gui.extension.makeymakey.name": "Makey Makey",
    "gui.extension.makeymakey.description": "Make anything into a key.",
    "gui.libraryTags.all": "All",
    "gui.libraryTags.animals": "Animals",
    "gui.libraryTags.dance": "Dance",
    "gui.libraryTags.effects": "Effects",
    "gui.libraryTags.fantasy": "Fantasy",
    "gui.libraryTags.fashion": "Fashion",
    "gui.libraryTags.food": "Food",
    "gui.libraryTags.indoors": "Indoors",
    "gui.libraryTags.loops": "Loops",
    "gui.libraryTags.music": "Music",
    "gui.libraryTags.notes": "Notes",
    "gui.libraryTags.outdoors": "Outdoors",
    "gui.libraryTags.patterns": "Patterns",
    "gui.libraryTags.people": "People",
    "gui.libraryTags.percussion": "Percussion",
    "gui.libraryTags.space": "Space",
    "gui.libraryTags.sports": "Sports",
    "gui.libraryTags.underwater": "Underwater",
    "gui.libraryTags.voice": "Voice",
    "gui.libraryTags.wacky": "Wacky",
    "gui.libraryTags.animation": "Animation",
    "gui.libraryTags.art": "Art",
    "gui.libraryTags.games": "Games",
    "gui.libraryTags.stories": "Stories",
    "gui.libraryTags.letters": "Letters",
    "gui.libraryTags.transport": "Transport",
    "gui.libraryTags.flags": "Flags",
    "gui.libraryTags.city": "City",
    "gui.libraryTags.plants": "Plants",
    "gui.libraryTags.game": "Game",
    "gui.libraryTags.others": "Others",
    "gui.opcodeLabels.direction": "direction",
    "gui.opcodeLabels.xposition": "x position",
    "gui.opcodeLabels.yposition": "y position",
    "gui.opcodeLabels.size": "size",
    "gui.opcodeLabels.costumename": "costume name",
    "gui.opcodeLabels.costumenumber": "costume number",
    "gui.opcodeLabels.backdropname": "backdrop name",
    "gui.opcodeLabels.backdropnumber": "backdrop number",
    "gui.opcodeLabels.mygetTempo": "tempo",
    "gui.opcodeLabels.sesSeviyesi": "sound level",
    "gui.opcodeLabels.sesSiddeti": "sound intensity",
    "gui.opcodeLabels.SallanmaSiddeti": "shaking intensity",
    "gui.opcodeLabels.pilSeviyesi": "battery level",
    "gui.opcodeLabels.egimAcisi": "tilt angle",
    "gui.opcodeLabels.yatisAcisi": "bank angle",
    "gui.opcodeLabels.volume": "volume",
    "gui.opcodeLabels.tempo": "tempo",
    "gui.opcodeLabels.answer": "answer",
    "gui.opcodeLabels.loudness": "loudness",
    "gui.opcodeLabels.username": "username",
    "gui.opcodeLabels.year": "year",
    "gui.opcodeLabels.month": "month",
    "gui.opcodeLabels.date": "date",
    "gui.opcodeLabels.dayofweek": "day of week",
    "gui.opcodeLabels.hour": "hour",
    "gui.opcodeLabels.minute": "minute",
    "gui.opcodeLabels.second": "second",
    "gui.opcodeLabels.timer": "timer",
    "gui.projectLoader.loadError": "The project file that was selected failed to load.",
    "gui.sharedMessages.backdrop": "backdrop{index}",
    "gui.sharedMessages.costume": "costume{index}",
    "gui.sharedMessages.sprite": "Sprite{index}",
    "gui.sharedMessages.pop": "pop",
    "gui.sharedMessages.replaceProjectWarning": "Replace contents of the current project?",
    "gui.sharedMessages.clearCacheWarning": "Make sure you want to clear cache and restart?",
    "gui.sharedMessages.loadFromComputerTitle": "Load from your computer",
    "gui.sharedMessages.openProjectsFromServer": "My Projects",
    "gui.gui.defaultProjectTitle": "ZTech Project",
    "arduinoEsp32.InterrupModeMenu.changeEdge": "change edge",
    "arduinoEsp32.InterrupModeMenu.fallingEdge": "falling edge",
    "arduinoEsp32.InterrupModeMenu.high": "high",
    "arduinoEsp32.InterrupModeMenu.low": "low",
    "arduinoEsp32.InterrupModeMenu.risingEdge": "rising edge",
    "arduinoEsp32.category.data": "Data",
    "arduinoEsp32.category.sensor": "Sensor",
    "arduinoEsp32.category.serial": "Serial",
    "arduinoEsp32.data.dataConstrain": "constrain [DATA] between ([ARG0], [ARG1])",
    "arduinoEsp32.data.dataConvert": "convert [DATA] to [TYPE]",
    "arduinoEsp32.data.dataConvertASCIICharacter": "convert [DATA] to ASCII character",
    "arduinoEsp32.data.dataConvertASCIINumber": "convert [DATA] to ASCII nubmer",
    "arduinoEsp32.data.dataMap": "map [DATA] from ([ARG0], [ARG1]) to ([ARG2], [ARG3])",
    "arduinoEsp32.dataTypeMenu.decimal": "decimal",
    "arduinoEsp32.dataTypeMenu.string": "string",
    "arduinoEsp32.dataTypeMenu.wholeNumber": "whole number",
    "arduinoEsp32.eolMenu.noWarp": "no-warp",
    "arduinoEsp32.eolMenu.warp": "warp",
    "arduinoEsp32.levelMenu.high": "high",
    "arduinoEsp32.levelMenu.low": "low",
    "arduinoEsp32.modeMenu.input": "input",
    "arduinoEsp32.modeMenu.inputPullup": "input-pullup",
    "arduinoEsp32.modeMenu.output": "output",
    "arduinoEsp32.pins.attachInterrupt": "attach interrupt pin [PIN] mode [MODE] executes",
    "arduinoEsp32.pins.detachInterrupt": "detach interrupt pin [PIN]",
    "arduinoEsp32.pins.esp32ReadTouchPin": "read touch pin [PIN]",
    "arduinoEsp32.pins.readAnalogPin": "read analog pin [PIN]",
    "arduinoEsp32.pins.readDigitalPin": "read digital pin [PIN]",
    "arduinoEsp32.pins.setServoOutput": "set servo pin [PIN] use channel [CH] out [OUT]",
    "arduinoEsp32.sensor.readHallSensor": "read hall sensor",
    "arduinoEsp32.sensor.runningTime": "running time (millis)",
    "arduinoEsp32.serial.multiSerialAvailable": "serial [NO] available data length",
    "arduinoEsp32.serial.multiSerialBegin": "serial [NO] begin baudrate [VALUE]",
    "arduinoEsp32.serial.multiSerialPrint": "serial [NO] print [VALUE] [EOL]",
    "arduinoEsp32.serial.multiSerialReadAByte": "serial [NO] read a byte",
    "arduinoLeonardo.category.data": "Data",
    "arduinoLeonardo.category.pins": "Pins",
    "arduinoLeonardo.data.dataConstrain": "constrain [DATA] between ([ARG0], [ARG1])",
    "arduinoLeonardo.data.dataConvert": "convert [DATA] to [TYPE]",
    "arduinoLeonardo.data.dataConvertASCIICharacter": "convert [DATA] to ASCII character",
    "arduinoLeonardo.data.dataConvertASCIINumber": "convert [DATA] to ASCII nubmer",
    "arduinoLeonardo.data.dataMap": "map [DATA] from ([ARG0], [ARG1]) to ([ARG2], [ARG3])",
    "arduinoLeonardo.dataTypeMenu.decimal": "decimal",
    "arduinoLeonardo.dataTypeMenu.string": "string",
    "arduinoLeonardo.dataTypeMenu.wholeNumber": "whole number",
    "arduinoLeonardo.levelMenu.high": "high",
    "arduinoLeonardo.levelMenu.low": "low",
    "arduinoLeonardo.modeMenu.input": "input",
    "arduinoLeonardo.modeMenu.inputPullup": "input-pullup",
    "arduinoLeonardo.modeMenu.output": "output",
    "arduinoLeonardo.pins.attachInterrupt": "attach interrupt pin [PIN] mode [MODE] executes",
    "arduinoLeonardo.pins.detachInterrupt": "detach interrupt pin [PIN]",
    "arduinoLeonardo.pins.readAnalogPin": "read analog pin [PIN]",
    "arduinoLeonardo.pins.readDigitalPin": "read digital pin [PIN]",
    "arduinoLeonardo.pins.setDigitalOutput": "set digital pin [PIN] out [LEVEL]",
    "arduinoLeonardo.pins.setPinMode": "set pin [PIN] mode [MODE]",
    "arduinoLeonardo.pins.setPwmOutput": "set pwm pin [PIN] out [OUT]",
    "arduinoLeonardo.pins.setServoOutput": "set servo pin [PIN] out [OUT]",
    "arduinoMega2560.category.data": "Data",
    "arduinoMega2560.category.pins": "Pins",
    "arduinoMega2560.category.serial": "Serial",
    "arduinoMega2560.data.dataConstrain": "constrain [DATA] between ([ARG0], [ARG1])",
    "arduinoMega2560.data.dataConvert": "convert [DATA] to [TYPE]",
    "arduinoMega2560.data.dataConvertASCIICharacter": "convert [DATA] to ASCII character",
    "arduinoMega2560.data.dataConvertASCIINumber": "convert [DATA] to ASCII nubmer",
    "arduinoMega2560.data.dataMap": "map [DATA] from ([ARG0], [ARG1]) to ([ARG2], [ARG3])",
    "arduinoMega2560.dataTypeMenu.decimal": "decimal",
    "arduinoMega2560.dataTypeMenu.string": "string",
    "arduinoMega2560.dataTypeMenu.wholeNumber": "whole number",
    "arduinoMega2560.levelMenu.high": "high",
    "arduinoMega2560.levelMenu.low": "low",
    "arduinoMega2560.modeMenu.input": "input",
    "arduinoMega2560.modeMenu.inputPullup": "input-pullup",
    "arduinoMega2560.modeMenu.output": "output",
    "arduinoMega2560.pins.attachInterrupt": "attach interrupt pin [PIN] mode [MODE] executes",
    "arduinoMega2560.pins.detachInterrupt": "detach interrupt pin [PIN]",
    "arduinoMega2560.pins.readAnalogPin": "read analog pin [PIN]",
    "arduinoMega2560.pins.readDigitalPin": "read digital pin [PIN]",
    "arduinoMega2560.pins.setDigitalOutput": "set digital pin [PIN] out [LEVEL]",
    "arduinoMega2560.pins.setPinMode": "set pin [PIN] mode [MODE]",
    "arduinoMega2560.pins.setPwmOutput": "set pwm pin [PIN] out [OUT]",
    "arduinoMega2560.pins.setServoOutput": "set servo pin [PIN] out [OUT]",
    "arduinoMega2560.serial.multiSerialAvailable": "serial [NO] available data length",
    "arduinoMega2560.serial.multiSerialBegin": "serial [NO] begin baudrate [VALUE]",
    "arduinoMega2560.serial.multiSerialPrint": "serial [NO] print [VALUE] [EOL]",
    "arduinoMega2560.serial.multiSerialReadAByte": "serial [NO] read a byte",
    "arduinoPeripheral.connection.connectFirmataTimeout": "Timeout when try to connect firmata, please download the firmware first",
    "arduinoUno.InterrupModeMenu.changeEdge": "change edge",
    "arduinoUno.InterrupModeMenu.fallingEdge": "falling edge",
    "arduinoUno.InterrupModeMenu.low": "low",
    "arduinoUno.InterrupModeMenu.risingEdge": "rising edge",
    "arduinoUno.category.data": "Data",
    "arduinoUno.category.pins": "Pins",
    "arduinoUno.category.serial": "Serial",
    "arduinoUno.data.dataConstrain": "constrain [DATA] between ([ARG0], [ARG1])",
    "arduinoUno.data.dataConvert": "convert [DATA] to [TYPE]",
    "arduinoUno.data.dataConvertASCIICharacter": "convert [DATA] to ASCII character",
    "arduinoUno.data.dataConvertASCIINumber": "convert [DATA] to ASCII nubmer",
    "arduinoUno.data.dataMap": "map [DATA] from ([ARG0], [ARG1]) to ([ARG2], [ARG3])",
    "arduinoUno.dataTypeMenu.decimal": "decimal",
    "arduinoUno.dataTypeMenu.string": "string",
    "arduinoUno.dataTypeMenu.wholeNumber": "whole number",
    "arduinoUno.eolMenu.noWarp": "no-warp",
    "arduinoUno.eolMenu.warp": "warp",
    "arduinoUno.levelMenu.high": "high",
    "arduinoUno.levelMenu.low": "low",
    "arduinoUno.modeMenu.input": "input",
    "arduinoUno.modeMenu.inputPullup": "input-pullup",
    "arduinoUno.modeMenu.output": "output",
    "arduinoUno.pins.attachInterrupt": "attach interrupt pin [PIN] mode [MODE] executes",
    "arduinoUno.pins.detachInterrupt": "detach interrupt pin [PIN]",
    "arduinoUno.pins.readAnalogPin": "read analog pin [PIN]",
    "arduinoUno.pins.readDigitalPin": "read digital pin [PIN]",
    "arduinoUno.pins.setDigitalOutput": "set digital pin [PIN] out [LEVEL]",
    "arduinoUno.pins.setPinMode": "set pin [PIN] mode [MODE]",
    "arduinoUno.pins.setPwmOutput": "set pwm pin [PIN] out [OUT]",
    "arduinoUno.pins.setServoOutput": "set servo pin [PIN] out [OUT]",
    "arduinoUno.serial.serialAvailable": "serial available data length",
    "arduinoUno.serial.serialBegin": "serial begin baudrate [VALUE]",
    "arduinoUno.serial.serialPrint": "serial print [VALUE] [EOL]",
    "arduinoUno.serial.serialReadData": "serial read data",
    "boost.color.any": "any color",
    "boost.color.black": "black",
    "boost.color.blue": "blue",
    "boost.color.green": "green",
    "boost.color.red": "red",
    "boost.color.white": "white",
    "boost.color.yellow": "yellow",
    "boost.getMotorPosition": "motor [MOTOR_REPORTER_ID] position",
    "boost.getTiltAngle": "tilt angle [TILT_DIRECTION]",
    "boost.motorDirection.backward": "that way",
    "boost.motorDirection.forward": "this way",
    "boost.motorDirection.reverse": "reverse",
    "boost.motorOff": "turn motor [MOTOR_ID] off",
    "boost.motorOn": "turn motor [MOTOR_ID] on",
    "boost.motorOnFor": "turn motor [MOTOR_ID] for [DURATION] seconds",
    "boost.motorOnForRotation": "turn motor [MOTOR_ID] for [ROTATION] rotations",
    "boost.seeingColor": "seeing [COLOR] brick?",
    "boost.setLightHue": "set light color to [HUE]",
    "boost.setMotorDirection": "set motor [MOTOR_ID] direction [MOTOR_DIRECTION]",
    "boost.setMotorPower": "set motor [MOTOR_ID] speed to [POWER] %",
    "boost.tiltDirection.any": "any",
    "boost.tiltDirection.down": "down",
    "boost.tiltDirection.left": "left",
    "boost.tiltDirection.right": "right",
    "boost.tiltDirection.up": "up",
    "boost.whenColor": "when [COLOR] brick seen",
    "boost.whenTilted": "when tilted [TILT_DIRECTION_ANY]",
    "esp32Arduino.category.pins": "Pins",
    "esp32Arduino.pins.esp32SetDACOutput": "set dac pin [PIN] out [OUT]",
    "esp32Arduino.pins.esp32SetPwmOutput": "set pwm pin [PIN] use channel [CH] out [OUT]",
    "esp32Arduino.pins.setDigitalOutput": "set digital pin [PIN] out [LEVEL]",
    "esp32Arduino.pins.setPinMode": "set pin [PIN] mode [MODE]",
    "ev3.beepNote": "beep note [NOTE] for [TIME] secs",
    "ev3.buttonPressed": "button [PORT] pressed?",
    "ev3.getBrightness": "brightness",
    "ev3.getDistance": "distance",
    "ev3.getMotorPosition": "motor [PORT] position",
    "ev3.motorSetPower": "motor [PORT] set power [POWER] %",
    "ev3.motorTurnClockwise": "motor [PORT] turn this way for [TIME] seconds",
    "ev3.motorTurnCounterClockwise": "motor [PORT] turn that way for [TIME] seconds",
    "ev3.whenBrightnessLessThan": "when brightness < [DISTANCE]",
    "ev3.whenButtonPressed": "when button [PORT] pressed",
    "ev3.whenDistanceLessThan": "when distance < [DISTANCE]",
    "gdxfor.getAcceleration": "acceleration [DIRECTION]",
    "gdxfor.getForce": "force",
    "gdxfor.getSpin": "spin speed [DIRECTION]",
    "gdxfor.getTilt": "tilt angle [TILT]",
    "gdxfor.isFreeFalling": "falling?",
    "gdxfor.isTilted": "tilted [TILT]?",
    "gdxfor.pulled": "pulled",
    "gdxfor.pushed": "pushed",
    "gdxfor.shaken": "shaken",
    "gdxfor.startedFalling": "started falling",
    "gdxfor.tiltDirectionMenu.any": "any",
    "gdxfor.tiltDirectionMenu.back": "back",
    "gdxfor.tiltDirectionMenu.front": "front",
    "gdxfor.tiltDirectionMenu.left": "left",
    "gdxfor.tiltDirectionMenu.right": "right",
    "gdxfor.turnedFaceDown": "turned face down",
    "gdxfor.turnedFaceUp": "turned face up",
    "gdxfor.whenForcePushedOrPulled": "when force sensor [PUSH_PULL]",
    "gdxfor.whenGesture": "when [GESTURE]",
    "gdxfor.whenTilted": "when tilted [TILT]",
    "makeymakey.downArrow": "down arrow",
    "makeymakey.downArrowShort": "down",
    "makeymakey.leftArrow": "left arrow",
    "makeymakey.leftArrowShort": "left",
    "makeymakey.rightArrow": "right arrow",
    "makeymakey.rightArrowShort": "right",
    "makeymakey.spaceKey": "space",
    "makeymakey.upArrow": "up arrow",
    "makeymakey.upArrowShort": "up",
    "makeymakey.whenKeyPressed": "when [KEY] key pressed",
    "makeymakey.whenKeysPressedInOrder": "when [SEQUENCE] pressed in order",
    "microbit.axisMenu.xAxis": "x-axis",
    "microbit.axisMenu.yAxis": "y-axis",
    "microbit.axisMenu.zAxis": "z-axis",
    "microbit.buttonsMenu.any": "any",
    "microbit.category.console": "Console",
    "microbit.category.display": "Display",
    "microbit.category.pins": "Pins",
    "microbit.category.sensor": "Sensor",
    "microbit.category.wireless": "Wireless",
    "microbit.clearDisplay": "clear display",
    "microbit.console.consolePrint": "print [TEXT]",
    "microbit.defaultTextToDisplay": "Hello!",
    "microbit.display.clearDisplay": "clear screen",
    "microbit.display.lightPixelAt": "light [STATE] at the x: [X] axis, y: [Y] axis",
    "microbit.display.show": "show [TEXT]",
    "microbit.display.showImage": "show image [VALUE]",
    "microbit.display.showImageUntil": "show image [VALUE] for [TIME] secs",
    "microbit.display.showOnPiexlbrightness": "show on the x: [X] axis, y: [Y] axis with brightness [BRT]",
    "microbit.display.showUntilScrollDone": "show [TEXT] until scroll done",
    "microbit.displaySymbol": "display [MATRIX]",
    "microbit.displayText": "display text [TEXT]",
    "microbit.gestruesMenu.faceDown": "face down",
    "microbit.gestruesMenu.faceUp": "face up",
    "microbit.gestruesMenu.freefall": "freefall",
    "microbit.gestruesMenu.shaken": "shaken",
    "microbit.gestruesMenu.tiltedDownward": "tilted downward",
    "microbit.gestruesMenu.tiltedLeftward": "tilted leftward",
    "microbit.gestruesMenu.tiltedRightward": "tilted rightward",
    "microbit.gestruesMenu.tiltedUpward": "tilted upward",
    "microbit.gesturesMenu.jumped": "jumped",
    "microbit.gesturesMenu.moved": "moved",
    "microbit.gesturesMenu.shaken": "shaken",
    "microbit.isButtonPressed": "[BTN] button pressed?",
    "microbit.isTilted": "tilted [DIRECTION]?",
    "microbit.ledState.off": "off",
    "microbit.ledState.on": "on",
    "microbit.levelMenu.high": "high",
    "microbit.levelMenu.low": "low",
    "microbit.pinStateMenu.off": "off",
    "microbit.pinStateMenu.on": "on",
    "microbit.pins.pinIsTouched": "pin [PIN] is touched",
    "microbit.pins.readAnalogPin": "read analog pin [PIN]",
    "microbit.pins.readDigitalPin": "read digital pin [PIN]",
    "microbit.pins.setDigitalOutput": "set digital pin [PIN] out [LEVEL]",
    "microbit.pins.setPwmOutput": "set pwm pin [PIN] out [OUT]",
    "microbit.sensor.axisAcceleration": "[AXIS] axis acceleration",
    "microbit.sensor.buttonIsPressed": "[KEY] button is pressed?",
    "microbit.sensor.calibrateCompass": "calibrate compass",
    "microbit.sensor.compassAngle": "compass angle",
    "microbit.sensor.compassMagneticDensity": "compass magnetic density",
    "microbit.sensor.gestureIsX": "gestrue is [STA]?",
    "microbit.sensor.lightLevel": "light level",
    "microbit.sensor.runningTime": "running time",
    "microbit.sensor.temperature": "temperature",
    "microbit.tiltAngle": "tilt angle [DIRECTION]",
    "microbit.tiltDirectionMenu.any": "any",
    "microbit.tiltDirectionMenu.back": "back",
    "microbit.tiltDirectionMenu.front": "front",
    "microbit.tiltDirectionMenu.left": "left",
    "microbit.tiltDirectionMenu.right": "right",
    "microbit.whenButtonPressed": "when [BTN] button pressed",
    "microbit.whenGesture": "when [GESTURE]",
    "microbit.whenPinConnected": "when pin [PIN] connected",
    "microbit.whenTilted": "when tilted [DIRECTION]",
    "microbit.wireless.closeWirelessCommunication": "close wireless communication",
    "microbit.wireless.openWirelessCommunication": "open wireless communication",
    "microbit.wireless.receiveWirelessMessage": "receive wireless message",
    "microbit.wireless.resetWirelessCommunication": "reset wireless communication",
    "microbit.wireless.sendWirelessMessage": "send wireless message [TEXT]",
    "microbit.wireless.setWirelessCommunicationChannel": "set wireless communication channel as [CH]",
    "music.categoryName": "Music",
    "music.changeTempo": "change tempo by [TEMPO]",
    "music.drumBass": "(2) Bass Drum",
    "music.drumBongo": "(13) Bongo",
    "music.drumCabasa": "(15) Cabasa",
    "music.drumClaves": "(9) Claves",
    "music.drumClosedHiHat": "(6) Closed Hi-Hat",
    "music.drumConga": "(14) Conga",
    "music.drumCowbell": "(11) Cowbell",
    "music.drumCrashCymbal": "(4) Crash Cymbal",
    "music.drumCuica": "(18) Cuica",
    "music.drumGuiro": "(16) Guiro",
    "music.drumHandClap": "(8) Hand Clap",
    "music.drumOpenHiHat": "(5) Open Hi-Hat",
    "music.drumSideStick": "(3) Side Stick",
    "music.drumSnare": "(1) Snare Drum",
    "music.drumTambourine": "(7) Tambourine",
    "music.drumTriangle": "(12) Triangle",
    "music.drumVibraslap": "(17) Vibraslap",
    "music.drumWoodBlock": "(10) Wood Block",
    "music.getTempo": "tempo",
    "music.instrumentBass": "(6) Bass",
    "music.instrumentBassoon": "(14) Bassoon",
    "music.instrumentCello": "(8) Cello",
    "music.instrumentChoir": "(15) Choir",
    "music.instrumentClarinet": "(10) Clarinet",
    "music.instrumentElectricGuitar": "(5) Electric Guitar",
    "music.instrumentElectricPiano": "(2) Electric Piano",
    "music.instrumentFlute": "(12) Flute",
    "music.instrumentGuitar": "(4) Guitar",
    "music.instrumentMarimba": "(19) Marimba",
    "music.instrumentMusicBox": "(17) Music Box",
    "music.instrumentOrgan": "(3) Organ",
    "music.instrumentPiano": "(1) Piano",
    "music.instrumentPizzicato": "(7) Pizzicato",
    "music.instrumentSaxophone": "(11) Saxophone",
    "music.instrumentSteelDrum": "(18) Steel Drum",
    "music.instrumentSynthLead": "(20) Synth Lead",
    "music.instrumentSynthPad": "(21) Synth Pad",
    "music.instrumentTrombone": "(9) Trombone",
    "music.instrumentVibraphone": "(16) Vibraphone",
    "music.instrumentWoodenFlute": "(13) Wooden Flute",
    "music.midiPlayDrumForBeats": "play drum [DRUM] for [BEATS] beats",
    "music.midiSetInstrument": "set instrument to [INSTRUMENT]",
    "music.playDrumForBeats": "play drum [DRUM] for [BEATS] beats",
    "music.playNoteForBeats": "play note [NOTE] for [BEATS] beats",
    "music.restForBeats": "rest for [BEATS] beats",
    "music.setInstrument": "set instrument to [INSTRUMENT]",
    "music.setTempo": "set tempo to [TEMPO]",
    "pen.categoryName": "Pen",
    "pen.changeColorParam": "change pen [COLOR_PARAM] by [VALUE]",
    "pen.changeHue": "change pen color by [HUE]",
    "pen.changeShade": "change pen shade by [SHADE]",
    "pen.changeSize": "change pen size by [SIZE]",
    "pen.clear": "erase all",
    "pen.colorMenu.brightness": "brightness",
    "pen.colorMenu.color": "color",
    "pen.colorMenu.saturation": "saturation",
    "pen.colorMenu.transparency": "transparency",
    "pen.penDown": "pen down",
    "pen.penUp": "pen up",
    "pen.setColor": "set pen color to [COLOR]",
    "pen.setColorParam": "set pen [COLOR_PARAM] to [VALUE]",
    "pen.setHue": "set pen color to [HUE]",
    "pen.setShade": "set pen shade to [SHADE]",
    "pen.setSize": "set pen size to [SIZE]",
    "pen.stamp": "stamp",
    "speech.defaultWhenIHearValue": "let''s go",
    "speech.extensionName": "Speech to Text",
    "speech.listenAndWait": "listen and wait",
    "speech.speechReporter": "speech",
    "speech.whenIHear": "when I hear [PHRASE]",
    "text2speech.alto": "alto",
    "text2speech.categoryName": "Text to Speech",
    "text2speech.defaultTextToSpeak": "hello",
    "text2speech.giant": "giant",
    "text2speech.kitten": "kitten",
    "text2speech.setLanguageBlock": "set language to [LANGUAGE]",
    "text2speech.setVoiceBlock": "set voice to [VOICE]",
    "text2speech.speakAndWaitBlock": "speak [WORDS]",
    "text2speech.squeak": "squeak",
    "text2speech.tenor": "tenor",
    "translate.categoryName": "Translate",
    "translate.defaultTextToTranslate": "hello",
    "translate.translateBlock": "translate [WORDS] to [LANGUAGE]",
    "translate.viewerLanguage": "language",
    "videoSensing.categoryName": "Video Sensing",
    "videoSensing.direction": "direction",
    "videoSensing.motion": "motion",
    "videoSensing.off": "off",
    "videoSensing.on": "on",
    "videoSensing.onFlipped": "on flipped",
    "videoSensing.setVideoTransparency": "set video transparency to [TRANSPARENCY]",
    "videoSensing.sprite": "sprite",
    "videoSensing.stage": "stage",
    "videoSensing.videoOn": "video [ATTRIBUTE] on [SUBJECT]",
    "videoSensing.videoToggle": "turn video [VIDEO_STATE]",
    "videoSensing.whenMotionGreaterThan": "when video motion > [REFERENCE]",
    "wedo2.getDistance": "distance",
    "wedo2.getTiltAngle": "tilt angle [TILT_DIRECTION]",
    "wedo2.isTilted": "tilted [TILT_DIRECTION_ANY]?",
    "wedo2.motorDirection.backward": "that way",
    "wedo2.motorDirection.forward": "this way",
    "wedo2.motorDirection.reverse": "reverse",
    "wedo2.motorId.a": "motor A",
    "wedo2.motorId.all": "all motors",
    "wedo2.motorId.b": "motor B",
    "wedo2.motorId.default": "motor",
    "wedo2.motorOff": "turn [MOTOR_ID] off",
    "wedo2.motorOn": "turn [MOTOR_ID] on",
    "wedo2.motorOnFor": "turn [MOTOR_ID] on for [DURATION] seconds",
    "wedo2.playNoteFor": "play note [NOTE] for [DURATION] seconds",
    "wedo2.setLightHue": "set light color to [HUE]",
    "wedo2.setMotorDirection": "set [MOTOR_ID] direction to [MOTOR_DIRECTION]",
    "wedo2.startMotorPower": "set [MOTOR_ID] power to [POWER]",
    "wedo2.tiltDirection.any": "any",
    "wedo2.tiltDirection.down": "down",
    "wedo2.tiltDirection.left": "left",
    "wedo2.tiltDirection.right": "right",
    "wedo2.tiltDirection.up": "up",
    "wedo2.whenDistance": "when distance [OP] [REFERENCE]",
    "wedo2.whenTilted": "when tilted [TILT_DIRECTION_ANY]",
    "paint.colorPicker.swap": "Swap",
    "paint.paintEditor.hue": "Color",
    "paint.paintEditor.saturation": "Saturation",
    "paint.paintEditor.brightness": "Brightness",
    "paint.paintEditor.costume": "Costume",
    "paint.paintEditor.group": "Group",
    "paint.paintEditor.ungroup": "Ungroup",
    "paint.paintEditor.undo": "Undo",
    "paint.paintEditor.redo": "Redo",
    "paint.paintEditor.forward": "Forward",
    "paint.paintEditor.backward": "Backward",
    "paint.paintEditor.front": "Front",
    "paint.paintEditor.back": "Back",
    "paint.paintEditor.more": "More",
    "paint.modeTools.brushSize": "Size",
    "paint.modeTools.eraserSize": "Eraser size",
    "paint.modeTools.copy": "Copy",
    "paint.modeTools.paste": "Paste",
    "paint.modeTools.delete": "Delete",
    "paint.modeTools.curved": "Curved",
    "paint.modeTools.pointed": "Pointed",
    "paint.modeTools.thickness": "Thickness",
    "paint.modeTools.flipHorizontal": "Flip Horizontal",
    "paint.modeTools.flipVertical": "Flip Vertical",
    "paint.modeTools.filled": "Filled",
    "paint.modeTools.outlined": "Outlined",
    "paint.paintEditor.bitmap": "Convert to Bitmap",
    "paint.paintEditor.vector": "Convert to Vector",
    "paint.paintEditor.fill": "Fill",
    "paint.paintEditor.stroke": "Outline",
    "paint.brushMode.brush": "Brush",
    "paint.eraserMode.eraser": "Eraser",
    "paint.fillMode.fill": "Fill",
    "paint.lineMode.line": "Line",
    "paint.ovalMode.oval": "Circle",
    "paint.rectMode.rect": "Rectangle",
    "paint.reshapeMode.reshape": "Reshape",
    "paint.roundedRectMode.roundedRect": "Rounded Rectangle",
    "paint.selectMode.select": "Select",
    "paint.textMode.text": "Text",
	  "gui.alerts.codeEditorIsLocked": "Code editor is in lock state, if you want to edit the code, please unlock it first",
	  "gui.alerts.codeEditorIsUnlocked": "Code editor is in unlock state, if you want to generate code from block, please lock it first",
 


    "gui.loadingPage.horizontalBtn": "Symbol Blocks",
    "gui.loadingPage.verticalBtn": "Word Blocks",
    "gui.game.congratulations": "Congratulations",
    "gui.game.tryAgain": "Try Again",
    "gui.game.successDesc": "You Have Successfully Completed This Level",
    "gui.game.errorDesc": "You Must Replay This Level",
    "gui.game.modal.title": "Map Menu",
    "gui.game.modal.mainGame": "Empty Map",
    "gui.game.modal.kidsBeginner": "Beginners",
    "gui.game.modal.kidsIntermediate": "Intermediate",
    "gui.game.modal.kidsColors": "Colors",
    "gui.game.modal.kidsShapes": "Shapes",
    "gui.game.modal.kidsAnimals": "Animals",
    "gui.game.modal.kidsSpace": "Space",
    "gui.game.modal.ilerlemePortal": "Portal",
    "gui.game.modal.ilerlemeMuzik": "Music",
    "gui.game.modal.up": "Up",
    "gui.game.modal.down": "Down",
    "gui.game.modal.forward": "Forward",
    "gui.game.modal.backward": "Backward",

  },
  "tr": {
    "gui.menuBar.uploadBilgisayarsızKodlama": "Bilgisayarsız Kodlama",
    "gui.alerts.lostPeripheralConnection": "ZTech ile bağlantı kesildi {extensionName}.",
    "gui.alerts.tryAgain": "Tekrar deneyin",
    "gui.alerts.download": "İndir",
    "gui.connection.reconnect": "yeniden bağlanın",
    "gui.alerts.downloadFirmware": "firmware indirin",
    "gui.backpack.costumeLabel": "kostüm",
    "gui.backpack.soundLabel": "ses",
    "gui.backpack.scriptLabel": "script",
    "gui.backpack.spriteLabel": "kukla",
    "gui.backpack.header": "Sırt çantası",
    "gui.backpack.errorBackpack": "Sırt çantası yüklenirken hata oluştu",
    "gui.backpack.loadingBackpack": "Yükleniyor...",
    "gui.backpack.more": "Daha",
    "gui.backpack.emptyBackpack": "Sırt çantası boş",
    "gui.unsupportedBrowser.label": "Tarayıcı desteklenmiyor",
    "gui.unsupportedBrowser.errorLabel": "Bir hata oluştu",
    "gui.unsupportedBrowser.notRecommended": "Çok üzgünüz, ancak ZTech'un desteklemediği bir tarayıcı sürümü kullanıyorsunuz. Google Chrome, Mozilla Firefox, Microsoft Edge veya Apple Safari gibi desteklenen bir tarayıcının en son sürümüne güncelleme yapmanızı öneririz.",
    "gui.unsupportedBrowser.description": "Çok üzgünüz, ancak ZTech bu tarayıcıyı desteklemiyor. Google Chrome, Mozilla Firefox, Microsoft Edge veya Apple Safari gibi desteklenen bir tarayıcının en son sürümüne güncelleme yapmanızı öneririz.",
    "gui.unsupportedBrowser.back": "Geri",
    "gui.unsupportedBrowser.previewfaq": "Daha fazla bilgi edinmek için şuraya gidin: {previewFaqLink}.",
    "gui.unsupportedBrowser.previewfaqlinktext": "SSS",
    "gui.cards.all-tutorials": "Öğreticiler",
    "gui.cards.shrink": "Çek",
    "gui.cards.expand": "Genişlet",
    "gui.cards.close": "Kapat",
    "gui.cards.more-things-to-try": "Denemek için daha fazla şey!",
    "gui.cards.see-more": "Daha fazla gör",
    "gui.comingSoon.message1": "Endişelenme, üzerindeyiz {emoji}",
    "gui.comingSoon.message2": "Çok yakında...",
    "gui.comingSoon.message3": "Üzerinde çalışıyoruz {emoji}",
    "gui.connection.auto-scanning.noPeripheralsFound": "Hiçbir cihaz bulunamadı",
    "gui.connection.auto-scanning.prescan": "Cihazınızı yakınınızda bulundurun, ardından aramaya başlayın.",
    "gui.connection.auto-scanning.pressbutton": "Cihazınızdaki düğmeye basın.",
    "gui.connection.auto-scanning.start-search": "Aramaya Başla",
    "gui.connection.connecting-searchbutton": "Aranıyor...",
    "gui.connection.auto-scanning.try-again": "Tekrar deneyin",
    "gui.connection.connectedSerial": "USB Bağlı",
    "gui.connection.connectedBle": "Bluetooth Bağlı",
    "gui.connection.disconnect": "Bağlantıyı kes",
    "gui.connection.go-to-editor": "Editör'e git",
    "gui.connection.connecting-cancelbutton": "Bağlanıyor...",
    "gui.connection.error.errorMessage": "Hata, bir şeyler ters gitmiş gibi görünüyor.",
    "gui.connection.error.tryagainbutton": "Tekrar deneyin",
    "gui.connection.error.helpbutton": "Yardım",
    "gui.connection.peripheral-name-label": "Cihaz adı",
    "gui.connection.connect": "Bağlan",
    "gui.connection.scanning.listAll": "Tüm bağlanabilir cihazları göster",
    "gui.connection.scanning.lookingforperipherals": "cihazlar aranıyor",
    "gui.connection.scanning.noPeripheralsFound": "Hiçbir cihaz bulunamadı",
    "gui.connection.scanning.instructions": "Yukarıdaki listeden cihazınızı seçin.",
    "gui.connection.search": "Yenile",
    "gui.connection.unavailable.installscratchlink": "ZTech Link'in kurulu ve çalışır durumda olduğundan emin olun",
    "gui.connection.unavailable.connectUsbEnableBluetooth": "USB kablosunun bağlı olduğunu kontrol edin",
    "gui.connection.unavailable.tryagainbutton": "Tekrar deneyin",
    "gui.connection.unavailable.helpbutton": "Yardım",
    "gui.controls.go": "Git",
    "gui.controls.stop": "Dur",
    "gui.crashMessage.label": "Hata! Bir şeyler yanlış gitti.",
    "gui.crashMessage.description": "Çok üzgünüz ama ZTech çökmüş gibi görünüyor. Bu hata otomatik olarak ZTech Ekibine bildirildi. Tekrar denemek için lütfen sayfanızı yenileyin.",
    "gui.crashMessage.errorNumber": "Hatanız id ile günlüğe kaydedildi {errorId}",
    "gui.crashMessage.reload": "Tekrar yükle",
    "gui.customProcedures.myblockModalTitle": "Blok Yap",
    "gui.customProcedures.addAnInputNumber": "Giriş ekle",
    "gui.customProcedures.numberType": "numara",
    "gui.customProcedures.addAnInputText": "Giriş ekle",
    "gui.customProcedures.TextType": "Metin",
    "gui.customProcedures.addAnInputBoolean": "Giriş ekle",
    "gui.customProcedures.booleanType": "boole",
    "gui.customProcedures.addALabel": "Etiket ekle",
    "gui.customProcedures.runWithoutScreenRefresh": "Ekran yenilemeden çalıştır",
    "gui.customProcedures.cancel": "İptal",
    "gui.customProcedures.ok": "Tamam",
    "gui.SpriteInfo.direction": "Yön",
    "gui.directionPicker.rotationStyles.allAround": "Her Yerde",
    "gui.directionPicker.rotationStyles.leftRight": "Sol/Sağ",
    "gui.directionPicker.rotationStyles.dontRotate": "döndürme",
    "gui.gui.addExtension": "Uzantı ekle",
    "gui.gui.codeTab": "Kod",
    "gui.gui.backdropsTab": "Arka planlar",
    "gui.gui.costumesTab": "Kostümler",
    "gui.gui.soundsTab": "Sesler",
    "gui.hardwareConsole.send": "Gönder",
    "gui.hardwareConsole.buadrate": "Buadrate",
    "gui.hardwareConsole.endOfLine": "Yolun sonu",
    "gui.hardwareConsole.hexform": "altıgen biçim",
    "gui.hardwareConsole.autoScroll": "Otomatik kaydırma",
    "gui.hardwareHeader.upload": "Yükle",
    "gui.stageHeader.codeStageSizeLarge": "Büyük sahneye geç",
    "gui.stageHeader.codeStageSizeSmall": "Küçük sahneye geç",
    "gui.stageHeader.hideStage": "Sahneyi gizle",
    "gui.extensionLibrary.comingSoon": "Çok yakında",
    "gui.extensionLibrary.requires": "Gereklilikler",
    "gui.extensionLibrary.collaboration": "İle işbirliği",
    "gui.extensionLibrary.manufactor": "üretici",
    "gui.extensionLibrary.version": "Sürüm",
    "gui.extensionLibrary.author": "Geliştirici",
    "gui.extensionLibrary.programMode": "Program modu",
    "gui.extensionLibrary.programLanguage": "Program dili",
    "gui.extensionLibrary.deviceLearnMore": "Daha fazla bilgi edin",
    "gui.extensionLibrary.extensionHelp": "Yardım",
    "gui.extensionLibrary.Processing": "İşlem...",
    "gui.extensionLibrary.loaded": "Yüklendi",
    "gui.extensionLibrary.notLoaded": "Yüklü değil",
    "gui.library.filterPlaceholder": "Arama",
    "gui.library.allTag": "Herşey",
    "gui.loader.message1": "Blok oluşturma …",
    "gui.loader.message2": "Sprite yükleniyor …",
    "gui.loader.message3": "Sesler yükleniyor …",
    "gui.loader.message4": "Uzantılar yükleniyor …",
    "gui.loader.message5": "gütme kediler …",
    "gui.loader.message6": "nanoları ilet …",
    "gui.loader.message7": "şişirme gobolar …",
    "gui.loader.message8": "Emoji hazırlama …",
    "gui.loader.headline": "Proje Yükleniyor",
    "gui.loader.creating": "Proje Oluşturma",
    "gui.accountMenu.profile": "Profil",
    "gui.accountMenu.myStuff": "benim eşyalarım",
    "gui.accountMenu.myClasses": "Sınıflarım",
    "gui.accountMenu.myClass": "Sınıfım",
    "gui.accountMenu.accountSettings": "Hesap ayarları",
    "gui.accountMenu.signOut": "oturumu Kapat",
    "gui.authorInfo.byUser": "{username}",
    "gui.menuBar.seeProjectPage": "Proje Sayfasına Bakın",
    "general.username": "Kullanıcı adı",
    "general.password": "Şifre",
    "general.signIn": "Kayıt olmak",
    "login.needHelp": "Yardıma mı ihtiyacınız var?",
    "form.validationRequired": "Bu alan gereklidir",
    "gui.menuBar.LanguageSelector": "dil seçici",
    "gui.menuBar.tutorialsLibrary": "öğreticiler",
    "gui.menuBar.restoreSprite": "Sprite'ı Geri Yükle",
    "gui.menuBar.restoreSound": "Sesi Geri Yükle",
    "gui.menuBar.restoreCostume": "Kostümü Geri Yükle",
    "gui.menuBar.restore": "Onarmak",
    "gui.menuBar.saveNow": "Şimdi kaydet",
    "gui.menuBar.saveAsCopy": "Kopya olarak kaydet",
    "gui.menuBar.remix": "Remix",
    "gui.menuBar.new": "Yeni",
    "gui.menuBar.checkUpdate": "Güncellemeyi kontrol et",
    "gui.menuBar.firmwareUpload": "Bellenim Yükle",
    "gui.menuBar.CalibarateDevice": "Cihaz Kalibre Et",
    "gui.menuBar.DownloadZconnect": "Zconnect İndir",
    "gui.menuBar.DeviceReset": "Cihazı Sıfırla",
    "gui.menuBar.installDriver": "Sürücüyü kur",
    "gui.menuBar.clearCacheAndRestart": "Önbelleği temizle ve yeniden başlat",
    "gui.menuBar.edit": "Düzenle",
    "gui.menuBar.turboModeOff": "Turbo Modu kapatın",
    "gui.menuBar.turboModeOn": "Turbo Modu açın",
    "gui.menuBar.noDeviceSelected": "Cihaz seçilmedi",
    "gui.menuBar.noConnection": "Bağlantısız",
    "gui.menuBar.file": "Dosya",
    "gui.menuBar.downloadToComputer": "Bilgisayarınıza kaydedin",
    "gui.menuBar.downloadFirmware": "Firmware İndirin",
    "gui.menu-bar.modeSwitchRealtime": "Canlı",
    "gui.menu-bar.modeSwitchRealtimeUpload": "Yükle",
    "gui.gui.projectTitlePlaceholder": "Proje başlığı burada",
    "gui.menuBar.saveNowLink": "Şimdi kaydet",
    "gui.menuBar.isShared": "Paylaşılan",
    "gui.menuBar.share": "Paylaş",
    "gui.modal.help": "Yardım",
    "gui.modal.back": "Geri",
    "gui.modal.delete": "Sil",
    "gui.monitor.listMonitor.empty": "(empty)",
    "gui.monitor.listMonitor.listLength": "uzunluk {length}",
    "gui.monitor.contextMenu.default": "normal okuma",
    "gui.monitor.contextMenu.large": "büyük okuma",
    "gui.monitor.contextMenu.slider": "kaydırıcı",
    "gui.monitor.contextMenu.sliderRange": "kaydırıcı aralığını değiştir",
    "gui.monitor.contextMenu.import": "içe aktar",
    "gui.monitor.contextMenu.export": "dışa aktar",
    "gui.playButton.play": "Oynat",
    "gui.playButton.stop": "Durdur",
    "gui.gui.variableScopeOptionAllSprites": "Bütün spriteslar için",
    "gui.gui.variableScopeOptionSpriteOnly": "Sadece bu sprite için",
    "gui.gui.cloudVariableOption": "Bulut değişkeni (stored on server)",
    "gui.gui.variablePromptAllSpritesMessage": "Bu değişken tüm sprite'lar için geçerli olacaktır.",
    "gui.prompt.cancel": "İptal",
    "gui.prompt.ok": "Tamam",
    "gui.playbackStep.stopMsg": "Durdur",
    "gui.playbackStep.playMsg": "Oynat",
    "gui.playbackStep.loadingMsg": "Yükleniyor...",
    "gui.playbackStep.saveMsg": "Kaydet",
    "gui.playbackStep.reRecordMsg": "yeniden kayıt",
    "gui.recordModal.title": "Kayıt sesi",
    "gui.recordingStep.beginRecord": "Aşağıdaki butona tıklayarak kayda başlayın",
    "gui.recordingStep.permission": "{arrow}Mikrofonunuzu kullanmak için izninize ihtiyacımız var",
    "gui.recordingStep.stop": "Kaydetmeyi bırak",
    "gui.recordingStep.record": "Kayıt",
    "gui.sliderModal.min": "Minimum değer",
    "gui.sliderModal.max": "Maksimum değer",
    "gui.sliderModal.title": "Kaydırıcı aralığını değiştir",
    "gui.sliderPrompt.cancel": "İptal",
    "gui.sliderPrompt.ok": "Tamam",
    "gui.soundEditor.sound": "Ses",
    "gui.soundEditor.play": "Oynat",
    "gui.soundEditor.stop": "Durdur",
    "gui.soundEditor.copy": "Kopyala",
    "gui.soundEditor.paste": "Yapıştır",
    "gui.soundEditor.copyToNew": "Yeniye Kopyala",
    "gui.soundEditor.delete": "Sil",
    "gui.soundEditor.save": "Kaydet",
    "gui.soundEditor.undo": "Geri alma",
    "gui.soundEditor.redo": "Yeniden yap",
    "gui.soundEditor.faster": "Daha hızlı",
    "gui.soundEditor.slower": "Yavaş",
    "gui.soundEditor.echo": "Eko",
    "gui.soundEditor.robot": "Robot",
    "gui.soundEditor.louder": "daha yüksek sesle",
    "gui.soundEditor.softer": "Daha yumuşak",
    "gui.soundEditor.reverse": "Ters",
    "gui.soundEditor.fadeOut": "karart",
    "gui.soundEditor.fadeIn": "yavaş yavaş",
    "gui.soundEditor.mute": "Sesi kapat",
    "gui.SpriteInfo.spritePlaceholder": "İsim",
    "gui.SpriteInfo.sprite": "Kukla",
    "gui.SpriteInfo.show": "Göster",
    "gui.SpriteInfo.size": "Boy",
    "gui.spriteSelectorItem.contextMenuDuplicate": "kopyala",
    "gui.spriteSelectorItem.contextMenuExport": "Dışa aktar",
    "gui.spriteSelectorItem.contextMenuDelete": "sil",
    "gui.spriteSelector.addSpriteFromLibrary": "Kukla seç",
    "gui.spriteSelector.addSpriteFromPaint": "Boya",
    "gui.spriteSelector.addSpriteFromSurprise": "Sürpriz",
    "gui.spriteSelector.addSpriteFromFile": "Sprite Yükle",
    "gui.stageHeader.stageSizeLarge": "Büyük sahneye geç",
    "gui.stageHeader.stageSizeSmall": "Küçük sahneye geç",
    "gui.stageHeader.stageSizeFull": "Tam ekran moduna girin",
    "gui.stageHeader.stageSizeUnFull": "Tam ekran modundan çık",
    "gui.stageHeader.fullscreenControl": "Tam Ekran Kontrolü",
    "gui.spriteSelector.addBackdropFromLibrary": "Bir Arka Plan Seçin",
    "gui.stageSelector.addBackdropFromPaint": "Boya",
    "gui.stageSelector.addBackdropFromSurprise": "Sürpriz",
    "gui.stageSelector.addBackdropFromFile": "Arka Planı Yükle",
    "gui.stageSelector.stage": "Sahne",
    "gui.stageSelector.backdrops": "Arka planlar",
    "gui.telemetryOptIn.label": "ZTech'u geliştirmek için istatistikleri raporlayın",
    "gui.telemetryOptIn.body1": "ZTech Ekibi, her zaman ZTech'un dünya çapında nasıl kullanıldığını daha iyi anlamaya çalışır. Bu çabayı desteklemek için ZTech'un kullanım bilgilerini ZTech Ekibine otomatik olarak göndermesine izin verebilirsiniz.",
    "gui.telemetryOptIn.body2": "Topladığımız bilgiler arasında dil seçimi, blok kullanımı ve proje kaydetme, yükleme ve karşıya yükleme gibi bazı olaylar yer alır. Herhangi bir kişisel bilgi TOPLAMIYORUZ. Daha fazla bilgi için lütfen {privacyPolicyLink} sayfamıza bakın.",
    "gui.telemetryOptIn.privacyPolicyLink": "Gizlilik Politikası",
    "gui.telemetryOptIn.optInText": "Kullanım verilerimi ZTech Ekibi ile paylaş",
    "gui.telemetryOptIn.optInTooltip": "Telemetriyi etkinleştir",
    "gui.telemetryOptIn.optOutText": "Kullanım verilerimi ZTech Ekibi ile paylaşma",
    "gui.telemetryOptIn.optOutTooltip": "Telemetriyi devre dışı bırak",
    "gui.telemetryOptIn.settingWasUpdated": "Ayarınız güncellendi.",
    "gui.telemetryOptIn.buttonClose": "Kapat",
    "gui.turboMode.active": "Turbo Modu",
    "gui.updateModel.tileUpdate": "Yeni harici kaynak sürümü algılandı",
    "gui.upgradeModal.upgradeLater": "Daha sonra yükselt",
    "gui.upgradeModal.upgradeAndRestart": "Yükselt ve yeniden başlat",
    "gui.updateModel.tileChecking": "Kontrol etme",
    "gui.updateModel.checkingTips": "Bu adım birkaç saniye sürecek, lütfen bekleyin.",
    "gui.upgradeModal.closeChecking": "Kapat",
    "gui.updateModel.tileAlreadyLatest": "Zaten en son",
    "gui.updateModel.alreadyLatestTips": "Harici kaynak zaten en yeni.",
    "gui.updateModel.upgrading": "Yükseltme",
    "gui.upgradeModal.downloading": "İndiriliyor",
    "gui.upgradeModal.covering": "kaplama",
    "gui.updateModel.tileError": "Operasyon başarısız",
    "gui.uploadProgress.uploading": "yükleniyor",
    "gui.uploadProgress.uploadSuccess": "Yükleme başarılı",
    "gui.uploadProgress.uploadError": "Yükleme hatası",
    "gui.uploadProgress.uploadTimeout": "Yükleme zaman aşımı",
    "gui.uploadProgress.close": "Kapat",
    "gui.webglModal.label": "Tarayıcınız WebGL'yi Desteklemiyor",
    "gui.webglModal.description": "Maalesef tarayıcınız veya bilgisayarınız {webGlLink} gibi görünüyor. ZTech'un çalışması için bu teknoloji gereklidir.",
    "gui.webglModal.webgllink": "WebGL'yi desteklemiyor",
    "gui.webglModal.back": "Geri",
    "gui.webglModal.previewfaq": "Daha fazla bilgi edinmek için {previewFaqLink} sayfasına gidin.",
    "gui.webglModal.previewfaqlinktext": "SSS",
    "gui.costumeLibrary.chooseABackdrop": "Bir Arka Plan Seçin",
    "gui.costumeLibrary.chooseACostume": "Bir Kostüm Seçin",
    "gui.costumeTab.addBackdropFromLibrary": "Bir Arka Plan Seçin",
    "gui.costumeTab.addCostumeFromLibrary": "Bir Kostüm Seçin",
    "gui.costumeTab.addBlankCostume": "Boya",
    "gui.costumeTab.addSurpriseCostume": "Sürpriz",
    "gui.costumeTab.addFileBackdrop": "Arka Planı Yükle",
    "gui.costumeTab.addFileCostume": "Kostüm Yükle",
    "gui.deviceLibrary.chooseADevice": "Bir Cihaz Seçin",
    "gui.deviceLibrary.deviceUrl": "Cihazın URL'sini girin",
    "gui.deviceLibrary.arduinoTag": "Arduino",
    "gui.deviceLibrary.microPythonTag": "MicroPython",
    "gui.deviceLibrary.kitTag": "Kit",
    "gui.extensionLibrary.chooseAnExtension": "Bir Uzantı Seçin",
    "gui.extensionLibrary.extensionUrl": "Uzantının URL'sini girin",
    "gui.library.shieldTag": "kalkan",
    "gui.library.actuatorTag": "aktüatör",
    "gui.library.sensorTag": "Sensör",
    "gui.library.displayTag": "Görüntü",
    "gui.library.communicationTag": "İletişim",
    "gui.library.otherTag": "Diğer",
    "gui.hardwareConsole.noLineTerminators": "Satır sonlandırıcı yok",
    "gui.hardwareConsole.lineFeed": "Satır besleme",
    "gui.hardwareConsole.carriageReturn": "Satırbaşı",
    "gui.hardwareConsole.lfAndCr": "LF & CR",
    "gui.monitors.importListColumnPrompt": "Hangi sütun kullanılmalıdır (1-{numberOfColumns})?",
    "gui.recordingStep.alertMsg": "Kayıt başlatılamadı",
    "gui.soundLibrary.chooseASound": "Bir Ses Seçin",
    "gui.soundTab.fileUploadSound": "Ses Yükle",
    "gui.soundTab.surpriseSound": "Sürpriz",
    "gui.soundTab.recordSound": "Kayıt",
    "gui.soundTab.addSoundFromLibrary": "Bir Ses Seçin",
    "gui.spriteLibrary.chooseASprite": "Bir Sprite Seçin",
    "gui.tipsLibrary.tutorials": "Bir Eğitim Seçin",
    "gui.updateModal.upgradeWarning": "Şu anda kaydedilmemiş projeler kaybolacak, yükseltmeye devam edilsin ve yeniden başlatılsın mı?",
    "gui.uploadProgress.uploadErrorMessage": "Yükleme hatası",
    "gui.uploadProgress.uploadTimeoutMessage": "Yükleme zaman aşımı",
    "gui.alerts.createsuccess": "Yeni proje oluşturuldu.",
    "gui.alerts.createcopysuccess": "Proje kopya olarak kaydedildi.",
    "gui.alerts.createremixsuccess": "Proje remix olarak kaydedildi.",
    "gui.alerts.creating": "Yeni oluşturma…",
    "gui.alerts.creatingCopy": "Proje kopyalanıyor…",
    "gui.alerts.creatingRemix": "Remiksleme projesi…",
    "gui.alerts.creatingError": "Proje oluşturulamadı. Lütfen tekrar deneyin!",
    "gui.alerts.savingError": "Proje kaydedilemedi.",
    "gui.alerts.savesuccess": "Proje kaydedildi.",
    "gui.alerts.saving": "Proje kaydediliyor…",
    "gui.alerts.cloudInfo": "Bulut değişkenlerinin yalnızca sayıları desteklediğini, harfleri veya simgeleri desteklemediğini lütfen unutmayın. {learnMoreLink}",
    "gui.alerts.cloudInfoLearnMore": "Daha fazla bilgi edin.",
    "gui.alerts.importing": "İçe aktarılıyor…",
    "gui.alerts.workspaceIsEmpty": "Çalışma alanı boş",
    "gui.alerts.workspaceIsNotEmpty": "Çalışma alanı boş değil, lütfen önce çalışma alanınızı temizleyin",
    "gui.alerts.uploadError": "Yükleme hatası",
    "gui.alerts.uploadSuccess": "Yükleme başarılı",
    "gui.alerts.selectADeviceFirst": "Lütfen önce bir cihaz seçin",
    "gui.alerts.connectAPeripheralFirst": "Lütfen önce bir çevre birimi bağlayın",
    "gui.defaultProject.chirp": "Hareket",
    "gui.defaultProject.variable": "benim değişkenim",
    "gui.howtos.intro-move-sayhello-hat.name": "Başlarken",
    "gui.howtos.intro-move.step_stepMove": "Hareket bloğu ekle",
    "gui.howtos.add-a-move-block.step_stepSay": "Söyle bloğu ekle",
    "gui.howtos.add-a-move-block.step_stepGreenFlag": "Başlamak için yeşil bayrağa tıklayın",
    "gui.howtos.animate-a-name.name": "Bir Adı Canlandır",
    "gui.howtos.animate-a-name.step_AnimatePickLetter": "Bir Harf Sprite Seçin",
    "gui.howtos.animate-a-name.step_AnimatePlaySound": "Tıklandığında Ses Çal",
    "gui.howtos.animate-a-name.step_AnimatePickLetter2": "Başka Bir Harf Sprite Seç",
    "gui.howtos.animate-a-name.step_AnimateChangeColor": "Rengi değiştir",
    "gui.howtos.animate-a-name.step_AnimateSpin": "Başka Bir Harf Sprite Seç ve Döndür",
    "gui.howtos.animate-a-name.step_AnimateGrow": "Başka Bir Harf Sprite Seçin ve Büyütün",
    "gui.howtos.imagine": "Bir Dünya Hayal Edin",
    "gui.howtos.imagine.step_imagineTypeWhatYouWant": "Söylemek İstediğinizi Yazın",
    "gui.howtos.imagine.step_imagineClickGreenFlag": "Başlamak için Yeşil Bayrağa tıklayın",
    "gui.howtos.imagine.step_imagineChooseBackdrop": "Herhangi Bir Zemin Seçin",
    "gui.howtos.imagine.step_imagineChooseSprite": "Herhangi Bir Sprite Seçin",
    "gui.howtos.imagine.step_imagineFlyAround": "Kaymak için Boşluk Tuşuna Basın",
    "gui.howtos.imagine.step_imagineChooseAnotherSprite": "Choose Another Sprite",
    "gui.howtos.imagine.step_imagineLeftRight": "Sol-Sağa Hareket Et",
    "gui.howtos.imagine.step_imagineUpDown": "Yukarı-Aşağı Taşı",
    "gui.howtos.imagine.step_imagineChangeCostumes": "Kostümleri Değiştir",
    "gui.howtos.imagine.step_imagineGlideToPoint": "Bir Noktaya Koy",
    "gui.howtos.imagine.step_imagineGrowShrink": "Büyü ve küçül",
    "gui.howtos.imagine.step_imagineChooseAnotherBackdrop": "Başka Bir Zemin Seçin",
    "gui.howtos.imagine.step_imagineSwitchBackdrops": "Arka Plan Değiştir",
    "gui.howtos.imagine.step_imagineRecordASound": "Ses Ekle",
    "gui.howtos.imagine.step_imagineChooseSound": "Sesinizi Seçin",
    "gui.howtos.make-music.name": "Müzik yap",
    "gui.howtos.Make-Music.step_PickInstrument": "Bir Enstrüman Sprite Seçin",
    "gui.howtos.Make-Music.step_PlaySoundClick": "Tıklandığında Ses Çal",
    "gui.howtos.Make-Music.step_MakeSong": "Şarkı Oluştur",
    "gui.howtos.make-music.step_MakeBeat": "Bir Davul Seçin ve Ritim Yapın",
    "gui.howtos.make-music.step_MakeBeatBox": "Mikrofon Sprite ve Sürpriz Beatbox'ı seçin",
    "gui.howtos.story.name": "Hikaye Oluştur",
    "gui.howtos.story.step_addbg": "Arka Plan Ekle",
    "gui.howtos.story.step_addsprite": "Karakter Ekle",
    "gui.howtos.story.step_saysomething": "Bir şey söyle",
    "gui.howtos.story.step_addanothersprite": "Başka Bir Karakter Ekle",
    "gui.howtos.story.step_flip": "Çevirme Yönü",
    "gui.howtos.story.step_conversation": "Görüşme yap",
    "gui.howtos.story.addanotherbg": "Başka Bir Arka Plan Ekle",
    "gui.howtos.story.step_swithbg": "Arka Plan Değiştir",
    "gui.howtos.story.step_hidewizard": "Karakter Gizle",
    "gui.howtos.story.step_showwizard": "Bir Karakter Göster",
    "gui.howtos.make-a-chase-game.name": "Kovalama Oyunu Yap",
    "gui.howtos.Chase-Game.step_BG": "Arka Plan Ekle",
    "gui.howtos.chase-game.step_AddOcto": "Bir Sprite Ekle",
    "gui.howtos.make-music.step_LeftRight": "Yön Tuşları ile Sağa ve Sola Hareket Edin",
    "gui.howtos.Chase-Game.step_UpDown": "Ok Tuşlarıyla Yukarı ve Aşağı Hareket Et",
    "gui.howtos.Chase-Game.step_AddStar": "Başka Bir Sprite Ekle",
    "gui.howtos.Chase-Game.step_MoveRandom": "Rastgele Hareket Et",
    "gui.howtos.Chase-Game.step_WhenTouch": "Octopus Sprite'da, Play Sound'a Dokunulduğunda",
    "gui.howtos.Chase-Game.step_ScoreVariable": "Puan Değişkeni Oluştur",
    "gui.howtos.Chase-Game.ScoreWhenTouch": "Octopus Sprite'da, Puan Ekle'ye dokunulduğunda",
    "gui.howtos.animate-char.name": "Bir Karakteri Canlandır",
    "gui.howtos.animate-char.step_addbg": "Arka Plan Ekle",
    "gui.howtos.animate-char.step_addsprite": "Bir Sprite Ekle",
    "gui.howtos.animate-char.step_saysomething": "Bir şey söyle",
    "gui.howtos.animate-char.step_addsound": "Ses Ekle",
    "gui.howtos.animate-char.step_animatetalking": "Konuşmayı Canlandır",
    "gui.howtos.animate-char.step_arrowkeys": "Ok Tuşlarını Kullanarak Hareket Etme",
    "gui.howtos.animate-char.step_jump": "Zıpla",
    "gui.howtos.animate-char.step_changecolor": "Rengi değiştir",
    "gui.howtos.make-a-game.name": "Tıklama Oyunu Yap",
    "gui.howtos.Make-A-Game.step_GamePickSprite": "Bir Sprite Seçin",
    "gui.howtos.make-a-game.step_GamePlaySound": "Tıklandığında Ses Çal",
    "gui.howtos.make-a-game.step_GameAddScore": "Puan Değişkeni Oluştur",
    "gui.howtos.make-a-game.step_GameChangeScore": "Tıklandığında Puanı Arttır",
    "gui.howtos.make-a-game.step_Random": "Rastgele bir pozisyona git",
    "gui.howtos.make-music.step_GameChangeColor": "Rengi değiştir",
    "gui.howtos.make-music.step_ResetScore": "Puanı Sıfırla",
    "gui.howtos.make-it-fly.name": "Uçmasını Sağla",
    "gui.howtos.fly.step_stepflyChooseBackdrop": "Bir Gökyüzü Arka Planı Seçin",
    "gui.howtos.add-a-move-block.step_stepflyChooseCharacter": "Bir Karakter Seçin",
    "gui.howtos.fly.step_stepflySaySomething": "Bir şey söyle",
    "gui.howtos.add-a-move-block.step_stepflyMoveArrows": "Yön Tuşları ile Hareket Et",
    "gui.howtos.fly.step_stepflyChooseObject": "Toplanacak Bir Nesne Seçin",
    "gui.howtos.add-a-move-block.step_stepflyFlyingObject": "Nesneyi Hareket Ettirin",
    "gui.howtos.add-a-move-block.step_stepflySelectFlyingSprite": "Karakterinizi Seçin",
    "gui.howtos.add-a-move-block.step_stepflyAddScore": "Puan Ekle",
    "gui.howtos.add-a-move-block.step_stepflyKeepScore": "Puanı Tut",
    "gui.howtos.add-a-move-block.step_stepflyAddScenery": "Manzara Ekle",
    "gui.howtos.add-a-move-block.step_stepflyMoveScenery": "Manzarayı Hareket Ettir",
    "gui.howtos.add-a-move-block.step_stepflySwitchLooks": "Sonraki Kostüm",
    "gui.howtos.pong": "Pong Oyunu",
    "gui.howtos.pong.step_pongAddBackdrop": "Arka Plan Ekle",
    "gui.howtos.pong.step_pongAddBallSprite": "Top Sprite Ekle",
    "gui.howtos.pong.step_pongBounceAround": "Zıpla",
    "gui.howtos.pong.step_pongAddPaddle": "Bir Kürek Ekle",
    "gui.howtos.pong.step_pongMoveThePaddle": "Küreği hareket ettirin",
    "gui.howtos.pong.step_pongSelectBallSprite": "Ball Sprite'ı seçin",
    "gui.howtos.pong.step_pongAddMoreCodeToBall": "Topu Raketten Zıplamak için Kod Ekle",
    "gui.howtos.pong.step_pongAddAScore": "Puan Ekle",
    "gui.howtos.pong.step_pongChooseScoreFromMenu": "Menüden 'Puan'ı seçin",
    "gui.howtos.pong.step_pongInsertChangeScoreBlock": "'Puan Değiştir' Bloğu Ekle",
    "gui.howtos.pong.step_pongResetScore": "Puanı Sıfırla",
    "gui.howtos.pong.step_pongAddLineSprite": "Çizgi Sprite'ı ekleyin",
    "gui.howtos.pong.step_pongGameOver": "Oyun bitti",
    "gui.howtos.code-cartoon": "Çizgi Film Kodla",
    "gui.howtos.code-cartoon.step_codeCartoonSaySomething": "Yeşil Bayrağa Tıkladığınızda Bir Şey Söyleyin",
    "gui.howtos.code-cartoon.step_codeCartoonAnimate": "Tıkladığınızda Bir Karakteri Canlandırın",
    "gui.howtos.code-cartoon.step_codeCartoonSelectDifferentCharacter": "Farklı Bir Karakter Seçin",
    "gui.howtos.code-cartoon.step_codeCartoonUseMinusSign": "Küçültmek için Eksi İşareti Kullanın",
    "gui.howtos.code-cartoon.step_codeCartoonGrowShrink": "Bir Karakteri Büyüt ve Küçült",
    "gui.howtos.code-cartoon.step_codeCartoonSelectDifferentCharacter2": "Farklı Bir Karakter Seçin",
    "gui.howtos.code-cartoon.step_codeCartoonJump": "Aşağı yukarı zıpla",
    "gui.howtos.code-cartoon.step_codeCartoonChangeScenes": "Sahneleri Değiştirmek İçin Bir Karaktere Tıklayın",
    "gui.howtos.code-cartoon.step_codeCartoonGlideAround": "Etrafında süzül",
    "gui.howtos.code-cartoon.step_codeCartoonChangeCostumes": "Kostümleri Değiştir",
    "gui.howtos.code-cartoon.step_codeCartoonChooseMoreCharacters": "Çizgi Filminize Eklemek İçin Daha Fazla Karakter Seçin",
    "gui.howtos.cartoon-network": "Bir Macera Oyunu Canlandırın",
    "gui.howtos.cartoon-network.step_CNcharacter": "Gösterilecek Karakteri Seçin",
    "gui.howtos.cartoon-network.step_CNsay": "Bir şey söyle",
    "gui.howtos.cartoon-network.step_CNglide": "Etrafında süzül",
    "gui.howtos.cartoon-network.step_CNpicksprite": "Kovalamak için Bir Nesne Seçin",
    "gui.howtos.cartoon-network.step_CNcollect": "Nesneleri Topla",
    "gui.howtos.cartoon-network.step_CNvariable": "Bir Puan Değişkeni Yapın",
    "gui.howtos.cartoon-network.step_CNscore": "Puanı Tut",
    "gui.howtos.cartoon-network.step_CNbackdrop": "Seviye Atla: Arka Planı Değiştir",
    "gui.howtos.videosens.name": "Video Algılama",
    "gui.howtos.videosens.step_addextension": "Uzantı ekle",
    "gui.howtos.videosens.step_pet": "Kediyi Sev",
    "gui.howtos.videosens.step_animate": "Canlandır",
    "gui.howtos.videosens.step_pop": "Balon Patlat",
    "gui.howtos.say-it-out-loud": "Konuşan Animasyonlar Yaratın",
    "gui.howtos.say-it-out-loud.step_AddTXTextension": "Metinden Konuşmaya blokları ekleyin",
    "gui.howtos.say-it-out-loud.step_TXTSpeech": "Bir şey söyle",
    "gui.howtos.say-it-out-loud_TXTSetVoice": "Bir Ses Ayarla",
    "gui.howtos.say-it-out-loud.step_TXTMove": "Etrafında yürü",
    "gui.howtos.say-it-out-loud.step_TXTBackdrop": "Arka Plan Ekle",
    "gui.howtos.say-it-out-loud.step_TXTAddSprite": "Başka Bir Karakter Ekle",
    "gui.howtos.say-it-out-loud.step_TXTSong": "Şarkı söyle",
    "gui.howtos.say-it-out-loud.step_TXTColor": "Rengi değiştir",
    "gui.howtos.say-it-out-loud.step_TXTSpin": "Etrafında dön",
    "gui.howtos.say-it-out-loud.step_TXTGrow": "Büyü ve küçül",
    "gui.howtos.talking": "konuşan masallar",
    "gui.howtos.talking.step_talesAddExtension": "Metin-Konuşma Bloklarını Eklemek İçin Tıklayın",
    "gui.howtos.talking.step_talesChooseSprite": "Bir Sprite Seçin",
    "gui.howtos.talking.step_talesSaySomething": "Bir Karakterin Konuşmasını Sağlayın",
    "gui.howtos.talking.step_talesChooseBackdrop": "Bir Arka Plan Seçin",
    "gui.howtos.talking.step_talesSwitchBackdrop": "Sonraki Arka Plana Gitmek İçin Bir Karaktere Tıklayın",
    "gui.howtos.talking.step_talesChooseAnotherSprite": "Başka Bir Sprite Seçin",
    "gui.howtos.talking.step_talesMoveAround": "Etrafında yürü",
    "gui.howtos.talking.step_talesChooseAnotherBackdrop": "Başka Bir Zemin Seçin",
    "gui.howtos.talking.step_talesAnimateTalking": "Konuşmayı Canlandır",
    "gui.howtos.talking.step_talesChooseThirdBackdrop": "Başka Bir Zemin Seçin",
    "gui.howtos.talking.step_talesChooseSound": "Dans Edeceğiniz Bir Şarkı Seçin",
    "gui.howtos.talking.step_talesDanceMoves": "Dans hareketleri",
    "gui.howtos.talking.step_talesAskAnswer": "Algılama Kategorisinden Sor ve Cevap Bloklarını Alın",
    "gui.howtos.add-sprite.name": "Bir Sprite Ekle",
    "gui.howtos.add-sprite.step_addSprite": "Bir Sprite Ekle",
    "gui.howtos.add-a-backdrop.name": "Arka Plan Ekle",
    "gui.howtos.add-a-backdrop.step_addBackdrop": "Arka Plan Ekle",
    "gui.howtos.change-size.name": "Boyutu değiştir",
    "gui.howtos.change-size.step_changeSize": "Boyutu değiştir",
    "gui.howtos.glide-around.name": "Etrafında süzül",
    "gui.howtos.change-size.step_glideAroundBackAndForth": "Etrafında süzül",
    "gui.howtos.change-size.step_glideAroundPoint": "Bir Noktaya Koy",
    "gui.howtos.record-a-sound.name": "Ses Kaydet",
    "gui.howtos.change-size.step_recordASoundSoundsTab": "'Sesler' Sekmesine tıklayın",
    "gui.howtos.change-size.step_recordASoundClickRecord": "'Kaydet'i tıklayın'",
    "gui.howtos.change-size.step_recordASoundPressRecordButton": "Kayıt Düğmesine basın",
    "gui.howtos.change-size.step_recordASoundChooseSound": "Sesinizi Seçin",
    "gui.howtos.change-size.step_recordASoundPlayYourSound": "Sesinizi Çalın",
    "gui.howtos.spin-video.name": "Döndür",
    "gui.howtos.change-size.step_spinTurn": "Dönüş",
    "gui.howtos.change-size.step_spinPointInDirection": "Yönü Ayarla",
    "gui.howtos.hide-and-show.name": "Gizle ve Göster",
    "gui.howtos.change-size.step_hideAndShow": "Gizle ve Göster",
    "gui.howtos.switch-costume.name": "Bir Sprite'ı Canlandırın",
    "gui.howtos.change-size.step_switchCostumes": "Bir Sprite'ı Canlandırın",
    "gui.howtos.move-around-with-arrow-keys.name": "Ok tuşlarını kullan",
    "gui.howtos.add-a-backdrop.step_moveArrowKeysLeftRight": "Sola ve Sağa Taşı",
    "gui.howtos.add-a-backdrop.step_moveArrowKeysUpDown": "Yukarı ve aşağı hareket et",
    "gui.howtos.add-effects.name": "Efekt ekle",
    "gui.howtos.videosens.step_addEffects": "Efekt ekle",
    "gui.device.unselectDevice.name": "Kuklalar",
    "gui.device.unselectDevice.description": "Cihazın seçimini kaldırın, sadece kuklalar ile programlama moduna dönün.",
    "gui.device.arduinoUno.description": "Elektronik ve kodlamaya başlamak için harika bir tahta.",
    "gui.device.arduino.connectingMessage": "Bağlanıyor",
    "gui.device.arduinoNano.description": "Arduino Nano, projelerinizi oluşturmak için klasik bir küçük tahtadır.",
    "gui.device.arduinoMini.description": "Arduino Mini, projelerinizi oluşturmak için klasik bir küçük tahta.",
    "gui.device.arduinoLeonardo.description": "Fare veya klavye görevi görebilen klasik Arduino kartı.",
    "gui.device.arduinoMega2560.description": "54 dijital pinli, 16 analog girişli ve 4 seri portlu 8 bitlik kart.",
    "gui.device.arduinoEsp32.description": "Zengin işlevlere sahip Wi-Fi ve Bluetooth kontrol panosu.",
    "gui.device.arduinoEsp32.connectingMessage": "Bağlanıyor",
    "gui.device.arduinoEsp8266.description": "Düşük maliyetli Wi-Fi SOC kontrol panosu.",
    "gui.device.arduinoEsp8266.connectingMessage": "Bağlanıyor",
    "gui.device.microbit.description": "Cep boyutunda bilgisayar, dijital beceri öğrenimini dönüştürüyor.",
    "gui.device.microbit.connectingMessage": "Bağlanıyor",
    "gui.device.microbitV2.description": "Yükseltilmiş işlemci, dahili hoparlör ve mikrofon, dokunmaya duyarlı logo.",
    "gui.device.makeymakey.description": "Her şeyi bir anahtara dönüştürün.",
    "gui.extension.music.name": "Müzik",
    "gui.extension.music.description": "Enstrüman ve davul çalın.",
    "gui.extension.pen.name": "Kalem",
    "gui.extension.pen.description": "Sprite'larınızla çizin.",
    "gui.extension.videosensing.name": "Video Algılama",
    "gui.extension.videosensing.description": "Kamera ile hareketi hissedin.",
    "gui.extension.text2speech.name": "Konuşma metni",
    "gui.extension.text2speech.description": "Projelerinizi konuşturun.",
    "gui.extension.translate.name": "Çevir",
    "gui.extension.translate.description": "Metni birçok dile çevirin.",
    "gui.extension.makeymakey.name": "Makey Makey",
    "gui.extension.makeymakey.description": "Her şeyi bir anahtara dönüştürün.",
    "gui.libraryTags.all": "Herşey",
    "gui.libraryTags.animals": "Hayvanlar",
    "gui.libraryTags.dance": "Dans",
    "gui.libraryTags.effects": "Efektler",
    "gui.libraryTags.fantasy": "Fantastik",
    "gui.libraryTags.fashion": "Moda",
    "gui.libraryTags.food": "Yemek",
    "gui.libraryTags.indoors": "Ev",
    "gui.libraryTags.loops": "Döngüler",
    "gui.libraryTags.music": "Müzik",
    "gui.libraryTags.notes": "Not",
    "gui.libraryTags.outdoors": "Açık hava",
    "gui.libraryTags.patterns": "Desenler",
    "gui.libraryTags.people": "İnsanlar",
    "gui.libraryTags.percussion": "Vurmalı Çalgılar",
    "gui.libraryTags.space": "Uzay",
    "gui.libraryTags.sports": "Spor",
    "gui.libraryTags.underwater": "Su altı",
    "gui.libraryTags.voice": "Ses",
    "gui.libraryTags.wacky": "Tuhaf",
    "gui.libraryTags.animation": "Animasyon",
    "gui.libraryTags.art": "Sanat",
    "gui.libraryTags.games": "Oyun",
    "gui.libraryTags.stories": "Hikaye",
    "gui.libraryTags.letters": "Edebiyat",
    "gui.libraryTags.transport": "Ulaşım",
    "gui.libraryTags.flags": "Bayraklar",
    "gui.libraryTags.city": "Şehir",
    "gui.libraryTags.plants": "Bitkiler",
    "gui.libraryTags.game": "Oyun",
    "gui.libraryTags.others": "Diğerleri",
    "gui.opcodeLabels.mygetTempo": "tempo",
    "gui.opcodeLabels.sesSeviyesi": "ses seviyesi",
    "gui.opcodeLabels.sesSiddeti": "ses şiddeti",
    "gui.opcodeLabels.SallanmaSiddeti": "sallanma şiddeti",
    "gui.opcodeLabels.pilSeviyesi": "pil seviyesi",
    "gui.opcodeLabels.egimAcisi": "eğim açısı",
    "gui.opcodeLabels.yatisAcisi": "yatış açısı",
    "gui.opcodeLabels.direction": "yön",
    "gui.opcodeLabels.xposition": "x pozisyonu",
    "gui.opcodeLabels.yposition": "y pozisyonu",
    "gui.opcodeLabels.size": "boyut",
    "gui.opcodeLabels.costumename": "kostüm adı",
    "gui.opcodeLabels.costumenumber": "kostüm numarası",
    "gui.opcodeLabels.backdropname": "zemin adı",
    "gui.opcodeLabels.backdropnumber": "arka plan numarası",
    "gui.opcodeLabels.volume": "ses",
    "gui.opcodeLabels.tempo": "tempo",
    "gui.opcodeLabels.answer": "cevap",
    "gui.opcodeLabels.loudness": "ses yüksekliği",
    "gui.opcodeLabels.username": "Kullanıcı adı",
    "gui.opcodeLabels.year": "yıl",
    "gui.opcodeLabels.month": "ay",
    "gui.opcodeLabels.date": "tarih",
    "gui.opcodeLabels.dayofweek": "haftanın günü",
    "gui.opcodeLabels.hour": "saat",
    "gui.opcodeLabels.minute": "dakika",
    "gui.opcodeLabels.second": "saniye",
    "gui.opcodeLabels.timer": "zamanlayıcı",
    "gui.projectLoader.loadError": "Seçilen proje dosyası yüklenemedi.",
    "gui.sharedMessages.backdrop": "dekor{index}",
    "gui.sharedMessages.costume": "kostüm{index}",
    "gui.sharedMessages.sprite": "Zbot{index}",
    "gui.sharedMessages.pop": "geri",
    "gui.sharedMessages.replaceProjectWarning": "Mevcut projenin içeriği değiştirilsin mi?",
    "gui.sharedMessages.clearCacheWarning": "Önbelleği temizlemek ve yeniden başlatmak istediğinizden emin misiniz?",
    "gui.sharedMessages.loadFromComputerTitle": "Bilgisayarınızdan yükleyin",
    "gui.sharedMessages.openProjectsFromServer": "Projelerim",
    "gui.gui.defaultProjectTitle": "ZTech Proje",
    "arduinoEsp32.InterrupModeMenu.changeEdge": "kenarı değiştir",
    "arduinoEsp32.InterrupModeMenu.fallingEdge": "düşen kenar",
    "arduinoEsp32.InterrupModeMenu.high": "yüksek",
    "arduinoEsp32.InterrupModeMenu.low": "düşük",
    "arduinoEsp32.InterrupModeMenu.risingEdge": "yükselen kenar",
    "arduinoEsp32.category.data": "Data",
    "arduinoEsp32.category.sensor": "Sensor",
    "arduinoEsp32.category.serial": "Seri",
    "arduinoEsp32.data.dataConstrain": "[DATA] yı ([ARG0], [ARG1]) arasında sınırla",
    "arduinoEsp32.data.dataConvert": "çevir [DATA] to [TYPE]",
    "arduinoEsp32.data.dataConvertASCIICharacter": "çevir [DATA] to ASCII character",
    "arduinoEsp32.data.dataConvertASCIINumber": "çevir [DATA] to ASCII nubmer",
    "arduinoEsp32.data.dataMap": "map [DATA] den ([ARG0], [ARG1]) to ([ARG2], [ARG3])",
    "arduinoEsp32.dataTypeMenu.decimal": "decimal",
    "arduinoEsp32.dataTypeMenu.string": "string",
    "arduinoEsp32.dataTypeMenu.wholeNumber": "bütün sayılar",
    "arduinoEsp32.eolMenu.noWarp": "aynı satır",
    "arduinoEsp32.eolMenu.warp": "yeni satır",
    "arduinoEsp32.levelMenu.high": "yüksek",
    "arduinoEsp32.levelMenu.low": "düşük",
    "arduinoEsp32.modeMenu.input": "giriş",
    "arduinoEsp32.modeMenu.inputPullup": "giriş-çekme",
    "arduinoEsp32.modeMenu.output": "çıkış",
    "arduinoEsp32.pins.attachInterrupt": "kesme pimi ekle [PIN] modu [MODE] yürütülür",
    "arduinoEsp32.pins.detachInterrupt": "kesme pinini [PIN] ayır",
    "arduinoEsp32.pins.esp32ReadTouchPin": "dokunmatik pini oku [PIN]",
    "arduinoEsp32.pins.readAnalogPin": "analog pini oku [PIN]",
    "arduinoEsp32.pins.readDigitalPin": "dijital pini oku [PIN]",
    "arduinoEsp32.pins.setServoOutput": "servo pini [PIN] ayarlayın, [CH] çıkışını kullanın [OUT]",
    "arduinoEsp32.sensor.readHallSensor": "okuma salonu sensörü",
    "arduinoEsp32.sensor.runningTime": "çalışma süresi (milis)",
    "arduinoEsp32.serial.multiSerialAvailable": "seri [NO] mevcut veri uzunluğu",
    "arduinoEsp32.serial.multiSerialBegin": "seri port [NO] başlat baudrate [VALUE]",
    "arduinoEsp32.serial.multiSerialPrint": "seri port [NO] yazdır [VALUE] [EOL]",
    "arduinoEsp32.serial.multiSerialReadAByte": "seri port [NO] byte oku",
    "arduinoLeonardo.category.data": "Veri",
    "arduinoLeonardo.category.pins": "Pinler",
    "arduinoLeonardo.data.dataConstrain": "kısıtlamak [DATA] arasında ([ARG0], [ARG1])",
    "arduinoLeonardo.data.dataConvert": "çevir [DATA] dan  [TYPE]",
    "arduinoLeonardo.data.dataConvertASCIICharacter": "çevir [DATA] ASCII karaktere",
    "arduinoLeonardo.data.dataConvertASCIINumber": "çevir [DATA] ASCII sayısına",
    "arduinoLeonardo.data.dataMap": "map [DATA] from ([ARG0], [ARG1]) to ([ARG2], [ARG3])",
    "arduinoLeonardo.dataTypeMenu.decimal": "decimal",
    "arduinoLeonardo.dataTypeMenu.string": "string",
    "arduinoLeonardo.dataTypeMenu.wholeNumber": "bütün sayılar",
    "arduinoLeonardo.levelMenu.high": "yüksek",
    "arduinoLeonardo.levelMenu.low": "low",
    "arduinoLeonardo.modeMenu.input": "input",
    "arduinoLeonardo.modeMenu.inputPullup": "input-pullup",
    "arduinoLeonardo.modeMenu.output": "output",
    "arduinoLeonardo.pins.attachInterrupt": "attach interrupt pin [PIN] mode [MODE] executes",
    "arduinoLeonardo.pins.detachInterrupt": "detach interrupt pin [PIN]",
    "arduinoLeonardo.pins.readAnalogPin": "read analog pin [PIN]",
    "arduinoLeonardo.pins.readDigitalPin": "read digital pin [PIN]",
    "arduinoLeonardo.pins.setDigitalOutput": "set digital pin [PIN] out [LEVEL]",
    "arduinoLeonardo.pins.setPinMode": "set pin [PIN] mode [MODE]",
    "arduinoLeonardo.pins.setPwmOutput": "set pwm pin [PIN] out [OUT]",
    "arduinoLeonardo.pins.setServoOutput": "set servo pin [PIN] out [OUT]",
    "arduinoMega2560.category.data": "Data",
    "arduinoMega2560.category.pins": "Pins",
    "arduinoMega2560.category.serial": "Serial",
    "arduinoMega2560.data.dataConstrain": "constrain [DATA] between ([ARG0], [ARG1])",
    "arduinoMega2560.data.dataConvert": "convert [DATA] to [TYPE]",
    "arduinoMega2560.data.dataConvertASCIICharacter": "convert [DATA] to ASCII character",
    "arduinoMega2560.data.dataConvertASCIINumber": "convert [DATA] to ASCII nubmer",
    "arduinoMega2560.data.dataMap": "map [DATA] from ([ARG0], [ARG1]) to ([ARG2], [ARG3])",
    "arduinoMega2560.dataTypeMenu.decimal": "decimal",
    "arduinoMega2560.dataTypeMenu.string": "string",
    "arduinoMega2560.dataTypeMenu.wholeNumber": "whole number",
    "arduinoMega2560.levelMenu.high": "high",
    "arduinoMega2560.levelMenu.low": "low",
    "arduinoMega2560.modeMenu.input": "input",
    "arduinoMega2560.modeMenu.inputPullup": "input-pullup",
    "arduinoMega2560.modeMenu.output": "output",
    "arduinoMega2560.pins.attachInterrupt": "attach interrupt pin [PIN] mode [MODE] executes",
    "arduinoMega2560.pins.detachInterrupt": "detach interrupt pin [PIN]",
    "arduinoMega2560.pins.readAnalogPin": "read analog pin [PIN]",
    "arduinoMega2560.pins.readDigitalPin": "read digital pin [PIN]",
    "arduinoMega2560.pins.setDigitalOutput": "set digital pin [PIN] out [LEVEL]",
    "arduinoMega2560.pins.setPinMode": "set pin [PIN] mode [MODE]",
    "arduinoMega2560.pins.setPwmOutput": "set pwm pin [PIN] out [OUT]",
    "arduinoMega2560.pins.setServoOutput": "set servo pin [PIN] out [OUT]",
    "arduinoMega2560.serial.multiSerialAvailable": "serial [NO] available data length",
    "arduinoMega2560.serial.multiSerialBegin": "serial [NO] begin baudrate [VALUE]",
    "arduinoMega2560.serial.multiSerialPrint": "serial [NO] print [VALUE] [EOL]",
    "arduinoMega2560.serial.multiSerialReadAByte": "serial [NO] read a byte",
    "arduinoPeripheral.connection.connectFirmataTimeout": "Timeout when try to connect firmata, please download the firmware first",
    "arduinoUno.InterrupModeMenu.changeEdge": "change edge",
    "arduinoUno.InterrupModeMenu.fallingEdge": "falling edge",
    "arduinoUno.InterrupModeMenu.low": "low",
    "arduinoUno.InterrupModeMenu.risingEdge": "rising edge",
    "arduinoUno.category.data": "Data",
    "arduinoUno.category.pins": "Pins",
    "arduinoUno.category.serial": "Serial",
    "arduinoUno.data.dataConstrain": "constrain [DATA] between ([ARG0], [ARG1])",
    "arduinoUno.data.dataConvert": "convert [DATA] to [TYPE]",
    "arduinoUno.data.dataConvertASCIICharacter": "convert [DATA] to ASCII character",
    "arduinoUno.data.dataConvertASCIINumber": "convert [DATA] to ASCII nubmer",
    "arduinoUno.data.dataMap": "map [DATA] from ([ARG0], [ARG1]) to ([ARG2], [ARG3])",
    "arduinoUno.dataTypeMenu.decimal": "decimal",
    "arduinoUno.dataTypeMenu.string": "string",
    "arduinoUno.dataTypeMenu.wholeNumber": "whole number",
    "arduinoUno.eolMenu.noWarp": "no-warp",
    "arduinoUno.eolMenu.warp": "warp",
    "arduinoUno.levelMenu.high": "high",
    "arduinoUno.levelMenu.low": "low",
    "arduinoUno.modeMenu.input": "input",
    "arduinoUno.modeMenu.inputPullup": "input-pullup",
    "arduinoUno.modeMenu.output": "output",
    "arduinoUno.pins.attachInterrupt": "attach interrupt pin [PIN] mode [MODE] executes",
    "arduinoUno.pins.detachInterrupt": "detach interrupt pin [PIN]",
    "arduinoUno.pins.readAnalogPin": "read analog pin [PIN]",
    "arduinoUno.pins.readDigitalPin": "read digital pin [PIN]",
    "arduinoUno.pins.setDigitalOutput": "set digital pin [PIN] out [LEVEL]",
    "arduinoUno.pins.setPinMode": "set pin [PIN] mode [MODE]",
    "arduinoUno.pins.setPwmOutput": "set pwm pin [PIN] out [OUT]",
    "arduinoUno.pins.setServoOutput": "set servo pin [PIN] out [OUT]",
    "arduinoUno.serial.serialAvailable": "serial available data length",
    "arduinoUno.serial.serialBegin": "serial begin baudrate [VALUE]",
    "arduinoUno.serial.serialPrint": "serial print [VALUE] [EOL]",
    "arduinoUno.serial.serialReadData": "serial read data",
    "boost.color.any": "any color",
    "boost.color.black": "siyah",
    "boost.color.blue": "mavi",
    "boost.color.green": "yeşil",
    "boost.color.red": "kırmızı",
    "boost.color.white": "beyaz",
    "boost.color.yellow": "sarı",
    "boost.getMotorPosition": "motor [MOTOR_REPORTER_ID] position",
    "boost.getTiltAngle": "tilt angle [TILT_DIRECTION]",
    "boost.motorDirection.backward": "that way",
    "boost.motorDirection.forward": "this way",
    "boost.motorDirection.reverse": "reverse",
    "boost.motorOff": "turn motor [MOTOR_ID] off",
    "boost.motorOn": "turn motor [MOTOR_ID] on",
    "boost.motorOnFor": "turn motor [MOTOR_ID] for [DURATION] seconds",
    "boost.motorOnForRotation": "turn motor [MOTOR_ID] for [ROTATION] rotations",
    "boost.seeingColor": "seeing [COLOR] brick?",
    "boost.setLightHue": "set light color to [HUE]",
    "boost.setMotorDirection": "set motor [MOTOR_ID] direction [MOTOR_DIRECTION]",
    "boost.setMotorPower": "set motor [MOTOR_ID] speed to [POWER] %",
    "boost.tiltDirection.any": "herhangi",
    "boost.tiltDirection.down": "aşağı",
    "boost.tiltDirection.left": "sol",
    "boost.tiltDirection.right": "sağ",
    "boost.tiltDirection.up": "yukaru",
    "boost.whenColor": "when [COLOR] brick seen",
    "boost.whenTilted": "when tilted [TILT_DIRECTION_ANY]",
    "esp32Arduino.category.pins": "Pins",
    "esp32Arduino.pins.esp32SetDACOutput": "set dac pin [PIN] out [OUT]",
    "esp32Arduino.pins.esp32SetPwmOutput": "set pwm pin [PIN] use channel [CH] out [OUT]",
    "esp32Arduino.pins.setDigitalOutput": "set digital pin [PIN] out [LEVEL]",
    "esp32Arduino.pins.setPinMode": "set pin [PIN] mode [MODE]",
    "ev3.beepNote": "beep note [NOTE] for [TIME] secs",
    "ev3.buttonPressed": "button [PORT] pressed?",
    "ev3.getBrightness": "brightness",
    "ev3.getDistance": "distance",
    "ev3.getMotorPosition": "motor [PORT] position",
    "ev3.motorSetPower": "motor [PORT] set power [POWER] %",
    "ev3.motorTurnClockwise": "motor [PORT] turn this way for [TIME] seconds",
    "ev3.motorTurnCounterClockwise": "motor [PORT] turn that way for [TIME] seconds",
    "ev3.whenBrightnessLessThan": "when brightness < [DISTANCE]",
    "ev3.whenButtonPressed": "when button [PORT] pressed",
    "ev3.whenDistanceLessThan": "when distance < [DISTANCE]",
    "gdxfor.getAcceleration": "acceleration [DIRECTION]",
    "gdxfor.getForce": "force",
    "gdxfor.getSpin": "spin speed [DIRECTION]",
    "gdxfor.getTilt": "tilt angle [TILT]",
    "gdxfor.isFreeFalling": "falling?",
    "gdxfor.isTilted": "tilted [TILT]?",
    "gdxfor.pulled": "pulled",
    "gdxfor.pushed": "pushed",
    "gdxfor.shaken": "shaken",
    "gdxfor.startedFalling": "started falling",
    "gdxfor.tiltDirectionMenu.any": "herhangi",
    "gdxfor.tiltDirectionMenu.back": "geri",
    "gdxfor.tiltDirectionMenu.front": "ön",
    "gdxfor.tiltDirectionMenu.left": "sol",
    "gdxfor.tiltDirectionMenu.right": "sağ",
    "gdxfor.turnedFaceDown": "yüzü aşağı dönük",
    "gdxfor.turnedFaceUp": "yüzü yukarı döndü",
    "gdxfor.whenForcePushedOrPulled": "when force sensor [PUSH_PULL]",
    "gdxfor.whenGesture": "when [GESTURE]",
    "gdxfor.whenTilted": "when tilted [TILT]",
    "makeymakey.downArrow": "aşağı ok",
    "makeymakey.downArrowShort": "aşağı",
    "makeymakey.leftArrow": "sol ok",
    "makeymakey.leftArrowShort": "sol",
    "makeymakey.rightArrow": "sağ ok",
    "makeymakey.rightArrowShort": "sağ",
    "makeymakey.spaceKey": "uzay",
    "makeymakey.upArrow": "yukarı ok",
    "makeymakey.upArrowShort": "yukarı",
    "makeymakey.whenKeyPressed": "when [KEY] key pressed",
    "makeymakey.whenKeysPressedInOrder": "when [SEQUENCE] pressed in order",
    "microbit.axisMenu.xAxis": "x-ekseni",
    "microbit.axisMenu.yAxis": "y-ekseni",
    "microbit.axisMenu.zAxis": "z-ekseni",
    "microbit.buttonsMenu.any": "herhangi",
    "microbit.category.console": "Console",
    "microbit.category.display": "Görüntüle",
    "microbit.category.pins": "Pins",
    "microbit.category.sensor": "Sensor",
    "microbit.category.wireless": "Wireless",
    "microbit.clearDisplay": "clear display",
    "microbit.console.consolePrint": "print [TEXT]",
    "microbit.defaultTextToDisplay": "Hello!",
    "microbit.display.clearDisplay": "clear screen",
    "microbit.display.lightPixelAt": "ışık [STATE] de x: [X] ekseni, y: [Y] ekseni",
    "microbit.display.show": "göster [TEXT]",
    "microbit.display.showImage": "resmi göster [VALUE]",
    "microbit.display.showImageUntil": "resmi göster [VALUE] for [TIME] secs",
    "microbit.display.showOnPiexlbrightness": "show on the x: [X] axis, y: [Y] axis with brightness [BRT]",
    "microbit.display.showUntilScrollDone": "show [TEXT] until scroll done",
    "microbit.displaySymbol": "display [MATRIX]",
    "microbit.displayText": "display text [TEXT]",
    "microbit.gestruesMenu.faceDown": "face down",
    "microbit.gestruesMenu.faceUp": "face up",
    "microbit.gestruesMenu.freefall": "freefall",
    "microbit.gestruesMenu.shaken": "shaken",
    "microbit.gestruesMenu.tiltedDownward": "tilted downward",
    "microbit.gestruesMenu.tiltedLeftward": "tilted leftward",
    "microbit.gestruesMenu.tiltedRightward": "tilted rightward",
    "microbit.gestruesMenu.tiltedUpward": "tilted upward",
    "microbit.gesturesMenu.jumped": "jumped",
    "microbit.gesturesMenu.moved": "moved",
    "microbit.gesturesMenu.shaken": "shaken",
    "microbit.isButtonPressed": "[BTN] button pressed?",
    "microbit.isTilted": "tilted [DIRECTION]?",
    "microbit.ledState.off": "off",
    "microbit.ledState.on": "on",
    "microbit.levelMenu.high": "high",
    "microbit.levelMenu.low": "low",
    "microbit.pinStateMenu.off": "off",
    "microbit.pinStateMenu.on": "on",
    "microbit.pins.pinIsTouched": "pin [PIN] is touched",
    "microbit.pins.readAnalogPin": "read analog pin [PIN]",
    "microbit.pins.readDigitalPin": "read digital pin [PIN]",
    "microbit.pins.setDigitalOutput": "set digital pin [PIN] out [LEVEL]",
    "microbit.pins.setPwmOutput": "set pwm pin [PIN] out [OUT]",
    "microbit.sensor.axisAcceleration": "[AXIS] axis acceleration",
    "microbit.sensor.buttonIsPressed": "[KEY] button is pressed?",
    "microbit.sensor.calibrateCompass": "calibrate compass",
    "microbit.sensor.compassAngle": "compass angle",
    "microbit.sensor.compassMagneticDensity": "compass magnetic density",
    "microbit.sensor.gestureIsX": "gestrue is [STA]?",
    "microbit.sensor.lightLevel": "light level",
    "microbit.sensor.runningTime": "running time",
    "microbit.sensor.temperature": "temperature",
    "microbit.tiltAngle": "tilt angle [DIRECTION]",
    "microbit.tiltDirectionMenu.any": "any",
    "microbit.tiltDirectionMenu.back": "back",
    "microbit.tiltDirectionMenu.front": "front",
    "microbit.tiltDirectionMenu.left": "left",
    "microbit.tiltDirectionMenu.right": "right",
    "microbit.whenButtonPressed": "when [BTN] button pressed",
    "microbit.whenGesture": "when [GESTURE]",
    "microbit.whenPinConnected": "when pin [PIN] connected",
    "microbit.whenTilted": "when tilted [DIRECTION]",
    "microbit.wireless.closeWirelessCommunication": "close wireless communication",
    "microbit.wireless.openWirelessCommunication": "open wireless communication",
    "microbit.wireless.receiveWirelessMessage": "receive wireless message",
    "microbit.wireless.resetWirelessCommunication": "reset wireless communication",
    "microbit.wireless.sendWirelessMessage": "kablosuz mesaj gönder [TEXT]",
    "microbit.wireless.setWirelessCommunicationChannel": "kablosuz iletişim kanalını [CH] olarak ayarla",
    "music.categoryName": "Müzik",
    "music.changeTempo": "[TEMPO] ile tempoyu değiştirin",
    "music.drumBass": "(2) Bass Drum",
    "music.drumBongo": "(13) Bongo",
    "music.drumCabasa": "(15) Cabasa",
    "music.drumClaves": "(9) Claves",
    "music.drumClosedHiHat": "(6) Closed Hi-Hat",
    "music.drumConga": "(14) Conga",
    "music.drumCowbell": "(11) Cowbell",
    "music.drumCrashCymbal": "(4) Crash Cymbal",
    "music.drumCuica": "(18) Cuica",
    "music.drumGuiro": "(16) Guiro",
    "music.drumHandClap": "(8) Hand Clap",
    "music.drumOpenHiHat": "(5) Open Hi-Hat",
    "music.drumSideStick": "(3) Side Stick",
    "music.drumSnare": "(1) Snare Drum",
    "music.drumTambourine": "(7) Tambourine",
    "music.drumTriangle": "(12) Triangle",
    "music.drumVibraslap": "(17) Vibraslap",
    "music.drumWoodBlock": "(10) Wood Block",
    "music.getTempo": "tempo",
    "music.instrumentBass": "(6) Bass",
    "music.instrumentBassoon": "(14) Bassoon",
    "music.instrumentCello": "(8) Cello",
    "music.instrumentChoir": "(15) Koro",
    "music.instrumentClarinet": "(10) Klarnet",
    "music.instrumentElectricGuitar": "(5) Elektro gitar",
    "music.instrumentElectricPiano": "(2) Elektrikli Piano",
    "music.instrumentFlute": "(12) Flüt",
    "music.instrumentGuitar": "(4) Gitar",
    "music.instrumentMarimba": "(19) Marimba",
    "music.instrumentMusicBox": "(17) Müzik kutusu",
    "music.instrumentOrgan": "(3) Organ",
    "music.instrumentPiano": "(1) Piano",
    "music.instrumentPizzicato": "(7) Pizzicato",
    "music.instrumentSaxophone": "(11) saksafon",
    "music.instrumentSteelDrum": "(18) Steel Drum",
    "music.instrumentSynthLead": "(20) sentetik kurşun",
    "music.instrumentSynthPad": "(21) Synth Pad",
    "music.instrumentTrombone": "(9) Trombon",
    "music.instrumentVibraphone": "(16) Vibrafon",
    "music.instrumentWoodenFlute": "(13) Tahta Flüt",
    "music.midiPlayDrumForBeats": "[BEATS] vuruşları için davul [DRUM] çalındı",
    "music.midiSetInstrument": "enstrümanı [INSTRUMENT] olarak ayarlayın",
    "music.playDrumForBeats": "[BEATS] vuruşları için davul [DRUM] çalın",
    "music.playNoteForBeats": "[BEATS] vuruşları için [NOTE] notasını çal",
    "music.restForBeats": "[BEATS] vuruş için dinlenme",
    "music.setInstrument": "enstrümanı [INSTRUMENT] olarak ayarlayın",
    "music.setTempo": "tempoyu ayarla [TEMPO]",
    "pen.categoryName": "Kalem",
    "pen.changeColorParam": "[COLOR_PARAM] kalemini [VALUE] ile değiştir",
    "pen.changeHue": "kalem rengini değiştir [HUE]",
    "pen.changeShade": "kalem gölgesini değiştir [SHADE]",
    "pen.changeSize": "kalem boyutunu değiştir [SIZE]",
    "pen.clear": "hepsini sil",
    "pen.colorMenu.brightness": "parlaklık",
    "pen.colorMenu.color": "renkler",
    "pen.colorMenu.saturation": "doyma",
    "pen.colorMenu.transparency": "şeffaflık",
    "pen.penDown": "kalem aşağı",
    "pen.penUp": "kalem yukarı",
    "pen.setColor": "kalem rengini [COLOR] olarak ayarla",
    "pen.setColorParam": "[COLOR_PARAM] kalemini [VALUE] olarak ayarla",
    "pen.setHue": "kalem rengini [HUE] olarak ayarlayın",
    "pen.setShade": "kalem gölgesini [SHADE] olarak ayarlayın",
    "pen.setSize": "kalem boyutunu [SIZE] olarak ayarla",
    "pen.stamp": "pul",
    "speech.defaultWhenIHearValue": "Hadi gidelim",
    "speech.extensionName": "Konuşmadan yazıya",
    "speech.listenAndWait": "dinle ve bekle",
    "speech.speechReporter": "konuş",
    "speech.whenIHear": "duyduğumda [PHRASE]",
    "text2speech.alto": "alto",
    "text2speech.categoryName": "Konuşma metni",
    "text2speech.defaultTextToSpeak": "merhaba",
    "text2speech.giant": "devasa",
    "text2speech.kitten": "kedi yavrusu",
    "text2speech.setLanguageBlock": "dili ayarla [LANGUAGE]",
    "text2speech.setVoiceBlock": "sesi ayarla[VOICE]",
    "text2speech.speakAndWaitBlock": "konuşmak [WORDS]",
    "text2speech.squeak": "gıcırtı",
    "text2speech.tenor": "tenor",
    "translate.categoryName": "Çevir",
    "translate.defaultTextToTranslate": "merhaba",
    "translate.translateBlock": " [WORDS] den çevir [LANGUAGE]",
    "translate.viewerLanguage": "dil",
    "videoSensing.categoryName": "Video Algılama",
    "videoSensing.direction": "yön",
    "videoSensing.motion": "hareket",
    "videoSensing.off": "kapalı",
    "videoSensing.on": "açık",
    "videoSensing.onFlipped": "ters çevrilmiş",
    "videoSensing.setVideoTransparency": "video şeffaflığını şuna ayarla[TRANSPARENCY]",
    "videoSensing.sprite": "sprite",
    "videoSensing.stage": "sahne",
    "videoSensing.videoOn": "video [ATTRIBUTE] on [SUBJECT]",
    "videoSensing.videoToggle": "videoyu çevir[VIDEO_STATE]",
    "videoSensing.whenMotionGreaterThan": "video hareketi ne zaman > [REFERENCE]",
    "wedo2.getDistance": "mesafe",
    "wedo2.getTiltAngle": "eğim açısı[TILT_DIRECTION]",
    "wedo2.isTilted": "eğik [TILT_DIRECTION_ANY]?",
    "wedo2.motorDirection.backward": "bu şekilde",
    "wedo2.motorDirection.forward": "Bu taraftan",
    "wedo2.motorDirection.reverse": "ters",
    "wedo2.motorId.a": "motor A",
    "wedo2.motorId.all": "tüm motorlar",
    "wedo2.motorId.b": "motor B",
    "wedo2.motorId.default": "motor",
    "wedo2.motorOff": "[MOTOR_ID]'yi kapat",
    "wedo2.motorOn": "[MOTOR_ID]'yi aç",
    "wedo2.motorOnFor": "[DURATION] saniye boyunca [MOTOR_ID] özelliğini açın",
    "wedo2.playNoteFor": "[DURATION] saniye boyunca [NOTE] notunu çal",
    "wedo2.setLightHue": "açık rengi [HUE] olarak ayarlayın",
    "wedo2.setMotorDirection": "[MOTOR_ID] yönünü [MOTOR_DIRECTION] olarak ayarla",
    "wedo2.startMotorPower": "[MOTOR_ID] gücünü [POWER] olarak ayarlayın",
    "wedo2.tiltDirection.any": "herhangi",
    "wedo2.tiltDirection.down": "aşağı",
    "wedo2.tiltDirection.left": "sol",
    "wedo2.tiltDirection.right": "sağ",
    "wedo2.tiltDirection.up": "yukarı",
    "wedo2.whenDistance": "mesafe oldukça [OP] [REFERENCE]",
    "wedo2.whenTilted": "eğildiğinde [TILT_DIRECTION_ANY]",
    "paint.colorPicker.swap": "Takas",
    "paint.paintEditor.hue": "Renk",
    "paint.paintEditor.saturation": "Doyma",
    "paint.paintEditor.brightness": "Parlaklık",
    "paint.paintEditor.costume": "Kostüm",
    "paint.paintEditor.group": "Grup",
    "paint.paintEditor.ungroup": "Grubu çöz",
    "paint.paintEditor.undo": "Geri alma",
    "paint.paintEditor.redo": "Yeniden yap",
    "paint.paintEditor.forward": "İleriye",
    "paint.paintEditor.backward": "Geriye",
    "paint.paintEditor.front": "Ön",
    "paint.paintEditor.back": "Geri",
    "paint.paintEditor.more": "Daha fazla",
    "paint.modeTools.brushSize": "Boyut",
    "paint.modeTools.eraserSize": "Silgi boyutu",
    "paint.modeTools.copy": "Kopyala",
    "paint.modeTools.paste": "Yapıştır",
    "paint.modeTools.delete": "Sil",
    "paint.modeTools.curved": "kavisli",
    "paint.modeTools.pointed": "İşaretlendi",
    "paint.modeTools.thickness": "Kalınlık",
    "paint.modeTools.flipHorizontal": "Yatay çevirmek",
    "paint.modeTools.flipVertical": "Dikey Çevir",
    "paint.modeTools.filled": "Dolu",
    "paint.modeTools.outlined": "İçi Boş",
    "paint.paintEditor.bitmap": "Bitmap'e Dönüştür",
    "paint.paintEditor.vector": "Vektöre Dönüştür",
    "paint.paintEditor.fill": "Doldur",
    "paint.paintEditor.stroke": "anahat",
    "paint.brushMode.brush": "Fırçalamak",
    "paint.eraserMode.eraser": "Silgi",
    "paint.fillMode.fill": "Doldur",
    "paint.lineMode.line": "Çizgi",
    "paint.ovalMode.oval": "Daire",
    "paint.rectMode.rect": "Dikdörtgen",
    "paint.reshapeMode.reshape": "yeniden şekillendir",
    "paint.roundedRectMode.roundedRect": "Yuvarlarılmış dikdörtgen",
    "paint.selectMode.select": "Seç",
    "paint.textMode.text": "Metin",
	  "gui.alerts.codeEditorIsLocked": "Kod düzenleyici kilit durumunda, kodu düzenlemek istiyorsanız, lütfen önce kilidini açın",
	  "gui.alerts.codeEditorIsUnlocked": "Kod düzenleyici kilit açma durumunda, bloktan kod oluşturmak istiyorsanız lütfen önce kilitleyin",
    
    
    "gui.loadingPage.horizontalBtn": "Simge Blokları",
    "gui.loadingPage.verticalBtn": "Sözcük Blokları",
    "gui.game.congratulations": "Tebrikler",
    "gui.game.tryAgain": "Yeniden Dene",
    "gui.game.successDesc": "Bu Bölümü Başarıyla Tamamladın",
    "gui.game.errorDesc": "Bu Bölümü Tekrar Oynamalısın",
    "gui.game.modal.title": "Harita Menüsü",
    "gui.game.modal.mainGame": "Boş Harita",
    "gui.game.modal.kidsBeginner": "Başlangıç Seviyesi",
    "gui.game.modal.kidsIntermediate": "İleri Seviye",
    "gui.game.modal.kidsColors": "Renkler",
    "gui.game.modal.kidsShapes": "Şekiller",
    "gui.game.modal.kidsAnimals": "Hayvanlar",
    "gui.game.modal.kidsSpace": "Uzay",
    "gui.game.modal.ilerlemePortal": "Portal",
    "gui.game.modal.ilerlemeMuzik": "Müzik",
    "gui.game.modal.up": "Yukarı",
    "gui.game.modal.down": "Aşağı",
    "gui.game.modal.forward": "İleri",
    "gui.game.modal.backward": "Geri",
    
  },
  "ar": {
    "gui.alerts.lostPeripheralConnection": "قطع الاتصال بـ ZTech {extensionName}.",
    "gui.alerts.tryAgain": "حاول مرة أخرى",
    "gui.alerts.download": "تحميل",
    "gui.connection.reconnect": "أعد الاتصال",
    "gui.alerts.downloadFirmware": "تنزيل البرامج الثابتة",
    "gui.backpack.costumeLabel": "جريت",
    "gui.backpack.soundLabel": "يبدو",
    "gui.backpack.scriptLabel": "النصي",
    "gui.backpack.spriteLabel": "العفاريت",
    "gui.backpack.header": "حقيبة الظهر",
    "gui.backpack.errorBackpack": "خطأ في تحميل حقيبة الظهر",
    "gui.backpack.loadingBackpack": "جار التحميل...",
    "gui.backpack.more": "أكثر",
    "gui.backpack.emptyBackpack": "حقيبة ظهر فارغة",
    "gui.unsupportedBrowser.label": "المستعرض غير مدعوم",
    "gui.unsupportedBrowser.errorLabel": "هناك خطأ ما",
    "gui.unsupportedBrowser.notRecommended": "نحن آسفون للغاية ، لكنك تستخدم إصدار متصفح لا تدعمه ZTech. نوصي بالتحديث إلى أحدث إصدار من المتصفح المدعوم مثل Google Chrome أو Mozilla Firefox أو Microsoft Edge أو Apple Safari.",
    "gui.unsupportedBrowser.description": "نحن آسفون جدًا ، لكن ZTech لا يدعم هذا المتصفح. نوصي بالتحديث إلى أحدث إصدار من المتصفح المدعوم مثل Google Chrome أو Mozilla Firefox أو Microsoft Edge أو Apple Safari.",
    "gui.unsupportedBrowser.back": "خلف",
    "gui.unsupportedBrowser.previewfaq": "لمعرفة المزيد ، انتقل إلى: {previewFaqLink}.",
    "gui.unsupportedBrowser.previewfaqlinktext": "التعليمات",
    "gui.cards.all-tutorials": "دروس",
    "gui.cards.shrink": "يفحص",
    "gui.cards.expand": "وسعت",
    "gui.cards.close": "أغلق",
    "gui.cards.more-things-to-try": "المزيد من الأشياء لمحاولة!",
    "gui.cards.see-more": "شاهد المزيد",
    "gui.comingSoon.message1": "لا تقلق ، نحن موجودون عليها {emoji}",
    "gui.comingSoon.message2": "قريب جدا...",
    "gui.comingSoon.message3": "نحن نعمل عليه {emoji}",
    "gui.connection.auto-scanning.noPeripheralsFound": "لم تجد الأجهزة",
    "gui.connection.auto-scanning.prescan": "اجعل جهازك قريبًا ، ثم ابدأ البحث.",
    "gui.connection.auto-scanning.pressbutton": "اضغط على الزر الموجود على جهازك.",
    "gui.connection.auto-scanning.start-search": "ابدأ البحث",
    "gui.connection.connecting-searchbutton": "يبحث...",
    "gui.connection.auto-scanning.try-again": "حاول مرة أخرى",
    "gui.connection.connected": "متصل",
    "gui.connection.disconnect": "قطع الاتصال",
    "gui.connection.go-to-editor": "اذهب إلى المحرر",
    "gui.connection.connecting-cancelbutton": "Bağlanıyor...",
    "gui.connection.error.errorMessage": "خطأ ، يبدو أن هناك خطأ ما.",
    "gui.connection.error.tryagainbutton": "حاول مرة أخرى",
    "gui.connection.error.helpbutton": "مساعدة",
    "gui.connection.peripheral-name-label": "اسم الجهاز",
    "gui.connection.connect": "الاتصال",
    "gui.connection.scanning.listAll": "عرض جميع الأجهزة القابلة للتوصيل",
    "gui.connection.scanning.lookingforperipherals": "البحث عن الأجهزة",
    "gui.connection.scanning.noPeripheralsFound": "لم تجد الأجهزة",
    "gui.connection.scanning.instructions": "حدد جهازك من القائمة أعلاه.",
    "gui.connection.search": "ينعش",
    "gui.connection.unavailable.installscratchlink": "تأكد من تثبيت ZTech Link وتشغيله",
    "gui.connection.unavailable.connectUsbEnableBluetooth": "تحقق من توصيل كبل USB أو تمكين Bluetooth",
    "gui.connection.unavailable.tryagainbutton": "حاول مرة أخرى",
    "gui.connection.unavailable.helpbutton": "مساعدة",
    "gui.controls.go": "يذهب",
    "gui.controls.stop": "قف",
    "gui.crashMessage.label": "خطأ! هناك خطأ ما.",
    "gui.crashMessage.description": "نحن آسفون للغاية ولكن يبدو أن ZTech قد تعطل. تم الإبلاغ عن هذا الخطأ تلقائيًا إلى فريق ZTech. يرجى تحديث صفحتك للمحاولة مرة أخرى.",
    "gui.crashMessage.errorNumber": "تم تسجيل الخطأ الخاص بك مع معرف {errorId}",
    "gui.crashMessage.reload": "إعادة تحميل",
    "gui.customProcedures.myblockModalTitle": "جعل بلوك",
    "gui.customProcedures.addAnInputNumber": "إضافة إدخال",
    "gui.customProcedures.numberType": "رقم",
    "gui.customProcedures.addAnInputText": "إضافة إدخال",
    "gui.customProcedures.TextType": "نص",
    "gui.customProcedures.addAnInputBoolean": "إضافة إدخال",
    "gui.customProcedures.booleanType": "boole",
    "gui.customProcedures.addALabel": "إضافة علامة",
    "gui.customProcedures.runWithoutScreenRefresh": "تشغيل بدون تحديث الشاشة",
    "gui.customProcedures.cancel": "يلغي",
    "gui.customProcedures.ok": "حسنا",
    "gui.SpriteInfo.direction": "اتجاه",
    "gui.directionPicker.rotationStyles.allAround": "في كل مكان",
    "gui.directionPicker.rotationStyles.leftRight": "يسا/ يمين",
    "gui.directionPicker.rotationStyles.dontRotate": "دوران",
    "gui.gui.addExtension": "إضافة ملحق",
    "gui.gui.codeTab": "شفرة",
    "gui.gui.backdropsTab": "الخلفيات",
    "gui.gui.costumesTab": "ازياء خاصة",
    "gui.gui.soundsTab": "اصوات",
    "gui.hardwareConsole.send": "إرسال",
    "gui.hardwareConsole.buadrate": "بوادرات",
    "gui.hardwareConsole.endOfLine": "نهاية طريقك",
    "gui.hardwareConsole.hexform": "شكل سداسي",
    "gui.hardwareConsole.autoScroll": "لف تلقائي",
    "gui.hardwareHeader.upload": "حمل",
    "gui.stageHeader.codeStageSizeLarge": "كن على المسرح الكبير",
    "gui.stageHeader.codeStageSizeSmall": "التحول إلى المرحلة الصغيرة",
    "gui.stageHeader.hideStage": "إخفاء المشهد",
    "gui.extensionLibrary.comingSoon": "قريب جدا",
    "gui.extensionLibrary.requires": "متطلبات",
    "gui.extensionLibrary.collaboration": "بالتعاون مع",
    "gui.extensionLibrary.manufactor": "منتج",
    "gui.extensionLibrary.version": "إصدار",
    "gui.extensionLibrary.author": "مطور",
    "gui.extensionLibrary.programMode": "وضع البرنامج",
    "gui.extensionLibrary.programLanguage": "لغة برمجة",
    "gui.extensionLibrary.deviceLearnMore": "يتعلم أكثر",
    "gui.extensionLibrary.extensionHelp": "مساعدة",
    "gui.extensionLibrary.Processing": "معالجة...",
    "gui.extensionLibrary.loaded": "تم الرفع",
    "gui.extensionLibrary.notLoaded": "لم يتم التحميل",
    "gui.library.filterPlaceholder": "Arama",
    "gui.library.allTag": "Herşey",
    "gui.loader.message1": "Blok oluşturma …",
    "gui.loader.message2": "Sprite yükleniyor …",
    "gui.loader.message3": "Sesler yükleniyor …",
    "gui.loader.message4": "Uzantılar yükleniyor …",
    "gui.loader.message5": "gütme kediler …",
    "gui.loader.message6": "nanoları ilet …",
    "gui.loader.message7": "şişirme gobolar …",
    "gui.loader.message8": "Emoji hazırlama …",
    "gui.loader.headline": "Proje Yükleniyor",
    "gui.loader.creating": "Proje Oluşturma",
    "gui.accountMenu.profile": "Profil",
    "gui.accountMenu.myStuff": "benim eşyalarım",
    "gui.accountMenu.myClasses": "Sınıflarım",
    "gui.accountMenu.myClass": "Sınıfım",
    "gui.accountMenu.accountSettings": "Hesap ayarları",
    "gui.accountMenu.signOut": "oturumu Kapat",
    "gui.authorInfo.byUser": "{username}",
    "gui.menuBar.seeProjectPage": "Proje Sayfasına Bakın",
    "general.username": "Kullanıcı adı",
    "general.password": "Şifre",
    "general.signIn": "Kayıt olmak",
    "login.needHelp": "Yardıma mı ihtiyacınız var?",
    "form.validationRequired": "Bu alan gereklidir",
    "gui.menuBar.LanguageSelector": "dil seçici",
    "gui.menuBar.tutorialsLibrary": "öğreticiler",
    "gui.menuBar.restoreSprite": "Sprite'ı Geri Yükle",
    "gui.menuBar.restoreSound": "Sesi Geri Yükle",
    "gui.menuBar.restoreCostume": "Kostümü Geri Yükle",
    "gui.menuBar.restore": "Onarmak",
    "gui.menuBar.saveNow": "Şimdi kaydet",
    "gui.menuBar.saveAsCopy": "Kopya olarak kaydet",
    "gui.menuBar.remix": "Remix",
    "gui.menuBar.new": "Yeni",
    "gui.menuBar.checkUpdate": "Güncellemeyi kontrol et",
    "gui.menuBar.installDriver": "Sürücüyü kur",
    "gui.menuBar.clearCacheAndRestart": "Önbelleği temizle ve yeniden başlat",
    "gui.menuBar.edit": "Düzenle",
    "gui.menuBar.turboModeOff": "Turbo Modu kapatın",
    "gui.menuBar.turboModeOn": "Turbo Modu açın",
    "gui.menuBar.noDeviceSelected": "Cihaz seçilmedi",
    "gui.menuBar.noConnection": "Bağlantısız",
    "gui.menuBar.file": "Dosya",
    "gui.menuBar.downloadToComputer": "Bilgisayarınıza kaydedin",
    "gui.menuBar.downloadFirmware": "Firmware İndirin",
    "gui.menu-bar.modeSwitchRealtime": "Canlı",
    "gui.menu-bar.modeSwitchRealtimeUpload": "Yükle",
    "gui.gui.projectTitlePlaceholder": "Proje başlığı burada",
    "gui.menuBar.saveNowLink": "Şimdi kaydet",
    "gui.menuBar.isShared": "Paylaşılan",
    "gui.menuBar.share": "Paylaş",
    "gui.modal.help": "Yardım",
    "gui.modal.back": "Geri",
    "gui.monitor.listMonitor.empty": "(empty)",
    "gui.monitor.listMonitor.listLength": "uzunluk {length}",
    "gui.monitor.contextMenu.default": "normal okuma",
    "gui.monitor.contextMenu.large": "büyük okuma",
    "gui.monitor.contextMenu.slider": "kaydırıcı",
    "gui.monitor.contextMenu.sliderRange": "kaydırıcı aralığını değiştir",
    "gui.monitor.contextMenu.import": "içe aktar",
    "gui.monitor.contextMenu.export": "dışa aktar",
    "gui.playButton.play": "Oynat",
    "gui.playButton.stop": "Durdur",
    "gui.gui.variableScopeOptionAllSprites": "Bütün spriteslar için",
    "gui.gui.variableScopeOptionSpriteOnly": "Sadece bu sprite için",
    "gui.gui.cloudVariableOption": "Bulut değişkeni (stored on server)",
    "gui.gui.variablePromptAllSpritesMessage": "Bu değişken tüm sprite'lar için geçerli olacaktır.",
    "gui.prompt.cancel": "İptal",
    "gui.prompt.ok": "Tamam",
    "gui.playbackStep.stopMsg": "Durdur",
    "gui.playbackStep.playMsg": "Oynat",
    "gui.playbackStep.loadingMsg": "Yükleniyor...",
    "gui.playbackStep.saveMsg": "Kaydet",
    "gui.playbackStep.reRecordMsg": "yeniden kayıt",
    "gui.recordModal.title": "Kayıt sesi",
    "gui.recordingStep.beginRecord": "Aşağıdaki butona tıklayarak kayda başlayın",
    "gui.recordingStep.permission": "{arrow}Mikrofonunuzu kullanmak için izninize ihtiyacımız var",
    "gui.recordingStep.stop": "Kaydetmeyi bırak",
    "gui.recordingStep.record": "Kayıt",
    "gui.sliderModal.min": "Minimum değer",
    "gui.sliderModal.max": "Maksimum değer",
    "gui.sliderModal.title": "Kaydırıcı aralığını değiştir",
    "gui.sliderPrompt.cancel": "İptal",
    "gui.sliderPrompt.ok": "Tamam",
    "gui.soundEditor.sound": "Ses",
    "gui.soundEditor.play": "Oynat",
    "gui.soundEditor.stop": "Durdur",
    "gui.soundEditor.copy": "Kopyala",
    "gui.soundEditor.paste": "Yapıştır",
    "gui.soundEditor.copyToNew": "Yeniye Kopyala",
    "gui.soundEditor.delete": "Sil",
    "gui.soundEditor.save": "Kaydet",
    "gui.soundEditor.undo": "Geri alma",
    "gui.soundEditor.redo": "Yeniden yap",
    "gui.soundEditor.faster": "Daha hızlı",
    "gui.soundEditor.slower": "Yavaş",
    "gui.soundEditor.echo": "Eko",
    "gui.soundEditor.robot": "Robot",
    "gui.soundEditor.louder": "daha yüksek sesle",
    "gui.soundEditor.softer": "Daha yumuşak",
    "gui.soundEditor.reverse": "Ters",
    "gui.soundEditor.fadeOut": "karart",
    "gui.soundEditor.fadeIn": "yavaş yavaş",
    "gui.soundEditor.mute": "Sesi kapat",
    "gui.SpriteInfo.spritePlaceholder": "İsim",
    "gui.SpriteInfo.sprite": "Sprite",
    "gui.SpriteInfo.show": "Göster",
    "gui.SpriteInfo.size": "Boy",
    "gui.spriteSelectorItem.contextMenuDuplicate": "kopyala",
    "gui.spriteSelectorItem.contextMenuExport": "Dışa aktar",
    "gui.spriteSelectorItem.contextMenuDelete": "sil",
    "gui.spriteSelector.addSpriteFromLibrary": "Sprite seç",
    "gui.spriteSelector.addSpriteFromPaint": "Boya",
    "gui.spriteSelector.addSpriteFromSurprise": "Sürpriz",
    "gui.spriteSelector.addSpriteFromFile": "Sprite Yükle",
    "gui.stageHeader.stageSizeLarge": "Büyük sahneye geç",
    "gui.stageHeader.stageSizeSmall": "Küçük sahneye geç",
    "gui.stageHeader.stageSizeFull": "Tam ekran moduna girin",
    "gui.stageHeader.stageSizeUnFull": "Tam ekran modundan çık",
    "gui.stageHeader.fullscreenControl": "Tam Ekran Kontrolü",
    "gui.spriteSelector.addBackdropFromLibrary": "Bir Arka Plan Seçin",
    "gui.stageSelector.addBackdropFromPaint": "Boya",
    "gui.stageSelector.addBackdropFromSurprise": "Sürpriz",
    "gui.stageSelector.addBackdropFromFile": "Arka Planı Yükle",
    "gui.stageSelector.stage": "Sahne",
    "gui.stageSelector.backdrops": "Arka planlar",
    "gui.telemetryOptIn.label": "ZTech'u geliştirmek için istatistikleri raporlayın",
    "gui.telemetryOptIn.body1": "ZTech Ekibi, her zaman ZTech'un dünya çapında nasıl kullanıldığını daha iyi anlamaya çalışır. Bu çabayı desteklemek için ZTech'un kullanım bilgilerini ZTech Ekibine otomatik olarak göndermesine izin verebilirsiniz.",
    "gui.telemetryOptIn.body2": "Topladığımız bilgiler arasında dil seçimi, blok kullanımı ve proje kaydetme, yükleme ve karşıya yükleme gibi bazı olaylar yer alır. Herhangi bir kişisel bilgi TOPLAMIYORUZ. Daha fazla bilgi için lütfen {privacyPolicyLink} sayfamıza bakın.",
    "gui.telemetryOptIn.privacyPolicyLink": "Gizlilik Politikası",
    "gui.telemetryOptIn.optInText": "Kullanım verilerimi ZTech Ekibi ile paylaş",
    "gui.telemetryOptIn.optInTooltip": "Telemetriyi etkinleştir",
    "gui.telemetryOptIn.optOutText": "Kullanım verilerimi ZTech Ekibi ile paylaşma",
    "gui.telemetryOptIn.optOutTooltip": "Telemetriyi devre dışı bırak",
    "gui.telemetryOptIn.settingWasUpdated": "Ayarınız güncellendi.",
    "gui.telemetryOptIn.buttonClose": "Kapat",
    "gui.turboMode.active": "Turbo Modu",
    "gui.updateModel.tileUpdate": "Yeni harici kaynak sürümü algılandı",
    "gui.upgradeModal.upgradeLater": "Daha sonra yükselt",
    "gui.upgradeModal.upgradeAndRestart": "Yükselt ve yeniden başlat",
    "gui.updateModel.tileChecking": "Kontrol etme",
    "gui.updateModel.checkingTips": "Bu adım birkaç saniye sürecek, lütfen bekleyin.",
    "gui.upgradeModal.closeChecking": "Kapat",
    "gui.updateModel.tileAlreadyLatest": "Zaten en son",
    "gui.updateModel.alreadyLatestTips": "Harici kaynak zaten en yeni.",
    "gui.updateModel.upgrading": "Yükseltme",
    "gui.upgradeModal.downloading": "İndiriliyor",
    "gui.upgradeModal.covering": "kaplama",
    "gui.updateModel.tileError": "Operasyon başarısız",
    "gui.uploadProgress.uploading": "yükleniyor",
    "gui.uploadProgress.uploadSuccess": "Yükleme başarılı",
    "gui.uploadProgress.uploadError": "Yükleme hatası",
    "gui.uploadProgress.uploadTimeout": "Yükleme zaman aşımı",
    "gui.uploadProgress.close": "Kapat",
    "gui.webglModal.label": "Tarayıcınız WebGL'yi Desteklemiyor",
    "gui.webglModal.description": "Maalesef tarayıcınız veya bilgisayarınız {webGlLink} gibi görünüyor. ZTech'un çalışması için bu teknoloji gereklidir.",
    "gui.webglModal.webgllink": "WebGL'yi desteklemiyor",
    "gui.webglModal.back": "Geri",
    "gui.webglModal.previewfaq": "Daha fazla bilgi edinmek için {previewFaqLink} sayfasına gidin.",
    "gui.webglModal.previewfaqlinktext": "SSS",
    "gui.costumeLibrary.chooseABackdrop": "Bir Arka Plan Seçin",
    "gui.costumeLibrary.chooseACostume": "Bir Kostüm Seçin",
    "gui.costumeTab.addBackdropFromLibrary": "Bir Arka Plan Seçin",
    "gui.costumeTab.addCostumeFromLibrary": "Bir Kostüm Seçin",
    "gui.costumeTab.addBlankCostume": "Boya",
    "gui.costumeTab.addSurpriseCostume": "Sürpriz",
    "gui.costumeTab.addFileBackdrop": "Arka Planı Yükle",
    "gui.costumeTab.addFileCostume": "Kostüm Yükle",
    "gui.deviceLibrary.chooseADevice": "Bir Cihaz Seçin",
    "gui.deviceLibrary.deviceUrl": "Cihazın URL'sini girin",
    "gui.deviceLibrary.arduinoTag": "Arduino",
    "gui.deviceLibrary.microPythonTag": "MicroPython",
    "gui.deviceLibrary.kitTag": "Kit",
    "gui.extensionLibrary.chooseAnExtension": "Bir Uzantı Seçin",
    "gui.extensionLibrary.extensionUrl": "Uzantının URL'sini girin",
    "gui.library.shieldTag": "kalkan",
    "gui.library.actuatorTag": "aktüatör",
    "gui.library.sensorTag": "Sensör",
    "gui.library.displayTag": "Görüntü",
    "gui.library.communicationTag": "İletişim",
    "gui.library.otherTag": "Diğer",
    "gui.hardwareConsole.noLineTerminators": "Satır sonlandırıcı yok",
    "gui.hardwareConsole.lineFeed": "Satır besleme",
    "gui.hardwareConsole.carriageReturn": "Satırbaşı",
    "gui.hardwareConsole.lfAndCr": "LF & CR",
    "gui.monitors.importListColumnPrompt": "Hangi sütun kullanılmalıdır (1-{numberOfColumns})?",
    "gui.recordingStep.alertMsg": "Kayıt başlatılamadı",
    "gui.soundLibrary.chooseASound": "Bir Ses Seçin",
    "gui.soundTab.fileUploadSound": "Ses Yükle",
    "gui.soundTab.surpriseSound": "Sürpriz",
    "gui.soundTab.recordSound": "Kayıt",
    "gui.soundTab.addSoundFromLibrary": "Bir Ses Seçin",
    "gui.spriteLibrary.chooseASprite": "Bir Sprite Seçin",
    "gui.tipsLibrary.tutorials": "Bir Eğitim Seçin",
    "gui.updateModal.upgradeWarning": "Şu anda kaydedilmemiş projeler kaybolacak, yükseltmeye devam edilsin ve yeniden başlatılsın mı?",
    "gui.uploadProgress.uploadErrorMessage": "Yükleme hatası",
    "gui.uploadProgress.uploadTimeoutMessage": "Yükleme zaman aşımı",
    "gui.alerts.createsuccess": "Yeni proje oluşturuldu.",
    "gui.alerts.createcopysuccess": "Proje kopya olarak kaydedildi.",
    "gui.alerts.createremixsuccess": "Proje remix olarak kaydedildi.",
    "gui.alerts.creating": "Yeni oluşturma…",
    "gui.alerts.creatingCopy": "Proje kopyalanıyor…",
    "gui.alerts.creatingRemix": "Remiksleme projesi…",
    "gui.alerts.creatingError": "Proje oluşturulamadı. Lütfen tekrar deneyin!",
    "gui.alerts.savingError": "Proje kaydedilemedi.",
    "gui.alerts.savesuccess": "Proje kaydedildi.",
    "gui.alerts.saving": "Proje kaydediliyor…",
    "gui.alerts.cloudInfo": "Bulut değişkenlerinin yalnızca sayıları desteklediğini, harfleri veya simgeleri desteklemediğini lütfen unutmayın. {learnMoreLink}",
    "gui.alerts.cloudInfoLearnMore": "Daha fazla bilgi edin.",
    "gui.alerts.importing": "İçe aktarılıyor…",
    "gui.alerts.workspaceIsEmpty": "Çalışma alanı boş",
    "gui.alerts.workspaceIsNotEmpty": "Çalışma alanı boş değil, lütfen önce çalışma alanınızı temizleyin",
    "gui.alerts.uploadError": "Yükleme hatası",
    "gui.alerts.uploadSuccess": "Yükleme başarılı",
    "gui.alerts.selectADeviceFirst": "Lütfen önce bir cihaz seçin",
    "gui.alerts.connectAPeripheralFirst": "Lütfen önce bir çevre birimi bağlayın",
    "gui.defaultProject.chirp": "Hareket",
    "gui.defaultProject.variable": "benim değişkenim",
    "gui.howtos.intro-move-sayhello-hat.name": "Başlarken",
    "gui.howtos.intro-move.step_stepMove": "Hareket bloğu ekle",
    "gui.howtos.add-a-move-block.step_stepSay": "Söyle bloğu ekle",
    "gui.howtos.add-a-move-block.step_stepGreenFlag": "Başlamak için yeşil bayrağa tıklayın",
    "gui.howtos.animate-a-name.name": "Bir Adı Canlandır",
    "gui.howtos.animate-a-name.step_AnimatePickLetter": "Bir Harf Sprite Seçin",
    "gui.howtos.animate-a-name.step_AnimatePlaySound": "Tıklandığında Ses Çal",
    "gui.howtos.animate-a-name.step_AnimatePickLetter2": "Başka Bir Harf Sprite Seç",
    "gui.howtos.animate-a-name.step_AnimateChangeColor": "Rengi değiştir",
    "gui.howtos.animate-a-name.step_AnimateSpin": "Başka Bir Harf Sprite Seç ve Döndür",
    "gui.howtos.animate-a-name.step_AnimateGrow": "Başka Bir Harf Sprite Seçin ve Büyütün",
    "gui.howtos.imagine": "Bir Dünya Hayal Edin",
    "gui.howtos.imagine.step_imagineTypeWhatYouWant": "Söylemek İstediğinizi Yazın",
    "gui.howtos.imagine.step_imagineClickGreenFlag": "Başlamak için Yeşil Bayrağa tıklayın",
    "gui.howtos.imagine.step_imagineChooseBackdrop": "Herhangi Bir Zemin Seçin",
    "gui.howtos.imagine.step_imagineChooseSprite": "Herhangi Bir Sprite Seçin",
    "gui.howtos.imagine.step_imagineFlyAround": "Kaymak için Boşluk Tuşuna Basın",
    "gui.howtos.imagine.step_imagineChooseAnotherSprite": "Choose Another Sprite",
    "gui.howtos.imagine.step_imagineLeftRight": "Sol-Sağa Hareket Et",
    "gui.howtos.imagine.step_imagineUpDown": "Yukarı-Aşağı Taşı",
    "gui.howtos.imagine.step_imagineChangeCostumes": "Kostümleri Değiştir",
    "gui.howtos.imagine.step_imagineGlideToPoint": "Bir Noktaya Koy",
    "gui.howtos.imagine.step_imagineGrowShrink": "Büyü ve küçül",
    "gui.howtos.imagine.step_imagineChooseAnotherBackdrop": "Başka Bir Zemin Seçin",
    "gui.howtos.imagine.step_imagineSwitchBackdrops": "Arka Plan Değiştir",
    "gui.howtos.imagine.step_imagineRecordASound": "Ses Ekle",
    "gui.howtos.imagine.step_imagineChooseSound": "Sesinizi Seçin",
    "gui.howtos.make-music.name": "Müzik yap",
    "gui.howtos.Make-Music.step_PickInstrument": "Bir Enstrüman Sprite Seçin",
    "gui.howtos.Make-Music.step_PlaySoundClick": "Tıklandığında Ses Çal",
    "gui.howtos.Make-Music.step_MakeSong": "Şarkı Oluştur",
    "gui.howtos.make-music.step_MakeBeat": "Bir Davul Seçin ve Ritim Yapın",
    "gui.howtos.make-music.step_MakeBeatBox": "Mikrofon Sprite ve Sürpriz Beatbox'ı seçin",
    "gui.howtos.story.name": "Hikaye Oluştur",
    "gui.howtos.story.step_addbg": "Arka Plan Ekle",
    "gui.howtos.story.step_addsprite": "Karakter Ekle",
    "gui.howtos.story.step_saysomething": "Bir şey söyle",
    "gui.howtos.story.step_addanothersprite": "Başka Bir Karakter Ekle",
    "gui.howtos.story.step_flip": "Çevirme Yönü",
    "gui.howtos.story.step_conversation": "Görüşme yap",
    "gui.howtos.story.addanotherbg": "Başka Bir Arka Plan Ekle",
    "gui.howtos.story.step_swithbg": "Arka Plan Değiştir",
    "gui.howtos.story.step_hidewizard": "Karakter Gizle",
    "gui.howtos.story.step_showwizard": "Bir Karakter Göster",
    "gui.howtos.make-a-chase-game.name": "Kovalama Oyunu Yap",
    "gui.howtos.Chase-Game.step_BG": "Arka Plan Ekle",
    "gui.howtos.chase-game.step_AddOcto": "Bir Sprite Ekle",
    "gui.howtos.make-music.step_LeftRight": "Yön Tuşları ile Sağa ve Sola Hareket Edin",
    "gui.howtos.Chase-Game.step_UpDown": "Ok Tuşlarıyla Yukarı ve Aşağı Hareket Et",
    "gui.howtos.Chase-Game.step_AddStar": "Başka Bir Sprite Ekle",
    "gui.howtos.Chase-Game.step_MoveRandom": "Rastgele Hareket Et",
    "gui.howtos.Chase-Game.step_WhenTouch": "Octopus Sprite'da, Play Sound'a Dokunulduğunda",
    "gui.howtos.Chase-Game.step_ScoreVariable": "Puan Değişkeni Oluştur",
    "gui.howtos.Chase-Game.ScoreWhenTouch": "Octopus Sprite'da, Puan Ekle'ye dokunulduğunda",
    "gui.howtos.animate-char.name": "Bir Karakteri Canlandır",
    "gui.howtos.animate-char.step_addbg": "Arka Plan Ekle",
    "gui.howtos.animate-char.step_addsprite": "Bir Sprite Ekle",
    "gui.howtos.animate-char.step_saysomething": "Bir şey söyle",
    "gui.howtos.animate-char.step_addsound": "Ses Ekle",
    "gui.howtos.animate-char.step_animatetalking": "Konuşmayı Canlandır",
    "gui.howtos.animate-char.step_arrowkeys": "Ok Tuşlarını Kullanarak Hareket Etme",
    "gui.howtos.animate-char.step_jump": "Zıpla",
    "gui.howtos.animate-char.step_changecolor": "Rengi değiştir",
    "gui.howtos.make-a-game.name": "Tıklama Oyunu Yap",
    "gui.howtos.Make-A-Game.step_GamePickSprite": "Bir Sprite Seçin",
    "gui.howtos.make-a-game.step_GamePlaySound": "Tıklandığında Ses Çal",
    "gui.howtos.make-a-game.step_GameAddScore": "Puan Değişkeni Oluştur",
    "gui.howtos.make-a-game.step_GameChangeScore": "Tıklandığında Puanı Arttır",
    "gui.howtos.make-a-game.step_Random": "Rastgele bir pozisyona git",
    "gui.howtos.make-music.step_GameChangeColor": "Rengi değiştir",
    "gui.howtos.make-music.step_ResetScore": "Puanı Sıfırla",
    "gui.howtos.make-it-fly.name": "Uçmasını Sağla",
    "gui.howtos.fly.step_stepflyChooseBackdrop": "Bir Gökyüzü Arka Planı Seçin",
    "gui.howtos.add-a-move-block.step_stepflyChooseCharacter": "Bir Karakter Seçin",
    "gui.howtos.fly.step_stepflySaySomething": "Bir şey söyle",
    "gui.howtos.add-a-move-block.step_stepflyMoveArrows": "Yön Tuşları ile Hareket Et",
    "gui.howtos.fly.step_stepflyChooseObject": "Toplanacak Bir Nesne Seçin",
    "gui.howtos.add-a-move-block.step_stepflyFlyingObject": "Nesneyi Hareket Ettirin",
    "gui.howtos.add-a-move-block.step_stepflySelectFlyingSprite": "Karakterinizi Seçin",
    "gui.howtos.add-a-move-block.step_stepflyAddScore": "Puan Ekle",
    "gui.howtos.add-a-move-block.step_stepflyKeepScore": "Puanı Tut",
    "gui.howtos.add-a-move-block.step_stepflyAddScenery": "Manzara Ekle",
    "gui.howtos.add-a-move-block.step_stepflyMoveScenery": "Manzarayı Hareket Ettir",
    "gui.howtos.add-a-move-block.step_stepflySwitchLooks": "Sonraki Kostüm",
    "gui.howtos.pong": "Pong Oyunu",
    "gui.howtos.pong.step_pongAddBackdrop": "Arka Plan Ekle",
    "gui.howtos.pong.step_pongAddBallSprite": "Top Sprite Ekle",
    "gui.howtos.pong.step_pongBounceAround": "Zıpla",
    "gui.howtos.pong.step_pongAddPaddle": "Bir Kürek Ekle",
    "gui.howtos.pong.step_pongMoveThePaddle": "Küreği hareket ettirin",
    "gui.howtos.pong.step_pongSelectBallSprite": "Ball Sprite'ı seçin",
    "gui.howtos.pong.step_pongAddMoreCodeToBall": "Topu Raketten Zıplamak için Kod Ekle",
    "gui.howtos.pong.step_pongAddAScore": "Puan Ekle",
    "gui.howtos.pong.step_pongChooseScoreFromMenu": "Menüden 'Puan'ı seçin",
    "gui.howtos.pong.step_pongInsertChangeScoreBlock": "'Puan Değiştir' Bloğu Ekle",
    "gui.howtos.pong.step_pongResetScore": "Puanı Sıfırla",
    "gui.howtos.pong.step_pongAddLineSprite": "Çizgi Sprite'ı ekleyin",
    "gui.howtos.pong.step_pongGameOver": "Oyun bitti",
    "gui.howtos.code-cartoon": "Çizgi Film Kodla",
    "gui.howtos.code-cartoon.step_codeCartoonSaySomething": "Yeşil Bayrağa Tıkladığınızda Bir Şey Söyleyin",
    "gui.howtos.code-cartoon.step_codeCartoonAnimate": "Tıkladığınızda Bir Karakteri Canlandırın",
    "gui.howtos.code-cartoon.step_codeCartoonSelectDifferentCharacter": "Farklı Bir Karakter Seçin",
    "gui.howtos.code-cartoon.step_codeCartoonUseMinusSign": "Küçültmek için Eksi İşareti Kullanın",
    "gui.howtos.code-cartoon.step_codeCartoonGrowShrink": "Bir Karakteri Büyüt ve Küçült",
    "gui.howtos.code-cartoon.step_codeCartoonSelectDifferentCharacter2": "Farklı Bir Karakter Seçin",
    "gui.howtos.code-cartoon.step_codeCartoonJump": "Aşağı yukarı zıpla",
    "gui.howtos.code-cartoon.step_codeCartoonChangeScenes": "Sahneleri Değiştirmek İçin Bir Karaktere Tıklayın",
    "gui.howtos.code-cartoon.step_codeCartoonGlideAround": "Etrafında süzül",
    "gui.howtos.code-cartoon.step_codeCartoonChangeCostumes": "Kostümleri Değiştir",
    "gui.howtos.code-cartoon.step_codeCartoonChooseMoreCharacters": "Çizgi Filminize Eklemek İçin Daha Fazla Karakter Seçin",
    "gui.howtos.cartoon-network": "Bir Macera Oyunu Canlandırın",
    "gui.howtos.cartoon-network.step_CNcharacter": "Gösterilecek Karakteri Seçin",
    "gui.howtos.cartoon-network.step_CNsay": "Bir şey söyle",
    "gui.howtos.cartoon-network.step_CNglide": "Etrafında süzül",
    "gui.howtos.cartoon-network.step_CNpicksprite": "Kovalamak için Bir Nesne Seçin",
    "gui.howtos.cartoon-network.step_CNcollect": "Nesneleri Topla",
    "gui.howtos.cartoon-network.step_CNvariable": "Bir Puan Değişkeni Yapın",
    "gui.howtos.cartoon-network.step_CNscore": "Puanı Tut",
    "gui.howtos.cartoon-network.step_CNbackdrop": "Seviye Atla: Arka Planı Değiştir",
    "gui.howtos.videosens.name": "Video Algılama",
    "gui.howtos.videosens.step_addextension": "Uzantı ekle",
    "gui.howtos.videosens.step_pet": "Kediyi Sev",
    "gui.howtos.videosens.step_animate": "Canlandır",
    "gui.howtos.videosens.step_pop": "Balon Patlat",
    "gui.howtos.say-it-out-loud": "Konuşan Animasyonlar Yaratın",
    "gui.howtos.say-it-out-loud.step_AddTXTextension": "Metinden Konuşmaya blokları ekleyin",
    "gui.howtos.say-it-out-loud.step_TXTSpeech": "Bir şey söyle",
    "gui.howtos.say-it-out-loud_TXTSetVoice": "Bir Ses Ayarla",
    "gui.howtos.say-it-out-loud.step_TXTMove": "Etrafında yürü",
    "gui.howtos.say-it-out-loud.step_TXTBackdrop": "Arka Plan Ekle",
    "gui.howtos.say-it-out-loud.step_TXTAddSprite": "Başka Bir Karakter Ekle",
    "gui.howtos.say-it-out-loud.step_TXTSong": "Şarkı söyle",
    "gui.howtos.say-it-out-loud.step_TXTColor": "Rengi değiştir",
    "gui.howtos.say-it-out-loud.step_TXTSpin": "Etrafında dön",
    "gui.howtos.say-it-out-loud.step_TXTGrow": "Büyü ve küçül",
    "gui.howtos.talking": "konuşan masallar",
    "gui.howtos.talking.step_talesAddExtension": "Metin-Konuşma Bloklarını Eklemek İçin Tıklayın",
    "gui.howtos.talking.step_talesChooseSprite": "Bir Sprite Seçin",
    "gui.howtos.talking.step_talesSaySomething": "Bir Karakterin Konuşmasını Sağlayın",
    "gui.howtos.talking.step_talesChooseBackdrop": "Bir Arka Plan Seçin",
    "gui.howtos.talking.step_talesSwitchBackdrop": "Sonraki Arka Plana Gitmek İçin Bir Karaktere Tıklayın",
    "gui.howtos.talking.step_talesChooseAnotherSprite": "Başka Bir Sprite Seçin",
    "gui.howtos.talking.step_talesMoveAround": "Etrafında yürü",
    "gui.howtos.talking.step_talesChooseAnotherBackdrop": "Başka Bir Zemin Seçin",
    "gui.howtos.talking.step_talesAnimateTalking": "Konuşmayı Canlandır",
    "gui.howtos.talking.step_talesChooseThirdBackdrop": "Başka Bir Zemin Seçin",
    "gui.howtos.talking.step_talesChooseSound": "Dans Edeceğiniz Bir Şarkı Seçin",
    "gui.howtos.talking.step_talesDanceMoves": "Dans hareketleri",
    "gui.howtos.talking.step_talesAskAnswer": "Algılama Kategorisinden Sor ve Cevap Bloklarını Alın",
    "gui.howtos.add-sprite.name": "Bir Sprite Ekle",
    "gui.howtos.add-sprite.step_addSprite": "Bir Sprite Ekle",
    "gui.howtos.add-a-backdrop.name": "Arka Plan Ekle",
    "gui.howtos.add-a-backdrop.step_addBackdrop": "Arka Plan Ekle",
    "gui.howtos.change-size.name": "Boyutu değiştir",
    "gui.howtos.change-size.step_changeSize": "Boyutu değiştir",
    "gui.howtos.glide-around.name": "Etrafında süzül",
    "gui.howtos.change-size.step_glideAroundBackAndForth": "Etrafında süzül",
    "gui.howtos.change-size.step_glideAroundPoint": "Bir Noktaya Koy",
    "gui.howtos.record-a-sound.name": "Ses Kaydet",
    "gui.howtos.change-size.step_recordASoundSoundsTab": "'Sesler' Sekmesine tıklayın",
    "gui.howtos.change-size.step_recordASoundClickRecord": "'Kaydet'i tıklayın'",
    "gui.howtos.change-size.step_recordASoundPressRecordButton": "Kayıt Düğmesine basın",
    "gui.howtos.change-size.step_recordASoundChooseSound": "Sesinizi Seçin",
    "gui.howtos.change-size.step_recordASoundPlayYourSound": "Sesinizi Çalın",
    "gui.howtos.spin-video.name": "Döndür",
    "gui.howtos.change-size.step_spinTurn": "Dönüş",
    "gui.howtos.change-size.step_spinPointInDirection": "Yönü Ayarla",
    "gui.howtos.hide-and-show.name": "Gizle ve Göster",
    "gui.howtos.change-size.step_hideAndShow": "Gizle ve Göster",
    "gui.howtos.switch-costume.name": "Bir Sprite'ı Canlandırın",
    "gui.howtos.change-size.step_switchCostumes": "Bir Sprite'ı Canlandırın",
    "gui.howtos.move-around-with-arrow-keys.name": "Ok tuşlarını kullan",
    "gui.howtos.add-a-backdrop.step_moveArrowKeysLeftRight": "Sola ve Sağa Taşı",
    "gui.howtos.add-a-backdrop.step_moveArrowKeysUpDown": "Yukarı ve aşağı hareket et",
    "gui.howtos.add-effects.name": "Efekt ekle",
    "gui.howtos.videosens.step_addEffects": "Efekt ekle",
    "gui.device.unselectDevice.name": "Cihazın seçimini kaldır",
    "gui.device.unselectDevice.description": "Cihazın seçimini kaldırın, saf gerçek zamanlı programlama moduna dönün.",
    "gui.device.arduinoUno.description": "Elektronik ve kodlamaya başlamak için harika bir tahta.",
    "gui.device.arduino.connectingMessage": "Bağlanıyor",
    "gui.device.arduinoNano.description": "Arduino Nano, projelerinizi oluşturmak için klasik bir küçük tahtadır.",
    "gui.device.arduinoMini.description": "Arduino Mini, projelerinizi oluşturmak için klasik bir küçük tahta.",
    "gui.device.arduinoLeonardo.description": "Fare veya klavye görevi görebilen klasik Arduino kartı.",
    "gui.device.arduinoMega2560.description": "54 dijital pinli, 16 analog girişli ve 4 seri portlu 8 bitlik kart.",
    "gui.device.arduinoEsp32.description": "Zengin işlevlere sahip Wi-Fi ve Bluetooth kontrol panosu.",
    "gui.device.arduinoEsp32.connectingMessage": "Bağlanıyor",
    "gui.device.arduinoEsp8266.description": "Düşük maliyetli Wi-Fi SOC kontrol panosu.",
    "gui.device.arduinoEsp8266.connectingMessage": "Bağlanıyor",
    "gui.device.microbit.description": "Cep boyutunda bilgisayar, dijital beceri öğrenimini dönüştürüyor.",
    "gui.device.microbit.connectingMessage": "Bağlanıyor",
    "gui.device.microbitV2.description": "Yükseltilmiş işlemci, dahili hoparlör ve mikrofon, dokunmaya duyarlı logo.",
    "gui.device.makeymakey.description": "Her şeyi bir anahtara dönüştürün.",
    "gui.extension.music.name": "Müzik",
    "gui.extension.music.description": "Enstrüman ve davul çalın.",
    "gui.extension.pen.name": "Kalem",
    "gui.extension.pen.description": "Sprite'larınızla çizin.",
    "gui.extension.videosensing.name": "Video Algılama",
    "gui.extension.videosensing.description": "Kamera ile hareketi hissedin.",
    "gui.extension.text2speech.name": "Konuşma metni",
    "gui.extension.text2speech.description": "Projelerinizi konuşturun.",
    "gui.extension.translate.name": "Çevir",
    "gui.extension.translate.description": "Metni birçok dile çevirin.",
    "gui.extension.makeymakey.name": "Makey Makey",
    "gui.extension.makeymakey.description": "Her şeyi bir anahtara dönüştürün.",
    "gui.libraryTags.all": "Herşey",
    "gui.libraryTags.animals": "Hayvanlar",
    "gui.libraryTags.dance": "Dans",
    "gui.libraryTags.effects": "Efektler",
    "gui.libraryTags.fantasy": "Fantastik",
    "gui.libraryTags.fashion": "Moda",
    "gui.libraryTags.food": "Yemek",
    "gui.libraryTags.indoors": "Ev",
    "gui.libraryTags.loops": "Döngüler",
    "gui.libraryTags.music": "Müzik",
    "gui.libraryTags.notes": "Not",
    "gui.libraryTags.outdoors": "Açık hava",
    "gui.libraryTags.patterns": "Desenler",
    "gui.libraryTags.people": "İnsanlar",
    "gui.libraryTags.percussion": "Vurmalı Çalgılar",
    "gui.libraryTags.space": "Uzay",
    "gui.libraryTags.sports": "Spor",
    "gui.libraryTags.underwater": "Su altı",
    "gui.libraryTags.voice": "Ses",
    "gui.libraryTags.wacky": "Tuhaf",
    "gui.libraryTags.animation": "Animasyon",
    "gui.libraryTags.art": "Sanat",
    "gui.libraryTags.games": "Oyun",
    "gui.libraryTags.stories": "Hikaye",
    "gui.libraryTags.letters": "Edebiyat",
    "gui.opcodeLabels.direction": "yön",
    "gui.opcodeLabels.xposition": "x pozisyonu",
    "gui.opcodeLabels.yposition": "y pozisyonu",
    "gui.opcodeLabels.size": "boyut",
    "gui.opcodeLabels.costumename": "kostüm adı",
    "gui.opcodeLabels.costumenumber": "kostüm numarası",
    "gui.opcodeLabels.backdropname": "zemin adı",
    "gui.opcodeLabels.backdropnumber": "arka plan numarası",
    "gui.opcodeLabels.volume": "ses",
    "gui.opcodeLabels.tempo": "tempo",
    "gui.opcodeLabels.answer": "cevap",
    "gui.opcodeLabels.loudness": "ses yüksekliği",
    "gui.opcodeLabels.username": "Kullanıcı adı",
    "gui.opcodeLabels.year": "yıl",
    "gui.opcodeLabels.month": "ay",
    "gui.opcodeLabels.date": "tarih",
    "gui.opcodeLabels.dayofweek": "haftanın günü",
    "gui.opcodeLabels.hour": "saat",
    "gui.opcodeLabels.minute": "dakika",
    "gui.opcodeLabels.second": "saniye",
    "gui.opcodeLabels.timer": "zamanlayıcı",
    "gui.projectLoader.loadError": "Seçilen proje dosyası yüklenemedi.",
    "gui.sharedMessages.backdrop": "dekor{index}",
    "gui.sharedMessages.costume": "kostüm{index}",
    "gui.sharedMessages.sprite": "Sprite{index}",
    "gui.sharedMessages.pop": "geri",
    "gui.sharedMessages.replaceProjectWarning": "Mevcut projenin içeriği değiştirilsin mi?",
    "gui.sharedMessages.clearCacheWarning": "Önbelleği temizlemek ve yeniden başlatmak istediğinizden emin misiniz?",
    "gui.sharedMessages.loadFromComputerTitle": "Bilgisayarınızdan yükleyin",
    "gui.sharedMessages.openProjectsFromServer": "Projelerim",
    "gui.gui.defaultProjectTitle": "ZTech Proje",
    "arduinoEsp32.InterrupModeMenu.changeEdge": "kenarı değiştir",
    "arduinoEsp32.InterrupModeMenu.fallingEdge": "düşen kenar",
    "arduinoEsp32.InterrupModeMenu.high": "yüksek",
    "arduinoEsp32.InterrupModeMenu.low": "düşük",
    "arduinoEsp32.InterrupModeMenu.risingEdge": "yükselen kenar",
    "arduinoEsp32.category.data": "Data",
    "arduinoEsp32.category.sensor": "Sensor",
    "arduinoEsp32.category.serial": "Seri",
    "arduinoEsp32.data.dataConstrain": "[DATA] yı ([ARG0], [ARG1]) arasında sınırla",
    "arduinoEsp32.data.dataConvert": "çevir [DATA] to [TYPE]",
    "arduinoEsp32.data.dataConvertASCIICharacter": "çevir [DATA] to ASCII character",
    "arduinoEsp32.data.dataConvertASCIINumber": "çevir [DATA] to ASCII nubmer",
    "arduinoEsp32.data.dataMap": "map [DATA] den ([ARG0], [ARG1]) to ([ARG2], [ARG3])",
    "arduinoEsp32.dataTypeMenu.decimal": "decimal",
    "arduinoEsp32.dataTypeMenu.string": "string",
    "arduinoEsp32.dataTypeMenu.wholeNumber": "bütün sayılar",
    "arduinoEsp32.eolMenu.noWarp": "aynı satır",
    "arduinoEsp32.eolMenu.warp": "yeni satır",
    "arduinoEsp32.levelMenu.high": "yüksek",
    "arduinoEsp32.levelMenu.low": "düşük",
    "arduinoEsp32.modeMenu.input": "giriş",
    "arduinoEsp32.modeMenu.inputPullup": "giriş-çekme",
    "arduinoEsp32.modeMenu.output": "çıkış",
    "arduinoEsp32.pins.attachInterrupt": "kesme pimi ekle [PIN] modu [MODE] yürütülür",
    "arduinoEsp32.pins.detachInterrupt": "kesme pinini [PIN] ayır",
    "arduinoEsp32.pins.esp32ReadTouchPin": "dokunmatik pini oku [PIN]",
    "arduinoEsp32.pins.readAnalogPin": "analog pini oku [PIN]",
    "arduinoEsp32.pins.readDigitalPin": "dijital pini oku [PIN]",
    "arduinoEsp32.pins.setServoOutput": "servo pini [PIN] ayarlayın, [CH] çıkışını kullanın [OUT]",
    "arduinoEsp32.sensor.readHallSensor": "okuma salonu sensörü",
    "arduinoEsp32.sensor.runningTime": "çalışma süresi (milis)",
    "arduinoEsp32.serial.multiSerialAvailable": "seri [NO] mevcut veri uzunluğu",
    "arduinoEsp32.serial.multiSerialBegin": "seri port [NO] başlat baudrate [VALUE]",
    "arduinoEsp32.serial.multiSerialPrint": "seri port [NO] yazdır [VALUE] [EOL]",
    "arduinoEsp32.serial.multiSerialReadAByte": "seri port [NO] byte oku",
    "arduinoLeonardo.category.data": "Veri",
    "arduinoLeonardo.category.pins": "Pinler",
    "arduinoLeonardo.data.dataConstrain": "kısıtlamak [DATA] arasında ([ARG0], [ARG1])",
    "arduinoLeonardo.data.dataConvert": "çevir [DATA] dan  [TYPE]",
    "arduinoLeonardo.data.dataConvertASCIICharacter": "çevir [DATA] ASCII karaktere",
    "arduinoLeonardo.data.dataConvertASCIINumber": "çevir [DATA] ASCII sayısına",
    "arduinoLeonardo.data.dataMap": "map [DATA] from ([ARG0], [ARG1]) to ([ARG2], [ARG3])",
    "arduinoLeonardo.dataTypeMenu.decimal": "decimal",
    "arduinoLeonardo.dataTypeMenu.string": "string",
    "arduinoLeonardo.dataTypeMenu.wholeNumber": "bütün sayılar",
    "arduinoLeonardo.levelMenu.high": "yüksek",
    "arduinoLeonardo.levelMenu.low": "low",
    "arduinoLeonardo.modeMenu.input": "input",
    "arduinoLeonardo.modeMenu.inputPullup": "input-pullup",
    "arduinoLeonardo.modeMenu.output": "output",
    "arduinoLeonardo.pins.attachInterrupt": "attach interrupt pin [PIN] mode [MODE] executes",
    "arduinoLeonardo.pins.detachInterrupt": "detach interrupt pin [PIN]",
    "arduinoLeonardo.pins.readAnalogPin": "read analog pin [PIN]",
    "arduinoLeonardo.pins.readDigitalPin": "read digital pin [PIN]",
    "arduinoLeonardo.pins.setDigitalOutput": "set digital pin [PIN] out [LEVEL]",
    "arduinoLeonardo.pins.setPinMode": "set pin [PIN] mode [MODE]",
    "arduinoLeonardo.pins.setPwmOutput": "set pwm pin [PIN] out [OUT]",
    "arduinoLeonardo.pins.setServoOutput": "set servo pin [PIN] out [OUT]",
    "arduinoMega2560.category.data": "Data",
    "arduinoMega2560.category.pins": "Pins",
    "arduinoMega2560.category.serial": "Serial",
    "arduinoMega2560.data.dataConstrain": "constrain [DATA] between ([ARG0], [ARG1])",
    "arduinoMega2560.data.dataConvert": "convert [DATA] to [TYPE]",
    "arduinoMega2560.data.dataConvertASCIICharacter": "convert [DATA] to ASCII character",
    "arduinoMega2560.data.dataConvertASCIINumber": "convert [DATA] to ASCII nubmer",
    "arduinoMega2560.data.dataMap": "map [DATA] from ([ARG0], [ARG1]) to ([ARG2], [ARG3])",
    "arduinoMega2560.dataTypeMenu.decimal": "decimal",
    "arduinoMega2560.dataTypeMenu.string": "string",
    "arduinoMega2560.dataTypeMenu.wholeNumber": "whole number",
    "arduinoMega2560.levelMenu.high": "high",
    "arduinoMega2560.levelMenu.low": "low",
    "arduinoMega2560.modeMenu.input": "input",
    "arduinoMega2560.modeMenu.inputPullup": "input-pullup",
    "arduinoMega2560.modeMenu.output": "output",
    "arduinoMega2560.pins.attachInterrupt": "attach interrupt pin [PIN] mode [MODE] executes",
    "arduinoMega2560.pins.detachInterrupt": "detach interrupt pin [PIN]",
    "arduinoMega2560.pins.readAnalogPin": "read analog pin [PIN]",
    "arduinoMega2560.pins.readDigitalPin": "read digital pin [PIN]",
    "arduinoMega2560.pins.setDigitalOutput": "set digital pin [PIN] out [LEVEL]",
    "arduinoMega2560.pins.setPinMode": "set pin [PIN] mode [MODE]",
    "arduinoMega2560.pins.setPwmOutput": "set pwm pin [PIN] out [OUT]",
    "arduinoMega2560.pins.setServoOutput": "set servo pin [PIN] out [OUT]",
    "arduinoMega2560.serial.multiSerialAvailable": "serial [NO] available data length",
    "arduinoMega2560.serial.multiSerialBegin": "serial [NO] begin baudrate [VALUE]",
    "arduinoMega2560.serial.multiSerialPrint": "serial [NO] print [VALUE] [EOL]",
    "arduinoMega2560.serial.multiSerialReadAByte": "serial [NO] read a byte",
    "arduinoPeripheral.connection.connectFirmataTimeout": "Timeout when try to connect firmata, please download the firmware first",
    "arduinoUno.InterrupModeMenu.changeEdge": "change edge",
    "arduinoUno.InterrupModeMenu.fallingEdge": "falling edge",
    "arduinoUno.InterrupModeMenu.low": "low",
    "arduinoUno.InterrupModeMenu.risingEdge": "rising edge",
    "arduinoUno.category.data": "Data",
    "arduinoUno.category.pins": "Pins",
    "arduinoUno.category.serial": "Serial",
    "arduinoUno.data.dataConstrain": "constrain [DATA] between ([ARG0], [ARG1])",
    "arduinoUno.data.dataConvert": "convert [DATA] to [TYPE]",
    "arduinoUno.data.dataConvertASCIICharacter": "convert [DATA] to ASCII character",
    "arduinoUno.data.dataConvertASCIINumber": "convert [DATA] to ASCII nubmer",
    "arduinoUno.data.dataMap": "map [DATA] from ([ARG0], [ARG1]) to ([ARG2], [ARG3])",
    "arduinoUno.dataTypeMenu.decimal": "decimal",
    "arduinoUno.dataTypeMenu.string": "string",
    "arduinoUno.dataTypeMenu.wholeNumber": "whole number",
    "arduinoUno.eolMenu.noWarp": "no-warp",
    "arduinoUno.eolMenu.warp": "warp",
    "arduinoUno.levelMenu.high": "high",
    "arduinoUno.levelMenu.low": "low",
    "arduinoUno.modeMenu.input": "input",
    "arduinoUno.modeMenu.inputPullup": "input-pullup",
    "arduinoUno.modeMenu.output": "output",
    "arduinoUno.pins.attachInterrupt": "attach interrupt pin [PIN] mode [MODE] executes",
    "arduinoUno.pins.detachInterrupt": "detach interrupt pin [PIN]",
    "arduinoUno.pins.readAnalogPin": "read analog pin [PIN]",
    "arduinoUno.pins.readDigitalPin": "read digital pin [PIN]",
    "arduinoUno.pins.setDigitalOutput": "set digital pin [PIN] out [LEVEL]",
    "arduinoUno.pins.setPinMode": "set pin [PIN] mode [MODE]",
    "arduinoUno.pins.setPwmOutput": "set pwm pin [PIN] out [OUT]",
    "arduinoUno.pins.setServoOutput": "set servo pin [PIN] out [OUT]",
    "arduinoUno.serial.serialAvailable": "serial available data length",
    "arduinoUno.serial.serialBegin": "serial begin baudrate [VALUE]",
    "arduinoUno.serial.serialPrint": "serial print [VALUE] [EOL]",
    "arduinoUno.serial.serialReadData": "serial read data",
    "boost.color.any": "any color",
    "boost.color.black": "siyah",
    "boost.color.blue": "mavi",
    "boost.color.green": "yeşil",
    "boost.color.red": "kırmızı",
    "boost.color.white": "beyaz",
    "boost.color.yellow": "sarı",
    "boost.getMotorPosition": "motor [MOTOR_REPORTER_ID] position",
    "boost.getTiltAngle": "tilt angle [TILT_DIRECTION]",
    "boost.motorDirection.backward": "that way",
    "boost.motorDirection.forward": "this way",
    "boost.motorDirection.reverse": "reverse",
    "boost.motorOff": "turn motor [MOTOR_ID] off",
    "boost.motorOn": "turn motor [MOTOR_ID] on",
    "boost.motorOnFor": "turn motor [MOTOR_ID] for [DURATION] seconds",
    "boost.motorOnForRotation": "turn motor [MOTOR_ID] for [ROTATION] rotations",
    "boost.seeingColor": "seeing [COLOR] brick?",
    "boost.setLightHue": "set light color to [HUE]",
    "boost.setMotorDirection": "set motor [MOTOR_ID] direction [MOTOR_DIRECTION]",
    "boost.setMotorPower": "set motor [MOTOR_ID] speed to [POWER] %",
    "boost.tiltDirection.any": "herhangi",
    "boost.tiltDirection.down": "aşağı",
    "boost.tiltDirection.left": "sol",
    "boost.tiltDirection.right": "sağ",
    "boost.tiltDirection.up": "yukaru",
    "boost.whenColor": "when [COLOR] brick seen",
    "boost.whenTilted": "when tilted [TILT_DIRECTION_ANY]",
    "esp32Arduino.category.pins": "Pins",
    "esp32Arduino.pins.esp32SetDACOutput": "set dac pin [PIN] out [OUT]",
    "esp32Arduino.pins.esp32SetPwmOutput": "set pwm pin [PIN] use channel [CH] out [OUT]",
    "esp32Arduino.pins.setDigitalOutput": "set digital pin [PIN] out [LEVEL]",
    "esp32Arduino.pins.setPinMode": "set pin [PIN] mode [MODE]",
    "ev3.beepNote": "beep note [NOTE] for [TIME] secs",
    "ev3.buttonPressed": "button [PORT] pressed?",
    "ev3.getBrightness": "brightness",
    "ev3.getDistance": "distance",
    "ev3.getMotorPosition": "motor [PORT] position",
    "ev3.motorSetPower": "motor [PORT] set power [POWER] %",
    "ev3.motorTurnClockwise": "motor [PORT] turn this way for [TIME] seconds",
    "ev3.motorTurnCounterClockwise": "motor [PORT] turn that way for [TIME] seconds",
    "ev3.whenBrightnessLessThan": "when brightness < [DISTANCE]",
    "ev3.whenButtonPressed": "when button [PORT] pressed",
    "ev3.whenDistanceLessThan": "when distance < [DISTANCE]",
    "gdxfor.getAcceleration": "acceleration [DIRECTION]",
    "gdxfor.getForce": "force",
    "gdxfor.getSpin": "spin speed [DIRECTION]",
    "gdxfor.getTilt": "tilt angle [TILT]",
    "gdxfor.isFreeFalling": "falling?",
    "gdxfor.isTilted": "tilted [TILT]?",
    "gdxfor.pulled": "pulled",
    "gdxfor.pushed": "pushed",
    "gdxfor.shaken": "shaken",
    "gdxfor.startedFalling": "started falling",
    "gdxfor.tiltDirectionMenu.any": "herhangi",
    "gdxfor.tiltDirectionMenu.back": "geri",
    "gdxfor.tiltDirectionMenu.front": "ön",
    "gdxfor.tiltDirectionMenu.left": "sol",
    "gdxfor.tiltDirectionMenu.right": "sağ",
    "gdxfor.turnedFaceDown": "yüzü aşağı dönük",
    "gdxfor.turnedFaceUp": "yüzü yukarı döndü",
    "gdxfor.whenForcePushedOrPulled": "when force sensor [PUSH_PULL]",
    "gdxfor.whenGesture": "when [GESTURE]",
    "gdxfor.whenTilted": "when tilted [TILT]",
    "makeymakey.downArrow": "aşağı ok",
    "makeymakey.downArrowShort": "aşağı",
    "makeymakey.leftArrow": "sol ok",
    "makeymakey.leftArrowShort": "sol",
    "makeymakey.rightArrow": "sağ ok",
    "makeymakey.rightArrowShort": "sağ",
    "makeymakey.spaceKey": "uzay",
    "makeymakey.upArrow": "yukarı ok",
    "makeymakey.upArrowShort": "yukarı",
    "makeymakey.whenKeyPressed": "when [KEY] key pressed",
    "makeymakey.whenKeysPressedInOrder": "when [SEQUENCE] pressed in order",
    "microbit.axisMenu.xAxis": "x-ekseni",
    "microbit.axisMenu.yAxis": "y-ekseni",
    "microbit.axisMenu.zAxis": "z-ekseni",
    "microbit.buttonsMenu.any": "herhangi",
    "microbit.category.console": "Console",
    "microbit.category.display": "Görüntüle",
    "microbit.category.pins": "Pins",
    "microbit.category.sensor": "Sensor",
    "microbit.category.wireless": "Wireless",
    "microbit.clearDisplay": "clear display",
    "microbit.console.consolePrint": "print [TEXT]",
    "microbit.defaultTextToDisplay": "Hello!",
    "microbit.display.clearDisplay": "clear screen",
    "microbit.display.lightPixelAt": "ışık [STATE] de x: [X] ekseni, y: [Y] ekseni",
    "microbit.display.show": "göster [TEXT]",
    "microbit.display.showImage": "resmi göster [VALUE]",
    "microbit.display.showImageUntil": "resmi göster [VALUE] for [TIME] secs",
    "microbit.display.showOnPiexlbrightness": "show on the x: [X] axis, y: [Y] axis with brightness [BRT]",
    "microbit.display.showUntilScrollDone": "show [TEXT] until scroll done",
    "microbit.displaySymbol": "display [MATRIX]",
    "microbit.displayText": "display text [TEXT]",
    "microbit.gestruesMenu.faceDown": "face down",
    "microbit.gestruesMenu.faceUp": "face up",
    "microbit.gestruesMenu.freefall": "freefall",
    "microbit.gestruesMenu.shaken": "shaken",
    "microbit.gestruesMenu.tiltedDownward": "tilted downward",
    "microbit.gestruesMenu.tiltedLeftward": "tilted leftward",
    "microbit.gestruesMenu.tiltedRightward": "tilted rightward",
    "microbit.gestruesMenu.tiltedUpward": "tilted upward",
    "microbit.gesturesMenu.jumped": "jumped",
    "microbit.gesturesMenu.moved": "moved",
    "microbit.gesturesMenu.shaken": "shaken",
    "microbit.isButtonPressed": "[BTN] button pressed?",
    "microbit.isTilted": "tilted [DIRECTION]?",
    "microbit.ledState.off": "off",
    "microbit.ledState.on": "on",
    "microbit.levelMenu.high": "high",
    "microbit.levelMenu.low": "low",
    "microbit.pinStateMenu.off": "off",
    "microbit.pinStateMenu.on": "on",
    "microbit.pins.pinIsTouched": "pin [PIN] is touched",
    "microbit.pins.readAnalogPin": "read analog pin [PIN]",
    "microbit.pins.readDigitalPin": "read digital pin [PIN]",
    "microbit.pins.setDigitalOutput": "set digital pin [PIN] out [LEVEL]",
    "microbit.pins.setPwmOutput": "set pwm pin [PIN] out [OUT]",
    "microbit.sensor.axisAcceleration": "[AXIS] axis acceleration",
    "microbit.sensor.buttonIsPressed": "[KEY] button is pressed?",
    "microbit.sensor.calibrateCompass": "calibrate compass",
    "microbit.sensor.compassAngle": "compass angle",
    "microbit.sensor.compassMagneticDensity": "compass magnetic density",
    "microbit.sensor.gestureIsX": "gestrue is [STA]?",
    "microbit.sensor.lightLevel": "light level",
    "microbit.sensor.runningTime": "running time",
    "microbit.sensor.temperature": "temperature",
    "microbit.tiltAngle": "tilt angle [DIRECTION]",
    "microbit.tiltDirectionMenu.any": "any",
    "microbit.tiltDirectionMenu.back": "back",
    "microbit.tiltDirectionMenu.front": "front",
    "microbit.tiltDirectionMenu.left": "left",
    "microbit.tiltDirectionMenu.right": "right",
    "microbit.whenButtonPressed": "when [BTN] button pressed",
    "microbit.whenGesture": "when [GESTURE]",
    "microbit.whenPinConnected": "when pin [PIN] connected",
    "microbit.whenTilted": "when tilted [DIRECTION]",
    "microbit.wireless.closeWirelessCommunication": "close wireless communication",
    "microbit.wireless.openWirelessCommunication": "open wireless communication",
    "microbit.wireless.receiveWirelessMessage": "receive wireless message",
    "microbit.wireless.resetWirelessCommunication": "reset wireless communication",
    "microbit.wireless.sendWirelessMessage": "kablosuz mesaj gönder [TEXT]",
    "microbit.wireless.setWirelessCommunicationChannel": "kablosuz iletişim kanalını [CH] olarak ayarla",
    "music.categoryName": "Müzik",
    "music.changeTempo": "[TEMPO] ile tempoyu değiştirin",
    "music.drumBass": "(2) Bass Drum",
    "music.drumBongo": "(13) Bongo",
    "music.drumCabasa": "(15) Cabasa",
    "music.drumClaves": "(9) Claves",
    "music.drumClosedHiHat": "(6) Closed Hi-Hat",
    "music.drumConga": "(14) Conga",
    "music.drumCowbell": "(11) Cowbell",
    "music.drumCrashCymbal": "(4) Crash Cymbal",
    "music.drumCuica": "(18) Cuica",
    "music.drumGuiro": "(16) Guiro",
    "music.drumHandClap": "(8) Hand Clap",
    "music.drumOpenHiHat": "(5) Open Hi-Hat",
    "music.drumSideStick": "(3) Side Stick",
    "music.drumSnare": "(1) Snare Drum",
    "music.drumTambourine": "(7) Tambourine",
    "music.drumTriangle": "(12) Triangle",
    "music.drumVibraslap": "(17) Vibraslap",
    "music.drumWoodBlock": "(10) Wood Block",
    "music.getTempo": "tempo",
    "music.instrumentBass": "(6) Bass",
    "music.instrumentBassoon": "(14) Bassoon",
    "music.instrumentCello": "(8) Cello",
    "music.instrumentChoir": "(15) Koro",
    "music.instrumentClarinet": "(10) Klarnet",
    "music.instrumentElectricGuitar": "(5) Elektro gitar",
    "music.instrumentElectricPiano": "(2) Elektrikli Piano",
    "music.instrumentFlute": "(12) Flüt",
    "music.instrumentGuitar": "(4) Gitar",
    "music.instrumentMarimba": "(19) Marimba",
    "music.instrumentMusicBox": "(17) Müzik kutusu",
    "music.instrumentOrgan": "(3) Organ",
    "music.instrumentPiano": "(1) Piano",
    "music.instrumentPizzicato": "(7) Pizzicato",
    "music.instrumentSaxophone": "(11) saksafon",
    "music.instrumentSteelDrum": "(18) Steel Drum",
    "music.instrumentSynthLead": "(20) sentetik kurşun",
    "music.instrumentSynthPad": "(21) Synth Pad",
    "music.instrumentTrombone": "(9) Trombon",
    "music.instrumentVibraphone": "(16) Vibrafon",
    "music.instrumentWoodenFlute": "(13) Tahta Flüt",
    "music.midiPlayDrumForBeats": "[BEATS] vuruşları için davul [DRUM] çalındı",
    "music.midiSetInstrument": "enstrümanı [INSTRUMENT] olarak ayarlayın",
    "music.playDrumForBeats": "[BEATS] vuruşları için davul [DRUM] çalın",
    "music.playNoteForBeats": "[BEATS] vuruşları için [NOTE] notasını çal",
    "music.restForBeats": "[BEATS] vuruş için dinlenme",
    "music.setInstrument": "enstrümanı [INSTRUMENT] olarak ayarlayın",
    "music.setTempo": "tempoyu ayarla [TEMPO]",
    "pen.categoryName": "Kalem",
    "pen.changeColorParam": "[COLOR_PARAM] kalemini [VALUE] ile değiştir",
    "pen.changeHue": "kalem rengini değiştir [HUE]",
    "pen.changeShade": "kalem gölgesini değiştir [SHADE]",
    "pen.changeSize": "kalem boyutunu değiştir [SIZE]",
    "pen.clear": "hepsini sil",
    "pen.colorMenu.brightness": "parlaklık",
    "pen.colorMenu.color": "renkler",
    "pen.colorMenu.saturation": "doyma",
    "pen.colorMenu.transparency": "şeffaflık",
    "pen.penDown": "kalem aşağı",
    "pen.penUp": "kalem yukarı",
    "pen.setColor": "kalem rengini [COLOR] olarak ayarla",
    "pen.setColorParam": "[COLOR_PARAM] kalemini [VALUE] olarak ayarla",
    "pen.setHue": "kalem rengini [HUE] olarak ayarlayın",
    "pen.setShade": "kalem gölgesini [SHADE] olarak ayarlayın",
    "pen.setSize": "kalem boyutunu [SIZE] olarak ayarla",
    "pen.stamp": "pul",
    "speech.defaultWhenIHearValue": "Hadi gidelim",
    "speech.extensionName": "Konuşmadan yazıya",
    "speech.listenAndWait": "dinle ve bekle",
    "speech.speechReporter": "konuş",
    "speech.whenIHear": "duyduğumda [PHRASE]",
    "text2speech.alto": "alto",
    "text2speech.categoryName": "Konuşma metni",
    "text2speech.defaultTextToSpeak": "merhaba",
    "text2speech.giant": "devasa",
    "text2speech.kitten": "kedi yavrusu",
    "text2speech.setLanguageBlock": "dili ayarla [LANGUAGE]",
    "text2speech.setVoiceBlock": "sesi ayarla[VOICE]",
    "text2speech.speakAndWaitBlock": "konuşmak [WORDS]",
    "text2speech.squeak": "gıcırtı",
    "text2speech.tenor": "tenor",
    "translate.categoryName": "Çevir",
    "translate.defaultTextToTranslate": "merhaba",
    "translate.translateBlock": " [WORDS] den çevir [LANGUAGE]",
    "translate.viewerLanguage": "dil",
    "videoSensing.categoryName": "Video Algılama",
    "videoSensing.direction": "yön",
    "videoSensing.motion": "hareket",
    "videoSensing.off": "kapalı",
    "videoSensing.on": "açık",
    "videoSensing.onFlipped": "ters çevrilmiş",
    "videoSensing.setVideoTransparency": "video şeffaflığını şuna ayarla[TRANSPARENCY]",
    "videoSensing.sprite": "sprite",
    "videoSensing.stage": "sahne",
    "videoSensing.videoOn": "video [ATTRIBUTE] on [SUBJECT]",
    "videoSensing.videoToggle": "videoyu çevir[VIDEO_STATE]",
    "videoSensing.whenMotionGreaterThan": "video hareketi ne zaman > [REFERENCE]",
    "wedo2.getDistance": "mesafe",
    "wedo2.getTiltAngle": "eğim açısı[TILT_DIRECTION]",
    "wedo2.isTilted": "eğik [TILT_DIRECTION_ANY]?",
    "wedo2.motorDirection.backward": "bu şekilde",
    "wedo2.motorDirection.forward": "Bu taraftan",
    "wedo2.motorDirection.reverse": "ters",
    "wedo2.motorId.a": "motor A",
    "wedo2.motorId.all": "tüm motorlar",
    "wedo2.motorId.b": "motor B",
    "wedo2.motorId.default": "motor",
    "wedo2.motorOff": "[MOTOR_ID]'yi kapat",
    "wedo2.motorOn": "[MOTOR_ID]'yi aç",
    "wedo2.motorOnFor": "[DURATION] saniye boyunca [MOTOR_ID] özelliğini açın",
    "wedo2.playNoteFor": "[DURATION] saniye boyunca [NOTE] notunu çal",
    "wedo2.setLightHue": "açık rengi [HUE] olarak ayarlayın",
    "wedo2.setMotorDirection": "[MOTOR_ID] yönünü [MOTOR_DIRECTION] olarak ayarla",
    "wedo2.startMotorPower": "[MOTOR_ID] gücünü [POWER] olarak ayarlayın",
    "wedo2.tiltDirection.any": "herhangi",
    "wedo2.tiltDirection.down": "aşağı",
    "wedo2.tiltDirection.left": "sol",
    "wedo2.tiltDirection.right": "sağ",
    "wedo2.tiltDirection.up": "yukarı",
    "wedo2.whenDistance": "mesafe oldukça [OP] [REFERENCE]",
    "wedo2.whenTilted": "eğildiğinde [TILT_DIRECTION_ANY]",
    "paint.colorPicker.swap": "Takas",
    "paint.paintEditor.hue": "Renk",
    "paint.paintEditor.saturation": "Doyma",
    "paint.paintEditor.brightness": "Parlaklık",
    "paint.paintEditor.costume": "Kostüm",
    "paint.paintEditor.group": "Grup",
    "paint.paintEditor.ungroup": "Grubu çöz",
    "paint.paintEditor.undo": "Geri alma",
    "paint.paintEditor.redo": "Yeniden yap",
    "paint.paintEditor.forward": "İleriye",
    "paint.paintEditor.backward": "Geriye",
    "paint.paintEditor.front": "Ön",
    "paint.paintEditor.back": "Geri",
    "paint.paintEditor.more": "Daha fazla",
    "paint.modeTools.brushSize": "Boyut",
    "paint.modeTools.eraserSize": "Silgi boyutu",
    "paint.modeTools.copy": "Kopyala",
    "paint.modeTools.paste": "Yapıştır",
    "paint.modeTools.delete": "Sil",
    "paint.modeTools.curved": "kavisli",
    "paint.modeTools.pointed": "İşaretlendi",
    "paint.modeTools.thickness": "Kalınlık",
    "paint.modeTools.flipHorizontal": "Yatay çevirmek",
    "paint.modeTools.flipVertical": "Dikey Çevir",
    "paint.modeTools.filled": "Dolu",
    "paint.modeTools.outlined": "İçi Boş",
    "paint.paintEditor.bitmap": "Bitmap'e Dönüştür",
    "paint.paintEditor.vector": "Vektöre Dönüştür",
    "paint.paintEditor.fill": "Doldur",
    "paint.paintEditor.stroke": "anahat",
    "paint.brushMode.brush": "Fırçalamak",
    "paint.eraserMode.eraser": "Silgi",
    "paint.fillMode.fill": "Doldur",
    "paint.lineMode.line": "Çizgi",
    "paint.ovalMode.oval": "Daire",
    "paint.rectMode.rect": "Dikdörtgen",
    "paint.reshapeMode.reshape": "yeniden şekillendir",
    "paint.roundedRectMode.roundedRect": "Yuvarlarılmış dikdörtgen",
    "paint.selectMode.select": "Seç",
    "paint.textMode.text": "Metin",
	  "gui.alerts.codeEditorIsLocked": "Kod düzenleyici kilit durumunda, kodu düzenlemek istiyorsanız, lütfen önce kilidini açın",
	  "gui.alerts.codeEditorIsUnlocked": "Kod düzenleyici kilit açma durumunda, bloktan kod oluşturmak istiyorsanız lütfen önce kilitleyin",
  },
  "zh-cn": {
    "gui.alerts.lostPeripheralConnection": "ZTech与{extensionName}的连接已断开。",
    "gui.alerts.tryAgain": "重试",
    "gui.alerts.download": "下载",
    "gui.connection.reconnect": "重新连接",
    "gui.alerts.downloadFirmware": "下载固件",
    "gui.backpack.costumeLabel": "造型",
    "gui.backpack.soundLabel": "声音",
    "gui.backpack.scriptLabel": "脚本",
    "gui.backpack.spriteLabel": "角色",
    "gui.backpack.header": "书包",
    "gui.backpack.errorBackpack": "载入书包时出错了",
    "gui.backpack.loadingBackpack": "载入中……",
    "gui.backpack.more": "更多",
    "gui.backpack.emptyBackpack": "书包是空的",
    "gui.unsupportedBrowser.label": "不支持当前浏览器",
    "gui.unsupportedBrowser.errorLabel": "出错了",
    "gui.unsupportedBrowser.notRecommended": "我们十分抱歉，ZTech不支持您正在使用的浏览器，我们建议您升级到我们所支持的浏览器的最新版本，例如Chrome、火狐、Edge或者Safari。",
    "gui.unsupportedBrowser.description": "我们十分抱歉，ZTech并不支持您当前使用的浏览器，我们建议您升级到我们所支持的浏览器的最新版，例如Chrome、火狐、Edge或者Safari。",
    "gui.unsupportedBrowser.back": "返回",
    "gui.unsupportedBrowser.previewfaq": "访问{previewFaqLink}，了解更多。",
    "gui.unsupportedBrowser.previewfaqlinktext": "常见问题",
    "gui.cards.all-tutorials": "教程",
    "gui.cards.shrink": "收起",
    "gui.cards.expand": "展开",
    "gui.cards.close": "关闭",
    "gui.cards.more-things-to-try": "再试试这些！",
    "gui.cards.see-more": "更多技巧",
    "gui.comingSoon.message1": "别急，我们还在做这个 {emoji}",
    "gui.comingSoon.message2": "即将启用...",
    "gui.comingSoon.message3": "还在开发 {emoji}",
    "gui.connection.auto-scanning.noPeripheralsFound": "没有发现任何设备",
    "gui.connection.auto-scanning.prescan": "把设备放在边上，然后开始搜索",
    "gui.connection.auto-scanning.pressbutton": "按下设备上的按钮",
    "gui.connection.auto-scanning.start-search": "开始搜索",
    "gui.connection.connecting-searchbutton": "正在搜索……",
    "gui.connection.auto-scanning.try-again": "重试",
    "gui.connection.connected": "已连接",
    "gui.connection.disconnect": "断开连接",
    "gui.connection.go-to-editor": "返回编辑器",
    "gui.connection.connecting-cancelbutton": "正在连接……",
    "gui.connection.error.errorMessage": "呀，好像出错了。",
    "gui.connection.error.tryagainbutton": "重试",
    "gui.connection.error.helpbutton": "帮助",
    "gui.connection.peripheral-name-label": "设备名称",
    "gui.connection.connect": "连接",
    "gui.connection.scanning.listAll": "显示全部可连接的设备",
    "gui.connection.scanning.lookingforperipherals": "正在查找设备",
    "gui.connection.scanning.noPeripheralsFound": "没有发现任何设备",
    "gui.connection.scanning.instructions": "选择上面列出的设备。",
    "gui.connection.search": "刷新",
    "gui.connection.unavailable.installscratchlink": "确保ZTech Link已安装并正在运行",
    "gui.connection.unavailable.connectUsbEnableBluetooth": "请检查USB线缆已连接或蓝牙已经开启",
    "gui.connection.unavailable.tryagainbutton": "重试",
    "gui.connection.unavailable.helpbutton": "帮助",
    "gui.controls.go": "运行",
    "gui.controls.stop": "停止",
    "gui.crashMessage.label": "呀，出错了。",
    "gui.crashMessage.description": "很抱歉，ZTech出故障了。这个问题已经自动报告给ZTech开发团队。请刷新页面再试试。",
    "gui.crashMessage.errorNumber": "该错误已记录，编号{errorId}",
    "gui.crashMessage.reload": "重新载入",
    "gui.customProcedures.myblockModalTitle": "制作新的积木",
    "gui.customProcedures.addAnInputNumber": "添加输入项",
    "gui.customProcedures.numberType": "数字",
    "gui.customProcedures.addAnInputText": "添加输入项",
    "gui.customProcedures.TextType": "文本",
    "gui.customProcedures.addAnInputBoolean": "添加输入项",
    "gui.customProcedures.booleanType": "布尔值",
    "gui.customProcedures.addALabel": "添加文本标签：",
    "gui.customProcedures.runWithoutScreenRefresh": "运行时不刷新屏幕",
    "gui.customProcedures.cancel": "取消",
    "gui.customProcedures.ok": "完成",
    "gui.SpriteInfo.direction": "方向",
    "gui.directionPicker.rotationStyles.allAround": "任意旋转",
    "gui.directionPicker.rotationStyles.leftRight": "左右翻转",
    "gui.directionPicker.rotationStyles.dontRotate": "不旋转",
    "gui.gui.addExtension": "添加扩展",
    "gui.gui.codeTab": "代码",
    "gui.gui.backdropsTab": "背景",
    "gui.gui.costumesTab": "造型",
    "gui.gui.soundsTab": "声音",
    "gui.hardwareConsole.send": "发送",
    "gui.hardwareConsole.buadrate": "波特率",
    "gui.hardwareConsole.endOfLine": "行结尾",
    "gui.hardwareConsole.hexform": "16进制格式",
    "gui.hardwareConsole.autoScroll": "自动滚屏",
    "gui.hardwareHeader.upload": "上传",
    "gui.stageHeader.codeStageSizeLarge": "放大舞台",
    "gui.stageHeader.codeStageSizeSmall": "缩小舞台",
    "gui.stageHeader.hideStage": "隐藏舞台",
    "gui.extensionLibrary.comingSoon": "即将启用",
    "gui.extensionLibrary.requires": "系统需求",
    "gui.extensionLibrary.collaboration": "合作者",
    "gui.extensionLibrary.manufactor": "制造商",
    "gui.extensionLibrary.version": "版本",
    "gui.extensionLibrary.author": "作者",
    "gui.extensionLibrary.programMode": "编程模式",
    "gui.extensionLibrary.programLanguage": "编程语言",
    "gui.extensionLibrary.deviceLearnMore": "了解更多",
    "gui.extensionLibrary.extensionHelp": "帮助",
    "gui.extensionLibrary.Processing": "处理中...",
    "gui.extensionLibrary.loaded": "已加载",
    "gui.extensionLibrary.notLoaded": "未加载",
    "gui.library.filterPlaceholder": "搜索",
    "gui.library.allTag": "所有",
    "gui.loader.message1": "正在创建积木……",
    "gui.loader.message2": "载入角色……",
    "gui.loader.message3": "载入声音……",
    "gui.loader.message4": "加载扩展……",
    "gui.loader.message5": "呼唤小猫……",
    "gui.loader.message6": "传送Nano……",
    "gui.loader.message7": "给Gobo充气 …",
    "gui.loader.message8": "准备表情……",
    "gui.loader.headline": "载入作品",
    "gui.loader.creating": "新建作品",
    "gui.accountMenu.profile": "个人资料",
    "gui.accountMenu.myStuff": "我的东西",
    "gui.accountMenu.myClasses": "我的所有班级",
    "gui.accountMenu.myClass": "我的班级",
    "gui.accountMenu.accountSettings": "账号设置",
    "gui.accountMenu.signOut": "登出",
    "gui.authorInfo.byUser": "作者{username}",
    "gui.menuBar.seeProjectPage": "查看作品页面",
    "general.username": "用户名",
    "general.password": "密码",
    "general.signIn": "登录",
    "login.needHelp": "需要帮助？",
    "form.validationRequired": "必填字段",
    "gui.menuBar.LanguageSelector": "语言菜单",
    "gui.menuBar.tutorialsLibrary": "教程",
    "gui.menuBar.restoreSprite": "复原删除的角色",
    "gui.menuBar.restoreSound": "复原删除的声音",
    "gui.menuBar.restoreCostume": "复原删除的造型",
    "gui.menuBar.restore": "恢复",
    "gui.menuBar.saveNow": "立即保存",
    "gui.menuBar.saveAsCopy": "保存副本",
    "gui.menuBar.remix": "改编",
    "gui.menuBar.new": "新作品",
    "gui.menuBar.checkUpdate": "检查更新",
    "gui.menuBar.installDriver": "安装驱动",
    "gui.menuBar.clearCacheAndRestart": "清除缓存并重启",
    "gui.menuBar.edit": "编辑",
    "gui.menuBar.turboModeOff": "加速模式",
    "gui.menuBar.turboModeOn": "加速模式",
    "gui.menuBar.noDeviceSelected": "未选择设备",
    "gui.menuBar.noConnection": "没有连接",
    "gui.menuBar.file": "文件",
    "gui.menuBar.downloadToComputer": "保存到电脑",
    "gui.menuBar.downloadFirmware": "下载固件",
    "gui.menu-bar.modeSwitchRealtime": "实时模式",
    "gui.menu-bar.modeSwitchRealtimeUpload": "上传模式",
    "gui.gui.projectTitlePlaceholder": "在此填写作品标题",
    "gui.menuBar.saveNowLink": "立即保存",
    "gui.menuBar.isShared": "已分享",
    "gui.menuBar.share": "分享",
    "gui.modal.help": "帮助",
    "gui.modal.back": "返回",
    "gui.monitor.listMonitor.empty": "(空)",
    "gui.monitor.listMonitor.listLength": "长度{length}",
    "gui.monitor.contextMenu.default": "正常显示",
    "gui.monitor.contextMenu.large": "大字显示",
    "gui.monitor.contextMenu.slider": "滑杆",
    "gui.monitor.contextMenu.sliderRange": "改变滑块范围",
    "gui.monitor.contextMenu.import": "导入",
    "gui.monitor.contextMenu.export": "导出",
    "gui.playButton.play": "播放",
    "gui.playButton.stop": "停止",
    "gui.gui.variableScopeOptionAllSprites": "适用于所有角色",
    "gui.gui.variableScopeOptionSpriteOnly": "仅适用于当前角色",
    "gui.gui.cloudVariableOption": "云变量 (存储在服务器上)",
    "gui.gui.variablePromptAllSpritesMessage": "所有角色都可使用该变量。",
    "gui.prompt.cancel": "取消",
    "gui.prompt.ok": "确定",
    "gui.playbackStep.stopMsg": "停止",
    "gui.playbackStep.playMsg": "播放",
    "gui.playbackStep.loadingMsg": "载入中……",
    "gui.playbackStep.saveMsg": "保存",
    "gui.playbackStep.reRecordMsg": "重新录制",
    "gui.recordModal.title": "录制声音",
    "gui.recordingStep.beginRecord": "点击下方的按钮开始录制",
    "gui.recordingStep.permission": "{arrow}我们需要你的许可才能使用麦克风。",
    "gui.recordingStep.stop": "停止录制",
    "gui.recordingStep.record": "录制",
    "gui.sliderModal.min": "最小值",
    "gui.sliderModal.max": "最大值",
    "gui.sliderModal.title": "改变滑块范围",
    "gui.sliderPrompt.cancel": "取消",
    "gui.sliderPrompt.ok": "确定",
    "gui.soundEditor.sound": "声音",
    "gui.soundEditor.play": "播放",
    "gui.soundEditor.stop": "停止",
    "gui.soundEditor.copy": "复制",
    "gui.soundEditor.paste": "粘贴",
    "gui.soundEditor.copyToNew": "新拷贝",
    "gui.soundEditor.delete": "删除",
    "gui.soundEditor.save": "保存",
    "gui.soundEditor.undo": "撤销",
    "gui.soundEditor.redo": "重做",
    "gui.soundEditor.faster": "快一点",
    "gui.soundEditor.slower": "慢一点",
    "gui.soundEditor.echo": "回声",
    "gui.soundEditor.robot": "机械化",
    "gui.soundEditor.louder": "响一点",
    "gui.soundEditor.softer": "轻一点",
    "gui.soundEditor.reverse": "反转",
    "gui.soundEditor.fadeOut": "渐弱",
    "gui.soundEditor.fadeIn": "渐强",
    "gui.soundEditor.mute": "静音",
    "gui.SpriteInfo.spritePlaceholder": "名字",
    "gui.SpriteInfo.sprite": "角色",
    "gui.SpriteInfo.show": "显示",
    "gui.SpriteInfo.size": "大小",
    "gui.spriteSelectorItem.contextMenuDuplicate": "复制",
    "gui.spriteSelectorItem.contextMenuExport": "导出",
    "gui.spriteSelectorItem.contextMenuDelete": "删除",
    "gui.spriteSelector.addSpriteFromLibrary": "选择一个角色",
    "gui.spriteSelector.addSpriteFromPaint": "绘制",
    "gui.spriteSelector.addSpriteFromSurprise": "随机",
    "gui.spriteSelector.addSpriteFromFile": "上传角色",
    "gui.stageHeader.stageSizeLarge": "放大舞台",
    "gui.stageHeader.stageSizeSmall": "缩小舞台",
    "gui.stageHeader.stageSizeFull": "进入全屏模式",
    "gui.stageHeader.stageSizeUnFull": "退出全屏模式",
    "gui.stageHeader.fullscreenControl": "全屏模式",
    "gui.spriteSelector.addBackdropFromLibrary": "选择一个背景",
    "gui.stageSelector.addBackdropFromPaint": "绘制",
    "gui.stageSelector.addBackdropFromSurprise": "随机",
    "gui.stageSelector.addBackdropFromFile": "上传背景",
    "gui.stageSelector.stage": "舞台",
    "gui.stageSelector.backdrops": "背景",
    "gui.telemetryOptIn.label": "统计使用情况并发送给ZTech",
    "gui.telemetryOptIn.body1": "允许ZTech自动发送使用情况信息给ZTech团队，帮助我们了解ZTech在全世界的使用情况。",
    "gui.telemetryOptIn.body2": "我们收集的信息包括所选语言、积木使用情况以及诸如保存、加载和上传作品等功能的使用情况。我们不会收集任何个人信息。请查看我们的{privacyPolicyLink}了解更多信息。",
    "gui.telemetryOptIn.privacyPolicyLink": "隐私政策",
    "gui.telemetryOptIn.optInText": "与 ZTech 团队分享我的使用数据",
    "gui.telemetryOptIn.optInTooltip": "发送统计信息",
    "gui.telemetryOptIn.optOutText": "不要与 ZTech 团队分享我的使用数据",
    "gui.telemetryOptIn.optOutTooltip": "不发送统计信息",
    "gui.telemetryOptIn.settingWasUpdated": "您的设置已更新。",
    "gui.telemetryOptIn.buttonClose": "关闭",
    "gui.turboMode.active": "加速模式",
    "gui.updateModel.tileUpdate": "检测到新版本的外部资源",
    "gui.upgradeModal.upgradeLater": "稍后升级",
    "gui.upgradeModal.upgradeAndRestart": "升级并重启",
    "gui.updateModel.tileChecking": "检查中",
    "gui.updateModel.checkingTips": "此步骤需要几秒钟时间，请稍侯。",
    "gui.upgradeModal.closeChecking": "关闭",
    "gui.updateModel.tileAlreadyLatest": "已经是最新的了",
    "gui.updateModel.alreadyLatestTips": "外部资源已经是最新的了。",
    "gui.updateModel.upgrading": "升级中",
    "gui.upgradeModal.downloading": "下载中",
    "gui.upgradeModal.covering": "覆盖中",
    "gui.updateModel.tileError": "操作失败",
    "gui.uploadProgress.uploading": "上传中",
    "gui.uploadProgress.uploadSuccess": "上传成功",
    "gui.uploadProgress.uploadError": "上传错误",
    "gui.uploadProgress.uploadTimeout": "上传超时",
    "gui.uploadProgress.close": "关闭",
    "gui.webglModal.label": "你的浏览器不支持WebGL",
    "gui.webglModal.description": "非常遗憾，你的电脑或浏览器{webGlLink}。ZTech需要该技术才能运行。",
    "gui.webglModal.webgllink": "不支持WebGL",
    "gui.webglModal.back": "返回",
    "gui.webglModal.previewfaq": "访问{previewFaqLink}，了解更多。",
    "gui.webglModal.previewfaqlinktext": "常见问题",
    "gui.costumeLibrary.chooseABackdrop": "选择一个背景",
    "gui.costumeLibrary.chooseACostume": "选择一个造型",
    "gui.costumeTab.addBackdropFromLibrary": "选择一个背景",
    "gui.costumeTab.addCostumeFromLibrary": "选择一个造型",
    "gui.costumeTab.addBlankCostume": "绘制",
    "gui.costumeTab.addSurpriseCostume": "随机",
    "gui.costumeTab.addFileBackdrop": "上传背景",
    "gui.costumeTab.addFileCostume": "上传造型",
    "gui.deviceLibrary.chooseADevice": "选择一个设备",
    "gui.deviceLibrary.deviceUrl": "输入设备的URL",
    "gui.deviceLibrary.arduinoTag": "Arduino",
    "gui.deviceLibrary.microPythonTag": "MicroPython",
    "gui.deviceLibrary.kitTag": "套件",
    "gui.extensionLibrary.chooseAnExtension": "选择一个扩展",
    "gui.extensionLibrary.extensionUrl": "键入扩展的URL",
    "gui.library.shieldTag": "扩展板",
    "gui.library.actuatorTag": "执行器",
    "gui.library.sensorTag": "传感器",
    "gui.library.displayTag": "显示器",
    "gui.library.communicationTag": "通信",
    "gui.library.otherTag": "其它",
    "gui.hardwareConsole.noLineTerminators": "没有结束符",
    "gui.hardwareConsole.lineFeed": "换行符",
    "gui.hardwareConsole.carriageReturn": "回车",
    "gui.hardwareConsole.lfAndCr": "回车加换行",
    "gui.monitors.importListColumnPrompt": "使用第几列（1-{numberOfColumns}）？",
    "gui.recordingStep.alertMsg": "无法启动录音",
    "gui.soundLibrary.chooseASound": "选择一个声音",
    "gui.soundTab.fileUploadSound": "上传声音",
    "gui.soundTab.surpriseSound": "随机",
    "gui.soundTab.recordSound": "录制",
    "gui.soundTab.addSoundFromLibrary": "选择一个声音",
    "gui.spriteLibrary.chooseASprite": "选择一个角色",
    "gui.tipsLibrary.tutorials": "选择一个教程",
    "gui.updateModal.upgradeWarning": "当前未保存的工程将会丢失，继续更新并重启？",
    "gui.uploadProgress.uploadErrorMessage": "上传错误",
    "gui.uploadProgress.uploadTimeoutMessage": "上传超时",
    "gui.alerts.createsuccess": "作品已创建。",
    "gui.alerts.createcopysuccess": "作品已保存为副本。",
    "gui.alerts.createremixsuccess": "改编作品已保存。",
    "gui.alerts.creating": "正在创建...",
    "gui.alerts.creatingCopy": "正在复制作品……",
    "gui.alerts.creatingRemix": "正在改编作品……",
    "gui.alerts.creatingError": "无法创建作品。 请再试一次！",
    "gui.alerts.savingError": "作品未能保存。",
    "gui.alerts.savesuccess": "作品已保存。",
    "gui.alerts.saving": "正在保存作品……",
    "gui.alerts.cloudInfo": "请注意，云变量只支持数字，不能存放字母和符号。{learnMoreLink}",
    "gui.alerts.cloudInfoLearnMore": "进一步了解。",
    "gui.alerts.importing": "正在导入……",
    "gui.alerts.workspaceIsEmpty": "工作空间是空的",
    "gui.alerts.workspaceIsNotEmpty": "工作区不是空的，请先清理你的工作空间",
    "gui.alerts.uploadError": "上传错误",
    "gui.alerts.uploadSuccess": "上传成功",
    "gui.alerts.selectADeviceFirst": "请先选择一个设备",
    "gui.alerts.connectAPeripheralFirst": "请先连接设备",
    "gui.defaultProject.chirp": "啁啾",
    "gui.defaultProject.variable": "我的变量",
    "gui.howtos.intro-move-sayhello-hat.name": "入门",
    "gui.howtos.intro-move.step_stepMove": "添加「移动」积木",
    "gui.howtos.add-a-move-block.step_stepSay": "添加「说」积木",
    "gui.howtos.add-a-move-block.step_stepGreenFlag": "单击绿色小旗开始",
    "gui.howtos.animate-a-name.name": "姓名动画",
    "gui.howtos.animate-a-name.step_AnimatePickLetter": "选一个字母角色",
    "gui.howtos.animate-a-name.step_AnimatePlaySound": "点击时播放声音",
    "gui.howtos.animate-a-name.step_AnimatePickLetter2": "另选一个字母角色",
    "gui.howtos.animate-a-name.step_AnimateChangeColor": "改变颜色",
    "gui.howtos.animate-a-name.step_AnimateSpin": "选一个字母，让它旋转",
    "gui.howtos.animate-a-name.step_AnimateGrow": "另选一个字母，让它变大",
    "gui.howtos.imagine": "Imagine a World",
    "gui.howtos.imagine.step_imagineTypeWhatYouWant": "Type What You Want to Say",
    "gui.howtos.imagine.step_imagineClickGreenFlag": "Click the Green Flag to Start",
    "gui.howtos.imagine.step_imagineChooseBackdrop": "Choose Any Backdrop",
    "gui.howtos.imagine.step_imagineChooseSprite": "Choose Any Sprite",
    "gui.howtos.imagine.step_imagineFlyAround": "Press the Space Key to Glide",
    "gui.howtos.imagine.step_imagineChooseAnotherSprite": "Choose Another Sprite",
    "gui.howtos.imagine.step_imagineLeftRight": "Move Left-Right",
    "gui.howtos.imagine.step_imagineUpDown": "Move Up-Down",
    "gui.howtos.imagine.step_imagineChangeCostumes": "Change Costumes",
    "gui.howtos.imagine.step_imagineGlideToPoint": "Glide to a Point",
    "gui.howtos.imagine.step_imagineGrowShrink": "Grow and Shrink",
    "gui.howtos.imagine.step_imagineChooseAnotherBackdrop": "Choose Another Backdrop",
    "gui.howtos.imagine.step_imagineSwitchBackdrops": "Switch Backdrops",
    "gui.howtos.imagine.step_imagineRecordASound": "Add a Sound",
    "gui.howtos.imagine.step_imagineChooseSound": "Choose Your Sound",
    "gui.howtos.make-music.name": "制作音乐",
    "gui.howtos.Make-Music.step_PickInstrument": "选一个乐器角色",
    "gui.howtos.Make-Music.step_PlaySoundClick": "点击时播放声音",
    "gui.howtos.Make-Music.step_MakeSong": "创作乐曲",
    "gui.howtos.make-music.step_MakeBeat": "选一种鼓，演奏鼓点",
    "gui.howtos.make-music.step_MakeBeatBox": "用麦克风角色演唱节奏口技",
    "gui.howtos.story.name": "创作故事",
    "gui.howtos.story.step_addbg": "添加一个背景",
    "gui.howtos.story.step_addsprite": "添加一个角色",
    "gui.howtos.story.step_saysomething": "让角色说些话",
    "gui.howtos.story.step_addanothersprite": "添加另一个角色",
    "gui.howtos.story.step_flip": "翻转方向",
    "gui.howtos.story.step_conversation": "进行对话",
    "gui.howtos.story.addanotherbg": "添加另一个背景",
    "gui.howtos.story.step_swithbg": "切换背景",
    "gui.howtos.story.step_hidewizard": "隐藏一个角色",
    "gui.howtos.story.step_showwizard": "展示一个角色",
    "gui.howtos.make-a-chase-game.name": "制作追赶游戏",
    "gui.howtos.Chase-Game.step_BG": "添加一个背景",
    "gui.howtos.chase-game.step_AddOcto": "添加一个角色",
    "gui.howtos.make-music.step_LeftRight": "用方向键控制左右移动",
    "gui.howtos.Chase-Game.step_UpDown": "用方向键控制上下移动",
    "gui.howtos.Chase-Game.step_AddStar": "添加另一个角色",
    "gui.howtos.Chase-Game.step_MoveRandom": "随机移动",
    "gui.howtos.Chase-Game.step_WhenTouch": "让章鱼被碰到的时候发出声音",
    "gui.howtos.Chase-Game.step_ScoreVariable": "建立一个得分变量",
    "gui.howtos.Chase-Game.ScoreWhenTouch": "让章鱼被碰到时，增加得分",
    "gui.howtos.animate-char.name": "角色动画",
    "gui.howtos.animate-char.step_addbg": "添加一个背景",
    "gui.howtos.animate-char.step_addsprite": "添加一个角色",
    "gui.howtos.animate-char.step_saysomething": "让角色说些话",
    "gui.howtos.animate-char.step_addsound": "添加声音",
    "gui.howtos.animate-char.step_animatetalking": "交谈动画",
    "gui.howtos.animate-char.step_arrowkeys": "使用方向键移动",
    "gui.howtos.animate-char.step_jump": "跳跃",
    "gui.howtos.animate-char.step_changecolor": "改变颜色",
    "gui.howtos.make-a-game.name": "制作消除游戏",
    "gui.howtos.Make-A-Game.step_GamePickSprite": "挑选一个角色",
    "gui.howtos.make-a-game.step_GamePlaySound": "点击时播放声音",
    "gui.howtos.make-a-game.step_GameAddScore": "建立一个得分变量",
    "gui.howtos.make-a-game.step_GameChangeScore": "被点击时增加分数",
    "gui.howtos.make-a-game.step_Random": "移动到随机位置",
    "gui.howtos.make-music.step_GameChangeColor": "改变颜色",
    "gui.howtos.make-music.step_ResetScore": "把得分清零",
    "gui.howtos.make-it-fly.name": "让角色飞行",
    "gui.howtos.fly.step_stepflyChooseBackdrop": "选一个天空的背景",
    "gui.howtos.add-a-move-block.step_stepflyChooseCharacter": "选择一个角色",
    "gui.howtos.fly.step_stepflySaySomething": "让角色说些话",
    "gui.howtos.add-a-move-block.step_stepflyMoveArrows": "用方向键控制移动",
    "gui.howtos.fly.step_stepflyChooseObject": "选择要采集的对象",
    "gui.howtos.add-a-move-block.step_stepflyFlyingObject": "让对象移动",
    "gui.howtos.add-a-move-block.step_stepflySelectFlyingSprite": "点选你的角色",
    "gui.howtos.add-a-move-block.step_stepflyAddScore": "添加分数变量",
    "gui.howtos.add-a-move-block.step_stepflyKeepScore": "保存成绩",
    "gui.howtos.add-a-move-block.step_stepflyAddScenery": "加入景物",
    "gui.howtos.add-a-move-block.step_stepflyMoveScenery": "让景物移动",
    "gui.howtos.add-a-move-block.step_stepflySwitchLooks": "换成下一个造型",
    "gui.howtos.pong": "乒乓球游戏",
    "gui.howtos.pong.step_pongAddBackdrop": "添加一个背景",
    "gui.howtos.pong.step_pongAddBallSprite": "添加球体角色",
    "gui.howtos.pong.step_pongBounceAround": "让球反弹",
    "gui.howtos.pong.step_pongAddPaddle": "添加滑板",
    "gui.howtos.pong.step_pongMoveThePaddle": "控制滑板移动",
    "gui.howtos.pong.step_pongSelectBallSprite": "点选球体角色",
    "gui.howtos.pong.step_pongAddMoreCodeToBall": "添加代码让球体碰到滑板反弹",
    "gui.howtos.pong.step_pongAddAScore": "添加分数变量",
    "gui.howtos.pong.step_pongChooseScoreFromMenu": "从菜单中选择「分数」",
    "gui.howtos.pong.step_pongInsertChangeScoreBlock": "插入「改变分数」积木",
    "gui.howtos.pong.step_pongResetScore": "把得分清零",
    "gui.howtos.pong.step_pongAddLineSprite": "添加线条角色",
    "gui.howtos.pong.step_pongGameOver": "碰到红线游戏结束",
    "gui.howtos.code-cartoon": "Code a Cartoon",
    "gui.howtos.code-cartoon.step_codeCartoonSaySomething": "Say Something When You Click the Green Flag",
    "gui.howtos.code-cartoon.step_codeCartoonAnimate": "Animate a Character When You Click It",
    "gui.howtos.code-cartoon.step_codeCartoonSelectDifferentCharacter": "Select a Different Character",
    "gui.howtos.code-cartoon.step_codeCartoonUseMinusSign": "Use a Minus Sign to Get Smaller",
    "gui.howtos.code-cartoon.step_codeCartoonGrowShrink": "Make a Character Grow and Shrink",
    "gui.howtos.code-cartoon.step_codeCartoonSelectDifferentCharacter2": "Select a Different Character",
    "gui.howtos.code-cartoon.step_codeCartoonJump": "Jump Up and Down",
    "gui.howtos.code-cartoon.step_codeCartoonChangeScenes": "Click a Character to Change Scenes",
    "gui.howtos.code-cartoon.step_codeCartoonGlideAround": "Glide Around",
    "gui.howtos.code-cartoon.step_codeCartoonChangeCostumes": "Change Costumes",
    "gui.howtos.code-cartoon.step_codeCartoonChooseMoreCharacters": "Choose More Characters to Add to Your Cartoon",
    "gui.howtos.cartoon-network": "冒险游戏动画",
    "gui.howtos.cartoon-network.step_CNcharacter": "选择要显示的角色",
    "gui.howtos.cartoon-network.step_CNsay": "让角色说些话",
    "gui.howtos.cartoon-network.step_CNglide": "滑来滑去",
    "gui.howtos.cartoon-network.step_CNpicksprite": "选择要捕捉的目标",
    "gui.howtos.cartoon-network.step_CNcollect": "收集物品",
    "gui.howtos.cartoon-network.step_CNvariable": "建立分数变量",
    "gui.howtos.cartoon-network.step_CNscore": "保存成绩",
    "gui.howtos.cartoon-network.step_CNbackdrop": "过关：改变背景",
    "gui.howtos.videosens.name": "视频侦测",
    "gui.howtos.videosens.step_addextension": "添加扩展",
    "gui.howtos.videosens.step_pet": "爱抚猫咪",
    "gui.howtos.videosens.step_animate": "动画",
    "gui.howtos.videosens.step_pop": "戳破气球",
    "gui.howtos.say-it-out-loud": "创建会说话的动画",
    "gui.howtos.say-it-out-loud.step_AddTXTextension": "添加文字朗读扩展积木",
    "gui.howtos.say-it-out-loud.step_TXTSpeech": "让角色说些话",
    "gui.howtos.say-it-out-loud_TXTSetVoice": "设置语音",
    "gui.howtos.say-it-out-loud.step_TXTMove": "四处移动",
    "gui.howtos.say-it-out-loud.step_TXTBackdrop": "添加一个背景",
    "gui.howtos.say-it-out-loud.step_TXTAddSprite": "添加另一个角色",
    "gui.howtos.say-it-out-loud.step_TXTSong": "演奏一首歌",
    "gui.howtos.say-it-out-loud.step_TXTColor": "改变颜色",
    "gui.howtos.say-it-out-loud.step_TXTSpin": "原地转动",
    "gui.howtos.say-it-out-loud.step_TXTGrow": "变大变小",
    "gui.howtos.talking": "Talking Tales",
    "gui.howtos.talking.step_talesAddExtension": "Click to Add the Text-to-Speech Blocks",
    "gui.howtos.talking.step_talesChooseSprite": "Choose a Sprite",
    "gui.howtos.talking.step_talesSaySomething": "Make a Character Speak",
    "gui.howtos.talking.step_talesChooseBackdrop": "Choose a Backdrop",
    "gui.howtos.talking.step_talesSwitchBackdrop": "Click a Character to Go to the Next Backdrop",
    "gui.howtos.talking.step_talesChooseAnotherSprite": "Choose Another Sprite",
    "gui.howtos.talking.step_talesMoveAround": "Move Around",
    "gui.howtos.talking.step_talesChooseAnotherBackdrop": "Choose Another Backdrop",
    "gui.howtos.talking.step_talesAnimateTalking": "Animate Talking",
    "gui.howtos.talking.step_talesChooseThirdBackdrop": "Choose Another Backdrop",
    "gui.howtos.talking.step_talesChooseSound": "Choose a Song to Dance To",
    "gui.howtos.talking.step_talesDanceMoves": "Dance Moves",
    "gui.howtos.talking.step_talesAskAnswer": "Get the Ask and Answer Blocks from the Sensing Category",
    "gui.howtos.add-sprite.name": "添加一个角色",
    "gui.howtos.add-sprite.step_addSprite": "添加一个角色",
    "gui.howtos.add-a-backdrop.name": "添加一个背景",
    "gui.howtos.add-a-backdrop.step_addBackdrop": "添加一个背景",
    "gui.howtos.change-size.name": "变大变小",
    "gui.howtos.change-size.step_changeSize": "变大变小",
    "gui.howtos.glide-around.name": "滑来滑去",
    "gui.howtos.change-size.step_glideAroundBackAndForth": "滑来滑去",
    "gui.howtos.change-size.step_glideAroundPoint": "滑动到某处",
    "gui.howtos.record-a-sound.name": "录制声音",
    "gui.howtos.change-size.step_recordASoundSoundsTab": "点击「声音」标签页",
    "gui.howtos.change-size.step_recordASoundClickRecord": "点击「录制」",
    "gui.howtos.change-size.step_recordASoundPressRecordButton": "按下录制按钮",
    "gui.howtos.change-size.step_recordASoundChooseSound": "选择已录制的声音",
    "gui.howtos.change-size.step_recordASoundPlayYourSound": "播放录制的声音",
    "gui.howtos.spin-video.name": "让字母旋转",
    "gui.howtos.change-size.step_spinTurn": "转动",
    "gui.howtos.change-size.step_spinPointInDirection": "设置方向",
    "gui.howtos.hide-and-show.name": "隐藏和出现",
    "gui.howtos.change-size.step_hideAndShow": "隐藏和出现",
    "gui.howtos.switch-costume.name": "角色动画",
    "gui.howtos.change-size.step_switchCostumes": "角色动画",
    "gui.howtos.move-around-with-arrow-keys.name": "使用方向键",
    "gui.howtos.add-a-backdrop.step_moveArrowKeysLeftRight": "左右移动",
    "gui.howtos.add-a-backdrop.step_moveArrowKeysUpDown": "上下移动",
    "gui.howtos.add-effects.name": "添加一些效果",
    "gui.howtos.videosens.step_addEffects": "添加视觉效果",
    "gui.device.unselectDevice.name": "取消设备选择",
    "gui.device.unselectDevice.description": "取消设备选择，回到纯实时编程模式。",
    "gui.device.arduinoUno.description": "很好的电子编程入门控制板。",
    "gui.device.arduino.connectingMessage": "正在连接",
    "gui.device.arduinoNano.description": "Arduino Nano 用于构建项目的经典小控制板。",
    "gui.device.arduinoMini.description": "Arduino Mini 用于构建项目的更小控制板。",
    "gui.device.arduinoLeonardo.description": "经典的Arduino板，可以用作鼠标或键盘。",
    "gui.device.arduinoMega2560.description": "拥有54个数字引脚，16个模拟输入，和4个串口的8位控制板。",
    "gui.device.arduinoEsp32.description": "功能强大且带有 Wi-Fi 和 蓝牙的控制板。",
    "gui.device.arduinoEsp32.connectingMessage": "连接中",
    "gui.device.arduinoEsp8266.description": "低功耗 Wi-Fi SOC 控制板。",
    "gui.device.arduinoEsp8266.connectingMessage": "连接中",
    "gui.device.microbit.description": "可以学习电子知识的口袋计算机。",
    "gui.device.microbit.connectingMessage": "连接中",
    "gui.device.microbitV2.description": "升级的处理器，板载扬声器和麦克风，触摸感应徽标。",
    "gui.device.makeymakey.description": "把任何东西变成按键。",
    "gui.extension.music.name": "音乐",
    "gui.extension.music.description": "演奏乐器，敲锣打鼓。",
    "gui.extension.pen.name": "画笔",
    "gui.extension.pen.description": "绘制角色。",
    "gui.extension.videosensing.name": "视频侦测",
    "gui.extension.videosensing.description": "使用摄像头侦测运动。",
    "gui.extension.text2speech.name": "文字朗读",
    "gui.extension.text2speech.description": "让你的作品开口说话",
    "gui.extension.translate.name": "翻译",
    "gui.extension.translate.description": "把文字翻译成多种语言。",
    "gui.extension.makeymakey.name": "Makey Makey",
    "gui.extension.makeymakey.description": "把任何东西变成按键",
    "gui.libraryTags.all": "所有",
    "gui.libraryTags.animals": "动物",
    "gui.libraryTags.dance": "舞蹈",
    "gui.libraryTags.effects": "效果",
    "gui.libraryTags.fantasy": "奇幻",
    "gui.libraryTags.fashion": "时尚",
    "gui.libraryTags.food": "食物",
    "gui.libraryTags.indoors": "室内",
    "gui.libraryTags.loops": "可循环",
    "gui.libraryTags.music": "音乐",
    "gui.libraryTags.notes": "音符",
    "gui.libraryTags.outdoors": "户外",
    "gui.libraryTags.patterns": "图案",
    "gui.libraryTags.people": "人物",
    "gui.libraryTags.percussion": "打击乐器",
    "gui.libraryTags.space": "太空",
    "gui.libraryTags.sports": "运动",
    "gui.libraryTags.underwater": "水下",
    "gui.libraryTags.voice": "人声",
    "gui.libraryTags.wacky": "古怪",
    "gui.libraryTags.animation": "动画",
    "gui.libraryTags.art": "艺术",
    "gui.libraryTags.games": "游戏",
    "gui.libraryTags.stories": "故事",
    "gui.libraryTags.letters": "字母",
    "gui.opcodeLabels.direction": "方向",
    "gui.opcodeLabels.xposition": "x 坐标",
    "gui.opcodeLabels.yposition": "y 坐标",
    "gui.opcodeLabels.size": "大小",
    "gui.opcodeLabels.costumename": "造型名称",
    "gui.opcodeLabels.costumenumber": "造型编号",
    "gui.opcodeLabels.backdropname": "背景名称",
    "gui.opcodeLabels.backdropnumber": "背景编号",
    "gui.opcodeLabels.volume": "音量",
    "gui.opcodeLabels.tempo": "演奏速度",
    "gui.opcodeLabels.answer": "回答",
    "gui.opcodeLabels.loudness": "响度",
    "gui.opcodeLabels.username": "用户名",
    "gui.opcodeLabels.year": "年",
    "gui.opcodeLabels.month": "月",
    "gui.opcodeLabels.date": "日",
    "gui.opcodeLabels.dayofweek": "星期",
    "gui.opcodeLabels.hour": "时",
    "gui.opcodeLabels.minute": "分",
    "gui.opcodeLabels.second": "秒",
    "gui.opcodeLabels.timer": "计时器",
    "gui.projectLoader.loadError": "加载作品文件失败。",
    "gui.sharedMessages.backdrop": "背景{index}",
    "gui.sharedMessages.costume": "造型{index}",
    "gui.sharedMessages.sprite": "角色{index}",
    "gui.sharedMessages.pop": "啵",
    "gui.sharedMessages.replaceProjectWarning": "是否丢弃当前作品中尚未保存的内容？",
    "gui.sharedMessages.clearCacheWarning": "确保你想要清除缓存并重启？",
    "gui.sharedMessages.loadFromComputerTitle": "从电脑中上传",
    "gui.gui.defaultProjectTitle": "ZTech作品",
    "arduinoEsp32.InterrupModeMenu.changeEdge": "电平改变触发",
    "arduinoEsp32.InterrupModeMenu.fallingEdge": "下降沿触发",
    "arduinoEsp32.InterrupModeMenu.high": "高电平触发",
    "arduinoEsp32.InterrupModeMenu.low": "低电平触发",
    "arduinoEsp32.InterrupModeMenu.risingEdge": "上升沿触发",
    "arduinoEsp32.category.data": "数据",
    "arduinoEsp32.category.sensor": "传感器",
    "arduinoEsp32.category.serial": "串口",
    "arduinoEsp32.data.dataConstrain": "约束 [DATA] 介于 ([ARG0], [ARG1])",
    "arduinoEsp32.data.dataConvert": "转换 [DATA] 为 [TYPE]",
    "arduinoEsp32.data.dataConvertASCIICharacter": "转换 [DATA] 为 ASCII 字符",
    "arduinoEsp32.data.dataConvertASCIINumber": "转换 [DATA] 为 ASCII 编码",
    "arduinoEsp32.data.dataMap": "映射 [DATA] 从 ([ARG0], [ARG1]) 到 ([ARG2], [ARG3])",
    "arduinoEsp32.dataTypeMenu.decimal": "小数",
    "arduinoEsp32.dataTypeMenu.string": "字符串",
    "arduinoEsp32.dataTypeMenu.wholeNumber": "整数",
    "arduinoEsp32.eolMenu.noWarp": "不换行",
    "arduinoEsp32.eolMenu.warp": "换行",
    "arduinoEsp32.levelMenu.high": "高",
    "arduinoEsp32.levelMenu.low": "低",
    "arduinoEsp32.modeMenu.input": "输入",
    "arduinoEsp32.modeMenu.inputPullup": "输入上拉",
    "arduinoEsp32.modeMenu.output": "输出",
    "arduinoEsp32.pins.attachInterrupt": "设置中断引脚 [PIN] 模式 [MODE] 执行",
    "arduinoEsp32.pins.detachInterrupt": "取消中断引脚 [PIN]",
    "arduinoEsp32.pins.esp32ReadTouchPin": "读取触摸引脚 [PIN]",
    "arduinoEsp32.pins.readAnalogPin": "读取模拟引脚 [PIN]",
    "arduinoEsp32.pins.readDigitalPin": "读取数字引脚 [PIN]",
    "arduinoEsp32.pins.setServoOutput": "设置舵机引脚 [PIN] 使用通道 [CH] 输出 [OUT]",
    "arduinoEsp32.sensor.readHallSensor": "读取霍尔传感器",
    "arduinoEsp32.sensor.runningTime": "运行时间（毫秒）",
    "arduinoEsp32.serial.multiSerialAvailable": "串口 [NO] 可读取字节数",
    "arduinoEsp32.serial.multiSerialBegin": "串口 [NO] 启动波特率  [VALUE]",
    "arduinoEsp32.serial.multiSerialPrint": "串口 [NO] 打印 [VALUE] [EOL]",
    "arduinoEsp32.serial.multiSerialReadAByte": "串口 [NO] 读取1个字节",
    "arduinoLeonardo.category.data": "数据",
    "arduinoLeonardo.category.pins": "引脚",
    "arduinoLeonardo.data.dataConstrain": "约束[DATA] 介于 ([ARG0], [ARG1])",
    "arduinoLeonardo.data.dataConvert": "转换 [DATA] 为 [TYPE]",
    "arduinoLeonardo.data.dataConvertASCIICharacter": "转换 [DATA] 为 ASCII 字符",
    "arduinoLeonardo.data.dataConvertASCIINumber": "转换 [DATA] 为 ASCII 编码",
    "arduinoLeonardo.data.dataMap": "映射 [DATA] 从 ([ARG0], [ARG1]) 到 ([ARG2], [ARG3])",
    "arduinoLeonardo.dataTypeMenu.decimal": "小数",
    "arduinoLeonardo.dataTypeMenu.string": "字符串",
    "arduinoLeonardo.dataTypeMenu.wholeNumber": "整数",
    "arduinoLeonardo.levelMenu.high": "高",
    "arduinoLeonardo.levelMenu.low": "低",
    "arduinoLeonardo.modeMenu.input": "输入",
    "arduinoLeonardo.modeMenu.inputPullup": "输入上拉",
    "arduinoLeonardo.modeMenu.output": "输出",
    "arduinoLeonardo.pins.attachInterrupt": "设置中断引脚 [PIN] 模式 [MODE] 执行",
    "arduinoLeonardo.pins.detachInterrupt": "取消中断引脚 [PIN]",
    "arduinoLeonardo.pins.readAnalogPin": "读取模拟引脚 [PIN]",
    "arduinoLeonardo.pins.readDigitalPin": "读取数字引脚 [PIN]",
    "arduinoLeonardo.pins.setDigitalOutput": "设置引脚 [PIN] 输出为 [LEVEL]",
    "arduinoLeonardo.pins.setPinMode": "设置引脚 [PIN] 模式 [MODE]",
    "arduinoLeonardo.pins.setPwmOutput": "设置 pwm 引脚 [PIN] 输出 [OUT]",
    "arduinoLeonardo.pins.setServoOutput": "设置舵机引脚 [PIN] 输出 [OUT]",
    "arduinoMega2560.category.data": "数据",
    "arduinoMega2560.category.pins": "引脚",
    "arduinoMega2560.category.serial": "串口",
    "arduinoMega2560.data.dataConstrain": "约束[DATA] 介于 ([ARG0], [ARG1])",
    "arduinoMega2560.data.dataConvert": "转换 [DATA] 为 [TYPE]",
    "arduinoMega2560.data.dataConvertASCIICharacter": "转换 [DATA] 为 ASCII 字符",
    "arduinoMega2560.data.dataConvertASCIINumber": "转换 [DATA] 为 ASCII 编码",
    "arduinoMega2560.data.dataMap": "映射 [DATA] 从 ([ARG0], [ARG1]) 到 ([ARG2], [ARG3])",
    "arduinoMega2560.dataTypeMenu.decimal": "小数",
    "arduinoMega2560.dataTypeMenu.string": "字符串",
    "arduinoMega2560.dataTypeMenu.wholeNumber": "整数",
    "arduinoMega2560.levelMenu.high": "高",
    "arduinoMega2560.levelMenu.low": "低",
    "arduinoMega2560.modeMenu.input": "输入",
    "arduinoMega2560.modeMenu.inputPullup": "输入上拉",
    "arduinoMega2560.modeMenu.output": "输出",
    "arduinoMega2560.pins.attachInterrupt": "设置中断引脚 [PIN] 模式 [MODE] 执行",
    "arduinoMega2560.pins.detachInterrupt": "取消中断引脚 [PIN]",
    "arduinoMega2560.pins.readAnalogPin": "读取模拟引脚 [PIN]",
    "arduinoMega2560.pins.readDigitalPin": "读取数字引脚 [PIN]",
    "arduinoMega2560.pins.setDigitalOutput": "设置引脚 [PIN] 输出为 [LEVEL]",
    "arduinoMega2560.pins.setPinMode": "设置引脚 [PIN] 模式 [MODE]",
    "arduinoMega2560.pins.setPwmOutput": "设置 pwm 引脚 [PIN] 输出 [OUT]",
    "arduinoMega2560.pins.setServoOutput": "设置舵机引脚 [PIN] 输出 [OUT]",
    "arduinoMega2560.serial.multiSerialAvailable": "串口 [NO] 可读取字节数",
    "arduinoMega2560.serial.multiSerialBegin": "串口 [NO] 启动波特率  [VALUE]",
    "arduinoMega2560.serial.multiSerialPrint": "串口 [NO] 打印 [VALUE] [EOL]",
    "arduinoMega2560.serial.multiSerialReadAByte": "串口 [NO] 读取1个字节",
    "arduinoPeripheral.connection.connectFirmataTimeout": "尝试连接 firmata 超时, 请先下载固件",
    "arduinoUno.InterrupModeMenu.changeEdge": "电平改变触发",
    "arduinoUno.InterrupModeMenu.fallingEdge": "下降沿触发",
    "arduinoUno.InterrupModeMenu.low": "低电平触发",
    "arduinoUno.InterrupModeMenu.risingEdge": "上升沿触发",
    "arduinoUno.category.data": "数据",
    "arduinoUno.category.pins": "引脚",
    "arduinoUno.category.serial": "串口",
    "arduinoUno.data.dataConstrain": "约束[DATA] 介于 ([ARG0], [ARG1])",
    "arduinoUno.data.dataConvert": "转换 [DATA] 为 [TYPE]",
    "arduinoUno.data.dataConvertASCIICharacter": "转换 [DATA] 为 ASCII 字符",
    "arduinoUno.data.dataConvertASCIINumber": "转换 [DATA] 为 ASCII 编码",
    "arduinoUno.data.dataMap": "映射 [DATA] 从 ([ARG0], [ARG1]) 到 ([ARG2], [ARG3])",
    "arduinoUno.dataTypeMenu.decimal": "小数",
    "arduinoUno.dataTypeMenu.string": "字符串",
    "arduinoUno.dataTypeMenu.wholeNumber": "整数",
    "arduinoUno.eolMenu.noWarp": "不换行",
    "arduinoUno.eolMenu.warp": "换行",
    "arduinoUno.levelMenu.high": "高",
    "arduinoUno.levelMenu.low": "低",
    "arduinoUno.modeMenu.input": "输入",
    "arduinoUno.modeMenu.inputPullup": "输入上拉",
    "arduinoUno.modeMenu.output": "输出",
    "arduinoUno.pins.attachInterrupt": "设置中断引脚 [PIN] 模式 [MODE] 执行",
    "arduinoUno.pins.detachInterrupt": "取消中断引脚 [PIN]",
    "arduinoUno.pins.readAnalogPin": "读取模拟引脚 [PIN]",
    "arduinoUno.pins.readDigitalPin": "读取数字引脚 [PIN]",
    "arduinoUno.pins.setDigitalOutput": "设置引脚 [PIN] 输出为 [LEVEL]",
    "arduinoUno.pins.setPinMode": "设置引脚 [PIN] 模式 [MODE]",
    "arduinoUno.pins.setPwmOutput": "设置引脚 [PIN] pwm为 [OUT]",
    "arduinoUno.pins.setServoOutput": "设置舵机引脚 [PIN] 输出 [OUT]",
    "arduinoUno.serial.serialAvailable": "串口可读取字节数",
    "arduinoUno.serial.serialBegin": "串口启动波特率  [VALUE]",
    "arduinoUno.serial.serialPrint": "串口打印 [VALUE] [EOL]",
    "arduinoUno.serial.serialReadData": "串口读取数据",
    "boost.color.any": "任何颜色",
    "boost.color.black": "黑色",
    "boost.color.blue": "蓝色",
    "boost.color.green": "绿色",
    "boost.color.red": "黄色",
    "boost.color.white": "白色",
    "boost.color.yellow": "黄色",
    "boost.getMotorPosition": "马达 [MOTOR_REPORTER_ID] 位置",
    "boost.getTiltAngle": "向[TILT_DIRECTION]倾角",
    "boost.motorDirection.backward": "逆时针",
    "boost.motorDirection.forward": "顺时针",
    "boost.motorDirection.reverse": "反转",
    "boost.motorOff": "关闭马达 [MOTOR_ID]",
    "boost.motorOn": "开启马达 [MOTOR_ID]",
    "boost.motorOnFor": "使马达 [MOTOR_ID]转动 [DURATION] 秒",
    "boost.motorOnForRotation": "使马达 [MOTOR_ID]转动 [ROTATION] 圈",
    "boost.seeingColor": "看到 [COLOR]积木 ?",
    "boost.setLightHue": "将灯光颜色设为[HUE]",
    "boost.setMotorDirection": "将马达[MOTOR_ID] 方向设为 [MOTOR_DIRECTION]",
    "boost.setMotorPower": "将马达 [MOTOR_ID] 速度设为 [POWER]%",
    "boost.tiltDirection.any": "任意方向",
    "boost.tiltDirection.down": "下",
    "boost.tiltDirection.left": "左",
    "boost.tiltDirection.right": "右",
    "boost.tiltDirection.up": "上",
    "boost.whenColor": "当看到[COLOR]积木",
    "boost.whenTilted": "当向[TILT_DIRECTION_ANY]倾斜",
    "esp32Arduino.category.pins": "引脚",
    "esp32Arduino.pins.esp32SetDACOutput": "设置 dac 引脚 [PIN] 输出 [OUT]",
    "esp32Arduino.pins.esp32SetPwmOutput": "设置 pwm 引脚 [PIN] 使用通道 [CH] 输出 [OUT]",
    "esp32Arduino.pins.setDigitalOutput": "设置引脚 [PIN] 输出 [LEVEL]",
    "esp32Arduino.pins.setPinMode": "设置引脚 [PIN] 模式 [MODE]",
    "ev3.beepNote": "鸣笛[NOTE][TIME]秒",
    "ev3.buttonPressed": "按下按钮[PORT]？",
    "ev3.getBrightness": "亮度",
    "ev3.getDistance": "距离",
    "ev3.getMotorPosition": "马达[PORT]的位置",
    "ev3.motorSetPower": "马达[PORT]功率设为[POWER]%",
    "ev3.motorTurnClockwise": "马达[PORT]正转[TIME]秒",
    "ev3.motorTurnCounterClockwise": "马达[PORT]反转[TIME]秒",
    "ev3.whenBrightnessLessThan": "当亮度 < [DISTANCE]",
    "ev3.whenButtonPressed": "当按下按钮[PORT]",
    "ev3.whenDistanceLessThan": "当距离 < [DISTANCE]",
    "gdxfor.getAcceleration": "向[DIRECTION]的加速度",
    "gdxfor.getForce": "受力",
    "gdxfor.getSpin": "向[DIRECTION]的旋转速度",
    "gdxfor.getTilt": "向[TILT]倾角",
    "gdxfor.isFreeFalling": "自由落体？",
    "gdxfor.isTilted": "向[TILT]倾斜？",
    "gdxfor.pulled": "拉扯",
    "gdxfor.pushed": "挤压",
    "gdxfor.shaken": "被晃动",
    "gdxfor.startedFalling": "开始自由落体",
    "gdxfor.tiltDirectionMenu.any": "任意方向",
    "gdxfor.tiltDirectionMenu.back": "后",
    "gdxfor.tiltDirectionMenu.front": "前",
    "gdxfor.tiltDirectionMenu.left": "左",
    "gdxfor.tiltDirectionMenu.right": "右",
    "gdxfor.turnedFaceDown": "正面向下",
    "gdxfor.turnedFaceUp": "正面向上",
    "gdxfor.whenForcePushedOrPulled": "当力传感器被[PUSH_PULL]",
    "gdxfor.whenGesture": "当传感器[GESTURE]",
    "gdxfor.whenTilted": "当向[TILT]倾斜",
    "makeymakey.downArrow": "↓",
    "makeymakey.downArrowShort": "下",
    "makeymakey.leftArrow": "←",
    "makeymakey.leftArrowShort": "左",
    "makeymakey.rightArrow": "→",
    "makeymakey.rightArrowShort": "右",
    "makeymakey.spaceKey": "空格",
    "makeymakey.upArrow": "↑",
    "makeymakey.upArrowShort": "上",
    "makeymakey.whenKeyPressed": "当按下 [KEY] 键",
    "makeymakey.whenKeysPressedInOrder": "当依次按下[SEQUENCE]键时",
    "microbit.axisMenu.xAxis": "x轴",
    "microbit.axisMenu.yAxis": "y轴",
    "microbit.axisMenu.zAxis": "z轴",
    "microbit.buttonsMenu.any": "任意",
    "microbit.category.console": "控制台",
    "microbit.category.display": "显示",
    "microbit.category.pins": "引脚",
    "microbit.category.sensor": "传感器",
    "microbit.category.wireless": "无线",
    "microbit.clearDisplay": "清空屏幕",
    "microbit.console.consolePrint": "打印 [TEXT]",
    "microbit.defaultTextToDisplay": "Hello!",
    "microbit.display.clearDisplay": "清空屏幕",
    "microbit.display.lightPixelAt": "[STATE] 在坐标 x: [X] , y: [Y] ",
    "microbit.display.show": "显示 [TEXT]",
    "microbit.display.showImage": "显示图案 [VALUE]",
    "microbit.display.showImageUntil": "显示图案 [VALUE] 持续 [TIME] 秒",
    "microbit.display.showOnPiexlbrightness": "设置坐标 x: [X], y: [Y] 的亮度为 [BRT]",
    "microbit.display.showUntilScrollDone": "显示 [TEXT] 直到滚动结束",
    "microbit.displaySymbol": "显示[MATRIX]",
    "microbit.displayText": "显示文本[TEXT]",
    "microbit.gestruesMenu.faceDown": "正面朝下",
    "microbit.gestruesMenu.faceUp": "正面朝上",
    "microbit.gestruesMenu.freefall": "自由下落",
    "microbit.gestruesMenu.shaken": "被摇晃",
    "microbit.gestruesMenu.tiltedDownward": "向下倾斜",
    "microbit.gestruesMenu.tiltedLeftward": "向左倾斜",
    "microbit.gestruesMenu.tiltedRightward": "向右倾斜",
    "microbit.gestruesMenu.tiltedUpward": "向上倾斜",
    "microbit.gesturesMenu.jumped": "抛起",
    "microbit.gesturesMenu.moved": "移动",
    "microbit.gesturesMenu.shaken": "晃动",
    "microbit.isButtonPressed": "按下[BTN]按钮？",
    "microbit.isTilted": "向[DIRECTION]倾斜？",
    "microbit.ledState.off": "熄灭",
    "microbit.ledState.on": "点亮",
    "microbit.levelMenu.high": "高",
    "microbit.levelMenu.low": "低",
    "microbit.pinStateMenu.off": "低电平",
    "microbit.pinStateMenu.on": "高电平",
    "microbit.pins.pinIsTouched": "引脚 [PIN] 被触摸",
    "microbit.pins.readAnalogPin": "读取模拟引脚 [PIN]",
    "microbit.pins.readDigitalPin": "读取数字引脚 [PIN]",
    "microbit.pins.setDigitalOutput": "设置引脚 [PIN] 输出 [LEVEL]",
    "microbit.pins.setPwmOutput": "设置 pwm 引脚 [PIN] 输出 [OUT]",
    "microbit.sensor.axisAcceleration": "[AXIS] 轴加速度",
    "microbit.sensor.buttonIsPressed": "按键 [KEY] 被按下?",
    "microbit.sensor.calibrateCompass": "校准指南针",
    "microbit.sensor.compassAngle": "指南针角度",
    "microbit.sensor.compassMagneticDensity": "指南针磁场强度",
    "microbit.sensor.gestureIsX": "姿态是 [STA]?",
    "microbit.sensor.lightLevel": "亮度",
    "microbit.sensor.runningTime": "运行时间",
    "microbit.sensor.temperature": "温度",
    "microbit.tiltAngle": "向[DIRECTION]倾角",
    "microbit.tiltDirectionMenu.any": "任意",
    "microbit.tiltDirectionMenu.back": "后",
    "microbit.tiltDirectionMenu.front": "前",
    "microbit.tiltDirectionMenu.left": "左",
    "microbit.tiltDirectionMenu.right": "右",
    "microbit.whenButtonPressed": "当按下[BTN]按钮",
    "microbit.whenGesture": "当被[GESTURE]",
    "microbit.whenPinConnected": "当引脚[PIN]接地",
    "microbit.whenTilted": "当向[DIRECTION]倾斜",
    "microbit.wireless.closeWirelessCommunication": "关闭无线通讯",
    "microbit.wireless.openWirelessCommunication": "打开无线通讯",
    "microbit.wireless.receiveWirelessMessage": "收到无线消息",
    "microbit.wireless.resetWirelessCommunication": "复位无线通讯",
    "microbit.wireless.sendWirelessMessage": "发送无线消息 [TEXT]",
    "microbit.wireless.setWirelessCommunicationChannel": "设置无线通讯频道为 [CH]",
    "music.categoryName": "音乐",
    "music.changeTempo": "将演奏速度增加[TEMPO]",
    "music.drumBass": "(2) 低音鼓",
    "music.drumBongo": "(13) 邦戈鼓",
    "music.drumCabasa": "(15) 卡巴萨",
    "music.drumClaves": "(9) 音棒",
    "music.drumClosedHiHat": "(6) 闭击踩镲",
    "music.drumConga": "(14) 康加鼓",
    "music.drumCowbell": "(11) 牛铃",
    "music.drumCrashCymbal": "(4) 碎音钹",
    "music.drumCuica": "(18) 锯加鼓",
    "music.drumGuiro": "(16) 刮瓜",
    "music.drumHandClap": "(8) 手掌",
    "music.drumOpenHiHat": "(5) 开击踩镲",
    "music.drumSideStick": "(3) 敲鼓边",
    "music.drumSnare": "(1) 小军鼓",
    "music.drumTambourine": "(7) 铃鼓",
    "music.drumTriangle": "(12) 三角铁",
    "music.drumVibraslap": "(17) 颤音器",
    "music.drumWoodBlock": "(10) 木鱼",
    "music.getTempo": "演奏速度",
    "music.instrumentBass": "(6) 贝斯",
    "music.instrumentBassoon": "(14) 巴松管",
    "music.instrumentCello": "(8) 大提琴",
    "music.instrumentChoir": "(15) 唱诗班",
    "music.instrumentClarinet": "(10) 单簧管",
    "music.instrumentElectricGuitar": "(5) 电吉他",
    "music.instrumentElectricPiano": "(2) 电钢琴",
    "music.instrumentFlute": "(12) 长笛",
    "music.instrumentGuitar": "(4) 吉他",
    "music.instrumentMarimba": "(19) 马林巴琴",
    "music.instrumentMusicBox": "(17) 八音盒",
    "music.instrumentOrgan": "(3) 风琴",
    "music.instrumentPiano": "(1) 钢琴",
    "music.instrumentPizzicato": "(7) 拨弦",
    "music.instrumentSaxophone": "(11) 萨克斯管",
    "music.instrumentSteelDrum": "(18) 钢鼓",
    "music.instrumentSynthLead": "(20) 合成主音",
    "music.instrumentSynthPad": "(21) 合成柔音",
    "music.instrumentTrombone": "(9) 长号",
    "music.instrumentVibraphone": "(16) 颤音琴",
    "music.instrumentWoodenFlute": "(13) 木长笛",
    "music.midiPlayDrumForBeats": "击打 [DRUM] [BEATS] 拍",
    "music.midiSetInstrument": "将乐器设为 [INSTRUMENT]",
    "music.playDrumForBeats": "击打 [DRUM] [BEATS] 拍",
    "music.playNoteForBeats": "演奏音符[NOTE][BEATS]拍",
    "music.restForBeats": "休止[BEATS]拍",
    "music.setInstrument": "将乐器设为 [INSTRUMENT]",
    "music.setTempo": "将演奏速度设定为[TEMPO]",
    "pen.categoryName": "画笔",
    "pen.changeColorParam": "将笔的 [COLOR_PARAM] 增加[VALUE]",
    "pen.changeHue": "将笔的颜色增加[HUE]",
    "pen.changeShade": "将笔的亮度增加[SHADE]",
    "pen.changeSize": "将笔的粗细增加[SIZE]",
    "pen.clear": "全部擦除",
    "pen.colorMenu.brightness": "亮度",
    "pen.colorMenu.color": "颜色",
    "pen.colorMenu.saturation": "饱和度",
    "pen.colorMenu.transparency": "透明度",
    "pen.penDown": "落笔",
    "pen.penUp": "抬笔",
    "pen.setColor": "将笔的颜色设为[COLOR]",
    "pen.setColorParam": "将笔的 [COLOR_PARAM] 设为[VALUE]",
    "pen.setHue": "将笔的颜色设为[HUE]",
    "pen.setShade": "将笔的亮度设为[SHADE]",
    "pen.setSize": "将笔的粗细设为[SIZE]",
    "pen.stamp": "图章",
    "speech.defaultWhenIHearValue": "开始",
    "speech.extensionName": "语音识别",
    "speech.listenAndWait": "听候语音输入",
    "speech.speechReporter": "语音输入",
    "speech.whenIHear": "当听到[PHRASE]",
    "text2speech.alto": "中音",
    "text2speech.categoryName": "文字朗读",
    "text2speech.defaultTextToSpeak": "你好",
    "text2speech.giant": "巨人",
    "text2speech.kitten": "小猫",
    "text2speech.setLanguageBlock": "将朗读语言设置为[LANGUAGE]",
    "text2speech.setVoiceBlock": "使用 [VOICE] 嗓音",
    "text2speech.speakAndWaitBlock": "朗读 [WORDS]",
    "text2speech.squeak": "尖细",
    "text2speech.tenor": "男高音",
    "translate.categoryName": "翻译",
    "translate.defaultTextToTranslate": "你好",
    "translate.translateBlock": "将 [WORDS] 译为 [LANGUAGE]",
    "translate.viewerLanguage": "访客语言",
    "videoSensing.categoryName": "视频侦测",
    "videoSensing.direction": "方向",
    "videoSensing.motion": "运动",
    "videoSensing.off": "关闭",
    "videoSensing.on": "开启",
    "videoSensing.onFlipped": "镜像开启",
    "videoSensing.setVideoTransparency": "将视频透明度设为[TRANSPARENCY]",
    "videoSensing.sprite": "角色",
    "videoSensing.stage": "舞台",
    "videoSensing.videoOn": "相对于[SUBJECT]的视频[ATTRIBUTE]",
    "videoSensing.videoToggle": "[VIDEO_STATE]摄像头",
    "videoSensing.whenMotionGreaterThan": "当视频运动 > [REFERENCE]",
    "wedo2.getDistance": "距离",
    "wedo2.getTiltAngle": "向[TILT_DIRECTION]倾角",
    "wedo2.isTilted": "向[TILT_DIRECTION_ANY]倾斜？",
    "wedo2.motorDirection.backward": "逆时针",
    "wedo2.motorDirection.forward": "顺时针",
    "wedo2.motorDirection.reverse": "反转",
    "wedo2.motorId.a": "马达A",
    "wedo2.motorId.all": "所有马达",
    "wedo2.motorId.b": "马达B",
    "wedo2.motorId.default": "马达",
    "wedo2.motorOff": "关闭[MOTOR_ID]",
    "wedo2.motorOn": "开启[MOTOR_ID]",
    "wedo2.motorOnFor": "开启[MOTOR_ID][DURATION]秒",
    "wedo2.playNoteFor": "演奏音符[NOTE][DURATION]秒",
    "wedo2.setLightHue": "将灯光颜色设为[HUE]",
    "wedo2.setMotorDirection": "将[MOTOR_ID]转向设为[MOTOR_DIRECTION]",
    "wedo2.startMotorPower": "将[MOTOR_ID]功率设为[POWER]",
    "wedo2.tiltDirection.any": "任意方向",
    "wedo2.tiltDirection.down": "下",
    "wedo2.tiltDirection.left": "左",
    "wedo2.tiltDirection.right": "右",
    "wedo2.tiltDirection.up": "上",
    "wedo2.whenDistance": "当距离[OP][REFERENCE]",
    "wedo2.whenTilted": "当向[TILT_DIRECTION_ANY]倾斜",
    "paint.colorPicker.swap": "交换",
    "paint.paintEditor.hue": "颜色",
    "paint.paintEditor.saturation": "饱和度",
    "paint.paintEditor.brightness": "亮度",
    "paint.paintEditor.costume": "造型",
    "paint.paintEditor.group": "组合",
    "paint.paintEditor.ungroup": "拆散",
    "paint.paintEditor.undo": "撤销",
    "paint.paintEditor.redo": "重做",
    "paint.paintEditor.forward": "往前放",
    "paint.paintEditor.backward": "往后放",
    "paint.paintEditor.front": "放最前面",
    "paint.paintEditor.back": "放最后面",
    "paint.paintEditor.more": "更多",
    "paint.modeTools.brushSize": "粗细",
    "paint.modeTools.eraserSize": "橡皮擦大小",
    "paint.modeTools.copy": "复制",
    "paint.modeTools.paste": "粘贴",
    "paint.modeTools.delete": "删除",
    "paint.modeTools.curved": "曲线",
    "paint.modeTools.pointed": "折线",
    "paint.modeTools.thickness": "粗细",
    "paint.modeTools.flipHorizontal": "水平翻转",
    "paint.modeTools.flipVertical": "垂直翻转",
    "paint.modeTools.filled": "实心",
    "paint.modeTools.outlined": "轮廓",
    "paint.paintEditor.bitmap": "转换为位图",
    "paint.paintEditor.vector": "转换为矢量图",
    "paint.paintEditor.fill": "填充",
    "paint.paintEditor.stroke": "轮廓",
    "paint.brushMode.brush": "画笔",
    "paint.eraserMode.eraser": "橡皮擦",
    "paint.fillMode.fill": "填充",
    "paint.lineMode.line": "线段",
    "paint.ovalMode.oval": "圆",
    "paint.rectMode.rect": "矩形",
    "paint.reshapeMode.reshape": "变形",
    "paint.roundedRectMode.roundedRect": "圆角矩形",
    "paint.selectMode.select": "选择",
    "paint.textMode.text": "文本"
  }
};
