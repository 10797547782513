const blockSeparator = '<sep gap="36"/>'; // At default scale, about 28px
const categorySeparator = '<sep gap="36"/>';
import ScratchBlocks from '../../../../mymodule/ztech-blocks/dist/horizontal.js';


export const motion = function (isInitialSetup, isStage, targetId) {
    const stageSelected = ScratchBlocks.ScratchMsgs.translate(
        'MOTION_STAGE_SELECTED',
        'Stage selected: no motion blocks'
    );
    return `
    <category name="%{BKY_CATEGORY_MOTION}" id="motion" colour="#4C97FF" secondaryColour="#3373CC">
    ${isStage ? `
    <label text="${stageSelected}"></label>
    ` : `
        <block type="hareket_ileri">
            <value name="SPEED">
                <shadow type="dropdown_speed">
                    <field name="SPEED">1</field>
                </shadow>
            </value>       
        </block>
        <block type="hareket_geri">
            <value name="SPEED">
                <shadow type="dropdown_speed_back">
                    <field name="SPEED">1</field>
                </shadow>
            </value>   
        </block>
        <block type="hareket_ileri">
            <value name="SPEED">
                <shadow type="math_positive_number">
                    <field name="SPEED">1</field>
                </shadow>
            </value>       
        </block>
        <block type="hareket_geri">
            <value name="repeat">
                <shadow type="math_positive_number">
                    <field name="repeat">1</field>
                </shadow>
            </value>   
        </block>
        <block type="hareket_saga_don">
            <value name="repeat">
                <shadow type="math_positive_number">
                    <field name="repeat">1</field>
                </shadow>
            </value>
        </block>
        <block type="hareket_sola_don">
            <value name="repeat">
                <shadow type="math_positive_number">
                    <field name="repeat">1</field>
                </shadow>
            </value>
        </block>
        <block type="hareket_dur">
        </block>

        <block type="hareket_z1">
        </block>

        <block type="hareket_z2">
        </block>
`}
        ${categorySeparator}
    </category>
    `;
};