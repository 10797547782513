import messages from './tag-messages.js';
export default [
    {tag: 'animals', intlLabel: messages.animals},
    {tag: 'people', intlLabel: messages.people},
    {tag: 'fantasy', intlLabel: messages.fantasy},
    {tag: 'dance', intlLabel: messages.dance},
    {tag: 'music', intlLabel: messages.music},
    {tag: 'sports', intlLabel: messages.sports},
    {tag: 'food', intlLabel: messages.food},
    {tag: 'fashion', intlLabel: messages.fashion},
    {tag: 'letters', intlLabel: messages.letters},
    {tag: 'transport', intlLabel: messages.transport},
    {tag: 'flags', intlLabel: messages.flags},
    {tag: 'city', intlLabel: messages.city},
    {tag: 'plants', intlLabel: messages.plants},
    {tag: 'game', intlLabel: messages.game},
    {tag: 'others', intlLabel: messages.others},
];
