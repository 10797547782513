import ScratchBlocks from "../../../../../mymodule/ztech-blocks";

const categorySeparator = '<sep gap="36"/>';

const blockSeparator = '<sep gap="36"/>'; // At default scale, about 28px

/* eslint-disable no-unused-vars */

const aydinlatma = function () {
    return `
    <category
        name="%{BKY_CATEGORY_LIGHTING}"
        id="RGBLEDSTRIP_CATEGORY"
        colour="#7700FF"
        secondaryColour="#FF4D6A"
        >
        <block type="lights_on"/>
        <block type="lights_off"/>
        <block type="rgb_lights">
            <value name="COLOR">
                <shadow type="dropdown_rgb">
                    <field name="COLOR">red</field>
                </shadow>
            </value>
        </block>
    
    </category>
    `;
};

const speaker = function () {
    return `
    <category
        name="%{BKY_CATEGORY_SPEAKER}"
        id="Hoperlor_CATEGORY"
        colour="#00D7B0"
        secondaryColour="#FF4D6A"
        >

    <block type="PlayNoteForBeats" id="PlayNoteForBeats">
   
    <value name="X">
    <shadow type="text">
        <field name="TEXT">B0</field>
    </shadow>
<shadow type="math_float">
    <field name="NUM">0.25</field>
</shadow>
</value>

    </block> 

   
    <block type="PlaySoundAtFrequencyOfHzForSeconds" id="PlaySoundAtFrequencyOfHzForSeconds">
    <value name="X">
    <shadow type="note">
            <field name="NOTE">60</field>
    </shadow>
</value>
<value name="Y">
    <shadow type="math_whole_number">
        <field name="NUM">0</field>
    </shadow>
</value>
    </block> 


  

  

    <block type="OpenBuzzer" id="OpenBuzzer">
    </block>

    <block type="CloseBuzzer" id="CloseBuzzer">
    </block>


    </category>
    `;
};

const motionGame = function () {
    return `
    <category
        name="Hareket"
        id="Hareket"
        colour="#00D7B0"
        secondaryColour="#FF4D6A"
        >


        <block type="hareket_dur" id="hareket_dur">
       
            </block> 
    

            <block type="hareket_speed" id="hareket_speed">
           
           </block> 

           <block type="hareket_speed_back" id="hareket_speed_back">
           
           </block> 


   
    <block type="hareket_ileri" id="hareket_ileri">
<value name="SPEED">
    <shadow type="math_whole_number">
        <field name="NUM">1</field>
    </shadow>
</value>
    </block> 


    <block type="hareket_geri" id="hareket_geri">
            <value name="repeat">
                <shadow type="math_whole_number">
                    <field name="NUM">1</field>
                </shadow>
            </value>
                </block> 



    <block type="hareket_saga_don" id="hareket_saga_don">
    <value name="pressedkey">
            <shadow type="dropdown_keypressed">
                <field name="pressedkey">space</field>
            </shadow>
        </value>
        </block> 


        <block type="hareket_sola_don" id="hareket_sola_don">
        <value name="pressedkey">
        <shadow type="dropdown_keypressed">
            <field name="pressedkey">space</field>
        </shadow>
    </value>
            </block> 


            




    </category>
    `;
};

const sensorler = function () {
    return `
    <category
        name="%{BKY_CATEGORY_SENSING}"
        id="Sensorler_CATEGORY"
        colour="#fc4e15"
        secondaryColour="#FF4D6A"
        >

        <block type="ButtonPressed"/>
        <block type="BatteryLevel"/>

        <block type="UltrasonikInitPyboard"/>

        <block type="DistanceValueUltrasonik"/>
        

        <block type="EnvironmentLightIntensity"/>
      

      
        <block type="StartMotionSensor"/>
        <block type="TiltDirection"/>
        <block type="GearPotantiometerValue"/>
        <block type="DirectionTiltAngle"/>
        <block type="TiltAngle"/>
        <block type="Acceleration"/>


        <block type="soundIntensity"/>

        <block type="toprak_nem_read_pyboard"/>


        <block type="relative_humidity_read_pyboard"/>
        <block type="relative_tempature_read_pyboard"/>


        <block type="waterproof_tempature_read_pyboard"/>



    </category>
    `;
};

const oled = function () {
    return `
    <category
        name="Oled"
        id="OLED_CATEGORY"
        colour="#FF8C1A"
        secondaryColour="#FF4D6A"
        >

    <block type="display_deneme" id="display_deneme">
        <value name="MATRIX"> 
            <shadow type="matrixjr"> 
                <field name="MATRIX">00000000000000000000000000000000001111100111110000001100000110000001000000100000001111100111110000000000000000000000000000000000</field> 
            </shadow> 
        </value>
    </block>

  
    <block type="display_deneme2" id="display_deneme2">
    <value name="MATRIX"> 
        <shadow type="matrixjr"> 
            <field name="MATRIX">00000000000000000000000000000000001111100111110000001100000110000001000000100000001111100111110000000000000000000000000000000000</field> 
        </shadow> 
    </value>
</block>


<block type="display_deneme3" id="display_deneme3">
<value name="MATRIX"> 
    <shadow type="matrixjr"> 
        <field name="MATRIX">00000000000000000000000000000000001111100111110000001100000110000001000000100000001111100111110000000000000000000000000000000000</field> 
    </shadow> 
</value>
</block>

<block type="display_deneme4" id="display_deneme4">
<value name="MATRIX"> 
    <shadow type="matrixjr"> 
        <field name="MATRIX">00000000000000000000000000000000001111100111110000001100000110000001000000100000001111100111110000000000000000000000000000000000</field> 
    </shadow> 
</value>
</block>

  
    </category>
    `;
};

const motion = function (isInitialSetup, isStage, targetId) {
    const stageSelected = ScratchBlocks.ScratchMsgs.translate(
        "MOTION_STAGE_SELECTED",
        "Stage selected: no motion blocks"
    );
    return `
    <category name="%{BKY_CATEGORY_MOTION}" id="motion" colour="#4C97FF" secondaryColour="#3373CC">
        ${
            isStage
                ? `
        <label text="${stageSelected}"></label>
        `
                : `
        <block type="hareket_ileri">
        <value name="SPEED">
            <shadow type="dropdown_speed">
                <field name="SPEED">1</field>
            </shadow>
        </value>       
    </block>
    <block type="hareket_geri">
        <value name="SPEED">
            <shadow type="dropdown_speed_back">
                <field name="SPEED">1</field>
            </shadow>
        </value>   
    </block>
    <block type="hareket_ileri">
        <value name="repeat">
            <shadow type="math_positive_number">
                <field name="repeat">1</field>
            </shadow>
        </value>       
    </block>
    <block type="hareket_geri">
        <value name="repeat">
            <shadow type="math_positive_number">
                <field name="repeat">1</field>
            </shadow>
        </value>   
    </block>
    <block type="hareket_saga_don">
        <value name="repeat">
            <shadow type="math_positive_number">
                <field name="repeat">1</field>
            </shadow>
        </value>
    </block>
    <block type="hareket_sola_don">
        <value name="repeat">
            <shadow type="math_positive_number">
                <field name="repeat">1</field>
            </shadow>
        </value>
    </block>
    <block type="hareket_dur">
    </block>
        `
        }
        ${categorySeparator}
    </category>
    `;
};

const looks = function (
    isInitialSetup,
    isStage,
    targetId,
    costumeName,
    backdropName
) {
    const hello = ScratchBlocks.ScratchMsgs.translate("LOOKS_HELLO", "Hello!");
    const hmm = ScratchBlocks.ScratchMsgs.translate("LOOKS_HMM", "Hmm...");
    return `
    <category name="%{BKY_CATEGORY_LOOKS}" id="looks" colour="#9966FF" secondaryColour="#774DCB">
        ${
            isStage
                ? ""
                : `
        <block type="looks_sayforsecs">
            <value name="MESSAGE">
                <shadow type="text">
                    <field name="TEXT">${hello}</field>
                </shadow>
            </value>
            <value name="SECS">
                <shadow type="math_number">
                    <field name="NUM">2</field>
                </shadow>
            </value>
        </block>
        <block type="looks_say">
            <value name="MESSAGE">
                <shadow type="text">
                    <field name="TEXT">${hello}</field>
                </shadow>
            </value>
        </block>
        <block type="looks_thinkforsecs">
            <value name="MESSAGE">
                <shadow type="text">
                    <field name="TEXT">${hmm}</field>
                </shadow>
            </value>
            <value name="SECS">
                <shadow type="math_number">
                    <field name="NUM">2</field>
                </shadow>
            </value>
        </block>
        <block type="looks_think">
            <value name="MESSAGE">
                <shadow type="text">
                    <field name="TEXT">${hmm}</field>
                </shadow>
            </value>
        </block>
        ${blockSeparator}
        `
        }
        ${
            isStage
                ? `
            <block type="looks_switchbackdropto">
                <value name="BACKDROP">
                    <shadow type="looks_backdrops">
                        <field name="BACKDROP">${backdropName}</field>
                    </shadow>
                </value>
            </block>
            <block type="looks_switchbackdroptoandwait">
                <value name="BACKDROP">
                    <shadow type="looks_backdrops">
                        <field name="BACKDROP">${backdropName}</field>
                    </shadow>
                </value>
            </block>
            <block type="looks_nextbackdrop"/>
        `
                : `
            <block id="${targetId}_switchcostumeto" type="looks_switchcostumeto">
                <value name="COSTUME">
                    <shadow type="looks_costume">
                        <field name="COSTUME">${costumeName}</field>
                    </shadow>
                </value>
            </block>
            <block type="looks_nextcostume"/>
            <block type="looks_switchbackdropto">
                <value name="BACKDROP">
                    <shadow type="looks_backdrops">
                        <field name="BACKDROP">${backdropName}</field>
                    </shadow>
                </value>
            </block>
            <block type="looks_nextbackdrop"/>
            ${blockSeparator}
            <block type="looks_changesizeby">
                <value name="CHANGE">
                    <shadow type="math_number">
                        <field name="NUM">10</field>
                    </shadow>
                </value>
            </block>
            <block type="looks_setsizeto">
                <value name="SIZE">
                    <shadow type="math_number">
                        <field name="NUM">100</field>
                    </shadow>
                </value>
            </block>
        `
        }
        ${blockSeparator}
        <block type="looks_changeeffectby">
            <value name="CHANGE">
                <shadow type="math_number">
                    <field name="NUM">25</field>
                </shadow>
            </value>
        </block>
        <block type="looks_seteffectto">
            <value name="VALUE">
                <shadow type="math_number">
                    <field name="NUM">0</field>
                </shadow>
            </value>
        </block>
        <block type="looks_cleargraphiceffects"/>
        ${blockSeparator}
        ${
            isStage
                ? ""
                : `
            <block type="looks_show"/>
            <block type="looks_hide"/>
        ${blockSeparator}
            <block type="looks_gotofrontback"/>
            <block type="looks_goforwardbackwardlayers">
                <value name="NUM">
                    <shadow type="math_integer">
                        <field name="NUM">1</field>
                    </shadow>
                </value>
            </block>
        `
        }
        ${
            isStage
                ? `
            <block id="backdropnumbername" type="looks_backdropnumbername"/>
        `
                : `
            <block id="${targetId}_costumenumbername" type="looks_costumenumbername"/>
            <block id="backdropnumbername" type="looks_backdropnumbername"/>
            <block id="${targetId}_size" type="looks_size"/>
        `
        }
        ${categorySeparator}
    </category>
    `;
};

const sound = function (isInitialSetup, isStage, targetId, soundName) {
    return `
    <category name="%{BKY_CATEGORY_SOUND}" id="sound" colour="#D65CD6" secondaryColour="#BD42BD">
    <block type="play_notes">
    <value name="NOTES">
        <shadow type="dropdown_notes">
            <field name="NOTES">do</field>
        </shadow>
    </value>
</block>
<block type="set_volume">
    <value name="VOLUME">
        <shadow type="dropdown_volume">
            <field name="VOLUME">100</field>
        </shadow>
    </value>
</block>
<block type="play_sound">
    <value name="SOUND">
        <shadow type="dropdown_sound">
            <field name="SOUND">cat</field>
        </shadow>
    </value>
</block>
<block type="record_sound"></block>
<block type="stop_recording"></block>
<block type="play_recording"></block>
<block type="stop_all_sounds"></block>

${categorySeparator}
    </category>
    `;
};

const events = function (isInitialSetup, isStage, targetId, isRealtimeMode) {
    return `
    <category name="%{BKY_CATEGORY_EVENTS}" id="events" colour="#FFD500" secondaryColour="#CC9900">
        ${
            isRealtimeMode
                ? `
        <block type="game_event_whenflagclicked"/>

        <block type="game_when_shake_robot">        </block> 

   

        <block type="game_when_detect_light">

        <value name="X">
        <shadow type="math_float">
            <field name="NUM">100</field>
        </shadow>
    </value>
    
        </block> 


        <block type="game_detect_sound_intensity" >

        <value name="X">
        <shadow type="math_float">
            <field name="NUM">100</field>
        </shadow>
    </value>
    
        </block> 
       
   
   

        <block type="game_when_detect_color">
        <value name="pressedkey">
            <shadow type="dropdown_keypressed">
                <field name="pressedkey">space</field>
            </shadow>
        </value>
    </block>

    <block type="game_when_detect_barrier">
    <value name="pressedkey">
        <shadow type="dropdown_keypressed">
            <field name="pressedkey">space</field>
        </shadow>
    </value>
</block>


<block type="game_when_keypress_game">
<value name="pressedkey">
    <shadow type="dropdown_keypressed">
        <field name="pressedkey">space</field>
    </shadow>
</value>
</block>


        <block type="event_whenkeypressed">
            <value name="pressedkey">
                <shadow type="dropdown_keypressed">
                    <field name="pressedkey">space</field>
                </shadow>
            </value>
        </block>

        <block type="event_whenbroadcastreceived">
            <value name="CHOICE">
                <shadow type="dropdown_whenbroadcast">
                    <field name="CHOICE">blue</field>
                </shadow>
            </value>
        </block>

        <block type="event_broadcast">
            <value name="CHOICE">
                <shadow type="dropdown_broadcast">
                    <field name="CHOICE">blue</field>
                </shadow>
            </value> 
        </block> 
        `
                : `
            <block type="event_whenarduinobegin"/>
        `
        }
        ${categorySeparator}

        <block type="event_whenbroadcastreceived" id="event_whenbroadcastreceived">
    </block>
    <block type="event_broadcast" id="event_broadcast">
      <value name="BROADCAST_INPUT">
     <shadow type="event_broadcast_menu"></shadow>
      </value>
    </block>
    <block type="event_broadcastandwait" id="event_broadcastandwait">
      <value name="BROADCAST_INPUT">
        <shadow type="event_broadcast_menu"></shadow>
      </value>
    </block>
    </category>
    `;
};

const control = function (isInitialSetup, isStage, targetId, isRealtimeMode) {
    return `
    <category name="%{BKY_CATEGORY_CONTROL}" id="control" colour="#FFAB19" secondaryColour="#CF8B17">
    <block type="control_wait">
    <value name="DURATION">
        <shadow type="math_positive_number">
            <field name="NUM">1</field>
        </shadow>
    </value>
</block>
${blockSeparator}
<block type="control_repeat">
    <value name="TIMES">
        <shadow type="math_whole_number">
            <field name="NUM">10</field>
        </shadow>
    </value>
</block>
<block id="forever" type="control_forever"/>
<block type="control_stop"/>

        ${categorySeparator}
    </category>
    `;
};

const sensing = function (isInitialSetup, isStage) {
    const name = ScratchBlocks.ScratchMsgs.translate(
        "SENSING_ASK_TEXT",
        "What's your name?"
    );
    return `
    <category name="%{BKY_CATEGORY_SENSING}" id="sensing" colour="#4CBFE6" secondaryColour="#2E8EB8">
        ${
            isStage
                ? ""
                : `
            <block type="sensing_touchingobject">
                <value name="TOUCHINGOBJECTMENU">
                    <shadow type="sensing_touchingobjectmenu"/>
                </value>
            </block>
            <block type="sensing_touchingcolor">
                <value name="COLOR">
                    <shadow type="colour_picker"/>
                </value>
            </block>
            <block type="sensing_coloristouchingcolor">
                <value name="COLOR">
                    <shadow type="colour_picker"/>
                </value>
                <value name="COLOR2">
                    <shadow type="colour_picker"/>
                </value>
            </block>
            <block type="sensing_distanceto">
                <value name="DISTANCETOMENU">
                    <shadow type="sensing_distancetomenu"/>
                </value>
            </block>
            ${blockSeparator}
        `
        }
        ${
            isInitialSetup
                ? ""
                : `
            <block id="askandwait" type="sensing_askandwait">
                <value name="QUESTION">
                    <shadow type="text">
                        <field name="TEXT">${name}</field>
                    </shadow>
                </value>
            </block>
        `
        }
        <block id="answer" type="sensing_answer"/>
        ${blockSeparator}
        <block type="sensing_keypressed">
            <value name="KEY_OPTION">
                <shadow type="sensing_keyoptions"/>
            </value>
        </block>
        <block type="sensing_mousedown"/>
        <block type="sensing_mousex"/>
        <block type="sensing_mousey"/>
        ${
            isStage
                ? ""
                : `
            ${blockSeparator}
            '<block type="sensing_setdragmode" id="sensing_setdragmode"></block>'+
            ${blockSeparator}
        `
        }
        ${blockSeparator}
        <block id="loudness" type="sensing_loudness"/>
        ${blockSeparator}
        <block id="timer" type="sensing_timer"/>
        <block type="sensing_resettimer"/>
    
        ${blockSeparator}
        <block id="current" type="sensing_current"/>
        <block type="sensing_dayssince2000"/>
        ${blockSeparator}
        <block type="sensing_username"/>
        ${categorySeparator}
    </category>
    `;
};

const operators = function (isInitialSetup) {
    const apple = ScratchBlocks.ScratchMsgs.translate(
        "OPERATORS_JOIN_APPLE",
        "apple"
    );
    const banana = ScratchBlocks.ScratchMsgs.translate(
        "OPERATORS_JOIN_BANANA",
        "banana"
    );
    const letter = ScratchBlocks.ScratchMsgs.translate(
        "OPERATORS_LETTEROF_APPLE",
        "a"
    );
    return `
    <category name="%{BKY_CATEGORY_OPERATORS}" id="operators" colour="#40BF4A" secondaryColour="#389438">
        <block type="operator_add">
            <value name="NUM1">
                <shadow type="math_number">
                    <field name="NUM"/>
                </shadow>
            </value>
            <value name="NUM2">
                <shadow type="math_number">
                    <field name="NUM"/>
                </shadow>
            </value>
        </block>
        <block type="operator_subtract">
            <value name="NUM1">
                <shadow type="math_number">
                    <field name="NUM"/>
                </shadow>
            </value>
            <value name="NUM2">
                <shadow type="math_number">
                    <field name="NUM"/>
                </shadow>
            </value>
        </block>
        <block type="operator_multiply">
            <value name="NUM1">
                <shadow type="math_number">
                    <field name="NUM"/>
                </shadow>
            </value>
            <value name="NUM2">
                <shadow type="math_number">
                    <field name="NUM"/>
                </shadow>
            </value>
        </block>
        <block type="operator_divide">
            <value name="NUM1">
                <shadow type="math_number">
                    <field name="NUM"/>
                </shadow>
            </value>
            <value name="NUM2">
                <shadow type="math_number">
                    <field name="NUM"/>
                </shadow>
            </value>
        </block>
        ${blockSeparator}
        <block type="operator_random">
            <value name="FROM">
                <shadow type="math_number">
                    <field name="NUM">1</field>
                </shadow>
            </value>
            <value name="TO">
                <shadow type="math_number">
                    <field name="NUM">10</field>
                </shadow>
            </value>
        </block>
        ${blockSeparator}
        <block type="operator_gt">
            <value name="OPERAND1">
                <shadow type="text">
                    <field name="TEXT"/>
                </shadow>
            </value>
            <value name="OPERAND2">
                <shadow type="text">
                    <field name="TEXT">50</field>
                </shadow>
            </value>
        </block>
        <block type="operator_lt">
            <value name="OPERAND1">
                <shadow type="text">
                    <field name="TEXT"/>
                </shadow>
            </value>
            <value name="OPERAND2">
                <shadow type="text">
                    <field name="TEXT">50</field>
                </shadow>
            </value>
        </block>
        <block type="operator_equals">
            <value name="OPERAND1">
                <shadow type="text">
                    <field name="TEXT"/>
                </shadow>
            </value>
            <value name="OPERAND2">
                <shadow type="text">
                    <field name="TEXT">50</field>
                </shadow>
            </value>
        </block>
        ${blockSeparator}
        <block type="operator_and"/>
        <block type="operator_or"/>
        <block type="operator_not"/>
        ${blockSeparator}
        ${
            isInitialSetup
                ? ""
                : `
            <block type="operator_join">
                <value name="STRING1">
                    <shadow type="text">
                        <field name="TEXT">${apple}</field>
                    </shadow>
                </value>
                <value name="STRING2">
                    <shadow type="text">
                        <field name="TEXT">${banana}</field>
                    </shadow>
                </value>
            </block>
            <block type="operator_letter_of">
                <value name="LETTER">
                    <shadow type="math_whole_number">
                        <field name="NUM">1</field>
                    </shadow>
                </value>
                <value name="STRING">
                    <shadow type="text">
                        <field name="TEXT">${apple}</field>
                    </shadow>
                </value>
            </block>
            <block type="operator_length">
                <value name="STRING">
                    <shadow type="text">
                        <field name="TEXT">${apple}</field>
                    </shadow>
                </value>
            </block>
            <block type="operator_contains" id="operator_contains">
              <value name="STRING1">
                <shadow type="text">
                  <field name="TEXT">${apple}</field>
                </shadow>
              </value>
              <value name="STRING2">
                <shadow type="text">
                  <field name="TEXT">${letter}</field>
                </shadow>
              </value>
            </block>
        `
        }
        ${blockSeparator}
        <block type="operator_mod">
            <value name="NUM1">
                <shadow type="math_number">
                    <field name="NUM"/>
                </shadow>
            </value>
            <value name="NUM2">
                <shadow type="math_number">
                    <field name="NUM"/>
                </shadow>
            </value>
        </block>
        <block type="operator_round">
            <value name="NUM">
                <shadow type="math_number">
                    <field name="NUM"/>
                </shadow>
            </value>
        </block>
        ${blockSeparator}
        <block type="operator_mathop">
            <value name="NUM">
                <shadow type="math_number">
                    <field name="NUM"/>
                </shadow>
            </value>
        </block>
        ${categorySeparator}
    </category>
    `;
};

const variables = function () {
    return `
    <category
        name="%{BKY_CATEGORY_VARIABLES}"
        id="variables"
        colour="#FF8C1A"
        secondaryColour="#DB6E00"
        custom="VARIABLE">
    </category>
    `;
};

const myBlocks = function () {
    return `
    <category
        name="%{BKY_CATEGORY_MYBLOCKS}"
        id="myBlocks"
        colour="#FF6680"
        secondaryColour="#FF4D6A"
        custom="PROCEDURE">
    </category>
    `;
};

const hareket = function () {
    return `
    <category
        name="%{BKY_CATEGORY_MOTION}"
        id="hareket_CATEGORY"
        colour="#094fc0"
        secondaryColour="#FF4D6A"
        >

    <block type="sec_git" id="sec_git">
    <value name="Y">
        <shadow type="math_power_number">
            <field name="NUM">0</field>
        </shadow>
    </value>
        </block> 


        <block type="sec_git_rpm" id="sec_git_rpm">
        <value name="X">
        <shadow type="math_rpm_number">
            <field name="NUM">0</field>
        </shadow>
    </value>
    <value name="Y">
        <shadow type="math_rpm_number">
            <field name="NUM">0</field>
        </shadow>
    </value>
        </block>


        <block type="sol_saga_dereceli_git_power" id="sol_saga_dereceli_git_power">
        <value name="X">
        <shadow type="math_power_number">
            <field name="NUM">0</field>
        </shadow>
    </value>
    <value name="Y">
        <shadow type="math_power_number">
            <field name="NUM">0</field>
        </shadow>
    </value>
        </block> 



        <block type="sol_saga_dereceli_git_rpm" id="sol_saga_dereceli_git_rpm">
        <value name="X">
        <shadow type="math_rpm_number">
            <field name="NUM">0</field>
        </shadow>
    </value>
    <value name="Y">
        <shadow type="math_rpm_number">
            <field name="NUM">0</field>
        </shadow>
    </value>
        </block> 


        <block type="motor_rotates_at" id="motor_rotates_at">
        <value name="X">
        <shadow type="math_rpm_number">
            <field name="NUM">0</field>
        </shadow>
    </value>
        </block> 


        
      ///buradan


      <block type="guc_saniye_yon" id="guc_saniye_yon">
      <value name="X">
      <shadow type="math_power_number_positif">
          <field name="NUM">0</field>
      </shadow>
  </value>
  <value name="Y">
  <shadow type="math_whole_number">
      <field name="NUM">0</field>
  </shadow>
</value>    
      </block> 


      <block type="rpm_saniye_yon" id="rpm_saniye_yon">
      <value name="X">
      <shadow type="math_rpm_number_positif">
          <field name="NUM">0</field>
      </shadow>
  </value>
  <value name="Y">
  <shadow type="math_whole_number">
      <field name="NUM">0</field>
  </shadow>
</value>    
      </block>

     


  <block type="hareketi_durdur" id="hareketi_durdur">
  </block>



  <block type="motor_rotates_at_time" id="motor_rotates_at_time">
  <value name="X">
  <shadow type="math_rpm_number">
      <field name="NUM">0</field>
  </shadow>
</value>
<value name="Y">
<shadow type="math_whole_number">
  <field name="NUM">0</field>
</shadow>
</value>
  </block> 


  <block type="motor_rotated_angle" id="motor_rotated_angle">
  <value name="X">
  <shadow type="aci_araligi">
      <field name="NUM">0</field>
  </shadow>
</value>

  </block> 

  

  
  <block type="motor_moves_cm" id="motor_moves_cm">
  <value name="X">
  <shadow type="math_whole_number">
      <field name="NUM">0</field>
  </shadow>
</value>
  </block>

    </category>
    `;
};

const gorunum = function () {
    return `
    <category
        name="Görünüm"
        id="Görünüm_CATEGORY"
        colour="#14b379"
        secondaryColour="#FF4D6A"
        >

        <block type="resim_goster_delay" id="resim_goster_delay">
        <value name="MATRIX"> 
          <shadow type="matrix"> 
            <field name="MATRIX">00000000000000000000000000000000001111100111110000001100000110000001000000100000001111100111110000000000000000000000000000000000</field> 
          </shadow> 
        </value>
        <value name="X">
    <shadow type="math_whole_number">
        <field name="NUM">0</field>
    </shadow>
</value>
      </block>

      <block type="resim_goster" id="resim_goster">
        <value name="MATRIX"> 
          <shadow type="matrix"> 
            <field name="MATRIX">0100000</field> 
          </shadow> 
        </value>
      </block>

      <block type="resim_goster_x_y" id="resim_goster_x_y">
        <value name="MATRIX"> 
          <shadow type="matrix"> 
            <field name="MATRIX">0100000</field> 
          </shadow> 
        </value>
        <value name="X">
    <shadow type="math_whole_number">
        <field name="NUM">0</field>
    </shadow>
</value>
<value name="Y">
    <shadow type="math_whole_number">
        <field name="NUM">0</field>
    </shadow>
</value>
      </block>
    
      <block type="ekrani_kapat" id="ekrani_kapat">
    </block>

    
    <block type="goster" id="goster">
    <value name="X">
    <shadow type="math_whole_number">
        <field name="NUM">0</field>
    </shadow>
</value>
    </block> 


    <block type="goster_kaydirilana_kadar" id="goster_kaydirilana_kadar">
    <value name="X">
    <shadow type="math_whole_number">
        <field name="NUM">0</field>
    </shadow>
</value>
    </block> 


    <block type="goster_x_y" id="goster_x_y">
    <value name="X">
    <shadow type="math_whole_number">
        <field name="NUM">0</field>
    </shadow>
</value>
<value name="Y">
<shadow type="math_whole_number">
    <field name="NUM">0</field>
</shadow>
</value>
<value name="Z">
<shadow type="math_whole_number">
    <field name="NUM">0</field>
</shadow>
</value>
    </block> 

    <block type="ısik_yak_x_y" id="ısik_yak_x_y">
    <value name="X">
    <shadow type="math_whole_number">
        <field name="NUM">0</field>
    </shadow>
</value>
<value name="Y">
<shadow type="math_whole_number">
    <field name="NUM">0</field>
</shadow>
</value>

    </block> 


    <block type="ısik_kapat_x_y" id="ısik_kapat_x_y">
    <value name="X">
    <shadow type="math_whole_number">
        <field name="NUM">0</field>
    </shadow>
</value>
<value name="Y">
<shadow type="math_whole_number">
    <field name="NUM">0</field>
</shadow>
</value>

    </block> 

    <block type="ısik_yak_sondur_x_y" id="ısik_yak_sondur_x_y">
    <value name="X">
    <shadow type="math_whole_number">
        <field name="NUM">0</field>
    </shadow>
</value>
<value name="Y">
<shadow type="math_whole_number">
    <field name="NUM">0</field>
</shadow>
</value>

    </block> 


    </category>
    `;
};

const network = function () {
    return `
    <category
        name="%{BKY_CATEGORY_NETWORK}"
        id="Network_CATEGORY"
        colour="#14b379"
        secondaryColour="#FF4D6A"
        >

        <block type="Connect2Wifi" id="Connect2Wifi">
             <value name="X">
        <shadow type="text">
            <field name="TEXT">SSID</field>
        </shadow>
            </value>
            <value name="Y">
        <shadow type="text">
            <field name="TEXT">PASSWORD</field>
        </shadow>
            </value>
        </block>


        <block type="CheckConnectionStatus" id="CheckConnectionStatus"></block>
        <block type="Reconnect2Wifi" id="Reconnect2Wifi"></block>
        <block type="DisconnectWifi" id="DisconnectWifi"></block>


        <block type="GetTimePyboard" id="GetTimePyboard"></block>


    </category>
    `;
};

const harici_sensorler = function () {
    return `
    <category
        name="Harici"
        id="Harici_CATEGORY"
        colour="#14b379"
        secondaryColour="#FF4D6A"
        >

        <block type="toprak_nem_init"/>
        <block type="toprak_nem_oku"/>
        <block type="relative_humidity_init"/>
        <block type="relative_humidity_oku"/>
        
        <block type="su_gecirmez_sicaklik_oku"/>
        <block type="su_gecirmez_sicaklik_init"/>
    </category>
    `;
};
/* eslint-enable no-unused-vars */

const getXML = function (
    isInitialSetup,
    isStage,
    targetId,
    isRealtimeMode,
    costumeName,
    backdropName,
    soundName
) {
    const gap = [categorySeparator];

    const motionXML = motion(isInitialSetup, isStage, targetId);
    const looksXML = looks(
        isInitialSetup,
        isStage,
        targetId,
        costumeName,
        backdropName
    );
    const soundXML = sound(isInitialSetup, isStage, targetId, soundName);
    const eventsXML = events(isInitialSetup, isStage, targetId, isRealtimeMode);
    const controlXML = control(
        isInitialSetup,
        isStage,
        targetId,
        isRealtimeMode
    );
    const sensingXML = sensing(isInitialSetup, isStage, targetId);
    const operatorsXML = operators(
        isInitialSetup,
        isStage,
        targetId,
        isRealtimeMode
    );
    const variablesXML = variables(
        isInitialSetup,
        isStage,
        targetId,
        isRealtimeMode
    );
    const myBlocksXML = myBlocks(isInitialSetup, isStage, targetId);
    const oledXML = oled(isInitialSetup, isStage, targetId);
    const aydinlatmaXML = aydinlatma(isInitialSetup, isStage, targetId);
    const speakerXML = speaker(isInitialSetup, isStage, targetId);
    const hareketXML = hareket(isInitialSetup, isStage, targetId);
    const motionGameXML = motionGame(isInitialSetup, isStage, targetId);

    const sensorlerXML = sensorler(isInitialSetup, isStage, targetId);
    const gorunumXML = gorunum(isInitialSetup, isStage, targetId);
    const networkXML = network(isInitialSetup, isStage, targetId);
    const harici_sensorlerXML = harici_sensorler(
        isInitialSetup,
        isStage,
        targetId
    );
    let baseToolboxXml = [];

    if (isRealtimeMode === true) {
        baseToolboxXml = [
            eventsXML,gap,
            motionGameXML,gap,
            controlXML,gap,
            operatorsXML,gap,
        //    aydinlatmaXML,gap,
         //   sensingXML,gap,
          //  motionXML,gap,
          //  looksXML,gap,
          //  speakerXML,gap,
            variablesXML,gap,
            myBlocksXML,
        ];
    } else {
        baseToolboxXml = [
            eventsXML, gap,
            controlXML,gap,
            operatorsXML,gap,
            //aydinlatmaXML, gap,
           // motionXML,gap,

            speakerXML, gap,
            sensorlerXML, gap,
            networkXML, gap,
            variablesXML, gap,
            myBlocksXML
        ];
    }

    return baseToolboxXml;
};

export default getXML;
