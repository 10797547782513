import React from "react";
import classNames from "classnames";
import styles from "../stage-modal.css";
import { FormattedMessage } from "react-intl";


const Ilerleme = ({ setSelected,setIlerlemeSubMenu }) => {

    return (
        <div>
            
        <div className={classNames(styles.container, styles.menuContainer)}>
            <div
                className={classNames(styles.selectBtn, styles.menuItemDouble)}
                onClick={() => {
                    setIlerlemeSubMenu(1);
                }}
            >
                <p className={classNames(styles.selectBtnText)}>
                <FormattedMessage
                    defaultMessage="Portal"
                    description="Portal menu title"
                    id="gui.game.modal.ilerlemePortal"
                />
                </p>
            </div>
            <div
                className={classNames(styles.selectBtn, styles.menuItemDouble)}
                onClick={() => {
                    setIlerlemeSubMenu(2);
                }}
            >
                <p className={classNames(styles.selectBtnText)}>
                <FormattedMessage
                    defaultMessage="Müzik"
                    description="Müzik menu title"
                    id="gui.game.modal.ilerlemeMuzik"
                />
                </p>
            </div>
            <div
                className={classNames(styles.selectBtn, styles.menuItemDouble)}
                onClick={() => {
                    setIlerlemeSubMenu(3);
                }}
            >
                <p className={classNames(styles.selectBtnText)}>
                <FormattedMessage
                    defaultMessage="Engel"
                    description="Engel menu title"
                    id="gui.game.modal.ilerlemeEngel"
                />
                </p>
            </div>
            {/* <div
                className={classNames(styles.selectBtn, styles.menuItemDouble)}
                onClick={() => {
                    setIlerlemeSubMenu(2);
                }}
            >
                <p className={classNames(styles.selectBtnText)}>
                    Ses -Işık Gösterileri
                </p>
            </div>
            <div
                className={classNames(styles.selectBtn, styles.menuItemDouble)}
                onClick={() => {
                    setIlerlemeSubMenu(3);
                }}
            >
                <p className={classNames(styles.selectBtnText)}>
                    Sensörler
                </p>
            </div>
            <div
                className={classNames(styles.selectBtn, styles.menuItemDouble)}
                onClick={() => {
                    setIlerlemeSubMenu(4);
                }}
            >
                <p className={classNames(styles.selectBtnText)}>
                    Parkurlar
                </p>
            </div> */}
        </div>       
        
        </div>
    );
};

export default Ilerleme;
