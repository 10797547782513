/*** IMPORTS FROM imports-loader ***/
(function() {

// Do not edit this file; automatically generated by build.py.
'use strict';

var $jscomp=$jscomp||{};$jscomp.scope={};var COMPILED=!0,goog=goog||{};goog.global=this;goog.isDef=function(a){return void 0!==a};goog.isString=function(a){return"string"==typeof a};goog.isBoolean=function(a){return"boolean"==typeof a};goog.isNumber=function(a){return"number"==typeof a};
goog.exportPath_=function(a,b,c){a=a.split(".");c=c||goog.global;a[0]in c||"undefined"==typeof c.execScript||c.execScript("var "+a[0]);for(var d;a.length&&(d=a.shift());)!a.length&&goog.isDef(b)?c[d]=b:c=c[d]&&c[d]!==Object.prototype[d]?c[d]:c[d]={}};
goog.define=function(a,b){if(!COMPILED){var c=goog.global.CLOSURE_UNCOMPILED_DEFINES,d=goog.global.CLOSURE_DEFINES;c&&void 0===c.nodeType&&Object.prototype.hasOwnProperty.call(c,a)?b=c[a]:d&&void 0===d.nodeType&&Object.prototype.hasOwnProperty.call(d,a)&&(b=d[a])}goog.exportPath_(a,b);return b};goog.DEBUG=!1;goog.LOCALE="en";goog.TRUSTED_SITE=!0;goog.STRICT_MODE_COMPATIBLE=!1;goog.DISALLOW_TEST_ONLY_CODE=COMPILED&&!goog.DEBUG;goog.ENABLE_CHROME_APP_SAFE_SCRIPT_LOADING=!1;
goog.provide=function(a){if(goog.isInModuleLoader_())throw Error("goog.provide cannot be used within a module.");if(!COMPILED&&goog.isProvided_(a))throw Error('Namespace "'+a+'" already declared.');goog.constructNamespace_(a)};goog.constructNamespace_=function(a,b){if(!COMPILED){delete goog.implicitNamespaces_[a];for(var c=a;(c=c.substring(0,c.lastIndexOf(".")))&&!goog.getObjectByName(c);)goog.implicitNamespaces_[c]=!0}goog.exportPath_(a,b)};
goog.getScriptNonce=function(a){if(a&&a!=goog.global)return goog.getScriptNonce_(a.document);null===goog.cspNonce_&&(goog.cspNonce_=goog.getScriptNonce_(goog.global.document));return goog.cspNonce_};goog.NONCE_PATTERN_=/^[\w+/_-]+[=]{0,2}$/;goog.cspNonce_=null;goog.getScriptNonce_=function(a){return(a=a.querySelector&&a.querySelector("script[nonce]"))&&(a=a.nonce||a.getAttribute("nonce"))&&goog.NONCE_PATTERN_.test(a)?a:""};goog.VALID_MODULE_RE_=/^[a-zA-Z_$][a-zA-Z0-9._$]*$/;
goog.module=function(a){if(!goog.isString(a)||!a||-1==a.search(goog.VALID_MODULE_RE_))throw Error("Invalid module identifier");if(!goog.isInGoogModuleLoader_())throw Error("Module "+a+" has been loaded incorrectly. Note, modules cannot be loaded as normal scripts. They require some kind of pre-processing step. You're likely trying to load a module via a script tag or as a part of a concatenated bundle without rewriting the module. For more info see: https://github.com/google/closure-library/wiki/goog.module:-an-ES6-module-like-alternative-to-goog.provide.");
if(goog.moduleLoaderState_.moduleName)throw Error("goog.module may only be called once per module.");goog.moduleLoaderState_.moduleName=a;if(!COMPILED){if(goog.isProvided_(a))throw Error('Namespace "'+a+'" already declared.');delete goog.implicitNamespaces_[a]}};goog.module.get=function(a){return goog.module.getInternal_(a)};
goog.module.getInternal_=function(a){if(!COMPILED){if(a in goog.loadedModules_)return goog.loadedModules_[a].exports;if(!goog.implicitNamespaces_[a])return a=goog.getObjectByName(a),null!=a?a:null}return null};goog.ModuleType={ES6:"es6",GOOG:"goog"};goog.moduleLoaderState_=null;goog.isInModuleLoader_=function(){return goog.isInGoogModuleLoader_()||goog.isInEs6ModuleLoader_()};goog.isInGoogModuleLoader_=function(){return!!goog.moduleLoaderState_&&goog.moduleLoaderState_.type==goog.ModuleType.GOOG};
goog.isInEs6ModuleLoader_=function(){if(goog.moduleLoaderState_&&goog.moduleLoaderState_.type==goog.ModuleType.ES6)return!0;var a=goog.global.$jscomp;return a?"function"!=typeof a.getCurrentModulePath?!1:!!a.getCurrentModulePath():!1};
goog.module.declareLegacyNamespace=function(){if(!COMPILED&&!goog.isInGoogModuleLoader_())throw Error("goog.module.declareLegacyNamespace must be called from within a goog.module");if(!COMPILED&&!goog.moduleLoaderState_.moduleName)throw Error("goog.module must be called prior to goog.module.declareLegacyNamespace.");goog.moduleLoaderState_.declareLegacyNamespace=!0};
goog.declareModuleId=function(a){if(!COMPILED){if(!goog.isInEs6ModuleLoader_())throw Error("goog.declareModuleId may only be called from within an ES6 module");if(goog.moduleLoaderState_&&goog.moduleLoaderState_.moduleName)throw Error("goog.declareModuleId may only be called once per module.");if(a in goog.loadedModules_)throw Error('Module with namespace "'+a+'" already exists.');}if(goog.moduleLoaderState_)goog.moduleLoaderState_.moduleName=a;else{var b=goog.global.$jscomp;if(!b||"function"!=typeof b.getCurrentModulePath)throw Error('Module with namespace "'+
a+'" has been loaded incorrectly.');b=b.require(b.getCurrentModulePath());goog.loadedModules_[a]={exports:b,type:goog.ModuleType.ES6,moduleId:a}}};goog.module.declareNamespace=goog.declareModuleId;goog.setTestOnly=function(a){if(goog.DISALLOW_TEST_ONLY_CODE)throw a=a||"",Error("Importing test-only code into non-debug environment"+(a?": "+a:"."));};goog.forwardDeclare=function(a){};
COMPILED||(goog.isProvided_=function(a){return a in goog.loadedModules_||!goog.implicitNamespaces_[a]&&goog.isDefAndNotNull(goog.getObjectByName(a))},goog.implicitNamespaces_={"goog.module":!0});goog.getObjectByName=function(a,b){a=a.split(".");b=b||goog.global;for(var c=0;c<a.length;c++)if(b=b[a[c]],!goog.isDefAndNotNull(b))return null;return b};goog.globalize=function(a,b){b=b||goog.global;for(var c in a)b[c]=a[c]};
goog.addDependency=function(a,b,c,d){!COMPILED&&goog.DEPENDENCIES_ENABLED&&goog.debugLoader_.addDependency(a,b,c,d)};goog.ENABLE_DEBUG_LOADER=!0;goog.logToConsole_=function(a){goog.global.console&&goog.global.console.error(a)};
goog.require=function(a){if(!COMPILED){goog.ENABLE_DEBUG_LOADER&&goog.debugLoader_.requested(a);if(goog.isProvided_(a)){if(goog.isInModuleLoader_())return goog.module.getInternal_(a)}else if(goog.ENABLE_DEBUG_LOADER){var b=goog.moduleLoaderState_;goog.moduleLoaderState_=null;try{goog.debugLoader_.load_(a)}finally{goog.moduleLoaderState_=b}}return null}};goog.requireType=function(a){return{}};goog.basePath="";goog.nullFunction=function(){};
goog.abstractMethod=function(){throw Error("unimplemented abstract method");};goog.addSingletonGetter=function(a){a.instance_=void 0;a.getInstance=function(){if(a.instance_)return a.instance_;goog.DEBUG&&(goog.instantiatedSingletons_[goog.instantiatedSingletons_.length]=a);return a.instance_=new a}};goog.instantiatedSingletons_=[];goog.LOAD_MODULE_USING_EVAL=!0;goog.SEAL_MODULE_EXPORTS=goog.DEBUG;goog.loadedModules_={};goog.DEPENDENCIES_ENABLED=!COMPILED&&goog.ENABLE_DEBUG_LOADER;goog.TRANSPILE="detect";
goog.ASSUME_ES_MODULES_TRANSPILED=!1;goog.TRANSPILE_TO_LANGUAGE="";goog.TRANSPILER="transpile.js";goog.hasBadLetScoping=null;goog.useSafari10Workaround=function(){if(null==goog.hasBadLetScoping){try{var a=!eval('"use strict";let x = 1; function f() { return typeof x; };f() == "number";')}catch(b){a=!1}goog.hasBadLetScoping=a}return goog.hasBadLetScoping};goog.workaroundSafari10EvalBug=function(a){return"(function(){"+a+"\n;})();\n"};
goog.loadModule=function(a){var b=goog.moduleLoaderState_;try{goog.moduleLoaderState_={moduleName:"",declareLegacyNamespace:!1,type:goog.ModuleType.GOOG};if(goog.isFunction(a))var c=a.call(void 0,{});else if(goog.isString(a))goog.useSafari10Workaround()&&(a=goog.workaroundSafari10EvalBug(a)),c=goog.loadModuleFromSource_.call(void 0,a);else throw Error("Invalid module definition");var d=goog.moduleLoaderState_.moduleName;if(goog.isString(d)&&d)goog.moduleLoaderState_.declareLegacyNamespace?goog.constructNamespace_(d,
c):goog.SEAL_MODULE_EXPORTS&&Object.seal&&"object"==typeof c&&null!=c&&Object.seal(c),goog.loadedModules_[d]={exports:c,type:goog.ModuleType.GOOG,moduleId:goog.moduleLoaderState_.moduleName};else throw Error('Invalid module name "'+d+'"');}finally{goog.moduleLoaderState_=b}};goog.loadModuleFromSource_=function(a){eval(a);return{}};goog.normalizePath_=function(a){a=a.split("/");for(var b=0;b<a.length;)"."==a[b]?a.splice(b,1):b&&".."==a[b]&&a[b-1]&&".."!=a[b-1]?a.splice(--b,2):b++;return a.join("/")};
goog.loadFileSync_=function(a){if(goog.global.CLOSURE_LOAD_FILE_SYNC)return goog.global.CLOSURE_LOAD_FILE_SYNC(a);try{var b=new goog.global.XMLHttpRequest;b.open("get",a,!1);b.send();return 0==b.status||200==b.status?b.responseText:null}catch(c){return null}};
goog.transpile_=function(a,b,c){var d=goog.global.$jscomp;d||(goog.global.$jscomp=d={});var e=d.transpile;if(!e){var f=goog.basePath+goog.TRANSPILER,g=goog.loadFileSync_(f);if(g){(function(){eval(g+"\n//# sourceURL="+f)}).call(goog.global);if(goog.global.$gwtExport&&goog.global.$gwtExport.$jscomp&&!goog.global.$gwtExport.$jscomp.transpile)throw Error('The transpiler did not properly export the "transpile" method. $gwtExport: '+JSON.stringify(goog.global.$gwtExport));goog.global.$jscomp.transpile=
goog.global.$gwtExport.$jscomp.transpile;d=goog.global.$jscomp;e=d.transpile}}e||(e=d.transpile=function(a,b){goog.logToConsole_(b+" requires transpilation but no transpiler was found.");return a});return e(a,b,c)};
goog.typeOf=function(a){var b=typeof a;if("object"==b)if(a){if(a instanceof Array)return"array";if(a instanceof Object)return b;var c=Object.prototype.toString.call(a);if("[object Window]"==c)return"object";if("[object Array]"==c||"number"==typeof a.length&&"undefined"!=typeof a.splice&&"undefined"!=typeof a.propertyIsEnumerable&&!a.propertyIsEnumerable("splice"))return"array";if("[object Function]"==c||"undefined"!=typeof a.call&&"undefined"!=typeof a.propertyIsEnumerable&&!a.propertyIsEnumerable("call"))return"function"}else return"null";
else if("function"==b&&"undefined"==typeof a.call)return"object";return b};goog.isNull=function(a){return null===a};goog.isDefAndNotNull=function(a){return null!=a};goog.isArray=function(a){return"array"==goog.typeOf(a)};goog.isArrayLike=function(a){var b=goog.typeOf(a);return"array"==b||"object"==b&&"number"==typeof a.length};goog.isDateLike=function(a){return goog.isObject(a)&&"function"==typeof a.getFullYear};goog.isFunction=function(a){return"function"==goog.typeOf(a)};
goog.isObject=function(a){var b=typeof a;return"object"==b&&null!=a||"function"==b};goog.getUid=function(a){return a[goog.UID_PROPERTY_]||(a[goog.UID_PROPERTY_]=++goog.uidCounter_)};goog.hasUid=function(a){return!!a[goog.UID_PROPERTY_]};goog.removeUid=function(a){null!==a&&"removeAttribute"in a&&a.removeAttribute(goog.UID_PROPERTY_);try{delete a[goog.UID_PROPERTY_]}catch(b){}};goog.UID_PROPERTY_="closure_uid_"+(1E9*Math.random()>>>0);goog.uidCounter_=0;goog.getHashCode=goog.getUid;
goog.removeHashCode=goog.removeUid;goog.cloneObject=function(a){var b=goog.typeOf(a);if("object"==b||"array"==b){if("function"===typeof a.clone)return a.clone();b="array"==b?[]:{};for(var c in a)b[c]=goog.cloneObject(a[c]);return b}return a};goog.bindNative_=function(a,b,c){return a.call.apply(a.bind,arguments)};
goog.bindJs_=function(a,b,c){if(!a)throw Error();if(2<arguments.length){var d=Array.prototype.slice.call(arguments,2);return function(){var c=Array.prototype.slice.call(arguments);Array.prototype.unshift.apply(c,d);return a.apply(b,c)}}return function(){return a.apply(b,arguments)}};goog.bind=function(a,b,c){Function.prototype.bind&&-1!=Function.prototype.bind.toString().indexOf("native code")?goog.bind=goog.bindNative_:goog.bind=goog.bindJs_;return goog.bind.apply(null,arguments)};
goog.partial=function(a,b){var c=Array.prototype.slice.call(arguments,1);return function(){var b=c.slice();b.push.apply(b,arguments);return a.apply(this,b)}};goog.mixin=function(a,b){for(var c in b)a[c]=b[c]};goog.now=goog.TRUSTED_SITE&&Date.now||function(){return+new Date};
goog.globalEval=function(a){if(goog.global.execScript)goog.global.execScript(a,"JavaScript");else if(goog.global.eval){if(null==goog.evalWorksForGlobals_){try{goog.global.eval("var _evalTest_ = 1;")}catch(d){}if("undefined"!=typeof goog.global._evalTest_){try{delete goog.global._evalTest_}catch(d){}goog.evalWorksForGlobals_=!0}else goog.evalWorksForGlobals_=!1}if(goog.evalWorksForGlobals_)goog.global.eval(a);else{var b=goog.global.document,c=b.createElement("SCRIPT");c.type="text/javascript";c.defer=
!1;c.appendChild(b.createTextNode(a));b.head.appendChild(c);b.head.removeChild(c)}}else throw Error("goog.globalEval not available");};goog.evalWorksForGlobals_=null;
goog.getCssName=function(a,b){if("."==String(a).charAt(0))throw Error('className passed in goog.getCssName must not start with ".". You passed: '+a);var c=function(a){return goog.cssNameMapping_[a]||a},d=function(a){a=a.split("-");for(var b=[],d=0;d<a.length;d++)b.push(c(a[d]));return b.join("-")};d=goog.cssNameMapping_?"BY_WHOLE"==goog.cssNameMappingStyle_?c:d:function(a){return a};a=b?a+"-"+d(b):d(a);return goog.global.CLOSURE_CSS_NAME_MAP_FN?goog.global.CLOSURE_CSS_NAME_MAP_FN(a):a};
goog.setCssNameMapping=function(a,b){goog.cssNameMapping_=a;goog.cssNameMappingStyle_=b};!COMPILED&&goog.global.CLOSURE_CSS_NAME_MAPPING&&(goog.cssNameMapping_=goog.global.CLOSURE_CSS_NAME_MAPPING);goog.getMsg=function(a,b){b&&(a=a.replace(/\{\$([^}]+)}/g,function(a,d){return null!=b&&d in b?b[d]:a}));return a};goog.getMsgWithFallback=function(a,b){return a};goog.exportSymbol=function(a,b,c){goog.exportPath_(a,b,c)};goog.exportProperty=function(a,b,c){a[b]=c};
goog.inherits=function(a,b){function c(){}c.prototype=b.prototype;a.superClass_=b.prototype;a.prototype=new c;a.prototype.constructor=a;a.base=function(a,c,f){for(var d=Array(arguments.length-2),e=2;e<arguments.length;e++)d[e-2]=arguments[e];return b.prototype[c].apply(a,d)}};
goog.base=function(a,b,c){var d=arguments.callee.caller;if(goog.STRICT_MODE_COMPATIBLE||goog.DEBUG&&!d)throw Error("arguments.caller not defined.  goog.base() cannot be used with strict mode code. See http://www.ecma-international.org/ecma-262/5.1/#sec-C");if("undefined"!==typeof d.superClass_){for(var e=Array(arguments.length-1),f=1;f<arguments.length;f++)e[f-1]=arguments[f];return d.superClass_.constructor.apply(a,e)}if("string"!=typeof b&&"symbol"!=typeof b)throw Error("method names provided to goog.base must be a string or a symbol");
e=Array(arguments.length-2);for(f=2;f<arguments.length;f++)e[f-2]=arguments[f];f=!1;for(var g=a.constructor;g;g=g.superClass_&&g.superClass_.constructor)if(g.prototype[b]===d)f=!0;else if(f)return g.prototype[b].apply(a,e);if(a[b]===d)return a.constructor.prototype[b].apply(a,e);throw Error("goog.base called from a method of one name to a method of a different name");};goog.scope=function(a){if(goog.isInModuleLoader_())throw Error("goog.scope is not supported within a module.");a.call(goog.global)};
COMPILED||(goog.global.COMPILED=COMPILED);goog.defineClass=function(a,b){var c=b.constructor,d=b.statics;c&&c!=Object.prototype.constructor||(c=function(){throw Error("cannot instantiate an interface (no constructor defined).");});c=goog.defineClass.createSealingConstructor_(c,a);a&&goog.inherits(c,a);delete b.constructor;delete b.statics;goog.defineClass.applyProperties_(c.prototype,b);null!=d&&(d instanceof Function?d(c):goog.defineClass.applyProperties_(c,d));return c};
goog.defineClass.SEAL_CLASS_INSTANCES=goog.DEBUG;goog.defineClass.createSealingConstructor_=function(a,b){if(!goog.defineClass.SEAL_CLASS_INSTANCES)return a;var c=!goog.defineClass.isUnsealable_(b),d=function(){var b=a.apply(this,arguments)||this;b[goog.UID_PROPERTY_]=b[goog.UID_PROPERTY_];this.constructor===d&&c&&Object.seal instanceof Function&&Object.seal(b);return b};return d};goog.defineClass.isUnsealable_=function(a){return a&&a.prototype&&a.prototype[goog.UNSEALABLE_CONSTRUCTOR_PROPERTY_]};
goog.defineClass.OBJECT_PROTOTYPE_FIELDS_="constructor hasOwnProperty isPrototypeOf propertyIsEnumerable toLocaleString toString valueOf".split(" ");goog.defineClass.applyProperties_=function(a,b){for(var c in b)Object.prototype.hasOwnProperty.call(b,c)&&(a[c]=b[c]);for(var d=0;d<goog.defineClass.OBJECT_PROTOTYPE_FIELDS_.length;d++)c=goog.defineClass.OBJECT_PROTOTYPE_FIELDS_[d],Object.prototype.hasOwnProperty.call(b,c)&&(a[c]=b[c])};
goog.tagUnsealableClass=function(a){!COMPILED&&goog.defineClass.SEAL_CLASS_INSTANCES&&(a.prototype[goog.UNSEALABLE_CONSTRUCTOR_PROPERTY_]=!0)};goog.UNSEALABLE_CONSTRUCTOR_PROPERTY_="goog_defineClass_legacy_unsealable";
!COMPILED&&goog.DEPENDENCIES_ENABLED&&(goog.inHtmlDocument_=function(){var a=goog.global.document;return null!=a&&"write"in a},goog.isDocumentLoading_=function(){var a=goog.global.document;return a.attachEvent?"complete"!=a.readyState:"loading"==a.readyState},goog.findBasePath_=function(){if(goog.isDef(goog.global.CLOSURE_BASE_PATH)&&goog.isString(goog.global.CLOSURE_BASE_PATH))goog.basePath=goog.global.CLOSURE_BASE_PATH;else if(goog.inHtmlDocument_()){var a=goog.global.document,b=a.currentScript;
a=b?[b]:a.getElementsByTagName("SCRIPT");for(b=a.length-1;0<=b;--b){var c=a[b].src,d=c.lastIndexOf("?");d=-1==d?c.length:d;if("base.js"==c.substr(d-7,7)){goog.basePath=c.substr(0,d-7);break}}}},goog.findBasePath_(),goog.Transpiler=function(){this.requiresTranspilation_=null;this.transpilationTarget_=goog.TRANSPILE_TO_LANGUAGE},goog.Transpiler.prototype.createRequiresTranspilation_=function(){function a(a,b){e?d[a]=!0:b()?(c=a,d[a]=!1):e=d[a]=!0}function b(a){try{return!!eval(a)}catch(h){return!1}}
var c="es3",d={es3:!1},e=!1,f=goog.global.navigator&&goog.global.navigator.userAgent?goog.global.navigator.userAgent:"";a("es5",function(){return b("[1,].length==1")});a("es6",function(){return f.match(/Edge\/(\d+)(\.\d)*/i)?!1:b('(()=>{"use strict";class X{constructor(){if(new.target!=String)throw 1;this.x=42}}let q=Reflect.construct(X,[],String);if(q.x!=42||!(q instanceof String))throw 1;for(const a of[2,3]){if(a==2)continue;function f(z={a}){let a=0;return z.a}{function f(){return 0;}}return f()==3}})()')});
a("es6-impl",function(){return!0});a("es7",function(){return b("2 ** 2 == 4")});a("es8",function(){return b("async () => 1, true")});a("es9",function(){return b("({...rest} = {}), true")});a("es_next",function(){return!1});return{target:c,map:d}},goog.Transpiler.prototype.needsTranspile=function(a,b){if("always"==goog.TRANSPILE)return!0;if("never"==goog.TRANSPILE)return!1;if(!this.requiresTranspilation_){var c=this.createRequiresTranspilation_();this.requiresTranspilation_=c.map;this.transpilationTarget_=
this.transpilationTarget_||c.target}if(a in this.requiresTranspilation_)return this.requiresTranspilation_[a]?!0:!goog.inHtmlDocument_()||"es6"!=b||"noModule"in goog.global.document.createElement("script")?!1:!0;throw Error("Unknown language mode: "+a);},goog.Transpiler.prototype.transpile=function(a,b){return goog.transpile_(a,b,this.transpilationTarget_)},goog.transpiler_=new goog.Transpiler,goog.protectScriptTag_=function(a){return a.replace(/<\/(SCRIPT)/ig,"\\x3c/$1")},goog.DebugLoader_=function(){this.dependencies_=
{};this.idToPath_={};this.written_={};this.loadingDeps_=[];this.depsToLoad_=[];this.paused_=!1;this.factory_=new goog.DependencyFactory(goog.transpiler_);this.deferredCallbacks_={};this.deferredQueue_=[]},goog.DebugLoader_.prototype.bootstrap=function(a,b){function c(){d&&(goog.global.setTimeout(d,0),d=null)}var d=b;if(a.length){b=[];for(var e=0;e<a.length;e++){var f=this.getPathFromDeps_(a[e]);if(!f)throw Error("Unregonized namespace: "+a[e]);b.push(this.dependencies_[f])}f=goog.require;var g=0;
for(e=0;e<a.length;e++)f(a[e]),b[e].onLoad(function(){++g==a.length&&c()})}else c()},goog.DebugLoader_.prototype.loadClosureDeps=function(){this.depsToLoad_.push(this.factory_.createDependency(goog.normalizePath_(goog.basePath+"deps.js"),"deps.js",[],[],{},!1));this.loadDeps_()},goog.DebugLoader_.prototype.requested=function(a,b){(a=this.getPathFromDeps_(a))&&(b||this.areDepsLoaded_(this.dependencies_[a].requires))&&(b=this.deferredCallbacks_[a])&&(delete this.deferredCallbacks_[a],b())},goog.DebugLoader_.prototype.setDependencyFactory=
function(a){this.factory_=a},goog.DebugLoader_.prototype.load_=function(a){if(this.getPathFromDeps_(a)){var b=this,c=[],d=function(a){var e=b.getPathFromDeps_(a);if(!e)throw Error("Bad dependency path or symbol: "+a);if(!b.written_[e]){b.written_[e]=!0;a=b.dependencies_[e];for(e=0;e<a.requires.length;e++)goog.isProvided_(a.requires[e])||d(a.requires[e]);c.push(a)}};d(a);a=!!this.depsToLoad_.length;this.depsToLoad_=this.depsToLoad_.concat(c);this.paused_||a||this.loadDeps_()}else throw a="goog.require could not find: "+
a,goog.logToConsole_(a),Error(a);},goog.DebugLoader_.prototype.loadDeps_=function(){for(var a=this,b=this.paused_;this.depsToLoad_.length&&!b;)(function(){var c=!1,d=a.depsToLoad_.shift(),e=!1;a.loading_(d);var f={pause:function(){if(c)throw Error("Cannot call pause after the call to load.");b=!0},resume:function(){c?a.resume_():b=!1},loaded:function(){if(e)throw Error("Double call to loaded.");e=!0;a.loaded_(d)},pending:function(){for(var b=[],c=0;c<a.loadingDeps_.length;c++)b.push(a.loadingDeps_[c]);
return b},setModuleState:function(a){goog.moduleLoaderState_={type:a,moduleName:"",declareLegacyNamespace:!1}},registerEs6ModuleExports:function(a,b,c){c&&(goog.loadedModules_[c]={exports:b,type:goog.ModuleType.ES6,moduleId:c||""})},registerGoogModuleExports:function(a,b){goog.loadedModules_[a]={exports:b,type:goog.ModuleType.GOOG,moduleId:a}},clearModuleState:function(){goog.moduleLoaderState_=null},defer:function(b){if(c)throw Error("Cannot register with defer after the call to load.");a.defer_(d,
b)},areDepsLoaded:function(){return a.areDepsLoaded_(d.requires)}};try{d.load(f)}finally{c=!0}})();b&&this.pause_()},goog.DebugLoader_.prototype.pause_=function(){this.paused_=!0},goog.DebugLoader_.prototype.resume_=function(){this.paused_&&(this.paused_=!1,this.loadDeps_())},goog.DebugLoader_.prototype.loading_=function(a){this.loadingDeps_.push(a)},goog.DebugLoader_.prototype.loaded_=function(a){for(var b=0;b<this.loadingDeps_.length;b++)if(this.loadingDeps_[b]==a){this.loadingDeps_.splice(b,1);
break}for(b=0;b<this.deferredQueue_.length;b++)if(this.deferredQueue_[b]==a.path){this.deferredQueue_.splice(b,1);break}if(this.loadingDeps_.length==this.deferredQueue_.length&&!this.depsToLoad_.length)for(;this.deferredQueue_.length;)this.requested(this.deferredQueue_.shift(),!0);a.loaded()},goog.DebugLoader_.prototype.areDepsLoaded_=function(a){for(var b=0;b<a.length;b++){var c=this.getPathFromDeps_(a[b]);if(!c||!(c in this.deferredCallbacks_||goog.isProvided_(a[b])))return!1}return!0},goog.DebugLoader_.prototype.getPathFromDeps_=
function(a){return a in this.idToPath_?this.idToPath_[a]:a in this.dependencies_?a:null},goog.DebugLoader_.prototype.defer_=function(a,b){this.deferredCallbacks_[a.path]=b;this.deferredQueue_.push(a.path)},goog.LoadController=function(){},goog.LoadController.prototype.pause=function(){},goog.LoadController.prototype.resume=function(){},goog.LoadController.prototype.loaded=function(){},goog.LoadController.prototype.pending=function(){},goog.LoadController.prototype.registerEs6ModuleExports=function(a,
b,c){},goog.LoadController.prototype.setModuleState=function(a){},goog.LoadController.prototype.clearModuleState=function(){},goog.LoadController.prototype.defer=function(a){},goog.LoadController.prototype.areDepsLoaded=function(){},goog.Dependency=function(a,b,c,d,e){this.path=a;this.relativePath=b;this.provides=c;this.requires=d;this.loadFlags=e;this.loaded_=!1;this.loadCallbacks_=[]},goog.Dependency.prototype.getPathName=function(){var a=this.path,b=a.indexOf("://");0<=b&&(a=a.substring(b+3),b=
a.indexOf("/"),0<=b&&(a=a.substring(b+1)));return a},goog.Dependency.prototype.onLoad=function(a){this.loaded_?a():this.loadCallbacks_.push(a)},goog.Dependency.prototype.loaded=function(){this.loaded_=!0;var a=this.loadCallbacks_;this.loadCallbacks_=[];for(var b=0;b<a.length;b++)a[b]()},goog.Dependency.defer_=!1,goog.Dependency.callbackMap_={},goog.Dependency.registerCallback_=function(a){var b=Math.random().toString(32);goog.Dependency.callbackMap_[b]=a;return b},goog.Dependency.unregisterCallback_=
function(a){delete goog.Dependency.callbackMap_[a]},goog.Dependency.callback_=function(a,b){if(a in goog.Dependency.callbackMap_){for(var c=goog.Dependency.callbackMap_[a],d=[],e=1;e<arguments.length;e++)d.push(arguments[e]);c.apply(void 0,d)}else throw Error("Callback key "+a+" does not exist (was base.js loaded more than once?).");},goog.Dependency.prototype.load=function(a){if(goog.global.CLOSURE_IMPORT_SCRIPT)goog.global.CLOSURE_IMPORT_SCRIPT(this.path)?a.loaded():a.pause();else if(goog.inHtmlDocument_()){var b=
goog.global.document;if("complete"==b.readyState&&!goog.ENABLE_CHROME_APP_SAFE_SCRIPT_LOADING){if(/\bdeps.js$/.test(this.path)){a.loaded();return}throw Error('Cannot write "'+this.path+'" after document load');}if(!goog.ENABLE_CHROME_APP_SAFE_SCRIPT_LOADING&&goog.isDocumentLoading_()){var c=goog.Dependency.registerCallback_(function(b){goog.DebugLoader_.IS_OLD_IE_&&"complete"!=b.readyState||(goog.Dependency.unregisterCallback_(c),a.loaded())}),d=!goog.DebugLoader_.IS_OLD_IE_&&goog.getScriptNonce()?
' nonce="'+goog.getScriptNonce()+'"':"";b.write('<script src="'+this.path+'" '+(goog.DebugLoader_.IS_OLD_IE_?"onreadystatechange":"onload")+"=\"goog.Dependency.callback_('"+c+'\', this)" type="text/javascript" '+(goog.Dependency.defer_?"defer":"")+d+">\x3c/script>")}else{var e=b.createElement("script");e.defer=goog.Dependency.defer_;e.async=!1;e.type="text/javascript";(d=goog.getScriptNonce())&&e.setAttribute("nonce",d);goog.DebugLoader_.IS_OLD_IE_?(a.pause(),e.onreadystatechange=function(){if("loaded"==
e.readyState||"complete"==e.readyState)a.loaded(),a.resume()}):e.onload=function(){e.onload=null;a.loaded()};e.src=this.path;b.head.appendChild(e)}}else goog.logToConsole_("Cannot use default debug loader outside of HTML documents."),"deps.js"==this.relativePath?(goog.logToConsole_("Consider setting CLOSURE_IMPORT_SCRIPT before loading base.js, or setting CLOSURE_NO_DEPS to true."),a.loaded()):a.pause()},goog.Es6ModuleDependency=function(a,b,c,d,e){goog.Dependency.call(this,a,b,c,d,e)},goog.inherits(goog.Es6ModuleDependency,
goog.Dependency),goog.Es6ModuleDependency.prototype.load=function(a){function b(a,b){b?d.write('<script type="module" crossorigin>'+b+"\x3c/script>"):d.write('<script type="module" crossorigin src="'+a+'">\x3c/script>')}function c(a,b){var c=d.createElement("script");c.defer=!0;c.async=!1;c.type="module";c.setAttribute("crossorigin",!0);var e=goog.getScriptNonce();e&&c.setAttribute("nonce",e);b?c.textContent=b:c.src=a;d.head.appendChild(c)}if(goog.global.CLOSURE_IMPORT_SCRIPT)goog.global.CLOSURE_IMPORT_SCRIPT(this.path)?
a.loaded():a.pause();else if(goog.inHtmlDocument_()){var d=goog.global.document,e=this;if(goog.isDocumentLoading_()){var f=b;goog.Dependency.defer_=!0}else f=c;var g=goog.Dependency.registerCallback_(function(){goog.Dependency.unregisterCallback_(g);a.setModuleState(goog.ModuleType.ES6)});f(void 0,'goog.Dependency.callback_("'+g+'")');f(this.path,void 0);var h=goog.Dependency.registerCallback_(function(b){goog.Dependency.unregisterCallback_(h);a.registerEs6ModuleExports(e.path,b,goog.moduleLoaderState_.moduleName)});
f(void 0,'import * as m from "'+this.path+'"; goog.Dependency.callback_("'+h+'", m)');var k=goog.Dependency.registerCallback_(function(){goog.Dependency.unregisterCallback_(k);a.clearModuleState();a.loaded()});f(void 0,'goog.Dependency.callback_("'+k+'")')}else goog.logToConsole_("Cannot use default debug loader outside of HTML documents."),a.pause()},goog.TransformedDependency=function(a,b,c,d,e){goog.Dependency.call(this,a,b,c,d,e);this.contents_=null;this.lazyFetch_=!goog.inHtmlDocument_()||!("noModule"in
goog.global.document.createElement("script"))},goog.inherits(goog.TransformedDependency,goog.Dependency),goog.TransformedDependency.prototype.load=function(a){function b(){e.contents_=goog.loadFileSync_(e.path);e.contents_&&(e.contents_=e.transform(e.contents_),e.contents_&&(e.contents_+="\n//# sourceURL="+e.path))}function c(){e.lazyFetch_&&b();if(e.contents_){f&&a.setModuleState(goog.ModuleType.ES6);try{var c=e.contents_;e.contents_=null;goog.globalEval(c);if(f)var d=goog.moduleLoaderState_.moduleName}finally{f&&
a.clearModuleState()}f&&goog.global.$jscomp.require.ensure([e.getPathName()],function(){a.registerEs6ModuleExports(e.path,goog.global.$jscomp.require(e.getPathName()),d)});a.loaded()}}function d(){var a=goog.global.document,b=goog.Dependency.registerCallback_(function(){goog.Dependency.unregisterCallback_(b);c()});a.write('<script type="text/javascript">'+goog.protectScriptTag_('goog.Dependency.callback_("'+b+'");')+"\x3c/script>")}var e=this;if(goog.global.CLOSURE_IMPORT_SCRIPT)b(),this.contents_&&
goog.global.CLOSURE_IMPORT_SCRIPT("",this.contents_)?(this.contents_=null,a.loaded()):a.pause();else{var f=this.loadFlags.module==goog.ModuleType.ES6;this.lazyFetch_||b();var g=1<a.pending().length,h=g&&goog.DebugLoader_.IS_OLD_IE_;g=goog.Dependency.defer_&&(g||goog.isDocumentLoading_());if(h||g)a.defer(function(){c()});else{var k=goog.global.document;h=goog.inHtmlDocument_()&&"ActiveXObject"in goog.global;if(f&&goog.inHtmlDocument_()&&goog.isDocumentLoading_()&&!h){goog.Dependency.defer_=!0;a.pause();
var l=k.onreadystatechange;k.onreadystatechange=function(){"interactive"==k.readyState&&(k.onreadystatechange=l,c(),a.resume());goog.isFunction(l)&&l.apply(void 0,arguments)}}else!goog.DebugLoader_.IS_OLD_IE_&&goog.inHtmlDocument_()&&goog.isDocumentLoading_()?d():c()}}},goog.TransformedDependency.prototype.transform=function(a){},goog.TranspiledDependency=function(a,b,c,d,e,f){goog.TransformedDependency.call(this,a,b,c,d,e);this.transpiler=f},goog.inherits(goog.TranspiledDependency,goog.TransformedDependency),
goog.TranspiledDependency.prototype.transform=function(a){return this.transpiler.transpile(a,this.getPathName())},goog.PreTranspiledEs6ModuleDependency=function(a,b,c,d,e){goog.TransformedDependency.call(this,a,b,c,d,e)},goog.inherits(goog.PreTranspiledEs6ModuleDependency,goog.TransformedDependency),goog.PreTranspiledEs6ModuleDependency.prototype.transform=function(a){return a},goog.GoogModuleDependency=function(a,b,c,d,e,f,g){goog.TransformedDependency.call(this,a,b,c,d,e);this.needsTranspile_=f;
this.transpiler_=g},goog.inherits(goog.GoogModuleDependency,goog.TransformedDependency),goog.GoogModuleDependency.prototype.transform=function(a){this.needsTranspile_&&(a=this.transpiler_.transpile(a,this.getPathName()));return goog.LOAD_MODULE_USING_EVAL&&goog.isDef(goog.global.JSON)?"goog.loadModule("+goog.global.JSON.stringify(a+"\n//# sourceURL="+this.path+"\n")+");":'goog.loadModule(function(exports) {"use strict";'+a+"\n;return exports});\n//# sourceURL="+this.path+"\n"},goog.DebugLoader_.IS_OLD_IE_=
!(goog.global.atob||!goog.global.document||!goog.global.document.all),goog.DebugLoader_.prototype.addDependency=function(a,b,c,d){b=b||[];a=a.replace(/\\/g,"/");var e=goog.normalizePath_(goog.basePath+a);d&&"boolean"!==typeof d||(d=d?{module:goog.ModuleType.GOOG}:{});c=this.factory_.createDependency(e,a,b,c,d,goog.transpiler_.needsTranspile(d.lang||"es3",d.module));this.dependencies_[e]=c;for(c=0;c<b.length;c++)this.idToPath_[b[c]]=e;this.idToPath_[a]=e},goog.DependencyFactory=function(a){this.transpiler=
a},goog.DependencyFactory.prototype.createDependency=function(a,b,c,d,e,f){return e.module==goog.ModuleType.GOOG?new goog.GoogModuleDependency(a,b,c,d,e,f,this.transpiler):f?new goog.TranspiledDependency(a,b,c,d,e,this.transpiler):e.module==goog.ModuleType.ES6?"never"==goog.TRANSPILE&&goog.ASSUME_ES_MODULES_TRANSPILED?new goog.PreTranspiledEs6ModuleDependency(a,b,c,d,e):new goog.Es6ModuleDependency(a,b,c,d,e):new goog.Dependency(a,b,c,d,e)},goog.debugLoader_=new goog.DebugLoader_,goog.loadClosureDeps=
function(){goog.debugLoader_.loadClosureDeps()},goog.setDependencyFactory=function(a){goog.debugLoader_.setDependencyFactory(a)},goog.global.CLOSURE_NO_DEPS||goog.debugLoader_.loadClosureDeps(),goog.bootstrap=function(a,b){goog.debugLoader_.bootstrap(a,b)});
var Blockly={};Blockly.Blocks=Object(null);/*

 Visual Blocks Editor

 Copyright 2016 Massachusetts Institute of Technology
 All rights reserved.

 Licensed under the Apache License, Version 2.0 (the "License");
 you may not use this file except in compliance with the License.
 You may obtain a copy of the License at

   http://www.apache.org/licenses/LICENSE-2.0

 Unless required by applicable law or agreed to in writing, software
 distributed under the License is distributed on an "AS IS" BASIS,
 WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 See the License for the specific language governing permissions and
 limitations under the License.
*/
Blockly.Colours={motion:{primary:"#4C97FF",secondary:"#4280D7",tertiary:"#3373CC"},looks:{primary:"#9966FF",secondary:"#855CD6",tertiary:"#774DCB"},lights:{primary:"#04dbd0",secondary:"#b7bbff",tertiary:"#adffbf"},sounds:{primary:"#CF63CF",secondary:"#C94FC9",tertiary:"#BD42BD"},control:{primary:"#FFAB19",secondary:"#EC9C13",tertiary:"#CF8B17"},event:{primary:"#FFBF00",secondary:"#E6AC00",tertiary:"#CC9900"},sensing:{primary:"#5CB1D6",secondary:"#47A8D1",tertiary:"#2E8EB8"},pen:{primary:"#0fBD8C",
secondary:"#0DA57A",tertiary:"#0B8E69"},operators:{primary:"#59C059",secondary:"#46B946",tertiary:"#389438"},data:{primary:"#FF8C1A",secondary:"#FF8000",tertiary:"#DB6E00"},data_lists:{primary:"#FF661A",secondary:"#FF5500",tertiary:"#E64D00"},more:{primary:"#FF6680",secondary:"#FF4D6A",tertiary:"#FF3355"},text:"#575E75",workspace:"#F9F9F9",toolboxHover:"#4C97FF",toolboxSelected:"#e9eef2",toolboxText:"#575E75",toolbox:"#FFFFFF",flyout:"#F9F9F9",scrollbar:"#CECDCE",scrollbarHover:"#CECDCE",textField:"#FFFFFF",
insertionMarker:"#000000",insertionMarkerOpacity:.2,dragShadowOpacity:.3,stackGlow:"#FFF200",stackGlowSize:4,stackGlowOpacity:1,replacementGlow:"#FFFFFF",replacementGlowSize:2,replacementGlowOpacity:1,colourPickerStroke:"#FFFFFF",fieldShadow:"rgba(0,0,0,0.1)",dropDownShadow:"rgba(0, 0, 0, .3)",numPadBackground:"#547AB2",numPadBorder:"#435F91",numPadActiveBackground:"#435F91",numPadText:"white",valueReportBackground:"#FFFFFF",valueReportBorder:"#AAAAAA"};
Blockly.Colours.overrideColours=function(a){if(a)for(var b in a)if(a.hasOwnProperty(b)&&Blockly.Colours.hasOwnProperty(b)){var c=a[b];if(goog.isObject(c))for(var d in c)c.hasOwnProperty(d)&&Blockly.Colours[b].hasOwnProperty(d)&&(Blockly.Colours[b][d]=c[d]);else Blockly.Colours[b]=c}};goog.debug={};goog.debug.Error=function(a){if(Error.captureStackTrace)Error.captureStackTrace(this,goog.debug.Error);else{var b=Error().stack;b&&(this.stack=b)}a&&(this.message=String(a));this.reportErrorToServer=!0};goog.inherits(goog.debug.Error,Error);goog.debug.Error.prototype.name="CustomError";goog.dom={};goog.dom.NodeType={ELEMENT:1,ATTRIBUTE:2,TEXT:3,CDATA_SECTION:4,ENTITY_REFERENCE:5,ENTITY:6,PROCESSING_INSTRUCTION:7,COMMENT:8,DOCUMENT:9,DOCUMENT_TYPE:10,DOCUMENT_FRAGMENT:11,NOTATION:12};goog.asserts={};goog.asserts.ENABLE_ASSERTS=goog.DEBUG;goog.asserts.AssertionError=function(a,b){goog.debug.Error.call(this,goog.asserts.subs_(a,b));this.messagePattern=a};goog.inherits(goog.asserts.AssertionError,goog.debug.Error);goog.asserts.AssertionError.prototype.name="AssertionError";goog.asserts.DEFAULT_ERROR_HANDLER=function(a){throw a;};goog.asserts.errorHandler_=goog.asserts.DEFAULT_ERROR_HANDLER;
goog.asserts.subs_=function(a,b){a=a.split("%s");for(var c="",d=a.length-1,e=0;e<d;e++)c+=a[e]+(e<b.length?b[e]:"%s");return c+a[d]};goog.asserts.doAssertFailure_=function(a,b,c,d){var e="Assertion failed";if(c){e+=": "+c;var f=d}else a&&(e+=": "+a,f=b);a=new goog.asserts.AssertionError(""+e,f||[]);goog.asserts.errorHandler_(a)};goog.asserts.setErrorHandler=function(a){goog.asserts.ENABLE_ASSERTS&&(goog.asserts.errorHandler_=a)};
goog.asserts.assert=function(a,b,c){goog.asserts.ENABLE_ASSERTS&&!a&&goog.asserts.doAssertFailure_("",null,b,Array.prototype.slice.call(arguments,2));return a};goog.asserts.fail=function(a,b){goog.asserts.ENABLE_ASSERTS&&goog.asserts.errorHandler_(new goog.asserts.AssertionError("Failure"+(a?": "+a:""),Array.prototype.slice.call(arguments,1)))};
goog.asserts.assertNumber=function(a,b,c){goog.asserts.ENABLE_ASSERTS&&!goog.isNumber(a)&&goog.asserts.doAssertFailure_("Expected number but got %s: %s.",[goog.typeOf(a),a],b,Array.prototype.slice.call(arguments,2));return a};goog.asserts.assertString=function(a,b,c){goog.asserts.ENABLE_ASSERTS&&!goog.isString(a)&&goog.asserts.doAssertFailure_("Expected string but got %s: %s.",[goog.typeOf(a),a],b,Array.prototype.slice.call(arguments,2));return a};
goog.asserts.assertFunction=function(a,b,c){goog.asserts.ENABLE_ASSERTS&&!goog.isFunction(a)&&goog.asserts.doAssertFailure_("Expected function but got %s: %s.",[goog.typeOf(a),a],b,Array.prototype.slice.call(arguments,2));return a};goog.asserts.assertObject=function(a,b,c){goog.asserts.ENABLE_ASSERTS&&!goog.isObject(a)&&goog.asserts.doAssertFailure_("Expected object but got %s: %s.",[goog.typeOf(a),a],b,Array.prototype.slice.call(arguments,2));return a};
goog.asserts.assertArray=function(a,b,c){goog.asserts.ENABLE_ASSERTS&&!goog.isArray(a)&&goog.asserts.doAssertFailure_("Expected array but got %s: %s.",[goog.typeOf(a),a],b,Array.prototype.slice.call(arguments,2));return a};goog.asserts.assertBoolean=function(a,b,c){goog.asserts.ENABLE_ASSERTS&&!goog.isBoolean(a)&&goog.asserts.doAssertFailure_("Expected boolean but got %s: %s.",[goog.typeOf(a),a],b,Array.prototype.slice.call(arguments,2));return a};
goog.asserts.assertElement=function(a,b,c){!goog.asserts.ENABLE_ASSERTS||goog.isObject(a)&&a.nodeType==goog.dom.NodeType.ELEMENT||goog.asserts.doAssertFailure_("Expected Element but got %s: %s.",[goog.typeOf(a),a],b,Array.prototype.slice.call(arguments,2));return a};
goog.asserts.assertInstanceof=function(a,b,c,d){!goog.asserts.ENABLE_ASSERTS||a instanceof b||goog.asserts.doAssertFailure_("Expected instanceof %s but got %s.",[goog.asserts.getType_(b),goog.asserts.getType_(a)],c,Array.prototype.slice.call(arguments,3));return a};goog.asserts.assertFinite=function(a,b,c){!goog.asserts.ENABLE_ASSERTS||"number"==typeof a&&isFinite(a)||goog.asserts.doAssertFailure_("Expected %s to be a finite number but it is not.",[a],b,Array.prototype.slice.call(arguments,2));return a};
goog.asserts.assertObjectPrototypeIsIntact=function(){for(var a in Object.prototype)goog.asserts.fail(a+" should not be enumerable in Object.prototype.")};goog.asserts.getType_=function(a){return a instanceof Function?a.displayName||a.name||"unknown type name":a instanceof Object?a.constructor.displayName||a.constructor.name||Object.prototype.toString.call(a):null===a?"null":typeof a};goog.debug.entryPointRegistry={};goog.debug.EntryPointMonitor=function(){};goog.debug.entryPointRegistry.refList_=[];goog.debug.entryPointRegistry.monitors_=[];goog.debug.entryPointRegistry.monitorsMayExist_=!1;goog.debug.entryPointRegistry.register=function(a){goog.debug.entryPointRegistry.refList_[goog.debug.entryPointRegistry.refList_.length]=a;if(goog.debug.entryPointRegistry.monitorsMayExist_)for(var b=goog.debug.entryPointRegistry.monitors_,c=0;c<b.length;c++)a(goog.bind(b[c].wrap,b[c]))};
goog.debug.entryPointRegistry.monitorAll=function(a){goog.debug.entryPointRegistry.monitorsMayExist_=!0;for(var b=goog.bind(a.wrap,a),c=0;c<goog.debug.entryPointRegistry.refList_.length;c++)goog.debug.entryPointRegistry.refList_[c](b);goog.debug.entryPointRegistry.monitors_.push(a)};
goog.debug.entryPointRegistry.unmonitorAllIfPossible=function(a){var b=goog.debug.entryPointRegistry.monitors_;goog.asserts.assert(a==b[b.length-1],"Only the most recent monitor can be unwrapped.");a=goog.bind(a.unwrap,a);for(var c=0;c<goog.debug.entryPointRegistry.refList_.length;c++)goog.debug.entryPointRegistry.refList_[c](a);b.length--};goog.array={};goog.NATIVE_ARRAY_PROTOTYPES=goog.TRUSTED_SITE;goog.array.ASSUME_NATIVE_FUNCTIONS=!1;goog.array.peek=function(a){return a[a.length-1]};goog.array.last=goog.array.peek;
goog.array.indexOf=goog.NATIVE_ARRAY_PROTOTYPES&&(goog.array.ASSUME_NATIVE_FUNCTIONS||Array.prototype.indexOf)?function(a,b,c){goog.asserts.assert(null!=a.length);return Array.prototype.indexOf.call(a,b,c)}:function(a,b,c){c=null==c?0:0>c?Math.max(0,a.length+c):c;if(goog.isString(a))return goog.isString(b)&&1==b.length?a.indexOf(b,c):-1;for(;c<a.length;c++)if(c in a&&a[c]===b)return c;return-1};
goog.array.lastIndexOf=goog.NATIVE_ARRAY_PROTOTYPES&&(goog.array.ASSUME_NATIVE_FUNCTIONS||Array.prototype.lastIndexOf)?function(a,b,c){goog.asserts.assert(null!=a.length);return Array.prototype.lastIndexOf.call(a,b,null==c?a.length-1:c)}:function(a,b,c){c=null==c?a.length-1:c;0>c&&(c=Math.max(0,a.length+c));if(goog.isString(a))return goog.isString(b)&&1==b.length?a.lastIndexOf(b,c):-1;for(;0<=c;c--)if(c in a&&a[c]===b)return c;return-1};
goog.array.forEach=goog.NATIVE_ARRAY_PROTOTYPES&&(goog.array.ASSUME_NATIVE_FUNCTIONS||Array.prototype.forEach)?function(a,b,c){goog.asserts.assert(null!=a.length);Array.prototype.forEach.call(a,b,c)}:function(a,b,c){for(var d=a.length,e=goog.isString(a)?a.split(""):a,f=0;f<d;f++)f in e&&b.call(c,e[f],f,a)};goog.array.forEachRight=function(a,b,c){var d=a.length,e=goog.isString(a)?a.split(""):a;for(--d;0<=d;--d)d in e&&b.call(c,e[d],d,a)};
goog.array.filter=goog.NATIVE_ARRAY_PROTOTYPES&&(goog.array.ASSUME_NATIVE_FUNCTIONS||Array.prototype.filter)?function(a,b,c){goog.asserts.assert(null!=a.length);return Array.prototype.filter.call(a,b,c)}:function(a,b,c){for(var d=a.length,e=[],f=0,g=goog.isString(a)?a.split(""):a,h=0;h<d;h++)if(h in g){var k=g[h];b.call(c,k,h,a)&&(e[f++]=k)}return e};
goog.array.map=goog.NATIVE_ARRAY_PROTOTYPES&&(goog.array.ASSUME_NATIVE_FUNCTIONS||Array.prototype.map)?function(a,b,c){goog.asserts.assert(null!=a.length);return Array.prototype.map.call(a,b,c)}:function(a,b,c){for(var d=a.length,e=Array(d),f=goog.isString(a)?a.split(""):a,g=0;g<d;g++)g in f&&(e[g]=b.call(c,f[g],g,a));return e};
goog.array.reduce=goog.NATIVE_ARRAY_PROTOTYPES&&(goog.array.ASSUME_NATIVE_FUNCTIONS||Array.prototype.reduce)?function(a,b,c,d){goog.asserts.assert(null!=a.length);d&&(b=goog.bind(b,d));return Array.prototype.reduce.call(a,b,c)}:function(a,b,c,d){var e=c;goog.array.forEach(a,function(c,g){e=b.call(d,e,c,g,a)});return e};
goog.array.reduceRight=goog.NATIVE_ARRAY_PROTOTYPES&&(goog.array.ASSUME_NATIVE_FUNCTIONS||Array.prototype.reduceRight)?function(a,b,c,d){goog.asserts.assert(null!=a.length);goog.asserts.assert(null!=b);d&&(b=goog.bind(b,d));return Array.prototype.reduceRight.call(a,b,c)}:function(a,b,c,d){var e=c;goog.array.forEachRight(a,function(c,g){e=b.call(d,e,c,g,a)});return e};
goog.array.some=goog.NATIVE_ARRAY_PROTOTYPES&&(goog.array.ASSUME_NATIVE_FUNCTIONS||Array.prototype.some)?function(a,b,c){goog.asserts.assert(null!=a.length);return Array.prototype.some.call(a,b,c)}:function(a,b,c){for(var d=a.length,e=goog.isString(a)?a.split(""):a,f=0;f<d;f++)if(f in e&&b.call(c,e[f],f,a))return!0;return!1};
goog.array.every=goog.NATIVE_ARRAY_PROTOTYPES&&(goog.array.ASSUME_NATIVE_FUNCTIONS||Array.prototype.every)?function(a,b,c){goog.asserts.assert(null!=a.length);return Array.prototype.every.call(a,b,c)}:function(a,b,c){for(var d=a.length,e=goog.isString(a)?a.split(""):a,f=0;f<d;f++)if(f in e&&!b.call(c,e[f],f,a))return!1;return!0};goog.array.count=function(a,b,c){var d=0;goog.array.forEach(a,function(a,f,g){b.call(c,a,f,g)&&++d},c);return d};
goog.array.find=function(a,b,c){b=goog.array.findIndex(a,b,c);return 0>b?null:goog.isString(a)?a.charAt(b):a[b]};goog.array.findIndex=function(a,b,c){for(var d=a.length,e=goog.isString(a)?a.split(""):a,f=0;f<d;f++)if(f in e&&b.call(c,e[f],f,a))return f;return-1};goog.array.findRight=function(a,b,c){b=goog.array.findIndexRight(a,b,c);return 0>b?null:goog.isString(a)?a.charAt(b):a[b]};
goog.array.findIndexRight=function(a,b,c){var d=a.length,e=goog.isString(a)?a.split(""):a;for(--d;0<=d;d--)if(d in e&&b.call(c,e[d],d,a))return d;return-1};goog.array.contains=function(a,b){return 0<=goog.array.indexOf(a,b)};goog.array.isEmpty=function(a){return 0==a.length};goog.array.clear=function(a){if(!goog.isArray(a))for(var b=a.length-1;0<=b;b--)delete a[b];a.length=0};goog.array.insert=function(a,b){goog.array.contains(a,b)||a.push(b)};
goog.array.insertAt=function(a,b,c){goog.array.splice(a,c,0,b)};goog.array.insertArrayAt=function(a,b,c){goog.partial(goog.array.splice,a,c,0).apply(null,b)};goog.array.insertBefore=function(a,b,c){var d;2==arguments.length||0>(d=goog.array.indexOf(a,c))?a.push(b):goog.array.insertAt(a,b,d)};goog.array.remove=function(a,b){b=goog.array.indexOf(a,b);var c;(c=0<=b)&&goog.array.removeAt(a,b);return c};
goog.array.removeLast=function(a,b){b=goog.array.lastIndexOf(a,b);return 0<=b?(goog.array.removeAt(a,b),!0):!1};goog.array.removeAt=function(a,b){goog.asserts.assert(null!=a.length);return 1==Array.prototype.splice.call(a,b,1).length};goog.array.removeIf=function(a,b,c){b=goog.array.findIndex(a,b,c);return 0<=b?(goog.array.removeAt(a,b),!0):!1};goog.array.removeAllIf=function(a,b,c){var d=0;goog.array.forEachRight(a,function(e,f){b.call(c,e,f,a)&&goog.array.removeAt(a,f)&&d++});return d};
goog.array.concat=function(a){return Array.prototype.concat.apply([],arguments)};goog.array.join=function(a){return Array.prototype.concat.apply([],arguments)};goog.array.toArray=function(a){var b=a.length;if(0<b){for(var c=Array(b),d=0;d<b;d++)c[d]=a[d];return c}return[]};goog.array.clone=goog.array.toArray;goog.array.extend=function(a,b){for(var c=1;c<arguments.length;c++){var d=arguments[c];if(goog.isArrayLike(d)){var e=a.length||0,f=d.length||0;a.length=e+f;for(var g=0;g<f;g++)a[e+g]=d[g]}else a.push(d)}};
goog.array.splice=function(a,b,c,d){goog.asserts.assert(null!=a.length);return Array.prototype.splice.apply(a,goog.array.slice(arguments,1))};goog.array.slice=function(a,b,c){goog.asserts.assert(null!=a.length);return 2>=arguments.length?Array.prototype.slice.call(a,b):Array.prototype.slice.call(a,b,c)};
goog.array.removeDuplicates=function(a,b,c){b=b||a;var d=function(a){return goog.isObject(a)?"o"+goog.getUid(a):(typeof a).charAt(0)+a};c=c||d;d={};for(var e=0,f=0;f<a.length;){var g=a[f++],h=c(g);Object.prototype.hasOwnProperty.call(d,h)||(d[h]=!0,b[e++]=g)}b.length=e};goog.array.binarySearch=function(a,b,c){return goog.array.binarySearch_(a,c||goog.array.defaultCompare,!1,b)};goog.array.binarySelect=function(a,b,c){return goog.array.binarySearch_(a,b,!0,void 0,c)};
goog.array.binarySearch_=function(a,b,c,d,e){for(var f=0,g=a.length,h;f<g;){var k=f+g>>1;var l=c?b.call(e,a[k],k,a):b(d,a[k]);0<l?f=k+1:(g=k,h=!l)}return h?f:~f};goog.array.sort=function(a,b){a.sort(b||goog.array.defaultCompare)};goog.array.stableSort=function(a,b){for(var c=Array(a.length),d=0;d<a.length;d++)c[d]={index:d,value:a[d]};var e=b||goog.array.defaultCompare;goog.array.sort(c,function(a,b){return e(a.value,b.value)||a.index-b.index});for(d=0;d<a.length;d++)a[d]=c[d].value};
goog.array.sortByKey=function(a,b,c){var d=c||goog.array.defaultCompare;goog.array.sort(a,function(a,c){return d(b(a),b(c))})};goog.array.sortObjectsByKey=function(a,b,c){goog.array.sortByKey(a,function(a){return a[b]},c)};goog.array.isSorted=function(a,b,c){b=b||goog.array.defaultCompare;for(var d=1;d<a.length;d++){var e=b(a[d-1],a[d]);if(0<e||0==e&&c)return!1}return!0};
goog.array.equals=function(a,b,c){if(!goog.isArrayLike(a)||!goog.isArrayLike(b)||a.length!=b.length)return!1;var d=a.length;c=c||goog.array.defaultCompareEquality;for(var e=0;e<d;e++)if(!c(a[e],b[e]))return!1;return!0};goog.array.compare3=function(a,b,c){c=c||goog.array.defaultCompare;for(var d=Math.min(a.length,b.length),e=0;e<d;e++){var f=c(a[e],b[e]);if(0!=f)return f}return goog.array.defaultCompare(a.length,b.length)};goog.array.defaultCompare=function(a,b){return a>b?1:a<b?-1:0};
goog.array.inverseDefaultCompare=function(a,b){return-goog.array.defaultCompare(a,b)};goog.array.defaultCompareEquality=function(a,b){return a===b};goog.array.binaryInsert=function(a,b,c){c=goog.array.binarySearch(a,b,c);return 0>c?(goog.array.insertAt(a,b,-(c+1)),!0):!1};goog.array.binaryRemove=function(a,b,c){b=goog.array.binarySearch(a,b,c);return 0<=b?goog.array.removeAt(a,b):!1};
goog.array.bucket=function(a,b,c){for(var d={},e=0;e<a.length;e++){var f=a[e],g=b.call(c,f,e,a);goog.isDef(g)&&(d[g]||(d[g]=[])).push(f)}return d};goog.array.toObject=function(a,b,c){var d={};goog.array.forEach(a,function(e,f){d[b.call(c,e,f,a)]=e});return d};goog.array.range=function(a,b,c){var d=[],e=0,f=a;c=c||1;void 0!==b&&(e=a,f=b);if(0>c*(f-e))return[];if(0<c)for(a=e;a<f;a+=c)d.push(a);else for(a=e;a>f;a+=c)d.push(a);return d};
goog.array.repeat=function(a,b){for(var c=[],d=0;d<b;d++)c[d]=a;return c};goog.array.flatten=function(a){for(var b=[],c=0;c<arguments.length;c++){var d=arguments[c];if(goog.isArray(d))for(var e=0;e<d.length;e+=8192){var f=goog.array.slice(d,e,e+8192);f=goog.array.flatten.apply(null,f);for(var g=0;g<f.length;g++)b.push(f[g])}else b.push(d)}return b};
goog.array.rotate=function(a,b){goog.asserts.assert(null!=a.length);a.length&&(b%=a.length,0<b?Array.prototype.unshift.apply(a,a.splice(-b,b)):0>b&&Array.prototype.push.apply(a,a.splice(0,-b)));return a};goog.array.moveItem=function(a,b,c){goog.asserts.assert(0<=b&&b<a.length);goog.asserts.assert(0<=c&&c<a.length);b=Array.prototype.splice.call(a,b,1);Array.prototype.splice.call(a,c,0,b[0])};
goog.array.zip=function(a){if(!arguments.length)return[];for(var b=[],c=arguments[0].length,d=1;d<arguments.length;d++)arguments[d].length<c&&(c=arguments[d].length);for(d=0;d<c;d++){for(var e=[],f=0;f<arguments.length;f++)e.push(arguments[f][d]);b.push(e)}return b};goog.array.shuffle=function(a,b){b=b||Math.random;for(var c=a.length-1;0<c;c--){var d=Math.floor(b()*(c+1)),e=a[c];a[c]=a[d];a[d]=e}};goog.array.copyByIndex=function(a,b){var c=[];goog.array.forEach(b,function(b){c.push(a[b])});return c};
goog.array.concatMap=function(a,b,c){return goog.array.concat.apply([],goog.array.map(a,b,c))};goog.debug.errorcontext={};goog.debug.errorcontext.addErrorContext=function(a,b,c){a[goog.debug.errorcontext.CONTEXT_KEY_]||(a[goog.debug.errorcontext.CONTEXT_KEY_]={});a[goog.debug.errorcontext.CONTEXT_KEY_][b]=c};goog.debug.errorcontext.getErrorContext=function(a){return a[goog.debug.errorcontext.CONTEXT_KEY_]||{}};goog.debug.errorcontext.CONTEXT_KEY_="__closure__error__context__984382";goog.string={};goog.string.internal={};goog.string.internal.startsWith=function(a,b){return 0==a.lastIndexOf(b,0)};goog.string.internal.endsWith=function(a,b){var c=a.length-b.length;return 0<=c&&a.indexOf(b,c)==c};goog.string.internal.caseInsensitiveStartsWith=function(a,b){return 0==goog.string.internal.caseInsensitiveCompare(b,a.substr(0,b.length))};goog.string.internal.caseInsensitiveEndsWith=function(a,b){return 0==goog.string.internal.caseInsensitiveCompare(b,a.substr(a.length-b.length,b.length))};
goog.string.internal.caseInsensitiveEquals=function(a,b){return a.toLowerCase()==b.toLowerCase()};goog.string.internal.isEmptyOrWhitespace=function(a){return/^[\s\xa0]*$/.test(a)};goog.string.internal.trim=goog.TRUSTED_SITE&&String.prototype.trim?function(a){return a.trim()}:function(a){return/^[\s\xa0]*([\s\S]*?)[\s\xa0]*$/.exec(a)[1]};goog.string.internal.caseInsensitiveCompare=function(a,b){a=String(a).toLowerCase();b=String(b).toLowerCase();return a<b?-1:a==b?0:1};
goog.string.internal.newLineToBr=function(a,b){return a.replace(/(\r\n|\r|\n)/g,b?"<br />":"<br>")};
goog.string.internal.htmlEscape=function(a,b){if(b)a=a.replace(goog.string.internal.AMP_RE_,"&amp;").replace(goog.string.internal.LT_RE_,"&lt;").replace(goog.string.internal.GT_RE_,"&gt;").replace(goog.string.internal.QUOT_RE_,"&quot;").replace(goog.string.internal.SINGLE_QUOTE_RE_,"&#39;").replace(goog.string.internal.NULL_RE_,"&#0;");else{if(!goog.string.internal.ALL_RE_.test(a))return a;-1!=a.indexOf("&")&&(a=a.replace(goog.string.internal.AMP_RE_,"&amp;"));-1!=a.indexOf("<")&&(a=a.replace(goog.string.internal.LT_RE_,
"&lt;"));-1!=a.indexOf(">")&&(a=a.replace(goog.string.internal.GT_RE_,"&gt;"));-1!=a.indexOf('"')&&(a=a.replace(goog.string.internal.QUOT_RE_,"&quot;"));-1!=a.indexOf("'")&&(a=a.replace(goog.string.internal.SINGLE_QUOTE_RE_,"&#39;"));-1!=a.indexOf("\x00")&&(a=a.replace(goog.string.internal.NULL_RE_,"&#0;"))}return a};goog.string.internal.AMP_RE_=/&/g;goog.string.internal.LT_RE_=/</g;goog.string.internal.GT_RE_=/>/g;goog.string.internal.QUOT_RE_=/"/g;goog.string.internal.SINGLE_QUOTE_RE_=/'/g;
goog.string.internal.NULL_RE_=/\x00/g;goog.string.internal.ALL_RE_=/[\x00&<>"']/;goog.string.internal.whitespaceEscape=function(a,b){return goog.string.internal.newLineToBr(a.replace(/  /g," &#160;"),b)};goog.string.internal.contains=function(a,b){return-1!=a.indexOf(b)};goog.string.internal.caseInsensitiveContains=function(a,b){return goog.string.internal.contains(a.toLowerCase(),b.toLowerCase())};
goog.string.internal.compareVersions=function(a,b){var c=0;a=goog.string.internal.trim(String(a)).split(".");b=goog.string.internal.trim(String(b)).split(".");for(var d=Math.max(a.length,b.length),e=0;0==c&&e<d;e++){var f=a[e]||"",g=b[e]||"";do{f=/(\d*)(\D*)(.*)/.exec(f)||["","","",""];g=/(\d*)(\D*)(.*)/.exec(g)||["","","",""];if(0==f[0].length&&0==g[0].length)break;c=0==f[1].length?0:parseInt(f[1],10);var h=0==g[1].length?0:parseInt(g[1],10);c=goog.string.internal.compareElements_(c,h)||goog.string.internal.compareElements_(0==
f[2].length,0==g[2].length)||goog.string.internal.compareElements_(f[2],g[2]);f=f[3];g=g[3]}while(0==c)}return c};goog.string.internal.compareElements_=function(a,b){return a<b?-1:a>b?1:0};goog.labs={};goog.labs.userAgent={};goog.labs.userAgent.util={};goog.labs.userAgent.util.getNativeUserAgentString_=function(){var a=goog.labs.userAgent.util.getNavigator_();return a&&(a=a.userAgent)?a:""};goog.labs.userAgent.util.getNavigator_=function(){return goog.global.navigator};goog.labs.userAgent.util.userAgent_=goog.labs.userAgent.util.getNativeUserAgentString_();goog.labs.userAgent.util.setUserAgent=function(a){goog.labs.userAgent.util.userAgent_=a||goog.labs.userAgent.util.getNativeUserAgentString_()};
goog.labs.userAgent.util.getUserAgent=function(){return goog.labs.userAgent.util.userAgent_};goog.labs.userAgent.util.matchUserAgent=function(a){var b=goog.labs.userAgent.util.getUserAgent();return goog.string.internal.contains(b,a)};goog.labs.userAgent.util.matchUserAgentIgnoreCase=function(a){var b=goog.labs.userAgent.util.getUserAgent();return goog.string.internal.caseInsensitiveContains(b,a)};
goog.labs.userAgent.util.extractVersionTuples=function(a){for(var b=/(\w[\w ]+)\/([^\s]+)\s*(?:\((.*?)\))?/g,c=[],d;d=b.exec(a);)c.push([d[1],d[2],d[3]||void 0]);return c};goog.object={};goog.object.is=function(a,b){return a===b?0!==a||1/a===1/b:a!==a&&b!==b};goog.object.forEach=function(a,b,c){for(var d in a)b.call(c,a[d],d,a)};goog.object.filter=function(a,b,c){var d={},e;for(e in a)b.call(c,a[e],e,a)&&(d[e]=a[e]);return d};goog.object.map=function(a,b,c){var d={},e;for(e in a)d[e]=b.call(c,a[e],e,a);return d};goog.object.some=function(a,b,c){for(var d in a)if(b.call(c,a[d],d,a))return!0;return!1};
goog.object.every=function(a,b,c){for(var d in a)if(!b.call(c,a[d],d,a))return!1;return!0};goog.object.getCount=function(a){var b=0,c;for(c in a)b++;return b};goog.object.getAnyKey=function(a){for(var b in a)return b};goog.object.getAnyValue=function(a){for(var b in a)return a[b]};goog.object.contains=function(a,b){return goog.object.containsValue(a,b)};goog.object.getValues=function(a){var b=[],c=0,d;for(d in a)b[c++]=a[d];return b};
goog.object.getKeys=function(a){var b=[],c=0,d;for(d in a)b[c++]=d;return b};goog.object.getValueByKeys=function(a,b){var c=goog.isArrayLike(b),d=c?b:arguments;for(c=c?0:1;c<d.length;c++){if(null==a)return;a=a[d[c]]}return a};goog.object.containsKey=function(a,b){return null!==a&&b in a};goog.object.containsValue=function(a,b){for(var c in a)if(a[c]==b)return!0;return!1};goog.object.findKey=function(a,b,c){for(var d in a)if(b.call(c,a[d],d,a))return d};
goog.object.findValue=function(a,b,c){return(b=goog.object.findKey(a,b,c))&&a[b]};goog.object.isEmpty=function(a){for(var b in a)return!1;return!0};goog.object.clear=function(a){for(var b in a)delete a[b]};goog.object.remove=function(a,b){var c;(c=b in a)&&delete a[b];return c};goog.object.add=function(a,b,c){if(null!==a&&b in a)throw Error('The object already contains the key "'+b+'"');goog.object.set(a,b,c)};goog.object.get=function(a,b,c){return null!==a&&b in a?a[b]:c};
goog.object.set=function(a,b,c){a[b]=c};goog.object.setIfUndefined=function(a,b,c){return b in a?a[b]:a[b]=c};goog.object.setWithReturnValueIfNotSet=function(a,b,c){if(b in a)return a[b];c=c();return a[b]=c};goog.object.equals=function(a,b){for(var c in a)if(!(c in b)||a[c]!==b[c])return!1;for(c in b)if(!(c in a))return!1;return!0};goog.object.clone=function(a){var b={},c;for(c in a)b[c]=a[c];return b};
goog.object.unsafeClone=function(a){var b=goog.typeOf(a);if("object"==b||"array"==b){if(goog.isFunction(a.clone))return a.clone();b="array"==b?[]:{};for(var c in a)b[c]=goog.object.unsafeClone(a[c]);return b}return a};goog.object.transpose=function(a){var b={},c;for(c in a)b[a[c]]=c;return b};goog.object.PROTOTYPE_FIELDS_="constructor hasOwnProperty isPrototypeOf propertyIsEnumerable toLocaleString toString valueOf".split(" ");
goog.object.extend=function(a,b){for(var c,d,e=1;e<arguments.length;e++){d=arguments[e];for(c in d)a[c]=d[c];for(var f=0;f<goog.object.PROTOTYPE_FIELDS_.length;f++)c=goog.object.PROTOTYPE_FIELDS_[f],Object.prototype.hasOwnProperty.call(d,c)&&(a[c]=d[c])}};
goog.object.create=function(a){var b=arguments.length;if(1==b&&goog.isArray(arguments[0]))return goog.object.create.apply(null,arguments[0]);if(b%2)throw Error("Uneven number of arguments");for(var c={},d=0;d<b;d+=2)c[arguments[d]]=arguments[d+1];return c};goog.object.createSet=function(a){var b=arguments.length;if(1==b&&goog.isArray(arguments[0]))return goog.object.createSet.apply(null,arguments[0]);for(var c={},d=0;d<b;d++)c[arguments[d]]=!0;return c};
goog.object.createImmutableView=function(a){var b=a;Object.isFrozen&&!Object.isFrozen(a)&&(b=Object.create(a),Object.freeze(b));return b};goog.object.isImmutableView=function(a){return!!Object.isFrozen&&Object.isFrozen(a)};
goog.object.getAllPropertyNames=function(a,b,c){if(!a)return[];if(!Object.getOwnPropertyNames||!Object.getPrototypeOf)return goog.object.getKeys(a);for(var d={};a&&(a!==Object.prototype||b)&&(a!==Function.prototype||c);){for(var e=Object.getOwnPropertyNames(a),f=0;f<e.length;f++)d[e[f]]=!0;a=Object.getPrototypeOf(a)}return goog.object.getKeys(d)};goog.labs.userAgent.browser={};goog.labs.userAgent.browser.matchOpera_=function(){return goog.labs.userAgent.util.matchUserAgent("Opera")};goog.labs.userAgent.browser.matchIE_=function(){return goog.labs.userAgent.util.matchUserAgent("Trident")||goog.labs.userAgent.util.matchUserAgent("MSIE")};goog.labs.userAgent.browser.matchEdge_=function(){return goog.labs.userAgent.util.matchUserAgent("Edge")};
goog.labs.userAgent.browser.matchFirefox_=function(){return goog.labs.userAgent.util.matchUserAgent("Firefox")||goog.labs.userAgent.util.matchUserAgent("FxiOS")};
goog.labs.userAgent.browser.matchSafari_=function(){return goog.labs.userAgent.util.matchUserAgent("Safari")&&!(goog.labs.userAgent.browser.matchChrome_()||goog.labs.userAgent.browser.matchCoast_()||goog.labs.userAgent.browser.matchOpera_()||goog.labs.userAgent.browser.matchEdge_()||goog.labs.userAgent.browser.matchFirefox_()||goog.labs.userAgent.browser.isSilk()||goog.labs.userAgent.util.matchUserAgent("Android"))};goog.labs.userAgent.browser.matchCoast_=function(){return goog.labs.userAgent.util.matchUserAgent("Coast")};
goog.labs.userAgent.browser.matchIosWebview_=function(){return(goog.labs.userAgent.util.matchUserAgent("iPad")||goog.labs.userAgent.util.matchUserAgent("iPhone"))&&!goog.labs.userAgent.browser.matchSafari_()&&!goog.labs.userAgent.browser.matchChrome_()&&!goog.labs.userAgent.browser.matchCoast_()&&!goog.labs.userAgent.browser.matchFirefox_()&&goog.labs.userAgent.util.matchUserAgent("AppleWebKit")};
goog.labs.userAgent.browser.matchChrome_=function(){return(goog.labs.userAgent.util.matchUserAgent("Chrome")||goog.labs.userAgent.util.matchUserAgent("CriOS"))&&!goog.labs.userAgent.browser.matchEdge_()};goog.labs.userAgent.browser.matchAndroidBrowser_=function(){return goog.labs.userAgent.util.matchUserAgent("Android")&&!(goog.labs.userAgent.browser.isChrome()||goog.labs.userAgent.browser.isFirefox()||goog.labs.userAgent.browser.isOpera()||goog.labs.userAgent.browser.isSilk())};
goog.labs.userAgent.browser.isOpera=goog.labs.userAgent.browser.matchOpera_;goog.labs.userAgent.browser.isIE=goog.labs.userAgent.browser.matchIE_;goog.labs.userAgent.browser.isEdge=goog.labs.userAgent.browser.matchEdge_;goog.labs.userAgent.browser.isFirefox=goog.labs.userAgent.browser.matchFirefox_;goog.labs.userAgent.browser.isSafari=goog.labs.userAgent.browser.matchSafari_;goog.labs.userAgent.browser.isCoast=goog.labs.userAgent.browser.matchCoast_;goog.labs.userAgent.browser.isIosWebview=goog.labs.userAgent.browser.matchIosWebview_;
goog.labs.userAgent.browser.isChrome=goog.labs.userAgent.browser.matchChrome_;goog.labs.userAgent.browser.isAndroidBrowser=goog.labs.userAgent.browser.matchAndroidBrowser_;goog.labs.userAgent.browser.isSilk=function(){return goog.labs.userAgent.util.matchUserAgent("Silk")};
goog.labs.userAgent.browser.getVersion=function(){function a(a){a=goog.array.find(a,d);return c[a]||""}var b=goog.labs.userAgent.util.getUserAgent();if(goog.labs.userAgent.browser.isIE())return goog.labs.userAgent.browser.getIEVersion_(b);b=goog.labs.userAgent.util.extractVersionTuples(b);var c={};goog.array.forEach(b,function(a){c[a[0]]=a[1]});var d=goog.partial(goog.object.containsKey,c);return goog.labs.userAgent.browser.isOpera()?a(["Version","Opera"]):goog.labs.userAgent.browser.isEdge()?a(["Edge"]):
goog.labs.userAgent.browser.isChrome()?a(["Chrome","CriOS"]):(b=b[2])&&b[1]||""};goog.labs.userAgent.browser.isVersionOrHigher=function(a){return 0<=goog.string.internal.compareVersions(goog.labs.userAgent.browser.getVersion(),a)};
goog.labs.userAgent.browser.getIEVersion_=function(a){var b=/rv: *([\d\.]*)/.exec(a);if(b&&b[1])return b[1];b="";var c=/MSIE +([\d\.]+)/.exec(a);if(c&&c[1])if(a=/Trident\/(\d.\d)/.exec(a),"7.0"==c[1])if(a&&a[1])switch(a[1]){case "4.0":b="8.0";break;case "5.0":b="9.0";break;case "6.0":b="10.0";break;case "7.0":b="11.0"}else b="7.0";else b=c[1];return b};goog.string.DETECT_DOUBLE_ESCAPING=!1;goog.string.FORCE_NON_DOM_HTML_UNESCAPING=!1;goog.string.Unicode={NBSP:"\u00a0"};goog.string.startsWith=goog.string.internal.startsWith;goog.string.endsWith=goog.string.internal.endsWith;goog.string.caseInsensitiveStartsWith=goog.string.internal.caseInsensitiveStartsWith;goog.string.caseInsensitiveEndsWith=goog.string.internal.caseInsensitiveEndsWith;goog.string.caseInsensitiveEquals=goog.string.internal.caseInsensitiveEquals;
goog.string.subs=function(a,b){for(var c=a.split("%s"),d="",e=Array.prototype.slice.call(arguments,1);e.length&&1<c.length;)d+=c.shift()+e.shift();return d+c.join("%s")};goog.string.collapseWhitespace=function(a){return a.replace(/[\s\xa0]+/g," ").replace(/^\s+|\s+$/g,"")};goog.string.isEmptyOrWhitespace=goog.string.internal.isEmptyOrWhitespace;goog.string.isEmptyString=function(a){return 0==a.length};goog.string.isEmpty=goog.string.isEmptyOrWhitespace;goog.string.isEmptyOrWhitespaceSafe=function(a){return goog.string.isEmptyOrWhitespace(goog.string.makeSafe(a))};
goog.string.isEmptySafe=goog.string.isEmptyOrWhitespaceSafe;goog.string.isBreakingWhitespace=function(a){return!/[^\t\n\r ]/.test(a)};goog.string.isAlpha=function(a){return!/[^a-zA-Z]/.test(a)};goog.string.isNumeric=function(a){return!/[^0-9]/.test(a)};goog.string.isAlphaNumeric=function(a){return!/[^a-zA-Z0-9]/.test(a)};goog.string.isSpace=function(a){return" "==a};goog.string.isUnicodeChar=function(a){return 1==a.length&&" "<=a&&"~">=a||"\u0080"<=a&&"\ufffd">=a};
goog.string.stripNewlines=function(a){return a.replace(/(\r\n|\r|\n)+/g," ")};goog.string.canonicalizeNewlines=function(a){return a.replace(/(\r\n|\r|\n)/g,"\n")};goog.string.normalizeWhitespace=function(a){return a.replace(/\xa0|\s/g," ")};goog.string.normalizeSpaces=function(a){return a.replace(/\xa0|[ \t]+/g," ")};goog.string.collapseBreakingSpaces=function(a){return a.replace(/[\t\r\n ]+/g," ").replace(/^[\t\r\n ]+|[\t\r\n ]+$/g,"")};goog.string.trim=goog.string.internal.trim;
goog.string.trimLeft=function(a){return a.replace(/^[\s\xa0]+/,"")};goog.string.trimRight=function(a){return a.replace(/[\s\xa0]+$/,"")};goog.string.caseInsensitiveCompare=goog.string.internal.caseInsensitiveCompare;
goog.string.numberAwareCompare_=function(a,b,c){if(a==b)return 0;if(!a)return-1;if(!b)return 1;for(var d=a.toLowerCase().match(c),e=b.toLowerCase().match(c),f=Math.min(d.length,e.length),g=0;g<f;g++){c=d[g];var h=e[g];if(c!=h)return a=parseInt(c,10),!isNaN(a)&&(b=parseInt(h,10),!isNaN(b)&&a-b)?a-b:c<h?-1:1}return d.length!=e.length?d.length-e.length:a<b?-1:1};goog.string.intAwareCompare=function(a,b){return goog.string.numberAwareCompare_(a,b,/\d+|\D+/g)};
goog.string.floatAwareCompare=function(a,b){return goog.string.numberAwareCompare_(a,b,/\d+|\.\d+|\D+/g)};goog.string.numerateCompare=goog.string.floatAwareCompare;goog.string.urlEncode=function(a){return encodeURIComponent(String(a))};goog.string.urlDecode=function(a){return decodeURIComponent(a.replace(/\+/g," "))};goog.string.newLineToBr=goog.string.internal.newLineToBr;
goog.string.htmlEscape=function(a,b){a=goog.string.internal.htmlEscape(a,b);goog.string.DETECT_DOUBLE_ESCAPING&&(a=a.replace(goog.string.E_RE_,"&#101;"));return a};goog.string.E_RE_=/e/g;goog.string.unescapeEntities=function(a){return goog.string.contains(a,"&")?!goog.string.FORCE_NON_DOM_HTML_UNESCAPING&&"document"in goog.global?goog.string.unescapeEntitiesUsingDom_(a):goog.string.unescapePureXmlEntities_(a):a};
goog.string.unescapeEntitiesWithDocument=function(a,b){return goog.string.contains(a,"&")?goog.string.unescapeEntitiesUsingDom_(a,b):a};
goog.string.unescapeEntitiesUsingDom_=function(a,b){var c={"&amp;":"&","&lt;":"<","&gt;":">","&quot;":'"'};var d=b?b.createElement("div"):goog.global.document.createElement("div");return a.replace(goog.string.HTML_ENTITY_PATTERN_,function(a,b){var e=c[a];if(e)return e;"#"==b.charAt(0)&&(b=Number("0"+b.substr(1)),isNaN(b)||(e=String.fromCharCode(b)));e||(d.innerHTML=a+" ",e=d.firstChild.nodeValue.slice(0,-1));return c[a]=e})};
goog.string.unescapePureXmlEntities_=function(a){return a.replace(/&([^;]+);/g,function(a,c){switch(c){case "amp":return"&";case "lt":return"<";case "gt":return">";case "quot":return'"';default:return"#"!=c.charAt(0)||(c=Number("0"+c.substr(1)),isNaN(c))?a:String.fromCharCode(c)}})};goog.string.HTML_ENTITY_PATTERN_=/&([^;\s<&]+);?/g;goog.string.whitespaceEscape=function(a,b){return goog.string.newLineToBr(a.replace(/  /g," &#160;"),b)};
goog.string.preserveSpaces=function(a){return a.replace(/(^|[\n ]) /g,"$1"+goog.string.Unicode.NBSP)};goog.string.stripQuotes=function(a,b){for(var c=b.length,d=0;d<c;d++){var e=1==c?b:b.charAt(d);if(a.charAt(0)==e&&a.charAt(a.length-1)==e)return a.substring(1,a.length-1)}return a};goog.string.truncate=function(a,b,c){c&&(a=goog.string.unescapeEntities(a));a.length>b&&(a=a.substring(0,b-3)+"...");c&&(a=goog.string.htmlEscape(a));return a};
goog.string.truncateMiddle=function(a,b,c,d){c&&(a=goog.string.unescapeEntities(a));if(d&&a.length>b){d>b&&(d=b);var e=a.length-d;a=a.substring(0,b-d)+"..."+a.substring(e)}else a.length>b&&(d=Math.floor(b/2),e=a.length-d,a=a.substring(0,d+b%2)+"..."+a.substring(e));c&&(a=goog.string.htmlEscape(a));return a};goog.string.specialEscapeChars_={"\x00":"\\0","\b":"\\b","\f":"\\f","\n":"\\n","\r":"\\r","\t":"\\t","\x0B":"\\x0B",'"':'\\"',"\\":"\\\\","<":"<"};goog.string.jsEscapeCache_={"'":"\\'"};
goog.string.quote=function(a){a=String(a);for(var b=['"'],c=0;c<a.length;c++){var d=a.charAt(c),e=d.charCodeAt(0);b[c+1]=goog.string.specialEscapeChars_[d]||(31<e&&127>e?d:goog.string.escapeChar(d))}b.push('"');return b.join("")};goog.string.escapeString=function(a){for(var b=[],c=0;c<a.length;c++)b[c]=goog.string.escapeChar(a.charAt(c));return b.join("")};
goog.string.escapeChar=function(a){if(a in goog.string.jsEscapeCache_)return goog.string.jsEscapeCache_[a];if(a in goog.string.specialEscapeChars_)return goog.string.jsEscapeCache_[a]=goog.string.specialEscapeChars_[a];var b=a.charCodeAt(0);if(31<b&&127>b)var c=a;else{if(256>b){if(c="\\x",16>b||256<b)c+="0"}else c="\\u",4096>b&&(c+="0");c+=b.toString(16).toUpperCase()}return goog.string.jsEscapeCache_[a]=c};goog.string.contains=goog.string.internal.contains;goog.string.caseInsensitiveContains=goog.string.internal.caseInsensitiveContains;
goog.string.countOf=function(a,b){return a&&b?a.split(b).length-1:0};goog.string.removeAt=function(a,b,c){var d=a;0<=b&&b<a.length&&0<c&&(d=a.substr(0,b)+a.substr(b+c,a.length-b-c));return d};goog.string.remove=function(a,b){return a.replace(b,"")};goog.string.removeAll=function(a,b){b=new RegExp(goog.string.regExpEscape(b),"g");return a.replace(b,"")};goog.string.replaceAll=function(a,b,c){b=new RegExp(goog.string.regExpEscape(b),"g");return a.replace(b,c.replace(/\$/g,"$$$$"))};
goog.string.regExpEscape=function(a){return String(a).replace(/([-()\[\]{}+?*.$\^|,:#<!\\])/g,"\\$1").replace(/\x08/g,"\\x08")};goog.string.repeat=String.prototype.repeat?function(a,b){return a.repeat(b)}:function(a,b){return Array(b+1).join(a)};goog.string.padNumber=function(a,b,c){a=goog.isDef(c)?a.toFixed(c):String(a);c=a.indexOf(".");-1==c&&(c=a.length);return goog.string.repeat("0",Math.max(0,b-c))+a};goog.string.makeSafe=function(a){return null==a?"":String(a)};
goog.string.buildString=function(a){return Array.prototype.join.call(arguments,"")};goog.string.getRandomString=function(){return Math.floor(2147483648*Math.random()).toString(36)+Math.abs(Math.floor(2147483648*Math.random())^goog.now()).toString(36)};goog.string.compareVersions=goog.string.internal.compareVersions;goog.string.hashCode=function(a){for(var b=0,c=0;c<a.length;++c)b=31*b+a.charCodeAt(c)>>>0;return b};goog.string.uniqueStringCounter_=2147483648*Math.random()|0;
goog.string.createUniqueString=function(){return"goog_"+goog.string.uniqueStringCounter_++};goog.string.toNumber=function(a){var b=Number(a);return 0==b&&goog.string.isEmptyOrWhitespace(a)?NaN:b};goog.string.isLowerCamelCase=function(a){return/^[a-z]+([A-Z][a-z]*)*$/.test(a)};goog.string.isUpperCamelCase=function(a){return/^([A-Z][a-z]*)+$/.test(a)};goog.string.toCamelCase=function(a){return String(a).replace(/\-([a-z])/g,function(a,c){return c.toUpperCase()})};
goog.string.toSelectorCase=function(a){return String(a).replace(/([A-Z])/g,"-$1").toLowerCase()};goog.string.toTitleCase=function(a,b){b=goog.isString(b)?goog.string.regExpEscape(b):"\\s";return a.replace(new RegExp("(^"+(b?"|["+b+"]+":"")+")([a-z])","g"),function(a,b,e){return b+e.toUpperCase()})};goog.string.capitalize=function(a){return String(a.charAt(0)).toUpperCase()+String(a.substr(1)).toLowerCase()};
goog.string.parseInt=function(a){isFinite(a)&&(a=String(a));return goog.isString(a)?/^\s*-?0x/i.test(a)?parseInt(a,16):parseInt(a,10):NaN};goog.string.splitLimit=function(a,b,c){a=a.split(b);for(var d=[];0<c&&a.length;)d.push(a.shift()),c--;a.length&&d.push(a.join(b));return d};goog.string.lastComponent=function(a,b){if(b)"string"==typeof b&&(b=[b]);else return a;for(var c=-1,d=0;d<b.length;d++)if(""!=b[d]){var e=a.lastIndexOf(b[d]);e>c&&(c=e)}return-1==c?a:a.slice(c+1)};
goog.string.editDistance=function(a,b){var c=[],d=[];if(a==b)return 0;if(!a.length||!b.length)return Math.max(a.length,b.length);for(var e=0;e<b.length+1;e++)c[e]=e;for(e=0;e<a.length;e++){d[0]=e+1;for(var f=0;f<b.length;f++)d[f+1]=Math.min(d[f]+1,c[f+1]+1,c[f]+Number(a[e]!=b[f]));for(f=0;f<c.length;f++)c[f]=d[f]}return d[b.length]};goog.labs.userAgent.engine={};goog.labs.userAgent.engine.isPresto=function(){return goog.labs.userAgent.util.matchUserAgent("Presto")};goog.labs.userAgent.engine.isTrident=function(){return goog.labs.userAgent.util.matchUserAgent("Trident")||goog.labs.userAgent.util.matchUserAgent("MSIE")};goog.labs.userAgent.engine.isEdge=function(){return goog.labs.userAgent.util.matchUserAgent("Edge")};
goog.labs.userAgent.engine.isWebKit=function(){return goog.labs.userAgent.util.matchUserAgentIgnoreCase("WebKit")&&!goog.labs.userAgent.engine.isEdge()};goog.labs.userAgent.engine.isGecko=function(){return goog.labs.userAgent.util.matchUserAgent("Gecko")&&!goog.labs.userAgent.engine.isWebKit()&&!goog.labs.userAgent.engine.isTrident()&&!goog.labs.userAgent.engine.isEdge()};
goog.labs.userAgent.engine.getVersion=function(){var a=goog.labs.userAgent.util.getUserAgent();if(a){a=goog.labs.userAgent.util.extractVersionTuples(a);var b=goog.labs.userAgent.engine.getEngineTuple_(a);if(b)return"Gecko"==b[0]?goog.labs.userAgent.engine.getVersionForKey_(a,"Firefox"):b[1];a=a[0];var c;if(a&&(c=a[2])&&(c=/Trident\/([^\s;]+)/.exec(c)))return c[1]}return""};
goog.labs.userAgent.engine.getEngineTuple_=function(a){if(!goog.labs.userAgent.engine.isEdge())return a[1];for(var b=0;b<a.length;b++){var c=a[b];if("Edge"==c[0])return c}};goog.labs.userAgent.engine.isVersionOrHigher=function(a){return 0<=goog.string.compareVersions(goog.labs.userAgent.engine.getVersion(),a)};goog.labs.userAgent.engine.getVersionForKey_=function(a,b){return(a=goog.array.find(a,function(a){return b==a[0]}))&&a[1]||""};goog.labs.userAgent.platform={};goog.labs.userAgent.platform.isAndroid=function(){return goog.labs.userAgent.util.matchUserAgent("Android")};goog.labs.userAgent.platform.isIpod=function(){return goog.labs.userAgent.util.matchUserAgent("iPod")};goog.labs.userAgent.platform.isIphone=function(){return goog.labs.userAgent.util.matchUserAgent("iPhone")&&!goog.labs.userAgent.util.matchUserAgent("iPod")&&!goog.labs.userAgent.util.matchUserAgent("iPad")};goog.labs.userAgent.platform.isIpad=function(){return goog.labs.userAgent.util.matchUserAgent("iPad")};
goog.labs.userAgent.platform.isIos=function(){return goog.labs.userAgent.platform.isIphone()||goog.labs.userAgent.platform.isIpad()||goog.labs.userAgent.platform.isIpod()};goog.labs.userAgent.platform.isMacintosh=function(){return goog.labs.userAgent.util.matchUserAgent("Macintosh")};goog.labs.userAgent.platform.isLinux=function(){return goog.labs.userAgent.util.matchUserAgent("Linux")};goog.labs.userAgent.platform.isWindows=function(){return goog.labs.userAgent.util.matchUserAgent("Windows")};
goog.labs.userAgent.platform.isChromeOS=function(){return goog.labs.userAgent.util.matchUserAgent("CrOS")};goog.labs.userAgent.platform.isChromecast=function(){return goog.labs.userAgent.util.matchUserAgent("CrKey")};goog.labs.userAgent.platform.isKaiOS=function(){return goog.labs.userAgent.util.matchUserAgentIgnoreCase("KaiOS")};goog.labs.userAgent.platform.isGo2Phone=function(){return goog.labs.userAgent.util.matchUserAgentIgnoreCase("GAFP")};
goog.labs.userAgent.platform.getVersion=function(){var a=goog.labs.userAgent.util.getUserAgent(),b="";goog.labs.userAgent.platform.isWindows()?(b=/Windows (?:NT|Phone) ([0-9.]+)/,b=(a=b.exec(a))?a[1]:"0.0"):goog.labs.userAgent.platform.isIos()?(b=/(?:iPhone|iPod|iPad|CPU)\s+OS\s+(\S+)/,b=(a=b.exec(a))&&a[1].replace(/_/g,".")):goog.labs.userAgent.platform.isMacintosh()?(b=/Mac OS X ([0-9_.]+)/,b=(a=b.exec(a))?a[1].replace(/_/g,"."):"10"):goog.labs.userAgent.platform.isAndroid()?(b=/Android\s+([^\);]+)(\)|;)/,
b=(a=b.exec(a))&&a[1]):goog.labs.userAgent.platform.isChromeOS()&&(b=/(?:CrOS\s+(?:i686|x86_64)\s+([0-9.]+))/,b=(a=b.exec(a))&&a[1]);return b||""};goog.labs.userAgent.platform.isVersionOrHigher=function(a){return 0<=goog.string.compareVersions(goog.labs.userAgent.platform.getVersion(),a)};goog.reflect={};goog.reflect.object=function(a,b){return b};goog.reflect.objectProperty=function(a,b){return a};goog.reflect.sinkValue=function(a){goog.reflect.sinkValue[" "](a);return a};goog.reflect.sinkValue[" "]=goog.nullFunction;goog.reflect.canAccessProperty=function(a,b){try{return goog.reflect.sinkValue(a[b]),!0}catch(c){}return!1};goog.reflect.cache=function(a,b,c,d){d=d?d(b):b;return Object.prototype.hasOwnProperty.call(a,d)?a[d]:a[d]=c(b)};goog.userAgent={};goog.userAgent.ASSUME_IE=!1;goog.userAgent.ASSUME_EDGE=!1;goog.userAgent.ASSUME_GECKO=!1;goog.userAgent.ASSUME_WEBKIT=!1;goog.userAgent.ASSUME_MOBILE_WEBKIT=!1;goog.userAgent.ASSUME_OPERA=!1;goog.userAgent.ASSUME_ANY_VERSION=!1;goog.userAgent.BROWSER_KNOWN_=goog.userAgent.ASSUME_IE||goog.userAgent.ASSUME_EDGE||goog.userAgent.ASSUME_GECKO||goog.userAgent.ASSUME_MOBILE_WEBKIT||goog.userAgent.ASSUME_WEBKIT||goog.userAgent.ASSUME_OPERA;goog.userAgent.getUserAgentString=function(){return goog.labs.userAgent.util.getUserAgent()};
goog.userAgent.getNavigatorTyped=function(){return goog.global.navigator||null};goog.userAgent.getNavigator=function(){return goog.userAgent.getNavigatorTyped()};goog.userAgent.OPERA=goog.userAgent.BROWSER_KNOWN_?goog.userAgent.ASSUME_OPERA:goog.labs.userAgent.browser.isOpera();goog.userAgent.IE=goog.userAgent.BROWSER_KNOWN_?goog.userAgent.ASSUME_IE:goog.labs.userAgent.browser.isIE();goog.userAgent.EDGE=goog.userAgent.BROWSER_KNOWN_?goog.userAgent.ASSUME_EDGE:goog.labs.userAgent.engine.isEdge();
goog.userAgent.EDGE_OR_IE=goog.userAgent.EDGE||goog.userAgent.IE;goog.userAgent.GECKO=goog.userAgent.BROWSER_KNOWN_?goog.userAgent.ASSUME_GECKO:goog.labs.userAgent.engine.isGecko();goog.userAgent.WEBKIT=goog.userAgent.BROWSER_KNOWN_?goog.userAgent.ASSUME_WEBKIT||goog.userAgent.ASSUME_MOBILE_WEBKIT:goog.labs.userAgent.engine.isWebKit();goog.userAgent.isMobile_=function(){return goog.userAgent.WEBKIT&&goog.labs.userAgent.util.matchUserAgent("Mobile")};
goog.userAgent.MOBILE=goog.userAgent.ASSUME_MOBILE_WEBKIT||goog.userAgent.isMobile_();goog.userAgent.SAFARI=goog.userAgent.WEBKIT;goog.userAgent.determinePlatform_=function(){var a=goog.userAgent.getNavigatorTyped();return a&&a.platform||""};goog.userAgent.PLATFORM=goog.userAgent.determinePlatform_();goog.userAgent.ASSUME_MAC=!1;goog.userAgent.ASSUME_WINDOWS=!1;goog.userAgent.ASSUME_LINUX=!1;goog.userAgent.ASSUME_X11=!1;goog.userAgent.ASSUME_ANDROID=!1;goog.userAgent.ASSUME_IPHONE=!1;
goog.userAgent.ASSUME_IPAD=!1;goog.userAgent.ASSUME_IPOD=!1;goog.userAgent.ASSUME_KAIOS=!1;goog.userAgent.ASSUME_GO2PHONE=!1;goog.userAgent.PLATFORM_KNOWN_=goog.userAgent.ASSUME_MAC||goog.userAgent.ASSUME_WINDOWS||goog.userAgent.ASSUME_LINUX||goog.userAgent.ASSUME_X11||goog.userAgent.ASSUME_ANDROID||goog.userAgent.ASSUME_IPHONE||goog.userAgent.ASSUME_IPAD||goog.userAgent.ASSUME_IPOD;goog.userAgent.MAC=goog.userAgent.PLATFORM_KNOWN_?goog.userAgent.ASSUME_MAC:goog.labs.userAgent.platform.isMacintosh();
goog.userAgent.WINDOWS=goog.userAgent.PLATFORM_KNOWN_?goog.userAgent.ASSUME_WINDOWS:goog.labs.userAgent.platform.isWindows();goog.userAgent.isLegacyLinux_=function(){return goog.labs.userAgent.platform.isLinux()||goog.labs.userAgent.platform.isChromeOS()};goog.userAgent.LINUX=goog.userAgent.PLATFORM_KNOWN_?goog.userAgent.ASSUME_LINUX:goog.userAgent.isLegacyLinux_();goog.userAgent.isX11_=function(){var a=goog.userAgent.getNavigatorTyped();return!!a&&goog.string.contains(a.appVersion||"","X11")};
goog.userAgent.X11=goog.userAgent.PLATFORM_KNOWN_?goog.userAgent.ASSUME_X11:goog.userAgent.isX11_();goog.userAgent.ANDROID=goog.userAgent.PLATFORM_KNOWN_?goog.userAgent.ASSUME_ANDROID:goog.labs.userAgent.platform.isAndroid();goog.userAgent.IPHONE=goog.userAgent.PLATFORM_KNOWN_?goog.userAgent.ASSUME_IPHONE:goog.labs.userAgent.platform.isIphone();goog.userAgent.IPAD=goog.userAgent.PLATFORM_KNOWN_?goog.userAgent.ASSUME_IPAD:goog.labs.userAgent.platform.isIpad();
goog.userAgent.IPOD=goog.userAgent.PLATFORM_KNOWN_?goog.userAgent.ASSUME_IPOD:goog.labs.userAgent.platform.isIpod();goog.userAgent.IOS=goog.userAgent.PLATFORM_KNOWN_?goog.userAgent.ASSUME_IPHONE||goog.userAgent.ASSUME_IPAD||goog.userAgent.ASSUME_IPOD:goog.labs.userAgent.platform.isIos();goog.userAgent.KAIOS=goog.userAgent.PLATFORM_KNOWN_?goog.userAgent.ASSUME_KAIOS:goog.labs.userAgent.platform.isKaiOS();goog.userAgent.GO2PHONE=goog.userAgent.PLATFORM_KNOWN_?goog.userAgent.ASSUME_GO2PHONE:goog.labs.userAgent.platform.isGo2Phone();
goog.userAgent.determineVersion_=function(){var a="",b=goog.userAgent.getVersionRegexResult_();b&&(a=b?b[1]:"");return goog.userAgent.IE&&(b=goog.userAgent.getDocumentMode_(),null!=b&&b>parseFloat(a))?String(b):a};
goog.userAgent.getVersionRegexResult_=function(){var a=goog.userAgent.getUserAgentString();if(goog.userAgent.GECKO)return/rv:([^\);]+)(\)|;)/.exec(a);if(goog.userAgent.EDGE)return/Edge\/([\d\.]+)/.exec(a);if(goog.userAgent.IE)return/\b(?:MSIE|rv)[: ]([^\);]+)(\)|;)/.exec(a);if(goog.userAgent.WEBKIT)return/WebKit\/(\S+)/.exec(a);if(goog.userAgent.OPERA)return/(?:Version)[ \/]?(\S+)/.exec(a)};goog.userAgent.getDocumentMode_=function(){var a=goog.global.document;return a?a.documentMode:void 0};
goog.userAgent.VERSION=goog.userAgent.determineVersion_();goog.userAgent.compare=function(a,b){return goog.string.compareVersions(a,b)};goog.userAgent.isVersionOrHigherCache_={};goog.userAgent.isVersionOrHigher=function(a){return goog.userAgent.ASSUME_ANY_VERSION||goog.reflect.cache(goog.userAgent.isVersionOrHigherCache_,a,function(){return 0<=goog.string.compareVersions(goog.userAgent.VERSION,a)})};goog.userAgent.isVersion=goog.userAgent.isVersionOrHigher;
goog.userAgent.isDocumentModeOrHigher=function(a){return Number(goog.userAgent.DOCUMENT_MODE)>=a};goog.userAgent.isDocumentMode=goog.userAgent.isDocumentModeOrHigher;goog.userAgent.DOCUMENT_MODE=function(){var a=goog.global.document,b=goog.userAgent.getDocumentMode_();if(a&&goog.userAgent.IE)return b||("CSS1Compat"==a.compatMode?parseInt(goog.userAgent.VERSION,10):5)}();goog.debug.LOGGING_ENABLED=goog.DEBUG;goog.debug.FORCE_SLOPPY_STACKS=!1;goog.debug.catchErrors=function(a,b,c){c=c||goog.global;var d=c.onerror,e=!!b;goog.userAgent.WEBKIT&&!goog.userAgent.isVersionOrHigher("535.3")&&(e=!e);c.onerror=function(b,c,h,k,l){d&&d(b,c,h,k,l);a({message:b,fileName:c,line:h,lineNumber:h,col:k,error:l});return e}};
goog.debug.expose=function(a,b){if("undefined"==typeof a)return"undefined";if(null==a)return"NULL";var c=[],d;for(d in a)if(b||!goog.isFunction(a[d])){var e=d+" = ";try{e+=a[d]}catch(f){e+="*** "+f+" ***"}c.push(e)}return c.join("\n")};
goog.debug.deepExpose=function(a,b){var c=[],d=[],e={},f=function(a,h){var g=h+"  ";try{if(goog.isDef(a))if(goog.isNull(a))c.push("NULL");else if(goog.isString(a))c.push('"'+a.replace(/\n/g,"\n"+h)+'"');else if(goog.isFunction(a))c.push(String(a).replace(/\n/g,"\n"+h));else if(goog.isObject(a)){goog.hasUid(a)||d.push(a);var l=goog.getUid(a);if(e[l])c.push("*** reference loop detected (id="+l+") ***");else{e[l]=!0;c.push("{");for(var m in a)if(b||!goog.isFunction(a[m]))c.push("\n"),c.push(g),c.push(m+
" = "),f(a[m],g);c.push("\n"+h+"}");delete e[l]}}else c.push(a);else c.push("undefined")}catch(n){c.push("*** "+n+" ***")}};f(a,"");for(a=0;a<d.length;a++)goog.removeUid(d[a]);return c.join("")};goog.debug.exposeArray=function(a){for(var b=[],c=0;c<a.length;c++)goog.isArray(a[c])?b.push(goog.debug.exposeArray(a[c])):b.push(a[c]);return"[ "+b.join(", ")+" ]"};
goog.debug.normalizeErrorObject=function(a){var b=goog.getObjectByName("window.location.href");null==a&&(a='Unknown Error of type "null/undefined"');if(goog.isString(a))return{message:a,name:"Unknown error",lineNumber:"Not available",fileName:b,stack:"Not available"};var c=!1;try{var d=a.lineNumber||a.line||"Not available"}catch(f){d="Not available",c=!0}try{var e=a.fileName||a.filename||a.sourceURL||goog.global.$googDebugFname||b}catch(f){e="Not available",c=!0}return!c&&a.lineNumber&&a.fileName&&
a.stack&&a.message&&a.name?a:(b=a.message,null==b&&(b=a.constructor&&a.constructor instanceof Function?'Unknown Error of type "'+(a.constructor.name?a.constructor.name:goog.debug.getFunctionName(a.constructor))+'"':"Unknown Error of unknown type"),{message:b,name:a.name||"UnknownError",lineNumber:d,fileName:e,stack:a.stack||"Not available"})};
goog.debug.enhanceError=function(a,b){a instanceof Error||(a=Error(a),Error.captureStackTrace&&Error.captureStackTrace(a,goog.debug.enhanceError));a.stack||(a.stack=goog.debug.getStacktrace(goog.debug.enhanceError));if(b){for(var c=0;a["message"+c];)++c;a["message"+c]=String(b)}return a};goog.debug.enhanceErrorWithContext=function(a,b){a=goog.debug.enhanceError(a);if(b)for(var c in b)goog.debug.errorcontext.addErrorContext(a,c,b[c]);return a};
goog.debug.getStacktraceSimple=function(a){if(!goog.debug.FORCE_SLOPPY_STACKS){var b=goog.debug.getNativeStackTrace_(goog.debug.getStacktraceSimple);if(b)return b}b=[];for(var c=arguments.callee.caller,d=0;c&&(!a||d<a);){b.push(goog.debug.getFunctionName(c));b.push("()\n");try{c=c.caller}catch(e){b.push("[exception trying to get caller]\n");break}d++;if(d>=goog.debug.MAX_STACK_DEPTH){b.push("[...long stack...]");break}}a&&d>=a?b.push("[...reached max depth limit...]"):b.push("[end]");return b.join("")};
goog.debug.MAX_STACK_DEPTH=50;goog.debug.getNativeStackTrace_=function(a){var b=Error();if(Error.captureStackTrace)return Error.captureStackTrace(b,a),String(b.stack);try{throw b;}catch(c){b=c}return(a=b.stack)?String(a):null};goog.debug.getStacktrace=function(a){var b;goog.debug.FORCE_SLOPPY_STACKS||(b=goog.debug.getNativeStackTrace_(a||goog.debug.getStacktrace));b||(b=goog.debug.getStacktraceHelper_(a||arguments.callee.caller,[]));return b};
goog.debug.getStacktraceHelper_=function(a,b){var c=[];if(goog.array.contains(b,a))c.push("[...circular reference...]");else if(a&&b.length<goog.debug.MAX_STACK_DEPTH){c.push(goog.debug.getFunctionName(a)+"(");for(var d=a.arguments,e=0;d&&e<d.length;e++){0<e&&c.push(", ");var f=d[e];switch(typeof f){case "object":f=f?"object":"null";break;case "string":break;case "number":f=String(f);break;case "boolean":f=f?"true":"false";break;case "function":f=(f=goog.debug.getFunctionName(f))?f:"[fn]";break;default:f=
typeof f}40<f.length&&(f=f.substr(0,40)+"...");c.push(f)}b.push(a);c.push(")\n");try{c.push(goog.debug.getStacktraceHelper_(a.caller,b))}catch(g){c.push("[exception trying to get caller]\n")}}else a?c.push("[...long stack...]"):c.push("[end]");return c.join("")};goog.debug.getFunctionName=function(a){if(goog.debug.fnNameCache_[a])return goog.debug.fnNameCache_[a];a=String(a);if(!goog.debug.fnNameCache_[a]){var b=/function\s+([^\(]+)/m.exec(a);goog.debug.fnNameCache_[a]=b?b[1]:"[Anonymous]"}return goog.debug.fnNameCache_[a]};
goog.debug.makeWhitespaceVisible=function(a){return a.replace(/ /g,"[_]").replace(/\f/g,"[f]").replace(/\n/g,"[n]\n").replace(/\r/g,"[r]").replace(/\t/g,"[t]")};goog.debug.runtimeType=function(a){return a instanceof Function?a.displayName||a.name||"unknown type name":a instanceof Object?a.constructor.displayName||a.constructor.name||Object.prototype.toString.call(a):null===a?"null":typeof a};goog.debug.fnNameCache_={};goog.debug.freezeInternal_=goog.DEBUG&&Object.freeze||function(a){return a};
goog.debug.freeze=function(a){return goog.debug.freezeInternal_(a)};goog.events={};$jscomp.scope.purify=function(a){return a()};
goog.events.BrowserFeature={HAS_W3C_BUTTON:!goog.userAgent.IE||goog.userAgent.isDocumentModeOrHigher(9),HAS_W3C_EVENT_SUPPORT:!goog.userAgent.IE||goog.userAgent.isDocumentModeOrHigher(9),SET_KEY_CODE_TO_PREVENT_DEFAULT:goog.userAgent.IE&&!goog.userAgent.isVersionOrHigher("9"),HAS_NAVIGATOR_ONLINE_PROPERTY:!goog.userAgent.WEBKIT||goog.userAgent.isVersionOrHigher("528"),HAS_HTML5_NETWORK_EVENT_SUPPORT:goog.userAgent.GECKO&&goog.userAgent.isVersionOrHigher("1.9b")||goog.userAgent.IE&&goog.userAgent.isVersionOrHigher("8")||
goog.userAgent.OPERA&&goog.userAgent.isVersionOrHigher("9.5")||goog.userAgent.WEBKIT&&goog.userAgent.isVersionOrHigher("528"),HTML5_NETWORK_EVENTS_FIRE_ON_BODY:goog.userAgent.GECKO&&!goog.userAgent.isVersionOrHigher("8")||goog.userAgent.IE&&!goog.userAgent.isVersionOrHigher("9"),TOUCH_ENABLED:"ontouchstart"in goog.global||!!(goog.global.document&&document.documentElement&&"ontouchstart"in document.documentElement)||!(!goog.global.navigator||!goog.global.navigator.maxTouchPoints&&!goog.global.navigator.msMaxTouchPoints),
POINTER_EVENTS:"PointerEvent"in goog.global,MSPOINTER_EVENTS:"MSPointerEvent"in goog.global&&!(!goog.global.navigator||!goog.global.navigator.msPointerEnabled),PASSIVE_EVENTS:(0,$jscomp.scope.purify)(function(){if(!goog.global.addEventListener||!Object.defineProperty)return!1;var a=!1,b=Object.defineProperty({},"passive",{get:function(){a=!0}});try{goog.global.addEventListener("test",goog.nullFunction,b),goog.global.removeEventListener("test",goog.nullFunction,b)}catch(c){}return a})};goog.disposable={};goog.disposable.IDisposable=function(){};goog.disposable.IDisposable.prototype.dispose=goog.abstractMethod;goog.disposable.IDisposable.prototype.isDisposed=goog.abstractMethod;goog.Disposable=function(){goog.Disposable.MONITORING_MODE!=goog.Disposable.MonitoringMode.OFF&&(goog.Disposable.INCLUDE_STACK_ON_CREATION&&(this.creationStack=Error().stack),goog.Disposable.instances_[goog.getUid(this)]=this);this.disposed_=this.disposed_;this.onDisposeCallbacks_=this.onDisposeCallbacks_};goog.Disposable.MonitoringMode={OFF:0,PERMANENT:1,INTERACTIVE:2};goog.Disposable.MONITORING_MODE=0;goog.Disposable.INCLUDE_STACK_ON_CREATION=!0;goog.Disposable.instances_={};
goog.Disposable.getUndisposedObjects=function(){var a=[],b;for(b in goog.Disposable.instances_)goog.Disposable.instances_.hasOwnProperty(b)&&a.push(goog.Disposable.instances_[Number(b)]);return a};goog.Disposable.clearUndisposedObjects=function(){goog.Disposable.instances_={}};goog.Disposable.prototype.disposed_=!1;goog.Disposable.prototype.isDisposed=function(){return this.disposed_};goog.Disposable.prototype.getDisposed=goog.Disposable.prototype.isDisposed;
goog.Disposable.prototype.dispose=function(){if(!this.disposed_&&(this.disposed_=!0,this.disposeInternal(),goog.Disposable.MONITORING_MODE!=goog.Disposable.MonitoringMode.OFF)){var a=goog.getUid(this);if(goog.Disposable.MONITORING_MODE==goog.Disposable.MonitoringMode.PERMANENT&&!goog.Disposable.instances_.hasOwnProperty(a))throw Error(this+" did not call the goog.Disposable base constructor or was disposed of after a clearUndisposedObjects call");if(goog.Disposable.MONITORING_MODE!=goog.Disposable.MonitoringMode.OFF&&
this.onDisposeCallbacks_&&0<this.onDisposeCallbacks_.length)throw Error(this+" did not empty its onDisposeCallbacks queue. This probably means it overrode dispose() or disposeInternal() without calling the superclass' method.");delete goog.Disposable.instances_[a]}};goog.Disposable.prototype.registerDisposable=function(a){this.addOnDisposeCallback(goog.partial(goog.dispose,a))};
goog.Disposable.prototype.addOnDisposeCallback=function(a,b){this.disposed_?goog.isDef(b)?a.call(b):a():(this.onDisposeCallbacks_||(this.onDisposeCallbacks_=[]),this.onDisposeCallbacks_.push(goog.isDef(b)?goog.bind(a,b):a))};goog.Disposable.prototype.disposeInternal=function(){if(this.onDisposeCallbacks_)for(;this.onDisposeCallbacks_.length;)this.onDisposeCallbacks_.shift()()};goog.Disposable.isDisposed=function(a){return a&&"function"==typeof a.isDisposed?a.isDisposed():!1};
goog.dispose=function(a){a&&"function"==typeof a.dispose&&a.dispose()};goog.disposeAll=function(a){for(var b=0,c=arguments.length;b<c;++b){var d=arguments[b];goog.isArrayLike(d)?goog.disposeAll.apply(null,d):goog.dispose(d)}};goog.events.EventId=function(a){this.id=a};goog.events.EventId.prototype.toString=function(){return this.id};goog.events.Event=function(a,b){this.type=a instanceof goog.events.EventId?String(a):a;this.currentTarget=this.target=b;this.defaultPrevented=this.propagationStopped_=!1;this.returnValue_=!0};goog.events.Event.prototype.stopPropagation=function(){this.propagationStopped_=!0};goog.events.Event.prototype.preventDefault=function(){this.defaultPrevented=!0;this.returnValue_=!1};goog.events.Event.stopPropagation=function(a){a.stopPropagation()};goog.events.Event.preventDefault=function(a){a.preventDefault()};goog.events.getVendorPrefixedName_=function(a){return goog.userAgent.WEBKIT?"webkit"+a:goog.userAgent.OPERA?"o"+a.toLowerCase():a.toLowerCase()};
goog.events.EventType={CLICK:"click",RIGHTCLICK:"rightclick",DBLCLICK:"dblclick",AUXCLICK:"auxclick",MOUSEDOWN:"mousedown",MOUSEUP:"mouseup",MOUSEOVER:"mouseover",MOUSEOUT:"mouseout",MOUSEMOVE:"mousemove",MOUSEENTER:"mouseenter",MOUSELEAVE:"mouseleave",MOUSECANCEL:"mousecancel",SELECTIONCHANGE:"selectionchange",SELECTSTART:"selectstart",WHEEL:"wheel",KEYPRESS:"keypress",KEYDOWN:"keydown",KEYUP:"keyup",BLUR:"blur",FOCUS:"focus",DEACTIVATE:"deactivate",FOCUSIN:"focusin",FOCUSOUT:"focusout",CHANGE:"change",
RESET:"reset",SELECT:"select",SUBMIT:"submit",INPUT:"input",PROPERTYCHANGE:"propertychange",DRAGSTART:"dragstart",DRAG:"drag",DRAGENTER:"dragenter",DRAGOVER:"dragover",DRAGLEAVE:"dragleave",DROP:"drop",DRAGEND:"dragend",TOUCHSTART:"touchstart",TOUCHMOVE:"touchmove",TOUCHEND:"touchend",TOUCHCANCEL:"touchcancel",BEFOREUNLOAD:"beforeunload",CONSOLEMESSAGE:"consolemessage",CONTEXTMENU:"contextmenu",DEVICECHANGE:"devicechange",DEVICEMOTION:"devicemotion",DEVICEORIENTATION:"deviceorientation",DOMCONTENTLOADED:"DOMContentLoaded",
ERROR:"error",HELP:"help",LOAD:"load",LOSECAPTURE:"losecapture",ORIENTATIONCHANGE:"orientationchange",READYSTATECHANGE:"readystatechange",RESIZE:"resize",SCROLL:"scroll",UNLOAD:"unload",CANPLAY:"canplay",CANPLAYTHROUGH:"canplaythrough",DURATIONCHANGE:"durationchange",EMPTIED:"emptied",ENDED:"ended",LOADEDDATA:"loadeddata",LOADEDMETADATA:"loadedmetadata",PAUSE:"pause",PLAY:"play",PLAYING:"playing",RATECHANGE:"ratechange",SEEKED:"seeked",SEEKING:"seeking",STALLED:"stalled",SUSPEND:"suspend",TIMEUPDATE:"timeupdate",
VOLUMECHANGE:"volumechange",WAITING:"waiting",SOURCEOPEN:"sourceopen",SOURCEENDED:"sourceended",SOURCECLOSED:"sourceclosed",ABORT:"abort",UPDATE:"update",UPDATESTART:"updatestart",UPDATEEND:"updateend",HASHCHANGE:"hashchange",PAGEHIDE:"pagehide",PAGESHOW:"pageshow",POPSTATE:"popstate",COPY:"copy",PASTE:"paste",CUT:"cut",BEFORECOPY:"beforecopy",BEFORECUT:"beforecut",BEFOREPASTE:"beforepaste",ONLINE:"online",OFFLINE:"offline",MESSAGE:"message",CONNECT:"connect",INSTALL:"install",ACTIVATE:"activate",
FETCH:"fetch",FOREIGNFETCH:"foreignfetch",MESSAGEERROR:"messageerror",STATECHANGE:"statechange",UPDATEFOUND:"updatefound",CONTROLLERCHANGE:"controllerchange",ANIMATIONSTART:goog.events.getVendorPrefixedName_("AnimationStart"),ANIMATIONEND:goog.events.getVendorPrefixedName_("AnimationEnd"),ANIMATIONITERATION:goog.events.getVendorPrefixedName_("AnimationIteration"),TRANSITIONEND:goog.events.getVendorPrefixedName_("TransitionEnd"),POINTERDOWN:"pointerdown",POINTERUP:"pointerup",POINTERCANCEL:"pointercancel",
POINTERMOVE:"pointermove",POINTEROVER:"pointerover",POINTEROUT:"pointerout",POINTERENTER:"pointerenter",POINTERLEAVE:"pointerleave",GOTPOINTERCAPTURE:"gotpointercapture",LOSTPOINTERCAPTURE:"lostpointercapture",MSGESTURECHANGE:"MSGestureChange",MSGESTUREEND:"MSGestureEnd",MSGESTUREHOLD:"MSGestureHold",MSGESTURESTART:"MSGestureStart",MSGESTURETAP:"MSGestureTap",MSGOTPOINTERCAPTURE:"MSGotPointerCapture",MSINERTIASTART:"MSInertiaStart",MSLOSTPOINTERCAPTURE:"MSLostPointerCapture",MSPOINTERCANCEL:"MSPointerCancel",
MSPOINTERDOWN:"MSPointerDown",MSPOINTERENTER:"MSPointerEnter",MSPOINTERHOVER:"MSPointerHover",MSPOINTERLEAVE:"MSPointerLeave",MSPOINTERMOVE:"MSPointerMove",MSPOINTEROUT:"MSPointerOut",MSPOINTEROVER:"MSPointerOver",MSPOINTERUP:"MSPointerUp",TEXT:"text",TEXTINPUT:goog.userAgent.IE?"textinput":"textInput",COMPOSITIONSTART:"compositionstart",COMPOSITIONUPDATE:"compositionupdate",COMPOSITIONEND:"compositionend",BEFOREINPUT:"beforeinput",EXIT:"exit",LOADABORT:"loadabort",LOADCOMMIT:"loadcommit",LOADREDIRECT:"loadredirect",
LOADSTART:"loadstart",LOADSTOP:"loadstop",RESPONSIVE:"responsive",SIZECHANGED:"sizechanged",UNRESPONSIVE:"unresponsive",VISIBILITYCHANGE:"visibilitychange",STORAGE:"storage",DOMSUBTREEMODIFIED:"DOMSubtreeModified",DOMNODEINSERTED:"DOMNodeInserted",DOMNODEREMOVED:"DOMNodeRemoved",DOMNODEREMOVEDFROMDOCUMENT:"DOMNodeRemovedFromDocument",DOMNODEINSERTEDINTODOCUMENT:"DOMNodeInsertedIntoDocument",DOMATTRMODIFIED:"DOMAttrModified",DOMCHARACTERDATAMODIFIED:"DOMCharacterDataModified",BEFOREPRINT:"beforeprint",
AFTERPRINT:"afterprint",BEFOREINSTALLPROMPT:"beforeinstallprompt",APPINSTALLED:"appinstalled"};goog.events.getPointerFallbackEventName_=function(a,b,c){return goog.events.BrowserFeature.POINTER_EVENTS?a:goog.events.BrowserFeature.MSPOINTER_EVENTS?b:c};
goog.events.PointerFallbackEventType={POINTERDOWN:goog.events.getPointerFallbackEventName_(goog.events.EventType.POINTERDOWN,goog.events.EventType.MSPOINTERDOWN,goog.events.EventType.MOUSEDOWN),POINTERUP:goog.events.getPointerFallbackEventName_(goog.events.EventType.POINTERUP,goog.events.EventType.MSPOINTERUP,goog.events.EventType.MOUSEUP),POINTERCANCEL:goog.events.getPointerFallbackEventName_(goog.events.EventType.POINTERCANCEL,goog.events.EventType.MSPOINTERCANCEL,goog.events.EventType.MOUSECANCEL),
POINTERMOVE:goog.events.getPointerFallbackEventName_(goog.events.EventType.POINTERMOVE,goog.events.EventType.MSPOINTERMOVE,goog.events.EventType.MOUSEMOVE),POINTEROVER:goog.events.getPointerFallbackEventName_(goog.events.EventType.POINTEROVER,goog.events.EventType.MSPOINTEROVER,goog.events.EventType.MOUSEOVER),POINTEROUT:goog.events.getPointerFallbackEventName_(goog.events.EventType.POINTEROUT,goog.events.EventType.MSPOINTEROUT,goog.events.EventType.MOUSEOUT),POINTERENTER:goog.events.getPointerFallbackEventName_(goog.events.EventType.POINTERENTER,
goog.events.EventType.MSPOINTERENTER,goog.events.EventType.MOUSEENTER),POINTERLEAVE:goog.events.getPointerFallbackEventName_(goog.events.EventType.POINTERLEAVE,goog.events.EventType.MSPOINTERLEAVE,goog.events.EventType.MOUSELEAVE)};
goog.events.PointerTouchFallbackEventType={POINTERDOWN:goog.events.getPointerFallbackEventName_(goog.events.EventType.POINTERDOWN,goog.events.EventType.MSPOINTERDOWN,goog.events.EventType.TOUCHSTART),POINTERUP:goog.events.getPointerFallbackEventName_(goog.events.EventType.POINTERUP,goog.events.EventType.MSPOINTERUP,goog.events.EventType.TOUCHEND),POINTERCANCEL:goog.events.getPointerFallbackEventName_(goog.events.EventType.POINTERCANCEL,goog.events.EventType.MSPOINTERCANCEL,goog.events.EventType.TOUCHCANCEL),
POINTERMOVE:goog.events.getPointerFallbackEventName_(goog.events.EventType.POINTERMOVE,goog.events.EventType.MSPOINTERMOVE,goog.events.EventType.TOUCHMOVE)};
goog.events.PointerAsMouseEventType={MOUSEDOWN:goog.events.PointerFallbackEventType.POINTERDOWN,MOUSEUP:goog.events.PointerFallbackEventType.POINTERUP,MOUSECANCEL:goog.events.PointerFallbackEventType.POINTERCANCEL,MOUSEMOVE:goog.events.PointerFallbackEventType.POINTERMOVE,MOUSEOVER:goog.events.PointerFallbackEventType.POINTEROVER,MOUSEOUT:goog.events.PointerFallbackEventType.POINTEROUT,MOUSEENTER:goog.events.PointerFallbackEventType.POINTERENTER,MOUSELEAVE:goog.events.PointerFallbackEventType.POINTERLEAVE};
goog.events.MouseAsMouseEventType={MOUSEDOWN:goog.events.EventType.MOUSEDOWN,MOUSEUP:goog.events.EventType.MOUSEUP,MOUSECANCEL:goog.events.EventType.MOUSECANCEL,MOUSEMOVE:goog.events.EventType.MOUSEMOVE,MOUSEOVER:goog.events.EventType.MOUSEOVER,MOUSEOUT:goog.events.EventType.MOUSEOUT,MOUSEENTER:goog.events.EventType.MOUSEENTER,MOUSELEAVE:goog.events.EventType.MOUSELEAVE};
goog.events.PointerAsTouchEventType={TOUCHCANCEL:goog.events.PointerTouchFallbackEventType.POINTERCANCEL,TOUCHEND:goog.events.PointerTouchFallbackEventType.POINTERUP,TOUCHMOVE:goog.events.PointerTouchFallbackEventType.POINTERMOVE,TOUCHSTART:goog.events.PointerTouchFallbackEventType.POINTERDOWN};goog.events.USE_LAYER_XY_AS_OFFSET_XY=!1;goog.events.BrowserEvent=function(a,b){goog.events.Event.call(this,a?a.type:"");this.relatedTarget=this.currentTarget=this.target=null;this.button=this.screenY=this.screenX=this.clientY=this.clientX=this.offsetY=this.offsetX=0;this.key="";this.charCode=this.keyCode=0;this.metaKey=this.shiftKey=this.altKey=this.ctrlKey=!1;this.state=null;this.platformModifierKey=!1;this.pointerId=0;this.pointerType="";this.event_=null;a&&this.init(a,b)};
goog.inherits(goog.events.BrowserEvent,goog.events.Event);goog.events.BrowserEvent.MouseButton={LEFT:0,MIDDLE:1,RIGHT:2};goog.events.BrowserEvent.PointerType={MOUSE:"mouse",PEN:"pen",TOUCH:"touch"};goog.events.BrowserEvent.IEButtonMap=goog.debug.freeze([1,4,2]);goog.events.BrowserEvent.IE_BUTTON_MAP=goog.events.BrowserEvent.IEButtonMap;goog.events.BrowserEvent.IE_POINTER_TYPE_MAP=goog.debug.freeze({2:goog.events.BrowserEvent.PointerType.TOUCH,3:goog.events.BrowserEvent.PointerType.PEN,4:goog.events.BrowserEvent.PointerType.MOUSE});
goog.events.BrowserEvent.prototype.init=function(a,b){var c=this.type=a.type,d=a.changedTouches&&a.changedTouches.length?a.changedTouches[0]:null;this.target=a.target||a.srcElement;this.currentTarget=b;(b=a.relatedTarget)?goog.userAgent.GECKO&&(goog.reflect.canAccessProperty(b,"nodeName")||(b=null)):c==goog.events.EventType.MOUSEOVER?b=a.fromElement:c==goog.events.EventType.MOUSEOUT&&(b=a.toElement);this.relatedTarget=b;d?(this.clientX=void 0!==d.clientX?d.clientX:d.pageX,this.clientY=void 0!==d.clientY?
d.clientY:d.pageY,this.screenX=d.screenX||0,this.screenY=d.screenY||0):(goog.events.USE_LAYER_XY_AS_OFFSET_XY?(this.offsetX=void 0!==a.layerX?a.layerX:a.offsetX,this.offsetY=void 0!==a.layerY?a.layerY:a.offsetY):(this.offsetX=goog.userAgent.WEBKIT||void 0!==a.offsetX?a.offsetX:a.layerX,this.offsetY=goog.userAgent.WEBKIT||void 0!==a.offsetY?a.offsetY:a.layerY),this.clientX=void 0!==a.clientX?a.clientX:a.pageX,this.clientY=void 0!==a.clientY?a.clientY:a.pageY,this.screenX=a.screenX||0,this.screenY=
a.screenY||0);this.button=a.button;this.keyCode=a.keyCode||0;this.key=a.key||"";this.charCode=a.charCode||("keypress"==c?a.keyCode:0);this.ctrlKey=a.ctrlKey;this.altKey=a.altKey;this.shiftKey=a.shiftKey;this.metaKey=a.metaKey;this.platformModifierKey=goog.userAgent.MAC?a.metaKey:a.ctrlKey;this.pointerId=a.pointerId||0;this.pointerType=goog.events.BrowserEvent.getPointerType_(a);this.state=a.state;this.event_=a;a.defaultPrevented&&this.preventDefault()};
goog.events.BrowserEvent.prototype.isButton=function(a){return goog.events.BrowserFeature.HAS_W3C_BUTTON?this.event_.button==a:"click"==this.type?a==goog.events.BrowserEvent.MouseButton.LEFT:!!(this.event_.button&goog.events.BrowserEvent.IE_BUTTON_MAP[a])};goog.events.BrowserEvent.prototype.isMouseActionButton=function(){return this.isButton(goog.events.BrowserEvent.MouseButton.LEFT)&&!(goog.userAgent.WEBKIT&&goog.userAgent.MAC&&this.ctrlKey)};
goog.events.BrowserEvent.prototype.stopPropagation=function(){goog.events.BrowserEvent.superClass_.stopPropagation.call(this);this.event_.stopPropagation?this.event_.stopPropagation():this.event_.cancelBubble=!0};
goog.events.BrowserEvent.prototype.preventDefault=function(){goog.events.BrowserEvent.superClass_.preventDefault.call(this);var a=this.event_;if(a.preventDefault)a.preventDefault();else if(a.returnValue=!1,goog.events.BrowserFeature.SET_KEY_CODE_TO_PREVENT_DEFAULT)try{if(a.ctrlKey||112<=a.keyCode&&123>=a.keyCode)a.keyCode=-1}catch(b){}};goog.events.BrowserEvent.prototype.getBrowserEvent=function(){return this.event_};
goog.events.BrowserEvent.getPointerType_=function(a){return goog.isString(a.pointerType)?a.pointerType:goog.events.BrowserEvent.IE_POINTER_TYPE_MAP[a.pointerType]||""};goog.events.Listenable=function(){};goog.events.Listenable.IMPLEMENTED_BY_PROP="closure_listenable_"+(1E6*Math.random()|0);goog.events.Listenable.addImplementation=function(a){a.prototype[goog.events.Listenable.IMPLEMENTED_BY_PROP]=!0};goog.events.Listenable.isImplementedBy=function(a){return!(!a||!a[goog.events.Listenable.IMPLEMENTED_BY_PROP])};goog.events.ListenableKey=function(){};goog.events.ListenableKey.counter_=0;goog.events.ListenableKey.reserveKey=function(){return++goog.events.ListenableKey.counter_};goog.events.Listener=function(a,b,c,d,e,f){goog.events.Listener.ENABLE_MONITORING&&(this.creationStack=Error().stack);this.listener=a;this.proxy=b;this.src=c;this.type=d;this.capture=!!e;this.handler=f;this.key=goog.events.ListenableKey.reserveKey();this.removed=this.callOnce=!1};goog.events.Listener.ENABLE_MONITORING=!1;goog.events.Listener.prototype.markAsRemoved=function(){this.removed=!0;this.handler=this.src=this.proxy=this.listener=null};goog.events.ListenerMap=function(a){this.src=a;this.listeners={};this.typeCount_=0};goog.events.ListenerMap.prototype.getTypeCount=function(){return this.typeCount_};goog.events.ListenerMap.prototype.getListenerCount=function(){var a=0,b;for(b in this.listeners)a+=this.listeners[b].length;return a};
goog.events.ListenerMap.prototype.add=function(a,b,c,d,e){var f=a.toString();a=this.listeners[f];a||(a=this.listeners[f]=[],this.typeCount_++);var g=goog.events.ListenerMap.findListenerIndex_(a,b,d,e);-1<g?(b=a[g],c||(b.callOnce=!1)):(b=new goog.events.Listener(b,null,this.src,f,!!d,e),b.callOnce=c,a.push(b));return b};
goog.events.ListenerMap.prototype.remove=function(a,b,c,d){a=a.toString();if(!(a in this.listeners))return!1;var e=this.listeners[a];b=goog.events.ListenerMap.findListenerIndex_(e,b,c,d);return-1<b?(e[b].markAsRemoved(),goog.array.removeAt(e,b),0==e.length&&(delete this.listeners[a],this.typeCount_--),!0):!1};
goog.events.ListenerMap.prototype.removeByKey=function(a){var b=a.type;if(!(b in this.listeners))return!1;var c=goog.array.remove(this.listeners[b],a);c&&(a.markAsRemoved(),0==this.listeners[b].length&&(delete this.listeners[b],this.typeCount_--));return c};goog.events.ListenerMap.prototype.removeAll=function(a){a=a&&a.toString();var b=0,c;for(c in this.listeners)if(!a||c==a){for(var d=this.listeners[c],e=0;e<d.length;e++)++b,d[e].markAsRemoved();delete this.listeners[c];this.typeCount_--}return b};
goog.events.ListenerMap.prototype.getListeners=function(a,b){a=this.listeners[a.toString()];var c=[];if(a)for(var d=0;d<a.length;++d){var e=a[d];e.capture==b&&c.push(e)}return c};goog.events.ListenerMap.prototype.getListener=function(a,b,c,d){a=this.listeners[a.toString()];var e=-1;a&&(e=goog.events.ListenerMap.findListenerIndex_(a,b,c,d));return-1<e?a[e]:null};
goog.events.ListenerMap.prototype.hasListener=function(a,b){var c=goog.isDef(a),d=c?a.toString():"",e=goog.isDef(b);return goog.object.some(this.listeners,function(a,g){for(g=0;g<a.length;++g)if(!(c&&a[g].type!=d||e&&a[g].capture!=b))return!0;return!1})};goog.events.ListenerMap.findListenerIndex_=function(a,b,c,d){for(var e=0;e<a.length;++e){var f=a[e];if(!f.removed&&f.listener==b&&f.capture==!!c&&f.handler==d)return e}return-1};goog.events.LISTENER_MAP_PROP_="closure_lm_"+(1E6*Math.random()|0);goog.events.onString_="on";goog.events.onStringMap_={};goog.events.CaptureSimulationMode={OFF_AND_FAIL:0,OFF_AND_SILENT:1,ON:2};goog.events.CAPTURE_SIMULATION_MODE=2;goog.events.listenerCountEstimate_=0;
goog.events.listen=function(a,b,c,d,e){if(d&&d.once)return goog.events.listenOnce(a,b,c,d,e);if(goog.isArray(b)){for(var f=0;f<b.length;f++)goog.events.listen(a,b[f],c,d,e);return null}c=goog.events.wrapListener(c);return goog.events.Listenable.isImplementedBy(a)?(d=goog.isObject(d)?!!d.capture:!!d,a.listen(b,c,d,e)):goog.events.listen_(a,b,c,!1,d,e)};
goog.events.listen_=function(a,b,c,d,e,f){if(!b)throw Error("Invalid event type");var g=goog.isObject(e)?!!e.capture:!!e;if(g&&!goog.events.BrowserFeature.HAS_W3C_EVENT_SUPPORT){if(goog.events.CAPTURE_SIMULATION_MODE==goog.events.CaptureSimulationMode.OFF_AND_FAIL)return goog.asserts.fail("Can not register capture listener in IE8-."),null;if(goog.events.CAPTURE_SIMULATION_MODE==goog.events.CaptureSimulationMode.OFF_AND_SILENT)return null}var h=goog.events.getListenerMap_(a);h||(a[goog.events.LISTENER_MAP_PROP_]=
h=new goog.events.ListenerMap(a));c=h.add(b,c,d,g,f);if(c.proxy)return c;d=goog.events.getProxy();c.proxy=d;d.src=a;d.listener=c;if(a.addEventListener)goog.events.BrowserFeature.PASSIVE_EVENTS||(e=g),void 0===e&&(e=!1),a.addEventListener(b.toString(),d,e);else if(a.attachEvent)a.attachEvent(goog.events.getOnString_(b.toString()),d);else if(a.addListener&&a.removeListener)goog.asserts.assert("change"===b,"MediaQueryList only has a change event"),a.addListener(d);else throw Error("addEventListener and attachEvent are unavailable.");
goog.events.listenerCountEstimate_++;return c};goog.events.getProxy=function(){var a=goog.events.handleBrowserEvent_,b=goog.events.BrowserFeature.HAS_W3C_EVENT_SUPPORT?function(c){return a.call(b.src,b.listener,c)}:function(c){c=a.call(b.src,b.listener,c);if(!c)return c};return b};
goog.events.listenOnce=function(a,b,c,d,e){if(goog.isArray(b)){for(var f=0;f<b.length;f++)goog.events.listenOnce(a,b[f],c,d,e);return null}c=goog.events.wrapListener(c);return goog.events.Listenable.isImplementedBy(a)?(d=goog.isObject(d)?!!d.capture:!!d,a.listenOnce(b,c,d,e)):goog.events.listen_(a,b,c,!0,d,e)};goog.events.listenWithWrapper=function(a,b,c,d,e){b.listen(a,c,d,e)};
goog.events.unlisten=function(a,b,c,d,e){if(goog.isArray(b)){for(var f=0;f<b.length;f++)goog.events.unlisten(a,b[f],c,d,e);return null}d=goog.isObject(d)?!!d.capture:!!d;c=goog.events.wrapListener(c);if(goog.events.Listenable.isImplementedBy(a))return a.unlisten(b,c,d,e);if(!a)return!1;if(a=goog.events.getListenerMap_(a))if(b=a.getListener(b,c,d,e))return goog.events.unlistenByKey(b);return!1};
goog.events.unlistenByKey=function(a){if(goog.isNumber(a)||!a||a.removed)return!1;var b=a.src;if(goog.events.Listenable.isImplementedBy(b))return b.unlistenByKey(a);var c=a.type,d=a.proxy;b.removeEventListener?b.removeEventListener(c,d,a.capture):b.detachEvent?b.detachEvent(goog.events.getOnString_(c),d):b.addListener&&b.removeListener&&b.removeListener(d);goog.events.listenerCountEstimate_--;(c=goog.events.getListenerMap_(b))?(c.removeByKey(a),0==c.getTypeCount()&&(c.src=null,b[goog.events.LISTENER_MAP_PROP_]=
null)):a.markAsRemoved();return!0};goog.events.unlistenWithWrapper=function(a,b,c,d,e){b.unlisten(a,c,d,e)};goog.events.removeAll=function(a,b){if(!a)return 0;if(goog.events.Listenable.isImplementedBy(a))return a.removeAllListeners(b);a=goog.events.getListenerMap_(a);if(!a)return 0;var c=0;b=b&&b.toString();for(var d in a.listeners)if(!b||d==b)for(var e=a.listeners[d].concat(),f=0;f<e.length;++f)goog.events.unlistenByKey(e[f])&&++c;return c};
goog.events.getListeners=function(a,b,c){return goog.events.Listenable.isImplementedBy(a)?a.getListeners(b,c):a?(a=goog.events.getListenerMap_(a))?a.getListeners(b,c):[]:[]};goog.events.getListener=function(a,b,c,d,e){c=goog.events.wrapListener(c);d=!!d;return goog.events.Listenable.isImplementedBy(a)?a.getListener(b,c,d,e):a?(a=goog.events.getListenerMap_(a))?a.getListener(b,c,d,e):null:null};
goog.events.hasListener=function(a,b,c){if(goog.events.Listenable.isImplementedBy(a))return a.hasListener(b,c);a=goog.events.getListenerMap_(a);return!!a&&a.hasListener(b,c)};goog.events.expose=function(a){var b=[],c;for(c in a)a[c]&&a[c].id?b.push(c+" = "+a[c]+" ("+a[c].id+")"):b.push(c+" = "+a[c]);return b.join("\n")};goog.events.getOnString_=function(a){return a in goog.events.onStringMap_?goog.events.onStringMap_[a]:goog.events.onStringMap_[a]=goog.events.onString_+a};
goog.events.fireListeners=function(a,b,c,d){return goog.events.Listenable.isImplementedBy(a)?a.fireListeners(b,c,d):goog.events.fireListeners_(a,b,c,d)};goog.events.fireListeners_=function(a,b,c,d){var e=!0;if(a=goog.events.getListenerMap_(a))if(b=a.listeners[b.toString()])for(b=b.concat(),a=0;a<b.length;a++){var f=b[a];f&&f.capture==c&&!f.removed&&(f=goog.events.fireListener(f,d),e=e&&!1!==f)}return e};
goog.events.fireListener=function(a,b){var c=a.listener,d=a.handler||a.src;a.callOnce&&goog.events.unlistenByKey(a);return c.call(d,b)};goog.events.getTotalListenerCount=function(){return goog.events.listenerCountEstimate_};goog.events.dispatchEvent=function(a,b){goog.asserts.assert(goog.events.Listenable.isImplementedBy(a),"Can not use goog.events.dispatchEvent with non-goog.events.Listenable instance.");return a.dispatchEvent(b)};
goog.events.protectBrowserEventEntryPoint=function(a){goog.events.handleBrowserEvent_=a.protectEntryPoint(goog.events.handleBrowserEvent_)};
goog.events.handleBrowserEvent_=function(a,b){if(a.removed)return!0;if(!goog.events.BrowserFeature.HAS_W3C_EVENT_SUPPORT){var c=b||goog.getObjectByName("window.event");b=new goog.events.BrowserEvent(c,this);var d=!0;if(goog.events.CAPTURE_SIMULATION_MODE==goog.events.CaptureSimulationMode.ON){if(!goog.events.isMarkedIeEvent_(c)){goog.events.markIeEvent_(c);c=[];for(var e=b.currentTarget;e;e=e.parentNode)c.push(e);a=a.type;for(e=c.length-1;!b.propagationStopped_&&0<=e;e--){b.currentTarget=c[e];var f=
goog.events.fireListeners_(c[e],a,!0,b);d=d&&f}for(e=0;!b.propagationStopped_&&e<c.length;e++)b.currentTarget=c[e],f=goog.events.fireListeners_(c[e],a,!1,b),d=d&&f}}else d=goog.events.fireListener(a,b);return d}return goog.events.fireListener(a,new goog.events.BrowserEvent(b,this))};goog.events.markIeEvent_=function(a){var b=!1;if(0==a.keyCode)try{a.keyCode=-1;return}catch(c){b=!0}if(b||void 0==a.returnValue)a.returnValue=!0};goog.events.isMarkedIeEvent_=function(a){return 0>a.keyCode||void 0!=a.returnValue};
goog.events.uniqueIdCounter_=0;goog.events.getUniqueId=function(a){return a+"_"+goog.events.uniqueIdCounter_++};goog.events.getListenerMap_=function(a){a=a[goog.events.LISTENER_MAP_PROP_];return a instanceof goog.events.ListenerMap?a:null};goog.events.LISTENER_WRAPPER_PROP_="__closure_events_fn_"+(1E9*Math.random()>>>0);
goog.events.wrapListener=function(a){goog.asserts.assert(a,"Listener can not be null.");if(goog.isFunction(a))return a;goog.asserts.assert(a.handleEvent,"An object listener must have handleEvent method.");a[goog.events.LISTENER_WRAPPER_PROP_]||(a[goog.events.LISTENER_WRAPPER_PROP_]=function(b){return a.handleEvent(b)});return a[goog.events.LISTENER_WRAPPER_PROP_]};goog.debug.entryPointRegistry.register(function(a){goog.events.handleBrowserEvent_=a(goog.events.handleBrowserEvent_)});
Blockly.Touch={};Blockly.Touch.touchIdentifier_=null;Blockly.Touch.TOUCH_MAP={};goog.events.BrowserFeature.TOUCH_ENABLED&&(Blockly.Touch.TOUCH_MAP={mousedown:["touchstart"],mousemove:["touchmove"],mouseup:["touchend","touchcancel"]});Blockly.longPid_=0;Blockly.longStart_=function(a,b){Blockly.longStop_();1==a.changedTouches.length&&(Blockly.longPid_=setTimeout(function(){a.button=2;a.clientX=a.changedTouches[0].clientX;a.clientY=a.changedTouches[0].clientY;b&&b.handleRightClick(a)},Blockly.LONGPRESS))};
Blockly.longStop_=function(){Blockly.longPid_&&(clearTimeout(Blockly.longPid_),Blockly.longPid_=0)};Blockly.Touch.clearTouchIdentifier=function(){Blockly.Touch.touchIdentifier_=null};Blockly.Touch.shouldHandleEvent=function(a){return!Blockly.Touch.isMouseOrTouchEvent(a)||Blockly.Touch.checkTouchIdentifier(a)};
Blockly.Touch.getTouchIdentifierFromEvent=function(a){return a.changedTouches&&a.changedTouches[0]&&void 0!=a.changedTouches[0].identifier&&null!=a.changedTouches[0].identifier?a.changedTouches[0].identifier:"mouse"};
Blockly.Touch.checkTouchIdentifier=function(a){var b=Blockly.Touch.getTouchIdentifierFromEvent(a);return void 0!=Blockly.Touch.touchIdentifier_&&null!=Blockly.Touch.touchIdentifier_?Blockly.Touch.touchIdentifier_==b:"mousedown"==a.type||"touchstart"==a.type?(Blockly.Touch.touchIdentifier_=b,!0):!1};Blockly.Touch.setClientFromTouch=function(a){if(Blockly.utils.startsWith(a.type,"touch")){var b=a.changedTouches[0];a.clientX=b.clientX;a.clientY=b.clientY}};
Blockly.Touch.isMouseOrTouchEvent=function(a){return Blockly.utils.startsWith(a.type,"touch")||Blockly.utils.startsWith(a.type,"mouse")};Blockly.Touch.splitEventByTouches=function(a){var b=[];if(a.changedTouches)for(var c=0;c<a.changedTouches.length;c++)b[c]={type:a.type,changedTouches:[a.changedTouches[c]],target:a.target,stopPropagation:function(){a.stopPropagation()},preventDefault:function(){a.preventDefault()}};else b.push(a);return b};goog.math={};goog.math.randomInt=function(a){return Math.floor(Math.random()*a)};goog.math.uniformRandom=function(a,b){return a+Math.random()*(b-a)};goog.math.clamp=function(a,b,c){return Math.min(Math.max(a,b),c)};goog.math.modulo=function(a,b){a%=b;return 0>a*b?a+b:a};goog.math.lerp=function(a,b,c){return a+c*(b-a)};goog.math.nearlyEquals=function(a,b,c){return Math.abs(a-b)<=(c||1E-6)};goog.math.standardAngle=function(a){return goog.math.modulo(a,360)};
goog.math.standardAngleInRadians=function(a){return goog.math.modulo(a,2*Math.PI)};goog.math.toRadians=function(a){return a*Math.PI/180};goog.math.toDegrees=function(a){return 180*a/Math.PI};goog.math.angleDx=function(a,b){return b*Math.cos(goog.math.toRadians(a))};goog.math.angleDy=function(a,b){return b*Math.sin(goog.math.toRadians(a))};goog.math.angle=function(a,b,c,d){return goog.math.standardAngle(goog.math.toDegrees(Math.atan2(d-b,c-a)))};
goog.math.angleDifference=function(a,b){a=goog.math.standardAngle(b)-goog.math.standardAngle(a);180<a?a-=360:-180>=a&&(a=360+a);return a};goog.math.sign=function(a){return 0<a?1:0>a?-1:a};
goog.math.longestCommonSubsequence=function(a,b,c,d){c=c||function(a,b){return a==b};d=d||function(b,c){return a[b]};for(var e=a.length,f=b.length,g=[],h=0;h<e+1;h++)g[h]=[],g[h][0]=0;for(var k=0;k<f+1;k++)g[0][k]=0;for(h=1;h<=e;h++)for(k=1;k<=f;k++)c(a[h-1],b[k-1])?g[h][k]=g[h-1][k-1]+1:g[h][k]=Math.max(g[h-1][k],g[h][k-1]);var l=[];h=e;for(k=f;0<h&&0<k;)c(a[h-1],b[k-1])?(l.unshift(d(h-1,k-1)),h--,k--):g[h-1][k]>g[h][k-1]?h--:k--;return l};
goog.math.sum=function(a){return goog.array.reduce(arguments,function(a,c){return a+c},0)};goog.math.average=function(a){return goog.math.sum.apply(null,arguments)/arguments.length};goog.math.sampleVariance=function(a){var b=arguments.length;if(2>b)return 0;var c=goog.math.average.apply(null,arguments);return goog.math.sum.apply(null,goog.array.map(arguments,function(a){return Math.pow(a-c,2)}))/(b-1)};goog.math.standardDeviation=function(a){return Math.sqrt(goog.math.sampleVariance.apply(null,arguments))};
goog.math.isInt=function(a){return isFinite(a)&&0==a%1};goog.math.isFiniteNumber=function(a){return isFinite(a)};goog.math.isNegativeZero=function(a){return 0==a&&0>1/a};goog.math.log10Floor=function(a){if(0<a){var b=Math.round(Math.log(a)*Math.LOG10E);return b-(parseFloat("1e"+b)>a?1:0)}return 0==a?-Infinity:NaN};goog.math.safeFloor=function(a,b){goog.asserts.assert(!goog.isDef(b)||0<b);return Math.floor(a+(b||2E-15))};
goog.math.safeCeil=function(a,b){goog.asserts.assert(!goog.isDef(b)||0<b);return Math.ceil(a-(b||2E-15))};goog.math.Coordinate=function(a,b){this.x=goog.isDef(a)?a:0;this.y=goog.isDef(b)?b:0};goog.math.Coordinate.prototype.clone=function(){return new goog.math.Coordinate(this.x,this.y)};goog.DEBUG&&(goog.math.Coordinate.prototype.toString=function(){return"("+this.x+", "+this.y+")"});goog.math.Coordinate.prototype.equals=function(a){return a instanceof goog.math.Coordinate&&goog.math.Coordinate.equals(this,a)};goog.math.Coordinate.equals=function(a,b){return a==b?!0:a&&b?a.x==b.x&&a.y==b.y:!1};
goog.math.Coordinate.distance=function(a,b){var c=a.x-b.x;a=a.y-b.y;return Math.sqrt(c*c+a*a)};goog.math.Coordinate.magnitude=function(a){return Math.sqrt(a.x*a.x+a.y*a.y)};goog.math.Coordinate.azimuth=function(a){return goog.math.angle(0,0,a.x,a.y)};goog.math.Coordinate.squaredDistance=function(a,b){var c=a.x-b.x;a=a.y-b.y;return c*c+a*a};goog.math.Coordinate.difference=function(a,b){return new goog.math.Coordinate(a.x-b.x,a.y-b.y)};
goog.math.Coordinate.sum=function(a,b){return new goog.math.Coordinate(a.x+b.x,a.y+b.y)};goog.math.Coordinate.prototype.ceil=function(){this.x=Math.ceil(this.x);this.y=Math.ceil(this.y);return this};goog.math.Coordinate.prototype.floor=function(){this.x=Math.floor(this.x);this.y=Math.floor(this.y);return this};goog.math.Coordinate.prototype.round=function(){this.x=Math.round(this.x);this.y=Math.round(this.y);return this};
goog.math.Coordinate.prototype.translate=function(a,b){a instanceof goog.math.Coordinate?(this.x+=a.x,this.y+=a.y):(this.x+=Number(a),goog.isNumber(b)&&(this.y+=b));return this};goog.math.Coordinate.prototype.scale=function(a,b){b=goog.isNumber(b)?b:a;this.x*=a;this.y*=b;return this};goog.math.Coordinate.prototype.rotateRadians=function(a,b){b=b||new goog.math.Coordinate(0,0);var c=this.x,d=this.y,e=Math.cos(a);a=Math.sin(a);this.x=(c-b.x)*e-(d-b.y)*a+b.x;this.y=(c-b.x)*a+(d-b.y)*e+b.y};
goog.math.Coordinate.prototype.rotateDegrees=function(a,b){this.rotateRadians(goog.math.toRadians(a),b)};Blockly.Events={};Blockly.Events.group_="";Blockly.Events.recordUndo=!0;Blockly.Events.disabled_=0;Blockly.Events.CREATE="create";Blockly.Events.BLOCK_CREATE=Blockly.Events.CREATE;Blockly.Events.DELETE="delete";Blockly.Events.BLOCK_DELETE=Blockly.Events.DELETE;Blockly.Events.CHANGE="change";Blockly.Events.BLOCK_CHANGE=Blockly.Events.CHANGE;Blockly.Events.MOVE="move";Blockly.Events.DRAG_OUTSIDE="dragOutside";Blockly.Events.END_DRAG="endDrag";Blockly.Events.UPDATE_TOOLBOX_FINISH="toolBoxFinish";
Blockly.Events.BLOCK_MOVE=Blockly.Events.MOVE;Blockly.Events.VAR_CREATE="var_create";Blockly.Events.VAR_DELETE="var_delete";Blockly.Events.VAR_RENAME="var_rename";Blockly.Events.COMMENT_CREATE="comment_create";Blockly.Events.COMMENT_MOVE="comment_move";Blockly.Events.COMMENT_CHANGE="comment_change";Blockly.Events.COMMENT_DELETE="comment_delete";Blockly.Events.UI="ui";Blockly.Events.FIRE_QUEUE_=[];
Blockly.Events.fire=function(a){Blockly.Events.isEnabled()&&(Blockly.Events.FIRE_QUEUE_.length||setTimeout(Blockly.Events.fireNow_,0),Blockly.Events.FIRE_QUEUE_.push(a))};Blockly.Events.fireNow_=function(){for(var a=Blockly.Events.filter(Blockly.Events.FIRE_QUEUE_,!0),b=Blockly.Events.FIRE_QUEUE_.length=0,c;c=a[b];b++){var d=Blockly.Workspace.getById(c.workspaceId);d&&d.fireChangeListener(c)}};
Blockly.Events.filter=function(a,b){a=goog.array.clone(a);b||a.reverse();for(var c=[],d=Object.create(null),e=0,f;f=a[e];e++)if(!f.isNull()){var g=[f.type,f.blockId,f.workspaceId].join(" "),h=d[g],k=h?h.event:null;h?f.type==Blockly.Events.MOVE&&h.index==e-1?(k.newParentId=f.newParentId,k.newInputName=f.newInputName,k.newCoordinate=f.newCoordinate,h.index=e):f.type==Blockly.Events.CHANGE&&f.element==k.element&&f.name==k.name?k.newValue=f.newValue:f.type!=Blockly.Events.UI||"click"!=f.element||"commentOpen"!=
k.element&&"mutatorOpen"!=k.element&&"warningOpen"!=k.element?(d[g]={event:f,index:1},c.push(f)):k.newValue=f.newValue:(d[g]={event:f,index:e},c.push(f))}a=c.filter(function(a){return!a.isNull()});b||a.reverse();for(e=1;f=a[e];e++)f.type==Blockly.Events.CHANGE&&"mutation"==f.element&&a.unshift(a.splice(e,1)[0]);return a};Blockly.Events.clearPendingUndo=function(){for(var a=0,b;b=Blockly.Events.FIRE_QUEUE_[a];a++)b.recordUndo=!1};Blockly.Events.disable=function(){Blockly.Events.disabled_++};
Blockly.Events.enable=function(){Blockly.Events.disabled_--};Blockly.Events.isEnabled=function(){return 0==Blockly.Events.disabled_};Blockly.Events.getGroup=function(){return Blockly.Events.group_};Blockly.Events.setGroup=function(a){Blockly.Events.group_="boolean"==typeof a?a?Blockly.utils.genUid():"":a};Blockly.Events.getDescendantIds_=function(a){var b=[];a=a.getDescendants(!1);for(var c=0,d;d=a[c];c++)b[c]=d.id;return b};
Blockly.Events.fromJson=function(a,b){switch(a.type){case Blockly.Events.CREATE:var c=new Blockly.Events.Create(null);break;case Blockly.Events.DELETE:c=new Blockly.Events.Delete(null);break;case Blockly.Events.CHANGE:c=new Blockly.Events.Change(null);break;case Blockly.Events.MOVE:c=new Blockly.Events.Move(null);break;case Blockly.Events.VAR_CREATE:c=new Blockly.Events.VarCreate(null);break;case Blockly.Events.VAR_DELETE:c=new Blockly.Events.VarDelete(null);break;case Blockly.Events.VAR_RENAME:c=
new Blockly.Events.VarRename(null);break;case Blockly.Events.COMMENT_CREATE:c=new Blockly.Events.CommentCreate(null);break;case Blockly.Events.COMMENT_CHANGE:c=new Blockly.Events.CommentChange(null);break;case Blockly.Events.COMMENT_MOVE:c=new Blockly.Events.CommentMove(null);break;case Blockly.Events.COMMENT_DELETE:c=new Blockly.Events.CommentDelete(null);break;case Blockly.Events.UI:c=new Blockly.Events.Ui(null);break;case Blockly.Events.DRAG_OUTSIDE:c=new Blockly.Events.DragBlockOutside(null);
break;case Blockly.Events.END_DRAG:c=new Blockly.Events.EndBlockDrag(null,!1);break;case Blockly.Events.UPDATE_TOOLBOX_FINISH:c=new Blockly.Events.UpdateToolboxFinish(null);break;default:throw"Unknown event type.";}c.fromJson(a);c.workspaceId=b.id;return c};
Blockly.Events.disableOrphans=function(a){if(a.type==Blockly.Events.MOVE||a.type==Blockly.Events.CREATE){Blockly.Events.disable();var b=Blockly.Workspace.getById(a.workspaceId);if(a=b.getBlockById(a.blockId))if(a.getParent()&&!a.getParent().disabled){b=a.getDescendants(!1);a=0;for(var c;c=b[a];a++)c.setDisabled(!1)}else if((a.outputConnection||a.previousConnection)&&!b.isDragging()){do a.setDisabled(!0),a=a.getNextBlock();while(a)}Blockly.Events.enable()}};
Blockly.Events.Abstract=function(){this.workspaceId=void 0;this.group=Blockly.Events.group_;this.recordUndo=Blockly.Events.recordUndo};Blockly.Events.Abstract.prototype.toJson=function(){var a={type:this.type};this.group&&(a.group=this.group);return a};Blockly.Events.Abstract.prototype.fromJson=function(a){this.group=a.group};Blockly.Events.Abstract.prototype.isNull=function(){return!1};Blockly.Events.Abstract.prototype.run=function(a){};
Blockly.Events.Abstract.prototype.getEventWorkspace_=function(){var a=Blockly.Workspace.getById(this.workspaceId);if(!a)throw Error("Workspace is null. Event must have been generated from real Blockly events.");return a};Blockly.Events.VarBase=function(a){Blockly.Events.VarBase.superClass_.constructor.call(this);this.varId=a.getId();this.workspaceId=a.workspace.id};goog.inherits(Blockly.Events.VarBase,Blockly.Events.Abstract);Blockly.Events.VarBase.prototype.toJson=function(){var a=Blockly.Events.VarBase.superClass_.toJson.call(this);a.varId=this.varId;return a};Blockly.Events.VarBase.prototype.fromJson=function(a){Blockly.Events.VarBase.superClass_.toJson.call(this);this.varId=a.varId};
Blockly.Events.VarCreate=function(a){a&&(Blockly.Events.VarCreate.superClass_.constructor.call(this,a),this.varType=a.type,this.varName=a.name,this.isLocal=a.isLocal,this.isCloud=a.isCloud)};goog.inherits(Blockly.Events.VarCreate,Blockly.Events.VarBase);Blockly.Events.VarCreate.prototype.type=Blockly.Events.VAR_CREATE;
Blockly.Events.VarCreate.prototype.toJson=function(){var a=Blockly.Events.VarCreate.superClass_.toJson.call(this);a.varType=this.varType;a.varName=this.varName;a.isLocal=this.isLocal;a.isCloud=this.isCloud;return a};Blockly.Events.VarCreate.prototype.fromJson=function(a){Blockly.Events.VarCreate.superClass_.fromJson.call(this,a);this.varType=a.varType;this.varName=a.varName;this.isLocal=a.isLocal;this.isCloud=a.isCloud};
Blockly.Events.VarCreate.prototype.run=function(a){var b=this.getEventWorkspace_();a?b.createVariable(this.varName,this.varType,this.varId,this.isLocal,this.isCloud):b.deleteVariableById(this.varId)};Blockly.Events.VarDelete=function(a){a&&(Blockly.Events.VarDelete.superClass_.constructor.call(this,a),this.varType=a.type,this.varName=a.name,this.isLocal=a.isLocal,this.isCloud=a.isCloud)};goog.inherits(Blockly.Events.VarDelete,Blockly.Events.VarBase);Blockly.Events.VarDelete.prototype.type=Blockly.Events.VAR_DELETE;
Blockly.Events.VarDelete.prototype.toJson=function(){var a=Blockly.Events.VarDelete.superClass_.toJson.call(this);a.varType=this.varType;a.varName=this.varName;a.isLocal=this.isLocal;a.isCloud=this.isCloud;return a};Blockly.Events.VarDelete.prototype.fromJson=function(a){Blockly.Events.VarDelete.superClass_.fromJson.call(this,a);this.varType=a.varType;this.varName=a.varName;this.isLocal=a.isLocal;this.isCloud=a.isCloud};
Blockly.Events.VarDelete.prototype.run=function(a){var b=this.getEventWorkspace_();a?b.deleteVariableById(this.varId):b.createVariable(this.varName,this.varType,this.varId,this.isLocal,this.isCloud)};Blockly.Events.VarRename=function(a,b){a&&(Blockly.Events.VarRename.superClass_.constructor.call(this,a),this.oldName=a.name,this.newName=b)};goog.inherits(Blockly.Events.VarRename,Blockly.Events.VarBase);Blockly.Events.VarRename.prototype.type=Blockly.Events.VAR_RENAME;
Blockly.Events.VarRename.prototype.toJson=function(){var a=Blockly.Events.VarRename.superClass_.toJson.call(this);a.oldName=this.oldName;a.newName=this.newName;return a};Blockly.Events.VarRename.prototype.fromJson=function(a){Blockly.Events.VarRename.superClass_.fromJson.call(this,a);this.oldName=a.oldName;this.newName=a.newName};Blockly.Events.VarRename.prototype.run=function(a){var b=this.getEventWorkspace_();a?b.renameVariableById(this.varId,this.newName):b.renameVariableById(this.varId,this.oldName)};
Blockly.VariableModel=function(a,b,c,d,e,f){this.workspace=a;this.name=b;this.type=c||"";this.id_=d||Blockly.utils.genUid();this.isLocal=e||!1;this.isCloud=f||!1;Blockly.Events.fire(new Blockly.Events.VarCreate(this))};Blockly.VariableModel.prototype.getId=function(){return this.id_};Blockly.VariableModel.compareByName=function(a,b){return Blockly.scratchBlocksUtils.compareStrings(a.name,b.name)};Blockly.VariableMap=function(a){this.variableMap_={};this.workspace=a};Blockly.VariableMap.prototype.clear=function(){this.variableMap_=Object(null)};
Blockly.VariableMap.prototype.renameVariable=function(a,b){var c=this.getVariable(b,a.type),d=this.workspace.getAllBlocks();Blockly.Events.setGroup(!0);try{c?console.warn("Unexpected conflict when attempting to rename variable with name: "+a.name+" and id: "+a.getId()+" to new name: "+b+". A variable with the new name already exists and has id: "+c.getId()):this.renameVariableAndUses_(a,b,d)}finally{Blockly.Events.setGroup(!1)}};
Blockly.VariableMap.prototype.renameVariableById=function(a,b){var c=this.getVariableById(a);if(!c)throw Error("Tried to rename a variable that didn't exist. ID: "+a);this.renameVariable(c,b)};Blockly.VariableMap.prototype.renameVariableAndUses_=function(a,b,c){Blockly.Events.fire(new Blockly.Events.VarRename(a,b));a.name=b;for(b=0;b<c.length;b++)c[b].updateVarName(a)};
Blockly.VariableMap.prototype.renameVariableWithConflict_=function(a,b,c,d){var e=a.type;b!=c.name&&this.renameVariableAndUses_(c,b,d);for(b=0;b<d.length;b++)d[b].renameVarById(a.getId(),c.getId());Blockly.Events.fire(new Blockly.Events.VarDelete(a));a=this.getVariablesOfType(e).indexOf(a);this.variableMap_[e].splice(a,1)};
Blockly.VariableMap.prototype.createVariable=function(a,b,c,d,e){var f=this.getVariable(a,b);if(f)return c&&f.getId()!=c&&console.warn('Variable "'+a+'" is already in use and its id is "'+f.getId()+'" which conflicts with the passed in id, "'+c+'".'),f;if(c&&(f=this.getVariableById(c)))return console.warn('Variable id, "'+c+'", is already in use.'),f;c=c||Blockly.utils.genUid();b=b||"";f=new Blockly.VariableModel(this.workspace,a,b,c,d,e);this.variableMap_[b]?this.variableMap_[b].push(f):this.variableMap_[b]=
[f];return f};Blockly.VariableMap.prototype.deleteVariable=function(a){for(var b=this.variableMap_[a.type],c=0,d;d=b[c];c++)if(d.getId()==a.getId()){b.splice(c,1);Blockly.Events.fire(new Blockly.Events.VarDelete(a));break}};
Blockly.VariableMap.prototype.deleteVariableById=function(a){var b=this.getVariableById(a);if(b){var c=b.name,d=this.getVariableUsesById(a);a=0;for(var e;e=d[a];a++)if(e.type==Blockly.PROCEDURES_DEFINITION_BLOCK_TYPE||"procedures_defreturn"==e.type){a=e.getFieldValue("NAME");c=Blockly.Msg.CANNOT_DELETE_VARIABLE_PROCEDURE.replace("%1",c).replace("%2",a);Blockly.alert(c);return}var f=this;1<d.length?(c=Blockly.Msg.DELETE_VARIABLE_CONFIRMATION.replace("%1",String(d.length)).replace("%2",c),Blockly.confirm(c,
function(a){a&&f.deleteVariableInternal_(b,d)})):f.deleteVariableInternal_(b,d)}else console.warn("Can't delete non-existent variable: "+a)};Blockly.VariableMap.prototype.deleteVariableInternal_=function(a,b){var c=Blockly.Events.getGroup();c||Blockly.Events.setGroup(!0);try{for(var d=0;d<b.length;d++)b[d].dispose(!0,!1);this.deleteVariable(a)}finally{c||Blockly.Events.setGroup(!1)}};
Blockly.VariableMap.prototype.getVariable=function(a,b){if(b=this.variableMap_[b||""])for(var c=0,d;d=b[c];c++)if(d.name==a)return d;return null};Blockly.VariableMap.prototype.getVariableById=function(a){for(var b=Object.keys(this.variableMap_),c=0;c<b.length;c++)for(var d=b[c],e=0,f;f=this.variableMap_[d][e];e++)if(f.getId()==a)return f;return null};Blockly.VariableMap.prototype.getVariablesOfType=function(a){return(a=this.variableMap_[a||""])?a.slice():[]};
Blockly.VariableMap.prototype.getVariableTypes=function(){for(var a=Object.keys(this.variableMap_),b=!1,c=0;c<a.length;c++)""==a[c]&&(b=!0);b||a.push("");return a};Blockly.VariableMap.prototype.getAllVariables=function(){for(var a=[],b=Object.keys(this.variableMap_),c=0;c<b.length;c++)a=a.concat(this.variableMap_[b[c]]);return a};
Blockly.VariableMap.prototype.getVariableUsesById=function(a){for(var b=[],c=this.workspace.getAllBlocks(),d=0;d<c.length;d++){var e=c[d].getVarModels();if(e)for(var f=0;f<e.length;f++)e[f].getId()==a&&b.push(c[d])}return b};Blockly.Events.CommentBase=function(a){this.commentId=a.id;this.workspaceId=a.workspace.id;this.blockId=a.blockId||null;this.group=Blockly.Events.group_;this.recordUndo=Blockly.Events.recordUndo};goog.inherits(Blockly.Events.CommentBase,Blockly.Events.Abstract);Blockly.Events.CommentBase.prototype.toJson=function(){var a={type:this.type};this.group&&(a.group=this.group);this.commentId&&(a.commentId=this.commentId);this.blockId&&(a.blockId=this.blockId);return a};
Blockly.Events.CommentBase.prototype.fromJson=function(a){this.commentId=a.commentId;this.group=a.group;this.blockId=a.blockId};Blockly.Events.CommentBase.prototype.getComment_=function(){return this.getEventWorkspace_().getCommentById(this.commentId)};Blockly.Events.CommentChange=function(a,b,c){a&&(Blockly.Events.CommentChange.superClass_.constructor.call(this,a),this.oldContents_=b,this.newContents_=c)};goog.inherits(Blockly.Events.CommentChange,Blockly.Events.CommentBase);
Blockly.Events.CommentChange.prototype.type=Blockly.Events.COMMENT_CHANGE;Blockly.Events.CommentChange.prototype.toJson=function(){var a=Blockly.Events.CommentChange.superClass_.toJson.call(this);a.newContents=this.newContents_;return a};Blockly.Events.CommentChange.prototype.fromJson=function(a){Blockly.Events.CommentChange.superClass_.fromJson.call(this,a);this.newContents_=a.newValue};Blockly.Events.CommentChange.prototype.isNull=function(){return this.oldContents_==this.newContents_};
Blockly.Events.CommentChange.prototype.run=function(a){var b=this.getComment_();b?(a=a?this.newContents_:this.oldContents_,a.hasOwnProperty("minimized")&&b.setMinimized(a.minimized),a.hasOwnProperty("width")&&a.hasOwnProperty("height")&&b.setSize(a.width,a.height),a.hasOwnProperty("text")&&b.setText(a.text)):console.warn("Can't change non-existent comment: "+this.commentId)};
Blockly.Events.CommentCreate=function(a){if(a){Blockly.Events.CommentCreate.superClass_.constructor.call(this,a);this.text=a.getText();this.xy=a.getXY();var b=a.getHeightWidth();this.width=b.width;this.height=b.height;this.minimized=a.isMinimized()||!1;this.xml=a.toXmlWithXY()}};goog.inherits(Blockly.Events.CommentCreate,Blockly.Events.CommentBase);Blockly.Events.CommentCreate.prototype.type=Blockly.Events.COMMENT_CREATE;
Blockly.Events.CommentCreate.prototype.toJson=function(){var a=Blockly.Events.CommentCreate.superClass_.toJson.call(this);a.xml=Blockly.Xml.domToText(this.xml);return a};Blockly.Events.CommentCreate.prototype.fromJson=function(a){Blockly.Events.CommentCreate.superClass_.fromJson.call(this,a);this.xml=Blockly.Xml.textToDom("<xml>"+a.xml+"</xml>").firstChild};
Blockly.Events.CommentCreate.prototype.run=function(a){if(a)if(a=this.getEventWorkspace_(),this.blockId)(a=a.getBlockById(this.blockId))&&a.setCommentText("",this.commentId,this.xy.x,this.xy.y,this.minimized);else{var b=goog.dom.createDom("xml");b.appendChild(this.xml);Blockly.Xml.domToWorkspace(b,a)}else(a=this.getComment_())?a.dispose(!1,!1):console.warn("Can't uncreate non-existent comment: "+this.commentId)};
Blockly.Events.CommentDelete=function(a){if(a){Blockly.Events.CommentDelete.superClass_.constructor.call(this,a);this.xy=a.getXY();this.minimized=a.isMinimized()||!1;this.text=a.getText();var b=a.getHeightWidth();this.height=b.height;this.width=b.width;this.xml=a.toXmlWithXY()}};goog.inherits(Blockly.Events.CommentDelete,Blockly.Events.CommentBase);Blockly.Events.CommentDelete.prototype.type=Blockly.Events.COMMENT_DELETE;Blockly.Events.CommentDelete.prototype.toJson=function(){return Blockly.Events.CommentDelete.superClass_.toJson.call(this)};
Blockly.Events.CommentDelete.prototype.fromJson=function(a){Blockly.Events.CommentDelete.superClass_.fromJson.call(this,a)};
Blockly.Events.CommentDelete.prototype.run=function(a){if(a)(a=this.getComment_())?a.dispose(!1,!1):console.warn("Can't delete non-existent comment: "+this.commentId);else if(a=this.getEventWorkspace_(),this.blockId)a=a.getBlockById(this.blockId),a.setCommentText(this.text,this.commentId,this.xy.x,this.xy.y,this.minimized),a.comment.setSize(this.width,this.height);else{var b=goog.dom.createDom("xml");b.appendChild(this.xml);Blockly.Xml.domToWorkspace(b,a)}};
Blockly.Events.CommentMove=function(a){a&&(Blockly.Events.CommentMove.superClass_.constructor.call(this,a),this.comment_=a,this.workspaceWidth_=a.workspace.getWidth(),this.oldCoordinate_=this.currentLocation_(),this.newCoordinate_=null)};goog.inherits(Blockly.Events.CommentMove,Blockly.Events.CommentBase);
Blockly.Events.CommentMove.prototype.currentLocation_=function(){var a=this.comment_.getXY();if(!this.comment_.workspace.RTL)return a;if(this.comment_ instanceof Blockly.ScratchBlockComment){var b=this.comment_.getBubbleSize().width;b=this.workspaceWidth_-a.x-b}else b=this.workspaceWidth_-a.x;return new goog.math.Coordinate(b,a.y)};
Blockly.Events.CommentMove.prototype.recordNew=function(){if(!this.comment_)throw Error("Tried to record the new position of a comment on the same event twice.");this.newCoordinate_=this.currentLocation_();this.comment_=null};Blockly.Events.CommentMove.prototype.type=Blockly.Events.COMMENT_MOVE;Blockly.Events.CommentMove.prototype.setOldCoordinate=function(a){this.oldCoordinate_=new goog.math.Coordinate(this.comment_.workspace.RTL?this.workspaceWidth_-a.x:a.x,a.y)};
Blockly.Events.CommentMove.prototype.toJson=function(){var a=Blockly.Events.CommentMove.superClass_.toJson.call(this);this.newCoordinate_&&(a.newCoordinate=Math.round(this.newCoordinate_.x)+","+Math.round(this.newCoordinate_.y));return a};Blockly.Events.CommentMove.prototype.fromJson=function(a){Blockly.Events.CommentMove.superClass_.fromJson.call(this,a);a.newCoordinate&&(a=a.newCoordinate.split(","),this.newCoordinate_=new goog.math.Coordinate(parseFloat(a[0]),parseFloat(a[1])))};
Blockly.Events.CommentMove.prototype.isNull=function(){return goog.math.Coordinate.equals(this.oldCoordinate_,this.newCoordinate_)};
Blockly.Events.CommentMove.prototype.run=function(a){var b=this.getComment_();if(b)if(a=a?this.newCoordinate_:this.oldCoordinate_,b instanceof Blockly.ScratchBlockComment)b.workspace.RTL?b.moveTo(this.workspaceWidth_-a.x,a.y):b.moveTo(a.x,a.y);else{var c=b.getXY();b.workspace.RTL?b.moveBy(-(a.x-(this.workspaceWidth_-c.x)),a.y-c.y):b.moveBy(a.x-c.x,a.y-c.y)}else console.warn("Can't move non-existent comment: "+this.commentId)};Blockly.WorkspaceComment=function(a,b,c,d,e,f){this.id=f&&!a.getCommentById(f)?f:Blockly.utils.genUid();a.addTopComment(this);this.xy_=new goog.math.Coordinate(0,0);this.height_=c;this.width_=d;this.isMinimized_=e;this.workspace=a;this.RTL=a.RTL;this.movable_=this.deletable_=!0;this.content_=b;this.isComment=!0;Blockly.WorkspaceComment.fireCreateEvent(this)};Blockly.WorkspaceComment.MAX_LABEL_LENGTH=12;Blockly.WorkspaceComment.COMMENT_TEXT_LIMIT=8E3;
Blockly.WorkspaceComment.prototype.dispose=function(){this.workspace&&(Blockly.Events.isEnabled()&&Blockly.Events.fire(new Blockly.Events.CommentDelete(this)),this.workspace.removeTopComment(this),this.workspace=null)};Blockly.WorkspaceComment.prototype.getHeight=function(){return this.height_};Blockly.WorkspaceComment.prototype.setHeight=function(a){this.height_=a};Blockly.WorkspaceComment.prototype.getWidth=function(){return this.width_};
Blockly.WorkspaceComment.prototype.setWidth=function(a){this.width_=a};Blockly.WorkspaceComment.prototype.getHeightWidth=function(){return{height:this.height_,width:this.width_}};Blockly.WorkspaceComment.prototype.getXY=function(){return this.xy_.clone()};Blockly.WorkspaceComment.prototype.moveBy=function(a,b){var c=new Blockly.Events.CommentMove(this);this.xy_.translate(a,b);c.recordNew();Blockly.Events.fire(c)};
Blockly.WorkspaceComment.prototype.isDeletable=function(){return this.deletable_&&!(this.workspace&&this.workspace.options.readOnly)};Blockly.WorkspaceComment.prototype.setDeletable=function(a){this.deletable_=a};Blockly.WorkspaceComment.prototype.isMovable=function(){return this.movable_&&!(this.workspace&&this.workspace.options.readOnly)};Blockly.WorkspaceComment.prototype.setMovable=function(a){this.movable_=a};Blockly.WorkspaceComment.prototype.getText=function(){return this.content_};
Blockly.WorkspaceComment.prototype.setText=function(a){this.content_!=a&&(Blockly.Events.fire(new Blockly.Events.CommentChange(this,{text:this.content_},{text:a})),this.content_=a)};Blockly.WorkspaceComment.prototype.isMinimized=function(){return this.isMinimized_};Blockly.WorkspaceComment.prototype.toXmlWithXY=function(a){a=this.toXml(a);a.setAttribute("x",Math.round(this.xy_.x));a.setAttribute("y",Math.round(this.xy_.y));a.setAttribute("h",this.height_);a.setAttribute("w",this.width_);return a};
Blockly.WorkspaceComment.prototype.getLabelText=function(){return this.content_.length>Blockly.WorkspaceComment.MAX_LABEL_LENGTH?this.RTL?"\u2026"+this.content_.slice(0,Blockly.WorkspaceComment.MAX_LABEL_LENGTH):this.content_.slice(0,Blockly.WorkspaceComment.MAX_LABEL_LENGTH)+"\u2026":this.content_};
Blockly.WorkspaceComment.prototype.toXml=function(a){var b=goog.dom.createDom("comment");a||b.setAttribute("id",this.id);this.isMinimized_&&b.setAttribute("minimized",!0);b.textContent=this.getText();return b};Blockly.WorkspaceComment.fireCreateEvent=function(a){if(Blockly.Events.isEnabled()){var b=Blockly.Events.getGroup();b||Blockly.Events.setGroup(!0);try{Blockly.Events.fire(new Blockly.Events.CommentCreate(a))}finally{b||Blockly.Events.setGroup(!1)}}};
Blockly.WorkspaceComment.fromXml=function(a,b){a=Blockly.WorkspaceComment.parseAttributes(a);b=new Blockly.WorkspaceComment(b,a.content,a.h,a.w,a.minimized,a.id);isNaN(a.x)||isNaN(a.y)||b.moveBy(a.x,a.y);Blockly.WorkspaceComment.fireCreateEvent(b);return b};
Blockly.WorkspaceComment.parseAttributes=function(a){var b=a.getAttribute("h"),c=a.getAttribute("w");return{id:a.getAttribute("id"),h:b?parseInt(b,10):100,w:c?parseInt(c,10):100,x:parseInt(a.getAttribute("x"),10),y:parseInt(a.getAttribute("y"),10),minimized:"true"==a.getAttribute("minimized")||!1,content:a.textContent}};
Blockly.Workspace=function(a){this.id=Blockly.utils.genUid();Blockly.Workspace.WorkspaceDB_[this.id]=this;this.options=a||{};this.RTL=!!this.options.RTL;this.horizontalLayout=!!this.options.horizontalLayout;this.toolboxPosition=this.options.toolboxPosition;this.topBlocks_=[];this.topComments_=[];this.commentDB_=Object.create(null);this.listeners_=[];this.tapListeners_=[];this.undoStack_=[];this.redoStack_=[];this.blockDB_=Object.create(null);this.variableMap_=new Blockly.VariableMap(this);this.potentialVariableMap_=
null};Blockly.Workspace.prototype.rendered=!1;Blockly.Workspace.prototype.isClearing=!1;Blockly.Workspace.prototype.MAX_UNDO=1024;Blockly.Workspace.prototype.refreshToolboxSelection_=function(){};Blockly.Workspace.prototype.dispose=function(){this.listeners_.length=0;this.clear();delete Blockly.Workspace.WorkspaceDB_[this.id]};Blockly.Workspace.SCAN_ANGLE=3;Blockly.Workspace.prototype.addTopBlock=function(a){this.topBlocks_.push(a)};
Blockly.Workspace.prototype.removeTopBlock=function(a){if(!goog.array.remove(this.topBlocks_,a))throw"Block not present in workspace's list of top-most blocks.";};Blockly.Workspace.prototype.getTopBlocks=function(a){var b=[].concat(this.topBlocks_);if(a&&1<b.length){var c=Math.sin(goog.math.toRadians(Blockly.Workspace.SCAN_ANGLE));this.RTL&&(c*=-1);b.sort(function(a,b){a=a.getRelativeToSurfaceXY();b=b.getRelativeToSurfaceXY();return a.y+c*a.x-(b.y+c*b.x)})}return b};
Blockly.Workspace.prototype.addTopComment=function(a){this.topComments_.push(a);this.commentDB_[a.id]&&console.warn('Overriding an existing comment on this workspace, with id "'+a.id+'"');this.commentDB_[a.id]=a};Blockly.Workspace.prototype.removeTopComment=function(a){if(!goog.array.remove(this.topComments_,a))throw"Comment not present in workspace's list of top-most comments.";delete this.commentDB_[a.id]};
Blockly.Workspace.prototype.getTopComments=function(a){var b=[].concat(this.topComments_);if(a&&1<b.length){var c=Math.sin(goog.math.toRadians(Blockly.Workspace.SCAN_ANGLE));this.RTL&&(c*=-1);b.sort(function(a,b){a=a instanceof Blockly.ScratchBlockComment?a.getXY():a.getRelativeToSurfaceXY();b=b instanceof Blockly.ScratchBlockComment?b.getXY():b.getRelativeToSurfaceXY();return a.y+c*a.x-(b.y+c*b.x)})}return b};
Blockly.Workspace.prototype.getAllBlocks=function(a){if(a){a=this.getTopBlocks(!0);for(var b=[],c=0;c<a.length;c++)b.push.apply(b,a[c].getDescendants(!0))}else for(b=this.getTopBlocks(!1),c=0;c<b.length;c++)b.push.apply(b,b[c].getChildren(!1));return b};
Blockly.Workspace.prototype.clear=function(){this.isClearing=!0;var a=Blockly.Events.getGroup();for(a||Blockly.Events.setGroup(!0);this.topBlocks_.length;)this.topBlocks_[0].dispose();for(;this.topComments_.length;)this.topComments_[this.topComments_.length-1].dispose();a||Blockly.Events.setGroup(!1);this.variableMap_.clear();Blockly.DropDownDiv&&Blockly.DropDownDiv.hideWithoutAnimation();Blockly.WidgetDiv&&Blockly.WidgetDiv.hide(!0);this.potentialVariableMap_&&this.potentialVariableMap_.clear();
this.isClearing=!1};Blockly.Workspace.prototype.renameVariableById=function(a,b){this.variableMap_.renameVariableById(a,b)};Blockly.Workspace.prototype.createVariable=function(a,b,c,d,e){return this.variableMap_.createVariable(a,b,c,d,e)};Blockly.Workspace.prototype.getVariableUsesById=function(a){return this.variableMap_.getVariableUsesById(a)};Blockly.Workspace.prototype.deleteVariableById=function(a){this.variableMap_.deleteVariableById(a)};
Blockly.Workspace.prototype.deleteVariableInternal_=function(a,b){this.variableMap_.deleteVariableInternal_(a,b)};Blockly.Workspace.prototype.variableIndexOf=function(a){console.warn("Deprecated call to Blockly.Workspace.prototype.variableIndexOf");return-1};Blockly.Workspace.prototype.getVariable=function(a,b){return this.variableMap_.getVariable(a,b)};Blockly.Workspace.prototype.getVariableById=function(a){return this.variableMap_.getVariableById(a)};
Blockly.Workspace.prototype.getVariablesOfType=function(a){return this.variableMap_.getVariablesOfType(a)};Blockly.Workspace.prototype.getVariableTypes=function(){return this.variableMap_.getVariableTypes()};Blockly.Workspace.prototype.getAllVariables=function(){return this.variableMap_.getAllVariables()};Blockly.Workspace.prototype.getWidth=function(){return 0};Blockly.Workspace.prototype.newBlock=function(a,b){return new Blockly.Block(this,a,b)};
Blockly.Workspace.prototype.undo=function(a){var b=a?this.redoStack_:this.undoStack_,c=a?this.undoStack_:this.redoStack_,d=b.pop();if(d){for(var e=[d];b.length&&d.group&&d.group==b[b.length-1].group;)e.push(b.pop());for(b=0;d=e[b];b++)c.push(d);e=Blockly.Events.filter(e,a);Blockly.Events.recordUndo=!1;if(Blockly.selected){Blockly.Events.disable();try{Blockly.selected.unselect()}finally{Blockly.Events.enable()}}try{for(b=0;d=e[b];b++)d.run(a)}finally{Blockly.Events.recordUndo=!0}}};
Blockly.Workspace.prototype.clearUndo=function(){this.undoStack_.length=0;this.redoStack_.length=0;Blockly.Events.clearPendingUndo()};Blockly.Workspace.prototype.hasRedoStack=function(){return 0!=this.redoStack_.length};Blockly.Workspace.prototype.hasUndoStack=function(){return 0!=this.undoStack_.length};Blockly.Workspace.prototype.addChangeListener=function(a){this.listeners_.push(a);return a};Blockly.Workspace.prototype.removeChangeListener=function(a){goog.array.remove(this.listeners_,a)};
Blockly.Workspace.prototype.fireChangeListener=function(a){a.recordUndo&&(this.undoStack_.push(a),this.redoStack_.length=0,this.undoStack_.length>this.MAX_UNDO&&this.undoStack_.unshift());for(var b=this.listeners_.slice(),c=0,d;d=b[c];c++)d(a)};Blockly.Workspace.prototype.getBlockById=function(a){var b=this.blockDB_[a];!b&&this.getFlyout()&&this.getFlyout().getWorkspace()&&(b=this.getFlyout().getWorkspace().blockDB_[a]);return b||null};
Blockly.Workspace.prototype.getCommentById=function(a){return this.commentDB_[a]||null};Blockly.Workspace.prototype.getFlyout=function(){return null};Blockly.Workspace.prototype.allInputsFilled=function(a){for(var b=this.getTopBlocks(!1),c=0,d;d=b[c];c++)if(!d.allInputsFilled(a))return!1;return!0};Blockly.Workspace.prototype.getPotentialVariableMap=function(){return this.potentialVariableMap_};Blockly.Workspace.prototype.createPotentialVariableMap=function(){this.potentialVariableMap_=new Blockly.VariableMap(this)};
Blockly.Workspace.prototype.getVariableMap=function(){return this.variableMap_};Blockly.Workspace.WorkspaceDB_=Object.create(null);Blockly.Workspace.getById=function(a){return Blockly.Workspace.WorkspaceDB_[a]||null};Blockly.Workspace.prototype.clear=Blockly.Workspace.prototype.clear;Blockly.Workspace.prototype.clearUndo=Blockly.Workspace.prototype.clearUndo;Blockly.Workspace.prototype.addChangeListener=Blockly.Workspace.prototype.addChangeListener;
Blockly.Workspace.prototype.removeChangeListener=Blockly.Workspace.prototype.removeChangeListener;goog.dom.BrowserFeature={CAN_ADD_NAME_OR_TYPE_ATTRIBUTES:!goog.userAgent.IE||goog.userAgent.isDocumentModeOrHigher(9),CAN_USE_CHILDREN_ATTRIBUTE:!goog.userAgent.GECKO&&!goog.userAgent.IE||goog.userAgent.IE&&goog.userAgent.isDocumentModeOrHigher(9)||goog.userAgent.GECKO&&goog.userAgent.isVersionOrHigher("1.9.1"),CAN_USE_INNER_TEXT:goog.userAgent.IE&&!goog.userAgent.isVersionOrHigher("9"),CAN_USE_PARENT_ELEMENT_PROPERTY:goog.userAgent.IE||goog.userAgent.OPERA||goog.userAgent.WEBKIT,INNER_HTML_NEEDS_SCOPED_ELEMENT:goog.userAgent.IE,
LEGACY_IE_RANGES:goog.userAgent.IE&&!goog.userAgent.isDocumentModeOrHigher(9)};goog.dom.HtmlElement=function(){};goog.dom.TagName=function(a){this.tagName_=a};goog.dom.TagName.prototype.toString=function(){return this.tagName_};goog.dom.TagName.A=new goog.dom.TagName("A");goog.dom.TagName.ABBR=new goog.dom.TagName("ABBR");goog.dom.TagName.ACRONYM=new goog.dom.TagName("ACRONYM");goog.dom.TagName.ADDRESS=new goog.dom.TagName("ADDRESS");goog.dom.TagName.APPLET=new goog.dom.TagName("APPLET");goog.dom.TagName.AREA=new goog.dom.TagName("AREA");goog.dom.TagName.ARTICLE=new goog.dom.TagName("ARTICLE");
goog.dom.TagName.ASIDE=new goog.dom.TagName("ASIDE");goog.dom.TagName.AUDIO=new goog.dom.TagName("AUDIO");goog.dom.TagName.B=new goog.dom.TagName("B");goog.dom.TagName.BASE=new goog.dom.TagName("BASE");goog.dom.TagName.BASEFONT=new goog.dom.TagName("BASEFONT");goog.dom.TagName.BDI=new goog.dom.TagName("BDI");goog.dom.TagName.BDO=new goog.dom.TagName("BDO");goog.dom.TagName.BIG=new goog.dom.TagName("BIG");goog.dom.TagName.BLOCKQUOTE=new goog.dom.TagName("BLOCKQUOTE");goog.dom.TagName.BODY=new goog.dom.TagName("BODY");
goog.dom.TagName.BR=new goog.dom.TagName("BR");goog.dom.TagName.BUTTON=new goog.dom.TagName("BUTTON");goog.dom.TagName.CANVAS=new goog.dom.TagName("CANVAS");goog.dom.TagName.CAPTION=new goog.dom.TagName("CAPTION");goog.dom.TagName.CENTER=new goog.dom.TagName("CENTER");goog.dom.TagName.CITE=new goog.dom.TagName("CITE");goog.dom.TagName.CODE=new goog.dom.TagName("CODE");goog.dom.TagName.COL=new goog.dom.TagName("COL");goog.dom.TagName.COLGROUP=new goog.dom.TagName("COLGROUP");
goog.dom.TagName.COMMAND=new goog.dom.TagName("COMMAND");goog.dom.TagName.DATA=new goog.dom.TagName("DATA");goog.dom.TagName.DATALIST=new goog.dom.TagName("DATALIST");goog.dom.TagName.DD=new goog.dom.TagName("DD");goog.dom.TagName.DEL=new goog.dom.TagName("DEL");goog.dom.TagName.DETAILS=new goog.dom.TagName("DETAILS");goog.dom.TagName.DFN=new goog.dom.TagName("DFN");goog.dom.TagName.DIALOG=new goog.dom.TagName("DIALOG");goog.dom.TagName.DIR=new goog.dom.TagName("DIR");goog.dom.TagName.DIV=new goog.dom.TagName("DIV");
goog.dom.TagName.DL=new goog.dom.TagName("DL");goog.dom.TagName.DT=new goog.dom.TagName("DT");goog.dom.TagName.EM=new goog.dom.TagName("EM");goog.dom.TagName.EMBED=new goog.dom.TagName("EMBED");goog.dom.TagName.FIELDSET=new goog.dom.TagName("FIELDSET");goog.dom.TagName.FIGCAPTION=new goog.dom.TagName("FIGCAPTION");goog.dom.TagName.FIGURE=new goog.dom.TagName("FIGURE");goog.dom.TagName.FONT=new goog.dom.TagName("FONT");goog.dom.TagName.FOOTER=new goog.dom.TagName("FOOTER");goog.dom.TagName.FORM=new goog.dom.TagName("FORM");
goog.dom.TagName.FRAME=new goog.dom.TagName("FRAME");goog.dom.TagName.FRAMESET=new goog.dom.TagName("FRAMESET");goog.dom.TagName.H1=new goog.dom.TagName("H1");goog.dom.TagName.H2=new goog.dom.TagName("H2");goog.dom.TagName.H3=new goog.dom.TagName("H3");goog.dom.TagName.H4=new goog.dom.TagName("H4");goog.dom.TagName.H5=new goog.dom.TagName("H5");goog.dom.TagName.H6=new goog.dom.TagName("H6");goog.dom.TagName.HEAD=new goog.dom.TagName("HEAD");goog.dom.TagName.HEADER=new goog.dom.TagName("HEADER");
goog.dom.TagName.HGROUP=new goog.dom.TagName("HGROUP");goog.dom.TagName.HR=new goog.dom.TagName("HR");goog.dom.TagName.HTML=new goog.dom.TagName("HTML");goog.dom.TagName.I=new goog.dom.TagName("I");goog.dom.TagName.IFRAME=new goog.dom.TagName("IFRAME");goog.dom.TagName.IMG=new goog.dom.TagName("IMG");goog.dom.TagName.INPUT=new goog.dom.TagName("INPUT");goog.dom.TagName.INS=new goog.dom.TagName("INS");goog.dom.TagName.ISINDEX=new goog.dom.TagName("ISINDEX");goog.dom.TagName.KBD=new goog.dom.TagName("KBD");
goog.dom.TagName.KEYGEN=new goog.dom.TagName("KEYGEN");goog.dom.TagName.LABEL=new goog.dom.TagName("LABEL");goog.dom.TagName.LEGEND=new goog.dom.TagName("LEGEND");goog.dom.TagName.LI=new goog.dom.TagName("LI");goog.dom.TagName.LINK=new goog.dom.TagName("LINK");goog.dom.TagName.MAIN=new goog.dom.TagName("MAIN");goog.dom.TagName.MAP=new goog.dom.TagName("MAP");goog.dom.TagName.MARK=new goog.dom.TagName("MARK");goog.dom.TagName.MATH=new goog.dom.TagName("MATH");goog.dom.TagName.MENU=new goog.dom.TagName("MENU");
goog.dom.TagName.MENUITEM=new goog.dom.TagName("MENUITEM");goog.dom.TagName.META=new goog.dom.TagName("META");goog.dom.TagName.METER=new goog.dom.TagName("METER");goog.dom.TagName.NAV=new goog.dom.TagName("NAV");goog.dom.TagName.NOFRAMES=new goog.dom.TagName("NOFRAMES");goog.dom.TagName.NOSCRIPT=new goog.dom.TagName("NOSCRIPT");goog.dom.TagName.OBJECT=new goog.dom.TagName("OBJECT");goog.dom.TagName.OL=new goog.dom.TagName("OL");goog.dom.TagName.OPTGROUP=new goog.dom.TagName("OPTGROUP");
goog.dom.TagName.OPTION=new goog.dom.TagName("OPTION");goog.dom.TagName.OUTPUT=new goog.dom.TagName("OUTPUT");goog.dom.TagName.P=new goog.dom.TagName("P");goog.dom.TagName.PARAM=new goog.dom.TagName("PARAM");goog.dom.TagName.PICTURE=new goog.dom.TagName("PICTURE");goog.dom.TagName.PRE=new goog.dom.TagName("PRE");goog.dom.TagName.PROGRESS=new goog.dom.TagName("PROGRESS");goog.dom.TagName.Q=new goog.dom.TagName("Q");goog.dom.TagName.RP=new goog.dom.TagName("RP");goog.dom.TagName.RT=new goog.dom.TagName("RT");
goog.dom.TagName.RTC=new goog.dom.TagName("RTC");goog.dom.TagName.RUBY=new goog.dom.TagName("RUBY");goog.dom.TagName.S=new goog.dom.TagName("S");goog.dom.TagName.SAMP=new goog.dom.TagName("SAMP");goog.dom.TagName.SCRIPT=new goog.dom.TagName("SCRIPT");goog.dom.TagName.SECTION=new goog.dom.TagName("SECTION");goog.dom.TagName.SELECT=new goog.dom.TagName("SELECT");goog.dom.TagName.SMALL=new goog.dom.TagName("SMALL");goog.dom.TagName.SOURCE=new goog.dom.TagName("SOURCE");goog.dom.TagName.SPAN=new goog.dom.TagName("SPAN");
goog.dom.TagName.STRIKE=new goog.dom.TagName("STRIKE");goog.dom.TagName.STRONG=new goog.dom.TagName("STRONG");goog.dom.TagName.STYLE=new goog.dom.TagName("STYLE");goog.dom.TagName.SUB=new goog.dom.TagName("SUB");goog.dom.TagName.SUMMARY=new goog.dom.TagName("SUMMARY");goog.dom.TagName.SUP=new goog.dom.TagName("SUP");goog.dom.TagName.SVG=new goog.dom.TagName("SVG");goog.dom.TagName.TABLE=new goog.dom.TagName("TABLE");goog.dom.TagName.TBODY=new goog.dom.TagName("TBODY");goog.dom.TagName.TD=new goog.dom.TagName("TD");
goog.dom.TagName.TEMPLATE=new goog.dom.TagName("TEMPLATE");goog.dom.TagName.TEXTAREA=new goog.dom.TagName("TEXTAREA");goog.dom.TagName.TFOOT=new goog.dom.TagName("TFOOT");goog.dom.TagName.TH=new goog.dom.TagName("TH");goog.dom.TagName.THEAD=new goog.dom.TagName("THEAD");goog.dom.TagName.TIME=new goog.dom.TagName("TIME");goog.dom.TagName.TITLE=new goog.dom.TagName("TITLE");goog.dom.TagName.TR=new goog.dom.TagName("TR");goog.dom.TagName.TRACK=new goog.dom.TagName("TRACK");goog.dom.TagName.TT=new goog.dom.TagName("TT");
goog.dom.TagName.U=new goog.dom.TagName("U");goog.dom.TagName.UL=new goog.dom.TagName("UL");goog.dom.TagName.VAR=new goog.dom.TagName("VAR");goog.dom.TagName.VIDEO=new goog.dom.TagName("VIDEO");goog.dom.TagName.WBR=new goog.dom.TagName("WBR");goog.dom.asserts={};goog.dom.asserts.assertIsLocation=function(a){if(goog.asserts.ENABLE_ASSERTS){var b=goog.dom.asserts.getWindow_(a);"undefined"!=typeof b.Location&&"undefined"!=typeof b.Element&&goog.asserts.assert(a&&(a instanceof b.Location||!(a instanceof b.Element)),"Argument is not a Location (or a non-Element mock); got: %s",goog.dom.asserts.debugStringForType_(a))}return a};
goog.dom.asserts.assertIsElementType_=function(a,b){if(goog.asserts.ENABLE_ASSERTS){var c=goog.dom.asserts.getWindow_(a);"undefined"!=typeof c[b]&&"undefined"!=typeof c.Location&&"undefined"!=typeof c.Element&&goog.asserts.assert(a&&(a instanceof c[b]||!(a instanceof c.Location||a instanceof c.Element)),"Argument is not a %s (or a non-Element, non-Location mock); got: %s",b,goog.dom.asserts.debugStringForType_(a))}return a};
goog.dom.asserts.assertIsHTMLAnchorElement=function(a){return goog.dom.asserts.assertIsElementType_(a,"HTMLAnchorElement")};goog.dom.asserts.assertIsHTMLButtonElement=function(a){return goog.dom.asserts.assertIsElementType_(a,"HTMLButtonElement")};goog.dom.asserts.assertIsHTMLLinkElement=function(a){return goog.dom.asserts.assertIsElementType_(a,"HTMLLinkElement")};goog.dom.asserts.assertIsHTMLImageElement=function(a){return goog.dom.asserts.assertIsElementType_(a,"HTMLImageElement")};
goog.dom.asserts.assertIsHTMLAudioElement=function(a){return goog.dom.asserts.assertIsElementType_(a,"HTMLAudioElement")};goog.dom.asserts.assertIsHTMLVideoElement=function(a){return goog.dom.asserts.assertIsElementType_(a,"HTMLVideoElement")};goog.dom.asserts.assertIsHTMLInputElement=function(a){return goog.dom.asserts.assertIsElementType_(a,"HTMLInputElement")};goog.dom.asserts.assertIsHTMLTextAreaElement=function(a){return goog.dom.asserts.assertIsElementType_(a,"HTMLTextAreaElement")};
goog.dom.asserts.assertIsHTMLCanvasElement=function(a){return goog.dom.asserts.assertIsElementType_(a,"HTMLCanvasElement")};goog.dom.asserts.assertIsHTMLEmbedElement=function(a){return goog.dom.asserts.assertIsElementType_(a,"HTMLEmbedElement")};goog.dom.asserts.assertIsHTMLFormElement=function(a){return goog.dom.asserts.assertIsElementType_(a,"HTMLFormElement")};goog.dom.asserts.assertIsHTMLFrameElement=function(a){return goog.dom.asserts.assertIsElementType_(a,"HTMLFrameElement")};
goog.dom.asserts.assertIsHTMLIFrameElement=function(a){return goog.dom.asserts.assertIsElementType_(a,"HTMLIFrameElement")};goog.dom.asserts.assertIsHTMLObjectElement=function(a){return goog.dom.asserts.assertIsElementType_(a,"HTMLObjectElement")};goog.dom.asserts.assertIsHTMLScriptElement=function(a){return goog.dom.asserts.assertIsElementType_(a,"HTMLScriptElement")};
goog.dom.asserts.debugStringForType_=function(a){return goog.isObject(a)?a.constructor.displayName||a.constructor.name||Object.prototype.toString.call(a):void 0===a?"undefined":null===a?"null":typeof a};goog.dom.asserts.getWindow_=function(a){return(a=a&&a.ownerDocument)&&(a.defaultView||a.parentWindow)||goog.global};goog.functions={};goog.functions.constant=function(a){return function(){return a}};goog.functions.FALSE=function(){return!1};goog.functions.TRUE=function(){return!0};goog.functions.NULL=function(){return null};goog.functions.identity=function(a,b){return a};goog.functions.error=function(a){return function(){throw Error(a);}};goog.functions.fail=function(a){return function(){throw a;}};
goog.functions.lock=function(a,b){b=b||0;return function(){return a.apply(this,Array.prototype.slice.call(arguments,0,b))}};goog.functions.nth=function(a){return function(){return arguments[a]}};goog.functions.partialRight=function(a,b){var c=Array.prototype.slice.call(arguments,1);return function(){var b=Array.prototype.slice.call(arguments);b.push.apply(b,c);return a.apply(this,b)}};goog.functions.withReturnValue=function(a,b){return goog.functions.sequence(a,goog.functions.constant(b))};
goog.functions.equalTo=function(a,b){return function(c){return b?a==c:a===c}};goog.functions.compose=function(a,b){var c=arguments,d=c.length;return function(){var a;d&&(a=c[d-1].apply(this,arguments));for(var b=d-2;0<=b;b--)a=c[b].call(this,a);return a}};goog.functions.sequence=function(a){var b=arguments,c=b.length;return function(){for(var a,e=0;e<c;e++)a=b[e].apply(this,arguments);return a}};
goog.functions.and=function(a){var b=arguments,c=b.length;return function(){for(var a=0;a<c;a++)if(!b[a].apply(this,arguments))return!1;return!0}};goog.functions.or=function(a){var b=arguments,c=b.length;return function(){for(var a=0;a<c;a++)if(b[a].apply(this,arguments))return!0;return!1}};goog.functions.not=function(a){return function(){return!a.apply(this,arguments)}};
goog.functions.create=function(a,b){var c=function(){};c.prototype=a.prototype;c=new c;a.apply(c,Array.prototype.slice.call(arguments,1));return c};goog.functions.CACHE_RETURN_VALUE=!0;goog.functions.cacheReturnValue=function(a){var b=!1,c;return function(){if(!goog.functions.CACHE_RETURN_VALUE)return a();b||(c=a(),b=!0);return c}};goog.functions.once=function(a){var b=a;return function(){if(b){var a=b;b=null;a()}}};
goog.functions.debounce=function(a,b,c){var d=0;return function(e){goog.global.clearTimeout(d);var f=arguments;d=goog.global.setTimeout(function(){a.apply(c,f)},b)}};goog.functions.throttle=function(a,b,c){var d=0,e=!1,f=[],g=function(){d=0;e&&(e=!1,h())},h=function(){d=goog.global.setTimeout(g,b);a.apply(c,f)};return function(a){f=arguments;d?e=!0:h()}};goog.functions.rateLimit=function(a,b,c){var d=0,e=function(){d=0};return function(f){d||(d=goog.global.setTimeout(e,b),a.apply(c,arguments))}};goog.dom.tags={};goog.dom.tags.VOID_TAGS_={area:!0,base:!0,br:!0,col:!0,command:!0,embed:!0,hr:!0,img:!0,input:!0,keygen:!0,link:!0,meta:!0,param:!0,source:!0,track:!0,wbr:!0};goog.dom.tags.isVoidTag=function(a){return!0===goog.dom.tags.VOID_TAGS_[a]};goog.string.TypedString=function(){};goog.string.Const=function(a,b){this.stringConstValueWithSecurityContract__googStringSecurityPrivate_=a===goog.string.Const.GOOG_STRING_CONSTRUCTOR_TOKEN_PRIVATE_&&b||"";this.STRING_CONST_TYPE_MARKER__GOOG_STRING_SECURITY_PRIVATE_=goog.string.Const.TYPE_MARKER_};goog.string.Const.prototype.implementsGoogStringTypedString=!0;goog.string.Const.prototype.getTypedStringValue=function(){return this.stringConstValueWithSecurityContract__googStringSecurityPrivate_};
goog.string.Const.prototype.toString=function(){return"Const{"+this.stringConstValueWithSecurityContract__googStringSecurityPrivate_+"}"};goog.string.Const.unwrap=function(a){if(a instanceof goog.string.Const&&a.constructor===goog.string.Const&&a.STRING_CONST_TYPE_MARKER__GOOG_STRING_SECURITY_PRIVATE_===goog.string.Const.TYPE_MARKER_)return a.stringConstValueWithSecurityContract__googStringSecurityPrivate_;goog.asserts.fail("expected object of type Const, got '"+a+"'");return"type_error:Const"};
goog.string.Const.from=function(a){return new goog.string.Const(goog.string.Const.GOOG_STRING_CONSTRUCTOR_TOKEN_PRIVATE_,a)};goog.string.Const.TYPE_MARKER_={};goog.string.Const.GOOG_STRING_CONSTRUCTOR_TOKEN_PRIVATE_={};goog.string.Const.EMPTY=goog.string.Const.from("");goog.html={};goog.html.SafeScript=function(){this.privateDoNotAccessOrElseSafeScriptWrappedValue_="";this.SAFE_SCRIPT_TYPE_MARKER_GOOG_HTML_SECURITY_PRIVATE_=goog.html.SafeScript.TYPE_MARKER_GOOG_HTML_SECURITY_PRIVATE_};goog.html.SafeScript.prototype.implementsGoogStringTypedString=!0;goog.html.SafeScript.TYPE_MARKER_GOOG_HTML_SECURITY_PRIVATE_={};goog.html.SafeScript.fromConstant=function(a){a=goog.string.Const.unwrap(a);return 0===a.length?goog.html.SafeScript.EMPTY:goog.html.SafeScript.createSafeScriptSecurityPrivateDoNotAccessOrElse(a)};
goog.html.SafeScript.fromConstantAndArgs=function(a,b){for(var c=[],d=1;d<arguments.length;d++)c.push(goog.html.SafeScript.stringify_(arguments[d]));return goog.html.SafeScript.createSafeScriptSecurityPrivateDoNotAccessOrElse("("+goog.string.Const.unwrap(a)+")("+c.join(", ")+");")};goog.html.SafeScript.fromJson=function(a){return goog.html.SafeScript.createSafeScriptSecurityPrivateDoNotAccessOrElse(goog.html.SafeScript.stringify_(a))};goog.html.SafeScript.prototype.getTypedStringValue=function(){return this.privateDoNotAccessOrElseSafeScriptWrappedValue_};
goog.DEBUG&&(goog.html.SafeScript.prototype.toString=function(){return"SafeScript{"+this.privateDoNotAccessOrElseSafeScriptWrappedValue_+"}"});
goog.html.SafeScript.unwrap=function(a){if(a instanceof goog.html.SafeScript&&a.constructor===goog.html.SafeScript&&a.SAFE_SCRIPT_TYPE_MARKER_GOOG_HTML_SECURITY_PRIVATE_===goog.html.SafeScript.TYPE_MARKER_GOOG_HTML_SECURITY_PRIVATE_)return a.privateDoNotAccessOrElseSafeScriptWrappedValue_;goog.asserts.fail("expected object of type SafeScript, got '"+a+"' of type "+goog.typeOf(a));return"type_error:SafeScript"};goog.html.SafeScript.stringify_=function(a){return JSON.stringify(a).replace(/</g,"\\x3c")};
goog.html.SafeScript.createSafeScriptSecurityPrivateDoNotAccessOrElse=function(a){return(new goog.html.SafeScript).initSecurityPrivateDoNotAccessOrElse_(a)};goog.html.SafeScript.prototype.initSecurityPrivateDoNotAccessOrElse_=function(a){this.privateDoNotAccessOrElseSafeScriptWrappedValue_=a;return this};goog.html.SafeScript.EMPTY=goog.html.SafeScript.createSafeScriptSecurityPrivateDoNotAccessOrElse("");goog.fs={};goog.fs.url={};goog.fs.url.createObjectUrl=function(a){return goog.fs.url.getUrlObject_().createObjectURL(a)};goog.fs.url.revokeObjectUrl=function(a){goog.fs.url.getUrlObject_().revokeObjectURL(a)};goog.fs.url.getUrlObject_=function(){var a=goog.fs.url.findUrlObject_();if(null!=a)return a;throw Error("This browser doesn't seem to support blob URLs");};
goog.fs.url.findUrlObject_=function(){return goog.isDef(goog.global.URL)&&goog.isDef(goog.global.URL.createObjectURL)?goog.global.URL:goog.isDef(goog.global.webkitURL)&&goog.isDef(goog.global.webkitURL.createObjectURL)?goog.global.webkitURL:goog.isDef(goog.global.createObjectURL)?goog.global:null};goog.fs.url.browserSupportsObjectUrls=function(){return null!=goog.fs.url.findUrlObject_()};goog.i18n={};goog.i18n.bidi={};goog.i18n.bidi.FORCE_RTL=!1;
goog.i18n.bidi.IS_RTL=goog.i18n.bidi.FORCE_RTL||("ar"==goog.LOCALE.substring(0,2).toLowerCase()||"fa"==goog.LOCALE.substring(0,2).toLowerCase()||"he"==goog.LOCALE.substring(0,2).toLowerCase()||"iw"==goog.LOCALE.substring(0,2).toLowerCase()||"ps"==goog.LOCALE.substring(0,2).toLowerCase()||"sd"==goog.LOCALE.substring(0,2).toLowerCase()||"ug"==goog.LOCALE.substring(0,2).toLowerCase()||"ur"==goog.LOCALE.substring(0,2).toLowerCase()||"yi"==goog.LOCALE.substring(0,2).toLowerCase())&&(2==goog.LOCALE.length||
"-"==goog.LOCALE.substring(2,3)||"_"==goog.LOCALE.substring(2,3))||3<=goog.LOCALE.length&&"ckb"==goog.LOCALE.substring(0,3).toLowerCase()&&(3==goog.LOCALE.length||"-"==goog.LOCALE.substring(3,4)||"_"==goog.LOCALE.substring(3,4))||7<=goog.LOCALE.length&&("-"==goog.LOCALE.substring(2,3)||"_"==goog.LOCALE.substring(2,3))&&("adlm"==goog.LOCALE.substring(3,7).toLowerCase()||"arab"==goog.LOCALE.substring(3,7).toLowerCase()||"hebr"==goog.LOCALE.substring(3,7).toLowerCase()||"nkoo"==goog.LOCALE.substring(3,
7).toLowerCase()||"rohg"==goog.LOCALE.substring(3,7).toLowerCase()||"thaa"==goog.LOCALE.substring(3,7).toLowerCase())||8<=goog.LOCALE.length&&("-"==goog.LOCALE.substring(3,4)||"_"==goog.LOCALE.substring(3,4))&&("adlm"==goog.LOCALE.substring(4,8).toLowerCase()||"arab"==goog.LOCALE.substring(4,8).toLowerCase()||"hebr"==goog.LOCALE.substring(4,8).toLowerCase()||"nkoo"==goog.LOCALE.substring(4,8).toLowerCase()||"rohg"==goog.LOCALE.substring(4,8).toLowerCase()||"thaa"==goog.LOCALE.substring(4,8).toLowerCase());
goog.i18n.bidi.Format={LRE:"\u202a",RLE:"\u202b",PDF:"\u202c",LRM:"\u200e",RLM:"\u200f"};goog.i18n.bidi.Dir={LTR:1,RTL:-1,NEUTRAL:0};goog.i18n.bidi.RIGHT="right";goog.i18n.bidi.LEFT="left";goog.i18n.bidi.I18N_RIGHT=goog.i18n.bidi.IS_RTL?goog.i18n.bidi.LEFT:goog.i18n.bidi.RIGHT;goog.i18n.bidi.I18N_LEFT=goog.i18n.bidi.IS_RTL?goog.i18n.bidi.RIGHT:goog.i18n.bidi.LEFT;
goog.i18n.bidi.toDir=function(a,b){return"number"==typeof a?0<a?goog.i18n.bidi.Dir.LTR:0>a?goog.i18n.bidi.Dir.RTL:b?null:goog.i18n.bidi.Dir.NEUTRAL:null==a?null:a?goog.i18n.bidi.Dir.RTL:goog.i18n.bidi.Dir.LTR};goog.i18n.bidi.ltrChars_="A-Za-z\u00c0-\u00d6\u00d8-\u00f6\u00f8-\u02b8\u0300-\u0590\u0900-\u1fff\u200e\u2c00-\ud801\ud804-\ud839\ud83c-\udbff\uf900-\ufb1c\ufe00-\ufe6f\ufefd-\uffff";goog.i18n.bidi.rtlChars_="\u0591-\u06ef\u06fa-\u08ff\u200f\ud802-\ud803\ud83a-\ud83b\ufb1d-\ufdff\ufe70-\ufefc";
goog.i18n.bidi.htmlSkipReg_=/<[^>]*>|&[^;]+;/g;goog.i18n.bidi.stripHtmlIfNeeded_=function(a,b){return b?a.replace(goog.i18n.bidi.htmlSkipReg_,""):a};goog.i18n.bidi.rtlCharReg_=new RegExp("["+goog.i18n.bidi.rtlChars_+"]");goog.i18n.bidi.ltrCharReg_=new RegExp("["+goog.i18n.bidi.ltrChars_+"]");goog.i18n.bidi.hasAnyRtl=function(a,b){return goog.i18n.bidi.rtlCharReg_.test(goog.i18n.bidi.stripHtmlIfNeeded_(a,b))};goog.i18n.bidi.hasRtlChar=goog.i18n.bidi.hasAnyRtl;
goog.i18n.bidi.hasAnyLtr=function(a,b){return goog.i18n.bidi.ltrCharReg_.test(goog.i18n.bidi.stripHtmlIfNeeded_(a,b))};goog.i18n.bidi.ltrRe_=new RegExp("^["+goog.i18n.bidi.ltrChars_+"]");goog.i18n.bidi.rtlRe_=new RegExp("^["+goog.i18n.bidi.rtlChars_+"]");goog.i18n.bidi.isRtlChar=function(a){return goog.i18n.bidi.rtlRe_.test(a)};goog.i18n.bidi.isLtrChar=function(a){return goog.i18n.bidi.ltrRe_.test(a)};goog.i18n.bidi.isNeutralChar=function(a){return!goog.i18n.bidi.isLtrChar(a)&&!goog.i18n.bidi.isRtlChar(a)};
goog.i18n.bidi.ltrDirCheckRe_=new RegExp("^[^"+goog.i18n.bidi.rtlChars_+"]*["+goog.i18n.bidi.ltrChars_+"]");goog.i18n.bidi.rtlDirCheckRe_=new RegExp("^[^"+goog.i18n.bidi.ltrChars_+"]*["+goog.i18n.bidi.rtlChars_+"]");goog.i18n.bidi.startsWithRtl=function(a,b){return goog.i18n.bidi.rtlDirCheckRe_.test(goog.i18n.bidi.stripHtmlIfNeeded_(a,b))};goog.i18n.bidi.isRtlText=goog.i18n.bidi.startsWithRtl;
goog.i18n.bidi.startsWithLtr=function(a,b){return goog.i18n.bidi.ltrDirCheckRe_.test(goog.i18n.bidi.stripHtmlIfNeeded_(a,b))};goog.i18n.bidi.isLtrText=goog.i18n.bidi.startsWithLtr;goog.i18n.bidi.isRequiredLtrRe_=/^http:\/\/.*/;goog.i18n.bidi.isNeutralText=function(a,b){a=goog.i18n.bidi.stripHtmlIfNeeded_(a,b);return goog.i18n.bidi.isRequiredLtrRe_.test(a)||!goog.i18n.bidi.hasAnyLtr(a)&&!goog.i18n.bidi.hasAnyRtl(a)};
goog.i18n.bidi.ltrExitDirCheckRe_=new RegExp("["+goog.i18n.bidi.ltrChars_+"][^"+goog.i18n.bidi.rtlChars_+"]*$");goog.i18n.bidi.rtlExitDirCheckRe_=new RegExp("["+goog.i18n.bidi.rtlChars_+"][^"+goog.i18n.bidi.ltrChars_+"]*$");goog.i18n.bidi.endsWithLtr=function(a,b){return goog.i18n.bidi.ltrExitDirCheckRe_.test(goog.i18n.bidi.stripHtmlIfNeeded_(a,b))};goog.i18n.bidi.isLtrExitText=goog.i18n.bidi.endsWithLtr;
goog.i18n.bidi.endsWithRtl=function(a,b){return goog.i18n.bidi.rtlExitDirCheckRe_.test(goog.i18n.bidi.stripHtmlIfNeeded_(a,b))};goog.i18n.bidi.isRtlExitText=goog.i18n.bidi.endsWithRtl;goog.i18n.bidi.rtlLocalesRe_=/^(ar|ckb|dv|he|iw|fa|nqo|ps|sd|ug|ur|yi|.*[-_](Adlm|Arab|Hebr|Nkoo|Rohg|Thaa))(?!.*[-_](Latn|Cyrl)($|-|_))($|-|_)/i;goog.i18n.bidi.isRtlLanguage=function(a){return goog.i18n.bidi.rtlLocalesRe_.test(a)};goog.i18n.bidi.bracketGuardTextRe_=/(\(.*?\)+)|(\[.*?\]+)|(\{.*?\}+)|(<.*?>+)/g;
goog.i18n.bidi.guardBracketInText=function(a,b){b=(void 0===b?goog.i18n.bidi.hasAnyRtl(a):b)?goog.i18n.bidi.Format.RLM:goog.i18n.bidi.Format.LRM;return a.replace(goog.i18n.bidi.bracketGuardTextRe_,b+"$&"+b)};goog.i18n.bidi.enforceRtlInHtml=function(a){return"<"==a.charAt(0)?a.replace(/<\w+/,"$& dir=rtl"):"\n<span dir=rtl>"+a+"</span>"};goog.i18n.bidi.enforceRtlInText=function(a){return goog.i18n.bidi.Format.RLE+a+goog.i18n.bidi.Format.PDF};
goog.i18n.bidi.enforceLtrInHtml=function(a){return"<"==a.charAt(0)?a.replace(/<\w+/,"$& dir=ltr"):"\n<span dir=ltr>"+a+"</span>"};goog.i18n.bidi.enforceLtrInText=function(a){return goog.i18n.bidi.Format.LRE+a+goog.i18n.bidi.Format.PDF};goog.i18n.bidi.dimensionsRe_=/:\s*([.\d][.\w]*)\s+([.\d][.\w]*)\s+([.\d][.\w]*)\s+([.\d][.\w]*)/g;goog.i18n.bidi.leftRe_=/left/gi;goog.i18n.bidi.rightRe_=/right/gi;goog.i18n.bidi.tempRe_=/%%%%/g;
goog.i18n.bidi.mirrorCSS=function(a){return a.replace(goog.i18n.bidi.dimensionsRe_,":$1 $4 $3 $2").replace(goog.i18n.bidi.leftRe_,"%%%%").replace(goog.i18n.bidi.rightRe_,goog.i18n.bidi.LEFT).replace(goog.i18n.bidi.tempRe_,goog.i18n.bidi.RIGHT)};goog.i18n.bidi.doubleQuoteSubstituteRe_=/([\u0591-\u05f2])"/g;goog.i18n.bidi.singleQuoteSubstituteRe_=/([\u0591-\u05f2])'/g;
goog.i18n.bidi.normalizeHebrewQuote=function(a){return a.replace(goog.i18n.bidi.doubleQuoteSubstituteRe_,"$1\u05f4").replace(goog.i18n.bidi.singleQuoteSubstituteRe_,"$1\u05f3")};goog.i18n.bidi.wordSeparatorRe_=/\s+/;goog.i18n.bidi.hasNumeralsRe_=/[\d\u06f0-\u06f9]/;goog.i18n.bidi.rtlDetectionThreshold_=.4;
goog.i18n.bidi.estimateDirection=function(a,b){var c=0,d=0,e=!1;a=goog.i18n.bidi.stripHtmlIfNeeded_(a,b).split(goog.i18n.bidi.wordSeparatorRe_);for(b=0;b<a.length;b++){var f=a[b];goog.i18n.bidi.startsWithRtl(f)?(c++,d++):goog.i18n.bidi.isRequiredLtrRe_.test(f)?e=!0:goog.i18n.bidi.hasAnyLtr(f)?d++:goog.i18n.bidi.hasNumeralsRe_.test(f)&&(e=!0)}return 0==d?e?goog.i18n.bidi.Dir.LTR:goog.i18n.bidi.Dir.NEUTRAL:c/d>goog.i18n.bidi.rtlDetectionThreshold_?goog.i18n.bidi.Dir.RTL:goog.i18n.bidi.Dir.LTR};
goog.i18n.bidi.detectRtlDirectionality=function(a,b){return goog.i18n.bidi.estimateDirection(a,b)==goog.i18n.bidi.Dir.RTL};goog.i18n.bidi.setElementDirAndAlign=function(a,b){a&&(b=goog.i18n.bidi.toDir(b))&&(a.style.textAlign=b==goog.i18n.bidi.Dir.RTL?goog.i18n.bidi.RIGHT:goog.i18n.bidi.LEFT,a.dir=b==goog.i18n.bidi.Dir.RTL?"rtl":"ltr")};
goog.i18n.bidi.setElementDirByTextDirectionality=function(a,b){switch(goog.i18n.bidi.estimateDirection(b)){case goog.i18n.bidi.Dir.LTR:a.dir="ltr";break;case goog.i18n.bidi.Dir.RTL:a.dir="rtl";break;default:a.removeAttribute("dir")}};goog.i18n.bidi.DirectionalString=function(){};goog.html.TrustedResourceUrl=function(){this.privateDoNotAccessOrElseTrustedResourceUrlWrappedValue_="";this.TRUSTED_RESOURCE_URL_TYPE_MARKER_GOOG_HTML_SECURITY_PRIVATE_=goog.html.TrustedResourceUrl.TYPE_MARKER_GOOG_HTML_SECURITY_PRIVATE_};goog.html.TrustedResourceUrl.prototype.implementsGoogStringTypedString=!0;goog.html.TrustedResourceUrl.prototype.getTypedStringValue=function(){return this.privateDoNotAccessOrElseTrustedResourceUrlWrappedValue_};
goog.html.TrustedResourceUrl.prototype.implementsGoogI18nBidiDirectionalString=!0;goog.html.TrustedResourceUrl.prototype.getDirection=function(){return goog.i18n.bidi.Dir.LTR};
goog.html.TrustedResourceUrl.prototype.cloneWithParams=function(a,b){var c=goog.html.TrustedResourceUrl.unwrap(this);c=goog.html.TrustedResourceUrl.URL_PARAM_PARSER_.exec(c);var d=c[3]||"";return goog.html.TrustedResourceUrl.createTrustedResourceUrlSecurityPrivateDoNotAccessOrElse(c[1]+goog.html.TrustedResourceUrl.stringifyParams_("?",c[2]||"",a)+goog.html.TrustedResourceUrl.stringifyParams_("#",d,b))};
goog.DEBUG&&(goog.html.TrustedResourceUrl.prototype.toString=function(){return"TrustedResourceUrl{"+this.privateDoNotAccessOrElseTrustedResourceUrlWrappedValue_+"}"});
goog.html.TrustedResourceUrl.unwrap=function(a){if(a instanceof goog.html.TrustedResourceUrl&&a.constructor===goog.html.TrustedResourceUrl&&a.TRUSTED_RESOURCE_URL_TYPE_MARKER_GOOG_HTML_SECURITY_PRIVATE_===goog.html.TrustedResourceUrl.TYPE_MARKER_GOOG_HTML_SECURITY_PRIVATE_)return a.privateDoNotAccessOrElseTrustedResourceUrlWrappedValue_;goog.asserts.fail("expected object of type TrustedResourceUrl, got '"+a+"' of type "+goog.typeOf(a));return"type_error:TrustedResourceUrl"};
goog.html.TrustedResourceUrl.format=function(a,b){var c=goog.string.Const.unwrap(a);if(!goog.html.TrustedResourceUrl.BASE_URL_.test(c))throw Error("Invalid TrustedResourceUrl format: "+c);a=c.replace(goog.html.TrustedResourceUrl.FORMAT_MARKER_,function(a,e){if(!Object.prototype.hasOwnProperty.call(b,e))throw Error('Found marker, "'+e+'", in format string, "'+c+'", but no valid label mapping found in args: '+JSON.stringify(b));a=b[e];return a instanceof goog.string.Const?goog.string.Const.unwrap(a):
encodeURIComponent(String(a))});return goog.html.TrustedResourceUrl.createTrustedResourceUrlSecurityPrivateDoNotAccessOrElse(a)};goog.html.TrustedResourceUrl.FORMAT_MARKER_=/%{(\w+)}/g;goog.html.TrustedResourceUrl.BASE_URL_=/^((https:)?\/\/[0-9a-z.:[\]-]+\/|\/[^/\\]|[^:/\\%]+\/|[^:/\\%]*[?#]|about:blank#)/i;goog.html.TrustedResourceUrl.URL_PARAM_PARSER_=/^([^?#]*)(\?[^#]*)?(#[\s\S]*)?/;
goog.html.TrustedResourceUrl.formatWithParams=function(a,b,c,d){return goog.html.TrustedResourceUrl.format(a,b).cloneWithParams(c,d)};goog.html.TrustedResourceUrl.fromConstant=function(a){return goog.html.TrustedResourceUrl.createTrustedResourceUrlSecurityPrivateDoNotAccessOrElse(goog.string.Const.unwrap(a))};goog.html.TrustedResourceUrl.fromConstants=function(a){for(var b="",c=0;c<a.length;c++)b+=goog.string.Const.unwrap(a[c]);return goog.html.TrustedResourceUrl.createTrustedResourceUrlSecurityPrivateDoNotAccessOrElse(b)};
goog.html.TrustedResourceUrl.TYPE_MARKER_GOOG_HTML_SECURITY_PRIVATE_={};goog.html.TrustedResourceUrl.createTrustedResourceUrlSecurityPrivateDoNotAccessOrElse=function(a){var b=new goog.html.TrustedResourceUrl;b.privateDoNotAccessOrElseTrustedResourceUrlWrappedValue_=a;return b};
goog.html.TrustedResourceUrl.stringifyParams_=function(a,b,c){if(null==c)return b;if(goog.isString(c))return c?a+encodeURIComponent(c):"";for(var d in c){var e=c[d];e=goog.isArray(e)?e:[e];for(var f=0;f<e.length;f++){var g=e[f];null!=g&&(b||(b=a),b+=(b.length>a.length?"&":"")+encodeURIComponent(d)+"="+encodeURIComponent(String(g)))}}return b};goog.html.SafeUrl=function(){this.privateDoNotAccessOrElseSafeUrlWrappedValue_="";this.SAFE_URL_TYPE_MARKER_GOOG_HTML_SECURITY_PRIVATE_=goog.html.SafeUrl.TYPE_MARKER_GOOG_HTML_SECURITY_PRIVATE_};goog.html.SafeUrl.INNOCUOUS_STRING="about:invalid#zClosurez";goog.html.SafeUrl.prototype.implementsGoogStringTypedString=!0;goog.html.SafeUrl.prototype.getTypedStringValue=function(){return this.privateDoNotAccessOrElseSafeUrlWrappedValue_};
goog.html.SafeUrl.prototype.implementsGoogI18nBidiDirectionalString=!0;goog.html.SafeUrl.prototype.getDirection=function(){return goog.i18n.bidi.Dir.LTR};goog.DEBUG&&(goog.html.SafeUrl.prototype.toString=function(){return"SafeUrl{"+this.privateDoNotAccessOrElseSafeUrlWrappedValue_+"}"});
goog.html.SafeUrl.unwrap=function(a){if(a instanceof goog.html.SafeUrl&&a.constructor===goog.html.SafeUrl&&a.SAFE_URL_TYPE_MARKER_GOOG_HTML_SECURITY_PRIVATE_===goog.html.SafeUrl.TYPE_MARKER_GOOG_HTML_SECURITY_PRIVATE_)return a.privateDoNotAccessOrElseSafeUrlWrappedValue_;goog.asserts.fail("expected object of type SafeUrl, got '"+a+"' of type "+goog.typeOf(a));return"type_error:SafeUrl"};goog.html.SafeUrl.fromConstant=function(a){return goog.html.SafeUrl.createSafeUrlSecurityPrivateDoNotAccessOrElse(goog.string.Const.unwrap(a))};
goog.html.SAFE_MIME_TYPE_PATTERN_=/^(?:audio\/(?:3gpp2|3gpp|aac|L16|midi|mp3|mp4|mpeg|oga|ogg|opus|x-m4a|x-wav|wav|webm)|image\/(?:bmp|gif|jpeg|jpg|png|tiff|webp|x-icon)|text\/csv|video\/(?:mpeg|mp4|ogg|webm|quicktime))$/i;goog.html.SafeUrl.fromBlob=function(a){a=goog.html.SAFE_MIME_TYPE_PATTERN_.test(a.type)?goog.fs.url.createObjectUrl(a):goog.html.SafeUrl.INNOCUOUS_STRING;return goog.html.SafeUrl.createSafeUrlSecurityPrivateDoNotAccessOrElse(a)};goog.html.DATA_URL_PATTERN_=/^data:([^;,]*);base64,[a-z0-9+\/]+=*$/i;
goog.html.SafeUrl.fromDataUrl=function(a){a=a.replace(/(%0A|%0D)/g,"");var b=a.match(goog.html.DATA_URL_PATTERN_);b=b&&goog.html.SAFE_MIME_TYPE_PATTERN_.test(b[1]);return goog.html.SafeUrl.createSafeUrlSecurityPrivateDoNotAccessOrElse(b?a:goog.html.SafeUrl.INNOCUOUS_STRING)};goog.html.SafeUrl.fromTelUrl=function(a){goog.string.internal.caseInsensitiveStartsWith(a,"tel:")||(a=goog.html.SafeUrl.INNOCUOUS_STRING);return goog.html.SafeUrl.createSafeUrlSecurityPrivateDoNotAccessOrElse(a)};
goog.html.SIP_URL_PATTERN_=/^sip[s]?:[+a-z0-9_.!$%&'*\/=^`{|}~-]+@([a-z0-9-]+\.)+[a-z0-9]{2,63}$/i;goog.html.SafeUrl.fromSipUrl=function(a){goog.html.SIP_URL_PATTERN_.test(decodeURIComponent(a))||(a=goog.html.SafeUrl.INNOCUOUS_STRING);return goog.html.SafeUrl.createSafeUrlSecurityPrivateDoNotAccessOrElse(a)};goog.html.SafeUrl.fromFacebookMessengerUrl=function(a){goog.string.internal.caseInsensitiveStartsWith(a,"fb-messenger://share")||(a=goog.html.SafeUrl.INNOCUOUS_STRING);return goog.html.SafeUrl.createSafeUrlSecurityPrivateDoNotAccessOrElse(a)};
goog.html.SafeUrl.fromSmsUrl=function(a){goog.string.internal.caseInsensitiveStartsWith(a,"sms:")&&goog.html.SafeUrl.isSmsUrlBodyValid_(a)||(a=goog.html.SafeUrl.INNOCUOUS_STRING);return goog.html.SafeUrl.createSafeUrlSecurityPrivateDoNotAccessOrElse(a)};goog.html.SafeUrl.isSmsUrlBodyValid_=function(a){var b=a.indexOf("#");0<b&&(a=a.substring(0,b));b=a.match(/[?&]body=/gi);if(!b)return!0;if(1<b.length)return!1;a=a.match(/[?&]body=([^&]*)/)[1];if(!a)return!0;try{decodeURIComponent(a)}catch(c){return!1}return/^(?:[a-z0-9\-_.~]|%[0-9a-f]{2})+$/i.test(a)};
goog.html.SafeUrl.fromSshUrl=function(a){goog.string.internal.caseInsensitiveStartsWith(a,"ssh://")||(a=goog.html.SafeUrl.INNOCUOUS_STRING);return goog.html.SafeUrl.createSafeUrlSecurityPrivateDoNotAccessOrElse(a)};goog.html.SafeUrl.sanitizeChromeExtensionUrl=function(a,b){return goog.html.SafeUrl.sanitizeExtensionUrl_(/^chrome-extension:\/\/([^\/]+)\//,a,b)};
goog.html.SafeUrl.sanitizeFirefoxExtensionUrl=function(a,b){return goog.html.SafeUrl.sanitizeExtensionUrl_(/^moz-extension:\/\/([^\/]+)\//,a,b)};goog.html.SafeUrl.sanitizeEdgeExtensionUrl=function(a,b){return goog.html.SafeUrl.sanitizeExtensionUrl_(/^ms-browser-extension:\/\/([^\/]+)\//,a,b)};
goog.html.SafeUrl.sanitizeExtensionUrl_=function(a,b,c){(a=a.exec(b))?(a=a[1],-1==(c instanceof goog.string.Const?[goog.string.Const.unwrap(c)]:c.map(function(a){return goog.string.Const.unwrap(a)})).indexOf(a)&&(b=goog.html.SafeUrl.INNOCUOUS_STRING)):b=goog.html.SafeUrl.INNOCUOUS_STRING;return goog.html.SafeUrl.createSafeUrlSecurityPrivateDoNotAccessOrElse(b)};goog.html.SafeUrl.fromTrustedResourceUrl=function(a){return goog.html.SafeUrl.createSafeUrlSecurityPrivateDoNotAccessOrElse(goog.html.TrustedResourceUrl.unwrap(a))};
goog.html.SAFE_URL_PATTERN_=/^(?:(?:https?|mailto|ftp):|[^:/?#]*(?:[/?#]|$))/i;goog.html.SafeUrl.SAFE_URL_PATTERN=goog.html.SAFE_URL_PATTERN_;goog.html.SafeUrl.sanitize=function(a){if(a instanceof goog.html.SafeUrl)return a;a="object"==typeof a&&a.implementsGoogStringTypedString?a.getTypedStringValue():String(a);goog.html.SAFE_URL_PATTERN_.test(a)||(a=goog.html.SafeUrl.INNOCUOUS_STRING);return goog.html.SafeUrl.createSafeUrlSecurityPrivateDoNotAccessOrElse(a)};
goog.html.SafeUrl.sanitizeAssertUnchanged=function(a,b){if(a instanceof goog.html.SafeUrl)return a;a="object"==typeof a&&a.implementsGoogStringTypedString?a.getTypedStringValue():String(a);if(b&&/^data:/i.test(a)&&(b=goog.html.SafeUrl.fromDataUrl(a),b.getTypedStringValue()==a))return b;goog.asserts.assert(goog.html.SAFE_URL_PATTERN_.test(a),"%s does not match the safe URL pattern",a)||(a=goog.html.SafeUrl.INNOCUOUS_STRING);return goog.html.SafeUrl.createSafeUrlSecurityPrivateDoNotAccessOrElse(a)};
goog.html.SafeUrl.TYPE_MARKER_GOOG_HTML_SECURITY_PRIVATE_={};goog.html.SafeUrl.createSafeUrlSecurityPrivateDoNotAccessOrElse=function(a){var b=new goog.html.SafeUrl;b.privateDoNotAccessOrElseSafeUrlWrappedValue_=a;return b};goog.html.SafeUrl.ABOUT_BLANK=goog.html.SafeUrl.createSafeUrlSecurityPrivateDoNotAccessOrElse("about:blank");goog.html.SafeStyle=function(){this.privateDoNotAccessOrElseSafeStyleWrappedValue_="";this.SAFE_STYLE_TYPE_MARKER_GOOG_HTML_SECURITY_PRIVATE_=goog.html.SafeStyle.TYPE_MARKER_GOOG_HTML_SECURITY_PRIVATE_};goog.html.SafeStyle.prototype.implementsGoogStringTypedString=!0;goog.html.SafeStyle.TYPE_MARKER_GOOG_HTML_SECURITY_PRIVATE_={};
goog.html.SafeStyle.fromConstant=function(a){a=goog.string.Const.unwrap(a);if(0===a.length)return goog.html.SafeStyle.EMPTY;goog.asserts.assert(goog.string.internal.endsWith(a,";"),"Last character of style string is not ';': "+a);goog.asserts.assert(goog.string.internal.contains(a,":"),"Style string must contain at least one ':', to specify a \"name: value\" pair: "+a);return goog.html.SafeStyle.createSafeStyleSecurityPrivateDoNotAccessOrElse(a)};
goog.html.SafeStyle.prototype.getTypedStringValue=function(){return this.privateDoNotAccessOrElseSafeStyleWrappedValue_};goog.DEBUG&&(goog.html.SafeStyle.prototype.toString=function(){return"SafeStyle{"+this.privateDoNotAccessOrElseSafeStyleWrappedValue_+"}"});
goog.html.SafeStyle.unwrap=function(a){if(a instanceof goog.html.SafeStyle&&a.constructor===goog.html.SafeStyle&&a.SAFE_STYLE_TYPE_MARKER_GOOG_HTML_SECURITY_PRIVATE_===goog.html.SafeStyle.TYPE_MARKER_GOOG_HTML_SECURITY_PRIVATE_)return a.privateDoNotAccessOrElseSafeStyleWrappedValue_;goog.asserts.fail("expected object of type SafeStyle, got '"+a+"' of type "+goog.typeOf(a));return"type_error:SafeStyle"};goog.html.SafeStyle.createSafeStyleSecurityPrivateDoNotAccessOrElse=function(a){return(new goog.html.SafeStyle).initSecurityPrivateDoNotAccessOrElse_(a)};
goog.html.SafeStyle.prototype.initSecurityPrivateDoNotAccessOrElse_=function(a){this.privateDoNotAccessOrElseSafeStyleWrappedValue_=a;return this};goog.html.SafeStyle.EMPTY=goog.html.SafeStyle.createSafeStyleSecurityPrivateDoNotAccessOrElse("");goog.html.SafeStyle.INNOCUOUS_STRING="zClosurez";
goog.html.SafeStyle.create=function(a){var b="",c;for(c in a){if(!/^[-_a-zA-Z0-9]+$/.test(c))throw Error("Name allows only [-_a-zA-Z0-9], got: "+c);var d=a[c];null!=d&&(d=goog.isArray(d)?goog.array.map(d,goog.html.SafeStyle.sanitizePropertyValue_).join(" "):goog.html.SafeStyle.sanitizePropertyValue_(d),b+=c+":"+d+";")}return b?goog.html.SafeStyle.createSafeStyleSecurityPrivateDoNotAccessOrElse(b):goog.html.SafeStyle.EMPTY};
goog.html.SafeStyle.sanitizePropertyValue_=function(a){if(a instanceof goog.html.SafeUrl)return'url("'+goog.html.SafeUrl.unwrap(a).replace(/</g,"%3c").replace(/[\\"]/g,"\\$&")+'")';a=a instanceof goog.string.Const?goog.string.Const.unwrap(a):goog.html.SafeStyle.sanitizePropertyValueString_(String(a));if(/[{;}]/.test(a))throw new goog.asserts.AssertionError("Value does not allow [{;}], got: %s.",[a]);return a};
goog.html.SafeStyle.sanitizePropertyValueString_=function(a){var b=a.replace(goog.html.SafeStyle.FUNCTIONS_RE_,"$1").replace(goog.html.SafeStyle.FUNCTIONS_RE_,"$1").replace(goog.html.SafeStyle.URL_RE_,"url");if(goog.html.SafeStyle.VALUE_RE_.test(b)){if(goog.html.SafeStyle.COMMENT_RE_.test(a))return goog.asserts.fail("String value disallows comments, got: "+a),goog.html.SafeStyle.INNOCUOUS_STRING;if(!goog.html.SafeStyle.hasBalancedQuotes_(a))return goog.asserts.fail("String value requires balanced quotes, got: "+
a),goog.html.SafeStyle.INNOCUOUS_STRING;if(!goog.html.SafeStyle.hasBalancedSquareBrackets_(a))return goog.asserts.fail("String value requires balanced square brackets and one identifier per pair of brackets, got: "+a),goog.html.SafeStyle.INNOCUOUS_STRING}else return goog.asserts.fail("String value allows only "+goog.html.SafeStyle.VALUE_ALLOWED_CHARS_+" and simple functions, got: "+a),goog.html.SafeStyle.INNOCUOUS_STRING;return goog.html.SafeStyle.sanitizeUrl_(a)};
goog.html.SafeStyle.hasBalancedQuotes_=function(a){for(var b=!0,c=!0,d=0;d<a.length;d++){var e=a.charAt(d);"'"==e&&c?b=!b:'"'==e&&b&&(c=!c)}return b&&c};goog.html.SafeStyle.hasBalancedSquareBrackets_=function(a){for(var b=!0,c=/^[-_a-zA-Z0-9]$/,d=0;d<a.length;d++){var e=a.charAt(d);if("]"==e){if(b)return!1;b=!0}else if("["==e){if(!b)return!1;b=!1}else if(!b&&!c.test(e))return!1}return b};goog.html.SafeStyle.VALUE_ALLOWED_CHARS_="[-,.\"'%_!# a-zA-Z0-9\\[\\]]";
goog.html.SafeStyle.VALUE_RE_=new RegExp("^"+goog.html.SafeStyle.VALUE_ALLOWED_CHARS_+"+$");goog.html.SafeStyle.URL_RE_=/\b(url\([ \t\n]*)('[ -&(-\[\]-~]*'|"[ !#-\[\]-~]*"|[!#-&*-\[\]-~]*)([ \t\n]*\))/g;goog.html.SafeStyle.FUNCTIONS_RE_=/\b(hsl|hsla|rgb|rgba|matrix|calc|minmax|fit-content|repeat|(rotate|scale|translate)(X|Y|Z|3d)?)\([-+*/0-9a-z.%\[\], ]+\)/g;goog.html.SafeStyle.COMMENT_RE_=/\/\*/;
goog.html.SafeStyle.sanitizeUrl_=function(a){return a.replace(goog.html.SafeStyle.URL_RE_,function(a,c,d,e){var b="";d=d.replace(/^(['"])(.*)\1$/,function(a,c,d){b=c;return d});a=goog.html.SafeUrl.sanitize(d).getTypedStringValue();return c+b+a+b+e})};goog.html.SafeStyle.concat=function(a){var b="",c=function(a){goog.isArray(a)?goog.array.forEach(a,c):b+=goog.html.SafeStyle.unwrap(a)};goog.array.forEach(arguments,c);return b?goog.html.SafeStyle.createSafeStyleSecurityPrivateDoNotAccessOrElse(b):goog.html.SafeStyle.EMPTY};goog.html.SafeStyleSheet=function(){this.privateDoNotAccessOrElseSafeStyleSheetWrappedValue_="";this.SAFE_STYLE_SHEET_TYPE_MARKER_GOOG_HTML_SECURITY_PRIVATE_=goog.html.SafeStyleSheet.TYPE_MARKER_GOOG_HTML_SECURITY_PRIVATE_};goog.html.SafeStyleSheet.prototype.implementsGoogStringTypedString=!0;goog.html.SafeStyleSheet.TYPE_MARKER_GOOG_HTML_SECURITY_PRIVATE_={};
goog.html.SafeStyleSheet.createRule=function(a,b){if(goog.string.internal.contains(a,"<"))throw Error("Selector does not allow '<', got: "+a);var c=a.replace(/('|")((?!\1)[^\r\n\f\\]|\\[\s\S])*\1/g,"");if(!/^[-_a-zA-Z0-9#.:* ,>+~[\]()=^$|]+$/.test(c))throw Error("Selector allows only [-_a-zA-Z0-9#.:* ,>+~[\\]()=^$|] and strings, got: "+a);if(!goog.html.SafeStyleSheet.hasBalancedBrackets_(c))throw Error("() and [] in selector must be balanced, got: "+a);b instanceof goog.html.SafeStyle||(b=goog.html.SafeStyle.create(b));
a=a+"{"+goog.html.SafeStyle.unwrap(b).replace(/</g,"\\3C ")+"}";return goog.html.SafeStyleSheet.createSafeStyleSheetSecurityPrivateDoNotAccessOrElse(a)};goog.html.SafeStyleSheet.hasBalancedBrackets_=function(a){for(var b={"(":")","[":"]"},c=[],d=0;d<a.length;d++){var e=a[d];if(b[e])c.push(b[e]);else if(goog.object.contains(b,e)&&c.pop()!=e)return!1}return 0==c.length};
goog.html.SafeStyleSheet.concat=function(a){var b="",c=function(a){goog.isArray(a)?goog.array.forEach(a,c):b+=goog.html.SafeStyleSheet.unwrap(a)};goog.array.forEach(arguments,c);return goog.html.SafeStyleSheet.createSafeStyleSheetSecurityPrivateDoNotAccessOrElse(b)};
goog.html.SafeStyleSheet.fromConstant=function(a){a=goog.string.Const.unwrap(a);if(0===a.length)return goog.html.SafeStyleSheet.EMPTY;goog.asserts.assert(!goog.string.internal.contains(a,"<"),"Forbidden '<' character in style sheet string: "+a);return goog.html.SafeStyleSheet.createSafeStyleSheetSecurityPrivateDoNotAccessOrElse(a)};goog.html.SafeStyleSheet.prototype.getTypedStringValue=function(){return this.privateDoNotAccessOrElseSafeStyleSheetWrappedValue_};
goog.DEBUG&&(goog.html.SafeStyleSheet.prototype.toString=function(){return"SafeStyleSheet{"+this.privateDoNotAccessOrElseSafeStyleSheetWrappedValue_+"}"});
goog.html.SafeStyleSheet.unwrap=function(a){if(a instanceof goog.html.SafeStyleSheet&&a.constructor===goog.html.SafeStyleSheet&&a.SAFE_STYLE_SHEET_TYPE_MARKER_GOOG_HTML_SECURITY_PRIVATE_===goog.html.SafeStyleSheet.TYPE_MARKER_GOOG_HTML_SECURITY_PRIVATE_)return a.privateDoNotAccessOrElseSafeStyleSheetWrappedValue_;goog.asserts.fail("expected object of type SafeStyleSheet, got '"+a+"' of type "+goog.typeOf(a));return"type_error:SafeStyleSheet"};
goog.html.SafeStyleSheet.createSafeStyleSheetSecurityPrivateDoNotAccessOrElse=function(a){return(new goog.html.SafeStyleSheet).initSecurityPrivateDoNotAccessOrElse_(a)};goog.html.SafeStyleSheet.prototype.initSecurityPrivateDoNotAccessOrElse_=function(a){this.privateDoNotAccessOrElseSafeStyleSheetWrappedValue_=a;return this};goog.html.SafeStyleSheet.EMPTY=goog.html.SafeStyleSheet.createSafeStyleSheetSecurityPrivateDoNotAccessOrElse("");goog.html.SafeHtml=function(){this.privateDoNotAccessOrElseSafeHtmlWrappedValue_="";this.SAFE_HTML_TYPE_MARKER_GOOG_HTML_SECURITY_PRIVATE_=goog.html.SafeHtml.TYPE_MARKER_GOOG_HTML_SECURITY_PRIVATE_;this.dir_=null};goog.html.SafeHtml.prototype.implementsGoogI18nBidiDirectionalString=!0;goog.html.SafeHtml.prototype.getDirection=function(){return this.dir_};goog.html.SafeHtml.prototype.implementsGoogStringTypedString=!0;goog.html.SafeHtml.prototype.getTypedStringValue=function(){return this.privateDoNotAccessOrElseSafeHtmlWrappedValue_};
goog.DEBUG&&(goog.html.SafeHtml.prototype.toString=function(){return"SafeHtml{"+this.privateDoNotAccessOrElseSafeHtmlWrappedValue_+"}"});
goog.html.SafeHtml.unwrap=function(a){if(a instanceof goog.html.SafeHtml&&a.constructor===goog.html.SafeHtml&&a.SAFE_HTML_TYPE_MARKER_GOOG_HTML_SECURITY_PRIVATE_===goog.html.SafeHtml.TYPE_MARKER_GOOG_HTML_SECURITY_PRIVATE_)return a.privateDoNotAccessOrElseSafeHtmlWrappedValue_;goog.asserts.fail("expected object of type SafeHtml, got '"+a+"' of type "+goog.typeOf(a));return"type_error:SafeHtml"};
goog.html.SafeHtml.htmlEscape=function(a){if(a instanceof goog.html.SafeHtml)return a;var b="object"==typeof a,c=null;b&&a.implementsGoogI18nBidiDirectionalString&&(c=a.getDirection());a=b&&a.implementsGoogStringTypedString?a.getTypedStringValue():String(a);return goog.html.SafeHtml.createSafeHtmlSecurityPrivateDoNotAccessOrElse(goog.string.internal.htmlEscape(a),c)};
goog.html.SafeHtml.htmlEscapePreservingNewlines=function(a){if(a instanceof goog.html.SafeHtml)return a;a=goog.html.SafeHtml.htmlEscape(a);return goog.html.SafeHtml.createSafeHtmlSecurityPrivateDoNotAccessOrElse(goog.string.internal.newLineToBr(goog.html.SafeHtml.unwrap(a)),a.getDirection())};
goog.html.SafeHtml.htmlEscapePreservingNewlinesAndSpaces=function(a){if(a instanceof goog.html.SafeHtml)return a;a=goog.html.SafeHtml.htmlEscape(a);return goog.html.SafeHtml.createSafeHtmlSecurityPrivateDoNotAccessOrElse(goog.string.internal.whitespaceEscape(goog.html.SafeHtml.unwrap(a)),a.getDirection())};goog.html.SafeHtml.from=goog.html.SafeHtml.htmlEscape;goog.html.SafeHtml.VALID_NAMES_IN_TAG_=/^[a-zA-Z0-9-]+$/;
goog.html.SafeHtml.URL_ATTRIBUTES_={action:!0,cite:!0,data:!0,formaction:!0,href:!0,manifest:!0,poster:!0,src:!0};goog.html.SafeHtml.NOT_ALLOWED_TAG_NAMES_={APPLET:!0,BASE:!0,EMBED:!0,IFRAME:!0,LINK:!0,MATH:!0,META:!0,OBJECT:!0,SCRIPT:!0,STYLE:!0,SVG:!0,TEMPLATE:!0};goog.html.SafeHtml.create=function(a,b,c){goog.html.SafeHtml.verifyTagName(String(a));return goog.html.SafeHtml.createSafeHtmlTagSecurityPrivateDoNotAccessOrElse(String(a),b,c)};
goog.html.SafeHtml.verifyTagName=function(a){if(!goog.html.SafeHtml.VALID_NAMES_IN_TAG_.test(a))throw Error("Invalid tag name <"+a+">.");if(a.toUpperCase()in goog.html.SafeHtml.NOT_ALLOWED_TAG_NAMES_)throw Error("Tag name <"+a+"> is not allowed for SafeHtml.");};
goog.html.SafeHtml.createIframe=function(a,b,c,d){a&&goog.html.TrustedResourceUrl.unwrap(a);var e={};e.src=a||null;e.srcdoc=b&&goog.html.SafeHtml.unwrap(b);a=goog.html.SafeHtml.combineAttributes(e,{sandbox:""},c);return goog.html.SafeHtml.createSafeHtmlTagSecurityPrivateDoNotAccessOrElse("iframe",a,d)};
goog.html.SafeHtml.createSandboxIframe=function(a,b,c,d){if(!goog.html.SafeHtml.canUseSandboxIframe())throw Error("The browser does not support sandboxed iframes.");var e={};e.src=a?goog.html.SafeUrl.unwrap(goog.html.SafeUrl.sanitize(a)):null;e.srcdoc=b||null;e.sandbox="";a=goog.html.SafeHtml.combineAttributes(e,{},c);return goog.html.SafeHtml.createSafeHtmlTagSecurityPrivateDoNotAccessOrElse("iframe",a,d)};
goog.html.SafeHtml.canUseSandboxIframe=function(){return goog.global.HTMLIFrameElement&&"sandbox"in goog.global.HTMLIFrameElement.prototype};goog.html.SafeHtml.createScriptSrc=function(a,b){goog.html.TrustedResourceUrl.unwrap(a);a=goog.html.SafeHtml.combineAttributes({src:a},{},b);return goog.html.SafeHtml.createSafeHtmlTagSecurityPrivateDoNotAccessOrElse("script",a)};
goog.html.SafeHtml.createScript=function(a,b){for(var c in b){var d=c.toLowerCase();if("language"==d||"src"==d||"text"==d||"type"==d)throw Error('Cannot set "'+d+'" attribute');}c="";a=goog.array.concat(a);for(d=0;d<a.length;d++)c+=goog.html.SafeScript.unwrap(a[d]);a=goog.html.SafeHtml.createSafeHtmlSecurityPrivateDoNotAccessOrElse(c,goog.i18n.bidi.Dir.NEUTRAL);return goog.html.SafeHtml.createSafeHtmlTagSecurityPrivateDoNotAccessOrElse("script",b,a)};
goog.html.SafeHtml.createStyle=function(a,b){b=goog.html.SafeHtml.combineAttributes({type:"text/css"},{},b);var c="";a=goog.array.concat(a);for(var d=0;d<a.length;d++)c+=goog.html.SafeStyleSheet.unwrap(a[d]);a=goog.html.SafeHtml.createSafeHtmlSecurityPrivateDoNotAccessOrElse(c,goog.i18n.bidi.Dir.NEUTRAL);return goog.html.SafeHtml.createSafeHtmlTagSecurityPrivateDoNotAccessOrElse("style",b,a)};
goog.html.SafeHtml.createMetaRefresh=function(a,b){a=goog.html.SafeUrl.unwrap(goog.html.SafeUrl.sanitize(a));(goog.labs.userAgent.browser.isIE()||goog.labs.userAgent.browser.isEdge())&&goog.string.internal.contains(a,";")&&(a="'"+a.replace(/'/g,"%27")+"'");return goog.html.SafeHtml.createSafeHtmlTagSecurityPrivateDoNotAccessOrElse("meta",{"http-equiv":"refresh",content:(b||0)+"; url="+a})};
goog.html.SafeHtml.getAttrNameAndValue_=function(a,b,c){if(c instanceof goog.string.Const)c=goog.string.Const.unwrap(c);else if("style"==b.toLowerCase())c=goog.html.SafeHtml.getStyleValue_(c);else{if(/^on/i.test(b))throw Error('Attribute "'+b+'" requires goog.string.Const value, "'+c+'" given.');if(b.toLowerCase()in goog.html.SafeHtml.URL_ATTRIBUTES_)if(c instanceof goog.html.TrustedResourceUrl)c=goog.html.TrustedResourceUrl.unwrap(c);else if(c instanceof goog.html.SafeUrl)c=goog.html.SafeUrl.unwrap(c);
else if(goog.isString(c))c=goog.html.SafeUrl.sanitize(c).getTypedStringValue();else throw Error('Attribute "'+b+'" on tag "'+a+'" requires goog.html.SafeUrl, goog.string.Const, or string, value "'+c+'" given.');}c.implementsGoogStringTypedString&&(c=c.getTypedStringValue());goog.asserts.assert(goog.isString(c)||goog.isNumber(c),"String or number value expected, got "+typeof c+" with value: "+c);return b+'="'+goog.string.internal.htmlEscape(String(c))+'"'};
goog.html.SafeHtml.getStyleValue_=function(a){if(!goog.isObject(a))throw Error('The "style" attribute requires goog.html.SafeStyle or map of style properties, '+typeof a+" given: "+a);a instanceof goog.html.SafeStyle||(a=goog.html.SafeStyle.create(a));return goog.html.SafeStyle.unwrap(a)};goog.html.SafeHtml.createWithDir=function(a,b,c,d){b=goog.html.SafeHtml.create(b,c,d);b.dir_=a;return b};
goog.html.SafeHtml.concat=function(a){var b=goog.i18n.bidi.Dir.NEUTRAL,c="",d=function(a){goog.isArray(a)?goog.array.forEach(a,d):(a=goog.html.SafeHtml.htmlEscape(a),c+=goog.html.SafeHtml.unwrap(a),a=a.getDirection(),b==goog.i18n.bidi.Dir.NEUTRAL?b=a:a!=goog.i18n.bidi.Dir.NEUTRAL&&b!=a&&(b=null))};goog.array.forEach(arguments,d);return goog.html.SafeHtml.createSafeHtmlSecurityPrivateDoNotAccessOrElse(c,b)};
goog.html.SafeHtml.concatWithDir=function(a,b){var c=goog.html.SafeHtml.concat(goog.array.slice(arguments,1));c.dir_=a;return c};goog.html.SafeHtml.TYPE_MARKER_GOOG_HTML_SECURITY_PRIVATE_={};goog.html.SafeHtml.createSafeHtmlSecurityPrivateDoNotAccessOrElse=function(a,b){return(new goog.html.SafeHtml).initSecurityPrivateDoNotAccessOrElse_(a,b)};goog.html.SafeHtml.prototype.initSecurityPrivateDoNotAccessOrElse_=function(a,b){this.privateDoNotAccessOrElseSafeHtmlWrappedValue_=a;this.dir_=b;return this};
goog.html.SafeHtml.createSafeHtmlTagSecurityPrivateDoNotAccessOrElse=function(a,b,c){var d=null;var e="<"+a+goog.html.SafeHtml.stringifyAttributes(a,b);goog.isDefAndNotNull(c)?goog.isArray(c)||(c=[c]):c=[];goog.dom.tags.isVoidTag(a.toLowerCase())?(goog.asserts.assert(!c.length,"Void tag <"+a+"> does not allow content."),e+=">"):(d=goog.html.SafeHtml.concat(c),e+=">"+goog.html.SafeHtml.unwrap(d)+"</"+a+">",d=d.getDirection());(a=b&&b.dir)&&(d=/^(ltr|rtl|auto)$/i.test(a)?goog.i18n.bidi.Dir.NEUTRAL:
null);return goog.html.SafeHtml.createSafeHtmlSecurityPrivateDoNotAccessOrElse(e,d)};goog.html.SafeHtml.stringifyAttributes=function(a,b){var c="";if(b)for(var d in b){if(!goog.html.SafeHtml.VALID_NAMES_IN_TAG_.test(d))throw Error('Invalid attribute name "'+d+'".');var e=b[d];goog.isDefAndNotNull(e)&&(c+=" "+goog.html.SafeHtml.getAttrNameAndValue_(a,d,e))}return c};
goog.html.SafeHtml.combineAttributes=function(a,b,c){var d={},e;for(e in a)goog.asserts.assert(e.toLowerCase()==e,"Must be lower case"),d[e]=a[e];for(e in b)goog.asserts.assert(e.toLowerCase()==e,"Must be lower case"),d[e]=b[e];for(e in c){var f=e.toLowerCase();if(f in a)throw Error('Cannot override "'+f+'" attribute, got "'+e+'" with value "'+c[e]+'"');f in b&&delete d[f];d[e]=c[e]}return d};
goog.html.SafeHtml.DOCTYPE_HTML=goog.html.SafeHtml.createSafeHtmlSecurityPrivateDoNotAccessOrElse("<!DOCTYPE html>",goog.i18n.bidi.Dir.NEUTRAL);goog.html.SafeHtml.EMPTY=goog.html.SafeHtml.createSafeHtmlSecurityPrivateDoNotAccessOrElse("",goog.i18n.bidi.Dir.NEUTRAL);goog.html.SafeHtml.BR=goog.html.SafeHtml.createSafeHtmlSecurityPrivateDoNotAccessOrElse("<br>",goog.i18n.bidi.Dir.NEUTRAL);goog.dom.safe={};goog.dom.safe.InsertAdjacentHtmlPosition={AFTERBEGIN:"afterbegin",AFTEREND:"afterend",BEFOREBEGIN:"beforebegin",BEFOREEND:"beforeend"};goog.dom.safe.insertAdjacentHtml=function(a,b,c){a.insertAdjacentHTML(b,goog.html.SafeHtml.unwrap(c))};goog.dom.safe.SET_INNER_HTML_DISALLOWED_TAGS_={MATH:!0,SCRIPT:!0,STYLE:!0,SVG:!0,TEMPLATE:!0};
goog.dom.safe.isInnerHtmlCleanupRecursive_=goog.functions.cacheReturnValue(function(){if(goog.DEBUG&&"undefined"===typeof document)return!1;var a=document.createElement("div");a.innerHTML="<div><div></div></div>";if(goog.DEBUG&&!a.firstChild)return!1;var b=a.firstChild.firstChild;a.innerHTML="";return!b.parentElement});goog.dom.safe.unsafeSetInnerHtmlDoNotUseOrElse=function(a,b){if(goog.dom.safe.isInnerHtmlCleanupRecursive_())for(;a.lastChild;)a.removeChild(a.lastChild);a.innerHTML=b};
goog.dom.safe.setInnerHtml=function(a,b){if(goog.asserts.ENABLE_ASSERTS){var c=a.tagName.toUpperCase();if(goog.dom.safe.SET_INNER_HTML_DISALLOWED_TAGS_[c])throw Error("goog.dom.safe.setInnerHtml cannot be used to set content of "+a.tagName+".");}goog.dom.safe.unsafeSetInnerHtmlDoNotUseOrElse(a,goog.html.SafeHtml.unwrap(b))};goog.dom.safe.setOuterHtml=function(a,b){a.outerHTML=goog.html.SafeHtml.unwrap(b)};
goog.dom.safe.setFormElementAction=function(a,b){b=b instanceof goog.html.SafeUrl?b:goog.html.SafeUrl.sanitizeAssertUnchanged(b);goog.dom.asserts.assertIsHTMLFormElement(a).action=goog.html.SafeUrl.unwrap(b)};goog.dom.safe.setButtonFormAction=function(a,b){b=b instanceof goog.html.SafeUrl?b:goog.html.SafeUrl.sanitizeAssertUnchanged(b);goog.dom.asserts.assertIsHTMLButtonElement(a).formAction=goog.html.SafeUrl.unwrap(b)};
goog.dom.safe.setInputFormAction=function(a,b){b=b instanceof goog.html.SafeUrl?b:goog.html.SafeUrl.sanitizeAssertUnchanged(b);goog.dom.asserts.assertIsHTMLInputElement(a).formAction=goog.html.SafeUrl.unwrap(b)};goog.dom.safe.setStyle=function(a,b){a.style.cssText=goog.html.SafeStyle.unwrap(b)};goog.dom.safe.documentWrite=function(a,b){a.write(goog.html.SafeHtml.unwrap(b))};
goog.dom.safe.setAnchorHref=function(a,b){goog.dom.asserts.assertIsHTMLAnchorElement(a);b=b instanceof goog.html.SafeUrl?b:goog.html.SafeUrl.sanitizeAssertUnchanged(b);a.href=goog.html.SafeUrl.unwrap(b)};goog.dom.safe.setImageSrc=function(a,b){goog.dom.asserts.assertIsHTMLImageElement(a);if(!(b instanceof goog.html.SafeUrl)){var c=/^data:image\//i.test(b);b=goog.html.SafeUrl.sanitizeAssertUnchanged(b,c)}a.src=goog.html.SafeUrl.unwrap(b)};
goog.dom.safe.setAudioSrc=function(a,b){goog.dom.asserts.assertIsHTMLAudioElement(a);if(!(b instanceof goog.html.SafeUrl)){var c=/^data:audio\//i.test(b);b=goog.html.SafeUrl.sanitizeAssertUnchanged(b,c)}a.src=goog.html.SafeUrl.unwrap(b)};goog.dom.safe.setVideoSrc=function(a,b){goog.dom.asserts.assertIsHTMLVideoElement(a);if(!(b instanceof goog.html.SafeUrl)){var c=/^data:video\//i.test(b);b=goog.html.SafeUrl.sanitizeAssertUnchanged(b,c)}a.src=goog.html.SafeUrl.unwrap(b)};
goog.dom.safe.setEmbedSrc=function(a,b){goog.dom.asserts.assertIsHTMLEmbedElement(a);a.src=goog.html.TrustedResourceUrl.unwrap(b)};goog.dom.safe.setFrameSrc=function(a,b){goog.dom.asserts.assertIsHTMLFrameElement(a);a.src=goog.html.TrustedResourceUrl.unwrap(b)};goog.dom.safe.setIframeSrc=function(a,b){goog.dom.asserts.assertIsHTMLIFrameElement(a);a.src=goog.html.TrustedResourceUrl.unwrap(b)};goog.dom.safe.setIframeSrcdoc=function(a,b){goog.dom.asserts.assertIsHTMLIFrameElement(a);a.srcdoc=goog.html.SafeHtml.unwrap(b)};
goog.dom.safe.setLinkHrefAndRel=function(a,b,c){goog.dom.asserts.assertIsHTMLLinkElement(a);a.rel=c;goog.string.internal.caseInsensitiveContains(c,"stylesheet")?(goog.asserts.assert(b instanceof goog.html.TrustedResourceUrl,'URL must be TrustedResourceUrl because "rel" contains "stylesheet"'),a.href=goog.html.TrustedResourceUrl.unwrap(b)):a.href=b instanceof goog.html.TrustedResourceUrl?goog.html.TrustedResourceUrl.unwrap(b):b instanceof goog.html.SafeUrl?goog.html.SafeUrl.unwrap(b):goog.html.SafeUrl.sanitizeAssertUnchanged(b).getTypedStringValue()};
goog.dom.safe.setObjectData=function(a,b){goog.dom.asserts.assertIsHTMLObjectElement(a);a.data=goog.html.TrustedResourceUrl.unwrap(b)};goog.dom.safe.setScriptSrc=function(a,b){goog.dom.asserts.assertIsHTMLScriptElement(a);a.src=goog.html.TrustedResourceUrl.unwrap(b);(b=goog.getScriptNonce())&&a.setAttribute("nonce",b)};goog.dom.safe.setScriptContent=function(a,b){goog.dom.asserts.assertIsHTMLScriptElement(a);a.text=goog.html.SafeScript.unwrap(b);(b=goog.getScriptNonce())&&a.setAttribute("nonce",b)};
goog.dom.safe.setLocationHref=function(a,b){goog.dom.asserts.assertIsLocation(a);b=b instanceof goog.html.SafeUrl?b:goog.html.SafeUrl.sanitizeAssertUnchanged(b);a.href=goog.html.SafeUrl.unwrap(b)};goog.dom.safe.assignLocation=function(a,b){goog.dom.asserts.assertIsLocation(a);b=b instanceof goog.html.SafeUrl?b:goog.html.SafeUrl.sanitizeAssertUnchanged(b);a.assign(goog.html.SafeUrl.unwrap(b))};
goog.dom.safe.replaceLocation=function(a,b){goog.dom.asserts.assertIsLocation(a);b=b instanceof goog.html.SafeUrl?b:goog.html.SafeUrl.sanitizeAssertUnchanged(b);a.replace(goog.html.SafeUrl.unwrap(b))};goog.dom.safe.openInWindow=function(a,b,c,d,e){a=a instanceof goog.html.SafeUrl?a:goog.html.SafeUrl.sanitizeAssertUnchanged(a);return(b||window).open(goog.html.SafeUrl.unwrap(a),c?goog.string.Const.unwrap(c):"",d,e)};
goog.dom.safe.parseFromStringHtml=function(a,b){return goog.dom.safe.parseFromString(a,b,"text/html")};goog.dom.safe.parseFromString=function(a,b,c){return a.parseFromString(goog.html.SafeHtml.unwrap(b),c)};goog.dom.safe.createImageFromBlob=function(a){if(!/^image\/.*/g.test(a.type))throw Error("goog.dom.safe.createImageFromBlob only accepts MIME type image/.*.");var b=window.URL.createObjectURL(a);a=new Image;a.onload=function(){window.URL.revokeObjectURL(b)};a.src=b;return a};goog.html.uncheckedconversions={};goog.html.uncheckedconversions.safeHtmlFromStringKnownToSatisfyTypeContract=function(a,b,c){goog.asserts.assertString(goog.string.Const.unwrap(a),"must provide justification");goog.asserts.assert(!goog.string.internal.isEmptyOrWhitespace(goog.string.Const.unwrap(a)),"must provide non-empty justification");return goog.html.SafeHtml.createSafeHtmlSecurityPrivateDoNotAccessOrElse(b,c||null)};
goog.html.uncheckedconversions.safeScriptFromStringKnownToSatisfyTypeContract=function(a,b){goog.asserts.assertString(goog.string.Const.unwrap(a),"must provide justification");goog.asserts.assert(!goog.string.internal.isEmptyOrWhitespace(goog.string.Const.unwrap(a)),"must provide non-empty justification");return goog.html.SafeScript.createSafeScriptSecurityPrivateDoNotAccessOrElse(b)};
goog.html.uncheckedconversions.safeStyleFromStringKnownToSatisfyTypeContract=function(a,b){goog.asserts.assertString(goog.string.Const.unwrap(a),"must provide justification");goog.asserts.assert(!goog.string.internal.isEmptyOrWhitespace(goog.string.Const.unwrap(a)),"must provide non-empty justification");return goog.html.SafeStyle.createSafeStyleSecurityPrivateDoNotAccessOrElse(b)};
goog.html.uncheckedconversions.safeStyleSheetFromStringKnownToSatisfyTypeContract=function(a,b){goog.asserts.assertString(goog.string.Const.unwrap(a),"must provide justification");goog.asserts.assert(!goog.string.internal.isEmptyOrWhitespace(goog.string.Const.unwrap(a)),"must provide non-empty justification");return goog.html.SafeStyleSheet.createSafeStyleSheetSecurityPrivateDoNotAccessOrElse(b)};
goog.html.uncheckedconversions.safeUrlFromStringKnownToSatisfyTypeContract=function(a,b){goog.asserts.assertString(goog.string.Const.unwrap(a),"must provide justification");goog.asserts.assert(!goog.string.internal.isEmptyOrWhitespace(goog.string.Const.unwrap(a)),"must provide non-empty justification");return goog.html.SafeUrl.createSafeUrlSecurityPrivateDoNotAccessOrElse(b)};
goog.html.uncheckedconversions.trustedResourceUrlFromStringKnownToSatisfyTypeContract=function(a,b){goog.asserts.assertString(goog.string.Const.unwrap(a),"must provide justification");goog.asserts.assert(!goog.string.internal.isEmptyOrWhitespace(goog.string.Const.unwrap(a)),"must provide non-empty justification");return goog.html.TrustedResourceUrl.createTrustedResourceUrlSecurityPrivateDoNotAccessOrElse(b)};goog.math.Size=function(a,b){this.width=a;this.height=b};goog.math.Size.equals=function(a,b){return a==b?!0:a&&b?a.width==b.width&&a.height==b.height:!1};goog.math.Size.prototype.clone=function(){return new goog.math.Size(this.width,this.height)};goog.DEBUG&&(goog.math.Size.prototype.toString=function(){return"("+this.width+" x "+this.height+")"});goog.math.Size.prototype.getLongest=function(){return Math.max(this.width,this.height)};
goog.math.Size.prototype.getShortest=function(){return Math.min(this.width,this.height)};goog.math.Size.prototype.area=function(){return this.width*this.height};goog.math.Size.prototype.perimeter=function(){return 2*(this.width+this.height)};goog.math.Size.prototype.aspectRatio=function(){return this.width/this.height};goog.math.Size.prototype.isEmpty=function(){return!this.area()};goog.math.Size.prototype.ceil=function(){this.width=Math.ceil(this.width);this.height=Math.ceil(this.height);return this};
goog.math.Size.prototype.fitsInside=function(a){return this.width<=a.width&&this.height<=a.height};goog.math.Size.prototype.floor=function(){this.width=Math.floor(this.width);this.height=Math.floor(this.height);return this};goog.math.Size.prototype.round=function(){this.width=Math.round(this.width);this.height=Math.round(this.height);return this};goog.math.Size.prototype.scale=function(a,b){b=goog.isNumber(b)?b:a;this.width*=a;this.height*=b;return this};
goog.math.Size.prototype.scaleToCover=function(a){a=this.aspectRatio()<=a.aspectRatio()?a.width/this.width:a.height/this.height;return this.scale(a)};goog.math.Size.prototype.scaleToFit=function(a){a=this.aspectRatio()>a.aspectRatio()?a.width/this.width:a.height/this.height;return this.scale(a)};goog.dom.ASSUME_QUIRKS_MODE=!1;goog.dom.ASSUME_STANDARDS_MODE=!1;goog.dom.COMPAT_MODE_KNOWN_=goog.dom.ASSUME_QUIRKS_MODE||goog.dom.ASSUME_STANDARDS_MODE;goog.dom.getDomHelper=function(a){return a?new goog.dom.DomHelper(goog.dom.getOwnerDocument(a)):goog.dom.defaultDomHelper_||(goog.dom.defaultDomHelper_=new goog.dom.DomHelper)};goog.dom.getDocument=function(){return document};goog.dom.getElement=function(a){return goog.dom.getElementHelper_(document,a)};
goog.dom.getElementHelper_=function(a,b){return goog.isString(b)?a.getElementById(b):b};goog.dom.getRequiredElement=function(a){return goog.dom.getRequiredElementHelper_(document,a)};goog.dom.getRequiredElementHelper_=function(a,b){goog.asserts.assertString(b);a=goog.dom.getElementHelper_(a,b);return a=goog.asserts.assertElement(a,"No element found with id: "+b)};goog.dom.$=goog.dom.getElement;goog.dom.getElementsByTagName=function(a,b){return(b||document).getElementsByTagName(String(a))};
goog.dom.getElementsByTagNameAndClass=function(a,b,c){return goog.dom.getElementsByTagNameAndClass_(document,a,b,c)};goog.dom.getElementByTagNameAndClass=function(a,b,c){return goog.dom.getElementByTagNameAndClass_(document,a,b,c)};goog.dom.getElementsByClass=function(a,b){var c=b||document;return goog.dom.canUseQuerySelector_(c)?c.querySelectorAll("."+a):goog.dom.getElementsByTagNameAndClass_(document,"*",a,b)};
goog.dom.getElementByClass=function(a,b){var c=b||document;return(c.getElementsByClassName?c.getElementsByClassName(a)[0]:goog.dom.getElementByTagNameAndClass_(document,"*",a,b))||null};goog.dom.getRequiredElementByClass=function(a,b){b=goog.dom.getElementByClass(a,b);return goog.asserts.assert(b,"No element found with className: "+a)};goog.dom.canUseQuerySelector_=function(a){return!(!a.querySelectorAll||!a.querySelector)};
goog.dom.getElementsByTagNameAndClass_=function(a,b,c,d){a=d||a;b=b&&"*"!=b?String(b).toUpperCase():"";if(goog.dom.canUseQuerySelector_(a)&&(b||c))return a.querySelectorAll(b+(c?"."+c:""));if(c&&a.getElementsByClassName){a=a.getElementsByClassName(c);if(b){d={};for(var e=0,f=0,g;g=a[f];f++)b==g.nodeName&&(d[e++]=g);d.length=e;return d}return a}a=a.getElementsByTagName(b||"*");if(c){d={};for(f=e=0;g=a[f];f++)b=g.className,"function"==typeof b.split&&goog.array.contains(b.split(/\s+/),c)&&(d[e++]=g);
d.length=e;return d}return a};goog.dom.getElementByTagNameAndClass_=function(a,b,c,d){var e=d||a,f=b&&"*"!=b?String(b).toUpperCase():"";return goog.dom.canUseQuerySelector_(e)&&(f||c)?e.querySelector(f+(c?"."+c:"")):goog.dom.getElementsByTagNameAndClass_(a,b,c,d)[0]||null};goog.dom.$$=goog.dom.getElementsByTagNameAndClass;
goog.dom.setProperties=function(a,b){goog.object.forEach(b,function(b,d){b&&"object"==typeof b&&b.implementsGoogStringTypedString&&(b=b.getTypedStringValue());"style"==d?a.style.cssText=b:"class"==d?a.className=b:"for"==d?a.htmlFor=b:goog.dom.DIRECT_ATTRIBUTE_MAP_.hasOwnProperty(d)?a.setAttribute(goog.dom.DIRECT_ATTRIBUTE_MAP_[d],b):goog.string.startsWith(d,"aria-")||goog.string.startsWith(d,"data-")?a.setAttribute(d,b):a[d]=b})};
goog.dom.DIRECT_ATTRIBUTE_MAP_={cellpadding:"cellPadding",cellspacing:"cellSpacing",colspan:"colSpan",frameborder:"frameBorder",height:"height",maxlength:"maxLength",nonce:"nonce",role:"role",rowspan:"rowSpan",type:"type",usemap:"useMap",valign:"vAlign",width:"width"};goog.dom.getViewportSize=function(a){return goog.dom.getViewportSize_(a||window)};goog.dom.getViewportSize_=function(a){a=a.document;a=goog.dom.isCss1CompatMode_(a)?a.documentElement:a.body;return new goog.math.Size(a.clientWidth,a.clientHeight)};
goog.dom.getDocumentHeight=function(){return goog.dom.getDocumentHeight_(window)};goog.dom.getDocumentHeightForWindow=function(a){return goog.dom.getDocumentHeight_(a)};
goog.dom.getDocumentHeight_=function(a){var b=a.document,c=0;if(b){c=b.body;var d=b.documentElement;if(!d||!c)return 0;a=goog.dom.getViewportSize_(a).height;if(goog.dom.isCss1CompatMode_(b)&&d.scrollHeight)c=d.scrollHeight!=a?d.scrollHeight:d.offsetHeight;else{b=d.scrollHeight;var e=d.offsetHeight;d.clientHeight!=e&&(b=c.scrollHeight,e=c.offsetHeight);c=b>a?b>e?b:e:b<e?b:e}}return c};goog.dom.getPageScroll=function(a){return goog.dom.getDomHelper((a||goog.global||window).document).getDocumentScroll()};
goog.dom.getDocumentScroll=function(){return goog.dom.getDocumentScroll_(document)};goog.dom.getDocumentScroll_=function(a){var b=goog.dom.getDocumentScrollElement_(a);a=goog.dom.getWindow_(a);return goog.userAgent.IE&&goog.userAgent.isVersionOrHigher("10")&&a.pageYOffset!=b.scrollTop?new goog.math.Coordinate(b.scrollLeft,b.scrollTop):new goog.math.Coordinate(a.pageXOffset||b.scrollLeft,a.pageYOffset||b.scrollTop)};goog.dom.getDocumentScrollElement=function(){return goog.dom.getDocumentScrollElement_(document)};
goog.dom.getDocumentScrollElement_=function(a){return a.scrollingElement?a.scrollingElement:!goog.userAgent.WEBKIT&&goog.dom.isCss1CompatMode_(a)?a.documentElement:a.body||a.documentElement};goog.dom.getWindow=function(a){return a?goog.dom.getWindow_(a):window};goog.dom.getWindow_=function(a){return a.parentWindow||a.defaultView};goog.dom.createDom=function(a,b,c){return goog.dom.createDom_(document,arguments)};
goog.dom.createDom_=function(a,b){var c=String(b[0]),d=b[1];if(!goog.dom.BrowserFeature.CAN_ADD_NAME_OR_TYPE_ATTRIBUTES&&d&&(d.name||d.type)){c=["<",c];d.name&&c.push(' name="',goog.string.htmlEscape(d.name),'"');if(d.type){c.push(' type="',goog.string.htmlEscape(d.type),'"');var e={};goog.object.extend(e,d);delete e.type;d=e}c.push(">");c=c.join("")}c=a.createElement(c);d&&(goog.isString(d)?c.className=d:goog.isArray(d)?c.className=d.join(" "):goog.dom.setProperties(c,d));2<b.length&&goog.dom.append_(a,
c,b,2);return c};goog.dom.append_=function(a,b,c,d){function e(c){c&&b.appendChild(goog.isString(c)?a.createTextNode(c):c)}for(;d<c.length;d++){var f=c[d];goog.isArrayLike(f)&&!goog.dom.isNodeLike(f)?goog.array.forEach(goog.dom.isNodeList(f)?goog.array.toArray(f):f,e):e(f)}};goog.dom.$dom=goog.dom.createDom;goog.dom.createElement=function(a){return goog.dom.createElement_(document,a)};goog.dom.createElement_=function(a,b){return a.createElement(String(b))};goog.dom.createTextNode=function(a){return document.createTextNode(String(a))};
goog.dom.createTable=function(a,b,c){return goog.dom.createTable_(document,a,b,!!c)};goog.dom.createTable_=function(a,b,c,d){for(var e=goog.dom.createElement_(a,"TABLE"),f=e.appendChild(goog.dom.createElement_(a,"TBODY")),g=0;g<b;g++){for(var h=goog.dom.createElement_(a,"TR"),k=0;k<c;k++){var l=goog.dom.createElement_(a,"TD");d&&goog.dom.setTextContent(l,goog.string.Unicode.NBSP);h.appendChild(l)}f.appendChild(h)}return e};
goog.dom.constHtmlToNode=function(a){var b=goog.array.map(arguments,goog.string.Const.unwrap);b=goog.html.uncheckedconversions.safeHtmlFromStringKnownToSatisfyTypeContract(goog.string.Const.from("Constant HTML string, that gets turned into a Node later, so it will be automatically balanced."),b.join(""));return goog.dom.safeHtmlToNode(b)};goog.dom.safeHtmlToNode=function(a){return goog.dom.safeHtmlToNode_(document,a)};
goog.dom.safeHtmlToNode_=function(a,b){var c=goog.dom.createElement_(a,"DIV");goog.dom.BrowserFeature.INNER_HTML_NEEDS_SCOPED_ELEMENT?(goog.dom.safe.setInnerHtml(c,goog.html.SafeHtml.concat(goog.html.SafeHtml.BR,b)),c.removeChild(goog.asserts.assert(c.firstChild))):goog.dom.safe.setInnerHtml(c,b);return goog.dom.childrenToNode_(a,c)};
goog.dom.childrenToNode_=function(a,b){if(1==b.childNodes.length)return b.removeChild(goog.asserts.assert(b.firstChild));for(a=a.createDocumentFragment();b.firstChild;)a.appendChild(b.firstChild);return a};goog.dom.isCss1CompatMode=function(){return goog.dom.isCss1CompatMode_(document)};goog.dom.isCss1CompatMode_=function(a){return goog.dom.COMPAT_MODE_KNOWN_?goog.dom.ASSUME_STANDARDS_MODE:"CSS1Compat"==a.compatMode};
goog.dom.canHaveChildren=function(a){if(a.nodeType!=goog.dom.NodeType.ELEMENT)return!1;switch(a.tagName){case "APPLET":case "AREA":case "BASE":case "BR":case "COL":case "COMMAND":case "EMBED":case "FRAME":case "HR":case "IMG":case "INPUT":case "IFRAME":case "ISINDEX":case "KEYGEN":case "LINK":case "NOFRAMES":case "NOSCRIPT":case "META":case "OBJECT":case "PARAM":case "SCRIPT":case "SOURCE":case "STYLE":case "TRACK":case "WBR":return!1}return!0};
goog.dom.appendChild=function(a,b){goog.asserts.assert(null!=a&&null!=b,"goog.dom.appendChild expects non-null arguments");a.appendChild(b)};goog.dom.append=function(a,b){goog.dom.append_(goog.dom.getOwnerDocument(a),a,arguments,1)};goog.dom.removeChildren=function(a){for(var b;b=a.firstChild;)a.removeChild(b)};goog.dom.insertSiblingBefore=function(a,b){goog.asserts.assert(null!=a&&null!=b,"goog.dom.insertSiblingBefore expects non-null arguments");b.parentNode&&b.parentNode.insertBefore(a,b)};
goog.dom.insertSiblingAfter=function(a,b){goog.asserts.assert(null!=a&&null!=b,"goog.dom.insertSiblingAfter expects non-null arguments");b.parentNode&&b.parentNode.insertBefore(a,b.nextSibling)};goog.dom.insertChildAt=function(a,b,c){goog.asserts.assert(null!=a,"goog.dom.insertChildAt expects a non-null parent");a.insertBefore(b,a.childNodes[c]||null)};goog.dom.removeNode=function(a){return a&&a.parentNode?a.parentNode.removeChild(a):null};
goog.dom.replaceNode=function(a,b){goog.asserts.assert(null!=a&&null!=b,"goog.dom.replaceNode expects non-null arguments");var c=b.parentNode;c&&c.replaceChild(a,b)};goog.dom.flattenElement=function(a){var b,c=a.parentNode;if(c&&c.nodeType!=goog.dom.NodeType.DOCUMENT_FRAGMENT){if(a.removeNode)return a.removeNode(!1);for(;b=a.firstChild;)c.insertBefore(b,a);return goog.dom.removeNode(a)}};
goog.dom.getChildren=function(a){return goog.dom.BrowserFeature.CAN_USE_CHILDREN_ATTRIBUTE&&void 0!=a.children?a.children:goog.array.filter(a.childNodes,function(a){return a.nodeType==goog.dom.NodeType.ELEMENT})};goog.dom.getFirstElementChild=function(a){return goog.isDef(a.firstElementChild)?a.firstElementChild:goog.dom.getNextElementNode_(a.firstChild,!0)};goog.dom.getLastElementChild=function(a){return goog.isDef(a.lastElementChild)?a.lastElementChild:goog.dom.getNextElementNode_(a.lastChild,!1)};
goog.dom.getNextElementSibling=function(a){return goog.isDef(a.nextElementSibling)?a.nextElementSibling:goog.dom.getNextElementNode_(a.nextSibling,!0)};goog.dom.getPreviousElementSibling=function(a){return goog.isDef(a.previousElementSibling)?a.previousElementSibling:goog.dom.getNextElementNode_(a.previousSibling,!1)};goog.dom.getNextElementNode_=function(a,b){for(;a&&a.nodeType!=goog.dom.NodeType.ELEMENT;)a=b?a.nextSibling:a.previousSibling;return a};
goog.dom.getNextNode=function(a){if(!a)return null;if(a.firstChild)return a.firstChild;for(;a&&!a.nextSibling;)a=a.parentNode;return a?a.nextSibling:null};goog.dom.getPreviousNode=function(a){if(!a)return null;if(!a.previousSibling)return a.parentNode;for(a=a.previousSibling;a&&a.lastChild;)a=a.lastChild;return a};goog.dom.isNodeLike=function(a){return goog.isObject(a)&&0<a.nodeType};goog.dom.isElement=function(a){return goog.isObject(a)&&a.nodeType==goog.dom.NodeType.ELEMENT};
goog.dom.isWindow=function(a){return goog.isObject(a)&&a.window==a};goog.dom.getParentElement=function(a){var b;if(goog.dom.BrowserFeature.CAN_USE_PARENT_ELEMENT_PROPERTY&&!(goog.userAgent.IE&&goog.userAgent.isVersionOrHigher("9")&&!goog.userAgent.isVersionOrHigher("10")&&goog.global.SVGElement&&a instanceof goog.global.SVGElement)&&(b=a.parentElement))return b;b=a.parentNode;return goog.dom.isElement(b)?b:null};
goog.dom.contains=function(a,b){if(!a||!b)return!1;if(a.contains&&b.nodeType==goog.dom.NodeType.ELEMENT)return a==b||a.contains(b);if("undefined"!=typeof a.compareDocumentPosition)return a==b||!!(a.compareDocumentPosition(b)&16);for(;b&&a!=b;)b=b.parentNode;return b==a};
goog.dom.compareNodeOrder=function(a,b){if(a==b)return 0;if(a.compareDocumentPosition)return a.compareDocumentPosition(b)&2?1:-1;if(goog.userAgent.IE&&!goog.userAgent.isDocumentModeOrHigher(9)){if(a.nodeType==goog.dom.NodeType.DOCUMENT)return-1;if(b.nodeType==goog.dom.NodeType.DOCUMENT)return 1}if("sourceIndex"in a||a.parentNode&&"sourceIndex"in a.parentNode){var c=a.nodeType==goog.dom.NodeType.ELEMENT,d=b.nodeType==goog.dom.NodeType.ELEMENT;if(c&&d)return a.sourceIndex-b.sourceIndex;var e=a.parentNode,
f=b.parentNode;return e==f?goog.dom.compareSiblingOrder_(a,b):!c&&goog.dom.contains(e,b)?-1*goog.dom.compareParentsDescendantNodeIe_(a,b):!d&&goog.dom.contains(f,a)?goog.dom.compareParentsDescendantNodeIe_(b,a):(c?a.sourceIndex:e.sourceIndex)-(d?b.sourceIndex:f.sourceIndex)}d=goog.dom.getOwnerDocument(a);c=d.createRange();c.selectNode(a);c.collapse(!0);a=d.createRange();a.selectNode(b);a.collapse(!0);return c.compareBoundaryPoints(goog.global.Range.START_TO_END,a)};
goog.dom.compareParentsDescendantNodeIe_=function(a,b){var c=a.parentNode;if(c==b)return-1;for(;b.parentNode!=c;)b=b.parentNode;return goog.dom.compareSiblingOrder_(b,a)};goog.dom.compareSiblingOrder_=function(a,b){for(;b=b.previousSibling;)if(b==a)return-1;return 1};
goog.dom.findCommonAncestor=function(a){var b,c=arguments.length;if(!c)return null;if(1==c)return arguments[0];var d=[],e=Infinity;for(b=0;b<c;b++){for(var f=[],g=arguments[b];g;)f.unshift(g),g=g.parentNode;d.push(f);e=Math.min(e,f.length)}f=null;for(b=0;b<e;b++){g=d[0][b];for(var h=1;h<c;h++)if(g!=d[h][b])return f;f=g}return f};goog.dom.getOwnerDocument=function(a){goog.asserts.assert(a,"Node cannot be null or undefined.");return a.nodeType==goog.dom.NodeType.DOCUMENT?a:a.ownerDocument||a.document};
goog.dom.getFrameContentDocument=function(a){return a.contentDocument||a.contentWindow.document};goog.dom.getFrameContentWindow=function(a){try{return a.contentWindow||(a.contentDocument?goog.dom.getWindow(a.contentDocument):null)}catch(b){}return null};
goog.dom.setTextContent=function(a,b){goog.asserts.assert(null!=a,"goog.dom.setTextContent expects a non-null value for node");if("textContent"in a)a.textContent=b;else if(a.nodeType==goog.dom.NodeType.TEXT)a.data=String(b);else if(a.firstChild&&a.firstChild.nodeType==goog.dom.NodeType.TEXT){for(;a.lastChild!=a.firstChild;)a.removeChild(goog.asserts.assert(a.lastChild));a.firstChild.data=String(b)}else{goog.dom.removeChildren(a);var c=goog.dom.getOwnerDocument(a);a.appendChild(c.createTextNode(String(b)))}};
goog.dom.getOuterHtml=function(a){goog.asserts.assert(null!==a,"goog.dom.getOuterHtml expects a non-null value for element");if("outerHTML"in a)return a.outerHTML;var b=goog.dom.getOwnerDocument(a);b=goog.dom.createElement_(b,"DIV");b.appendChild(a.cloneNode(!0));return b.innerHTML};goog.dom.findNode=function(a,b){var c=[];return goog.dom.findNodes_(a,b,c,!0)?c[0]:void 0};goog.dom.findNodes=function(a,b){var c=[];goog.dom.findNodes_(a,b,c,!1);return c};
goog.dom.findNodes_=function(a,b,c,d){if(null!=a)for(a=a.firstChild;a;){if(b(a)&&(c.push(a),d)||goog.dom.findNodes_(a,b,c,d))return!0;a=a.nextSibling}return!1};goog.dom.TAGS_TO_IGNORE_={SCRIPT:1,STYLE:1,HEAD:1,IFRAME:1,OBJECT:1};goog.dom.PREDEFINED_TAG_VALUES_={IMG:" ",BR:"\n"};goog.dom.isFocusableTabIndex=function(a){return goog.dom.hasSpecifiedTabIndex_(a)&&goog.dom.isTabIndexFocusable_(a)};goog.dom.setFocusableTabIndex=function(a,b){b?a.tabIndex=0:(a.tabIndex=-1,a.removeAttribute("tabIndex"))};
goog.dom.isFocusable=function(a){var b;return(b=goog.dom.nativelySupportsFocus_(a)?!a.disabled&&(!goog.dom.hasSpecifiedTabIndex_(a)||goog.dom.isTabIndexFocusable_(a)):goog.dom.isFocusableTabIndex(a))&&goog.userAgent.IE?goog.dom.hasNonZeroBoundingRect_(a):b};goog.dom.hasSpecifiedTabIndex_=function(a){return goog.userAgent.IE&&!goog.userAgent.isVersionOrHigher("9")?(a=a.getAttributeNode("tabindex"),goog.isDefAndNotNull(a)&&a.specified):a.hasAttribute("tabindex")};
goog.dom.isTabIndexFocusable_=function(a){a=a.tabIndex;return goog.isNumber(a)&&0<=a&&32768>a};goog.dom.nativelySupportsFocus_=function(a){return"A"==a.tagName&&a.hasAttribute("href")||"INPUT"==a.tagName||"TEXTAREA"==a.tagName||"SELECT"==a.tagName||"BUTTON"==a.tagName};
goog.dom.hasNonZeroBoundingRect_=function(a){a=!goog.isFunction(a.getBoundingClientRect)||goog.userAgent.IE&&null==a.parentElement?{height:a.offsetHeight,width:a.offsetWidth}:a.getBoundingClientRect();return goog.isDefAndNotNull(a)&&0<a.height&&0<a.width};
goog.dom.getTextContent=function(a){if(goog.dom.BrowserFeature.CAN_USE_INNER_TEXT&&null!==a&&"innerText"in a)a=goog.string.canonicalizeNewlines(a.innerText);else{var b=[];goog.dom.getTextContent_(a,b,!0);a=b.join("")}a=a.replace(/ \xAD /g," ").replace(/\xAD/g,"");a=a.replace(/\u200B/g,"");goog.dom.BrowserFeature.CAN_USE_INNER_TEXT||(a=a.replace(/ +/g," "));" "!=a&&(a=a.replace(/^\s*/,""));return a};goog.dom.getRawTextContent=function(a){var b=[];goog.dom.getTextContent_(a,b,!1);return b.join("")};
goog.dom.getTextContent_=function(a,b,c){if(!(a.nodeName in goog.dom.TAGS_TO_IGNORE_))if(a.nodeType==goog.dom.NodeType.TEXT)c?b.push(String(a.nodeValue).replace(/(\r\n|\r|\n)/g,"")):b.push(a.nodeValue);else if(a.nodeName in goog.dom.PREDEFINED_TAG_VALUES_)b.push(goog.dom.PREDEFINED_TAG_VALUES_[a.nodeName]);else for(a=a.firstChild;a;)goog.dom.getTextContent_(a,b,c),a=a.nextSibling};goog.dom.getNodeTextLength=function(a){return goog.dom.getTextContent(a).length};
goog.dom.getNodeTextOffset=function(a,b){b=b||goog.dom.getOwnerDocument(a).body;for(var c=[];a&&a!=b;){for(var d=a;d=d.previousSibling;)c.unshift(goog.dom.getTextContent(d));a=a.parentNode}return goog.string.trimLeft(c.join("")).replace(/ +/g," ").length};
goog.dom.getNodeAtOffset=function(a,b,c){a=[a];for(var d=0,e=null;0<a.length&&d<b;)if(e=a.pop(),!(e.nodeName in goog.dom.TAGS_TO_IGNORE_))if(e.nodeType==goog.dom.NodeType.TEXT){var f=e.nodeValue.replace(/(\r\n|\r|\n)/g,"").replace(/ +/g," ");d+=f.length}else if(e.nodeName in goog.dom.PREDEFINED_TAG_VALUES_)d+=goog.dom.PREDEFINED_TAG_VALUES_[e.nodeName].length;else for(f=e.childNodes.length-1;0<=f;f--)a.push(e.childNodes[f]);goog.isObject(c)&&(c.remainder=e?e.nodeValue.length+b-d-1:0,c.node=e);return e};
goog.dom.isNodeList=function(a){if(a&&"number"==typeof a.length){if(goog.isObject(a))return"function"==typeof a.item||"string"==typeof a.item;if(goog.isFunction(a))return"function"==typeof a.item}return!1};goog.dom.getAncestorByTagNameAndClass=function(a,b,c,d){if(!b&&!c)return null;var e=b?String(b).toUpperCase():null;return goog.dom.getAncestor(a,function(a){return(!e||a.nodeName==e)&&(!c||goog.isString(a.className)&&goog.array.contains(a.className.split(/\s+/),c))},!0,d)};
goog.dom.getAncestorByClass=function(a,b,c){return goog.dom.getAncestorByTagNameAndClass(a,null,b,c)};goog.dom.getAncestor=function(a,b,c,d){a&&!c&&(a=a.parentNode);for(c=0;a&&(null==d||c<=d);){goog.asserts.assert("parentNode"!=a.name);if(b(a))return a;a=a.parentNode;c++}return null};goog.dom.getActiveElement=function(a){try{var b=a&&a.activeElement;return b&&b.nodeName?b:null}catch(c){return null}};
goog.dom.getPixelRatio=function(){var a=goog.dom.getWindow();return goog.isDef(a.devicePixelRatio)?a.devicePixelRatio:a.matchMedia?goog.dom.matchesPixelRatio_(3)||goog.dom.matchesPixelRatio_(2)||goog.dom.matchesPixelRatio_(1.5)||goog.dom.matchesPixelRatio_(1)||.75:1};goog.dom.matchesPixelRatio_=function(a){return goog.dom.getWindow().matchMedia("(min-resolution: "+a+"dppx),(min--moz-device-pixel-ratio: "+a+"),(min-resolution: "+96*a+"dpi)").matches?a:0};goog.dom.getCanvasContext2D=function(a){return a.getContext("2d")};
goog.dom.DomHelper=function(a){this.document_=a||goog.global.document||document};goog.dom.DomHelper.prototype.getDomHelper=goog.dom.getDomHelper;goog.dom.DomHelper.prototype.setDocument=function(a){this.document_=a};goog.dom.DomHelper.prototype.getDocument=function(){return this.document_};goog.dom.DomHelper.prototype.getElement=function(a){return goog.dom.getElementHelper_(this.document_,a)};
goog.dom.DomHelper.prototype.getRequiredElement=function(a){return goog.dom.getRequiredElementHelper_(this.document_,a)};goog.dom.DomHelper.prototype.$=goog.dom.DomHelper.prototype.getElement;goog.dom.DomHelper.prototype.getElementsByTagName=function(a,b){return(b||this.document_).getElementsByTagName(String(a))};goog.dom.DomHelper.prototype.getElementsByTagNameAndClass=function(a,b,c){return goog.dom.getElementsByTagNameAndClass_(this.document_,a,b,c)};
goog.dom.DomHelper.prototype.getElementByTagNameAndClass=function(a,b,c){return goog.dom.getElementByTagNameAndClass_(this.document_,a,b,c)};goog.dom.DomHelper.prototype.getElementsByClass=function(a,b){return goog.dom.getElementsByClass(a,b||this.document_)};goog.dom.DomHelper.prototype.getElementByClass=function(a,b){return goog.dom.getElementByClass(a,b||this.document_)};goog.dom.DomHelper.prototype.getRequiredElementByClass=function(a,b){return goog.dom.getRequiredElementByClass(a,b||this.document_)};
goog.dom.DomHelper.prototype.$$=goog.dom.DomHelper.prototype.getElementsByTagNameAndClass;goog.dom.DomHelper.prototype.setProperties=goog.dom.setProperties;goog.dom.DomHelper.prototype.getViewportSize=function(a){return goog.dom.getViewportSize(a||this.getWindow())};goog.dom.DomHelper.prototype.getDocumentHeight=function(){return goog.dom.getDocumentHeight_(this.getWindow())};goog.dom.DomHelper.prototype.createDom=function(a,b,c){return goog.dom.createDom_(this.document_,arguments)};
goog.dom.DomHelper.prototype.$dom=goog.dom.DomHelper.prototype.createDom;goog.dom.DomHelper.prototype.createElement=function(a){return goog.dom.createElement_(this.document_,a)};goog.dom.DomHelper.prototype.createTextNode=function(a){return this.document_.createTextNode(String(a))};goog.dom.DomHelper.prototype.createTable=function(a,b,c){return goog.dom.createTable_(this.document_,a,b,!!c)};goog.dom.DomHelper.prototype.safeHtmlToNode=function(a){return goog.dom.safeHtmlToNode_(this.document_,a)};
goog.dom.DomHelper.prototype.isCss1CompatMode=function(){return goog.dom.isCss1CompatMode_(this.document_)};goog.dom.DomHelper.prototype.getWindow=function(){return goog.dom.getWindow_(this.document_)};goog.dom.DomHelper.prototype.getDocumentScrollElement=function(){return goog.dom.getDocumentScrollElement_(this.document_)};goog.dom.DomHelper.prototype.getDocumentScroll=function(){return goog.dom.getDocumentScroll_(this.document_)};
goog.dom.DomHelper.prototype.getActiveElement=function(a){return goog.dom.getActiveElement(a||this.document_)};goog.dom.DomHelper.prototype.appendChild=goog.dom.appendChild;goog.dom.DomHelper.prototype.append=goog.dom.append;goog.dom.DomHelper.prototype.canHaveChildren=goog.dom.canHaveChildren;goog.dom.DomHelper.prototype.removeChildren=goog.dom.removeChildren;goog.dom.DomHelper.prototype.insertSiblingBefore=goog.dom.insertSiblingBefore;goog.dom.DomHelper.prototype.insertSiblingAfter=goog.dom.insertSiblingAfter;
goog.dom.DomHelper.prototype.insertChildAt=goog.dom.insertChildAt;goog.dom.DomHelper.prototype.removeNode=goog.dom.removeNode;goog.dom.DomHelper.prototype.replaceNode=goog.dom.replaceNode;goog.dom.DomHelper.prototype.flattenElement=goog.dom.flattenElement;goog.dom.DomHelper.prototype.getChildren=goog.dom.getChildren;goog.dom.DomHelper.prototype.getFirstElementChild=goog.dom.getFirstElementChild;goog.dom.DomHelper.prototype.getLastElementChild=goog.dom.getLastElementChild;
goog.dom.DomHelper.prototype.getNextElementSibling=goog.dom.getNextElementSibling;goog.dom.DomHelper.prototype.getPreviousElementSibling=goog.dom.getPreviousElementSibling;goog.dom.DomHelper.prototype.getNextNode=goog.dom.getNextNode;goog.dom.DomHelper.prototype.getPreviousNode=goog.dom.getPreviousNode;goog.dom.DomHelper.prototype.isNodeLike=goog.dom.isNodeLike;goog.dom.DomHelper.prototype.isElement=goog.dom.isElement;goog.dom.DomHelper.prototype.isWindow=goog.dom.isWindow;
goog.dom.DomHelper.prototype.getParentElement=goog.dom.getParentElement;goog.dom.DomHelper.prototype.contains=goog.dom.contains;goog.dom.DomHelper.prototype.compareNodeOrder=goog.dom.compareNodeOrder;goog.dom.DomHelper.prototype.findCommonAncestor=goog.dom.findCommonAncestor;goog.dom.DomHelper.prototype.getOwnerDocument=goog.dom.getOwnerDocument;goog.dom.DomHelper.prototype.getFrameContentDocument=goog.dom.getFrameContentDocument;goog.dom.DomHelper.prototype.getFrameContentWindow=goog.dom.getFrameContentWindow;
goog.dom.DomHelper.prototype.setTextContent=goog.dom.setTextContent;goog.dom.DomHelper.prototype.getOuterHtml=goog.dom.getOuterHtml;goog.dom.DomHelper.prototype.findNode=goog.dom.findNode;goog.dom.DomHelper.prototype.findNodes=goog.dom.findNodes;goog.dom.DomHelper.prototype.isFocusableTabIndex=goog.dom.isFocusableTabIndex;goog.dom.DomHelper.prototype.setFocusableTabIndex=goog.dom.setFocusableTabIndex;goog.dom.DomHelper.prototype.isFocusable=goog.dom.isFocusable;
goog.dom.DomHelper.prototype.getTextContent=goog.dom.getTextContent;goog.dom.DomHelper.prototype.getNodeTextLength=goog.dom.getNodeTextLength;goog.dom.DomHelper.prototype.getNodeTextOffset=goog.dom.getNodeTextOffset;goog.dom.DomHelper.prototype.getNodeAtOffset=goog.dom.getNodeAtOffset;goog.dom.DomHelper.prototype.isNodeList=goog.dom.isNodeList;goog.dom.DomHelper.prototype.getAncestorByTagNameAndClass=goog.dom.getAncestorByTagNameAndClass;goog.dom.DomHelper.prototype.getAncestorByClass=goog.dom.getAncestorByClass;
goog.dom.DomHelper.prototype.getAncestor=goog.dom.getAncestor;goog.dom.DomHelper.prototype.getCanvasContext2D=goog.dom.getCanvasContext2D;Blockly.Bubble=function(a,b,c,d,e,f){this.workspace_=a;this.content_=b;this.shape_=c;c=Blockly.Bubble.ARROW_ANGLE;this.workspace_.RTL&&(c=-c);this.arrow_radians_=Blockly.utils.toRadians(c);a.getBubbleCanvas().appendChild(this.createDom_(b,!(!e||!f)));this.setAnchorLocation(d);e&&f||(b=this.content_.getBBox(),e=b.width+2*Blockly.Bubble.BORDER_WIDTH,f=b.height+2*Blockly.Bubble.BORDER_WIDTH);this.setBubbleSize(e,f);this.positionBubble_();this.renderArrow_();this.rendered_=!0;a.options.readOnly||(Blockly.bindEventWithChecks_(this.bubbleBack_,
"mousedown",this,this.bubbleMouseDown_),this.resizeGroup_&&Blockly.bindEventWithChecks_(this.resizeGroup_,"mousedown",this,this.resizeMouseDown_))};Blockly.Bubble.BORDER_WIDTH=6;Blockly.Bubble.ARROW_THICKNESS=5;Blockly.Bubble.ARROW_ANGLE=20;Blockly.Bubble.ARROW_BEND=4;Blockly.Bubble.ANCHOR_RADIUS=8;Blockly.Bubble.onMouseUpWrapper_=null;Blockly.Bubble.onMouseMoveWrapper_=null;Blockly.Bubble.prototype.resizeCallback_=null;
Blockly.Bubble.unbindDragEvents_=function(){Blockly.Bubble.onMouseUpWrapper_&&(Blockly.unbindEvent_(Blockly.Bubble.onMouseUpWrapper_),Blockly.Bubble.onMouseUpWrapper_=null);Blockly.Bubble.onMouseMoveWrapper_&&(Blockly.unbindEvent_(Blockly.Bubble.onMouseMoveWrapper_),Blockly.Bubble.onMouseMoveWrapper_=null)};Blockly.Bubble.bubbleMouseUp_=function(){Blockly.Touch.clearTouchIdentifier();Blockly.Bubble.unbindDragEvents_()};Blockly.Bubble.prototype.rendered_=!1;Blockly.Bubble.prototype.anchorXY_=null;
Blockly.Bubble.prototype.relativeLeft_=0;Blockly.Bubble.prototype.relativeTop_=0;Blockly.Bubble.prototype.width_=0;Blockly.Bubble.prototype.height_=0;Blockly.Bubble.prototype.autoLayout_=!0;
Blockly.Bubble.prototype.createDom_=function(a,b){this.bubbleGroup_=Blockly.utils.createSvgElement("g",{},null);var c={filter:"url(#"+this.workspace_.options.embossFilterId+")"};-1!=goog.userAgent.getUserAgentString().indexOf("JavaFX")&&(c={});c=Blockly.utils.createSvgElement("g",c,this.bubbleGroup_);this.bubbleArrow_=Blockly.utils.createSvgElement("path",{},c);this.bubbleBack_=Blockly.utils.createSvgElement("rect",{"class":"blocklyDraggable",x:0,y:0,rx:Blockly.Bubble.BORDER_WIDTH,ry:Blockly.Bubble.BORDER_WIDTH},
c);b?(this.resizeGroup_=Blockly.utils.createSvgElement("g",{"class":this.workspace_.RTL?"blocklyResizeSW":"blocklyResizeSE"},this.bubbleGroup_),b=2*Blockly.Bubble.BORDER_WIDTH,Blockly.utils.createSvgElement("polygon",{points:"0,x x,x x,0".replace(/x/g,b.toString())},this.resizeGroup_),Blockly.utils.createSvgElement("line",{"class":"blocklyResizeLine",x1:b/3,y1:b-1,x2:b-1,y2:b/3},this.resizeGroup_),Blockly.utils.createSvgElement("line",{"class":"blocklyResizeLine",x1:2*b/3,y1:b-1,x2:b-1,y2:2*b/3},
this.resizeGroup_)):this.resizeGroup_=null;this.bubbleGroup_.appendChild(a);return this.bubbleGroup_};Blockly.Bubble.prototype.getSvgRoot=function(){return this.bubbleGroup_};Blockly.Bubble.prototype.setSvgId=function(a){this.bubbleGroup_.dataset&&(this.bubbleGroup_.dataset.blockId=a)};Blockly.Bubble.prototype.bubbleMouseDown_=function(a){var b=this.workspace_.getGesture(a);b&&b.handleBubbleStart(a,this)};Blockly.Bubble.prototype.showContextMenu_=function(a){};
Blockly.Bubble.prototype.isDeletable=function(){return!1};
Blockly.Bubble.prototype.resizeMouseDown_=function(a){this.promote_();Blockly.Bubble.unbindDragEvents_();Blockly.utils.isRightButton(a)||(this.workspace_.startDrag(a,new goog.math.Coordinate(this.workspace_.RTL?-this.width_:this.width_,this.height_)),Blockly.Bubble.onMouseUpWrapper_=Blockly.bindEventWithChecks_(document,"mouseup",this,Blockly.Bubble.bubbleMouseUp_),Blockly.Bubble.onMouseMoveWrapper_=Blockly.bindEventWithChecks_(document,"mousemove",this,this.resizeMouseMove_),Blockly.hideChaff());
a.stopPropagation()};Blockly.Bubble.prototype.resizeMouseMove_=function(a){this.autoLayout_=!1;a=this.workspace_.moveDrag(a);this.setBubbleSize(this.workspace_.RTL?-a.x:a.x,a.y);this.workspace_.RTL&&this.positionBubble_()};Blockly.Bubble.prototype.registerResizeEvent=function(a){this.resizeCallback_=a};Blockly.Bubble.prototype.promote_=function(){var a=this.bubbleGroup_.parentNode;return a.lastChild!==this.bubbleGroup_?(a.appendChild(this.bubbleGroup_),!0):!1};
Blockly.Bubble.prototype.setAnchorLocation=function(a){this.anchorXY_=a;this.rendered_&&this.positionBubble_()};
Blockly.Bubble.prototype.layoutBubble_=function(){var a=-this.width_/4,b=-this.height_-Blockly.BlockSvg.MIN_BLOCK_Y,c=this.workspace_.getMetrics();c.viewWidth/=this.workspace_.scale;c.viewLeft/=this.workspace_.scale;var d=this.anchorXY_.x;this.workspace_.RTL?d-c.viewLeft-a-this.width_<Blockly.Scrollbar.scrollbarThickness?a=d-c.viewLeft-this.width_-Blockly.Scrollbar.scrollbarThickness:d-c.viewLeft-a>c.viewWidth&&(a=d-c.viewLeft-c.viewWidth):d+a<c.viewLeft?a=c.viewLeft-d:c.viewLeft+c.viewWidth<d+a+
this.width_+Blockly.BlockSvg.SEP_SPACE_X+Blockly.Scrollbar.scrollbarThickness&&(a=c.viewLeft+c.viewWidth-d-this.width_-Blockly.Scrollbar.scrollbarThickness);this.anchorXY_.y+b<c.viewTop&&(b=this.shape_.getBBox().height);this.relativeLeft_=a;this.relativeTop_=b};Blockly.Bubble.prototype.positionBubble_=function(){var a=this.anchorXY_.x;a=this.workspace_.RTL?a-this.relativeLeft_:a+this.relativeLeft_;this.moveTo(a,this.relativeTop_+this.anchorXY_.y)};
Blockly.Bubble.prototype.moveTo=function(a,b){this.bubbleGroup_.setAttribute("transform","translate("+a+","+b+")")};Blockly.Bubble.prototype.getBubbleSize=function(){return{width:this.width_,height:this.height_}};
Blockly.Bubble.prototype.setBubbleSize=function(a,b){var c=2*Blockly.Bubble.BORDER_WIDTH;a=Math.max(a,c+45);b=Math.max(b,c+20);this.width_=a;this.height_=b;this.bubbleBack_.setAttribute("width",a);this.bubbleBack_.setAttribute("height",b);this.resizeGroup_&&(this.workspace_.RTL?this.resizeGroup_.setAttribute("transform","translate("+2*Blockly.Bubble.BORDER_WIDTH+","+(b-c)+") scale(-1 1)"):this.resizeGroup_.setAttribute("transform","translate("+(a-c)+","+(b-c)+")"));this.rendered_&&(this.autoLayout_&&
this.layoutBubble_(),this.positionBubble_(),this.renderArrow_());this.resizeCallback_&&this.resizeCallback_()};
Blockly.Bubble.prototype.renderArrow_=function(){var a=[],b=this.width_/2,c=this.height_/2,d=-this.relativeLeft_,e=-this.relativeTop_;if(b==d&&c==e)a.push("M "+b+","+c);else{e-=c;d-=b;this.workspace_.RTL&&(d*=-1);var f=Math.sqrt(e*e+d*d),g=Math.acos(d/f);0>e&&(g=2*Math.PI-g);var h=g+Math.PI/2;h>2*Math.PI&&(h-=2*Math.PI);var k=Math.sin(h),l=Math.cos(h),m=this.getBubbleSize();h=(m.width+m.height)/Blockly.Bubble.ARROW_THICKNESS;h=Math.min(h,m.width,m.height)/4;m=1-Blockly.Bubble.ANCHOR_RADIUS/f;d=b+
m*d;e=c+m*e;m=b+h*l;var n=c+h*k;b-=h*l;c-=h*k;k=g+this.arrow_radians_;k>2*Math.PI&&(k-=2*Math.PI);g=Math.sin(k)*f/Blockly.Bubble.ARROW_BEND;f=Math.cos(k)*f/Blockly.Bubble.ARROW_BEND;a.push("M"+m+","+n);a.push("C"+(m+f)+","+(n+g)+" "+d+","+e+" "+d+","+e);a.push("C"+d+","+e+" "+(b+f)+","+(c+g)+" "+b+","+c)}a.push("z");this.bubbleArrow_.setAttribute("d",a.join(" "))};Blockly.Bubble.prototype.setColour=function(a){this.bubbleBack_.setAttribute("fill",a);this.bubbleArrow_.setAttribute("fill",a)};
Blockly.Bubble.prototype.dispose=function(){Blockly.Bubble.unbindDragEvents_();goog.dom.removeNode(this.bubbleGroup_);this.shape_=this.content_=this.workspace_=this.resizeGroup_=this.bubbleBack_=this.bubbleArrow_=this.bubbleGroup_=null};Blockly.Bubble.prototype.moveDuringDrag=function(a,b){a?a.translateSurface(b.x,b.y):this.moveTo(b.x,b.y);this.relativeLeft_=this.workspace_.RTL?this.anchorXY_.x-b.x-this.width_:b.x-this.anchorXY_.x;this.relativeTop_=b.y-this.anchorXY_.y;this.renderArrow_()};
Blockly.Bubble.prototype.getRelativeToSurfaceXY=function(){return new goog.math.Coordinate(this.workspace_.RTL?this.anchorXY_.x-this.relativeLeft_:this.anchorXY_.x+this.relativeLeft_,this.anchorXY_.y+this.relativeTop_)};Blockly.Bubble.prototype.setAutoLayout=function(a){this.autoLayout_=a};Blockly.Events.BlockBase=function(a){Blockly.Events.BlockBase.superClass_.constructor.call(this);this.blockId=a.id;this.workspaceId=a.workspace.id};goog.inherits(Blockly.Events.BlockBase,Blockly.Events.Abstract);Blockly.Events.BlockBase.prototype.toJson=function(){var a=Blockly.Events.BlockBase.superClass_.toJson.call(this);a.blockId=this.blockId;return a};Blockly.Events.BlockBase.prototype.fromJson=function(a){Blockly.Events.BlockBase.superClass_.toJson.call(this);this.blockId=a.blockId};
Blockly.Events.Change=function(a,b,c,d,e){a&&(Blockly.Events.Change.superClass_.constructor.call(this,a),this.element=b,this.name=c,this.oldValue=d,this.newValue=e)};goog.inherits(Blockly.Events.Change,Blockly.Events.BlockBase);Blockly.Events.BlockChange=Blockly.Events.Change;Blockly.Events.Change.prototype.type=Blockly.Events.CHANGE;
Blockly.Events.Change.prototype.toJson=function(){var a=Blockly.Events.Change.superClass_.toJson.call(this);a.element=this.element;this.name&&(a.name=this.name);a.newValue=this.newValue;return a};Blockly.Events.Change.prototype.fromJson=function(a){Blockly.Events.Change.superClass_.fromJson.call(this,a);this.element=a.element;this.name=a.name;this.newValue=a.newValue};Blockly.Events.Change.prototype.isNull=function(){return this.oldValue==this.newValue};
Blockly.Events.Change.prototype.run=function(a){var b=this.getEventWorkspace_().getBlockById(this.blockId);if(b)switch(b.mutator&&b.mutator.setVisible(!1),a=a?this.newValue:this.oldValue,this.element){case "field":(b=b.getField(this.name))?(b.callValidator(a),b.setValue(a)):console.warn("Can't set non-existent field: "+this.name);break;case "comment":b.setCommentText(a||null);break;case "collapsed":b.setCollapsed(a);break;case "disabled":b.setDisabled(a);break;case "inline":b.setInputsInline(a);break;
case "mutation":var c="";b.mutationToDom&&(c=(c=b.mutationToDom())&&Blockly.Xml.domToText(c));if(b.domToMutation){a=a||"<mutation></mutation>";var d=Blockly.Xml.textToDom("<xml>"+a+"</xml>");b.domToMutation(d.firstChild)}Blockly.Events.fire(new Blockly.Events.Change(b,"mutation",null,c,a));break;default:console.warn("Unknown change type: "+this.element)}else console.warn("Can't change non-existent block: "+this.blockId)};
Blockly.Events.Create=function(a){a&&(Blockly.Events.Create.superClass_.constructor.call(this,a),this.xml=a.workspace.rendered?Blockly.Xml.blockToDomWithXY(a):Blockly.Xml.blockToDom(a),this.ids=Blockly.Events.getDescendantIds_(a))};goog.inherits(Blockly.Events.Create,Blockly.Events.BlockBase);Blockly.Events.BlockCreate=Blockly.Events.Create;Blockly.Events.Create.prototype.type=Blockly.Events.CREATE;
Blockly.Events.Create.prototype.toJson=function(){var a=Blockly.Events.Create.superClass_.toJson.call(this);a.xml=Blockly.Xml.domToText(this.xml);a.ids=this.ids;return a};Blockly.Events.Create.prototype.fromJson=function(a){Blockly.Events.Create.superClass_.fromJson.call(this,a);this.xml=Blockly.Xml.textToDom("<xml>"+a.xml+"</xml>").firstChild;this.ids=a.ids};
Blockly.Events.Create.prototype.run=function(a){var b=this.getEventWorkspace_();if(a)a=goog.dom.createDom("xml"),a.appendChild(this.xml),Blockly.Xml.domToWorkspace(a,b);else{a=0;for(var c;c=this.ids[a];a++){var d=b.getBlockById(c);d?d.dispose(!1,!1):c==this.blockId&&console.warn("Can't uncreate non-existent block: "+c)}}};
Blockly.Events.Delete=function(a){if(a){if(a.getParent())throw"Connected blocks cannot be deleted.";Blockly.Events.Delete.superClass_.constructor.call(this,a);this.oldXml=a.workspace.rendered?Blockly.Xml.blockToDomWithXY(a):Blockly.Xml.blockToDom(a);this.ids=Blockly.Events.getDescendantIds_(a)}};goog.inherits(Blockly.Events.Delete,Blockly.Events.BlockBase);Blockly.Events.BlockDelete=Blockly.Events.Delete;Blockly.Events.Delete.prototype.type=Blockly.Events.DELETE;
Blockly.Events.Delete.prototype.toJson=function(){var a=Blockly.Events.Delete.superClass_.toJson.call(this);a.ids=this.ids;return a};Blockly.Events.Delete.prototype.fromJson=function(a){Blockly.Events.Delete.superClass_.fromJson.call(this,a);this.ids=a.ids};
Blockly.Events.Delete.prototype.run=function(a){var b=this.getEventWorkspace_();if(a){a=0;for(var c;c=this.ids[a];a++){var d=b.getBlockById(c);d?d.dispose(!1,!1):c==this.blockId&&console.warn("Can't delete non-existent block: "+c)}}else a=goog.dom.createDom("xml"),a.appendChild(this.oldXml),Blockly.Xml.domToWorkspace(a,b)};
Blockly.Events.Move=function(a){a&&(Blockly.Events.Move.superClass_.constructor.call(this,a),a=this.currentLocation_(),this.oldParentId=a.parentId,this.oldInputName=a.inputName,this.oldCoordinate=a.coordinate)};goog.inherits(Blockly.Events.Move,Blockly.Events.BlockBase);Blockly.Events.BlockMove=Blockly.Events.Move;Blockly.Events.Move.prototype.type=Blockly.Events.MOVE;
Blockly.Events.Move.prototype.toJson=function(){var a=Blockly.Events.Move.superClass_.toJson.call(this);this.newParentId&&(a.newParentId=this.newParentId);this.newInputName&&(a.newInputName=this.newInputName);this.newCoordinate&&(a.newCoordinate=Math.round(this.newCoordinate.x)+","+Math.round(this.newCoordinate.y));return a};
Blockly.Events.Move.prototype.fromJson=function(a){Blockly.Events.Move.superClass_.fromJson.call(this,a);this.newParentId=a.newParentId;this.newInputName=a.newInputName;a.newCoordinate&&(a=a.newCoordinate.split(","),this.newCoordinate=new goog.math.Coordinate(parseFloat(a[0]),parseFloat(a[1])))};Blockly.Events.Move.prototype.recordNew=function(){var a=this.currentLocation_();this.newParentId=a.parentId;this.newInputName=a.inputName;this.newCoordinate=a.coordinate};
Blockly.Events.Move.prototype.currentLocation_=function(){var a=Blockly.Workspace.getById(this.workspaceId),b=a.getBlockById(this.blockId),c={},d=b.getParent();if(d){if(c.parentId=d.id,a=d.getInputWithBlock(b))c.inputName=a.name}else b=b.getRelativeToSurfaceXY(),a=a.RTL?a.getWidth()-b.x:b.x,c.coordinate=new goog.math.Coordinate(a,b.y);return c};
Blockly.Events.Move.prototype.isNull=function(){return this.oldParentId==this.newParentId&&this.oldInputName==this.newInputName&&goog.math.Coordinate.equals(this.oldCoordinate,this.newCoordinate)};
Blockly.Events.Move.prototype.run=function(a){var b=this.getEventWorkspace_(),c=b.getBlockById(this.blockId);if(c){var d=a?this.newParentId:this.oldParentId,e=a?this.newInputName:this.oldInputName;a=a?this.newCoordinate:this.oldCoordinate;var f=null;if(d&&(f=b.getBlockById(d),!f)){console.warn("Can't connect to non-existent block: "+d);return}c.getParent()&&c.unplug();if(a){e=c.getRelativeToSurfaceXY();var g=b.RTL?b.getWidth()-a.x:a.x;c.moveBy(g-e.x,a.y-e.y)}else{c=c.outputConnection||c.previousConnection;
if(e){if(b=f.getInput(e))g=b.connection}else c.type==Blockly.PREVIOUS_STATEMENT&&(g=f.nextConnection);g?c.connect(g):console.warn("Can't connect to non-existent input: "+e)}}else console.warn("Can't move non-existent block: "+this.blockId)};Blockly.Events.Ui=function(a,b,c,d){Blockly.Events.Ui.superClass_.constructor.call(this);this.blockId=a?a.id:null;this.workspaceId=a?a.workspace.id:null;this.element=b;this.oldValue=c;this.newValue=d;this.recordUndo=!1};goog.inherits(Blockly.Events.Ui,Blockly.Events.Abstract);Blockly.Events.Ui.prototype.type=Blockly.Events.UI;
Blockly.Events.Ui.prototype.toJson=function(){var a=Blockly.Events.Ui.superClass_.toJson.call(this);a.element=this.element;void 0!==this.newValue&&(a.newValue=this.newValue);this.blockId&&(a.blockId=this.blockId);return a};Blockly.Events.Ui.prototype.fromJson=function(a){Blockly.Events.Ui.superClass_.fromJson.call(this,a);this.element=a.element;this.newValue=a.newValue;this.blockId=a.blockId};Blockly.Icon=function(a){this.block_=a};Blockly.Icon.prototype.collapseHidden=!0;Blockly.Icon.prototype.SIZE=17;Blockly.Icon.prototype.bubble_=null;Blockly.Icon.prototype.iconXY_=null;
Blockly.Icon.prototype.createIcon=function(){this.iconGroup_||(this.iconGroup_=Blockly.utils.createSvgElement("g",{"class":"blocklyIconGroup"},null),this.block_.isInFlyout&&Blockly.utils.addClass(this.iconGroup_,"blocklyIconGroupReadonly"),this.drawIcon_(this.iconGroup_),this.block_.getSvgRoot().appendChild(this.iconGroup_),Blockly.bindEventWithChecks_(this.iconGroup_,"mouseup",this,this.iconClick_),this.updateEditable())};
Blockly.Icon.prototype.dispose=function(){goog.dom.removeNode(this.iconGroup_);this.iconGroup_=null;this.setVisible(!1);this.block_=null};Blockly.Icon.prototype.updateEditable=function(){};Blockly.Icon.prototype.isVisible=function(){return!!this.bubble_};Blockly.Icon.prototype.iconClick_=function(a){this.block_.workspace.isDragging()||this.block_.isInFlyout||Blockly.utils.isRightButton(a)||this.setVisible(!this.isVisible())};Blockly.Icon.prototype.updateColour=function(){this.isVisible()&&this.bubble_.setColour(this.block_.getColour())};
Blockly.Icon.prototype.renderIcon=function(a){if(this.collapseHidden&&this.block_.isCollapsed())return this.iconGroup_.setAttribute("display","none"),a;this.iconGroup_.setAttribute("display","block");var b=this.SIZE;this.block_.RTL&&(a-=b);this.iconGroup_.setAttribute("transform","translate("+a+",5)");this.computeIconLocation();return a=this.block_.RTL?a-Blockly.BlockSvg.SEP_SPACE_X:a+(b+Blockly.BlockSvg.SEP_SPACE_X)};
Blockly.Icon.prototype.setIconLocation=function(a){this.iconXY_=a;this.isVisible()&&this.bubble_.setAnchorLocation(a)};Blockly.Icon.prototype.computeIconLocation=function(){var a=this.block_.getRelativeToSurfaceXY(),b=Blockly.utils.getRelativeXY(this.iconGroup_);a=new goog.math.Coordinate(a.x+b.x+this.SIZE/2,a.y+b.y+this.SIZE/2);goog.math.Coordinate.equals(this.getIconLocation(),a)||this.setIconLocation(a)};Blockly.Icon.prototype.getIconLocation=function(){return this.iconXY_};
Blockly.Comment=function(a){Blockly.Comment.superClass_.constructor.call(this,a);this.createIcon()};goog.inherits(Blockly.Comment,Blockly.Icon);Blockly.Comment.prototype.text_="";Blockly.Comment.prototype.width_=160;Blockly.Comment.prototype.height_=80;
Blockly.Comment.prototype.drawIcon_=function(a){Blockly.utils.createSvgElement("circle",{"class":"blocklyIconShape",r:"8",cx:"8",cy:"8"},a);Blockly.utils.createSvgElement("path",{"class":"blocklyIconSymbol",d:"m6.8,10h2c0.003,-0.617 0.271,-0.962 0.633,-1.266 2.875,-2.4050.607,-5.534 -3.765,-3.874v1.7c3.12,-1.657 3.698,0.118 2.336,1.25-1.201,0.998 -1.201,1.528 -1.204,2.19z"},a);Blockly.utils.createSvgElement("rect",{"class":"blocklyIconSymbol",x:"6.8",y:"10.78",height:"2",width:"2"},a)};
Blockly.Comment.prototype.createEditor_=function(){this.foreignObject_=Blockly.utils.createSvgElement("foreignObject",{x:Blockly.Bubble.BORDER_WIDTH,y:Blockly.Bubble.BORDER_WIDTH},null);var a=document.createElementNS(Blockly.HTML_NS,"body");a.setAttribute("xmlns",Blockly.HTML_NS);a.className="blocklyMinimalBody";var b=document.createElementNS(Blockly.HTML_NS,"textarea");b.className="blocklyCommentTextarea";b.setAttribute("dir",this.block_.RTL?"RTL":"LTR");a.appendChild(b);this.textarea_=b;this.foreignObject_.appendChild(a);
Blockly.bindEventWithChecks_(b,"mouseup",this,this.textareaFocus_);Blockly.bindEventWithChecks_(b,"wheel",this,function(a){a.stopPropagation()});Blockly.bindEventWithChecks_(b,"change",this,function(a){this.text_!=b.value&&(Blockly.Events.fire(new Blockly.Events.BlockChange(this.block_,"comment",null,this.text_,b.value)),this.text_=b.value)});setTimeout(function(){b.focus()},0);return this.foreignObject_};
Blockly.Comment.prototype.updateEditable=function(){this.isVisible()&&(this.setVisible(!1),this.setVisible(!0));Blockly.Icon.prototype.updateEditable.call(this)};Blockly.Comment.prototype.resizeBubble_=function(){if(this.isVisible()){var a=this.bubble_.getBubbleSize(),b=2*Blockly.Bubble.BORDER_WIDTH;this.foreignObject_.setAttribute("width",a.width-b);this.foreignObject_.setAttribute("height",a.height-b);this.textarea_.style.width=a.width-b-4+"px";this.textarea_.style.height=a.height-b-4+"px"}};
Blockly.Comment.prototype.setVisible=function(a){if(a!=this.isVisible())if(Blockly.Events.fire(new Blockly.Events.Ui(this.block_,"commentOpen",!a,a)),!this.block_.isEditable()&&!this.textarea_||goog.userAgent.IE)Blockly.Warning.prototype.setVisible.call(this,a);else{var b=this.getText(),c=this.getBubbleSize();a?(this.bubble_=new Blockly.Bubble(this.block_.workspace,this.createEditor_(),this.block_.svgPath_,this.iconXY_,this.width_,this.height_),this.bubble_.setSvgId(this.block_.id),this.bubble_.registerResizeEvent(this.resizeBubble_.bind(this)),
this.updateColour()):(this.bubble_.dispose(),this.foreignObject_=this.textarea_=this.bubble_=null);this.setText(b);this.setBubbleSize(c.width,c.height)}};Blockly.Comment.prototype.textareaFocus_=function(a){this.bubble_.promote_()&&this.textarea_.focus()};Blockly.Comment.prototype.getBubbleSize=function(){return this.isVisible()?this.bubble_.getBubbleSize():{width:this.width_,height:this.height_}};
Blockly.Comment.prototype.setBubbleSize=function(a,b){this.textarea_?this.bubble_.setBubbleSize(a,b):(this.width_=a,this.height_=b)};Blockly.Comment.prototype.getText=function(){return this.textarea_?this.textarea_.value:this.text_};Blockly.Comment.prototype.setText=function(a){this.text_!=a&&(Blockly.Events.fire(new Blockly.Events.BlockChange(this.block_,"comment",null,this.text_,a)),this.text_=a);this.textarea_&&(this.textarea_.value=a)};
Blockly.Comment.prototype.dispose=function(){Blockly.Events.isEnabled()&&this.setText("");this.block_.comment=null;Blockly.Icon.prototype.dispose.call(this)};Blockly.ScratchBubble=function(a,b,c,d,e,f,g,h,k){this.comment=a;this.workspace_=b;this.content_=c;this.x=g;this.y=h;this.isMinimized_=k||!1;b.getBubbleCanvas().appendChild(this.createDom_(c,!(!e||!f),this.isMinimized_));this.setAnchorLocation(d);e&&f||(a=this.content_.getBBox(),e=a.width+2*Blockly.ScratchBubble.BORDER_WIDTH,f=a.height+2*Blockly.ScratchBubble.BORDER_WIDTH);this.setBubbleSize(e,f);this.positionBubble_();this.renderArrow_();this.rendered_=!0;b.options.readOnly||(Blockly.bindEventWithChecks_(this.minimizeArrow_,
"mousedown",this,this.minimizeArrowMouseDown_,!0),Blockly.bindEventWithChecks_(this.minimizeArrow_,"mouseout",this,this.minimizeArrowMouseOut_,!0),Blockly.bindEventWithChecks_(this.minimizeArrow_,"mouseup",this,this.minimizeArrowMouseUp_,!0),Blockly.bindEventWithChecks_(this.deleteIcon_,"mousedown",this,this.deleteMouseDown_,!0),Blockly.bindEventWithChecks_(this.deleteIcon_,"mouseout",this,this.deleteMouseOut_,!0),Blockly.bindEventWithChecks_(this.deleteIcon_,"mouseup",this,this.deleteMouseUp_,!0),
Blockly.bindEventWithChecks_(this.commentTopBar_,"mousedown",this,this.bubbleMouseDown_),Blockly.bindEventWithChecks_(this.bubbleBack_,"mousedown",this,this.bubbleMouseDown_),this.resizeGroup_&&(Blockly.bindEventWithChecks_(this.resizeGroup_,"mousedown",this,this.resizeMouseDown_),Blockly.bindEventWithChecks_(this.resizeGroup_,"mouseup",this,this.resizeMouseUp_)));this.setAutoLayout(!1);this.moveTo(this.x,this.y)};goog.inherits(Blockly.ScratchBubble,Blockly.Bubble);
Blockly.ScratchBubble.BORDER_WIDTH=1;Blockly.ScratchBubble.LINE_THICKNESS=1;Blockly.ScratchBubble.TOP_BAR_HEIGHT=32;Blockly.ScratchBubble.MINIMIZE_ICON_SIZE=32;Blockly.ScratchBubble.DELETE_ICON_SIZE=32;Blockly.ScratchBubble.TOP_BAR_ICON_INSET=0;Blockly.ScratchBubble.RESIZE_SIZE=16;Blockly.ScratchBubble.RESIZE_CORNER_PAD=4;Blockly.ScratchBubble.RESIZE_OUTER_PAD=8;
Blockly.ScratchBubble.prototype.createDom_=function(a,b,c){this.bubbleGroup_=Blockly.utils.createSvgElement("g",{},null);this.bubbleArrow_=Blockly.utils.createSvgElement("line",{"stroke-linecap":"round"},this.bubbleGroup_);this.bubbleBack_=Blockly.utils.createSvgElement("rect",{"class":"blocklyDraggable scratchCommentRect",x:0,y:0,rx:4*Blockly.ScratchBubble.BORDER_WIDTH,ry:4*Blockly.ScratchBubble.BORDER_WIDTH},this.bubbleGroup_);this.labelText_=a.labelText;this.createCommentTopBar_();this.commentEditor_=
a.commentEditor;this.bubbleGroup_.appendChild(this.commentEditor_);b?this.createResizeHandle_():this.resizeGroup_=null;c?(this.minimizeArrow_.setAttributeNS("http://www.w3.org/1999/xlink","xlink:href",Blockly.mainWorkspace.options.pathToMedia+"comment-arrow-up.svg"),this.commentEditor_.setAttribute("display","none"),this.resizeGroup_.setAttribute("display","none")):(this.minimizeArrow_.setAttributeNS("http://www.w3.org/1999/xlink","xlink:href",Blockly.mainWorkspace.options.pathToMedia+"comment-arrow-down.svg"),
this.topBarLabel_.setAttribute("display","none"));return this.bubbleGroup_};Blockly.ScratchBubble.prototype.createCommentTopBar_=function(){this.commentTopBar_=Blockly.utils.createSvgElement("rect",{"class":"blocklyDraggable scratchCommentTopBar",rx:Blockly.ScratchBubble.BORDER_WIDTH,ry:Blockly.ScratchBubble.BORDER_WIDTH,height:Blockly.ScratchBubble.TOP_BAR_HEIGHT},this.bubbleGroup_);this.createTopBarIcons_();this.createTopBarLabel_()};
Blockly.ScratchBubble.prototype.createTopBarIcons_=function(){var a=Blockly.ScratchBubble.TOP_BAR_HEIGHT/2+Blockly.ScratchBubble.BORDER_WIDTH,b=Blockly.ScratchBubble.TOP_BAR_ICON_INSET;this.minimizeArrow_=Blockly.utils.createSvgElement("image",{x:b,y:a-Blockly.ScratchBubble.MINIMIZE_ICON_SIZE/2,width:Blockly.ScratchBubble.MINIMIZE_ICON_SIZE,height:Blockly.ScratchBubble.MINIMIZE_ICON_SIZE},this.bubbleGroup_);this.deleteIcon_=Blockly.utils.createSvgElement("image",{x:b,y:a-Blockly.ScratchBubble.DELETE_ICON_SIZE/
2,width:Blockly.ScratchBubble.DELETE_ICON_SIZE,height:Blockly.ScratchBubble.DELETE_ICON_SIZE},this.bubbleGroup_);this.deleteIcon_.setAttributeNS("http://www.w3.org/1999/xlink","xlink:href",Blockly.mainWorkspace.options.pathToMedia+"delete-x.svg")};
Blockly.ScratchBubble.prototype.createTopBarLabel_=function(){this.topBarLabel_=Blockly.utils.createSvgElement("text",{"class":"scratchCommentText",x:this.width_/2,y:Blockly.ScratchBubble.TOP_BAR_HEIGHT/2+Blockly.ScratchBubble.BORDER_WIDTH,"text-anchor":"middle","dominant-baseline":"middle"},this.bubbleGroup_);var a=document.createTextNode(this.labelText_);this.topBarLabel_.appendChild(a)};
Blockly.ScratchBubble.prototype.createResizeHandle_=function(){this.resizeGroup_=Blockly.utils.createSvgElement("g",{"class":this.workspace_.RTL?"scratchCommentResizeSW":"scratchCommentResizeSE"},this.bubbleGroup_);var a=Blockly.ScratchBubble.RESIZE_SIZE,b=Blockly.ScratchBubble.RESIZE_OUTER_PAD,c=Blockly.ScratchBubble.RESIZE_CORNER_PAD;Blockly.utils.createSvgElement("polygon",{points:[-b,a+c,a+c,a+c,a+c,-b].join(" ")},this.resizeGroup_);Blockly.utils.createSvgElement("line",{"class":"blocklyResizeLine",
x1:a/3,y1:a-1,x2:a-1,y2:a/3},this.resizeGroup_);Blockly.utils.createSvgElement("line",{"class":"blocklyResizeLine",x1:2*a/3,y1:a-1,x2:a-1,y2:2*a/3},this.resizeGroup_)};Blockly.ScratchBubble.prototype.showContextMenu_=function(a){this.workspace_.options.readOnly||this.contextMenuCallback_&&this.contextMenuCallback_(a)};Blockly.ScratchBubble.prototype.minimizeArrowMouseDown_=function(a){this.shouldToggleMinimize_=!0;a.stopPropagation()};
Blockly.ScratchBubble.prototype.minimizeArrowMouseOut_=function(a){this.shouldToggleMinimize_=!1};Blockly.ScratchBubble.prototype.minimizeArrowMouseUp_=function(a){this.shouldToggleMinimize_&&(this.shouldToggleMinimize_=!1,this.minimizeToggleCallback_&&this.minimizeToggleCallback_.call(this));a.stopPropagation()};Blockly.ScratchBubble.prototype.deleteMouseDown_=function(a){this.shouldDelete_=!0;a.stopPropagation()};Blockly.ScratchBubble.prototype.deleteMouseOut_=function(a){this.shouldDelete_=!1};
Blockly.ScratchBubble.prototype.deleteMouseUp_=function(a){this.shouldDelete_&&(this.shouldDelete_=!1,this.deleteCallback_&&this.deleteCallback_.call(this));a.stopPropagation()};Blockly.ScratchBubble.prototype.resizeMouseDown_=function(a){this.resizeStartSize_={width:this.width_,height:this.height_};this.workspace_.setResizesEnabled(!1);Blockly.ScratchBubble.superClass_.resizeMouseDown_.call(this,a)};
Blockly.ScratchBubble.prototype.resizeMouseUp_=function(a){a=this.resizeStartSize_;this.resizeStartSize_=null;if(this.width_!=a.width||this.height_!=a.height)Blockly.Events.fire(new Blockly.Events.CommentChange(this.comment,{width:a.width,height:a.height},{width:this.width_,height:this.height_})),this.workspace_.setResizesEnabled(!0)};
Blockly.ScratchBubble.prototype.setMinimized=function(a,b){a!=this.isMinimized_&&(a?(this.isMinimized_=!0,this.minimizeArrow_.setAttributeNS("http://www.w3.org/1999/xlink","xlink:href",Blockly.mainWorkspace.options.pathToMedia+"comment-arrow-up.svg"),this.commentEditor_.setAttribute("display","none"),this.resizeGroup_&&this.resizeGroup_.setAttribute("display","none"),b&&this.labelText_!=b&&(this.topBarLabel_.textContent=b),Blockly.utils.removeAttribute(this.topBarLabel_,"display")):(this.isMinimized_=
!1,this.minimizeArrow_.setAttributeNS("http://www.w3.org/1999/xlink","xlink:href",Blockly.mainWorkspace.options.pathToMedia+"comment-arrow-down.svg"),this.topBarLabel_.setAttribute("display","none"),Blockly.utils.removeAttribute(this.commentEditor_,"display"),this.resizeGroup_&&Blockly.utils.removeAttribute(this.resizeGroup_,"display")))};Blockly.ScratchBubble.prototype.registerMinimizeToggleEvent=function(a){this.minimizeToggleCallback_=a};
Blockly.ScratchBubble.prototype.registerDeleteEvent=function(a){this.deleteCallback_=a};Blockly.ScratchBubble.prototype.registerContextMenuCallback=function(a){this.contextMenuCallback_=a};Blockly.ScratchBubble.prototype.setAnchorLocation=function(a){this.anchorXY_=a;this.rendered_&&this.positionBubble_()};Blockly.ScratchBubble.prototype.moveTo=function(a,b){Blockly.ScratchBubble.superClass_.moveTo.call(this,a,b);this.updatePosition_(a,b)};
Blockly.ScratchBubble.prototype.setBubbleSize=function(a,b){var c=2*Blockly.ScratchBubble.BORDER_WIDTH;a=Math.max(a,c+50);b=Math.max(b,Blockly.ScratchBubble.TOP_BAR_HEIGHT);this.width_=a;this.height_=b;this.bubbleBack_.setAttribute("width",a);this.bubbleBack_.setAttribute("height",b);this.commentTopBar_.setAttribute("width",a);this.commentTopBar_.setAttribute("height",Blockly.ScratchBubble.TOP_BAR_HEIGHT);this.workspace_.RTL?this.minimizeArrow_.setAttribute("x",a-Blockly.ScratchBubble.MINIMIZE_ICON_SIZE-
Blockly.ScratchBubble.TOP_BAR_ICON_INSET):this.deleteIcon_.setAttribute("x",a-Blockly.ScratchBubble.DELETE_ICON_SIZE-Blockly.ScratchBubble.TOP_BAR_ICON_INSET);this.resizeGroup_&&(a=Blockly.ScratchBubble.RESIZE_SIZE,this.workspace_.RTL?this.resizeGroup_.setAttribute("transform","translate("+(a+c)+","+(this.height_-c-a)+") scale(-1, 1)"):this.resizeGroup_.setAttribute("transform","translate("+(this.width_-c-a)+","+(this.height_-c-a)+")"));this.isMinimized_&&(this.topBarLabel_.setAttribute("x",this.width_/
2),this.topBarLabel_.setAttribute("y",this.height_/2));this.rendered_&&(this.positionBubble_(),this.renderArrow_());this.resizeCallback_&&this.resizeCallback_()};
Blockly.ScratchBubble.prototype.renderArrow_=function(){var a=this.width_/2,b=Blockly.ScratchBubble.TOP_BAR_HEIGHT/2,c=-this.relativeLeft_,d=-this.relativeTop_;if(a!=c||b!=d)d-=b,c-=a,this.workspace_.RTL&&(c=-1*c-this.width_),this.bubbleArrow_.setAttribute("x1",a),this.bubbleArrow_.setAttribute("y1",b),this.bubbleArrow_.setAttribute("x2",a+c),this.bubbleArrow_.setAttribute("y2",b+d),this.bubbleArrow_.setAttribute("stroke-width",Blockly.ScratchBubble.LINE_THICKNESS)};
Blockly.ScratchBubble.prototype.setColour=function(a){this.bubbleBack_.setAttribute("stroke",a);this.bubbleArrow_.setAttribute("stroke",a)};Blockly.ScratchBubble.prototype.moveDuringDrag=function(a,b){a?(a.translateSurface(b.x,b.y),this.updatePosition_(b.x,b.y)):this.moveTo(b.x,b.y)};Blockly.ScratchBubble.prototype.updatePosition_=function(a,b){this.relativeLeft_=this.workspace_.RTL?this.anchorXY_.x-a:a-this.anchorXY_.x;this.relativeTop_=b-this.anchorXY_.y;this.renderArrow_()};
Blockly.ScratchBubble.prototype.dispose=function(){Blockly.ScratchBubble.superClass_.dispose.call(this);this.deleteIcon_=this.minimizeArrow_=this.commentTopBar_=this.topBarLabel_=null};Blockly.ScratchBlockComment=function(a,b,c,d,e,f){Blockly.ScratchBlockComment.superClass_.constructor.call(this,a);this.text_=b;b="number"==typeof d&&!isNaN(d);var g="number"==typeof e&&!isNaN(e);this.needsAutoPositioning_=!b&&!g;this.x_=b?d:0;this.y_=g?e:0;this.isMinimized_=f||!1;this.workspace=a.workspace;this.id=goog.isString(c)&&!this.workspace.getCommentById(c)?c:Blockly.utils.genUid();this.workspace.addTopComment(this);this.blockId=a.id;a.rendered||Blockly.ScratchBlockComment.fireCreateEvent(this)};
goog.inherits(Blockly.ScratchBlockComment,Blockly.Comment);Blockly.ScratchBlockComment.prototype.width_=200;Blockly.ScratchBlockComment.prototype.height_=200;Blockly.ScratchBlockComment.prototype.SIZE=0;Blockly.ScratchBlockComment.TEXTAREA_OFFSET=12;Blockly.ScratchBlockComment.MAX_LABEL_LENGTH=12;Blockly.ScratchBlockComment.COMMENT_TEXT_LIMIT=8E3;Blockly.ScratchBlockComment.MINIMIZE_WIDTH=200;Blockly.ScratchBlockComment.prototype.drawIcon_=function(a){};
Blockly.ScratchBlockComment.prototype.renderIcon=function(a,b){if(this.collapseHidden&&this.block_.isCollapsed())return this.iconGroup_.setAttribute("display","none"),a;this.iconGroup_.setAttribute("display","block");var c=this.SIZE;this.block_.RTL&&(a-=c);this.iconGroup_.setAttribute("transform","translate("+a+","+b+")");this.computeIconLocation();return a=this.block_.RTL?a-Blockly.BlockSvg.SEP_SPACE_X:a+(c+Blockly.BlockSvg.SEP_SPACE_X)};
Blockly.ScratchBlockComment.prototype.createEditor_=function(){this.foreignObject_=Blockly.utils.createSvgElement("foreignObject",{x:Blockly.ScratchBubble.BORDER_WIDTH,y:Blockly.ScratchBubble.BORDER_WIDTH+Blockly.ScratchBubble.TOP_BAR_HEIGHT,"class":"scratchCommentForeignObject"},null);var a=document.createElementNS(Blockly.HTML_NS,"body");a.setAttribute("xmlns",Blockly.HTML_NS);a.className="blocklyMinimalBody scratchCommentBody";var b=document.createElementNS(Blockly.HTML_NS,"textarea");b.className=
"scratchCommentTextarea scratchCommentText";b.setAttribute("dir",this.block_.RTL?"RTL":"LTR");b.setAttribute("maxlength",Blockly.ScratchBlockComment.COMMENT_TEXT_LIMIT);b.setAttribute("placeholder",Blockly.Msg.WORKSPACE_COMMENT_DEFAULT_TEXT);a.appendChild(b);this.textarea_=b;this.textarea_.style.margin=Blockly.ScratchBlockComment.TEXTAREA_OFFSET+"px";this.foreignObject_.appendChild(a);Blockly.bindEventWithChecks_(b,"mousedown",this,this.textareaFocus_,!0,!0);Blockly.bindEventWithChecks_(b,"wheel",
this,function(a){a.stopPropagation()});Blockly.bindEventWithChecks_(b,"change",this,function(a){this.text_!=b.value&&(Blockly.Events.fire(new Blockly.Events.CommentChange(this,{text:this.text_},{text:b.value})),this.text_=b.value)});this.label_=this.getLabelText();return{commentEditor:this.foreignObject_,labelText:this.label_}};Blockly.ScratchBlockComment.prototype.textareaFocus_=function(a){Blockly.ScratchBlockComment.superClass_.textareaFocus_.call(this,a);a.stopPropagation()};
Blockly.ScratchBlockComment.prototype.resizeBubble_=function(){if(this.isVisible()&&!this.isMinimized_){var a=this.bubble_.getBubbleSize(),b=2*Blockly.ScratchBubble.BORDER_WIDTH,c=2*Blockly.ScratchBlockComment.TEXTAREA_OFFSET;this.foreignObject_.setAttribute("width",a.width-b);this.foreignObject_.setAttribute("height",a.height-b-Blockly.ScratchBubble.TOP_BAR_HEIGHT);this.textarea_.style.width=a.width-c+"px";this.textarea_.style.height=a.height-b-Blockly.ScratchBubble.TOP_BAR_HEIGHT-c+"px";this.width_=
a.width;this.height_=a.height}};Blockly.ScratchBlockComment.prototype.updateColour=function(){this.isVisible()&&this.bubble_.setColour(this.block_.getColourTertiary())};
Blockly.ScratchBlockComment.prototype.autoPosition_=function(){if(this.needsAutoPositioning_){if(this.isMinimized_){var a=4*Blockly.BlockSvg.GRID_UNIT;this.x_=this.block_.RTL?this.iconXY_.x-this.getBubbleSize().width-a:this.iconXY_.x+a}else{a=Math.floor(this.block_.svgPath_.getBBox().width);a=Math.floor(this.block_.getHeightWidth().width)-a;var b=8*Blockly.BlockSvg.GRID_UNIT;this.x_=this.block_.RTL?this.iconXY_.x-this.width_-a-b:this.iconXY_.x+a+b}this.y_=this.iconXY_.y-Blockly.ScratchBubble.TOP_BAR_HEIGHT/
2}};
Blockly.ScratchBlockComment.prototype.setVisible=function(a){if(a!=this.isVisible())if(!this.block_.isEditable()&&!this.textarea_||goog.userAgent.IE)Blockly.Warning.prototype.setVisible.call(this,a);else{var b=this.getText(),c=this.getBubbleSize();a?(this.needsAutoPositioning_&&(this.autoPosition_(),this.needsAutoPositioning_=!1),this.bubble_=new Blockly.ScratchBubble(this,this.block_.workspace,this.createEditor_(),this.iconXY_,this.width_,this.height_,this.x_,this.y_,this.isMinimized_),this.bubble_.setAutoLayout(!1),this.bubble_.registerResizeEvent(this.resizeBubble_.bind(this)),
this.bubble_.registerMinimizeToggleEvent(this.toggleMinimize_.bind(this)),this.bubble_.registerDeleteEvent(this.dispose.bind(this)),this.bubble_.registerContextMenuCallback(this.showContextMenu_.bind(this)),this.updateColour()):(this.bubble_.dispose(),this.label_=this.foreignObject_=this.textarea_=this.bubble_=null);this.setText(b);this.setBubbleSize(c.width,c.height);a&&Blockly.ScratchBlockComment.fireCreateEvent(this)}};Blockly.ScratchBlockComment.prototype.toggleMinimize_=function(){this.setMinimized(!this.isMinimized_)};
Blockly.ScratchBlockComment.prototype.setMinimized=function(a){this.isMinimized_!=a&&(Blockly.Events.fire(new Blockly.Events.CommentChange(this,{minimized:this.isMinimized_},{minimized:a})),(this.isMinimized_=a)?(this.bubble_.setMinimized(!0,this.getLabelText()),this.setBubbleSize(Blockly.ScratchBlockComment.MINIMIZE_WIDTH,Blockly.ScratchBubble.TOP_BAR_HEIGHT)):(this.bubble_.setMinimized(!1),this.setText(this.text_),this.setBubbleSize(this.width_,this.height_)))};
Blockly.ScratchBlockComment.prototype.setBubbleSize=function(a,b){this.bubble_&&(this.isMinimized_?this.bubble_.setBubbleSize(Blockly.ScratchBlockComment.MINIMIZE_WIDTH,Blockly.ScratchBubble.TOP_BAR_HEIGHT):this.bubble_.setBubbleSize(a,b))};
Blockly.ScratchBlockComment.prototype.setSize=function(a,b){var c=this.width_,d=this.height_;this.isMinimized_||this.setBubbleSize(a,b);this.height_=b;this.width_=a;c==this.width_&&d==this.height_||Blockly.Events.fire(new Blockly.Events.CommentChange(this,{width:c,height:d},{width:this.width_,height:this.height_}))};
Blockly.ScratchBlockComment.prototype.getLabelText=function(){return this.text_.length>Blockly.ScratchBlockComment.MAX_LABEL_LENGTH?this.block_.RTL?"\u2026"+this.text_.slice(0,Blockly.ScratchBlockComment.MAX_LABEL_LENGTH):this.text_.slice(0,Blockly.ScratchBlockComment.MAX_LABEL_LENGTH)+"\u2026":this.text_};
Blockly.ScratchBlockComment.prototype.setText=function(a){this.text_!=a&&(Blockly.Events.fire(new Blockly.Events.CommentChange(this,{text:this.text_},{text:a})),this.text_=a);this.textarea_&&(this.textarea_.value=a)};Blockly.ScratchBlockComment.prototype.moveTo=function(a,b){var c=new Blockly.Events.CommentMove(this);this.bubble_&&this.bubble_.moveTo(a,b);this.x_=a;this.y_=b;c.recordNew();Blockly.Events.fire(c)};
Blockly.ScratchBlockComment.prototype.getXY=function(){if(this.bubble_)return this.bubble_.getRelativeToSurfaceXY();this.needsAutoPositioning_&&this.iconXY_&&this.autoPosition_();return new goog.math.Coordinate(this.x_,this.y_)};Blockly.ScratchBlockComment.prototype.getHeightWidth=function(){return{height:this.height_,width:this.width_}};
Blockly.ScratchBlockComment.prototype.getBoundingRectangle=function(){var a=this.getXY(),b=this.getBubbleSize();if(this.workspace.RTL){var c=new goog.math.Coordinate(a.x-b.width,a.y);a=new goog.math.Coordinate(a.x,a.y+b.height)}else c=new goog.math.Coordinate(a.x,a.y),a=new goog.math.Coordinate(a.x+b.width,a.y+b.height);return{topLeft:c,bottomRight:a}};Blockly.ScratchBlockComment.prototype.isMinimized=function(){return this.isMinimized_};
Blockly.ScratchBlockComment.prototype.showContextMenu_=function(a){var b=[];b.push(Blockly.ContextMenu.commentDeleteOption(this,Blockly.Msg.DELETE));Blockly.ContextMenu.show(a,b,this.block_.RTL)};
Blockly.ScratchBlockComment.prototype.toXmlWithXY=function(){var a=goog.dom.createDom("comment");a.setAttribute("id",this.id);a.textContent=this.text_;a.setAttribute("x",Math.round(this.workspace.RTL?this.workspace.getWidth()-this.x_:this.x_));a.setAttribute("y",Math.round(this.y_));a.setAttribute("h",this.height_);a.setAttribute("w",this.width_);return a};
Blockly.ScratchBlockComment.fireCreateEvent=function(a){if(Blockly.Events.isEnabled()){var b=Blockly.Events.getGroup();b||Blockly.Events.setGroup(!0);try{Blockly.Events.fire(new Blockly.Events.CommentCreate(a))}finally{b||Blockly.Events.setGroup(!1)}}};Blockly.ScratchBlockComment.prototype.dispose=function(){Blockly.Events.isEnabled()&&Blockly.Events.fire(new Blockly.Events.CommentDelete(this));this.block_.comment=null;this.workspace.removeTopComment(this);Blockly.Icon.prototype.dispose.call(this)};
Blockly.ScratchBlockComment.prototype.focus=function(){this.textarea_.focus()};Blockly.Connection=function(a,b){this.sourceBlock_=a;this.type=b;a.workspace.connectionDBList&&(this.db_=a.workspace.connectionDBList[b],this.dbOpposite_=a.workspace.connectionDBList[Blockly.OPPOSITE_TYPE[b]],this.hidden_=!this.db_)};Blockly.Connection.CAN_CONNECT=0;Blockly.Connection.REASON_SELF_CONNECTION=1;Blockly.Connection.REASON_WRONG_TYPE=2;Blockly.Connection.REASON_TARGET_NULL=3;Blockly.Connection.REASON_CHECKS_FAILED=4;Blockly.Connection.REASON_DIFFERENT_WORKSPACES=5;
Blockly.Connection.REASON_SHADOW_PARENT=6;Blockly.Connection.REASON_CUSTOM_PROCEDURE=7;Blockly.Connection.prototype.targetConnection=null;Blockly.Connection.prototype.check_=null;Blockly.Connection.prototype.shadowDom_=null;Blockly.Connection.prototype.x_=0;Blockly.Connection.prototype.y_=0;Blockly.Connection.prototype.inDB_=!1;Blockly.Connection.prototype.db_=null;Blockly.Connection.prototype.dbOpposite_=null;Blockly.Connection.prototype.hidden_=null;
Blockly.Connection.prototype.connect_=function(a){var b=this,c=b.getSourceBlock(),d=a.getSourceBlock(),e=!1;b==c.getFirstStatementConnection()&&(e=!0);if(a.isConnected()){if(e)var f=a.targetConnection;a.disconnect()}if(b.isConnected()){var g=b.targetBlock(),h=b.getShadowDom();b.setShadowDom(null);if(g.isShadow())h=Blockly.Xml.blockToDom(g),g.dispose(),g=null;else if(b.type==Blockly.NEXT_STATEMENT){if(!g.previousConnection)throw"Orphan block does not have a previous connection.";for(var k=d;k.nextConnection;){var l=
k.getNextBlock();if(l&&!l.isShadow())k=l;else{g.previousConnection.checkType_(k.nextConnection)&&(k.nextConnection.connect(g.previousConnection),g=null);break}}}if(g&&(b.disconnect(),Blockly.Events.recordUndo)){var m=Blockly.Events.getGroup();setTimeout(function(){g.workspace&&!g.getParent()&&(Blockly.Events.setGroup(m),g.outputConnection?g.outputConnection.bumpAwayFrom_(b):g.previousConnection&&g.previousConnection.bumpAwayFrom_(b),Blockly.Events.setGroup(!1))},Blockly.BUMP_DELAY)}b.setShadowDom(h)}e&&
f&&f.connect(c.previousConnection);var n;Blockly.Events.isEnabled()&&(n=new Blockly.Events.BlockMove(d));Blockly.Connection.connectReciprocally_(b,a);d.setParent(c);n&&(n.recordNew(),Blockly.Events.fire(n))};Blockly.Connection.prototype.dispose=function(){if(this.isConnected())throw"Disconnect connection before disposing of it.";this.inDB_&&this.db_.removeConnection_(this);this.dbOpposite_=this.db_=null};
Blockly.Connection.prototype.isConnectedToNonInsertionMarker=function(){return this.targetConnection&&!this.targetBlock().isInsertionMarker()};Blockly.Connection.prototype.getSourceBlock=function(){return this.sourceBlock_};Blockly.Connection.prototype.isSuperior=function(){return this.type==Blockly.INPUT_VALUE||this.type==Blockly.NEXT_STATEMENT};Blockly.Connection.prototype.isConnected=function(){return!!this.targetConnection};
Blockly.Connection.prototype.canConnectWithReason_=function(a){if(!a)return Blockly.Connection.REASON_TARGET_NULL;if(this.isSuperior())var b=this.sourceBlock_,c=a.getSourceBlock(),d=this;else c=this.sourceBlock_,b=a.getSourceBlock(),d=a;if(b&&b==c)return Blockly.Connection.REASON_SELF_CONNECTION;if(a.type!=Blockly.OPPOSITE_TYPE[this.type])return Blockly.Connection.REASON_WRONG_TYPE;if(b&&c&&b.workspace!==c.workspace)return Blockly.Connection.REASON_DIFFERENT_WORKSPACES;if(this.checkType_(a)){if(b.isShadow()&&
!c.isShadow())return Blockly.Connection.REASON_SHADOW_PARENT;if(b.type==Blockly.PROCEDURES_DEFINITION_BLOCK_TYPE&&c.type!=Blockly.PROCEDURES_PROTOTYPE_BLOCK_TYPE&&d==b.getInput("custom_block").connection||c.type==Blockly.PROCEDURES_PROTOTYPE_BLOCK_TYPE&&b.type!=Blockly.PROCEDURES_DEFINITION_BLOCK_TYPE)return Blockly.Connection.REASON_CUSTOM_PROCEDURE}else return Blockly.Connection.REASON_CHECKS_FAILED;return Blockly.Connection.CAN_CONNECT};
Blockly.Connection.prototype.checkConnection_=function(a){switch(this.canConnectWithReason_(a)){case Blockly.Connection.CAN_CONNECT:break;case Blockly.Connection.REASON_SELF_CONNECTION:throw"Attempted to connect a block to itself.";case Blockly.Connection.REASON_DIFFERENT_WORKSPACES:throw"Blocks not on same workspace.";case Blockly.Connection.REASON_WRONG_TYPE:throw"Attempt to connect incompatible types.";case Blockly.Connection.REASON_TARGET_NULL:throw"Target connection is null.";case Blockly.Connection.REASON_CHECKS_FAILED:throw"Connection checks failed. "+
(this+" expected "+this.check_+", found "+a.check_);case Blockly.Connection.REASON_SHADOW_PARENT:throw"Connecting non-shadow to shadow block.";case Blockly.Connection.REASON_CUSTOM_PROCEDURE:throw"Trying to replace a shadow on a custom procedure definition.";default:throw"Unknown connection failure: this should never happen!";}};
Blockly.Connection.prototype.canConnectToPrevious_=function(a){if(this.targetConnection||-1!=Blockly.draggingConnections_.indexOf(a))return!1;var b=this.sourceBlock_.getFirstStatementConnection(),c=this==b,d=this==this.sourceBlock_.nextConnection;if(null!=b&&!c&&!d)return!1;b=null!=this.sourceBlock_.previousConnection;if(c&&b)return!0;if(d||c&&!b){if(!a.targetConnection)return!0;a=a.targetBlock();return a.isInsertionMarker()?!a.getPreviousBlock():!1}};
Blockly.Connection.prototype.isConnectionAllowed=function(a){if(a.sourceBlock_.isInsertionMarker()||this.canConnectWithReason_(a)!=Blockly.Connection.CAN_CONNECT)return!1;var b=this.sourceBlock_.getFirstStatementConnection();switch(a.type){case Blockly.PREVIOUS_STATEMENT:return this.canConnectToPrevious_(a);case Blockly.OUTPUT_VALUE:return!1;case Blockly.INPUT_VALUE:if(a.targetConnection&&!a.targetBlock().isMovable()&&!a.targetBlock().isShadow())return!1;break;case Blockly.NEXT_STATEMENT:if(b&&this==
this.sourceBlock_.previousConnection&&a.isConnectedToNonInsertionMarker()&&!b.targetConnection||a.isConnectedToNonInsertionMarker()&&!this.sourceBlock_.nextConnection&&!a.targetBlock().isShadow()&&a.targetBlock().nextConnection)return!1;break;default:throw"Unknown connection type in isConnectionAllowed";}return-1!=Blockly.draggingConnections_.indexOf(a)?!1:!0};Blockly.Connection.prototype.connect=function(a){this.targetConnection!=a&&(this.checkConnection_(a),this.isSuperior()?this.connect_(a):a.connect_(this))};
Blockly.Connection.connectReciprocally_=function(a,b){goog.asserts.assert(a&&b,"Cannot connect null connections.");a.targetConnection=b;b.targetConnection=a};Blockly.Connection.singleConnection_=function(a,b){for(var c=!1,d=0;d<a.inputList.length;d++){var e=a.inputList[d].connection;if(e&&e.type==Blockly.INPUT_VALUE&&b.outputConnection.checkType_(e)){if(c)return null;c=e}}return c};
Blockly.Connection.prototype.disconnect=function(){var a=this.targetConnection;goog.asserts.assert(a,"Source connection not connected.");goog.asserts.assert(a.targetConnection==this,"Target connection not connected to source connection.");if(this.isSuperior()){var b=this.sourceBlock_;var c=a.getSourceBlock();a=this}else b=a.getSourceBlock(),c=this.sourceBlock_;this.disconnectInternal_(b,c);a.respawnShadow_()};
Blockly.Connection.prototype.disconnectInternal_=function(a,b){var c;Blockly.Events.isEnabled()&&(c=new Blockly.Events.BlockMove(b));this.targetConnection=this.targetConnection.targetConnection=null;b.setParent(null);c&&(c.recordNew(),Blockly.Events.fire(c))};
Blockly.Connection.prototype.respawnShadow_=function(){var a=this.getSourceBlock(),b=this.getShadowDom();if(a.workspace&&b&&Blockly.Events.recordUndo)if(a=Blockly.Xml.domToBlock(b,a.workspace),a.outputConnection)this.connect(a.outputConnection);else if(a.previousConnection)this.connect(a.previousConnection);else throw"Child block does not have output or previous statement.";};Blockly.Connection.prototype.targetBlock=function(){return this.isConnected()?this.targetConnection.getSourceBlock():null};
Blockly.Connection.prototype.checkType_=function(a){if(!this.check_||!a.check_)return!0;for(var b=0;b<this.check_.length;b++)if(-1!=a.check_.indexOf(this.check_[b]))return!0;return!1};Blockly.Connection.prototype.onCheckChanged_=function(){this.isConnected()&&!this.checkType_(this.targetConnection)&&(this.isSuperior()?this.targetBlock():this.sourceBlock_).unplug()};
Blockly.Connection.prototype.setCheck=function(a){a?(goog.isArray(a)||(a=[a]),this.check_=a,this.onCheckChanged_()):this.check_=null;return this};Blockly.Connection.prototype.getOutputShape=function(){return this.check_?-1!==this.check_.indexOf("Boolean")?Blockly.OUTPUT_SHAPE_HEXAGONAL:-1!==this.check_.indexOf("Number")?Blockly.OUTPUT_SHAPE_ROUND:-1!==this.check_.indexOf("String")?Blockly.OUTPUT_SHAPE_SQUARE:Blockly.OUTPUT_SHAPE_ROUND:Blockly.OUTPUT_SHAPE_ROUND};
Blockly.Connection.prototype.setShadowDom=function(a){this.shadowDom_=a};Blockly.Connection.prototype.getShadowDom=function(){return this.shadowDom_};Blockly.Connection.prototype.neighbours_=function(){return[]};
Blockly.Connection.prototype.toString=function(){var a=this.sourceBlock_;if(a)if(a.outputConnection==this)var b="Output Connection of ";else if(a.previousConnection==this)b="Previous Connection of ";else if(a.nextConnection==this)b="Next Connection of ";else if(b=goog.array.find(a.inputList,function(a){return a.connection==this},this))b='Input "'+b.name+'" connection on ';else return console.warn("Connection not actually connected to sourceBlock_"),"Orphan Connection";else return"Orphan Connection";
return b+a.toDevString()};Blockly.ConnectionDB=function(){this.connections_=[]};Blockly.ConnectionDB.prototype.addConnection=function(a){if(a.inDB_)throw Error("Connection already in database.");if(!a.getSourceBlock().isInFlyout){var b=this.findPositionForConnection_(a);this.connections_.splice(b,0,a);a.inDB_=!0}};
Blockly.ConnectionDB.prototype.findConnection=function(a){if(!this.connections_.length)return-1;var b=this.findPositionForConnection_(a);if(b>=this.connections_.length)return-1;for(var c=a.y_,d=b;0<=d&&this.connections_[d].y_==c;){if(this.connections_[d]==a)return d;d--}for(;b<this.connections_.length&&this.connections_[b].y_==c;){if(this.connections_[b]==a)return b;b++}return-1};
Blockly.ConnectionDB.prototype.findPositionForConnection_=function(a){if(!this.connections_.length)return 0;for(var b=0,c=this.connections_.length;b<c;){var d=Math.floor((b+c)/2);if(this.connections_[d].y_<a.y_)b=d+1;else if(this.connections_[d].y_>a.y_)c=d;else{b=d;break}}return b};
Blockly.ConnectionDB.prototype.removeConnection_=function(a){if(!a.inDB_)throw Error("Connection not in database.");var b=this.findConnection(a);if(-1==b)throw Error("Unable to find connection in connectionDB.");a.inDB_=!1;this.connections_.splice(b,1)};
Blockly.ConnectionDB.prototype.getNeighbours=function(a,b){function c(a){var c=e-d[a].x_,g=f-d[a].y_;Math.sqrt(c*c+g*g)<=b&&k.push(d[a]);return g<b}var d=this.connections_,e=a.x_,f=a.y_;a=0;for(var g=d.length-2,h=g;a<h;)d[h].y_<f?a=h:g=h,h=Math.floor((a+g)/2);var k=[];g=a=h;if(d.length){for(;0<=a&&c(a);)a--;do g++;while(g<d.length&&c(g))}return k};Blockly.ConnectionDB.prototype.isInYRange_=function(a,b,c){return Math.abs(this.connections_[a].y_-b)<=c};
Blockly.ConnectionDB.prototype.searchForClosest=function(a,b,c){if(!this.connections_.length)return{connection:null,radius:b};var d=a.y_,e=a.x_;a.x_=e+c.x;a.y_=d+c.y;var f=this.findPositionForConnection_(a);c=null;for(var g=b,h,k=f-1;0<=k&&this.isInYRange_(k,a.y_,b);)h=this.connections_[k],a.isConnectionAllowed(h,g)&&(c=h,g=h.distanceFrom(a)),k--;for(;f<this.connections_.length&&this.isInYRange_(f,a.y_,b);)h=this.connections_[f],a.isConnectionAllowed(h,g)&&(c=h,g=h.distanceFrom(a)),f++;a.x_=e;a.y_=
d;return{connection:c,radius:g}};Blockly.ConnectionDB.init=function(a){var b=[];b[Blockly.INPUT_VALUE]=new Blockly.ConnectionDB;b[Blockly.OUTPUT_VALUE]=new Blockly.ConnectionDB;b[Blockly.NEXT_STATEMENT]=new Blockly.ConnectionDB;b[Blockly.PREVIOUS_STATEMENT]=new Blockly.ConnectionDB;a.connectionDBList=b};Blockly.constants={};Blockly.DRAG_RADIUS=3;Blockly.FLYOUT_DRAG_RADIUS=10;Blockly.SNAP_RADIUS=48;Blockly.CONNECTING_SNAP_RADIUS=68;Blockly.CURRENT_CONNECTION_PREFERENCE=20;Blockly.BUMP_DELAY=0;Blockly.COLLAPSE_CHARS=30;Blockly.LONGPRESS=750;Blockly.LINE_SCROLL_MULTIPLIER=15;Blockly.SOUND_LIMIT=100;Blockly.DRAG_STACK=!0;Blockly.HSV_SATURATION=.45;Blockly.HSV_VALUE=.65;Blockly.SPRITE={width:96,height:124,url:"sprites.png"};Blockly.SVG_NS="http://www.w3.org/2000/svg";Blockly.HTML_NS="http://www.w3.org/1999/xhtml";
Blockly.INPUT_VALUE=1;Blockly.OUTPUT_VALUE=2;Blockly.NEXT_STATEMENT=3;Blockly.PREVIOUS_STATEMENT=4;Blockly.DUMMY_INPUT=5;Blockly.ALIGN_LEFT=-1;Blockly.ALIGN_CENTRE=0;Blockly.ALIGN_RIGHT=1;Blockly.DRAG_NONE=0;Blockly.DRAG_STICKY=1;Blockly.DRAG_BEGIN=1;Blockly.DRAG_FREE=2;Blockly.OPPOSITE_TYPE=[];Blockly.OPPOSITE_TYPE[Blockly.INPUT_VALUE]=Blockly.OUTPUT_VALUE;Blockly.OPPOSITE_TYPE[Blockly.OUTPUT_VALUE]=Blockly.INPUT_VALUE;Blockly.OPPOSITE_TYPE[Blockly.NEXT_STATEMENT]=Blockly.PREVIOUS_STATEMENT;
Blockly.OPPOSITE_TYPE[Blockly.PREVIOUS_STATEMENT]=Blockly.NEXT_STATEMENT;Blockly.TOOLBOX_AT_TOP=0;Blockly.TOOLBOX_AT_BOTTOM=1;Blockly.TOOLBOX_AT_LEFT=2;Blockly.TOOLBOX_AT_RIGHT=3;Blockly.OUTPUT_SHAPE_HEXAGONAL=1;Blockly.OUTPUT_SHAPE_ROUND=2;Blockly.OUTPUT_SHAPE_SQUARE=3;Blockly.Categories={motion:"motion",looks:"looks",sound:"sounds",pen:"pen",data:"data",dataLists:"data-lists",event:"events",control:"control",sensing:"sensing",operators:"operators",more:"more"};Blockly.DELETE_AREA_NONE=null;
Blockly.DELETE_AREA_TRASH=1;Blockly.DELETE_AREA_TOOLBOX=2;Blockly.VARIABLE_CATEGORY_NAME="VARIABLE";Blockly.PROCEDURE_CATEGORY_NAME="PROCEDURE";Blockly.RENAME_VARIABLE_ID="RENAME_VARIABLE_ID";Blockly.DELETE_VARIABLE_ID="DELETE_VARIABLE_ID";Blockly.NEW_BROADCAST_MESSAGE_ID="NEW_BROADCAST_MESSAGE_ID";Blockly.BROADCAST_MESSAGE_VARIABLE_TYPE="broadcast_msg";Blockly.LIST_VARIABLE_TYPE="list";Blockly.SCALAR_VARIABLE_TYPE="";Blockly.PROCEDURES_DEFINITION_BLOCK_TYPE="procedures_definition";
Blockly.PROCEDURES_PROTOTYPE_BLOCK_TYPE="procedures_prototype";Blockly.PROCEDURES_CALL_BLOCK_TYPE="procedures_call";Blockly.StatusButtonState={READY:"ready",NOT_READY:"not ready"};Blockly.ProgramMode={};Blockly.ProgramMode.REALTIME="realtime";Blockly.ProgramMode.UPLOAD="upload";Blockly.ProgramMode.programMode=Blockly.ProgramMode.REALTIME;Blockly.ProgramMode.setProgramMode=function(a){Blockly.ProgramMode.programMode=a};Blockly.ProgramMode.getProgramMode=function(){return Blockly.ProgramMode.programMode};Blockly.Variables={};Blockly.Variables.NAME_TYPE=Blockly.VARIABLE_CATEGORY_NAME;Blockly.Variables.CLOUD_PREFIX="\u2601 ";
Blockly.Variables.allUsedVariables=function(a){if(a instanceof Blockly.Block)var b=a.getDescendants(!1);else if(a instanceof Blockly.Workspace||a instanceof Blockly.WorkspaceSvg)b=a.getAllBlocks();else throw"Not Block or Workspace: "+a;var c=Blockly.Variables.noVariableText();a=Object.create(null);for(var d=0;d<b.length;d++){var e=b[d].getVarModels();if(e)for(var f=0;f<e.length;f++){var g=e[f];g.getId()&&g.name.toLowerCase()!=c&&(a[g.name.toLowerCase()]=g.name)}}b=[];for(var h in a)b.push(a[h]);return b};
Blockly.Variables.allVariables=function(a){return a instanceof Blockly.Block?(console.warn("Deprecated call to Blockly.Variables.allVariables with a block instead of a workspace.  You may want Blockly.Variables.allUsedVariables"),{}):a.getAllVariables()};Blockly.Variables.allDeveloperVariables=function(a){var b=a.getAllBlocks();a={};for(var c=0;c<b.length;c++){var d=b[c];if(d.getDeveloperVars){d=d.getDeveloperVars();for(var e=0;e<d.length;e++)a[d[e]]=d[e]}}b=[];for(var f in a)b.push(a[f]);return b};
Blockly.Variables.noVariableText=function(){return"No variable selected"};Blockly.Variables.generateUniqueName=function(a){a=a.getAllVariables();var b="";if(a.length)for(var c=1,d=0,e="ijkmnopqrstuvwxyzabcdefgh".charAt(d);!b;){for(var f=!1,g=0;g<a.length;g++)if(a[g].name.toLowerCase()==e){f=!0;break}f?(d++,25==d&&(d=0,c++),e="ijkmnopqrstuvwxyzabcdefgh".charAt(d),1<c&&(e+=c)):b=e}else b="i";return b};
Blockly.Variables.realizePotentialVar=function(a,b,c,d){var e=c.getPotentialVariableMap();c=c.targetWorkspace;if(e){var f;d&&(f=Blockly.Variables.getVariable(c,null,a,b));d=e.getVariable(a,b);if(!d)return null;var g=d.getId();e.deleteVariable(d);return f?f:c.createVariable(a,b,g)}console.warn("Called Blockly.Variables.realizePotentialVar with incorrect workspace. The provided workspace does not have a potential variable map.")};
Blockly.Variables.createVariable=function(a,b,c){if(c==Blockly.BROADCAST_MESSAGE_VARIABLE_TYPE){var d=Blockly.Msg.NEW_BROADCAST_MESSAGE_TITLE;var e=Blockly.Msg.BROADCAST_MODAL_TITLE}else c==Blockly.LIST_VARIABLE_TYPE?(d=Blockly.Msg.NEW_LIST_TITLE,e=Blockly.Msg.LIST_MODAL_TITLE):(c=c?c:"",d=Blockly.Msg.NEW_VARIABLE_TITLE,e=Blockly.Msg.VARIABLE_MODAL_TITLE);var f=Blockly.Variables.nameValidator_.bind(null,c);Blockly.prompt(d,"",function(d,e,k){k=k||{};var g="local"===k.scope||!1;k=k.isCloud||!1;e=e||
[];if(d=f(d,a,g?[]:e,k,b)){var h;a.getPotentialVariableMap()&&c&&(h=Blockly.Variables.realizePotentialVar(d,c,a,!1));h||(h=a.createVariable(d,c,null,g,k));g=a.isFlyout?a:a.getFlyout();h=h.getId();g.setCheckboxState&&g.setCheckboxState(h,!0);b&&b(h)}else b&&b(null)},e,c)};
Blockly.Variables.nameValidator_=function(a,b,c,d,e,f){return a==Blockly.BROADCAST_MESSAGE_VARIABLE_TYPE?Blockly.Variables.validateBroadcastMessageName_(b,c,f):a==Blockly.LIST_VARIABLE_TYPE?Blockly.Variables.validateScalarVarOrListName_(b,c,d,!1,a,Blockly.Msg.LIST_ALREADY_EXISTS):Blockly.Variables.validateScalarVarOrListName_(b,c,d,e,a,Blockly.Msg.VARIABLE_ALREADY_EXISTS)};
Blockly.Variables.validateBroadcastMessageName_=function(a,b,c){return a?(b=b.getVariable(a,Blockly.BROADCAST_MESSAGE_VARIABLE_TYPE))?(c&&c(b.getId()),null):a:null};Blockly.Variables.validateScalarVarOrListName_=function(a,b,c,d,e,f){a=Blockly.Variables.trimName_(a);if(!a)return null;d&&(a=Blockly.Variables.CLOUD_PREFIX+a);return b.getVariable(a,e)||0<=c.indexOf(a)?(Blockly.alert(f.replace("%1",a)),null):a};
Blockly.Variables.renameVariable=function(a,b,c){var d=b.type;if(d==Blockly.BROADCAST_MESSAGE_VARIABLE_TYPE)console.warn("Unexpected attempt to rename a broadcast message with id: "+b.getId()+" and name: "+b.name);else{if(d==Blockly.LIST_VARIABLE_TYPE){var e=Blockly.Msg.RENAME_LIST_TITLE;var f=Blockly.Msg.RENAME_LIST_MODAL_TITLE}else e=Blockly.Msg.RENAME_VARIABLE_TITLE,f=Blockly.Msg.RENAME_VARIABLE_MODAL_TITLE;var g=Blockly.Variables.nameValidator_.bind(null,d);e=e.replace("%1",b.name);var h=b.name;
b.isCloud&&0==b.name.indexOf(Blockly.Variables.CLOUD_PREFIX)&&(h=h.substring(Blockly.Variables.CLOUD_PREFIX.length));Blockly.prompt(e,h,function(d,e){b.isCloud&&0<d.length&&0==d.indexOf(Blockly.Variables.CLOUD_PREFIX)&&(d=d.substring(Blockly.Variables.CLOUD_PREFIX.length));e=e||[];(e=g(d,a,b.isLocal?[]:e,b.isCloud))?(a.renameVariableById(b.getId(),e),c&&c(d)):c&&c(null)},f,d)}};Blockly.Variables.trimName_=function(a){return a?goog.string.trim(a):a};
Blockly.Variables.generateVariableFieldXml_=function(a,b){var c=a.type;""==c&&(c="''");return'<field name="'+(b||"VARIABLE")+'" id="'+a.getId()+'" variabletype="'+goog.string.htmlEscape(c)+'">'+goog.string.htmlEscape(a.name)+"</field>"};Blockly.Variables.getOrCreateVariablePackage=function(a,b,c,d){var e=Blockly.Variables.getVariable(a,b,c,d);e||(e=Blockly.Variables.createVariable_(a,b,c,d));return e};
Blockly.Variables.getVariable=function(a,b,c,d){var e=a.getPotentialVariableMap();if(b){var f=a.getVariableById(b);!f&&e&&(f=e.getVariableById(b))}else if(c){if(void 0==d)throw Error("Tried to look up a variable by name without a type");f=a.getVariable(c,d);!f&&e&&(f=e.getVariable(c,d))}return f};
Blockly.Variables.createVariable_=function(a,b,c,d){var e=a.getPotentialVariableMap();c||(c=Blockly.Variables.generateUniqueName(a.isFlyout?a.targetWorkspace:a));return e?e.createVariable(c,d,b):a.createVariable(c,d,b)};Blockly.Variables.getAddedVariables=function(a,b){a=a.getAllVariables();var c=[];if(b.length!=a.length)for(var d=0;d<a.length;d++){var e=a[d];b.includes(e)||c.push(e)}return c};Blockly.DataCategory=function(a){var b=a.getVariablesOfType("");b.sort(Blockly.VariableModel.compareByName);var c=[];Blockly.DataCategory.addCreateButton(c,a,"VARIABLE");for(var d=0;d<b.length;d++)Blockly.DataCategory.addDataVariable(c,b[d]);0<b.length&&(c[c.length-1].setAttribute("gap",24),b=b[0],Blockly.DataCategory.addSetVariableTo(c,b),Blockly.DataCategory.addChangeVariableBy(c,b),Blockly.ProgramMode.getProgramMode()!==Blockly.ProgramMode.UPLOAD&&(Blockly.DataCategory.addShowVariable(c,b),Blockly.DataCategory.addHideVariable(c,
b)));Blockly.DataCategory.addCreateButton(c,a,"LIST");b=a.getVariablesOfType(Blockly.LIST_VARIABLE_TYPE);b.sort(Blockly.VariableModel.compareByName);for(d=0;d<b.length;d++)Blockly.DataCategory.addDataList(c,b[d]);0<b.length&&(c[c.length-1].setAttribute("gap",24),b=b[0],Blockly.DataCategory.addAddToList(c,b),Blockly.DataCategory.addSep(c),Blockly.DataCategory.addDeleteOfList(c,b),Blockly.DataCategory.addDeleteAllOfList(c,b),Blockly.DataCategory.addInsertAtList(c,b),Blockly.DataCategory.addReplaceItemOfList(c,
b),Blockly.DataCategory.addSep(c),Blockly.DataCategory.addItemOfList(c,b),Blockly.DataCategory.addItemNumberOfList(c,b),Blockly.DataCategory.addLengthOfList(c,b),Blockly.DataCategory.addListContainsItem(c,b),Blockly.DataCategory.addSep(c),Blockly.DataCategory.addShowList(c,b),Blockly.DataCategory.addHideList(c,b));return c};Blockly.DataCategory.addDataVariable=function(a,b){Blockly.DataCategory.addBlock(a,b,"data_variable","VARIABLE");a[a.length-1].setAttribute("id",b.getId())};
Blockly.DataCategory.addSetVariableTo=function(a,b){Blockly.DataCategory.addBlock(a,b,"data_setvariableto","VARIABLE",["VALUE","text",0])};Blockly.DataCategory.addChangeVariableBy=function(a,b){Blockly.DataCategory.addBlock(a,b,"data_changevariableby","VARIABLE",["VALUE","math_number",1])};Blockly.DataCategory.addShowVariable=function(a,b){Blockly.DataCategory.addBlock(a,b,"data_showvariable","VARIABLE")};
Blockly.DataCategory.addHideVariable=function(a,b){Blockly.DataCategory.addBlock(a,b,"data_hidevariable","VARIABLE")};Blockly.DataCategory.addDataList=function(a,b){Blockly.DataCategory.addBlock(a,b,"data_listcontents","LIST");a[a.length-1].setAttribute("id",b.getId())};Blockly.DataCategory.addAddToList=function(a,b){Blockly.DataCategory.addBlock(a,b,"data_addtolist","LIST",["ITEM","text",Blockly.Msg.DEFAULT_LIST_ITEM])};
Blockly.DataCategory.addDeleteOfList=function(a,b){Blockly.DataCategory.addBlock(a,b,"data_deleteoflist","LIST",["INDEX","math_integer",1])};Blockly.DataCategory.addDeleteAllOfList=function(a,b){Blockly.DataCategory.addBlock(a,b,"data_deletealloflist","LIST")};Blockly.DataCategory.addInsertAtList=function(a,b){Blockly.DataCategory.addBlock(a,b,"data_insertatlist","LIST",["INDEX","math_integer",1],["ITEM","text",Blockly.Msg.DEFAULT_LIST_ITEM])};
Blockly.DataCategory.addReplaceItemOfList=function(a,b){Blockly.DataCategory.addBlock(a,b,"data_replaceitemoflist","LIST",["INDEX","math_integer",1],["ITEM","text",Blockly.Msg.DEFAULT_LIST_ITEM])};Blockly.DataCategory.addItemOfList=function(a,b){Blockly.DataCategory.addBlock(a,b,"data_itemoflist","LIST",["INDEX","math_integer",1])};Blockly.DataCategory.addItemNumberOfList=function(a,b){Blockly.DataCategory.addBlock(a,b,"data_itemnumoflist","LIST",["ITEM","text",Blockly.Msg.DEFAULT_LIST_ITEM])};
Blockly.DataCategory.addLengthOfList=function(a,b){Blockly.DataCategory.addBlock(a,b,"data_lengthoflist","LIST")};Blockly.DataCategory.addListContainsItem=function(a,b){Blockly.DataCategory.addBlock(a,b,"data_listcontainsitem","LIST",["ITEM","text",Blockly.Msg.DEFAULT_LIST_ITEM])};Blockly.DataCategory.addShowList=function(a,b){Blockly.DataCategory.addBlock(a,b,"data_showlist","LIST")};Blockly.DataCategory.addHideList=function(a,b){Blockly.DataCategory.addBlock(a,b,"data_hidelist","LIST")};
Blockly.DataCategory.addCreateButton=function(a,b,c){var d=goog.dom.createDom("button"),e=Blockly.Msg.NEW_VARIABLE,f="CREATE_VARIABLE",g=function(a){Blockly.Variables.createVariable(a.getTargetWorkspace(),null,"")};"LIST"===c&&(e=Blockly.Msg.NEW_LIST,f="CREATE_LIST",g=function(a){Blockly.Variables.createVariable(a.getTargetWorkspace(),null,Blockly.LIST_VARIABLE_TYPE)});d.setAttribute("text",e);d.setAttribute("callbackKey",f);b.registerButtonCallback(f,g);a.push(d)};
Blockly.DataCategory.addBlock=function(a,b,c,d,e,f){if(Blockly.Blocks[c]){var g,h;e&&(g=Blockly.DataCategory.createValue(e[0],e[1],e[2]));f&&(h=Blockly.DataCategory.createValue(f[0],f[1],f[2]));b='<xml><block type="'+c+'" gap="8">'+Blockly.Variables.generateVariableFieldXml_(b,d)+g+h+"</block></xml>";b=Blockly.Xml.textToDom(b).firstChild;a.push(b)}};
Blockly.DataCategory.createValue=function(a,b,c){switch(a){case "ITEM":var d="TEXT";break;case "INDEX":d="NUM";break;case "VALUE":d="math_number"===b?"NUM":"TEXT"}return'<value name="'+a+'"><shadow type="'+b+'"><field name="'+d+'">'+c+"</field></shadow></value>"};Blockly.DataCategory.addSep=function(a){var b=Blockly.Xml.textToDom('<xml><sep gap="36"/></xml>').firstChild;a.push(b)};goog.dom.vendor={};goog.dom.vendor.getVendorJsPrefix=function(){return goog.userAgent.WEBKIT?"Webkit":goog.userAgent.GECKO?"Moz":goog.userAgent.IE?"ms":goog.userAgent.OPERA?"O":null};goog.dom.vendor.getVendorPrefix=function(){return goog.userAgent.WEBKIT?"-webkit":goog.userAgent.GECKO?"-moz":goog.userAgent.IE?"-ms":goog.userAgent.OPERA?"-o":null};
goog.dom.vendor.getPrefixedPropertyName=function(a,b){if(b&&a in b)return a;var c=goog.dom.vendor.getVendorJsPrefix();return c?(c=c.toLowerCase(),a=c+goog.string.toTitleCase(a),!goog.isDef(b)||a in b?a:null):null};goog.dom.vendor.getPrefixedEventType=function(a){return((goog.dom.vendor.getVendorJsPrefix()||"")+a).toLowerCase()};goog.math.Box=function(a,b,c,d){this.top=a;this.right=b;this.bottom=c;this.left=d};goog.math.Box.boundingBox=function(a){for(var b=new goog.math.Box(arguments[0].y,arguments[0].x,arguments[0].y,arguments[0].x),c=1;c<arguments.length;c++)b.expandToIncludeCoordinate(arguments[c]);return b};goog.math.Box.prototype.getWidth=function(){return this.right-this.left};goog.math.Box.prototype.getHeight=function(){return this.bottom-this.top};
goog.math.Box.prototype.clone=function(){return new goog.math.Box(this.top,this.right,this.bottom,this.left)};goog.DEBUG&&(goog.math.Box.prototype.toString=function(){return"("+this.top+"t, "+this.right+"r, "+this.bottom+"b, "+this.left+"l)"});goog.math.Box.prototype.contains=function(a){return goog.math.Box.contains(this,a)};
goog.math.Box.prototype.expand=function(a,b,c,d){goog.isObject(a)?(this.top-=a.top,this.right+=a.right,this.bottom+=a.bottom,this.left-=a.left):(this.top-=a,this.right+=Number(b),this.bottom+=Number(c),this.left-=Number(d));return this};goog.math.Box.prototype.expandToInclude=function(a){this.left=Math.min(this.left,a.left);this.top=Math.min(this.top,a.top);this.right=Math.max(this.right,a.right);this.bottom=Math.max(this.bottom,a.bottom)};
goog.math.Box.prototype.expandToIncludeCoordinate=function(a){this.top=Math.min(this.top,a.y);this.right=Math.max(this.right,a.x);this.bottom=Math.max(this.bottom,a.y);this.left=Math.min(this.left,a.x)};goog.math.Box.equals=function(a,b){return a==b?!0:a&&b?a.top==b.top&&a.right==b.right&&a.bottom==b.bottom&&a.left==b.left:!1};
goog.math.Box.contains=function(a,b){return a&&b?b instanceof goog.math.Box?b.left>=a.left&&b.right<=a.right&&b.top>=a.top&&b.bottom<=a.bottom:b.x>=a.left&&b.x<=a.right&&b.y>=a.top&&b.y<=a.bottom:!1};goog.math.Box.relativePositionX=function(a,b){return b.x<a.left?b.x-a.left:b.x>a.right?b.x-a.right:0};goog.math.Box.relativePositionY=function(a,b){return b.y<a.top?b.y-a.top:b.y>a.bottom?b.y-a.bottom:0};
goog.math.Box.distance=function(a,b){var c=goog.math.Box.relativePositionX(a,b);a=goog.math.Box.relativePositionY(a,b);return Math.sqrt(c*c+a*a)};goog.math.Box.intersects=function(a,b){return a.left<=b.right&&b.left<=a.right&&a.top<=b.bottom&&b.top<=a.bottom};goog.math.Box.intersectsWithPadding=function(a,b,c){return a.left<=b.right+c&&b.left<=a.right+c&&a.top<=b.bottom+c&&b.top<=a.bottom+c};
goog.math.Box.prototype.ceil=function(){this.top=Math.ceil(this.top);this.right=Math.ceil(this.right);this.bottom=Math.ceil(this.bottom);this.left=Math.ceil(this.left);return this};goog.math.Box.prototype.floor=function(){this.top=Math.floor(this.top);this.right=Math.floor(this.right);this.bottom=Math.floor(this.bottom);this.left=Math.floor(this.left);return this};
goog.math.Box.prototype.round=function(){this.top=Math.round(this.top);this.right=Math.round(this.right);this.bottom=Math.round(this.bottom);this.left=Math.round(this.left);return this};goog.math.Box.prototype.translate=function(a,b){a instanceof goog.math.Coordinate?(this.left+=a.x,this.right+=a.x,this.top+=a.y,this.bottom+=a.y):(goog.asserts.assertNumber(a),this.left+=a,this.right+=a,goog.isNumber(b)&&(this.top+=b,this.bottom+=b));return this};
goog.math.Box.prototype.scale=function(a,b){b=goog.isNumber(b)?b:a;this.left*=a;this.right*=a;this.top*=b;this.bottom*=b;return this};goog.math.IRect=function(){};goog.math.Rect=function(a,b,c,d){this.left=a;this.top=b;this.width=c;this.height=d};goog.math.Rect.prototype.clone=function(){return new goog.math.Rect(this.left,this.top,this.width,this.height)};goog.math.Rect.prototype.toBox=function(){return new goog.math.Box(this.top,this.left+this.width,this.top+this.height,this.left)};goog.math.Rect.createFromPositionAndSize=function(a,b){return new goog.math.Rect(a.x,a.y,b.width,b.height)};
goog.math.Rect.createFromBox=function(a){return new goog.math.Rect(a.left,a.top,a.right-a.left,a.bottom-a.top)};goog.DEBUG&&(goog.math.Rect.prototype.toString=function(){return"("+this.left+", "+this.top+" - "+this.width+"w x "+this.height+"h)"});goog.math.Rect.equals=function(a,b){return a==b?!0:a&&b?a.left==b.left&&a.width==b.width&&a.top==b.top&&a.height==b.height:!1};
goog.math.Rect.prototype.intersection=function(a){var b=Math.max(this.left,a.left),c=Math.min(this.left+this.width,a.left+a.width);if(b<=c){var d=Math.max(this.top,a.top);a=Math.min(this.top+this.height,a.top+a.height);if(d<=a)return this.left=b,this.top=d,this.width=c-b,this.height=a-d,!0}return!1};
goog.math.Rect.intersection=function(a,b){var c=Math.max(a.left,b.left),d=Math.min(a.left+a.width,b.left+b.width);if(c<=d){var e=Math.max(a.top,b.top);a=Math.min(a.top+a.height,b.top+b.height);if(e<=a)return new goog.math.Rect(c,e,d-c,a-e)}return null};goog.math.Rect.intersects=function(a,b){return a.left<=b.left+b.width&&b.left<=a.left+a.width&&a.top<=b.top+b.height&&b.top<=a.top+a.height};goog.math.Rect.prototype.intersects=function(a){return goog.math.Rect.intersects(this,a)};
goog.math.Rect.difference=function(a,b){var c=goog.math.Rect.intersection(a,b);if(!c||!c.height||!c.width)return[a.clone()];c=[];var d=a.top,e=a.height,f=a.left+a.width,g=a.top+a.height,h=b.left+b.width,k=b.top+b.height;b.top>a.top&&(c.push(new goog.math.Rect(a.left,a.top,a.width,b.top-a.top)),d=b.top,e-=b.top-a.top);k<g&&(c.push(new goog.math.Rect(a.left,k,a.width,g-k)),e=k-d);b.left>a.left&&c.push(new goog.math.Rect(a.left,d,b.left-a.left,e));h<f&&c.push(new goog.math.Rect(h,d,f-h,e));return c};
goog.math.Rect.prototype.difference=function(a){return goog.math.Rect.difference(this,a)};goog.math.Rect.prototype.boundingRect=function(a){var b=Math.max(this.left+this.width,a.left+a.width),c=Math.max(this.top+this.height,a.top+a.height);this.left=Math.min(this.left,a.left);this.top=Math.min(this.top,a.top);this.width=b-this.left;this.height=c-this.top};goog.math.Rect.boundingRect=function(a,b){if(!a||!b)return null;a=new goog.math.Rect(a.left,a.top,a.width,a.height);a.boundingRect(b);return a};
goog.math.Rect.prototype.contains=function(a){return a instanceof goog.math.Coordinate?a.x>=this.left&&a.x<=this.left+this.width&&a.y>=this.top&&a.y<=this.top+this.height:this.left<=a.left&&this.left+this.width>=a.left+a.width&&this.top<=a.top&&this.top+this.height>=a.top+a.height};goog.math.Rect.prototype.squaredDistance=function(a){var b=a.x<this.left?this.left-a.x:Math.max(a.x-(this.left+this.width),0);a=a.y<this.top?this.top-a.y:Math.max(a.y-(this.top+this.height),0);return b*b+a*a};
goog.math.Rect.prototype.distance=function(a){return Math.sqrt(this.squaredDistance(a))};goog.math.Rect.prototype.getSize=function(){return new goog.math.Size(this.width,this.height)};goog.math.Rect.prototype.getTopLeft=function(){return new goog.math.Coordinate(this.left,this.top)};goog.math.Rect.prototype.getCenter=function(){return new goog.math.Coordinate(this.left+this.width/2,this.top+this.height/2)};
goog.math.Rect.prototype.getBottomRight=function(){return new goog.math.Coordinate(this.left+this.width,this.top+this.height)};goog.math.Rect.prototype.ceil=function(){this.left=Math.ceil(this.left);this.top=Math.ceil(this.top);this.width=Math.ceil(this.width);this.height=Math.ceil(this.height);return this};goog.math.Rect.prototype.floor=function(){this.left=Math.floor(this.left);this.top=Math.floor(this.top);this.width=Math.floor(this.width);this.height=Math.floor(this.height);return this};
goog.math.Rect.prototype.round=function(){this.left=Math.round(this.left);this.top=Math.round(this.top);this.width=Math.round(this.width);this.height=Math.round(this.height);return this};goog.math.Rect.prototype.translate=function(a,b){a instanceof goog.math.Coordinate?(this.left+=a.x,this.top+=a.y):(this.left+=goog.asserts.assertNumber(a),goog.isNumber(b)&&(this.top+=b));return this};
goog.math.Rect.prototype.scale=function(a,b){b=goog.isNumber(b)?b:a;this.left*=a;this.width*=a;this.top*=b;this.height*=b;return this};goog.style={};goog.style.setStyle=function(a,b,c){if(goog.isString(b))goog.style.setStyle_(a,c,b);else for(var d in b)goog.style.setStyle_(a,b[d],d)};goog.style.setStyle_=function(a,b,c){(c=goog.style.getVendorJsStyleName_(a,c))&&(a.style[c]=b)};goog.style.styleNameCache_={};
goog.style.getVendorJsStyleName_=function(a,b){var c=goog.style.styleNameCache_[b];if(!c){var d=goog.string.toCamelCase(b);c=d;void 0===a.style[d]&&(d=goog.dom.vendor.getVendorJsPrefix()+goog.string.toTitleCase(d),void 0!==a.style[d]&&(c=d));goog.style.styleNameCache_[b]=c}return c};
goog.style.getVendorStyleName_=function(a,b){var c=goog.string.toCamelCase(b);return void 0===a.style[c]&&(c=goog.dom.vendor.getVendorJsPrefix()+goog.string.toTitleCase(c),void 0!==a.style[c])?goog.dom.vendor.getVendorPrefix()+"-"+b:b};goog.style.getStyle=function(a,b){var c=a.style[goog.string.toCamelCase(b)];return"undefined"!==typeof c?c:a.style[goog.style.getVendorJsStyleName_(a,b)]||""};
goog.style.getComputedStyle=function(a,b){var c=goog.dom.getOwnerDocument(a);return c.defaultView&&c.defaultView.getComputedStyle&&(a=c.defaultView.getComputedStyle(a,null))?a[b]||a.getPropertyValue(b)||"":""};goog.style.getCascadedStyle=function(a,b){return a.currentStyle?a.currentStyle[b]:null};goog.style.getStyle_=function(a,b){return goog.style.getComputedStyle(a,b)||goog.style.getCascadedStyle(a,b)||a.style&&a.style[b]};
goog.style.getComputedBoxSizing=function(a){return goog.style.getStyle_(a,"boxSizing")||goog.style.getStyle_(a,"MozBoxSizing")||goog.style.getStyle_(a,"WebkitBoxSizing")||null};goog.style.getComputedPosition=function(a){return goog.style.getStyle_(a,"position")};goog.style.getBackgroundColor=function(a){return goog.style.getStyle_(a,"backgroundColor")};goog.style.getComputedOverflowX=function(a){return goog.style.getStyle_(a,"overflowX")};
goog.style.getComputedOverflowY=function(a){return goog.style.getStyle_(a,"overflowY")};goog.style.getComputedZIndex=function(a){return goog.style.getStyle_(a,"zIndex")};goog.style.getComputedTextAlign=function(a){return goog.style.getStyle_(a,"textAlign")};goog.style.getComputedCursor=function(a){return goog.style.getStyle_(a,"cursor")};goog.style.getComputedTransform=function(a){var b=goog.style.getVendorStyleName_(a,"transform");return goog.style.getStyle_(a,b)||goog.style.getStyle_(a,"transform")};
goog.style.setPosition=function(a,b,c){if(b instanceof goog.math.Coordinate){var d=b.x;b=b.y}else d=b,b=c;a.style.left=goog.style.getPixelStyleValue_(d,!1);a.style.top=goog.style.getPixelStyleValue_(b,!1)};goog.style.getPosition=function(a){return new goog.math.Coordinate(a.offsetLeft,a.offsetTop)};
goog.style.getClientViewportElement=function(a){a=a?goog.dom.getOwnerDocument(a):goog.dom.getDocument();return!goog.userAgent.IE||goog.userAgent.isDocumentModeOrHigher(9)||goog.dom.getDomHelper(a).isCss1CompatMode()?a.documentElement:a.body};goog.style.getViewportPageOffset=function(a){var b=a.body;a=a.documentElement;return new goog.math.Coordinate(b.scrollLeft||a.scrollLeft,b.scrollTop||a.scrollTop)};
goog.style.getBoundingClientRect_=function(a){try{var b=a.getBoundingClientRect()}catch(c){return{left:0,top:0,right:0,bottom:0}}goog.userAgent.IE&&a.ownerDocument.body&&(a=a.ownerDocument,b.left-=a.documentElement.clientLeft+a.body.clientLeft,b.top-=a.documentElement.clientTop+a.body.clientTop);return b};
goog.style.getOffsetParent=function(a){if(goog.userAgent.IE&&!goog.userAgent.isDocumentModeOrHigher(8))return goog.asserts.assert(a&&"offsetParent"in a),a.offsetParent;var b=goog.dom.getOwnerDocument(a),c=goog.style.getStyle_(a,"position"),d="fixed"==c||"absolute"==c;for(a=a.parentNode;a&&a!=b;a=a.parentNode)if(a.nodeType==goog.dom.NodeType.DOCUMENT_FRAGMENT&&a.host&&(a=a.host),c=goog.style.getStyle_(a,"position"),d=d&&"static"==c&&a!=b.documentElement&&a!=b.body,!d&&(a.scrollWidth>a.clientWidth||
a.scrollHeight>a.clientHeight||"fixed"==c||"absolute"==c||"relative"==c))return a;return null};
goog.style.getVisibleRectForElement=function(a){for(var b=new goog.math.Box(0,Infinity,Infinity,0),c=goog.dom.getDomHelper(a),d=c.getDocument().body,e=c.getDocument().documentElement,f=c.getDocumentScrollElement();a=goog.style.getOffsetParent(a);)if(!(goog.userAgent.IE&&0==a.clientWidth||goog.userAgent.WEBKIT&&0==a.clientHeight&&a==d)&&a!=d&&a!=e&&"visible"!=goog.style.getStyle_(a,"overflow")){var g=goog.style.getPageOffset(a),h=goog.style.getClientLeftTop(a);g.x+=h.x;g.y+=h.y;b.top=Math.max(b.top,
g.y);b.right=Math.min(b.right,g.x+a.clientWidth);b.bottom=Math.min(b.bottom,g.y+a.clientHeight);b.left=Math.max(b.left,g.x)}d=f.scrollLeft;f=f.scrollTop;b.left=Math.max(b.left,d);b.top=Math.max(b.top,f);c=c.getViewportSize();b.right=Math.min(b.right,d+c.width);b.bottom=Math.min(b.bottom,f+c.height);return 0<=b.top&&0<=b.left&&b.bottom>b.top&&b.right>b.left?b:null};
goog.style.getContainerOffsetToScrollInto=function(a,b,c){var d=b||goog.dom.getDocumentScrollElement(),e=goog.style.getPageOffset(a),f=goog.style.getPageOffset(d),g=goog.style.getBorderBox(d);d==goog.dom.getDocumentScrollElement()?(b=e.x-d.scrollLeft,e=e.y-d.scrollTop,goog.userAgent.IE&&!goog.userAgent.isDocumentModeOrHigher(10)&&(b+=g.left,e+=g.top)):(b=e.x-f.x-g.left,e=e.y-f.y-g.top);g=goog.style.getSizeWithDisplay_(a);a=d.clientWidth-g.width;g=d.clientHeight-g.height;f=d.scrollLeft;d=d.scrollTop;
c?(f+=b-a/2,d+=e-g/2):(f+=Math.min(b,Math.max(b-a,0)),d+=Math.min(e,Math.max(e-g,0)));return new goog.math.Coordinate(f,d)};goog.style.scrollIntoContainerView=function(a,b,c){b=b||goog.dom.getDocumentScrollElement();a=goog.style.getContainerOffsetToScrollInto(a,b,c);b.scrollLeft=a.x;b.scrollTop=a.y};goog.style.getClientLeftTop=function(a){return new goog.math.Coordinate(a.clientLeft,a.clientTop)};
goog.style.getPageOffset=function(a){var b=goog.dom.getOwnerDocument(a);goog.asserts.assertObject(a,"Parameter is required");var c=new goog.math.Coordinate(0,0),d=goog.style.getClientViewportElement(b);if(a==d)return c;a=goog.style.getBoundingClientRect_(a);b=goog.dom.getDomHelper(b).getDocumentScroll();c.x=a.left+b.x;c.y=a.top+b.y;return c};goog.style.getPageOffsetLeft=function(a){return goog.style.getPageOffset(a).x};goog.style.getPageOffsetTop=function(a){return goog.style.getPageOffset(a).y};
goog.style.getFramedPageOffset=function(a,b){var c=new goog.math.Coordinate(0,0),d=goog.dom.getWindow(goog.dom.getOwnerDocument(a));if(!goog.reflect.canAccessProperty(d,"parent"))return c;do{var e=d==b?goog.style.getPageOffset(a):goog.style.getClientPositionForElement_(goog.asserts.assert(a));c.x+=e.x;c.y+=e.y}while(d&&d!=b&&d!=d.parent&&(a=d.frameElement)&&(d=d.parent));return c};
goog.style.translateRectForAnotherFrame=function(a,b,c){if(b.getDocument()!=c.getDocument()){var d=b.getDocument().body;c=goog.style.getFramedPageOffset(d,c.getWindow());c=goog.math.Coordinate.difference(c,goog.style.getPageOffset(d));!goog.userAgent.IE||goog.userAgent.isDocumentModeOrHigher(9)||b.isCss1CompatMode()||(c=goog.math.Coordinate.difference(c,b.getDocumentScroll()));a.left+=c.x;a.top+=c.y}};
goog.style.getRelativePosition=function(a,b){a=goog.style.getClientPosition(a);b=goog.style.getClientPosition(b);return new goog.math.Coordinate(a.x-b.x,a.y-b.y)};goog.style.getClientPositionForElement_=function(a){a=goog.style.getBoundingClientRect_(a);return new goog.math.Coordinate(a.left,a.top)};
goog.style.getClientPosition=function(a){goog.asserts.assert(a);if(a.nodeType==goog.dom.NodeType.ELEMENT)return goog.style.getClientPositionForElement_(a);a=a.changedTouches?a.changedTouches[0]:a;return new goog.math.Coordinate(a.clientX,a.clientY)};goog.style.setPageOffset=function(a,b,c){var d=goog.style.getPageOffset(a);b instanceof goog.math.Coordinate&&(c=b.y,b=b.x);b=goog.asserts.assertNumber(b)-d.x;goog.style.setPosition(a,a.offsetLeft+b,a.offsetTop+(Number(c)-d.y))};
goog.style.setSize=function(a,b,c){if(b instanceof goog.math.Size)c=b.height,b=b.width;else if(void 0==c)throw Error("missing height argument");goog.style.setWidth(a,b);goog.style.setHeight(a,c)};goog.style.getPixelStyleValue_=function(a,b){"number"==typeof a&&(a=(b?Math.round(a):a)+"px");return a};goog.style.setHeight=function(a,b){a.style.height=goog.style.getPixelStyleValue_(b,!0)};goog.style.setWidth=function(a,b){a.style.width=goog.style.getPixelStyleValue_(b,!0)};
goog.style.getSize=function(a){return goog.style.evaluateWithTemporaryDisplay_(goog.style.getSizeWithDisplay_,a)};goog.style.evaluateWithTemporaryDisplay_=function(a,b){if("none"!=goog.style.getStyle_(b,"display"))return a(b);var c=b.style,d=c.display,e=c.visibility,f=c.position;c.visibility="hidden";c.position="absolute";c.display="inline";a=a(b);c.display=d;c.position=f;c.visibility=e;return a};
goog.style.getSizeWithDisplay_=function(a){var b=a.offsetWidth,c=a.offsetHeight,d=goog.userAgent.WEBKIT&&!b&&!c;return goog.isDef(b)&&!d||!a.getBoundingClientRect?new goog.math.Size(b,c):(a=goog.style.getBoundingClientRect_(a),new goog.math.Size(a.right-a.left,a.bottom-a.top))};goog.style.getTransformedSize=function(a){if(!a.getBoundingClientRect)return null;a=goog.style.evaluateWithTemporaryDisplay_(goog.style.getBoundingClientRect_,a);return new goog.math.Size(a.right-a.left,a.bottom-a.top)};
goog.style.getBounds=function(a){var b=goog.style.getPageOffset(a);a=goog.style.getSize(a);return new goog.math.Rect(b.x,b.y,a.width,a.height)};goog.style.toCamelCase=function(a){return goog.string.toCamelCase(String(a))};goog.style.toSelectorCase=function(a){return goog.string.toSelectorCase(a)};
goog.style.getOpacity=function(a){goog.asserts.assert(a);var b=a.style;a="";"opacity"in b?a=b.opacity:"MozOpacity"in b?a=b.MozOpacity:"filter"in b&&(b=b.filter.match(/alpha\(opacity=([\d.]+)\)/))&&(a=String(b[1]/100));return""==a?a:Number(a)};goog.style.setOpacity=function(a,b){goog.asserts.assert(a);a=a.style;"opacity"in a?a.opacity=b:"MozOpacity"in a?a.MozOpacity=b:"filter"in a&&(a.filter=""===b?"":"alpha(opacity="+100*Number(b)+")")};
goog.style.setTransparentBackgroundImage=function(a,b){a=a.style;goog.userAgent.IE&&!goog.userAgent.isVersionOrHigher("8")?a.filter='progid:DXImageTransform.Microsoft.AlphaImageLoader(src="'+b+'", sizingMethod="crop")':(a.backgroundImage="url("+b+")",a.backgroundPosition="top left",a.backgroundRepeat="no-repeat")};goog.style.clearTransparentBackgroundImage=function(a){a=a.style;"filter"in a?a.filter="":a.backgroundImage="none"};goog.style.showElement=function(a,b){goog.style.setElementShown(a,b)};
goog.style.setElementShown=function(a,b){a.style.display=b?"":"none"};goog.style.isElementShown=function(a){return"none"!=a.style.display};
goog.style.installSafeStyleSheet=function(a,b){b=goog.dom.getDomHelper(b);var c=b.getDocument();if(goog.userAgent.IE&&c.createStyleSheet)return b=c.createStyleSheet(),goog.style.setSafeStyleSheet(b,a),b;c=b.getElementsByTagNameAndClass("HEAD")[0];if(!c){var d=b.getElementsByTagNameAndClass("BODY")[0];c=b.createDom("HEAD");d.parentNode.insertBefore(c,d)}d=b.createDom("STYLE");goog.style.setSafeStyleSheet(d,a);b.appendChild(c,d);return d};
goog.style.uninstallStyles=function(a){goog.dom.removeNode(a.ownerNode||a.owningElement||a)};goog.style.setSafeStyleSheet=function(a,b){b=goog.html.SafeStyleSheet.unwrap(b);goog.userAgent.IE&&goog.isDef(a.cssText)?a.cssText=b:a.innerHTML=b};goog.style.setPreWrap=function(a){a=a.style;goog.userAgent.IE&&!goog.userAgent.isVersionOrHigher("8")?(a.whiteSpace="pre",a.wordWrap="break-word"):a.whiteSpace=goog.userAgent.GECKO?"-moz-pre-wrap":"pre-wrap"};
goog.style.setInlineBlock=function(a){a=a.style;a.position="relative";goog.userAgent.IE&&!goog.userAgent.isVersionOrHigher("8")?(a.zoom="1",a.display="inline"):a.display="inline-block"};goog.style.isRightToLeft=function(a){return"rtl"==goog.style.getStyle_(a,"direction")};goog.style.unselectableStyle_=goog.userAgent.GECKO?"MozUserSelect":goog.userAgent.WEBKIT||goog.userAgent.EDGE?"WebkitUserSelect":null;
goog.style.isUnselectable=function(a){return goog.style.unselectableStyle_?"none"==a.style[goog.style.unselectableStyle_].toLowerCase():goog.userAgent.IE||goog.userAgent.OPERA?"on"==a.getAttribute("unselectable"):!1};
goog.style.setUnselectable=function(a,b,c){c=c?null:a.getElementsByTagName("*");var d=goog.style.unselectableStyle_;if(d){if(b=b?"none":"",a.style&&(a.style[d]=b),c){a=0;for(var e;e=c[a];a++)e.style&&(e.style[d]=b)}}else if(goog.userAgent.IE||goog.userAgent.OPERA)if(b=b?"on":"",a.setAttribute("unselectable",b),c)for(a=0;e=c[a];a++)e.setAttribute("unselectable",b)};goog.style.getBorderBoxSize=function(a){return new goog.math.Size(a.offsetWidth,a.offsetHeight)};
goog.style.setBorderBoxSize=function(a,b){var c=goog.dom.getOwnerDocument(a),d=goog.dom.getDomHelper(c).isCss1CompatMode();!goog.userAgent.IE||goog.userAgent.isVersionOrHigher("10")||d&&goog.userAgent.isVersionOrHigher("8")?goog.style.setBoxSizingSize_(a,b,"border-box"):(c=a.style,d?(d=goog.style.getPaddingBox(a),a=goog.style.getBorderBox(a),c.pixelWidth=b.width-a.left-d.left-d.right-a.right,c.pixelHeight=b.height-a.top-d.top-d.bottom-a.bottom):(c.pixelWidth=b.width,c.pixelHeight=b.height))};
goog.style.getContentBoxSize=function(a){var b=goog.dom.getOwnerDocument(a),c=goog.userAgent.IE&&a.currentStyle;if(c&&goog.dom.getDomHelper(b).isCss1CompatMode()&&"auto"!=c.width&&"auto"!=c.height&&!c.boxSizing)return b=goog.style.getIePixelValue_(a,c.width,"width","pixelWidth"),a=goog.style.getIePixelValue_(a,c.height,"height","pixelHeight"),new goog.math.Size(b,a);c=goog.style.getBorderBoxSize(a);b=goog.style.getPaddingBox(a);a=goog.style.getBorderBox(a);return new goog.math.Size(c.width-a.left-
b.left-b.right-a.right,c.height-a.top-b.top-b.bottom-a.bottom)};
goog.style.setContentBoxSize=function(a,b){var c=goog.dom.getOwnerDocument(a),d=goog.dom.getDomHelper(c).isCss1CompatMode();!goog.userAgent.IE||goog.userAgent.isVersionOrHigher("10")||d&&goog.userAgent.isVersionOrHigher("8")?goog.style.setBoxSizingSize_(a,b,"content-box"):(c=a.style,d?(c.pixelWidth=b.width,c.pixelHeight=b.height):(d=goog.style.getPaddingBox(a),a=goog.style.getBorderBox(a),c.pixelWidth=b.width+a.left+d.left+d.right+a.right,c.pixelHeight=b.height+a.top+d.top+d.bottom+a.bottom))};
goog.style.setBoxSizingSize_=function(a,b,c){a=a.style;goog.userAgent.GECKO?a.MozBoxSizing=c:goog.userAgent.WEBKIT?a.WebkitBoxSizing=c:a.boxSizing=c;a.width=Math.max(b.width,0)+"px";a.height=Math.max(b.height,0)+"px"};goog.style.getIePixelValue_=function(a,b,c,d){if(/^\d+px?$/.test(b))return parseInt(b,10);var e=a.style[c],f=a.runtimeStyle[c];a.runtimeStyle[c]=a.currentStyle[c];a.style[c]=b;b=a.style[d];a.style[c]=e;a.runtimeStyle[c]=f;return+b};
goog.style.getIePixelDistance_=function(a,b){return(b=goog.style.getCascadedStyle(a,b))?goog.style.getIePixelValue_(a,b,"left","pixelLeft"):0};
goog.style.getBox_=function(a,b){if(goog.userAgent.IE){var c=goog.style.getIePixelDistance_(a,b+"Left"),d=goog.style.getIePixelDistance_(a,b+"Right"),e=goog.style.getIePixelDistance_(a,b+"Top");a=goog.style.getIePixelDistance_(a,b+"Bottom");return new goog.math.Box(e,d,a,c)}c=goog.style.getComputedStyle(a,b+"Left");d=goog.style.getComputedStyle(a,b+"Right");e=goog.style.getComputedStyle(a,b+"Top");a=goog.style.getComputedStyle(a,b+"Bottom");return new goog.math.Box(parseFloat(e),parseFloat(d),parseFloat(a),
parseFloat(c))};goog.style.getPaddingBox=function(a){return goog.style.getBox_(a,"padding")};goog.style.getMarginBox=function(a){return goog.style.getBox_(a,"margin")};goog.style.ieBorderWidthKeywords_={thin:2,medium:4,thick:6};goog.style.getIePixelBorder_=function(a,b){if("none"==goog.style.getCascadedStyle(a,b+"Style"))return 0;b=goog.style.getCascadedStyle(a,b+"Width");return b in goog.style.ieBorderWidthKeywords_?goog.style.ieBorderWidthKeywords_[b]:goog.style.getIePixelValue_(a,b,"left","pixelLeft")};
goog.style.getBorderBox=function(a){if(goog.userAgent.IE&&!goog.userAgent.isDocumentModeOrHigher(9)){var b=goog.style.getIePixelBorder_(a,"borderLeft"),c=goog.style.getIePixelBorder_(a,"borderRight"),d=goog.style.getIePixelBorder_(a,"borderTop");a=goog.style.getIePixelBorder_(a,"borderBottom");return new goog.math.Box(d,c,a,b)}b=goog.style.getComputedStyle(a,"borderLeftWidth");c=goog.style.getComputedStyle(a,"borderRightWidth");d=goog.style.getComputedStyle(a,"borderTopWidth");a=goog.style.getComputedStyle(a,
"borderBottomWidth");return new goog.math.Box(parseFloat(d),parseFloat(c),parseFloat(a),parseFloat(b))};goog.style.getFontFamily=function(a){var b=goog.dom.getOwnerDocument(a),c="";if(b.body.createTextRange&&goog.dom.contains(b,a)){b=b.body.createTextRange();b.moveToElementText(a);try{c=b.queryCommandValue("FontName")}catch(d){c=""}}c||(c=goog.style.getStyle_(a,"fontFamily"));a=c.split(",");1<a.length&&(c=a[0]);return goog.string.stripQuotes(c,"\"'")};goog.style.lengthUnitRegex_=/[^\d]+$/;
goog.style.getLengthUnits=function(a){return(a=a.match(goog.style.lengthUnitRegex_))&&a[0]||null};goog.style.ABSOLUTE_CSS_LENGTH_UNITS_={cm:1,"in":1,mm:1,pc:1,pt:1};goog.style.CONVERTIBLE_RELATIVE_CSS_UNITS_={em:1,ex:1};
goog.style.getFontSize=function(a){var b=goog.style.getStyle_(a,"fontSize"),c=goog.style.getLengthUnits(b);if(b&&"px"==c)return parseInt(b,10);if(goog.userAgent.IE){if(String(c)in goog.style.ABSOLUTE_CSS_LENGTH_UNITS_)return goog.style.getIePixelValue_(a,b,"left","pixelLeft");if(a.parentNode&&a.parentNode.nodeType==goog.dom.NodeType.ELEMENT&&String(c)in goog.style.CONVERTIBLE_RELATIVE_CSS_UNITS_)return a=a.parentNode,c=goog.style.getStyle_(a,"fontSize"),goog.style.getIePixelValue_(a,b==c?"1em":b,
"left","pixelLeft")}c=goog.dom.createDom("SPAN",{style:"visibility:hidden;position:absolute;line-height:0;padding:0;margin:0;border:0;height:1em;"});goog.dom.appendChild(a,c);b=c.offsetHeight;goog.dom.removeNode(c);return b};goog.style.parseStyleAttribute=function(a){var b={};goog.array.forEach(a.split(/\s*;\s*/),function(a){var c=a.match(/\s*([\w-]+)\s*:(.+)/);c&&(a=c[1],c=goog.string.trim(c[2]),b[goog.string.toCamelCase(a.toLowerCase())]=c)});return b};
goog.style.toStyleAttribute=function(a){var b=[];goog.object.forEach(a,function(a,d){b.push(goog.string.toSelectorCase(d),":",a,";")});return b.join("")};goog.style.setFloat=function(a,b){a.style[goog.userAgent.IE?"styleFloat":"cssFloat"]=b};goog.style.getFloat=function(a){return a.style[goog.userAgent.IE?"styleFloat":"cssFloat"]||""};
goog.style.getScrollbarWidth=function(a){var b=goog.dom.createElement("DIV");a&&(b.className=a);b.style.cssText="overflow:auto;position:absolute;top:0;width:100px;height:100px";a=goog.dom.createElement("DIV");goog.style.setSize(a,"200px","200px");b.appendChild(a);goog.dom.appendChild(goog.dom.getDocument().body,b);a=b.offsetWidth-b.clientWidth;goog.dom.removeNode(b);return a};goog.style.MATRIX_TRANSLATION_REGEX_=/matrix\([0-9\.\-]+, [0-9\.\-]+, [0-9\.\-]+, [0-9\.\-]+, ([0-9\.\-]+)p?x?, ([0-9\.\-]+)p?x?\)/;
goog.style.getCssTranslation=function(a){a=goog.style.getComputedTransform(a);return a?(a=a.match(goog.style.MATRIX_TRANSLATION_REGEX_))?new goog.math.Coordinate(parseFloat(a[1]),parseFloat(a[2])):new goog.math.Coordinate(0,0):new goog.math.Coordinate(0,0)};Blockly.DropDownDiv=function(){};Blockly.DropDownDiv.DIV_=null;Blockly.DropDownDiv.boundsElement_=null;Blockly.DropDownDiv.owner_=null;Blockly.DropDownDiv.ARROW_SIZE=16;Blockly.DropDownDiv.BORDER_SIZE=1;Blockly.DropDownDiv.ARROW_HORIZONTAL_PADDING=12;Blockly.DropDownDiv.PADDING_Y=20;Blockly.DropDownDiv.ANIMATION_TIME=.25;Blockly.DropDownDiv.animateOutTimer_=null;Blockly.DropDownDiv.onHide_=0;
Blockly.DropDownDiv.createDom=function(){Blockly.DropDownDiv.DIV_||(Blockly.DropDownDiv.DIV_=goog.dom.createDom("div","blocklyDropDownDiv"),document.body.appendChild(Blockly.DropDownDiv.DIV_),Blockly.DropDownDiv.content_=goog.dom.createDom("div","blocklyDropDownContent"),Blockly.DropDownDiv.DIV_.appendChild(Blockly.DropDownDiv.content_),Blockly.DropDownDiv.arrow_=goog.dom.createDom("div","blocklyDropDownArrow"),Blockly.DropDownDiv.DIV_.appendChild(Blockly.DropDownDiv.arrow_),Blockly.DropDownDiv.DIV_.style.transition=
"transform "+Blockly.DropDownDiv.ANIMATION_TIME+"s, opacity "+Blockly.DropDownDiv.ANIMATION_TIME+"s")};Blockly.DropDownDiv.setBoundsElement=function(a){Blockly.DropDownDiv.boundsElement_=a};Blockly.DropDownDiv.getContentDiv=function(){return Blockly.DropDownDiv.content_};Blockly.DropDownDiv.clearContent=function(){Blockly.DropDownDiv.content_.innerHTML="";Blockly.DropDownDiv.content_.style.width=""};Blockly.DropDownDiv.ContentStyle=function(){Blockly.DropDownDiv.content_.style="blocklyDropDownContentMatrix"};
Blockly.DropDownDiv.setColour=function(a,b){Blockly.DropDownDiv.DIV_.style.backgroundColor=a;Blockly.DropDownDiv.DIV_.style.borderColor=b};Blockly.DropDownDiv.setCategory=function(a){Blockly.DropDownDiv.DIV_.setAttribute("data-category",a)};
Blockly.DropDownDiv.showPositionedByBlock=function(a,b,c,d){var e=b.workspace.scale,f=b.width;a=b.height;f*=e;a*=e;e=b.getSvgRoot().getBoundingClientRect();f=e.left+f/2;a=e.top+a;e=e.top;d&&(e+=d);Blockly.DropDownDiv.setBoundsElement(b.workspace.getParentSvg().parentNode);return Blockly.DropDownDiv.show(this,f,a,f,e,c)};
Blockly.DropDownDiv.show=function(a,b,c,d,e,f){Blockly.DropDownDiv.owner_=a;Blockly.DropDownDiv.onHide_=f;f=Blockly.DropDownDiv.DIV_;b=Blockly.DropDownDiv.getPositionMetrics(b,c,d,e);Blockly.DropDownDiv.arrow_.style.transform="translate("+b.arrowX+"px,"+b.arrowY+"px) rotate(45deg)";Blockly.DropDownDiv.arrow_.setAttribute("class",b.arrowAtTop?"blocklyDropDownArrow arrowTop":"blocklyDropDownArrow arrowBottom");f.style.direction=a.sourceBlock_&&a.sourceBlock_.RTL?"rtl":"ltr";f.style.left=b.initialX+
"px";f.style.top=b.initialY+"px";f.style.display="block";f.style.opacity=1;f.style.transform="translate("+(b.finalX-b.initialX)+"px,"+(b.finalY-b.initialY)+"px)";return b.arrowAtTop};
Blockly.DropDownDiv.getPositionMetrics=function(a,b,c,d){var e=Blockly.DropDownDiv.DIV_,f=Blockly.DropDownDiv.boundsElement_.getBoundingClientRect(),g=goog.style.getSize(Blockly.DropDownDiv.boundsElement_);e=goog.style.getSize(e);if(b+e.height>f.top+g.height)if(d-e.height<f.top){c=b+Blockly.DropDownDiv.PADDING_Y;var h=!1}else a=c,c=d-e.height-Blockly.DropDownDiv.PADDING_Y,h=!0;else c=b+Blockly.DropDownDiv.PADDING_Y,h=!1;var k=a-Blockly.DropDownDiv.ARROW_SIZE/2;k=Math.max(f.left,Math.min(k,f.left+
g.width));a=Math.max(f.left,Math.min(a-e.width/2,f.left+g.width-e.width));k=Math.max(Blockly.DropDownDiv.ARROW_HORIZONTAL_PADDING,Math.min(k-a,e.width-Blockly.DropDownDiv.ARROW_HORIZONTAL_PADDING-Blockly.DropDownDiv.ARROW_SIZE));f=h?e.height-Blockly.DropDownDiv.BORDER_SIZE:0;f-=Blockly.DropDownDiv.ARROW_SIZE/2+Blockly.DropDownDiv.BORDER_SIZE;return{initialX:a,initialY:h?d-e.height:b,finalX:a,finalY:c,arrowX:k,arrowY:f,arrowAtTop:!h}};Blockly.DropDownDiv.isVisible=function(){return!!Blockly.DropDownDiv.owner_};
Blockly.DropDownDiv.hideIfOwner=function(a){return Blockly.DropDownDiv.owner_===a?(Blockly.DropDownDiv.hide(),!0):!1};Blockly.DropDownDiv.hide=function(){var a=Blockly.DropDownDiv.DIV_;a.style.transform="translate(0px, 0px)";a.style.opacity=0;Blockly.DropDownDiv.animateOutTimer_=setTimeout(function(){Blockly.DropDownDiv.hideWithoutAnimation()},1E3*Blockly.DropDownDiv.ANIMATION_TIME);Blockly.DropDownDiv.onHide_&&(Blockly.DropDownDiv.onHide_(),Blockly.DropDownDiv.onHide_=null)};
Blockly.DropDownDiv.hideWithoutAnimation=function(){if(Blockly.DropDownDiv.isVisible()){var a=Blockly.DropDownDiv.DIV_;Blockly.DropDownDiv.animateOutTimer_&&window.clearTimeout(Blockly.DropDownDiv.animateOutTimer_);a.style.transform="";a.style.top="";a.style.left="";a.style.display="none";Blockly.DropDownDiv.clearContent();Blockly.DropDownDiv.owner_=null;Blockly.DropDownDiv.onHide_&&(Blockly.DropDownDiv.onHide_(),Blockly.DropDownDiv.onHide_=null)}};Blockly.Events.UpdateToolboxFinish=function(a){Blockly.Events.UpdateToolboxFinish.superClass_.constructor.call(this,a);this.blockId="fakeId";this.workspaceId=a.id};goog.inherits(Blockly.Events.UpdateToolboxFinish,Blockly.Events.Abstract);Blockly.Events.UpdateToolboxFinish.prototype.type=Blockly.Events.UPDATE_TOOLBOX_FINISH;Blockly.Events.UpdateToolboxFinish.prototype.toJson=function(){var a=Blockly.Events.UpdateToolboxFinish.superClass_.toJson.call(this);a.blockId=this.blockId;return a};
Blockly.Events.UpdateToolboxFinish.prototype.fromJson=function(a){Blockly.Events.UpdateToolboxFinish.superClass_.fromJson.call(this,a);this.blockId=a.blockId};Blockly.Events.DragBlockOutside=function(a){a&&(Blockly.Events.DragBlockOutside.superClass_.constructor.call(this,a),this.recordUndo=!1)};goog.inherits(Blockly.Events.DragBlockOutside,Blockly.Events.BlockBase);Blockly.Events.DragBlockOutside.prototype.type=Blockly.Events.DRAG_OUTSIDE;
Blockly.Events.DragBlockOutside.prototype.toJson=function(){var a=Blockly.Events.DragBlockOutside.superClass_.toJson.call(this);this.isOutside&&(a.isOutside=this.isOutside);return a};Blockly.Events.DragBlockOutside.prototype.fromJson=function(a){Blockly.Events.DragBlockOutside.superClass_.fromJson.call(this,a);this.isOutside=a.isOutside};
Blockly.Events.EndBlockDrag=function(a,b){if(a){Blockly.Events.EndBlockDrag.superClass_.constructor.call(this,a);if(this.isOutside=b)this.xml=Blockly.Xml.blockToDom(a,!0);this.recordUndo=!1}};goog.inherits(Blockly.Events.EndBlockDrag,Blockly.Events.BlockBase);Blockly.Events.EndBlockDrag.prototype.type=Blockly.Events.END_DRAG;
Blockly.Events.EndBlockDrag.prototype.toJson=function(){var a=Blockly.Events.EndBlockDrag.superClass_.toJson.call(this);this.isOutside&&(a.isOutside=this.isOutside);this.xml&&(a.xml=this.xml);return a};Blockly.Events.EndBlockDrag.prototype.fromJson=function(a){Blockly.Events.EndBlockDrag.superClass_.fromJson.call(this,a);this.isOutside=a.isOutside;this.xml=a.xml};Blockly.BlockAnimations={};Blockly.BlockAnimations.disposeUiEffect=function(a){var b=a.workspace,c=a.getSvgRoot();b.getAudioManager().play("delete");a=b.getSvgXY(c);c=c.cloneNode(!0);c.translateX_=a.x;c.translateY_=a.y;c.setAttribute("transform","translate("+a.x+","+a.y+")");b.getParentSvg().appendChild(c);c.bBox_=c.getBBox();Blockly.BlockAnimations.disposeUiStep_(c,b.RTL,new Date,b.scale)};
Blockly.BlockAnimations.disposeUiStep_=function(a,b,c,d){var e=(new Date-c)/150;1<e?goog.dom.removeNode(a):(a.setAttribute("transform","translate("+(a.translateX_+(b?-1:1)*a.bBox_.width*d/2*e)+","+(a.translateY_+a.bBox_.height*d*e)+") scale("+(1-e)*d+")"),setTimeout(Blockly.BlockAnimations.disposeUiStep_,10,a,b,c,d))};Blockly.BlockAnimations.connectionUiEffect=function(a){a.workspace.getAudioManager().play("click")};Blockly.BlockAnimations.disconnectUiEffect=function(a){};
Blockly.BlockAnimations.disconnectUiStop=function(){};Blockly.RenderedConnection=function(a,b){Blockly.RenderedConnection.superClass_.constructor.call(this,a,b);this.offsetInBlock_=new goog.math.Coordinate(0,0)};goog.inherits(Blockly.RenderedConnection,Blockly.Connection);Blockly.RenderedConnection.prototype.distanceFrom=function(a){var b=this.x_-a.x_;a=this.y_-a.y_;return Math.sqrt(b*b+a*a)};
Blockly.RenderedConnection.prototype.bumpAwayFrom_=function(a){if(!this.sourceBlock_.workspace.isDragging()){var b=this.sourceBlock_.getRootBlock();if(!b.isInFlyout){var c=!1;if(!b.isMovable()){b=a.getSourceBlock().getRootBlock();if(!b.isMovable())return;a=this;c=!0}var d=Blockly.selected==b;d||b.addSelect();var e=a.x_+Blockly.SNAP_RADIUS-this.x_;a=a.y_+Blockly.SNAP_RADIUS-this.y_;c&&(a=-a);b.RTL&&(e=-e);b.moveBy(e,a);d||b.removeSelect()}}};
Blockly.RenderedConnection.prototype.moveTo=function(a,b){this.inDB_&&this.db_.removeConnection_(this);this.x_=a;this.y_=b;this.hidden_||this.db_.addConnection(this)};Blockly.RenderedConnection.prototype.moveBy=function(a,b){this.moveTo(this.x_+a,this.y_+b)};Blockly.RenderedConnection.prototype.moveToOffset=function(a){this.moveTo(a.x+this.offsetInBlock_.x,a.y+this.offsetInBlock_.y)};
Blockly.RenderedConnection.prototype.setOffsetInBlock=function(a,b){this.offsetInBlock_.x=a;this.offsetInBlock_.y=b};Blockly.RenderedConnection.prototype.tighten_=function(){var a=this.targetConnection.x_-this.x_,b=this.targetConnection.y_-this.y_;if(0!=a||0!=b){var c=this.targetBlock(),d=c.getSvgRoot();if(!d)throw"block is not rendered.";d=Blockly.utils.getRelativeXY(d);c.getSvgRoot().setAttribute("transform","translate("+(d.x-a)+","+(d.y-b)+")");c.moveConnections_(-a,-b)}};
Blockly.RenderedConnection.prototype.closest=function(a,b){return this.dbOpposite_.searchForClosest(this,a,b)};
Blockly.RenderedConnection.prototype.highlight=function(){var a="m -20,0 h 5 "+Blockly.BlockSvg.NOTCH_PATH_LEFT+" h 5";var b=this.sourceBlock_.getRelativeToSurfaceXY();Blockly.Connection.highlightedPath_=Blockly.utils.createSvgElement("path",{"class":"blocklyHighlightedConnectionPath",d:a,transform:"translate("+(this.x_-b.x)+","+(this.y_-b.y)+")"+(this.sourceBlock_.RTL?" scale(-1 1)":"")},this.sourceBlock_.getSvgRoot())};
Blockly.RenderedConnection.prototype.unhideAll=function(){this.setHidden(!1);var a=[];if(this.type!=Blockly.INPUT_VALUE&&this.type!=Blockly.NEXT_STATEMENT)return a;var b=this.targetBlock();if(b){if(b.isCollapsed()){var c=[];b.outputConnection&&c.push(b.outputConnection);b.nextConnection&&c.push(b.nextConnection);b.previousConnection&&c.push(b.previousConnection)}else c=b.getConnections_(!0);for(var d=0;d<c.length;d++)a.push.apply(a,c[d].unhideAll());a.length||(a[0]=b)}return a};
Blockly.RenderedConnection.prototype.unhighlight=function(){goog.dom.removeNode(Blockly.Connection.highlightedPath_);delete Blockly.Connection.highlightedPath_};Blockly.RenderedConnection.prototype.setHidden=function(a){(this.hidden_=a)&&this.inDB_?this.db_.removeConnection_(this):a||this.inDB_||this.db_.addConnection(this)};
Blockly.RenderedConnection.prototype.hideAll=function(){this.setHidden(!0);if(this.targetConnection)for(var a=this.targetBlock().getDescendants(!1),b=0;b<a.length;b++){for(var c=a[b],d=c.getConnections_(!0),e=0;e<d.length;e++)d[e].setHidden(!0);c=c.getIcons();for(e=0;e<c.length;e++)c[e].setVisible(!1)}};Blockly.RenderedConnection.prototype.isConnectionAllowed=function(a,b){return this.distanceFrom(a)>b?!1:Blockly.RenderedConnection.superClass_.isConnectionAllowed.call(this,a)};
Blockly.RenderedConnection.prototype.disconnectInternal_=function(a,b){Blockly.RenderedConnection.superClass_.disconnectInternal_.call(this,a,b);a.rendered&&a.render();b.rendered&&(b.updateDisabled(),b.render())};
Blockly.RenderedConnection.prototype.respawnShadow_=function(){var a=this.getSourceBlock(),b=this.getShadowDom();if(a.workspace&&b&&Blockly.Events.recordUndo){Blockly.RenderedConnection.superClass_.respawnShadow_.call(this);b=this.targetBlock();if(!b)throw"Couldn't respawn the shadow block that should exist here.";b.initSvg();b.render(!1);a.rendered&&a.render()}};Blockly.RenderedConnection.prototype.neighbours_=function(a){return this.dbOpposite_.getNeighbours(this,a)};
Blockly.RenderedConnection.prototype.connect_=function(a){Blockly.RenderedConnection.superClass_.connect_.call(this,a);var b=this.getSourceBlock();a=a.getSourceBlock();b.rendered&&b.updateDisabled();a.rendered&&a.updateDisabled();b.rendered&&a.rendered&&(this.type==Blockly.NEXT_STATEMENT||this.type==Blockly.PREVIOUS_STATEMENT?a.render():b.render())};
Blockly.RenderedConnection.prototype.onCheckChanged_=function(){this.isConnected()&&!this.checkType_(this.targetConnection)&&((this.isSuperior()?this.targetBlock():this.sourceBlock_).unplug(),this.sourceBlock_.bumpNeighbours_())};Blockly.InsertionMarkerManager=function(a){this.topBlock_=Blockly.selected=a;this.workspace_=a.workspace;this.lastMarker_=this.lastOnStack_=null;this.firstMarker_=this.createMarkerBlock_(this.topBlock_);this.localConnection_=this.closestConnection_=null;this.wouldDeleteBlock_=!1;this.markerConnection_=null;this.highlightingBlock_=!1;this.highlightedBlock_=null;this.availableConnections_=this.initAvailableConnections_()};
Blockly.InsertionMarkerManager.prototype.dispose=function(){this.workspace_=this.topBlock_=null;this.availableConnections_.length=0;this.localConnection_=this.closestConnection_=null;Blockly.Events.disable();try{this.firstMarker_&&(this.firstMarker_.dispose(),this.firstMarker_=null),this.lastMarker_&&(this.lastMarker_.dispose(),this.lastMarker_=null)}finally{Blockly.Events.enable()}this.highlightedBlock_=null};Blockly.InsertionMarkerManager.prototype.wouldDeleteBlock=function(){return this.wouldDeleteBlock_};
Blockly.InsertionMarkerManager.prototype.wouldConnectBlock=function(){return!!this.closestConnection_};
Blockly.InsertionMarkerManager.prototype.applyConnections=function(){if(this.closestConnection_&&(Blockly.Events.disable(),this.hidePreview_(),Blockly.Events.enable(),this.localConnection_.connect(this.closestConnection_),this.topBlock_.rendered)){var a=this.localConnection_.isSuperior()?this.closestConnection_:this.localConnection_;Blockly.BlockAnimations.connectionUiEffect(a.getSourceBlock());this.topBlock_.getRootBlock().bringToFront()}};
Blockly.InsertionMarkerManager.prototype.update=function(a,b){var c=this.getCandidate_(a);if((this.wouldDeleteBlock_=this.shouldDelete_(c,b))||this.shouldUpdatePreviews_(c,a))Blockly.Events.disable(),this.maybeHidePreview_(c),this.maybeShowPreview_(c),Blockly.Events.enable()};
Blockly.InsertionMarkerManager.prototype.createMarkerBlock_=function(a){var b=a.type;Blockly.Events.disable();try{var c=this.workspace_.newBlock(b);c.setInsertionMarker(!0,a.width);if(a.mutationToDom){var d=a.mutationToDom();d&&c.domToMutation(d)}c.initSvg()}finally{Blockly.Events.enable()}return c};
Blockly.InsertionMarkerManager.prototype.initAvailableConnections_=function(){var a=this.topBlock_.getConnections_(!1),b=this.topBlock_.lastConnectionInStack();b&&b!=this.topBlock_.nextConnection&&(a.push(b),this.lastOnStack_=b,this.lastMarker_=this.createMarkerBlock_(b.sourceBlock_));return a};
Blockly.InsertionMarkerManager.prototype.shouldUpdatePreviews_=function(a,b){var c=a.local,d=a.closest;a=a.radius;if(c&&d){if(c.type==Blockly.OUTPUT_VALUE)return!0;if(this.localConnection_&&this.closestConnection_){if(this.closestConnection_==d)return!1;c=this.localConnection_.x_+b.x-this.closestConnection_.x_;b=this.localConnection_.y_+b.y-this.closestConnection_.y_;b=Math.sqrt(c*c+b*b);return!(d&&a>b-Blockly.CURRENT_CONNECTION_PREFERENCE)}if(this.localConnection_||this.closestConnection_)console.error("Only one of localConnection_ and closestConnection_ was set.");
else return!0}else return!(!this.localConnection_||!this.closestConnection_);console.error("Returning true from shouldUpdatePreviews, but it's not clear why.");return!0};Blockly.InsertionMarkerManager.prototype.getCandidate_=function(a){for(var b=this.getStartRadius_(),c=null,d=null,e=0;e<this.availableConnections_.length;e++){var f=this.availableConnections_[e],g=f.closest(b,a);g.connection&&(c=g.connection,d=f,b=g.radius)}return{closest:c,local:d,radius:b}};
Blockly.InsertionMarkerManager.prototype.getStartRadius_=function(){return this.closestConnection_&&this.localConnection_?Blockly.CONNECTING_SNAP_RADIUS:Blockly.SNAP_RADIUS};Blockly.InsertionMarkerManager.prototype.shouldReplace_=function(){var a=this.closestConnection_,b=this.localConnection_;return b.type==Blockly.OUTPUT_VALUE?!0:b==b.sourceBlock_.getFirstStatementConnection()?!1:!this.topBlock_.nextConnection&&b.type==Blockly.PREVIOUS_STATEMENT&&a.isConnected()?!0:!1};
Blockly.InsertionMarkerManager.prototype.shouldDelete_=function(a,b){a=a&&!!a.closest&&b!=Blockly.DELETE_AREA_TOOLBOX;return!!b&&!this.topBlock_.getParent()&&this.topBlock_.isDeletable()&&!a};Blockly.InsertionMarkerManager.prototype.maybeShowPreview_=function(a){if(!this.wouldDeleteBlock_){var b=a.closest;a=a.local;b&&b!=this.closestConnection_&&!b.sourceBlock_.isInsertionMarker()&&(this.closestConnection_=b,this.localConnection_=a,this.showPreview_())}};
Blockly.InsertionMarkerManager.prototype.showPreview_=function(){this.shouldReplace_()?this.highlightBlock_():this.connectMarker_()};Blockly.InsertionMarkerManager.prototype.maybeHidePreview_=function(a){a.closest||this.hidePreview_();var b=this.closestConnection_!=a.closest;a=this.localConnection_!=a.local;this.closestConnection_&&this.localConnection_&&(b||a||this.wouldDeleteBlock_)&&this.hidePreview_();this.localConnection_=this.closestConnection_=this.markerConnection_=null};
Blockly.InsertionMarkerManager.prototype.hidePreview_=function(){this.highlightingBlock_?this.unhighlightBlock_():this.markerConnection_&&this.disconnectMarker_()};
Blockly.InsertionMarkerManager.prototype.highlightBlock_=function(){var a=this.closestConnection_,b=this.localConnection_;a.targetBlock()?(this.highlightedBlock_=a.targetBlock(),a.targetBlock().highlightForReplacement(!0)):b.type==Blockly.OUTPUT_VALUE&&(this.highlightedBlock_=a.sourceBlock_,a.sourceBlock_.highlightShapeForInput(a,!0));this.highlightingBlock_=!0};
Blockly.InsertionMarkerManager.prototype.unhighlightBlock_=function(){var a=this.closestConnection_;a.type!=Blockly.INPUT_VALUE||a.isConnected()?this.highlightedBlock_.highlightForReplacement(!1):this.highlightedBlock_.highlightShapeForInput(a,!1);this.highlightedBlock_=null;this.highlightingBlock_=!1};
Blockly.InsertionMarkerManager.prototype.disconnectMarker_=function(){if(this.markerConnection_){var a=this.markerConnection_,b=a.sourceBlock_,c=b.nextConnection,d=b.previousConnection;a!=c||d&&d.targetConnection?a.type==Blockly.NEXT_STATEMENT&&a!=c?(c=a.targetConnection,c.sourceBlock_.unplug(!1),d=d?d.targetConnection:null,b.unplug(!0),d&&d.connect(c)):b.unplug(!0):a.targetBlock().unplug(!1);if(a.targetConnection)throw"markerConnection_ still connected at the end of disconnectInsertionMarker";this.markerConnection_=
null;b.getSvgRoot().setAttribute("visibility","hidden")}else console.log("No insertion marker connection to disconnect")};
Blockly.InsertionMarkerManager.prototype.connectMarker_=function(){var a=this.localConnection_,b=this.closestConnection_,c=this.lastOnStack_&&a==this.lastOnStack_?this.lastMarker_:this.firstMarker_;a=c.getMatchingConnection(a.sourceBlock_,a);goog.asserts.assert(a!=this.markerConnection_,"Made it to connectMarker_ even though the marker isn't changing");c.render();c.rendered=!0;c.getSvgRoot().setAttribute("visibility","visible");c.positionNewBlock(c,a,b);a.connect(b);this.markerConnection_=a};Blockly.BlockDragger=function(a,b){this.draggingBlock_=a;this.workspace_=b;this.draggedConnectionManager_=new Blockly.InsertionMarkerManager(this.draggingBlock_);this.deleteArea_=null;this.wasOutside_=this.wouldDeleteBlock_=!1;this.startXY_=this.draggingBlock_.getRelativeToSurfaceXY();this.dragIconData_=Blockly.BlockDragger.initIconData_(a)};
Blockly.BlockDragger.prototype.dispose=function(){this.startWorkspace_=this.workspace_=this.draggingBlock_=null;this.dragIconData_.length=0;this.draggedConnectionManager_&&(this.draggedConnectionManager_.dispose(),this.draggedConnectionManager_=null)};Blockly.BlockDragger.initIconData_=function(a){var b=[];a=a.getDescendants(!1);for(var c=0,d;d=a[c];c++){d=d.getIcons();for(var e=0;e<d.length;e++){var f={location:d[e].getIconLocation(),icon:d[e]};b.push(f)}}return b};
Blockly.BlockDragger.prototype.startBlockDrag=function(a){Blockly.Events.getGroup()||Blockly.Events.setGroup(!0);this.workspace_.setResizesEnabled(!1);Blockly.BlockAnimations.disconnectUiStop();this.draggingBlock_.getParent()&&(this.draggingBlock_.unplug(),a=this.pixelsToWorkspaceUnits_(a),a=goog.math.Coordinate.sum(this.startXY_,a),this.draggingBlock_.translate(a.x,a.y),Blockly.BlockAnimations.disconnectUiEffect(this.draggingBlock_));this.draggingBlock_.setDragging(!0);this.draggingBlock_.moveToDragSurface_();
if(a=this.workspace_.getToolbox()){var b=this.draggingBlock_.isDeletable()?"blocklyToolboxDelete":"blocklyToolboxGrab";a.addStyle(b)}};
Blockly.BlockDragger.prototype.dragBlock=function(a,b){b=this.pixelsToWorkspaceUnits_(b);var c=goog.math.Coordinate.sum(this.startXY_,b);this.draggingBlock_.moveDuringDrag(c);this.dragIcons_(b);this.deleteArea_=this.workspace_.isDeleteArea(a);a=!this.workspace_.isInsideBlocksArea(a);this.draggedConnectionManager_.update(b,this.deleteArea_,a);a!==this.wasOutside_&&(this.fireDragOutsideEvent_(a),this.wasOutside_=a);this.updateCursorDuringBlockDrag_(a);return a};
Blockly.BlockDragger.prototype.endBlockDrag=function(a,b){this.dragBlock(a,b);this.dragIconData_=[];a=this.wasOutside_;this.fireEndDragEvent_(a);this.draggingBlock_.setMouseThroughStyle(!1);Blockly.BlockAnimations.disconnectUiStop();var c=this.pixelsToWorkspaceUnits_(b);b=goog.math.Coordinate.sum(this.startXY_,c);this.draggingBlock_.moveOffDragSurface_(b);b=this.wouldDeleteBlock_&&this.draggingBlock_.type==Blockly.PROCEDURES_DEFINITION_BLOCK_TYPE;this.maybeDeleteBlock_()||(this.draggingBlock_.moveConnections_(c.x,
c.y),this.draggingBlock_.setDragging(!1),this.fireMoveEvent_(),this.draggedConnectionManager_.wouldConnectBlock()?this.draggedConnectionManager_.applyConnections():this.draggingBlock_.render(),this.draggingBlock_.scheduleSnapAndBump());this.workspace_.setResizesEnabled(!0);if(c=this.workspace_.getToolbox()){var d=this.draggingBlock_.isDeletable()?"blocklyToolboxDelete":"blocklyToolboxGrab";c.removeStyle(d)}Blockly.Events.setGroup(!1);if(a){var e=this.workspace_;setTimeout(function(){e.undo()})}b&&
(e=this.workspace_,setTimeout(function(){for(var a=e.getAllBlocks(),b=0;b<a.length;b++){var c=a[b];if(c.type==Blockly.PROCEDURES_CALL_BLOCK_TYPE&&(c=c.getProcCode(),!Blockly.Procedures.getDefineBlock(c,e))){alert(Blockly.Msg.PROCEDURE_USED);e.undo();return}}e.refreshToolboxSelection_()}))};Blockly.BlockDragger.prototype.fireDragOutsideEvent_=function(a){var b=new Blockly.Events.DragBlockOutside(this.draggingBlock_);b.isOutside=a;Blockly.Events.fire(b)};
Blockly.BlockDragger.prototype.fireEndDragEvent_=function(a){a=new Blockly.Events.EndBlockDrag(this.draggingBlock_,a);Blockly.Events.fire(a)};Blockly.BlockDragger.prototype.fireMoveEvent_=function(){var a=new Blockly.Events.BlockMove(this.draggingBlock_);a.oldCoordinate=this.startXY_;a.recordNew();Blockly.Events.fire(a)};
Blockly.BlockDragger.prototype.maybeDeleteBlock_=function(){var a=this.workspace_.trashcan;this.wouldDeleteBlock_?(a&&goog.Timer.callOnce(a.close,100,a),this.fireMoveEvent_(),this.draggingBlock_.dispose(!1,!0)):a&&a.close();return this.wouldDeleteBlock_};
Blockly.BlockDragger.prototype.updateCursorDuringBlockDrag_=function(a){this.wouldDeleteBlock_=this.draggedConnectionManager_.wouldDeleteBlock();var b=this.workspace_.trashcan;this.wouldDeleteBlock_?(this.draggingBlock_.setDeleteStyle(!0),this.deleteArea_==Blockly.DELETE_AREA_TRASH&&b&&b.setOpen_(!0)):(this.draggingBlock_.setDeleteStyle(!1),b&&b.setOpen_(!1));a?this.draggingBlock_.setMouseThroughStyle(!0):this.draggingBlock_.setMouseThroughStyle(!1)};
Blockly.BlockDragger.prototype.pixelsToWorkspaceUnits_=function(a){a=new goog.math.Coordinate(a.x/this.workspace_.scale,a.y/this.workspace_.scale);this.workspace_.isMutator&&(a=a.scale(1/this.workspace_.options.parentWorkspace.scale));return a};Blockly.BlockDragger.prototype.dragIcons_=function(a){for(var b=0;b<this.dragIconData_.length;b++){var c=this.dragIconData_[b];c.icon.setIconLocation(goog.math.Coordinate.sum(c.location,a))}};Blockly.WorkspaceCommentSvg=function(a,b,c,d,e,f){this.svgGroup_=Blockly.utils.createSvgElement("g",{},null);this.svgGroup_.translate_="";this.svgRect_=Blockly.utils.createSvgElement("rect",{"class":"scratchCommentRect scratchWorkspaceCommentBorder",x:0,y:0,rx:4*Blockly.WorkspaceCommentSvg.BORDER_WIDTH,ry:4*Blockly.WorkspaceCommentSvg.BORDER_WIDTH});this.svgGroup_.appendChild(this.svgRect_);this.rendered_=!1;this.useDragSurface_=Blockly.utils.is3dSupported()&&!!a.blockDragSurface_;Blockly.WorkspaceCommentSvg.superClass_.constructor.call(this,
a,b,c,d,e,f);this.render()};goog.inherits(Blockly.WorkspaceCommentSvg,Blockly.WorkspaceComment);Blockly.WorkspaceCommentSvg.DEFAULT_SIZE=200;
Blockly.WorkspaceCommentSvg.prototype.dispose=function(){this.workspace&&(Blockly.selected==this&&(this.unselect(),this.workspace.cancelCurrentGesture()),Blockly.Events.isEnabled()&&Blockly.Events.fire(new Blockly.Events.CommentDelete(this)),goog.dom.removeNode(this.svgGroup_),this.svgRect_=this.svgGroup_=null,this.disposeInternal_(),Blockly.Events.disable(),Blockly.WorkspaceCommentSvg.superClass_.dispose.call(this),Blockly.Events.enable())};
Blockly.WorkspaceCommentSvg.prototype.initSvg=function(){goog.asserts.assert(this.workspace.rendered,"Workspace is headless.");this.workspace.options.readOnly||this.eventsInit_||(Blockly.bindEventWithChecks_(this.svgRectTarget_,"mousedown",this,this.pathMouseDown_),Blockly.bindEventWithChecks_(this.svgHandleTarget_,"mousedown",this,this.pathMouseDown_));this.eventsInit_=!0;this.updateMovable();this.getSvgRoot().parentNode||this.workspace.getBubbleCanvas().appendChild(this.getSvgRoot())};
Blockly.WorkspaceCommentSvg.prototype.pathMouseDown_=function(a){var b=this.workspace.getGesture(a);b&&b.handleBubbleStart(a,this)};Blockly.WorkspaceCommentSvg.prototype.showContextMenu_=function(a){if(!this.workspace.options.readOnly){var b=[];this.isDeletable()&&this.isMovable()&&(b.push(Blockly.ContextMenu.commentDuplicateOption(this)),b.push(Blockly.ContextMenu.commentDeleteOption(this)));Blockly.ContextMenu.show(a,b,this.RTL)}};
Blockly.WorkspaceCommentSvg.prototype.select=function(){if(Blockly.selected!=this){var a=null;if(Blockly.selected){a=Blockly.selected.id;Blockly.Events.disable();try{Blockly.selected.unselect()}finally{Blockly.Events.enable()}}a=new Blockly.Events.Ui(null,"selected",a,this.id);a.workspaceId=this.workspace.id;Blockly.Events.fire(a);Blockly.selected=this;this.addSelect()}};
Blockly.WorkspaceCommentSvg.prototype.unselect=function(){if(Blockly.selected==this){var a=new Blockly.Events.Ui(null,"selected",this.id,null);a.workspaceId=this.workspace.id;Blockly.Events.fire(a);Blockly.selected=null;this.removeSelect()}};Blockly.WorkspaceCommentSvg.prototype.addSelect=function(){Blockly.utils.addClass(this.svgGroup_,"blocklySelected");this.setFocus()};Blockly.WorkspaceCommentSvg.prototype.removeSelect=function(){Blockly.utils.removeClass(this.svgGroup_,"blocklySelected");this.blurFocus()};
Blockly.WorkspaceCommentSvg.prototype.addFocus=function(){Blockly.utils.addClass(this.svgGroup_,"blocklyFocused")};Blockly.WorkspaceCommentSvg.prototype.removeFocus=function(){Blockly.utils.removeClass(this.svgGroup_,"blocklyFocused")};
Blockly.WorkspaceCommentSvg.prototype.getRelativeToSurfaceXY=function(){var a=0,b=0,c=this.useDragSurface_?this.workspace.blockDragSurface_.getGroup():null,d=this.getSvgRoot();if(d){do{var e=Blockly.utils.getRelativeXY(d);a+=e.x;b+=e.y;this.useDragSurface_&&this.workspace.blockDragSurface_.getCurrentBlock()==d&&(e=this.workspace.blockDragSurface_.getSurfaceTranslation(),a+=e.x,b+=e.y);d=d.parentNode}while(d&&d!=this.workspace.getBubbleCanvas()&&d!=c)}return this.xy_=new goog.math.Coordinate(a,b)};
Blockly.WorkspaceCommentSvg.prototype.moveBy=function(a,b){var c=new Blockly.Events.CommentMove(this),d=this.getRelativeToSurfaceXY();this.translate(d.x+a,d.y+b);c.recordNew();Blockly.Events.fire(c);this.workspace.resizeContents()};Blockly.WorkspaceCommentSvg.prototype.translate=function(a,b){this.xy_=new goog.math.Coordinate(a,b);this.getSvgRoot().setAttribute("transform","translate("+a+","+b+")")};
Blockly.WorkspaceCommentSvg.prototype.moveToDragSurface_=function(){if(this.useDragSurface_){var a=this.getRelativeToSurfaceXY();this.clearTransformAttributes_();this.workspace.blockDragSurface_.translateSurface(a.x,a.y);this.workspace.blockDragSurface_.setBlocksAndShow(this.getSvgRoot())}};Blockly.WorkspaceCommentSvg.prototype.moveOffDragSurface_=function(a){this.useDragSurface_&&(this.translate(a.x,a.y),this.workspace.blockDragSurface_.clearAndHide(this.workspace.getCanvas()))};
Blockly.WorkspaceCommentSvg.prototype.moveDuringDrag=function(a,b){a?a.translateSurface(b.x,b.y):(this.svgGroup_.translate_="translate("+b.x+","+b.y+")",this.svgGroup_.setAttribute("transform",this.svgGroup_.translate_+this.svgGroup_.skew_))};Blockly.WorkspaceCommentSvg.prototype.moveTo=function(a,b){this.translate(a,b)};Blockly.WorkspaceCommentSvg.prototype.clearTransformAttributes_=function(){Blockly.utils.removeAttribute(this.getSvgRoot(),"transform")};
Blockly.WorkspaceCommentSvg.prototype.getBubbleSize=function(){if(this.rendered_)return{width:parseInt(this.svgRect_.getAttribute("width")),height:parseInt(this.svgRect_.getAttribute("height"))};this.getHeightWidth()};
Blockly.WorkspaceCommentSvg.prototype.getBoundingRectangle=function(){var a=this.getRelativeToSurfaceXY(),b=this.getHeightWidth();if(this.RTL){var c=new goog.math.Coordinate(a.x-b.width,a.y);a=new goog.math.Coordinate(a.x,a.y+b.height)}else c=new goog.math.Coordinate(a.x,a.y),a=new goog.math.Coordinate(a.x+b.width,a.y+b.height);return{topLeft:c,bottomRight:a}};
Blockly.WorkspaceCommentSvg.prototype.updateMovable=function(){this.isMovable()?Blockly.utils.addClass(this.svgGroup_,"blocklyDraggable"):Blockly.utils.removeClass(this.svgGroup_,"blocklyDraggable")};Blockly.WorkspaceCommentSvg.prototype.setMovable=function(a){Blockly.WorkspaceCommentSvg.superClass_.setMovable.call(this,a);this.updateMovable()};
Blockly.WorkspaceCommentSvg.prototype.setDragging=function(a){a?(a=this.getSvgRoot(),a.translate_="",a.skew_="",Blockly.utils.addClass(this.svgGroup_,"blocklyDragging")):Blockly.utils.removeClass(this.svgGroup_,"blocklyDragging")};Blockly.WorkspaceCommentSvg.prototype.getSvgRoot=function(){return this.svgGroup_};Blockly.WorkspaceCommentSvg.prototype.getText=function(){return this.textarea_?this.textarea_.value:this.content_};
Blockly.WorkspaceCommentSvg.prototype.setText=function(a){Blockly.WorkspaceCommentSvg.superClass_.setText.call(this,a);this.textarea_&&(this.textarea_.value=a)};Blockly.WorkspaceCommentSvg.prototype.setDeleteStyle=function(a){a?Blockly.utils.addClass(this.svgGroup_,"blocklyDraggingDelete"):Blockly.utils.removeClass(this.svgGroup_,"blocklyDraggingDelete")};Blockly.WorkspaceCommentSvg.prototype.setAutoLayout=function(){};
Blockly.WorkspaceCommentSvg.fromXml=function(a,b,c){Blockly.Events.disable();try{var d=Blockly.WorkspaceComment.parseAttributes(a),e=new Blockly.WorkspaceCommentSvg(b,d.content,d.h,d.w,d.minimized,d.id);b.rendered&&(e.initSvg(),e.render(!1));if(!isNaN(d.x)&&!isNaN(d.y))if(b.RTL){var f=c||b.getWidth();e.moveBy(f-d.x,d.y)}else e.moveBy(d.x,d.y)}finally{Blockly.Events.enable()}Blockly.WorkspaceComment.fireCreateEvent(e);return e};
Blockly.WorkspaceCommentSvg.prototype.toXmlWithXY=function(a){var b;this.workspace.RTL&&(b=this.workspace.getWidth());a=this.toXml(a);var c=this.getRelativeToSurfaceXY();a.setAttribute("x",Math.round(this.workspace.RTL?b-c.x:c.x));a.setAttribute("y",Math.round(c.y));a.setAttribute("h",this.getHeight());a.setAttribute("w",this.getWidth());return a};Blockly.BubbleDragger=function(a,b){this.draggingBubble_=a;this.workspace_=b;this.deleteArea_=null;this.wouldDeleteBubble_=!1;this.startXY_=this.draggingBubble_.getRelativeToSurfaceXY();this.dragSurface_=Blockly.utils.is3dSupported()&&b.getBlockDragSurface()?b.getBlockDragSurface():null};Blockly.BubbleDragger.prototype.dispose=function(){this.dragSurface_=this.workspace_=this.draggingBubble_=null};
Blockly.BubbleDragger.prototype.startBubbleDrag=function(){Blockly.Events.getGroup()||Blockly.Events.setGroup(!0);this.workspace_.setResizesEnabled(!1);this.draggingBubble_.setAutoLayout(!1);this.dragSurface_&&this.moveToDragSurface_();this.draggingBubble_.setDragging&&this.draggingBubble_.setDragging(!0);var a=this.workspace_.getToolbox();if(a){var b=this.draggingBubble_.isDeletable()?"blocklyToolboxDelete":"blocklyToolboxGrab";a.addStyle(b)}};
Blockly.BubbleDragger.prototype.dragBubble=function(a,b){b=this.pixelsToWorkspaceUnits_(b);b=goog.math.Coordinate.sum(this.startXY_,b);this.draggingBubble_.moveDuringDrag(this.dragSurface_,b);this.draggingBubble_.isDeletable()&&(this.deleteArea_=this.workspace_.isDeleteArea(a),this.updateCursorDuringBubbleDrag_())};
Blockly.BubbleDragger.prototype.maybeDeleteBubble_=function(){var a=this.workspace_.trashcan;this.wouldDeleteBubble_?(a&&setTimeout(a.close.bind(a),100),this.fireMoveEvent_(),this.draggingBubble_.dispose(!1,!0)):a&&a.close();return this.wouldDeleteBubble_};
Blockly.BubbleDragger.prototype.updateCursorDuringBubbleDrag_=function(){this.wouldDeleteBubble_=this.deleteArea_!=Blockly.DELETE_AREA_NONE;var a=this.workspace_.trashcan;this.wouldDeleteBubble_?(this.draggingBubble_.setDeleteStyle(!0),this.deleteArea_==Blockly.DELETE_AREA_TRASH&&a&&a.setOpen_(!0)):(this.draggingBubble_.setDeleteStyle(!1),a&&a.setOpen_(!1))};
Blockly.BubbleDragger.prototype.endBubbleDrag=function(a,b){this.dragBubble(a,b);a=this.pixelsToWorkspaceUnits_(b);a=goog.math.Coordinate.sum(this.startXY_,a);this.draggingBubble_.moveTo(a.x,a.y);this.maybeDeleteBubble_()||(this.dragSurface_&&this.dragSurface_.clearAndHide(this.workspace_.getBubbleCanvas()),this.draggingBubble_.setDragging&&this.draggingBubble_.setDragging(!1),this.fireMoveEvent_());this.workspace_.setResizesEnabled(!0);this.workspace_.toolbox_&&(a=this.draggingBubble_.isDeletable()?
"blocklyToolboxDelete":"blocklyToolboxGrab",this.workspace_.toolbox_.removeStyle(a));Blockly.Events.setGroup(!1)};Blockly.BubbleDragger.prototype.fireMoveEvent_=function(){if(this.draggingBubble_.isComment)var a=new Blockly.Events.CommentMove(this.draggingBubble_);else if(this.draggingBubble_ instanceof Blockly.ScratchBubble)a=new Blockly.Events.CommentMove(this.draggingBubble_.comment);else return;a.setOldCoordinate(this.startXY_);a.recordNew();Blockly.Events.fire(a)};
Blockly.BubbleDragger.prototype.pixelsToWorkspaceUnits_=function(a){a=new goog.math.Coordinate(a.x/this.workspace_.scale,a.y/this.workspace_.scale);this.workspace_.isMutator&&(a=a.scale(1/this.workspace_.options.parentWorkspace.scale));return a};Blockly.BubbleDragger.prototype.moveToDragSurface_=function(){this.draggingBubble_.moveTo(0,0);this.dragSurface_.translateSurface(this.startXY_.x,this.startXY_.y);this.dragSurface_.setBlocksAndShow(this.draggingBubble_.getSvgRoot())};Blockly.WorkspaceDragger=function(a){this.workspace_=a;this.startDragMetrics_=a.getMetrics();this.startScrollXY_=new goog.math.Coordinate(a.scrollX,a.scrollY)};Blockly.WorkspaceDragger.prototype.dispose=function(){this.workspace_=null};Blockly.WorkspaceDragger.prototype.startDrag=function(){Blockly.selected&&Blockly.selected.unselect();this.workspace_.setupDragSurface()};Blockly.WorkspaceDragger.prototype.endDrag=function(a){this.drag(a);this.workspace_.resetDragSurface()};
Blockly.WorkspaceDragger.prototype.drag=function(a){var b=this.startDragMetrics_,c=goog.math.Coordinate.sum(this.startScrollXY_,a);a=Math.min(c.x,-b.contentLeft);c=Math.min(c.y,-b.contentTop);a=Math.max(a,b.viewWidth-b.contentLeft-b.contentWidth);c=Math.max(c,b.viewHeight-b.contentTop-b.contentHeight);a=-a-b.contentLeft;c=-c-b.contentTop;this.updateScroll_(a,c)};Blockly.WorkspaceDragger.prototype.updateScroll_=function(a,b){this.workspace_.scrollbar.set(a,b)};Blockly.FlyoutDragger=function(a){Blockly.FlyoutDragger.superClass_.constructor.call(this,a.getWorkspace());this.scrollbar_=a.scrollbar_;this.horizontalLayout_=a.horizontalLayout_};goog.inherits(Blockly.FlyoutDragger,Blockly.WorkspaceDragger);Blockly.FlyoutDragger.prototype.updateScroll_=function(a,b){this.horizontalLayout_?this.scrollbar_.set(a):this.scrollbar_.set(b)};Blockly.scratchBlocksUtils={};Blockly.scratchBlocksUtils.measureText=function(a,b,c,d){var e=document.createElement("canvas").getContext("2d");e.font=c+" "+a+" "+b;return e.measureText(d).width};Blockly.scratchBlocksUtils.encodeEntities=function(a){return a.replace(/[\u00A0-\u9999<>&]/gim,function(a){return"&#"+a.charCodeAt(0)+";"})};
Blockly.scratchBlocksUtils.changeObscuredShadowIds=function(a){a=a.getDescendants(!1);for(var b=a.length-1;0<=b;b--)for(var c=a[b],d=0;d<c.inputList.length;d++){var e=c.inputList[d].connection;if(e){var f=e.getShadowDom();f&&(f.setAttribute("id",Blockly.utils.genUid()),e.setShadowDom(f))}}};
Blockly.scratchBlocksUtils.isShadowArgumentReporter=function(a){return a.isShadow()&&("argument_reporter_boolean"==a.type||"argument_reporter_string_number"==a.type||"argument_reporter_number"==a.type||"argument_reporter_string"==a.type)};Blockly.scratchBlocksUtils.compareStrings=function(a,b){return a.localeCompare(b,[],{sensitivity:"base",numeric:!0})};
Blockly.scratchBlocksUtils.blockIsRecyclable=function(a){if(a.mutationToDom&&a.domToMutation)return!1;for(var b=0;b<a.inputList.length;b++){for(var c=a.inputList[b],d=0;d<c.fieldRow.length;d++){var e=c.fieldRow[d];if(e instanceof Blockly.FieldVariable||e instanceof Blockly.FieldVariableGetter||(e instanceof Blockly.FieldDropdown||e instanceof Blockly.FieldNumberDropdown||e instanceof Blockly.FieldTextDropdown)&&e.isOptionListDynamic())return!1}if(c.connection&&(c=c.connection.targetBlock())&&!Blockly.scratchBlocksUtils.blockIsRecyclable(c))return!1}return!0};
Blockly.scratchBlocksUtils.duplicateAndDragCallback=function(a,b){var c="mouse"===Blockly.Touch.getTouchIdentifierFromEvent(b);return function(d){setTimeout(function(){var e=a.workspace;if(!a.getSvgRoot())throw Error("oldBlock is not rendered.");var f=Blockly.Xml.blockToDom(a);e.setResizesEnabled(!1);Blockly.Events.disable();try{var g=Blockly.Xml.domToBlock(f,e);Blockly.scratchBlocksUtils.changeObscuredShadowIds(g);if(!g.getSvgRoot())throw Error("newBlock is not rendered.");var h=a.getRelativeToSurfaceXY();
g.moveBy(h.x,h.y);c||g.moveBy(e.RTL?-100:100,100)}finally{Blockly.Events.enable()}Blockly.Events.isEnabled()&&Blockly.Events.fire(new Blockly.Events.BlockCreate(g));c&&e.startDragWithFakeEvent({clientX:b.clientX,clientY:b.clientY,type:"mousedown",preventDefault:function(){d.preventDefault()},stopPropagation:function(){d.stopPropagation()},target:d.target},g)},0)}};Blockly.Tooltip={};Blockly.Tooltip.visible=!1;Blockly.Tooltip.blocked_=!1;Blockly.Tooltip.LIMIT=50;Blockly.Tooltip.mouseOutPid_=0;Blockly.Tooltip.showPid_=0;Blockly.Tooltip.lastX_=0;Blockly.Tooltip.lastY_=0;Blockly.Tooltip.element_=null;Blockly.Tooltip.poisonedElement_=null;Blockly.Tooltip.OFFSET_X=0;Blockly.Tooltip.OFFSET_Y=10;Blockly.Tooltip.RADIUS_OK=10;Blockly.Tooltip.HOVER_MS=750;Blockly.Tooltip.MARGINS=5;Blockly.Tooltip.DIV=null;
Blockly.Tooltip.createDom=function(){Blockly.Tooltip.DIV||(Blockly.Tooltip.DIV=goog.dom.createDom("DIV","blocklyTooltipDiv"),document.body.appendChild(Blockly.Tooltip.DIV))};Blockly.Tooltip.bindMouseEvents=function(a){Blockly.bindEvent_(a,"mouseover",null,Blockly.Tooltip.onMouseOver_);Blockly.bindEvent_(a,"mouseout",null,Blockly.Tooltip.onMouseOut_);a.addEventListener("mousemove",Blockly.Tooltip.onMouseMove_,!1)};
Blockly.Tooltip.onMouseOver_=function(a){if(!Blockly.Tooltip.blocked_){for(a=a.target;!goog.isString(a.tooltip)&&!goog.isFunction(a.tooltip);)a=a.tooltip;Blockly.Tooltip.element_!=a&&(Blockly.Tooltip.hide(),Blockly.Tooltip.poisonedElement_=null,Blockly.Tooltip.element_=a);clearTimeout(Blockly.Tooltip.mouseOutPid_)}};
Blockly.Tooltip.onMouseOut_=function(a){Blockly.Tooltip.blocked_||(Blockly.Tooltip.mouseOutPid_=setTimeout(function(){Blockly.Tooltip.element_=null;Blockly.Tooltip.poisonedElement_=null;Blockly.Tooltip.hide()},1),clearTimeout(Blockly.Tooltip.showPid_))};
Blockly.Tooltip.onMouseMove_=function(a){if(Blockly.Tooltip.element_&&Blockly.Tooltip.element_.tooltip&&!Blockly.WidgetDiv.isVisible()&&!Blockly.Tooltip.blocked_)if(Blockly.Tooltip.visible){var b=Blockly.Tooltip.lastX_-a.pageX;a=Blockly.Tooltip.lastY_-a.pageY;Math.sqrt(b*b+a*a)>Blockly.Tooltip.RADIUS_OK&&Blockly.Tooltip.hide()}else Blockly.Tooltip.poisonedElement_!=Blockly.Tooltip.element_&&(clearTimeout(Blockly.Tooltip.showPid_),Blockly.Tooltip.lastX_=a.pageX,Blockly.Tooltip.lastY_=a.pageY,Blockly.Tooltip.showPid_=
setTimeout(Blockly.Tooltip.show_,Blockly.Tooltip.HOVER_MS))};Blockly.Tooltip.hide=function(){Blockly.Tooltip.visible&&(Blockly.Tooltip.visible=!1,Blockly.Tooltip.DIV&&(Blockly.Tooltip.DIV.style.display="none"));Blockly.Tooltip.showPid_&&clearTimeout(Blockly.Tooltip.showPid_)};Blockly.Tooltip.block=function(){Blockly.Tooltip.hide();Blockly.Tooltip.blocked_=!0};Blockly.Tooltip.unblock=function(){Blockly.Tooltip.blocked_=!1};
Blockly.Tooltip.show_=function(){if(!Blockly.Tooltip.blocked_&&(Blockly.Tooltip.poisonedElement_=Blockly.Tooltip.element_,Blockly.Tooltip.DIV)){goog.dom.removeChildren(Blockly.Tooltip.DIV);for(var a=Blockly.Tooltip.element_.tooltip;goog.isFunction(a);)a=a();a=Blockly.utils.wrap(a,Blockly.Tooltip.LIMIT);a=a.split("\n");for(var b=0;b<a.length;b++){var c=document.createElement("div");c.appendChild(document.createTextNode(a[b]));Blockly.Tooltip.DIV.appendChild(c)}a=Blockly.Tooltip.element_.RTL;b=goog.dom.getViewportSize();
Blockly.Tooltip.DIV.style.direction=a?"rtl":"ltr";Blockly.Tooltip.DIV.style.display="block";Blockly.Tooltip.visible=!0;c=Blockly.Tooltip.lastX_;c=a?c-(Blockly.Tooltip.OFFSET_X+Blockly.Tooltip.DIV.offsetWidth):c+Blockly.Tooltip.OFFSET_X;var d=Blockly.Tooltip.lastY_+Blockly.Tooltip.OFFSET_Y;d+Blockly.Tooltip.DIV.offsetHeight>b.height+window.scrollY&&(d-=Blockly.Tooltip.DIV.offsetHeight+2*Blockly.Tooltip.OFFSET_Y);a?c=Math.max(Blockly.Tooltip.MARGINS-window.scrollX,c):c+Blockly.Tooltip.DIV.offsetWidth>
b.width+window.scrollX-2*Blockly.Tooltip.MARGINS&&(c=b.width-Blockly.Tooltip.DIV.offsetWidth-2*Blockly.Tooltip.MARGINS);Blockly.Tooltip.DIV.style.top=d+"px";Blockly.Tooltip.DIV.style.left=c+"px"}};Blockly.Gesture=function(a,b){this.startWorkspace_=this.targetBlock_=this.startBlock_=this.startField_=this.startBubble_=this.currentDragDeltaXY_=this.mouseDownXY_=null;this.creatorWorkspace_=b;this.isDraggingBubble_=this.isDraggingBlock_=this.isDraggingWorkspace_=this.hasExceededDragRadius_=!1;this.mostRecentEvent_=a;this.flyout_=this.workspaceDragger_=this.blockDragger_=this.bubbleDragger_=this.onUpWrapper_=this.onMoveWrapper_=null;this.shouldDuplicateOnDrag_=this.isEnding_=this.hasStarted_=this.calledUpdateIsDragging_=
!1};
Blockly.Gesture.prototype.dispose=function(){Blockly.Touch.clearTouchIdentifier();Blockly.Tooltip.unblock();this.creatorWorkspace_.clearGesture();this.onMoveWrapper_&&Blockly.unbindEvent_(this.onMoveWrapper_);this.onUpWrapper_&&Blockly.unbindEvent_(this.onUpWrapper_);this.flyout_=this.startWorkspace_=this.targetBlock_=this.startBlock_=this.startField_=null;this.blockDragger_&&(this.blockDragger_.dispose(),this.blockDragger_=null);this.workspaceDragger_&&(this.workspaceDragger_.dispose(),this.workspaceDragger_=null);
this.bubbleDragger_&&(this.bubbleDragger_.dispose(),this.bubbleDragger_=null)};Blockly.Gesture.prototype.updateFromEvent_=function(a){var b=new goog.math.Coordinate(a.clientX,a.clientY);this.updateDragDelta_(b)&&(this.updateIsDragging_(),Blockly.longStop_());this.mostRecentEvent_=a};
Blockly.Gesture.prototype.updateDragDelta_=function(a){this.currentDragDeltaXY_=goog.math.Coordinate.difference(a,this.mouseDownXY_);return this.hasExceededDragRadius_?!1:this.hasExceededDragRadius_=goog.math.Coordinate.magnitude(this.currentDragDeltaXY_)>(this.flyout_?Blockly.FLYOUT_DRAG_RADIUS:Blockly.DRAG_RADIUS)};
Blockly.Gesture.prototype.updateIsDraggingFromFlyout_=function(){return this.targetBlock_.disabled?!1:!this.flyout_.isScrollable()||this.flyout_.isDragTowardWorkspace(this.currentDragDeltaXY_)?(this.startWorkspace_=this.flyout_.targetWorkspace_,this.startWorkspace_.updateScreenCalculationsIfScrolled(),Blockly.Events.getGroup()||Blockly.Events.setGroup(!0),this.startBlock_=null,this.targetBlock_=this.flyout_.createBlock(this.targetBlock_),this.targetBlock_.select(),!0):!1};
Blockly.Gesture.prototype.updateIsDraggingBubble_=function(){if(!this.startBubble_)return!1;this.isDraggingBubble_=!0;this.startDraggingBubble_();return!0};Blockly.Gesture.prototype.updateIsDraggingBlock_=function(){if(!this.targetBlock_)return!1;if(this.flyout_)this.isDraggingBlock_=this.updateIsDraggingFromFlyout_();else if(this.targetBlock_.isMovable()||this.shouldDuplicateOnDrag_)this.isDraggingBlock_=!0;return this.isDraggingBlock_?(this.startDraggingBlock_(),!0):!1};
Blockly.Gesture.prototype.updateIsDraggingWorkspace_=function(){if(this.flyout_?this.flyout_.isScrollable():this.startWorkspace_&&this.startWorkspace_.isDraggable())this.workspaceDragger_=this.flyout_?new Blockly.FlyoutDragger(this.flyout_):new Blockly.WorkspaceDragger(this.startWorkspace_),this.isDraggingWorkspace_=!0,this.workspaceDragger_.startDrag()};
Blockly.Gesture.prototype.updateIsDragging_=function(){goog.asserts.assert(!this.calledUpdateIsDragging_,"updateIsDragging_ should only be called once per gesture.");this.calledUpdateIsDragging_=!0;this.updateIsDraggingBubble_()||this.updateIsDraggingBlock_()||this.updateIsDraggingWorkspace_()};
Blockly.Gesture.prototype.startDraggingBlock_=function(){this.shouldDuplicateOnDrag_&&this.duplicateOnDrag_();this.blockDragger_=new Blockly.BlockDragger(this.targetBlock_,this.startWorkspace_);this.blockDragger_.startBlockDrag(this.currentDragDeltaXY_);this.blockDragger_.dragBlock(this.mostRecentEvent_,this.currentDragDeltaXY_)};
Blockly.Gesture.prototype.startDraggingBubble_=function(){this.bubbleDragger_=new Blockly.BubbleDragger(this.startBubble_,this.startWorkspace_);this.bubbleDragger_.startBubbleDrag();this.bubbleDragger_.dragBubble(this.mostRecentEvent_,this.currentDragDeltaXY_)};
Blockly.Gesture.prototype.doStart=function(a){Blockly.utils.isTargetInput(a)?this.cancel():(this.hasStarted_=!0,Blockly.BlockAnimations.disconnectUiStop(),this.startWorkspace_.updateScreenCalculationsIfScrolled(),this.startWorkspace_.isMutator&&this.startWorkspace_.resize(),this.startWorkspace_.markFocused(),this.mostRecentEvent_=a,Blockly.hideChaff(!!this.flyout_),Blockly.Tooltip.block(),this.targetBlock_&&this.targetBlock_.select(),Blockly.utils.isRightButton(a)?this.handleRightClick(a):(goog.string.caseInsensitiveEquals(a.type,
"touchstart")&&Blockly.longStart_(a,this),this.mouseDownXY_=new goog.math.Coordinate(a.clientX,a.clientY),this.currentDragDeltaXY_=new goog.math.Coordinate(0,0),this.bindMouseEvents(a)))};Blockly.Gesture.prototype.bindMouseEvents=function(a){this.onMoveWrapper_=Blockly.bindEventWithChecks_(document,"mousemove",null,this.handleMove.bind(this));this.onUpWrapper_=Blockly.bindEventWithChecks_(document,"mouseup",null,this.handleUp.bind(this));a.preventDefault();a.stopPropagation()};
Blockly.Gesture.prototype.handleMove=function(a){var b=!0;this.updateFromEvent_(a);this.isDraggingWorkspace_?this.workspaceDragger_.drag(this.currentDragDeltaXY_):this.isDraggingBlock_?this.blockDragger_.dragBlock(this.mostRecentEvent_,this.currentDragDeltaXY_)&&(b=!1):this.isDraggingBubble_&&this.bubbleDragger_.dragBubble(this.mostRecentEvent_,this.currentDragDeltaXY_);b&&(a.preventDefault(),a.stopPropagation())};
Blockly.Gesture.prototype.handleUp=function(a){this.updateFromEvent_(a);Blockly.longStop_();this.isEnding_||(this.isEnding_=!0,this.isDraggingBubble_?this.bubbleDragger_.endBubbleDrag(a,this.currentDragDeltaXY_):this.isDraggingBlock_?this.blockDragger_.endBlockDrag(a,this.currentDragDeltaXY_):this.isDraggingWorkspace_?this.workspaceDragger_.endDrag(this.currentDragDeltaXY_):this.isBubbleClick_()?this.doBubbleClick_():this.isFieldClick_()?this.doFieldClick_():this.isBlockClick_()?this.doBlockClick_():
this.isWorkspaceClick_()&&this.doWorkspaceClick_(),a.preventDefault(),a.stopPropagation(),this.dispose())};
Blockly.Gesture.prototype.cancel=function(){this.isEnding_?console.log("Trying to cancel a gesture recursively."):(this.isEnding_=!0,Blockly.longStop_(),this.isDraggingBubble_?this.bubbleDragger_.endBubbleDrag(this.mostRecentEvent_,this.currentDragDeltaXY_):this.isDraggingBlock_?this.blockDragger_.endBlockDrag(this.mostRecentEvent_,this.currentDragDeltaXY_):this.isDraggingWorkspace_&&this.workspaceDragger_.endDrag(this.currentDragDeltaXY_),this.dispose())};
Blockly.Gesture.prototype.handleRightClick=function(a){this.targetBlock_?(this.bringBlockToFront_(),Blockly.hideChaff(this.flyout_),this.targetBlock_.showContextMenu_(a)):this.startBubble_?this.startBubble_.showContextMenu_(a):this.startWorkspace_&&!this.flyout_&&(Blockly.hideChaff(),this.startWorkspace_.showContextMenu_(a));a.preventDefault();a.stopPropagation();this.dispose()};
Blockly.Gesture.prototype.handleWsStart=function(a,b){goog.asserts.assert(!this.hasStarted_,"Tried to call gesture.handleWsStart, but the gesture had already been started.");this.setStartWorkspace_(b);this.mostRecentEvent_=a;this.doStart(a)};Blockly.Gesture.prototype.handleFlyoutStart=function(a,b){goog.asserts.assert(!this.hasStarted_,"Tried to call gesture.handleFlyoutStart, but the gesture had already been started.");this.setStartFlyout_(b);this.handleWsStart(a,b.getWorkspace())};
Blockly.Gesture.prototype.handleBlockStart=function(a,b){goog.asserts.assert(!this.hasStarted_,"Tried to call gesture.handleBlockStart, but the gesture had already been started.");this.setStartBlock(b);this.mostRecentEvent_=a};Blockly.Gesture.prototype.handleBubbleStart=function(a,b){goog.asserts.assert(!this.hasStarted_,"Tried to call gesture.handleBubbleStart, but the gesture had already been started.");this.setStartBubble(b);this.mostRecentEvent_=a};
Blockly.Gesture.prototype.doBubbleClick_=function(){this.startBubble_.setFocus&&this.startBubble_.setFocus();this.startBubble_.select&&this.startBubble_.select()};Blockly.Gesture.prototype.doFieldClick_=function(){this.startField_.showEditor_();this.bringBlockToFront_()};
Blockly.Gesture.prototype.doBlockClick_=function(){if(this.flyout_&&this.flyout_.autoClose)this.targetBlock_.disabled||(Blockly.Events.getGroup()||Blockly.Events.setGroup(!0),this.flyout_.createBlock(this.targetBlock_).scheduleSnapAndBump());else if(!Blockly.WidgetDiv.isVisible()&&!Blockly.DropDownDiv.isVisible()){Blockly.Events.fire(new Blockly.Events.Ui(this.startBlock_,"click",void 0,void 0));var a=this.startBlock_.getRootBlock();Blockly.Events.fire(new Blockly.Events.Ui(a,"stackclick",void 0,
void 0))}this.bringBlockToFront_();Blockly.Events.setGroup(!1)};Blockly.Gesture.prototype.doWorkspaceClick_=function(){Blockly.selected&&Blockly.selected.unselect()};Blockly.Gesture.prototype.bringBlockToFront_=function(){this.targetBlock_&&!this.flyout_&&this.targetBlock_.bringToFront()};Blockly.Gesture.prototype.setStartField=function(a){goog.asserts.assert(!this.hasStarted_,"Tried to call gesture.setStartField, but the gesture had already been started.");this.startField_||(this.startField_=a)};
Blockly.Gesture.prototype.setStartBubble=function(a){this.startBubble_||(this.startBubble_=a)};Blockly.Gesture.prototype.setStartBlock=function(a){this.startBlock_||this.startBubble_||(this.startBlock_=a,this.shouldDuplicateOnDrag_=Blockly.scratchBlocksUtils.isShadowArgumentReporter(a),a.isInFlyout&&a!=a.getRootBlock()?this.setTargetBlock_(a.getRootBlock()):this.setTargetBlock_(a))};
Blockly.Gesture.prototype.setTargetBlock_=function(a){a.isShadow()&&!this.shouldDuplicateOnDrag_?this.setTargetBlock_(a.getParent()):this.targetBlock_=a};Blockly.Gesture.prototype.setStartWorkspace_=function(a){this.startWorkspace_||(this.startWorkspace_=a)};Blockly.Gesture.prototype.setStartFlyout_=function(a){this.flyout_||(this.flyout_=a)};Blockly.Gesture.prototype.isBubbleClick_=function(){return!!this.startBubble_&&!this.hasExceededDragRadius_};
Blockly.Gesture.prototype.isBlockClick_=function(){return!!this.startBlock_&&!this.hasExceededDragRadius_&&!this.isFieldClick_()};Blockly.Gesture.prototype.isFieldClick_=function(){return(this.startField_?this.startField_.isCurrentlyEditable():!1)&&!this.hasExceededDragRadius_};Blockly.Gesture.prototype.isWorkspaceClick_=function(){return!this.startBlock_&&!this.startBubble_&&!this.startField_&&!this.hasExceededDragRadius_};
Blockly.Gesture.prototype.isDragging=function(){return this.isDraggingWorkspace_||this.isDraggingBlock_||this.isDraggingBubble_};Blockly.Gesture.prototype.hasStarted=function(){return this.hasStarted_};Blockly.Gesture.prototype.forceStartBlockDrag=function(a,b){this.handleBlockStart(a,b);this.handleWsStart(a,b.workspace);this.hasExceededDragRadius_=this.isDraggingBlock_=!0;this.startDraggingBlock_()};
Blockly.Gesture.prototype.duplicateOnDrag_=function(){var a=null;Blockly.Events.disable();try{this.startWorkspace_.setResizesEnabled(!1);var b=Blockly.Xml.blockToDom(this.targetBlock_);a=Blockly.Xml.domToBlock(b,this.startWorkspace_);var c=this.targetBlock_.getRelativeToSurfaceXY();a.moveBy(c.x,c.y);a.setShadow(!1)}finally{Blockly.Events.enable()}a?(Blockly.Events.isEnabled()&&Blockly.Events.fire(new Blockly.Events.BlockCreate(a)),a.select(),this.targetBlock_=a):console.error("Something went wrong while duplicating a block.")};Blockly.utils={};Blockly.utils.getMessageArray_=function(){return goog.global.Blockly.Msg};Blockly.utils.removeAttribute=function(a,b){goog.userAgent.IE&&goog.userAgent.isVersion("10.0")?a.setAttribute(b,null):a.removeAttribute(b)};Blockly.utils.addClass=function(a,b){var c=a.getAttribute("class")||"";if(-1!=(" "+c+" ").indexOf(" "+b+" "))return!1;c&&(c+=" ");a.setAttribute("class",c+b);return!0};
Blockly.utils.removeClass=function(a,b){var c=a.getAttribute("class");if(-1==(" "+c+" ").indexOf(" "+b+" "))return!1;c=c.split(/\s+/);for(var d=0;d<c.length;d++)c[d]&&c[d]!=b||(c.splice(d,1),d--);c.length?a.setAttribute("class",c.join(" ")):Blockly.utils.removeAttribute(a,"class");return!0};Blockly.utils.hasClass=function(a,b){return-1!=(" "+a.getAttribute("class")+" ").indexOf(" "+b+" ")};Blockly.utils.noEvent=function(a){a.preventDefault();a.stopPropagation()};
Blockly.utils.isTargetInput=function(a){return"textarea"==a.target.type||"text"==a.target.type||"number"==a.target.type||"email"==a.target.type||"password"==a.target.type||"search"==a.target.type||"tel"==a.target.type||"url"==a.target.type||a.target.isContentEditable};
Blockly.utils.getRelativeXY=function(a){var b=new goog.math.Coordinate(0,0),c=a.getAttribute("x");c&&(b.x=parseInt(c,10));if(c=a.getAttribute("y"))b.y=parseInt(c,10);if(c=(c=a.getAttribute("transform"))&&c.match(Blockly.utils.getRelativeXY.XY_REGEX_))b.x+=parseFloat(c[1]),c[3]&&(b.y+=parseFloat(c[3]));(a=a.getAttribute("style"))&&-1<a.indexOf("translate")&&(a=a.match(Blockly.utils.getRelativeXY.XY_STYLE_REGEX_))&&(b.x+=parseFloat(a[1]),a[3]&&(b.y+=parseFloat(a[3])));return b};
Blockly.utils.getInjectionDivXY_=function(a){for(var b=0,c=0;a;){var d=Blockly.utils.getRelativeXY(a),e=Blockly.utils.getScale_(a);b=b*e+d.x;c=c*e+d.y;if(-1!=(" "+(a.getAttribute("class")||"")+" ").indexOf(" injectionDiv "))break;a=a.parentNode}return new goog.math.Coordinate(b,c)};Blockly.utils.getScale_=function(a){var b=1;(a=a.getAttribute("transform"))&&(a=a.match(Blockly.utils.getScale_.REGEXP_))&&a[0]&&(b=parseFloat(a[0]));return b};Blockly.utils.getRelativeXY.XY_REGEX_=/translate\(\s*([-+\d.e]+)([ ,]\s*([-+\d.e]+)\s*)?/;
Blockly.utils.getScale_REGEXP_=/scale\(\s*([-+\d.e]+)\s*\)/;Blockly.utils.getRelativeXY.XY_STYLE_REGEX_=/transform:\s*translate(?:3d)?\(\s*([-+\d.e]+)\s*px([ ,]\s*([-+\d.e]+)\s*px)?/;Blockly.utils.createSvgElement=function(a,b,c){a=document.createElementNS(Blockly.SVG_NS,a);for(var d in b)a.setAttribute(d,b[d]);document.body.runtimeStyle&&(a.runtimeStyle=a.currentStyle=a.style);c&&c.appendChild(a);return a};Blockly.utils.isRightButton=function(a){return a.ctrlKey&&goog.userAgent.MAC?!0:2==a.button};
Blockly.utils.mouseToSvg=function(a,b,c){var d=b.createSVGPoint();d.x=a.clientX;d.y=a.clientY;c||(c=b.getScreenCTM().inverse());return d.matrixTransform(c)};Blockly.utils.shortestStringLength=function(a){return a.length?a.reduce(function(a,c){return a.length<c.length?a:c}).length:0};
Blockly.utils.commonWordPrefix=function(a,b){if(!a.length)return 0;if(1==a.length)return a[0].length;var c=0;b=b||Blockly.utils.shortestStringLength(a);for(var d=0;d<b;d++){for(var e=a[0][d],f=1;f<a.length;f++)if(e!=a[f][d])return c;" "==e&&(c=d+1)}for(f=1;f<a.length;f++)if((e=a[f][d])&&" "!=e)return c;return b};
Blockly.utils.commonWordSuffix=function(a,b){if(!a.length)return 0;if(1==a.length)return a[0].length;var c=0;b=b||Blockly.utils.shortestStringLength(a);for(var d=0;d<b;d++){for(var e=a[0].substr(-d-1,1),f=1;f<a.length;f++)if(e!=a[f].substr(-d-1,1))return c;" "==e&&(c=d+1)}for(f=1;f<a.length;f++)if((e=a[f].charAt(a[f].length-d-1))&&" "!=e)return c;return b};Blockly.utils.tokenizeInterpolation=function(a){return Blockly.utils.tokenizeInterpolation_(a,!0)};
Blockly.utils.replaceMessageReferences=function(a){if(!goog.isString(a))return a;a=Blockly.utils.tokenizeInterpolation_(a,!1);return a.length?a[0]:""};Blockly.utils.checkMessageReferences=function(a){for(var b=!0,c=/%{BKY_([a-zA-Z][a-zA-Z0-9_]*)}/g,d=c.exec(a);d;){var e=d[1];void 0==Blockly.utils.getMessageArray_()[e]&&(console.log("WARNING: No message string for %{BKY_"+e+"}."),b=!1);a=a.substring(d.index+e.length+1);d=c.exec(a)}return b};
Blockly.utils.tokenizeInterpolation_=function(a,b){var c=[],d=a.split("");d.push("");var e=0;a=[];for(var f=null,g=0;g<d.length;g++){var h=d[g];0==e?"%"==h?((h=a.join(""))&&c.push(h),a.length=0,e=1):a.push(h):1==e?"%"==h?(a.push(h),e=0):b&&"0"<=h&&"9">=h?(e=2,f=h,(h=a.join(""))&&c.push(h),a.length=0):"{"==h?e=3:(a.push("%",h),e=0):2==e?"0"<=h&&"9">=h?f+=h:(c.push(parseInt(f,10)),g--,e=0):3==e&&(""==h?(a.splice(0,0,"%{"),g--,e=0):"}"!=h?a.push(h):(e=a.join(""),/[a-zA-Z][a-zA-Z0-9_]*/.test(e)?(h=e.toUpperCase(),
(h=goog.string.startsWith(h,"BKY_")?h.substring(4):null)&&h in Blockly.Msg?(e=Blockly.Msg[h],goog.isString(e)?Array.prototype.push.apply(c,Blockly.utils.tokenizeInterpolation(e)):b?c.push(String(e)):c.push(e)):c.push("%{"+e+"}")):c.push("%{"+e+"}"),e=a.length=0))}(h=a.join(""))&&c.push(h);b=[];for(g=a.length=0;g<c.length;++g)"string"==typeof c[g]?a.push(c[g]):((h=a.join(""))&&b.push(h),a.length=0,b.push(c[g]));(h=a.join(""))&&b.push(h);a.length=0;return b};
Blockly.utils.genUid=function(){for(var a=Blockly.utils.genUid.soup_.length,b=[],c=0;20>c;c++)b[c]=Blockly.utils.genUid.soup_.charAt(Math.random()*a);return b.join("")};Blockly.utils.genUid.soup_="!#$%()*+,-./:;=?@[]^_`{|}~ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";Blockly.utils.wrap=function(a,b){a=a.split("\n");for(var c=0;c<a.length;c++)a[c]=Blockly.utils.wrapLine_(a[c],b);return a.join("\n")};
Blockly.utils.wrapLine_=function(a,b){if(a.length<=b)return a;for(var c=a.trim().split(/\s+/),d=0;d<c.length;d++)c[d].length>b&&(b=c[d].length);d=-Infinity;var e=1;do{var f=d;var g=a;a=[];var h=c.length/e,k=1;for(d=0;d<c.length-1;d++)k<(d+1.5)/h?(k++,a[d]=!0):a[d]=!1;a=Blockly.utils.wrapMutate_(c,a,b);d=Blockly.utils.wrapScore_(c,a,b);a=Blockly.utils.wrapToText_(c,a);e++}while(d>f);return g};
Blockly.utils.wrapScore_=function(a,b,c){for(var d=[0],e=[],f=0;f<a.length;f++)d[d.length-1]+=a[f].length,!0===b[f]?(d.push(0),e.push(a[f].charAt(a[f].length-1))):!1===b[f]&&d[d.length-1]++;a=Math.max.apply(Math,d);for(f=b=0;f<d.length;f++)b-=2*Math.pow(Math.abs(c-d[f]),1.5),b-=Math.pow(a-d[f],1.5),-1!=".?!".indexOf(e[f])?b+=c/3:-1!=",;)]}".indexOf(e[f])&&(b+=c/4);1<d.length&&d[d.length-1]<=d[d.length-2]&&(b+=.5);return b};
Blockly.utils.wrapMutate_=function(a,b,c){for(var d=Blockly.utils.wrapScore_(a,b,c),e,f=0;f<b.length-1;f++)if(b[f]!=b[f+1]){var g=[].concat(b);g[f]=!g[f];g[f+1]=!g[f+1];var h=Blockly.utils.wrapScore_(a,g,c);h>d&&(d=h,e=g)}return e?Blockly.utils.wrapMutate_(a,e,c):b};Blockly.utils.wrapToText_=function(a,b){for(var c=[],d=0;d<a.length;d++)c.push(a[d]),void 0!==b[d]&&c.push(b[d]?"\n":" ");return c.join("")};
Blockly.utils.is3dSupported=function(){if(void 0!==Blockly.utils.is3dSupported.cached_)return Blockly.utils.is3dSupported.cached_;if(!goog.global.getComputedStyle)return!1;var a=document.createElement("p"),b="none",c={webkitTransform:"-webkit-transform",OTransform:"-o-transform",msTransform:"-ms-transform",MozTransform:"-moz-transform",transform:"transform"};document.body.insertBefore(a,null);for(var d in c)if(void 0!==a.style[d]){a.style[d]="translate3d(1px,1px,1px)";b=goog.global.getComputedStyle(a);
if(!b)return document.body.removeChild(a),!1;b=b.getPropertyValue(c[d])}document.body.removeChild(a);Blockly.utils.is3dSupported.cached_="none"!==b;return Blockly.utils.is3dSupported.cached_};Blockly.utils.insertAfter=function(a,b){var c=b.nextSibling;b=b.parentNode;if(!b)throw"Reference node has no parent.";c?b.insertBefore(a,c):b.appendChild(a)};
Blockly.utils.runAfterPageLoad=function(a){if(!document)throw Error("Blockly.utils.runAfterPageLoad() requires browser document.");if("complete"===document.readyState)a();else var b=setInterval(function(){"complete"===document.readyState&&(clearInterval(b),a())},10)};Blockly.utils.setCssTransform=function(a,b){a.style.transform=b;a.style["-webkit-transform"]=b};
Blockly.utils.getViewportBBox=function(){var a=goog.dom.getViewportSize(),b=goog.style.getViewportPageOffset(document);return{right:a.width+b.x,bottom:a.height+b.y,top:b.y,left:b.x}};Blockly.utils.startsWith=function(a,b){return 0==a.lastIndexOf(b,0)};Blockly.utils.toRadians=function(a){return a*Math.PI/180};Blockly.Grid=function(a,b){this.gridPattern_=a;this.spacing_=b.spacing;this.length_=b.length;this.line2_=(this.line1_=a.firstChild)&&this.line1_.nextSibling;this.snapToGrid_=b.snap};Blockly.Grid.prototype.scale_=1;Blockly.Grid.prototype.dispose=function(){this.gridPattern_=null};Blockly.Grid.prototype.shouldSnap=function(){return this.snapToGrid_};Blockly.Grid.prototype.getSpacing=function(){return this.spacing_};Blockly.Grid.prototype.getPatternId=function(){return this.gridPattern_.id};
Blockly.Grid.prototype.update=function(a){this.scale_=a;var b=this.spacing_*a||100;this.gridPattern_.setAttribute("width",b);this.gridPattern_.setAttribute("height",b);b=Math.floor(this.spacing_/2)+.5;var c=b-this.length_/2,d=b+this.length_/2;b*=a;c*=a;d*=a;this.setLineAttributes_(this.line1_,a,c,d,b,b);this.setLineAttributes_(this.line2_,a,b,b,c,d)};
Blockly.Grid.prototype.setLineAttributes_=function(a,b,c,d,e,f){a&&(a.setAttribute("stroke-width",b),a.setAttribute("x1",c),a.setAttribute("y1",e),a.setAttribute("x2",d),a.setAttribute("y2",f))};Blockly.Grid.prototype.moveTo=function(a,b){this.gridPattern_.setAttribute("x",a);this.gridPattern_.setAttribute("y",b);(goog.userAgent.IE||goog.userAgent.EDGE)&&this.update(this.scale_)};
Blockly.Grid.createDom=function(a,b,c){a=Blockly.utils.createSvgElement("pattern",{id:"blocklyGridPattern"+a,patternUnits:"userSpaceOnUse"},c);0<b.length&&0<b.spacing?(Blockly.utils.createSvgElement("line",{stroke:b.colour},a),1<b.length&&Blockly.utils.createSvgElement("line",{stroke:b.colour},a)):Blockly.utils.createSvgElement("line",{},a);return a};Blockly.Options=function(a){var b=!!a.readOnly;if(b)var c=null,d=!1,e=!1,f=!1,g=!1,h=!1,k=!1;else!a.toolbox&&Blockly.Blocks.defaultToolbox&&(c=(new DOMParser).parseFromString(Blockly.Blocks.defaultToolbox,"text/xml"),a.toolbox=c.documentElement),c=Blockly.Options.parseToolboxTree(a.toolbox),d=!(!c||!c.getElementsByTagName("category").length),e=a.trashcan,void 0===e&&(e=!1),f=a.collapse,void 0===f&&(f=d),g=a.comments,void 0===g&&(g=d),h=a.disable,void 0===h&&(h=d),k=a.sounds,void 0===k&&(k=!0);var l=
!!a.rtl,m=a.horizontalLayout;void 0===m&&(m=!1);var n=a.toolboxPosition;n="end"===n?!1:!0;n=m?n?Blockly.TOOLBOX_AT_TOP:Blockly.TOOLBOX_AT_BOTTOM:n==l?Blockly.TOOLBOX_AT_RIGHT:Blockly.TOOLBOX_AT_LEFT;var p=a.scrollbars;void 0===p&&(p=d);var q=a.css;void 0===q&&(q=!0);var r="https://blockly-demo.appspot.com/static/media/";a.media?r=a.media:a.path&&(r=a.path+"media/");var t=void 0===a.oneBasedIndex?!0:!!a.oneBasedIndex;Blockly.Colours.overrideColours(a.colours);this.RTL=l;this.oneBasedIndex=t;this.collapse=
f;this.comments=g;this.disable=h;this.readOnly=b;this.pathToMedia=r;this.hasCategories=d;this.hasScrollbars=p;this.hasTrashcan=e;this.hasSounds=k;this.hasCss=q;this.horizontalLayout=m;this.languageTree=c;this.gridOptions=Blockly.Options.parseGridOptions_(a);this.zoomOptions=Blockly.Options.parseZoomOptions_(a);this.toolboxPosition=n};Blockly.Options.prototype.parentWorkspace=null;Blockly.Options.prototype.setMetrics=null;Blockly.Options.prototype.getMetrics=null;
Blockly.Options.parseZoomOptions_=function(a){a=a.zoom||{};var b={};b.controls=void 0===a.controls?!1:!!a.controls;b.wheel=void 0===a.wheel?!1:!!a.wheel;b.startScale=void 0===a.startScale?1.2:parseFloat(a.startScale);b.maxScale=void 0===a.maxScale?3:parseFloat(a.maxScale);b.minScale=void 0===a.minScale?.3:parseFloat(a.minScale);b.scaleSpeed=void 0===a.scaleSpeed?1.2:parseFloat(a.scaleSpeed);return b};
Blockly.Options.parseGridOptions_=function(a){a=a.grid||{};var b={};b.spacing=parseFloat(a.spacing)||0;b.colour=a.colour||"#888";b.length=parseFloat(a.length)||1;b.snap=0<b.spacing&&!!a.snap;return b};Blockly.Options.parseToolboxTree=function(a){a?("string"!=typeof a&&("undefined"==typeof XSLTProcessor&&a.outerHTML?a=a.outerHTML:a instanceof Element||(a=null)),"string"==typeof a&&(a=Blockly.Xml.textToDom(a))):a=null;return a};Blockly.ScrollbarPair=function(a){this.workspace_=a;this.hScroll=new Blockly.Scrollbar(a,!0,!0,"blocklyMainWorkspaceScrollbar");this.vScroll=new Blockly.Scrollbar(a,!1,!0,"blocklyMainWorkspaceScrollbar");this.corner_=Blockly.utils.createSvgElement("rect",{height:Blockly.Scrollbar.scrollbarThickness,width:Blockly.Scrollbar.scrollbarThickness,"class":"blocklyScrollbarBackground"},null);Blockly.utils.insertAfter(this.corner_,a.getBubbleCanvas())};Blockly.ScrollbarPair.prototype.oldHostMetrics_=null;
Blockly.ScrollbarPair.prototype.dispose=function(){goog.dom.removeNode(this.corner_);this.oldHostMetrics_=this.workspace_=this.corner_=null;this.hScroll.dispose();this.hScroll=null;this.vScroll.dispose();this.vScroll=null};
Blockly.ScrollbarPair.prototype.resize=function(){var a=this.workspace_.getMetrics();if(a){var b=!1,c=!1;this.oldHostMetrics_&&this.oldHostMetrics_.viewWidth==a.viewWidth&&this.oldHostMetrics_.viewHeight==a.viewHeight&&this.oldHostMetrics_.absoluteTop==a.absoluteTop&&this.oldHostMetrics_.absoluteLeft==a.absoluteLeft?(this.oldHostMetrics_&&this.oldHostMetrics_.contentWidth==a.contentWidth&&this.oldHostMetrics_.viewLeft==a.viewLeft&&this.oldHostMetrics_.contentLeft==a.contentLeft||(b=!0),this.oldHostMetrics_&&
this.oldHostMetrics_.contentHeight==a.contentHeight&&this.oldHostMetrics_.viewTop==a.viewTop&&this.oldHostMetrics_.contentTop==a.contentTop||(c=!0)):c=b=!0;b&&this.hScroll.resize(a);c&&this.vScroll.resize(a);this.oldHostMetrics_&&this.oldHostMetrics_.viewWidth==a.viewWidth&&this.oldHostMetrics_.absoluteLeft==a.absoluteLeft||this.corner_.setAttribute("x",this.vScroll.position_.x);this.oldHostMetrics_&&this.oldHostMetrics_.viewHeight==a.viewHeight&&this.oldHostMetrics_.absoluteTop==a.absoluteTop||this.corner_.setAttribute("y",
this.hScroll.position_.y);this.oldHostMetrics_=a}};Blockly.ScrollbarPair.prototype.set=function(a,b){var c={};a*=this.hScroll.ratio_;b*=this.vScroll.ratio_;var d=this.vScroll.scrollViewSize_;c.x=this.getRatio_(a,this.hScroll.scrollViewSize_);c.y=this.getRatio_(b,d);this.workspace_.setMetrics(c);this.hScroll.setHandlePosition(a);this.vScroll.setHandlePosition(b)};Blockly.ScrollbarPair.prototype.getRatio_=function(a,b){a/=b;return isNaN(a)?0:a};
Blockly.Scrollbar=function(a,b,c,d){this.workspace_=a;this.pair_=c||!1;this.horizontal_=b;this.oldHostMetrics_=null;this.createDom_(d);this.position_=new goog.math.Coordinate(0,0);a=Blockly.Scrollbar.scrollbarThickness;b?(this.svgBackground_.setAttribute("height",a),this.outerSvg_.setAttribute("height",a),this.svgHandle_.setAttribute("height",a-5),this.svgHandle_.setAttribute("y",2.5),this.lengthAttribute_="width",this.positionAttribute_="x"):(this.svgBackground_.setAttribute("width",a),this.outerSvg_.setAttribute("width",
a),this.svgHandle_.setAttribute("width",a-5),this.svgHandle_.setAttribute("x",2.5),this.lengthAttribute_="height",this.positionAttribute_="y");this.onMouseDownBarWrapper_=Blockly.bindEventWithChecks_(this.svgBackground_,"mousedown",this,this.onMouseDownBar_);this.onMouseDownHandleWrapper_=Blockly.bindEventWithChecks_(this.svgHandle_,"mousedown",this,this.onMouseDownHandle_)};Blockly.Scrollbar.prototype.origin_=new goog.math.Coordinate(0,0);Blockly.Scrollbar.prototype.originHasChanged_=!0;
Blockly.Scrollbar.prototype.scrollViewSize_=0;Blockly.Scrollbar.prototype.handleLength_=0;Blockly.Scrollbar.prototype.handlePosition_=0;Blockly.Scrollbar.prototype.isVisible_=!0;Blockly.Scrollbar.prototype.containerVisible_=!0;Blockly.Scrollbar.scrollbarThickness=11;goog.events.BrowserFeature.TOUCH_ENABLED&&(Blockly.Scrollbar.scrollbarThickness=14);
Blockly.Scrollbar.metricsAreEquivalent_=function(a,b){return a&&b&&a.viewWidth==b.viewWidth&&a.viewHeight==b.viewHeight&&a.viewLeft==b.viewLeft&&a.viewTop==b.viewTop&&a.absoluteTop==b.absoluteTop&&a.absoluteLeft==b.absoluteLeft&&a.contentWidth==b.contentWidth&&a.contentHeight==b.contentHeight&&a.contentLeft==b.contentLeft&&a.contentTop==b.contentTop?!0:!1};
Blockly.Scrollbar.prototype.dispose=function(){this.cleanUp_();Blockly.unbindEvent_(this.onMouseDownBarWrapper_);this.onMouseDownBarWrapper_=null;Blockly.unbindEvent_(this.onMouseDownHandleWrapper_);this.onMouseDownHandleWrapper_=null;goog.dom.removeNode(this.outerSvg_);this.workspace_=this.svgHandle_=this.svgBackground_=this.svgGroup_=this.outerSvg_=null};Blockly.Scrollbar.prototype.setHandleLength_=function(a){this.handleLength_=a;this.svgHandle_.setAttribute(this.lengthAttribute_,this.handleLength_)};
Blockly.Scrollbar.prototype.setHandlePosition=function(a){this.handlePosition_=a;this.svgHandle_.setAttribute(this.positionAttribute_,this.handlePosition_)};Blockly.Scrollbar.prototype.setScrollViewSize_=function(a){this.scrollViewSize_=a;this.outerSvg_.setAttribute(this.lengthAttribute_,this.scrollViewSize_);this.svgBackground_.setAttribute(this.lengthAttribute_,this.scrollViewSize_)};Blockly.ScrollbarPair.prototype.setContainerVisible=function(a){this.hScroll.setContainerVisible(a);this.vScroll.setContainerVisible(a)};
Blockly.Scrollbar.prototype.setPosition_=function(a,b){this.position_.x=a;this.position_.y=b;Blockly.utils.setCssTransform(this.outerSvg_,"translate("+(this.position_.x+this.origin_.x)+"px,"+(this.position_.y+this.origin_.y)+"px)")};
Blockly.Scrollbar.prototype.resize=function(a){if(!a&&(a=this.workspace_.getMetrics(),!a))return;if(this.originHasChanged_)this.originHasChanged_=!1;else if(Blockly.Scrollbar.metricsAreEquivalent_(a,this.oldHostMetrics_))return;this.oldHostMetrics_=a;this.horizontal_?this.resizeHorizontal_(a):this.resizeVertical_(a);this.onScroll_()};Blockly.Scrollbar.prototype.resizeHorizontal_=function(a){this.resizeViewHorizontal(a)};
Blockly.Scrollbar.prototype.resizeViewHorizontal=function(a){var b=a.viewWidth-1;this.pair_&&(b-=Blockly.Scrollbar.scrollbarThickness);this.setScrollViewSize_(Math.max(0,b));b=a.absoluteLeft+.5;this.pair_&&this.workspace_.RTL&&(b+=Blockly.Scrollbar.scrollbarThickness);this.setPosition_(b,a.absoluteTop+a.viewHeight-Blockly.Scrollbar.scrollbarThickness-.5);this.resizeContentHorizontal(a)};
Blockly.Scrollbar.prototype.resizeContentHorizontal=function(a){this.pair_||this.setVisible(this.scrollViewSize_<a.contentWidth);this.ratio_=this.scrollViewSize_/a.contentWidth;if(-Infinity==this.ratio_||Infinity==this.ratio_||isNaN(this.ratio_))this.ratio_=0;this.setHandleLength_(Math.max(0,a.viewWidth*this.ratio_));this.setHandlePosition(this.constrainHandle_((a.viewLeft-a.contentLeft)*this.ratio_))};Blockly.Scrollbar.prototype.resizeVertical_=function(a){this.resizeViewVertical(a)};
Blockly.Scrollbar.prototype.resizeViewVertical=function(a){var b=a.viewHeight-1;this.pair_&&(b-=Blockly.Scrollbar.scrollbarThickness);this.setScrollViewSize_(Math.max(0,b));b=a.absoluteLeft+.5;this.workspace_.RTL||(b+=a.viewWidth-Blockly.Scrollbar.scrollbarThickness-1);this.setPosition_(b,a.absoluteTop+.5);this.resizeContentVertical(a)};
Blockly.Scrollbar.prototype.resizeContentVertical=function(a){this.pair_||this.setVisible(this.scrollViewSize_<a.contentHeight);this.ratio_=this.scrollViewSize_/a.contentHeight;if(-Infinity==this.ratio_||Infinity==this.ratio_||isNaN(this.ratio_))this.ratio_=0;this.setHandleLength_(Math.max(0,a.viewHeight*this.ratio_));this.setHandlePosition(this.constrainHandle_((a.viewTop-a.contentTop)*this.ratio_))};
Blockly.Scrollbar.prototype.createDom_=function(a){var b="blocklyScrollbar"+(this.horizontal_?"Horizontal":"Vertical");a&&(b+=" "+a);this.outerSvg_=Blockly.utils.createSvgElement("svg",{"class":b},null);this.svgGroup_=Blockly.utils.createSvgElement("g",{},this.outerSvg_);this.svgBackground_=Blockly.utils.createSvgElement("rect",{"class":"blocklyScrollbarBackground"},this.svgGroup_);a=Math.floor((Blockly.Scrollbar.scrollbarThickness-5)/2);this.svgHandle_=Blockly.utils.createSvgElement("rect",{"class":"blocklyScrollbarHandle",
rx:a,ry:a},this.svgGroup_);Blockly.utils.insertAfter(this.outerSvg_,this.workspace_.getParentSvg())};Blockly.Scrollbar.prototype.isVisible=function(){return this.isVisible_};Blockly.Scrollbar.prototype.setContainerVisible=function(a){var b=a!=this.containerVisible_;this.containerVisible_=a;b&&this.updateDisplay_()};Blockly.Scrollbar.prototype.setVisible=function(a){var b=a!=this.isVisible();if(this.pair_)throw"Unable to toggle visibility of paired scrollbars.";this.isVisible_=a;b&&this.updateDisplay_()};
Blockly.Scrollbar.prototype.updateDisplay_=function(){this.containerVisible_&&this.isVisible()?this.outerSvg_.setAttribute("display","block"):this.outerSvg_.setAttribute("display","none")};
Blockly.Scrollbar.prototype.onMouseDownBar_=function(a){this.workspace_.markFocused();Blockly.Touch.clearTouchIdentifier();this.cleanUp_();if(Blockly.utils.isRightButton(a))a.stopPropagation();else{var b=Blockly.utils.mouseToSvg(a,this.workspace_.getParentSvg(),this.workspace_.getInverseScreenCTM());b=this.horizontal_?b.x:b.y;var c=Blockly.utils.getInjectionDivXY_(this.svgHandle_);c=this.horizontal_?c.x:c.y;var d=this.handlePosition_,e=.95*this.handleLength_;b<=c?d-=e:b>=c+this.handleLength_&&(d+=
e);Blockly.WidgetDiv.hide(!0);Blockly.DropDownDiv.hideWithoutAnimation();this.setHandlePosition(this.constrainHandle_(d));this.onScroll_();a.stopPropagation();a.preventDefault()}};
Blockly.Scrollbar.prototype.onMouseDownHandle_=function(a){this.workspace_.markFocused();this.cleanUp_();Blockly.utils.isRightButton(a)?a.stopPropagation():(this.startDragHandle=this.handlePosition_,this.workspace_.setupDragSurface(),this.startDragMouse_=this.horizontal_?a.clientX:a.clientY,Blockly.Scrollbar.onMouseUpWrapper_=Blockly.bindEventWithChecks_(document,"mouseup",this,this.onMouseUpHandle_),Blockly.Scrollbar.onMouseMoveWrapper_=Blockly.bindEventWithChecks_(document,"mousemove",this,this.onMouseMoveHandle_),
Blockly.WidgetDiv.hide(!0),Blockly.DropDownDiv.hideWithoutAnimation(),a.stopPropagation(),a.preventDefault())};Blockly.Scrollbar.prototype.onMouseMoveHandle_=function(a){this.setHandlePosition(this.constrainHandle_(this.startDragHandle+((this.horizontal_?a.clientX:a.clientY)-this.startDragMouse_)));this.onScroll_()};Blockly.Scrollbar.prototype.onMouseUpHandle_=function(){this.workspace_.resetDragSurface();Blockly.Touch.clearTouchIdentifier();this.cleanUp_()};
Blockly.Scrollbar.prototype.cleanUp_=function(){Blockly.hideChaff(!0);Blockly.Scrollbar.onMouseUpWrapper_&&(Blockly.unbindEvent_(Blockly.Scrollbar.onMouseUpWrapper_),Blockly.Scrollbar.onMouseUpWrapper_=null);Blockly.Scrollbar.onMouseMoveWrapper_&&(Blockly.unbindEvent_(Blockly.Scrollbar.onMouseMoveWrapper_),Blockly.Scrollbar.onMouseMoveWrapper_=null)};
Blockly.Scrollbar.prototype.constrainHandle_=function(a){return a=0>=a||isNaN(a)||this.scrollViewSize_<this.handleLength_?0:Math.min(a,this.scrollViewSize_-this.handleLength_)};Blockly.Scrollbar.prototype.onScroll_=function(){var a=this.handlePosition_/this.scrollViewSize_;isNaN(a)&&(a=0);var b={};this.horizontal_?b.x=a:b.y=a;this.workspace_.setMetrics(b)};Blockly.Scrollbar.prototype.set=function(a){this.setHandlePosition(this.constrainHandle_(a*this.ratio_));this.onScroll_()};
Blockly.Scrollbar.prototype.setOrigin=function(a,b){if(a!=this.origin_.x||b!=this.origin_.y)this.origin_=new goog.math.Coordinate(a,b),this.originHasChanged_=!0};Blockly.Trashcan=function(a){this.workspace_=a};Blockly.Trashcan.prototype.WIDTH_=47;Blockly.Trashcan.prototype.BODY_HEIGHT_=44;Blockly.Trashcan.prototype.LID_HEIGHT_=16;Blockly.Trashcan.prototype.MARGIN_BOTTOM_=20;Blockly.Trashcan.prototype.MARGIN_SIDE_=20;Blockly.Trashcan.prototype.MARGIN_HOTSPOT_=10;Blockly.Trashcan.prototype.SPRITE_LEFT_=0;Blockly.Trashcan.prototype.SPRITE_TOP_=32;Blockly.Trashcan.prototype.isOpen=!1;Blockly.Trashcan.prototype.svgGroup_=null;
Blockly.Trashcan.prototype.svgLid_=null;Blockly.Trashcan.prototype.lidTask_=0;Blockly.Trashcan.prototype.lidOpen_=0;Blockly.Trashcan.prototype.left_=0;Blockly.Trashcan.prototype.top_=0;
Blockly.Trashcan.prototype.createDom=function(){this.svgGroup_=Blockly.utils.createSvgElement("g",{"class":"blocklyTrash"},null);var a=String(Math.random()).substring(2);var b=Blockly.utils.createSvgElement("clipPath",{id:"blocklyTrashBodyClipPath"+a},this.svgGroup_);Blockly.utils.createSvgElement("rect",{width:this.WIDTH_,height:this.BODY_HEIGHT_,y:this.LID_HEIGHT_},b);Blockly.utils.createSvgElement("image",{width:Blockly.SPRITE.width,x:-this.SPRITE_LEFT_,height:Blockly.SPRITE.height,y:-this.SPRITE_TOP_,
"clip-path":"url(#blocklyTrashBodyClipPath"+a+")"},this.svgGroup_).setAttributeNS("http://www.w3.org/1999/xlink","xlink:href",this.workspace_.options.pathToMedia+Blockly.SPRITE.url);b=Blockly.utils.createSvgElement("clipPath",{id:"blocklyTrashLidClipPath"+a},this.svgGroup_);Blockly.utils.createSvgElement("rect",{width:this.WIDTH_,height:this.LID_HEIGHT_},b);this.svgLid_=Blockly.utils.createSvgElement("image",{width:Blockly.SPRITE.width,x:-this.SPRITE_LEFT_,height:Blockly.SPRITE.height,y:-this.SPRITE_TOP_,
"clip-path":"url(#blocklyTrashLidClipPath"+a+")"},this.svgGroup_);this.svgLid_.setAttributeNS("http://www.w3.org/1999/xlink","xlink:href",this.workspace_.options.pathToMedia+Blockly.SPRITE.url);Blockly.bindEventWithChecks_(this.svgGroup_,"mouseup",this,this.click);this.animateLid_();return this.svgGroup_};Blockly.Trashcan.prototype.init=function(a){this.bottom_=this.MARGIN_BOTTOM_+a;this.setOpen_(!1);return this.bottom_+this.BODY_HEIGHT_+this.LID_HEIGHT_};
Blockly.Trashcan.prototype.dispose=function(){this.svgGroup_&&(goog.dom.removeNode(this.svgGroup_),this.svgGroup_=null);this.workspace_=this.svgLid_=null;clearTimeout(this.lidTask_)};
Blockly.Trashcan.prototype.position=function(){var a=this.workspace_.getMetrics();a&&(this.workspace_.RTL?(this.left_=this.MARGIN_SIDE_+Blockly.Scrollbar.scrollbarThickness,a.toolboxPosition==Blockly.TOOLBOX_AT_LEFT&&(this.left_+=a.flyoutWidth,this.workspace_.toolbox_&&(this.left_+=a.absoluteLeft))):(this.left_=a.viewWidth+a.absoluteLeft-this.WIDTH_-this.MARGIN_SIDE_-Blockly.Scrollbar.scrollbarThickness,a.toolboxPosition==Blockly.TOOLBOX_AT_RIGHT&&(this.left_-=a.flyoutWidth)),this.top_=a.viewHeight+
a.absoluteTop-(this.BODY_HEIGHT_+this.LID_HEIGHT_)-this.bottom_,a.toolboxPosition==Blockly.TOOLBOX_AT_BOTTOM&&(this.top_-=a.flyoutHeight),this.svgGroup_.setAttribute("transform","translate("+this.left_+","+this.top_+")"))};
Blockly.Trashcan.prototype.getClientRect=function(){if(!this.svgGroup_)return null;var a=this.svgGroup_.getBoundingClientRect();return new goog.math.Rect(a.left+this.SPRITE_LEFT_-this.MARGIN_HOTSPOT_,a.top+this.SPRITE_TOP_-this.MARGIN_HOTSPOT_,this.WIDTH_+2*this.MARGIN_HOTSPOT_,this.LID_HEIGHT_+this.BODY_HEIGHT_+2*this.MARGIN_HOTSPOT_)};Blockly.Trashcan.prototype.setOpen_=function(a){this.isOpen!=a&&(clearTimeout(this.lidTask_),this.isOpen=a,this.animateLid_())};
Blockly.Trashcan.prototype.animateLid_=function(){this.lidOpen_+=this.isOpen?.2:-.2;this.lidOpen_=Math.min(Math.max(this.lidOpen_,0),1);var a=45*this.lidOpen_;this.svgLid_.setAttribute("transform","rotate("+(this.workspace_.RTL?-a:a)+","+(this.workspace_.RTL?4:this.WIDTH_-4)+","+(this.LID_HEIGHT_-2)+")");this.svgGroup_.style.opacity=.4+.4*this.lidOpen_;0<this.lidOpen_&&1>this.lidOpen_&&(this.lidTask_=setTimeout(this.animateLid_.bind(this),20))};Blockly.Trashcan.prototype.close=function(){this.setOpen_(!1)};
Blockly.Trashcan.prototype.click=function(){var a=this.workspace_.startScrollX-this.workspace_.scrollX,b=this.workspace_.startScrollY-this.workspace_.scrollY;Math.sqrt(a*a+b*b)>Blockly.DRAG_RADIUS||console.log("TODO: Inspect trash.")};Blockly.WorkspaceAudio=function(a){this.parentWorkspace_=a;this.SOUNDS_=Object.create(null)};Blockly.WorkspaceAudio.prototype.lastSound_=null;Blockly.WorkspaceAudio.prototype.dispose=function(){this.SOUNDS_=this.parentWorkspace_=null};Blockly.WorkspaceAudio.prototype.load=function(a,b){if(a.length){try{var c=new window.Audio}catch(h){return}for(var d,e=0;e<a.length;e++){var f=a[e],g=f.match(/\.(\w+)$/);if(g&&c.canPlayType("audio/"+g[1])){d=new window.Audio(f);break}}d&&d.play&&(this.SOUNDS_[b]=d)}};
Blockly.WorkspaceAudio.prototype.preload=function(){for(var a in this.SOUNDS_){var b=this.SOUNDS_[a];b.volume=.01;var c=b.play();c?c.then(b.pause).catch(function(){}):b.pause();if(goog.userAgent.IPAD||goog.userAgent.IPHONE)break}};
Blockly.WorkspaceAudio.prototype.play=function(a,b){var c=this.SOUNDS_[a];c?(a=new Date,null!=this.lastSound_&&a-this.lastSound_<Blockly.SOUND_LIMIT||(this.lastSound_=a,c=goog.userAgent.DOCUMENT_MODE&&9===goog.userAgent.DOCUMENT_MODE||goog.userAgent.IPAD||goog.userAgent.ANDROID?c:c.cloneNode(),c.volume=void 0===b?1:b,c.play())):this.parentWorkspace_&&this.parentWorkspace_.getAudioManager().play(a,b)};Blockly.WorkspaceCommentSvg.render={};Blockly.WorkspaceCommentSvg.BORDER_WIDTH=1;Blockly.WorkspaceCommentSvg.RESIZE_SIZE=16;Blockly.WorkspaceCommentSvg.TEXTAREA_OFFSET=12;Blockly.WorkspaceCommentSvg.TOP_BAR_HEIGHT=32;Blockly.WorkspaceCommentSvg.MINIMIZE_ICON_SIZE=32;Blockly.WorkspaceCommentSvg.DELETE_ICON_SIZE=32;Blockly.WorkspaceCommentSvg.TOP_BAR_ICON_INSET=0;Blockly.WorkspaceCommentSvg.RESIZE_CORNER_PAD=4;Blockly.WorkspaceCommentSvg.RESIZE_OUTER_PAD=8;
Blockly.WorkspaceCommentSvg.MINIMIZE_WIDTH=200;Blockly.WorkspaceCommentSvg.prototype.getHeightWidth=function(){return{width:this.getWidth(),height:this.getHeight()}};
Blockly.WorkspaceCommentSvg.prototype.render=function(){if(!this.rendered_){var a=this.getHeightWidth();this.commentEditor_=this.createEditor_();this.svgGroup_.appendChild(this.commentEditor_);this.createCommentTopBar_();this.svgRectTarget_=Blockly.utils.createSvgElement("rect",{"class":"blocklyDraggable scratchCommentTarget",x:0,y:Blockly.WorkspaceCommentSvg.TOP_BAR_HEIGHT,rx:4*Blockly.WorkspaceCommentSvg.BORDER_WIDTH,ry:4*Blockly.WorkspaceCommentSvg.BORDER_WIDTH});this.svgGroup_.appendChild(this.svgRectTarget_);
this.addResizeDom_();this.isMinimized()?(this.minimizeArrow_.setAttributeNS("http://www.w3.org/1999/xlink","xlink:href",Blockly.mainWorkspace.options.pathToMedia+"comment-arrow-up.svg"),this.commentEditor_.setAttribute("display","none"),this.resizeGroup_.setAttribute("display","none")):(this.minimizeArrow_.setAttributeNS("http://www.w3.org/1999/xlink","xlink:href",Blockly.mainWorkspace.options.pathToMedia+"comment-arrow-down.svg"),this.topBarLabel_.setAttribute("display","none"));this.setSize(a.width,
a.height);this.textarea_.value=this.content_;this.rendered_=!0;this.resizeGroup_&&(Blockly.bindEventWithChecks_(this.resizeGroup_,"mousedown",this,this.resizeMouseDown_),Blockly.bindEventWithChecks_(this.resizeGroup_,"mouseup",this,this.resizeMouseUp_));Blockly.bindEventWithChecks_(this.minimizeArrow_,"mousedown",this,this.minimizeArrowMouseDown_,!0);Blockly.bindEventWithChecks_(this.minimizeArrow_,"mouseout",this,this.minimizeArrowMouseOut_,!0);Blockly.bindEventWithChecks_(this.minimizeArrow_,"mouseup",
this,this.minimizeArrowMouseUp_,!0);Blockly.bindEventWithChecks_(this.deleteIcon_,"mousedown",this,this.deleteMouseDown_,!0);Blockly.bindEventWithChecks_(this.deleteIcon_,"mouseout",this,this.deleteMouseOut_,!0);Blockly.bindEventWithChecks_(this.deleteIcon_,"mouseup",this,this.deleteMouseUp_,!0)}};
Blockly.WorkspaceCommentSvg.prototype.createEditor_=function(){this.foreignObject_=Blockly.utils.createSvgElement("foreignObject",{x:Blockly.WorkspaceCommentSvg.BORDER_WIDTH,y:Blockly.WorkspaceCommentSvg.BORDER_WIDTH+Blockly.WorkspaceCommentSvg.TOP_BAR_HEIGHT,"class":"scratchCommentForeignObject"},null);var a=document.createElementNS(Blockly.HTML_NS,"body");a.setAttribute("xmlns",Blockly.HTML_NS);a.className="blocklyMinimalBody scratchCommentBody";var b=document.createElementNS(Blockly.HTML_NS,"textarea");
b.className="scratchCommentTextarea scratchCommentText";b.setAttribute("dir",this.RTL?"RTL":"LTR");b.setAttribute("maxlength",Blockly.WorkspaceComment.COMMENT_TEXT_LIMIT);b.setAttribute("placeholder",Blockly.Msg.WORKSPACE_COMMENT_DEFAULT_TEXT);a.appendChild(b);this.textarea_=b;this.textarea_.style.margin=Blockly.WorkspaceCommentSvg.TEXTAREA_OFFSET+"px";this.foreignObject_.appendChild(a);Blockly.bindEventWithChecks_(b,"mousedown",this,function(a){a.stopPropagation()},!0,!0);Blockly.bindEventWithChecks_(b,
"wheel",this,function(a){a.stopPropagation()});Blockly.bindEventWithChecks_(b,"change",this,function(a){this.text_!=b.value&&this.setText(b.value)});this.labelText_=this.getLabelText();return this.foreignObject_};
Blockly.WorkspaceCommentSvg.prototype.addResizeDom_=function(){this.resizeGroup_=Blockly.utils.createSvgElement("g",{"class":this.RTL?"scratchCommentResizeSW":"scratchCommentResizeSE"},this.svgGroup_);var a=Blockly.WorkspaceCommentSvg.RESIZE_SIZE,b=Blockly.ScratchBubble.RESIZE_OUTER_PAD,c=Blockly.ScratchBubble.RESIZE_CORNER_PAD;Blockly.utils.createSvgElement("polygon",{points:[-b,a+c,a+c,a+c,a+c,-b].join(" ")},this.resizeGroup_);Blockly.utils.createSvgElement("line",{"class":"blocklyResizeLine",x1:a/
3,y1:a-1,x2:a-1,y2:a/3},this.resizeGroup_);Blockly.utils.createSvgElement("line",{"class":"blocklyResizeLine",x1:2*a/3,y1:a-1,x2:a-1,y2:2*a/3},this.resizeGroup_)};
Blockly.WorkspaceCommentSvg.prototype.createCommentTopBar_=function(){this.svgHandleTarget_=Blockly.utils.createSvgElement("rect",{"class":"blocklyDraggable scratchCommentTopBar",rx:Blockly.WorkspaceCommentSvg.BORDER_WIDTH,ry:Blockly.WorkspaceCommentSvg.BORDER_WIDTH,height:Blockly.WorkspaceCommentSvg.TOP_BAR_HEIGHT},this.svgGroup_);this.createTopBarIcons_();this.createTopBarLabel_()};
Blockly.WorkspaceCommentSvg.prototype.createTopBarLabel_=function(){this.topBarLabel_=Blockly.utils.createSvgElement("text",{"class":"scratchCommentText",x:this.width_/2,y:Blockly.WorkspaceCommentSvg.TOP_BAR_HEIGHT/2+Blockly.WorkspaceCommentSvg.BORDER_WIDTH,"text-anchor":"middle","dominant-baseline":"middle"},this.svgGroup_);var a=document.createTextNode(this.labelText_);this.topBarLabel_.appendChild(a)};
Blockly.WorkspaceCommentSvg.prototype.createTopBarIcons_=function(){var a=Blockly.WorkspaceCommentSvg.TOP_BAR_HEIGHT/2+Blockly.WorkspaceCommentSvg.BORDER_WIDTH,b=Blockly.WorkspaceCommentSvg.TOP_BAR_ICON_INSET;this.minimizeArrow_=Blockly.utils.createSvgElement("image",{x:b,y:a-Blockly.WorkspaceCommentSvg.MINIMIZE_ICON_SIZE/2,width:Blockly.WorkspaceCommentSvg.MINIMIZE_ICON_SIZE,height:Blockly.WorkspaceCommentSvg.MINIMIZE_ICON_SIZE},this.svgGroup_);this.deleteIcon_=Blockly.utils.createSvgElement("image",
{x:b,y:a-Blockly.WorkspaceCommentSvg.DELETE_ICON_SIZE/2,width:Blockly.WorkspaceCommentSvg.DELETE_ICON_SIZE,height:Blockly.WorkspaceCommentSvg.DELETE_ICON_SIZE},this.svgGroup_);this.deleteIcon_.setAttributeNS("http://www.w3.org/1999/xlink","xlink:href",Blockly.mainWorkspace.options.pathToMedia+"delete-x.svg")};Blockly.WorkspaceCommentSvg.prototype.minimizeArrowMouseDown_=function(a){this.shouldToggleMinimize_=!0;a.stopPropagation()};
Blockly.WorkspaceCommentSvg.prototype.minimizeArrowMouseOut_=function(a){this.shouldToggleMinimize_=!1};Blockly.WorkspaceCommentSvg.prototype.minimizeArrowMouseUp_=function(a){this.shouldToggleMinimize_&&(this.shouldToggleMinimize=!1,this.toggleMinimize_());a.stopPropagation()};Blockly.WorkspaceCommentSvg.prototype.deleteMouseDown_=function(a){this.shouldDelete_=!0;a.stopPropagation()};Blockly.WorkspaceCommentSvg.prototype.deleteMouseOut_=function(a){this.shouldDelete_=!1};
Blockly.WorkspaceCommentSvg.prototype.deleteMouseUp_=function(a){this.shouldDelete_&&this.dispose();a.stopPropagation()};
Blockly.WorkspaceCommentSvg.prototype.resizeMouseDown_=function(a){this.resizeStartSize_={width:this.width_,height:this.height_};this.unbindDragEvents_();this.workspace.setResizesEnabled(!1);Blockly.utils.isRightButton(a)||(this.workspace.startDrag(a,new goog.math.Coordinate(this.workspace.RTL?-this.width_:this.width_,this.height_)),this.onMouseUpWrapper_=Blockly.bindEventWithChecks_(document,"mouseup",this,this.resizeMouseUp_),this.onMouseMoveWrapper_=Blockly.bindEventWithChecks_(document,"mousemove",
this,this.resizeMouseMove_),Blockly.hideChaff());a.stopPropagation()};
Blockly.WorkspaceCommentSvg.prototype.setRenderedMinimizeState_=function(a,b){a?(this.minimizeArrow_.setAttributeNS("http://www.w3.org/1999/xlink","xlink:href",Blockly.mainWorkspace.options.pathToMedia+"comment-arrow-up.svg"),this.commentEditor_.setAttribute("display","none"),this.resizeGroup_&&this.resizeGroup_.setAttribute("display","none"),b&&this.labelText_!=b&&(this.topBarLabel_.textContent=b),Blockly.utils.removeAttribute(this.topBarLabel_,"display")):(this.minimizeArrow_.setAttributeNS("http://www.w3.org/1999/xlink",
"xlink:href",Blockly.mainWorkspace.options.pathToMedia+"comment-arrow-down.svg"),this.topBarLabel_.setAttribute("display","none"),Blockly.utils.removeAttribute(this.commentEditor_,"display"),this.resizeGroup_&&Blockly.utils.removeAttribute(this.resizeGroup_,"display"))};
Blockly.WorkspaceCommentSvg.prototype.unbindDragEvents_=function(){this.onMouseUpWrapper_&&(Blockly.unbindEvent_(this.onMouseUpWrapper_),this.onMouseUpWrapper_=null);this.onMouseMoveWrapper_&&(Blockly.unbindEvent_(this.onMouseMoveWrapper_),this.onMouseMoveWrapper_=null)};
Blockly.WorkspaceCommentSvg.prototype.resizeMouseUp_=function(){Blockly.Touch.clearTouchIdentifier();this.unbindDragEvents_();var a=this.resizeStartSize_;this.resizeStartSize_=null;if(this.width_!=a.width||this.height_!=a.height)Blockly.Events.fire(new Blockly.Events.CommentChange(this,{width:a.width,height:a.height},{width:this.width_,height:this.height_})),this.workspace.setResizesEnabled(!0)};
Blockly.WorkspaceCommentSvg.prototype.resizeMouseMove_=function(a){this.autoLayout_=!1;a=this.workspace.moveDrag(a);var b=!1;Blockly.Events.isEnabled()&&(Blockly.Events.disable(),b=!0);this.setSize(this.RTL?-a.x:a.x,a.y);b&&Blockly.Events.enable()};
Blockly.WorkspaceCommentSvg.prototype.resizeComment_=function(){var a=2*Blockly.WorkspaceCommentSvg.BORDER_WIDTH,b=Blockly.WorkspaceCommentSvg.TOP_BAR_HEIGHT,c=2*Blockly.WorkspaceCommentSvg.TEXTAREA_OFFSET;this.foreignObject_.setAttribute("width",this.width_-a);this.foreignObject_.setAttribute("height",this.height_-a-b);this.RTL&&this.foreignObject_.setAttribute("x",-this.width_);this.textarea_.style.width=this.width_-c+"px";this.textarea_.style.height=this.height_-a-c-b+"px"};
Blockly.WorkspaceCommentSvg.prototype.setSize=function(a,b){var c=this.width_,d=this.height_,e=2*Blockly.WorkspaceCommentSvg.BORDER_WIDTH;this.isMinimized_?(a=Blockly.WorkspaceCommentSvg.MINIMIZE_WIDTH,b=Blockly.WorkspaceCommentSvg.TOP_BAR_HEIGHT):(a=Math.max(a,e+50),b=Math.max(b,e+20+Blockly.WorkspaceCommentSvg.TOP_BAR_HEIGHT),this.width_=a,this.height_=b,Blockly.Events.fire(new Blockly.Events.CommentChange(this,{width:c,height:d},{width:this.width_,height:this.height_})));this.svgRect_.setAttribute("width",
a);this.svgRect_.setAttribute("height",b);this.svgRectTarget_.setAttribute("width",a);this.svgRectTarget_.setAttribute("height",b-Blockly.WorkspaceCommentSvg.TOP_BAR_HEIGHT);this.svgHandleTarget_.setAttribute("width",a);this.svgHandleTarget_.setAttribute("height",Blockly.WorkspaceCommentSvg.TOP_BAR_HEIGHT);this.RTL?(this.minimizeArrow_.setAttribute("x",a-Blockly.WorkspaceCommentSvg.MINIMIZE_ICON_SIZE-Blockly.WorkspaceCommentSvg.TOP_BAR_ICON_INSET),this.deleteIcon_.setAttribute("x",-a+Blockly.WorkspaceCommentSvg.TOP_BAR_ICON_INSET),
this.svgRect_.setAttribute("transform","scale(-1 1)"),this.svgHandleTarget_.setAttribute("transform","scale(-1 1)"),this.svgHandleTarget_.setAttribute("transform","translate("+-a+", 1)"),this.minimizeArrow_.setAttribute("transform","translate("+-a+", 1)"),this.deleteIcon_.setAttribute("tranform","translate("+-a+", 1)"),this.svgRectTarget_.setAttribute("transform","translate("+-a+", 1)"),this.topBarLabel_.setAttribute("transform","translate("+-a+", 1)")):this.deleteIcon_.setAttribute("x",a-Blockly.WorkspaceCommentSvg.DELETE_ICON_SIZE-
Blockly.WorkspaceCommentSvg.TOP_BAR_ICON_INSET);c=Blockly.WorkspaceCommentSvg.RESIZE_SIZE;this.resizeGroup_&&(this.RTL?this.resizeGroup_.setAttribute("transform","translate("+(-a+e+c)+","+(b-e-c)+") scale(-1 1)"):this.resizeGroup_.setAttribute("transform","translate("+(a-e-c)+","+(b-e-c)+")"));this.isMinimized_&&(this.topBarLabel_.setAttribute("x",a/2),this.topBarLabel_.setAttribute("y",b/2));this.resizeComment_()};Blockly.WorkspaceComment.prototype.toggleMinimize_=function(){this.setMinimized(!this.isMinimized_)};
Blockly.WorkspaceComment.prototype.setMinimized=function(a){this.isMinimized_!=a&&(Blockly.Events.fire(new Blockly.Events.CommentChange(this,{minimized:this.isMinimized_},{minimized:a})),(this.isMinimized_=a)?(this.rendered_&&this.setRenderedMinimizeState_(!0,this.getLabelText()),this.setSize(Blockly.WorkspaceCommentSvg.MINIMIZE_WIDTH,Blockly.WorkspaceCommentSvg.TOP_BAR_HEIGHT)):(this.rendered_&&this.setRenderedMinimizeState_(!1),this.setText(this.content_),this.setSize(this.width_,this.height_)))};
Blockly.WorkspaceCommentSvg.prototype.disposeInternal_=function(){this.svgHandleTarget_=this.svgRectTarget_=this.svgRect_=this.foreignObject_=this.textarea_=null};Blockly.WorkspaceCommentSvg.prototype.setFocus=function(){var a=this;this.focused_=!0;a.textarea_.focus();setTimeout(function(){a.addFocus();Blockly.utils.addClass(a.svgRectTarget_,"scratchCommentTargetFocused");Blockly.utils.addClass(a.svgHandleTarget_,"scratchCommentHandleTargetFocused")},0)};
Blockly.WorkspaceCommentSvg.prototype.blurFocus=function(){var a=this;this.focused_=!1;a.textarea_.blur();setTimeout(function(){a.svgGroup_&&(a.removeFocus(),Blockly.utils.removeClass(a.svgRectTarget_,"scratchCommentTargetFocused"),Blockly.utils.removeClass(a.svgHandleTarget_,"scratchCommentHandleTargetFocused"))},0)};Blockly.WorkspaceDragSurfaceSvg=function(a){this.container_=a;this.createDom()};Blockly.WorkspaceDragSurfaceSvg.prototype.SVG_=null;Blockly.WorkspaceDragSurfaceSvg.prototype.dragGroup_=null;Blockly.WorkspaceDragSurfaceSvg.prototype.container_=null;
Blockly.WorkspaceDragSurfaceSvg.prototype.createDom=function(){this.SVG_||(this.SVG_=Blockly.utils.createSvgElement("svg",{xmlns:Blockly.SVG_NS,"xmlns:html":Blockly.HTML_NS,"xmlns:xlink":"http://www.w3.org/1999/xlink",version:"1.1","class":"blocklyWsDragSurface blocklyOverflowVisible"},null),this.container_.appendChild(this.SVG_))};
Blockly.WorkspaceDragSurfaceSvg.prototype.translateSurface=function(a,b){a=a.toFixed(0);b=b.toFixed(0);this.SVG_.style.display="block";Blockly.utils.setCssTransform(this.SVG_,"translate3d("+a+"px, "+b+"px, 0px)")};Blockly.WorkspaceDragSurfaceSvg.prototype.getSurfaceTranslation=function(){return Blockly.utils.getRelativeXY(this.SVG_)};
Blockly.WorkspaceDragSurfaceSvg.prototype.clearAndHide=function(a){if(!a)throw"Couldn't clear and hide the drag surface: missing new surface.";var b=this.SVG_.childNodes[0],c=this.SVG_.childNodes[1];if(!(b&&c&&Blockly.utils.hasClass(b,"blocklyBlockCanvas")&&Blockly.utils.hasClass(c,"blocklyBubbleCanvas")))throw"Couldn't clear and hide the drag surface.  A node was missing.";null!=this.previousSibling_?Blockly.utils.insertAfter(b,this.previousSibling_):a.insertBefore(b,a.firstChild);Blockly.utils.insertAfter(c,
b);this.SVG_.style.display="none";goog.asserts.assert(0==this.SVG_.childNodes.length,"Drag surface was not cleared.");Blockly.utils.setCssTransform(this.SVG_,"");this.previousSibling_=null};
Blockly.WorkspaceDragSurfaceSvg.prototype.setContentsAndShow=function(a,b,c,d,e,f){goog.asserts.assert(0==this.SVG_.childNodes.length,"Already dragging a block.");this.previousSibling_=c;a.setAttribute("transform","translate(0, 0) scale("+f+")");b.setAttribute("transform","translate(0, 0) scale("+f+")");this.SVG_.setAttribute("width",d);this.SVG_.setAttribute("height",e);this.SVG_.appendChild(a);this.SVG_.appendChild(b);this.SVG_.style.display="block"};Blockly.Xml={};Blockly.Xml.workspaceToDom=function(a,b){var c=goog.dom.createDom("xml");c.appendChild(Blockly.Xml.variablesToDom(a.getAllVariables()));for(var d=a.getTopComments(!0).filter(function(a){return a instanceof Blockly.WorkspaceComment}),e=0,f;f=d[e];e++)c.appendChild(f.toXmlWithXY(b));a=a.getTopBlocks(!0);for(e=0;d=a[e];e++)c.appendChild(Blockly.Xml.blockToDomWithXY(d,b));return c};
Blockly.Xml.variablesToDom=function(a){for(var b=goog.dom.createDom("variables"),c=0,d;d=a[c];c++){var e=goog.dom.createDom("variable",null,d.name);e.setAttribute("type",d.type);e.setAttribute("id",d.getId());e.setAttribute("islocal",d.isLocal);e.setAttribute("isCloud",d.isCloud);b.appendChild(e)}return b};
Blockly.Xml.blockToDomWithXY=function(a,b){var c;a.workspace.RTL&&(c=a.workspace.getWidth());b=Blockly.Xml.blockToDom(a,b);var d=a.getRelativeToSurfaceXY();b.setAttribute("x",Math.round(a.workspace.RTL?c-d.x:d.x));b.setAttribute("y",Math.round(d.y));return b};
Blockly.Xml.fieldToDomVariable_=function(a){null==a.getValue()&&(a.initModel(),a.getValue());var b=a.getVariable();if(!b)throw Error("Tried to serialize a variable field with no variable.");var c=goog.dom.createDom("field",null,b.name);c.setAttribute("name",a.name);c.setAttribute("id",b.getId());c.setAttribute("variabletype",b.type);return c};
Blockly.Xml.fieldToDom_=function(a){if(a.name&&a.SERIALIZABLE){if(a.referencesVariables())return Blockly.Xml.fieldToDomVariable_(a);var b=goog.dom.createDom("field",null,a.getValue());b.setAttribute("name",a.name);return b}return null};Blockly.Xml.allFieldsToDom_=function(a,b){for(var c=0,d;d=a.inputList[c];c++)for(var e=0,f;f=d.fieldRow[e];e++)(f=Blockly.Xml.fieldToDom_(f))&&b.appendChild(f)};
Blockly.Xml.blockToDom=function(a,b){var c=goog.dom.createDom(a.isShadow()?"shadow":"block");c.setAttribute("type",a.type);b||c.setAttribute("id",a.id);if(a.mutationToDom){var d=a.mutationToDom();d&&(d.hasChildNodes()||d.hasAttributes())&&c.appendChild(d)}Blockly.Xml.allFieldsToDom_(a,c);Blockly.Xml.scratchCommentToDom_(a,c);a.data&&(d=goog.dom.createDom("data",null,a.data),c.appendChild(d));d=0;for(var e;e=a.inputList[d];d++){var f,g=!0;if(e.type!=Blockly.DUMMY_INPUT){var h=e.connection.targetBlock();
e.type==Blockly.INPUT_VALUE?f=goog.dom.createDom("value"):e.type==Blockly.NEXT_STATEMENT&&(f=goog.dom.createDom("statement"));var k=e.connection.getShadowDom();!k||h&&h.isShadow()||(k=Blockly.Xml.cloneShadow_(k),b&&k.getAttribute("id")&&k.removeAttribute("id"),f.appendChild(k));h&&(f.appendChild(Blockly.Xml.blockToDom(h,b)),g=!1);f.setAttribute("name",e.name);g||c.appendChild(f)}}a.inputsInlineDefault!=a.inputsInline&&c.setAttribute("inline",a.inputsInline);a.isCollapsed()&&c.setAttribute("collapsed",
!0);a.disabled&&c.setAttribute("disabled",!0);a.isDeletable()||a.isShadow()||c.setAttribute("deletable",!1);a.isMovable()||a.isShadow()||c.setAttribute("movable",!1);a.isEditable()||c.setAttribute("editable",!1);if(d=a.getNextBlock())f=goog.dom.createDom("next",null,Blockly.Xml.blockToDom(d,b)),c.appendChild(f);k=a.nextConnection&&a.nextConnection.getShadowDom();!k||d&&d.isShadow()||f.appendChild(Blockly.Xml.cloneShadow_(k));return c};
Blockly.Xml.scratchCommentToDom_=function(a,b){var c=a.getCommentText();if(c){c=goog.dom.createDom("comment",null,c);if("object"==typeof a.comment){c.setAttribute("id",a.comment.id);c.setAttribute("pinned",a.comment.isVisible());var d=a.comment instanceof Blockly.ScratchBlockComment?a.comment.getHeightWidth():a.comment.getBubbleSize();c.setAttribute("h",d.height);c.setAttribute("w",d.width);var e=a.comment.getXY();c.setAttribute("x",Math.round(a.workspace.RTL?a.workspace.getWidth()-e.x-d.width:e.x));
c.setAttribute("y",e.y);c.setAttribute("minimized",a.comment.isMinimized())}b.appendChild(c)}};Blockly.Xml.cloneShadow_=function(a){for(var b=a=a.cloneNode(!0),c;b;)if(b.firstChild)b=b.firstChild;else{for(;b&&!b.nextSibling;)c=b,b=b.parentNode,3==c.nodeType&&""==c.data.trim()&&b.firstChild!=c&&goog.dom.removeNode(c);b&&(c=b,b=b.nextSibling,3==c.nodeType&&""==c.data.trim()&&goog.dom.removeNode(c))}return a};Blockly.Xml.domToText=function(a){return(new XMLSerializer).serializeToString(a)};
Blockly.Xml.domToPrettyText=function(a){a=Blockly.Xml.domToText(a).split("<");for(var b="",c=1;c<a.length;c++){var d=a[c];"/"==d[0]&&(b=b.substring(2));a[c]=b+"<"+d;"/"!=d[0]&&"/>"!=d.slice(-2)&&(b+="  ")}a=a.join("\n");a=a.replace(/(<(\w+)\b[^>]*>[^\n]*)\n *<\/\2>/g,"$1</$2>");return a.replace(/^\n/,"")};
Blockly.Xml.textToDom=function(a){(a=(new DOMParser).parseFromString(a,"text/xml"))&&a.firstChild&&"xml"==a.firstChild.nodeName.toLowerCase()&&a.firstChild===a.lastChild||goog.asserts.fail("Blockly.Xml.textToDom did not obtain a valid XML tree.");return a.firstChild};Blockly.Xml.clearWorkspaceAndLoadFromXml=function(a,b){b.setResizesEnabled(!1);b.setToolboxRefreshEnabled(!1);b.clear();a=Blockly.Xml.domToWorkspace(a,b);b.setResizesEnabled(!0);b.setToolboxRefreshEnabled(!0);return a};
Blockly.Xml.domToWorkspace=function(a,b){if(a instanceof Blockly.Workspace){var c=a;a=b;b=c;console.warn("Deprecated call to Blockly.Xml.domToWorkspace, swap the arguments.")}var d;b.RTL&&(d=b.getWidth());c=[];Blockly.Field.startCache();var e=a.childNodes.length,f=Blockly.Events.getGroup();f||Blockly.Events.setGroup(!0);b.setResizesEnabled&&b.setResizesEnabled(!1);var g=!0;try{for(var h=0;h<e;h++){var k=a.childNodes[h],l=k.nodeName.toLowerCase();if("block"==l||"shadow"==l&&!Blockly.Events.recordUndo){var m=
Blockly.Xml.domToBlock(k,b);c.push(m.id);var n=k.hasAttribute("x")?parseInt(k.getAttribute("x"),10):10,p=k.hasAttribute("y")?parseInt(k.getAttribute("y"),10):10;if(!isNaN(n)&&!isNaN(p)&&(m.moveBy(b.RTL?d-n:n,p),m.comment&&"object"===typeof m.comment)){var q=m.comment.getXY(),r=m.comment.getBubbleSize().width;m.comment.moveTo(m.workspace.RTL?d-q.x-r:q.x,q.y)}g=!1}else if("shadow"==l)goog.asserts.fail("Shadow block cannot be a top-level block."),g=!1;else if("comment"==l)b.rendered?Blockly.WorkspaceCommentSvg.fromXml(k,
b,d):Blockly.WorkspaceComment.fromXml(k,b);else if("variables"==l){if(g)Blockly.Xml.domToVariables(k,b);else throw Error("'variables' tag must exist once before block and shadow tag elements in the workspace XML, but it was found in another location.");g=!1}}}finally{f||Blockly.Events.setGroup(!1),Blockly.Field.stopCache()}b.setResizesEnabled&&b.setResizesEnabled(!0);return c};
Blockly.Xml.appendDomToWorkspace=function(a,b){if(b.hasOwnProperty("scale")){var c=Blockly.BlockSvg.TAB_WIDTH;try{Blockly.BlockSvg.TAB_WIDTH=0;var d=b.getBlocksBoundingBox()}finally{Blockly.BlockSvg.TAB_WIDTH=c}}a=Blockly.Xml.domToWorkspace(a,b);if(d&&d.height){c=d.y+d.height;var e=d.x;var f=Infinity,g=Infinity;for(d=0;d<a.length;d++){var h=b.getBlockById(a[d]).getRelativeToSurfaceXY();h.y<g&&(g=h.y);h.x<f&&(f=h.x)}c=c-g+Blockly.BlockSvg.SEP_SPACE_Y;e-=f;var k;b.RTL&&(k=b.getWidth());for(d=0;d<a.length;d++)b.getBlockById(a[d]).moveBy(b.RTL?
k-e:e,c)}return a};
Blockly.Xml.domToBlock=function(a,b){if(a instanceof Blockly.Workspace){var c=a;a=b;b=c;console.warn("Deprecated call to Blockly.Xml.domToBlock, swap the arguments.")}Blockly.Events.disable();c=b.getAllVariables();try{var d=Blockly.Xml.domToBlockHeadless_(a,b),e=d.getDescendants(!1);if(b.rendered){d.setConnectionsHidden(!0);for(var f=e.length-1;0<=f;f--)e[f].initSvg();for(f=e.length-1;0<=f;f--)e[f].render(!1);b.isFlyout||setTimeout(function(){d.workspace&&d.setConnectionsHidden(!1)},1);d.updateDisabled();
b.resizeContents()}else for(f=e.length-1;0<=f;f--)e[f].initModel()}finally{Blockly.Events.enable()}if(Blockly.Events.isEnabled()){a=Blockly.Variables.getAddedVariables(b,c);for(f=0;f<a.length;f++)Blockly.Events.fire(new Blockly.Events.VarCreate(a[f]));Blockly.Events.fire(new Blockly.Events.BlockCreate(d))}return d};
Blockly.Xml.domToVariables=function(a,b){for(var c=0,d;d=a.children[c];c++){var e=d.getAttribute("type"),f=d.getAttribute("id"),g="true"==d.getAttribute("islocal"),h="true"==d.getAttribute("iscloud");d=d.textContent;if(null===e)throw Error("Variable with id, "+f+" is without a type");b.createVariable(d,e,f,g,h)}};
Blockly.Xml.domToBlockHeadless_=function(a,b){var c=null,d=a.getAttribute("type");goog.asserts.assert(d,"Block type unspecified: %s",a.outerHTML);var e=a.getAttribute("id");c=b.newBlock(d,e);var f=null;e=0;for(var g;g=a.childNodes[e];e++)if(3!=g.nodeType){for(var h=f=null,k=0,l;l=g.childNodes[k];k++)1==l.nodeType&&("block"==l.nodeName.toLowerCase()?f=l:"shadow"==l.nodeName.toLowerCase()&&(h=l));!f&&h&&(f=h);k=g.getAttribute("name");switch(g.nodeName.toLowerCase()){case "mutation":c.domToMutation&&
(c.domToMutation(g),c.initSvg&&c.initSvg());break;case "comment":f=g.getAttribute("id");h=parseInt(g.getAttribute("x"),10);k=parseInt(g.getAttribute("y"),10);l=g.getAttribute("minimized")||!1;c.setCommentText(g.textContent,f,h,k,"true"==l);var m=g.getAttribute("pinned");m&&!c.isInFlyout&&setTimeout(function(){c.comment&&c.comment.setVisible&&c.comment.setVisible("true"==m)},1);f=parseInt(g.getAttribute("w"),10);g=parseInt(g.getAttribute("h"),10);!isNaN(f)&&!isNaN(g)&&c.comment&&c.comment.setVisible&&
(c.comment instanceof Blockly.ScratchBlockComment?c.comment.setSize(f,g):c.comment.setBubbleSize(f,g));break;case "data":c.data=g.textContent;break;case "title":case "field":Blockly.Xml.domToField_(c,k,g);break;case "value":case "statement":g=c.getInput(k);if(!g){console.warn("Ignoring non-existent input "+k+" in block "+d);break}h&&g.connection.setShadowDom(h);f&&(f=Blockly.Xml.domToBlockHeadless_(f,b),f.outputConnection?g.connection.connect(f.outputConnection):f.previousConnection?g.connection.connect(f.previousConnection):
goog.asserts.fail("Child block does not have output or previous statement."));break;case "next":h&&c.nextConnection&&c.nextConnection.setShadowDom(h);f&&(goog.asserts.assert(c.nextConnection,"Next statement does not exist."),goog.asserts.assert(!c.nextConnection.isConnected(),"Next statement is already connected."),f=Blockly.Xml.domToBlockHeadless_(f,b),goog.asserts.assert(f.previousConnection,"Next block does not have previous statement."),c.nextConnection.connect(f.previousConnection));break;default:console.warn("Ignoring unknown tag: "+
g.nodeName)}}(e=a.getAttribute("inline"))&&c.setInputsInline("true"==e);(e=a.getAttribute("disabled"))&&c.setDisabled("true"==e||"disabled"==e);(e=a.getAttribute("deletable"))&&c.setDeletable("true"==e);(e=a.getAttribute("movable"))&&c.setMovable("true"==e);(e=a.getAttribute("editable"))&&c.setEditable("true"==e);(e=a.getAttribute("collapsed"))&&c.setCollapsed("true"==e);if("shadow"==a.nodeName.toLowerCase()){a=c.getChildren(!1);for(e=0;b=a[e];e++)goog.asserts.assert(b.isShadow(),"Shadow block not allowed non-shadow child.");
c.setShadow(!0)}return c};
Blockly.Xml.domToFieldVariable_=function(a,b,c,d){var e=b.getAttribute("variabletype")||"";"''"==e&&(e="");if(!a.getPotentialVariableMap()&&!a.isFlyout&&a.getFlyout()){var f=a.getFlyout().getWorkspace();f=Blockly.Variables.realizePotentialVar(c,e,f,!0)}f||(f=Blockly.Variables.getOrCreateVariablePackage(a,b.id,c,e));if(null!=e&&e!==f.type)throw Error("Serialized variable type with id '"+f.getId()+"' had type "+f.type+", and does not match variable field that references it: "+Blockly.Xml.domToText(b)+
".");d.setValue(f.getId())};Blockly.Xml.domToField_=function(a,b,c){var d=a.getField(b);d?(a=a.workspace,b=c.textContent,d.referencesVariables()?Blockly.Xml.domToFieldVariable_(a,c,b,d):d.setValue(b)):console.warn("Ignoring non-existent field "+b+" in block "+a.type)};Blockly.Xml.deleteNext=function(a){for(var b=0,c;c=a.childNodes[b];b++)if("next"==c.nodeName.toLowerCase()){a.removeChild(c);break}};goog.global.Blockly||(goog.global.Blockly={});goog.global.Blockly.Xml||(goog.global.Blockly.Xml={});
goog.global.Blockly.Xml.domToText=Blockly.Xml.domToText;goog.global.Blockly.Xml.domToWorkspace=Blockly.Xml.domToWorkspace;goog.global.Blockly.Xml.textToDom=Blockly.Xml.textToDom;goog.global.Blockly.Xml.workspaceToDom=Blockly.Xml.workspaceToDom;goog.global.Blockly.Xml.clearWorkspaceAndLoadFromXml=Blockly.Xml.clearWorkspaceAndLoadFromXml;Blockly.utils.uiMenu={};Blockly.utils.uiMenu.getSize=function(a){a=a.getElement();var b=goog.style.getSize(a);b.height=a.scrollHeight;return b};Blockly.utils.uiMenu.adjustBBoxesForRTL=function(a,b,c){b.left+=c.width;b.right+=c.width;a.left+=c.width;a.right+=c.width};goog.events.EventHandler=function(a){goog.Disposable.call(this);this.handler_=a;this.keys_={}};goog.inherits(goog.events.EventHandler,goog.Disposable);goog.events.EventHandler.typeArray_=[];goog.events.EventHandler.prototype.listen=function(a,b,c,d){return this.listen_(a,b,c,d)};goog.events.EventHandler.prototype.listenWithScope=function(a,b,c,d,e){return this.listen_(a,b,c,d,e)};
goog.events.EventHandler.prototype.listen_=function(a,b,c,d,e){goog.isArray(b)||(b&&(goog.events.EventHandler.typeArray_[0]=b.toString()),b=goog.events.EventHandler.typeArray_);for(var f=0;f<b.length;f++){var g=goog.events.listen(a,b[f],c||this.handleEvent,d||!1,e||this.handler_||this);if(!g)break;this.keys_[g.key]=g}return this};goog.events.EventHandler.prototype.listenOnce=function(a,b,c,d){return this.listenOnce_(a,b,c,d)};
goog.events.EventHandler.prototype.listenOnceWithScope=function(a,b,c,d,e){return this.listenOnce_(a,b,c,d,e)};goog.events.EventHandler.prototype.listenOnce_=function(a,b,c,d,e){if(goog.isArray(b))for(var f=0;f<b.length;f++)this.listenOnce_(a,b[f],c,d,e);else{a=goog.events.listenOnce(a,b,c||this.handleEvent,d,e||this.handler_||this);if(!a)return this;this.keys_[a.key]=a}return this};goog.events.EventHandler.prototype.listenWithWrapper=function(a,b,c,d){return this.listenWithWrapper_(a,b,c,d)};
goog.events.EventHandler.prototype.listenWithWrapperAndScope=function(a,b,c,d,e){return this.listenWithWrapper_(a,b,c,d,e)};goog.events.EventHandler.prototype.listenWithWrapper_=function(a,b,c,d,e){b.listen(a,c,d,e||this.handler_||this,this);return this};goog.events.EventHandler.prototype.getListenerCount=function(){var a=0,b;for(b in this.keys_)Object.prototype.hasOwnProperty.call(this.keys_,b)&&a++;return a};
goog.events.EventHandler.prototype.unlisten=function(a,b,c,d,e){if(goog.isArray(b))for(var f=0;f<b.length;f++)this.unlisten(a,b[f],c,d,e);else if(d=goog.isObject(d)?!!d.capture:!!d,a=goog.events.getListener(a,b,c||this.handleEvent,d,e||this.handler_||this))goog.events.unlistenByKey(a),delete this.keys_[a.key];return this};goog.events.EventHandler.prototype.unlistenWithWrapper=function(a,b,c,d,e){b.unlisten(a,c,d,e||this.handler_||this,this);return this};
goog.events.EventHandler.prototype.removeAll=function(){goog.object.forEach(this.keys_,function(a,b){this.keys_.hasOwnProperty(b)&&goog.events.unlistenByKey(a)},this);this.keys_={}};goog.events.EventHandler.prototype.disposeInternal=function(){goog.events.EventHandler.superClass_.disposeInternal.call(this);this.removeAll()};goog.events.EventHandler.prototype.handleEvent=function(a){throw Error("EventHandler.handleEvent not implemented");};goog.events.EventTarget=function(){goog.Disposable.call(this);this.eventTargetListeners_=new goog.events.ListenerMap(this);this.actualEventTarget_=this;this.parentEventTarget_=null};goog.inherits(goog.events.EventTarget,goog.Disposable);goog.events.Listenable.addImplementation(goog.events.EventTarget);goog.events.EventTarget.MAX_ANCESTORS_=1E3;goog.events.EventTarget.prototype.getParentEventTarget=function(){return this.parentEventTarget_};
goog.events.EventTarget.prototype.setParentEventTarget=function(a){this.parentEventTarget_=a};goog.events.EventTarget.prototype.addEventListener=function(a,b,c,d){goog.events.listen(this,a,b,c,d)};goog.events.EventTarget.prototype.removeEventListener=function(a,b,c,d){goog.events.unlisten(this,a,b,c,d)};
goog.events.EventTarget.prototype.dispatchEvent=function(a){this.assertInitialized_();var b=this.getParentEventTarget();if(b){var c=[];for(var d=1;b;b=b.getParentEventTarget())c.push(b),goog.asserts.assert(++d<goog.events.EventTarget.MAX_ANCESTORS_,"infinite loop")}return goog.events.EventTarget.dispatchEventInternal_(this.actualEventTarget_,a,c)};
goog.events.EventTarget.prototype.disposeInternal=function(){goog.events.EventTarget.superClass_.disposeInternal.call(this);this.removeAllListeners();this.parentEventTarget_=null};goog.events.EventTarget.prototype.listen=function(a,b,c,d){this.assertInitialized_();return this.eventTargetListeners_.add(String(a),b,!1,c,d)};goog.events.EventTarget.prototype.listenOnce=function(a,b,c,d){return this.eventTargetListeners_.add(String(a),b,!0,c,d)};
goog.events.EventTarget.prototype.unlisten=function(a,b,c,d){return this.eventTargetListeners_.remove(String(a),b,c,d)};goog.events.EventTarget.prototype.unlistenByKey=function(a){return this.eventTargetListeners_.removeByKey(a)};goog.events.EventTarget.prototype.removeAllListeners=function(a){return this.eventTargetListeners_?this.eventTargetListeners_.removeAll(a):0};
goog.events.EventTarget.prototype.fireListeners=function(a,b,c){a=this.eventTargetListeners_.listeners[String(a)];if(!a)return!0;a=a.concat();for(var d=!0,e=0;e<a.length;++e){var f=a[e];if(f&&!f.removed&&f.capture==b){var g=f.listener,h=f.handler||f.src;f.callOnce&&this.unlistenByKey(f);d=!1!==g.call(h,c)&&d}}return d&&0!=c.returnValue_};goog.events.EventTarget.prototype.getListeners=function(a,b){return this.eventTargetListeners_.getListeners(String(a),b)};
goog.events.EventTarget.prototype.getListener=function(a,b,c,d){return this.eventTargetListeners_.getListener(String(a),b,c,d)};goog.events.EventTarget.prototype.hasListener=function(a,b){a=goog.isDef(a)?String(a):void 0;return this.eventTargetListeners_.hasListener(a,b)};goog.events.EventTarget.prototype.setTargetForTesting=function(a){this.actualEventTarget_=a};goog.events.EventTarget.prototype.assertInitialized_=function(){goog.asserts.assert(this.eventTargetListeners_,"Event target is not initialized. Did you call the superclass (goog.events.EventTarget) constructor?")};
goog.events.EventTarget.dispatchEventInternal_=function(a,b,c){var d=b.type||b;if(goog.isString(b))b=new goog.events.Event(b,a);else if(b instanceof goog.events.Event)b.target=b.target||a;else{var e=b;b=new goog.events.Event(d,a);goog.object.extend(b,e)}e=!0;if(c)for(var f=c.length-1;!b.propagationStopped_&&0<=f;f--){var g=b.currentTarget=c[f];e=g.fireListeners(d,!0,b)&&e}b.propagationStopped_||(g=b.currentTarget=a,e=g.fireListeners(d,!0,b)&&e,b.propagationStopped_||(e=g.fireListeners(d,!1,b)&&e));
if(c)for(f=0;!b.propagationStopped_&&f<c.length;f++)g=b.currentTarget=c[f],e=g.fireListeners(d,!1,b)&&e;return e};goog.ui={};goog.ui.IdGenerator=function(){};goog.addSingletonGetter(goog.ui.IdGenerator);goog.ui.IdGenerator.prototype.nextId_=0;goog.ui.IdGenerator.prototype.idPrefix_="";goog.ui.IdGenerator.prototype.setIdPrefix=function(a){this.idPrefix_=a};goog.ui.IdGenerator.prototype.getNextUniqueId=function(){return this.idPrefix_+":"+(this.nextId_++).toString(36)};goog.ui.Component=function(a){goog.events.EventTarget.call(this);this.dom_=a||goog.dom.getDomHelper();this.rightToLeft_=goog.ui.Component.defaultRightToLeft_;this.id_=null;this.inDocument_=!1;this.element_=null;this.googUiComponentHandler_=void 0;this.childIndex_=this.children_=this.parent_=this.model_=null;this.pointerEventsEnabled_=this.wasDecorated_=!1};goog.inherits(goog.ui.Component,goog.events.EventTarget);goog.ui.Component.ALLOW_DETACHED_DECORATION=!1;
goog.ui.Component.prototype.idGenerator_=goog.ui.IdGenerator.getInstance();goog.ui.Component.DEFAULT_BIDI_DIR=0;goog.ui.Component.defaultRightToLeft_=1==goog.ui.Component.DEFAULT_BIDI_DIR?!1:-1==goog.ui.Component.DEFAULT_BIDI_DIR?!0:null;
goog.ui.Component.EventType={BEFORE_SHOW:"beforeshow",SHOW:"show",HIDE:"hide",DISABLE:"disable",ENABLE:"enable",HIGHLIGHT:"highlight",UNHIGHLIGHT:"unhighlight",ACTIVATE:"activate",DEACTIVATE:"deactivate",SELECT:"select",UNSELECT:"unselect",CHECK:"check",UNCHECK:"uncheck",FOCUS:"focus",BLUR:"blur",OPEN:"open",CLOSE:"close",ENTER:"enter",LEAVE:"leave",ACTION:"action",CHANGE:"change"};
goog.ui.Component.Error={NOT_SUPPORTED:"Method not supported",DECORATE_INVALID:"Invalid element to decorate",ALREADY_RENDERED:"Component already rendered",PARENT_UNABLE_TO_BE_SET:"Unable to set parent component",CHILD_INDEX_OUT_OF_BOUNDS:"Child component index out of bounds",NOT_OUR_CHILD:"Child is not in parent component",NOT_IN_DOCUMENT:"Operation not supported while component is not in document",STATE_INVALID:"Invalid component state"};
goog.ui.Component.State={ALL:255,DISABLED:1,HOVER:2,ACTIVE:4,SELECTED:8,CHECKED:16,FOCUSED:32,OPENED:64};
goog.ui.Component.getStateTransitionEvent=function(a,b){switch(a){case goog.ui.Component.State.DISABLED:return b?goog.ui.Component.EventType.DISABLE:goog.ui.Component.EventType.ENABLE;case goog.ui.Component.State.HOVER:return b?goog.ui.Component.EventType.HIGHLIGHT:goog.ui.Component.EventType.UNHIGHLIGHT;case goog.ui.Component.State.ACTIVE:return b?goog.ui.Component.EventType.ACTIVATE:goog.ui.Component.EventType.DEACTIVATE;case goog.ui.Component.State.SELECTED:return b?goog.ui.Component.EventType.SELECT:
goog.ui.Component.EventType.UNSELECT;case goog.ui.Component.State.CHECKED:return b?goog.ui.Component.EventType.CHECK:goog.ui.Component.EventType.UNCHECK;case goog.ui.Component.State.FOCUSED:return b?goog.ui.Component.EventType.FOCUS:goog.ui.Component.EventType.BLUR;case goog.ui.Component.State.OPENED:return b?goog.ui.Component.EventType.OPEN:goog.ui.Component.EventType.CLOSE}throw Error(goog.ui.Component.Error.STATE_INVALID);};
goog.ui.Component.setDefaultRightToLeft=function(a){goog.ui.Component.defaultRightToLeft_=a};goog.ui.Component.prototype.getId=function(){return this.id_||(this.id_=this.idGenerator_.getNextUniqueId())};goog.ui.Component.prototype.setId=function(a){this.parent_&&this.parent_.childIndex_&&(goog.object.remove(this.parent_.childIndex_,this.id_),goog.object.add(this.parent_.childIndex_,a,this));this.id_=a};goog.ui.Component.prototype.getElement=function(){return this.element_};
goog.ui.Component.prototype.getElementStrict=function(){var a=this.element_;goog.asserts.assert(a,"Can not call getElementStrict before rendering/decorating.");return a};goog.ui.Component.prototype.setElementInternal=function(a){this.element_=a};goog.ui.Component.prototype.getElementsByClass=function(a){return this.element_?this.dom_.getElementsByClass(a,this.element_):[]};goog.ui.Component.prototype.getElementByClass=function(a){return this.element_?this.dom_.getElementByClass(a,this.element_):null};
goog.ui.Component.prototype.getRequiredElementByClass=function(a){var b=this.getElementByClass(a);goog.asserts.assert(b,"Expected element in component with class: %s",a);return b};goog.ui.Component.prototype.getHandler=function(){this.googUiComponentHandler_||(this.googUiComponentHandler_=new goog.events.EventHandler(this));return goog.asserts.assert(this.googUiComponentHandler_)};
goog.ui.Component.prototype.setParent=function(a){if(this==a)throw Error(goog.ui.Component.Error.PARENT_UNABLE_TO_BE_SET);if(a&&this.parent_&&this.id_&&this.parent_.getChild(this.id_)&&this.parent_!=a)throw Error(goog.ui.Component.Error.PARENT_UNABLE_TO_BE_SET);this.parent_=a;goog.ui.Component.superClass_.setParentEventTarget.call(this,a)};goog.ui.Component.prototype.getParent=function(){return this.parent_};
goog.ui.Component.prototype.setParentEventTarget=function(a){if(this.parent_&&this.parent_!=a)throw Error(goog.ui.Component.Error.NOT_SUPPORTED);goog.ui.Component.superClass_.setParentEventTarget.call(this,a)};goog.ui.Component.prototype.getDomHelper=function(){return this.dom_};goog.ui.Component.prototype.isInDocument=function(){return this.inDocument_};goog.ui.Component.prototype.createDom=function(){this.element_=this.dom_.createElement("DIV")};goog.ui.Component.prototype.render=function(a){this.render_(a)};
goog.ui.Component.prototype.renderBefore=function(a){this.render_(a.parentNode,a)};goog.ui.Component.prototype.render_=function(a,b){if(this.inDocument_)throw Error(goog.ui.Component.Error.ALREADY_RENDERED);this.element_||this.createDom();a?a.insertBefore(this.element_,b||null):this.dom_.getDocument().body.appendChild(this.element_);this.parent_&&!this.parent_.isInDocument()||this.enterDocument()};
goog.ui.Component.prototype.decorate=function(a){if(this.inDocument_)throw Error(goog.ui.Component.Error.ALREADY_RENDERED);if(a&&this.canDecorate(a)){this.wasDecorated_=!0;var b=goog.dom.getOwnerDocument(a);this.dom_&&this.dom_.getDocument()==b||(this.dom_=goog.dom.getDomHelper(a));this.decorateInternal(a);goog.ui.Component.ALLOW_DETACHED_DECORATION&&!goog.dom.contains(b,a)||this.enterDocument()}else throw Error(goog.ui.Component.Error.DECORATE_INVALID);};goog.ui.Component.prototype.canDecorate=function(a){return!0};
goog.ui.Component.prototype.wasDecorated=function(){return this.wasDecorated_};goog.ui.Component.prototype.decorateInternal=function(a){this.element_=a};goog.ui.Component.prototype.enterDocument=function(){this.inDocument_=!0;this.forEachChild(function(a){!a.isInDocument()&&a.getElement()&&a.enterDocument()})};
goog.ui.Component.prototype.exitDocument=function(){this.forEachChild(function(a){a.isInDocument()&&a.exitDocument()});this.googUiComponentHandler_&&this.googUiComponentHandler_.removeAll();this.inDocument_=!1};
goog.ui.Component.prototype.disposeInternal=function(){this.inDocument_&&this.exitDocument();this.googUiComponentHandler_&&(this.googUiComponentHandler_.dispose(),delete this.googUiComponentHandler_);this.forEachChild(function(a){a.dispose()});!this.wasDecorated_&&this.element_&&goog.dom.removeNode(this.element_);this.parent_=this.model_=this.element_=this.childIndex_=this.children_=null;goog.ui.Component.superClass_.disposeInternal.call(this)};
goog.ui.Component.prototype.makeId=function(a){return this.getId()+"."+a};goog.ui.Component.prototype.makeIds=function(a){var b={},c;for(c in a)b[c]=this.makeId(a[c]);return b};goog.ui.Component.prototype.getModel=function(){return this.model_};goog.ui.Component.prototype.setModel=function(a){this.model_=a};goog.ui.Component.prototype.getFragmentFromId=function(a){return a.substring(this.getId().length+1)};
goog.ui.Component.prototype.getElementByFragment=function(a){if(!this.inDocument_)throw Error(goog.ui.Component.Error.NOT_IN_DOCUMENT);return this.dom_.getElement(this.makeId(a))};goog.ui.Component.prototype.addChild=function(a,b){this.addChildAt(a,this.getChildCount(),b)};
goog.ui.Component.prototype.addChildAt=function(a,b,c){goog.asserts.assert(!!a,"Provided element must not be null.");if(a.inDocument_&&(c||!this.inDocument_))throw Error(goog.ui.Component.Error.ALREADY_RENDERED);if(0>b||b>this.getChildCount())throw Error(goog.ui.Component.Error.CHILD_INDEX_OUT_OF_BOUNDS);this.childIndex_&&this.children_||(this.childIndex_={},this.children_=[]);a.getParent()==this?(goog.object.set(this.childIndex_,a.getId(),a),goog.array.remove(this.children_,a)):goog.object.add(this.childIndex_,
a.getId(),a);a.setParent(this);goog.array.insertAt(this.children_,a,b);a.inDocument_&&this.inDocument_&&a.getParent()==this?(c=this.getContentElement(),b=c.childNodes[b]||null,b!=a.getElement()&&c.insertBefore(a.getElement(),b)):c?(this.element_||this.createDom(),b=this.getChildAt(b+1),a.render_(this.getContentElement(),b?b.element_:null)):this.inDocument_&&!a.inDocument_&&a.element_&&a.element_.parentNode&&a.element_.parentNode.nodeType==goog.dom.NodeType.ELEMENT&&a.enterDocument()};
goog.ui.Component.prototype.getContentElement=function(){return this.element_};goog.ui.Component.prototype.isRightToLeft=function(){null==this.rightToLeft_&&(this.rightToLeft_=goog.style.isRightToLeft(this.inDocument_?this.element_:this.dom_.getDocument().body));return this.rightToLeft_};goog.ui.Component.prototype.setRightToLeft=function(a){if(this.inDocument_)throw Error(goog.ui.Component.Error.ALREADY_RENDERED);this.rightToLeft_=a};
goog.ui.Component.prototype.hasChildren=function(){return!!this.children_&&0!=this.children_.length};goog.ui.Component.prototype.getChildCount=function(){return this.children_?this.children_.length:0};goog.ui.Component.prototype.getChildIds=function(){var a=[];this.forEachChild(function(b){a.push(b.getId())});return a};goog.ui.Component.prototype.getChild=function(a){return this.childIndex_&&a?goog.object.get(this.childIndex_,a)||null:null};
goog.ui.Component.prototype.getChildAt=function(a){return this.children_?this.children_[a]||null:null};goog.ui.Component.prototype.forEachChild=function(a,b){this.children_&&goog.array.forEach(this.children_,a,b)};goog.ui.Component.prototype.indexOfChild=function(a){return this.children_&&a?goog.array.indexOf(this.children_,a):-1};
goog.ui.Component.prototype.removeChild=function(a,b){if(a){var c=goog.isString(a)?a:a.getId();a=this.getChild(c);c&&a&&(goog.object.remove(this.childIndex_,c),goog.array.remove(this.children_,a),b&&(a.exitDocument(),a.element_&&goog.dom.removeNode(a.element_)),a.setParent(null))}if(!a)throw Error(goog.ui.Component.Error.NOT_OUR_CHILD);return a};goog.ui.Component.prototype.removeChildAt=function(a,b){return this.removeChild(this.getChildAt(a),b)};
goog.ui.Component.prototype.removeChildren=function(a){for(var b=[];this.hasChildren();)b.push(this.removeChildAt(0,a));return b};goog.ui.Component.prototype.pointerEventsEnabled=function(){return this.pointerEventsEnabled_};goog.ui.Component.prototype.setPointerEventsEnabled=function(a){if(this.inDocument_)throw Error(goog.ui.Component.Error.ALREADY_RENDERED);this.pointerEventsEnabled_=a};goog.a11y={};goog.a11y.aria={};
goog.a11y.aria.Role={ALERT:"alert",ALERTDIALOG:"alertdialog",APPLICATION:"application",ARTICLE:"article",BANNER:"banner",BUTTON:"button",CHECKBOX:"checkbox",COLUMNHEADER:"columnheader",COMBOBOX:"combobox",COMPLEMENTARY:"complementary",CONTENTINFO:"contentinfo",DEFINITION:"definition",DIALOG:"dialog",DIRECTORY:"directory",DOCUMENT:"document",FORM:"form",GRID:"grid",GRIDCELL:"gridcell",GROUP:"group",HEADING:"heading",IMG:"img",LINK:"link",LIST:"list",LISTBOX:"listbox",LISTITEM:"listitem",LOG:"log",
MAIN:"main",MARQUEE:"marquee",MATH:"math",MENU:"menu",MENUBAR:"menubar",MENU_ITEM:"menuitem",MENU_ITEM_CHECKBOX:"menuitemcheckbox",MENU_ITEM_RADIO:"menuitemradio",NAVIGATION:"navigation",NOTE:"note",OPTION:"option",PRESENTATION:"presentation",PROGRESSBAR:"progressbar",RADIO:"radio",RADIOGROUP:"radiogroup",REGION:"region",ROW:"row",ROWGROUP:"rowgroup",ROWHEADER:"rowheader",SCROLLBAR:"scrollbar",SEARCH:"search",SEPARATOR:"separator",SLIDER:"slider",SPINBUTTON:"spinbutton",STATUS:"status",TAB:"tab",
TAB_LIST:"tablist",TAB_PANEL:"tabpanel",TEXTBOX:"textbox",TEXTINFO:"textinfo",TIMER:"timer",TOOLBAR:"toolbar",TOOLTIP:"tooltip",TREE:"tree",TREEGRID:"treegrid",TREEITEM:"treeitem"};goog.a11y.aria.State={ACTIVEDESCENDANT:"activedescendant",ATOMIC:"atomic",AUTOCOMPLETE:"autocomplete",BUSY:"busy",CHECKED:"checked",COLINDEX:"colindex",CONTROLS:"controls",DESCRIBEDBY:"describedby",DISABLED:"disabled",DROPEFFECT:"dropeffect",EXPANDED:"expanded",FLOWTO:"flowto",GRABBED:"grabbed",HASPOPUP:"haspopup",HIDDEN:"hidden",INVALID:"invalid",LABEL:"label",LABELLEDBY:"labelledby",LEVEL:"level",LIVE:"live",MULTILINE:"multiline",MULTISELECTABLE:"multiselectable",ORIENTATION:"orientation",OWNS:"owns",
POSINSET:"posinset",PRESSED:"pressed",READONLY:"readonly",RELEVANT:"relevant",REQUIRED:"required",ROWINDEX:"rowindex",SELECTED:"selected",SETSIZE:"setsize",SORT:"sort",VALUEMAX:"valuemax",VALUEMIN:"valuemin",VALUENOW:"valuenow",VALUETEXT:"valuetext"};goog.a11y.aria.AutoCompleteValues={INLINE:"inline",LIST:"list",BOTH:"both",NONE:"none"};goog.a11y.aria.DropEffectValues={COPY:"copy",MOVE:"move",LINK:"link",EXECUTE:"execute",POPUP:"popup",NONE:"none"};
goog.a11y.aria.LivePriority={OFF:"off",POLITE:"polite",ASSERTIVE:"assertive"};goog.a11y.aria.OrientationValues={VERTICAL:"vertical",HORIZONTAL:"horizontal"};goog.a11y.aria.RelevantValues={ADDITIONS:"additions",REMOVALS:"removals",TEXT:"text",ALL:"all"};goog.a11y.aria.SortValues={ASCENDING:"ascending",DESCENDING:"descending",NONE:"none",OTHER:"other"};goog.a11y.aria.CheckedValues={TRUE:"true",FALSE:"false",MIXED:"mixed",UNDEFINED:"undefined"};
goog.a11y.aria.ExpandedValues={TRUE:"true",FALSE:"false",UNDEFINED:"undefined"};goog.a11y.aria.GrabbedValues={TRUE:"true",FALSE:"false",UNDEFINED:"undefined"};goog.a11y.aria.InvalidValues={FALSE:"false",TRUE:"true",GRAMMAR:"grammar",SPELLING:"spelling"};goog.a11y.aria.PressedValues={TRUE:"true",FALSE:"false",MIXED:"mixed",UNDEFINED:"undefined"};goog.a11y.aria.SelectedValues={TRUE:"true",FALSE:"false",UNDEFINED:"undefined"};goog.a11y.aria.datatables={};
goog.a11y.aria.datatables.getDefaultValuesMap=function(){goog.a11y.aria.DefaultStateValueMap_||(goog.a11y.aria.DefaultStateValueMap_=goog.object.create(goog.a11y.aria.State.ATOMIC,!1,goog.a11y.aria.State.AUTOCOMPLETE,"none",goog.a11y.aria.State.DROPEFFECT,"none",goog.a11y.aria.State.HASPOPUP,!1,goog.a11y.aria.State.LIVE,"off",goog.a11y.aria.State.MULTILINE,!1,goog.a11y.aria.State.MULTISELECTABLE,!1,goog.a11y.aria.State.ORIENTATION,"vertical",goog.a11y.aria.State.READONLY,!1,goog.a11y.aria.State.RELEVANT,
"additions text",goog.a11y.aria.State.REQUIRED,!1,goog.a11y.aria.State.SORT,"none",goog.a11y.aria.State.BUSY,!1,goog.a11y.aria.State.DISABLED,!1,goog.a11y.aria.State.HIDDEN,!1,goog.a11y.aria.State.INVALID,"false"));return goog.a11y.aria.DefaultStateValueMap_};goog.a11y.aria.ARIA_PREFIX_="aria-";goog.a11y.aria.ROLE_ATTRIBUTE_="role";goog.a11y.aria.TAGS_WITH_ASSUMED_ROLES_=goog.object.createSet("A AREA BUTTON HEAD INPUT LINK MENU META OPTGROUP OPTION PROGRESS STYLE SELECT SOURCE TEXTAREA TITLE TRACK".split(" "));
goog.a11y.aria.CONTAINER_ROLES_=[goog.a11y.aria.Role.COMBOBOX,goog.a11y.aria.Role.GRID,goog.a11y.aria.Role.GROUP,goog.a11y.aria.Role.LISTBOX,goog.a11y.aria.Role.MENU,goog.a11y.aria.Role.MENUBAR,goog.a11y.aria.Role.RADIOGROUP,goog.a11y.aria.Role.ROW,goog.a11y.aria.Role.ROWGROUP,goog.a11y.aria.Role.TAB_LIST,goog.a11y.aria.Role.TEXTBOX,goog.a11y.aria.Role.TOOLBAR,goog.a11y.aria.Role.TREE,goog.a11y.aria.Role.TREEGRID];
goog.a11y.aria.setRole=function(a,b){b?(goog.asserts.ENABLE_ASSERTS&&goog.asserts.assert(goog.object.containsValue(goog.a11y.aria.Role,b),"No such ARIA role "+b),a.setAttribute(goog.a11y.aria.ROLE_ATTRIBUTE_,b)):goog.a11y.aria.removeRole(a)};goog.a11y.aria.getRole=function(a){return a.getAttribute(goog.a11y.aria.ROLE_ATTRIBUTE_)||null};goog.a11y.aria.removeRole=function(a){a.removeAttribute(goog.a11y.aria.ROLE_ATTRIBUTE_)};
goog.a11y.aria.setState=function(a,b,c){goog.isArray(c)&&(c=c.join(" "));var d=goog.a11y.aria.getAriaAttributeName_(b);""===c||void 0==c?(c=goog.a11y.aria.datatables.getDefaultValuesMap(),b in c?a.setAttribute(d,c[b]):a.removeAttribute(d)):a.setAttribute(d,c)};goog.a11y.aria.toggleState=function(a,b){var c=goog.a11y.aria.getState(a,b);goog.string.isEmptyOrWhitespace(goog.string.makeSafe(c))||"true"==c||"false"==c?goog.a11y.aria.setState(a,b,"true"==c?"false":"true"):goog.a11y.aria.removeState(a,b)};
goog.a11y.aria.removeState=function(a,b){a.removeAttribute(goog.a11y.aria.getAriaAttributeName_(b))};goog.a11y.aria.getState=function(a,b){a=a.getAttribute(goog.a11y.aria.getAriaAttributeName_(b));return null==a||void 0==a?"":String(a)};goog.a11y.aria.getActiveDescendant=function(a){var b=goog.a11y.aria.getState(a,goog.a11y.aria.State.ACTIVEDESCENDANT);return goog.dom.getOwnerDocument(a).getElementById(b)};
goog.a11y.aria.setActiveDescendant=function(a,b){var c="";b&&(c=b.id,goog.asserts.assert(c,"The active element should have an id."));goog.a11y.aria.setState(a,goog.a11y.aria.State.ACTIVEDESCENDANT,c)};goog.a11y.aria.getLabel=function(a){return goog.a11y.aria.getState(a,goog.a11y.aria.State.LABEL)};goog.a11y.aria.setLabel=function(a,b){goog.a11y.aria.setState(a,goog.a11y.aria.State.LABEL,b)};
goog.a11y.aria.assertRoleIsSetInternalUtil=function(a,b){goog.a11y.aria.TAGS_WITH_ASSUMED_ROLES_[a.tagName]||(a=goog.a11y.aria.getRole(a),goog.asserts.assert(null!=a,"The element ARIA role cannot be null."),goog.asserts.assert(goog.array.contains(b,a),'Non existing or incorrect role set for element.The role set is "'+a+'". The role should be any of "'+b+'". Check the ARIA specification for more details http://www.w3.org/TR/wai-aria/roles.'))};
goog.a11y.aria.getStateBoolean=function(a,b){a=a.getAttribute(goog.a11y.aria.getAriaAttributeName_(b));goog.asserts.assert(goog.isBoolean(a)||null==a||"true"==a||"false"==a);return null==a?a:goog.isBoolean(a)?a:"true"==a};goog.a11y.aria.getStateNumber=function(a,b){a=a.getAttribute(goog.a11y.aria.getAriaAttributeName_(b));goog.asserts.assert((null==a||!isNaN(Number(a)))&&!goog.isBoolean(a));return null==a?null:Number(a)};
goog.a11y.aria.getStateString=function(a,b){a=a.getAttribute(goog.a11y.aria.getAriaAttributeName_(b));goog.asserts.assert((null==a||goog.isString(a))&&(""==a||isNaN(Number(a)))&&"true"!=a&&"false"!=a);return null==a||""==a?null:a};goog.a11y.aria.getStringArrayStateInternalUtil=function(a,b){a=a.getAttribute(goog.a11y.aria.getAriaAttributeName_(b));return goog.a11y.aria.splitStringOnWhitespace_(a)};goog.a11y.aria.hasState=function(a,b){return a.hasAttribute(goog.a11y.aria.getAriaAttributeName_(b))};
goog.a11y.aria.isContainerRole=function(a){a=goog.a11y.aria.getRole(a);return goog.array.contains(goog.a11y.aria.CONTAINER_ROLES_,a)};goog.a11y.aria.splitStringOnWhitespace_=function(a){return a?a.split(/\s+/):[]};goog.a11y.aria.getAriaAttributeName_=function(a){goog.asserts.ENABLE_ASSERTS&&(goog.asserts.assert(a,"ARIA attribute cannot be empty."),goog.asserts.assert(goog.object.containsValue(goog.a11y.aria.State,a),"No such ARIA attribute "+a));return goog.a11y.aria.ARIA_PREFIX_+a};goog.events.KeyCodes={WIN_KEY_FF_LINUX:0,MAC_ENTER:3,BACKSPACE:8,TAB:9,NUM_CENTER:12,ENTER:13,SHIFT:16,CTRL:17,ALT:18,PAUSE:19,CAPS_LOCK:20,ESC:27,SPACE:32,PAGE_UP:33,PAGE_DOWN:34,END:35,HOME:36,LEFT:37,UP:38,RIGHT:39,DOWN:40,PLUS_SIGN:43,PRINT_SCREEN:44,INSERT:45,DELETE:46,ZERO:48,ONE:49,TWO:50,THREE:51,FOUR:52,FIVE:53,SIX:54,SEVEN:55,EIGHT:56,NINE:57,FF_SEMICOLON:59,FF_EQUALS:61,FF_DASH:173,FF_HASH:163,QUESTION_MARK:63,AT_SIGN:64,A:65,B:66,C:67,D:68,E:69,F:70,G:71,H:72,I:73,J:74,K:75,L:76,M:77,
N:78,O:79,P:80,Q:81,R:82,S:83,T:84,U:85,V:86,W:87,X:88,Y:89,Z:90,META:91,WIN_KEY_RIGHT:92,CONTEXT_MENU:93,NUM_ZERO:96,NUM_ONE:97,NUM_TWO:98,NUM_THREE:99,NUM_FOUR:100,NUM_FIVE:101,NUM_SIX:102,NUM_SEVEN:103,NUM_EIGHT:104,NUM_NINE:105,NUM_MULTIPLY:106,NUM_PLUS:107,NUM_MINUS:109,NUM_PERIOD:110,NUM_DIVISION:111,F1:112,F2:113,F3:114,F4:115,F5:116,F6:117,F7:118,F8:119,F9:120,F10:121,F11:122,F12:123,NUMLOCK:144,SCROLL_LOCK:145,FIRST_MEDIA_KEY:166,LAST_MEDIA_KEY:183,SEMICOLON:186,DASH:189,EQUALS:187,COMMA:188,
PERIOD:190,SLASH:191,APOSTROPHE:192,TILDE:192,SINGLE_QUOTE:222,OPEN_SQUARE_BRACKET:219,BACKSLASH:220,CLOSE_SQUARE_BRACKET:221,WIN_KEY:224,MAC_FF_META:224,MAC_WK_CMD_LEFT:91,MAC_WK_CMD_RIGHT:93,WIN_IME:229,VK_NONAME:252,PHANTOM:255};
goog.events.KeyCodes.isTextModifyingKeyEvent=function(a){if(a.altKey&&!a.ctrlKey||a.metaKey||a.keyCode>=goog.events.KeyCodes.F1&&a.keyCode<=goog.events.KeyCodes.F12)return!1;switch(a.keyCode){case goog.events.KeyCodes.ALT:case goog.events.KeyCodes.CAPS_LOCK:case goog.events.KeyCodes.CONTEXT_MENU:case goog.events.KeyCodes.CTRL:case goog.events.KeyCodes.DOWN:case goog.events.KeyCodes.END:case goog.events.KeyCodes.ESC:case goog.events.KeyCodes.HOME:case goog.events.KeyCodes.INSERT:case goog.events.KeyCodes.LEFT:case goog.events.KeyCodes.MAC_FF_META:case goog.events.KeyCodes.META:case goog.events.KeyCodes.NUMLOCK:case goog.events.KeyCodes.NUM_CENTER:case goog.events.KeyCodes.PAGE_DOWN:case goog.events.KeyCodes.PAGE_UP:case goog.events.KeyCodes.PAUSE:case goog.events.KeyCodes.PHANTOM:case goog.events.KeyCodes.PRINT_SCREEN:case goog.events.KeyCodes.RIGHT:case goog.events.KeyCodes.SCROLL_LOCK:case goog.events.KeyCodes.SHIFT:case goog.events.KeyCodes.UP:case goog.events.KeyCodes.VK_NONAME:case goog.events.KeyCodes.WIN_KEY:case goog.events.KeyCodes.WIN_KEY_RIGHT:return!1;case goog.events.KeyCodes.WIN_KEY_FF_LINUX:return!goog.userAgent.GECKO;
default:return a.keyCode<goog.events.KeyCodes.FIRST_MEDIA_KEY||a.keyCode>goog.events.KeyCodes.LAST_MEDIA_KEY}};
goog.events.KeyCodes.firesKeyPressEvent=function(a,b,c,d,e,f){if(goog.userAgent.WEBKIT&&!goog.userAgent.isVersionOrHigher("525"))return!0;if(goog.userAgent.MAC&&e)return goog.events.KeyCodes.isCharacterKey(a);if(e&&!d)return!1;if(!goog.userAgent.GECKO){goog.isNumber(b)&&(b=goog.events.KeyCodes.normalizeKeyCode(b));var g=b==goog.events.KeyCodes.CTRL||b==goog.events.KeyCodes.ALT||goog.userAgent.MAC&&b==goog.events.KeyCodes.META,h=b==goog.events.KeyCodes.SHIFT&&(d||f);if((!c||goog.userAgent.MAC)&&g||
goog.userAgent.MAC&&h)return!1}if((goog.userAgent.WEBKIT||goog.userAgent.EDGE)&&d&&c)switch(a){case goog.events.KeyCodes.BACKSLASH:case goog.events.KeyCodes.OPEN_SQUARE_BRACKET:case goog.events.KeyCodes.CLOSE_SQUARE_BRACKET:case goog.events.KeyCodes.TILDE:case goog.events.KeyCodes.SEMICOLON:case goog.events.KeyCodes.DASH:case goog.events.KeyCodes.EQUALS:case goog.events.KeyCodes.COMMA:case goog.events.KeyCodes.PERIOD:case goog.events.KeyCodes.SLASH:case goog.events.KeyCodes.APOSTROPHE:case goog.events.KeyCodes.SINGLE_QUOTE:return!1}if(goog.userAgent.IE&&
d&&b==a)return!1;switch(a){case goog.events.KeyCodes.ENTER:return goog.userAgent.GECKO?f||e?!1:!(c&&d):!0;case goog.events.KeyCodes.ESC:return!(goog.userAgent.WEBKIT||goog.userAgent.EDGE||goog.userAgent.GECKO)}return goog.userAgent.GECKO&&(d||e||f)?!1:goog.events.KeyCodes.isCharacterKey(a)};
goog.events.KeyCodes.isCharacterKey=function(a){if(a>=goog.events.KeyCodes.ZERO&&a<=goog.events.KeyCodes.NINE||a>=goog.events.KeyCodes.NUM_ZERO&&a<=goog.events.KeyCodes.NUM_MULTIPLY||a>=goog.events.KeyCodes.A&&a<=goog.events.KeyCodes.Z||(goog.userAgent.WEBKIT||goog.userAgent.EDGE)&&0==a)return!0;switch(a){case goog.events.KeyCodes.SPACE:case goog.events.KeyCodes.PLUS_SIGN:case goog.events.KeyCodes.QUESTION_MARK:case goog.events.KeyCodes.AT_SIGN:case goog.events.KeyCodes.NUM_PLUS:case goog.events.KeyCodes.NUM_MINUS:case goog.events.KeyCodes.NUM_PERIOD:case goog.events.KeyCodes.NUM_DIVISION:case goog.events.KeyCodes.SEMICOLON:case goog.events.KeyCodes.FF_SEMICOLON:case goog.events.KeyCodes.DASH:case goog.events.KeyCodes.EQUALS:case goog.events.KeyCodes.FF_EQUALS:case goog.events.KeyCodes.COMMA:case goog.events.KeyCodes.PERIOD:case goog.events.KeyCodes.SLASH:case goog.events.KeyCodes.APOSTROPHE:case goog.events.KeyCodes.SINGLE_QUOTE:case goog.events.KeyCodes.OPEN_SQUARE_BRACKET:case goog.events.KeyCodes.BACKSLASH:case goog.events.KeyCodes.CLOSE_SQUARE_BRACKET:case goog.events.KeyCodes.FF_HASH:return!0;
default:return!1}};goog.events.KeyCodes.normalizeKeyCode=function(a){return goog.userAgent.GECKO?goog.events.KeyCodes.normalizeGeckoKeyCode(a):goog.userAgent.MAC&&goog.userAgent.WEBKIT?goog.events.KeyCodes.normalizeMacWebKitKeyCode(a):a};
goog.events.KeyCodes.normalizeGeckoKeyCode=function(a){switch(a){case goog.events.KeyCodes.FF_EQUALS:return goog.events.KeyCodes.EQUALS;case goog.events.KeyCodes.FF_SEMICOLON:return goog.events.KeyCodes.SEMICOLON;case goog.events.KeyCodes.FF_DASH:return goog.events.KeyCodes.DASH;case goog.events.KeyCodes.MAC_FF_META:return goog.events.KeyCodes.META;case goog.events.KeyCodes.WIN_KEY_FF_LINUX:return goog.events.KeyCodes.WIN_KEY;default:return a}};
goog.events.KeyCodes.normalizeMacWebKitKeyCode=function(a){switch(a){case goog.events.KeyCodes.MAC_WK_CMD_RIGHT:return goog.events.KeyCodes.META;default:return a}};goog.events.KeyHandler=function(a,b){goog.events.EventTarget.call(this);a&&this.attach(a,b)};goog.inherits(goog.events.KeyHandler,goog.events.EventTarget);goog.events.KeyHandler.prototype.element_=null;goog.events.KeyHandler.prototype.keyPressKey_=null;goog.events.KeyHandler.prototype.keyDownKey_=null;goog.events.KeyHandler.prototype.keyUpKey_=null;goog.events.KeyHandler.prototype.lastKey_=-1;goog.events.KeyHandler.prototype.keyCode_=-1;goog.events.KeyHandler.prototype.altKey_=!1;
goog.events.KeyHandler.EventType={KEY:"key"};
goog.events.KeyHandler.safariKey_={3:goog.events.KeyCodes.ENTER,12:goog.events.KeyCodes.NUMLOCK,63232:goog.events.KeyCodes.UP,63233:goog.events.KeyCodes.DOWN,63234:goog.events.KeyCodes.LEFT,63235:goog.events.KeyCodes.RIGHT,63236:goog.events.KeyCodes.F1,63237:goog.events.KeyCodes.F2,63238:goog.events.KeyCodes.F3,63239:goog.events.KeyCodes.F4,63240:goog.events.KeyCodes.F5,63241:goog.events.KeyCodes.F6,63242:goog.events.KeyCodes.F7,63243:goog.events.KeyCodes.F8,63244:goog.events.KeyCodes.F9,63245:goog.events.KeyCodes.F10,
63246:goog.events.KeyCodes.F11,63247:goog.events.KeyCodes.F12,63248:goog.events.KeyCodes.PRINT_SCREEN,63272:goog.events.KeyCodes.DELETE,63273:goog.events.KeyCodes.HOME,63275:goog.events.KeyCodes.END,63276:goog.events.KeyCodes.PAGE_UP,63277:goog.events.KeyCodes.PAGE_DOWN,63289:goog.events.KeyCodes.NUMLOCK,63302:goog.events.KeyCodes.INSERT};
goog.events.KeyHandler.keyIdentifier_={Up:goog.events.KeyCodes.UP,Down:goog.events.KeyCodes.DOWN,Left:goog.events.KeyCodes.LEFT,Right:goog.events.KeyCodes.RIGHT,Enter:goog.events.KeyCodes.ENTER,F1:goog.events.KeyCodes.F1,F2:goog.events.KeyCodes.F2,F3:goog.events.KeyCodes.F3,F4:goog.events.KeyCodes.F4,F5:goog.events.KeyCodes.F5,F6:goog.events.KeyCodes.F6,F7:goog.events.KeyCodes.F7,F8:goog.events.KeyCodes.F8,F9:goog.events.KeyCodes.F9,F10:goog.events.KeyCodes.F10,F11:goog.events.KeyCodes.F11,F12:goog.events.KeyCodes.F12,
"U+007F":goog.events.KeyCodes.DELETE,Home:goog.events.KeyCodes.HOME,End:goog.events.KeyCodes.END,PageUp:goog.events.KeyCodes.PAGE_UP,PageDown:goog.events.KeyCodes.PAGE_DOWN,Insert:goog.events.KeyCodes.INSERT};goog.events.KeyHandler.USES_KEYDOWN_=!goog.userAgent.WEBKIT||goog.userAgent.isVersionOrHigher("525");goog.events.KeyHandler.SAVE_ALT_FOR_KEYPRESS_=goog.userAgent.MAC&&goog.userAgent.GECKO;
goog.events.KeyHandler.prototype.handleKeyDown_=function(a){(goog.userAgent.WEBKIT||goog.userAgent.EDGE)&&(this.lastKey_==goog.events.KeyCodes.CTRL&&!a.ctrlKey||this.lastKey_==goog.events.KeyCodes.ALT&&!a.altKey||goog.userAgent.MAC&&this.lastKey_==goog.events.KeyCodes.META&&!a.metaKey)&&this.resetState();-1==this.lastKey_&&(a.ctrlKey&&a.keyCode!=goog.events.KeyCodes.CTRL?this.lastKey_=goog.events.KeyCodes.CTRL:a.altKey&&a.keyCode!=goog.events.KeyCodes.ALT?this.lastKey_=goog.events.KeyCodes.ALT:a.metaKey&&
a.keyCode!=goog.events.KeyCodes.META&&(this.lastKey_=goog.events.KeyCodes.META));goog.events.KeyHandler.USES_KEYDOWN_&&!goog.events.KeyCodes.firesKeyPressEvent(a.keyCode,this.lastKey_,a.shiftKey,a.ctrlKey,a.altKey,a.metaKey)?this.handleEvent(a):(this.keyCode_=goog.events.KeyCodes.normalizeKeyCode(a.keyCode),goog.events.KeyHandler.SAVE_ALT_FOR_KEYPRESS_&&(this.altKey_=a.altKey))};goog.events.KeyHandler.prototype.resetState=function(){this.keyCode_=this.lastKey_=-1};
goog.events.KeyHandler.prototype.handleKeyup_=function(a){this.resetState();this.altKey_=a.altKey};
goog.events.KeyHandler.prototype.handleEvent=function(a){var b=a.getBrowserEvent(),c=b.altKey;if(goog.userAgent.IE&&a.type==goog.events.EventType.KEYPRESS){var d=this.keyCode_;var e=d!=goog.events.KeyCodes.ENTER&&d!=goog.events.KeyCodes.ESC?b.keyCode:0}else(goog.userAgent.WEBKIT||goog.userAgent.EDGE)&&a.type==goog.events.EventType.KEYPRESS?(d=this.keyCode_,e=0<=b.charCode&&63232>b.charCode&&goog.events.KeyCodes.isCharacterKey(d)?b.charCode:0):goog.userAgent.OPERA&&!goog.userAgent.WEBKIT?(d=this.keyCode_,
e=goog.events.KeyCodes.isCharacterKey(d)?b.keyCode:0):(a.type==goog.events.EventType.KEYPRESS?(goog.events.KeyHandler.SAVE_ALT_FOR_KEYPRESS_&&(c=this.altKey_),b.keyCode==b.charCode?32>b.keyCode?(d=b.keyCode,e=0):(d=this.keyCode_,e=b.charCode):(d=b.keyCode||this.keyCode_,e=b.charCode||0)):(d=b.keyCode||this.keyCode_,e=b.charCode||0),goog.userAgent.MAC&&e==goog.events.KeyCodes.QUESTION_MARK&&d==goog.events.KeyCodes.WIN_KEY&&(d=goog.events.KeyCodes.SLASH));var f=d=goog.events.KeyCodes.normalizeKeyCode(d);
d?63232<=d&&d in goog.events.KeyHandler.safariKey_?f=goog.events.KeyHandler.safariKey_[d]:25==d&&a.shiftKey&&(f=9):b.keyIdentifier&&b.keyIdentifier in goog.events.KeyHandler.keyIdentifier_&&(f=goog.events.KeyHandler.keyIdentifier_[b.keyIdentifier]);goog.userAgent.GECKO&&goog.events.KeyHandler.USES_KEYDOWN_&&a.type==goog.events.EventType.KEYPRESS&&!goog.events.KeyCodes.firesKeyPressEvent(f,this.lastKey_,a.shiftKey,a.ctrlKey,c,a.metaKey)||(a=f==this.lastKey_,this.lastKey_=f,b=new goog.events.KeyEvent(f,
e,a,b),b.altKey=c,this.dispatchEvent(b))};goog.events.KeyHandler.prototype.getElement=function(){return this.element_};
goog.events.KeyHandler.prototype.attach=function(a,b){this.keyUpKey_&&this.detach();this.element_=a;this.keyPressKey_=goog.events.listen(this.element_,goog.events.EventType.KEYPRESS,this,b);this.keyDownKey_=goog.events.listen(this.element_,goog.events.EventType.KEYDOWN,this.handleKeyDown_,b,this);this.keyUpKey_=goog.events.listen(this.element_,goog.events.EventType.KEYUP,this.handleKeyup_,b,this)};
goog.events.KeyHandler.prototype.detach=function(){this.keyPressKey_&&(goog.events.unlistenByKey(this.keyPressKey_),goog.events.unlistenByKey(this.keyDownKey_),goog.events.unlistenByKey(this.keyUpKey_),this.keyUpKey_=this.keyDownKey_=this.keyPressKey_=null);this.element_=null;this.keyCode_=this.lastKey_=-1};goog.events.KeyHandler.prototype.disposeInternal=function(){goog.events.KeyHandler.superClass_.disposeInternal.call(this);this.detach()};
goog.events.KeyEvent=function(a,b,c,d){goog.events.BrowserEvent.call(this,d);this.type=goog.events.KeyHandler.EventType.KEY;this.keyCode=a;this.charCode=b;this.repeat=c};goog.inherits(goog.events.KeyEvent,goog.events.BrowserEvent);goog.ui.ComponentUtil={};goog.ui.ComponentUtil.getMouseEventType=function(a){return a.pointerEventsEnabled()?goog.events.PointerAsMouseEventType:goog.events.MouseAsMouseEventType};goog.dom.classlist={};goog.dom.classlist.ALWAYS_USE_DOM_TOKEN_LIST=!1;goog.dom.classlist.get=function(a){if(goog.dom.classlist.ALWAYS_USE_DOM_TOKEN_LIST||a.classList)return a.classList;a=a.className;return goog.isString(a)&&a.match(/\S+/g)||[]};goog.dom.classlist.set=function(a,b){a.className=b};goog.dom.classlist.contains=function(a,b){return goog.dom.classlist.ALWAYS_USE_DOM_TOKEN_LIST||a.classList?a.classList.contains(b):goog.array.contains(goog.dom.classlist.get(a),b)};
goog.dom.classlist.add=function(a,b){goog.dom.classlist.ALWAYS_USE_DOM_TOKEN_LIST||a.classList?a.classList.add(b):goog.dom.classlist.contains(a,b)||(a.className+=0<a.className.length?" "+b:b)};
goog.dom.classlist.addAll=function(a,b){if(goog.dom.classlist.ALWAYS_USE_DOM_TOKEN_LIST||a.classList)goog.array.forEach(b,function(b){goog.dom.classlist.add(a,b)});else{var c={};goog.array.forEach(goog.dom.classlist.get(a),function(a){c[a]=!0});goog.array.forEach(b,function(a){c[a]=!0});a.className="";for(var d in c)a.className+=0<a.className.length?" "+d:d}};
goog.dom.classlist.remove=function(a,b){goog.dom.classlist.ALWAYS_USE_DOM_TOKEN_LIST||a.classList?a.classList.remove(b):goog.dom.classlist.contains(a,b)&&(a.className=goog.array.filter(goog.dom.classlist.get(a),function(a){return a!=b}).join(" "))};
goog.dom.classlist.removeAll=function(a,b){goog.dom.classlist.ALWAYS_USE_DOM_TOKEN_LIST||a.classList?goog.array.forEach(b,function(b){goog.dom.classlist.remove(a,b)}):a.className=goog.array.filter(goog.dom.classlist.get(a),function(a){return!goog.array.contains(b,a)}).join(" ")};goog.dom.classlist.enable=function(a,b,c){c?goog.dom.classlist.add(a,b):goog.dom.classlist.remove(a,b)};goog.dom.classlist.enableAll=function(a,b,c){(c?goog.dom.classlist.addAll:goog.dom.classlist.removeAll)(a,b)};
goog.dom.classlist.swap=function(a,b,c){return goog.dom.classlist.contains(a,b)?(goog.dom.classlist.remove(a,b),goog.dom.classlist.add(a,c),!0):!1};goog.dom.classlist.toggle=function(a,b){var c=!goog.dom.classlist.contains(a,b);goog.dom.classlist.enable(a,b,c);return c};goog.dom.classlist.addRemove=function(a,b,c){goog.dom.classlist.remove(a,b);goog.dom.classlist.add(a,c)};goog.ui.registry={};goog.ui.registry.getDefaultRenderer=function(a){for(var b;a;){b=goog.getUid(a);if(b=goog.ui.registry.defaultRenderers_[b])break;a=a.superClass_?a.superClass_.constructor:null}return b?goog.isFunction(b.getInstance)?b.getInstance():new b:null};goog.ui.registry.setDefaultRenderer=function(a,b){if(!goog.isFunction(a))throw Error("Invalid component class "+a);if(!goog.isFunction(b))throw Error("Invalid renderer class "+b);a=goog.getUid(a);goog.ui.registry.defaultRenderers_[a]=b};
goog.ui.registry.getDecoratorByClassName=function(a){return a in goog.ui.registry.decoratorFunctions_?goog.ui.registry.decoratorFunctions_[a]():null};goog.ui.registry.setDecoratorByClassName=function(a,b){if(!a)throw Error("Invalid class name "+a);if(!goog.isFunction(b))throw Error("Invalid decorator function "+b);goog.ui.registry.decoratorFunctions_[a]=b};
goog.ui.registry.getDecorator=function(a){goog.asserts.assert(a);for(var b=goog.dom.classlist.get(a),c=0,d=b.length;c<d;c++)if(a=goog.ui.registry.getDecoratorByClassName(b[c]))return a;return null};goog.ui.registry.reset=function(){goog.ui.registry.defaultRenderers_={};goog.ui.registry.decoratorFunctions_={}};goog.ui.registry.defaultRenderers_={};goog.ui.registry.decoratorFunctions_={};goog.ui.ContainerRenderer=function(a){this.ariaRole_=a};goog.addSingletonGetter(goog.ui.ContainerRenderer);goog.ui.ContainerRenderer.getCustomRenderer=function(a,b){a=new a;a.getCssClass=function(){return b};return a};goog.ui.ContainerRenderer.CSS_CLASS="goog-container";goog.ui.ContainerRenderer.prototype.getAriaRole=function(){return this.ariaRole_};goog.ui.ContainerRenderer.prototype.enableTabIndex=function(a,b){a&&(a.tabIndex=b?0:-1)};
goog.ui.ContainerRenderer.prototype.createDom=function(a){return a.getDomHelper().createDom("DIV",this.getClassNames(a).join(" "))};goog.ui.ContainerRenderer.prototype.getContentElement=function(a){return a};goog.ui.ContainerRenderer.prototype.canDecorate=function(a){return"DIV"==a.tagName};
goog.ui.ContainerRenderer.prototype.decorate=function(a,b){b.id&&a.setId(b.id);var c=this.getCssClass(),d=!1,e=goog.dom.classlist.get(b);e&&goog.array.forEach(e,function(b){b==c?d=!0:b&&this.setStateFromClassName(a,b,c)},this);d||goog.dom.classlist.add(b,c);this.decorateChildren(a,this.getContentElement(b));return b};
goog.ui.ContainerRenderer.prototype.setStateFromClassName=function(a,b,c){b==c+"-disabled"?a.setEnabled(!1):b==c+"-horizontal"?a.setOrientation(goog.ui.Container.Orientation.HORIZONTAL):b==c+"-vertical"&&a.setOrientation(goog.ui.Container.Orientation.VERTICAL)};
goog.ui.ContainerRenderer.prototype.decorateChildren=function(a,b,c){if(b){c=c||b.firstChild;for(var d;c&&c.parentNode==b;){d=c.nextSibling;if(c.nodeType==goog.dom.NodeType.ELEMENT){var e=this.getDecoratorForChild(c);e&&(e.setElementInternal(c),a.isEnabled()||e.setEnabled(!1),a.addChild(e),e.decorate(c))}else c.nodeValue&&""!=goog.string.trim(c.nodeValue)||b.removeChild(c);c=d}}};goog.ui.ContainerRenderer.prototype.getDecoratorForChild=function(a){return goog.ui.registry.getDecorator(a)};
goog.ui.ContainerRenderer.prototype.initializeDom=function(a){a=a.getElement();goog.asserts.assert(a,"The container DOM element cannot be null.");goog.style.setUnselectable(a,!0,goog.userAgent.GECKO);goog.userAgent.IE&&(a.hideFocus=!0);var b=this.getAriaRole();b&&goog.a11y.aria.setRole(a,b)};goog.ui.ContainerRenderer.prototype.getKeyEventTarget=function(a){return a.getElement()};goog.ui.ContainerRenderer.prototype.getCssClass=function(){return goog.ui.ContainerRenderer.CSS_CLASS};
goog.ui.ContainerRenderer.prototype.getClassNames=function(a){var b=this.getCssClass(),c=a.getOrientation()==goog.ui.Container.Orientation.HORIZONTAL;c=[b,c?b+"-horizontal":b+"-vertical"];a.isEnabled()||c.push(b+"-disabled");return c};goog.ui.ContainerRenderer.prototype.getDefaultOrientation=function(){return goog.ui.Container.Orientation.VERTICAL};goog.ui.ControlRenderer=function(){};goog.addSingletonGetter(goog.ui.ControlRenderer);goog.tagUnsealableClass(goog.ui.ControlRenderer);goog.ui.ControlRenderer.getCustomRenderer=function(a,b){a=new a;a.getCssClass=function(){return b};return a};goog.ui.ControlRenderer.CSS_CLASS="goog-control";goog.ui.ControlRenderer.IE6_CLASS_COMBINATIONS=[];
goog.ui.ControlRenderer.TOGGLE_ARIA_STATE_MAP_=goog.object.create(goog.a11y.aria.Role.BUTTON,goog.a11y.aria.State.PRESSED,goog.a11y.aria.Role.CHECKBOX,goog.a11y.aria.State.CHECKED,goog.a11y.aria.Role.MENU_ITEM,goog.a11y.aria.State.SELECTED,goog.a11y.aria.Role.MENU_ITEM_CHECKBOX,goog.a11y.aria.State.CHECKED,goog.a11y.aria.Role.MENU_ITEM_RADIO,goog.a11y.aria.State.CHECKED,goog.a11y.aria.Role.RADIO,goog.a11y.aria.State.CHECKED,goog.a11y.aria.Role.TAB,goog.a11y.aria.State.SELECTED,goog.a11y.aria.Role.TREEITEM,
goog.a11y.aria.State.SELECTED);goog.ui.ControlRenderer.prototype.getAriaRole=function(){};goog.ui.ControlRenderer.prototype.createDom=function(a){return a.getDomHelper().createDom("DIV",this.getClassNames(a).join(" "),a.getContent())};goog.ui.ControlRenderer.prototype.getContentElement=function(a){return a};
goog.ui.ControlRenderer.prototype.enableClassName=function(a,b,c){if(a=a.getElement?a.getElement():a){var d=[b];goog.userAgent.IE&&!goog.userAgent.isVersionOrHigher("7")&&(d=this.getAppliedCombinedClassNames_(goog.dom.classlist.get(a),b),d.push(b));goog.dom.classlist.enableAll(a,d,c)}};goog.ui.ControlRenderer.prototype.enableExtraClassName=function(a,b,c){this.enableClassName(a,b,c)};goog.ui.ControlRenderer.prototype.canDecorate=function(a){return!0};
goog.ui.ControlRenderer.prototype.decorate=function(a,b){b.id&&a.setId(b.id);var c=this.getContentElement(b);c&&c.firstChild?a.setContentInternal(c.firstChild.nextSibling?goog.array.clone(c.childNodes):c.firstChild):a.setContentInternal(null);var d=0,e=this.getCssClass(),f=this.getStructuralCssClass(),g=!1,h=!1,k=!1,l=goog.array.toArray(goog.dom.classlist.get(b));goog.array.forEach(l,function(a){g||a!=e?h||a!=f?d|=this.getStateFromClass(a):h=!0:(g=!0,f==e&&(h=!0));this.getStateFromClass(a)==goog.ui.Component.State.DISABLED&&
(goog.asserts.assertElement(c),goog.dom.isFocusableTabIndex(c)&&goog.dom.setFocusableTabIndex(c,!1))},this);a.setStateInternal(d);g||(l.push(e),f==e&&(h=!0));h||l.push(f);(a=a.getExtraClassNames())&&l.push.apply(l,a);if(goog.userAgent.IE&&!goog.userAgent.isVersionOrHigher("7")){var m=this.getAppliedCombinedClassNames_(l);0<m.length&&(l.push.apply(l,m),k=!0)}g&&h&&!a&&!k||goog.dom.classlist.set(b,l.join(" "));return b};
goog.ui.ControlRenderer.prototype.initializeDom=function(a){a.isRightToLeft()&&this.setRightToLeft(a.getElement(),!0);a.isEnabled()&&this.setFocusable(a,a.isVisible())};goog.ui.ControlRenderer.prototype.setAriaRole=function(a,b){if(b=b||this.getAriaRole()){goog.asserts.assert(a,"The element passed as a first parameter cannot be null.");var c=goog.a11y.aria.getRole(a);b!=c&&goog.a11y.aria.setRole(a,b)}};
goog.ui.ControlRenderer.prototype.setAriaStates=function(a,b){goog.asserts.assert(a);goog.asserts.assert(b);var c=a.getAriaLabel();goog.isDefAndNotNull(c)&&this.setAriaLabel(b,c);a.isVisible()||goog.a11y.aria.setState(b,goog.a11y.aria.State.HIDDEN,!a.isVisible());a.isEnabled()||this.updateAriaState(b,goog.ui.Component.State.DISABLED,!a.isEnabled());a.isSupportedState(goog.ui.Component.State.SELECTED)&&this.updateAriaState(b,goog.ui.Component.State.SELECTED,a.isSelected());a.isSupportedState(goog.ui.Component.State.CHECKED)&&
this.updateAriaState(b,goog.ui.Component.State.CHECKED,a.isChecked());a.isSupportedState(goog.ui.Component.State.OPENED)&&this.updateAriaState(b,goog.ui.Component.State.OPENED,a.isOpen())};goog.ui.ControlRenderer.prototype.setAriaLabel=function(a,b){goog.a11y.aria.setLabel(a,b)};goog.ui.ControlRenderer.prototype.setAllowTextSelection=function(a,b){goog.style.setUnselectable(a,!b,!goog.userAgent.IE&&!goog.userAgent.OPERA)};
goog.ui.ControlRenderer.prototype.setRightToLeft=function(a,b){this.enableClassName(a,this.getStructuralCssClass()+"-rtl",b)};goog.ui.ControlRenderer.prototype.isFocusable=function(a){var b;return a.isSupportedState(goog.ui.Component.State.FOCUSED)&&(b=a.getKeyEventTarget())?goog.dom.isFocusableTabIndex(b):!1};
goog.ui.ControlRenderer.prototype.setFocusable=function(a,b){var c;if(a.isSupportedState(goog.ui.Component.State.FOCUSED)&&(c=a.getKeyEventTarget())){if(!b&&a.isFocused()){try{c.blur()}catch(d){}a.isFocused()&&a.handleBlur(null)}goog.dom.isFocusableTabIndex(c)!=b&&goog.dom.setFocusableTabIndex(c,b)}};goog.ui.ControlRenderer.prototype.setVisible=function(a,b){goog.style.setElementShown(a,b);a&&goog.a11y.aria.setState(a,goog.a11y.aria.State.HIDDEN,!b)};
goog.ui.ControlRenderer.prototype.setState=function(a,b,c){var d=a.getElement();if(d){var e=this.getClassForState(b);e&&this.enableClassName(a,e,c);this.updateAriaState(d,b,c)}};
goog.ui.ControlRenderer.prototype.updateAriaState=function(a,b,c){goog.ui.ControlRenderer.ariaAttributeMap_||(goog.ui.ControlRenderer.ariaAttributeMap_=goog.object.create(goog.ui.Component.State.DISABLED,goog.a11y.aria.State.DISABLED,goog.ui.Component.State.SELECTED,goog.a11y.aria.State.SELECTED,goog.ui.Component.State.CHECKED,goog.a11y.aria.State.CHECKED,goog.ui.Component.State.OPENED,goog.a11y.aria.State.EXPANDED));goog.asserts.assert(a,"The element passed as a first parameter cannot be null.");
(b=goog.ui.ControlRenderer.getAriaStateForAriaRole_(a,goog.ui.ControlRenderer.ariaAttributeMap_[b]))&&goog.a11y.aria.setState(a,b,c)};goog.ui.ControlRenderer.getAriaStateForAriaRole_=function(a,b){a=goog.a11y.aria.getRole(a);if(!a)return b;a=goog.ui.ControlRenderer.TOGGLE_ARIA_STATE_MAP_[a]||b;return goog.ui.ControlRenderer.isAriaState_(b)?a:b};goog.ui.ControlRenderer.isAriaState_=function(a){return a==goog.a11y.aria.State.CHECKED||a==goog.a11y.aria.State.SELECTED};
goog.ui.ControlRenderer.prototype.setContent=function(a,b){var c=this.getContentElement(a);c&&(goog.dom.removeChildren(c),b&&(goog.isString(b)?goog.dom.setTextContent(c,b):(a=function(a){if(a){var b=goog.dom.getOwnerDocument(c);c.appendChild(goog.isString(a)?b.createTextNode(a):a)}},goog.isArray(b)?goog.array.forEach(b,a):!goog.isArrayLike(b)||"nodeType"in b?a(b):goog.array.forEach(goog.array.clone(b),a))))};goog.ui.ControlRenderer.prototype.getKeyEventTarget=function(a){return a.getElement()};
goog.ui.ControlRenderer.prototype.getCssClass=function(){return goog.ui.ControlRenderer.CSS_CLASS};goog.ui.ControlRenderer.prototype.getIe6ClassCombinations=function(){return[]};goog.ui.ControlRenderer.prototype.getStructuralCssClass=function(){return this.getCssClass()};
goog.ui.ControlRenderer.prototype.getClassNames=function(a){var b=this.getCssClass(),c=[b],d=this.getStructuralCssClass();d!=b&&c.push(d);b=this.getClassNamesForState(a.getState());c.push.apply(c,b);(a=a.getExtraClassNames())&&c.push.apply(c,a);goog.userAgent.IE&&!goog.userAgent.isVersionOrHigher("7")&&c.push.apply(c,this.getAppliedCombinedClassNames_(c));return c};
goog.ui.ControlRenderer.prototype.getAppliedCombinedClassNames_=function(a,b){var c=[];b&&(a=goog.array.concat(a,[b]));goog.array.forEach(this.getIe6ClassCombinations(),function(d){!goog.array.every(d,goog.partial(goog.array.contains,a))||b&&!goog.array.contains(d,b)||c.push(d.join("_"))});return c};goog.ui.ControlRenderer.prototype.getClassNamesForState=function(a){for(var b=[];a;){var c=a&-a;b.push(this.getClassForState(c));a&=~c}return b};
goog.ui.ControlRenderer.prototype.getClassForState=function(a){this.classByState_||this.createClassByStateMap_();return this.classByState_[a]};goog.ui.ControlRenderer.prototype.getStateFromClass=function(a){this.stateByClass_||this.createStateByClassMap_();a=parseInt(this.stateByClass_[a],10);return isNaN(a)?0:a};
goog.ui.ControlRenderer.prototype.createClassByStateMap_=function(){var a=this.getStructuralCssClass(),b=!goog.string.contains(goog.string.normalizeWhitespace(a)," ");goog.asserts.assert(b,"ControlRenderer has an invalid css class: '"+a+"'");this.classByState_=goog.object.create(goog.ui.Component.State.DISABLED,a+"-disabled",goog.ui.Component.State.HOVER,a+"-hover",goog.ui.Component.State.ACTIVE,a+"-active",goog.ui.Component.State.SELECTED,a+"-selected",goog.ui.Component.State.CHECKED,a+"-checked",
goog.ui.Component.State.FOCUSED,a+"-focused",goog.ui.Component.State.OPENED,a+"-open")};goog.ui.ControlRenderer.prototype.createStateByClassMap_=function(){this.classByState_||this.createClassByStateMap_();this.stateByClass_=goog.object.transpose(this.classByState_)};goog.ui.Control=function(a,b,c){goog.ui.Component.call(this,c);this.renderer_=b||goog.ui.registry.getDefaultRenderer(this.constructor);this.setContentInternal(goog.isDef(a)?a:null);this.ariaLabel_=null};goog.inherits(goog.ui.Control,goog.ui.Component);goog.tagUnsealableClass(goog.ui.Control);goog.ui.Control.registerDecorator=goog.ui.registry.setDecoratorByClassName;goog.ui.Control.getDecorator=goog.ui.registry.getDecorator;goog.ui.Control.prototype.content_=null;goog.ui.Control.prototype.state_=0;
goog.ui.Control.prototype.supportedStates_=goog.ui.Component.State.DISABLED|goog.ui.Component.State.HOVER|goog.ui.Component.State.ACTIVE|goog.ui.Component.State.FOCUSED;goog.ui.Control.prototype.autoStates_=goog.ui.Component.State.ALL;goog.ui.Control.prototype.statesWithTransitionEvents_=0;goog.ui.Control.prototype.visible_=!0;goog.ui.Control.prototype.extraClassNames_=null;goog.ui.Control.prototype.handleMouseEvents_=!0;goog.ui.Control.prototype.allowTextSelection_=!1;
goog.ui.Control.prototype.preferredAriaRole_=null;goog.ui.Control.prototype.isHandleMouseEvents=function(){return this.handleMouseEvents_};goog.ui.Control.prototype.setHandleMouseEvents=function(a){this.isInDocument()&&a!=this.handleMouseEvents_&&this.enableMouseEventHandling_(a);this.handleMouseEvents_=a};goog.ui.Control.prototype.getKeyEventTarget=function(){return this.renderer_.getKeyEventTarget(this)};
goog.ui.Control.prototype.getKeyHandler=function(){return this.keyHandler_||(this.keyHandler_=new goog.events.KeyHandler)};goog.ui.Control.prototype.getRenderer=function(){return this.renderer_};goog.ui.Control.prototype.setRenderer=function(a){if(this.isInDocument())throw Error(goog.ui.Component.Error.ALREADY_RENDERED);this.getElement()&&this.setElementInternal(null);this.renderer_=a};goog.ui.Control.prototype.getExtraClassNames=function(){return this.extraClassNames_};
goog.ui.Control.prototype.addClassName=function(a){a&&(this.extraClassNames_?goog.array.contains(this.extraClassNames_,a)||this.extraClassNames_.push(a):this.extraClassNames_=[a],this.renderer_.enableExtraClassName(this,a,!0))};goog.ui.Control.prototype.removeClassName=function(a){a&&this.extraClassNames_&&goog.array.remove(this.extraClassNames_,a)&&(0==this.extraClassNames_.length&&(this.extraClassNames_=null),this.renderer_.enableExtraClassName(this,a,!1))};
goog.ui.Control.prototype.enableClassName=function(a,b){b?this.addClassName(a):this.removeClassName(a)};goog.ui.Control.prototype.createDom=function(){var a=this.renderer_.createDom(this);this.setElementInternal(a);this.renderer_.setAriaRole(a,this.getPreferredAriaRole());this.isAllowTextSelection()||this.renderer_.setAllowTextSelection(a,!1);this.isVisible()||this.renderer_.setVisible(a,!1)};goog.ui.Control.prototype.getPreferredAriaRole=function(){return this.preferredAriaRole_};
goog.ui.Control.prototype.setPreferredAriaRole=function(a){this.preferredAriaRole_=a};goog.ui.Control.prototype.getAriaLabel=function(){return this.ariaLabel_};goog.ui.Control.prototype.setAriaLabel=function(a){this.ariaLabel_=a;var b=this.getElement();b&&this.renderer_.setAriaLabel(b,a)};goog.ui.Control.prototype.getContentElement=function(){return this.renderer_.getContentElement(this.getElement())};goog.ui.Control.prototype.canDecorate=function(a){return this.renderer_.canDecorate(a)};
goog.ui.Control.prototype.decorateInternal=function(a){a=this.renderer_.decorate(this,a);this.setElementInternal(a);this.renderer_.setAriaRole(a,this.getPreferredAriaRole());this.isAllowTextSelection()||this.renderer_.setAllowTextSelection(a,!1);this.visible_="none"!=a.style.display};
goog.ui.Control.prototype.enterDocument=function(){goog.ui.Control.superClass_.enterDocument.call(this);this.renderer_.setAriaStates(this,this.getElementStrict());this.renderer_.initializeDom(this);if(this.supportedStates_&~goog.ui.Component.State.DISABLED&&(this.isHandleMouseEvents()&&this.enableMouseEventHandling_(!0),this.isSupportedState(goog.ui.Component.State.FOCUSED))){var a=this.getKeyEventTarget();if(a){var b=this.getKeyHandler();b.attach(a);this.getHandler().listen(b,goog.events.KeyHandler.EventType.KEY,
this.handleKeyEvent).listen(a,goog.events.EventType.FOCUS,this.handleFocus).listen(a,goog.events.EventType.BLUR,this.handleBlur)}}};
goog.ui.Control.prototype.enableMouseEventHandling_=function(a){var b=goog.ui.ComponentUtil.getMouseEventType(this),c=this.getHandler(),d=this.getElement();a?(c.listen(d,b.MOUSEDOWN,this.handleMouseDown).listen(d,[b.MOUSEUP,b.MOUSECANCEL],this.handleMouseUp).listen(d,goog.events.EventType.MOUSEOVER,this.handleMouseOver).listen(d,goog.events.EventType.MOUSEOUT,this.handleMouseOut),this.pointerEventsEnabled()&&c.listen(d,goog.events.EventType.GOTPOINTERCAPTURE,this.preventPointerCapture_),this.handleContextMenu!=
goog.nullFunction&&c.listen(d,goog.events.EventType.CONTEXTMENU,this.handleContextMenu),goog.userAgent.IE&&(goog.userAgent.isVersionOrHigher(9)||c.listen(d,goog.events.EventType.DBLCLICK,this.handleDblClick),this.ieMouseEventSequenceSimulator_||(this.ieMouseEventSequenceSimulator_=new goog.ui.Control.IeMouseEventSequenceSimulator_(this),this.registerDisposable(this.ieMouseEventSequenceSimulator_)))):(c.unlisten(d,b.MOUSEDOWN,this.handleMouseDown).unlisten(d,[b.MOUSEUP,b.MOUSECANCEL],this.handleMouseUp).unlisten(d,
goog.events.EventType.MOUSEOVER,this.handleMouseOver).unlisten(d,goog.events.EventType.MOUSEOUT,this.handleMouseOut),this.pointerEventsEnabled()&&c.unlisten(d,goog.events.EventType.GOTPOINTERCAPTURE,this.preventPointerCapture_),this.handleContextMenu!=goog.nullFunction&&c.unlisten(d,goog.events.EventType.CONTEXTMENU,this.handleContextMenu),goog.userAgent.IE&&(goog.userAgent.isVersionOrHigher(9)||c.unlisten(d,goog.events.EventType.DBLCLICK,this.handleDblClick),goog.dispose(this.ieMouseEventSequenceSimulator_),
this.ieMouseEventSequenceSimulator_=null))};goog.ui.Control.prototype.exitDocument=function(){goog.ui.Control.superClass_.exitDocument.call(this);this.keyHandler_&&this.keyHandler_.detach();this.isVisible()&&this.isEnabled()&&this.renderer_.setFocusable(this,!1)};
goog.ui.Control.prototype.disposeInternal=function(){goog.ui.Control.superClass_.disposeInternal.call(this);this.keyHandler_&&(this.keyHandler_.dispose(),delete this.keyHandler_);delete this.renderer_;this.ieMouseEventSequenceSimulator_=this.extraClassNames_=this.content_=null};goog.ui.Control.prototype.getContent=function(){return this.content_};goog.ui.Control.prototype.setContent=function(a){this.renderer_.setContent(this.getElement(),a);this.setContentInternal(a)};
goog.ui.Control.prototype.setContentInternal=function(a){this.content_=a};goog.ui.Control.prototype.getCaption=function(){var a=this.getContent();if(!a)return"";a=goog.isString(a)?a:goog.isArray(a)?goog.array.map(a,goog.dom.getRawTextContent).join(""):goog.dom.getTextContent(a);return goog.string.collapseBreakingSpaces(a)};goog.ui.Control.prototype.setCaption=function(a){this.setContent(a)};
goog.ui.Control.prototype.setRightToLeft=function(a){goog.ui.Control.superClass_.setRightToLeft.call(this,a);var b=this.getElement();b&&this.renderer_.setRightToLeft(b,a)};goog.ui.Control.prototype.isAllowTextSelection=function(){return this.allowTextSelection_};goog.ui.Control.prototype.setAllowTextSelection=function(a){this.allowTextSelection_=a;var b=this.getElement();b&&this.renderer_.setAllowTextSelection(b,a)};goog.ui.Control.prototype.isVisible=function(){return this.visible_};
goog.ui.Control.prototype.setVisible=function(a,b){return b||this.visible_!=a&&this.dispatchEvent(a?goog.ui.Component.EventType.SHOW:goog.ui.Component.EventType.HIDE)?((b=this.getElement())&&this.renderer_.setVisible(b,a),this.isEnabled()&&this.renderer_.setFocusable(this,a),this.visible_=a,!0):!1};goog.ui.Control.prototype.isEnabled=function(){return!this.hasState(goog.ui.Component.State.DISABLED)};
goog.ui.Control.prototype.isParentDisabled_=function(){var a=this.getParent();return!!a&&"function"==typeof a.isEnabled&&!a.isEnabled()};goog.ui.Control.prototype.setEnabled=function(a){!this.isParentDisabled_()&&this.isTransitionAllowed(goog.ui.Component.State.DISABLED,!a)&&(a||(this.setActive(!1),this.setHighlighted(!1)),this.isVisible()&&this.renderer_.setFocusable(this,a),this.setState(goog.ui.Component.State.DISABLED,!a,!0))};goog.ui.Control.prototype.isHighlighted=function(){return this.hasState(goog.ui.Component.State.HOVER)};
goog.ui.Control.prototype.setHighlighted=function(a){this.isTransitionAllowed(goog.ui.Component.State.HOVER,a)&&this.setState(goog.ui.Component.State.HOVER,a)};goog.ui.Control.prototype.isActive=function(){return this.hasState(goog.ui.Component.State.ACTIVE)};goog.ui.Control.prototype.setActive=function(a){this.isTransitionAllowed(goog.ui.Component.State.ACTIVE,a)&&this.setState(goog.ui.Component.State.ACTIVE,a)};goog.ui.Control.prototype.isSelected=function(){return this.hasState(goog.ui.Component.State.SELECTED)};
goog.ui.Control.prototype.setSelected=function(a){this.isTransitionAllowed(goog.ui.Component.State.SELECTED,a)&&this.setState(goog.ui.Component.State.SELECTED,a)};goog.ui.Control.prototype.isChecked=function(){return this.hasState(goog.ui.Component.State.CHECKED)};goog.ui.Control.prototype.setChecked=function(a){this.isTransitionAllowed(goog.ui.Component.State.CHECKED,a)&&this.setState(goog.ui.Component.State.CHECKED,a)};goog.ui.Control.prototype.isFocused=function(){return this.hasState(goog.ui.Component.State.FOCUSED)};
goog.ui.Control.prototype.setFocused=function(a){this.isTransitionAllowed(goog.ui.Component.State.FOCUSED,a)&&this.setState(goog.ui.Component.State.FOCUSED,a)};goog.ui.Control.prototype.isOpen=function(){return this.hasState(goog.ui.Component.State.OPENED)};goog.ui.Control.prototype.setOpen=function(a){this.isTransitionAllowed(goog.ui.Component.State.OPENED,a)&&this.setState(goog.ui.Component.State.OPENED,a)};goog.ui.Control.prototype.getState=function(){return this.state_};
goog.ui.Control.prototype.hasState=function(a){return!!(this.state_&a)};goog.ui.Control.prototype.setState=function(a,b,c){c||a!=goog.ui.Component.State.DISABLED?this.isSupportedState(a)&&b!=this.hasState(a)&&(this.renderer_.setState(this,a,b),this.state_=b?this.state_|a:this.state_&~a):this.setEnabled(!b)};goog.ui.Control.prototype.setStateInternal=function(a){this.state_=a};goog.ui.Control.prototype.isSupportedState=function(a){return!!(this.supportedStates_&a)};
goog.ui.Control.prototype.setSupportedState=function(a,b){if(this.isInDocument()&&this.hasState(a)&&!b)throw Error(goog.ui.Component.Error.ALREADY_RENDERED);!b&&this.hasState(a)&&this.setState(a,!1);this.supportedStates_=b?this.supportedStates_|a:this.supportedStates_&~a};goog.ui.Control.prototype.isAutoState=function(a){return!!(this.autoStates_&a)&&this.isSupportedState(a)};goog.ui.Control.prototype.setAutoStates=function(a,b){this.autoStates_=b?this.autoStates_|a:this.autoStates_&~a};
goog.ui.Control.prototype.isDispatchTransitionEvents=function(a){return!!(this.statesWithTransitionEvents_&a)&&this.isSupportedState(a)};goog.ui.Control.prototype.setDispatchTransitionEvents=function(a,b){this.statesWithTransitionEvents_=b?this.statesWithTransitionEvents_|a:this.statesWithTransitionEvents_&~a};
goog.ui.Control.prototype.isTransitionAllowed=function(a,b){return this.isSupportedState(a)&&this.hasState(a)!=b&&(!(this.statesWithTransitionEvents_&a)||this.dispatchEvent(goog.ui.Component.getStateTransitionEvent(a,b)))&&!this.isDisposed()};goog.ui.Control.prototype.handleMouseOver=function(a){!goog.ui.Control.isMouseEventWithinElement_(a,this.getElement())&&this.dispatchEvent(goog.ui.Component.EventType.ENTER)&&this.isEnabled()&&this.isAutoState(goog.ui.Component.State.HOVER)&&this.setHighlighted(!0)};
goog.ui.Control.prototype.handleMouseOut=function(a){!goog.ui.Control.isMouseEventWithinElement_(a,this.getElement())&&this.dispatchEvent(goog.ui.Component.EventType.LEAVE)&&(this.isAutoState(goog.ui.Component.State.ACTIVE)&&this.setActive(!1),this.isAutoState(goog.ui.Component.State.HOVER)&&this.setHighlighted(!1))};goog.ui.Control.prototype.preventPointerCapture_=function(a){var b=a.target;b.releasePointerCapture&&b.releasePointerCapture(a.pointerId)};
goog.ui.Control.prototype.handleContextMenu=goog.nullFunction;goog.ui.Control.isMouseEventWithinElement_=function(a,b){return!!a.relatedTarget&&goog.dom.contains(b,a.relatedTarget)};
goog.ui.Control.prototype.handleMouseDown=function(a){this.isEnabled()&&(this.isAutoState(goog.ui.Component.State.HOVER)&&this.setHighlighted(!0),a.isMouseActionButton()&&(this.isAutoState(goog.ui.Component.State.ACTIVE)&&this.setActive(!0),this.renderer_&&this.renderer_.isFocusable(this)&&this.getKeyEventTarget().focus()));!this.isAllowTextSelection()&&a.isMouseActionButton()&&a.preventDefault()};
goog.ui.Control.prototype.handleMouseUp=function(a){this.isEnabled()&&(this.isAutoState(goog.ui.Component.State.HOVER)&&this.setHighlighted(!0),this.isActive()&&this.performActionInternal(a)&&this.isAutoState(goog.ui.Component.State.ACTIVE)&&this.setActive(!1))};goog.ui.Control.prototype.handleDblClick=function(a){this.isEnabled()&&this.performActionInternal(a)};
goog.ui.Control.prototype.performActionInternal=function(a){this.isAutoState(goog.ui.Component.State.CHECKED)&&this.setChecked(!this.isChecked());this.isAutoState(goog.ui.Component.State.SELECTED)&&this.setSelected(!0);this.isAutoState(goog.ui.Component.State.OPENED)&&this.setOpen(!this.isOpen());var b=new goog.events.Event(goog.ui.Component.EventType.ACTION,this);a&&(b.altKey=a.altKey,b.ctrlKey=a.ctrlKey,b.metaKey=a.metaKey,b.shiftKey=a.shiftKey,b.platformModifierKey=a.platformModifierKey);return this.dispatchEvent(b)};
goog.ui.Control.prototype.handleFocus=function(a){this.isAutoState(goog.ui.Component.State.FOCUSED)&&this.setFocused(!0)};goog.ui.Control.prototype.handleBlur=function(a){this.isAutoState(goog.ui.Component.State.ACTIVE)&&this.setActive(!1);this.isAutoState(goog.ui.Component.State.FOCUSED)&&this.setFocused(!1)};goog.ui.Control.prototype.handleKeyEvent=function(a){return this.isVisible()&&this.isEnabled()&&this.handleKeyEventInternal(a)?(a.preventDefault(),a.stopPropagation(),!0):!1};
goog.ui.Control.prototype.handleKeyEventInternal=function(a){return a.keyCode==goog.events.KeyCodes.ENTER&&this.performActionInternal(a)};goog.ui.registry.setDefaultRenderer(goog.ui.Control,goog.ui.ControlRenderer);goog.ui.registry.setDecoratorByClassName(goog.ui.ControlRenderer.CSS_CLASS,function(){return new goog.ui.Control(null)});
goog.ui.Control.IeMouseEventSequenceSimulator_=function(a){goog.Disposable.call(this);this.control_=a;this.clickExpected_=!1;this.handler_=new goog.events.EventHandler(this);this.registerDisposable(this.handler_);a=this.control_.getElementStrict();this.handler_.listen(a,goog.events.EventType.MOUSEDOWN,this.handleMouseDown_).listen(a,goog.events.EventType.MOUSEUP,this.handleMouseUp_).listen(a,goog.events.EventType.CLICK,this.handleClick_)};
goog.inherits(goog.ui.Control.IeMouseEventSequenceSimulator_,goog.Disposable);goog.ui.Control.IeMouseEventSequenceSimulator_.SYNTHETIC_EVENTS_=!goog.userAgent.IE||goog.userAgent.isDocumentModeOrHigher(9);goog.ui.Control.IeMouseEventSequenceSimulator_.prototype.handleMouseDown_=function(){this.clickExpected_=!1};goog.ui.Control.IeMouseEventSequenceSimulator_.prototype.handleMouseUp_=function(){this.clickExpected_=!0};
goog.ui.Control.IeMouseEventSequenceSimulator_.makeLeftMouseEvent_=function(a,b){if(!goog.ui.Control.IeMouseEventSequenceSimulator_.SYNTHETIC_EVENTS_)return a.button=goog.events.BrowserEvent.MouseButton.LEFT,a.type=b,a;var c=document.createEvent("MouseEvents");c.initMouseEvent(b,a.bubbles,a.cancelable,a.view||null,a.detail,a.screenX,a.screenY,a.clientX,a.clientY,a.ctrlKey,a.altKey,a.shiftKey,a.metaKey,goog.events.BrowserEvent.MouseButton.LEFT,a.relatedTarget||null);return c};
goog.ui.Control.IeMouseEventSequenceSimulator_.prototype.handleClick_=function(a){if(this.clickExpected_)this.clickExpected_=!1;else{var b=a.getBrowserEvent(),c=b.button,d=b.type,e=goog.ui.Control.IeMouseEventSequenceSimulator_.makeLeftMouseEvent_(b,goog.events.EventType.MOUSEDOWN);this.control_.handleMouseDown(new goog.events.BrowserEvent(e,a.currentTarget));e=goog.ui.Control.IeMouseEventSequenceSimulator_.makeLeftMouseEvent_(b,goog.events.EventType.MOUSEUP);this.control_.handleMouseUp(new goog.events.BrowserEvent(e,
a.currentTarget));goog.ui.Control.IeMouseEventSequenceSimulator_.SYNTHETIC_EVENTS_||(b.button=c,b.type=d)}};goog.ui.Control.IeMouseEventSequenceSimulator_.prototype.disposeInternal=function(){this.control_=null;goog.ui.Control.IeMouseEventSequenceSimulator_.superClass_.disposeInternal.call(this)};goog.ui.Container=function(a,b,c){goog.ui.Component.call(this,c);this.renderer_=b||goog.ui.ContainerRenderer.getInstance();this.orientation_=a||this.renderer_.getDefaultOrientation()};goog.inherits(goog.ui.Container,goog.ui.Component);goog.tagUnsealableClass(goog.ui.Container);goog.ui.Container.EventType={AFTER_SHOW:"aftershow",AFTER_HIDE:"afterhide"};goog.ui.Container.Orientation={HORIZONTAL:"horizontal",VERTICAL:"vertical"};goog.ui.Container.prototype.keyEventTarget_=null;
goog.ui.Container.prototype.keyHandler_=null;goog.ui.Container.prototype.renderer_=null;goog.ui.Container.prototype.orientation_=null;goog.ui.Container.prototype.visible_=!0;goog.ui.Container.prototype.enabled_=!0;goog.ui.Container.prototype.focusable_=!0;goog.ui.Container.prototype.highlightedIndex_=-1;goog.ui.Container.prototype.openItem_=null;goog.ui.Container.prototype.mouseButtonPressed_=!1;goog.ui.Container.prototype.allowFocusableChildren_=!1;
goog.ui.Container.prototype.openFollowsHighlight_=!0;goog.ui.Container.prototype.childElementIdMap_=null;goog.ui.Container.prototype.getKeyEventTarget=function(){return this.keyEventTarget_||this.renderer_.getKeyEventTarget(this)};
goog.ui.Container.prototype.setKeyEventTarget=function(a){if(this.focusable_){var b=this.getKeyEventTarget(),c=this.isInDocument();this.keyEventTarget_=a;var d=this.getKeyEventTarget();c&&(this.keyEventTarget_=b,this.enableFocusHandling_(!1),this.keyEventTarget_=a,this.getKeyHandler().attach(d),this.enableFocusHandling_(!0))}else throw Error("Can't set key event target for container that doesn't support keyboard focus!");};
goog.ui.Container.prototype.getKeyHandler=function(){return this.keyHandler_||(this.keyHandler_=new goog.events.KeyHandler(this.getKeyEventTarget()))};goog.ui.Container.prototype.getRenderer=function(){return this.renderer_};goog.ui.Container.prototype.setRenderer=function(a){if(this.getElement())throw Error(goog.ui.Component.Error.ALREADY_RENDERED);this.renderer_=a};goog.ui.Container.prototype.createDom=function(){this.setElementInternal(this.renderer_.createDom(this))};
goog.ui.Container.prototype.getContentElement=function(){return this.renderer_.getContentElement(this.getElement())};goog.ui.Container.prototype.canDecorate=function(a){return this.renderer_.canDecorate(a)};goog.ui.Container.prototype.decorateInternal=function(a){this.setElementInternal(this.renderer_.decorate(this,a));"none"==a.style.display&&(this.visible_=!1)};
goog.ui.Container.prototype.enterDocument=function(){goog.ui.Container.superClass_.enterDocument.call(this);this.forEachChild(function(a){a.isInDocument()&&this.registerChildId_(a)},this);var a=this.getElement();this.renderer_.initializeDom(this);this.setVisible(this.visible_,!0);var b=goog.ui.ComponentUtil.getMouseEventType(this);this.getHandler().listen(this,goog.ui.Component.EventType.ENTER,this.handleEnterItem).listen(this,goog.ui.Component.EventType.HIGHLIGHT,this.handleHighlightItem).listen(this,
goog.ui.Component.EventType.UNHIGHLIGHT,this.handleUnHighlightItem).listen(this,goog.ui.Component.EventType.OPEN,this.handleOpenItem).listen(this,goog.ui.Component.EventType.CLOSE,this.handleCloseItem).listen(a,b.MOUSEDOWN,this.handleMouseDown).listen(goog.dom.getOwnerDocument(a),[b.MOUSEUP,b.MOUSECANCEL],this.handleDocumentMouseUp).listen(a,[b.MOUSEDOWN,b.MOUSEUP,b.MOUSECANCEL,goog.events.EventType.MOUSEOVER,goog.events.EventType.MOUSEOUT,goog.events.EventType.CONTEXTMENU],this.handleChildMouseEvents);
this.pointerEventsEnabled()&&this.getHandler().listen(a,goog.events.EventType.GOTPOINTERCAPTURE,this.preventPointerCapture_);this.isFocusable()&&this.enableFocusHandling_(!0)};goog.ui.Container.prototype.preventPointerCapture_=function(a){var b=a.target;b.releasePointerCapture&&b.releasePointerCapture(a.pointerId)};
goog.ui.Container.prototype.enableFocusHandling_=function(a){var b=this.getHandler(),c=this.getKeyEventTarget();a?b.listen(c,goog.events.EventType.FOCUS,this.handleFocus).listen(c,goog.events.EventType.BLUR,this.handleBlur).listen(this.getKeyHandler(),goog.events.KeyHandler.EventType.KEY,this.handleKeyEvent):b.unlisten(c,goog.events.EventType.FOCUS,this.handleFocus).unlisten(c,goog.events.EventType.BLUR,this.handleBlur).unlisten(this.getKeyHandler(),goog.events.KeyHandler.EventType.KEY,this.handleKeyEvent)};
goog.ui.Container.prototype.exitDocument=function(){this.setHighlightedIndex(-1);this.openItem_&&this.openItem_.setOpen(!1);this.mouseButtonPressed_=!1;goog.ui.Container.superClass_.exitDocument.call(this)};goog.ui.Container.prototype.disposeInternal=function(){goog.ui.Container.superClass_.disposeInternal.call(this);this.keyHandler_&&(this.keyHandler_.dispose(),this.keyHandler_=null);this.renderer_=this.openItem_=this.childElementIdMap_=this.keyEventTarget_=null};
goog.ui.Container.prototype.handleEnterItem=function(a){return!0};
goog.ui.Container.prototype.handleHighlightItem=function(a){var b=this.indexOfChild(a.target);if(-1<b&&b!=this.highlightedIndex_){var c=this.getHighlighted();c&&c.setHighlighted(!1);this.highlightedIndex_=b;c=this.getHighlighted();this.isMouseButtonPressed()&&c.setActive(!0);this.openFollowsHighlight_&&this.openItem_&&c!=this.openItem_&&(c.isSupportedState(goog.ui.Component.State.OPENED)?c.setOpen(!0):this.openItem_.setOpen(!1))}b=this.getElement();goog.asserts.assert(b,"The DOM element for the container cannot be null.");
null!=a.target.getElement()&&goog.a11y.aria.setState(b,goog.a11y.aria.State.ACTIVEDESCENDANT,a.target.getElement().id)};goog.ui.Container.prototype.handleUnHighlightItem=function(a){a.target==this.getHighlighted()&&(this.highlightedIndex_=-1);a=this.getElement();goog.asserts.assert(a,"The DOM element for the container cannot be null.");goog.a11y.aria.removeState(a,goog.a11y.aria.State.ACTIVEDESCENDANT)};
goog.ui.Container.prototype.handleOpenItem=function(a){(a=a.target)&&a!=this.openItem_&&a.getParent()==this&&(this.openItem_&&this.openItem_.setOpen(!1),this.openItem_=a)};goog.ui.Container.prototype.handleCloseItem=function(a){a.target==this.openItem_&&(this.openItem_=null);var b=this.getElement(),c=a.target.getElement();b&&a.target.isHighlighted()&&c&&goog.a11y.aria.setActiveDescendant(b,c)};
goog.ui.Container.prototype.handleMouseDown=function(a){this.enabled_&&this.setMouseButtonPressed(!0);var b=this.getKeyEventTarget();b&&goog.dom.isFocusableTabIndex(b)?b.focus():a.preventDefault()};goog.ui.Container.prototype.handleDocumentMouseUp=function(a){this.setMouseButtonPressed(!1)};
goog.ui.Container.prototype.handleChildMouseEvents=function(a){var b=goog.ui.ComponentUtil.getMouseEventType(this),c=this.getOwnerControl(a.target);if(c)switch(a.type){case b.MOUSEDOWN:c.handleMouseDown(a);break;case b.MOUSEUP:case b.MOUSECANCEL:c.handleMouseUp(a);break;case goog.events.EventType.MOUSEOVER:c.handleMouseOver(a);break;case goog.events.EventType.MOUSEOUT:c.handleMouseOut(a);break;case goog.events.EventType.CONTEXTMENU:c.handleContextMenu(a)}};
goog.ui.Container.prototype.getOwnerControl=function(a){if(this.childElementIdMap_)for(var b=this.getElement();a&&a!==b;){var c=a.id;if(c in this.childElementIdMap_)return this.childElementIdMap_[c];a=a.parentNode}return null};goog.ui.Container.prototype.handleFocus=function(a){};goog.ui.Container.prototype.handleBlur=function(a){this.setHighlightedIndex(-1);this.setMouseButtonPressed(!1);this.openItem_&&this.openItem_.setOpen(!1)};
goog.ui.Container.prototype.handleKeyEvent=function(a){return this.isEnabled()&&this.isVisible()&&(0!=this.getChildCount()||this.keyEventTarget_)&&this.handleKeyEventInternal(a)?(a.preventDefault(),a.stopPropagation(),!0):!1};
goog.ui.Container.prototype.handleKeyEventInternal=function(a){var b=this.getHighlighted();if(b&&"function"==typeof b.handleKeyEvent&&b.handleKeyEvent(a)||this.openItem_&&this.openItem_!=b&&"function"==typeof this.openItem_.handleKeyEvent&&this.openItem_.handleKeyEvent(a))return!0;if(a.shiftKey||a.ctrlKey||a.metaKey||a.altKey)return!1;switch(a.keyCode){case goog.events.KeyCodes.ESC:if(this.isFocusable())this.getKeyEventTarget().blur();else return!1;break;case goog.events.KeyCodes.HOME:this.highlightFirst();
break;case goog.events.KeyCodes.END:this.highlightLast();break;case goog.events.KeyCodes.UP:if(this.orientation_==goog.ui.Container.Orientation.VERTICAL)this.highlightPrevious();else return!1;break;case goog.events.KeyCodes.LEFT:if(this.orientation_==goog.ui.Container.Orientation.HORIZONTAL)this.isRightToLeft()?this.highlightNext():this.highlightPrevious();else return!1;break;case goog.events.KeyCodes.DOWN:if(this.orientation_==goog.ui.Container.Orientation.VERTICAL)this.highlightNext();else return!1;
break;case goog.events.KeyCodes.RIGHT:if(this.orientation_==goog.ui.Container.Orientation.HORIZONTAL)this.isRightToLeft()?this.highlightPrevious():this.highlightNext();else return!1;break;default:return!1}return!0};goog.ui.Container.prototype.registerChildId_=function(a){var b=a.getElement();b=b.id||(b.id=a.getId());this.childElementIdMap_||(this.childElementIdMap_={});this.childElementIdMap_[b]=a};
goog.ui.Container.prototype.addChild=function(a,b){goog.asserts.assertInstanceof(a,goog.ui.Control,"The child of a container must be a control");goog.ui.Container.superClass_.addChild.call(this,a,b)};
goog.ui.Container.prototype.addChildAt=function(a,b,c){goog.asserts.assertInstanceof(a,goog.ui.Control);a.setDispatchTransitionEvents(goog.ui.Component.State.HOVER,!0);a.setDispatchTransitionEvents(goog.ui.Component.State.OPENED,!0);!this.isFocusable()&&this.isFocusableChildrenAllowed()||a.setSupportedState(goog.ui.Component.State.FOCUSED,!1);a.setHandleMouseEvents(!1);var d=a.getParent()==this?this.indexOfChild(a):-1;goog.ui.Container.superClass_.addChildAt.call(this,a,b,c);a.isInDocument()&&this.isInDocument()&&
this.registerChildId_(a);this.updateHighlightedIndex_(d,b)};goog.ui.Container.prototype.updateHighlightedIndex_=function(a,b){-1==a&&(a=this.getChildCount());a==this.highlightedIndex_?this.highlightedIndex_=Math.min(this.getChildCount()-1,b):a>this.highlightedIndex_&&b<=this.highlightedIndex_?this.highlightedIndex_++:a<this.highlightedIndex_&&b>this.highlightedIndex_&&this.highlightedIndex_--};
goog.ui.Container.prototype.removeChild=function(a,b){a=goog.isString(a)?this.getChild(a):a;goog.asserts.assertInstanceof(a,goog.ui.Control);if(a){var c=this.indexOfChild(a);-1!=c&&(c==this.highlightedIndex_?(a.setHighlighted(!1),this.highlightedIndex_=-1):c<this.highlightedIndex_&&this.highlightedIndex_--);(c=a.getElement())&&c.id&&this.childElementIdMap_&&goog.object.remove(this.childElementIdMap_,c.id)}a=goog.ui.Container.superClass_.removeChild.call(this,a,b);a.setHandleMouseEvents(!0);return a};
goog.ui.Container.prototype.getOrientation=function(){return this.orientation_};goog.ui.Container.prototype.setOrientation=function(a){if(this.getElement())throw Error(goog.ui.Component.Error.ALREADY_RENDERED);this.orientation_=a};goog.ui.Container.prototype.isVisible=function(){return this.visible_};
goog.ui.Container.prototype.setVisible=function(a,b){if(b||this.visible_!=a&&this.dispatchEvent(a?goog.ui.Component.EventType.SHOW:goog.ui.Component.EventType.HIDE)){this.visible_=a;var c=this.getElement();c&&(goog.style.setElementShown(c,a),this.isFocusable()&&this.renderer_.enableTabIndex(this.getKeyEventTarget(),this.enabled_&&this.visible_),b||this.dispatchEvent(this.visible_?goog.ui.Container.EventType.AFTER_SHOW:goog.ui.Container.EventType.AFTER_HIDE));return!0}return!1};
goog.ui.Container.prototype.isEnabled=function(){return this.enabled_};
goog.ui.Container.prototype.setEnabled=function(a){this.enabled_!=a&&this.dispatchEvent(a?goog.ui.Component.EventType.ENABLE:goog.ui.Component.EventType.DISABLE)&&(a?(this.enabled_=!0,this.forEachChild(function(a){a.wasDisabled?delete a.wasDisabled:a.setEnabled(!0)})):(this.forEachChild(function(a){a.isEnabled()?a.setEnabled(!1):a.wasDisabled=!0}),this.enabled_=!1,this.setMouseButtonPressed(!1)),this.isFocusable()&&this.renderer_.enableTabIndex(this.getKeyEventTarget(),a&&this.visible_))};
goog.ui.Container.prototype.isFocusable=function(){return this.focusable_};goog.ui.Container.prototype.setFocusable=function(a){a!=this.focusable_&&this.isInDocument()&&this.enableFocusHandling_(a);this.focusable_=a;this.enabled_&&this.visible_&&this.renderer_.enableTabIndex(this.getKeyEventTarget(),a)};goog.ui.Container.prototype.isFocusableChildrenAllowed=function(){return this.allowFocusableChildren_};
goog.ui.Container.prototype.setFocusableChildrenAllowed=function(a){this.allowFocusableChildren_=a};goog.ui.Container.prototype.isOpenFollowsHighlight=function(){return this.openFollowsHighlight_};goog.ui.Container.prototype.setOpenFollowsHighlight=function(a){this.openFollowsHighlight_=a};goog.ui.Container.prototype.getHighlightedIndex=function(){return this.highlightedIndex_};
goog.ui.Container.prototype.setHighlightedIndex=function(a){(a=this.getChildAt(a))?a.setHighlighted(!0):-1<this.highlightedIndex_&&this.getHighlighted().setHighlighted(!1)};goog.ui.Container.prototype.setHighlighted=function(a){this.setHighlightedIndex(this.indexOfChild(a))};goog.ui.Container.prototype.getHighlighted=function(){return this.getChildAt(this.highlightedIndex_)};
goog.ui.Container.prototype.highlightFirst=function(){this.highlightHelper(function(a,b){return(a+1)%b},this.getChildCount()-1)};goog.ui.Container.prototype.highlightLast=function(){this.highlightHelper(function(a,b){a--;return 0>a?b-1:a},0)};goog.ui.Container.prototype.highlightNext=function(){this.highlightHelper(function(a,b){return(a+1)%b},this.highlightedIndex_)};goog.ui.Container.prototype.highlightPrevious=function(){this.highlightHelper(function(a,b){a--;return 0>a?b-1:a},this.highlightedIndex_)};
goog.ui.Container.prototype.highlightHelper=function(a,b){b=0>b?this.indexOfChild(this.openItem_):b;var c=this.getChildCount();b=a.call(this,b,c);for(var d=0;d<=c;){var e=this.getChildAt(b);if(e&&this.canHighlightItem(e))return this.setHighlightedIndexFromKeyEvent(b),!0;d++;b=a.call(this,b,c)}return!1};goog.ui.Container.prototype.canHighlightItem=function(a){return a.isVisible()&&a.isEnabled()&&a.isSupportedState(goog.ui.Component.State.HOVER)};
goog.ui.Container.prototype.setHighlightedIndexFromKeyEvent=function(a){this.setHighlightedIndex(a)};goog.ui.Container.prototype.getOpenItem=function(){return this.openItem_};goog.ui.Container.prototype.isMouseButtonPressed=function(){return this.mouseButtonPressed_};goog.ui.Container.prototype.setMouseButtonPressed=function(a){this.mouseButtonPressed_=a};goog.ui.MenuHeaderRenderer=function(){goog.ui.ControlRenderer.call(this)};goog.inherits(goog.ui.MenuHeaderRenderer,goog.ui.ControlRenderer);goog.addSingletonGetter(goog.ui.MenuHeaderRenderer);goog.ui.MenuHeaderRenderer.CSS_CLASS="goog-menuheader";goog.ui.MenuHeaderRenderer.prototype.getCssClass=function(){return goog.ui.MenuHeaderRenderer.CSS_CLASS};goog.ui.MenuHeader=function(a,b,c){goog.ui.Control.call(this,a,c||goog.ui.MenuHeaderRenderer.getInstance(),b);this.setSupportedState(goog.ui.Component.State.DISABLED,!1);this.setSupportedState(goog.ui.Component.State.HOVER,!1);this.setSupportedState(goog.ui.Component.State.ACTIVE,!1);this.setSupportedState(goog.ui.Component.State.FOCUSED,!1);this.setStateInternal(goog.ui.Component.State.DISABLED)};goog.inherits(goog.ui.MenuHeader,goog.ui.Control);
goog.ui.registry.setDecoratorByClassName(goog.ui.MenuHeaderRenderer.CSS_CLASS,function(){return new goog.ui.MenuHeader(null)});goog.ui.MenuItemRenderer=function(){goog.ui.ControlRenderer.call(this);this.classNameCache_=[]};goog.inherits(goog.ui.MenuItemRenderer,goog.ui.ControlRenderer);goog.addSingletonGetter(goog.ui.MenuItemRenderer);goog.ui.MenuItemRenderer.CSS_CLASS="goog-menuitem";goog.ui.MenuItemRenderer.CompositeCssClassIndex_={HOVER:0,CHECKBOX:1,CONTENT:2};
goog.ui.MenuItemRenderer.prototype.getCompositeCssClass_=function(a){var b=this.classNameCache_[a];if(!b){switch(a){case goog.ui.MenuItemRenderer.CompositeCssClassIndex_.HOVER:b=this.getStructuralCssClass()+"-highlight";break;case goog.ui.MenuItemRenderer.CompositeCssClassIndex_.CHECKBOX:b=this.getStructuralCssClass()+"-checkbox";break;case goog.ui.MenuItemRenderer.CompositeCssClassIndex_.CONTENT:b=this.getStructuralCssClass()+"-content"}this.classNameCache_[a]=b}return b};
goog.ui.MenuItemRenderer.prototype.getAriaRole=function(){return goog.a11y.aria.Role.MENU_ITEM};goog.ui.MenuItemRenderer.prototype.createDom=function(a){var b=a.getDomHelper().createDom("DIV",this.getClassNames(a).join(" "),this.createContent(a.getContent(),a.getDomHelper()));this.setEnableCheckBoxStructure(a,b,a.isSupportedState(goog.ui.Component.State.SELECTED)||a.isSupportedState(goog.ui.Component.State.CHECKED));return b};
goog.ui.MenuItemRenderer.prototype.getContentElement=function(a){return a&&a.firstChild};goog.ui.MenuItemRenderer.prototype.decorate=function(a,b){goog.asserts.assert(b);this.hasContentStructure(b)||b.appendChild(this.createContent(b.childNodes,a.getDomHelper()));goog.dom.classlist.contains(b,"goog-option")&&(a.setCheckable(!0),this.setCheckable(a,b,!0));return goog.ui.MenuItemRenderer.superClass_.decorate.call(this,a,b)};
goog.ui.MenuItemRenderer.prototype.setContent=function(a,b){var c=this.getContentElement(a),d=this.hasCheckBoxStructure(a)?c.firstChild:null;goog.ui.MenuItemRenderer.superClass_.setContent.call(this,a,b);d&&!this.hasCheckBoxStructure(a)&&c.insertBefore(d,c.firstChild||null)};
goog.ui.MenuItemRenderer.prototype.hasContentStructure=function(a){a=goog.dom.getFirstElementChild(a);var b=this.getCompositeCssClass_(goog.ui.MenuItemRenderer.CompositeCssClassIndex_.CONTENT);return!!a&&goog.dom.classlist.contains(a,b)};goog.ui.MenuItemRenderer.prototype.createContent=function(a,b){var c=this.getCompositeCssClass_(goog.ui.MenuItemRenderer.CompositeCssClassIndex_.CONTENT);return b.createDom("DIV",c,a)};
goog.ui.MenuItemRenderer.prototype.setSelectable=function(a,b,c){a&&b&&this.setEnableCheckBoxStructure(a,b,c)};goog.ui.MenuItemRenderer.prototype.setCheckable=function(a,b,c){a&&b&&this.setEnableCheckBoxStructure(a,b,c)};goog.ui.MenuItemRenderer.prototype.hasCheckBoxStructure=function(a){if(a=this.getContentElement(a)){a=a.firstChild;var b=this.getCompositeCssClass_(goog.ui.MenuItemRenderer.CompositeCssClassIndex_.CHECKBOX);return!!a&&goog.dom.isElement(a)&&goog.dom.classlist.contains(a,b)}return!1};
goog.ui.MenuItemRenderer.prototype.setEnableCheckBoxStructure=function(a,b,c){this.setAriaRole(b,a.getPreferredAriaRole());this.setAriaStates(a,b);c!=this.hasCheckBoxStructure(b)&&(goog.dom.classlist.enable(b,"goog-option",c),b=this.getContentElement(b),c?(c=this.getCompositeCssClass_(goog.ui.MenuItemRenderer.CompositeCssClassIndex_.CHECKBOX),b.insertBefore(a.getDomHelper().createDom("DIV",c),b.firstChild||null)):b.removeChild(b.firstChild))};
goog.ui.MenuItemRenderer.prototype.getClassForState=function(a){switch(a){case goog.ui.Component.State.HOVER:return this.getCompositeCssClass_(goog.ui.MenuItemRenderer.CompositeCssClassIndex_.HOVER);case goog.ui.Component.State.CHECKED:case goog.ui.Component.State.SELECTED:return"goog-option-selected";default:return goog.ui.MenuItemRenderer.superClass_.getClassForState.call(this,a)}};
goog.ui.MenuItemRenderer.prototype.getStateFromClass=function(a){var b=this.getCompositeCssClass_(goog.ui.MenuItemRenderer.CompositeCssClassIndex_.HOVER);switch(a){case "goog-option-selected":return goog.ui.Component.State.CHECKED;case b:return goog.ui.Component.State.HOVER;default:return goog.ui.MenuItemRenderer.superClass_.getStateFromClass.call(this,a)}};goog.ui.MenuItemRenderer.prototype.getCssClass=function(){return goog.ui.MenuItemRenderer.CSS_CLASS};goog.ui.MenuItem=function(a,b,c,d){goog.ui.Control.call(this,a,d||goog.ui.MenuItemRenderer.getInstance(),c);this.setValue(b)};goog.inherits(goog.ui.MenuItem,goog.ui.Control);goog.tagUnsealableClass(goog.ui.MenuItem);goog.ui.MenuItem.MNEMONIC_WRAPPER_CLASS_="goog-menuitem-mnemonic-separator";goog.ui.MenuItem.ACCELERATOR_CLASS="goog-menuitem-accel";goog.ui.MenuItem.prototype.getValue=function(){var a=this.getModel();return null!=a?a:this.getCaption()};goog.ui.MenuItem.prototype.setValue=function(a){this.setModel(a)};
goog.ui.MenuItem.prototype.setSupportedState=function(a,b){goog.ui.MenuItem.superClass_.setSupportedState.call(this,a,b);switch(a){case goog.ui.Component.State.SELECTED:this.setSelectableInternal_(b);break;case goog.ui.Component.State.CHECKED:this.setCheckableInternal_(b)}};goog.ui.MenuItem.prototype.setSelectable=function(a){this.setSupportedState(goog.ui.Component.State.SELECTED,a)};
goog.ui.MenuItem.prototype.setSelectableInternal_=function(a){this.isChecked()&&!a&&this.setChecked(!1);var b=this.getElement();b&&this.getRenderer().setSelectable(this,b,a)};goog.ui.MenuItem.prototype.setCheckable=function(a){this.setSupportedState(goog.ui.Component.State.CHECKED,a)};goog.ui.MenuItem.prototype.setCheckableInternal_=function(a){var b=this.getElement();b&&this.getRenderer().setCheckable(this,b,a)};
goog.ui.MenuItem.prototype.getCaption=function(){var a=this.getContent();if(goog.isArray(a)){var b=goog.ui.MenuItem.ACCELERATOR_CLASS,c=goog.ui.MenuItem.MNEMONIC_WRAPPER_CLASS_;a=goog.array.map(a,function(a){return goog.dom.isElement(a)&&(goog.dom.classlist.contains(a,b)||goog.dom.classlist.contains(a,c))?"":goog.dom.getRawTextContent(a)}).join("");return goog.string.collapseBreakingSpaces(a)}return goog.ui.MenuItem.superClass_.getCaption.call(this)};
goog.ui.MenuItem.prototype.getAccelerator=function(){var a=this.getDomHelper(),b=this.getContent();return goog.isArray(b)&&(b=goog.array.find(b,function(a){return goog.dom.classlist.contains(a,goog.ui.MenuItem.ACCELERATOR_CLASS)}))?a.getTextContent(b):null};
goog.ui.MenuItem.prototype.handleMouseUp=function(a){var b=this.getParent();if(b){var c=b.openingCoords;b.openingCoords=null;if(c&&goog.isNumber(a.clientX)&&(b=new goog.math.Coordinate(a.clientX,a.clientY),goog.math.Coordinate.equals(c,b)))return}goog.ui.MenuItem.superClass_.handleMouseUp.call(this,a)};goog.ui.MenuItem.prototype.handleKeyEventInternal=function(a){return a.keyCode==this.getMnemonic()&&this.performActionInternal(a)?!0:goog.ui.MenuItem.superClass_.handleKeyEventInternal.call(this,a)};
goog.ui.MenuItem.prototype.setMnemonic=function(a){this.mnemonicKey_=a};goog.ui.MenuItem.prototype.getMnemonic=function(){return this.mnemonicKey_};goog.ui.registry.setDecoratorByClassName(goog.ui.MenuItemRenderer.CSS_CLASS,function(){return new goog.ui.MenuItem(null)});
goog.ui.MenuItem.prototype.getPreferredAriaRole=function(){return this.isSupportedState(goog.ui.Component.State.CHECKED)?goog.a11y.aria.Role.MENU_ITEM_CHECKBOX:this.isSupportedState(goog.ui.Component.State.SELECTED)?goog.a11y.aria.Role.MENU_ITEM_RADIO:goog.ui.MenuItem.superClass_.getPreferredAriaRole.call(this)};goog.ui.MenuItem.prototype.getParent=function(){return goog.ui.Control.prototype.getParent.call(this)};goog.ui.MenuItem.prototype.getParentEventTarget=function(){return goog.ui.Control.prototype.getParentEventTarget.call(this)};goog.ui.MenuSeparatorRenderer=function(){goog.ui.ControlRenderer.call(this)};goog.inherits(goog.ui.MenuSeparatorRenderer,goog.ui.ControlRenderer);goog.addSingletonGetter(goog.ui.MenuSeparatorRenderer);goog.ui.MenuSeparatorRenderer.CSS_CLASS="goog-menuseparator";goog.ui.MenuSeparatorRenderer.prototype.createDom=function(a){return a.getDomHelper().createDom("DIV",this.getCssClass())};
goog.ui.MenuSeparatorRenderer.prototype.decorate=function(a,b){b.id&&a.setId(b.id);if("HR"==b.tagName){var c=b;b=this.createDom(a);goog.dom.insertSiblingBefore(b,c);goog.dom.removeNode(c)}else goog.dom.classlist.add(b,this.getCssClass());return b};goog.ui.MenuSeparatorRenderer.prototype.setContent=function(a,b){};goog.ui.MenuSeparatorRenderer.prototype.getCssClass=function(){return goog.ui.MenuSeparatorRenderer.CSS_CLASS};goog.ui.Separator=function(a,b){goog.ui.Control.call(this,null,a||goog.ui.MenuSeparatorRenderer.getInstance(),b);this.setSupportedState(goog.ui.Component.State.DISABLED,!1);this.setSupportedState(goog.ui.Component.State.HOVER,!1);this.setSupportedState(goog.ui.Component.State.ACTIVE,!1);this.setSupportedState(goog.ui.Component.State.FOCUSED,!1);this.setStateInternal(goog.ui.Component.State.DISABLED)};goog.inherits(goog.ui.Separator,goog.ui.Control);
goog.ui.Separator.prototype.enterDocument=function(){goog.ui.Separator.superClass_.enterDocument.call(this);var a=this.getElement();goog.asserts.assert(a,"The DOM element for the separator cannot be null.");goog.a11y.aria.setRole(a,"separator")};goog.ui.registry.setDecoratorByClassName(goog.ui.MenuSeparatorRenderer.CSS_CLASS,function(){return new goog.ui.Separator});goog.ui.MenuRenderer=function(a){goog.ui.ContainerRenderer.call(this,a||goog.a11y.aria.Role.MENU)};goog.inherits(goog.ui.MenuRenderer,goog.ui.ContainerRenderer);goog.addSingletonGetter(goog.ui.MenuRenderer);goog.ui.MenuRenderer.CSS_CLASS="goog-menu";goog.ui.MenuRenderer.prototype.canDecorate=function(a){return"UL"==a.tagName||goog.ui.MenuRenderer.superClass_.canDecorate.call(this,a)};
goog.ui.MenuRenderer.prototype.getDecoratorForChild=function(a){return"HR"==a.tagName?new goog.ui.Separator:goog.ui.MenuRenderer.superClass_.getDecoratorForChild.call(this,a)};goog.ui.MenuRenderer.prototype.containsElement=function(a,b){return goog.dom.contains(a.getElement(),b)};goog.ui.MenuRenderer.prototype.getCssClass=function(){return goog.ui.MenuRenderer.CSS_CLASS};
goog.ui.MenuRenderer.prototype.initializeDom=function(a){goog.ui.MenuRenderer.superClass_.initializeDom.call(this,a);a=a.getElement();goog.asserts.assert(a,"The menu DOM element cannot be null.");goog.a11y.aria.setState(a,goog.a11y.aria.State.HASPOPUP,"true")};goog.ui.MenuSeparator=function(a){goog.ui.Separator.call(this,goog.ui.MenuSeparatorRenderer.getInstance(),a)};goog.inherits(goog.ui.MenuSeparator,goog.ui.Separator);goog.ui.registry.setDecoratorByClassName(goog.ui.MenuSeparatorRenderer.CSS_CLASS,function(){return new goog.ui.Separator});goog.ui.Menu=function(a,b){goog.ui.Container.call(this,goog.ui.Container.Orientation.VERTICAL,b||goog.ui.MenuRenderer.getInstance(),a);this.setFocusable(!1)};goog.inherits(goog.ui.Menu,goog.ui.Container);goog.tagUnsealableClass(goog.ui.Menu);goog.ui.Menu.EventType={BEFORE_SHOW:goog.ui.Component.EventType.BEFORE_SHOW,SHOW:goog.ui.Component.EventType.SHOW,BEFORE_HIDE:goog.ui.Component.EventType.HIDE,HIDE:goog.ui.Component.EventType.HIDE};goog.ui.Menu.CSS_CLASS=goog.ui.MenuRenderer.CSS_CLASS;
goog.ui.Menu.prototype.allowAutoFocus_=!0;goog.ui.Menu.prototype.allowHighlightDisabled_=!1;goog.ui.Menu.prototype.getCssClass=function(){return this.getRenderer().getCssClass()};goog.ui.Menu.prototype.containsElement=function(a){if(this.getRenderer().containsElement(this,a))return!0;for(var b=0,c=this.getChildCount();b<c;b++){var d=this.getChildAt(b);if("function"==typeof d.containsElement&&d.containsElement(a))return!0}return!1};goog.ui.Menu.prototype.addItem=function(a){this.addChild(a,!0)};
goog.ui.Menu.prototype.addItemAt=function(a,b){this.addChildAt(a,b,!0)};goog.ui.Menu.prototype.removeItem=function(a){(a=this.removeChild(a,!0))&&a.dispose()};goog.ui.Menu.prototype.removeItemAt=function(a){(a=this.removeChildAt(a,!0))&&a.dispose()};goog.ui.Menu.prototype.getItemAt=function(a){return this.getChildAt(a)};goog.ui.Menu.prototype.getItemCount=function(){return this.getChildCount()};goog.ui.Menu.prototype.getItems=function(){var a=[];this.forEachChild(function(b){a.push(b)});return a};
goog.ui.Menu.prototype.setPosition=function(a,b){var c=this.isVisible();c||goog.style.setElementShown(this.getElement(),!0);goog.style.setPageOffset(this.getElement(),a,b);c||goog.style.setElementShown(this.getElement(),!1)};goog.ui.Menu.prototype.getPosition=function(){return this.isVisible()?goog.style.getPageOffset(this.getElement()):null};goog.ui.Menu.prototype.setAllowAutoFocus=function(a){(this.allowAutoFocus_=a)&&this.setFocusable(!0)};goog.ui.Menu.prototype.getAllowAutoFocus=function(){return this.allowAutoFocus_};
goog.ui.Menu.prototype.setAllowHighlightDisabled=function(a){this.allowHighlightDisabled_=a};goog.ui.Menu.prototype.getAllowHighlightDisabled=function(){return this.allowHighlightDisabled_};goog.ui.Menu.prototype.setVisible=function(a,b,c){(b=goog.ui.Menu.superClass_.setVisible.call(this,a,b))&&a&&this.isInDocument()&&this.allowAutoFocus_&&this.getKeyEventTarget().focus();a&&c&&goog.isNumber(c.clientX)?this.openingCoords=new goog.math.Coordinate(c.clientX,c.clientY):this.openingCoords=null;return b};
goog.ui.Menu.prototype.handleEnterItem=function(a){this.allowAutoFocus_&&this.getKeyEventTarget().focus();return goog.ui.Menu.superClass_.handleEnterItem.call(this,a)};goog.ui.Menu.prototype.highlightNextPrefix=function(a){var b=new RegExp("^"+goog.string.regExpEscape(a),"i");return this.highlightHelper(function(a,d){var c=0>a?0:a,f=!1;do{++a;a==d&&(a=0,f=!0);var g=this.getChildAt(a).getCaption();if(g&&g.match(b))return a}while(!f||a!=c);return this.getHighlightedIndex()},this.getHighlightedIndex())};
goog.ui.Menu.prototype.canHighlightItem=function(a){return(this.allowHighlightDisabled_||a.isEnabled())&&a.isVisible()&&a.isSupportedState(goog.ui.Component.State.HOVER)};goog.ui.Menu.prototype.decorateInternal=function(a){this.decorateContent(a);goog.ui.Menu.superClass_.decorateInternal.call(this,a)};
goog.ui.Menu.prototype.handleKeyEventInternal=function(a){var b=goog.ui.Menu.superClass_.handleKeyEventInternal.call(this,a);b||this.forEachChild(function(c){!b&&c.getMnemonic&&c.getMnemonic()==a.keyCode&&(this.isEnabled()&&this.setHighlighted(c),b=c.handleKeyEvent(a))},this);return b};goog.ui.Menu.prototype.setHighlightedIndex=function(a){goog.ui.Menu.superClass_.setHighlightedIndex.call(this,a);(a=this.getChildAt(a))&&goog.style.scrollIntoContainerView(a.getElement(),this.getElement())};
goog.ui.Menu.prototype.decorateContent=function(a){var b=this.getRenderer();a=this.getDomHelper().getElementsByTagNameAndClass("DIV",b.getCssClass()+"-content",a);for(var c=a.length,d=0;d<c;d++)b.decorateChildren(this,a[d])};Blockly.ContextMenu={};Blockly.ContextMenu.currentBlock=null;Blockly.ContextMenu.eventWrapper_=null;Blockly.ContextMenu.show=function(a,b,c){Blockly.WidgetDiv.show(Blockly.ContextMenu,c,null);if(b.length){var d=Blockly.ContextMenu.populate_(b,c);goog.events.listen(d,goog.ui.Component.EventType.ACTION,Blockly.ContextMenu.hide);Blockly.ContextMenu.position_(d,a,c);setTimeout(function(){d.getElement().focus()},1);Blockly.ContextMenu.currentBlock=null}else Blockly.ContextMenu.hide()};
Blockly.ContextMenu.populate_=function(a,b){var c=new goog.ui.Menu;c.setRightToLeft(b);for(var d=0,e;e=a[d];d++){var f=new goog.ui.MenuItem(e.text);f.setRightToLeft(b);c.addChild(f,!0);f.setEnabled(e.enabled);e.enabled&&(goog.events.listen(f,goog.ui.Component.EventType.ACTION,e.callback),f.handleContextMenu=function(){goog.events.dispatchEvent(this,goog.ui.Component.EventType.ACTION)})}return c};
Blockly.ContextMenu.position_=function(a,b,c){var d=Blockly.utils.getViewportBBox();b={top:b.clientY+d.top,bottom:b.clientY+d.top,left:b.clientX+d.left,right:b.clientX+d.left};Blockly.ContextMenu.createWidget_(a);var e=Blockly.utils.uiMenu.getSize(a);c&&Blockly.utils.uiMenu.adjustBBoxesForRTL(d,b,e);Blockly.WidgetDiv.positionWithAnchor(d,b,e,c);a.getElement().focus()};
Blockly.ContextMenu.createWidget_=function(a){a.render(Blockly.WidgetDiv.DIV);var b=a.getElement();Blockly.utils.addClass(b,"blocklyContextMenu");Blockly.bindEventWithChecks_(b,"contextmenu",null,Blockly.utils.noEvent);a.setAllowAutoFocus(!0)};Blockly.ContextMenu.hide=function(){Blockly.WidgetDiv.hideIfOwner(Blockly.ContextMenu);Blockly.ContextMenu.currentBlock=null;Blockly.ContextMenu.eventWrapper_&&Blockly.unbindEvent_(Blockly.ContextMenu.eventWrapper_)};
Blockly.ContextMenu.callbackFactory=function(a,b){return function(){Blockly.Events.disable();try{var c=Blockly.Xml.domToBlock(b,a.workspace),d=a.getRelativeToSurfaceXY();d.x=a.RTL?d.x-Blockly.SNAP_RADIUS:d.x+Blockly.SNAP_RADIUS;d.y+=2*Blockly.SNAP_RADIUS;c.moveBy(d.x,d.y)}finally{Blockly.Events.enable()}Blockly.Events.isEnabled()&&!c.isShadow()&&Blockly.Events.fire(new Blockly.Events.BlockCreate(c));c.select()}};
Blockly.ContextMenu.blockDeleteOption=function(a){var b=a.getDescendants(!1,!0).length,c=a.getNextBlock();c&&(b-=c.getDescendants(!1,!0).length);return{text:1==b?Blockly.Msg.DELETE_BLOCK:Blockly.Msg.DELETE_X_BLOCKS.replace("%1",String(b)),enabled:!0,callback:function(){Blockly.Events.setGroup(!0);a.dispose(!0,!0);Blockly.Events.setGroup(!1)}}};Blockly.ContextMenu.blockHelpOption=function(a){return{enabled:!(goog.isFunction(a.helpUrl)?!a.helpUrl():!a.helpUrl),text:Blockly.Msg.HELP,callback:function(){a.showHelp_()}}};
Blockly.ContextMenu.blockDuplicateOption=function(a,b){return{text:Blockly.Msg.DUPLICATE,enabled:!0,callback:Blockly.scratchBlocksUtils.duplicateAndDragCallback(a,b)}};Blockly.ContextMenu.blockCommentOption=function(a){var b={enabled:!goog.userAgent.IE};a.comment?(b.text=Blockly.Msg.REMOVE_COMMENT,b.callback=function(){a.setCommentText(null)}):(b.text=Blockly.Msg.ADD_COMMENT,b.callback=function(){a.setCommentText("");a.comment.focus()});return b};
Blockly.ContextMenu.wsUndoOption=function(a){return{text:Blockly.Msg.UNDO,enabled:a.hasUndoStack(),callback:a.undo.bind(a,!1)}};Blockly.ContextMenu.wsRedoOption=function(a){return{text:Blockly.Msg.REDO,enabled:a.hasRedoStack(),callback:a.undo.bind(a,!0)}};Blockly.ContextMenu.wsCleanupOption=function(a,b){return{text:Blockly.Msg.CLEAN_UP,enabled:1<b,callback:a.cleanUp.bind(a,!0)}};
Blockly.ContextMenu.toggleCollapseFn_=function(a,b){for(var c=0,d=0;d<a.length;d++)for(var e=a[d];e;)setTimeout(e.setCollapsed.bind(e,b),c),e=e.getNextBlock(),c+=10};Blockly.ContextMenu.wsCollapseOption=function(a,b){return{enabled:a,text:Blockly.Msg.COLLAPSE_ALL,callback:function(){Blockly.ContextMenu.toggleCollapseFn_(b,!0)}}};Blockly.ContextMenu.wsExpandOption=function(a,b){return{enabled:a,text:Blockly.Msg.EXPAND_ALL,callback:function(){Blockly.ContextMenu.toggleCollapseFn_(b,!1)}}};
Blockly.ContextMenu.commentDeleteOption=function(a){return{text:Blockly.Msg.DELETE,enabled:!0,callback:function(){Blockly.Events.setGroup(!0);a.dispose(!0,!0);Blockly.Events.setGroup(!1)}}};Blockly.ContextMenu.commentDuplicateOption=function(a){return{text:Blockly.Msg.DUPLICATE,enabled:!0,callback:function(){Blockly.duplicate_(a)}}};
Blockly.ContextMenu.workspaceCommentOption=function(a,b){var c={enabled:!0};c.text=Blockly.Msg.ADD_COMMENT;c.callback=function(){var c=!1;Blockly.Events.isEnabled()&&(Blockly.Events.disable(),c=!0);var e=new Blockly.WorkspaceCommentSvg(a,"",Blockly.WorkspaceCommentSvg.DEFAULT_SIZE,Blockly.WorkspaceCommentSvg.DEFAULT_SIZE,!1),f=a.getInjectionDiv().getBoundingClientRect();f=new goog.math.Coordinate(b.clientX-f.left,b.clientY-f.top);var g=a.getOriginOffsetInPixels();f=goog.math.Coordinate.difference(f,
g).scale(1/a.scale);e.moveBy(f.x,f.y);a.rendered&&(e.initSvg(),e.render(!1),e.select());c&&Blockly.Events.enable();Blockly.WorkspaceComment.fireCreateEvent(e)};return c};
Blockly.ZoomControls=function(a){this.workspace_=a};Blockly.ZoomControls.prototype.ZOOM_OUT_PATH_="zoomout.svg";Blockly.ZoomControls.prototype.ZOOM_IN_PATH_="zoomin.svg";Blockly.ZoomControls.prototype.ZOOM_RESET_PATH_="zoomreset.svg";Blockly.ZoomControls.prototype.UNDO_PATH_="geri.svg";Blockly.ZoomControls.prototype.REDO_PATH_="ileri.svg";Blockly.ZoomControls.prototype.All_DELETE_PATH_="all_delete.svg";Blockly.ZoomControls.prototype.WIDTH_=36;Blockly.ZoomControls.prototype.HEIGHT_=124;
Blockly.ZoomControls.prototype.MARGIN_BETWEEN_=8;Blockly.ZoomControls.prototype.MARGIN_BOTTOM_=12;Blockly.ZoomControls.prototype.MARGIN_SIDE_=12;Blockly.ZoomControls.prototype.svgGroup_=null;Blockly.ZoomControls.prototype.left_=0;Blockly.ZoomControls.prototype.top_=0;
Blockly.ZoomControls.prototype.createDom=function(){this.svgGroup_=Blockly.utils.createSvgElement("g",{},null);this.createZoomOutSvg_();this.createZoomInSvg_();this.createZoomResetSvg_();this.createUndoSvg_();this.createRedoSvg_();this.createDeleteSvg_();return this.svgGroup_};Blockly.ZoomControls.prototype.init=function(a){this.bottom_=this.MARGIN_BOTTOM_+a;return this.bottom_+this.HEIGHT_};
Blockly.ZoomControls.prototype.dispose=function(){this.svgGroup_&&(goog.dom.removeNode(this.svgGroup_),this.svgGroup_=null);this.workspace_=null};
Blockly.ZoomControls.prototype.position=function(){var a=this.workspace_.getMetrics();a&&(this.workspace_.horizontalLayout?this.workspace_.RTL?(this.left_=this.MARGIN_SIDE_+Blockly.Scrollbar.scrollbarThickness,a.toolboxPosition==Blockly.TOOLBOX_AT_RIGHT&&(this.left_-=a.flyoutWidth)):(this.left_=a.viewWidth+a.absoluteLeft-this.WIDTH_-this.MARGIN_SIDE_-Blockly.Scrollbar.scrollbarThickness,a.toolboxPosition==Blockly.TOOLBOX_AT_LEFT&&(this.left_+=a.flyoutWidth,this.workspace_.toolbox_&&(this.left_+=a.absoluteLeft))):
this.workspace_.RTL?(this.left_=this.MARGIN_SIDE_+Blockly.Scrollbar.scrollbarThickness,a.toolboxPosition==Blockly.TOOLBOX_AT_LEFT&&(this.left_+=a.flyoutWidth,this.workspace_.toolbox_&&(this.left_+=a.absoluteLeft))):(this.left_=a.viewWidth+a.absoluteLeft-this.WIDTH_-this.MARGIN_SIDE_-Blockly.Scrollbar.scrollbarThickness,a.toolboxPosition==Blockly.TOOLBOX_AT_RIGHT&&(this.left_-=a.flyoutWidth)),this.top_=a.viewHeight+a.absoluteTop-this.HEIGHT_-this.bottom_,a.toolboxPosition==Blockly.TOOLBOX_AT_BOTTOM&&
(this.top_-=a.flyoutHeight),this.svgGroup_.setAttribute("transform","translate("+this.left_+","+this.top_+")"))};
Blockly.ZoomControls.prototype.createUndoSvg_=function(){var a=this.workspace_,b=Blockly.utils.createSvgElement("image",this.workspace_.horizontalLayout?{width:this.WIDTH_,height:this.WIDTH_,y:-112,x:-200}:{width:this.WIDTH_,height:this.WIDTH_,y:2*this.WIDTH_+2*this.MARGIN_BETWEEN_,x:-120},this.svgGroup_);b.setAttributeNS("http://www.w3.org/1999/xlink","xlink:href",a.options.pathToMedia+this.UNDO_PATH_);Blockly.bindEventWithChecks_(b,"mousedown",null,function(b){a.undo(!1)})};
Blockly.ZoomControls.prototype.createRedoSvg_=function(){var a=this.workspace_,b=Blockly.utils.createSvgElement("image",this.workspace_.horizontalLayout?{width:this.WIDTH_,height:this.WIDTH_,y:-112,x:-150}:{width:this.WIDTH_,height:this.WIDTH_,y:2*this.WIDTH_+2*this.MARGIN_BETWEEN_,x:-60},this.svgGroup_);b.setAttributeNS("http://www.w3.org/1999/xlink","xlink:href",a.options.pathToMedia+this.REDO_PATH_);Blockly.bindEventWithChecks_(b,"mousedown",null,function(b){a.undo(!0)})};
Blockly.ZoomControls.prototype.createZoomOutSvg_=function(){var a=this.workspace_,b=Blockly.utils.createSvgElement("image",this.workspace_.horizontalLayout?{width:this.WIDTH_,height:this.WIDTH_,y:-112,x:-50}:{width:this.WIDTH_,height:this.WIDTH_,y:1*this.WIDTH_+1*this.MARGIN_BETWEEN_},this.svgGroup_);b.setAttributeNS("http://www.w3.org/1999/xlink","xlink:href",a.options.pathToMedia+this.ZOOM_OUT_PATH_);Blockly.bindEventWithChecks_(b,"mousedown",null,function(b){a.markFocused();a.zoomCenter(-1);Blockly.Touch.clearTouchIdentifier();
b.stopPropagation();b.preventDefault()})};
Blockly.ZoomControls.prototype.createZoomInSvg_=function(){var a=this.workspace_,b=Blockly.utils.createSvgElement("image",this.workspace_.horizontalLayout?{width:this.WIDTH_,height:this.WIDTH_,y:-112,x:-100}:{width:this.WIDTH_,height:this.WIDTH_,y:0},this.svgGroup_);b.setAttributeNS("http://www.w3.org/1999/xlink","xlink:href",a.options.pathToMedia+this.ZOOM_IN_PATH_);Blockly.bindEventWithChecks_(b,"mousedown",null,function(b){a.markFocused();a.zoomCenter(1);Blockly.Touch.clearTouchIdentifier();b.stopPropagation();
b.preventDefault()})};
Blockly.ZoomControls.prototype.createZoomResetSvg_=function(){var a=this.workspace_,b=Blockly.utils.createSvgElement("image",this.workspace_.horizontalLayout?{width:this.WIDTH_,height:this.WIDTH_,y:-112,x:0}:{width:this.WIDTH_,height:this.WIDTH_,y:2*this.WIDTH_+2*this.MARGIN_BETWEEN_},this.svgGroup_);b.setAttributeNS("http://www.w3.org/1999/xlink","xlink:href",a.options.pathToMedia+this.ZOOM_RESET_PATH_);Blockly.bindEventWithChecks_(b,"mousedown",null,function(b){a.markFocused();a.setScale(a.options.zoomOptions.startScale);
a.scrollCenter();Blockly.Touch.clearTouchIdentifier();b.stopPropagation();b.preventDefault()})};
Blockly.ZoomControls.prototype.createDeleteSvg_=function(){var a=this.workspace_,b=Blockly.utils.createSvgElement("image",this.workspace_.horizontalLayout?{width:this.WIDTH_,height:this.WIDTH_,y:-112,x:-250}:{width:this.WIDTH_,height:this.WIDTH_,y:2*this.WIDTH_+2*this.MARGIN_BETWEEN_-130},this.svgGroup_);b.setAttributeNS("http://www.w3.org/1999/xlink","xlink:href",a.options.pathToMedia+this.All_DELETE_PATH_);Blockly.bindEventWithChecks_(b,"mousedown",null,function(a){Blockly.mainWorkspace.clear()})};
Blockly.WorkspaceSvg=function(a,b,c){Blockly.WorkspaceSvg.superClass_.constructor.call(this,a);this.getMetrics=a.getMetrics||Blockly.WorkspaceSvg.getTopLevelWorkspaceMetrics_;this.setMetrics=a.setMetrics||Blockly.WorkspaceSvg.setTopLevelWorkspaceMetrics_;Blockly.ConnectionDB.init(this);b&&(this.blockDragSurface_=b);c&&(this.workspaceDragSurface_=c);this.useWorkspaceDragSurface_=this.workspaceDragSurface_&&Blockly.utils.is3dSupported();this.highlightedBlocks_=[];this.audioManager_=new Blockly.WorkspaceAudio(a.parentWorkspace);
this.grid_=this.options.gridPattern?new Blockly.Grid(a.gridPattern,a.gridOptions):null;this.registerToolboxCategoryCallback(Blockly.VARIABLE_CATEGORY_NAME,Blockly.DataCategory);this.registerToolboxCategoryCallback(Blockly.PROCEDURE_CATEGORY_NAME,Blockly.Procedures.flyoutCategory)};goog.inherits(Blockly.WorkspaceSvg,Blockly.Workspace);Blockly.WorkspaceSvg.prototype.resizeHandlerWrapper_=null;Blockly.WorkspaceSvg.prototype.rendered=!0;Blockly.WorkspaceSvg.prototype.isVisible_=!0;
Blockly.WorkspaceSvg.prototype.isFlyout=!1;Blockly.WorkspaceSvg.prototype.isMutator=!1;Blockly.WorkspaceSvg.prototype.resizesEnabled_=!0;Blockly.WorkspaceSvg.prototype.toolboxRefreshEnabled_=!0;Blockly.WorkspaceSvg.prototype.scrollX=0;Blockly.WorkspaceSvg.prototype.scrollY=0;Blockly.WorkspaceSvg.prototype.startScrollX=0;Blockly.WorkspaceSvg.prototype.startScrollY=0;Blockly.WorkspaceSvg.prototype.dragDeltaXY_=null;Blockly.WorkspaceSvg.prototype.scale=1;Blockly.WorkspaceSvg.prototype.trashcan=null;
Blockly.WorkspaceSvg.prototype.scrollbar=null;Blockly.WorkspaceSvg.prototype.currentGesture_=null;Blockly.WorkspaceSvg.prototype.blockDragSurface_=null;Blockly.WorkspaceSvg.prototype.workspaceDragSurface_=null;Blockly.WorkspaceSvg.prototype.useWorkspaceDragSurface_=!1;Blockly.WorkspaceSvg.prototype.isDragSurfaceActive_=!1;Blockly.WorkspaceSvg.prototype.injectionDiv_=null;Blockly.WorkspaceSvg.prototype.lastRecordedPageScroll_=null;Blockly.WorkspaceSvg.prototype.flyoutButtonCallbacks_={};
Blockly.WorkspaceSvg.prototype.toolboxCategoryCallbacks_={};Blockly.WorkspaceSvg.prototype.inverseScreenCTM_=null;Blockly.WorkspaceSvg.prototype.inverseScreenCTMDirty_=!0;Blockly.WorkspaceSvg.prototype.getInverseScreenCTM=function(){if(this.inverseScreenCTMDirty_){var a=this.getParentSvg().getScreenCTM();a&&(this.inverseScreenCTM_=a.inverse(),this.inverseScreenCTMDirty_=!1)}return this.inverseScreenCTM_};Blockly.WorkspaceSvg.prototype.isVisible=function(){return this.isVisible_};
Blockly.WorkspaceSvg.prototype.updateInverseScreenCTM=function(){this.inverseScreenCTMDirty_=!0};Blockly.WorkspaceSvg.prototype.getSvgXY=function(a){var b=0,c=0,d=1;if(goog.dom.contains(this.getCanvas(),a)||goog.dom.contains(this.getBubbleCanvas(),a))d=this.scale;do{var e=Blockly.utils.getRelativeXY(a);if(a==this.getCanvas()||a==this.getBubbleCanvas())d=1;b+=e.x*d;c+=e.y*d;a=a.parentNode}while(a&&a!=this.getParentSvg());return new goog.math.Coordinate(b,c)};
Blockly.WorkspaceSvg.prototype.getOriginOffsetInPixels=function(){return Blockly.utils.getInjectionDivXY_(this.svgBlockCanvas_)};Blockly.WorkspaceSvg.prototype.getInjectionDiv=function(){if(!this.injectionDiv_)for(var a=this.svgGroup_;a;){if(-1!=(" "+(a.getAttribute("class")||"")+" ").indexOf(" injectionDiv ")){this.injectionDiv_=a;break}a=a.parentNode}return this.injectionDiv_};Blockly.WorkspaceSvg.prototype.setResizeHandlerWrapper=function(a){this.resizeHandlerWrapper_=a};
Blockly.WorkspaceSvg.prototype.createDom=function(a){this.svgGroup_=Blockly.utils.createSvgElement("g",{"class":"blocklyWorkspace"},null);a&&(this.svgBackground_=Blockly.utils.createSvgElement("rect",{height:"100%",width:"100%","class":a},this.svgGroup_),"blocklyMainBackground"==a&&this.grid_&&(this.svgBackground_.style.fill="url(#"+this.grid_.getPatternId()+")"));this.svgBlockCanvas_=Blockly.utils.createSvgElement("g",{"class":"blocklyBlockCanvas"},this.svgGroup_,this);this.svgBubbleCanvas_=Blockly.utils.createSvgElement("g",
{"class":"blocklyBubbleCanvas"},this.svgGroup_,this);a=Blockly.Scrollbar.scrollbarThickness;this.options.hasTrashcan&&(a=this.addTrashcan_(a));this.options.zoomOptions&&this.options.zoomOptions.controls&&this.addZoomControls_(a);this.isFlyout||(Blockly.bindEventWithChecks_(this.svgGroup_,"mousedown",this,this.onMouseDown_),this.options.zoomOptions&&this.options.zoomOptions.wheel&&Blockly.bindEventWithChecks_(this.svgGroup_,"wheel",this,this.onMouseWheel_));this.options.hasCategories&&(this.toolbox_=
new Blockly.Toolbox(this));this.grid_&&this.grid_.update(this.scale);this.recordCachedAreas();return this.svgGroup_};
Blockly.WorkspaceSvg.prototype.dispose=function(){this.rendered=!1;this.currentGesture_&&this.currentGesture_.cancel();Blockly.WorkspaceSvg.superClass_.dispose.call(this);this.svgGroup_&&(goog.dom.removeNode(this.svgGroup_),this.svgGroup_=null);this.svgBubbleCanvas_=this.svgBlockCanvas_=null;this.toolbox_&&(this.toolbox_.dispose(),this.toolbox_=null);this.flyout_&&(this.flyout_.dispose(),this.flyout_=null);this.trashcan&&(this.trashcan.dispose(),this.trashcan=null);this.scrollbar&&(this.scrollbar.dispose(),
this.scrollbar=null);this.zoomControls_&&(this.zoomControls_.dispose(),this.zoomControls_=null);this.audioManager_&&(this.audioManager_.dispose(),this.audioManager_=null);this.grid_&&(this.grid_.dispose(),this.grid_=null);this.toolboxCategoryCallbacks_&&(this.toolboxCategoryCallbacks_=null);this.flyoutButtonCallbacks_&&(this.flyoutButtonCallbacks_=null);this.options.parentWorkspace||goog.dom.removeNode(this.getParentSvg().parentNode);this.resizeHandlerWrapper_&&(Blockly.unbindEvent_(this.resizeHandlerWrapper_),
this.resizeHandlerWrapper_=null)};Blockly.WorkspaceSvg.prototype.newBlock=function(a,b){return new Blockly.BlockSvg(this,a,b)};Blockly.WorkspaceSvg.prototype.addTrashcan_=function(a){this.trashcan=new Blockly.Trashcan(this);var b=this.trashcan.createDom();this.svgGroup_.insertBefore(b,this.svgBlockCanvas_);return this.trashcan.init(a)};
Blockly.WorkspaceSvg.prototype.addZoomControls_=function(a){this.zoomControls_=new Blockly.ZoomControls(this);var b=this.zoomControls_.createDom();this.svgGroup_.appendChild(b);return this.zoomControls_.init(a)};
Blockly.WorkspaceSvg.prototype.addFlyout_=function(a){var b={disabledPatternId:this.options.disabledPatternId,parentWorkspace:this,RTL:this.RTL,oneBasedIndex:this.options.oneBasedIndex,horizontalLayout:this.horizontalLayout,toolboxPosition:this.options.toolboxPosition,stackGlowFilterId:this.options.stackGlowFilterId};this.flyout_=this.horizontalLayout?new Blockly.HorizontalFlyout(b):new Blockly.VerticalFlyout(b);this.flyout_.autoClose=!1;return this.flyout_.createDom(a)};
Blockly.WorkspaceSvg.prototype.getFlyout=function(){return this.flyout_?this.flyout_:this.toolbox_?this.toolbox_.flyout_:null};Blockly.WorkspaceSvg.prototype.getToolbox=function(){return this.toolbox_};Blockly.WorkspaceSvg.prototype.updateScreenCalculations_=function(){this.updateInverseScreenCTM();this.recordCachedAreas()};Blockly.WorkspaceSvg.prototype.resizeContents=function(){this.resizesEnabled_&&this.rendered&&(this.scrollbar&&this.scrollbar.resize(),this.updateInverseScreenCTM())};
Blockly.WorkspaceSvg.prototype.resize=function(){this.toolbox_&&this.toolbox_.position();this.flyout_&&this.flyout_.position();this.trashcan&&this.trashcan.position();this.zoomControls_&&this.zoomControls_.position();this.scrollbar&&this.scrollbar.resize();this.updateScreenCalculations_()};Blockly.WorkspaceSvg.prototype.updateScreenCalculationsIfScrolled=function(){var a=goog.dom.getDocumentScroll();goog.math.Coordinate.equals(this.lastRecordedPageScroll_,a)||(this.lastRecordedPageScroll_=a,this.updateScreenCalculations_())};
Blockly.WorkspaceSvg.prototype.getCanvas=function(){return this.svgBlockCanvas_};Blockly.WorkspaceSvg.prototype.getBubbleCanvas=function(){return this.svgBubbleCanvas_};Blockly.WorkspaceSvg.prototype.getParentSvg=function(){if(this.cachedParentSvg_)return this.cachedParentSvg_;for(var a=this.svgGroup_;a;){if("svg"==a.tagName)return this.cachedParentSvg_=a;a=a.parentNode}return null};
Blockly.WorkspaceSvg.prototype.translate=function(a,b){if(this.useWorkspaceDragSurface_&&this.isDragSurfaceActive_)this.workspaceDragSurface_.translateSurface(a,b);else{var c="translate("+a+","+b+") scale("+this.scale+")";this.svgBlockCanvas_.setAttribute("transform",c);this.svgBubbleCanvas_.setAttribute("transform",c)}this.blockDragSurface_&&this.blockDragSurface_.translateAndScaleGroup(a,b,this.scale)};
Blockly.WorkspaceSvg.prototype.resetDragSurface=function(){if(this.useWorkspaceDragSurface_){this.isDragSurfaceActive_=!1;var a=this.workspaceDragSurface_.getSurfaceTranslation();this.workspaceDragSurface_.clearAndHide(this.svgGroup_);a="translate("+a.x+","+a.y+") scale("+this.scale+")";this.svgBlockCanvas_.setAttribute("transform",a);this.svgBubbleCanvas_.setAttribute("transform",a)}};
Blockly.WorkspaceSvg.prototype.setupDragSurface=function(){if(this.useWorkspaceDragSurface_&&!this.isDragSurfaceActive_){this.isDragSurfaceActive_=!0;var a=this.svgBlockCanvas_.previousSibling,b=parseInt(this.getParentSvg().getAttribute("width"),10),c=parseInt(this.getParentSvg().getAttribute("height"),10),d=Blockly.utils.getRelativeXY(this.svgBlockCanvas_);this.workspaceDragSurface_.setContentsAndShow(this.svgBlockCanvas_,this.svgBubbleCanvas_,a,b,c,this.scale);this.workspaceDragSurface_.translateSurface(d.x,
d.y)}};Blockly.WorkspaceSvg.prototype.getBlockDragSurface=function(){return this.blockDragSurface_};Blockly.WorkspaceSvg.prototype.getWidth=function(){var a=this.getMetrics();return a?a.viewWidth/this.scale:0};
Blockly.WorkspaceSvg.prototype.setVisible=function(a){this.scrollbar&&this.scrollbar.setContainerVisible(a);this.getFlyout()&&this.getFlyout().setContainerVisible(a);this.getParentSvg().style.display=a?"block":"none";this.toolbox_&&(this.toolbox_.HtmlDiv.style.display=a?"block":"none");a?(this.render(),this.resize()):(Blockly.hideChaff(!0),Blockly.DropDownDiv.hideWithoutAnimation());this.isVisible_=a};Blockly.WorkspaceSvg.prototype.render=function(){for(var a=this.getAllBlocks(),b=a.length-1;0<=b;b--)a[b].render(!1)};
Blockly.WorkspaceSvg.prototype.traceOn=function(){console.warn("Deprecated call to traceOn, delete this.")};Blockly.WorkspaceSvg.prototype.highlightBlock=function(a,b){if(void 0===b){for(var c=0,d;d=this.highlightedBlocks_[c];c++)d.setHighlighted(!1);this.highlightedBlocks_.length=0}if(d=a?this.getBlockById(a):null)(a=void 0===b||b)?-1==this.highlightedBlocks_.indexOf(d)&&this.highlightedBlocks_.push(d):goog.array.remove(this.highlightedBlocks_,d),d.setHighlighted(a)};
Blockly.WorkspaceSvg.prototype.glowBlock=function(a,b){var c=null;if(a&&(c=this.getBlockById(a),!c))throw"Tried to glow block that does not exist.";c.setGlowBlock(b)};Blockly.WorkspaceSvg.prototype.glowStack=function(a,b){var c=null;if(a&&(c=this.getBlockById(a),!c))throw"Tried to glow stack on block that does not exist.";c.setGlowStack(b)};
Blockly.WorkspaceSvg.prototype.reportValue=function(a,b){a=this.getBlockById(a);if(!a)throw"Tried to report value on block that does not exist.";Blockly.DropDownDiv.hideWithoutAnimation();Blockly.DropDownDiv.clearContent();var c=Blockly.DropDownDiv.getContentDiv(),d=goog.dom.createElement("div");d.setAttribute("class","valueReportBox");d.innerHTML=Blockly.scratchBlocksUtils.encodeEntities(b);c.appendChild(d);Blockly.DropDownDiv.setColour(Blockly.Colours.valueReportBackground,Blockly.Colours.valueReportBorder);
Blockly.DropDownDiv.showPositionedByBlock(this,a)};Blockly.WorkspaceSvg.prototype.paste=function(a){this.rendered&&(this.currentGesture_&&this.currentGesture_.cancel(),"comment"==a.tagName.toLowerCase()?this.pasteWorkspaceComment_(a):this.pasteBlock_(a))};
Blockly.WorkspaceSvg.prototype.pasteBlock_=function(a){Blockly.Events.disable();try{var b=Blockly.Xml.domToBlock(a,this);Blockly.scratchBlocksUtils.changeObscuredShadowIds(b);var c=parseInt(a.getAttribute("x"),10),d=parseInt(a.getAttribute("y"),10);if(!isNaN(c)&&!isNaN(d)){this.RTL&&(c=-c);do{a=!1;for(var e=this.getAllBlocks(),f=0,g;g=e[f];f++){var h=g.getRelativeToSurfaceXY();if(1>=Math.abs(c-h.x)&&1>=Math.abs(d-h.y)){a=!0;break}}if(!a){var k=b.getConnections_(!1);f=0;for(var l;l=k[f];f++)if(l.closest(Blockly.SNAP_RADIUS,
new goog.math.Coordinate(c,d)).connection){a=!0;break}}a&&(c=this.RTL?c-Blockly.SNAP_RADIUS:c+Blockly.SNAP_RADIUS,d+=2*Blockly.SNAP_RADIUS)}while(a);b.moveBy(c,d)}}finally{Blockly.Events.enable()}Blockly.Events.isEnabled()&&!b.isShadow()&&Blockly.Events.fire(new Blockly.Events.BlockCreate(b));b.select()};
Blockly.WorkspaceSvg.prototype.pasteWorkspaceComment_=function(a){Blockly.Events.disable();try{var b=Blockly.WorkspaceCommentSvg.fromXml(a,this),c=parseInt(a.getAttribute("x"),10),d=parseInt(a.getAttribute("y"),10);isNaN(c)||isNaN(d)||(this.RTL&&(c=-c),b.moveBy(c+50,d+50))}finally{Blockly.Events.enable()}Blockly.Events.isEnabled()&&Blockly.WorkspaceComment.fireCreateEvent(b);b.select()};
Blockly.WorkspaceSvg.prototype.refreshToolboxSelection_=function(){if(this.toolbox_)this.toolbox_.flyout_&&!this.currentGesture_&&this.toolboxRefreshEnabled_&&this.toolbox_.refreshSelection();else{var a=this.targetWorkspace;a&&a.toolbox_&&a.toolbox_.flyout_&&!a.currentGesture_&&a.toolboxRefreshEnabled_&&a.toolbox_.refreshSelection()}};Blockly.WorkspaceSvg.prototype.renameVariableById=function(a,b){Blockly.WorkspaceSvg.superClass_.renameVariableById.call(this,a,b);this.refreshToolboxSelection_()};
Blockly.WorkspaceSvg.prototype.deleteVariableById=function(a){Blockly.WorkspaceSvg.superClass_.deleteVariableById.call(this,a);this.refreshToolboxSelection_()};Blockly.WorkspaceSvg.prototype.createVariable=function(a,b,c,d,e){var f=null!=this.getVariable(a,b);a=Blockly.WorkspaceSvg.superClass_.createVariable.call(this,a,b,c,d,e);f||b==Blockly.BROADCAST_MESSAGE_VARIABLE_TYPE||this.refreshToolboxSelection_();return a};
Blockly.WorkspaceSvg.prototype.recordCachedAreas=function(){this.recordBlocksArea_();this.recordDeleteAreas_()};Blockly.WorkspaceSvg.prototype.recordDeleteAreas_=function(){this.deleteAreaTrash_=this.trashcan?this.trashcan.getClientRect():null;this.deleteAreaToolbox_=this.flyout_?this.flyout_.getClientRect():this.toolbox_?this.toolbox_.getClientRect():null};
Blockly.WorkspaceSvg.prototype.recordBlocksArea_=function(){var a=this.getParentSvg();a?(a=a.getBoundingClientRect(),this.blocksArea_=new goog.math.Rect(a.left,a.top,a.width,a.height)):this.blocksArea_=null};Blockly.WorkspaceSvg.prototype.isDeleteArea=function(a){a=new goog.math.Coordinate(a.clientX,a.clientY);return this.deleteAreaTrash_&&this.deleteAreaTrash_.contains(a)?Blockly.DELETE_AREA_TRASH:this.deleteAreaToolbox_&&this.deleteAreaToolbox_.contains(a)?Blockly.DELETE_AREA_TOOLBOX:Blockly.DELETE_AREA_NONE};
Blockly.WorkspaceSvg.prototype.isInsideBlocksArea=function(a){var b=new goog.math.Coordinate(a.clientX,a.clientY);return this.isDeleteArea(a)||this.blocksArea_&&this.blocksArea_.contains(b)?!0:!1};Blockly.WorkspaceSvg.prototype.onMouseDown_=function(a){var b=this.getGesture(a);b&&b.handleWsStart(a,this)};
Blockly.WorkspaceSvg.prototype.startDrag=function(a,b){a=Blockly.utils.mouseToSvg(a,this.getParentSvg(),this.getInverseScreenCTM());a.x/=this.scale;a.y/=this.scale;this.dragDeltaXY_=goog.math.Coordinate.difference(b,a)};Blockly.WorkspaceSvg.prototype.moveDrag=function(a){a=Blockly.utils.mouseToSvg(a,this.getParentSvg(),this.getInverseScreenCTM());a.x/=this.scale;a.y/=this.scale;return goog.math.Coordinate.sum(this.dragDeltaXY_,a)};
Blockly.WorkspaceSvg.prototype.isDragging=function(){return this.currentGesture_&&this.currentGesture_.isDragging()};Blockly.WorkspaceSvg.prototype.isDraggable=function(){return!!this.scrollbar};
Blockly.WorkspaceSvg.prototype.onMouseWheel_=function(a){this.currentGesture_&&this.currentGesture_.cancel();var b=1===a.deltaMode?Blockly.LINE_SCROLL_MULTIPLIER:1;if(a.ctrlKey){b*=-a.deltaY/50;var c=Blockly.utils.mouseToSvg(a,this.getParentSvg(),this.getInverseScreenCTM());this.zoom(c.x,c.y,b)}else{Blockly.WidgetDiv.hide(!0);Blockly.DropDownDiv.hideWithoutAnimation();c=this.scrollX-a.deltaX*b;var d=this.scrollY-a.deltaY*b;a.shiftKey&&0===a.deltaX&&(c=this.scrollX-a.deltaY*b,d=this.scrollY);this.startDragMetrics=
this.getMetrics();this.scroll(c,d)}a.preventDefault()};
Blockly.WorkspaceSvg.prototype.getBlocksBoundingBox=function(){var a=this.getTopBlocks(!1),b=this.getTopComments(!1);a=a.concat(b);if(!a.length)return{x:0,y:0,width:0,height:0};b=a[0].getBoundingRectangle();for(var c=1;c<a.length;c++){var d=a[c].getBoundingRectangle();d.topLeft.x<b.topLeft.x&&(b.topLeft.x=d.topLeft.x);d.bottomRight.x>b.bottomRight.x&&(b.bottomRight.x=d.bottomRight.x);d.topLeft.y<b.topLeft.y&&(b.topLeft.y=d.topLeft.y);d.bottomRight.y>b.bottomRight.y&&(b.bottomRight.y=d.bottomRight.y)}return{x:b.topLeft.x,
y:b.topLeft.y,width:b.bottomRight.x-b.topLeft.x,height:b.bottomRight.y-b.topLeft.y}};Blockly.WorkspaceSvg.prototype.cleanUp=function(){this.setResizesEnabled(!1);Blockly.Events.setGroup(!0);for(var a=this.getTopBlocks(!0),b=0,c=0,d;d=a[c];c++){var e=d.getRelativeToSurfaceXY();d.moveBy(-e.x,b-e.y);d.snapToGrid();b=d.getRelativeToSurfaceXY().y+d.getHeightWidth().height+Blockly.BlockSvg.MIN_BLOCK_Y}Blockly.Events.setGroup(!1);this.setResizesEnabled(!0)};
Blockly.WorkspaceSvg.prototype.cleanUpBlocks=function(){function a(){Blockly.Events.setGroup(eventGroup);var b=c.shift();b&&(b.workspace?(b.dispose(!1,!0),setTimeout(a,10)):a());Blockly.Events.setGroup(!1)}var b=this.getTopBlocks(!0),c=Blockly.WorkspaceSvg.buildDeleteList_(b),d=0;for(b=0;b<c.length;b++)c[b].isShadow()||d++;return{text:1==d?Blockly.Msg.DELETE_BLOCK:Blockly.Msg.DELETE_X_BLOCKS.replace("%1",String(d)),enabled:0<d,callback:function(){ws.currentGesture_&&ws.currentGesture_.cancel();2>
d?a():Blockly.confirm(Blockly.Msg.DELETE_ALL_BLOCKS.replace("%1",String(d)),function(b){b&&a()})}}};
Blockly.WorkspaceSvg.prototype.showContextMenu_=function(a){function b(){Blockly.Events.setGroup(e);var a=m.shift();a&&(a.workspace?(a.dispose(!1,!0),setTimeout(b,10)):b());Blockly.Events.setGroup(!1)}if(!this.options.readOnly&&!this.isFlyout){var c=[],d=this.getTopBlocks(!0),e=Blockly.utils.genUid(),f=this;c.push(Blockly.ContextMenu.wsUndoOption(this));c.push(Blockly.ContextMenu.wsRedoOption(this));this.scrollbar&&c.push(Blockly.ContextMenu.wsCleanupOption(this,d.length));if(this.options.collapse){for(var g=
!1,h=!1,k=0;k<d.length;k++)for(var l=d[k];l;)l.isCollapsed()?g=!0:h=!0,l=l.getNextBlock();c.push(Blockly.ContextMenu.wsCollapseOption(h,d));c.push(Blockly.ContextMenu.wsExpandOption(g,d))}this.options.comments&&c.push(Blockly.ContextMenu.workspaceCommentOption(f,a));var m=Blockly.WorkspaceSvg.buildDeleteList_(d),n=0;for(k=0;k<m.length;k++)m[k].isShadow()||n++;d={text:1==n?Blockly.Msg.DELETE_BLOCK:Blockly.Msg.DELETE_X_BLOCKS.replace("%1",String(n)),enabled:0<n,callback:function(){f.currentGesture_&&
f.currentGesture_.cancel();2>n?b():Blockly.confirm(Blockly.Msg.DELETE_ALL_BLOCKS.replace("%1",String(n)),function(a){a&&b()})}};c.push(d);Blockly.ContextMenu.show(a,c,this.RTL)}};Blockly.WorkspaceSvg.buildDeleteList_=function(a){function b(a){if(a.isDeletable())c=c.concat(a.getDescendants(!1));else{a=a.getChildren();for(var d=0;d<a.length;d++)b(a[d])}}for(var c=[],d=0;d<a.length;d++)b(a[d]);return c};
Blockly.WorkspaceSvg.prototype.updateToolbox=function(a){if(a=Blockly.Options.parseToolboxTree(a)){if(!this.options.languageTree)throw"Existing toolbox is null.  Can't create new toolbox.";if(a.getElementsByTagName("category").length){if(!this.toolbox_)throw"Existing toolbox has no categories.  Can't change mode.";this.options.languageTree=a;this.toolbox_.populate_(a);this.toolbox_.position();this.updateWorkspaceBlocksDisabledState();Blockly.Events.fire(new Blockly.Events.UpdateToolboxFinish(this))}else{if(!this.flyout_)throw"Existing toolbox has categories.  Can't change mode.";
this.options.languageTree=a;this.flyout_.show(a.childNodes)}}else if(this.options.languageTree)throw"Can't nullify an existing toolbox.";};
Blockly.WorkspaceSvg.prototype.updateWorkspaceBlocksDisabledState=function(){var a=this.getAllBlocks(),b=this.getFlyout().getFlyoutItems();a.forEach(function(a){var c=["procedures_definition","argument_reporter_string_number","argument_reporter_number","argument_reporter_string","argument_reporter_boolean"];b.find(function(b){return b.type===a.type})||c.includes(a.type)||a.isShadow()?a.setEnabled(!0):a.setEnabled(!1)})};
Blockly.WorkspaceSvg.prototype.markFocused=function(){this.options.parentWorkspace?this.options.parentWorkspace.markFocused():(Blockly.mainWorkspace=this,this.setBrowserFocus())};Blockly.WorkspaceSvg.prototype.setBrowserFocus=function(){document.activeElement&&document.activeElement.blur();try{this.getParentSvg().focus()}catch(a){try{this.getParentSvg().parentNode.setActive()}catch(b){this.getParentSvg().parentNode.focus()}}};
Blockly.WorkspaceSvg.prototype.zoom=function(a,b,c){var d=this.options.zoomOptions.scaleSpeed,e=this.getMetrics(),f=this.getParentSvg().createSVGPoint();f.x=a;f.y=b;f=f.matrixTransform(this.getCanvas().getCTM().inverse());a=f.x;b=f.y;f=this.getCanvas();d=Math.pow(d,c);c=this.scale*d;c>this.options.zoomOptions.maxScale?d=this.options.zoomOptions.maxScale/this.scale:c<this.options.zoomOptions.minScale&&(d=this.options.zoomOptions.minScale/this.scale);this.scale!=c&&(this.scrollbar&&(a=f.getCTM().translate(a*
(1-d),b*(1-d)).scale(d),this.scrollX=a.e-e.absoluteLeft,this.scrollY=a.f-e.absoluteTop),this.setScale(c),Blockly.WidgetDiv.hide(!0),Blockly.DropDownDiv.hideWithoutAnimation())};Blockly.WorkspaceSvg.prototype.zoomCenter=function(a){var b=this.getMetrics();this.zoom(b.viewWidth/2,b.viewHeight/2,a)};
Blockly.WorkspaceSvg.prototype.zoomToFit=function(){var a=this.getMetrics(),b=this.getBlocksBoundingBox(),c=b.width;b=b.height;if(c){var d=a.viewWidth,e=a.viewHeight;this.flyout_&&(d-=this.flyout_.width_);this.scrollbar||(c+=a.contentLeft,b+=a.contentTop);this.setScale(Math.min(d/c,e/b));this.scrollCenter()}};
Blockly.WorkspaceSvg.prototype.scrollCenter=function(){if(this.scrollbar){Blockly.WidgetDiv.hide(!0);Blockly.DropDownDiv.hideWithoutAnimation();Blockly.hideChaff(!1);var a=this.getMetrics(),b=(a.contentWidth-a.viewWidth)/2;this.flyout_&&(b-=this.flyout_.width_/2);this.scrollbar.set(b,(a.contentHeight-a.viewHeight)/2)}else console.warn("Tried to scroll a non-scrollable workspace.")};
Blockly.WorkspaceSvg.prototype.centerOnBlock=function(a){if(this.scrollbar){if(a=this.getBlockById(a)){var b=a.getRelativeToSurfaceXY(),c=a.getHeightWidth(),d=this.scale;a=(b.x+(this.RTL?-1:1)*c.width/2)*d;b=(b.y+c.height/2)*d;c=this.getMetrics();a=a-c.contentLeft-c.viewWidth/2;b=b-c.contentTop-c.viewHeight/2;Blockly.hideChaff();this.scrollbar.set(a,b)}}else console.warn("Tried to scroll a non-scrollable workspace.")};
Blockly.WorkspaceSvg.prototype.setScale=function(a){this.options.zoomOptions.maxScale&&a>this.options.zoomOptions.maxScale?a=this.options.zoomOptions.maxScale:this.options.zoomOptions.minScale&&a<this.options.zoomOptions.minScale&&(a=this.options.zoomOptions.minScale);this.scale=a;this.grid_&&this.grid_.update(this.scale);this.scrollbar?this.scrollbar.resize():this.translate(this.scrollX,this.scrollY);Blockly.hideChaff(!1);this.flyout_&&this.flyout_.reflow()};
Blockly.WorkspaceSvg.prototype.scroll=function(a,b){var c=this.startDragMetrics;a=Math.min(a,-c.contentLeft);b=Math.min(b,-c.contentTop);a=Math.max(a,c.viewWidth-c.contentLeft-c.contentWidth);b=Math.max(b,c.viewHeight-c.contentTop-c.contentHeight);Blockly.WidgetDiv.hide(!0);Blockly.DropDownDiv.hideWithoutAnimation();this.scrollbar.set(-a-c.contentLeft,-b-c.contentTop)};
Blockly.WorkspaceSvg.prototype.updateStackGlowScale_=function(){this.options.stackGlowBlur&&this.options.stackGlowBlur.setAttribute("stdDeviation",Blockly.Colours.stackGlowSize/this.scale)};Blockly.WorkspaceSvg.getDimensionsPx_=function(a){var b=0,c=0;a&&(b=a.getWidth(),c=a.getHeight());return{width:b,height:c}};Blockly.WorkspaceSvg.getContentDimensions_=function(a,b){return a.scrollbar?Blockly.WorkspaceSvg.getContentDimensionsBounded_(a,b):Blockly.WorkspaceSvg.getContentDimensionsExact_(a)};
Blockly.WorkspaceSvg.getContentDimensionsExact_=function(a){var b=a.getBlocksBoundingBox(),c=a.scale;a=b.width*c;var d=b.height*c,e=b.x*c;b=b.y*c;return{left:e,top:b,right:e+a,bottom:b+d,width:a,height:d}};
Blockly.WorkspaceSvg.getContentDimensionsBounded_=function(a,b){a=Blockly.WorkspaceSvg.getContentDimensionsExact_(a);var c=b.width;b=b.height;var d=c/2,e=b/2,f=Math.min(a.left-d,a.right-c),g=Math.min(a.top-e,a.bottom-b);return{left:f,top:g,height:Math.max(a.bottom+e,a.top+b)-g,width:Math.max(a.right+d,a.left+c)-f}};
Blockly.WorkspaceSvg.getTopLevelWorkspaceMetrics_=function(){var a=Blockly.WorkspaceSvg.getDimensionsPx_(this.toolbox_),b=Blockly.WorkspaceSvg.getDimensionsPx_(this.flyout_),c=Blockly.svgSize(this.getParentSvg());if(this.toolbox_)if(this.toolboxPosition==Blockly.TOOLBOX_AT_TOP||this.toolboxPosition==Blockly.TOOLBOX_AT_BOTTOM)c.height-=a.height;else if(this.toolboxPosition==Blockly.TOOLBOX_AT_LEFT||this.toolboxPosition==Blockly.TOOLBOX_AT_RIGHT)c.width-=a.width;var d=Blockly.WorkspaceSvg.getContentDimensions_(this,
c),e=0;this.toolbox_&&this.toolboxPosition==Blockly.TOOLBOX_AT_LEFT&&(e=a.width);var f=0;this.toolbox_&&this.toolboxPosition==Blockly.TOOLBOX_AT_TOP&&(f=a.height);return{contentHeight:d.height,contentWidth:d.width,contentTop:d.top,contentLeft:d.left,viewHeight:c.height,viewWidth:c.width,viewTop:-this.scrollY,viewLeft:-this.scrollX,absoluteTop:f,absoluteLeft:e,toolboxWidth:a.width,toolboxHeight:a.height,flyoutWidth:b.width,flyoutHeight:b.height,toolboxPosition:this.toolboxPosition}};
Blockly.WorkspaceSvg.setTopLevelWorkspaceMetrics_=function(a){if(!this.scrollbar)throw"Attempt to set top level workspace scroll without scrollbars.";var b=this.getMetrics();goog.isNumber(a.x)&&(this.scrollX=-b.contentWidth*a.x-b.contentLeft);goog.isNumber(a.y)&&(this.scrollY=-b.contentHeight*a.y-b.contentTop);a=this.scrollX+b.absoluteLeft;b=this.scrollY+b.absoluteTop;this.translate(a,b);this.grid_&&this.grid_.moveTo(a,b)};
Blockly.WorkspaceSvg.prototype.setResizesEnabled=function(a){var b=!this.resizesEnabled_&&a;this.resizesEnabled_=a;b&&this.resizeContents()};Blockly.WorkspaceSvg.prototype.setToolboxRefreshEnabled=function(a){var b=!this.toolboxRefreshEnabled_&&a;this.toolboxRefreshEnabled_=a;b&&this.refreshToolboxSelection_()};Blockly.WorkspaceSvg.prototype.clear=function(){this.setResizesEnabled(!1);Blockly.WorkspaceSvg.superClass_.clear.call(this);this.setResizesEnabled(!0)};
Blockly.WorkspaceSvg.prototype.registerButtonCallback=function(a,b){goog.asserts.assert(goog.isFunction(b),"Button callbacks must be functions.");this.flyoutButtonCallbacks_[a]=b};Blockly.WorkspaceSvg.prototype.getButtonCallback=function(a){return(a=this.flyoutButtonCallbacks_[a])?a:null};Blockly.WorkspaceSvg.prototype.removeButtonCallback=function(a){this.flyoutButtonCallbacks_[a]=null};
Blockly.WorkspaceSvg.prototype.registerToolboxCategoryCallback=function(a,b){goog.asserts.assert(goog.isFunction(b),"Toolbox category callbacks must be functions.");this.toolboxCategoryCallbacks_[a]=b};Blockly.WorkspaceSvg.prototype.getToolboxCategoryCallback=function(a){return(a=this.toolboxCategoryCallbacks_[a])?a:null};Blockly.WorkspaceSvg.prototype.removeToolboxCategoryCallback=function(a){this.toolboxCategoryCallbacks_[a]=null};
Blockly.WorkspaceSvg.prototype.getGesture=function(a){var b="mousedown"==a.type||"touchstart"==a.type,c=this.currentGesture_;return c?b&&c.hasStarted()?(c.cancel(),null):c:b?this.currentGesture_=new Blockly.Gesture(a,this):null};Blockly.WorkspaceSvg.prototype.clearGesture=function(){this.currentGesture_=null};Blockly.WorkspaceSvg.prototype.cancelCurrentGesture=function(){this.currentGesture_&&this.currentGesture_.cancel()};
Blockly.WorkspaceSvg.prototype.startDragWithFakeEvent=function(a,b){Blockly.Touch.clearTouchIdentifier();Blockly.Touch.checkTouchIdentifier(a);b.workspace.getGesture(a).forceStartBlockDrag(a,b)};Blockly.WorkspaceSvg.prototype.getAudioManager=function(){return this.audioManager_};Blockly.WorkspaceSvg.prototype.getGrid=function(){return this.grid_};Blockly.WorkspaceSvg.prototype.setVisible=Blockly.WorkspaceSvg.prototype.setVisible;Blockly.Mutator=function(a){Blockly.Mutator.superClass_.constructor.call(this,null);this.quarkNames_=a};goog.inherits(Blockly.Mutator,Blockly.Icon);Blockly.Mutator.prototype.workspaceWidth_=0;Blockly.Mutator.prototype.workspaceHeight_=0;
Blockly.Mutator.prototype.drawIcon_=function(a){Blockly.utils.createSvgElement("rect",{"class":"blocklyIconShape",rx:"4",ry:"4",height:"16",width:"16"},a);Blockly.utils.createSvgElement("path",{"class":"blocklyIconSymbol",d:"m4.203,7.296 0,1.368 -0.92,0.677 -0.11,0.41 0.9,1.559 0.41,0.11 1.043,-0.457 1.187,0.683 0.127,1.134 0.3,0.3 1.8,0 0.3,-0.299 0.127,-1.138 1.185,-0.682 1.046,0.458 0.409,-0.11 0.9,-1.559 -0.11,-0.41 -0.92,-0.677 0,-1.366 0.92,-0.677 0.11,-0.41 -0.9,-1.559 -0.409,-0.109 -1.046,0.458 -1.185,-0.682 -0.127,-1.138 -0.3,-0.299 -1.8,0 -0.3,0.3 -0.126,1.135 -1.187,0.682 -1.043,-0.457 -0.41,0.11 -0.899,1.559 0.108,0.409z"},a);
Blockly.utils.createSvgElement("circle",{"class":"blocklyIconShape",r:"2.7",cx:"8",cy:"8"},a)};Blockly.Mutator.prototype.iconClick_=function(a){this.block_.isEditable()&&Blockly.Icon.prototype.iconClick_.call(this,a)};
Blockly.Mutator.prototype.createEditor_=function(){this.svgDialog_=Blockly.utils.createSvgElement("svg",{x:Blockly.Bubble.BORDER_WIDTH,y:Blockly.Bubble.BORDER_WIDTH},null);if(this.quarkNames_.length)for(var a=goog.dom.createDom("xml"),b=0,c;c=this.quarkNames_[b];b++)a.appendChild(goog.dom.createDom("block",{type:c}));else a=null;a={languageTree:a,parentWorkspace:this.block_.workspace,pathToMedia:this.block_.workspace.options.pathToMedia,RTL:this.block_.RTL,toolboxPosition:this.block_.RTL?Blockly.TOOLBOX_AT_RIGHT:
Blockly.TOOLBOX_AT_LEFT,horizontalLayout:!1,getMetrics:this.getFlyoutMetrics_.bind(this),setMetrics:null};this.workspace_=new Blockly.WorkspaceSvg(a,this.block_.workspace.dragSurface);this.workspace_.isMutator=!0;a=this.workspace_.addFlyout_("g");b=this.workspace_.createDom("blocklyMutatorBackground");b.insertBefore(a,this.workspace_.svgBlockCanvas_);this.svgDialog_.appendChild(b);return this.svgDialog_};
Blockly.Mutator.prototype.updateEditable=function(){this.block_.isInFlyout||(this.block_.isEditable()?this.iconGroup_&&Blockly.utils.removeClass(this.iconGroup_,"blocklyIconGroupReadonly"):(this.setVisible(!1),this.iconGroup_&&Blockly.utils.addClass(this.iconGroup_,"blocklyIconGroupReadonly")));Blockly.Icon.prototype.updateEditable.call(this)};
Blockly.Mutator.prototype.resizeBubble_=function(){var a=2*Blockly.Bubble.BORDER_WIDTH,b=this.workspace_.getCanvas().getBBox();var c=this.block_.RTL?-b.x:b.width+b.x;b=b.height+3*a;if(this.workspace_.flyout_){var d=this.workspace_.flyout_.getMetrics_();b=Math.max(b,d.contentHeight+20)}c+=3*a;if(Math.abs(this.workspaceWidth_-c)>a||Math.abs(this.workspaceHeight_-b)>a)this.workspaceWidth_=c,this.workspaceHeight_=b,this.bubble_.setBubbleSize(c+a,b+a),this.svgDialog_.setAttribute("width",this.workspaceWidth_),
this.svgDialog_.setAttribute("height",this.workspaceHeight_);this.block_.RTL&&(a="translate("+this.workspaceWidth_+",0)",this.workspace_.getCanvas().setAttribute("transform",a));this.workspace_.resize()};
Blockly.Mutator.prototype.setVisible=function(a){if(a!=this.isVisible())if(Blockly.Events.fire(new Blockly.Events.Ui(this.block_,"mutatorOpen",!a,a)),a){this.bubble_=new Blockly.Bubble(this.block_.workspace,this.createEditor_(),this.block_.svgPath_,this.iconXY_,null,null);if(a=this.workspace_.options.languageTree)this.workspace_.flyout_.init(this.workspace_),this.workspace_.flyout_.show(a.childNodes);this.rootBlock_=this.block_.decompose(this.workspace_);a=this.rootBlock_.getDescendants(!1);for(var b=
0,c;c=a[b];b++)c.render();this.rootBlock_.setMovable(!1);this.rootBlock_.setDeletable(!1);this.workspace_.flyout_?(a=2*this.workspace_.flyout_.CORNER_RADIUS,b=this.workspace_.flyout_.width_+a):b=a=16;this.block_.RTL&&(b=-b);this.rootBlock_.moveBy(b,a);if(this.block_.saveConnections){var d=this;this.block_.saveConnections(this.rootBlock_);this.sourceListener_=function(){d.block_.saveConnections(d.rootBlock_)};this.block_.workspace.addChangeListener(this.sourceListener_)}this.resizeBubble_();this.workspace_.addChangeListener(this.workspaceChanged_.bind(this));
this.updateColour()}else this.svgDialog_=null,this.workspace_.dispose(),this.rootBlock_=this.workspace_=null,this.bubble_.dispose(),this.bubble_=null,this.workspaceHeight_=this.workspaceWidth_=0,this.sourceListener_&&(this.block_.workspace.removeChangeListener(this.sourceListener_),this.sourceListener_=null)};
Blockly.Mutator.prototype.workspaceChanged_=function(){if(!this.workspace_.isDragging())for(var a=this.workspace_.getTopBlocks(!1),b=0,c;c=a[b];b++){var d=c.getRelativeToSurfaceXY(),e=c.getHeightWidth();20>d.y+e.height&&c.moveBy(0,20-e.height-d.y)}if(this.rootBlock_.workspace==this.workspace_){Blockly.Events.setGroup(!0);c=this.block_;a=(a=c.mutationToDom())&&Blockly.Xml.domToText(a);b=c.rendered;c.rendered=!1;c.compose(this.rootBlock_);c.rendered=b;c.initSvg();b=(b=c.mutationToDom())&&Blockly.Xml.domToText(b);
if(a!=b){Blockly.Events.fire(new Blockly.Events.BlockChange(c,"mutation",null,a,b));var f=Blockly.Events.getGroup();setTimeout(function(){Blockly.Events.setGroup(f);c.bumpNeighbours_();Blockly.Events.setGroup(!1)},Blockly.BUMP_DELAY)}c.rendered&&c.render();this.workspace_.isDragging()||this.resizeBubble_();Blockly.Events.setGroup(!1)}};Blockly.Mutator.prototype.getFlyoutMetrics_=function(){return{viewHeight:this.workspaceHeight_,viewWidth:this.workspaceWidth_,absoluteTop:0,absoluteLeft:0}};
Blockly.Mutator.prototype.dispose=function(){this.block_.mutator=null;Blockly.Icon.prototype.dispose.call(this)};Blockly.Mutator.reconnect=function(a,b,c){if(!a||!a.getSourceBlock().workspace)return!1;c=b.getInput(c).connection;var d=a.targetBlock();return d&&d!=b||c.targetConnection==a?!1:(c.isConnected()&&c.disconnect(),c.connect(a),!0)};goog.global.Blockly||(goog.global.Blockly={});goog.global.Blockly.Mutator||(goog.global.Blockly.Mutator={});goog.global.Blockly.Mutator.reconnect=Blockly.Mutator.reconnect;Blockly.Extensions={};Blockly.Extensions.ALL_={};Blockly.Extensions.register=function(a,b){if(!goog.isString(a)||goog.string.isEmptyOrWhitespace(a))throw Error('Error: Invalid extension name "'+a+'"');if(Blockly.Extensions.ALL_[a])throw Error('Error: Extension "'+a+'" is already registered.');if(!goog.isFunction(b))throw Error('Error: Extension "'+a+'" must be a function');Blockly.Extensions.ALL_[a]=b};
Blockly.Extensions.registerMixin=function(a,b){if(!goog.isObject(b))throw Error('Error: Mixin "'+a+'" must be a object');Blockly.Extensions.register(a,function(){this.mixin(b)})};
Blockly.Extensions.registerMutator=function(a,b,c,d){var e='Error when registering mutator "'+a+'": ';Blockly.Extensions.checkHasFunction_(e,b.domToMutation,"domToMutation");Blockly.Extensions.checkHasFunction_(e,b.mutationToDom,"mutationToDom");var f=Blockly.Extensions.checkMutatorDialog_(b,e);if(c&&!goog.isFunction(c))throw Error('Extension "'+a+'" is not a function');Blockly.Extensions.register(a,function(){f&&this.setMutator(new Blockly.Mutator(d));this.mixin(b);c&&c.apply(this)})};
Blockly.Extensions.apply=function(a,b,c){var d=Blockly.Extensions.ALL_[a];if(!goog.isFunction(d))throw Error('Error: Extension "'+a+'" not found.');if(c)Blockly.Extensions.checkNoMutatorProperties_(a,b);else var e=Blockly.Extensions.getMutatorProperties_(b);d.apply(b);if(c)Blockly.Extensions.checkBlockHasMutatorProperties_('Error after applying mutator "'+a+'": ',b);else if(!Blockly.Extensions.mutatorPropertiesMatch_(e,b))throw Error('Error when applying extension "'+a+'": mutation properties changed when applying a non-mutator extension.');
};Blockly.Extensions.checkHasFunction_=function(a,b,c){if(!b)throw Error(a+'missing required property "'+c+'"');if("function"!=typeof b)throw Error(a+'" required property "'+c+'" must be a function');};Blockly.Extensions.checkNoMutatorProperties_=function(a,b){if(Blockly.Extensions.getMutatorProperties_(b).length)throw Error('Error: tried to apply mutation "'+a+'" to a block that already has mutator functions.  Block id: '+b.id);};
Blockly.Extensions.checkMutatorDialog_=function(a,b){var c=void 0!==a.compose,d=void 0!==a.decompose;if(c&&d){if("function"!=typeof a.compose)throw Error(b+"compose must be a function.");if("function"!=typeof a.decompose)throw Error(b+"decompose must be a function.");return!0}if(c||d)throw Error(b+'Must have both or neither of "compose" and "decompose"');return!1};
Blockly.Extensions.checkBlockHasMutatorProperties_=function(a,b){if("function"!==typeof b.domToMutation)throw Error(a+'Applying a mutator didn\'t add "domToMutation"');if("function"!=typeof b.mutationToDom)throw Error(a+'Applying a mutator didn\'t add "mutationToDom"');Blockly.Extensions.checkMutatorDialog_(b,a)};
Blockly.Extensions.getMutatorProperties_=function(a){var b=[];void 0!==a.domToMutation&&b.push(a.domToMutation);void 0!==a.mutationToDom&&b.push(a.mutationToDom);void 0!==a.compose&&b.push(a.compose);void 0!==a.decompose&&b.push(a.decompose);return b};Blockly.Extensions.mutatorPropertiesMatch_=function(a,b){b=Blockly.Extensions.getMutatorProperties_(b);if(b.length!=a.length)return!1;for(var c=0;c<b.length;c++)if(a[c]!=b[c])return!1;return!0};
Blockly.Extensions.buildTooltipForDropdown=function(a,b){var c=[];document&&Blockly.utils.runAfterPageLoad(function(){for(var a in b)Blockly.utils.checkMessageReferences(b[a])});return function(){this.type&&-1===c.indexOf(this.type)&&(Blockly.Extensions.checkDropdownOptionsInTable_(this,a,b),c.push(this.type));this.setTooltip(function(){var d=this.getFieldValue(a),e=b[d];null==e?-1===c.indexOf(this.type)&&(d="No tooltip mapping for value "+d+" of field "+a,null!=this.type&&(d+=" of block type "+this.type),
console.warn(d+".")):e=Blockly.utils.replaceMessageReferences(e);return e}.bind(this))}};Blockly.Extensions.checkDropdownOptionsInTable_=function(a,b,c){var d=a.getField(b);if(!d.isOptionListDynamic()){d=d.getOptions();for(var e=0;e<d.length;++e){var f=d[e][1];null==c[f]&&console.warn("No tooltip mapping for value "+f+" of field "+b+" of block type "+a.type)}}};
Blockly.Extensions.buildTooltipWithFieldValue=function(a,b){document&&Blockly.utils.runAfterPageLoad(function(){Blockly.utils.checkMessageReferences(a)});return function(){this.setTooltip(function(){return Blockly.utils.replaceMessageReferences(a).replace("%1",this.getFieldValue(b))}.bind(this))}};Blockly.Extensions.extensionParentTooltip_=function(){this.tooltipWhenNotConnected_=this.tooltip;this.setTooltip(function(){var a=this.getParent();return a&&a.getInputsInline()&&a.tooltip||this.tooltipWhenNotConnected_}.bind(this))};
Blockly.Extensions.register("parent_tooltip_when_inline",Blockly.Extensions.extensionParentTooltip_);Blockly.Field=function(a,b){this.size_=new goog.math.Size(Blockly.BlockSvg.FIELD_WIDTH,Blockly.BlockSvg.FIELD_HEIGHT);this.setValue(a);this.setValidator(b);this.maxDisplayLength=Blockly.BlockSvg.MAX_DISPLAY_LENGTH};Blockly.Field.TYPE_MAP_={};
Blockly.Field.register=function(a,b){if(!goog.isString(a)||goog.string.isEmptyOrWhitespace(a))throw Error('Invalid field type "'+a+'"');if(!goog.isObject(b)||!goog.isFunction(b.fromJson))throw Error('Field "'+b+'" must have a fromJson function');Blockly.Field.TYPE_MAP_[a]=b};Blockly.Field.fromJson=function(a){var b=Blockly.Field.TYPE_MAP_[a.type];return b?b.fromJson(a):null};Blockly.Field.cacheWidths_=null;Blockly.Field.cacheReference_=0;Blockly.Field.prototype.name=void 0;
Blockly.Field.prototype.className_="blocklyText";Blockly.Field.prototype.text_="";Blockly.Field.prototype.sourceBlock_=null;Blockly.Field.prototype.visible_=!0;Blockly.Field.prototype.argType_=null;Blockly.Field.prototype.validator_=null;Blockly.Field.prototype.useTouchInteraction_=!1;Blockly.Field.NBSP="\u00a0";Blockly.Field.IE_TEXT_OFFSET="0.3em";Blockly.Field.prototype.EDITABLE=!0;Blockly.Field.prototype.SERIALIZABLE=!0;
Blockly.Field.prototype.setSourceBlock=function(a){goog.asserts.assert(!this.sourceBlock_,"Field already bound to a block.");this.sourceBlock_=a};
Blockly.Field.prototype.init=function(){if(!this.fieldGroup_){this.fieldGroup_=Blockly.utils.createSvgElement("g",{},null);this.visible_||(this.fieldGroup_.style.display="none");null!==this.getArgTypes()&&(this.sourceBlock_.isShadow()?this.sourceBlock_.svgGroup_.setAttribute("data-argument-type",this.getArgTypes()):this.fieldGroup_.setAttribute("data-argument-type",this.getArgTypes()));var a=this.getSize();this.textElement_=Blockly.utils.createSvgElement("text",{"class":this.className_,x:this.sourceBlock_.RTL?
-a.width/2:a.width/2,y:a.height/2+Blockly.BlockSvg.FIELD_TOP_PADDING,"dominant-baseline":"middle",dy:goog.userAgent.EDGE_OR_IE?Blockly.Field.IE_TEXT_OFFSET:"0","text-anchor":"middle"},this.fieldGroup_);this.updateEditable();this.sourceBlock_.getSvgRoot().appendChild(this.fieldGroup_);this.render_();this.size_.width=0;this.mouseDownWrapper_=Blockly.bindEventWithChecks_(this.getClickTarget_(),"mousedown",this,this.onMouseDown_)}};Blockly.Field.prototype.initModel=function(){};
Blockly.Field.prototype.dispose=function(){this.mouseDownWrapper_&&(Blockly.unbindEvent_(this.mouseDownWrapper_),this.mouseDownWrapper_=null);this.sourceBlock_=null;goog.dom.removeNode(this.fieldGroup_);this.validator_=this.textElement_=this.fieldGroup_=null};
Blockly.Field.prototype.updateEditable=function(){var a=this.fieldGroup_;this.EDITABLE&&a&&(this.sourceBlock_.isEditable()?(Blockly.utils.addClass(a,"blocklyEditableText"),Blockly.utils.removeClass(a,"blocklyNonEditableText"),this.fieldGroup_.style.cursor=this.CURSOR):(Blockly.utils.addClass(a,"blocklyNonEditableText"),Blockly.utils.removeClass(a,"blocklyEditableText"),this.fieldGroup_.style.cursor=""))};
Blockly.Field.prototype.isCurrentlyEditable=function(){return this.EDITABLE&&!!this.sourceBlock_&&this.sourceBlock_.isEditable()};Blockly.Field.prototype.isVisible=function(){return this.visible_};Blockly.Field.prototype.setVisible=function(a){if(this.visible_!=a){this.visible_=a;var b=this.getSvgRoot();b&&(b.style.display=a?"block":"none",this.render_())}};Blockly.Field.prototype.addArgType=function(a){null==this.argType_&&(this.argType_=[]);this.argType_.push(a)};
Blockly.Field.prototype.getArgTypes=function(){return null===this.argType_||0===this.argType_.length?null:this.argType_.join(" ")};Blockly.Field.prototype.setValidator=function(a){this.validator_=a};Blockly.Field.prototype.getValidator=function(){return this.validator_};Blockly.Field.prototype.classValidator=function(a){return a};
Blockly.Field.prototype.callValidator=function(a){var b=this.classValidator(a);if(null===b)return null;void 0!==b&&(a=b);if(b=this.getValidator()){b=b.call(this,a);if(null===b)return null;void 0!==b&&(a=b)}return a};Blockly.Field.prototype.getSvgRoot=function(){return this.fieldGroup_};
Blockly.Field.prototype.render_=function(){if(this.visible_&&this.textElement_){this.textElement_.textContent=this.getDisplayText_();this.updateWidth();var a=(this.size_.width-this.arrowWidth_)/2;this.sourceBlock_.RTL&&(a+=this.arrowWidth_);if(this.sourceBlock_.isShadow()&&!this.positionArrow){var b=Blockly.BlockSvg.FIELD_WIDTH/2;a=this.sourceBlock_.RTL?Math.min(this.size_.width-b,a):Math.max(b,a)}this.textElement_.setAttribute("x",a)}this.box_&&(this.box_.setAttribute("width",this.size_.width),this.box_.setAttribute("height",
this.size_.height))};Blockly.Field.prototype.updateWidth=function(){var a=Blockly.Field.getCachedWidth(this.textElement_);this.EDITABLE&&(a+=Blockly.BlockSvg.EDITABLE_FIELD_PADDING);this.arrowWidth_=0;this.positionArrow&&(this.arrowWidth_=this.positionArrow(a),a+=this.arrowWidth_);this.box_&&(a+=2*Blockly.BlockSvg.BOX_FIELD_PADDING);this.size_.width=a};
Blockly.Field.getCachedWidth=function(a){var b=a.textContent+"\n"+a.className.baseVal,c;if(Blockly.Field.cacheWidths_&&(c=Blockly.Field.cacheWidths_[b]))return c;try{c=goog.userAgent.IE||goog.userAgent.EDGE?a.getBBox().width:a.getComputedTextLength()}catch(d){return 8*a.textContent.length}Blockly.Field.cacheWidths_&&(Blockly.Field.cacheWidths_[b]=c);return c};Blockly.Field.startCache=function(){Blockly.Field.cacheReference_++;Blockly.Field.cacheWidths_||(Blockly.Field.cacheWidths_={})};
Blockly.Field.stopCache=function(){Blockly.Field.cacheReference_--;Blockly.Field.cacheReference_||(Blockly.Field.cacheWidths_=null)};Blockly.Field.prototype.getSize=function(){this.size_.width||this.render_();return this.size_};Blockly.Field.prototype.getScaledBBox_=function(){var a=this.getSize(),b=a.height*this.sourceBlock_.workspace.scale;a=a.width*this.sourceBlock_.workspace.scale;var c=this.getAbsoluteXY_();return{top:c.y,bottom:c.y+b,left:c.x,right:c.x+a}};
Blockly.Field.prototype.getDisplayText_=function(){var a=this.text_;if(!a)return Blockly.Field.NBSP;a.length>this.maxDisplayLength&&(a=a.substring(0,this.maxDisplayLength-2)+"\u2026");a=a.replace(/\s/g,Blockly.Field.NBSP);this.sourceBlock_.RTL&&(a=this.sourceBlock_.editable_&&"math_number"===this.sourceBlock_.type?"\u202a"+a+"\u202c":"\u202b"+a+"\u202c");return a};Blockly.Field.prototype.getText=function(){return this.text_};
Blockly.Field.prototype.setText=function(a){null!==a&&(a=String(a),a!==this.text_&&(this.text_=a,this.forceRerender()))};Blockly.Field.prototype.forceRerender=function(){this.size_.width=0;this.sourceBlock_&&this.sourceBlock_.rendered&&(this.sourceBlock_.render(),this.sourceBlock_.bumpNeighbours_());this.render_()};
Blockly.Field.prototype.updateTextNode_=function(){if(this.textElement_){var a=this.text_;a.length>this.maxDisplayLength?(a=a.substring(0,this.maxDisplayLength-2)+"\u2026",this.textElement_.setAttribute("class",this.className_+" blocklyTextTruncated")):this.textElement_.setAttribute("class",this.className_);goog.dom.removeChildren(this.textElement_);a=a.replace(/\s/g,Blockly.Field.NBSP);this.sourceBlock_.RTL&&a&&(a=this.sourceBlock_.editable_&&"math_number"===this.sourceBlock_.type?"\u202a"+a+"\u202c":
"\u202b"+a+"\u202c");a||(a=Blockly.Field.NBSP);a=document.createTextNode(a);this.textElement_.appendChild(a);this.size_.width=0}};Blockly.Field.prototype.getValue=function(){return this.getText()};Blockly.Field.prototype.setValue=function(a){if(null!==a){var b=this.getValue();b!=a&&(this.sourceBlock_&&Blockly.Events.isEnabled()&&Blockly.Events.fire(new Blockly.Events.BlockChange(this.sourceBlock_,"field",this.name,b,a)),this.setText(a))}};
Blockly.Field.prototype.onMouseDown_=function(a){this.sourceBlock_&&this.sourceBlock_.workspace&&this.sourceBlock_.isEnabled()&&((a=this.sourceBlock_.workspace.getGesture(a))&&a.setStartField(this),this.useTouchInteraction_="mouse"!==Blockly.Touch.getTouchIdentifierFromEvent(event))};Blockly.Field.prototype.setTooltip=function(a){};
Blockly.Field.prototype.updateColour=function(){this.box_&&(this.box_.setAttribute("stroke",this.sourceBlock_.getColourTertiary()),this.box_.setAttribute("fill",this.sourceBlock_.getColour()),this.box_.setAttribute("fill-opacity",this.sourceBlock_.getOpacity()));this.updateEditable()};Blockly.Field.prototype.getClickTarget_=function(){for(var a=0,b=0,c;c=this.sourceBlock_.inputList[b];b++)a+=c.fieldRow.length;return 1>=a&&this.sourceBlock_.outputConnection?this.sourceBlock_.getSvgRoot():this.getSvgRoot()};
Blockly.Field.prototype.getAbsoluteXY_=function(){return goog.style.getPageOffset(this.getClickTarget_())};Blockly.Field.prototype.referencesVariables=function(){return!1};Blockly.FieldLabel=function(a,b){this.size_=new goog.math.Size(0,0);this.class_=b;this.setValue(a)};goog.inherits(Blockly.FieldLabel,Blockly.Field);Blockly.FieldLabel.fromJson=function(a){var b=Blockly.utils.replaceMessageReferences(a.text);return new Blockly.FieldLabel(b,a["class"])};Blockly.FieldLabel.prototype.EDITABLE=!1;Blockly.FieldLabel.prototype.SERIALIZABLE=!1;
Blockly.FieldLabel.prototype.init=function(){this.textElement_||(this.textElement_=Blockly.utils.createSvgElement("text",{"class":"blocklyText",y:Blockly.BlockSvg.FIELD_TOP_PADDING,"text-anchor":"middle","dominant-baseline":"middle",dy:goog.userAgent.EDGE_OR_IE?Blockly.Field.IE_TEXT_OFFSET:"0"},null),this.class_&&Blockly.utils.addClass(this.textElement_,this.class_),this.visible_||(this.textElement_.style.display="none"),this.sourceBlock_.getSvgRoot().appendChild(this.textElement_),this.textElement_.tooltip=
this.sourceBlock_,Blockly.Tooltip.bindMouseEvents(this.textElement_),this.render_())};Blockly.FieldLabel.prototype.dispose=function(){goog.dom.removeNode(this.textElement_);this.textElement_=null};Blockly.FieldLabel.prototype.getSvgRoot=function(){return this.textElement_};Blockly.FieldLabel.prototype.setTooltip=function(a){this.textElement_.tooltip=a};Blockly.Field.register("field_label",Blockly.FieldLabel);Blockly.FieldLabelSerializable=function(a,b){Blockly.FieldLabelSerializable.superClass_.constructor.call(this,a,b);this.arrowWidth_=0};goog.inherits(Blockly.FieldLabelSerializable,Blockly.FieldLabel);Blockly.FieldLabelSerializable.fromJson=function(a){var b=Blockly.utils.replaceMessageReferences(a.text);return new Blockly.FieldLabelSerializable(b,a["class"])};Blockly.FieldLabelSerializable.prototype.EDITABLE=!1;Blockly.FieldLabelSerializable.prototype.SERIALIZABLE=!0;
Blockly.FieldLabelSerializable.prototype.updateWidth=function(){this.size_.width=Blockly.Field.getCachedWidth(this.textElement_)};
Blockly.FieldLabelSerializable.prototype.render_=function(){if(this.visible_&&this.textElement_){goog.dom.removeChildren(this.textElement_);var a=document.createTextNode(this.getDisplayText_());this.textElement_.appendChild(a);this.updateWidth();a=this.size_.width/2;var b=Blockly.BlockSvg.FIELD_WIDTH/2;a=this.sourceBlock_.RTL?Math.min(this.size_.width-b,a):Math.max(b,a);this.textElement_.setAttribute("x",a)}};Blockly.Field.register("field_label_serializable",Blockly.FieldLabelSerializable);Blockly.FieldVariableGetter=function(a,b,c){this.size_=new goog.math.Size(Blockly.BlockSvg.FIELD_WIDTH,Blockly.BlockSvg.FIELD_HEIGHT);this.text_=a;this.maxDisplayLength=Blockly.BlockSvg.MAX_DISPLAY_LENGTH;this.name_=b;this.variableType_=c?c:""};goog.inherits(Blockly.FieldVariableGetter,Blockly.Field);Blockly.FieldVariableGetter.fromJson=function(a){var b=Blockly.utils.replaceMessageReferences(a.text);return new Blockly.FieldVariableGetter(b,a.name,a["class"],a.variableType)};
Blockly.FieldVariableGetter.prototype.EDITABLE=!1;Blockly.FieldVariableGetter.prototype.SERIALIZABLE=!0;Blockly.FieldVariableGetter.prototype.init=function(){if(!this.fieldGroup_&&(Blockly.FieldVariableGetter.superClass_.init.call(this),!this.variable_)){this.workspace_=this.sourceBlock_.workspace;var a=Blockly.Variables.getOrCreateVariablePackage(this.workspace_,null,this.text_,this.variableType_);this.setValue(a.getId())}};
Blockly.FieldVariableGetter.prototype.getValue=function(){return this.variable_?this.variable_.getId():""};Blockly.FieldVariableGetter.prototype.getText=function(){return this.variable_?this.variable_.name:""};Blockly.FieldVariableGetter.prototype.getVariable=function(){return this.variable_};
Blockly.FieldVariableGetter.prototype.setValue=function(a){var b=Blockly.Variables.getVariable(this.sourceBlock_.workspace,a);if(!b)throw Error("Variable id doesn't point to a real variable!  ID was "+a);if(this.sourceBlock_&&Blockly.Events.isEnabled()){var c=this.variable_?this.variable_.getId():null;Blockly.Events.fire(new Blockly.Events.BlockChange(this.sourceBlock_,"field",this.name,c,b.getId()))}this.variable_=b;this.value_=a;this.setText(b.name)};
Blockly.FieldVariableGetter.prototype.showEditor_=function(){};Blockly.FieldVariableGetter.prototype.updateEditable=function(){};Blockly.FieldVariableGetter.prototype.referencesVariables=function(){return!0};Blockly.Field.register("field_variable_getter",Blockly.FieldVariableGetter);Blockly.Input=function(a,b,c,d){if(a!=Blockly.DUMMY_INPUT&&!b)throw"Value inputs and statement inputs must have non-empty name.";this.type=a;this.name=b;this.sourceBlock_=c;this.connection=d;this.fieldRow=[];this.outlinePath=null};Blockly.Input.prototype.align=Blockly.ALIGN_LEFT;Blockly.Input.prototype.visible_=!0;Blockly.Input.prototype.appendField=function(a,b){this.insertFieldAt(this.fieldRow.length,a,b);return this};
Blockly.Input.prototype.insertFieldAt=function(a,b,c){if(0>a||a>this.fieldRow.length)throw Error("index "+a+" out of bounds.");if(!b&&!c)return this;goog.isString(b)&&(b=new Blockly.FieldLabel(b));b.setSourceBlock(this.sourceBlock_);this.sourceBlock_.rendered&&b.init();b.name=c;b.prefixField&&(a=this.insertFieldAt(a,b.prefixField));this.fieldRow.splice(a,0,b);++a;b.suffixField&&(a=this.insertFieldAt(a,b.suffixField));this.sourceBlock_.rendered&&(this.sourceBlock_.render(),this.sourceBlock_.bumpNeighbours_());
return a};Blockly.Input.prototype.removeField=function(a){for(var b=0,c;c=this.fieldRow[b];b++)if(c.name===a){c.dispose();this.fieldRow.splice(b,1);this.sourceBlock_.rendered&&(this.sourceBlock_.render(),this.sourceBlock_.bumpNeighbours_());return}goog.asserts.fail('Field "%s" not found.',a)};Blockly.Input.prototype.isVisible=function(){return this.visible_};
Blockly.Input.prototype.setVisible=function(a){var b=[];if(this.visible_==a)return b;for(var c=(this.visible_=a)?"block":"none",d=0,e;e=this.fieldRow[d];d++)e.setVisible(a);this.connection&&(a?b=this.connection.unhideAll():this.connection.hideAll(),d=this.connection.targetBlock())&&(d.getSvgRoot().style.display=c,a||(d.rendered=!1));return b};Blockly.Input.prototype.setCheck=function(a){if(!this.connection)throw"This input does not have a connection.";this.connection.setCheck(a);return this};
Blockly.Input.prototype.setAlign=function(a){this.align=a;this.sourceBlock_.rendered&&this.sourceBlock_.render();return this};Blockly.Input.prototype.init=function(){if(this.sourceBlock_.workspace.rendered)for(var a=0;a<this.fieldRow.length;a++)this.fieldRow[a].init(this.sourceBlock_)};Blockly.Input.prototype.dispose=function(){this.outlinePath&&goog.dom.removeNode(this.outlinePath);for(var a=0,b;b=this.fieldRow[a];a++)b.dispose();this.connection&&this.connection.dispose();this.sourceBlock_=null};
Blockly.Input.prototype.initOutlinePath=function(a){this.sourceBlock_.workspace.rendered&&!this.outlinePath&&this.type==Blockly.INPUT_VALUE&&(this.outlinePath=Blockly.utils.createSvgElement("path",{"class":"blocklyPath",style:"visibility: hidden",d:""},a))};Blockly.Warning=function(a){Blockly.Warning.superClass_.constructor.call(this,a);this.createIcon();this.text_={}};goog.inherits(Blockly.Warning,Blockly.Icon);Blockly.Warning.prototype.collapseHidden=!1;
Blockly.Warning.prototype.drawIcon_=function(a){Blockly.utils.createSvgElement("path",{"class":"blocklyIconShape",d:"M2,15Q-1,15 0.5,12L6.5,1.7Q8,-1 9.5,1.7L15.5,12Q17,15 14,15z"},a);Blockly.utils.createSvgElement("path",{"class":"blocklyIconSymbol",d:"m7,4.8v3.16l0.27,2.27h1.46l0.27,-2.27v-3.16z"},a);Blockly.utils.createSvgElement("rect",{"class":"blocklyIconSymbol",x:"7",y:"11",height:"2",width:"2"},a)};
Blockly.Warning.textToDom_=function(a){var b=Blockly.utils.createSvgElement("text",{"class":"blocklyText blocklyBubbleText",y:Blockly.Bubble.BORDER_WIDTH},null);a=a.split("\n");for(var c=0;c<a.length;c++){var d=Blockly.utils.createSvgElement("tspan",{dy:"1em",x:Blockly.Bubble.BORDER_WIDTH},b),e=document.createTextNode(a[c]);d.appendChild(e)}return b};
Blockly.Warning.prototype.setVisible=function(a){if(a!=this.isVisible())if(Blockly.Events.fire(new Blockly.Events.Ui(this.block_,"warningOpen",!a,a)),a){a=Blockly.Warning.textToDom_(this.getText());this.bubble_=new Blockly.Bubble(this.block_.workspace,a,this.block_.svgPath_,this.iconXY_,null,null);if(this.block_.RTL)for(var b=a.getBBox().width,c=0,d;d=a.childNodes[c];c++)d.setAttribute("text-anchor","end"),d.setAttribute("x",b+Blockly.Bubble.BORDER_WIDTH);this.updateColour();a=this.bubble_.getBubbleSize();
this.bubble_.setBubbleSize(a.width,a.height)}else this.bubble_.dispose(),this.body_=this.bubble_=null};Blockly.Warning.prototype.bodyFocus_=function(a){this.bubble_.promote_()};Blockly.Warning.prototype.setText=function(a,b){this.text_[b]!=a&&(a?this.text_[b]=a:delete this.text_[b],this.isVisible()&&(this.setVisible(!1),this.setVisible(!0)))};Blockly.Warning.prototype.getText=function(){var a=[],b;for(b in this.text_)a.push(this.text_[b]);return a.join("\n")};
Blockly.Warning.prototype.dispose=function(){this.block_.warning=null;Blockly.Icon.prototype.dispose.call(this)};Blockly.Block=function(a,b,c){var d=a&&a.getFlyout&&a.getFlyout()?a.getFlyout().getWorkspace():null;this.id=!c||a.getBlockById(c)||d&&d.getBlockById(c)?Blockly.utils.genUid():c;a.blockDB_[this.id]=this;this.previousConnection=this.nextConnection=this.outputConnection=null;this.inputList=[];this.inputsInline=!0;this.disabled=!1;this.tooltip="";this.contextMenu=!0;this.parentBlock_=null;this.childBlocks_=[];this.editable_=this.movable_=this.deletable_=!0;this.checkboxInFlyout_=this.collapsed_=this.isShadow_=
!1;this.category_=this.outputShape_=this.comment=null;this.xy_=new goog.math.Coordinate(0,0);this.workspace=a;this.isInFlyout=a.isFlyout;this.isInMutator=a.isMutator;this.RTL=a.RTL;this.isInsertionMarker_=!1;b&&(this.type=b,c=Blockly.Blocks[b],goog.asserts.assertObject(c,'Error: Unknown block type "%s".',b),goog.mixin(this,c));a.addTopBlock(this);goog.isFunction(this.init)&&this.init();this.inputsInlineDefault=this.inputsInline;if(Blockly.Events.isEnabled()){(a=Blockly.Events.getGroup())||Blockly.Events.setGroup(!0);
try{Blockly.Events.fire(new Blockly.Events.BlockCreate(this))}finally{a||Blockly.Events.setGroup(!1)}}goog.isFunction(this.onchange)&&this.setOnChange(this.onchange)};Blockly.Block.prototype.data=null;Blockly.Block.prototype.colour_="#FF0000";Blockly.Block.customMatrix_=[];Blockly.Block.prototype.colourSecondary_="#FF0000";Blockly.Block.prototype.colourTertiary_="#FF0000";Blockly.Block.prototype.shadowColour_=null;
Blockly.Block.prototype.dispose=function(a){if(this.workspace){this.onchangeWrapper_&&this.workspace.removeChangeListener(this.onchangeWrapper_);this.unplug(a);Blockly.Events.isEnabled()&&Blockly.Events.fire(new Blockly.Events.BlockDelete(this));Blockly.Events.disable();try{this.workspace&&(this.workspace.removeTopBlock(this),delete this.workspace.blockDB_[this.id],this.workspace=null);Blockly.selected==this&&(Blockly.selected=null);for(var b=this.childBlocks_.length-1;0<=b;b--)this.childBlocks_[b].dispose(!1);
b=0;for(var c;c=this.inputList[b];b++)c.dispose();this.inputList.length=0;var d=this.getConnections_(!0);for(b=0;b<d.length;b++){var e=d[b];e.isConnected()&&e.disconnect();d[b].dispose()}}finally{Blockly.Events.enable()}}};Blockly.Block.prototype.initModel=function(){for(var a=0,b;b=this.inputList[a];a++)for(var c=0,d;d=b.fieldRow[c];c++)d.initModel&&d.initModel()};
Blockly.Block.prototype.unplug=function(a){if(this.outputConnection)this.outputConnection.isConnected()&&this.outputConnection.disconnect();else{if(this.previousConnection){var b=null;this.previousConnection.isConnected()&&(b=this.previousConnection.targetConnection,this.previousConnection.disconnect())}var c=this.getNextBlock();a&&c&&(a=this.nextConnection.targetConnection,a.disconnect(),b&&b.checkType_(a)&&b.connect(a))}};
Blockly.Block.prototype.getConnections_=function(){var a=[];this.outputConnection&&a.push(this.outputConnection);this.previousConnection&&a.push(this.previousConnection);this.nextConnection&&a.push(this.nextConnection);for(var b=0,c;c=this.inputList[b];b++)c.connection&&a.push(c.connection);return a};Blockly.Block.prototype.lastConnectionInStack=function(){for(var a=this.nextConnection;a;){var b=a.targetBlock();if(!b)return a;a=b.nextConnection}return null};
Blockly.Block.prototype.bumpNeighbours_=function(){console.warn("Not expected to reach this bumpNeighbours_ function. The BlockSvg function for bumpNeighbours_ was expected to be called instead.")};Blockly.Block.prototype.getParent=function(){return this.parentBlock_};Blockly.Block.prototype.getInputWithBlock=function(a){for(var b=0,c;c=this.inputList[b];b++)if(c.connection&&c.connection.targetBlock()==a)return c;return null};
Blockly.Block.prototype.getInputWithConnection=function(a){for(var b=0,c;c=this.inputList[b];b++)if(c.connection==a)return c;return null};Blockly.Block.prototype.getSurroundParent=function(){var a=this;do{var b=a;a=a.getParent();if(!a)return null}while(a.getNextBlock()==b);return a};Blockly.Block.prototype.getNextBlock=function(){return this.nextConnection&&this.nextConnection.targetBlock()};Blockly.Block.prototype.getPreviousBlock=function(){return this.previousConnection&&this.previousConnection.targetBlock()};
Blockly.Block.prototype.getFirstStatementConnection=function(){for(var a=0,b;b=this.inputList[a];a++)if(b.connection&&b.connection.type==Blockly.NEXT_STATEMENT)return b.connection;return null};Blockly.Block.prototype.getRootBlock=function(){var a=this;do{var b=a;a=b.parentBlock_}while(a);return b};Blockly.Block.prototype.getTopStackBlock=function(){var a=this;do var b=a.getPreviousBlock();while(b&&b.getNextBlock()==a&&(a=b));return a};
Blockly.Block.prototype.getChildren=function(a){if(!a)return this.childBlocks_;a=[];for(var b=0,c;c=this.inputList[b];b++)c.connection&&(c=c.connection.targetBlock())&&a.push(c);(b=this.getNextBlock())&&a.push(b);return a};
Blockly.Block.prototype.setParent=function(a){if(a!=this.parentBlock_){if(this.parentBlock_){goog.array.remove(this.parentBlock_.childBlocks_,this);if(this.previousConnection&&this.previousConnection.isConnected())throw"Still connected to previous block.";if(this.outputConnection&&this.outputConnection.isConnected())throw"Still connected to parent block.";this.parentBlock_=null}else this.workspace.removeTopBlock(this);(this.parentBlock_=a)?a.childBlocks_.push(this):this.workspace.addTopBlock(this)}};
Blockly.Block.prototype.getDescendants=function(a,b){for(var c=[this],d=this.getChildren(a),e,f=0;e=d[f];f++)b&&e.isShadow_||c.push.apply(c,e.getDescendants(a,b));return c};Blockly.Block.prototype.isDeletable=function(){return this.deletable_&&!this.isShadow_&&!(this.workspace&&this.workspace.options.readOnly)};Blockly.Block.prototype.setDeletable=function(a){this.deletable_=a};Blockly.Block.prototype.isMovable=function(){return this.movable_&&!this.isShadow_&&!(this.workspace&&this.workspace.options.readOnly)};
Blockly.Block.prototype.setMovable=function(a){this.movable_=a};Blockly.Block.prototype.isShadow=function(){return this.isShadow_};Blockly.Block.prototype.setShadow=function(a){this.isShadow_=a};Blockly.Block.prototype.isInsertionMarker=function(){return this.isInsertionMarker_};
Blockly.Block.prototype.setInsertionMarker=function(a){this.isInsertionMarker_!=a&&(this.isInsertionMarker_=a)&&(this.setColour(Blockly.Colours.insertionMarker),this.setOpacity(Blockly.Colours.insertionMarkerOpacity),Blockly.utils.addClass(this.svgGroup_,"blocklyInsertionMarker"))};Blockly.Block.prototype.isEditable=function(){return this.getSurroundParent()?this.getSurroundParent().isEnabled():this.editable_&&!(this.workspace&&this.workspace.options.readOnly)};
Blockly.Block.prototype.setEditable=function(a){this.editable_=a;a=0;for(var b;b=this.inputList[a];a++)for(var c=0,d;d=b.fieldRow[c];c++)d.updateEditable()};
Blockly.Block.prototype.setConnectionsHidden=function(a){if(!a&&this.isCollapsed()){if(this.outputConnection&&this.outputConnection.setHidden(a),this.previousConnection&&this.previousConnection.setHidden(a),this.nextConnection){this.nextConnection.setHidden(a);var b=this.nextConnection.targetBlock();b&&b.setConnectionsHidden(a)}}else for(var c=this.getConnections_(!0),d=0;b=c[d];d++)b.setHidden(a),b.isSuperior()&&(b=b.targetBlock())&&b.setConnectionsHidden(a)};
Blockly.Block.prototype.getMatchingConnection=function(a,b){var c=this.getConnections_(!0);a=a.getConnections_(!0);if(c.length!=a.length)throw"Connection lists did not match in length.";for(var d=0;d<a.length;d++)if(a[d]==b)return c[d];return null};Blockly.Block.prototype.setHelpUrl=function(a){this.helpUrl=a};Blockly.Block.prototype.setTooltip=function(a){this.tooltip=a};
Blockly.Block.prototype.getColour=function(){return this.isEnabled()?this.colour_:"url(#"+this.workspace.options.disabledPatternId+")"};Blockly.Block.prototype.getColourSecondary=function(){return this.isEnabled()?this.colourSecondary_:"url(#"+this.workspace.options.disabledPatternId+")"};Blockly.Block.prototype.getColourTertiary=function(){return this.isEnabled()?this.colourTertiary_:"url(#"+this.workspace.options.disabledPatternId+")"};Blockly.Block.prototype.getShadowColour=function(){return this.shadowColour_};
Blockly.Block.prototype.setShadowColour=function(a){this.shadowColour_=this.makeColour_(a);this.rendered&&this.updateColour()};Blockly.Block.prototype.clearShadowColour=function(){this.shadowColour_=null;this.rendered&&this.updateColour()};Blockly.Block.prototype.makeColour_=function(a){var b=Number(a);if(isNaN(b)){if(goog.isString(a))return a;throw"Invalid colour: "+a;}return Blockly.hueToRgb(b)};
Blockly.Block.prototype.setColour=function(a,b,c){this.colour_=this.makeColour_(a);this.colourSecondary_=void 0!==b?this.makeColour_(b):goog.color.rgbArrayToHex(goog.color.darken(goog.color.hexToRgb(this.colour_),.1));this.colourTertiary_=void 0!==c?this.makeColour_(c):goog.color.rgbArrayToHex(goog.color.darken(goog.color.hexToRgb(this.colour_),.2));this.rendered&&this.updateColour()};
Blockly.Block.prototype.setOnChange=function(a){if(a&&!goog.isFunction(a))throw Error("onchange must be a function.");this.onchangeWrapper_&&this.workspace.removeChangeListener(this.onchangeWrapper_);if(this.onchange=a)this.onchangeWrapper_=a.bind(this),this.workspace.addChangeListener(this.onchangeWrapper_)};Blockly.Block.prototype.getField=function(a){for(var b=0,c;c=this.inputList[b];b++)for(var d=0,e;e=c.fieldRow[d];d++)if(e.name===a)return e;return null};
Blockly.Block.prototype.getVars=function(){for(var a=[],b=0,c;c=this.inputList[b];b++)for(var d=0,e;e=c.fieldRow[d];d++)e.referencesVariables()&&a.push(e.getValue());return a};Blockly.Block.prototype.getVarModels=function(){for(var a=[],b=0,c;c=this.inputList[b];b++)for(var d=0,e;e=c.fieldRow[d];d++)e.referencesVariables()&&(e=this.workspace.getVariableById(e.getValue()))&&a.push(e);return a};
Blockly.Block.prototype.updateVarName=function(a){for(var b=0,c;c=this.inputList[b];b++)for(var d=0,e;e=c.fieldRow[d];d++)e.referencesVariables()&&a.getId()==e.getValue()&&e.setText(a.name)};Blockly.Block.prototype.renameVarById=function(a,b){for(var c=0,d;d=this.inputList[c];c++)for(var e=0,f;f=d.fieldRow[e];e++)f.referencesVariables()&&a==f.getValue()&&f.setValue(b)};Blockly.Block.prototype.getFieldValue=function(a){return(a=this.getField(a))?a.getValue():null};
Blockly.Block.prototype.setFieldValue=function(a,b){var c=this.getField(b);goog.asserts.assertObject(c,'Field "%s" not found.',b);c.setValue(a)};
Blockly.Block.prototype.setPreviousStatement=function(a,b){a?(void 0===b&&(b=null),this.previousConnection||(goog.asserts.assert(!this.outputConnection,"Remove output connection prior to adding previous connection."),this.previousConnection=this.makeConnection_(Blockly.PREVIOUS_STATEMENT)),this.previousConnection.setCheck(b)):this.previousConnection&&(goog.asserts.assert(!this.previousConnection.isConnected(),"Must disconnect previous statement before removing connection."),this.previousConnection.dispose(),
this.previousConnection=null)};Blockly.Block.prototype.setNextStatement=function(a,b){a?(void 0===b&&(b=null),this.nextConnection||(this.nextConnection=this.makeConnection_(Blockly.NEXT_STATEMENT)),this.nextConnection.setCheck(b)):this.nextConnection&&(goog.asserts.assert(!this.nextConnection.isConnected(),"Must disconnect next statement before removing connection."),this.nextConnection.dispose(),this.nextConnection=null)};
Blockly.Block.prototype.setOutput=function(a,b){a?(void 0===b&&(b=null),this.outputConnection||(goog.asserts.assert(!this.previousConnection,"Remove previous connection prior to adding output connection."),this.outputConnection=this.makeConnection_(Blockly.OUTPUT_VALUE)),this.outputConnection.setCheck(b)):this.outputConnection&&(goog.asserts.assert(!this.outputConnection.isConnected(),"Must disconnect output value before removing connection."),this.outputConnection.dispose(),this.outputConnection=
null)};Blockly.Block.prototype.setInputsInline=function(a){this.inputsInline!=a&&(Blockly.Events.fire(new Blockly.Events.BlockChange(this,"inline",null,this.inputsInline,a)),this.inputsInline=a)};
Blockly.Block.prototype.getInputsInline=function(){if(void 0!=this.inputsInline)return this.inputsInline;for(var a=1;a<this.inputList.length;a++)if(this.inputList[a-1].type==Blockly.DUMMY_INPUT&&this.inputList[a].type==Blockly.DUMMY_INPUT)return!1;for(a=1;a<this.inputList.length;a++)if(this.inputList[a-1].type==Blockly.INPUT_VALUE&&this.inputList[a].type==Blockly.DUMMY_INPUT)return!0;return!1};Blockly.Block.prototype.setDisabled=function(a){this.setEnabled(!a)};
Blockly.Block.prototype.isEnabled=function(){return this.getSurroundParent()&&!this.getPreviousBlock()?this.getSurroundParent().isEnabled()?!this.disabled:!1:!this.disabled};
Blockly.Block.prototype.setEnabled=function(a){this.setEditable(a);if(this.isEnabled()!=a){Blockly.Events.fire(new Blockly.Events.BlockChange(this,"disabled",null,this.disabled,!a));this.disabled=!a;for(var b=0,c;c=this.inputList[b];b++)c.name&&c.type===Blockly.INPUT_VALUE&&(c=this.getInputTargetBlock(c.name),c.setEnabled(a),c.updateColour());this.rendered&&this.updateColour()}};
Blockly.Block.prototype.getInheritedDisabled=function(){for(var a=this.getSurroundParent();a;){if(a.disabled)return!0;a=a.getSurroundParent()}return!1};Blockly.Block.prototype.isCollapsed=function(){return this.collapsed_};Blockly.Block.prototype.setCollapsed=function(a){this.collapsed_!=a&&(Blockly.Events.fire(new Blockly.Events.BlockChange(this,"collapsed",null,this.collapsed_,a)),this.collapsed_=a)};
Blockly.Block.prototype.toString=function(a,b){var c=[],d=b||"?";if(this.collapsed_)c.push(this.getInput("_TEMP_COLLAPSED_INPUT").fieldRow[0].text_);else for(var e=0,f;f=this.inputList[e];e++){for(var g=0,h;h=f.fieldRow[g];g++)h instanceof Blockly.FieldDropdown&&!h.getValue()?c.push(d):c.push(h.getText());f.connection&&((f=f.connection.targetBlock())?c.push(f.toString(void 0,b)):c.push(d))}c=goog.string.trim(c.join(" "))||"???";a&&(c=goog.string.truncate(c,a));return c};
Blockly.Block.prototype.appendValueInput=function(a){return this.appendInput_(Blockly.INPUT_VALUE,a)};Blockly.Block.prototype.appendStatementInput=function(a){return this.appendInput_(Blockly.NEXT_STATEMENT,a)};Blockly.Block.prototype.appendDummyInput=function(a){return this.appendInput_(Blockly.DUMMY_INPUT,a||"")};
Blockly.Block.prototype.jsonInit=function(a){goog.asserts.assert(void 0==a.output||void 0==a.previousStatement,(a.type?'Block "'+a.type+'": ':"")+"Must not have both an output and a previousStatement.");void 0!==a.colour&&this.setColourFromJson_(a);for(var b=0;void 0!==a["message"+b];)this.interpolate_(a["message"+b],a["args"+b]||[],a["lastDummyAlign"+b]),b++;void 0!==a.inputsInline&&this.setInputsInline(a.inputsInline);void 0!==a.output&&this.setOutput(!0,a.output);void 0!==a.previousStatement&&
this.setPreviousStatement(!0,a.previousStatement);void 0!==a.nextStatement&&this.setNextStatement(!0,a.nextStatement);void 0!==a.tooltip&&(b=a.tooltip,b=Blockly.utils.replaceMessageReferences(b),this.setTooltip(b));void 0!==a.enableContextMenu&&(b=a.enableContextMenu,this.contextMenu=!!b);void 0!==a.helpUrl&&(b=a.helpUrl,b=Blockly.utils.replaceMessageReferences(b),this.setHelpUrl(b));goog.isString(a.extensions)&&(console.warn("JSON attribute 'extensions' should be an array of strings. Found raw string in JSON for '"+
a.type+"' block."),a.extensions=[a.extensions]);void 0!==a.mutator&&Blockly.Extensions.apply(a.mutator,this,!0);if(Array.isArray(a.extensions)){var c=a.extensions;for(b=0;b<c.length;++b)Blockly.Extensions.apply(c[b],this,!1)}void 0!==a.outputShape&&this.setOutputShape(a.outputShape);void 0!==a.checkboxInFlyout&&this.setCheckboxInFlyout(a.checkboxInFlyout);void 0!==a.category&&this.setCategory(a.category)};
Blockly.Block.prototype.mixin=function(a,b){if(goog.isDef(b)&&!goog.isBoolean(b))throw Error("opt_disableCheck must be a boolean if provided");if(!b){b=[];for(var c in a)void 0!==this[c]&&b.push(c);if(b.length)throw Error("Mixin will overwrite block members: "+JSON.stringify(b));}goog.mixin(this,a)};
Blockly.Block.prototype.setColourFromRawValues_=function(a,b,c){a=goog.isString(a)?Blockly.utils.replaceMessageReferences(a):a;b=goog.isString(b)?Blockly.utils.replaceMessageReferences(b):b;c=goog.isString(c)?Blockly.utils.replaceMessageReferences(c):c;this.setColour(a,b,c)};Blockly.Block.prototype.setColourFromJson_=function(a){this.setColourFromRawValues_(a.colour,a.colourSecondary,a.colourTertiary)};
Blockly.Block.prototype.interpolate_=function(a,b,c){var d=Blockly.utils.tokenizeInterpolation(a),e=[],f=0;a=[];for(var g=0;g<d.length;g++){var h=d[g];if("number"==typeof h){if(0>=h||h>b.length)throw Error('Block "'+this.type+'": Message index %'+h+" out of range.");if(e[h])throw Error('Block "'+this.type+'": Message index %'+h+" duplicated.");e[h]=!0;f++;a.push(b[h-1])}else(h=h.trim())&&a.push(h)}if(f!=b.length)throw Error('Block "'+this.type+'": Message does not reference all '+b.length+" arg(s).");
a.length&&("string"==typeof a[a.length-1]||goog.string.startsWith(a[a.length-1].type,"field_"))&&(g={type:"input_dummy"},c&&(g.align=c),a.push(g));c={LEFT:Blockly.ALIGN_LEFT,RIGHT:Blockly.ALIGN_RIGHT,CENTRE:Blockly.ALIGN_CENTRE};b=[];for(g=0;g<a.length;g++)if(e=a[g],"string"==typeof e)b.push([e,void 0]);else{d=f=null;do if(h=!1,"string"==typeof e)f=new Blockly.FieldLabel(e);else switch(e.type){case "input_value":d=this.appendValueInput(e.name);break;case "input_statement":d=this.appendStatementInput(e.name);
break;case "input_dummy":d=this.appendDummyInput(e.name);break;default:f=Blockly.Field.fromJson(e),f||(e.alt?(e=e.alt,h=!0):console.warn("Blockly could not create a field of type "+e.type+". You may need to register your custom field.  See github.com/google/blockly/issues/1584"))}while(h);if(f)b.push([f,e.name]);else if(d){e.check&&d.setCheck(e.check);e.align&&d.setAlign(c[e.align]);for(e=0;e<b.length;e++)d.appendField(b[e][0],b[e][1]);b.length=0}}};
Blockly.Block.prototype.appendInput_=function(a,b){var c=null;if(a==Blockly.INPUT_VALUE||a==Blockly.NEXT_STATEMENT)c=this.makeConnection_(a);a=new Blockly.Input(a,b,this,c);this.inputList.push(a);return a};
Blockly.Block.prototype.moveInputBefore=function(a,b){if(a!=b){for(var c=-1,d=b?-1:this.inputList.length,e=0,f;f=this.inputList[e];e++)if(f.name==a){if(c=e,-1!=d)break}else if(b&&f.name==b&&(d=e,-1!=c))break;goog.asserts.assert(-1!=c,'Named input "%s" not found.',a);goog.asserts.assert(-1!=d,'Reference input "%s" not found.',b);this.moveNumberedInputBefore(c,d)}};
Blockly.Block.prototype.moveNumberedInputBefore=function(a,b){goog.asserts.assert(a!=b,"Can't move input to itself.");goog.asserts.assert(a<this.inputList.length,"Input index "+a+" out of bounds.");goog.asserts.assert(b<=this.inputList.length,"Reference input "+b+" out of bounds.");var c=this.inputList[a];this.inputList.splice(a,1);a<b&&b--;this.inputList.splice(b,0,c)};
Blockly.Block.prototype.removeInput=function(a,b){for(var c=0,d;d=this.inputList[c];c++)if(d.name==a){d.connection&&d.connection.isConnected()&&(d.connection.setShadowDom(null),a=d.connection.targetBlock(),a.isShadow()?a.dispose():a.unplug());d.dispose();this.inputList.splice(c,1);return}b||goog.asserts.fail('Input "%s" not found.',a)};Blockly.Block.prototype.getInput=function(a){for(var b=0,c;c=this.inputList[b];b++)if(c.name==a)return c;return null};
Blockly.Block.prototype.getInputTargetBlock=function(a){return(a=this.getInput(a))&&a.connection&&a.connection.targetBlock()};Blockly.Block.prototype.getCommentText=function(){return this.comment||""};Blockly.Block.prototype.setCommentText=function(a){this.comment!=a&&(Blockly.Events.fire(new Blockly.Events.BlockChange(this,"comment",null,this.comment,a||"")),this.comment=a)};Blockly.Block.prototype.setOutputShape=function(a){this.outputShape_=a};Blockly.Block.prototype.getOutputShape=function(){return this.outputShape_};
Blockly.Block.prototype.setCategory=function(a){this.category_=a};Blockly.Block.prototype.getCategory=function(){return this.category_};Blockly.Block.prototype.setCheckboxInFlyout=function(a){this.checkboxInFlyout_=a};Blockly.Block.prototype.hasCheckboxInFlyout=function(){return this.checkboxInFlyout_};Blockly.Block.prototype.setWarningText=function(){};Blockly.Block.prototype.setMutator=function(){};Blockly.Block.prototype.getRelativeToSurfaceXY=function(){return this.xy_};
Blockly.Block.prototype.moveBy=function(a,b){goog.asserts.assert(!this.parentBlock_,"Block has parent.");var c=new Blockly.Events.BlockMove(this);this.xy_.translate(a,b);c.recordNew();Blockly.Events.fire(c)};Blockly.Block.prototype.makeConnection_=function(a){return new Blockly.Connection(this,a)};
Blockly.Block.prototype.allInputsFilled=function(a){void 0===a&&(a=!0);if(!a&&this.isShadow())return!1;for(var b=0,c;c=this.inputList[b];b++)if(c.connection&&(c=c.connection.targetBlock(),!c||!c.allInputsFilled(a)))return!1;return(b=this.getNextBlock())?b.allInputsFilled(a):!0};Blockly.Block.prototype.toDevString=function(){var a=this.type?'"'+this.type+'" block':"Block";this.id&&(a+=' (id="'+this.id+'")');return a};Blockly.Block.custommatrixpush=function(a){Blockly.Block.customMatrix_.pop();Blockly.Block.customMatrix_.push(a)};
Blockly.Block.custommatrixget=function(){return Blockly.Block.customMatrix_};Blockly.BlockDragSurfaceSvg=function(a){this.container_=a;this.createDom()};Blockly.BlockDragSurfaceSvg.prototype.SVG_=null;Blockly.BlockDragSurfaceSvg.prototype.dragGroup_=null;Blockly.BlockDragSurfaceSvg.prototype.container_=null;Blockly.BlockDragSurfaceSvg.prototype.scale_=1;Blockly.BlockDragSurfaceSvg.prototype.surfaceXY_=null;Blockly.BlockDragSurfaceSvg.prototype.dragShadowFilterId_="";Blockly.BlockDragSurfaceSvg.SHADOW_STD_DEVIATION=6;
Blockly.BlockDragSurfaceSvg.prototype.createDom=function(){if(!this.SVG_){this.SVG_=Blockly.utils.createSvgElement("svg",{xmlns:Blockly.SVG_NS,"xmlns:html":Blockly.HTML_NS,"xmlns:xlink":"http://www.w3.org/1999/xlink",version:"1.1","class":"blocklyBlockDragSurface"},this.container_);this.dragGroup_=Blockly.utils.createSvgElement("g",{},this.SVG_);var a=Blockly.utils.createSvgElement("defs",{},this.SVG_);this.dragShadowFilterId_=this.createDropShadowDom_(a);this.dragGroup_.setAttribute("filter","url(#"+
this.dragShadowFilterId_+")")}};
Blockly.BlockDragSurfaceSvg.prototype.createDropShadowDom_=function(a){var b=String(Math.random()).substring(2);a=Blockly.utils.createSvgElement("filter",{id:"blocklyDragShadowFilter"+b,height:"140%",width:"140%",y:"-20%",x:"-20%"},a);Blockly.utils.createSvgElement("feGaussianBlur",{"in":"SourceAlpha",stdDeviation:Blockly.BlockDragSurfaceSvg.SHADOW_STD_DEVIATION},a);b=Blockly.utils.createSvgElement("feComponentTransfer",{result:"offsetBlur"},a);Blockly.utils.createSvgElement("feFuncA",{type:"linear",
slope:Blockly.Colours.dragShadowOpacity},b);Blockly.utils.createSvgElement("feComposite",{"in":"SourceGraphic",in2:"offsetBlur",operator:"over"},a);return a.id};Blockly.BlockDragSurfaceSvg.prototype.setBlocksAndShow=function(a){goog.asserts.assert(0==this.dragGroup_.childNodes.length,"Already dragging a block.");this.dragGroup_.appendChild(a);this.SVG_.style.display="block";this.surfaceXY_=new goog.math.Coordinate(0,0);document.getElementsByClassName("injectionDiv")[0].style.overflow="visible"};
Blockly.BlockDragSurfaceSvg.prototype.translateAndScaleGroup=function(a,b,c){this.scale_=c;a=a.toFixed(0);b=b.toFixed(0);this.dragGroup_.setAttribute("transform","translate("+a+","+b+") scale("+c+")")};Blockly.BlockDragSurfaceSvg.prototype.translateSurfaceInternal_=function(){var a=this.surfaceXY_.x,b=this.surfaceXY_.y;a=a.toFixed(0);b=b.toFixed(0);this.SVG_.style.display="block";Blockly.utils.setCssTransform(this.SVG_,"translate3d("+a+"px, "+b+"px, 0px)")};
Blockly.BlockDragSurfaceSvg.prototype.translateSurface=function(a,b){this.surfaceXY_=new goog.math.Coordinate(a*this.scale_,b*this.scale_);this.translateSurfaceInternal_()};Blockly.BlockDragSurfaceSvg.prototype.getSurfaceTranslation=function(){var a=Blockly.utils.getRelativeXY(this.SVG_);return new goog.math.Coordinate(a.x/this.scale_,a.y/this.scale_)};Blockly.BlockDragSurfaceSvg.prototype.getGroup=function(){return this.dragGroup_};Blockly.BlockDragSurfaceSvg.prototype.getCurrentBlock=function(){return this.dragGroup_.firstChild};
Blockly.BlockDragSurfaceSvg.prototype.clearAndHide=function(a){a?a.appendChild(this.getCurrentBlock()):this.dragGroup_.removeChild(this.getCurrentBlock());this.SVG_.style.display="none";goog.asserts.assert(0==this.dragGroup_.childNodes.length,"Drag group was not cleared.");this.surfaceXY_=null;document.getElementsByClassName("injectionDiv")[0].style.overflow="hidden"};goog.Thenable=function(){};goog.Thenable.prototype.then=function(a,b,c){};goog.Thenable.IMPLEMENTED_BY_PROP="$goog_Thenable";goog.Thenable.addImplementation=function(a){COMPILED?a.prototype[goog.Thenable.IMPLEMENTED_BY_PROP]=!0:a.prototype.$goog_Thenable=!0};goog.Thenable.isImplementedBy=function(a){if(!a)return!1;try{return COMPILED?!!a[goog.Thenable.IMPLEMENTED_BY_PROP]:!!a.$goog_Thenable}catch(b){return!1}};goog.async={};goog.async.FreeList=function(a,b,c){this.limit_=c;this.create_=a;this.reset_=b;this.occupants_=0;this.head_=null};goog.async.FreeList.prototype.get=function(){if(0<this.occupants_){this.occupants_--;var a=this.head_;this.head_=a.next;a.next=null}else a=this.create_();return a};goog.async.FreeList.prototype.put=function(a){this.reset_(a);this.occupants_<this.limit_&&(this.occupants_++,a.next=this.head_,this.head_=a)};goog.async.FreeList.prototype.occupants=function(){return this.occupants_};goog.async.WorkQueue=function(){this.workTail_=this.workHead_=null};goog.async.WorkQueue.DEFAULT_MAX_UNUSED=100;goog.async.WorkQueue.freelist_=new goog.async.FreeList(function(){return new goog.async.WorkItem},function(a){a.reset()},goog.async.WorkQueue.DEFAULT_MAX_UNUSED);goog.async.WorkQueue.prototype.add=function(a,b){var c=this.getUnusedItem_();c.set(a,b);this.workTail_?this.workTail_.next=c:(goog.asserts.assert(!this.workHead_),this.workHead_=c);this.workTail_=c};
goog.async.WorkQueue.prototype.remove=function(){var a=null;this.workHead_&&(a=this.workHead_,this.workHead_=this.workHead_.next,this.workHead_||(this.workTail_=null),a.next=null);return a};goog.async.WorkQueue.prototype.returnUnused=function(a){goog.async.WorkQueue.freelist_.put(a)};goog.async.WorkQueue.prototype.getUnusedItem_=function(){return goog.async.WorkQueue.freelist_.get()};goog.async.WorkItem=function(){this.next=this.scope=this.fn=null};
goog.async.WorkItem.prototype.set=function(a,b){this.fn=a;this.scope=b;this.next=null};goog.async.WorkItem.prototype.reset=function(){this.next=this.scope=this.fn=null};goog.async.throwException=function(a){goog.global.setTimeout(function(){throw a;},0)};goog.async.nextTick=function(a,b,c){var d=a;b&&(d=goog.bind(a,b));d=goog.async.nextTick.wrapCallback_(d);goog.isFunction(goog.global.setImmediate)&&(c||goog.async.nextTick.useSetImmediate_())?goog.global.setImmediate(d):(goog.async.nextTick.setImmediate_||(goog.async.nextTick.setImmediate_=goog.async.nextTick.getSetImmediateEmulator_()),goog.async.nextTick.setImmediate_(d))};
goog.async.nextTick.useSetImmediate_=function(){return goog.global.Window&&goog.global.Window.prototype&&!goog.labs.userAgent.browser.isEdge()&&goog.global.Window.prototype.setImmediate==goog.global.setImmediate?!1:!0};
goog.async.nextTick.getSetImmediateEmulator_=function(){var a=goog.global.MessageChannel;"undefined"===typeof a&&"undefined"!==typeof window&&window.postMessage&&window.addEventListener&&!goog.labs.userAgent.engine.isPresto()&&(a=function(){var a=document.createElement("IFRAME");a.style.display="none";a.src="";document.documentElement.appendChild(a);var b=a.contentWindow;a=b.document;a.open();a.write("");a.close();var c="callImmediate"+Math.random(),d="file:"==b.location.protocol?"*":b.location.protocol+
"//"+b.location.host;a=goog.bind(function(a){if(("*"==d||a.origin==d)&&a.data==c)this.port1.onmessage()},this);b.addEventListener("message",a,!1);this.port1={};this.port2={postMessage:function(){b.postMessage(c,d)}}});if("undefined"!==typeof a&&!goog.labs.userAgent.browser.isIE()){var b=new a,c={},d=c;b.port1.onmessage=function(){if(goog.isDef(c.next)){c=c.next;var a=c.cb;c.cb=null;a()}};return function(a){d.next={cb:a};d=d.next;b.port2.postMessage(0)}}return"undefined"!==typeof document&&"onreadystatechange"in
document.createElement("SCRIPT")?function(a){var b=document.createElement("SCRIPT");b.onreadystatechange=function(){b.onreadystatechange=null;b.parentNode.removeChild(b);b=null;a();a=null};document.documentElement.appendChild(b)}:function(a){goog.global.setTimeout(a,0)}};goog.async.nextTick.wrapCallback_=goog.functions.identity;goog.debug.entryPointRegistry.register(function(a){goog.async.nextTick.wrapCallback_=a});goog.ASSUME_NATIVE_PROMISE=!1;goog.async.run=function(a,b){goog.async.run.schedule_||goog.async.run.initializeRunner_();goog.async.run.workQueueScheduled_||(goog.async.run.schedule_(),goog.async.run.workQueueScheduled_=!0);goog.async.run.workQueue_.add(a,b)};
goog.async.run.initializeRunner_=function(){if(goog.ASSUME_NATIVE_PROMISE||goog.global.Promise&&goog.global.Promise.resolve){var a=goog.global.Promise.resolve(void 0);goog.async.run.schedule_=function(){a.then(goog.async.run.processWorkQueue)}}else goog.async.run.schedule_=function(){goog.async.nextTick(goog.async.run.processWorkQueue)}};goog.async.run.forceNextTick=function(a){goog.async.run.schedule_=function(){goog.async.nextTick(goog.async.run.processWorkQueue);a&&a(goog.async.run.processWorkQueue)}};
goog.async.run.workQueueScheduled_=!1;goog.async.run.workQueue_=new goog.async.WorkQueue;goog.DEBUG&&(goog.async.run.resetQueue=function(){goog.async.run.workQueueScheduled_=!1;goog.async.run.workQueue_=new goog.async.WorkQueue});goog.async.run.processWorkQueue=function(){for(var a;a=goog.async.run.workQueue_.remove();){try{a.fn.call(a.scope)}catch(b){goog.async.throwException(b)}goog.async.run.workQueue_.returnUnused(a)}goog.async.run.workQueueScheduled_=!1};goog.promise={};goog.promise.Resolver=function(){};goog.Promise=function(a,b){this.state_=goog.Promise.State_.PENDING;this.result_=void 0;this.callbackEntriesTail_=this.callbackEntries_=this.parent_=null;this.executing_=!1;0<goog.Promise.UNHANDLED_REJECTION_DELAY?this.unhandledRejectionId_=0:0==goog.Promise.UNHANDLED_REJECTION_DELAY&&(this.hadUnhandledRejection_=!1);goog.Promise.LONG_STACK_TRACES&&(this.stack_=[],this.addStackTrace_(Error("created")),this.currentStep_=0);if(a!=goog.nullFunction)try{var c=this;a.call(b,function(a){c.resolve_(goog.Promise.State_.FULFILLED,
a)},function(a){if(goog.DEBUG&&!(a instanceof goog.Promise.CancellationError))try{if(a instanceof Error)throw a;throw Error("Promise rejected.");}catch(e){}c.resolve_(goog.Promise.State_.REJECTED,a)})}catch(d){this.resolve_(goog.Promise.State_.REJECTED,d)}};goog.Promise.LONG_STACK_TRACES=!1;goog.Promise.UNHANDLED_REJECTION_DELAY=0;goog.Promise.State_={PENDING:0,BLOCKED:1,FULFILLED:2,REJECTED:3};
goog.Promise.CallbackEntry_=function(){this.next=this.context=this.onRejected=this.onFulfilled=this.child=null;this.always=!1};goog.Promise.CallbackEntry_.prototype.reset=function(){this.context=this.onRejected=this.onFulfilled=this.child=null;this.always=!1};goog.Promise.DEFAULT_MAX_UNUSED=100;goog.Promise.freelist_=new goog.async.FreeList(function(){return new goog.Promise.CallbackEntry_},function(a){a.reset()},goog.Promise.DEFAULT_MAX_UNUSED);
goog.Promise.getCallbackEntry_=function(a,b,c){var d=goog.Promise.freelist_.get();d.onFulfilled=a;d.onRejected=b;d.context=c;return d};goog.Promise.returnEntry_=function(a){goog.Promise.freelist_.put(a)};goog.Promise.resolve=function(a){if(a instanceof goog.Promise)return a;var b=new goog.Promise(goog.nullFunction);b.resolve_(goog.Promise.State_.FULFILLED,a);return b};goog.Promise.reject=function(a){return new goog.Promise(function(b,c){c(a)})};
goog.Promise.resolveThen_=function(a,b,c){goog.Promise.maybeThen_(a,b,c,null)||goog.async.run(goog.partial(b,a))};goog.Promise.race=function(a){return new goog.Promise(function(b,c){a.length||b(void 0);for(var d=0,e;d<a.length;d++)e=a[d],goog.Promise.resolveThen_(e,b,c)})};
goog.Promise.all=function(a){return new goog.Promise(function(b,c){var d=a.length,e=[];if(d)for(var f=function(a,c){d--;e[a]=c;0==d&&b(e)},g=function(a){c(a)},h=0,k;h<a.length;h++)k=a[h],goog.Promise.resolveThen_(k,goog.partial(f,h),g);else b(e)})};
goog.Promise.allSettled=function(a){return new goog.Promise(function(b,c){var d=a.length,e=[];if(d){c=function(a,c,f){d--;e[a]=c?{fulfilled:!0,value:f}:{fulfilled:!1,reason:f};0==d&&b(e)};for(var f=0,g;f<a.length;f++)g=a[f],goog.Promise.resolveThen_(g,goog.partial(c,f,!0),goog.partial(c,f,!1))}else b(e)})};
goog.Promise.firstFulfilled=function(a){return new goog.Promise(function(b,c){var d=a.length,e=[];if(d)for(var f=function(a){b(a)},g=function(a,b){d--;e[a]=b;0==d&&c(e)},h=0,k;h<a.length;h++)k=a[h],goog.Promise.resolveThen_(k,f,goog.partial(g,h));else b(void 0)})};goog.Promise.withResolver=function(){var a,b,c=new goog.Promise(function(c,e){a=c;b=e});return new goog.Promise.Resolver_(c,a,b)};
goog.Promise.prototype.then=function(a,b,c){null!=a&&goog.asserts.assertFunction(a,"opt_onFulfilled should be a function.");null!=b&&goog.asserts.assertFunction(b,"opt_onRejected should be a function. Did you pass opt_context as the second argument instead of the third?");goog.Promise.LONG_STACK_TRACES&&this.addStackTrace_(Error("then"));return this.addChildPromise_(goog.isFunction(a)?a:null,goog.isFunction(b)?b:null,c)};goog.Thenable.addImplementation(goog.Promise);
goog.Promise.prototype.thenVoid=function(a,b,c){null!=a&&goog.asserts.assertFunction(a,"opt_onFulfilled should be a function.");null!=b&&goog.asserts.assertFunction(b,"opt_onRejected should be a function. Did you pass opt_context as the second argument instead of the third?");goog.Promise.LONG_STACK_TRACES&&this.addStackTrace_(Error("then"));this.addCallbackEntry_(goog.Promise.getCallbackEntry_(a||goog.nullFunction,b||null,c))};
goog.Promise.prototype.thenAlways=function(a,b){goog.Promise.LONG_STACK_TRACES&&this.addStackTrace_(Error("thenAlways"));a=goog.Promise.getCallbackEntry_(a,a,b);a.always=!0;this.addCallbackEntry_(a);return this};goog.Promise.prototype.thenCatch=function(a,b){goog.Promise.LONG_STACK_TRACES&&this.addStackTrace_(Error("thenCatch"));return this.addChildPromise_(null,a,b)};
goog.Promise.prototype.cancel=function(a){this.state_==goog.Promise.State_.PENDING&&goog.async.run(function(){var b=new goog.Promise.CancellationError(a);this.cancelInternal_(b)},this)};goog.Promise.prototype.cancelInternal_=function(a){this.state_==goog.Promise.State_.PENDING&&(this.parent_?(this.parent_.cancelChild_(this,a),this.parent_=null):this.resolve_(goog.Promise.State_.REJECTED,a))};
goog.Promise.prototype.cancelChild_=function(a,b){if(this.callbackEntries_){for(var c=0,d=null,e=null,f=this.callbackEntries_;f&&(f.always||(c++,f.child==a&&(d=f),!(d&&1<c)));f=f.next)d||(e=f);d&&(this.state_==goog.Promise.State_.PENDING&&1==c?this.cancelInternal_(b):(e?this.removeEntryAfter_(e):this.popEntry_(),this.executeCallback_(d,goog.Promise.State_.REJECTED,b)))}};
goog.Promise.prototype.addCallbackEntry_=function(a){this.hasEntry_()||this.state_!=goog.Promise.State_.FULFILLED&&this.state_!=goog.Promise.State_.REJECTED||this.scheduleCallbacks_();this.queueEntry_(a)};
goog.Promise.prototype.addChildPromise_=function(a,b,c){var d=goog.Promise.getCallbackEntry_(null,null,null);d.child=new goog.Promise(function(e,f){d.onFulfilled=a?function(b){try{var d=a.call(c,b);e(d)}catch(k){f(k)}}:e;d.onRejected=b?function(a){try{var d=b.call(c,a);!goog.isDef(d)&&a instanceof goog.Promise.CancellationError?f(a):e(d)}catch(k){f(k)}}:f});d.child.parent_=this;this.addCallbackEntry_(d);return d.child};
goog.Promise.prototype.unblockAndFulfill_=function(a){goog.asserts.assert(this.state_==goog.Promise.State_.BLOCKED);this.state_=goog.Promise.State_.PENDING;this.resolve_(goog.Promise.State_.FULFILLED,a)};goog.Promise.prototype.unblockAndReject_=function(a){goog.asserts.assert(this.state_==goog.Promise.State_.BLOCKED);this.state_=goog.Promise.State_.PENDING;this.resolve_(goog.Promise.State_.REJECTED,a)};
goog.Promise.prototype.resolve_=function(a,b){this.state_==goog.Promise.State_.PENDING&&(this===b&&(a=goog.Promise.State_.REJECTED,b=new TypeError("Promise cannot resolve to itself")),this.state_=goog.Promise.State_.BLOCKED,goog.Promise.maybeThen_(b,this.unblockAndFulfill_,this.unblockAndReject_,this)||(this.result_=b,this.state_=a,this.parent_=null,this.scheduleCallbacks_(),a!=goog.Promise.State_.REJECTED||b instanceof goog.Promise.CancellationError||goog.Promise.addUnhandledRejection_(this,b)))};
goog.Promise.maybeThen_=function(a,b,c,d){if(a instanceof goog.Promise)return a.thenVoid(b,c,d),!0;if(goog.Thenable.isImplementedBy(a))return a.then(b,c,d),!0;if(goog.isObject(a))try{var e=a.then;if(goog.isFunction(e))return goog.Promise.tryThen_(a,e,b,c,d),!0}catch(f){return c.call(d,f),!0}return!1};goog.Promise.tryThen_=function(a,b,c,d,e){var f=!1,g=function(a){f||(f=!0,c.call(e,a))},h=function(a){f||(f=!0,d.call(e,a))};try{b.call(a,g,h)}catch(k){h(k)}};
goog.Promise.prototype.scheduleCallbacks_=function(){this.executing_||(this.executing_=!0,goog.async.run(this.executeCallbacks_,this))};goog.Promise.prototype.hasEntry_=function(){return!!this.callbackEntries_};goog.Promise.prototype.queueEntry_=function(a){goog.asserts.assert(null!=a.onFulfilled);this.callbackEntriesTail_?this.callbackEntriesTail_.next=a:this.callbackEntries_=a;this.callbackEntriesTail_=a};
goog.Promise.prototype.popEntry_=function(){var a=null;this.callbackEntries_&&(a=this.callbackEntries_,this.callbackEntries_=a.next,a.next=null);this.callbackEntries_||(this.callbackEntriesTail_=null);null!=a&&goog.asserts.assert(null!=a.onFulfilled);return a};goog.Promise.prototype.removeEntryAfter_=function(a){goog.asserts.assert(this.callbackEntries_);goog.asserts.assert(null!=a);a.next==this.callbackEntriesTail_&&(this.callbackEntriesTail_=a);a.next=a.next.next};
goog.Promise.prototype.executeCallbacks_=function(){for(var a;a=this.popEntry_();)goog.Promise.LONG_STACK_TRACES&&this.currentStep_++,this.executeCallback_(a,this.state_,this.result_);this.executing_=!1};
goog.Promise.prototype.executeCallback_=function(a,b,c){b==goog.Promise.State_.REJECTED&&a.onRejected&&!a.always&&this.removeUnhandledRejection_();if(a.child)a.child.parent_=null,goog.Promise.invokeCallback_(a,b,c);else try{a.always?a.onFulfilled.call(a.context):goog.Promise.invokeCallback_(a,b,c)}catch(d){goog.Promise.handleRejection_.call(null,d)}goog.Promise.returnEntry_(a)};
goog.Promise.invokeCallback_=function(a,b,c){b==goog.Promise.State_.FULFILLED?a.onFulfilled.call(a.context,c):a.onRejected&&a.onRejected.call(a.context,c)};goog.Promise.prototype.addStackTrace_=function(a){if(goog.Promise.LONG_STACK_TRACES&&goog.isString(a.stack)){var b=a.stack.split("\n",4)[3];a=a.message;a+=Array(11-a.length).join(" ");this.stack_.push(a+b)}};
goog.Promise.prototype.appendLongStack_=function(a){if(goog.Promise.LONG_STACK_TRACES&&a&&goog.isString(a.stack)&&this.stack_.length){for(var b=["Promise trace:"],c=this;c;c=c.parent_){for(var d=this.currentStep_;0<=d;d--)b.push(c.stack_[d]);b.push("Value: ["+(c.state_==goog.Promise.State_.REJECTED?"REJECTED":"FULFILLED")+"] <"+String(c.result_)+">")}a.stack+="\n\n"+b.join("\n")}};
goog.Promise.prototype.removeUnhandledRejection_=function(){if(0<goog.Promise.UNHANDLED_REJECTION_DELAY)for(var a=this;a&&a.unhandledRejectionId_;a=a.parent_)goog.global.clearTimeout(a.unhandledRejectionId_),a.unhandledRejectionId_=0;else if(0==goog.Promise.UNHANDLED_REJECTION_DELAY)for(a=this;a&&a.hadUnhandledRejection_;a=a.parent_)a.hadUnhandledRejection_=!1};
goog.Promise.addUnhandledRejection_=function(a,b){0<goog.Promise.UNHANDLED_REJECTION_DELAY?a.unhandledRejectionId_=goog.global.setTimeout(function(){a.appendLongStack_(b);goog.Promise.handleRejection_.call(null,b)},goog.Promise.UNHANDLED_REJECTION_DELAY):0==goog.Promise.UNHANDLED_REJECTION_DELAY&&(a.hadUnhandledRejection_=!0,goog.async.run(function(){a.hadUnhandledRejection_&&(a.appendLongStack_(b),goog.Promise.handleRejection_.call(null,b))}))};goog.Promise.handleRejection_=goog.async.throwException;
goog.Promise.setUnhandledRejectionHandler=function(a){goog.Promise.handleRejection_=a};goog.Promise.CancellationError=function(a){goog.debug.Error.call(this,a)};goog.inherits(goog.Promise.CancellationError,goog.debug.Error);goog.Promise.CancellationError.prototype.name="cancel";goog.Promise.Resolver_=function(a,b,c){this.promise=a;this.resolve=b;this.reject=c};goog.Timer=function(a,b){goog.events.EventTarget.call(this);this.interval_=a||1;this.timerObject_=b||goog.Timer.defaultTimerObject;this.boundTick_=goog.bind(this.tick_,this);this.last_=goog.now()};goog.inherits(goog.Timer,goog.events.EventTarget);goog.Timer.MAX_TIMEOUT_=2147483647;goog.Timer.INVALID_TIMEOUT_ID_=-1;goog.Timer.prototype.enabled=!1;goog.Timer.defaultTimerObject=goog.global;goog.Timer.intervalScale=.8;goog.Timer.prototype.timer_=null;goog.Timer.prototype.getInterval=function(){return this.interval_};
goog.Timer.prototype.setInterval=function(a){this.interval_=a;this.timer_&&this.enabled?(this.stop(),this.start()):this.timer_&&this.stop()};goog.Timer.prototype.tick_=function(){if(this.enabled){var a=goog.now()-this.last_;0<a&&a<this.interval_*goog.Timer.intervalScale?this.timer_=this.timerObject_.setTimeout(this.boundTick_,this.interval_-a):(this.timer_&&(this.timerObject_.clearTimeout(this.timer_),this.timer_=null),this.dispatchTick(),this.enabled&&(this.stop(),this.start()))}};
goog.Timer.prototype.dispatchTick=function(){this.dispatchEvent(goog.Timer.TICK)};goog.Timer.prototype.start=function(){this.enabled=!0;this.timer_||(this.timer_=this.timerObject_.setTimeout(this.boundTick_,this.interval_),this.last_=goog.now())};goog.Timer.prototype.stop=function(){this.enabled=!1;this.timer_&&(this.timerObject_.clearTimeout(this.timer_),this.timer_=null)};goog.Timer.prototype.disposeInternal=function(){goog.Timer.superClass_.disposeInternal.call(this);this.stop();delete this.timerObject_};
goog.Timer.TICK="tick";goog.Timer.callOnce=function(a,b,c){if(goog.isFunction(a))c&&(a=goog.bind(a,c));else if(a&&"function"==typeof a.handleEvent)a=goog.bind(a.handleEvent,a);else throw Error("Invalid listener argument");return Number(b)>goog.Timer.MAX_TIMEOUT_?goog.Timer.INVALID_TIMEOUT_ID_:goog.Timer.defaultTimerObject.setTimeout(a,b||0)};goog.Timer.clear=function(a){goog.Timer.defaultTimerObject.clearTimeout(a)};
goog.Timer.promise=function(a,b){var c=null;return(new goog.Promise(function(d,e){c=goog.Timer.callOnce(function(){d(b)},a);c==goog.Timer.INVALID_TIMEOUT_ID_&&e(Error("Failed to schedule timer."))})).thenCatch(function(a){goog.Timer.clear(c);throw a;})};Blockly.BlockSvg=function(a,b,c){this.svgGroup_=Blockly.utils.createSvgElement("g",{},null);this.svgPath_=Blockly.utils.createSvgElement("path",{"class":"blocklyPath blocklyBlockBackground"},this.svgGroup_);this.svgPath_.tooltip=this;this.rendered=!1;this.useDragSurface_=Blockly.utils.is3dSupported()&&!!a.blockDragSurface_;Blockly.Tooltip.bindMouseEvents(this.svgPath_);Blockly.BlockSvg.superClass_.constructor.call(this,a,b,c);this.svgGroup_.dataset&&(this.svgGroup_.dataset.id=this.id)};
goog.inherits(Blockly.BlockSvg,Blockly.Block);Blockly.BlockSvg.prototype.height=0;Blockly.BlockSvg.prototype.width=0;Blockly.BlockSvg.prototype.insertionMarkerMinWidth_=0;Blockly.BlockSvg.prototype.opacity_=1;Blockly.BlockSvg.prototype.dragStartXY_=null;Blockly.BlockSvg.prototype.isGlowingBlock_=!1;Blockly.BlockSvg.prototype.isGlowingStack_=!1;Blockly.BlockSvg.INLINE=-1;
Blockly.BlockSvg.prototype.initSvg=function(){goog.asserts.assert(this.workspace.rendered,"Workspace is headless.");if(!this.isInsertionMarker()){for(var a=0,b;b=this.inputList[a];a++)b.init(),b.initOutlinePath(this.svgGroup_);b=this.getIcons();for(a=0;a<b.length;a++)b[a].createIcon()}this.updateColour();this.updateMovable();this.workspace.options.readOnly||this.eventsInit_||Blockly.bindEventWithChecks_(this.getSvgRoot(),"mousedown",this,this.onMouseDown_);this.eventsInit_=!0;this.getSvgRoot().parentNode||
this.workspace.getCanvas().appendChild(this.getSvgRoot())};Blockly.BlockSvg.prototype.select=function(){if(this.isShadow()&&this.getParent())this.getParent().select();else if(Blockly.selected!=this){var a=null;if(Blockly.selected){a=Blockly.selected.id;Blockly.Events.disable();try{Blockly.selected.unselect()}finally{Blockly.Events.enable()}}a=new Blockly.Events.Ui(null,"selected",a,this.id);a.workspaceId=this.workspace.id;Blockly.Events.fire(a);Blockly.selected=this;this.addSelect()}};
Blockly.BlockSvg.prototype.unselect=function(){if(Blockly.selected==this){var a=new Blockly.Events.Ui(null,"selected",this.id,null);a.workspaceId=this.workspace.id;Blockly.Events.fire(a);Blockly.selected=null;this.removeSelect()}};Blockly.BlockSvg.prototype.setGlowBlock=function(a){this.isGlowingBlock_=a;this.updateColour()};
Blockly.BlockSvg.prototype.setGlowStack=function(a){this.isGlowingStack_=a;a=this.getSvgRoot();this.isGlowingStack_&&!a.hasAttribute("filter")?a.setAttribute("filter","url(#"+(this.workspace.options.stackGlowFilterId||"blocklyStackGlowFilter")+")"):!this.isGlowingStack_&&a.hasAttribute("filter")&&a.removeAttribute("filter")};Blockly.BlockSvg.prototype.mutator=null;Blockly.BlockSvg.prototype.comment=null;Blockly.BlockSvg.prototype.warning=null;
Blockly.BlockSvg.prototype.getIcons=function(){var a=[];this.mutator&&a.push(this.mutator);this.comment&&a.push(this.comment);this.warning&&a.push(this.warning);return a};
Blockly.BlockSvg.prototype.setParent=function(a){var b=this.parentBlock_;if(a!=b){Blockly.Field.startCache();Blockly.BlockSvg.superClass_.setParent.call(this,a);Blockly.Field.stopCache();var c=this.getSvgRoot();if(!this.workspace.isClearing&&c){var d=this.getRelativeToSurfaceXY();a?(a.getSvgRoot().appendChild(c),b=this.getRelativeToSurfaceXY(),this.moveConnections_(b.x-d.x,b.y-d.y),this.isShadow()&&this.setColour(this.getColour(),this.getColourSecondary(),a.getColourTertiary())):b&&(this.workspace.getCanvas().appendChild(c),
this.translate(d.x,d.y))}}};Blockly.BlockSvg.prototype.getRelativeToSurfaceXY=function(){var a=0,b=0,c=this.useDragSurface_?this.workspace.blockDragSurface_.getGroup():null,d=this.getSvgRoot();if(d){do{var e=Blockly.utils.getRelativeXY(d);a+=e.x;b+=e.y;this.useDragSurface_&&this.workspace.blockDragSurface_.getCurrentBlock()==d&&(e=this.workspace.blockDragSurface_.getSurfaceTranslation(),a+=e.x,b+=e.y);d=d.parentNode}while(d&&d!=this.workspace.getCanvas()&&d!=c)}return new goog.math.Coordinate(a,b)};
Blockly.BlockSvg.prototype.moveBy=function(a,b){goog.asserts.assert(!this.parentBlock_,"Block has parent.");var c=Blockly.Events.isEnabled();if(c)var d=new Blockly.Events.BlockMove(this);var e=this.getRelativeToSurfaceXY();this.translate(e.x+a,e.y+b);this.moveConnections_(a,b);c&&(d.recordNew(),Blockly.Events.fire(d));this.workspace.resizeContents()};Blockly.BlockSvg.prototype.translate=function(a,b){this.getSvgRoot().setAttribute("transform","translate("+a+","+b+")")};
Blockly.BlockSvg.prototype.moveToDragSurface_=function(){if(this.useDragSurface_){var a=this.getRelativeToSurfaceXY();this.clearTransformAttributes_();this.workspace.blockDragSurface_.translateSurface(a.x,a.y);this.workspace.blockDragSurface_.setBlocksAndShow(this.getSvgRoot())}};Blockly.BlockSvg.prototype.moveOffDragSurface_=function(a){this.useDragSurface_&&(this.translate(a.x,a.y),this.workspace.blockDragSurface_.clearAndHide(this.workspace.getCanvas()))};
Blockly.BlockSvg.prototype.moveDuringDrag=function(a){this.useDragSurface_?this.workspace.blockDragSurface_.translateSurface(a.x,a.y):(this.svgGroup_.translate_="translate("+a.x+","+a.y+")",this.svgGroup_.setAttribute("transform",this.svgGroup_.translate_+this.svgGroup_.skew_))};Blockly.BlockSvg.prototype.clearTransformAttributes_=function(){Blockly.utils.removeAttribute(this.getSvgRoot(),"transform")};
Blockly.BlockSvg.prototype.snapToGrid=function(){if(this.workspace&&!this.workspace.isDragging()&&!this.getParent()&&!this.isInFlyout){var a=this.workspace.getGrid();if(a&&a.shouldSnap()){var b=a.getSpacing(),c=b/2,d=this.getRelativeToSurfaceXY();a=Math.round((d.x-c)/b)*b+c-d.x;b=Math.round((d.y-c)/b)*b+c-d.y;a=Math.round(a);b=Math.round(b);0==a&&0==b||this.moveBy(a,b)}}};
Blockly.BlockSvg.prototype.getBoundingRectangle=function(){var a=this.getRelativeToSurfaceXY(this),b=this.getHeightWidth();if(this.RTL){var c=new goog.math.Coordinate(a.x-b.width,a.y);a=new goog.math.Coordinate(a.x,a.y+b.height)}else c=new goog.math.Coordinate(a.x,a.y),a=new goog.math.Coordinate(a.x+b.width,a.y+b.height);return{topLeft:c,bottomRight:a}};Blockly.BlockSvg.prototype.setOpacity=function(a){this.opacity_=a;this.rendered&&this.updateColour()};
Blockly.BlockSvg.prototype.getOpacity=function(){return this.isEnabled()?this.opacity_:.5};
Blockly.BlockSvg.prototype.setCollapsed=function(a){if(this.collapsed_!=a){for(var b=[],c=0,d;d=this.inputList[c];c++)b.push.apply(b,d.setVisible(!a));if(a){d=this.getIcons();for(c=0;c<d.length;c++)d[c].setVisible(!1);c=this.toString(Blockly.COLLAPSE_CHARS);this.appendDummyInput("_TEMP_COLLAPSED_INPUT").appendField(c).init()}else this.removeInput("_TEMP_COLLAPSED_INPUT"),this.setWarningText(null);Blockly.BlockSvg.superClass_.setCollapsed.call(this,a);b.length||(b[0]=this);if(this.rendered)for(c=0;a=
b[c];c++)a.render()}};Blockly.BlockSvg.prototype.tab=function(a,b){var c=this.createTabList_();a=c.indexOf(a);-1==a&&(a=b?-1:c.length);(c=c[b?a+1:a-1])?c instanceof Blockly.Field?c.showEditor_():c.tab(null,b):(c=this.outputConnection&&this.outputConnection.targetBlock())?c.tab(this,b):(c=b?this.getNextBlock():this.getPreviousBlock())&&c.tab(this,b)};
Blockly.BlockSvg.prototype.createTabList_=function(){for(var a=[],b=0,c;c=this.inputList[b];b++){for(var d=0,e;e=c.fieldRow[d];d++)e instanceof Blockly.FieldTextInput&&a.push(e);c.connection&&(c=c.connection.targetBlock())&&a.push(c)}return a};Blockly.BlockSvg.prototype.onMouseDown_=function(a){var b=this.workspace&&this.workspace.getGesture(a);b&&b.handleBlockStart(a,this)};Blockly.BlockSvg.prototype.showHelp_=function(){var a=goog.isFunction(this.helpUrl)?this.helpUrl():this.helpUrl;a&&alert(a)};
Blockly.BlockSvg.prototype.showContextMenu_=function(a){if(!this.workspace.options.readOnly&&this.contextMenu){var b=[];if(this.isDeletable()&&this.isMovable()&&!this.isInFlyout)b.push(Blockly.ContextMenu.blockDuplicateOption(this,a)),this.isEditable()&&this.workspace.options.comments&&b.push(Blockly.ContextMenu.blockCommentOption(this)),b.push(Blockly.ContextMenu.blockDeleteOption(this));else if(this.parentBlock_&&this.isShadow_){this.parentBlock_.showContextMenu_(a);return}this.customContextMenu&&
this.customContextMenu(b);Blockly.ContextMenu.show(a,b,this.RTL);Blockly.ContextMenu.currentBlock=this}};Blockly.BlockSvg.prototype.moveConnections_=function(a,b){if(this.rendered){for(var c=this.getConnections_(!1),d=0;d<c.length;d++)c[d].moveBy(a,b);c=this.getIcons();for(d=0;d<c.length;d++)c[d].computeIconLocation();for(d=0;d<this.childBlocks_.length;d++)this.childBlocks_[d].moveConnections_(a,b)}};
Blockly.BlockSvg.prototype.setDragging=function(a){if(a){var b=this.getSvgRoot();b.translate_="";b.skew_="";Blockly.draggingConnections_=Blockly.draggingConnections_.concat(this.getConnections_(!0));Blockly.utils.addClass(this.svgGroup_,"blocklyDragging")}else Blockly.draggingConnections_=[],Blockly.utils.removeClass(this.svgGroup_,"blocklyDragging");for(b=0;b<this.childBlocks_.length;b++)this.childBlocks_[b].setDragging(a)};
Blockly.BlockSvg.prototype.updateMovable=function(){this.isMovable()?Blockly.utils.addClass(this.svgGroup_,"blocklyDraggable"):Blockly.utils.removeClass(this.svgGroup_,"blocklyDraggable")};Blockly.BlockSvg.prototype.setMovable=function(a){Blockly.BlockSvg.superClass_.setMovable.call(this,a);this.updateMovable()};Blockly.BlockSvg.prototype.setEditable=function(a){Blockly.BlockSvg.superClass_.setEditable.call(this,a);a=this.getIcons();for(var b=0;b<a.length;b++)a[b].updateEditable()};
Blockly.BlockSvg.prototype.setShadow=function(a){Blockly.BlockSvg.superClass_.setShadow.call(this,a);this.updateColour()};Blockly.BlockSvg.prototype.setInsertionMarker=function(a,b){Blockly.BlockSvg.superClass_.setInsertionMarker.call(this,a);this.insertionMarkerMinWidth_=b;this.updateColour()};Blockly.BlockSvg.prototype.getSvgRoot=function(){return this.svgGroup_};
Blockly.BlockSvg.prototype.dispose=function(a,b){if(this.workspace){Blockly.Tooltip.hide();Blockly.Field.startCache();var c=this.workspace;Blockly.selected==this&&(this.unselect(),this.workspace.cancelCurrentGesture());Blockly.ContextMenu.currentBlock==this&&Blockly.ContextMenu.hide();b&&this.rendered&&(this.unplug(a),Blockly.BlockAnimations.disposeUiEffect(this));this.rendered=!1;Blockly.Events.disable();try{var d=this.getIcons();for(b=0;b<d.length;b++)d[b].dispose()}finally{Blockly.Events.enable()}Blockly.BlockSvg.superClass_.dispose.call(this,
a);goog.dom.removeNode(this.svgGroup_);c.resizeContents();this.svgPath_=this.svgGroup_=null;Blockly.Field.stopCache()}};Blockly.BlockSvg.prototype.updateDisabled=function(){var a=this.getChildren(!1);this.updateColour();if(!this.isCollapsed())for(var b=0,c;c=a[b];b++)c.rendered&&c.updateDisabled()};Blockly.BlockSvg.prototype.getCommentText=function(){return this.comment?this.comment.getText().replace(/\s+$/,"").replace(/ +\n/g,"\n"):""};
Blockly.BlockSvg.prototype.setCommentText=function(a,b,c,d,e){var f=!1;goog.isString(a)?this.comment?this.comment.setText(a):(this.comment=new Blockly.ScratchBlockComment(this,a,b,c,d,e),f=!0):this.comment&&(this.comment.dispose(),f=!0);f&&this.rendered&&(this.render(),goog.isString(a)&&this.comment.setVisible(!0),this.bumpNeighbours_())};
Blockly.BlockSvg.prototype.setWarningText=function(a,b){this.setWarningText.pid_||(this.setWarningText.pid_=Object.create(null));var c=b||"";if(c)this.setWarningText.pid_[c]&&(clearTimeout(this.setWarningText.pid_[c]),delete this.setWarningText.pid_[c]);else for(var d in this.setWarningText.pid_)clearTimeout(this.setWarningText.pid_[d]),delete this.setWarningText.pid_[d];if(this.workspace.isDragging()){var e=this;this.setWarningText.pid_[c]=setTimeout(function(){e.workspace&&(delete e.setWarningText.pid_[c],
e.setWarningText(a,c))},100)}else this.isInFlyout&&(a=null),b=!1,goog.isString(a)?(this.warning||(this.warning=new Blockly.Warning(this),b=!0),this.warning.setText(a,c)):this.warning&&!c?(this.warning.dispose(),b=!0):this.warning&&(b=this.warning.getText(),this.warning.setText("",c),(d=this.warning.getText())||this.warning.dispose(),b=b!=d),b&&this.rendered&&(this.render(),this.bumpNeighbours_())};
Blockly.BlockSvg.prototype.setMutator=function(a){this.mutator&&this.mutator!==a&&this.mutator.dispose();a&&(a.block_=this,this.mutator=a,a.createIcon())};Blockly.BlockSvg.prototype.setEnabled=function(a){this.isEnabled()!=a&&(Blockly.BlockSvg.superClass_.setEnabled.call(this,a),this.rendered&&!this.getInheritedDisabled()&&this.updateDisabled())};Blockly.BlockSvg.prototype.addSelect=function(){Blockly.utils.addClass(this.svgGroup_,"blocklySelected")};
Blockly.BlockSvg.prototype.removeSelect=function(){Blockly.utils.removeClass(this.svgGroup_,"blocklySelected")};Blockly.BlockSvg.prototype.setMouseThroughStyle=function(a){a?Blockly.utils.addClass(this.svgGroup_,"blocklyDraggingMouseThrough"):Blockly.utils.removeClass(this.svgGroup_,"blocklyDraggingMouseThrough")};Blockly.BlockSvg.prototype.setDeleteStyle=function(a){a?Blockly.utils.addClass(this.svgGroup_,"blocklyDraggingDelete"):Blockly.utils.removeClass(this.svgGroup_,"blocklyDraggingDelete")};
Blockly.BlockSvg.prototype.setColour=function(a,b,c){Blockly.BlockSvg.superClass_.setColour.call(this,a,b,c);this.rendered&&this.updateColour()};Blockly.BlockSvg.prototype.bringToFront=function(){var a=this;do{var b=a.getSvgRoot();b.parentNode.appendChild(b);a=a.getParent()}while(a)};Blockly.BlockSvg.prototype.setPreviousStatement=function(a,b){Blockly.BlockSvg.superClass_.setPreviousStatement.call(this,a,b);this.rendered&&(this.render(),this.bumpNeighbours_())};
Blockly.BlockSvg.prototype.setNextStatement=function(a,b){Blockly.BlockSvg.superClass_.setNextStatement.call(this,a,b);this.rendered&&(this.render(),this.bumpNeighbours_())};Blockly.BlockSvg.prototype.setOutput=function(a,b){Blockly.BlockSvg.superClass_.setOutput.call(this,a,b);this.rendered&&(this.render(),this.bumpNeighbours_())};Blockly.BlockSvg.prototype.setInputsInline=function(a){Blockly.BlockSvg.superClass_.setInputsInline.call(this,a);this.rendered&&(this.render(),this.bumpNeighbours_())};
Blockly.BlockSvg.prototype.removeInput=function(a,b){Blockly.BlockSvg.superClass_.removeInput.call(this,a,b);this.rendered&&(this.render(),this.bumpNeighbours_())};Blockly.BlockSvg.prototype.moveNumberedInputBefore=function(a,b){Blockly.BlockSvg.superClass_.moveNumberedInputBefore.call(this,a,b);this.rendered&&(this.render(),this.bumpNeighbours_())};
Blockly.BlockSvg.prototype.appendInput_=function(a,b){a=Blockly.BlockSvg.superClass_.appendInput_.call(this,a,b);this.rendered&&(this.render(),this.bumpNeighbours_());return a};Blockly.BlockSvg.prototype.getConnections_=function(a){var b=[];if(a||this.rendered)if(this.outputConnection&&b.push(this.outputConnection),this.previousConnection&&b.push(this.previousConnection),this.nextConnection&&b.push(this.nextConnection),a||!this.collapsed_){a=0;for(var c;c=this.inputList[a];a++)c.connection&&b.push(c.connection)}return b};
Blockly.BlockSvg.prototype.makeConnection_=function(a){return new Blockly.RenderedConnection(this,a)};
Blockly.BlockSvg.prototype.bumpNeighbours_=function(){if(this.workspace&&!this.workspace.isDragging()){var a=this.getRootBlock();if(!a.isInFlyout)for(var b=this.getConnections_(!1),c=0,d;d=b[c];c++){d.isConnected()&&d.isSuperior()&&d.targetBlock().bumpNeighbours_();for(var e=d.neighbours_(Blockly.SNAP_RADIUS),f=0,g;g=e[f];f++)d.isConnected()&&g.isConnected()||g.getSourceBlock().getRootBlock()!=a&&(d.isSuperior()?g.bumpAwayFrom_(d):d.bumpAwayFrom_(g))}}};
Blockly.BlockSvg.prototype.scheduleSnapAndBump=function(){var a=this,b=Blockly.Events.getGroup();setTimeout(function(){Blockly.Events.setGroup(b);a.snapToGrid();Blockly.Events.setGroup(!1)},Blockly.BUMP_DELAY/2);setTimeout(function(){Blockly.Events.setGroup(b);a.bumpNeighbours_();Blockly.Events.setGroup(!1)},Blockly.BUMP_DELAY)};Blockly.BlockSvg.render={};Blockly.BlockSvg.GRID_UNIT=4;Blockly.BlockSvg.SEP_SPACE_X=2*Blockly.BlockSvg.GRID_UNIT;Blockly.BlockSvg.SEP_SPACE_Y=2*Blockly.BlockSvg.GRID_UNIT;Blockly.BlockSvg.MIN_BLOCK_X=16*Blockly.BlockSvg.GRID_UNIT;Blockly.BlockSvg.MIN_BLOCK_X_OUTPUT=12*Blockly.BlockSvg.GRID_UNIT;Blockly.BlockSvg.MIN_BLOCK_X_SHADOW_OUTPUT=10*Blockly.BlockSvg.GRID_UNIT;Blockly.BlockSvg.MIN_BLOCK_Y=12*Blockly.BlockSvg.GRID_UNIT;Blockly.BlockSvg.EXTRA_STATEMENT_ROW_Y=8*Blockly.BlockSvg.GRID_UNIT;
Blockly.BlockSvg.MIN_BLOCK_X_WITH_STATEMENT=40*Blockly.BlockSvg.GRID_UNIT;Blockly.BlockSvg.MIN_BLOCK_Y_SINGLE_FIELD_OUTPUT=8*Blockly.BlockSvg.GRID_UNIT;Blockly.BlockSvg.MIN_BLOCK_Y_REPORTER=10*Blockly.BlockSvg.GRID_UNIT;Blockly.BlockSvg.MIN_STATEMENT_INPUT_HEIGHT=6*Blockly.BlockSvg.GRID_UNIT;Blockly.BlockSvg.NOTCH_WIDTH=8*Blockly.BlockSvg.GRID_UNIT;Blockly.BlockSvg.NOTCH_HEIGHT=2*Blockly.BlockSvg.GRID_UNIT;Blockly.BlockSvg.CORNER_RADIUS=1*Blockly.BlockSvg.GRID_UNIT;
Blockly.BlockSvg.STATEMENT_INPUT_EDGE_WIDTH=4*Blockly.BlockSvg.GRID_UNIT;Blockly.BlockSvg.STATEMENT_INPUT_INNER_SPACE=2*Blockly.BlockSvg.GRID_UNIT;Blockly.BlockSvg.START_HAT_HEIGHT=16;Blockly.BlockSvg.ICON_SEPARATOR_HEIGHT=10*Blockly.BlockSvg.GRID_UNIT;Blockly.BlockSvg.START_HAT_PATH="c 25,-22 71,-22 96,0";Blockly.BlockSvg.NOTCH_PATH_LEFT="c 2,0 3,1 4,2 l 4,4 c 1,1 2,2 4,2 h 12 c 2,0 3,-1 4,-2 l 4,-4 c 1,-1 2,-2 4,-2";Blockly.BlockSvg.NOTCH_PATH_RIGHT="c -2,0 -3,1 -4,2 l -4,4 c -1,1 -2,2 -4,2 h -12 c -2,0 -3,-1 -4,-2 l -4,-4 c -1,-1 -2,-2 -4,-2";
Blockly.BlockSvg.NOTCH_START_PADDING=3*Blockly.BlockSvg.GRID_UNIT;Blockly.BlockSvg.TOP_LEFT_CORNER_START="m 0,"+Blockly.BlockSvg.CORNER_RADIUS;Blockly.BlockSvg.TOP_LEFT_CORNER="A "+Blockly.BlockSvg.CORNER_RADIUS+","+Blockly.BlockSvg.CORNER_RADIUS+" 0 0,1 "+Blockly.BlockSvg.CORNER_RADIUS+",0";Blockly.BlockSvg.TOP_RIGHT_CORNER="a "+Blockly.BlockSvg.CORNER_RADIUS+","+Blockly.BlockSvg.CORNER_RADIUS+" 0 0,1 "+Blockly.BlockSvg.CORNER_RADIUS+","+Blockly.BlockSvg.CORNER_RADIUS;
Blockly.BlockSvg.BOTTOM_RIGHT_CORNER=" a "+Blockly.BlockSvg.CORNER_RADIUS+","+Blockly.BlockSvg.CORNER_RADIUS+" 0 0,1 -"+Blockly.BlockSvg.CORNER_RADIUS+","+Blockly.BlockSvg.CORNER_RADIUS;Blockly.BlockSvg.BOTTOM_LEFT_CORNER="a "+Blockly.BlockSvg.CORNER_RADIUS+","+Blockly.BlockSvg.CORNER_RADIUS+" 0 0,1 -"+Blockly.BlockSvg.CORNER_RADIUS+",-"+Blockly.BlockSvg.CORNER_RADIUS;
Blockly.BlockSvg.INNER_TOP_LEFT_CORNER=" a "+Blockly.BlockSvg.CORNER_RADIUS+","+Blockly.BlockSvg.CORNER_RADIUS+" 0 0,0 -"+Blockly.BlockSvg.CORNER_RADIUS+","+Blockly.BlockSvg.CORNER_RADIUS;Blockly.BlockSvg.INNER_BOTTOM_LEFT_CORNER="a "+Blockly.BlockSvg.CORNER_RADIUS+","+Blockly.BlockSvg.CORNER_RADIUS+" 0 0,0 "+Blockly.BlockSvg.CORNER_RADIUS+","+Blockly.BlockSvg.CORNER_RADIUS;
Blockly.BlockSvg.INPUT_SHAPE_HEXAGONAL="M "+4*Blockly.BlockSvg.GRID_UNIT+",0  h "+4*Blockly.BlockSvg.GRID_UNIT+" l "+4*Blockly.BlockSvg.GRID_UNIT+","+4*Blockly.BlockSvg.GRID_UNIT+" l "+-4*Blockly.BlockSvg.GRID_UNIT+","+4*Blockly.BlockSvg.GRID_UNIT+" h "+-4*Blockly.BlockSvg.GRID_UNIT+" l "+-4*Blockly.BlockSvg.GRID_UNIT+","+-4*Blockly.BlockSvg.GRID_UNIT+" l "+4*Blockly.BlockSvg.GRID_UNIT+","+-4*Blockly.BlockSvg.GRID_UNIT+" z";Blockly.BlockSvg.INPUT_SHAPE_HEXAGONAL_WIDTH=12*Blockly.BlockSvg.GRID_UNIT;
Blockly.BlockSvg.INPUT_SHAPE_SQUARE=Blockly.BlockSvg.TOP_LEFT_CORNER_START+Blockly.BlockSvg.TOP_LEFT_CORNER+" h "+(12*Blockly.BlockSvg.GRID_UNIT-2*Blockly.BlockSvg.CORNER_RADIUS)+Blockly.BlockSvg.TOP_RIGHT_CORNER+" v "+(8*Blockly.BlockSvg.GRID_UNIT-2*Blockly.BlockSvg.CORNER_RADIUS)+Blockly.BlockSvg.BOTTOM_RIGHT_CORNER+" h "+(-12*Blockly.BlockSvg.GRID_UNIT+2*Blockly.BlockSvg.CORNER_RADIUS)+Blockly.BlockSvg.BOTTOM_LEFT_CORNER+" z";Blockly.BlockSvg.INPUT_SHAPE_SQUARE_WIDTH=10*Blockly.BlockSvg.GRID_UNIT;
Blockly.BlockSvg.INPUT_SHAPE_ROUND="M "+4*Blockly.BlockSvg.GRID_UNIT+",0 h "+4*Blockly.BlockSvg.GRID_UNIT+" a "+4*Blockly.BlockSvg.GRID_UNIT+" "+4*Blockly.BlockSvg.GRID_UNIT+" 0 0 1 0 "+8*Blockly.BlockSvg.GRID_UNIT+" h "+-4*Blockly.BlockSvg.GRID_UNIT+" a "+4*Blockly.BlockSvg.GRID_UNIT+" "+4*Blockly.BlockSvg.GRID_UNIT+" 0 0 1 0 -"+8*Blockly.BlockSvg.GRID_UNIT+" z";Blockly.BlockSvg.INPUT_SHAPE_ROUND_WIDTH=12*Blockly.BlockSvg.GRID_UNIT;Blockly.BlockSvg.INPUT_SHAPE_HEIGHT=8*Blockly.BlockSvg.GRID_UNIT;
Blockly.BlockSvg.FIELD_HEIGHT=8*Blockly.BlockSvg.GRID_UNIT;Blockly.BlockSvg.FIELD_WIDTH=6*Blockly.BlockSvg.GRID_UNIT;Blockly.BlockSvg.EDITABLE_FIELD_PADDING=6;Blockly.BlockSvg.BOX_FIELD_PADDING=2*Blockly.BlockSvg.GRID_UNIT;Blockly.BlockSvg.DROPDOWN_ARROW_PADDING=2*Blockly.BlockSvg.GRID_UNIT;Blockly.BlockSvg.FIELD_WIDTH_MIN_EDIT=8*Blockly.BlockSvg.GRID_UNIT;Blockly.BlockSvg.FIELD_WIDTH_MAX_EDIT=Infinity;Blockly.BlockSvg.FIELD_HEIGHT_MAX_EDIT=Blockly.BlockSvg.FIELD_HEIGHT;
Blockly.BlockSvg.FIELD_TOP_PADDING=.5*Blockly.BlockSvg.GRID_UNIT;Blockly.BlockSvg.NUMBER_FIELD_CORNER_RADIUS=4*Blockly.BlockSvg.GRID_UNIT;Blockly.BlockSvg.TEXT_FIELD_CORNER_RADIUS=1*Blockly.BlockSvg.GRID_UNIT;Blockly.BlockSvg.FIELD_DEFAULT_CORNER_RADIUS=4*Blockly.BlockSvg.GRID_UNIT;Blockly.BlockSvg.MAX_DISPLAY_LENGTH=Infinity;Blockly.BlockSvg.INPUT_AND_FIELD_MIN_X=12*Blockly.BlockSvg.GRID_UNIT;Blockly.BlockSvg.INLINE_PADDING_Y=1*Blockly.BlockSvg.GRID_UNIT;
Blockly.BlockSvg.FIELD_TEXTINPUT_FONTSIZE_INITIAL=12;Blockly.BlockSvg.FIELD_TEXTINPUT_FONTSIZE_FINAL=12;Blockly.BlockSvg.FIELD_TEXTINPUT_EXPAND_PAST_TRUNCATION=!1;Blockly.BlockSvg.FIELD_TEXTINPUT_ANIMATE_POSITIONING=!1;
Blockly.BlockSvg.SHAPE_IN_SHAPE_PADDING={1:{0:5*Blockly.BlockSvg.GRID_UNIT,1:2*Blockly.BlockSvg.GRID_UNIT,2:5*Blockly.BlockSvg.GRID_UNIT,3:5*Blockly.BlockSvg.GRID_UNIT},2:{0:3*Blockly.BlockSvg.GRID_UNIT,1:3*Blockly.BlockSvg.GRID_UNIT,2:1*Blockly.BlockSvg.GRID_UNIT,3:2*Blockly.BlockSvg.GRID_UNIT},3:{0:2*Blockly.BlockSvg.GRID_UNIT,1:2*Blockly.BlockSvg.GRID_UNIT,2:2*Blockly.BlockSvg.GRID_UNIT,3:2*Blockly.BlockSvg.GRID_UNIT}};Blockly.BlockSvg.DEFINE_HAT_CORNER_RADIUS=5*Blockly.BlockSvg.GRID_UNIT;
Blockly.BlockSvg.TOP_LEFT_CORNER_DEFINE_HAT="a "+Blockly.BlockSvg.DEFINE_HAT_CORNER_RADIUS+","+Blockly.BlockSvg.DEFINE_HAT_CORNER_RADIUS+" 0 0,1 "+Blockly.BlockSvg.DEFINE_HAT_CORNER_RADIUS+",-"+Blockly.BlockSvg.DEFINE_HAT_CORNER_RADIUS;Blockly.BlockSvg.TOP_RIGHT_CORNER_DEFINE_HAT="a "+Blockly.BlockSvg.DEFINE_HAT_CORNER_RADIUS+","+Blockly.BlockSvg.DEFINE_HAT_CORNER_RADIUS+" 0 0,1 "+Blockly.BlockSvg.DEFINE_HAT_CORNER_RADIUS+","+Blockly.BlockSvg.DEFINE_HAT_CORNER_RADIUS;
Blockly.BlockSvg.DEFINE_BLOCK_PADDING_RIGHT=2*Blockly.BlockSvg.GRID_UNIT;
Blockly.BlockSvg.prototype.updateColour=function(){var a=this.getColourTertiary(),b=this.isShadow()&&!Blockly.scratchBlocksUtils.isShadowArgumentReporter(this);b&&this.parentBlock_&&(a=this.parentBlock_.getColourTertiary(),this.inputList[0]&&this.inputList[0].fieldRow[0]&&(this.inputList[0].fieldRow[0]instanceof Blockly.FieldColour||this.inputList[0].fieldRow[0]instanceof Blockly.FieldColourSlider)&&(a=Blockly.Colours.colourPickerStroke));this.svgPath_.setAttribute("stroke",a);a=this.isGlowingBlock_||
b?this.getShadowColour()?this.getShadowColour():this.getColourSecondary():this.getColour();this.svgPath_.setAttribute("fill",a);this.svgPath_.setAttribute("fill-opacity",this.getOpacity());for(b=0;a=this.inputList[b];b++)a.outlinePath&&a.outlinePath.setAttribute("fill",this.getColourTertiary());a=this.getIcons();for(b=0;b<a.length;b++)a[b].updateColour();for(b=0;a=this.inputList[b];b++)for(var c=0,d;d=a.fieldRow[c];c++)d.setText(null),d.updateColour()};
Blockly.BlockSvg.prototype.highlightForReplacement=function(a){a?(this.svgPath_.setAttribute("filter","url(#"+(this.workspace.options.replacementGlowFilterId||"blocklyReplacementGlowFilter")+")"),Blockly.utils.addClass(this.svgGroup_,"blocklyReplaceable")):(this.svgPath_.removeAttribute("filter"),Blockly.utils.removeClass(this.svgGroup_,"blocklyReplaceable"))};
Blockly.BlockSvg.prototype.highlightShapeForInput=function(a,b){a=this.getInputWithConnection(a);if(!a)throw"No input found for the connection";a.outlinePath&&(b?(a.outlinePath.setAttribute("filter","url(#"+(this.workspace.options.replacementGlowFilterId||"blocklyReplacementGlowFilter")+")"),Blockly.utils.addClass(this.svgGroup_,"blocklyReplaceable")):(a.outlinePath.removeAttribute("filter"),Blockly.utils.removeClass(this.svgGroup_,"blocklyReplaceable")))};
Blockly.BlockSvg.prototype.getHeightWidth=function(){var a=this.height,b=this.width,c=this.getNextBlock();c&&(c=c.getHeightWidth(),a+=c.height,a-=Blockly.BlockSvg.NOTCH_HEIGHT,b=Math.max(b,c.width));return{height:a,width:b}};
Blockly.BlockSvg.prototype.render=function(a){Blockly.Field.startCache();this.rendered=!0;var b=Blockly.BlockSvg.SEP_SPACE_X;this.RTL&&(b=-b);for(var c=this.getIcons(),d=null,e=0;e<c.length;e++)c[e]instanceof Blockly.ScratchBlockComment?d=c[e]:b=c[e].renderIcon(b);b+=this.RTL?Blockly.BlockSvg.SEP_SPACE_X:-Blockly.BlockSvg.SEP_SPACE_X;this.isScratchExtension&&this.outputConnection&&(b+=this.RTL?-Blockly.BlockSvg.GRID_UNIT:Blockly.BlockSvg.GRID_UNIT);c=this.renderCompute_(b);this.renderDraw_(b,c);this.renderMoveConnections_();
this.renderClassify_();d&&d.renderIcon(this.RTL?-c.rightEdge:c.rightEdge,c[0].height/2);!1!==a&&((a=this.getParent())?a.render(!0):Blockly.resizeSvgContents(this.workspace));Blockly.Field.stopCache()};
Blockly.BlockSvg.prototype.renderFields_=function(a,b,c){this.RTL&&(b=-b);for(var d=0,e;e=a[d];d++){var f=e.getSvgRoot();if(f){!this.previousConnection||e instanceof Blockly.FieldLabel||e instanceof Blockly.FieldImage||(b=this.RTL?Math.min(b,-Blockly.BlockSvg.INPUT_AND_FIELD_MIN_X):Math.max(b,Blockly.BlockSvg.INPUT_AND_FIELD_MIN_X));var g=-e.getSize().height/2;this.isScratchExtension&&e===this.inputList[0].fieldRow[0]&&e instanceof Blockly.FieldImage&&this.previousConnection&&(g+=Blockly.BlockSvg.GRID_UNIT);
this.isScratchExtension&&!this.previousConnection&&this.nextConnection&&e instanceof Blockly.FieldVerticalSeparator&&e.setLineHeight(Blockly.BlockSvg.ICON_SEPARATOR_HEIGHT-Blockly.BlockSvg.GRID_UNIT);var h="";if(this.RTL){var k=b-=e.renderSep+e.renderWidth;g=c+g;e.renderWidth&&(b-=Blockly.BlockSvg.SEP_SPACE_X)}else k=b+e.renderSep,g=c+g,e.renderWidth&&(b+=e.renderSep+e.renderWidth+Blockly.BlockSvg.SEP_SPACE_X);this.RTL&&e instanceof Blockly.FieldImage&&e.getFlipRTL()&&(h="scale(-1 1)",k+=e.renderWidth);
f.setAttribute("transform","translate("+k+", "+g+") "+h);this.isInsertionMarker()&&f.setAttribute("display","none")}}return this.RTL?-b:b};
Blockly.BlockSvg.prototype.renderCompute_=function(a){var b=this.inputList,c=[];c.rightEdge=0;for(var d=c.bottomEdge=0,e=!1,f=!1,g=!1,h=void 0,k,l=0,m;m=b[l];l++)if(m.isVisible()){var n="procedures_definition"==this.type&&h&&h==Blockly.NEXT_STATEMENT;if(n||h&&h!=Blockly.NEXT_STATEMENT&&m.type!=Blockly.NEXT_STATEMENT)var p=c[c.length-1];else h=m.type,p=this.createRowForInput_(m),c.push(p);p.push(m);m.renderHeight=this.computeInputHeight_(m,p,k);m.renderWidth=this.computeInputWidth_(m);p.statementNotchAtBottom=
!0;m.connection&&m.connection.type===Blockly.NEXT_STATEMENT&&(k=m.connection.targetBlock())&&!k.lastConnectionInStack()&&(p.statementNotchAtBottom=!1);if(m.connection){k=m.connection.targetBlock();var q=0;k?(q=k.getHeightWidth(),k=q.height,q=q.width):k=Blockly.BlockSvg.INPUT_SHAPE_HEIGHT;m.connection.type===Blockly.INPUT_VALUE&&(k+=2*Blockly.BlockSvg.INLINE_PADDING_Y);m.connection.type===Blockly.NEXT_STATEMENT&&p.statementNotchAtBottom&&(k-=Blockly.BlockSvg.NOTCH_HEIGHT);m.renderHeight=Math.max(m.renderHeight,
k);m.renderWidth=Math.max(m.renderWidth,q)}p.height=Math.max(p.height,m.renderHeight);m.fieldWidth=0;1==c.length&&(m.fieldWidth+=this.RTL?-a:a);k=!1;q=0;for(var r;r=m.fieldRow[q];q++){0!=q&&(m.fieldWidth+=Blockly.BlockSvg.SEP_SPACE_X);var t=r.getSize();r.renderWidth=t.width;r.renderSep=k&&r.EDITABLE?Blockly.BlockSvg.SEP_SPACE_X:0;n||(m.fieldWidth+=r.renderWidth+r.renderSep);p.height=Math.max(p.height,t.height);k=r.EDITABLE}p.type!=Blockly.BlockSvg.INLINE&&(p.type==Blockly.NEXT_STATEMENT?(f=!0,d=Math.max(d,
m.fieldWidth)):p.type==Blockly.INPUT_VALUE?e=!0:p.type==Blockly.DUMMY_INPUT&&(g=!0));k=p}this.computeOutputPadding_(c);c.statementEdge=Blockly.BlockSvg.STATEMENT_INPUT_EDGE_WIDTH+d;c.rightEdge=this.computeRightEdge_(c.rightEdge,f);for(l=0;l<c.length;l++)c.bottomEdge+=c[l].height;c.hasValue=e;c.hasStatement=f;c.hasDummy=g;return c};
Blockly.BlockSvg.prototype.computeInputWidth_=function(a){if(a.type!=Blockly.INPUT_VALUE||a.connection&&a.connection.isConnected())return 0;switch(a.connection.getOutputShape()){case Blockly.OUTPUT_SHAPE_SQUARE:return Blockly.BlockSvg.INPUT_SHAPE_SQUARE_WIDTH;case Blockly.OUTPUT_SHAPE_ROUND:return Blockly.BlockSvg.INPUT_SHAPE_ROUND_WIDTH;case Blockly.OUTPUT_SHAPE_HEXAGONAL:return Blockly.BlockSvg.INPUT_SHAPE_HEXAGONAL_WIDTH;default:return 0}};
Blockly.BlockSvg.prototype.computeInputHeight_=function(a,b,c){return 1===this.inputList.length&&this.outputConnection&&this.isShadow()&&!Blockly.scratchBlocksUtils.isShadowArgumentReporter(this)?Blockly.BlockSvg.MIN_BLOCK_Y_SINGLE_FIELD_OUTPUT:this.outputConnection?this.isScratchExtension?Blockly.BlockSvg.MIN_BLOCK_Y_REPORTER+2*Blockly.BlockSvg.GRID_UNIT:Blockly.BlockSvg.MIN_BLOCK_Y_REPORTER:b.type==Blockly.NEXT_STATEMENT?Blockly.BlockSvg.MIN_STATEMENT_INPUT_HEIGHT:c&&c.type==Blockly.NEXT_STATEMENT?
Blockly.BlockSvg.EXTRA_STATEMENT_ROW_Y:this.isScratchExtension&&this.previousConnection?Blockly.BlockSvg.MIN_BLOCK_Y+2*Blockly.BlockSvg.GRID_UNIT:Blockly.BlockSvg.MIN_BLOCK_Y};Blockly.BlockSvg.prototype.createRowForInput_=function(a){var b=[];b.type=a.type!=Blockly.NEXT_STATEMENT?Blockly.BlockSvg.INLINE:a.type;b.height=0;b.paddingStart=Blockly.BlockSvg.SEP_SPACE_X;b.paddingEnd=Blockly.BlockSvg.SEP_SPACE_X;return b};
Blockly.BlockSvg.prototype.computeRightEdge_=function(a,b){this.previousConnection||this.nextConnection?a=Math.max(a,Blockly.BlockSvg.MIN_BLOCK_X):this.outputConnection&&(a=this.isShadow()&&!Blockly.scratchBlocksUtils.isShadowArgumentReporter(this)?Math.max(a,Blockly.BlockSvg.MIN_BLOCK_X_SHADOW_OUTPUT):Math.max(a,Blockly.BlockSvg.MIN_BLOCK_X_OUTPUT));b&&(a=Math.max(a,Blockly.BlockSvg.MIN_BLOCK_X_WITH_STATEMENT));0<this.insertionMarkerMinWidth_&&(a=Math.max(a,this.insertionMarkerMinWidth_));return a};
Blockly.BlockSvg.prototype.computeOutputPadding_=function(a){if(this.getOutputShape()&&this.outputConnection&&(!this.isShadow()||Blockly.scratchBlocksUtils.isShadowArgumentReporter(this))&&!(1<a.length)){a=a[0];var b=this.getOutputShape();a.paddingStart=0;a.paddingEnd=0;var c=a[0];if(c.fieldRow[0])var d=0;else d=c.connection,d=d.targetConnection?d.targetConnection.getSourceBlock().getOutputShape():d.getOutputShape(),b==Blockly.OUTPUT_SHAPE_HEXAGONAL&&d!=Blockly.OUTPUT_SHAPE_HEXAGONAL&&(c=c.renderHeight-
Blockly.BlockSvg.MIN_BLOCK_Y_REPORTER,a.paddingStart+=c/2);a.paddingStart+=Blockly.BlockSvg.SHAPE_IN_SHAPE_PADDING[b][d];c=a[a.length-1];c.connection?(d=c.connection,d=d.targetConnection?d.targetConnection.getSourceBlock().getOutputShape():d.getOutputShape(),b==Blockly.OUTPUT_SHAPE_HEXAGONAL&&d!=Blockly.OUTPUT_SHAPE_HEXAGONAL&&(c=c.renderHeight-Blockly.BlockSvg.MIN_BLOCK_Y_REPORTER,a.paddingEnd+=c/2)):d=0;a.paddingEnd+=Blockly.BlockSvg.SHAPE_IN_SHAPE_PADDING[b][d]}};
Blockly.BlockSvg.prototype.renderDraw_=function(a,b){this.squareTopLeftCorner_=this.startHat_=!1;this.outputConnection||this.previousConnection||(this.startHat_=this.squareTopLeftCorner_=!0,b.rightEdge=Math.max(b.rightEdge,100));this.edgeShapeWidth_=0;this.edgeShape_=null;if(this.outputConnection){var c=this.getOutputShape();if(c===Blockly.OUTPUT_SHAPE_HEXAGONAL||c===Blockly.OUTPUT_SHAPE_ROUND)this.edgeShapeWidth_=b.bottomEdge/2,this.edgeShape_=c,this.squareTopLeftCorner_=!0}c=[];this.renderDrawTop_(c,
b.rightEdge);a=this.renderDrawRight_(c,b,a);this.renderDrawBottom_(c,a);this.renderDrawLeft_(c);a=c.join(" ");this.svgPath_.setAttribute("d",a);this.RTL&&this.svgPath_.setAttribute("transform","scale(-1 1)")};
Blockly.BlockSvg.prototype.renderClassify_=function(){var a=[];if(this.outputConnection)this.isShadow_?a.push("argument"):a.push("reporter"),this.edgeShape_===Blockly.OUTPUT_SHAPE_HEXAGONAL?a.push("boolean"):this.edgeShape_===Blockly.OUTPUT_SHAPE_ROUND&&a.push("round");else{for(var b=this.inputList,c=0,d=0,e;e=b[d];d++)e.connection&&e.connection.type===Blockly.NEXT_STATEMENT&&c++;c&&(a.push("c-block"),a.push("c-"+c));this.startHat_?a.push("hat"):c||a.push("stack");this.nextConnection||a.push("end")}this.svgGroup_.setAttribute("data-shapes",
a.join(" "));this.getCategory()&&this.svgGroup_.setAttribute("data-category",this.getCategory())};
Blockly.BlockSvg.prototype.renderDrawTop_=function(a,b){this.type==Blockly.PROCEDURES_DEFINITION_BLOCK_TYPE?(a.push("m 0, 0"),a.push(Blockly.BlockSvg.TOP_LEFT_CORNER_DEFINE_HAT)):(this.squareTopLeftCorner_?(a.push("m 0,0"),this.startHat_&&a.push(Blockly.BlockSvg.START_HAT_PATH),this.edgeShapeWidth_&&a.push("m "+this.edgeShapeWidth_+",0")):(a.push(Blockly.BlockSvg.TOP_LEFT_CORNER_START),a.push(Blockly.BlockSvg.TOP_LEFT_CORNER)),this.previousConnection&&(a.push("H",Blockly.BlockSvg.NOTCH_START_PADDING),
a.push(Blockly.BlockSvg.NOTCH_PATH_LEFT),this.previousConnection.setOffsetInBlock(this.RTL?-Blockly.BlockSvg.NOTCH_WIDTH:Blockly.BlockSvg.NOTCH_WIDTH,0)));this.width=b};
Blockly.BlockSvg.prototype.renderDrawRight_=function(a,b,c){for(var d,e=0,f,g,h=0,k;k=b[h];h++){d=k.paddingStart;0==h&&(d+=this.RTL?-c:c);if(k.type==Blockly.BlockSvg.INLINE){for(var l=0,m;m=k[l];l++)if(f=e+k.height/2,d=Blockly.BlockSvg.getAlignedCursor_(d,m,b.rightEdge),d=this.renderFields_(m.fieldRow,d,f),m.type==Blockly.INPUT_VALUE){this.previousConnection&&(d=Math.max(d,Blockly.BlockSvg.INPUT_AND_FIELD_MIN_X));f=this.RTL?-d:d;var n=k.height/2;g=e+n;m.connection.setOffsetInBlock(f,g);this.renderInputShape_(m,
d,e+n);d+=m.renderWidth+Blockly.BlockSvg.SEP_SPACE_X}d-=Blockly.BlockSvg.SEP_SPACE_X;d+=k.paddingEnd;b.rightEdge=Math.max(d,b.rightEdge);d=Math.max(d,b.rightEdge);this.width=Math.max(this.width,d);this.edgeShape_?a.push("H",d-this.edgeShapeWidth_):(a.push("H",d-Blockly.BlockSvg.CORNER_RADIUS-this.edgeShapeWidth_),a.push(Blockly.BlockSvg.TOP_RIGHT_CORNER));this.edgeShape_||a.push("v",k.height-2*Blockly.BlockSvg.CORNER_RADIUS)}else k.type==Blockly.NEXT_STATEMENT&&(m=k[0],f=e,f+=Blockly.BlockSvg.MIN_STATEMENT_INPUT_HEIGHT,
this.renderFields_(m.fieldRow,d,f),d=b.statementEdge+Blockly.BlockSvg.NOTCH_WIDTH,this.type==Blockly.PROCEDURES_DEFINITION_BLOCK_TYPE?this.renderDefineBlock_(a,b,m,k,e):Blockly.BlockSvg.drawStatementInputFromTopRight_(a,d,b.rightEdge,k),f=this.RTL?-d:d,m.connection.setOffsetInBlock(f,e),m.connection.isConnected()&&(this.width=Math.max(this.width,b.statementEdge+m.connection.targetBlock().getHeightWidth().width)),this.type==Blockly.PROCEDURES_DEFINITION_BLOCK_TYPE||h!=b.length-1&&b[h+1].type!=Blockly.NEXT_STATEMENT||
(a.push(Blockly.BlockSvg.TOP_RIGHT_CORNER),a.push("v",Blockly.BlockSvg.EXTRA_STATEMENT_ROW_Y-2*Blockly.BlockSvg.CORNER_RADIUS),e+=Blockly.BlockSvg.EXTRA_STATEMENT_ROW_Y));e+=k.height}this.drawEdgeShapeRight_(a);b.length||(e=Blockly.BlockSvg.MIN_BLOCK_Y,a.push("V",e));return e};
Blockly.BlockSvg.prototype.renderInputShape_=function(a,b,c){var d=a.outlinePath;d&&(a.connection.targetConnection?d.setAttribute("style","visibility: hidden"):(a=Blockly.BlockSvg.getInputShapeInfo_(a.connection.getOutputShape()),b=this.RTL?-b-a.width:b,c-=Blockly.BlockSvg.INPUT_SHAPE_HEIGHT/2,d.setAttribute("d",a.path),d.setAttribute("transform","translate("+b+","+c+")"),d.setAttribute("data-argument-type",a.argType),d.setAttribute("style","visibility: visible")))};
Blockly.BlockSvg.prototype.renderDrawBottom_=function(a,b){this.height=b;this.edgeShape_||a.push(Blockly.BlockSvg.BOTTOM_RIGHT_CORNER);this.nextConnection&&(a.push("H",Blockly.BlockSvg.NOTCH_WIDTH+Blockly.BlockSvg.NOTCH_START_PADDING+Blockly.BlockSvg.CORNER_RADIUS," "),a.push(Blockly.BlockSvg.NOTCH_PATH_RIGHT),this.nextConnection.setOffsetInBlock(this.RTL?-Blockly.BlockSvg.NOTCH_WIDTH:Blockly.BlockSvg.NOTCH_WIDTH,b),this.height+=Blockly.BlockSvg.NOTCH_HEIGHT);this.edgeShape_?a.push("H",this.edgeShapeWidth_):
(a.push("H",Blockly.BlockSvg.CORNER_RADIUS),a.push(Blockly.BlockSvg.BOTTOM_LEFT_CORNER))};
Blockly.BlockSvg.prototype.renderDrawLeft_=function(a){this.outputConnection&&this.outputConnection.setOffsetInBlock(0,this.height/2);this.edgeShape_&&(this.edgeShape_===Blockly.OUTPUT_SHAPE_ROUND?a.push("a "+this.edgeShapeWidth_+" "+this.edgeShapeWidth_+" 0 0 1 0 -"+2*this.edgeShapeWidth_):this.edgeShape_===Blockly.OUTPUT_SHAPE_HEXAGONAL&&a.push("l "+-this.edgeShapeWidth_+" "+-this.edgeShapeWidth_+" l "+this.edgeShapeWidth_+" "+-this.edgeShapeWidth_));a.push("z")};
Blockly.BlockSvg.prototype.drawEdgeShapeRight_=function(a){this.edgeShape_&&(this.edgeShape_===Blockly.OUTPUT_SHAPE_ROUND?a.push("a "+this.edgeShapeWidth_+" "+this.edgeShapeWidth_+" 0 0 1 0 "+2*this.edgeShapeWidth_):this.edgeShape_===Blockly.OUTPUT_SHAPE_HEXAGONAL&&a.push("l "+this.edgeShapeWidth_+" "+this.edgeShapeWidth_+" l "+-this.edgeShapeWidth_+" "+this.edgeShapeWidth_))};Blockly.BlockSvg.prototype.positionNewBlock=function(a,b,c){b.type==Blockly.NEXT_STATEMENT&&a.moveBy(c.x_-b.x_,c.y_-b.y_)};
Blockly.BlockSvg.drawStatementInputFromTopRight_=function(a,b,c,d){Blockly.BlockSvg.drawStatementInputTop_(a,b);a.push("v",d.height-2*Blockly.BlockSvg.CORNER_RADIUS);Blockly.BlockSvg.drawStatementInputBottom_(a,c,d)};
Blockly.BlockSvg.drawStatementInputTop_=function(a,b){a.push(Blockly.BlockSvg.BOTTOM_RIGHT_CORNER);a.push("H",b+Blockly.BlockSvg.STATEMENT_INPUT_INNER_SPACE+2*Blockly.BlockSvg.CORNER_RADIUS);a.push(Blockly.BlockSvg.NOTCH_PATH_RIGHT);a.push("h","-"+Blockly.BlockSvg.STATEMENT_INPUT_INNER_SPACE);a.push(Blockly.BlockSvg.INNER_TOP_LEFT_CORNER)};
Blockly.BlockSvg.drawStatementInputBottom_=function(a,b,c){a.push(Blockly.BlockSvg.INNER_BOTTOM_LEFT_CORNER);c.statementNotchAtBottom&&(a.push("h ",Blockly.BlockSvg.STATEMENT_INPUT_INNER_SPACE),a.push(Blockly.BlockSvg.NOTCH_PATH_LEFT));a.push("H",b-Blockly.BlockSvg.CORNER_RADIUS)};
Blockly.BlockSvg.prototype.renderDefineBlock_=function(a,b,c,d,e){var f=2==d.length,g=b.rightEdge;g=c.connection&&c.connection.targetBlock()?b.statementEdge+c.connection.targetBlock().getHeightWidth().width+Blockly.BlockSvg.DEFINE_BLOCK_PADDING_RIGHT:Math.max(Blockly.BlockSvg.MIN_BLOCK_X_WITH_STATEMENT,g)+Blockly.BlockSvg.DEFINE_BLOCK_PADDING_RIGHT;g-=Blockly.BlockSvg.DEFINE_HAT_CORNER_RADIUS;f&&(b=d[1],c=g+3*Blockly.BlockSvg.SEP_SPACE_X,g+=b.fieldRow[0].getSize().width,g+=2*Blockly.BlockSvg.SEP_SPACE_X,
this.renderFields_(b.fieldRow,c,e+Blockly.BlockSvg.MIN_STATEMENT_INPUT_HEIGHT));a.push("H",g);a.push(Blockly.BlockSvg.TOP_RIGHT_CORNER_DEFINE_HAT);d.height+=3*Blockly.BlockSvg.GRID_UNIT;a.push("v",d.height);d.height+=Blockly.BlockSvg.GRID_UNIT};
Blockly.BlockSvg.getInputShapeInfo_=function(a){switch(a){case Blockly.OUTPUT_SHAPE_HEXAGONAL:a=Blockly.BlockSvg.INPUT_SHAPE_HEXAGONAL;var b=Blockly.BlockSvg.INPUT_SHAPE_HEXAGONAL_WIDTH;var c="boolean";break;case Blockly.OUTPUT_SHAPE_ROUND:a=Blockly.BlockSvg.INPUT_SHAPE_ROUND;b=Blockly.BlockSvg.INPUT_SHAPE_ROUND_WIDTH;c="round";break;default:a=Blockly.BlockSvg.INPUT_SHAPE_SQUARE,b=Blockly.BlockSvg.INPUT_SHAPE_SQUARE_WIDTH,c="square"}return{path:a,argType:c,width:b}};
Blockly.BlockSvg.getAlignedCursor_=function(a,b,c){b.align===Blockly.ALIGN_RIGHT?a+=c-b.fieldWidth-2*Blockly.BlockSvg.SEP_SPACE_X:b.align===Blockly.ALIGN_CENTRE&&(a=Math.max(a,c/2-b.fieldWidth/2));return a};
Blockly.BlockSvg.prototype.renderMoveConnections_=function(){var a=this.getRelativeToSurfaceXY();this.previousConnection&&this.previousConnection.moveToOffset(a);this.outputConnection&&this.outputConnection.moveToOffset(a);for(var b=0;b<this.inputList.length;b++){var c=this.inputList[b].connection;c&&(c.moveToOffset(a),c.isConnected()&&c.tighten_())}this.nextConnection&&(this.nextConnection.moveToOffset(a),this.nextConnection.isConnected()&&this.nextConnection.tighten_())};Blockly.Device={};Blockly.Device.deviceId=null;Blockly.Device.deviceType=null;Blockly.Device.setDevice=function(a,b){Blockly.Device.deviceId=a;Blockly.Device.deviceType=b};Blockly.Device.getDeviceId=function(){return Blockly.Device.deviceId};Blockly.Device.getDeviceType=function(){return Blockly.Device.deviceType};Blockly.Device.getPinOptions=function(a){return Blockly.getMainWorkspace().getFlyout().getFlyoutItems().find(function(b){return b.type===a}).getField("PIN").getOptions()};Blockly.DropDownDivMatrix=function(){};Blockly.DropDownDivMatrix.DIV_=null;Blockly.DropDownDivMatrix.boundsElement_=null;Blockly.DropDownDivMatrix.owner_=null;Blockly.DropDownDivMatrix.ARROW_SIZE=16;Blockly.DropDownDivMatrix.BORDER_SIZE=1;Blockly.DropDownDivMatrix.ARROW_HORIZONTAL_PADDING=12;Blockly.DropDownDivMatrix.PADDING_Y=20;Blockly.DropDownDivMatrix.ANIMATION_TIME=.25;Blockly.DropDownDivMatrix.animateOutTimer_=null;Blockly.DropDownDivMatrix.onHide_=0;
Blockly.DropDownDivMatrix.createDom=function(){Blockly.DropDownDivMatrix.DIV_||(Blockly.DropDownDivMatrix.DIV_=goog.dom.createDom("div","blocklyDropDownDiv"),document.body.appendChild(Blockly.DropDownDivMatrix.DIV_),Blockly.DropDownDivMatrix.content_=goog.dom.createDom("div","blocklyDropDownContentMatrix"),Blockly.DropDownDivMatrix.DIV_.appendChild(Blockly.DropDownDivMatrix.content_),Blockly.DropDownDivMatrix.arrow_=goog.dom.createDom("div","blocklyDropDownArrow"),Blockly.DropDownDivMatrix.DIV_.appendChild(Blockly.DropDownDivMatrix.arrow_),
Blockly.DropDownDivMatrix.DIV_.style.transition="transform "+Blockly.DropDownDivMatrix.ANIMATION_TIME+"s, opacity "+Blockly.DropDownDivMatrix.ANIMATION_TIME+"s")};Blockly.DropDownDivMatrix.setBoundsElement=function(a){Blockly.DropDownDivMatrix.boundsElement_=a};Blockly.DropDownDivMatrix.getContentDiv=function(){return Blockly.DropDownDivMatrix.content_};Blockly.DropDownDivMatrix.clearContent=function(){Blockly.DropDownDiv.content_.innerHTML="";Blockly.DropDownDiv.content_.style.width=""};
Blockly.DropDownDivMatrix.setColour=function(a,b){Blockly.DropDownDivMatrix.DIV_.style.backgroundColor=a;Blockly.DropDownDivMatrix.DIV_.style.borderColor=b};Blockly.DropDownDivMatrix.setCategory=function(a){Blockly.DropDownDivMatrix.DIV_.setAttribute("data-category",a)};
Blockly.DropDownDivMatrix.showPositionedByBlock=function(a,b,c,d){var e=b.workspace.scale,f=b.width;a=b.height;f*=e;a*=e;e=b.getSvgRoot().getBoundingClientRect();f=e.left+f/2;a=e.top+a;e=e.top;d&&(e+=d);Blockly.DropDownDivMatrix.setBoundsElement(b.workspace.getParentSvg().parentNode);return Blockly.DropDownDivMatrix.show(this,f,a,f,e,c)};
Blockly.DropDownDivMatrix.show=function(a,b,c,d,e,f){Blockly.DropDownDivMatrix.owner_=a;Blockly.DropDownDivMatrix.onHide_=f;f=Blockly.DropDownDivMatrix.DIV_;b=Blockly.DropDownDivMatrix.getPositionMetrics(b,c,d,e);Blockly.DropDownDivMatrix.arrow_.style.transform="translate("+b.arrowX+"px,"+b.arrowY+"px) rotate(45deg)";Blockly.DropDownDivMatrix.arrow_.setAttribute("class",b.arrowAtTop?"blocklyDropDownArrow arrowTop":"blocklyDropDownArrow arrowBottom");f.style.direction=a.sourceBlock_&&a.sourceBlock_.RTL?
"rtl":"ltr";f.style.left=b.initialX+"px";f.style.top=b.initialY+"px";f.style.display="block";f.style.opacity=1;f.style.transform="translate("+(b.finalX-b.initialX)+"px,"+(b.finalY-b.initialY)+"px)";return b.arrowAtTop};
Blockly.DropDownDivMatrix.getPositionMetrics=function(a,b,c,d){var e=Blockly.DropDownDivMatrix.DIV_,f=Blockly.DropDownDivMatrix.boundsElement_.getBoundingClientRect(),g=goog.style.getSize(Blockly.DropDownDivMatrix.boundsElement_);e=goog.style.getSize(e);if(b+e.height>f.top+g.height)if(d-e.height<f.top){c=b+Blockly.DropDownDivMatrix.PADDING_Y;var h=!1}else a=c,c=d-e.height-Blockly.DropDownDivMatrix.PADDING_Y,h=!0;else c=b+Blockly.DropDownDivMatrix.PADDING_Y,h=!1;var k=a-Blockly.DropDownDivMatrix.ARROW_SIZE/
2;k=Math.max(f.left,Math.min(k,f.left+g.width));a=Math.max(f.left,Math.min(a-e.width/2,f.left+g.width-e.width));k=Math.max(Blockly.DropDownDivMatrix.ARROW_HORIZONTAL_PADDING,Math.min(k-a,e.width-Blockly.DropDownDivMatrix.ARROW_HORIZONTAL_PADDING-Blockly.DropDownDivMatrix.ARROW_SIZE));f=h?e.height-Blockly.DropDownDivMatrix.BORDER_SIZE:0;f-=Blockly.DropDownDivMatrix.ARROW_SIZE/2+Blockly.DropDownDivMatrix.BORDER_SIZE;return{initialX:a,initialY:h?d-e.height:b,finalX:a,finalY:c,arrowX:k,arrowY:f,arrowAtTop:!h}};
Blockly.DropDownDivMatrix.isVisible=function(){return!!Blockly.DropDownDivMatrix.owner_};Blockly.DropDownDivMatrix.hideIfOwner=function(a){return Blockly.DropDownDivMatrix.owner_===a?(Blockly.DropDownDivMatrix.hide(),!0):!1};
Blockly.DropDownDivMatrix.hide=function(){var a=Blockly.DropDownDivMatrix.DIV_;a.style.transform="translate(0px, 0px)";a.style.opacity=0;Blockly.DropDownDivMatrix.animateOutTimer_=setTimeout(function(){Blockly.DropDownDivMatrix.hideWithoutAnimation()},1E3*Blockly.DropDownDivMatrix.ANIMATION_TIME);Blockly.DropDownDivMatrix.onHide_&&(Blockly.DropDownDivMatrix.onHide_(),Blockly.DropDownDivMatrix.onHide_=null)};
Blockly.DropDownDivMatrix.hideWithoutAnimation=function(){if(Blockly.DropDownDivMatrix.isVisible()){var a=Blockly.DropDownDivMatrix.DIV_;Blockly.DropDownDivMatrix.animateOutTimer_&&window.clearTimeout(Blockly.DropDownDivMatrix.animateOutTimer_);a.style.transform="";a.style.top="";a.style.left="";a.style.display="none";Blockly.DropDownDivMatrix.clearContent();Blockly.DropDownDivMatrix.owner_=null;Blockly.DropDownDivMatrix.onHide_&&(Blockly.DropDownDivMatrix.onHide_(),Blockly.DropDownDivMatrix.onHide_=
null)}};Blockly.Msg={};goog.getMsgOrig=goog.getMsg;goog.getMsg=function(a,b){var c=goog.getMsg.blocklyMsgMap[a];c&&(a=Blockly.Msg[c]);return goog.getMsgOrig(a,b)};goog.getMsg.blocklyMsgMap={Today:"TODAY"};Blockly.FieldTextInput=function(a,b,c){Blockly.FieldTextInput.superClass_.constructor.call(this,a,b);this.setRestrictor(c);this.addArgType("text")};goog.inherits(Blockly.FieldTextInput,Blockly.Field);Blockly.FieldTextInput.fromJson=function(a){var b=Blockly.utils.replaceMessageReferences(a.text);b=new Blockly.FieldTextInput(b,a["class"]);"boolean"===typeof a.spellcheck&&b.setSpellcheck(a.spellcheck);return b};Blockly.FieldTextInput.ANIMATION_TIME=.25;
Blockly.FieldTextInput.TEXT_MEASURE_PADDING_MAGIC=45;Blockly.FieldTextInput.htmlInput_=null;Blockly.FieldTextInput.ERROR_COLOR="#FFAAAA";Blockly.FieldTextInput.DEFAULT_COLOR="#FFFFFF";Blockly.FieldTextInput.prototype.CURSOR="text";Blockly.FieldTextInput.prototype.spellcheck_=!0;
Blockly.FieldTextInput.prototype.init=function(){if(!this.fieldGroup_){var a=!this.sourceBlock_.isShadow();a&&(this.className_+=" blocklyEditableLabel");Blockly.FieldTextInput.superClass_.init.call(this);a&&(this.box_=Blockly.utils.createSvgElement("rect",{x:0,y:0,width:this.size_.width,height:this.size_.height,fill:this.sourceBlock_.getColourTertiary()}),this.fieldGroup_.insertBefore(this.box_,this.textElement_))}};
Blockly.FieldTextInput.prototype.dispose=function(){Blockly.WidgetDiv.hideIfOwner(this);Blockly.FieldTextInput.superClass_.dispose.call(this)};Blockly.FieldTextInput.prototype.setValue=function(a){if(null!==a){if(this.sourceBlock_){var b=this.callValidator(a);null!==b&&(a=b)}Blockly.Field.prototype.setValue.call(this,a)}};
Blockly.FieldTextInput.prototype.setText=function(a){null!==a&&(a=String(a),a!==this.text_&&(this.sourceBlock_&&Blockly.Events.isEnabled()&&Blockly.Events.fire(new Blockly.Events.BlockChange(this.sourceBlock_,"field",this.name,this.text_,a)),Blockly.Field.prototype.setText.call(this,a)))};Blockly.FieldTextInput.prototype.setSpellcheck=function(a){this.spellcheck_=a};Blockly.FieldTextInput.prototype.setRestrictor=function(a){this.restrictor_=a};
Blockly.FieldTextInput.prototype.showEditor_=function(a,b,c,d){this.workspace_=this.sourceBlock_.workspace;a=a||!1;var e=b||!1;Blockly.WidgetDiv.show(this,this.sourceBlock_.RTL,this.widgetDispose_(),this.widgetDisposeAnimationFinished_(),Blockly.FieldTextInput.ANIMATION_TIME);b=Blockly.WidgetDiv.DIV;b.className+=" fieldTextInput";var f=goog.dom.createDom("INPUT","blocklyHtmlInput");f.setAttribute("spellcheck",this.spellcheck_);e&&f.setAttribute("readonly","true");Blockly.FieldTextInput.htmlInput_=
f;b.appendChild(f);c&&(this.sourceBlock_.RTL?f.style.paddingLeft=this.arrowSize_+Blockly.BlockSvg.DROPDOWN_ARROW_PADDING+"px":f.style.paddingRight=this.arrowSize_+Blockly.BlockSvg.DROPDOWN_ARROW_PADDING+"px",c=goog.dom.createDom("IMG","blocklyTextDropDownArrow"),c.setAttribute("src",Blockly.mainWorkspace.options.pathToMedia+"dropdown-arrow-dark.svg"),c.style.width=this.arrowSize_+"px",c.style.height=this.arrowSize_+"px",c.style.top=this.arrowY_+"px",c.style.cursor="pointer",this.sourceBlock_.RTL?
c.style.left="11px":c.style.right="11px",d&&(f.dropDownArrowMouseWrapper_=Blockly.bindEvent_(c,"mousedown",this,d)),b.appendChild(c));f.value=f.defaultValue=this.text_;f.oldValue_=null;this.validate_();this.resizeEditor_();a||(f.focus(),f.select(),f.setSelectionRange(0,99999));this.bindEvents_(f,a||e);d="box-shadow "+Blockly.FieldTextInput.ANIMATION_TIME+"s";Blockly.BlockSvg.FIELD_TEXTINPUT_ANIMATE_POSITIONING&&(b.style.transition+=",padding "+Blockly.FieldTextInput.ANIMATION_TIME+"s,width "+Blockly.FieldTextInput.ANIMATION_TIME+
"s,height "+Blockly.FieldTextInput.ANIMATION_TIME+"s,margin-left "+Blockly.FieldTextInput.ANIMATION_TIME+"s");b.style.transition=d;f.style.transition="font-size "+Blockly.FieldTextInput.ANIMATION_TIME+"s";f.style.fontSize=Blockly.BlockSvg.FIELD_TEXTINPUT_FONTSIZE_FINAL+"pt";b.style.boxShadow="0px 0px 0px 4px "+Blockly.Colours.fieldShadow};
Blockly.FieldTextInput.prototype.bindEvents_=function(a,b){a.onKeyDownWrapper_=Blockly.bindEventWithChecks_(a,"keydown",this,this.onHtmlInputKeyDown_);a.onKeyUpWrapper_=Blockly.bindEventWithChecks_(a,"keyup",this,this.onHtmlInputChange_);a.onKeyPressWrapper_=Blockly.bindEventWithChecks_(a,"keypress",this,this.onHtmlInputChange_);a.onInputWrapper_=Blockly.bindEvent_(a,"input",this,this.onHtmlInputChange_);a.onWorkspaceChangeWrapper_=this.resizeEditor_.bind(this);this.workspace_.addChangeListener(a.onWorkspaceChangeWrapper_);
b&&(a.onDocumentKeyDownWrapper_=Blockly.bindEventWithChecks_(document,"keydown",this,this.onDocumentKeyDown_))};Blockly.FieldTextInput.prototype.unbindEvents_=function(a){Blockly.unbindEvent_(a.onKeyDownWrapper_);Blockly.unbindEvent_(a.onKeyUpWrapper_);Blockly.unbindEvent_(a.onKeyPressWrapper_);Blockly.unbindEvent_(a.onInputWrapper_);this.workspace_.removeChangeListener(a.onWorkspaceChangeWrapper_);a.onDocumentKeyDownWrapper_&&Blockly.unbindEvent_(a.onDocumentKeyDownWrapper_)};
Blockly.FieldTextInput.prototype.onHtmlInputKeyDown_=function(a){var b=Blockly.FieldTextInput.htmlInput_;13==a.keyCode?(Blockly.WidgetDiv.hide(),Blockly.DropDownDiv.hideWithoutAnimation()):27==a.keyCode?(b.value=b.defaultValue,Blockly.WidgetDiv.hide(),Blockly.DropDownDiv.hideWithoutAnimation()):9==a.keyCode&&(Blockly.WidgetDiv.hide(),Blockly.DropDownDiv.hideWithoutAnimation(),this.sourceBlock_.tab(this,!a.shiftKey),a.preventDefault())};
Blockly.FieldTextInput.prototype.onDocumentKeyDown_=function(a){var b=Blockly.FieldTextInput.htmlInput_,c="INPUT"===a.target.tagName||"TEXTAREA"===a.target.tagName;a.target!==b&&c||(b.removeAttribute("readonly"),b.value="",b.focus(),Blockly.unbindEvent_(b.onDocumentKeyDownWrapper_),b.onDocumentKeyDownWrapper_=null)};Blockly.FieldTextInput.GECKO_KEYCODE_WHITELIST=[97,99,118,120];
Blockly.FieldTextInput.prototype.onHtmlInputChange_=function(a){if("keypress"===a.type&&this.restrictor_){var b=!1;if(goog.userAgent.GECKO){var c=a.charCode;if(32>c||127==c)b=!0;else if(a.metaKey||a.ctrlKey)b=-1<Blockly.FieldTextInput.GECKO_KEYCODE_WHITELIST.indexOf(c)}else c=a.keyCode;c=String.fromCharCode(c);if(!b&&!this.restrictor_.test(c)&&a.preventDefault){a.preventDefault();return}}a=Blockly.FieldTextInput.htmlInput_;b=a.value;b!==a.oldValue_?(a.oldValue_=b,this.setText(b),this.validate_()):
goog.userAgent.WEBKIT&&this.sourceBlock_.render();this.resizeEditor_()};
Blockly.FieldTextInput.prototype.validate_=function(){var a=!0;goog.asserts.assertObject(Blockly.FieldTextInput.htmlInput_);var b=Blockly.FieldTextInput.htmlInput_;this.sourceBlock_&&(a=this.callValidator(b.value),Blockly.FieldTextInput.htmlInput_.style.backgroundColor=a!==b.value?Blockly.FieldTextInput.ERROR_COLOR:Blockly.FieldTextInput.DEFAULT_COLOR);null===a?Blockly.utils.addClass(b,"blocklyInvalidInput"):Blockly.utils.removeClass(b,"blocklyInvalidInput")};
Blockly.FieldTextInput.prototype.resizeEditor_=function(){var a=this.sourceBlock_.workspace.scale,b=Blockly.WidgetDiv.DIV;var c=this.sourceBlock_.isShadow()?this.sourceBlock_.getHeightWidth().width*a:this.size_.width*a;if(Blockly.BlockSvg.FIELD_TEXTINPUT_EXPAND_PAST_TRUNCATION){var d=Blockly.scratchBlocksUtils.measureText(Blockly.FieldTextInput.htmlInput_.style.fontSize,Blockly.FieldTextInput.htmlInput_.style.fontFamily,Blockly.FieldTextInput.htmlInput_.style.fontWeight,Blockly.FieldTextInput.htmlInput_.value);
d+=Blockly.FieldTextInput.TEXT_MEASURE_PADDING_MAGIC;d*=a}else d=c;d=Math.max(d,Blockly.BlockSvg.FIELD_WIDTH_MIN_EDIT*a);d=Math.min(d,Blockly.BlockSvg.FIELD_WIDTH_MAX_EDIT*a);b.style.width=d/a+1+"px";b.style.height=Blockly.BlockSvg.FIELD_HEIGHT+1+"px";b.style.transform="scale("+a+")";b.style.marginLeft=-.5*(d-c)+"px";c=this.getBorderRadius()+.5;b.style.borderRadius=c+"px";Blockly.FieldTextInput.htmlInput_.style.borderRadius=c+"px";c=this.sourceBlock_.getColourTertiary();b.style.borderColor=c;c=this.getAbsoluteXY_();
c.x-=a/2;c.y-=a/2;this.sourceBlock_.RTL&&(c.x+=d,c.x-=b.offsetWidth*a,c.x+=1*a);c.y+=1*a;goog.userAgent.GECKO&&Blockly.WidgetDiv.DIV.style.top&&(c.x+=2*a,c.y+=1*a);goog.userAgent.WEBKIT&&(c.y-=1*a);b.style.left=c.x+"px";b.style.top=c.y+"px"};Blockly.FieldTextInput.prototype.getBorderRadius=function(){return this.sourceBlock_.getOutputShape()==Blockly.OUTPUT_SHAPE_ROUND?Blockly.BlockSvg.NUMBER_FIELD_CORNER_RADIUS:Blockly.BlockSvg.TEXT_FIELD_CORNER_RADIUS};
Blockly.FieldTextInput.prototype.widgetDispose_=function(){var a=this;return function(){var b=Blockly.WidgetDiv.DIV,c=Blockly.FieldTextInput.htmlInput_;a.maybeSaveEdit_();a.unbindEvents_(c);c.dropDownArrowMouseWrapper_&&Blockly.unbindEvent_(c.dropDownArrowMouseWrapper_);Blockly.Events.setGroup(!1);c.style.fontSize=Blockly.BlockSvg.FIELD_TEXTINPUT_FONTSIZE_INITIAL+"pt";b.style.boxShadow="";a.sourceBlock_&&(a.sourceBlock_.isShadow()?(c=a.sourceBlock_.getHeightWidth(),b.style.width=c.width+1+"px",b.style.height=
c.height+1+"px"):(b.style.width=a.size_.width+1+"px",b.style.height=Blockly.BlockSvg.FIELD_HEIGHT+1+"px"));b.style.marginLeft=0}};Blockly.FieldTextInput.prototype.widgetDisposeAnimationFinished_=function(){return function(){var a=Blockly.WidgetDiv.DIV.style;a.width="auto";a.height="auto";a.fontSize="";Blockly.WidgetDiv.DIV.className="blocklyWidgetDiv";Blockly.WidgetDiv.DIV.removeAttribute("style");Blockly.FieldTextInput.htmlInput_.style.transition="";Blockly.FieldTextInput.htmlInput_=null}};
Blockly.FieldTextInput.prototype.maybeSaveEdit_=function(){var a=Blockly.FieldTextInput.htmlInput_,b=a.value;if(this.sourceBlock_)if(b=this.callValidator(b),null===b)b=a.defaultValue;else if(this.onFinishEditing_)this.onFinishEditing_(b);this.setText(b);this.sourceBlock_.rendered&&this.sourceBlock_.render()};
Blockly.FieldTextInput.numberValidator=function(a){console.warn("Blockly.FieldTextInput.numberValidator is deprecated. Use Blockly.FieldNumber instead.");if(null===a)return null;a=String(a);a=a.replace(/O/ig,"0");a=a.replace(/,/g,"");a=parseFloat(a||0);return isNaN(a)?null:String(a)};Blockly.FieldTextInput.nonnegativeIntegerValidator=function(a){(a=Blockly.FieldTextInput.numberValidator(a))&&(a=String(Math.max(0,Math.floor(a))));return a};Blockly.Field.register("field_input",Blockly.FieldTextInput);Blockly.FieldAngle=function(a,b,c){this.max_=b;this.symbol_=Blockly.utils.createSvgElement("tspan",{},null);this.symbol_.appendChild(document.createTextNode("\u00b0"));a=a&&!isNaN(a)?String(a):"0";Blockly.FieldAngle.superClass_.constructor.call(this,a,c,/[\d]|[\.]|[-]|[eE]/);this.addArgType("angle")};goog.inherits(Blockly.FieldAngle,Blockly.FieldTextInput);Blockly.FieldAngle.fromJson=function(a){return new Blockly.FieldAngle(a.angle,a.max)};Blockly.FieldAngle.ROUND=15;Blockly.FieldAngle.HALF=60;
Blockly.FieldAngle.CLOCKWISE=!0;Blockly.FieldAngle.OFFSET=90;Blockly.FieldAngle.WRAP=360;Blockly.FieldAngle.HANDLE_RADIUS=10;Blockly.FieldAngle.ARROW_WIDTH=Blockly.FieldAngle.HANDLE_RADIUS;Blockly.FieldAngle.HANDLE_GLOW_WIDTH=3;Blockly.FieldAngle.RADIUS=Blockly.FieldAngle.HALF-Blockly.FieldAngle.HANDLE_RADIUS-Blockly.FieldAngle.HANDLE_GLOW_WIDTH;Blockly.FieldAngle.CENTER_RADIUS=2;Blockly.FieldAngle.ARROW_SVG_PATH="icons/arrow.svg";
Blockly.FieldAngle.prototype.dispose_=function(){var a=this;return function(){Blockly.FieldAngle.superClass_.dispose_.call(a)();a.gauge_=null;a.mouseDownWrapper_&&Blockly.unbindEvent_(a.mouseDownWrapper_);a.mouseUpWrapper_&&Blockly.unbindEvent_(a.mouseUpWrapper_);a.mouseMoveWrapper_&&Blockly.unbindEvent_(a.mouseMoveWrapper_)}};
Blockly.FieldAngle.prototype.showEditor_=function(){Blockly.FieldAngle.superClass_.showEditor_.call(this,this.useTouchInteraction_);Blockly.DropDownDiv.hideWithoutAnimation();Blockly.DropDownDiv.clearContent();var a=Blockly.DropDownDiv.getContentDiv();a=Blockly.utils.createSvgElement("svg",{xmlns:"http://www.w3.org/2000/svg","xmlns:html":"http://www.w3.org/1999/xhtml","xmlns:xlink":"http://www.w3.org/1999/xlink",version:"1.1",height:2*Blockly.FieldAngle.HALF+"px",width:2*Blockly.FieldAngle.HALF+"px"},
a);Blockly.utils.createSvgElement("circle",{cx:Blockly.FieldAngle.HALF,cy:Blockly.FieldAngle.HALF,r:Blockly.FieldAngle.RADIUS,stroke:this.sourceBlock_.parentBlock_.getColourTertiary(),fill:this.sourceBlock_.parentBlock_.getColourSecondary(),"class":"blocklyAngleCircle"},a);Blockly.utils.createSvgElement("path",{d:function(a,b,e,f,g){var c=Math.PI/180;e/=2;return"M"+a+" "+b+" "+(Math.cos(c*g)*e+a)+" "+(-Math.sin(c*g)*e+b)+" A"+e+" "+e+" 0 0 1 "+(Math.cos(c*f)*e+a)+" "+(-Math.sin(c*f)*e+b)+"Z"}(Blockly.FieldAngle.HALF,
Blockly.FieldAngle.HALF,2*Blockly.FieldAngle.RADIUS,90,90-this.max_),"class":"blocklyAngleDisableCircle"},a);this.gauge_=Blockly.utils.createSvgElement("path",{"class":"blocklyAngleGauge"},a);this.line_=Blockly.utils.createSvgElement("line",{x1:Blockly.FieldAngle.HALF,y1:Blockly.FieldAngle.HALF,"class":"blocklyAngleLine"},a);var b=Math.PI*Blockly.FieldAngle.OFFSET/180;Blockly.utils.createSvgElement("line",{x1:Blockly.FieldAngle.HALF,y1:Blockly.FieldAngle.HALF,x2:Blockly.FieldAngle.HALF+Blockly.FieldAngle.RADIUS*
Math.cos(b),y2:Blockly.FieldAngle.HALF-Blockly.FieldAngle.RADIUS*Math.sin(b),"class":"blocklyAngleLine"},a);for(b=0;360>b;b+=15)Blockly.utils.createSvgElement("line",{x1:Blockly.FieldAngle.HALF+Blockly.FieldAngle.RADIUS-13,y1:Blockly.FieldAngle.HALF,x2:Blockly.FieldAngle.HALF+Blockly.FieldAngle.RADIUS-7,y2:Blockly.FieldAngle.HALF,"class":"blocklyAngleMarks",transform:"rotate("+b+","+Blockly.FieldAngle.HALF+","+Blockly.FieldAngle.HALF+")"},a);Blockly.utils.createSvgElement("circle",{cx:Blockly.FieldAngle.HALF,
cy:Blockly.FieldAngle.HALF,r:Blockly.FieldAngle.CENTER_RADIUS,"class":"blocklyAngleCenterPoint"},a);this.handle_=Blockly.utils.createSvgElement("g",{},a);Blockly.utils.createSvgElement("circle",{cx:0,cy:0,r:Blockly.FieldAngle.HANDLE_RADIUS,"class":"blocklyAngleDragHandle"},this.handle_);this.arrowSvg_=Blockly.utils.createSvgElement("image",{width:Blockly.FieldAngle.ARROW_WIDTH,height:Blockly.FieldAngle.ARROW_WIDTH,x:-Blockly.FieldAngle.ARROW_WIDTH/2,y:-Blockly.FieldAngle.ARROW_WIDTH/2,"class":"blocklyAngleDragArrow"},
this.handle_);this.arrowSvg_.setAttributeNS("http://www.w3.org/1999/xlink","xlink:href",Blockly.mainWorkspace.options.pathToMedia+Blockly.FieldAngle.ARROW_SVG_PATH);Blockly.DropDownDiv.setColour(this.sourceBlock_.parentBlock_.getColour(),this.sourceBlock_.getColourTertiary());Blockly.DropDownDiv.setCategory(this.sourceBlock_.parentBlock_.getCategory());Blockly.DropDownDiv.showPositionedByBlock(this,this.sourceBlock_);this.mouseDownWrapper_=Blockly.bindEvent_(this.handle_,"mousedown",this,this.onMouseDown);
this.updateGraph_()};Blockly.FieldAngle.prototype.onMouseDown=function(){this.mouseMoveWrapper_=Blockly.bindEvent_(document.body,"mousemove",this,this.onMouseMove);this.mouseUpWrapper_=Blockly.bindEvent_(document.body,"mouseup",this,this.onMouseUp)};Blockly.FieldAngle.prototype.onMouseUp=function(){Blockly.unbindEvent_(this.mouseMoveWrapper_);Blockly.unbindEvent_(this.mouseUpWrapper_)};
Blockly.FieldAngle.prototype.onMouseMove=function(a){a.preventDefault();var b=this.gauge_.ownerSVGElement.getBoundingClientRect(),c=a.clientX-b.left-Blockly.FieldAngle.HALF;a=a.clientY-b.top-Blockly.FieldAngle.HALF;b=Math.atan(-a/c);isNaN(b)||(b=goog.math.toDegrees(b),0>c?b+=180:0<a&&(b+=360),b=Blockly.FieldAngle.CLOCKWISE?Blockly.FieldAngle.OFFSET+360-b:b-Blockly.FieldAngle.OFFSET,Blockly.FieldAngle.ROUND&&(b=Math.round(b/Blockly.FieldAngle.ROUND)*Blockly.FieldAngle.ROUND),b=this.callValidator(b),
Blockly.FieldTextInput.htmlInput_.value=b,this.setValue(b),this.validate_(),this.resizeEditor_())};Blockly.FieldAngle.prototype.setText=function(a){Blockly.FieldAngle.superClass_.setText.call(this,a);this.textElement_&&(this.updateGraph_(),this.size_.width=0)};
Blockly.FieldAngle.prototype.updateGraph_=function(){if(this.gauge_){var a=Number(this.getText())%360+Blockly.FieldAngle.OFFSET,b=goog.math.toRadians(a),c=["M ",Blockly.FieldAngle.HALF,",",Blockly.FieldAngle.HALF],d=Blockly.FieldAngle.HALF,e=Blockly.FieldAngle.HALF;if(!isNaN(b)){var f=goog.math.toRadians(Blockly.FieldAngle.OFFSET),g=Math.cos(f)*Blockly.FieldAngle.RADIUS,h=Math.sin(f)*-Blockly.FieldAngle.RADIUS;Blockly.FieldAngle.CLOCKWISE&&(b=2*f-b);d+=Math.cos(b)*Blockly.FieldAngle.RADIUS;e-=Math.sin(b)*
Blockly.FieldAngle.RADIUS;b=180<Math.abs(a-Blockly.FieldAngle.OFFSET)?1:0;f=Number(Blockly.FieldAngle.CLOCKWISE);a<Blockly.FieldAngle.OFFSET&&(f=1-f);c.push(" l ",g,",",h," A ",Blockly.FieldAngle.RADIUS,",",Blockly.FieldAngle.RADIUS," 0 ",b," ",f," ",d,",",e," z");this.arrowSvg_.setAttribute("transform","rotate("+(Blockly.FieldAngle.CLOCKWISE?a+2*Blockly.FieldAngle.OFFSET:-a)+")")}this.gauge_.setAttribute("d",c.join(""));this.line_.setAttribute("x2",d);this.line_.setAttribute("y2",e);this.handle_.setAttribute("transform",
"translate("+d+","+e+")")}};Blockly.FieldAngle.prototype.classValidator=function(a){if(null===a)return null;a=parseFloat(a||0);if(isNaN(a))return null;a%=360;0>a&&(a+=360);a>Blockly.FieldAngle.WRAP&&(a-=360);a>this.max_&&(a=this.max_);return String(a)};Blockly.Field.register("field_angle",Blockly.FieldAngle);Blockly.FieldCheckbox=function(a,b){Blockly.FieldCheckbox.superClass_.constructor.call(this,"",b);this.setValue(a);this.addArgType("checkbox")};goog.inherits(Blockly.FieldCheckbox,Blockly.Field);Blockly.FieldCheckbox.fromJson=function(a){return new Blockly.FieldCheckbox(a.checked?"TRUE":"FALSE")};Blockly.FieldCheckbox.CHECK_CHAR="\u2713";Blockly.FieldCheckbox.prototype.CURSOR="default";
Blockly.FieldCheckbox.prototype.init=function(){if(!this.fieldGroup_){Blockly.FieldCheckbox.superClass_.init.call(this);this.checkElement_=Blockly.utils.createSvgElement("text",{"class":"blocklyText blocklyCheckbox",x:-3,y:14},this.fieldGroup_);var a=document.createTextNode(Blockly.FieldCheckbox.CHECK_CHAR);this.checkElement_.appendChild(a);this.checkElement_.style.display=this.state_?"block":"none"}};Blockly.FieldCheckbox.prototype.getValue=function(){return String(this.state_).toUpperCase()};
Blockly.FieldCheckbox.prototype.setValue=function(a){a="string"==typeof a?"TRUE"==a.toUpperCase():!!a;this.state_!==a&&(this.sourceBlock_&&Blockly.Events.isEnabled()&&Blockly.Events.fire(new Blockly.Events.BlockChange(this.sourceBlock_,"field",this.name,this.state_,a)),this.state_=a,this.checkElement_&&(this.checkElement_.style.display=a?"block":"none"))};Blockly.FieldCheckbox.prototype.showEditor_=function(){var a=!this.state_;this.sourceBlock_&&(a=this.callValidator(a));null!==a&&this.setValue(String(a).toUpperCase())};
Blockly.Field.register("field_checkbox",Blockly.FieldCheckbox);goog.color={};
goog.color.names={aliceblue:"#f0f8ff",antiquewhite:"#faebd7",aqua:"#00ffff",aquamarine:"#7fffd4",azure:"#f0ffff",beige:"#f5f5dc",bisque:"#ffe4c4",black:"#000000",blanchedalmond:"#ffebcd",blue:"#0000ff",blueviolet:"#8a2be2",brown:"#a52a2a",burlywood:"#deb887",cadetblue:"#5f9ea0",chartreuse:"#7fff00",chocolate:"#d2691e",coral:"#ff7f50",cornflowerblue:"#6495ed",cornsilk:"#fff8dc",crimson:"#dc143c",cyan:"#00ffff",darkblue:"#00008b",darkcyan:"#008b8b",darkgoldenrod:"#b8860b",darkgray:"#a9a9a9",darkgreen:"#006400",
darkgrey:"#a9a9a9",darkkhaki:"#bdb76b",darkmagenta:"#8b008b",darkolivegreen:"#556b2f",darkorange:"#ff8c00",darkorchid:"#9932cc",darkred:"#8b0000",darksalmon:"#e9967a",darkseagreen:"#8fbc8f",darkslateblue:"#483d8b",darkslategray:"#2f4f4f",darkslategrey:"#2f4f4f",darkturquoise:"#00ced1",darkviolet:"#9400d3",deeppink:"#ff1493",deepskyblue:"#00bfff",dimgray:"#696969",dimgrey:"#696969",dodgerblue:"#1e90ff",firebrick:"#b22222",floralwhite:"#fffaf0",forestgreen:"#228b22",fuchsia:"#ff00ff",gainsboro:"#dcdcdc",
ghostwhite:"#f8f8ff",gold:"#ffd700",goldenrod:"#daa520",gray:"#808080",green:"#008000",greenyellow:"#adff2f",grey:"#808080",honeydew:"#f0fff0",hotpink:"#ff69b4",indianred:"#cd5c5c",indigo:"#4b0082",ivory:"#fffff0",khaki:"#f0e68c",lavender:"#e6e6fa",lavenderblush:"#fff0f5",lawngreen:"#7cfc00",lemonchiffon:"#fffacd",lightblue:"#add8e6",lightcoral:"#f08080",lightcyan:"#e0ffff",lightgoldenrodyellow:"#fafad2",lightgray:"#d3d3d3",lightgreen:"#90ee90",lightgrey:"#d3d3d3",lightpink:"#ffb6c1",lightsalmon:"#ffa07a",
lightseagreen:"#20b2aa",lightskyblue:"#87cefa",lightslategray:"#778899",lightslategrey:"#778899",lightsteelblue:"#b0c4de",lightyellow:"#ffffe0",lime:"#00ff00",limegreen:"#32cd32",linen:"#faf0e6",magenta:"#ff00ff",maroon:"#800000",mediumaquamarine:"#66cdaa",mediumblue:"#0000cd",mediumorchid:"#ba55d3",mediumpurple:"#9370db",mediumseagreen:"#3cb371",mediumslateblue:"#7b68ee",mediumspringgreen:"#00fa9a",mediumturquoise:"#48d1cc",mediumvioletred:"#c71585",midnightblue:"#191970",mintcream:"#f5fffa",mistyrose:"#ffe4e1",
moccasin:"#ffe4b5",navajowhite:"#ffdead",navy:"#000080",oldlace:"#fdf5e6",olive:"#808000",olivedrab:"#6b8e23",orange:"#ffa500",orangered:"#ff4500",orchid:"#da70d6",palegoldenrod:"#eee8aa",palegreen:"#98fb98",paleturquoise:"#afeeee",palevioletred:"#db7093",papayawhip:"#ffefd5",peachpuff:"#ffdab9",peru:"#cd853f",pink:"#ffc0cb",plum:"#dda0dd",powderblue:"#b0e0e6",purple:"#800080",red:"#ff0000",rosybrown:"#bc8f8f",royalblue:"#4169e1",saddlebrown:"#8b4513",salmon:"#fa8072",sandybrown:"#f4a460",seagreen:"#2e8b57",
seashell:"#fff5ee",sienna:"#a0522d",silver:"#c0c0c0",skyblue:"#87ceeb",slateblue:"#6a5acd",slategray:"#708090",slategrey:"#708090",snow:"#fffafa",springgreen:"#00ff7f",steelblue:"#4682b4",tan:"#d2b48c",teal:"#008080",thistle:"#d8bfd8",tomato:"#ff6347",turquoise:"#40e0d0",violet:"#ee82ee",wheat:"#f5deb3",white:"#ffffff",whitesmoke:"#f5f5f5",yellow:"#ffff00",yellowgreen:"#9acd32"};goog.color.parse=function(a){var b={};a=String(a);var c=goog.color.prependHashIfNecessaryHelper(a);if(goog.color.isValidHexColor_(c))return b.hex=goog.color.normalizeHex(c),b.type="hex",b;c=goog.color.isValidRgbColor_(a);if(c.length)return b.hex=goog.color.rgbArrayToHex(c),b.type="rgb",b;if(goog.color.names&&(c=goog.color.names[a.toLowerCase()]))return b.hex=c,b.type="named",b;throw Error(a+" is not a valid color string");};
goog.color.isValidColor=function(a){var b=goog.color.prependHashIfNecessaryHelper(a);return!!(goog.color.isValidHexColor_(b)||goog.color.isValidRgbColor_(a).length||goog.color.names&&goog.color.names[a.toLowerCase()])};goog.color.parseRgb=function(a){var b=goog.color.isValidRgbColor_(a);if(!b.length)throw Error(a+" is not a valid RGB color");return b};goog.color.hexToRgbStyle=function(a){return goog.color.rgbStyle_(goog.color.hexToRgb(a))};goog.color.hexTripletRe_=/#(.)(.)(.)/;
goog.color.normalizeHex=function(a){if(!goog.color.isValidHexColor_(a))throw Error("'"+a+"' is not a valid hex color");4==a.length&&(a=a.replace(goog.color.hexTripletRe_,"#$1$1$2$2$3$3"));return a.toLowerCase()};goog.color.hexToRgb=function(a){a=goog.color.normalizeHex(a);a=parseInt(a.substr(1),16);return[a>>16,a>>8&255,a&255]};
goog.color.rgbToHex=function(a,b,c){a=Number(a);b=Number(b);c=Number(c);if(a!=(a&255)||b!=(b&255)||c!=(c&255))throw Error('"('+a+","+b+","+c+'") is not a valid RGB color');b=a<<16|b<<8|c;return 16>a?"#"+(16777216|b).toString(16).substr(1):"#"+b.toString(16)};goog.color.rgbArrayToHex=function(a){return goog.color.rgbToHex(a[0],a[1],a[2])};
goog.color.rgbToHsl=function(a,b,c){a/=255;b/=255;c/=255;var d=Math.max(a,b,c),e=Math.min(a,b,c),f=0,g=0,h=.5*(d+e);d!=e&&(d==a?f=60*(b-c)/(d-e):d==b?f=60*(c-a)/(d-e)+120:d==c&&(f=60*(a-b)/(d-e)+240),g=0<h&&.5>=h?(d-e)/(2*h):(d-e)/(2-2*h));return[Math.round(f+360)%360,g,h]};goog.color.rgbArrayToHsl=function(a){return goog.color.rgbToHsl(a[0],a[1],a[2])};goog.color.hueToRgb_=function(a,b,c){0>c?c+=1:1<c&&--c;return 1>6*c?a+6*(b-a)*c:1>2*c?b:2>3*c?a+(b-a)*(2/3-c)*6:a};
goog.color.hslToRgb=function(a,b,c){a/=360;if(0==b)c=b=a=255*c;else{var d=.5>c?c*(1+b):c+b-b*c;var e=2*c-d;c=255*goog.color.hueToRgb_(e,d,a+1/3);b=255*goog.color.hueToRgb_(e,d,a);a=255*goog.color.hueToRgb_(e,d,a-1/3)}return[Math.round(c),Math.round(b),Math.round(a)]};goog.color.hslArrayToRgb=function(a){return goog.color.hslToRgb(a[0],a[1],a[2])};goog.color.validHexColorRe_=/^#(?:[0-9a-f]{3}){1,2}$/i;goog.color.isValidHexColor_=function(a){return goog.color.validHexColorRe_.test(a)};
goog.color.rgbColorRe_=/^(?:rgb)?\((0|[1-9]\d{0,2}),\s?(0|[1-9]\d{0,2}),\s?(0|[1-9]\d{0,2})\)$/i;goog.color.isValidRgbColor_=function(a){var b=a.match(goog.color.rgbColorRe_);if(b){a=Number(b[1]);var c=Number(b[2]);b=Number(b[3]);if(0<=a&&255>=a&&0<=c&&255>=c&&0<=b&&255>=b)return[a,c,b]}return[]};goog.color.prependZeroIfNecessaryHelper=function(a){return 1==a.length?"0"+a:a};goog.color.prependHashIfNecessaryHelper=function(a){return"#"==a.charAt(0)?a:"#"+a};
goog.color.rgbStyle_=function(a){return"rgb("+a.join(",")+")"};goog.color.hsvToRgb=function(a,b,c){var d=0,e=0,f=0;if(0==b)f=e=d=c;else{var g=Math.floor(a/60),h=a/60-g;a=c*(1-b);var k=c*(1-b*h);b=c*(1-b*(1-h));switch(g){case 1:d=k;e=c;f=a;break;case 2:d=a;e=c;f=b;break;case 3:d=a;e=k;f=c;break;case 4:d=b;e=a;f=c;break;case 5:d=c;e=a;f=k;break;case 6:case 0:d=c,e=b,f=a}}return[Math.floor(d),Math.floor(e),Math.floor(f)]};
goog.color.rgbToHsv=function(a,b,c){var d=Math.max(Math.max(a,b),c),e=Math.min(Math.min(a,b),c);if(e==d)e=a=0;else{var f=d-e;e=f/d;a=60*(a==d?(b-c)/f:b==d?2+(c-a)/f:4+(a-b)/f);0>a&&(a+=360);360<a&&(a-=360)}return[a,e,d]};goog.color.rgbArrayToHsv=function(a){return goog.color.rgbToHsv(a[0],a[1],a[2])};goog.color.hsvArrayToRgb=function(a){return goog.color.hsvToRgb(a[0],a[1],a[2])};goog.color.hexToHsl=function(a){a=goog.color.hexToRgb(a);return goog.color.rgbToHsl(a[0],a[1],a[2])};
goog.color.hslToHex=function(a,b,c){return goog.color.rgbArrayToHex(goog.color.hslToRgb(a,b,c))};goog.color.hslArrayToHex=function(a){return goog.color.rgbArrayToHex(goog.color.hslToRgb(a[0],a[1],a[2]))};goog.color.hexToHsv=function(a){return goog.color.rgbArrayToHsv(goog.color.hexToRgb(a))};goog.color.hsvToHex=function(a,b,c){return goog.color.rgbArrayToHex(goog.color.hsvToRgb(a,b,c))};goog.color.hsvArrayToHex=function(a){return goog.color.hsvToHex(a[0],a[1],a[2])};
goog.color.hslDistance=function(a,b){var c=.5>=a[2]?a[1]*a[2]:a[1]*(1-a[2]);var d=.5>=b[2]?b[1]*b[2]:b[1]*(1-b[2]);return(a[2]-b[2])*(a[2]-b[2])+c*c+d*d-2*c*d*Math.cos(2*(a[0]/360-b[0]/360)*Math.PI)};goog.color.blend=function(a,b,c){c=goog.math.clamp(c,0,1);return[Math.round(b[0]+c*(a[0]-b[0])),Math.round(b[1]+c*(a[1]-b[1])),Math.round(b[2]+c*(a[2]-b[2]))]};goog.color.darken=function(a,b){return goog.color.blend([0,0,0],a,b)};
goog.color.lighten=function(a,b){return goog.color.blend([255,255,255],a,b)};goog.color.highContrast=function(a,b){for(var c=[],d=0;d<b.length;d++)c.push({color:b[d],diff:goog.color.yiqBrightnessDiff_(b[d],a)+goog.color.colorDiff_(b[d],a)});c.sort(function(a,b){return b.diff-a.diff});return c[0].color};goog.color.yiqBrightness_=function(a){return Math.round((299*a[0]+587*a[1]+114*a[2])/1E3)};goog.color.yiqBrightnessDiff_=function(a,b){return Math.abs(goog.color.yiqBrightness_(a)-goog.color.yiqBrightness_(b))};
goog.color.colorDiff_=function(a,b){return Math.abs(a[0]-b[0])+Math.abs(a[1]-b[1])+Math.abs(a[2]-b[2])};goog.iter={};goog.iter.StopIteration="StopIteration"in goog.global?goog.global.StopIteration:{message:"StopIteration",stack:""};goog.iter.Iterator=function(){};goog.iter.Iterator.prototype.next=function(){throw goog.iter.StopIteration;};goog.iter.Iterator.prototype.__iterator__=function(a){return this};
goog.iter.toIterator=function(a){if(a instanceof goog.iter.Iterator)return a;if("function"==typeof a.__iterator__)return a.__iterator__(!1);if(goog.isArrayLike(a)){var b=0,c=new goog.iter.Iterator;c.next=function(){for(;;){if(b>=a.length)throw goog.iter.StopIteration;if(b in a)return a[b++];b++}};return c}throw Error("Not implemented");};
goog.iter.forEach=function(a,b,c){if(goog.isArrayLike(a))try{goog.array.forEach(a,b,c)}catch(d){if(d!==goog.iter.StopIteration)throw d;}else{a=goog.iter.toIterator(a);try{for(;;)b.call(c,a.next(),void 0,a)}catch(d){if(d!==goog.iter.StopIteration)throw d;}}};goog.iter.filter=function(a,b,c){var d=goog.iter.toIterator(a);a=new goog.iter.Iterator;a.next=function(){for(;;){var a=d.next();if(b.call(c,a,void 0,d))return a}};return a};
goog.iter.filterFalse=function(a,b,c){return goog.iter.filter(a,goog.functions.not(b),c)};goog.iter.range=function(a,b,c){var d=0,e=a,f=c||1;1<arguments.length&&(d=a,e=+b);if(0==f)throw Error("Range step argument must not be zero");var g=new goog.iter.Iterator;g.next=function(){if(0<f&&d>=e||0>f&&d<=e)throw goog.iter.StopIteration;var a=d;d+=f;return a};return g};goog.iter.join=function(a,b){return goog.iter.toArray(a).join(b)};
goog.iter.map=function(a,b,c){var d=goog.iter.toIterator(a);a=new goog.iter.Iterator;a.next=function(){var a=d.next();return b.call(c,a,void 0,d)};return a};goog.iter.reduce=function(a,b,c,d){var e=c;goog.iter.forEach(a,function(a){e=b.call(d,e,a)});return e};goog.iter.some=function(a,b,c){a=goog.iter.toIterator(a);try{for(;;)if(b.call(c,a.next(),void 0,a))return!0}catch(d){if(d!==goog.iter.StopIteration)throw d;}return!1};
goog.iter.every=function(a,b,c){a=goog.iter.toIterator(a);try{for(;;)if(!b.call(c,a.next(),void 0,a))return!1}catch(d){if(d!==goog.iter.StopIteration)throw d;}return!0};goog.iter.chain=function(a){return goog.iter.chainFromIterable(arguments)};
goog.iter.chainFromIterable=function(a){var b=goog.iter.toIterator(a);a=new goog.iter.Iterator;var c=null;a.next=function(){for(;;){if(null==c){var a=b.next();c=goog.iter.toIterator(a)}try{return c.next()}catch(e){if(e!==goog.iter.StopIteration)throw e;c=null}}};return a};goog.iter.dropWhile=function(a,b,c){var d=goog.iter.toIterator(a);a=new goog.iter.Iterator;var e=!0;a.next=function(){for(;;){var a=d.next();if(!e||!b.call(c,a,void 0,d))return e=!1,a}};return a};
goog.iter.takeWhile=function(a,b,c){var d=goog.iter.toIterator(a);a=new goog.iter.Iterator;a.next=function(){var a=d.next();if(b.call(c,a,void 0,d))return a;throw goog.iter.StopIteration;};return a};goog.iter.toArray=function(a){if(goog.isArrayLike(a))return goog.array.toArray(a);a=goog.iter.toIterator(a);var b=[];goog.iter.forEach(a,function(a){b.push(a)});return b};
goog.iter.equals=function(a,b,c){a=goog.iter.zipLongest({},a,b);var d=c||goog.array.defaultCompareEquality;return goog.iter.every(a,function(a){return d(a[0],a[1])})};goog.iter.nextOrValue=function(a,b){try{return goog.iter.toIterator(a).next()}catch(c){if(c!=goog.iter.StopIteration)throw c;return b}};
goog.iter.product=function(a){if(goog.array.some(arguments,function(a){return!a.length})||!arguments.length)return new goog.iter.Iterator;var b=new goog.iter.Iterator,c=arguments,d=goog.array.repeat(0,c.length);b.next=function(){if(d){for(var a=goog.array.map(d,function(a,b){return c[b][a]}),b=d.length-1;0<=b;b--){goog.asserts.assert(d);if(d[b]<c[b].length-1){d[b]++;break}if(0==b){d=null;break}d[b]=0}return a}throw goog.iter.StopIteration;};return b};
goog.iter.cycle=function(a){var b=goog.iter.toIterator(a),c=[],d=0;a=new goog.iter.Iterator;var e=!1;a.next=function(){var a=null;if(!e)try{return a=b.next(),c.push(a),a}catch(g){if(g!=goog.iter.StopIteration||goog.array.isEmpty(c))throw g;e=!0}a=c[d];d=(d+1)%c.length;return a};return a};goog.iter.count=function(a,b){var c=a||0,d=goog.isDef(b)?b:1;a=new goog.iter.Iterator;a.next=function(){var a=c;c+=d;return a};return a};
goog.iter.repeat=function(a){var b=new goog.iter.Iterator;b.next=goog.functions.constant(a);return b};goog.iter.accumulate=function(a){var b=goog.iter.toIterator(a),c=0;a=new goog.iter.Iterator;a.next=function(){return c+=b.next()};return a};goog.iter.zip=function(a){var b=arguments,c=new goog.iter.Iterator;if(0<b.length){var d=goog.array.map(b,goog.iter.toIterator);c.next=function(){return goog.array.map(d,function(a){return a.next()})}}return c};
goog.iter.zipLongest=function(a,b){var c=goog.array.slice(arguments,1),d=new goog.iter.Iterator;if(0<c.length){var e=goog.array.map(c,goog.iter.toIterator);d.next=function(){var b=!1,c=goog.array.map(e,function(c){try{var d=c.next();b=!0}catch(l){if(l!==goog.iter.StopIteration)throw l;d=a}return d});if(!b)throw goog.iter.StopIteration;return c}}return d};goog.iter.compress=function(a,b){var c=goog.iter.toIterator(b);return goog.iter.filter(a,function(){return!!c.next()})};
goog.iter.GroupByIterator_=function(a,b){this.iterator=goog.iter.toIterator(a);this.keyFunc=b||goog.functions.identity};goog.inherits(goog.iter.GroupByIterator_,goog.iter.Iterator);goog.iter.GroupByIterator_.prototype.next=function(){for(;this.currentKey==this.targetKey;)this.currentValue=this.iterator.next(),this.currentKey=this.keyFunc(this.currentValue);this.targetKey=this.currentKey;return[this.currentKey,this.groupItems_(this.targetKey)]};
goog.iter.GroupByIterator_.prototype.groupItems_=function(a){for(var b=[];this.currentKey==a;){b.push(this.currentValue);try{this.currentValue=this.iterator.next()}catch(c){if(c!==goog.iter.StopIteration)throw c;break}this.currentKey=this.keyFunc(this.currentValue)}return b};goog.iter.groupBy=function(a,b){return new goog.iter.GroupByIterator_(a,b)};
goog.iter.starMap=function(a,b,c){var d=goog.iter.toIterator(a);a=new goog.iter.Iterator;a.next=function(){var a=goog.iter.toArray(d.next());return b.apply(c,goog.array.concat(a,void 0,d))};return a};
goog.iter.tee=function(a,b){var c=goog.iter.toIterator(a);a=goog.isNumber(b)?b:2;var d=goog.array.map(goog.array.range(a),function(){return[]}),e=function(){var a=c.next();goog.array.forEach(d,function(b){b.push(a)})};return goog.array.map(d,function(a){var b=new goog.iter.Iterator;b.next=function(){goog.array.isEmpty(a)&&e();goog.asserts.assert(!goog.array.isEmpty(a));return a.shift()};return b})};goog.iter.enumerate=function(a,b){return goog.iter.zip(goog.iter.count(b),a)};
goog.iter.limit=function(a,b){goog.asserts.assert(goog.math.isInt(b)&&0<=b);var c=goog.iter.toIterator(a);a=new goog.iter.Iterator;var d=b;a.next=function(){if(0<d--)return c.next();throw goog.iter.StopIteration;};return a};goog.iter.consume=function(a,b){goog.asserts.assert(goog.math.isInt(b)&&0<=b);for(a=goog.iter.toIterator(a);0<b--;)goog.iter.nextOrValue(a,null);return a};
goog.iter.slice=function(a,b,c){goog.asserts.assert(goog.math.isInt(b)&&0<=b);a=goog.iter.consume(a,b);goog.isNumber(c)&&(goog.asserts.assert(goog.math.isInt(c)&&c>=b),a=goog.iter.limit(a,c-b));return a};goog.iter.hasDuplicates_=function(a){var b=[];goog.array.removeDuplicates(a,b);return a.length!=b.length};goog.iter.permutations=function(a,b){a=goog.iter.toArray(a);b=goog.isNumber(b)?b:a.length;b=goog.array.repeat(a,b);b=goog.iter.product.apply(void 0,b);return goog.iter.filter(b,function(a){return!goog.iter.hasDuplicates_(a)})};
goog.iter.combinations=function(a,b){function c(a){return d[a]}var d=goog.iter.toArray(a);a=goog.iter.range(d.length);b=goog.iter.permutations(a,b);var e=goog.iter.filter(b,function(a){return goog.array.isSorted(a)});b=new goog.iter.Iterator;b.next=function(){return goog.array.map(e.next(),c)};return b};
goog.iter.combinationsWithReplacement=function(a,b){function c(a){return d[a]}var d=goog.iter.toArray(a);a=goog.array.range(d.length);b=goog.array.repeat(a,b);b=goog.iter.product.apply(void 0,b);var e=goog.iter.filter(b,function(a){return goog.array.isSorted(a)});b=new goog.iter.Iterator;b.next=function(){return goog.array.map(e.next(),c)};return b};goog.dom.TagWalkType={START_TAG:1,OTHER:0,END_TAG:-1};goog.dom.TagIterator=function(a,b,c,d,e){this.reversed=!!b;this.node=null;this.tagType=goog.dom.TagWalkType.OTHER;this.started_=!1;this.constrained=!c;a&&this.setPosition(a,d);this.depth=void 0!=e?e:this.tagType||0;this.reversed&&(this.depth*=-1)};goog.inherits(goog.dom.TagIterator,goog.iter.Iterator);
goog.dom.TagIterator.prototype.setPosition=function(a,b,c){if(this.node=a)goog.isNumber(b)?this.tagType=b:this.tagType=this.node.nodeType!=goog.dom.NodeType.ELEMENT?goog.dom.TagWalkType.OTHER:this.reversed?goog.dom.TagWalkType.END_TAG:goog.dom.TagWalkType.START_TAG;goog.isNumber(c)&&(this.depth=c)};goog.dom.TagIterator.prototype.copyFrom=function(a){this.node=a.node;this.tagType=a.tagType;this.depth=a.depth;this.reversed=a.reversed;this.constrained=a.constrained};
goog.dom.TagIterator.prototype.clone=function(){return new goog.dom.TagIterator(this.node,this.reversed,!this.constrained,this.tagType,this.depth)};goog.dom.TagIterator.prototype.skipTag=function(){var a=this.reversed?goog.dom.TagWalkType.END_TAG:goog.dom.TagWalkType.START_TAG;this.tagType==a&&(this.tagType=-1*a,this.depth+=this.tagType*(this.reversed?-1:1))};
goog.dom.TagIterator.prototype.restartTag=function(){var a=this.reversed?goog.dom.TagWalkType.START_TAG:goog.dom.TagWalkType.END_TAG;this.tagType==a&&(this.tagType=-1*a,this.depth+=this.tagType*(this.reversed?-1:1))};
goog.dom.TagIterator.prototype.next=function(){if(this.started_){if(!this.node||this.constrained&&0==this.depth)throw goog.iter.StopIteration;var a=this.node;var b=this.reversed?goog.dom.TagWalkType.END_TAG:goog.dom.TagWalkType.START_TAG;if(this.tagType==b){var c=this.reversed?a.lastChild:a.firstChild;c?this.setPosition(c):this.setPosition(a,-1*b)}else(c=this.reversed?a.previousSibling:a.nextSibling)?this.setPosition(c):this.setPosition(a.parentNode,-1*b);this.depth+=this.tagType*(this.reversed?-1:
1)}else this.started_=!0;a=this.node;if(!this.node)throw goog.iter.StopIteration;return a};goog.dom.TagIterator.prototype.isStarted=function(){return this.started_};goog.dom.TagIterator.prototype.isStartTag=function(){return this.tagType==goog.dom.TagWalkType.START_TAG};goog.dom.TagIterator.prototype.isEndTag=function(){return this.tagType==goog.dom.TagWalkType.END_TAG};goog.dom.TagIterator.prototype.isNonElement=function(){return this.tagType==goog.dom.TagWalkType.OTHER};
goog.dom.TagIterator.prototype.equals=function(a){return a.node==this.node&&(!this.node||a.tagType==this.tagType)};goog.dom.TagIterator.prototype.splice=function(a){var b=this.node;this.restartTag();this.reversed=!this.reversed;goog.dom.TagIterator.prototype.next.call(this);this.reversed=!this.reversed;for(var c=goog.isArrayLike(arguments[0])?arguments[0]:arguments,d=c.length-1;0<=d;d--)goog.dom.insertSiblingAfter(c[d],b);goog.dom.removeNode(b)};goog.dom.NodeIterator=function(a,b,c,d){goog.dom.TagIterator.call(this,a,b,c,null,d)};goog.inherits(goog.dom.NodeIterator,goog.dom.TagIterator);goog.dom.NodeIterator.prototype.next=function(){do goog.dom.NodeIterator.superClass_.next.call(this);while(this.isEndTag());return this.node};goog.userAgent.product={};goog.userAgent.product.ASSUME_FIREFOX=!1;goog.userAgent.product.ASSUME_IPHONE=!1;goog.userAgent.product.ASSUME_IPAD=!1;goog.userAgent.product.ASSUME_ANDROID=!1;goog.userAgent.product.ASSUME_CHROME=!1;goog.userAgent.product.ASSUME_SAFARI=!1;
goog.userAgent.product.PRODUCT_KNOWN_=goog.userAgent.ASSUME_IE||goog.userAgent.ASSUME_EDGE||goog.userAgent.ASSUME_OPERA||goog.userAgent.product.ASSUME_FIREFOX||goog.userAgent.product.ASSUME_IPHONE||goog.userAgent.product.ASSUME_IPAD||goog.userAgent.product.ASSUME_ANDROID||goog.userAgent.product.ASSUME_CHROME||goog.userAgent.product.ASSUME_SAFARI;goog.userAgent.product.OPERA=goog.userAgent.OPERA;goog.userAgent.product.IE=goog.userAgent.IE;goog.userAgent.product.EDGE=goog.userAgent.EDGE;
goog.userAgent.product.FIREFOX=goog.userAgent.product.PRODUCT_KNOWN_?goog.userAgent.product.ASSUME_FIREFOX:goog.labs.userAgent.browser.isFirefox();goog.userAgent.product.isIphoneOrIpod_=function(){return goog.labs.userAgent.platform.isIphone()||goog.labs.userAgent.platform.isIpod()};goog.userAgent.product.IPHONE=goog.userAgent.product.PRODUCT_KNOWN_?goog.userAgent.product.ASSUME_IPHONE:goog.userAgent.product.isIphoneOrIpod_();
goog.userAgent.product.IPAD=goog.userAgent.product.PRODUCT_KNOWN_?goog.userAgent.product.ASSUME_IPAD:goog.labs.userAgent.platform.isIpad();goog.userAgent.product.ANDROID=goog.userAgent.product.PRODUCT_KNOWN_?goog.userAgent.product.ASSUME_ANDROID:goog.labs.userAgent.browser.isAndroidBrowser();goog.userAgent.product.CHROME=goog.userAgent.product.PRODUCT_KNOWN_?goog.userAgent.product.ASSUME_CHROME:goog.labs.userAgent.browser.isChrome();
goog.userAgent.product.isSafariDesktop_=function(){return goog.labs.userAgent.browser.isSafari()&&!goog.labs.userAgent.platform.isIos()};goog.userAgent.product.SAFARI=goog.userAgent.product.PRODUCT_KNOWN_?goog.userAgent.product.ASSUME_SAFARI:goog.userAgent.product.isSafariDesktop_();goog.dom.dataset={};goog.dom.dataset.ALLOWED_=!goog.userAgent.product.IE&&!goog.labs.userAgent.browser.isSafari();goog.dom.dataset.PREFIX_="data-";goog.dom.dataset.isValidProperty_=function(a){return!/-[a-z]/.test(a)};
goog.dom.dataset.set=function(a,b,c){if(goog.dom.dataset.ALLOWED_&&a.dataset)a.dataset[b]=c;else if(goog.dom.dataset.isValidProperty_(b))a.setAttribute(goog.dom.dataset.PREFIX_+goog.string.toSelectorCase(b),c);else throw Error(goog.DEBUG?'"'+b+'" is not a valid dataset property name.':"");};
goog.dom.dataset.get=function(a,b){if(!goog.dom.dataset.isValidProperty_(b))return null;if(goog.dom.dataset.ALLOWED_&&a.dataset){if(goog.labs.userAgent.browser.isAndroidBrowser()&&!(b in a.dataset))return null;a=a.dataset[b];return void 0===a?null:a}return a.getAttribute(goog.dom.dataset.PREFIX_+goog.string.toSelectorCase(b))};
goog.dom.dataset.remove=function(a,b){goog.dom.dataset.isValidProperty_(b)&&(goog.dom.dataset.ALLOWED_&&a.dataset?goog.dom.dataset.has(a,b)&&delete a.dataset[b]:a.removeAttribute(goog.dom.dataset.PREFIX_+goog.string.toSelectorCase(b)))};
goog.dom.dataset.has=function(a,b){return goog.dom.dataset.isValidProperty_(b)?goog.dom.dataset.ALLOWED_&&a.dataset?b in a.dataset:a.hasAttribute?a.hasAttribute(goog.dom.dataset.PREFIX_+goog.string.toSelectorCase(b)):!!a.getAttribute(goog.dom.dataset.PREFIX_+goog.string.toSelectorCase(b)):!1};
goog.dom.dataset.getAll=function(a){if(goog.dom.dataset.ALLOWED_&&a.dataset)return a.dataset;var b={};a=a.attributes;for(var c=0;c<a.length;++c){var d=a[c];if(goog.string.startsWith(d.name,goog.dom.dataset.PREFIX_)){var e=goog.string.toCamelCase(d.name.substr(5));b[e]=d.value}}return b};goog.ui.PaletteRenderer=function(){goog.ui.ControlRenderer.call(this)};goog.inherits(goog.ui.PaletteRenderer,goog.ui.ControlRenderer);goog.addSingletonGetter(goog.ui.PaletteRenderer);goog.ui.PaletteRenderer.cellId_=0;goog.ui.PaletteRenderer.CSS_CLASS="goog-palette";goog.ui.PaletteRenderer.GRID_WIDTH_ATTRIBUTE="gridWidth";
goog.ui.PaletteRenderer.prototype.createDom=function(a){var b=this.getClassNames(a);b=a.getDomHelper().createDom("DIV",b,this.createGrid(a.getContent(),a.getSize(),a.getDomHelper()));goog.a11y.aria.setRole(b,goog.a11y.aria.Role.GRID);goog.dom.dataset.set(b,goog.ui.PaletteRenderer.GRID_WIDTH_ATTRIBUTE,a.getSize().width);return b};
goog.ui.PaletteRenderer.prototype.createGrid=function(a,b,c){for(var d=[],e=0,f=0;e<b.height;e++){for(var g=[],h=0;h<b.width;h++){var k=a&&a[f++];g.push(this.createCell(k,c))}d.push(this.createRow(g,c))}return this.createTable(d,c)};goog.ui.PaletteRenderer.prototype.createTable=function(a,b){a=b.createDom("TABLE",this.getCssClass()+"-table",b.createDom("TBODY",this.getCssClass()+"-body",a));a.cellSpacing="0";a.cellPadding="0";return a};
goog.ui.PaletteRenderer.prototype.createRow=function(a,b){a=b.createDom("TR",this.getCssClass()+"-row",a);goog.a11y.aria.setRole(a,goog.a11y.aria.Role.ROW);return a};goog.ui.PaletteRenderer.prototype.createCell=function(a,b){a=b.createDom("TD",{"class":this.getCssClass()+"-cell",id:this.getCssClass()+"-cell-"+goog.ui.PaletteRenderer.cellId_++},a);goog.a11y.aria.setRole(a,goog.a11y.aria.Role.GRIDCELL);goog.a11y.aria.setState(a,goog.a11y.aria.State.SELECTED,!1);this.maybeUpdateAriaLabel_(a);return a};
goog.ui.PaletteRenderer.prototype.maybeUpdateAriaLabel_=function(a){if(!goog.dom.getTextContent(a)&&!goog.a11y.aria.getLabel(a)){for(var b=new goog.dom.NodeIterator(a),c="",d;!c&&(d=goog.iter.nextOrValue(b,null));)d.nodeType==goog.dom.NodeType.ELEMENT&&(c=goog.a11y.aria.getLabel(d)||d.title);c&&goog.a11y.aria.setLabel(a,c)}};goog.ui.PaletteRenderer.prototype.canDecorate=function(a){return!1};goog.ui.PaletteRenderer.prototype.decorate=function(a,b){return null};
goog.ui.PaletteRenderer.prototype.setContent=function(a,b){if(a){var c=goog.dom.getElementsByTagNameAndClass("TBODY",this.getCssClass()+"-body",a)[0];if(c){var d=0;goog.array.forEach(c.rows,function(a){goog.array.forEach(a.cells,function(a){goog.dom.removeChildren(a);goog.a11y.aria.removeState(a,goog.a11y.aria.State.LABEL);if(b){var c=b[d++];c&&(goog.dom.appendChild(a,c),this.maybeUpdateAriaLabel_(a))}},this)},this);if(d<b.length){for(var e=[],f=goog.dom.getDomHelper(a),g=goog.dom.dataset.get(a,goog.ui.PaletteRenderer.GRID_WIDTH_ATTRIBUTE);d<
b.length;){var h=b[d++];e.push(this.createCell(h,f));e.length==g&&(h=this.createRow(e,f),goog.dom.appendChild(c,h),e.length=0)}if(0<e.length){for(;e.length<g;)e.push(this.createCell("",f));h=this.createRow(e,f);goog.dom.appendChild(c,h)}}}goog.style.setUnselectable(a,!0,goog.userAgent.GECKO)}};
goog.ui.PaletteRenderer.prototype.getContainingItem=function(a,b){for(a=a.getElement();b&&b.nodeType==goog.dom.NodeType.ELEMENT&&b!=a;){if("TD"==b.tagName&&goog.dom.classlist.contains(b,this.getCssClass()+"-cell"))return b.firstChild;b=b.parentNode}return null};
goog.ui.PaletteRenderer.prototype.highlightCell=function(a,b,c){b&&(b=this.getCellForItem(b),goog.asserts.assert(b),goog.dom.classlist.enable(b,this.getCssClass()+"-cell-hover",c),c?goog.a11y.aria.setState(a.getElementStrict(),goog.a11y.aria.State.ACTIVEDESCENDANT,b.id):b.id==goog.a11y.aria.getState(a.getElementStrict(),goog.a11y.aria.State.ACTIVEDESCENDANT)&&goog.a11y.aria.removeState(a.getElementStrict(),goog.a11y.aria.State.ACTIVEDESCENDANT))};
goog.ui.PaletteRenderer.prototype.getCellForItem=function(a){return a?a.parentNode:null};goog.ui.PaletteRenderer.prototype.selectCell=function(a,b,c){b&&(a=b.parentNode,goog.dom.classlist.enable(a,this.getCssClass()+"-cell-selected",c),goog.a11y.aria.setState(a,goog.a11y.aria.State.SELECTED,c))};goog.ui.PaletteRenderer.prototype.getCssClass=function(){return goog.ui.PaletteRenderer.CSS_CLASS};goog.ui.SelectionModel=function(a){goog.events.EventTarget.call(this);this.items_=[];this.addItems(a)};goog.inherits(goog.ui.SelectionModel,goog.events.EventTarget);goog.tagUnsealableClass(goog.ui.SelectionModel);goog.ui.SelectionModel.prototype.selectedItem_=null;goog.ui.SelectionModel.prototype.selectionHandler_=null;goog.ui.SelectionModel.prototype.getSelectionHandler=function(){return this.selectionHandler_};
goog.ui.SelectionModel.prototype.setSelectionHandler=function(a){this.selectionHandler_=a};goog.ui.SelectionModel.prototype.getItemCount=function(){return this.items_.length};goog.ui.SelectionModel.prototype.indexOfItem=function(a){return a?goog.array.indexOf(this.items_,a):-1};goog.ui.SelectionModel.prototype.getFirst=function(){return this.items_[0]};goog.ui.SelectionModel.prototype.getLast=function(){return this.items_[this.items_.length-1]};
goog.ui.SelectionModel.prototype.getItemAt=function(a){return this.items_[a]||null};goog.ui.SelectionModel.prototype.addItems=function(a){a&&(goog.array.forEach(a,function(a){this.selectItem_(a,!1)},this),goog.array.extend(this.items_,a))};goog.ui.SelectionModel.prototype.addItem=function(a){this.addItemAt(a,this.getItemCount())};goog.ui.SelectionModel.prototype.addItemAt=function(a,b){a&&(this.selectItem_(a,!1),goog.array.insertAt(this.items_,a,b))};
goog.ui.SelectionModel.prototype.removeItem=function(a){a&&goog.array.remove(this.items_,a)&&a==this.selectedItem_&&(this.selectedItem_=null,this.dispatchEvent(goog.events.EventType.SELECT))};goog.ui.SelectionModel.prototype.removeItemAt=function(a){this.removeItem(this.getItemAt(a))};goog.ui.SelectionModel.prototype.getSelectedItem=function(){return this.selectedItem_};goog.ui.SelectionModel.prototype.getItems=function(){return goog.array.clone(this.items_)};
goog.ui.SelectionModel.prototype.setSelectedItem=function(a){a!=this.selectedItem_&&(this.selectItem_(this.selectedItem_,!1),this.selectedItem_=a,this.selectItem_(a,!0));this.dispatchEvent(goog.events.EventType.SELECT)};goog.ui.SelectionModel.prototype.getSelectedIndex=function(){return this.indexOfItem(this.selectedItem_)};goog.ui.SelectionModel.prototype.setSelectedIndex=function(a){this.setSelectedItem(this.getItemAt(a))};
goog.ui.SelectionModel.prototype.clear=function(){goog.array.clear(this.items_);this.selectedItem_=null};goog.ui.SelectionModel.prototype.disposeInternal=function(){goog.ui.SelectionModel.superClass_.disposeInternal.call(this);delete this.items_;this.selectedItem_=null};goog.ui.SelectionModel.prototype.selectItem_=function(a,b){a&&("function"==typeof this.selectionHandler_?this.selectionHandler_(a,b):"function"==typeof a.setSelected&&a.setSelected(b))};goog.ui.Palette=function(a,b,c){goog.ui.Control.call(this,a,b||goog.ui.PaletteRenderer.getInstance(),c);this.setAutoStates(goog.ui.Component.State.CHECKED|goog.ui.Component.State.SELECTED|goog.ui.Component.State.OPENED,!1);this.currentCellControl_=new goog.ui.Palette.CurrentCell_;this.currentCellControl_.setParentEventTarget(this);this.lastHighlightedIndex_=-1};goog.inherits(goog.ui.Palette,goog.ui.Control);goog.tagUnsealableClass(goog.ui.Palette);goog.ui.Palette.EventType={AFTER_HIGHLIGHT:goog.events.getUniqueId("afterhighlight")};
goog.ui.Palette.prototype.size_=null;goog.ui.Palette.prototype.highlightedIndex_=-1;goog.ui.Palette.prototype.selectionModel_=null;goog.ui.Palette.prototype.disposeInternal=function(){goog.ui.Palette.superClass_.disposeInternal.call(this);this.selectionModel_&&(this.selectionModel_.dispose(),this.selectionModel_=null);this.size_=null;this.currentCellControl_.dispose()};
goog.ui.Palette.prototype.setContentInternal=function(a){goog.ui.Palette.superClass_.setContentInternal.call(this,a);this.adjustSize_();this.selectionModel_?(this.selectionModel_.clear(),this.selectionModel_.addItems(a)):(this.selectionModel_=new goog.ui.SelectionModel(a),this.selectionModel_.setSelectionHandler(goog.bind(this.selectItem_,this)),this.getHandler().listen(this.selectionModel_,goog.events.EventType.SELECT,this.handleSelectionChange));this.highlightedIndex_=-1};
goog.ui.Palette.prototype.getCaption=function(){return""};goog.ui.Palette.prototype.setCaption=function(a){};goog.ui.Palette.prototype.handleMouseOver=function(a){goog.ui.Palette.superClass_.handleMouseOver.call(this,a);var b=this.getRenderer().getContainingItem(this,a.target);b&&a.relatedTarget&&goog.dom.contains(b,a.relatedTarget)||b!=this.getHighlightedItem()&&this.setHighlightedItem(b)};
goog.ui.Palette.prototype.handleMouseDown=function(a){goog.ui.Palette.superClass_.handleMouseDown.call(this,a);this.isActive()&&(a=this.getRenderer().getContainingItem(this,a.target),a!=this.getHighlightedItem()&&this.setHighlightedItem(a))};goog.ui.Palette.prototype.performActionInternal=function(a){var b=this.getHighlightedItem();return b?(a&&this.shouldSelectHighlightedItem_(a)&&this.setSelectedItem(b),goog.ui.Palette.superClass_.performActionInternal.call(this,a)):!1};
goog.ui.Palette.prototype.shouldSelectHighlightedItem_=function(a){return this.getSelectedItem()?"mouseup"!=a.type?!0:!!this.getRenderer().getContainingItem(this,a.target):!0};
goog.ui.Palette.prototype.handleKeyEvent=function(a){var b=this.getContent();b=b?b.length:0;var c=this.size_.width;if(0==b||!this.isEnabled())return!1;if(a.keyCode==goog.events.KeyCodes.ENTER||a.keyCode==goog.events.KeyCodes.SPACE)return this.performActionInternal(a);if(a.keyCode==goog.events.KeyCodes.HOME)return this.setHighlightedIndex(0),!0;if(a.keyCode==goog.events.KeyCodes.END)return this.setHighlightedIndex(b-1),!0;var d=0>this.highlightedIndex_?this.getSelectedIndex():this.highlightedIndex_;
switch(a.keyCode){case goog.events.KeyCodes.LEFT:if(-1==d||0==d)d=b;this.setHighlightedIndex(d-1);a.preventDefault();return!0;case goog.events.KeyCodes.RIGHT:return d==b-1&&(d=-1),this.setHighlightedIndex(d+1),a.preventDefault(),!0;case goog.events.KeyCodes.UP:-1==d&&(d=b+c-1);if(d>=c)return this.setHighlightedIndex(d-c),a.preventDefault(),!0;break;case goog.events.KeyCodes.DOWN:if(-1==d&&(d=-c),d<b-c)return this.setHighlightedIndex(d+c),a.preventDefault(),!0}return!1};
goog.ui.Palette.prototype.handleSelectionChange=function(a){};goog.ui.Palette.prototype.getSize=function(){return this.size_};goog.ui.Palette.prototype.setSize=function(a,b){if(this.getElement())throw Error(goog.ui.Component.Error.ALREADY_RENDERED);this.size_=goog.isNumber(a)?new goog.math.Size(a,b):a;this.adjustSize_()};goog.ui.Palette.prototype.getHighlightedIndex=function(){return this.highlightedIndex_};
goog.ui.Palette.prototype.getHighlightedItem=function(){var a=this.getContent();return a&&a[this.highlightedIndex_]};goog.ui.Palette.prototype.getHighlightedCellElement_=function(){return this.getRenderer().getCellForItem(this.getHighlightedItem())};goog.ui.Palette.prototype.setHighlightedIndex=function(a){a!=this.highlightedIndex_&&(this.highlightIndex_(this.highlightedIndex_,!1),this.lastHighlightedIndex_=this.highlightedIndex_,this.highlightedIndex_=a,this.highlightIndex_(a,!0),this.dispatchEvent(goog.ui.Palette.EventType.AFTER_HIGHLIGHT))};
goog.ui.Palette.prototype.setHighlightedItem=function(a){var b=this.getContent();this.setHighlightedIndex(b&&a?goog.array.indexOf(b,a):-1)};goog.ui.Palette.prototype.getSelectedIndex=function(){return this.selectionModel_?this.selectionModel_.getSelectedIndex():-1};goog.ui.Palette.prototype.getSelectedItem=function(){return this.selectionModel_?this.selectionModel_.getSelectedItem():null};goog.ui.Palette.prototype.setSelectedIndex=function(a){this.selectionModel_&&this.selectionModel_.setSelectedIndex(a)};
goog.ui.Palette.prototype.setSelectedItem=function(a){this.selectionModel_&&this.selectionModel_.setSelectedItem(a)};goog.ui.Palette.prototype.highlightIndex_=function(a,b){if(this.getElement()){var c=this.getContent();if(c&&0<=a&&a<c.length){var d=this.getHighlightedCellElement_();this.currentCellControl_.getElement()!=d&&this.currentCellControl_.setElementInternal(d);this.currentCellControl_.tryHighlight(b)&&this.getRenderer().highlightCell(this,c[a],b)}}};
goog.ui.Palette.prototype.setHighlighted=function(a){a&&-1==this.highlightedIndex_?this.setHighlightedIndex(-1<this.lastHighlightedIndex_?this.lastHighlightedIndex_:0):a||this.setHighlightedIndex(-1);goog.ui.Palette.superClass_.setHighlighted.call(this,a)};goog.ui.Palette.prototype.selectItem_=function(a,b){this.getElement()&&this.getRenderer().selectCell(this,a,b)};
goog.ui.Palette.prototype.adjustSize_=function(){var a=this.getContent();if(a)if(this.size_&&this.size_.width){if(a=Math.ceil(a.length/this.size_.width),!goog.isNumber(this.size_.height)||this.size_.height<a)this.size_.height=a}else a=Math.ceil(Math.sqrt(a.length)),this.size_=new goog.math.Size(a,a);else this.size_=new goog.math.Size(0,0)};goog.ui.Palette.CurrentCell_=function(){goog.ui.Control.call(this,null);this.setDispatchTransitionEvents(goog.ui.Component.State.HOVER,!0)};
goog.inherits(goog.ui.Palette.CurrentCell_,goog.ui.Control);goog.ui.Palette.CurrentCell_.prototype.tryHighlight=function(a){this.setHighlighted(a);return this.isHighlighted()==a};goog.ui.ColorPalette=function(a,b,c){this.colors_=a||[];goog.ui.Palette.call(this,null,b||goog.ui.PaletteRenderer.getInstance(),c);this.setColors(this.colors_)};goog.inherits(goog.ui.ColorPalette,goog.ui.Palette);goog.tagUnsealableClass(goog.ui.ColorPalette);goog.ui.ColorPalette.prototype.normalizedColors_=null;goog.ui.ColorPalette.prototype.labels_=null;goog.ui.ColorPalette.prototype.getColors=function(){return this.colors_};
goog.ui.ColorPalette.prototype.setColors=function(a,b){this.colors_=a;this.labels_=b||null;this.normalizedColors_=null;this.setContent(this.createColorNodes())};goog.ui.ColorPalette.prototype.getSelectedColor=function(){var a=this.getSelectedItem();return a?(a=goog.style.getStyle(a,"background-color"),goog.ui.ColorPalette.parseColor_(a)):null};
goog.ui.ColorPalette.prototype.setSelectedColor=function(a){a=goog.ui.ColorPalette.parseColor_(a);this.normalizedColors_||(this.normalizedColors_=goog.array.map(this.colors_,function(a){return goog.ui.ColorPalette.parseColor_(a)}));this.setSelectedIndex(a?goog.array.indexOf(this.normalizedColors_,a):-1)};
goog.ui.ColorPalette.prototype.createColorNodes=function(){return goog.array.map(this.colors_,function(a,b){var c=this.getDomHelper().createDom("DIV",{"class":this.getRenderer().getCssClass()+"-colorswatch",style:"background-color:"+a});c.title=this.labels_&&this.labels_[b]?this.labels_[b]:"#"==a.charAt(0)?"RGB ("+goog.color.hexToRgb(a).join(", ")+")":a;return c},this)};goog.ui.ColorPalette.parseColor_=function(a){if(a)try{return goog.color.parse(a).hex}catch(b){}return null};goog.ui.ColorPicker=function(a,b){goog.ui.Component.call(this,a);this.colorPalette_=b||null;this.getHandler().listen(this,goog.ui.Component.EventType.ACTION,this.onColorPaletteAction_)};goog.inherits(goog.ui.ColorPicker,goog.ui.Component);goog.ui.ColorPicker.DEFAULT_NUM_COLS=5;goog.ui.ColorPicker.EventType={CHANGE:"change"};goog.ui.ColorPicker.prototype.focusable_=!0;goog.ui.ColorPicker.prototype.getColors=function(){return this.colorPalette_?this.colorPalette_.getColors():null};
goog.ui.ColorPicker.prototype.setColors=function(a){this.colorPalette_?this.colorPalette_.setColors(a):this.createColorPalette_(a)};goog.ui.ColorPicker.prototype.addColors=function(a){this.setColors(a)};goog.ui.ColorPicker.prototype.setSize=function(a){this.colorPalette_||this.createColorPalette_([]);this.colorPalette_.setSize(a)};goog.ui.ColorPicker.prototype.getSize=function(){return this.colorPalette_?this.colorPalette_.getSize():null};goog.ui.ColorPicker.prototype.setColumnCount=function(a){this.setSize(a)};
goog.ui.ColorPicker.prototype.getSelectedIndex=function(){return this.colorPalette_?this.colorPalette_.getSelectedIndex():-1};goog.ui.ColorPicker.prototype.setSelectedIndex=function(a){this.colorPalette_&&this.colorPalette_.setSelectedIndex(a)};goog.ui.ColorPicker.prototype.getSelectedColor=function(){return this.colorPalette_?this.colorPalette_.getSelectedColor():null};goog.ui.ColorPicker.prototype.setSelectedColor=function(a){this.colorPalette_&&this.colorPalette_.setSelectedColor(a)};
goog.ui.ColorPicker.prototype.isFocusable=function(){return this.focusable_};goog.ui.ColorPicker.prototype.setFocusable=function(a){this.focusable_=a;this.colorPalette_&&this.colorPalette_.setSupportedState(goog.ui.Component.State.FOCUSED,a)};goog.ui.ColorPicker.prototype.canDecorate=function(a){return!1};
goog.ui.ColorPicker.prototype.enterDocument=function(){goog.ui.ColorPicker.superClass_.enterDocument.call(this);this.colorPalette_&&this.colorPalette_.render(this.getElement());this.getElement().unselectable="on"};goog.ui.ColorPicker.prototype.disposeInternal=function(){goog.ui.ColorPicker.superClass_.disposeInternal.call(this);this.colorPalette_&&(this.colorPalette_.dispose(),this.colorPalette_=null)};goog.ui.ColorPicker.prototype.focus=function(){this.colorPalette_&&this.colorPalette_.getElement().focus()};
goog.ui.ColorPicker.prototype.onColorPaletteAction_=function(a){a.stopPropagation();this.dispatchEvent(goog.ui.ColorPicker.EventType.CHANGE)};goog.ui.ColorPicker.prototype.createColorPalette_=function(a){a=new goog.ui.ColorPalette(a,null,this.getDomHelper());a.setSize(goog.ui.ColorPicker.DEFAULT_NUM_COLS);a.setSupportedState(goog.ui.Component.State.FOCUSED,this.focusable_);this.addChild(a);this.colorPalette_=a;this.isInDocument()&&this.colorPalette_.render(this.getElement())};
goog.ui.ColorPicker.createSimpleColorGrid=function(a){a=new goog.ui.ColorPicker(a);a.setSize(7);a.setColors(goog.ui.ColorPicker.SIMPLE_GRID_COLORS);return a};goog.ui.ColorPicker.SIMPLE_GRID_COLORS="#ffffff #cccccc #c0c0c0 #999999 #666666 #333333 #000000 #ffcccc #ff6666 #ff0000 #cc0000 #990000 #660000 #330000 #ffcc99 #ff9966 #ff9900 #ff6600 #cc6600 #993300 #663300 #ffff99 #ffff66 #ffcc66 #ffcc33 #cc9933 #996633 #663333 #ffffcc #ffff33 #ffff00 #ffcc00 #999900 #666600 #333300 #99ff99 #66ff99 #33ff33 #33cc00 #009900 #006600 #003300 #99ffff #33ffff #66cccc #00cccc #339999 #336666 #003333 #ccffff #66ffff #33ccff #3366ff #3333ff #000099 #000066 #ccccff #9999ff #6666cc #6633ff #6600cc #333399 #330099 #ffccff #ff99ff #cc66cc #cc33cc #993399 #663366 #330033".split(" ");Blockly.FieldColour=function(a,b){Blockly.FieldColour.superClass_.constructor.call(this,a,b);this.addArgType("colour")};goog.inherits(Blockly.FieldColour,Blockly.Field);Blockly.FieldColour.fromJson=function(a){return new Blockly.FieldColour(a.colour)};Blockly.FieldColour.prototype.colours_=null;Blockly.FieldColour.prototype.columns_=0;Blockly.FieldColour.prototype.init=function(a){this.fieldGroup_||(Blockly.FieldColour.superClass_.init.call(this,a),this.setValue(this.getValue()))};
Blockly.FieldColour.prototype.CURSOR="default";Blockly.FieldColour.prototype.dispose=function(){Blockly.WidgetDiv.hideIfOwner(this);Blockly.FieldColour.superClass_.dispose.call(this)};Blockly.FieldColour.prototype.getValue=function(){return this.colour_};
Blockly.FieldColour.prototype.setValue=function(a){this.sourceBlock_&&Blockly.Events.isEnabled()&&this.colour_!=a&&Blockly.Events.fire(new Blockly.Events.BlockChange(this.sourceBlock_,"field",this.name,this.colour_,a));this.colour_=a;this.sourceBlock_&&this.sourceBlock_.setColour(a,a,a)};Blockly.FieldColour.prototype.getText=function(){var a=this.colour_,b=a.match(/^#(.)\1(.)\2(.)\3$/);b&&(a="#"+b[1]+b[2]+b[3]);return a};
Blockly.FieldColour.prototype.getSize=function(){return new goog.math.Size(Blockly.BlockSvg.FIELD_WIDTH,Blockly.BlockSvg.FIELD_HEIGHT)};Blockly.FieldColour.COLOURS=goog.ui.ColorPicker.SIMPLE_GRID_COLORS;Blockly.FieldColour.COLUMNS=7;Blockly.FieldColour.prototype.setColours=function(a){this.colours_=a;return this};Blockly.FieldColour.prototype.setColumns=function(a){this.columns_=a;return this};
Blockly.FieldColour.prototype.showEditor_=function(){Blockly.WidgetDiv.show(this,this.sourceBlock_.RTL,Blockly.FieldColour.widgetDispose_);var a=Blockly.utils.getViewportBBox(),b=this.getScaledBBox_(),c=this.createWidget_(),d=goog.style.getSize(c.getElement());Blockly.WidgetDiv.positionWithAnchor(a,b,d,this.sourceBlock_.RTL);var e=this;Blockly.FieldColour.changeEventKey_=goog.events.listen(c,goog.ui.ColorPicker.EventType.CHANGE,function(a){a=a.target.getSelectedColor()||"#000000";Blockly.WidgetDiv.hide();
e.sourceBlock_&&(a=e.callValidator(a));null!==a&&e.setValue(a)})};Blockly.FieldColour.prototype.createWidget_=function(){var a=new goog.ui.ColorPicker;a.setSize(this.columns_||Blockly.FieldColour.COLUMNS);a.setColors(this.colours_||Blockly.FieldColour.COLOURS);a.render(Blockly.WidgetDiv.DIV);a.setSelectedColor(this.getValue());return a};Blockly.FieldColour.widgetDispose_=function(){Blockly.FieldColour.changeEventKey_&&goog.events.unlistenByKey(Blockly.FieldColour.changeEventKey_);Blockly.Events.setGroup(!1)};
Blockly.Field.register("field_colour",Blockly.FieldColour);goog.events.MouseWheelHandler=function(a,b){goog.events.EventTarget.call(this);this.element_=a;a=goog.dom.isElement(this.element_)?this.element_:this.element_?this.element_.body:null;this.isRtl_=!!a&&goog.style.isRightToLeft(a);this.listenKey_=goog.events.listen(this.element_,goog.userAgent.GECKO?"DOMMouseScroll":"mousewheel",this,b)};goog.inherits(goog.events.MouseWheelHandler,goog.events.EventTarget);goog.events.MouseWheelHandler.EventType={MOUSEWHEEL:"mousewheel"};
goog.events.MouseWheelHandler.prototype.setMaxDeltaX=function(a){this.maxDeltaX_=a};goog.events.MouseWheelHandler.prototype.setMaxDeltaY=function(a){this.maxDeltaY_=a};
goog.events.MouseWheelHandler.prototype.handleEvent=function(a){var b=0,c=0,d=a.getBrowserEvent();"mousewheel"==d.type?(a=goog.events.MouseWheelHandler.smartScale_(-d.wheelDelta,40),goog.isDef(d.wheelDeltaX)?(b=goog.events.MouseWheelHandler.smartScale_(-d.wheelDeltaX,40),c=goog.events.MouseWheelHandler.smartScale_(-d.wheelDeltaY,40)):c=a):(a=d.detail,100<a?a=3:-100>a&&(a=-3),goog.isDef(d.axis)&&d.axis===d.HORIZONTAL_AXIS?b=a:c=a);goog.isNumber(this.maxDeltaX_)&&(b=goog.math.clamp(b,-this.maxDeltaX_,
this.maxDeltaX_));goog.isNumber(this.maxDeltaY_)&&(c=goog.math.clamp(c,-this.maxDeltaY_,this.maxDeltaY_));this.isRtl_&&(b=-b);b=new goog.events.MouseWheelEvent(a,d,b,c);this.dispatchEvent(b)};goog.events.MouseWheelHandler.smartScale_=function(a,b){return goog.userAgent.WEBKIT&&(goog.userAgent.MAC||goog.userAgent.LINUX)&&0!=a%b?a:a/b};
goog.events.MouseWheelHandler.prototype.disposeInternal=function(){goog.events.MouseWheelHandler.superClass_.disposeInternal.call(this);goog.events.unlistenByKey(this.listenKey_);this.listenKey_=null};goog.events.MouseWheelEvent=function(a,b,c,d){goog.events.BrowserEvent.call(this,b);this.type=goog.events.MouseWheelHandler.EventType.MOUSEWHEEL;this.detail=a;this.deltaX=c;this.deltaY=d};goog.inherits(goog.events.MouseWheelEvent,goog.events.BrowserEvent);goog.fx={};goog.fx.Transition=function(){};goog.fx.Transition.EventType={PLAY:"play",BEGIN:"begin",RESUME:"resume",END:"end",STOP:"stop",FINISH:"finish",PAUSE:"pause"};goog.fx.TransitionBase=function(){goog.events.EventTarget.call(this);this.state_=goog.fx.TransitionBase.State.STOPPED;this.endTime=this.startTime=null};goog.inherits(goog.fx.TransitionBase,goog.events.EventTarget);goog.fx.TransitionBase.State={STOPPED:0,PAUSED:-1,PLAYING:1};goog.fx.TransitionBase.prototype.play=goog.abstractMethod;goog.fx.TransitionBase.prototype.stop=goog.abstractMethod;goog.fx.TransitionBase.prototype.pause=goog.abstractMethod;goog.fx.TransitionBase.prototype.getStateInternal=function(){return this.state_};
goog.fx.TransitionBase.prototype.setStatePlaying=function(){this.state_=goog.fx.TransitionBase.State.PLAYING};goog.fx.TransitionBase.prototype.setStatePaused=function(){this.state_=goog.fx.TransitionBase.State.PAUSED};goog.fx.TransitionBase.prototype.setStateStopped=function(){this.state_=goog.fx.TransitionBase.State.STOPPED};goog.fx.TransitionBase.prototype.isPlaying=function(){return this.state_==goog.fx.TransitionBase.State.PLAYING};
goog.fx.TransitionBase.prototype.isPaused=function(){return this.state_==goog.fx.TransitionBase.State.PAUSED};goog.fx.TransitionBase.prototype.isStopped=function(){return this.state_==goog.fx.TransitionBase.State.STOPPED};goog.fx.TransitionBase.prototype.onBegin=function(){this.dispatchAnimationEvent(goog.fx.Transition.EventType.BEGIN)};goog.fx.TransitionBase.prototype.onEnd=function(){this.dispatchAnimationEvent(goog.fx.Transition.EventType.END)};goog.fx.TransitionBase.prototype.onFinish=function(){this.dispatchAnimationEvent(goog.fx.Transition.EventType.FINISH)};
goog.fx.TransitionBase.prototype.onPause=function(){this.dispatchAnimationEvent(goog.fx.Transition.EventType.PAUSE)};goog.fx.TransitionBase.prototype.onPlay=function(){this.dispatchAnimationEvent(goog.fx.Transition.EventType.PLAY)};goog.fx.TransitionBase.prototype.onResume=function(){this.dispatchAnimationEvent(goog.fx.Transition.EventType.RESUME)};goog.fx.TransitionBase.prototype.onStop=function(){this.dispatchAnimationEvent(goog.fx.Transition.EventType.STOP)};
goog.fx.TransitionBase.prototype.dispatchAnimationEvent=function(a){this.dispatchEvent(a)};goog.async.AnimationDelay=function(a,b,c){goog.Disposable.call(this);this.id_=null;this.usingListeners_=!1;this.listener_=a;this.handler_=c;this.win_=b||window;this.callback_=goog.bind(this.doAction_,this)};goog.inherits(goog.async.AnimationDelay,goog.Disposable);goog.async.AnimationDelay.TIMEOUT=20;goog.async.AnimationDelay.MOZ_BEFORE_PAINT_EVENT_="MozBeforePaint";
goog.async.AnimationDelay.prototype.start=function(){this.stop();this.usingListeners_=!1;var a=this.getRaf_(),b=this.getCancelRaf_();a&&!b&&this.win_.mozRequestAnimationFrame?(this.id_=goog.events.listen(this.win_,goog.async.AnimationDelay.MOZ_BEFORE_PAINT_EVENT_,this.callback_),this.win_.mozRequestAnimationFrame(null),this.usingListeners_=!0):this.id_=a&&b?a.call(this.win_,this.callback_):this.win_.setTimeout(goog.functions.lock(this.callback_),goog.async.AnimationDelay.TIMEOUT)};
goog.async.AnimationDelay.prototype.startIfNotActive=function(){this.isActive()||this.start()};goog.async.AnimationDelay.prototype.stop=function(){if(this.isActive()){var a=this.getRaf_(),b=this.getCancelRaf_();a&&!b&&this.win_.mozRequestAnimationFrame?goog.events.unlistenByKey(this.id_):a&&b?b.call(this.win_,this.id_):this.win_.clearTimeout(this.id_)}this.id_=null};goog.async.AnimationDelay.prototype.fire=function(){this.stop();this.doAction_()};
goog.async.AnimationDelay.prototype.fireIfActive=function(){this.isActive()&&this.fire()};goog.async.AnimationDelay.prototype.isActive=function(){return null!=this.id_};goog.async.AnimationDelay.prototype.doAction_=function(){this.usingListeners_&&this.id_&&goog.events.unlistenByKey(this.id_);this.id_=null;this.listener_.call(this.handler_,goog.now())};goog.async.AnimationDelay.prototype.disposeInternal=function(){this.stop();goog.async.AnimationDelay.superClass_.disposeInternal.call(this)};
goog.async.AnimationDelay.prototype.getRaf_=function(){var a=this.win_;return a.requestAnimationFrame||a.webkitRequestAnimationFrame||a.mozRequestAnimationFrame||a.oRequestAnimationFrame||a.msRequestAnimationFrame||null};goog.async.AnimationDelay.prototype.getCancelRaf_=function(){var a=this.win_;return a.cancelAnimationFrame||a.cancelRequestAnimationFrame||a.webkitCancelRequestAnimationFrame||a.mozCancelRequestAnimationFrame||a.oCancelRequestAnimationFrame||a.msCancelRequestAnimationFrame||null};goog.async.Delay=function(a,b,c){goog.Disposable.call(this);this.listener_=a;this.interval_=b||0;this.handler_=c;this.callback_=goog.bind(this.doAction_,this)};goog.inherits(goog.async.Delay,goog.Disposable);goog.Delay=goog.async.Delay;goog.async.Delay.prototype.id_=0;goog.async.Delay.prototype.disposeInternal=function(){goog.async.Delay.superClass_.disposeInternal.call(this);this.stop();delete this.listener_;delete this.handler_};
goog.async.Delay.prototype.start=function(a){this.stop();this.id_=goog.Timer.callOnce(this.callback_,goog.isDef(a)?a:this.interval_)};goog.async.Delay.prototype.startIfNotActive=function(a){this.isActive()||this.start(a)};goog.async.Delay.prototype.stop=function(){this.isActive()&&goog.Timer.clear(this.id_);this.id_=0};goog.async.Delay.prototype.fire=function(){this.stop();this.doAction_()};goog.async.Delay.prototype.fireIfActive=function(){this.isActive()&&this.fire()};
goog.async.Delay.prototype.isActive=function(){return 0!=this.id_};goog.async.Delay.prototype.doAction_=function(){this.id_=0;this.listener_&&this.listener_.call(this.handler_)};goog.fx.anim={};goog.fx.anim.Animated=function(){};goog.fx.anim.TIMEOUT=goog.async.AnimationDelay.TIMEOUT;goog.fx.anim.activeAnimations_={};goog.fx.anim.animationWindow_=null;goog.fx.anim.animationDelay_=null;goog.fx.anim.registerAnimation=function(a){var b=goog.getUid(a);b in goog.fx.anim.activeAnimations_||(goog.fx.anim.activeAnimations_[b]=a);goog.fx.anim.requestAnimationFrame_()};
goog.fx.anim.unregisterAnimation=function(a){a=goog.getUid(a);delete goog.fx.anim.activeAnimations_[a];goog.object.isEmpty(goog.fx.anim.activeAnimations_)&&goog.fx.anim.cancelAnimationFrame_()};goog.fx.anim.tearDown=function(){goog.fx.anim.animationWindow_=null;goog.dispose(goog.fx.anim.animationDelay_);goog.fx.anim.animationDelay_=null;goog.fx.anim.activeAnimations_={}};
goog.fx.anim.setAnimationWindow=function(a){var b=goog.fx.anim.animationDelay_&&goog.fx.anim.animationDelay_.isActive();goog.dispose(goog.fx.anim.animationDelay_);goog.fx.anim.animationDelay_=null;goog.fx.anim.animationWindow_=a;b&&goog.fx.anim.requestAnimationFrame_()};
goog.fx.anim.requestAnimationFrame_=function(){goog.fx.anim.animationDelay_||(goog.fx.anim.animationDelay_=goog.fx.anim.animationWindow_?new goog.async.AnimationDelay(function(a){goog.fx.anim.cycleAnimations_(a)},goog.fx.anim.animationWindow_):new goog.async.Delay(function(){goog.fx.anim.cycleAnimations_(goog.now())},goog.fx.anim.TIMEOUT));var a=goog.fx.anim.animationDelay_;a.isActive()||a.start()};goog.fx.anim.cancelAnimationFrame_=function(){goog.fx.anim.animationDelay_&&goog.fx.anim.animationDelay_.stop()};
goog.fx.anim.cycleAnimations_=function(a){goog.object.forEach(goog.fx.anim.activeAnimations_,function(b){b.onAnimationFrame(a)});goog.object.isEmpty(goog.fx.anim.activeAnimations_)||goog.fx.anim.requestAnimationFrame_()};goog.fx.Animation=function(a,b,c,d){goog.fx.TransitionBase.call(this);if(!goog.isArray(a)||!goog.isArray(b))throw Error("Start and end parameters must be arrays");if(a.length!=b.length)throw Error("Start and end points must be the same length");this.startPoint=a;this.endPoint=b;this.duration=c;this.accel_=d;this.coords=[];this.useRightPositioningForRtl_=!1;this.progress=this.fps_=0;this.lastFrame=null};goog.inherits(goog.fx.Animation,goog.fx.TransitionBase);
goog.fx.Animation.prototype.getDuration=function(){return this.duration};goog.fx.Animation.prototype.enableRightPositioningForRtl=function(a){this.useRightPositioningForRtl_=a};goog.fx.Animation.prototype.isRightPositioningForRtlEnabled=function(){return this.useRightPositioningForRtl_};
goog.fx.Animation.EventType={PLAY:goog.fx.Transition.EventType.PLAY,BEGIN:goog.fx.Transition.EventType.BEGIN,RESUME:goog.fx.Transition.EventType.RESUME,END:goog.fx.Transition.EventType.END,STOP:goog.fx.Transition.EventType.STOP,FINISH:goog.fx.Transition.EventType.FINISH,PAUSE:goog.fx.Transition.EventType.PAUSE,ANIMATE:"animate",DESTROY:"destroy"};goog.fx.Animation.TIMEOUT=goog.fx.anim.TIMEOUT;goog.fx.Animation.State=goog.fx.TransitionBase.State;goog.fx.Animation.setAnimationWindow=function(a){goog.fx.anim.setAnimationWindow(a)};
goog.fx.Animation.prototype.play=function(a){if(a||this.isStopped())this.progress=0,this.coords=this.startPoint;else if(this.isPlaying())return!1;goog.fx.anim.unregisterAnimation(this);this.startTime=a=goog.now();this.isPaused()&&(this.startTime-=this.duration*this.progress);this.endTime=this.startTime+this.duration;this.lastFrame=this.startTime;if(!this.progress)this.onBegin();this.onPlay();if(this.isPaused())this.onResume();this.setStatePlaying();goog.fx.anim.registerAnimation(this);this.cycle(a);
return!0};goog.fx.Animation.prototype.stop=function(a){goog.fx.anim.unregisterAnimation(this);this.setStateStopped();a&&(this.progress=1);this.updateCoords_(this.progress);this.onStop();this.onEnd()};goog.fx.Animation.prototype.pause=function(){this.isPlaying()&&(goog.fx.anim.unregisterAnimation(this),this.setStatePaused(),this.onPause())};goog.fx.Animation.prototype.getProgress=function(){return this.progress};
goog.fx.Animation.prototype.setProgress=function(a){this.progress=a;this.isPlaying()&&(this.startTime=goog.now()-this.duration*this.progress,this.endTime=this.startTime+this.duration)};goog.fx.Animation.prototype.disposeInternal=function(){this.isStopped()||this.stop(!1);this.onDestroy();goog.fx.Animation.superClass_.disposeInternal.call(this)};goog.fx.Animation.prototype.destroy=function(){this.dispose()};goog.fx.Animation.prototype.onAnimationFrame=function(a){this.cycle(a)};
goog.fx.Animation.prototype.cycle=function(a){goog.asserts.assertNumber(this.startTime);goog.asserts.assertNumber(this.endTime);goog.asserts.assertNumber(this.lastFrame);a<this.startTime&&(this.endTime=a+this.endTime-this.startTime,this.startTime=a);this.progress=(a-this.startTime)/(this.endTime-this.startTime);1<this.progress&&(this.progress=1);this.fps_=1E3/(a-this.lastFrame);this.lastFrame=a;this.updateCoords_(this.progress);if(1==this.progress)this.setStateStopped(),goog.fx.anim.unregisterAnimation(this),
this.onFinish(),this.onEnd();else if(this.isPlaying())this.onAnimate()};goog.fx.Animation.prototype.updateCoords_=function(a){goog.isFunction(this.accel_)&&(a=this.accel_(a));this.coords=Array(this.startPoint.length);for(var b=0;b<this.startPoint.length;b++)this.coords[b]=(this.endPoint[b]-this.startPoint[b])*a+this.startPoint[b]};goog.fx.Animation.prototype.onAnimate=function(){this.dispatchAnimationEvent(goog.fx.Animation.EventType.ANIMATE)};goog.fx.Animation.prototype.onDestroy=function(){this.dispatchAnimationEvent(goog.fx.Animation.EventType.DESTROY)};
goog.fx.Animation.prototype.dispatchAnimationEvent=function(a){this.dispatchEvent(new goog.fx.AnimationEvent(a,this))};goog.fx.AnimationEvent=function(a,b){goog.events.Event.call(this,a);this.coords=b.coords;this.x=b.coords[0];this.y=b.coords[1];this.z=b.coords[2];this.duration=b.duration;this.progress=b.getProgress();this.fps=b.fps_;this.state=b.getStateInternal();this.anim=b};goog.inherits(goog.fx.AnimationEvent,goog.events.Event);
goog.fx.AnimationEvent.prototype.coordsAsInts=function(){return goog.array.map(this.coords,Math.round)};goog.fx.AnimationQueue=function(){goog.fx.TransitionBase.call(this);this.queue=[]};goog.inherits(goog.fx.AnimationQueue,goog.fx.TransitionBase);goog.fx.AnimationQueue.prototype.add=function(a){goog.asserts.assert(this.isStopped(),"Not allowed to add animations to a running animation queue.");goog.array.contains(this.queue,a)||(this.queue.push(a),goog.events.listen(a,goog.fx.Transition.EventType.FINISH,this.onAnimationFinish,!1,this))};
goog.fx.AnimationQueue.prototype.remove=function(a){goog.asserts.assert(this.isStopped(),"Not allowed to remove animations from a running animation queue.");goog.array.remove(this.queue,a)&&goog.events.unlisten(a,goog.fx.Transition.EventType.FINISH,this.onAnimationFinish,!1,this)};goog.fx.AnimationQueue.prototype.onAnimationFinish=goog.abstractMethod;goog.fx.AnimationQueue.prototype.disposeInternal=function(){goog.array.forEach(this.queue,function(a){a.dispose()});this.queue.length=0;goog.fx.AnimationQueue.superClass_.disposeInternal.call(this)};
goog.fx.AnimationParallelQueue=function(){goog.fx.AnimationQueue.call(this);this.finishedCounter_=0};goog.inherits(goog.fx.AnimationParallelQueue,goog.fx.AnimationQueue);
goog.fx.AnimationParallelQueue.prototype.play=function(a){if(0==this.queue.length)return!1;if(a||this.isStopped())this.finishedCounter_=0,this.onBegin();else if(this.isPlaying())return!1;this.onPlay();if(this.isPaused())this.onResume();var b=this.isPaused()&&!a;this.startTime=goog.now();this.endTime=null;this.setStatePlaying();goog.array.forEach(this.queue,function(c){b&&!c.isPaused()||c.play(a)});return!0};
goog.fx.AnimationParallelQueue.prototype.pause=function(){this.isPlaying()&&(goog.array.forEach(this.queue,function(a){a.isPlaying()&&a.pause()}),this.setStatePaused(),this.onPause())};goog.fx.AnimationParallelQueue.prototype.stop=function(a){goog.array.forEach(this.queue,function(b){b.isStopped()||b.stop(a)});this.setStateStopped();this.endTime=goog.now();this.onStop();this.onEnd()};
goog.fx.AnimationParallelQueue.prototype.onAnimationFinish=function(a){this.finishedCounter_++;this.finishedCounter_==this.queue.length&&(this.endTime=goog.now(),this.setStateStopped(),this.onFinish(),this.onEnd())};goog.fx.AnimationSerialQueue=function(){goog.fx.AnimationQueue.call(this);this.current_=0};goog.inherits(goog.fx.AnimationSerialQueue,goog.fx.AnimationQueue);
goog.fx.AnimationSerialQueue.prototype.play=function(a){if(0==this.queue.length)return!1;if(a||this.isStopped())this.current_<this.queue.length&&!this.queue[this.current_].isStopped()&&this.queue[this.current_].stop(!1),this.current_=0,this.onBegin();else if(this.isPlaying())return!1;this.onPlay();if(this.isPaused())this.onResume();this.startTime=goog.now();this.endTime=null;this.setStatePlaying();this.queue[this.current_].play(a);return!0};
goog.fx.AnimationSerialQueue.prototype.pause=function(){this.isPlaying()&&(this.queue[this.current_].pause(),this.setStatePaused(),this.onPause())};goog.fx.AnimationSerialQueue.prototype.stop=function(a){this.setStateStopped();this.endTime=goog.now();if(a)for(a=this.current_;a<this.queue.length;++a){var b=this.queue[a];b.isStopped()&&b.play();b.isStopped()||b.stop(!0)}else this.current_<this.queue.length&&this.queue[this.current_].stop(!1);this.onStop();this.onEnd()};
goog.fx.AnimationSerialQueue.prototype.onAnimationFinish=function(a){this.isPlaying()&&(this.current_++,this.current_<this.queue.length?this.queue[this.current_].play():(this.endTime=goog.now(),this.setStateStopped(),this.onFinish(),this.onEnd()))};goog.userAgent.platform={};
goog.userAgent.platform.determineVersion_=function(){if(goog.userAgent.WINDOWS){var a=/Windows NT ([0-9.]+)/;return(a=a.exec(goog.userAgent.getUserAgentString()))?a[1]:"0"}return goog.userAgent.MAC?(a=/10[_.][0-9_.]+/,(a=a.exec(goog.userAgent.getUserAgentString()))?a[0].replace(/_/g,"."):"10"):goog.userAgent.ANDROID?(a=/Android\s+([^\);]+)(\)|;)/,(a=a.exec(goog.userAgent.getUserAgentString()))?a[1]:""):goog.userAgent.IPHONE||goog.userAgent.IPAD||goog.userAgent.IPOD?(a=/(?:iPhone|CPU)\s+OS\s+(\S+)/,(a=
a.exec(goog.userAgent.getUserAgentString()))?a[1].replace(/_/g,"."):""):""};goog.userAgent.platform.VERSION=goog.userAgent.platform.determineVersion_();goog.userAgent.platform.isVersion=function(a){return 0<=goog.string.compareVersions(goog.userAgent.platform.VERSION,a)};goog.userAgent.product.determineVersion_=function(){if(goog.userAgent.product.FIREFOX)return goog.userAgent.product.getFirstRegExpGroup_(/Firefox\/([0-9.]+)/);if(goog.userAgent.product.IE||goog.userAgent.product.EDGE||goog.userAgent.product.OPERA)return goog.userAgent.VERSION;if(goog.userAgent.product.CHROME)return goog.labs.userAgent.platform.isIos()?goog.userAgent.product.getFirstRegExpGroup_(/CriOS\/([0-9.]+)/):goog.userAgent.product.getFirstRegExpGroup_(/Chrome\/([0-9.]+)/);if(goog.userAgent.product.SAFARI&&
!goog.labs.userAgent.platform.isIos())return goog.userAgent.product.getFirstRegExpGroup_(/Version\/([0-9.]+)/);if(goog.userAgent.product.IPHONE||goog.userAgent.product.IPAD){var a=goog.userAgent.product.execRegExp_(/Version\/(\S+).*Mobile\/(\S+)/);if(a)return a[1]+"."+a[2]}else if(goog.userAgent.product.ANDROID)return(a=goog.userAgent.product.getFirstRegExpGroup_(/Android\s+([0-9.]+)/))?a:goog.userAgent.product.getFirstRegExpGroup_(/Version\/([0-9.]+)/);return""};
goog.userAgent.product.getFirstRegExpGroup_=function(a){return(a=goog.userAgent.product.execRegExp_(a))?a[1]:""};goog.userAgent.product.execRegExp_=function(a){return a.exec(goog.userAgent.getUserAgentString())};goog.userAgent.product.VERSION=goog.userAgent.product.determineVersion_();goog.userAgent.product.isVersion=function(a){return 0<=goog.string.compareVersions(goog.userAgent.product.VERSION,a)};goog.style.bidi={};goog.style.bidi.getScrollLeft=function(a){var b=goog.style.isRightToLeft(a);return b&&goog.style.bidi.usesNegativeScrollLeftInRtl_()?-a.scrollLeft:!b||goog.userAgent.EDGE_OR_IE&&goog.userAgent.isVersionOrHigher("8")||"visible"==goog.style.getComputedOverflowX(a)?a.scrollLeft:a.scrollWidth-a.clientWidth-a.scrollLeft};
goog.style.bidi.getOffsetStart=function(a){var b=a.offsetLeft,c=a.offsetParent;c||"fixed"!=goog.style.getComputedPosition(a)||(c=goog.dom.getOwnerDocument(a).documentElement);if(!c)return b;if(goog.userAgent.GECKO&&!goog.userAgent.isVersionOrHigher(58)){var d=goog.style.getBorderBox(c);b+=d.left}else goog.userAgent.isDocumentModeOrHigher(8)&&!goog.userAgent.isDocumentModeOrHigher(9)&&(d=goog.style.getBorderBox(c),b-=d.left);return goog.style.isRightToLeft(c)?c.clientWidth-(b+a.offsetWidth):b};
goog.style.bidi.setScrollOffset=function(a,b){b=Math.max(b,0);goog.style.isRightToLeft(a)?goog.style.bidi.usesNegativeScrollLeftInRtl_()?a.scrollLeft=-b:goog.userAgent.EDGE_OR_IE&&goog.userAgent.isVersionOrHigher("8")?a.scrollLeft=b:a.scrollLeft=a.scrollWidth-b-a.clientWidth:a.scrollLeft=b};
goog.style.bidi.usesNegativeScrollLeftInRtl_=function(){var a=goog.userAgent.product.SAFARI&&goog.userAgent.product.isVersion(10),b=goog.userAgent.IOS&&goog.userAgent.platform.isVersion(10);return goog.userAgent.GECKO||a||b};goog.style.bidi.setPosition=function(a,b,c,d){goog.isNull(c)||(a.style.top=c+"px");d?(a.style.right=b+"px",a.style.left=""):(a.style.left=b+"px",a.style.right="")};goog.fx.Dragger=function(a,b,c){goog.events.EventTarget.call(this);this.target=a;this.handle=b||a;this.limits=c||new goog.math.Rect(NaN,NaN,NaN,NaN);this.document_=goog.dom.getOwnerDocument(a);this.eventHandler_=new goog.events.EventHandler(this);this.registerDisposable(this.eventHandler_);this.deltaY=this.deltaX=this.startY=this.startX=this.screenY=this.screenX=this.clientY=this.clientX=0;this.enabled_=!0;this.dragging_=!1;this.preventMouseDown_=!0;this.hysteresisDistanceSquared_=0;this.useRightPositioningForRtl_=
this.ieDragStartCancellingOn_=!1;goog.events.listen(this.handle,[goog.events.EventType.TOUCHSTART,goog.events.EventType.MOUSEDOWN],this.startDrag,!1,this);this.useSetCapture_=goog.fx.Dragger.HAS_SET_CAPTURE_};goog.inherits(goog.fx.Dragger,goog.events.EventTarget);goog.tagUnsealableClass(goog.fx.Dragger);goog.fx.Dragger.HAS_SET_CAPTURE_=goog.global.document&&goog.global.document.documentElement&&!!goog.global.document.documentElement.setCapture&&!!goog.global.document.releaseCapture;
goog.fx.Dragger.cloneNode=function(a){for(var b=a.cloneNode(!0),c=goog.dom.getElementsByTagName("TEXTAREA",a),d=goog.dom.getElementsByTagName("TEXTAREA",b),e=0;e<c.length;e++)d[e].value=c[e].value;switch(a.tagName){case "TR":return goog.dom.createDom("TABLE",null,goog.dom.createDom("TBODY",null,b));case "TD":case "TH":return goog.dom.createDom("TABLE",null,goog.dom.createDom("TBODY",null,goog.dom.createDom("TR",null,b)));case "TEXTAREA":b.value=a.value;default:return b}};
goog.fx.Dragger.EventType={EARLY_CANCEL:"earlycancel",START:"start",BEFOREDRAG:"beforedrag",DRAG:"drag",END:"end"};goog.fx.Dragger.prototype.setAllowSetCapture=function(a){this.useSetCapture_=a&&goog.fx.Dragger.HAS_SET_CAPTURE_};goog.fx.Dragger.prototype.enableRightPositioningForRtl=function(a){this.useRightPositioningForRtl_=a};goog.fx.Dragger.prototype.getHandler=function(){return this.eventHandler_};goog.fx.Dragger.prototype.setLimits=function(a){this.limits=a||new goog.math.Rect(NaN,NaN,NaN,NaN)};
goog.fx.Dragger.prototype.setHysteresis=function(a){this.hysteresisDistanceSquared_=Math.pow(a,2)};goog.fx.Dragger.prototype.getHysteresis=function(){return Math.sqrt(this.hysteresisDistanceSquared_)};goog.fx.Dragger.prototype.setScrollTarget=function(a){this.scrollTarget_=a};goog.fx.Dragger.prototype.setCancelIeDragStart=function(a){this.ieDragStartCancellingOn_=a};goog.fx.Dragger.prototype.getEnabled=function(){return this.enabled_};
goog.fx.Dragger.prototype.setEnabled=function(a){this.enabled_=a};goog.fx.Dragger.prototype.setPreventMouseDown=function(a){this.preventMouseDown_=a};goog.fx.Dragger.prototype.disposeInternal=function(){goog.fx.Dragger.superClass_.disposeInternal.call(this);goog.events.unlisten(this.handle,[goog.events.EventType.TOUCHSTART,goog.events.EventType.MOUSEDOWN],this.startDrag,!1,this);this.cleanUpAfterDragging_();this.handle=this.target=null};
goog.fx.Dragger.prototype.isRightToLeft_=function(){goog.isDef(this.rightToLeft_)||(this.rightToLeft_=goog.style.isRightToLeft(this.target));return this.rightToLeft_};
goog.fx.Dragger.prototype.startDrag=function(a){var b=a.type==goog.events.EventType.MOUSEDOWN;if(!this.enabled_||this.dragging_||b&&!a.isMouseActionButton())this.dispatchEvent(goog.fx.Dragger.EventType.EARLY_CANCEL);else{if(0==this.hysteresisDistanceSquared_)if(this.fireDragStart_(a))this.dragging_=!0,this.preventMouseDown_&&b&&a.preventDefault();else return;else this.preventMouseDown_&&b&&a.preventDefault();this.setupDragHandlers();this.clientX=this.startX=a.clientX;this.clientY=this.startY=a.clientY;
this.screenX=a.screenX;this.screenY=a.screenY;this.computeInitialPosition();this.pageScroll=goog.dom.getDomHelper(this.document_).getDocumentScroll()}};
goog.fx.Dragger.prototype.setupDragHandlers=function(){var a=this.document_,b=a.documentElement,c=!this.useSetCapture_;this.eventHandler_.listen(a,[goog.events.EventType.TOUCHMOVE,goog.events.EventType.MOUSEMOVE],this.handleMove_,{capture:c,passive:!1});this.eventHandler_.listen(a,[goog.events.EventType.TOUCHEND,goog.events.EventType.MOUSEUP],this.endDrag,c);this.useSetCapture_?(b.setCapture(!1),this.eventHandler_.listen(b,goog.events.EventType.LOSECAPTURE,this.endDrag)):this.eventHandler_.listen(goog.dom.getWindow(a),
goog.events.EventType.BLUR,this.endDrag);goog.userAgent.IE&&this.ieDragStartCancellingOn_&&this.eventHandler_.listen(a,goog.events.EventType.DRAGSTART,goog.events.Event.preventDefault);this.scrollTarget_&&this.eventHandler_.listen(this.scrollTarget_,goog.events.EventType.SCROLL,this.onScroll_,c)};goog.fx.Dragger.prototype.fireDragStart_=function(a){return this.dispatchEvent(new goog.fx.DragEvent(goog.fx.Dragger.EventType.START,this,a.clientX,a.clientY,a))};
goog.fx.Dragger.prototype.cleanUpAfterDragging_=function(){this.eventHandler_.removeAll();this.useSetCapture_&&this.document_.releaseCapture()};goog.fx.Dragger.prototype.endDrag=function(a,b){this.cleanUpAfterDragging_();if(this.dragging_){this.dragging_=!1;var c=this.limitX(this.deltaX),d=this.limitY(this.deltaY);this.dispatchEvent(new goog.fx.DragEvent(goog.fx.Dragger.EventType.END,this,a.clientX,a.clientY,a,c,d,b||a.type==goog.events.EventType.TOUCHCANCEL))}else this.dispatchEvent(goog.fx.Dragger.EventType.EARLY_CANCEL)};
goog.fx.Dragger.prototype.endDragCancel=function(a){this.endDrag(a,!0)};
goog.fx.Dragger.prototype.handleMove_=function(a){if(this.enabled_){var b=(this.useRightPositioningForRtl_&&this.isRightToLeft_()?-1:1)*(a.clientX-this.clientX),c=a.clientY-this.clientY;this.clientX=a.clientX;this.clientY=a.clientY;this.screenX=a.screenX;this.screenY=a.screenY;if(!this.dragging_){var d=this.startX-this.clientX,e=this.startY-this.clientY;if(d*d+e*e>this.hysteresisDistanceSquared_)if(this.fireDragStart_(a))this.dragging_=!0;else{this.isDisposed()||this.endDrag(a);return}}c=this.calculatePosition_(b,
c);b=c.x;c=c.y;this.dragging_&&this.dispatchEvent(new goog.fx.DragEvent(goog.fx.Dragger.EventType.BEFOREDRAG,this,a.clientX,a.clientY,a,b,c))&&(this.doDrag(a,b,c,!1),a.preventDefault())}};goog.fx.Dragger.prototype.calculatePosition_=function(a,b){var c=goog.dom.getDomHelper(this.document_).getDocumentScroll();a+=c.x-this.pageScroll.x;b+=c.y-this.pageScroll.y;this.pageScroll=c;this.deltaX+=a;this.deltaY+=b;a=this.limitX(this.deltaX);b=this.limitY(this.deltaY);return new goog.math.Coordinate(a,b)};
goog.fx.Dragger.prototype.onScroll_=function(a){var b=this.calculatePosition_(0,0);a.clientX=this.clientX;a.clientY=this.clientY;this.doDrag(a,b.x,b.y,!0)};goog.fx.Dragger.prototype.doDrag=function(a,b,c,d){this.defaultAction(b,c);this.dispatchEvent(new goog.fx.DragEvent(goog.fx.Dragger.EventType.DRAG,this,a.clientX,a.clientY,a,b,c))};
goog.fx.Dragger.prototype.limitX=function(a){var b=this.limits,c=isNaN(b.left)?null:b.left;b=isNaN(b.width)?0:b.width;return Math.min(null!=c?c+b:Infinity,Math.max(null!=c?c:-Infinity,a))};goog.fx.Dragger.prototype.limitY=function(a){var b=this.limits,c=isNaN(b.top)?null:b.top;b=isNaN(b.height)?0:b.height;return Math.min(null!=c?c+b:Infinity,Math.max(null!=c?c:-Infinity,a))};
goog.fx.Dragger.prototype.computeInitialPosition=function(){this.deltaX=this.useRightPositioningForRtl_?goog.style.bidi.getOffsetStart(this.target):this.target.offsetLeft;this.deltaY=this.target.offsetTop};goog.fx.Dragger.prototype.defaultAction=function(a,b){this.useRightPositioningForRtl_&&this.isRightToLeft_()?this.target.style.right=a+"px":this.target.style.left=a+"px";this.target.style.top=b+"px"};goog.fx.Dragger.prototype.isDragging=function(){return this.dragging_};
goog.fx.DragEvent=function(a,b,c,d,e,f,g,h){goog.events.Event.call(this,a);this.clientX=c;this.clientY=d;this.browserEvent=e;this.left=goog.isDef(f)?f:b.deltaX;this.top=goog.isDef(g)?g:b.deltaY;this.dragger=b;this.dragCanceled=!!h};goog.inherits(goog.fx.DragEvent,goog.events.Event);goog.fx.dom={};goog.fx.dom.PredefinedEffect=function(a,b,c,d,e){goog.fx.Animation.call(this,b,c,d,e);this.element=a};goog.inherits(goog.fx.dom.PredefinedEffect,goog.fx.Animation);goog.fx.dom.PredefinedEffect.prototype.updateStyle=goog.nullFunction;goog.fx.dom.PredefinedEffect.prototype.isRightToLeft=function(){goog.isDef(this.rightToLeft_)||(this.rightToLeft_=goog.style.isRightToLeft(this.element));return this.rightToLeft_};
goog.fx.dom.PredefinedEffect.prototype.onAnimate=function(){this.updateStyle();goog.fx.dom.PredefinedEffect.superClass_.onAnimate.call(this)};goog.fx.dom.PredefinedEffect.prototype.onEnd=function(){this.updateStyle();goog.fx.dom.PredefinedEffect.superClass_.onEnd.call(this)};goog.fx.dom.PredefinedEffect.prototype.onBegin=function(){this.updateStyle();goog.fx.dom.PredefinedEffect.superClass_.onBegin.call(this)};
goog.fx.dom.Slide=function(a,b,c,d,e){if(2!=b.length||2!=c.length)throw Error("Start and end points must be 2D");goog.fx.dom.PredefinedEffect.call(this,a,b,c,d,e)};goog.inherits(goog.fx.dom.Slide,goog.fx.dom.PredefinedEffect);goog.fx.dom.Slide.prototype.updateStyle=function(){var a=this.isRightPositioningForRtlEnabled()&&this.isRightToLeft()?"right":"left";this.element.style[a]=Math.round(this.coords[0])+"px";this.element.style.top=Math.round(this.coords[1])+"px"};
goog.fx.dom.SlideFrom=function(a,b,c,d){var e=[this.isRightPositioningForRtlEnabled()?goog.style.bidi.getOffsetStart(a):a.offsetLeft,a.offsetTop];goog.fx.dom.Slide.call(this,a,e,b,c,d)};goog.inherits(goog.fx.dom.SlideFrom,goog.fx.dom.Slide);goog.fx.dom.SlideFrom.prototype.onBegin=function(){this.startPoint=[this.isRightPositioningForRtlEnabled()?goog.style.bidi.getOffsetStart(this.element):this.element.offsetLeft,this.element.offsetTop];goog.fx.dom.SlideFrom.superClass_.onBegin.call(this)};
goog.fx.dom.Swipe=function(a,b,c,d,e){if(2!=b.length||2!=c.length)throw Error("Start and end points must be 2D");goog.fx.dom.PredefinedEffect.call(this,a,b,c,d,e);this.maxWidth_=Math.max(this.endPoint[0],this.startPoint[0]);this.maxHeight_=Math.max(this.endPoint[1],this.startPoint[1])};goog.inherits(goog.fx.dom.Swipe,goog.fx.dom.PredefinedEffect);
goog.fx.dom.Swipe.prototype.updateStyle=function(){var a=this.coords[0],b=this.coords[1];this.clip_(Math.round(a),Math.round(b),this.maxWidth_,this.maxHeight_);this.element.style.width=Math.round(a)+"px";var c=this.isRightPositioningForRtlEnabled()&&this.isRightToLeft()?"marginRight":"marginLeft";this.element.style[c]=Math.round(a)-this.maxWidth_+"px";this.element.style.marginTop=Math.round(b)-this.maxHeight_+"px"};
goog.fx.dom.Swipe.prototype.clip_=function(a,b,c,d){this.element.style.clip="rect("+(d-b)+"px "+c+"px "+d+"px "+(c-a)+"px)"};goog.fx.dom.Scroll=function(a,b,c,d,e){if(2!=b.length||2!=c.length)throw Error("Start and end points must be 2D");goog.fx.dom.PredefinedEffect.call(this,a,b,c,d,e)};goog.inherits(goog.fx.dom.Scroll,goog.fx.dom.PredefinedEffect);
goog.fx.dom.Scroll.prototype.updateStyle=function(){this.isRightPositioningForRtlEnabled()?goog.style.bidi.setScrollOffset(this.element,Math.round(this.coords[0])):this.element.scrollLeft=Math.round(this.coords[0]);this.element.scrollTop=Math.round(this.coords[1])};goog.fx.dom.Resize=function(a,b,c,d,e){if(2!=b.length||2!=c.length)throw Error("Start and end points must be 2D");goog.fx.dom.PredefinedEffect.call(this,a,b,c,d,e)};goog.inherits(goog.fx.dom.Resize,goog.fx.dom.PredefinedEffect);
goog.fx.dom.Resize.prototype.updateStyle=function(){this.element.style.width=Math.round(this.coords[0])+"px";this.element.style.height=Math.round(this.coords[1])+"px"};goog.fx.dom.ResizeWidth=function(a,b,c,d,e){goog.fx.dom.PredefinedEffect.call(this,a,[b],[c],d,e)};goog.inherits(goog.fx.dom.ResizeWidth,goog.fx.dom.PredefinedEffect);goog.fx.dom.ResizeWidth.prototype.updateStyle=function(){this.element.style.width=Math.round(this.coords[0])+"px"};
goog.fx.dom.ResizeHeight=function(a,b,c,d,e){goog.fx.dom.PredefinedEffect.call(this,a,[b],[c],d,e)};goog.inherits(goog.fx.dom.ResizeHeight,goog.fx.dom.PredefinedEffect);goog.fx.dom.ResizeHeight.prototype.updateStyle=function(){this.element.style.height=Math.round(this.coords[0])+"px"};
goog.fx.dom.Fade=function(a,b,c,d,e){goog.isNumber(b)&&(b=[b]);goog.isNumber(c)&&(c=[c]);goog.fx.dom.PredefinedEffect.call(this,a,b,c,d,e);if(1!=b.length||1!=c.length)throw Error("Start and end points must be 1D");this.lastOpacityUpdate_=goog.fx.dom.Fade.OPACITY_UNSET_};goog.inherits(goog.fx.dom.Fade,goog.fx.dom.PredefinedEffect);goog.fx.dom.Fade.TOLERANCE_=1/1024;goog.fx.dom.Fade.OPACITY_UNSET_=-1;
goog.fx.dom.Fade.prototype.updateStyle=function(){var a=this.coords[0];Math.abs(a-this.lastOpacityUpdate_)>=goog.fx.dom.Fade.TOLERANCE_&&(goog.style.setOpacity(this.element,a),this.lastOpacityUpdate_=a)};goog.fx.dom.Fade.prototype.onBegin=function(){this.lastOpacityUpdate_=goog.fx.dom.Fade.OPACITY_UNSET_;goog.fx.dom.Fade.superClass_.onBegin.call(this)};goog.fx.dom.Fade.prototype.onEnd=function(){this.lastOpacityUpdate_=goog.fx.dom.Fade.OPACITY_UNSET_;goog.fx.dom.Fade.superClass_.onEnd.call(this)};
goog.fx.dom.Fade.prototype.show=function(){this.element.style.display=""};goog.fx.dom.Fade.prototype.hide=function(){this.element.style.display="none"};goog.fx.dom.FadeOut=function(a,b,c){goog.fx.dom.Fade.call(this,a,1,0,b,c)};goog.inherits(goog.fx.dom.FadeOut,goog.fx.dom.Fade);goog.fx.dom.FadeIn=function(a,b,c){goog.fx.dom.Fade.call(this,a,0,1,b,c)};goog.inherits(goog.fx.dom.FadeIn,goog.fx.dom.Fade);goog.fx.dom.FadeOutAndHide=function(a,b,c){goog.fx.dom.Fade.call(this,a,1,0,b,c)};
goog.inherits(goog.fx.dom.FadeOutAndHide,goog.fx.dom.Fade);goog.fx.dom.FadeOutAndHide.prototype.onBegin=function(){this.show();goog.fx.dom.FadeOutAndHide.superClass_.onBegin.call(this)};goog.fx.dom.FadeOutAndHide.prototype.onEnd=function(){this.hide();goog.fx.dom.FadeOutAndHide.superClass_.onEnd.call(this)};goog.fx.dom.FadeInAndShow=function(a,b,c){goog.fx.dom.Fade.call(this,a,0,1,b,c)};goog.inherits(goog.fx.dom.FadeInAndShow,goog.fx.dom.Fade);
goog.fx.dom.FadeInAndShow.prototype.onBegin=function(){this.show();goog.fx.dom.FadeInAndShow.superClass_.onBegin.call(this)};goog.fx.dom.BgColorTransform=function(a,b,c,d,e){if(3!=b.length||3!=c.length)throw Error("Start and end points must be 3D");goog.fx.dom.PredefinedEffect.call(this,a,b,c,d,e)};goog.inherits(goog.fx.dom.BgColorTransform,goog.fx.dom.PredefinedEffect);
goog.fx.dom.BgColorTransform.prototype.setColor=function(){for(var a=[],b=0;b<this.coords.length;b++)a[b]=Math.round(this.coords[b]);a="rgb("+a.join(",")+")";this.element.style.backgroundColor=a};goog.fx.dom.BgColorTransform.prototype.updateStyle=function(){this.setColor()};
goog.fx.dom.bgColorFadeIn=function(a,b,c,d){function e(){a.style.backgroundColor=f}var f=a.style.backgroundColor||"",g=goog.style.getBackgroundColor(a);g=g&&"transparent"!=g&&"rgba(0, 0, 0, 0)"!=g?goog.color.hexToRgb(goog.color.parse(g).hex):[255,255,255];b=new goog.fx.dom.BgColorTransform(a,b,g,c);d?d.listen(b,goog.fx.Transition.EventType.END,e):goog.events.listen(b,goog.fx.Transition.EventType.END,e);b.play()};
goog.fx.dom.ColorTransform=function(a,b,c,d,e){if(3!=b.length||3!=c.length)throw Error("Start and end points must be 3D");goog.fx.dom.PredefinedEffect.call(this,a,b,c,d,e)};goog.inherits(goog.fx.dom.ColorTransform,goog.fx.dom.PredefinedEffect);goog.fx.dom.ColorTransform.prototype.updateStyle=function(){for(var a=[],b=0;b<this.coords.length;b++)a[b]=Math.round(this.coords[b]);a="rgb("+a.join(",")+")";this.element.style.color=a};goog.ui.RangeModel=function(){goog.events.EventTarget.call(this)};goog.inherits(goog.ui.RangeModel,goog.events.EventTarget);goog.tagUnsealableClass(goog.ui.RangeModel);goog.ui.RangeModel.prototype.value_=0;goog.ui.RangeModel.prototype.minimum_=0;goog.ui.RangeModel.prototype.maximum_=100;goog.ui.RangeModel.prototype.extent_=0;goog.ui.RangeModel.prototype.step_=1;goog.ui.RangeModel.prototype.isChanging_=!1;goog.ui.RangeModel.prototype.mute_=!1;
goog.ui.RangeModel.prototype.setMute=function(a){this.mute_=a};goog.ui.RangeModel.prototype.setValue=function(a){a=this.roundToStepWithMin(a);this.value_!=a&&(this.value_=a+this.extent_>this.maximum_?this.maximum_-this.extent_:a<this.minimum_?this.minimum_:a,this.isChanging_||this.mute_||this.dispatchEvent(goog.ui.Component.EventType.CHANGE))};goog.ui.RangeModel.prototype.getValue=function(){return this.roundToStepWithMin(this.value_)};
goog.ui.RangeModel.prototype.setExtent=function(a){a=this.roundToStepWithMin(a);this.extent_!=a&&(this.extent_=0>a?0:this.value_+a>this.maximum_?this.maximum_-this.value_:a,this.isChanging_||this.mute_||this.dispatchEvent(goog.ui.Component.EventType.CHANGE))};goog.ui.RangeModel.prototype.getExtent=function(){return this.roundToStep(this.extent_)};
goog.ui.RangeModel.prototype.setMinimum=function(a){if(this.minimum_!=a){var b=this.isChanging_;this.isChanging_=!0;this.minimum_=a;a+this.extent_>this.maximum_&&(this.extent_=this.maximum_-this.minimum_);a>this.value_&&this.setValue(a);a>this.maximum_&&(this.extent_=0,this.setMaximum(a),this.setValue(a));(this.isChanging_=b)||this.mute_||this.dispatchEvent(goog.ui.Component.EventType.CHANGE)}};goog.ui.RangeModel.prototype.getMinimum=function(){return this.roundToStepWithMin(this.minimum_)};
goog.ui.RangeModel.prototype.setMaximum=function(a){a=this.roundToStepWithMin(a);if(this.maximum_!=a){var b=this.isChanging_;this.isChanging_=!0;this.maximum_=a;a<this.value_+this.extent_&&this.setValue(a-this.extent_);a<this.minimum_&&(this.extent_=0,this.setMinimum(a),this.setValue(this.maximum_));a<this.minimum_+this.extent_&&(this.extent_=this.maximum_-this.minimum_);(this.isChanging_=b)||this.mute_||this.dispatchEvent(goog.ui.Component.EventType.CHANGE)}};
goog.ui.RangeModel.prototype.getMaximum=function(){return this.roundToStepWithMin(this.maximum_)};goog.ui.RangeModel.prototype.getStep=function(){return this.step_};goog.ui.RangeModel.prototype.setStep=function(a){this.step_!=a&&(this.step_=a,a=this.isChanging_,this.isChanging_=!0,this.setMaximum(this.getMaximum()),this.setExtent(this.getExtent()),this.setValue(this.getValue()),(this.isChanging_=a)||this.mute_||this.dispatchEvent(goog.ui.Component.EventType.CHANGE))};
goog.ui.RangeModel.prototype.roundToStepWithMin=function(a){return null==this.step_?a:this.minimum_+Math.round((a-this.minimum_)/this.step_)*this.step_};goog.ui.RangeModel.prototype.roundToStep=function(a){return null==this.step_?a:Math.round(a/this.step_)*this.step_};goog.ui.SliderBase=function(a,b){goog.ui.Component.call(this,a);this.additionalAnimations_=null;this.rangeModel=new goog.ui.RangeModel;this.labelFn_=b||goog.functions.NULL;this.focusElementOnSliderDrag_=!0;goog.events.listen(this.rangeModel,goog.ui.Component.EventType.CHANGE,this.handleRangeModelChange,!1,this)};goog.inherits(goog.ui.SliderBase,goog.ui.Component);goog.tagUnsealableClass(goog.ui.SliderBase);
goog.ui.SliderBase.EventType={DRAG_VALUE_START:goog.events.getUniqueId("dragvaluestart"),DRAG_VALUE_END:goog.events.getUniqueId("dragvalueend"),DRAG_EXTENT_START:goog.events.getUniqueId("dragextentstart"),DRAG_EXTENT_END:goog.events.getUniqueId("dragextentend"),DRAG_START:goog.events.getUniqueId("dragstart"),DRAG_END:goog.events.getUniqueId("dragend"),ANIMATION_END:goog.events.getUniqueId("animationend")};goog.ui.SliderBase.Orientation={VERTICAL:"vertical",HORIZONTAL:"horizontal"};
goog.ui.SliderBase.prototype.orientation_=goog.ui.SliderBase.Orientation.HORIZONTAL;goog.ui.SliderBase.MOUSE_DOWN_INCREMENT_INTERVAL_=200;goog.ui.SliderBase.ANIMATION_INTERVAL_=100;goog.ui.SliderBase.prototype.isAnimating_=!1;goog.ui.SliderBase.prototype.moveToPointEnabled_=!1;goog.ui.SliderBase.prototype.blockIncrement_=10;goog.ui.SliderBase.prototype.minExtent_=0;goog.ui.SliderBase.prototype.isHandleMouseWheel_=!0;goog.ui.SliderBase.prototype.mouseDownTime_=0;
goog.ui.SliderBase.prototype.MOUSE_DOWN_DELAY_=1E3;goog.ui.SliderBase.prototype.enabled_=!0;goog.ui.SliderBase.prototype.flipForRtl_=!1;goog.ui.SliderBase.prototype.enableFlipForRtl=function(a){this.flipForRtl_=a};goog.ui.SliderBase.prototype.getCssClass=goog.abstractMethod;goog.ui.SliderBase.prototype.createDom=function(){goog.ui.SliderBase.superClass_.createDom.call(this);var a=this.getDomHelper().createDom("DIV",this.getCssClass(this.orientation_));this.decorateInternal(a)};
goog.ui.SliderBase.prototype.createThumbs=goog.abstractMethod;goog.ui.SliderBase.SLIDER_DRAGGING_CSS_CLASS_="goog-slider-dragging";goog.ui.SliderBase.THUMB_DRAGGING_CSS_CLASS_="goog-slider-thumb-dragging";goog.ui.SliderBase.DISABLED_CSS_CLASS_="goog-slider-disabled";goog.ui.SliderBase.prototype.decorateInternal=function(a){goog.ui.SliderBase.superClass_.decorateInternal.call(this,a);goog.asserts.assert(a);goog.dom.classlist.add(a,this.getCssClass(this.orientation_));this.createThumbs();this.setAriaRoles()};
goog.ui.SliderBase.prototype.enterDocument=function(){goog.ui.SliderBase.superClass_.enterDocument.call(this);this.valueDragger_=new goog.fx.Dragger(this.valueThumb);this.extentDragger_=new goog.fx.Dragger(this.extentThumb);this.valueDragger_.enableRightPositioningForRtl(this.flipForRtl_);this.extentDragger_.enableRightPositioningForRtl(this.flipForRtl_);this.valueDragger_.defaultAction=this.extentDragger_.defaultAction=goog.nullFunction;this.keyHandler_=new goog.events.KeyHandler(this.getElement());
this.enableEventHandlers_(!0);this.getElement().tabIndex=0;this.updateUi_()};
goog.ui.SliderBase.prototype.enableEventHandlers_=function(a){a?(this.getHandler().listen(this.valueDragger_,goog.fx.Dragger.EventType.BEFOREDRAG,this.handleBeforeDrag_).listen(this.extentDragger_,goog.fx.Dragger.EventType.BEFOREDRAG,this.handleBeforeDrag_).listen(this.valueDragger_,[goog.fx.Dragger.EventType.START,goog.fx.Dragger.EventType.END],this.handleThumbDragStartEnd_).listen(this.extentDragger_,[goog.fx.Dragger.EventType.START,goog.fx.Dragger.EventType.END],this.handleThumbDragStartEnd_).listen(this.keyHandler_,
goog.events.KeyHandler.EventType.KEY,this.handleKeyDown_).listen(this.getElement(),goog.events.EventType.CLICK,this.handleMouseDownAndClick_).listen(this.getElement(),goog.events.EventType.MOUSEDOWN,this.handleMouseDownAndClick_),this.isHandleMouseWheel()&&this.enableMouseWheelHandling_(!0)):(this.getHandler().unlisten(this.valueDragger_,goog.fx.Dragger.EventType.BEFOREDRAG,this.handleBeforeDrag_).unlisten(this.extentDragger_,goog.fx.Dragger.EventType.BEFOREDRAG,this.handleBeforeDrag_).unlisten(this.valueDragger_,
[goog.fx.Dragger.EventType.START,goog.fx.Dragger.EventType.END],this.handleThumbDragStartEnd_).unlisten(this.extentDragger_,[goog.fx.Dragger.EventType.START,goog.fx.Dragger.EventType.END],this.handleThumbDragStartEnd_).unlisten(this.keyHandler_,goog.events.KeyHandler.EventType.KEY,this.handleKeyDown_).unlisten(this.getElement(),goog.events.EventType.CLICK,this.handleMouseDownAndClick_).unlisten(this.getElement(),goog.events.EventType.MOUSEDOWN,this.handleMouseDownAndClick_),this.isHandleMouseWheel()&&
this.enableMouseWheelHandling_(!1))};goog.ui.SliderBase.prototype.exitDocument=function(){goog.ui.SliderBase.superClass_.exitDocument.call(this);goog.disposeAll(this.valueDragger_,this.extentDragger_,this.keyHandler_,this.mouseWheelHandler_)};
goog.ui.SliderBase.prototype.handleBeforeDrag_=function(a){var b=a.dragger==this.valueDragger_?this.valueThumb:this.extentThumb;if(this.orientation_==goog.ui.SliderBase.Orientation.VERTICAL){var c=this.getElement().clientHeight-b.offsetHeight;c=(c-a.top)/c*(this.getMaximum()-this.getMinimum())+this.getMinimum()}else c=this.getElement().clientWidth-b.offsetWidth,c=a.left/c*(this.getMaximum()-this.getMinimum())+this.getMinimum();c=a.dragger==this.valueDragger_?Math.min(Math.max(c,this.getMinimum()),
this.getValue()+this.getExtent()):Math.min(Math.max(c,this.getValue()),this.getMaximum());this.setThumbPosition_(b,c)};
goog.ui.SliderBase.prototype.handleThumbDragStartEnd_=function(a){var b=a.type==goog.fx.Dragger.EventType.START;goog.dom.classlist.enable(goog.asserts.assertElement(this.getElement()),goog.ui.SliderBase.SLIDER_DRAGGING_CSS_CLASS_,b);goog.dom.classlist.enable(goog.asserts.assertElement(a.target.handle),goog.ui.SliderBase.THUMB_DRAGGING_CSS_CLASS_,b);a=a.dragger==this.valueDragger_;b?(this.dispatchEvent(goog.ui.SliderBase.EventType.DRAG_START),this.dispatchEvent(a?goog.ui.SliderBase.EventType.DRAG_VALUE_START:
goog.ui.SliderBase.EventType.DRAG_EXTENT_START)):(this.dispatchEvent(goog.ui.SliderBase.EventType.DRAG_END),this.dispatchEvent(a?goog.ui.SliderBase.EventType.DRAG_VALUE_END:goog.ui.SliderBase.EventType.DRAG_EXTENT_END))};
goog.ui.SliderBase.prototype.handleKeyDown_=function(a){var b=!0;switch(a.keyCode){case goog.events.KeyCodes.HOME:this.animatedSetValue(this.getMinimum());break;case goog.events.KeyCodes.END:this.animatedSetValue(this.getMaximum());break;case goog.events.KeyCodes.PAGE_UP:this.moveThumbs(this.getBlockIncrement());break;case goog.events.KeyCodes.PAGE_DOWN:this.moveThumbs(-this.getBlockIncrement());break;case goog.events.KeyCodes.LEFT:var c=this.flipForRtl_&&this.isRightToLeft()?1:-1;this.moveThumbs(a.shiftKey?
c*this.getBlockIncrement():c*this.getUnitIncrement());break;case goog.events.KeyCodes.DOWN:this.moveThumbs(a.shiftKey?-this.getBlockIncrement():-this.getUnitIncrement());break;case goog.events.KeyCodes.RIGHT:c=this.flipForRtl_&&this.isRightToLeft()?-1:1;this.moveThumbs(a.shiftKey?c*this.getBlockIncrement():c*this.getUnitIncrement());break;case goog.events.KeyCodes.UP:this.moveThumbs(a.shiftKey?this.getBlockIncrement():this.getUnitIncrement());break;default:b=!1}b&&a.preventDefault()};
goog.ui.SliderBase.prototype.handleMouseDownAndClick_=function(a){this.focusElementOnSliderDrag_&&this.getElement().focus&&this.getElement().focus();var b=a.target;goog.dom.contains(this.valueThumb,b)||goog.dom.contains(this.extentThumb,b)||(b=a.type==goog.events.EventType.CLICK,b&&goog.now()<this.mouseDownTime_+this.MOUSE_DOWN_DELAY_||(b||(this.mouseDownTime_=goog.now()),this.moveToPointEnabled_?this.animatedSetValue(this.getValueFromMousePosition(a)):this.startBlockIncrementing_(a)))};
goog.ui.SliderBase.prototype.handleMouseWheel_=function(a){this.moveThumbs((0<a.detail?-1:1)*this.getUnitIncrement());a.preventDefault()};
goog.ui.SliderBase.prototype.startBlockIncrementing_=function(a){this.storeMousePos_(a);this.thumbToMove_=this.getClosestThumb_(this.getValueFromMousePosition(a));this.incrementing_=this.orientation_==goog.ui.SliderBase.Orientation.VERTICAL?this.lastMousePosition_<this.thumbToMove_.offsetTop:this.lastMousePosition_>this.getOffsetStart_(this.thumbToMove_)+this.thumbToMove_.offsetWidth;a=goog.dom.getOwnerDocument(this.getElement());this.getHandler().listen(a,goog.events.EventType.MOUSEUP,this.stopBlockIncrementing_,
!0).listen(this.getElement(),goog.events.EventType.MOUSEMOVE,this.storeMousePos_);this.incTimer_||(this.incTimer_=new goog.Timer(goog.ui.SliderBase.MOUSE_DOWN_INCREMENT_INTERVAL_),this.getHandler().listen(this.incTimer_,goog.Timer.TICK,this.handleTimerTick_));this.handleTimerTick_();this.incTimer_.start()};
goog.ui.SliderBase.prototype.handleTimerTick_=function(){var a;if(this.orientation_==goog.ui.SliderBase.Orientation.VERTICAL){var b=this.lastMousePosition_,c=this.thumbToMove_.offsetTop;this.incrementing_?b<c&&(a=this.getThumbPosition_(this.thumbToMove_)+this.getBlockIncrement()):b>c+this.thumbToMove_.offsetHeight&&(a=this.getThumbPosition_(this.thumbToMove_)-this.getBlockIncrement())}else b=this.lastMousePosition_,c=this.getOffsetStart_(this.thumbToMove_),this.incrementing_?b>c+this.thumbToMove_.offsetWidth&&
(a=this.getThumbPosition_(this.thumbToMove_)+this.getBlockIncrement()):b<c&&(a=this.getThumbPosition_(this.thumbToMove_)-this.getBlockIncrement());goog.isDef(a)&&this.setThumbPosition_(this.thumbToMove_,a)};
goog.ui.SliderBase.prototype.stopBlockIncrementing_=function(){this.incTimer_&&this.incTimer_.stop();var a=goog.dom.getOwnerDocument(this.getElement());this.getHandler().unlisten(a,goog.events.EventType.MOUSEUP,this.stopBlockIncrementing_,!0).unlisten(this.getElement(),goog.events.EventType.MOUSEMOVE,this.storeMousePos_)};
goog.ui.SliderBase.prototype.getRelativeMousePos_=function(a){a=goog.style.getRelativePosition(a,this.getElement());return this.orientation_==goog.ui.SliderBase.Orientation.VERTICAL?a.y:this.flipForRtl_&&this.isRightToLeft()?this.getElement().clientWidth-a.x:a.x};goog.ui.SliderBase.prototype.storeMousePos_=function(a){this.lastMousePosition_=this.getRelativeMousePos_(a)};
goog.ui.SliderBase.prototype.getValueFromMousePosition=function(a){var b=this.getMinimum(),c=this.getMaximum();if(this.orientation_==goog.ui.SliderBase.Orientation.VERTICAL){var d=this.valueThumb.offsetHeight,e=this.getElement().clientHeight-d;a=this.getRelativeMousePos_(a)-d/2;return(c-b)*(e-a)/e+b}d=this.valueThumb.offsetWidth;e=this.getElement().clientWidth-d;a=this.getRelativeMousePos_(a)-d/2;return(c-b)*a/e+b};
goog.ui.SliderBase.prototype.getThumbPosition_=function(a){if(a==this.valueThumb)return this.rangeModel.getValue();if(a==this.extentThumb)return this.rangeModel.getValue()+this.rangeModel.getExtent();throw Error("Illegal thumb element. Neither minThumb nor maxThumb");};goog.ui.SliderBase.prototype.isDragging=function(){return this.valueDragger_.isDragging()||this.extentDragger_.isDragging()};
goog.ui.SliderBase.prototype.moveThumbs=function(a){Math.abs(a)<this.getStep()&&(a=goog.math.sign(a)*this.getStep());var b=this.getThumbPosition_(this.valueThumb)+a;a=this.getThumbPosition_(this.extentThumb)+a;b=goog.math.clamp(b,this.getMinimum(),this.getMaximum()-this.minExtent_);a=goog.math.clamp(a,this.getMinimum()+this.minExtent_,this.getMaximum());this.setValueAndExtent(b,a-b)};
goog.ui.SliderBase.prototype.setThumbPosition_=function(a,b){var c=this.rangeModel.roundToStepWithMin(b);b=a==this.valueThumb?c:this.rangeModel.getValue();a=a==this.extentThumb?c:this.rangeModel.getValue()+this.rangeModel.getExtent();b>=this.getMinimum()&&a>=b+this.minExtent_&&this.getMaximum()>=a&&this.setValueAndExtent(b,a-b)};
goog.ui.SliderBase.prototype.setValueAndExtent=function(a,b){this.getMinimum()<=a&&a<=this.getMaximum()-b&&this.minExtent_<=b&&b<=this.getMaximum()-a&&(a!=this.getValue()||b!=this.getExtent())&&(this.rangeModel.setMute(!0),this.rangeModel.setExtent(0),this.rangeModel.setValue(a),this.rangeModel.setExtent(b),this.rangeModel.setMute(!1),this.handleRangeModelChange(null))};goog.ui.SliderBase.prototype.getMinimum=function(){return this.rangeModel.getMinimum()};
goog.ui.SliderBase.prototype.setMinimum=function(a){this.rangeModel.setMinimum(a)};goog.ui.SliderBase.prototype.getMaximum=function(){return this.rangeModel.getMaximum()};goog.ui.SliderBase.prototype.setMaximum=function(a){this.rangeModel.setMaximum(a)};goog.ui.SliderBase.prototype.getValueThumb=function(){return this.valueThumb};goog.ui.SliderBase.prototype.getExtentThumb=function(){return this.extentThumb};
goog.ui.SliderBase.prototype.getClosestThumb_=function(a){return a<=this.rangeModel.getValue()+this.rangeModel.getExtent()/2?this.valueThumb:this.extentThumb};goog.ui.SliderBase.prototype.handleRangeModelChange=function(a){this.updateUi_();this.updateAriaStates();this.dispatchEvent(goog.ui.Component.EventType.CHANGE)};
goog.ui.SliderBase.prototype.updateUi_=function(){if(this.valueThumb&&!this.isAnimating_){var a=this.getThumbCoordinateForValue(this.getThumbPosition_(this.valueThumb)),b=this.getThumbCoordinateForValue(this.getThumbPosition_(this.extentThumb));if(this.orientation_==goog.ui.SliderBase.Orientation.VERTICAL)this.valueThumb.style.top=a.y+"px",this.extentThumb.style.top=b.y+"px",this.rangeHighlight&&(a=this.calculateRangeHighlightPositioning_(b.y,a.y,this.valueThumb.offsetHeight),this.rangeHighlight.style.top=
a.offset+"px",this.rangeHighlight.style.height=a.size+"px");else{var c=this.flipForRtl_&&this.isRightToLeft()?"right":"left";this.valueThumb.style[c]=a.x+"px";this.extentThumb.style[c]=b.x+"px";this.rangeHighlight&&(a=this.calculateRangeHighlightPositioning_(a.x,b.x,this.valueThumb.offsetWidth),this.rangeHighlight.style[c]=a.offset+"px",this.rangeHighlight.style.width=a.size+"px")}}};
goog.ui.SliderBase.prototype.calculateRangeHighlightPositioning_=function(a,b,c){var d=Math.ceil(c/2);return{offset:a+d,size:Math.max(b-a+c-2*d,0)}};
goog.ui.SliderBase.prototype.getThumbCoordinateForValue=function(a){var b=new goog.math.Coordinate;if(this.valueThumb){var c=this.getMinimum(),d=this.getMaximum();a=a==c&&c==d?0:(a-c)/(d-c);this.orientation_==goog.ui.SliderBase.Orientation.VERTICAL?(c=this.valueThumb.offsetHeight,c=this.getElement().clientHeight-c,a=Math.round(a*c),b.x=this.getOffsetStart_(this.valueThumb),b.y=c-a):(c=this.getElement().clientWidth-this.valueThumb.offsetWidth,b.x=Math.round(a*c),b.y=this.valueThumb.offsetTop)}return b};
goog.ui.SliderBase.prototype.animatedSetValue=function(a){a=goog.math.clamp(a,this.getMinimum(),this.getMaximum());this.isAnimating_&&(this.currentAnimation_.stop(!0),this.currentAnimation_.dispose());var b=new goog.fx.AnimationParallelQueue,c=this.getClosestThumb_(a),d=this.getValue(),e=this.getExtent(),f=this.getThumbPosition_(c),g=this.getThumbCoordinateForValue(f);var h=this.getStep();Math.abs(a-f)<h&&(a=goog.math.clamp(f+(a>f?h:-h),this.getMinimum(),this.getMaximum()));this.setThumbPosition_(c,
a);f=this.getThumbCoordinateForValue(this.getThumbPosition_(c));h=this.orientation_==goog.ui.SliderBase.Orientation.VERTICAL?[this.getOffsetStart_(c),f.y]:[f.x,c.offsetTop];g=new goog.fx.dom.Slide(c,[g.x,g.y],h,goog.ui.SliderBase.ANIMATION_INTERVAL_);g.enableRightPositioningForRtl(this.flipForRtl_);b.add(g);this.rangeHighlight&&this.addRangeHighlightAnimations_(c,d,e,f,b);this.additionalAnimations_&&(a=this.additionalAnimations_.createAnimations(d,a,goog.ui.SliderBase.ANIMATION_INTERVAL_),goog.array.forEach(a,
function(a){b.add(a)}));this.currentAnimation_=b;this.getHandler().listen(b,goog.fx.Transition.EventType.END,this.endAnimation_);this.isAnimating_=!0;b.play(!1)};goog.ui.SliderBase.prototype.isAnimating=function(){return this.isAnimating_};goog.ui.SliderBase.prototype.setAdditionalAnimations=function(a){this.additionalAnimations_=a};
goog.ui.SliderBase.prototype.addRangeHighlightAnimations_=function(a,b,c,d,e){var f=this.getThumbCoordinateForValue(b),g=this.getThumbCoordinateForValue(b+c);b=f;c=g;a==this.valueThumb?b=d:c=d;this.orientation_==goog.ui.SliderBase.Orientation.VERTICAL?(a=this.calculateRangeHighlightPositioning_(g.y,f.y,this.valueThumb.offsetHeight),f=this.calculateRangeHighlightPositioning_(c.y,b.y,this.valueThumb.offsetHeight),d=new goog.fx.dom.Slide(this.rangeHighlight,[this.getOffsetStart_(this.rangeHighlight),
a.offset],[this.getOffsetStart_(this.rangeHighlight),f.offset],goog.ui.SliderBase.ANIMATION_INTERVAL_),a=new goog.fx.dom.ResizeHeight(this.rangeHighlight,a.size,f.size,goog.ui.SliderBase.ANIMATION_INTERVAL_),d.enableRightPositioningForRtl(this.flipForRtl_),a.enableRightPositioningForRtl(this.flipForRtl_),e.add(d),e.add(a)):(a=this.calculateRangeHighlightPositioning_(f.x,g.x,this.valueThumb.offsetWidth),f=this.calculateRangeHighlightPositioning_(b.x,c.x,this.valueThumb.offsetWidth),d=new goog.fx.dom.Slide(this.rangeHighlight,
[a.offset,this.rangeHighlight.offsetTop],[f.offset,this.rangeHighlight.offsetTop],goog.ui.SliderBase.ANIMATION_INTERVAL_),a=new goog.fx.dom.ResizeWidth(this.rangeHighlight,a.size,f.size,goog.ui.SliderBase.ANIMATION_INTERVAL_),d.enableRightPositioningForRtl(this.flipForRtl_),a.enableRightPositioningForRtl(this.flipForRtl_),e.add(d),e.add(a))};goog.ui.SliderBase.prototype.endAnimation_=function(a){this.isAnimating_=!1;this.dispatchEvent(goog.ui.SliderBase.EventType.ANIMATION_END)};
goog.ui.SliderBase.prototype.setOrientation=function(a){if(this.orientation_!=a){var b=this.getCssClass(this.orientation_),c=this.getCssClass(a);this.orientation_=a;this.getElement()&&(goog.dom.classlist.swap(goog.asserts.assert(this.getElement()),b,c),a=this.flipForRtl_&&this.isRightToLeft()?"right":"left",this.valueThumb.style[a]=this.valueThumb.style.top="",this.extentThumb.style[a]=this.extentThumb.style.top="",this.rangeHighlight&&(this.rangeHighlight.style[a]=this.rangeHighlight.style.top="",
this.rangeHighlight.style.width=this.rangeHighlight.style.height=""),this.updateUi_())}};goog.ui.SliderBase.prototype.getOrientation=function(){return this.orientation_};
goog.ui.SliderBase.prototype.disposeInternal=function(){goog.ui.SliderBase.superClass_.disposeInternal.call(this);this.incTimer_&&this.incTimer_.dispose();delete this.incTimer_;this.currentAnimation_&&this.currentAnimation_.dispose();delete this.currentAnimation_;delete this.valueThumb;delete this.extentThumb;this.rangeHighlight&&delete this.rangeHighlight;this.rangeModel.dispose();delete this.rangeModel;this.keyHandler_&&(this.keyHandler_.dispose(),delete this.keyHandler_);this.mouseWheelHandler_&&
(this.mouseWheelHandler_.dispose(),delete this.mouseWheelHandler_);this.valueDragger_&&(this.valueDragger_.dispose(),delete this.valueDragger_);this.extentDragger_&&(this.extentDragger_.dispose(),delete this.extentDragger_)};goog.ui.SliderBase.prototype.getBlockIncrement=function(){return this.blockIncrement_};goog.ui.SliderBase.prototype.setBlockIncrement=function(a){this.blockIncrement_=a};goog.ui.SliderBase.prototype.setMinExtent=function(a){this.minExtent_=a};
goog.ui.SliderBase.prototype.unitIncrement_=1;goog.ui.SliderBase.prototype.getUnitIncrement=function(){return this.unitIncrement_};goog.ui.SliderBase.prototype.setUnitIncrement=function(a){this.unitIncrement_=a};goog.ui.SliderBase.prototype.getStep=function(){return this.rangeModel.getStep()};goog.ui.SliderBase.prototype.setStep=function(a){this.rangeModel.setStep(a)};goog.ui.SliderBase.prototype.getMoveToPointEnabled=function(){return this.moveToPointEnabled_};
goog.ui.SliderBase.prototype.setMoveToPointEnabled=function(a){this.moveToPointEnabled_=a};goog.ui.SliderBase.prototype.getValue=function(){return this.rangeModel.getValue()};goog.ui.SliderBase.prototype.setValue=function(a){this.setThumbPosition_(this.valueThumb,a)};goog.ui.SliderBase.prototype.getExtent=function(){return this.rangeModel.getExtent()};goog.ui.SliderBase.prototype.setExtent=function(a){this.setThumbPosition_(this.extentThumb,this.rangeModel.getValue()+a)};
goog.ui.SliderBase.prototype.setVisible=function(a){goog.style.setElementShown(this.getElement(),a);a&&this.updateUi_()};goog.ui.SliderBase.prototype.setAriaRoles=function(){var a=this.getElement();goog.asserts.assert(a,"The DOM element for the slider base cannot be null.");goog.a11y.aria.setRole(a,goog.a11y.aria.Role.SLIDER);this.updateAriaStates()};
goog.ui.SliderBase.prototype.updateAriaStates=function(){var a=this.getElement();a&&(goog.a11y.aria.setState(a,goog.a11y.aria.State.VALUEMIN,this.getMinimum()),goog.a11y.aria.setState(a,goog.a11y.aria.State.VALUEMAX,this.getMaximum()),goog.a11y.aria.setState(a,goog.a11y.aria.State.VALUENOW,this.getValue()),goog.a11y.aria.setState(a,goog.a11y.aria.State.VALUETEXT,this.getTextValue()||""))};
goog.ui.SliderBase.prototype.setHandleMouseWheel=function(a){this.isInDocument()&&a!=this.isHandleMouseWheel()&&this.enableMouseWheelHandling_(a);this.isHandleMouseWheel_=a};goog.ui.SliderBase.prototype.isHandleMouseWheel=function(){return this.isHandleMouseWheel_};
goog.ui.SliderBase.prototype.enableMouseWheelHandling_=function(a){a?(this.mouseWheelHandler_||(this.mouseWheelHandler_=new goog.events.MouseWheelHandler(this.getElement())),this.getHandler().listen(this.mouseWheelHandler_,goog.events.MouseWheelHandler.EventType.MOUSEWHEEL,this.handleMouseWheel_)):this.getHandler().unlisten(this.mouseWheelHandler_,goog.events.MouseWheelHandler.EventType.MOUSEWHEEL,this.handleMouseWheel_)};
goog.ui.SliderBase.prototype.setEnabled=function(a){this.enabled_!=a&&this.dispatchEvent(a?goog.ui.Component.EventType.ENABLE:goog.ui.Component.EventType.DISABLE)&&(this.enabled_=a,this.enableEventHandlers_(a),a||this.stopBlockIncrementing_(),goog.dom.classlist.enable(goog.asserts.assert(this.getElement()),goog.ui.SliderBase.DISABLED_CSS_CLASS_,!a))};goog.ui.SliderBase.prototype.isEnabled=function(){return this.enabled_};
goog.ui.SliderBase.prototype.getOffsetStart_=function(a){return this.flipForRtl_?goog.style.bidi.getOffsetStart(a):a.offsetLeft};goog.ui.SliderBase.prototype.getTextValue=function(){return this.labelFn_(this.getValue())};goog.ui.SliderBase.prototype.setFocusElementOnSliderDrag=function(a){this.focusElementOnSliderDrag_=a};goog.ui.SliderBase.AnimationFactory=function(){};goog.ui.Slider=function(a,b){goog.ui.SliderBase.call(this,a,b);this.rangeModel.setExtent(0)};goog.inherits(goog.ui.Slider,goog.ui.SliderBase);goog.tagUnsealableClass(goog.ui.Slider);goog.ui.Slider.Orientation=goog.ui.SliderBase.Orientation;goog.ui.Slider.CSS_CLASS_PREFIX="goog-slider";goog.ui.Slider.THUMB_CSS_CLASS=goog.ui.Slider.CSS_CLASS_PREFIX+"-thumb";
goog.ui.Slider.prototype.getCssClass=function(a){return a==goog.ui.SliderBase.Orientation.VERTICAL?goog.ui.Slider.CSS_CLASS_PREFIX+"-vertical":goog.ui.Slider.CSS_CLASS_PREFIX+"-horizontal"};goog.ui.Slider.prototype.getThumbCssClass=function(){return goog.ui.Slider.THUMB_CSS_CLASS};
goog.ui.Slider.prototype.createThumbs=function(){var a=this.getElement(),b=goog.dom.getElementsByTagNameAndClass(null,this.getThumbCssClass(),a)[0];b||(b=this.createThumb_(),a.appendChild(b));this.valueThumb=this.extentThumb=b};goog.ui.Slider.prototype.createThumb_=function(){var a=this.getDomHelper().createDom("DIV",this.getThumbCssClass());goog.a11y.aria.setRole(a,goog.a11y.aria.Role.BUTTON);return a};Blockly.FieldColourSlider=function(a,b){Blockly.FieldColourSlider.superClass_.constructor.call(this,a,b);this.addArgType("colour");this.sliderCallbacksEnabled_=!1};goog.inherits(Blockly.FieldColourSlider,Blockly.Field);Blockly.FieldColourSlider.fromJson=function(a){return new Blockly.FieldColourSlider(a.colour)};Blockly.FieldColourSlider.activateEyedropper_=null;Blockly.FieldColourSlider.EYEDROPPER_PATH="eyedropper.svg";
Blockly.FieldColourSlider.prototype.init=function(a){this.fieldGroup_||(Blockly.FieldColourSlider.superClass_.init.call(this,a),this.setValue(this.getValue()))};Blockly.FieldColourSlider.prototype.getValue=function(){return this.colour_};
Blockly.FieldColourSlider.prototype.setValue=function(a){this.sourceBlock_&&Blockly.Events.isEnabled()&&this.colour_!=a&&Blockly.Events.fire(new Blockly.Events.BlockChange(this.sourceBlock_,"field",this.name,this.colour_,a));this.colour_=a;this.sourceBlock_&&this.sourceBlock_.setColour(a,a,this.sourceBlock_.getColourTertiary());this.updateSliderHandles_();this.updateDom_()};
Blockly.FieldColourSlider.prototype.createColourStops_=function(a){for(var b=[],c=0;360>=c;c+=20)switch(a){case "hue":b.push(goog.color.hsvToHex(c,this.saturation_,this.brightness_));break;case "saturation":b.push(goog.color.hsvToHex(this.hue_,c/360,this.brightness_));break;case "brightness":b.push(goog.color.hsvToHex(this.hue_,this.saturation_,255*c/360));break;default:throw Error("Unknown channel for colour sliders: "+a);}return b};
Blockly.FieldColourSlider.prototype.setGradient_=function(a,b){b=this.createColourStops_(b).join(",");goog.style.setStyle(a,"background","-moz-linear-gradient(left, "+b+")");goog.style.setStyle(a,"background","-webkit-linear-gradient(left, "+b+")");goog.style.setStyle(a,"background","-o-linear-gradient(left, "+b+")");goog.style.setStyle(a,"background","-ms-linear-gradient(left, "+b+")");goog.style.setStyle(a,"background","linear-gradient(left, "+b+")")};
Blockly.FieldColourSlider.prototype.updateDom_=function(){this.hueSlider_&&(this.setGradient_(this.hueSlider_.getElement(),"hue"),this.setGradient_(this.saturationSlider_.getElement(),"saturation"),this.setGradient_(this.brightnessSlider_.getElement(),"brightness"),this.hueReadout_.textContent=Math.floor(100*this.hue_/360).toFixed(0),this.saturationReadout_.textContent=Math.floor(100*this.saturation_).toFixed(0),this.brightnessReadout_.textContent=Math.floor(100*this.brightness_/255).toFixed(0))};
Blockly.FieldColourSlider.prototype.updateSliderHandles_=function(){this.hueSlider_&&(this.sliderCallbacksEnabled_=!1,this.hueSlider_.setValue(this.hue_),this.saturationSlider_.setValue(this.saturation_),this.brightnessSlider_.setValue(this.brightness_),this.sliderCallbacksEnabled_=!0)};Blockly.FieldColourSlider.prototype.getText=function(){var a=this.colour_,b=a.match(/^#(.)\1(.)\2(.)\3$/);b&&(a="#"+b[1]+b[2]+b[3]);return a};
Blockly.FieldColourSlider.prototype.createLabelDom_=function(a){var b=document.createElement("div");b.setAttribute("class","scratchColourPickerLabel");var c=document.createElement("span");c.setAttribute("class","scratchColourPickerReadout");var d=document.createElement("span");d.setAttribute("class","scratchColourPickerLabelText");d.textContent=a;b.appendChild(d);b.appendChild(c);return[b,c]};
Blockly.FieldColourSlider.prototype.sliderCallbackFactory_=function(a){var b=this;return function(c){if(b.sliderCallbacksEnabled_){c=c.target.getValue();switch(a){case "hue":b.hue_=c;break;case "saturation":b.saturation_=c;break;case "brightness":b.brightness_=c}c=goog.color.hsvToHex(b.hue_,b.saturation_,b.brightness_);b.sourceBlock_&&(c=b.callValidator(c));null!==c&&b.setValue(c,!0)}}};
Blockly.FieldColourSlider.prototype.activateEyedropperInternal_=function(){var a=this;Blockly.FieldColourSlider.activateEyedropper_(function(b){var c=goog.color.hexToHsv(b);a.hue_=c[0];a.saturation_=c[1];a.brightness_=c[2];a.setValue(b)})};
Blockly.FieldColourSlider.prototype.showEditor_=function(){Blockly.DropDownDiv.hideWithoutAnimation();Blockly.DropDownDiv.clearContent();var a=Blockly.DropDownDiv.getContentDiv(),b=goog.color.hexToHsv(this.getValue());this.hue_=b[0];this.saturation_=b[1];this.brightness_=b[2];b=this.createLabelDom_(Blockly.Msg.COLOUR_HUE_LABEL);a.appendChild(b[0]);this.hueReadout_=b[1];this.hueSlider_=new goog.ui.Slider;this.hueSlider_.setUnitIncrement(5);this.hueSlider_.setMinimum(0);this.hueSlider_.setMaximum(360);
this.hueSlider_.setMoveToPointEnabled(!0);this.hueSlider_.render(a);b=this.createLabelDom_(Blockly.Msg.COLOUR_SATURATION_LABEL);a.appendChild(b[0]);this.saturationReadout_=b[1];this.saturationSlider_=new goog.ui.Slider;this.saturationSlider_.setMoveToPointEnabled(!0);this.saturationSlider_.setUnitIncrement(.01);this.saturationSlider_.setStep(.001);this.saturationSlider_.setMinimum(0);this.saturationSlider_.setMaximum(1);this.saturationSlider_.render(a);b=this.createLabelDom_(Blockly.Msg.COLOUR_BRIGHTNESS_LABEL);
a.appendChild(b[0]);this.brightnessReadout_=b[1];this.brightnessSlider_=new goog.ui.Slider;this.brightnessSlider_.setUnitIncrement(2);this.brightnessSlider_.setMinimum(0);this.brightnessSlider_.setMaximum(255);this.brightnessSlider_.setMoveToPointEnabled(!0);this.brightnessSlider_.render(a);if(Blockly.FieldColourSlider.activateEyedropper_){b=document.createElement("button");b.setAttribute("class","scratchEyedropper");var c=document.createElement("img");c.src=Blockly.mainWorkspace.options.pathToMedia+
Blockly.FieldColourSlider.EYEDROPPER_PATH;b.appendChild(c);a.appendChild(b);Blockly.FieldColourSlider.eyedropperEventData_=Blockly.bindEventWithChecks_(b,"click",this,this.activateEyedropperInternal_)}Blockly.DropDownDiv.setColour("#ffffff","#dddddd");Blockly.DropDownDiv.setCategory(this.sourceBlock_.parentBlock_.getCategory());Blockly.DropDownDiv.showPositionedByBlock(this,this.sourceBlock_);this.setValue(this.getValue());this.sliderCallbacksEnabled_=!0;Blockly.FieldColourSlider.hueChangeEventKey_=
goog.events.listen(this.hueSlider_,goog.ui.Component.EventType.CHANGE,this.sliderCallbackFactory_("hue"));Blockly.FieldColourSlider.saturationChangeEventKey_=goog.events.listen(this.saturationSlider_,goog.ui.Component.EventType.CHANGE,this.sliderCallbackFactory_("saturation"));Blockly.FieldColourSlider.brightnessChangeEventKey_=goog.events.listen(this.brightnessSlider_,goog.ui.Component.EventType.CHANGE,this.sliderCallbackFactory_("brightness"))};
Blockly.FieldColourSlider.prototype.dispose=function(){Blockly.FieldColourSlider.hueChangeEventKey_&&goog.events.unlistenByKey(Blockly.FieldColourSlider.hueChangeEventKey_);Blockly.FieldColourSlider.saturationChangeEventKey_&&goog.events.unlistenByKey(Blockly.FieldColourSlider.saturationChangeEventKey_);Blockly.FieldColourSlider.brightnessChangeEventKey_&&goog.events.unlistenByKey(Blockly.FieldColourSlider.brightnessChangeEventKey_);Blockly.FieldColourSlider.eyedropperEventData_&&Blockly.unbindEvent_(Blockly.FieldColourSlider.eyedropperEventData_);
Blockly.Events.setGroup(!1);Blockly.FieldColourSlider.superClass_.dispose.call(this)};Blockly.Field.register("field_colour_slider",Blockly.FieldColourSlider);Blockly.FieldDropdown=function(a,b){this.menuGenerator_=a;this.trimOptions_();a=this.getOptions()[0];Blockly.FieldDropdown.superClass_.constructor.call(this,a[1],b);this.addArgType("dropdown")};goog.inherits(Blockly.FieldDropdown,Blockly.Field);Blockly.FieldDropdown.fromJson=function(a){return new Blockly.FieldDropdown(a.options)};Blockly.FieldDropdown.CHECKMARK_OVERHANG=25;Blockly.FieldDropdown.prototype.CURSOR="default";Blockly.FieldDropdown.prototype.selectedItem=null;
Blockly.FieldDropdown.prototype.value_="";Blockly.FieldDropdown.prototype.imageElement_=null;Blockly.FieldDropdown.prototype.imageJson_=null;
Blockly.FieldDropdown.prototype.init=function(){if(!this.fieldGroup_){this.arrowSize_=12;this.arrowX_=0;this.arrowY_=11;this.arrow_=Blockly.utils.createSvgElement("image",{height:this.arrowSize_+"px",width:this.arrowSize_+"px"});this.arrow_.setAttributeNS("http://www.w3.org/1999/xlink","xlink:href",Blockly.mainWorkspace.options.pathToMedia+"dropdown-arrow.svg");this.className_+=" blocklyDropdownText";Blockly.FieldDropdown.superClass_.init.call(this);this.sourceBlock_.isShadow()||(this.box_=Blockly.utils.createSvgElement("rect",
{rx:Blockly.BlockSvg.CORNER_RADIUS,ry:Blockly.BlockSvg.CORNER_RADIUS,x:0,y:0,width:this.size_.width,height:this.size_.height,stroke:this.sourceBlock_.getColourTertiary(),fill:this.sourceBlock_.getColour(),"class":"blocklyBlockBackground","fill-opacity":1},null),this.fieldGroup_.insertBefore(this.box_,this.textElement_));var a=this.text_;this.text_=null;this.setText(a)}};
Blockly.FieldDropdown.prototype.showEditor_=function(){var a=this.getOptions();if(0!=a.length){this.dropDownOpen_=!0;Blockly.DropDownDiv.hideWithoutAnimation();Blockly.DropDownDiv.clearContent();var b=Blockly.DropDownDiv.getContentDiv(),c=this,d=new goog.ui.Menu;d.setRightToLeft(this.sourceBlock_.RTL);for(var e=0;e<a.length;e++){var f=a[e][0],g=a[e][1];if("object"==typeof f){var h=new Image(f.width,f.height);h.src=f.src;h.alt=f.alt||"";f=h}f=new goog.ui.MenuItem(f);f.setRightToLeft(this.sourceBlock_.RTL);
f.setValue(g);f.setCheckable(!0);d.addChild(f,!0);g=g==this.value_;f.setChecked(g);g&&(this.selectedItem=f)}goog.events.listen(d,goog.ui.Component.EventType.ACTION,function(a){if(a=a.target)c.onItemSelected(this,a);Blockly.DropDownDiv.hide();Blockly.Events.setGroup(!1)});d.render(b);a=d.getElement();Blockly.utils.addClass(a,"blocklyDropdownMenu");goog.style.getSize(a).height=a.scrollHeight;b=this.sourceBlock_.isShadow()?this.sourceBlock_.parentBlock_.getColour():this.sourceBlock_.getColour();Blockly.DropDownDiv.setColour(b,
this.sourceBlock_.getColourTertiary());b=this.sourceBlock_.isShadow()?this.sourceBlock_.parentBlock_.getCategory():this.sourceBlock_.getCategory();Blockly.DropDownDiv.setCategory(b);e=this.sourceBlock_.workspace.scale;g=this.size_.width;b=this.size_.height;g*=e;b*=e;e=this.fieldGroup_.getBoundingClientRect();g=e.left+g/2;b=e.top+b;e=e.top;Blockly.DropDownDiv.setBoundsElement(this.sourceBlock_.workspace.getParentSvg().parentNode);Blockly.DropDownDiv.show(this,g,b,g,e,this.onHide.bind(this));d.setAllowAutoFocus(!0);
a.focus();this.disableColourChange_||(this.sourceBlock_.isShadow()?this.sourceBlock_.setShadowColour(this.sourceBlock_.getColourTertiary()):this.box_&&this.box_.setAttribute("fill",this.sourceBlock_.getColourTertiary()))}};Blockly.FieldDropdown.prototype.onHide=function(){this.dropDownOpen_=!1;!this.disableColourChange_&&this.sourceBlock_&&(this.sourceBlock_.isShadow()?this.sourceBlock_.clearShadowColour():this.box_&&this.box_.setAttribute("fill",this.sourceBlock_.getColour()))};
Blockly.FieldDropdown.prototype.onItemSelected=function(a,b){a=b.getValue();this.sourceBlock_&&(a=this.callValidator(a));"function"==typeof a?a():null!==a&&this.setValue(a)};
Blockly.FieldDropdown.prototype.trimOptions_=function(){this.suffixField=this.prefixField=null;var a=this.menuGenerator_;if(goog.isArray(a)){for(var b=!1,c=0;c<a.length;c++){var d=a[c][0];"string"==typeof d?a[c][0]=Blockly.utils.replaceMessageReferences(d):(null!=d.alt&&(a[c][0].alt=Blockly.utils.replaceMessageReferences(d.alt)),b=!0)}if(!(b||2>a.length)){var e=[];for(c=0;c<a.length;c++)e.push(a[c][0]);c=Blockly.utils.shortestStringLength(e);b=Blockly.utils.commonWordPrefix(e,c);d=Blockly.utils.commonWordSuffix(e,
c);if((b||d)&&!(c<=b+d)){b&&(this.prefixField=e[0].substring(0,b-1));d&&(this.suffixField=e[0].substr(1-d));e=[];for(c=0;c<a.length;c++){var f=a[c][0],g=a[c][1];f=f.substring(b,f.length-d);e[c]=[f,g]}this.menuGenerator_=e}}}};Blockly.FieldDropdown.prototype.isOptionListDynamic=function(){return goog.isFunction(this.menuGenerator_)};Blockly.FieldDropdown.prototype.getOptions=function(){return goog.isFunction(this.menuGenerator_)?this.menuGenerator_.call(this):this.menuGenerator_};
Blockly.FieldDropdown.prototype.getValue=function(){return this.value_};
Blockly.FieldDropdown.prototype.setValue=function(a){if(null!==a&&a!==this.value_){this.sourceBlock_&&Blockly.Events.isEnabled()&&Blockly.Events.fire(new Blockly.Events.BlockChange(this.sourceBlock_,"field",this.name,this.value_,a));this.selectedItem&&(this.selectedItem.setChecked(!1),this.selectedItem=null);this.value_=a;for(var b=this.getOptions(),c=0;c<b.length;c++)if(b[c][1]==a){a=b[c][0];"object"==typeof a?(this.imageJson_=a,this.text_=a.alt):(this.imageJson_=null,this.text_=a);this.forceRerender();
return}this.text_=a;this.forceRerender()}};Blockly.FieldDropdown.prototype.setText=function(a){null!==a&&a!==this.text_&&(this.text_=a,this.updateTextNode_(),this.textElement_&&this.textElement_.parentNode.appendChild(this.arrow_),this.sourceBlock_&&this.sourceBlock_.rendered&&(this.sourceBlock_.render(),this.sourceBlock_.bumpNeighbours_()))};
Blockly.FieldDropdown.prototype.positionArrow=function(a){if(!this.arrow_)return 0;this.arrowX_=this.sourceBlock_.RTL?this.arrowSize_-Blockly.BlockSvg.DROPDOWN_ARROW_PADDING:a+Blockly.BlockSvg.DROPDOWN_ARROW_PADDING/2;a=this.arrowSize_+Blockly.BlockSvg.DROPDOWN_ARROW_PADDING;this.box_&&(this.arrowX_+=Blockly.BlockSvg.BOX_FIELD_PADDING);this.arrow_.setAttribute("transform","translate("+this.arrowX_+","+this.arrowY_+")");return a};
Blockly.FieldDropdown.prototype.dispose=function(){this.selectedItem=null;Blockly.WidgetDiv.hideIfOwner(this);Blockly.FieldDropdown.superClass_.dispose.call(this)};Blockly.Field.register("field_dropdown",Blockly.FieldDropdown);Blockly.FieldIconMenu=function(a){this.icons_=a;Blockly.FieldIconMenu.superClass_.constructor.call(this,a[0].value);this.addArgType("iconmenu")};goog.inherits(Blockly.FieldIconMenu,Blockly.Field);Blockly.FieldIconMenu.fromJson=function(a){return new Blockly.FieldIconMenu(a.options)};Blockly.FieldIconMenu.DROPDOWN_WIDTH=168;Blockly.FieldIconMenu.savedPrimary_=null;
Blockly.FieldIconMenu.prototype.init=function(a){this.fieldGroup_||(this.arrowX_=18,this.arrowY_=10,a.RTL&&(this.arrowX_=-this.arrowX_-12),this.arrowIcon_=Blockly.utils.createSvgElement("image",{height:"12px",width:"12px",transform:"translate("+this.arrowX_+","+this.arrowY_+")"}),this.arrowIcon_.setAttributeNS("http://www.w3.org/1999/xlink","xlink:href",Blockly.mainWorkspace.options.pathToMedia+"dropdown-arrow.svg"),a.getSvgRoot().appendChild(this.arrowIcon_),Blockly.FieldIconMenu.superClass_.init.call(this,
a))};Blockly.FieldIconMenu.prototype.CURSOR="default";Blockly.FieldIconMenu.prototype.setValue=function(a){null!==a&&a!==this.value_&&(this.sourceBlock_&&Blockly.Events.isEnabled()&&Blockly.Events.fire(new Blockly.Events.Change(this.sourceBlock_,"field",this.name,this.value_,a)),this.value_=a,this.setParentFieldImage(this.getSrcForValue(this.value_)))};
Blockly.FieldIconMenu.prototype.setParentFieldImage=function(a){if(this.sourceBlock_&&this.sourceBlock_.parentBlock_)for(var b=this.sourceBlock_.parentBlock_,c=0,d;d=b.inputList[c];c++)for(var e=0,f;f=d.fieldRow[e];e++)if(f instanceof Blockly.FieldImage){f.setValue(a);return}};Blockly.FieldIconMenu.prototype.getValue=function(){return this.value_};Blockly.FieldIconMenu.prototype.getSrcForValue=function(a){for(var b=0,c;c=this.icons_[b];b++)if(c.value===a)return c.src};
Blockly.FieldIconMenu.prototype.showEditor_=function(){if(!Blockly.DropDownDiv.hideIfOwner(this)){Blockly.DropDownDiv.hideWithoutAnimation();Blockly.DropDownDiv.clearContent();var a=Blockly.DropDownDiv.getContentDiv();a.setAttribute("role","menu");a.setAttribute("aria-haspopup","true");for(var b=0,c;c=this.icons_[b];b++)if("placeholder"==c.type){var d=document.createElement("span");d.setAttribute("class","blocklyDropDownPlaceholder");d.style.width=c.width+"px";d.style.height=c.height+"px";a.appendChild(d)}else{d=
document.createElement("button");d.setAttribute("id",":"+b);d.setAttribute("role","menuitem");d.setAttribute("class","blocklyDropDownButton");d.title=c.alt;d.style.width=c.width+"px";d.style.height=c.height+"px";var e=this.sourceBlock_.getColour();c.value==this.getValue()&&(e=this.sourceBlock_.getColourTertiary(),d.setAttribute("aria-selected","true"));d.style.backgroundColor=e;d.style.borderColor=this.sourceBlock_.getColourTertiary();Blockly.bindEvent_(d,"click",this,this.buttonClick_);Blockly.bindEvent_(d,
"mouseup",this,this.buttonClick_);Blockly.bindEvent_(d,"mousedown",d,function(a){this.setAttribute("class","blocklyDropDownButton blocklyDropDownButtonHover");a.preventDefault()});Blockly.bindEvent_(d,"mouseover",d,function(){this.setAttribute("class","blocklyDropDownButton blocklyDropDownButtonHover");a.setAttribute("aria-activedescendant",this.id)});Blockly.bindEvent_(d,"mouseout",d,function(){this.setAttribute("class","blocklyDropDownButton");a.removeAttribute("aria-activedescendant")});e=document.createElement("img");
e.src=c.src;d.setAttribute("data-value",c.value);e.setAttribute("data-value",c.value);d.appendChild(e);a.appendChild(d)}a.style.width=Blockly.FieldIconMenu.DROPDOWN_WIDTH+"px";Blockly.DropDownDiv.setColour(this.sourceBlock_.getColour(),this.sourceBlock_.getColourTertiary());Blockly.DropDownDiv.setCategory(this.sourceBlock_.parentBlock_.getCategory());this.savedPrimary_=this.sourceBlock_.getColour();this.sourceBlock_.setColour(this.sourceBlock_.getColourSecondary(),this.sourceBlock_.getColourSecondary(),
this.sourceBlock_.getColourTertiary());b=this.sourceBlock_.workspace.scale;b=-(Blockly.BlockSvg.MIN_BLOCK_Y*b)-Blockly.BlockSvg.FIELD_Y_OFFSET*b;Blockly.DropDownDiv.showPositionedByBlock(this,this.sourceBlock_,this.onHide_.bind(this),b)||this.arrowIcon_.setAttribute("transform","translate("+(this.arrowX_+Blockly.DropDownDiv.ARROW_SIZE/1.5+1)+","+(this.arrowY_+Blockly.DropDownDiv.ARROW_SIZE/1.5)+") rotate(180)")}};
Blockly.FieldIconMenu.prototype.buttonClick_=function(a){a=a.target.getAttribute("data-value");this.setValue(a);Blockly.DropDownDiv.hide()};
Blockly.FieldIconMenu.prototype.onHide_=function(){this.sourceBlock_&&this.sourceBlock_.setColour(this.savedPrimary_,this.sourceBlock_.getColourSecondary(),this.sourceBlock_.getColourTertiary());Blockly.DropDownDiv.content_.removeAttribute("role");Blockly.DropDownDiv.content_.removeAttribute("aria-haspopup");Blockly.DropDownDiv.content_.removeAttribute("aria-activedescendant");this.arrowIcon_.setAttribute("transform","translate("+this.arrowX_+","+this.arrowY_+")")};
Blockly.Field.register("field_iconmenu",Blockly.FieldIconMenu);Blockly.FieldImage=function(a,b,c,d,e){this.sourceBlock_=null;this.height_=Number(c);this.width_=Number(b);this.size_=new goog.math.Size(this.width_,this.height_);this.text_=d||"";this.flipRTL_=e;this.setValue(a)};goog.inherits(Blockly.FieldImage,Blockly.Field);
Blockly.FieldImage.fromJson=function(a){var b=Blockly.utils.replaceMessageReferences(a.src),c=Number(Blockly.utils.replaceMessageReferences(a.width)),d=Number(Blockly.utils.replaceMessageReferences(a.height)),e=Blockly.utils.replaceMessageReferences(a.alt);return new Blockly.FieldImage(b,c,d,e,!!a.flip_rtl||!!a.flipRtl)};Blockly.FieldImage.prototype.EDITABLE=!1;
Blockly.FieldImage.prototype.init=function(){this.fieldGroup_||(this.fieldGroup_=Blockly.utils.createSvgElement("g",{},null),this.visible_||(this.fieldGroup_.style.display="none"),this.imageElement_=Blockly.utils.createSvgElement("image",{height:this.height_+"px",width:this.width_+"px"},this.fieldGroup_),this.setValue(this.src_),this.sourceBlock_.getSvgRoot().appendChild(this.fieldGroup_),this.setTooltip(this.sourceBlock_),Blockly.Tooltip.bindMouseEvents(this.imageElement_))};
Blockly.FieldImage.prototype.dispose=function(){goog.dom.removeNode(this.fieldGroup_);this.imageElement_=this.fieldGroup_=null};Blockly.FieldImage.prototype.setTooltip=function(a){this.imageElement_.tooltip=a};Blockly.FieldImage.prototype.getValue=function(){return this.src_};Blockly.FieldImage.prototype.setValue=function(a){null!==a&&(this.src_=a,this.imageElement_&&this.imageElement_.setAttributeNS("http://www.w3.org/1999/xlink","xlink:href",a||""))};Blockly.FieldImage.prototype.getFlipRTL=function(){return this.flipRTL_};
Blockly.FieldImage.prototype.setText=function(a){null!==a&&(this.text_=a)};Blockly.FieldImage.prototype.render_=function(){};Blockly.FieldImage.prototype.updateWidth=function(){};Blockly.Field.register("field_image",Blockly.FieldImage);/*

 Visual Blocks Editor

 Copyright 2018 Massachusetts Institute of Technology
 All rights reserved.

 Licensed under the Apache License, Version 2.0 (the "License");
 you may not use this file except in compliance with the License.
 You may obtain a copy of the License at

   http://www.apache.org/licenses/LICENSE-2.0

 Unless required by applicable law or agreed to in writing, software
 distributed under the License is distributed on an "AS IS" BASIS,
 WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 See the License for the specific language governing permissions and
 limitations under the License.
*/
Blockly.FieldNote=function(a,b){a=a&&!isNaN(a)?String(a):"0";Blockly.FieldNote.superClass_.constructor.call(this,a,b);this.addArgType("note");this.fieldEditorHeight_=this.fieldEditorWidth_=0;this.pianoSVG_=null;this.keySVGs_=[];this.displayedOctave_=this.highCText_=this.lowCText_=this.noteNameText_=null;this.animationTarget_=this.animationPos_=0;this.mouseIsDown_=!1;this.mouseDownWrappers_=[];this.mouseUpWrapper_=null;this.mouseEnterWrappers_=[];this.octaveUpMouseDownWrapper_=this.octaveDownMouseDownWrapper_=
null};goog.inherits(Blockly.FieldNote,Blockly.FieldTextInput);Blockly.FieldNote.INSET=5;Blockly.FieldNote.TOP_MENU_HEIGHT=32-Blockly.FieldNote.INSET;Blockly.FieldNote.EDGE_PADDING=1;Blockly.FieldNote.SHADOW_HEIGHT=4;Blockly.FieldNote.SHADOW_COLOR="#000";Blockly.FieldNote.SHADOW_OPACITY=.2;Blockly.FieldNote.WHITE_KEY_COLOR="#FFFFFF";Blockly.FieldNote.BLACK_KEY_COLOR="#323133";Blockly.FieldNote.BLACK_KEY_STROKE="#555555";Blockly.FieldNote.KEY_SELECTED_COLOR="#b0d6ff";
Blockly.FieldNote.NUM_WHITE_KEYS=8;Blockly.FieldNote.WHITE_KEY_HEIGHT=72;Blockly.FieldNote.WHITE_KEY_WIDTH=40;Blockly.FieldNote.BLACK_KEY_HEIGHT=40;Blockly.FieldNote.BLACK_KEY_WIDTH=32;Blockly.FieldNote.KEY_RADIUS=6;Blockly.FieldNote.KEY_LABEL_PADDING=8;
Blockly.FieldNote.KEY_INFO=[{name:"C",pitch:0},{name:"C\u266f",pitch:1,isBlack:!0},{name:"D",pitch:2},{name:"E\u266d",pitch:3,isBlack:!0},{name:"E",pitch:4},{name:"F",pitch:5},{name:"F\u266f",pitch:6,isBlack:!0},{name:"G",pitch:7},{name:"G\u266f",pitch:8,isBlack:!0},{name:"A",pitch:9},{name:"B\u266d",pitch:10,isBlack:!0},{name:"B",pitch:11},{name:"C",pitch:12}];Blockly.FieldNote.MAX_NOTE=130;Blockly.FieldNote.ANIMATION_FRACTION=.2;Blockly.FieldNote.ARROW_SVG_PATH="icons/arrow_button.svg";
Blockly.FieldNote.OCTAVE_BUTTON_SIZE=32;Blockly.FieldNote.fromJson=function(a){return new Blockly.FieldNote(a.note)};
Blockly.FieldNote.prototype.dispose_=function(){var a=this;return function(){Blockly.FieldNote.superClass_.dispose_.call(a)();a.mouseDownWrappers_.forEach(function(a){Blockly.unbindEvent_(a)});a.mouseEnterWrappers_.forEach(function(a){Blockly.unbindEvent_(a)});a.mouseUpWrapper_&&Blockly.unbindEvent_(a.mouseUpWrapper_);a.octaveDownMouseDownWrapper_&&Blockly.unbindEvent_(a.octaveDownMouseDownWrapper_);a.octaveUpMouseDownWrapper_&&Blockly.unbindEvent_(a.octaveUpMouseDownWrapper_);this.pianoSVG_=null;
this.keySVGs_.length=0;this.highCText_=this.lowCText_=this.noteNameText_=null}};
Blockly.FieldNote.prototype.showEditor_=function(){Blockly.FieldNote.superClass_.showEditor_.call(this,this.useTouchInteraction_);Blockly.DropDownDiv.hideWithoutAnimation();Blockly.DropDownDiv.clearContent();var a=Blockly.DropDownDiv.getContentDiv();this.fieldEditorWidth_=Blockly.FieldNote.NUM_WHITE_KEYS*Blockly.FieldNote.WHITE_KEY_WIDTH+Blockly.FieldNote.EDGE_PADDING;this.fieldEditorHeight_=Blockly.FieldNote.TOP_MENU_HEIGHT+Blockly.FieldNote.WHITE_KEY_HEIGHT+Blockly.FieldNote.EDGE_PADDING;a=Blockly.utils.createSvgElement("svg",
{xmlns:"http://www.w3.org/2000/svg","xmlns:html":"http://www.w3.org/1999/xhtml","xmlns:xlink":"http://www.w3.org/1999/xlink",version:"1.1",height:this.fieldEditorHeight_+"px",width:this.fieldEditorWidth_+"px"},a);this.pianoSVG_=Blockly.utils.createSvgElement("g",{},a);var b=Blockly.utils.createSvgElement("g",{},this.pianoSVG_),c=Blockly.utils.createSvgElement("g",{},this.pianoSVG_);this.keySVGs_=[];this.addPianoOctave_(-this.fieldEditorWidth_+Blockly.FieldNote.EDGE_PADDING,b,c,null);this.addPianoOctave_(0,
b,c,this.keySVGs_);this.addPianoOctave_(this.fieldEditorWidth_-Blockly.FieldNote.EDGE_PADDING,b,c,null);this.noteNameText_=Blockly.utils.createSvgElement("text",{x:this.fieldEditorWidth_/2,y:Blockly.FieldNote.TOP_MENU_HEIGHT/2,"class":"blocklyText","text-anchor":"middle","dominant-baseline":"middle"},a);b=Blockly.FieldNote.WHITE_KEY_WIDTH/2;this.lowCText_=this.addCKeyLabel_(b,a);this.highCText_=this.addCKeyLabel_(b+Blockly.FieldNote.WHITE_KEY_WIDTH*(Blockly.FieldNote.NUM_WHITE_KEYS-1),a);Blockly.utils.createSvgElement("line",
{stroke:this.sourceBlock_.getColourTertiary(),x1:0,y1:Blockly.FieldNote.TOP_MENU_HEIGHT,x2:this.fieldEditorWidth_,y2:Blockly.FieldNote.TOP_MENU_HEIGHT},a);Blockly.utils.createSvgElement("rect",{x:0,y:Blockly.FieldNote.TOP_MENU_HEIGHT,width:this.fieldEditorWidth_,height:Blockly.FieldNote.SHADOW_HEIGHT,fill:Blockly.FieldNote.SHADOW_COLOR,"fill-opacity":Blockly.FieldNote.SHADOW_OPACITY},a);this.octaveDownButton=this.addOctaveButton_(0,!0,a);this.octaveUpButton=this.addOctaveButton_(this.fieldEditorWidth_+
2*Blockly.FieldNote.INSET-Blockly.FieldNote.OCTAVE_BUTTON_SIZE,!1,a);this.octaveDownMouseDownWrapper_=Blockly.bindEvent_(this.octaveDownButton,"mousedown",this,function(){this.changeOctaveBy_(-1)});this.octaveUpMouseDownWrapper_=Blockly.bindEvent_(this.octaveUpButton,"mousedown",this,function(){this.changeOctaveBy_(1)});Blockly.DropDownDiv.setColour(this.sourceBlock_.parentBlock_.getColour(),this.sourceBlock_.getColourTertiary());Blockly.DropDownDiv.setCategory(this.sourceBlock_.parentBlock_.getCategory());
Blockly.DropDownDiv.showPositionedByBlock(this,this.sourceBlock_);this.updateSelection_()};
Blockly.FieldNote.prototype.addPianoOctave_=function(a,b,c,d){a+=Blockly.FieldNote.EDGE_PADDING/2;for(var e=Blockly.FieldNote.TOP_MENU_HEIGHT,f=0;f<Blockly.FieldNote.KEY_INFO.length;f++){if(Blockly.FieldNote.KEY_INFO[f].isBlack){a-=Blockly.FieldNote.BLACK_KEY_WIDTH/2;var g=Blockly.FieldNote.BLACK_KEY_WIDTH/2;var h=Blockly.FieldNote.BLACK_KEY_WIDTH;var k=Blockly.FieldNote.BLACK_KEY_HEIGHT;var l=Blockly.FieldNote.BLACK_KEY_COLOR;var m=Blockly.FieldNote.BLACK_KEY_STROKE;var n=c}else h=g=Blockly.FieldNote.WHITE_KEY_WIDTH,
k=Blockly.FieldNote.WHITE_KEY_HEIGHT,l=Blockly.FieldNote.WHITE_KEY_COLOR,m=this.sourceBlock_.getColourTertiary(),n=b;h={d:this.getPianoKeyPath_(a,e,h,k),fill:l,stroke:m};a+=g;g=Blockly.utils.createSvgElement("path",h,n);d&&(d[f]=g,g.setAttribute("data-pitch",Blockly.FieldNote.KEY_INFO[f].pitch),g.setAttribute("data-name",Blockly.FieldNote.KEY_INFO[f].name),g.setAttribute("data-isBlack",Blockly.FieldNote.KEY_INFO[f].isBlack),this.mouseDownWrappers_[f]=Blockly.bindEvent_(g,"mousedown",this,this.onMouseDownOnKey_),
this.mouseEnterWrappers_[f]=Blockly.bindEvent_(g,"mouseenter",this,this.onMouseEnter_))}};Blockly.FieldNote.prototype.getPianoKeyPath_=function(a,b,c,d){return"M"+a+" "+b+" L"+a+" "+(b+d-Blockly.FieldNote.KEY_RADIUS)+" Q"+a+" "+(b+d)+" "+(a+Blockly.FieldNote.KEY_RADIUS)+" "+(b+d)+" L"+(a+c-Blockly.FieldNote.KEY_RADIUS)+" "+(b+d)+" Q"+(a+c)+" "+(b+d)+" "+(a+c)+" "+(b+d-Blockly.FieldNote.KEY_RADIUS)+" L"+(a+c)+" "+b+" L"+a+" "+b};
Blockly.FieldNote.prototype.addOctaveButton_=function(a,b,c){c=Blockly.utils.createSvgElement("g",{},c);var d=Blockly.FieldNote.OCTAVE_BUTTON_SIZE;Blockly.utils.createSvgElement("image",{width:d,height:d,x:a-Blockly.FieldNote.INSET,y:-1*Blockly.FieldNote.INSET},c).setAttributeNS("http://www.w3.org/1999/xlink","xlink:href",Blockly.mainWorkspace.options.pathToMedia+Blockly.FieldNote.ARROW_SVG_PATH);Blockly.utils.createSvgElement("line",{stroke:this.sourceBlock_.getColourTertiary(),x1:a-Blockly.FieldNote.INSET,
y1:0,x2:a-Blockly.FieldNote.INSET,y2:Blockly.FieldNote.TOP_MENU_HEIGHT-Blockly.FieldNote.INSET},c);b&&c.setAttribute("transform","scale(-1, 1) translate("+(-1*Blockly.FieldNote.OCTAVE_BUTTON_SIZE+2*Blockly.FieldNote.INSET)+", 0)");return c};
Blockly.FieldNote.prototype.addCKeyLabel_=function(a,b){return Blockly.utils.createSvgElement("text",{x:a,y:Blockly.FieldNote.TOP_MENU_HEIGHT+Blockly.FieldNote.WHITE_KEY_HEIGHT-Blockly.FieldNote.KEY_LABEL_PADDING,"class":"scratchNotePickerKeyLabel","text-anchor":"middle"},b)};
Blockly.FieldNote.prototype.setCKeyLabelsVisible_=function(a){a?(this.fadeSvgToOpacity_(this.lowCText_,1),this.fadeSvgToOpacity_(this.highCText_,1)):(this.fadeSvgToOpacity_(this.lowCText_,0),this.fadeSvgToOpacity_(this.highCText_,0))};Blockly.FieldNote.prototype.fadeSvgToOpacity_=function(a,b){a.setAttribute("style","opacity: "+b+"; transition: opacity 0.1s;")};
Blockly.FieldNote.prototype.onMouseDownOnKey_=function(a){this.mouseIsDown_=!0;this.mouseUpWrapper_=Blockly.bindEvent_(document.body,"mouseup",this,this.onMouseUp_);this.selectNoteWithMouseEvent_(a)};Blockly.FieldNote.prototype.onMouseUp_=function(){this.mouseIsDown_=!1;Blockly.unbindEvent_(this.mouseUpWrapper_)};Blockly.FieldNote.prototype.onMouseEnter_=function(a){this.mouseIsDown_&&this.selectNoteWithMouseEvent_(a)};
Blockly.FieldNote.prototype.selectNoteWithMouseEvent_=function(a){a=Number(a.target.getAttribute("data-pitch"))+12*this.displayedOctave_;this.setNoteNum_(a);this.playNoteInternal_()};Blockly.FieldNote.prototype.playNoteInternal_=function(){Blockly.FieldNote.playNote_&&Blockly.FieldNote.playNote_(this.getValue(),this.sourceBlock_.parentBlock_.getCategory())};Blockly.FieldNote.playNote_=function(){};
Blockly.FieldNote.prototype.changeOctaveBy_=function(a){this.displayedOctave_+=a;if(0>this.displayedOctave_)this.displayedOctave_=0;else{var b=Math.floor(Blockly.FieldNote.MAX_NOTE/12);this.displayedOctave_>b?this.displayedOctave_=b:(b=Number(this.getText())+12*a,this.setNoteNum_(b),this.animationTarget_=this.fieldEditorWidth_*a*-1,this.animationPos_=0,this.stepOctaveAnimation_(),this.setCKeyLabelsVisible_(!1))}};
Blockly.FieldNote.prototype.stepOctaveAnimation_=function(){1>Math.abs(this.animationPos_-this.animationTarget_)?(this.pianoSVG_.setAttribute("transform","translate(0, 0)"),this.setCKeyLabelsVisible_(!0),this.playNoteInternal_()):(this.animationPos_+=(this.animationTarget_-this.animationPos_)*Blockly.FieldNote.ANIMATION_FRACTION,this.pianoSVG_.setAttribute("transform","translate("+this.animationPos_+",0)"),requestAnimationFrame(this.stepOctaveAnimation_.bind(this)))};
Blockly.FieldNote.prototype.setNoteNum_=function(a){a=this.callValidator(a);this.setValue(a);Blockly.FieldTextInput.htmlInput_.value=a};Blockly.FieldNote.prototype.setText=function(a){Blockly.FieldNote.superClass_.setText.call(this,a);this.textElement_&&(this.updateSelection_(),this.size_.width=0)};Blockly.FieldNote.prototype.noteNumToKeyIndex_=function(a){return Math.floor(a)-12*this.displayedOctave_};
Blockly.FieldNote.prototype.updateSelection_=function(){var a=Number(this.getText());if(null==this.displayedOctave_||a>12*this.displayedOctave_+12||a<12*this.displayedOctave_)this.displayedOctave_=Math.floor(a/12);var b=this.noteNumToKeyIndex_(a);this.keySVGs_.forEach(function(a){"true"===a.getAttribute("data-isBlack")?a.setAttribute("fill",Blockly.FieldNote.BLACK_KEY_COLOR):a.setAttribute("fill",Blockly.FieldNote.WHITE_KEY_COLOR)});this.keySVGs_[b]&&(this.keySVGs_[b].setAttribute("fill",Blockly.FieldNote.KEY_SELECTED_COLOR),
this.noteNameText_.textContent=Blockly.FieldNote.KEY_INFO[b].name+" ("+Math.floor(a)+")",a=12*this.displayedOctave_,this.lowCText_.textContent="C("+a+")",this.highCText_.textContent="C("+(a+12)+")")};Blockly.FieldNote.prototype.classValidator=function(a){if(null===a)return null;a=parseFloat(a||0);if(isNaN(a))return null;0>a&&(a=0);a>Blockly.FieldNote.MAX_NOTE&&(a=Blockly.FieldNote.MAX_NOTE);return String(a)};Blockly.Field.register("field_note",Blockly.FieldNote);Blockly.FieldTextInputRemovable=function(a,b,c){Blockly.FieldTextInputRemovable.superClass_.constructor.call(this,a,b,c)};goog.inherits(Blockly.FieldTextInputRemovable,Blockly.FieldTextInput);
Blockly.FieldTextInputRemovable.prototype.showEditor_=function(){Blockly.FieldTextInputRemovable.superClass_.showEditor_.call(this);var a=Blockly.WidgetDiv.DIV;a.className+=" removableTextInput";var b=goog.dom.createDom("IMG","blocklyTextRemoveIcon");b.setAttribute("src",Blockly.mainWorkspace.options.pathToMedia+"icons/remove.svg");this.removeButtonMouseWrapper_=Blockly.bindEvent_(b,"mousedown",this,this.removeCallback_);a.appendChild(b)};
Blockly.FieldTextInputRemovable.prototype.removeCallback_=function(){this.sourceBlock_&&this.sourceBlock_.removeFieldCallback?this.sourceBlock_.removeFieldCallback(this):console.warn("Expected a source block with removeFieldCallback")};Blockly.FieldTextInputRemovable.fromJson=function(a){var b=Blockly.utils.replaceMessageReferences(a.text);b=new Blockly.FieldTextInputRemovable(b,a["class"]);"boolean"==typeof a.spellcheck&&b.setSpellcheck(a.spellcheck);return b};
Blockly.Field.register("field_input_removable",Blockly.FieldTextInputRemovable);Blockly.FieldTextDropdown=function(a,b,c,d){this.menuGenerator_=b;Blockly.FieldDropdown.prototype.trimOptions_.call(this);Blockly.FieldTextDropdown.superClass_.constructor.call(this,a,c,d);this.addArgType("textdropdown")};goog.inherits(Blockly.FieldTextDropdown,Blockly.FieldTextInput);Blockly.FieldTextDropdown.fromJson=function(a){var b=new Blockly.FieldTextDropdown(a.text,a.options);"boolean"==typeof a.spellcheck&&b.setSpellcheck(a.spellcheck);return b};
Blockly.FieldTextDropdown.prototype.init=function(){this.fieldGroup_||(Blockly.FieldTextDropdown.superClass_.init.call(this),this.arrow_||(this.arrowSize_=12,this.arrowX_=0,this.arrowY_=11,this.arrow_=Blockly.utils.createSvgElement("image",{height:this.arrowSize_+"px",width:this.arrowSize_+"px"}),this.arrow_.setAttributeNS("http://www.w3.org/1999/xlink","xlink:href",Blockly.mainWorkspace.options.pathToMedia+"dropdown-arrow-dark.svg"),this.arrow_.style.cursor="pointer",this.fieldGroup_.appendChild(this.arrow_),
this.mouseUpWrapper_=Blockly.bindEvent_(this.arrow_,"mouseup",this,this.showDropdown_)),this.disableColourChange_=!0)};Blockly.FieldTextDropdown.prototype.dispose=function(){this.mouseUpWrapper_&&(Blockly.unbindEvent_(this.mouseUpWrapper_),this.mouseUpWrapper_=null,Blockly.Touch.clearTouchIdentifier());Blockly.FieldTextDropdown.superClass_.dispose.call(this)};
Blockly.FieldTextDropdown.prototype.showEditor_=function(){this.dropDownOpen_||Blockly.FieldTextDropdown.superClass_.showEditor_.call(this,null,null,!0,function(){Blockly.WidgetDiv.hide();this.showDropdown_();Blockly.Touch.clearTouchIdentifier()})};Blockly.FieldTextDropdown.prototype.getOptions_=Blockly.FieldDropdown.prototype.getOptions_;Blockly.FieldTextDropdown.prototype.positionArrow=Blockly.FieldDropdown.prototype.positionArrow;Blockly.FieldTextDropdown.prototype.showDropdown_=Blockly.FieldDropdown.prototype.showEditor_;
Blockly.FieldTextDropdown.prototype.onHide=Blockly.FieldDropdown.prototype.onHide;Blockly.Field.register("field_textdropdown",Blockly.FieldTextDropdown);Blockly.FieldNumber=function(a,b,c,d,e){this.min_=b;this.max_=c;b=this.getNumRestrictor(b,c,d);a=a&&!isNaN(a)?String(a):"0";Blockly.FieldNumber.superClass_.constructor.call(this,a,e,b);this.addArgType("number")};goog.inherits(Blockly.FieldNumber,Blockly.FieldTextInput);Blockly.FieldNumber.fromJson=function(a){return new Blockly.FieldNumber(a.value,a.min,a.max,a.precision)};Blockly.FieldNumber.DROPDOWN_WIDTH=168;Blockly.FieldNumber.NUMPAD_BUTTONS="789456123.0- ".split("");
Blockly.FieldNumber.NUMPAD_DELETE_ICON='data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 40 40"><path d="M28.89,11.45H16.79a2.86,2.86,0,0,0-2,.84L9.09,18a2.85,2.85,0,0,0,0,4l5.69,5.69a2.86,2.86,0,0,0,2,.84h12.1a2.86,2.86,0,0,0,2.86-2.86V14.31A2.86,2.86,0,0,0,28.89,11.45ZM27.15,22.73a1,1,0,0,1,0,1.41,1,1,0,0,1-.71.3,1,1,0,0,1-.71-0.3L23,21.41l-2.73,2.73a1,1,0,0,1-1.41,0,1,1,0,0,1,0-1.41L21.59,20l-2.73-2.73a1,1,0,0,1,0-1.41,1,1,0,0,1,1.41,0L23,18.59l2.73-2.73a1,1,0,1,1,1.42,1.41L24.42,20Z" fill="'+
Blockly.Colours.numPadText+'"/></svg>';Blockly.FieldNumber.activeField_=null;Blockly.FieldNumber.prototype.getNumRestrictor=function(a,b,c){this.setConstraints_(a,b,c);a="[\\d]";this.decimalAllowed_&&(a+="|[\\.]");this.negativeAllowed_&&(a+="|[-]");this.exponentialAllowed_&&(a+="|[eE]");return new RegExp(a)};
Blockly.FieldNumber.prototype.setConstraints_=function(a,b,c){this.decimalAllowed_="undefined"==typeof c||isNaN(c)||0==c||Math.floor(c)!=c;this.negativeAllowed_="undefined"==typeof a||isNaN(a)||0>a;this.exponentialAllowed_=this.decimalAllowed_};Blockly.FieldNumber.prototype.showEditor_=function(){Blockly.FieldNumber.activeField_=this;var a=this.useTouchInteraction_;Blockly.FieldNumber.superClass_.showEditor_.call(this,!1,a);a&&this.showNumPad_()};
Blockly.FieldNumber.prototype.showNumPad_=function(){Blockly.DropDownDiv.hideWithoutAnimation();Blockly.DropDownDiv.clearContent();var a=Blockly.DropDownDiv.getContentDiv();a.setAttribute("role","menu");a.setAttribute("aria-haspopup","true");this.addButtons_(a);Blockly.DropDownDiv.setColour(this.sourceBlock_.parentBlock_.getColour(),this.sourceBlock_.getColourTertiary());a.style.width=Blockly.FieldNumber.DROPDOWN_WIDTH+"px";this.position_()};
Blockly.FieldNumber.prototype.position_=function(){var a=this.sourceBlock_.workspace.scale,b=this.sourceBlock_.getHeightWidth();b.width*=a;b.height*=a;var c=this.getAbsoluteXY_();a=c.x+b.width/2;b=c.y+b.height;c=c.y;Blockly.DropDownDiv.setBoundsElement(this.sourceBlock_.workspace.getParentSvg().parentNode);Blockly.DropDownDiv.show(this,a,b,a,c,this.onHide_.bind(this))};
Blockly.FieldNumber.prototype.addButtons_=function(a){for(var b=this.sourceBlock_.parentBlock_.getColour(),c=this.sourceBlock_.parentBlock_.getColourTertiary(),d=Blockly.FieldNumber.NUMPAD_BUTTONS,e=0,f;f=d[e];e++){var g=document.createElement("button");g.setAttribute("role","menuitem");g.setAttribute("class","blocklyNumPadButton");g.setAttribute("style","background:"+b+";border: 1px solid "+c+";");g.title=f;g.innerHTML=f;Blockly.bindEvent_(g,"mousedown",g,Blockly.FieldNumber.numPadButtonTouch);if("."!=
f||this.decimalAllowed_)if("-"!=f||this.negativeAllowed_)if(" "!=f||this.negativeAllowed_)" "==f&&this.negativeAllowed_&&g.setAttribute("style","visibility: hidden");else continue;else continue;else g.setAttribute("style","visibility: hidden");a.appendChild(g)}d=document.createElement("button");d.setAttribute("role","menuitem");d.setAttribute("class","blocklyNumPadButton");d.setAttribute("style","background:"+b+";border: 1px solid "+c+";");d.title="Delete";b=document.createElement("img");b.src=Blockly.FieldNumber.NUMPAD_DELETE_ICON;
d.appendChild(b);Blockly.bindEvent_(d,"mousedown",null,Blockly.FieldNumber.numPadEraseButtonTouch);a.appendChild(d)};Blockly.FieldNumber.numPadButtonTouch=function(a){var b=this.innerHTML,c=Blockly.FieldTextInput.htmlInput_.value,d=Blockly.FieldTextInput.htmlInput_.selectionStart,e=Blockly.FieldTextInput.htmlInput_.selectionEnd;c=c.slice(0,d)+b+c.slice(e);Blockly.FieldNumber.updateDisplay_(c,d+b.length);Blockly.Touch.clearTouchIdentifier();a.preventDefault()};
Blockly.FieldNumber.numPadEraseButtonTouch=function(a){var b=Blockly.FieldTextInput.htmlInput_.value,c=Blockly.FieldTextInput.htmlInput_.selectionStart,d=Blockly.FieldTextInput.htmlInput_.selectionEnd;c==d&&(c=Math.max(0,c-1));b=b.slice(0,c)+b.slice(d);Blockly.FieldNumber.updateDisplay_(b,c);Blockly.Touch.clearTouchIdentifier();a.preventDefault()};
Blockly.FieldNumber.updateDisplay_=function(a,b){var c=Blockly.FieldTextInput.htmlInput_;c.value=a;Blockly.FieldNumber.superClass_.resizeEditor_.call(Blockly.FieldNumber.activeField_);c.setSelectionRange(b,b);c.scrollLeft=c.scrollWidth;Blockly.FieldNumber.activeField_.validate_()};Blockly.FieldNumber.prototype.onHide_=function(){Blockly.DropDownDiv.content_.removeAttribute("role");Blockly.DropDownDiv.content_.removeAttribute("aria-haspopup")};
Blockly.FieldNumber.prototype.classValidator=function(a){if(null===a)return null;a=parseFloat(a||0);if(isNaN(a))return null;a<this.min_&&(a=this.min_);a>this.max_&&(a=this.max_);return String(a)};Blockly.Field.register("field_number",Blockly.FieldNumber);/*

 Copyright 2020 Google LLC
 SPDX-License-Identifier: Apache-2.0
*/
Blockly.FieldSlider=function(a,b,c,d,e){Blockly.FieldSlider.superClass_.constructor.call(this,a,b,c,d,e);this.boundEvents_=[];this.sliderInput_=null};goog.inherits(Blockly.FieldSlider,Blockly.FieldNumber);Blockly.FieldSlider.fromJson=function(a){return new Blockly.FieldSlider(a.value,a.min,a.max,a.precision)};Blockly.FieldSlider.prototype.dispose_=function(){return function(){for(var a in this.boundEvents_)Blockly.unbindEvent_(a);this.sliderInput_=null}};
Blockly.FieldSlider.prototype.showEditor_=function(){Blockly.FieldSlider.superClass_.showEditor_.call(this,this.useTouchInteraction_);var a=document.createElement("div");a.className="scratchSliderDiv";var b=document.createElement("input");b.setAttribute("type","range");b.setAttribute("min",this.min_);b.setAttribute("max",this.max_);b.setAttribute("step",this.precision_);b.setAttribute("value",this.getValue());b.className="scratchFieldSlider";b.style.setProperty("--trackColor",this.sourceBlock_.parentBlock_.getColourTertiary());
a.appendChild(b);this.sliderInput_=b;Blockly.DropDownDiv.getContentDiv().appendChild(a);Blockly.DropDownDiv.setColour(this.sourceBlock_.parentBlock_.getColour(),this.sourceBlock_.getColourTertiary());Blockly.DropDownDiv.setCategory(this.sourceBlock_.parentBlock_.getCategory());Blockly.DropDownDiv.showPositionedByBlock(this,this.sourceBlock_);this.boundEvents_.push(Blockly.bindEvent_(b,"input",this,this.onSliderChange_));this.boundEvents_.push(Blockly.bindEventWithChecks_(Blockly.FieldTextInput.htmlInput_,
"keyup",this,this.updateSlider_));this.boundEvents_.push(Blockly.bindEventWithChecks_(Blockly.FieldTextInput.htmlInput_,"keypress",this,this.updateSlider_))};Blockly.FieldSlider.prototype.onSliderChange_=function(){Blockly.FieldTextInput.htmlInput_.value=this.sliderInput_.value;this.setValue(this.sliderInput_.value);this.validate_();this.resizeEditor_()};Blockly.FieldSlider.prototype.updateSlider_=function(){this.sliderInput_&&this.sliderInput_.setAttribute("value",this.getValue())};
Blockly.Field.register("field_slider",Blockly.FieldSlider);Blockly.FieldNumberDropdown=function(a,b,c,d,e,f){this.setConstraints_=Blockly.FieldNumber.prototype.setConstraints_;c=Blockly.FieldNumber.prototype.getNumRestrictor.call(this,c,d,e);Blockly.FieldNumberDropdown.superClass_.constructor.call(this,a,b,f,c);this.addArgType("numberdropdown")};goog.inherits(Blockly.FieldNumberDropdown,Blockly.FieldTextDropdown);Blockly.FieldNumberDropdown.fromJson=function(a){return new Blockly.FieldNumberDropdown(a.value,a.options,a.min,a.max,a.precision)};
Blockly.Field.register("field_numberdropdown",Blockly.FieldNumberDropdown);var arrmatrix=[],arrcustommatrix=[];
Blockly.FieldMatrix=function(a){Blockly.FieldMatrix.superClass_.constructor.call(this,a);this.addArgType("matrix");this.ledThumbNodes_=[];this.ledButtons_=[];this.stopDragging=this.startDragging=this.addEventListener_=this.mycustommatrixtemplate_=this.mycustommatrix_=this.matrix_="";this.mymatrix1_="00001111111100000000000000110000000000000110000000000000110000000000000110000000000000110000000000000110000000000000111111110000";this.mymatrix2_="00000000000000000011111001111100001000100100010000100010010001000010001001000100001000100100010000111110011111000000000000000000";this.mymatrix3_=
"00000000000000000000110000110000000111100111100000011110011110000001111001111000000111100111100000001100001100000000000000000000";this.mymatrix4_="00000000000000000000000000000000011111100111111000111100001111000001100000011000000000000000000000000000000000000000000000000000";this.mymatrix5_="00000000000000000000000000000000001111100111110000001100000110000001000000100000001111100111110000000000000000000000000000000000";this.matrixReleaseWrapper_=this.matrixMoveWrapper_=this.matrixTouchWrapper_=
this.mycustomButtonDragger_=this.mycustomButtonWrapper_=this.fillButtonWrapper_=this.mytemplatematrix5ButtonWrapper_=this.mytemplatematrix4ButtonWrapper_=this.mytemplatematrix3ButtonWrapper_=this.mytemplatematrix2ButtonWrapper_=this.mytemplatematrix1ButtonWrapper_=this.mouseDownWrapper_=this.paintStyle_=this.arrow_=this.matrixStage_=null};goog.inherits(Blockly.FieldMatrix,Blockly.Field);Blockly.FieldMatrix.fromJson=function(a){return new Blockly.FieldMatrix(a.matrix)};
Blockly.FieldMatrix.THUMBNAIL_SIZE=26;var buttonDiv;Blockly.FieldMatrix.THUMBNAIL_NODE_SIZE=4;Blockly.FieldMatrix.THUMBNAIL_NODE_PAD=1;Blockly.FieldMatrix.ARROW_SIZE=12;Blockly.FieldMatrix.MATRIX_NODE_SIZE=32;Blockly.FieldMatrix.MATRIX_NODE_RADIUS=4;Blockly.FieldMatrix.MATRIX_NODE_PAD=8;Blockly.FieldMatrix.ZEROS="00000000000000000000000001111111111111111111111111000000000000000000000000011111111111111111111111111111111111111111111111111000";Blockly.FieldMatrix.ONES="00000000000000000000000001111111111111111111111111000000000000000000000000011111111111111111111111111111111111111111111111111000";
Blockly.FieldMatrix.CLEARMATRIX="00000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000";Blockly.FieldMatrix.FILLMATRIX="11111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111";
Blockly.FieldMatrix.prototype.init=function(){if(!this.fieldGroup_){if(this.sourceBlock_.getParent()){var a=this.sourceBlock_.getParent();this.sourceBlock_.setColour(a.getColour(),a.getColourSecondary(),a.getColourTertiary())}this.fieldGroup_=Blockly.utils.createSvgElement("g",{},null);this.size_.width=Blockly.FieldMatrix.THUMBNAIL_SIZE+Blockly.FieldMatrix.ARROW_SIZE+1.5*Blockly.BlockSvg.DROPDOWN_ARROW_PADDING+40;this.size_.height=43;this.sourceBlock_.getSvgRoot().appendChild(this.fieldGroup_);a=
Blockly.utils.createSvgElement("g",{transform:"translate("+(Blockly.BlockSvg.DROPDOWN_ARROW_PADDING/2+2)+", "+((this.size_.height-Blockly.FieldMatrix.THUMBNAIL_SIZE)/2-8)+")","pointer-events":"bounding-box",cursor:"pointer"},this.fieldGroup_);this.ledThumbNodes_=[];for(var b=Blockly.FieldMatrix.THUMBNAIL_NODE_SIZE,c=Blockly.FieldMatrix.THUMBNAIL_NODE_PAD,d=0;8>d;d++){for(var e=0;16>e;e++)this.ledThumbNodes_.push(Blockly.utils.createSvgElement("rect",{x:(b+c)*e+c,y:(b+c)*d+c,width:b,height:b,rx:c,
ry:c},a));a.style.cursor="default";this.updateMatrix_()}this.mouseDownWrapper_=Blockly.bindEventWithChecks_(this.getClickTarget_(),"mousedown",this,this.onMouseDown_);arrmatrix=[];arrmatrix.push(this.mymatrix1_);arrmatrix.push(this.mymatrix2_);arrmatrix.push(this.mymatrix3_);arrmatrix.push(this.mymatrix4_);arrmatrix.push(this.mymatrix5_)}};
Blockly.FieldMatrix.prototype.setValue=function(a,b){a&&a!==this.matrix_&&(this.sourceBlock_&&Blockly.Events.isEnabled()&&Blockly.Events.fire(new Blockly.Events.Change(this.sourceBlock_,"field",this.name,this.matrix_,a)),this.matrix_=a+=Blockly.FieldMatrix.ZEROS.substr(0,128-a.length),this.updateMatrix_())};Blockly.FieldMatrix.prototype.getValue=function(){return String(this.matrix_)};
Blockly.FieldMatrix.prototype.showEditor_=function(){Blockly.DropDownDiv.hideWithoutAnimation();Blockly.DropDownDiv.clearContent();var a=Blockly.DropDownDiv.getContentDiv();a.setAttribute("class","blocklyDropDownContentMatrix");this.matrixStage_=Blockly.utils.createSvgElement("svg",{xmlns:"http://www.w3.org/2000/svg","xmlns:html":"http://www.w3.org/1999/xhtml","xmlns:xlink":"http://www.w3.org/1999/xlink",version:"1.1",height:"350px",width:"650px"},a);this.matrixStage2_=Blockly.utils.createSvgElement("svg",
{xmlns:"http://www.w3.org/2000/svg","xmlns:html":"http://www.w3.org/1999/xhtml","xmlns:xlink":"http://www.w3.org/1999/xlink",version:"1.1",height:"350px",width:"100px"},a);this.ledButtons_=[];var b=document.createElement("button");b.setAttribute("id",":"+h);b.setAttribute("role","menuitem");b.setAttribute("class","myDownButtonFill");b.title="clear";b.style.width="60px";b.style.height="60px";var c=this.sourceBlock_.getColour();c=this.sourceBlock_.getColourTertiary();b.setAttribute("aria-selected",
"true");b.style.backgroundColor=c;b.style.borderColor=this.sourceBlock_.getColourTertiary();this.clearButtonWrapper_=Blockly.bindEvent_(b,"click",this,this.myclearMatrix_);c=document.createElement("img");c.src=Blockly.mainWorkspace.options.pathToMedia+"cleanMatrix.png";var d=Blockly.mainWorkspace.options.pathToMedia+"cleanMatrix.png";b.setAttribute("data-value",d);c.setAttribute("data-value",d);b.appendChild(c);var e=document.createElement("button");e.setAttribute("id",":"+h);e.setAttribute("role",
"menuitem");e.setAttribute("class","myDownButtonClean");e.title="fill";e.style.width="60px";e.style.height="60px";c=this.sourceBlock_.getColour();c=this.sourceBlock_.getColourTertiary();e.setAttribute("aria-selected","true");e.style.backgroundColor=c;e.style.borderColor=this.sourceBlock_.getColourTertiary();this.clearButtonWrapper_=Blockly.bindEvent_(e,"click",this,this.myfillMatrix_);c=document.createElement("img");c.src=Blockly.mainWorkspace.options.pathToMedia+"fillMatrix.png";d=Blockly.mainWorkspace.options.pathToMedia+
"fillMatrix.png";e.setAttribute("data-value",d);c.setAttribute("data-value",d);e.appendChild(c);var f=document.createElement("button");f.setAttribute("id",":"+h);f.setAttribute("role","menuitem");f.setAttribute("class","myDownButtonOk");f.title="ok";f.style.width="50px";f.style.height="50px";c=this.sourceBlock_.getColour();c=this.sourceBlock_.getColourTertiary();f.setAttribute("aria-selected","true");f.style.backgroundColor=c;f.style.borderColor=this.sourceBlock_.getColourTertiary();this.clearButtonWrapper_=
Blockly.bindEvent_(f,"click",this,this.myfuncOk_);c=document.createElement("img");c.src=Blockly.mainWorkspace.options.pathToMedia+"okey.png";d=Blockly.mainWorkspace.options.pathToMedia+"okey.png";f.setAttribute("data-value",d);c.setAttribute("data-value",d);f.appendChild(c);var g=document.createElement("button");g.setAttribute("id",":"+h);g.setAttribute("role","menuitem");g.setAttribute("class","myDownButtonSave");g.title="ok";g.style.width="60px";g.style.height="60px";c=this.sourceBlock_.getColour();
c=this.sourceBlock_.getColourTertiary();g.setAttribute("aria-selected","true");g.style.backgroundColor=c;g.style.borderColor=this.sourceBlock_.getColourTertiary();this.clearButtonWrapper_=Blockly.bindEvent_(g,"click",this,this.mysaveMatrix_);c=document.createElement("img");c.src=Blockly.mainWorkspace.options.pathToMedia+"save.png";d=Blockly.mainWorkspace.options.pathToMedia+"save.png";g.setAttribute("data-value",d);c.setAttribute("data-value",d);g.appendChild(c);a.appendChild(b);a.appendChild(e);
a.appendChild(f);a.appendChild(g);for(var h=0;8>h;h++)for(c=0;16>c;c++)d=Blockly.utils.createSvgElement("rect",{x:Blockly.FieldMatrix.MATRIX_NODE_SIZE*c+Blockly.FieldMatrix.MATRIX_NODE_PAD*(c+1)+"px",y:Blockly.FieldMatrix.MATRIX_NODE_SIZE*h+Blockly.FieldMatrix.MATRIX_NODE_PAD*(h+1)+"px",width:Blockly.FieldMatrix.MATRIX_NODE_SIZE,height:Blockly.FieldMatrix.MATRIX_NODE_SIZE,rx:Blockly.FieldMatrix.MATRIX_NODE_RADIUS,ry:Blockly.FieldMatrix.MATRIX_NODE_RADIUS},this.matrixStage_),this.matrixStage_.appendChild(d),
this.ledButtons_.push(d);buttonDiv=document.createElement("div");buttonDiv.setAttribute("class","mycontainer");buttonDiv.setAttribute("id","bottomButonZone");h=document.createElement("div");h.className="scratchMatrixButtonDiv";c=this.createButton_(0);h.appendChild(c);g=document.createElement("div");g.className="scratchMatrixButtonDiv";d=this.createButton_(1);g.appendChild(d);var k=document.createElement("div");k.className="scratchMatrixButtonDiv";b=this.createButton_(2);k.appendChild(b);var l=document.createElement("div");
l.className="scratchMatrixButtonDiv";e=this.createButton_(3);l.appendChild(e);var m=document.createElement("div");m.className="scratchMatrixButtonDiv";f=this.createButton_(4);m.appendChild(f);var n=document.createElement("div");n.className="scratchMatrixButtonDiv";var p=this.createButton_(0);n.appendChild(p);n=document.createElement("div");n.className="scratchMatrixButtonDiv";p=this.createButton_(0);n.appendChild(p);buttonDiv.appendChild(h);buttonDiv.appendChild(g);buttonDiv.appendChild(k);buttonDiv.appendChild(l);
buttonDiv.appendChild(m);for(h=0;h<arrcustommatrix.length;h++)g=document.createElement("div"),g.className="scratchMatrixButtonDiv5",k=this.createCustomTemplateButton_(h),k.setAttribute("id","mybtn"+h),g.setAttribute("id","mybtn"+h),k.setAttribute("name",h),g.appendChild(k),buttonDiv.appendChild(g),Blockly.bindEvent_(g,"click",this,this.myfuncMatrixCustom_),Blockly.bindEvent_(k,"click",this,this.myfuncMatrixCustom_);this.mytemplatematrix1ButtonWrapper_=Blockly.bindEvent_(c,"click",this,this.myfuncMatrix1_);
this.mytemplatematrix2ButtonWrapper_=Blockly.bindEvent_(d,"click",this,this.myfuncMatrix2_);this.mytemplatematrix3ButtonWrapper_=Blockly.bindEvent_(b,"click",this,this.myfuncMatrix3_);this.mytemplatematrix4ButtonWrapper_=Blockly.bindEvent_(e,"click",this,this.myfuncMatrix4_);this.mytemplatematrix5ButtonWrapper_=Blockly.bindEvent_(f,"click",this,this.myfuncMatrix5_);var q=!1,r,t,u=function(a){q=!0;var b=document.getElementById("bottomButonZone");r=a.pageX-b.offsetLeft;t=b.scrollLeft},v=function(a){q=
!1};Blockly.FieldMatrix.prototype.myclearMatrix2_=function(a){var b=document.getElementById("bottomButonZone");b.addEventListener("mousedown",u,!1);b.addEventListener("mouseup",v,!1);b.addEventListener("mouseleave",v,!1);a.preventDefault();q&&(b.scrollLeft=t-(a.pageX-b.offsetLeft-r))};Blockly.FieldMatrix.prototype.startDragging2_=function(a){};Blockly.FieldMatrix.prototype.stopDragging2_=function(a){0==a.button&&(q=!1)};Blockly.bindEvent_(buttonDiv,"mousemove",this,this.myclearMatrix2_);b=document.createElement("button");
b.setAttribute("id",":"+h);b.setAttribute("role","menuitem");b.setAttribute("class","myButtonRight");b.title="fill";b.style.width="30px";b.style.height="80px";c=this.sourceBlock_.getColour();c=this.sourceBlock_.getColourTertiary();b.setAttribute("aria-selected","true");b.style.backgroundColor=c;b.style.borderColor=this.sourceBlock_.getColourTertiary();Blockly.bindEvent_(b,"click",this,this.myscrollright_);c=document.createElement("img");c.src=Blockly.mainWorkspace.options.pathToMedia+"sagok.png";
d=Blockly.mainWorkspace.options.pathToMedia+"sagok.png";b.setAttribute("data-value",d);c.setAttribute("data-value",d);b.appendChild(c);e=document.createElement("button");e.setAttribute("id",":"+h);e.setAttribute("role","menuitem");e.setAttribute("class","myButtonLeft");e.title="fill";e.style.width="30px";e.style.height="80px";c=this.sourceBlock_.getColour();c=this.sourceBlock_.getColourTertiary();e.setAttribute("aria-selected","true");e.style.backgroundColor=c;e.style.borderColor=this.sourceBlock_.getColourTertiary();
this.clearButtonWrapper_=Blockly.bindEvent_(e,"click",this,this.myscrollleft_);c=document.createElement("img");c.src=Blockly.mainWorkspace.options.pathToMedia+"oksol.png";d=Blockly.mainWorkspace.options.pathToMedia+"oksol.png";e.setAttribute("data-value",d);c.setAttribute("data-value",d);e.appendChild(c);buttonDiv.appendChild(e);buttonDiv.appendChild(b);h=document.createElement("div");a.appendChild(buttonDiv);a.appendChild(h);Blockly.DropDownDiv.setColour(this.sourceBlock_.getColour(),this.sourceBlock_.getColourTertiary());
Blockly.DropDownDiv.setCategory(this.sourceBlock_.getCategory());Blockly.DropDownDiv.showPositionedByBlock(this,this.sourceBlock_);this.matrixTouchWrapper_=Blockly.bindEvent_(this.matrixStage_,"mousedown",this,this.onMouseDown);this.updateMatrix_()};this.nodeCallback_=function(a,b){console.log(b)};
Blockly.FieldMatrix.prototype.createButton_=function(a){for(var b=Blockly.utils.createSvgElement("svg",{xmlns:"http://www.w3.org/2000/svg","xmlns:html":"http://www.w3.org/1999/xhtml","xmlns:xlink":"http://www.w3.org/1999/xlink",version:"1.1",height:Blockly.FieldMatrix.MATRIX_NODE_SIZE/5*13+"px",width:Blockly.FieldMatrix.MATRIX_NODE_SIZE/5*25+"px"}),c=Blockly.FieldMatrix.MATRIX_NODE_SIZE/5,d=Blockly.FieldMatrix.MATRIX_NODE_SIZE/16,e=0,f=0,g=0;8>g;g++){this.mycustommatrix_=arrmatrix[a];f++;for(var h=
0;16>h;h++)Blockly.utils.createSvgElement("rect",{x:(c+d)*h+d,y:(c+d)*g+d,width:c,height:c,rx:d,ry:d,fill:"0"===this.mycustommatrix_[e]?this.sourceBlock_.colourSecondary_:"#FFFFFF"},b),e++}return b};
Blockly.FieldMatrix.prototype.createCustomTemplateButton_=function(a){var b=Blockly.utils.createSvgElement("svg",{xmlns:"http://www.w3.org/2000/svg","xmlns:html":"http://www.w3.org/1999/xhtml","xmlns:xlink":"http://www.w3.org/1999/xlink",version:"1.1",height:Blockly.FieldMatrix.MATRIX_NODE_SIZE/5*13+"px",width:Blockly.FieldMatrix.MATRIX_NODE_SIZE/5*25+"px"}),c=Blockly.FieldMatrix.MATRIX_NODE_SIZE/5,d=Blockly.FieldMatrix.MATRIX_NODE_SIZE/16,e=0,f=0;b.setAttribute("id","mybtn"+a);for(var g=0;8>g;g++){this.mycustommatrixtemplate_=
arrcustommatrix[a];f++;for(var h=0;16>h;h++){var k=Blockly.utils.createSvgElement("rect",{x:(c+d)*h+d,y:(c+d)*g+d,width:c,height:c,rx:d,ry:d,fill:"0"===this.mycustommatrixtemplate_[e]?this.sourceBlock_.colourSecondary_:"#FFFFFF"},b);k.setAttribute("id","mybtn"+a);Blockly.bindEvent_(k,"click",this,this.myfuncMatrixCustom_);e++}}Blockly.bindEvent_(b,"click",this,this.myfuncMatrixCustom_);return b};
Blockly.FieldMatrix.prototype.updateMatrix_=function(){for(var a=0;a<this.matrix_.length;a++)"0"===this.matrix_[a]?(this.fillMatrixNode_(this.ledButtons_,a,this.sourceBlock_.colourSecondary_),this.fillMatrixNode_(this.ledThumbNodes_,a,this.sourceBlock_.colour_)):(this.fillMatrixNode_(this.ledButtons_,a,"#FFFFFF"),this.fillMatrixNode_(this.ledThumbNodes_,a,"#FFFFFF"))};Blockly.FieldMatrix.prototype.myfuncMatrix1_=function(a){0==a.button&&this.setValue(this.mymatrix1_)};
Blockly.FieldMatrix.prototype.myfuncMatrix2_=function(a){0==a.button&&this.setValue(this.mymatrix2_)};Blockly.FieldMatrix.prototype.myfuncMatrix3_=function(a){0==a.button&&this.setValue(this.mymatrix3_)};Blockly.FieldMatrix.prototype.myfuncMatrix4_=function(a){0==a.button&&this.setValue(this.mymatrix4_)};Blockly.FieldMatrix.prototype.myfuncMatrix5_=function(a){0==a.button&&this.setValue(this.mymatrix5_)};
Blockly.FieldMatrix.prototype.myfuncMatrixCustom_=function(a){a=a.srcElement.id.slice(5,6);a=parseInt(a);this.setValue(arrcustommatrix[a])};Blockly.FieldMatrix.prototype.clearMatrix_=function(a){0==a.button&&this.setValue(Blockly.FieldMatrix.ZEROS)};Blockly.FieldMatrix.prototype.myclearMatrix_=function(a){0==a.button&&this.setValue(Blockly.FieldMatrix.CLEARMATRIX)};Blockly.FieldMatrix.prototype.myfillMatrix_=function(a){0==a.button&&this.setValue(Blockly.FieldMatrix.FILLMATRIX)};
Blockly.FieldMatrix.prototype.myfuncOk_=function(a){0==a.button&&Blockly.DropDownDiv.hideWithoutAnimation()};
Blockly.FieldMatrix.prototype.mysaveMatrix_=function(a){if(0==a.button){a=Blockly.utils.createSvgElement("svg",{xmlns:"http://www.w3.org/2000/svg","xmlns:html":"http://www.w3.org/1999/xhtml","xmlns:xlink":"http://www.w3.org/1999/xlink",version:"1.1",height:Blockly.FieldMatrix.MATRIX_NODE_SIZE/5*13+"px",width:Blockly.FieldMatrix.MATRIX_NODE_SIZE/5*25+"px"});var b=Blockly.FieldMatrix.MATRIX_NODE_SIZE/5,c=Blockly.FieldMatrix.MATRIX_NODE_SIZE/16,d=0;arrcustommatrix.push(this.matrix_);var e=arrcustommatrix.length-
1;a.setAttribute("id","mybtn"+e);for(var f=0;8>f;f++){for(var g=0;16>g;g++){var h=Blockly.utils.createSvgElement("rect",{x:(b+c)*g+c,y:(b+c)*f+c,width:b,height:b,rx:c,ry:c,fill:"0"===this.matrix_[d]?this.sourceBlock_.colourSecondary_:"#FFFFFF"},a);h.setAttribute("id","mybtn"+e);d++}Blockly.bindEvent_(h,"click",this,this.myfuncMatrixCustom_)}Blockly.bindEvent_(a,"click",this,this.myfuncMatrixCustom_);h=document.createElement("div");h.className="scratchMatrixButtonDiv";h.appendChild(a);buttonDiv.appendChild(h);
var k=document.getElementById("bottomButonZone"),l=0,m=setInterval(function(){k.scrollLeft+=50;l+=10;350<=l&&window.clearInterval(m)},40)}};Blockly.FieldMatrix.prototype.myscrollright_=function(a){if(0==a.button)var b=document.getElementById("bottomButonZone"),c=0,d=setInterval(function(){b.scrollLeft+=48;c+=10;110<=c&&window.clearInterval(d)},50)};
Blockly.FieldMatrix.prototype.myscrollleft_=function(a){if(0==a.button)var b=document.getElementById("bottomButonZone"),c=0,d=setInterval(function(){b.scrollLeft-=48;c+=10;110<=c&&window.clearInterval(d)},50)};Blockly.FieldMatrix.prototype.fillMatrix_=function(a){0==a.button&&this.setValue(Blockly.FieldMatrix.ONES)};Blockly.FieldMatrix.prototype.mycustomMatrix_=function(a){0==a.button&&this.setValue(this.mymatrix3_)};
Blockly.FieldMatrix.prototype.fillMatrixNode_=function(a,b,c){a&&a[b]&&c&&a[b].setAttribute("fill",c)};Blockly.FieldMatrix.prototype.setLEDNode_=function(a,b){0>a||127<a||(a=this.matrix_.substr(0,a)+b+this.matrix_.substr(a+1),this.setValue(a))};Blockly.FieldMatrix.prototype.fillLEDNode_=function(a){0>a||127<a||this.setLEDNode_(a,"1")};Blockly.FieldMatrix.prototype.clearLEDNode_=function(a){0>a||127<a||this.setLEDNode_(a,"0")};
Blockly.FieldMatrix.prototype.toggleLEDNode_=function(a){0>a||127<a||("0"===this.matrix_.charAt(a)?this.setLEDNode_(a,"1"):this.setLEDNode_(a,"0"))};
Blockly.FieldMatrix.prototype.onMouseDown=function(a){this.matrixMoveWrapper_=Blockly.bindEvent_(document.body,"mousemove",this,this.onMouseMove);this.matrixReleaseWrapper_=Blockly.bindEvent_(document.body,"mouseup",this,this.onMouseUp);a=this.checkForLED_(a);-1<a?("0"===this.matrix_.charAt(a)?this.paintStyle_="fill":this.paintStyle_="clear",this.toggleLEDNode_(a),this.updateMatrix_()):this.paintStyle_=null};
Blockly.FieldMatrix.prototype.onMouseUp=function(){Blockly.unbindEvent_(this.matrixMoveWrapper_);Blockly.unbindEvent_(this.matrixReleaseWrapper_);this.paintStyle_=null};Blockly.FieldMatrix.prototype.onMouseMove=function(a){a.preventDefault();this.paintStyle_&&(a=this.checkForLED_(a),0>a||("clear"===this.paintStyle_?this.clearLEDNode_(a):"fill"===this.paintStyle_&&this.fillLEDNode_(a)))};
Blockly.FieldMatrix.prototype.checkForLED_=function(a){var b=this.matrixStage_.getBoundingClientRect(),c=Blockly.FieldMatrix.MATRIX_NODE_SIZE,d=Blockly.FieldMatrix.MATRIX_NODE_PAD,e=a.clientX-b.left;a=a.clientY-b.top;var f=d/2;b=b.width-d/2;return e<f||e>b||a<f||a>b?-1:Math.trunc((e-d)/(c+d))+Math.trunc((a-d)/(c+d))*d*2};
Blockly.FieldMatrix.prototype.dispose_=function(){var a=this;return function(){Blockly.FieldMatrix.superClass_.dispose_.call(a)();a.matrixStage_=null;a.mouseDownWrapper_&&Blockly.unbindEvent_(a.mouseDownWrapper_);a.matrixTouchWrapper_&&Blockly.unbindEvent_(a.matrixTouchWrapper_);a.matrixReleaseWrapper_&&Blockly.unbindEvent_(a.matrixReleaseWrapper_);a.matrixMoveWrapper_&&Blockly.unbindEvent_(a.matrixMoveWrapper_);a.clearButtonWrapper_&&Blockly.unbindEvent_(a.clearButtonWrapper_);a.fillButtonWrapper_&&
Blockly.unbindEvent_(a.fillButtonWrapper_);a.mycustomButtonWrapper_&&Blockly.unbindEvent_(a.mycustomMatrix_);a.mytemplatematrix1ButtonWrapper_&&Blockly.unbindEvent_(a.mytemplatematrix1ButtonWrapper_);a.mytemplatematrix2ButtonWrapper_&&Blockly.unbindEvent_(a.mytemplatematrix2ButtonWrapper_);a.mytemplatematrix3ButtonWrapper_&&Blockly.unbindEvent_(a.mytemplatematrix3ButtonWrapper_);a.mytemplatematrix4ButtonWrapper_&&Blockly.unbindEvent_(a.mytemplatematrix4ButtonWrapper_);a.mytemplatematrix5ButtonWrapper_&&
Blockly.unbindEvent_(a.mytemplatematrix5ButtonWrapper_)}};Blockly.Field.register("field_matrix",Blockly.FieldMatrix);arrmatrix=[];arrcustommatrix=[];
Blockly.FieldMatrixJr=function(a){Blockly.FieldMatrixJr.superClass_.constructor.call(this,a);this.addArgType("matrix");this.ledThumbNodes_=[];this.ledButtons_=[];this.stopDragging=this.startDragging=this.addEventListener_=this.mycustommatrixtemplate_=this.mycustommatrix_=this.matrix_="";this.mymatrix1_="00001111111100000000000000110000000000000110000000000000110000000000000110000000000000110000000000000110000000000000111111110000";this.mymatrix2_="00000000000000000011111001111100001000100100010000100010010001000010001001000100001000100100010000111110011111000000000000000000";this.mymatrix3_=
"00000000000000000000110000110000000111100111100000011110011110000001111001111000000111100111100000001100001100000000000000000000";this.mymatrix4_="00000000000000000000000000000000011111100111111000111100001111000001100000011000000000000000000000000000000000000000000000000000";this.mymatrix5_="00000000000000000000000000000000001111100111110000001100000110000001000000100000001111100111110000000000000000000000000000000000";this.matrixReleaseWrapper_=this.matrixMoveWrapper_=this.matrixTouchWrapper_=
this.mycustomButtonDragger_=this.mycustomButtonWrapper_=this.fillButtonWrapper_=this.mytemplatematrix5ButtonWrapper_=this.mytemplatematrix4ButtonWrapper_=this.mytemplatematrix3ButtonWrapper_=this.mytemplatematrix2ButtonWrapper_=this.mytemplatematrix1ButtonWrapper_=this.mouseDownWrapper_=this.paintStyle_=this.arrow_=this.matrixStage_=null};goog.inherits(Blockly.FieldMatrixJr,Blockly.Field);Blockly.FieldMatrixJr.fromJson=function(a){return new Blockly.FieldMatrixJr(a.matrix)};
Blockly.FieldMatrixJr.THUMBNAIL_SIZE=26;Blockly.FieldMatrixJr.THUMBNAIL_NODE_SIZE=2.8;Blockly.FieldMatrixJr.THUMBNAIL_NODE_PAD=.7;Blockly.FieldMatrixJr.ARROW_SIZE=12;Blockly.FieldMatrixJr.MATRIX_NODE_SIZE=32;Blockly.FieldMatrixJr.MATRIX_NODE_RADIUS=4;Blockly.FieldMatrixJr.MATRIX_NODE_PAD=8;Blockly.FieldMatrixJr.ZEROS="00000000000000000000000001111111111111111111111111000000000000000000000000011111111111111111111111111111111111111111111111111000";Blockly.FieldMatrixJr.ONES="00000000000000000000000001111111111111111111111111000000000000000000000000011111111111111111111111111111111111111111111111111000";
Blockly.FieldMatrixJr.CLEARMATRIX="00000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000";Blockly.FieldMatrixJr.FILLMATRIX="11111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111";
Blockly.FieldMatrixJr.prototype.init=function(){if(!this.fieldGroup_){if(this.sourceBlock_.getParent()){var a=this.sourceBlock_.getParent();this.sourceBlock_.setColour(a.getColour(),a.getColourSecondary(),a.getColourTertiary())}this.fieldGroup_=Blockly.utils.createSvgElement("g",{},null);this.fieldGroup2_=Blockly.utils.createSvgElement("g",{},null);this.size_.width=Blockly.FieldMatrixJr.THUMBNAIL_SIZE+Blockly.FieldMatrixJr.ARROW_SIZE+1.5*Blockly.BlockSvg.DROPDOWN_ARROW_PADDING+40;this.size_.height=
60;this.sourceBlock_.getSvgRoot().appendChild(this.fieldGroup_);this.arrowSize_=12;this.arrowX_=0;this.arrowY_=11;this.arrow_=Blockly.utils.createSvgElement("image",{x:18,y:10,height:this.arrowSize_+"px",width:this.arrowSize_+"px"});this.arrow_.setAttributeNS("http://www.w3.org/1999/xlink","xlink:href",Blockly.mainWorkspace.options.pathToMedia+"dropdown-arrow.svg");this.sourceBlock_.getSvgRoot().appendChild(this.arrow_);a=Blockly.utils.createSvgElement("g",{transform:"translate("+(Blockly.BlockSvg.DROPDOWN_ARROW_PADDING/
2+2)+", "+((this.size_.height-Blockly.FieldMatrixJr.THUMBNAIL_SIZE)/2-8)+")","pointer-events":"bounding-box",cursor:"pointer"},this.fieldGroup_);this.ledThumbNodes_=[];for(var b=Blockly.FieldMatrixJr.THUMBNAIL_NODE_SIZE,c=Blockly.FieldMatrixJr.THUMBNAIL_NODE_PAD,d=0;8>d;d++){for(var e=0;16>e;e++)this.ledThumbNodes_.push(Blockly.utils.createSvgElement("rect",{x:(b+c)*e+c,y:(b+c)*d+c-40,width:b,height:b,rx:c,ry:c},a));a.style.cursor="default";this.updateMatrix_()}this.mouseDownWrapper_=Blockly.bindEventWithChecks_(this.getClickTarget_(),
"mousedown",this,this.onMouseDown_);arrmatrix=[];arrmatrix.push(this.mymatrix1_);arrmatrix.push(this.mymatrix2_);arrmatrix.push(this.mymatrix3_);arrmatrix.push(this.mymatrix4_);arrmatrix.push(this.mymatrix5_)}};
Blockly.FieldMatrixJr.prototype.setValue=function(a,b){a&&a!==this.matrix_&&(this.sourceBlock_&&Blockly.Events.isEnabled()&&Blockly.Events.fire(new Blockly.Events.Change(this.sourceBlock_,"field",this.name,this.matrix_,a)),this.matrix_=a+=Blockly.FieldMatrixJr.ZEROS.substr(0,128-a.length),this.updateMatrix_())};Blockly.FieldMatrixJr.prototype.getValue=function(){return String(this.matrix_)};
Blockly.FieldMatrixJr.prototype.showEditor_=function(){Blockly.DropDownDiv.hideWithoutAnimation();Blockly.DropDownDiv.clearContent();var a=Blockly.DropDownDiv.getContentDiv();this.matrixStage_=Blockly.utils.createSvgElement("svg",{xmlns:"http://www.w3.org/2000/svg","xmlns:html":"http://www.w3.org/1999/xhtml","xmlns:xlink":"http://www.w3.org/1999/xlink",version:"1.1",height:"350px",width:"650px"},a);this.matrixStage2_=Blockly.utils.createSvgElement("svg",{xmlns:"http://www.w3.org/2000/svg","xmlns:html":"http://www.w3.org/1999/xhtml",
"xmlns:xlink":"http://www.w3.org/1999/xlink",version:"1.1",height:"350px",width:"100px"},a);this.ledButtons_=[];var b=document.createElement("button");b.setAttribute("id",":"+h);b.setAttribute("role","menuitem");b.setAttribute("class","myDownButtonFill");b.title="clear";b.style.width="60px";b.style.height="60px";var c=this.sourceBlock_.getColour();c=this.sourceBlock_.getColourTertiary();b.setAttribute("aria-selected","true");b.style.backgroundColor=c;b.style.borderColor=this.sourceBlock_.getColourTertiary();
this.clearButtonWrapper_=Blockly.bindEvent_(b,"click",this,this.myclearMatrix_);c=document.createElement("img");c.src=Blockly.mainWorkspace.options.pathToMedia+"cleanMatrix.png";var d=Blockly.mainWorkspace.options.pathToMedia+"cleanMatrix.png";b.setAttribute("data-value",d);c.setAttribute("data-value",d);b.appendChild(c);var e=document.createElement("button");e.setAttribute("id",":"+h);e.setAttribute("role","menuitem");e.setAttribute("class","myDownButtonClean");e.title="fill";e.style.width="60px";
e.style.height="60px";c=this.sourceBlock_.getColour();c=this.sourceBlock_.getColourTertiary();e.setAttribute("aria-selected","true");e.style.backgroundColor=c;e.style.borderColor=this.sourceBlock_.getColourTertiary();this.clearButtonWrapper_=Blockly.bindEvent_(e,"click",this,this.myfillMatrix_);c=document.createElement("img");c.src=Blockly.mainWorkspace.options.pathToMedia+"fillMatrix.png";d=Blockly.mainWorkspace.options.pathToMedia+"fillMatrix.png";e.setAttribute("data-value",d);c.setAttribute("data-value",
d);e.appendChild(c);var f=document.createElement("button");f.setAttribute("id",":"+h);f.setAttribute("role","menuitem");f.setAttribute("class","myDownButtonOk");f.title="ok";f.style.width="50px";f.style.height="50px";c=this.sourceBlock_.getColour();c=this.sourceBlock_.getColourTertiary();f.setAttribute("aria-selected","true");f.style.backgroundColor=c;f.style.borderColor=this.sourceBlock_.getColourTertiary();this.clearButtonWrapper_=Blockly.bindEvent_(f,"click",this,this.myfuncOk_);c=document.createElement("img");
c.src=Blockly.mainWorkspace.options.pathToMedia+"okey.png";d=Blockly.mainWorkspace.options.pathToMedia+"okey.png";f.setAttribute("data-value",d);c.setAttribute("data-value",d);f.appendChild(c);var g=document.createElement("button");g.setAttribute("id",":"+h);g.setAttribute("role","menuitem");g.setAttribute("class","myDownButtonSave");g.title="ok";g.style.width="60px";g.style.height="60px";c=this.sourceBlock_.getColour();c=this.sourceBlock_.getColourTertiary();g.setAttribute("aria-selected","true");
g.style.backgroundColor=c;g.style.borderColor=this.sourceBlock_.getColourTertiary();this.clearButtonWrapper_=Blockly.bindEvent_(g,"click",this,this.mysaveMatrix_);c=document.createElement("img");c.src=Blockly.mainWorkspace.options.pathToMedia+"save.png";d=Blockly.mainWorkspace.options.pathToMedia+"save.png";g.setAttribute("data-value",d);c.setAttribute("data-value",d);g.appendChild(c);a.appendChild(b);a.appendChild(e);a.appendChild(f);a.appendChild(g);for(var h=0;8>h;h++)for(c=0;16>c;c++)d=Blockly.utils.createSvgElement("rect",
{x:Blockly.FieldMatrixJr.MATRIX_NODE_SIZE*c+Blockly.FieldMatrixJr.MATRIX_NODE_PAD*(c+1)+"px",y:Blockly.FieldMatrixJr.MATRIX_NODE_SIZE*h+Blockly.FieldMatrixJr.MATRIX_NODE_PAD*(h+1)+"px",width:Blockly.FieldMatrixJr.MATRIX_NODE_SIZE,height:Blockly.FieldMatrixJr.MATRIX_NODE_SIZE,rx:Blockly.FieldMatrixJr.MATRIX_NODE_RADIUS,ry:Blockly.FieldMatrixJr.MATRIX_NODE_RADIUS},this.matrixStage_),this.matrixStage_.appendChild(d),this.ledButtons_.push(d);buttonDiv=document.createElement("div");buttonDiv.setAttribute("class",
"mycontainer");buttonDiv.setAttribute("id","bottomButonZone");h=document.createElement("div");h.className="scratchMatrixButtonDiv";c=this.createButton_(0);h.appendChild(c);f=document.createElement("div");f.className="scratchMatrixButtonDiv";c=this.createButton_(1);f.appendChild(c);g=document.createElement("div");g.className="scratchMatrixButtonDiv";d=this.createButton_(2);g.appendChild(d);var k=document.createElement("div");k.className="scratchMatrixButtonDiv";b=this.createButton_(3);k.appendChild(b);
var l=document.createElement("div");l.className="scratchMatrixButtonDiv";e=this.createButton_(4);l.appendChild(e);var m=document.createElement("div");m.className="scratchMatrixButtonDiv";var n=this.createButton_(0);m.appendChild(n);m=document.createElement("div");m.className="scratchMatrixButtonDiv";n=this.createButton_(0);m.appendChild(n);buttonDiv.appendChild(h);buttonDiv.appendChild(f);buttonDiv.appendChild(g);buttonDiv.appendChild(k);buttonDiv.appendChild(l);for(h=0;h<arrcustommatrix.length;h++)f=
document.createElement("div"),f.className="scratchMatrixButtonDiv5",g=this.createCustomTemplateButton_(h),g.setAttribute("id","mybtn"+h),f.setAttribute("id","mybtn"+h),g.setAttribute("name",h),f.appendChild(g),buttonDiv.appendChild(f),Blockly.bindEvent_(f,"click",this,this.myfuncMatrixCustom_),Blockly.bindEvent_(g,"click",this,this.myfuncMatrixCustom_);this.mytemplatematrix2ButtonWrapper_=Blockly.bindEvent_(c,"click",this,this.myfuncMatrix2_);this.mytemplatematrix3ButtonWrapper_=Blockly.bindEvent_(d,
"click",this,this.myfuncMatrix3_);this.mytemplatematrix4ButtonWrapper_=Blockly.bindEvent_(b,"click",this,this.myfuncMatrix4_);this.mytemplatematrix5ButtonWrapper_=Blockly.bindEvent_(e,"click",this,this.myfuncMatrix5_);var p=!1,q,r,t=function(a){p=!0;var b=document.getElementById("bottomButonZone");q=a.pageX-b.offsetLeft;r=b.scrollLeft},u=function(a){p=!1};Blockly.FieldMatrixJr.prototype.myclearMatrix2_=function(a){var b=document.getElementById("bottomButonZone");b.addEventListener("mousedown",t,!1);
b.addEventListener("mouseup",u,!1);b.addEventListener("mouseleave",u,!1);a.preventDefault();p&&(b.scrollLeft=r-(a.pageX-b.offsetLeft-q))};Blockly.FieldMatrixJr.prototype.startDragging2_=function(a){};Blockly.FieldMatrixJr.prototype.stopDragging2_=function(a){0==a.button&&(p=!1)};Blockly.bindEvent_(buttonDiv,"mousemove",this,this.myclearMatrix2_);b=document.createElement("button");b.setAttribute("id",":"+h);b.setAttribute("role","menuitem");b.setAttribute("class","myButtonRight");b.title="fill";b.style.width=
"30px";b.style.height="80px";c=this.sourceBlock_.getColour();c=this.sourceBlock_.getColourTertiary();b.setAttribute("aria-selected","true");b.style.backgroundColor=c;b.style.borderColor=this.sourceBlock_.getColourTertiary();Blockly.bindEvent_(b,"click",this,this.myscrollright_);c=document.createElement("img");c.src=Blockly.mainWorkspace.options.pathToMedia+"sagok.png";d=Blockly.mainWorkspace.options.pathToMedia+"sagok.png";b.setAttribute("data-value",d);c.setAttribute("data-value",d);b.appendChild(c);
e=document.createElement("button");e.setAttribute("id",":"+h);e.setAttribute("role","menuitem");e.setAttribute("class","myButtonLeft");e.title="fill";e.style.width="30px";e.style.height="80px";c=this.sourceBlock_.getColour();c=this.sourceBlock_.getColourTertiary();e.setAttribute("aria-selected","true");e.style.backgroundColor=c;e.style.borderColor=this.sourceBlock_.getColourTertiary();this.clearButtonWrapper_=Blockly.bindEvent_(e,"click",this,this.myscrollleft_);c=document.createElement("img");c.src=
Blockly.mainWorkspace.options.pathToMedia+"oksol.png";d=Blockly.mainWorkspace.options.pathToMedia+"oksol.png";e.setAttribute("data-value",d);c.setAttribute("data-value",d);e.appendChild(c);buttonDiv.appendChild(e);buttonDiv.appendChild(b);h=document.createElement("div");a.appendChild(buttonDiv);a.appendChild(h);Blockly.DropDownDiv.setColour(this.sourceBlock_.getColour(),this.sourceBlock_.getColourTertiary());Blockly.DropDownDiv.setCategory(this.sourceBlock_.getCategory());Blockly.DropDownDiv.showPositionedByBlock(this,
this.sourceBlock_);this.matrixTouchWrapper_=Blockly.bindEvent_(this.matrixStage_,"mousedown",this,this.onMouseDown);this.updateMatrix_()};this.nodeCallback_=function(a,b){};
Blockly.FieldMatrixJr.prototype.createButton_=function(a){for(var b=Blockly.utils.createSvgElement("svg",{xmlns:"http://www.w3.org/2000/svg","xmlns:html":"http://www.w3.org/1999/xhtml","xmlns:xlink":"http://www.w3.org/1999/xlink",version:"1.1",height:Blockly.FieldMatrixJr.MATRIX_NODE_SIZE/5*13+"px",width:Blockly.FieldMatrixJr.MATRIX_NODE_SIZE/5*25+"px"}),c=Blockly.FieldMatrixJr.MATRIX_NODE_SIZE/5,d=Blockly.FieldMatrixJr.MATRIX_NODE_SIZE/16,e=0,f=0,g=0;8>g;g++){this.mycustommatrix_=arrmatrix[a];f++;
for(var h=0;16>h;h++)Blockly.utils.createSvgElement("rect",{x:(c+d)*h+d,y:(c+d)*g+d,width:c,height:c,rx:d,ry:d,fill:"0"===this.mycustommatrix_[e]?this.sourceBlock_.colourSecondary_:"#FFFFFF"},b),e++}return b};
Blockly.FieldMatrixJr.prototype.createCustomTemplateButton_=function(a){var b=Blockly.utils.createSvgElement("svg",{xmlns:"http://www.w3.org/2000/svg","xmlns:html":"http://www.w3.org/1999/xhtml","xmlns:xlink":"http://www.w3.org/1999/xlink",version:"1.1",height:Blockly.FieldMatrixJr.MATRIX_NODE_SIZE/5*13+"px",width:Blockly.FieldMatrixJr.MATRIX_NODE_SIZE/5*25+"px"}),c=Blockly.FieldMatrixJr.MATRIX_NODE_SIZE/5,d=Blockly.FieldMatrixJr.MATRIX_NODE_SIZE/16,e=0,f=0;b.setAttribute("id","mybtn"+a);for(var g=
0;8>g;g++){this.mycustommatrixtemplate_=arrcustommatrix[a];f++;for(var h=0;16>h;h++){var k=Blockly.utils.createSvgElement("rect",{x:(c+d)*h+d,y:(c+d)*g+d,width:c,height:c,rx:d,ry:d,fill:"0"===this.mycustommatrixtemplate_[e]?this.sourceBlock_.colourSecondary_:"#FFFFFF"},b);k.setAttribute("id","mybtn"+a);Blockly.bindEvent_(k,"click",this,this.myfuncMatrixCustom_);e++}}Blockly.bindEvent_(b,"click",this,this.myfuncMatrixCustom_);return b};
Blockly.FieldMatrixJr.prototype.updateMatrix_=function(){for(var a=0;a<this.matrix_.length;a++)"0"===this.matrix_[a]?(this.fillMatrixNode_(this.ledButtons_,a,this.sourceBlock_.colourSecondary_),this.fillMatrixNode_(this.ledThumbNodes_,a,this.sourceBlock_.colour_)):(this.fillMatrixNode_(this.ledButtons_,a,"#FFFFFF"),this.fillMatrixNode_(this.ledThumbNodes_,a,"#FFFFFF"))};Blockly.FieldMatrixJr.prototype.myfuncMatrix1_=function(a){0==a.button&&this.setValue(this.mymatrix1_)};
Blockly.FieldMatrixJr.prototype.myfuncMatrix2_=function(a){0==a.button&&this.setValue(this.mymatrix2_)};Blockly.FieldMatrixJr.prototype.myfuncMatrix3_=function(a){0==a.button&&this.setValue(this.mymatrix3_)};Blockly.FieldMatrixJr.prototype.myfuncMatrix4_=function(a){0==a.button&&this.setValue(this.mymatrix4_)};Blockly.FieldMatrixJr.prototype.myfuncMatrix5_=function(a){0==a.button&&this.setValue(this.mymatrix5_)};
Blockly.FieldMatrixJr.prototype.myfuncMatrixCustom_=function(a){a=a.srcElement.id.slice(5,6);a=parseInt(a);this.setValue(arrcustommatrix[a])};Blockly.FieldMatrixJr.prototype.clearMatrix_=function(a){0==a.button&&this.setValue(Blockly.FieldMatrixJr.ZEROS)};Blockly.FieldMatrixJr.prototype.myclearMatrix_=function(a){0==a.button&&this.setValue(Blockly.FieldMatrixJr.CLEARMATRIX)};Blockly.FieldMatrixJr.prototype.myfillMatrix_=function(a){0==a.button&&this.setValue(Blockly.FieldMatrixJr.FILLMATRIX)};
Blockly.FieldMatrixJr.prototype.myfuncOk_=function(a){0==a.button&&Blockly.DropDownDiv.hideWithoutAnimation()};
Blockly.FieldMatrixJr.prototype.mysaveMatrix_=function(a){if(0==a.button){a=Blockly.utils.createSvgElement("svg",{xmlns:"http://www.w3.org/2000/svg","xmlns:html":"http://www.w3.org/1999/xhtml","xmlns:xlink":"http://www.w3.org/1999/xlink",version:"1.1",height:Blockly.FieldMatrixJr.MATRIX_NODE_SIZE/5*13+"px",width:Blockly.FieldMatrixJr.MATRIX_NODE_SIZE/5*25+"px"});var b=Blockly.FieldMatrixJr.MATRIX_NODE_SIZE/5,c=Blockly.FieldMatrixJr.MATRIX_NODE_SIZE/16,d=0;arrcustommatrix.push(this.matrix_);Blockly.Block.custommatrixpush(this.matrix_);
var e=arrcustommatrix.length-1;a.setAttribute("id","mybtn"+e);for(var f=0;8>f;f++){for(var g=0;16>g;g++){var h=Blockly.utils.createSvgElement("rect",{x:(b+c)*g+c,y:(b+c)*f+c,width:b,height:b,rx:c,ry:c,fill:"0"===this.matrix_[d]?this.sourceBlock_.colourSecondary_:"#FFFFFF"},a);h.setAttribute("id","mybtn"+e);d++}Blockly.bindEvent_(h,"click",this,this.myfuncMatrixCustom_)}Blockly.bindEvent_(a,"click",this,this.myfuncMatrixCustom_);h=document.createElement("div");h.className="scratchMatrixButtonDiv";
h.appendChild(a);buttonDiv.appendChild(h);var k=document.getElementById("bottomButonZone"),l=0,m=setInterval(function(){k.scrollLeft+=50;l+=10;350<=l&&window.clearInterval(m)},40)}};Blockly.FieldMatrixJr.prototype.myscrollright_=function(a){if(0==a.button)var b=document.getElementById("bottomButonZone"),c=0,d=setInterval(function(){b.scrollLeft+=48;c+=10;110<=c&&window.clearInterval(d)},50)};
Blockly.FieldMatrixJr.prototype.myscrollleft_=function(a){if(0==a.button)var b=document.getElementById("bottomButonZone"),c=0,d=setInterval(function(){b.scrollLeft-=48;c+=10;110<=c&&window.clearInterval(d)},50)};Blockly.FieldMatrixJr.prototype.fillMatrix_=function(a){0==a.button&&this.setValue(Blockly.FieldMatrixJr.ONES)};Blockly.FieldMatrixJr.prototype.mycustomMatrix_=function(a){0==a.button&&this.setValue(this.mymatrix3_)};
Blockly.FieldMatrixJr.prototype.fillMatrixNode_=function(a,b,c){a&&a[b]&&c&&a[b].setAttribute("fill",c)};Blockly.FieldMatrixJr.prototype.setLEDNode_=function(a,b){0>a||127<a||(a=this.matrix_.substr(0,a)+b+this.matrix_.substr(a+1),this.setValue(a))};Blockly.FieldMatrixJr.prototype.fillLEDNode_=function(a){0>a||127<a||this.setLEDNode_(a,"1")};Blockly.FieldMatrixJr.prototype.clearLEDNode_=function(a){0>a||127<a||this.setLEDNode_(a,"0")};
Blockly.FieldMatrixJr.prototype.toggleLEDNode_=function(a){0>a||127<a||("0"===this.matrix_.charAt(a)?this.setLEDNode_(a,"1"):this.setLEDNode_(a,"0"))};
Blockly.FieldMatrixJr.prototype.onMouseDown=function(a){this.matrixMoveWrapper_=Blockly.bindEvent_(document.body,"mousemove",this,this.onMouseMove);this.matrixReleaseWrapper_=Blockly.bindEvent_(document.body,"mouseup",this,this.onMouseUp);a=this.checkForLED_(a);-1<a?("0"===this.matrix_.charAt(a)?this.paintStyle_="fill":this.paintStyle_="clear",this.toggleLEDNode_(a),this.updateMatrix_()):this.paintStyle_=null};
Blockly.FieldMatrixJr.prototype.onMouseUp=function(){Blockly.unbindEvent_(this.matrixMoveWrapper_);Blockly.unbindEvent_(this.matrixReleaseWrapper_);this.paintStyle_=null};Blockly.FieldMatrixJr.prototype.onMouseMove=function(a){a.preventDefault();this.paintStyle_&&(a=this.checkForLED_(a),0>a||("clear"===this.paintStyle_?this.clearLEDNode_(a):"fill"===this.paintStyle_&&this.fillLEDNode_(a)))};
Blockly.FieldMatrixJr.prototype.checkForLED_=function(a){var b=this.matrixStage_.getBoundingClientRect(),c=Blockly.FieldMatrixJr.MATRIX_NODE_SIZE,d=Blockly.FieldMatrixJr.MATRIX_NODE_PAD,e=a.clientX-b.left;a=a.clientY-b.top;var f=d/2;b=b.width-d/2;return e<f||e>b||a<f||a>b?-1:Math.trunc((e-d)/(c+d))+Math.trunc((a-d)/(c+d))*d*2};
Blockly.FieldMatrixJr.prototype.dispose_=function(){var a=this;return function(){Blockly.FieldMatrixJr.superClass_.dispose_.call(a)();a.matrixStage_=null;a.mouseDownWrapper_&&Blockly.unbindEvent_(a.mouseDownWrapper_);a.matrixTouchWrapper_&&Blockly.unbindEvent_(a.matrixTouchWrapper_);a.matrixReleaseWrapper_&&Blockly.unbindEvent_(a.matrixReleaseWrapper_);a.matrixMoveWrapper_&&Blockly.unbindEvent_(a.matrixMoveWrapper_);a.clearButtonWrapper_&&Blockly.unbindEvent_(a.clearButtonWrapper_);a.fillButtonWrapper_&&
Blockly.unbindEvent_(a.fillButtonWrapper_);a.mycustomButtonWrapper_&&Blockly.unbindEvent_(a.mycustomMatrix_);a.mytemplatematrix1ButtonWrapper_&&Blockly.unbindEvent_(a.mytemplatematrix1ButtonWrapper_);a.mytemplatematrix2ButtonWrapper_&&Blockly.unbindEvent_(a.mytemplatematrix2ButtonWrapper_);a.mytemplatematrix3ButtonWrapper_&&Blockly.unbindEvent_(a.mytemplatematrix3ButtonWrapper_);a.mytemplatematrix4ButtonWrapper_&&Blockly.unbindEvent_(a.mytemplatematrix4ButtonWrapper_);a.mytemplatematrix5ButtonWrapper_&&
Blockly.unbindEvent_(a.mytemplatematrix5ButtonWrapper_)}};Blockly.Field.register("field_matrixjr",Blockly.FieldMatrixJr);Blockly.FieldVariable=function(a,b,c){this.menuGenerator_=Blockly.FieldVariable.dropdownCreate;this.size_=new goog.math.Size(Blockly.BlockSvg.FIELD_WIDTH,Blockly.BlockSvg.FIELD_HEIGHT);this.setValidator(b);this.defaultVariableName=a||"";this.defaultType_=c&&1==c.length?c[0]:"";this.variableTypes=c;this.addArgType("variable");this.value_=null};goog.inherits(Blockly.FieldVariable,Blockly.FieldDropdown);
Blockly.FieldVariable.fromJson=function(a){var b=Blockly.utils.replaceMessageReferences(a.variable);return new Blockly.FieldVariable(b,null,a.variableTypes)};Blockly.FieldVariable.prototype.init=function(){this.fieldGroup_||(Blockly.FieldVariable.superClass_.init.call(this),this.initModel())};
Blockly.FieldVariable.prototype.initModel=function(){if(!this.variable_){this.workspace_=this.sourceBlock_.workspace;var a=this.initFlyoutBroadcast_(this.workspace_);a||(a=Blockly.Variables.getOrCreateVariablePackage(this.workspace_,null,this.defaultVariableName,this.defaultType_));Blockly.Events.disable();try{this.setValue(a.getId())}finally{Blockly.Events.enable()}}};
Blockly.FieldVariable.prototype.initFlyoutBroadcast_=function(a){var b=Blockly.BROADCAST_MESSAGE_VARIABLE_TYPE,c=a.getVariablesOfType(b);if(a.isFlyout&&this.defaultType_==b&&0!=c.length)return c.sort(Blockly.VariableModel.compareByName),c[0]};Blockly.FieldVariable.dispose=function(){Blockly.FieldVariable.superClass_.dispose.call(this);this.variableMap_=this.workspace_=null};
Blockly.FieldVariable.prototype.setSourceBlock=function(a){goog.asserts.assert(!a.isShadow(),"Variable fields are not allowed to exist on shadow blocks.");Blockly.FieldVariable.superClass_.setSourceBlock.call(this,a)};Blockly.FieldVariable.prototype.getValue=function(){return this.variable_?this.variable_.getId():null};Blockly.FieldVariable.prototype.getText=function(){return this.variable_?this.variable_.name:""};Blockly.FieldVariable.prototype.getVariable=function(){return this.variable_};
Blockly.FieldVariable.prototype.setValue=function(a){var b=Blockly.Variables.getVariable(this.sourceBlock_.workspace,a);if(!b)throw Error("Variable id doesn't point to a real variable!  ID was "+a);var c=b.type;if(!this.typeIsAllowed_(c))throw Error("Variable type doesn't match this field!  Type was "+c);this.sourceBlock_&&Blockly.Events.isEnabled()&&(c=this.variable_?this.variable_.getId():null,Blockly.Events.fire(new Blockly.Events.BlockChange(this.sourceBlock_,"field",this.name,c,a)));this.variable_=
b;this.value_=a;this.setText(b.name)};Blockly.FieldVariable.prototype.typeIsAllowed_=function(a){var b=this.getVariableTypes_();if(!b)return!0;for(var c=0;c<b.length;c++)if(a==b[c])return!0;return!1};Blockly.FieldVariable.prototype.getVariableTypes_=function(){var a=this.variableTypes;if(null===a&&this.sourceBlock_)return this.sourceBlock_.workspace.getVariableTypes();a=a||[""];if(0==a.length)throw a=this.getText(),Error("'variableTypes' of field variable "+a+" was an empty list");return a};
Blockly.FieldVariable.dropdownCreate=function(){if(!this.variable_)throw Error("Tried to call dropdownCreate on a variable field with no variable selected.");var a=[],b=this.getText(),c=null;this.sourceBlock_&&(c=this.sourceBlock_.workspace);if(c){var d=this.getVariableTypes_();a=[];for(var e=0;e<d.length;e++){var f=d[e],g=c.getVariablesOfType(f);a=a.concat(g);if(g=c.getPotentialVariableMap())f=g.getVariablesOfType(f),a=a.concat(f)}}a.sort(Blockly.VariableModel.compareByName);c=[];for(e=0;e<a.length;e++)c[e]=
[a[e].name,a[e].getId()];this.defaultType_==Blockly.BROADCAST_MESSAGE_VARIABLE_TYPE?c.unshift([Blockly.Msg.NEW_BROADCAST_MESSAGE,Blockly.NEW_BROADCAST_MESSAGE_ID]):(this.defaultType_==Blockly.LIST_VARIABLE_TYPE?(a=Blockly.Msg.RENAME_LIST,e=Blockly.Msg.DELETE_LIST):(a=Blockly.Msg.RENAME_VARIABLE,e=Blockly.Msg.DELETE_VARIABLE),c.push([a,Blockly.RENAME_VARIABLE_ID]),e&&c.push([e.replace("%1",b),Blockly.DELETE_VARIABLE_ID]));return c};
Blockly.FieldVariable.prototype.onItemSelected=function(a,b){a=b.getValue();if(this.sourceBlock_&&this.sourceBlock_.workspace){b=this.sourceBlock_.workspace;if(a==Blockly.RENAME_VARIABLE_ID){Blockly.Variables.renameVariable(b,this.variable_);return}if(a==Blockly.DELETE_VARIABLE_ID){b.deleteVariableById(this.variable_.getId());return}if(a==Blockly.NEW_BROADCAST_MESSAGE_ID){var c=this;Blockly.Variables.createVariable(b,function(a){a&&c.setValue(a)},Blockly.BROADCAST_MESSAGE_VARIABLE_TYPE);return}}this.setValue(a)};
Blockly.FieldVariable.prototype.referencesVariables=function(){return!0};Blockly.Field.register("field_variable",Blockly.FieldVariable);/*

 Visual Blocks Editor

 Copyright 2017 Massachusetts Institute of Technology
 https://developers.google.com/blockly/

 Licensed under the Apache License, Version 2.0 (the "License");
 you may not use this file except in compliance with the License.
 You may obtain a copy of the License at

   http://www.apache.org/licenses/LICENSE-2.0

 Unless required by applicable law or agreed to in writing, software
 distributed under the License is distributed on an "AS IS" BASIS,
 WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 See the License for the specific language governing permissions and
 limitations under the License.
*/
Blockly.FieldVerticalSeparator=function(){this.sourceBlock_=null;this.width_=1;this.height_=Blockly.BlockSvg.ICON_SEPARATOR_HEIGHT;this.size_=new goog.math.Size(this.width_,this.height_)};goog.inherits(Blockly.FieldVerticalSeparator,Blockly.Field);Blockly.FieldVerticalSeparator.fromJson=function(a){return new Blockly.FieldVerticalSeparator};Blockly.FieldVerticalSeparator.prototype.EDITABLE=!1;
Blockly.FieldVerticalSeparator.prototype.init=function(){this.fieldGroup_||(this.fieldGroup_=Blockly.utils.createSvgElement("g",{},null),this.visible_||(this.fieldGroup_.style.display="none"),this.lineElement_=Blockly.utils.createSvgElement("line",{stroke:this.sourceBlock_.getColourSecondary(),"stroke-linecap":"round",x1:0,y1:0,x2:0,y2:this.height_},this.fieldGroup_),this.sourceBlock_.getSvgRoot().appendChild(this.fieldGroup_))};
Blockly.FieldVerticalSeparator.prototype.setLineHeight=function(a){this.lineElement_.setAttribute("y2",a)};Blockly.FieldVerticalSeparator.prototype.dispose=function(){goog.dom.removeNode(this.fieldGroup_);this.lineElement_=this.fieldGroup_=null};Blockly.FieldVerticalSeparator.prototype.getValue=function(){return null};Blockly.FieldVerticalSeparator.prototype.setValue=function(a){};Blockly.FieldVerticalSeparator.prototype.setText=function(a){};Blockly.FieldVerticalSeparator.prototype.render_=function(){};
Blockly.FieldVerticalSeparator.prototype.updateWidth=function(){};Blockly.Field.register("field_vertical_separator",Blockly.FieldVerticalSeparator);Blockly.Generator=function(a){this.name_=a;this.FUNCTION_NAME_PLACEHOLDER_REGEXP_=new RegExp(this.FUNCTION_NAME_PLACEHOLDER_,"g")};Blockly.Generator.NAME_TYPE="generated_function";Blockly.Generator.prototype.INFINITE_LOOP_TRAP=null;Blockly.Generator.prototype.STATEMENT_PREFIX=null;Blockly.Generator.prototype.INDENT="  ";Blockly.Generator.prototype.COMMENT_WRAP=60;Blockly.Generator.prototype.ORDER_OVERRIDES=[];
Blockly.Generator.prototype.workspaceToCode=function(a){a||(console.warn("No workspace specified in workspaceToCode call.  Guessing."),a=Blockly.getMainWorkspace());var b=[];this.init(a);a=a.getTopBlocks(!0);for(var c=0,d;d=a[c];c++){var e=this.blockToCode(d);goog.isArray(e)&&(e=e[0]);e&&(d.outputConnection&&this.scrubNakedValue&&(e=this.scrubNakedValue(e)),b.push(e))}b=b.join("\n");b=this.finish(b);b=b.replace(/^\s+\n/,"");b=b.replace(/\n\s+$/,"\n");return b=b.replace(/[ \t]+\n/g,"\n")};
Blockly.Generator.prototype.prefixLines=function(a,b){return b+a.replace(/(?!\n$)\n/g,"\n"+b)};Blockly.Generator.prototype.allNestedComments=function(a){var b=[];a=a.getDescendants(!0);for(var c=0;c<a.length;c++){var d=a[c].getCommentText();d&&b.push(d)}b.length&&b.push("");return b.join("\n")};
Blockly.Generator.prototype.blockToCode=function(a){if(!a||!this.check_(a))return"";if(a.disabled)return this.blockToCode(a.getNextBlock());var b=this[a.type];goog.asserts.assertFunction(b,'Language "%s" does not know how to generate code for block type "%s".',this.name_,a.type);b=b.call(a,a);if(goog.isArray(b))return goog.asserts.assert(a.outputConnection,'Expecting string from statement block "%s".',a.type),[this.scrub_(a,b[0]),b[1]];if(goog.isString(b)){var c=a.id.replace(/\$/g,"$$$$");this.STATEMENT_PREFIX&&
(b=this.STATEMENT_PREFIX.replace(/%1/g,"'"+c+"'")+b);return this.scrub_(a,b)}if(null===b)return"";goog.asserts.fail("Invalid code generated: %s",b)};
Blockly.Generator.prototype.valueToCode=function(a,b,c){isNaN(c)&&goog.asserts.fail('Expecting valid order from block "%s".',a.type);var d=a.getInputTargetBlock(b);if(!d)return"";b=this.blockToCode(d);if(""===b)return"";goog.asserts.assertArray(b,'Expecting tuple from value block "%s".',d.type);a=b[0];b=b[1];isNaN(b)&&goog.asserts.fail('Expecting valid order from value block "%s".',d.type);if(!a)return"";d=!1;var e=Math.floor(c),f=Math.floor(b);if(e<=f&&(e!=f||0!=e&&99!=e))for(d=!0,e=0;e<this.ORDER_OVERRIDES.length;e++)if(this.ORDER_OVERRIDES[e][0]==
c&&this.ORDER_OVERRIDES[e][1]==b){d=!1;break}d&&(a="("+a+")");return a};Blockly.Generator.prototype.statementToCode=function(a,b){a=a.getInputTargetBlock(b);b=this.blockToCode(a);goog.asserts.assertString(b,'Expecting code from statement block "%s".',a&&a.type);b&&(b=this.prefixLines(b,this.INDENT));return b};
Blockly.Generator.prototype.addLoopTrap=function(a,b){b=b.replace(/\$/g,"$$$$");this.INFINITE_LOOP_TRAP&&(a=this.INFINITE_LOOP_TRAP.replace(/%1/g,"'"+b+"'")+a);this.STATEMENT_PREFIX&&(a+=this.prefixLines(this.STATEMENT_PREFIX.replace(/%1/g,"'"+b+"'"),this.INDENT));return a};Blockly.Generator.prototype.RESERVED_WORDS_="";Blockly.Generator.prototype.addReservedWords=function(a){this.RESERVED_WORDS_+=a+","};Blockly.Generator.prototype.FUNCTION_NAME_PLACEHOLDER_="{leCUI8hutHZI4480Dc}";
Blockly.Generator.prototype.provideFunction_=function(a,b){if(!this.definitions_[a]){var c=this.variableDB_.getDistinctName(a,Blockly.Procedures.NAME_TYPE);this.functionNames_[a]=c;b=b.join("\n").replace(this.FUNCTION_NAME_PLACEHOLDER_REGEXP_,c);for(var d;d!=b;)d=b,b=b.replace(/^(( {2})*) {2}/gm,"$1\x00");b=b.replace(/\0/g,this.INDENT);this.definitions_[a]=b}return this.functionNames_[a]};Blockly.Generator.prototype.init=function(a){};Blockly.Generator.prototype.scrub_=function(a,b){return b};
Blockly.Generator.prototype.check_=function(a){return!0};Blockly.Generator.prototype.finish=function(a){return a};Blockly.Generator.prototype.scrubNakedValue=function(a){return a};Blockly.Names=function(a,b){this.variablePrefix_=b||"";this.reservedDict_=Object.create(null);if(a)for(a=a.split(","),b=0;b<a.length;b++)this.reservedDict_[a[b]]=!0;this.reset()};Blockly.Names.DEVELOPER_VARIABLE_TYPE="DEVELOPER_VARIABLE";Blockly.Names.prototype.reset=function(){this.db_=Object.create(null);this.dbReverse_=Object.create(null);this.variableMap_=null};Blockly.Names.prototype.setVariableMap=function(a){this.variableMap_=a};
Blockly.Names.prototype.getNameForUserVariable_=function(a){return this.variableMap_?(a=this.variableMap_.getVariableById(a))?a.name:null:(console.log("Deprecated call to Blockly.Names.prototype.getName without defining a variable map. To fix, add the folowing code in your generator's init() function:\nBlockly.YourGeneratorName.variableDB_.setVariableMap(workspace.getVariableMap());"),null)};
Blockly.Names.prototype.getName=function(a,b){if(b==Blockly.Variables.NAME_TYPE){var c=this.getNameForUserVariable_(a);c&&(a=c)}c=a?a.toLowerCase()+"_"+b:"null_"+b;var d=b==Blockly.Variables.NAME_TYPE||b==Blockly.Names.DEVELOPER_VARIABLE_TYPE?this.variablePrefix_:"";if(c in this.db_)return d+this.db_[c];a=this.getDistinctName(a,b);this.db_[c]=a.substr(d.length);return a};
Blockly.Names.prototype.getDistinctName=function(a,b){a=this.safeName_(a);for(var c="";this.dbReverse_[a+c]||a+c in this.reservedDict_;)c=c?c+1:2;a+=c;this.dbReverse_[a]=!0;return(b==Blockly.Variables.NAME_TYPE||b==Blockly.Names.DEVELOPER_VARIABLE_TYPE?this.variablePrefix_:"")+a};Blockly.Names.prototype.safeName_=function(a){a?(a=encodeURI(a.replace(/ /g,"_")).replace(/[^\w]/g,"_"),-1!="0123456789".indexOf(a[0])&&(a="my_"+a)):a="unnamed";return a};
Blockly.Names.equals=function(a,b){return a.toLowerCase()==b.toLowerCase()};Blockly.Procedures={};Blockly.Procedures.NAME_TYPE=Blockly.PROCEDURE_CATEGORY_NAME;Blockly.Procedures.allProcedures=function(a){a=a.getAllBlocks();for(var b=[],c=[],d=0;d<a.length;d++)if(a[d].getProcedureDef){var e=a[d].getProcedureDef();e&&(e[2]?b.push(e):c.push(e))}c.sort(Blockly.Procedures.procTupleComparator_);b.sort(Blockly.Procedures.procTupleComparator_);return[c,b]};
Blockly.Procedures.allProcedureMutations=function(a){a=a.getAllBlocks();for(var b=[],c=0;c<a.length;c++)if(a[c].type==Blockly.PROCEDURES_PROTOTYPE_BLOCK_TYPE){var d=a[c].mutationToDom(!0);d&&b.push(d)}return b};Blockly.Procedures.sortProcedureMutations_=function(a){a=a.slice();a.sort(function(a,c){a=a.getAttribute("proccode");c=c.getAttribute("proccode");return Blockly.scratchBlocksUtils.compareStrings(a,c)});return a};
Blockly.Procedures.procTupleComparator_=function(a,b){return Blockly.scratchBlocksUtils.compareStrings(a[0],b[0])};Blockly.Procedures.findLegalName=function(a,b){if(b.isInFlyout)return a;for(;!Blockly.Procedures.isLegalName_(a,b.workspace,b);){var c=a.match(/^(.*?)(\d+)$/);a=c?c[1]+(parseInt(c[2],10)+1):a+"2"}return a};Blockly.Procedures.isLegalName_=function(a,b,c){return!Blockly.Procedures.isNameUsed(a,b,c)};
Blockly.Procedures.isNameUsed=function(a,b,c){b=b.getAllBlocks();for(var d=0;d<b.length;d++)if(b[d]!=c&&b[d].getProcedureDef){var e=b[d].getProcedureDef();if(Blockly.Names.equals(e[0],a))return!1}return!0};Blockly.Procedures.rename=function(a){a=a.replace(/^[\s\xa0]+|[\s\xa0]+$/g,"");var b=Blockly.Procedures.findLegalName(a,this.sourceBlock_),c=this.text_;if(c!=a&&c!=b){a=this.sourceBlock_.workspace.getAllBlocks();for(var d=0;d<a.length;d++)a[d].renameProcedure&&a[d].renameProcedure(c,b)}return b};
Blockly.Procedures.flyoutCategory=function(a){var b=[];Blockly.Procedures.addCreateButton_(a,b);a=Blockly.Procedures.allProcedureMutations(a);a=Blockly.Procedures.sortProcedureMutations_(a);for(var c=0;c<a.length;c++){var d=a[c],e=goog.dom.createDom("block");e.setAttribute("type","procedures_call");e.setAttribute("gap",16);e.appendChild(d);b.push(e)}return b};
Blockly.Procedures.addCreateButton_=function(a,b){var c=goog.dom.createDom("button");c.setAttribute("text",Blockly.Msg.NEW_PROCEDURE);c.setAttribute("callbackKey","CREATE_PROCEDURE");a.registerButtonCallback("CREATE_PROCEDURE",function(){Blockly.Procedures.createProcedureDefCallback_(a)});b.push(c)};
Blockly.Procedures.getCallers=function(a,b,c,d){var e=[],f=b.getTopBlocks();for(b=0;b<f.length;b++){var g=f[b];(g.id!=c.id||d)&&e.push.apply(e,g.getDescendants(!1))}c=[];for(b=0;b<e.length;b++)g=e[b],g.type==Blockly.PROCEDURES_CALL_BLOCK_TYPE&&(d=g.getProcCode())&&d==a&&c.push(g);return c};
Blockly.Procedures.mutateCallersAndPrototype=function(a,b,c){var d=Blockly.Procedures.getDefineBlock(a,b);b=Blockly.Procedures.getPrototypeBlock(a,b);if(d&&b){a=Blockly.Procedures.getCallers(a,d.workspace,d,!0);a.push(b);Blockly.Events.setGroup(!0);for(d=0;b=a[d];d++){var e=b.mutationToDom();e=e&&Blockly.Xml.domToText(e);b.domToMutation(c);var f=b.mutationToDom();f=f&&Blockly.Xml.domToText(f);e!=f&&Blockly.Events.fire(new Blockly.Events.BlockChange(b,"mutation",null,e,f))}Blockly.Events.setGroup(!1)}else alert("No define block on workspace")};
Blockly.Procedures.getDefineBlock=function(a,b){b=b.getTopBlocks(!1);for(var c=0;c<b.length;c++)if(b[c].type==Blockly.PROCEDURES_DEFINITION_BLOCK_TYPE){var d=b[c].getInput("custom_block").connection.targetBlock();if(d.getProcCode&&d.getProcCode()==a)return b[c]}return null};Blockly.Procedures.getPrototypeBlock=function(a,b){return(a=Blockly.Procedures.getDefineBlock(a,b))?a.getInput("custom_block").connection.targetBlock():null};
Blockly.Procedures.newProcedureMutation=function(){return Blockly.Xml.textToDom('<xml><mutation proccode="'+Blockly.Msg.PROCEDURE_DEFAULT_NAME+'" argumentids="[]" argumentnames="[]" argumentdefaults="[]" warp="false"></mutation></xml>').firstChild};Blockly.Procedures.createProcedureDefCallback_=function(a){Blockly.Procedures.externalProcedureDefCallback(Blockly.Procedures.newProcedureMutation(),Blockly.Procedures.createProcedureCallbackFactory_(a))};
Blockly.Procedures.createProcedureCallbackFactory_=function(a){return function(b){if(b){b='<xml><block type="procedures_definition"><statement name="custom_block"><shadow type="procedures_prototype">'+Blockly.Xml.domToText(b)+"</shadow></statement></block></xml>";b=Blockly.Xml.textToDom(b).firstChild;Blockly.Events.setGroup(!0);b=Blockly.Xml.domToBlock(b,a);var c=a.scale,d=-a.scrollX;d=a.RTL?d+(a.getMetrics().contentWidth-30):d+30;b.moveBy(d/c,(-a.scrollY+30)/c);b.scheduleSnapAndBump();Blockly.Events.setGroup(!1)}}};
Blockly.Procedures.editProcedureCallback_=function(a){if(a.type==Blockly.PROCEDURES_DEFINITION_BLOCK_TYPE){a=a.getInput("custom_block");if(!a){alert("Bad input");return}a=a.connection;if(!a){alert("Bad connection");return}a=a.targetBlock();if(!a||!a.type==Blockly.PROCEDURES_PROTOTYPE_BLOCK_TYPE){alert("Bad inner block");return}}else if(a.type==Blockly.PROCEDURES_CALL_BLOCK_TYPE){var b=a.workspace.isFlyout?a.workspace.targetWorkspace:a.workspace;a=Blockly.Procedures.getPrototypeBlock(a.getProcCode(),
b)}Blockly.Procedures.externalProcedureDefCallback(a.mutationToDom(),Blockly.Procedures.editProcedureCallbackFactory_(a))};Blockly.Procedures.editProcedureCallbackFactory_=function(a){return function(b){b&&Blockly.Procedures.mutateCallersAndPrototype(a.getProcCode(),a.workspace,b)}};Blockly.Procedures.externalProcedureDefCallback=function(){alert("External procedure editor must be override Blockly.Procedures.externalProcedureDefCallback")};
Blockly.Procedures.makeEditOption=function(a){return{enabled:!0,text:Blockly.Msg.EDIT_PROCEDURE,callback:function(){Blockly.Procedures.editProcedureCallback_(a)}}};Blockly.Procedures.showProcedureDefCallback_=function(a){alert('TODO(#1136): implement showing procedure definition (procCode was "'+a.procCode_+'")')};Blockly.Procedures.makeShowDefinitionOption=function(a){return{enabled:!0,text:Blockly.Msg.SHOW_PROCEDURE_DEFINITION,callback:function(){Blockly.Procedures.showProcedureDefCallback_(a)}}};
Blockly.Procedures.deleteProcedureDefCallback=function(a,b){if(0<Blockly.Procedures.getCallers(a,b.workspace,b,!1).length)return!1;a=b.workspace;Blockly.Events.setGroup(!0);b.dispose();Blockly.Events.setGroup(!1);a.refreshToolboxSelection_();return!0};Blockly.ScratchMsgs={};Blockly.ScratchMsgs.locales={};Blockly.ScratchMsgs.currentLocale_="en";Blockly.ScratchMsgs.setLocale=function(a){Object.keys(Blockly.ScratchMsgs.locales).includes(a)?(Blockly.ScratchMsgs.currentLocale_=a,Blockly.Msg=Object.assign({},Blockly.Msg,Blockly.ScratchMsgs.locales[a])):console.warn("Ignoring unrecognized locale: "+a)};
Blockly.ScratchMsgs.translate=function(a,b,c){c=c||Blockly.ScratchMsgs.currentLocale_;return Object.keys(Blockly.ScratchMsgs.locales).includes(c)&&(c=Blockly.ScratchMsgs.locales[c],Object.keys(c).includes(a))?c[a]:b};Blockly.FlyoutButton=function(a,b,c,d){this.init(a,b,c,d);this.callback_=null;a=c.getAttribute("callbackKey");this.isLabel_&&a?console.warn("Labels should not have callbacks. Label text: "+this.text_):this.isLabel_||a&&b.getButtonCallback(a)?this.callback_=b.getButtonCallback(a):console.warn("Buttons should have callbacks. Button text: "+this.text_)};Blockly.FlyoutButton.MARGIN=40;Blockly.FlyoutButton.prototype.width=0;Blockly.FlyoutButton.prototype.height=40;
Blockly.FlyoutButton.prototype.onMouseUpWrapper_=null;Blockly.FlyoutButton.prototype.init=function(a,b,c,d){this.workspace_=a;this.targetWorkspace_=b;this.text_=c.getAttribute("text");this.position_=new goog.math.Coordinate(0,0);this.isLabel_=d;this.isCategoryLabel_="true"===c.getAttribute("category-label");this.cssClass_=c.getAttribute("web-class")||null};
Blockly.FlyoutButton.prototype.createDom=function(){var a=this.isLabel_?"blocklyFlyoutLabel":"blocklyFlyoutButton";this.cssClass_&&(a+=" "+this.cssClass_);this.svgGroup_=Blockly.utils.createSvgElement("g",{"class":a},this.workspace_.getCanvas());IshorizontalLayout?this.nullTextSvg(this.isLabel_):this.addTextSvg(this.isLabel_);this.mouseUpWrapper_=Blockly.bindEventWithChecks_(this.svgGroup_,"mouseup",this,this.onMouseUp_);return this.svgGroup_};
Blockly.FlyoutButton.prototype.nullTextSvg=function(a){if(!a)var b=Blockly.utils.createSvgElement("rect",{"class":"blocklyFlyoutButtonShadow",rx:4,ry:4,x:1,y:1},this.svgGroup_);var c=Blockly.utils.createSvgElement("rect",{"class":a?"blocklyFlyoutLabelBackground":"blocklyFlyoutButtonBackground",rx:4,ry:4},this.svgGroup_),d=Blockly.utils.createSvgElement("text",{"class":"nullblocklyFlyoutLabelText",x:0,y:0,"text-anchor":"middle"},this.svgGroup_);d.textContent=Blockly.utils.replaceMessageReferences("_");
this.width=Blockly.Field.getCachedWidth(d);a||(this.width+=2*Blockly.FlyoutButton.MARGIN,b.setAttribute("width",this.width),b.setAttribute("height",this.height));c.setAttribute("width",this.width);c.setAttribute("height",this.height);d.setAttribute("text-anchor","middle");d.setAttribute("dominant-baseline","central");d.setAttribute("dy",goog.userAgent.EDGE_OR_IE?Blockly.Field.IE_TEXT_OFFSET:"0");d.setAttribute("x",this.width/2);d.setAttribute("y",this.height/2)};
Blockly.FlyoutButton.prototype.addTextSvg=function(a){if(!a)var b=Blockly.utils.createSvgElement("rect",{"class":"blocklyFlyoutButtonShadow",rx:4,ry:4,x:1,y:1},this.svgGroup_);var c=Blockly.utils.createSvgElement("rect",{"class":a?"blocklyFlyoutLabelBackground":"blocklyFlyoutButtonBackground",rx:4,ry:4},this.svgGroup_),d=Blockly.utils.createSvgElement("text",{"class":a?"blocklyFlyoutLabelText":"blocklyText",x:0,y:0,"text-anchor":"middle"},this.svgGroup_);d.textContent=Blockly.utils.replaceMessageReferences(this.text_);
this.width=Blockly.Field.getCachedWidth(d);a||(this.width+=2*Blockly.FlyoutButton.MARGIN,b.setAttribute("width",this.width),b.setAttribute("height",this.height));c.setAttribute("width",this.width);c.setAttribute("height",this.height);d.setAttribute("text-anchor","middle");d.setAttribute("dominant-baseline","central");d.setAttribute("dy",goog.userAgent.EDGE_OR_IE?Blockly.Field.IE_TEXT_OFFSET:"0");d.setAttribute("x",this.width/2);d.setAttribute("y",this.height/2)};
Blockly.FlyoutButton.prototype.show=function(){this.updateTransform_();this.svgGroup_.setAttribute("display","block")};Blockly.FlyoutButton.prototype.updateTransform_=function(){this.svgGroup_.setAttribute("transform","translate("+this.position_.x+","+this.position_.y+")")};Blockly.FlyoutButton.prototype.moveTo=function(a,b){this.position_.x=a;this.position_.y=b;this.updateTransform_()};Blockly.FlyoutButton.prototype.getTargetWorkspace=function(){return this.targetWorkspace_};
Blockly.FlyoutButton.prototype.getIsCategoryLabel=function(){return this.isCategoryLabel_};Blockly.FlyoutButton.prototype.getText=function(){return this.text_};Blockly.FlyoutButton.prototype.getPosition=function(){return this.position_};Blockly.FlyoutButton.prototype.dispose=function(){this.onMouseUpWrapper_&&Blockly.unbindEvent_(this.onMouseUpWrapper_);this.svgGroup_&&(goog.dom.removeNode(this.svgGroup_),this.svgGroup_=null);this.targetWorkspace_=this.workspace_=null};
Blockly.FlyoutButton.prototype.onMouseUp_=function(a){if(a=this.targetWorkspace_.getGesture(a)){if(a.isDragging())return;a.cancel()}this.callback_&&this.callback_(this)};Blockly.FlyoutExtensionCategoryHeader=function(a,b,c){this.init(a,b,c,!1);this.flyoutWidth_=this.targetWorkspace_.getFlyout().getWidth();this.extensionId=c.getAttribute("id");this.isCategoryLabel_=!0};goog.inherits(Blockly.FlyoutExtensionCategoryHeader,Blockly.FlyoutButton);
Blockly.FlyoutExtensionCategoryHeader.prototype.createDom=function(){this.svgGroup_=Blockly.utils.createSvgElement("g",{"class":"blocklyFlyoutLabel"},this.workspace_.getCanvas());this.addTextSvg(!0);this.refreshStatus();var a=this.workspace_.RTL?20-this.flyoutWidth_+30:(this.flyoutWidth_-30-20)/this.workspace_.scale;this.imageSrc_&&(this.imageElement_=Blockly.utils.createSvgElement("image",{"class":"blocklyFlyoutButton",height:"30px",width:"30px",x:a+"px",y:"5px"},this.svgGroup_),this.imageElementBackground_=
Blockly.utils.createSvgElement("rect",{"class":"blocklyTouchTargetBackground",height:"62px",width:"62px",x:a-16+"px",y:"-11px"},this.svgGroup_),this.setImageSrc(this.imageSrc_));this.callback_=Blockly.statusButtonCallback.bind(this,this.extensionId);this.mouseUpWrapper_=Blockly.bindEventWithChecks_(this.imageElementBackground_,"mouseup",this,this.onMouseUp_);return this.svgGroup_};
Blockly.FlyoutExtensionCategoryHeader.prototype.refreshStatus=function(){var a=Blockly.FlyoutExtensionCategoryHeader.getExtensionState(this.extensionId),b=Blockly.mainWorkspace.options.pathToMedia;a==Blockly.StatusButtonState.READY&&this.setImageSrc(b+"status-ready.svg");a==Blockly.StatusButtonState.NOT_READY&&this.setImageSrc(b+"status-not-ready.svg")};
Blockly.FlyoutExtensionCategoryHeader.prototype.setImageSrc=function(a){null!==a&&(this.imageSrc_=a,this.imageElement_&&this.imageElement_.setAttributeNS("http://www.w3.org/1999/xlink","xlink:href",this.imageSrc_||""))};Blockly.FlyoutExtensionCategoryHeader.getExtensionState=function(){return Blockly.StatusButtonState.NOT_READY};Blockly.Flyout=function(a){a.getMetrics=this.getMetrics_.bind(this);a.setMetrics=this.setMetrics_.bind(this);this.workspace_=new Blockly.WorkspaceSvg(a);this.workspace_.isFlyout=!0;var b=this.workspace_.newBlock;this.workspace_.newBlock=function(a,d){return b.call(this,a,d||a)};this.RTL=!!a.RTL;this.horizontalLayout_=a.horizontalLayout;this.toolboxPosition_=a.toolboxPosition;this.eventWrappers_=[];this.backgroundButtons_=[];this.buttons_=[];this.listeners_=[];this.blockContents_=[];this.permanentlyDisabled_=
[];this.scrollTarget=this.parentToolbox_=null;this.recycleBlocks_=[];this.deviceTypeList_=["microbit","arduino"]};Blockly.Flyout.prototype.autoClose=!1;Blockly.Flyout.prototype.isVisible_=!1;Blockly.Flyout.prototype.containerVisible_=!0;Blockly.Flyout.prototype.CORNER_RADIUS=0;Blockly.Flyout.prototype.MARGIN=12;Blockly.Flyout.prototype.GAP_X=3*Blockly.Flyout.prototype.MARGIN;Blockly.Flyout.prototype.GAP_Y=Blockly.Flyout.prototype.MARGIN;Blockly.Flyout.prototype.SCROLLBAR_PADDING=2;
Blockly.Flyout.prototype.width_=0;Blockly.Flyout.prototype.height_=0;Blockly.Flyout.prototype.contentWidth_=0;Blockly.Flyout.prototype.contentHeight_=0;Blockly.Flyout.prototype.verticalOffset_=0;Blockly.Flyout.prototype.dragAngleRange_=70;Blockly.Flyout.prototype.scrollAnimationFraction=.3;Blockly.Flyout.prototype.recyclingEnabled_=!0;
Blockly.Flyout.prototype.createDom=function(a){this.svgGroup_=Blockly.utils.createSvgElement(a,{"class":"blocklyFlyout",style:"display: none"},null);this.svgBackground_=Blockly.utils.createSvgElement("path",{"class":"blocklyFlyoutBackground"},this.svgGroup_);this.svgGroup_.appendChild(this.workspace_.createDom());return this.svgGroup_};
Blockly.Flyout.prototype.init=function(a){this.targetWorkspace_=a;this.workspace_.targetWorkspace=a;this.scrollbar_=new Blockly.Scrollbar(this.workspace_,this.horizontalLayout_,!1,"blocklyFlyoutScrollbar");this.position();Array.prototype.push.apply(this.eventWrappers_,Blockly.bindEventWithChecks_(this.svgGroup_,"wheel",this,this.wheel_));Array.prototype.push.apply(this.eventWrappers_,Blockly.bindEventWithChecks_(this.svgGroup_,"mousedown",this,this.onMouseDown_));this.workspace_.getGesture=this.targetWorkspace_.getGesture.bind(this.targetWorkspace_);
this.workspace_.variableMap_=this.targetWorkspace_.getVariableMap();this.workspace_.createPotentialVariableMap()};
Blockly.Flyout.prototype.dispose=function(){this.hide();Blockly.unbindEvent_(this.eventWrappers_);this.scrollbar_&&(this.scrollbar_.dispose(),this.scrollbar_=null);this.workspace_&&(this.workspace_.targetWorkspace=null,this.workspace_.dispose(),this.workspace_=null);this.svgGroup_&&(goog.dom.removeNode(this.svgGroup_),this.svgGroup_=null);this.targetWorkspace_=this.svgBackground_=this.parentToolbox_=null};Blockly.Flyout.prototype.setParentToolbox=function(a){this.parentToolbox_=a};
Blockly.Flyout.prototype.getWidth=function(){return this.DEFAULT_WIDTH};Blockly.Flyout.prototype.getHeight=function(){return this.height_};Blockly.Flyout.prototype.getWorkspace=function(){return this.workspace_};Blockly.Flyout.prototype.getFlyoutItems=function(){return this.blockContents_};Blockly.Flyout.prototype.isVisible=function(){return this.isVisible_};Blockly.Flyout.prototype.setVisible=function(a){var b=a!=this.isVisible();this.isVisible_=a;b&&this.updateDisplay_()};
Blockly.Flyout.prototype.setContainerVisible=function(a){var b=a!=this.containerVisible_;this.containerVisible_=a;b&&this.updateDisplay_()};Blockly.Flyout.prototype.updateDisplay_=function(){var a=this.containerVisible_?this.isVisible():!1;this.svgGroup_.style.display=a?"block":"none";this.scrollbar_.setContainerVisible(a)};
Blockly.Flyout.prototype.hide=function(){if(this.isVisible()){this.setVisible(!1);for(var a=0,b;b=this.listeners_[a];a++)Blockly.unbindEvent_(b);this.listeners_.length=0;this.reflowWrapper_&&(this.workspace_.removeChangeListener(this.reflowWrapper_),this.reflowWrapper_=null)}};
Blockly.Flyout.prototype.show=function(a){this.workspace_.setResizesEnabled(!1);this.hide();this.clearOldBlocks_();this.setVisible(!0);var b=[],c=[];this.permanentlyDisabled_.length=0;for(var d=this.blockContents_.length=0,e;e=a[d];d++){if("string"===typeof e){var f=this.workspace_.targetWorkspace.getToolboxCategoryCallback(a[d])(this.workspace_.targetWorkspace);a.splice.apply(a,[d,1].concat(f));e=a[d]}if(e.tagName){var g=e.tagName.toUpperCase();f=this.horizontalLayout_?this.GAP_X:this.GAP_Y;if("BLOCK"==
g){var h=e.getAttribute("id")||e.getAttribute("type");g=this.recycleBlocks_.findIndex(function(a){return a.id===h});this.deviceTypeList_.includes(e.getAttribute("type").split("_")[0])&&(g=-1);g=-1<g?this.recycleBlocks_.splice(g,1)[0]:Blockly.Xml.domToBlock(e,this.workspace_);g.disabled&&this.permanentlyDisabled_.push(g);this.blockContents_=this.blockContents_.concat(g.getDescendants());b.push({type:"block",block:g});e=parseInt(e.getAttribute("gap"),10);c.push(isNaN(e)?f:e)}else if("SEP"==e.tagName.toUpperCase())e=
parseInt(e.getAttribute("gap"),10),!isNaN(e)&&0<c.length?c[c.length-1]=e:c.push(f);else if("LABEL"==g&&"true"==e.getAttribute("showStatusButton"))e=new Blockly.FlyoutExtensionCategoryHeader(this.workspace_,this.targetWorkspace_,e),b.push({type:"button",button:e}),c.push(f);else if("BUTTON"==g||"LABEL"==g)e=new Blockly.FlyoutButton(this.workspace_,this.targetWorkspace_,e,"LABEL"==g),b.push({type:"button",button:e}),c.push(f)}}this.emptyRecycleBlocks_();this.layout_(b,c);this.listeners_.push(Blockly.bindEvent_(this.svgBackground_,
"mouseover",this,function(){for(var a=this.workspace_.getTopBlocks(!1),b=0,c;c=a[b];b++)c.removeSelect()}));this.workspace_.setResizesEnabled(!0);this.reflow();this.position();this.reflowWrapper_=this.reflow.bind(this);this.workspace_.addChangeListener(this.reflowWrapper_);this.recordCategoryScrollPositions_()};Blockly.Flyout.prototype.emptyRecycleBlocks_=function(){var a=this.recycleBlocks_;this.recycleBlocks_=[];for(var b=0;b<a.length;b++)a[b].dispose(!1,!1)};
Blockly.Flyout.prototype.recordCategoryScrollPositions_=function(){this.categoryScrollPositions=[];for(var a=0;a<this.buttons_.length;a++)if(this.buttons_[a].getIsCategoryLabel()){var b=this.buttons_[a];this.categoryScrollPositions.push({categoryName:b.getText(),position:this.horizontalLayout_?b.getPosition().x:b.getPosition().y})}b=this.categoryScrollPositions.length;if(0<b){for(a=0;a<b-1;a++)this.categoryScrollPositions[a].length=this.categoryScrollPositions[a+1].position-this.categoryScrollPositions[a].position;
for(a=this.categoryScrollPositions[b-1].length=0;a<b;a++){var c=this.parentToolbox_.getCategoryByIndex(a);c&&c.id_&&(this.categoryScrollPositions[a].categoryId=c.id_)}}};Blockly.Flyout.prototype.selectCategoryByScrollPosition=function(a){if(!this.scrollTarget){a=Math.round(a/this.workspace_.scale);for(var b=this.categoryScrollPositions.length-1;0<=b;b--)if(a>=this.categoryScrollPositions[b].position){this.parentToolbox_.selectCategoryById(this.categoryScrollPositions[b].categoryId);break}}};
Blockly.Flyout.prototype.stepScrollAnimation=function(){if(this.scrollTarget){var a=this.horizontalLayout_?-this.workspace_.scrollX:-this.workspace_.scrollY,b=this.scrollTarget-a;1>Math.abs(b)?(this.scrollbar_.set(this.scrollTarget),this.scrollTarget=null):(this.scrollbar_.set(a+b*this.scrollAnimationFraction),requestAnimationFrame(this.stepScrollAnimation.bind(this)))}};
Blockly.Flyout.prototype.getScrollPos=function(){return(this.horizontalLayout_?-this.workspace_.scrollX:-this.workspace_.scrollY)/this.workspace_.scale};Blockly.Flyout.prototype.setScrollPos=function(a){this.scrollbar_.set(a*this.workspace_.scale)};Blockly.Flyout.prototype.setRecyclingEnabled=function(a){this.recyclingEnabled_=a};
Blockly.Flyout.prototype.clearOldBlocks_=function(){for(var a=this.workspace_.getTopBlocks(!1),b=0,c;c=a[b];b++)c.workspace==this.workspace_&&(this.recyclingEnabled_&&Blockly.scratchBlocksUtils.blockIsRecyclable(c)?this.recycleBlock_(c):c.dispose(!1,!1));for(b=0;b<this.backgroundButtons_.length;b++)(a=this.backgroundButtons_[b])&&goog.dom.removeNode(a);for(b=this.backgroundButtons_.length=0;a=this.buttons_[b];b++)a.dispose();this.buttons_.length=0;this.workspace_.getPotentialVariableMap().clear()};
Blockly.Flyout.prototype.addBlockListeners_=function(a,b,c){this.listeners_.push(Blockly.bindEventWithChecks_(a,"mousedown",null,this.blockMouseDown_(b)));this.listeners_.push(Blockly.bindEventWithChecks_(c,"mousedown",null,this.blockMouseDown_(b)));this.listeners_.push(Blockly.bindEvent_(a,"mouseover",b,b.addSelect));this.listeners_.push(Blockly.bindEvent_(a,"mouseout",b,b.removeSelect));this.listeners_.push(Blockly.bindEvent_(c,"mouseover",b,b.addSelect));this.listeners_.push(Blockly.bindEvent_(c,
"mouseout",b,b.removeSelect))};Blockly.Flyout.prototype.blockMouseDown_=function(a){var b=this;return function(c){var d=b.targetWorkspace_.getGesture(c);d&&(d.setStartBlock(a),d.handleFlyoutStart(c,b))}};Blockly.Flyout.prototype.onMouseDown_=function(a){var b=this.targetWorkspace_.getGesture(a);b&&b.handleFlyoutStart(a,this)};
Blockly.Flyout.prototype.createBlock=function(a){var b=null;Blockly.Events.disable();var c=this.targetWorkspace_.getAllVariables();this.targetWorkspace_.setResizesEnabled(!1);try{b=this.placeNewBlock_(a),Blockly.hideChaff()}finally{Blockly.Events.enable()}a=Blockly.Variables.getAddedVariables(this.targetWorkspace_,c);if(Blockly.Events.isEnabled())for(Blockly.Events.setGroup(!0),Blockly.Events.fire(new Blockly.Events.Create(b)),c=0;c<a.length;c++)Blockly.Events.fire(new Blockly.Events.VarCreate(a[c]));
this.autoClose&&this.hide();return b};Blockly.Flyout.prototype.reflow=function(){this.reflowWrapper_&&this.workspace_.removeChangeListener(this.reflowWrapper_);var a=this.workspace_.getTopBlocks(!1);this.reflowInternal_(a);this.reflowWrapper_&&this.workspace_.addChangeListener(this.reflowWrapper_)};Blockly.Flyout.prototype.isScrollable=function(){return this.scrollbar_?this.scrollbar_.isVisible():!1};
Blockly.Flyout.prototype.placeNewBlock_=function(a){var b=this.targetWorkspace_;if(!a.getSvgRoot())throw"oldBlock is not rendered.";var c=Blockly.Xml.blockToDom(a);b.setResizesEnabled(!1);c=Blockly.Xml.domToBlock(c,b);if(!c.getSvgRoot())throw"block is not rendered.";var d=b.getOriginOffsetInPixels(),e=this.workspace_.getOriginOffsetInPixels();a=a.getRelativeToSurfaceXY().scale(this.workspace_.scale);a=goog.math.Coordinate.sum(e,a);b=goog.math.Coordinate.difference(a,d).scale(1/b.scale);c.moveBy(b.x,
b.y);return c};Blockly.Flyout.prototype.recycleBlock_=function(a){var b=a.getRelativeToSurfaceXY();a.moveBy(-b.x,-b.y);this.recycleBlocks_.push(a)};goog.dom.animationFrame={};goog.dom.animationFrame.polyfill={};goog.dom.animationFrame.polyfill.ENABLED=!0;
goog.dom.animationFrame.polyfill.install=function(){if(goog.dom.animationFrame.polyfill.ENABLED){for(var a=["ms","moz","webkit","o"],b=0,c;c=a[b]&&!goog.global.requestAnimationFrame;++b)goog.global.requestAnimationFrame=goog.global[c+"RequestAnimationFrame"],goog.global.cancelAnimationFrame=goog.global[c+"CancelAnimationFrame"]||goog.global[c+"CancelRequestAnimationFrame"];if(!goog.global.requestAnimationFrame){var d=0;goog.global.requestAnimationFrame=function(a){var b=(new Date).getTime(),c=Math.max(0,
16-(b-d));d=b+c;return goog.global.setTimeout(function(){a(b+c)},c)};goog.global.cancelAnimationFrame||(goog.global.cancelAnimationFrame=function(a){clearTimeout(a)})}}};Blockly.HorizontalFlyout=function(a){a.getMetrics=this.getMetrics_.bind(this);a.setMetrics=this.setMetrics_.bind(this);Blockly.HorizontalFlyout.superClass_.constructor.call(this,a);this.horizontalLayout_=!0};goog.inherits(Blockly.HorizontalFlyout,Blockly.Flyout);
Blockly.HorizontalFlyout.prototype.getMetrics_=function(){if(!this.isVisible())return null;try{var a=this.workspace_.getCanvas().getBBox()}catch(e){a={height:0,y:0,width:0,x:0}}var b=this.SCROLLBAR_PADDING,c=this.SCROLLBAR_PADDING;this.toolboxPosition_==Blockly.TOOLBOX_AT_BOTTOM&&(b=0);var d=this.height_;this.toolboxPosition_==Blockly.TOOLBOX_AT_TOP&&(d+=this.MARGIN);return{viewHeight:d,viewWidth:this.width_-2*this.SCROLLBAR_PADDING,contentHeight:a.height*this.workspace_.scale+2*this.MARGIN,contentWidth:a.width*
this.workspace_.scale+2*this.MARGIN,viewTop:-this.workspace_.scrollY,viewLeft:-this.workspace_.scrollX,contentTop:a.y,contentLeft:a.x,absoluteTop:b,absoluteLeft:c}};Blockly.HorizontalFlyout.prototype.setMetrics_=function(a){var b=this.getMetrics_();b&&(goog.isNumber(a.x)&&(this.workspace_.scrollX=-b.contentWidth*a.x),this.workspace_.translate(this.workspace_.scrollX+b.absoluteLeft,this.workspace_.scrollY+b.absoluteTop),this.categoryScrollPositions&&this.selectCategoryByScrollPosition(-this.workspace_.scrollX))};
Blockly.HorizontalFlyout.prototype.position=function(){if(this.isVisible()){var a=this.targetWorkspace_.getMetrics();if(a){this.setBackgroundPath_(this.horizontalLayout_?a.viewWidth-2*this.CORNER_RADIUS:this.width_-this.CORNER_RADIUS,this.horizontalLayout_?this.height_-this.CORNER_RADIUS:a.viewHeight-2*this.CORNER_RADIUS);var b=a.absoluteLeft;this.toolboxPosition_==Blockly.TOOLBOX_AT_RIGHT&&(b+=a.viewWidth,b-=this.width_);var c=a.absoluteTop;this.toolboxPosition_==Blockly.TOOLBOX_AT_BOTTOM&&(c+=a.viewHeight,
c-=this.height_);this.horizontalLayout_?this.width_=a.viewWidth:this.height_=a.viewHeight;this.svgGroup_.setAttribute("width",this.width_);this.svgGroup_.setAttribute("height",this.height_);Blockly.utils.setCssTransform(this.svgGroup_,"translate("+b+"px,"+c+"px)");this.scrollbar_&&(this.scrollbar_.setOrigin(b,c),this.scrollbar_.resize());this.svgGroup_.style.opacity=1}}};
Blockly.HorizontalFlyout.prototype.setBackgroundPath_=function(a,b){var c=this.toolboxPosition_==Blockly.TOOLBOX_AT_TOP,d=["M 0,"+(c?0:this.CORNER_RADIUS)];c?(d.push("h",a+2*this.CORNER_RADIUS),d.push("v",b),d.push("a",this.CORNER_RADIUS,this.CORNER_RADIUS,0,0,1,-this.CORNER_RADIUS,this.CORNER_RADIUS),d.push("h",-1*a),d.push("a",this.CORNER_RADIUS,this.CORNER_RADIUS,0,0,1,-this.CORNER_RADIUS,-this.CORNER_RADIUS)):(d.push("a",this.CORNER_RADIUS,this.CORNER_RADIUS,0,0,1,this.CORNER_RADIUS,-this.CORNER_RADIUS),
d.push("h",a),d.push("a",this.CORNER_RADIUS,this.CORNER_RADIUS,0,0,1,this.CORNER_RADIUS,this.CORNER_RADIUS),d.push("v",b),d.push("h",-a-2*this.CORNER_RADIUS));d.push("z");this.svgBackground_.setAttribute("d",d.join(" "))};Blockly.HorizontalFlyout.prototype.scrollToStart=function(){this.scrollbar_.set(this.RTL?Infinity:0)};
Blockly.HorizontalFlyout.prototype.scrollTo=function(a){this.scrollTarget=a*this.workspace_.scale;a=this.workspace_.getMetrics();this.scrollTarget=Math.min(this.scrollTarget,a.contentWidth-a.viewWidth);this.stepScrollAnimation()};
Blockly.HorizontalFlyout.prototype.wheel_=function(a){this.scrollTarget=null;var b=a.deltaX;Math.abs(a.deltaY)>Math.abs(b)&&(b=a.deltaY);if(b){goog.userAgent.GECKO&&1===a.deltaMode&&(b*=10);var c=this.getMetrics_();b=c.viewLeft+b;b=Math.min(b,c.contentWidth-c.viewWidth);b=Math.max(b,0);this.scrollbar_.set(b);Blockly.WidgetDiv.hide(!0);Blockly.DropDownDiv.hideWithoutAnimation()}a.preventDefault();a.stopPropagation()};
Blockly.HorizontalFlyout.prototype.layout_=function(a,b){this.workspace_.scale=this.targetWorkspace_.scale;var c=this.MARGIN,d=c;this.RTL&&(a=a.reverse());for(var e=0,f;f=a[e];e++)if("block"==f.type){f=f.block;for(var g=f.getDescendants(!1),h=0,k;k=g[h];h++)k.isInFlyout=!0;g=f.getSvgRoot();h=f.getHeightWidth();k=d;this.RTL&&(k+=h.width);f.moveBy(k,c);d+=h.width+b[e];h=Blockly.utils.createSvgElement("rect",{"fill-opacity":0},null);h.tooltip=f;Blockly.Tooltip.bindMouseEvents(h);this.workspace_.getCanvas().insertBefore(h,
f.getSvgRoot());f.flyoutRect_=h;this.backgroundButtons_[e]=h;this.addBlockListeners_(g,f,h)}else"button"==f.type&&(f=f.button,g=f.createDom(),f.moveTo(d,c),f.show(),this.listeners_.push(Blockly.bindEventWithChecks_(g,"mousedown",this,this.onMouseDown_)),this.buttons_.push(f),d+=f.width+b[e])};
Blockly.HorizontalFlyout.prototype.isDragTowardWorkspace=function(a){a=Math.atan2(a.y,a.x)/Math.PI*180;var b=!1,c=this.dragAngleRange_;this.toolboxPosition_==Blockly.TOOLBOX_AT_TOP?a<90+c&&a>90-c&&(b=!0):a>-90-c&&a<-90+c&&(b=!0);return b};
Blockly.HorizontalFlyout.prototype.getClientRect=function(){if(!this.svgGroup_)return null;var a=this.svgGroup_.getBoundingClientRect(),b=a.top;a=a.height;if(this.toolboxPosition_==Blockly.TOOLBOX_AT_TOP)return new goog.math.Rect(-1E9,b-1E9,2E9,1E9+a);if(this.toolboxPosition_==Blockly.TOOLBOX_AT_BOTTOM)return new goog.math.Rect(-1E9,b,2E9,1E9+a)};
Blockly.HorizontalFlyout.prototype.reflowInternal_=function(a){for(var b=0,c=0,d;d=a[c];c++)b=Math.max(b,d.getHeightWidth().height);b+=1.5*this.MARGIN;b*=this.workspace_.scale;b+=Blockly.Scrollbar.scrollbarThickness;if(this.height_!=b){for(c=0;d=a[c];c++){var e=d.getHeightWidth();if(d.flyoutRect_){d.flyoutRect_.setAttribute("width",e.width);d.flyoutRect_.setAttribute("height",e.height);var f=d.getRelativeToSurfaceXY();d.flyoutRect_.setAttribute("y",f.y);d.flyoutRect_.setAttribute("x",this.RTL?f.x-
e.width:f.x);(e=d.startHat_?Blockly.BlockSvg.START_HAT_HEIGHT:0)&&d.moveBy(0,e);d.flyoutRect_.setAttribute("y",f.y)}}this.height_=b;this.targetWorkspace_.resize()}};Blockly.VerticalFlyout=function(a){a.getMetrics=this.getMetrics_.bind(this);a.setMetrics=this.setMetrics_.bind(this);Blockly.VerticalFlyout.superClass_.constructor.call(this,a);this.horizontalLayout_=!1;this.checkboxes_={}};goog.inherits(Blockly.VerticalFlyout,Blockly.Flyout);Blockly.VerticalFlyout.prototype.autoClose=!1;Blockly.VerticalFlyout.prototype.DEFAULT_WIDTH=250;Blockly.VerticalFlyout.prototype.CHECKBOX_SIZE=25;Blockly.VerticalFlyout.prototype.CHECKBOX_TOUCH_PADDING=12;
Blockly.VerticalFlyout.prototype.CHECKMARK_PATH="M"+Blockly.VerticalFlyout.prototype.CHECKBOX_SIZE/4+" "+Blockly.VerticalFlyout.prototype.CHECKBOX_SIZE/2+"L"+5*Blockly.VerticalFlyout.prototype.CHECKBOX_SIZE/12+" "+2*Blockly.VerticalFlyout.prototype.CHECKBOX_SIZE/3+"L"+3*Blockly.VerticalFlyout.prototype.CHECKBOX_SIZE/4+" "+Blockly.VerticalFlyout.prototype.CHECKBOX_SIZE/3;Blockly.VerticalFlyout.prototype.CHECKBOX_CORNER_RADIUS=5;Blockly.VerticalFlyout.prototype.CHECKBOX_MARGIN=Blockly.Flyout.prototype.MARGIN;
Blockly.VerticalFlyout.prototype.CHECKBOX_SPACE_X=Blockly.VerticalFlyout.prototype.CHECKBOX_SIZE+2*Blockly.VerticalFlyout.prototype.CHECKBOX_MARGIN;Blockly.VerticalFlyout.prototype.init=function(a){Blockly.VerticalFlyout.superClass_.init.call(this,a);this.workspace_.scale=a.scale};
Blockly.VerticalFlyout.prototype.createDom=function(a){Blockly.VerticalFlyout.superClass_.createDom.call(this,a);this.defs_=Blockly.utils.createSvgElement("defs",{},this.svgGroup_);a=Blockly.utils.createSvgElement("clipPath",{id:"blocklyBlockMenuClipPath"},this.defs_);this.clipRect_=Blockly.utils.createSvgElement("rect",{id:"blocklyBlockMenuClipRect",height:"0",width:"0",y:"0",x:"0"},a);this.workspace_.svgGroup_.setAttribute("clip-path","url(#blocklyBlockMenuClipPath)");return this.svgGroup_};
Blockly.VerticalFlyout.prototype.getContentBoundingBox_=function(){var a=this.workspace_.getBlocksBoundingBox(),b=a.x,c=a.y,d=a.x+a.width;a=a.y+a.height;for(var e=0;e<this.buttons_.length;e++){var f=this.buttons_[e],g=f.getPosition();g.x<b&&(b=g.x);g.y<c&&(c=g.y);g.x+f.width>d&&(d=g.x+f.width);g.y+f.height>a&&(a=g.y+f.height)}return{x:b,y:c,width:d-b,height:a-c}};
Blockly.VerticalFlyout.prototype.getMetrics_=function(){if(!this.isVisible())return null;var a=this.getContentBoundingBox_(),b=this.SCROLLBAR_PADDING,c=this.height_-2*this.SCROLLBAR_PADDING,d=this.getWidth()-this.SCROLLBAR_PADDING,e=a.height*this.workspace_.scale;this.recordCategoryScrollPositions_();var f=this.MARGIN;if(0<this.categoryScrollPositions.length){var g=e-this.categoryScrollPositions[this.categoryScrollPositions.length-1].position*this.workspace_.scale;g<c&&(f=c-g)}return{viewHeight:c,
viewWidth:d,contentHeight:e+f,contentWidth:a.width*this.workspace_.scale+2*this.MARGIN,viewTop:-this.workspace_.scrollY+a.y,viewLeft:-this.workspace_.scrollX,contentTop:a.y,contentLeft:a.x,absoluteTop:b,absoluteLeft:0}};
Blockly.VerticalFlyout.prototype.setMetrics_=function(a){var b=this.getMetrics_();b&&(goog.isNumber(a.y)&&(this.workspace_.scrollY=-b.contentHeight*a.y),this.workspace_.translate(this.workspace_.scrollX+b.absoluteLeft,this.workspace_.scrollY+b.absoluteTop),this.clipRect_.setAttribute("height",Math.max(0,b.viewHeight)+"px"),this.clipRect_.setAttribute("width",b.viewWidth+"px"),this.categoryScrollPositions&&this.selectCategoryByScrollPosition(-this.workspace_.scrollY))};
Blockly.VerticalFlyout.prototype.position=function(){if(this.isVisible()){var a=this.targetWorkspace_.getMetrics();if(a){this.width_=this.getWidth();if(this.parentToolbox_){var b=this.parentToolbox_.getWidth()-this.width_;b=this.toolboxPosition_==Blockly.TOOLBOX_AT_RIGHT?a.viewWidth:b;var c=0}else b=this.toolboxPosition_==Blockly.TOOLBOX_AT_RIGHT?a.viewWidth-this.width_:0,c=0;this.height_=Math.max(0,a.viewHeight-c);this.setBackgroundPath_(this.width_,this.height_);this.svgGroup_.setAttribute("width",
this.width_);this.svgGroup_.setAttribute("height",this.height_);Blockly.utils.setCssTransform(this.svgGroup_,"translate("+b+"px,"+c+"px)");this.scrollbar_&&(this.scrollbar_.setOrigin(b,c),this.scrollbar_.resize());this.svgGroup_.style.opacity=1}}};
Blockly.VerticalFlyout.prototype.setBackgroundPath_=function(a,b){var c=this.toolboxPosition_==Blockly.TOOLBOX_AT_RIGHT,d=["M 0,0"];d.push("h",a);d.push("a",this.CORNER_RADIUS,this.CORNER_RADIUS,0,0,c?0:1,c?-this.CORNER_RADIUS:this.CORNER_RADIUS,this.CORNER_RADIUS);d.push("v",Math.max(0,b-2*this.CORNER_RADIUS));d.push("a",this.CORNER_RADIUS,this.CORNER_RADIUS,0,0,c?0:1,c?this.CORNER_RADIUS:-this.CORNER_RADIUS,this.CORNER_RADIUS);d.push("h",-a);d.push("z");this.svgBackground_.setAttribute("d",d.join(" "))};
Blockly.VerticalFlyout.prototype.scrollToStart=function(){this.scrollbar_.set(0)};Blockly.VerticalFlyout.prototype.scrollTo=function(a){this.scrollTarget=a*this.workspace_.scale;a=this.workspace_.getMetrics();this.scrollTarget=Math.min(this.scrollTarget,a.contentHeight-a.viewHeight);this.stepScrollAnimation()};
Blockly.VerticalFlyout.prototype.wheel_=function(a){this.scrollTarget=null;var b=a.deltaY;if(b){goog.userAgent.GECKO&&1===a.deltaMode&&(b*=10);var c=this.getMetrics_();b=c.viewTop-c.contentTop+b;b=Math.min(b,c.contentHeight-c.viewHeight);b=Math.max(b,0);this.scrollbar_.set(b);Blockly.WidgetDiv.hide(!0);Blockly.DropDownDiv.hideWithoutAnimation()}a.preventDefault();a.stopPropagation()};
Blockly.VerticalFlyout.prototype.clearOldBlocks_=function(){Blockly.VerticalFlyout.superClass_.clearOldBlocks_.call(this);for(var a in this.checkboxes_)if(Object.prototype.hasOwnProperty.call(this.checkboxes_,a)){var b=this.checkboxes_[a];b.block.flyoutCheckbox=null;goog.dom.removeNode(b.svgRoot)}this.checkboxes_={}};
Blockly.VerticalFlyout.prototype.addBlockListeners_=function(a,b,c){Blockly.VerticalFlyout.superClass_.addBlockListeners_.call(this,a,b,c);b.flyoutCheckbox&&this.listeners_.push(Blockly.bindEvent_(b.flyoutCheckbox.svgRoot,"mousedown",null,this.checkboxClicked_(b.flyoutCheckbox)));this.listeners_.push(Blockly.bindEvent_(this.svgGroup_,"mouseenter",this,this.flyoutMouseEnter_));this.listeners_.push(Blockly.bindEvent_(this.svgGroup_,"mouseleave",this,this.flyoutMouseLeave_));this.listeners_.push(Blockly.bindEvent_(this.svgGroup_,
"wheel",this,this.flyoutMouseEnter_))};
Blockly.VerticalFlyout.prototype.layout_=function(a,b){for(var c=this.MARGIN,d=this.getWidth()/this.workspace_.scale,e=c,f=0,g;g=a[f];f++)if("block"==g.type){g=g.block;for(var h=g.getDescendants(!1),k=0,l;l=h[k];k++)l.isInFlyout=!0;h=g.getSvgRoot();k=g.getHeightWidth();l=g.getRelativeToSurfaceXY().x;var m=d-this.MARGIN;l=this.RTL?m-l:c;g.hasCheckboxInFlyout()&&(this.createCheckbox_(g,c,e,k),l=this.RTL?l-(this.CHECKBOX_SIZE+this.CHECKBOX_MARGIN):l+(this.CHECKBOX_SIZE+this.CHECKBOX_MARGIN));g.moveBy(l,
e+(g.startHat_?Blockly.BlockSvg.START_HAT_HEIGHT:0));l=this.createRect_(g,this.RTL?l-k.width:l,e,k,f);this.addBlockListeners_(h,g,l);e+=k.height+b[f]+(g.startHat_?Blockly.BlockSvg.START_HAT_HEIGHT:0)}else"button"==g.type&&(g=g.button,h=g.createDom(),this.RTL?g.moveTo(d-this.MARGIN-g.width,e):g.moveTo(c,e),g.show(),this.listeners_.push(Blockly.bindEventWithChecks_(h,"mousedown",this,this.onMouseDown_)),this.buttons_.push(g),e+=g.height+b[f])};
Blockly.VerticalFlyout.prototype.createRect_=function(a,b,c,d,e){b=Blockly.utils.createSvgElement("rect",{"fill-opacity":0,x:b,y:c,height:d.height,width:d.width},null);b.tooltip=a;Blockly.Tooltip.bindMouseEvents(b);this.workspace_.getCanvas().insertBefore(b,a.getSvgRoot());a.flyoutRect_=b;return this.backgroundButtons_[e]=b};
Blockly.VerticalFlyout.prototype.createCheckbox_=function(a,b,c,d){var e=Blockly.VerticalFlyout.getCheckboxState(a.id),f=a.getSvgRoot(),g=this.CHECKBOX_SIZE+this.CHECKBOX_MARGIN;g=this.RTL?this.getWidth()/this.workspace_.scale-g:b;b=this.CHECKBOX_TOUCH_PADDING;c=Blockly.utils.createSvgElement("g",{transform:"translate("+g+", "+(c+d.height/2-this.CHECKBOX_SIZE/2)+")"},null);Blockly.utils.createSvgElement("rect",{"class":"blocklyFlyoutCheckbox",height:this.CHECKBOX_SIZE,width:this.CHECKBOX_SIZE,rx:this.CHECKBOX_CORNER_RADIUS,
ry:this.CHECKBOX_CORNER_RADIUS},c);Blockly.utils.createSvgElement("path",{"class":"blocklyFlyoutCheckboxPath",d:this.CHECKMARK_PATH},c);Blockly.utils.createSvgElement("rect",{"class":"blocklyTouchTargetBackground",x:-b+"px",y:-b+"px",height:this.CHECKBOX_SIZE+2*b,width:this.CHECKBOX_SIZE+2*b},c);d={svgRoot:c,clicked:e,block:a};e&&Blockly.utils.addClass(d.svgRoot,"checked");a.flyoutCheckbox=d;this.workspace_.getCanvas().insertBefore(c,f);this.checkboxes_[a.id]=d};
Blockly.VerticalFlyout.prototype.checkboxClicked_=function(a){return function(b){this.setCheckboxState(a.block.id,!a.clicked);b.stopPropagation();b.preventDefault()}.bind(this)};Blockly.VerticalFlyout.prototype.flyoutMouseEnter_=function(){this.svgGroup_.setAttribute("overflow","visible");this.clipRect_.setAttribute("width",this.getMetrics_().viewWidth+1E4+"px")};
Blockly.VerticalFlyout.prototype.flyoutMouseLeave_=function(){this.svgGroup_.setAttribute("overflow","hidden");this.clipRect_.setAttribute("width",this.getMetrics_().viewWidth+"px")};Blockly.VerticalFlyout.prototype.setCheckboxState=function(a,b){if((a=this.checkboxes_[a])&&a.clicked!==b){var c=a.clicked;a.clicked=b;a.clicked?Blockly.utils.addClass(a.svgRoot,"checked"):Blockly.utils.removeClass(a.svgRoot,"checked");Blockly.Events.fire(new Blockly.Events.Change(a.block,"checkbox",null,c,b))}};
Blockly.VerticalFlyout.prototype.isDragTowardWorkspace=function(a){a=Math.atan2(a.y,a.x)/Math.PI*180;var b=!1,c=this.dragAngleRange_;if(this.toolboxPosition_==Blockly.TOOLBOX_AT_LEFT)a<c&&a>-c&&(b=!0);else if(a<-180+c||a>180-c)b=!0;return b};
Blockly.VerticalFlyout.prototype.getClientRect=function(){if(!this.svgGroup_)return null;var a=this.svgGroup_.getBoundingClientRect(),b=a.left,c=a.top,d=a.width;a=a.height;return this.toolboxPosition_==Blockly.TOOLBOX_AT_LEFT?new goog.math.Rect(b-1E9,c,1E9+d,a):new goog.math.Rect(b,c,1E9+d,a)};Blockly.VerticalFlyout.prototype.reflowInternal_=function(){};Blockly.VerticalFlyout.getCheckboxState=function(){return!1};goog.events.FocusHandler=function(a){goog.events.EventTarget.call(this);this.element_=a;a=goog.userAgent.IE?"focusout":"blur";this.listenKeyIn_=goog.events.listen(this.element_,goog.userAgent.IE?"focusin":"focus",this,!goog.userAgent.IE);this.listenKeyOut_=goog.events.listen(this.element_,a,this,!goog.userAgent.IE)};goog.inherits(goog.events.FocusHandler,goog.events.EventTarget);goog.events.FocusHandler.EventType={FOCUSIN:"focusin",FOCUSOUT:"focusout"};
goog.events.FocusHandler.prototype.handleEvent=function(a){var b=a.getBrowserEvent();b=new goog.events.BrowserEvent(b);b.type="focusin"==a.type||"focus"==a.type?goog.events.FocusHandler.EventType.FOCUSIN:goog.events.FocusHandler.EventType.FOCUSOUT;this.dispatchEvent(b)};goog.events.FocusHandler.prototype.disposeInternal=function(){goog.events.FocusHandler.superClass_.disposeInternal.call(this);goog.events.unlistenByKey(this.listenKeyIn_);goog.events.unlistenByKey(this.listenKeyOut_);delete this.element_};goog.debug.LogRecord=function(a,b,c,d,e){this.reset(a,b,c,d,e)};goog.debug.LogRecord.prototype.sequenceNumber_=0;goog.debug.LogRecord.prototype.exception_=null;goog.debug.LogRecord.ENABLE_SEQUENCE_NUMBERS=!0;goog.debug.LogRecord.nextSequenceNumber_=0;
goog.debug.LogRecord.prototype.reset=function(a,b,c,d,e){goog.debug.LogRecord.ENABLE_SEQUENCE_NUMBERS&&(this.sequenceNumber_="number"==typeof e?e:goog.debug.LogRecord.nextSequenceNumber_++);this.time_=d||goog.now();this.level_=a;this.msg_=b;this.loggerName_=c;delete this.exception_};goog.debug.LogRecord.prototype.getLoggerName=function(){return this.loggerName_};goog.debug.LogRecord.prototype.getException=function(){return this.exception_};
goog.debug.LogRecord.prototype.setException=function(a){this.exception_=a};goog.debug.LogRecord.prototype.setLoggerName=function(a){this.loggerName_=a};goog.debug.LogRecord.prototype.getLevel=function(){return this.level_};goog.debug.LogRecord.prototype.setLevel=function(a){this.level_=a};goog.debug.LogRecord.prototype.getMessage=function(){return this.msg_};goog.debug.LogRecord.prototype.setMessage=function(a){this.msg_=a};goog.debug.LogRecord.prototype.getMillis=function(){return this.time_};
goog.debug.LogRecord.prototype.setMillis=function(a){this.time_=a};goog.debug.LogRecord.prototype.getSequenceNumber=function(){return this.sequenceNumber_};goog.debug.LogBuffer=function(){goog.asserts.assert(goog.debug.LogBuffer.isBufferingEnabled(),"Cannot use goog.debug.LogBuffer without defining goog.debug.LogBuffer.CAPACITY.");this.clear()};goog.debug.LogBuffer.getInstance=function(){goog.debug.LogBuffer.instance_||(goog.debug.LogBuffer.instance_=new goog.debug.LogBuffer);return goog.debug.LogBuffer.instance_};goog.debug.LogBuffer.CAPACITY=0;
goog.debug.LogBuffer.prototype.addRecord=function(a,b,c){var d=(this.curIndex_+1)%goog.debug.LogBuffer.CAPACITY;this.curIndex_=d;if(this.isFull_)return d=this.buffer_[d],d.reset(a,b,c),d;this.isFull_=d==goog.debug.LogBuffer.CAPACITY-1;return this.buffer_[d]=new goog.debug.LogRecord(a,b,c)};goog.debug.LogBuffer.isBufferingEnabled=function(){return 0<goog.debug.LogBuffer.CAPACITY};
goog.debug.LogBuffer.prototype.clear=function(){this.buffer_=Array(goog.debug.LogBuffer.CAPACITY);this.curIndex_=-1;this.isFull_=!1};goog.debug.LogBuffer.prototype.forEachRecord=function(a){var b=this.buffer_;if(b[0]){var c=this.curIndex_,d=this.isFull_?c:-1;do d=(d+1)%goog.debug.LogBuffer.CAPACITY,a(b[d]);while(d!=c)}};goog.debug.Logger=function(a){this.name_=a;this.handlers_=this.children_=this.level_=this.parent_=null};goog.debug.Logger.ROOT_LOGGER_NAME="";goog.debug.Logger.ENABLE_HIERARCHY=!0;goog.debug.Logger.ENABLE_PROFILER_LOGGING=!1;goog.debug.Logger.ENABLE_HIERARCHY||(goog.debug.Logger.rootHandlers_=[]);goog.debug.Logger.Level=function(a,b){this.name=a;this.value=b};goog.debug.Logger.Level.prototype.toString=function(){return this.name};goog.debug.Logger.Level.OFF=new goog.debug.Logger.Level("OFF",Infinity);
goog.debug.Logger.Level.SHOUT=new goog.debug.Logger.Level("SHOUT",1200);goog.debug.Logger.Level.SEVERE=new goog.debug.Logger.Level("SEVERE",1E3);goog.debug.Logger.Level.WARNING=new goog.debug.Logger.Level("WARNING",900);goog.debug.Logger.Level.INFO=new goog.debug.Logger.Level("INFO",800);goog.debug.Logger.Level.CONFIG=new goog.debug.Logger.Level("CONFIG",700);goog.debug.Logger.Level.FINE=new goog.debug.Logger.Level("FINE",500);goog.debug.Logger.Level.FINER=new goog.debug.Logger.Level("FINER",400);
goog.debug.Logger.Level.FINEST=new goog.debug.Logger.Level("FINEST",300);goog.debug.Logger.Level.ALL=new goog.debug.Logger.Level("ALL",0);goog.debug.Logger.Level.PREDEFINED_LEVELS=[goog.debug.Logger.Level.OFF,goog.debug.Logger.Level.SHOUT,goog.debug.Logger.Level.SEVERE,goog.debug.Logger.Level.WARNING,goog.debug.Logger.Level.INFO,goog.debug.Logger.Level.CONFIG,goog.debug.Logger.Level.FINE,goog.debug.Logger.Level.FINER,goog.debug.Logger.Level.FINEST,goog.debug.Logger.Level.ALL];
goog.debug.Logger.Level.predefinedLevelsCache_=null;goog.debug.Logger.Level.createPredefinedLevelsCache_=function(){goog.debug.Logger.Level.predefinedLevelsCache_={};for(var a=0,b;b=goog.debug.Logger.Level.PREDEFINED_LEVELS[a];a++)goog.debug.Logger.Level.predefinedLevelsCache_[b.value]=b,goog.debug.Logger.Level.predefinedLevelsCache_[b.name]=b};
goog.debug.Logger.Level.getPredefinedLevel=function(a){goog.debug.Logger.Level.predefinedLevelsCache_||goog.debug.Logger.Level.createPredefinedLevelsCache_();return goog.debug.Logger.Level.predefinedLevelsCache_[a]||null};
goog.debug.Logger.Level.getPredefinedLevelByValue=function(a){goog.debug.Logger.Level.predefinedLevelsCache_||goog.debug.Logger.Level.createPredefinedLevelsCache_();if(a in goog.debug.Logger.Level.predefinedLevelsCache_)return goog.debug.Logger.Level.predefinedLevelsCache_[a];for(var b=0;b<goog.debug.Logger.Level.PREDEFINED_LEVELS.length;++b){var c=goog.debug.Logger.Level.PREDEFINED_LEVELS[b];if(c.value<=a)return c}return null};goog.debug.Logger.getLogger=function(a){return goog.debug.LogManager.getLogger(a)};
goog.debug.Logger.logToProfilers=function(a){if(goog.debug.Logger.ENABLE_PROFILER_LOGGING){var b=goog.global.msWriteProfilerMark;b?b(a):(b=goog.global.console)&&b.timeStamp&&b.timeStamp(a)}};goog.debug.Logger.prototype.getName=function(){return this.name_};
goog.debug.Logger.prototype.addHandler=function(a){goog.debug.LOGGING_ENABLED&&(goog.debug.Logger.ENABLE_HIERARCHY?(this.handlers_||(this.handlers_=[]),this.handlers_.push(a)):(goog.asserts.assert(!this.name_,"Cannot call addHandler on a non-root logger when goog.debug.Logger.ENABLE_HIERARCHY is false."),goog.debug.Logger.rootHandlers_.push(a)))};
goog.debug.Logger.prototype.removeHandler=function(a){if(goog.debug.LOGGING_ENABLED){var b=goog.debug.Logger.ENABLE_HIERARCHY?this.handlers_:goog.debug.Logger.rootHandlers_;return!!b&&goog.array.remove(b,a)}return!1};goog.debug.Logger.prototype.getParent=function(){return this.parent_};goog.debug.Logger.prototype.getChildren=function(){this.children_||(this.children_={});return this.children_};
goog.debug.Logger.prototype.setLevel=function(a){goog.debug.LOGGING_ENABLED&&(goog.debug.Logger.ENABLE_HIERARCHY?this.level_=a:(goog.asserts.assert(!this.name_,"Cannot call setLevel() on a non-root logger when goog.debug.Logger.ENABLE_HIERARCHY is false."),goog.debug.Logger.rootLevel_=a))};goog.debug.Logger.prototype.getLevel=function(){return goog.debug.LOGGING_ENABLED?this.level_:goog.debug.Logger.Level.OFF};
goog.debug.Logger.prototype.getEffectiveLevel=function(){if(!goog.debug.LOGGING_ENABLED)return goog.debug.Logger.Level.OFF;if(!goog.debug.Logger.ENABLE_HIERARCHY)return goog.debug.Logger.rootLevel_;if(this.level_)return this.level_;if(this.parent_)return this.parent_.getEffectiveLevel();goog.asserts.fail("Root logger has no level set.");return null};goog.debug.Logger.prototype.isLoggable=function(a){return goog.debug.LOGGING_ENABLED&&a.value>=this.getEffectiveLevel().value};
goog.debug.Logger.prototype.log=function(a,b,c){goog.debug.LOGGING_ENABLED&&this.isLoggable(a)&&(goog.isFunction(b)&&(b=b()),this.doLogRecord_(this.getLogRecord(a,b,c)))};goog.debug.Logger.prototype.getLogRecord=function(a,b,c){a=goog.debug.LogBuffer.isBufferingEnabled()?goog.debug.LogBuffer.getInstance().addRecord(a,b,this.name_):new goog.debug.LogRecord(a,String(b),this.name_);c&&a.setException(c);return a};
goog.debug.Logger.prototype.shout=function(a,b){goog.debug.LOGGING_ENABLED&&this.log(goog.debug.Logger.Level.SHOUT,a,b)};goog.debug.Logger.prototype.severe=function(a,b){goog.debug.LOGGING_ENABLED&&this.log(goog.debug.Logger.Level.SEVERE,a,b)};goog.debug.Logger.prototype.warning=function(a,b){goog.debug.LOGGING_ENABLED&&this.log(goog.debug.Logger.Level.WARNING,a,b)};goog.debug.Logger.prototype.info=function(a,b){goog.debug.LOGGING_ENABLED&&this.log(goog.debug.Logger.Level.INFO,a,b)};
goog.debug.Logger.prototype.config=function(a,b){goog.debug.LOGGING_ENABLED&&this.log(goog.debug.Logger.Level.CONFIG,a,b)};goog.debug.Logger.prototype.fine=function(a,b){goog.debug.LOGGING_ENABLED&&this.log(goog.debug.Logger.Level.FINE,a,b)};goog.debug.Logger.prototype.finer=function(a,b){goog.debug.LOGGING_ENABLED&&this.log(goog.debug.Logger.Level.FINER,a,b)};goog.debug.Logger.prototype.finest=function(a,b){goog.debug.LOGGING_ENABLED&&this.log(goog.debug.Logger.Level.FINEST,a,b)};
goog.debug.Logger.prototype.logRecord=function(a){goog.debug.LOGGING_ENABLED&&this.isLoggable(a.getLevel())&&this.doLogRecord_(a)};goog.debug.Logger.prototype.doLogRecord_=function(a){goog.debug.Logger.ENABLE_PROFILER_LOGGING&&goog.debug.Logger.logToProfilers("log:"+a.getMessage());if(goog.debug.Logger.ENABLE_HIERARCHY)for(var b=this;b;)b.callPublish_(a),b=b.getParent();else{b=0;for(var c;c=goog.debug.Logger.rootHandlers_[b++];)c(a)}};
goog.debug.Logger.prototype.callPublish_=function(a){if(this.handlers_)for(var b=0,c;c=this.handlers_[b];b++)c(a)};goog.debug.Logger.prototype.setParent_=function(a){this.parent_=a};goog.debug.Logger.prototype.addChild_=function(a,b){this.getChildren()[a]=b};goog.debug.LogManager={};goog.debug.LogManager.loggers_={};goog.debug.LogManager.rootLogger_=null;
goog.debug.LogManager.initialize=function(){goog.debug.LogManager.rootLogger_||(goog.debug.LogManager.rootLogger_=new goog.debug.Logger(goog.debug.Logger.ROOT_LOGGER_NAME),goog.debug.LogManager.loggers_[goog.debug.Logger.ROOT_LOGGER_NAME]=goog.debug.LogManager.rootLogger_,goog.debug.LogManager.rootLogger_.setLevel(goog.debug.Logger.Level.CONFIG))};goog.debug.LogManager.getLoggers=function(){return goog.debug.LogManager.loggers_};
goog.debug.LogManager.getRoot=function(){goog.debug.LogManager.initialize();return goog.debug.LogManager.rootLogger_};goog.debug.LogManager.getLogger=function(a){goog.debug.LogManager.initialize();return goog.debug.LogManager.loggers_[a]||goog.debug.LogManager.createLogger_(a)};goog.debug.LogManager.createFunctionForCatchErrors=function(a){return function(b){(a||goog.debug.LogManager.getRoot()).severe("Error: "+b.message+" ("+b.fileName+" @ Line: "+b.line+")")}};
goog.debug.LogManager.createLogger_=function(a){var b=new goog.debug.Logger(a);if(goog.debug.Logger.ENABLE_HIERARCHY){var c=a.lastIndexOf("."),d=a.substr(0,c);c=a.substr(c+1);d=goog.debug.LogManager.getLogger(d);d.addChild_(c,b);b.setParent_(d)}return goog.debug.LogManager.loggers_[a]=b};goog.log={};goog.log.ENABLED=goog.debug.LOGGING_ENABLED;goog.log.ROOT_LOGGER_NAME=goog.debug.Logger.ROOT_LOGGER_NAME;goog.log.Logger=goog.debug.Logger;goog.log.Level=goog.debug.Logger.Level;goog.log.LogRecord=goog.debug.LogRecord;goog.log.getLogger=function(a,b){return goog.log.ENABLED?(a=goog.debug.LogManager.getLogger(a),b&&a&&a.setLevel(b),a):null};goog.log.addHandler=function(a,b){goog.log.ENABLED&&a&&a.addHandler(b)};
goog.log.removeHandler=function(a,b){return goog.log.ENABLED&&a?a.removeHandler(b):!1};goog.log.log=function(a,b,c,d){goog.log.ENABLED&&a&&a.log(b,c,d)};goog.log.error=function(a,b,c){goog.log.ENABLED&&a&&a.severe(b,c)};goog.log.warning=function(a,b,c){goog.log.ENABLED&&a&&a.warning(b,c)};goog.log.info=function(a,b,c){goog.log.ENABLED&&a&&a.info(b,c)};goog.log.fine=function(a,b,c){goog.log.ENABLED&&a&&a.fine(b,c)};goog.string.StringBuffer=function(a,b){null!=a&&this.append.apply(this,arguments)};goog.string.StringBuffer.prototype.buffer_="";goog.string.StringBuffer.prototype.set=function(a){this.buffer_=""+a};goog.string.StringBuffer.prototype.append=function(a,b,c){this.buffer_+=String(a);if(null!=b)for(var d=1;d<arguments.length;d++)this.buffer_+=arguments[d];return this};goog.string.StringBuffer.prototype.clear=function(){this.buffer_=""};goog.string.StringBuffer.prototype.getLength=function(){return this.buffer_.length};
goog.string.StringBuffer.prototype.toString=function(){return this.buffer_};goog.ui.tree={};goog.ui.tree.BaseNode=function(a,b,c){goog.ui.Component.call(this,c);this.config_=b||goog.ui.tree.BaseNode.defaultConfig;this.html_=goog.html.SafeHtml.htmlEscapePreservingNewlines(a);this.expanded_=this.selected_=!1;this.toolTip_=null;this.afterLabelHtml_=goog.html.SafeHtml.EMPTY;this.isUserCollapsible_=!0;this.depth_=-1};goog.inherits(goog.ui.tree.BaseNode,goog.ui.Component);
goog.ui.tree.BaseNode.EventType={BEFORE_EXPAND:"beforeexpand",EXPAND:"expand",BEFORE_COLLAPSE:"beforecollapse",COLLAPSE:"collapse"};goog.ui.tree.BaseNode.allNodes={};goog.ui.tree.BaseNode.prototype.disposeInternal=function(){goog.ui.tree.BaseNode.superClass_.disposeInternal.call(this);this.tree&&(this.tree.removeNode(this),this.tree=null);this.setElementInternal(null)};
goog.ui.tree.BaseNode.prototype.initAccessibility=function(){var a=this.getElement();if(a){var b=this.getLabelElement();b&&!b.id&&(b.id=this.getId()+".label");goog.a11y.aria.setRole(a,"treeitem");goog.a11y.aria.setState(a,"selected",!1);goog.a11y.aria.setState(a,"level",this.getDepth());b&&goog.a11y.aria.setState(a,"labelledby",b.id);(b=this.getIconElement())&&goog.a11y.aria.setRole(b,"presentation");(b=this.getExpandIconElement())&&goog.a11y.aria.setRole(b,"presentation");if(b=this.getChildrenElement())if(goog.a11y.aria.setRole(b,
"group"),b.hasChildNodes())for(goog.a11y.aria.setState(a,goog.a11y.aria.State.EXPANDED,!1),a=this.getChildCount(),b=1;b<=a;b++){var c=this.getChildAt(b-1).getElement();goog.asserts.assert(c,"The child element cannot be null");goog.a11y.aria.setState(c,"setsize",a);goog.a11y.aria.setState(c,"posinset",b)}}};goog.ui.tree.BaseNode.prototype.createDom=function(){var a=this.getDomHelper().safeHtmlToNode(this.toSafeHtml());this.setElementInternal(a)};
goog.ui.tree.BaseNode.prototype.enterDocument=function(){goog.ui.tree.BaseNode.superClass_.enterDocument.call(this);goog.ui.tree.BaseNode.allNodes[this.getId()]=this;this.initAccessibility()};goog.ui.tree.BaseNode.prototype.exitDocument=function(){goog.ui.tree.BaseNode.superClass_.exitDocument.call(this);delete goog.ui.tree.BaseNode.allNodes[this.getId()]};
goog.ui.tree.BaseNode.prototype.addChildAt=function(a,b,c){goog.asserts.assert(!a.getParent());goog.asserts.assertInstanceof(a,goog.ui.tree.BaseNode);c=this.getChildAt(b-1);var d=this.getChildAt(b);goog.ui.tree.BaseNode.superClass_.addChildAt.call(this,a,b);a.previousSibling_=c;a.nextSibling_=d;c?c.nextSibling_=a:this.firstChild_=a;d?d.previousSibling_=a:this.lastChild_=a;(b=this.getTree())&&a.setTreeInternal(b);a.setDepth_(this.getDepth()+1);if(b=this.getElement())if(this.updateExpandIcon(),goog.a11y.aria.setState(b,
goog.a11y.aria.State.EXPANDED,this.getExpanded()),this.getExpanded()){b=this.getChildrenElement();a.getElement()||a.createDom();var e=a.getElement(),f=d&&d.getElement();b.insertBefore(e,f);this.isInDocument()&&a.enterDocument();d||(c?c.updateExpandIcon():(goog.style.setElementShown(b,!0),this.setExpanded(this.getExpanded())))}};
goog.ui.tree.BaseNode.prototype.add=function(a,b){goog.asserts.assert(!b||b.getParent()==this,"Can only add nodes before siblings");a.getParent()&&a.getParent().removeChild(a);this.addChildAt(a,b?this.indexOfChild(b):this.getChildCount());return a};
goog.ui.tree.BaseNode.prototype.removeChild=function(a,b){var c=this.getTree();b=c?c.getSelectedItem():null;if(b==a||a.contains(b))c.hasFocus()?(this.select(),goog.Timer.callOnce(this.onTimeoutSelect_,10,this)):this.select();goog.ui.tree.BaseNode.superClass_.removeChild.call(this,a);this.lastChild_==a&&(this.lastChild_=a.previousSibling_);this.firstChild_==a&&(this.firstChild_=a.nextSibling_);a.previousSibling_&&(a.previousSibling_.nextSibling_=a.nextSibling_);a.nextSibling_&&(a.nextSibling_.previousSibling_=
a.previousSibling_);b=a.isLastSibling();a.tree=null;a.depth_=-1;if(c&&(c.removeNode(a),this.isInDocument())){c=this.getChildrenElement();if(a.isInDocument()){var d=a.getElement();c.removeChild(d);a.exitDocument()}b&&(b=this.getLastChild())&&b.updateExpandIcon();this.hasChildren()||(c.style.display="none",this.updateExpandIcon(),this.updateIcon_(),(b=this.getElement())&&goog.a11y.aria.removeState(b,goog.a11y.aria.State.EXPANDED))}return a};goog.ui.tree.BaseNode.prototype.remove=goog.ui.tree.BaseNode.prototype.removeChild;
goog.ui.tree.BaseNode.prototype.onTimeoutSelect_=function(){this.select()};goog.ui.tree.BaseNode.prototype.getTree=goog.abstractMethod;goog.ui.tree.BaseNode.prototype.getDepth=function(){var a=this.depth_;0>a&&(a=this.computeDepth_(),this.setDepth_(a));return a};goog.ui.tree.BaseNode.prototype.computeDepth_=function(){var a=this.getParent();return a?a.getDepth()+1:0};
goog.ui.tree.BaseNode.prototype.setDepth_=function(a){if(a!=this.depth_){this.depth_=a;var b=this.getRowElement();if(b){var c=this.getPixelIndent_()+"px";this.isRightToLeft()?b.style.paddingRight=c:b.style.paddingLeft=c}this.forEachChild(function(b){b.setDepth_(a+1)})}};goog.ui.tree.BaseNode.prototype.contains=function(a){for(;a;){if(a==this)return!0;a=a.getParent()}return!1};goog.ui.tree.BaseNode.EMPTY_CHILDREN_=[];
goog.ui.tree.BaseNode.prototype.getChildren=function(){var a=[];this.forEachChild(function(b){a.push(b)});return a};goog.ui.tree.BaseNode.prototype.getFirstChild=function(){return this.getChildAt(0)};goog.ui.tree.BaseNode.prototype.getLastChild=function(){return this.getChildAt(this.getChildCount()-1)};goog.ui.tree.BaseNode.prototype.getPreviousSibling=function(){return this.previousSibling_};goog.ui.tree.BaseNode.prototype.getNextSibling=function(){return this.nextSibling_};
goog.ui.tree.BaseNode.prototype.isLastSibling=function(){return!this.nextSibling_};goog.ui.tree.BaseNode.prototype.isSelected=function(){return this.selected_};goog.ui.tree.BaseNode.prototype.select=function(){var a=this.getTree();a&&a.setSelectedItem(this)};goog.ui.tree.BaseNode.prototype.deselect=goog.nullFunction;
goog.ui.tree.BaseNode.prototype.setSelectedInternal=function(a){if(this.selected_!=a){this.selected_=a;this.updateRow();var b=this.getElement();b&&(goog.a11y.aria.setState(b,"selected",a),a&&(a=this.getTree().getElement(),goog.asserts.assert(a,"The DOM element for the tree cannot be null"),goog.a11y.aria.setState(a,"activedescendant",this.getId())))}};goog.ui.tree.BaseNode.prototype.getExpanded=function(){return this.expanded_};
goog.ui.tree.BaseNode.prototype.setExpandedInternal=function(a){this.expanded_=a};
goog.ui.tree.BaseNode.prototype.setExpanded=function(a){var b=a!=this.expanded_;if(!b||this.dispatchEvent(a?goog.ui.tree.BaseNode.EventType.BEFORE_EXPAND:goog.ui.tree.BaseNode.EventType.BEFORE_COLLAPSE)){this.expanded_=a;var c=this.getTree();var d=this.getElement();if(this.hasChildren()){if(!a&&c&&this.contains(c.getSelectedItem())&&this.select(),d){if(c=this.getChildrenElement())if(goog.style.setElementShown(c,a),goog.a11y.aria.setState(d,goog.a11y.aria.State.EXPANDED,a),a&&this.isInDocument()&&
!c.hasChildNodes()){var e=[];this.forEachChild(function(a){e.push(a.toSafeHtml())});goog.dom.safe.setInnerHtml(c,goog.html.SafeHtml.concat(e));this.forEachChild(function(a){a.enterDocument()})}this.updateExpandIcon()}}else(c=this.getChildrenElement())&&goog.style.setElementShown(c,!1);d&&this.updateIcon_();b&&this.dispatchEvent(a?goog.ui.tree.BaseNode.EventType.EXPAND:goog.ui.tree.BaseNode.EventType.COLLAPSE)}};goog.ui.tree.BaseNode.prototype.toggle=function(){this.setExpanded(!this.getExpanded())};
goog.ui.tree.BaseNode.prototype.expand=function(){this.setExpanded(!0)};goog.ui.tree.BaseNode.prototype.collapse=function(){this.setExpanded(!1)};goog.ui.tree.BaseNode.prototype.collapseChildren=function(){this.forEachChild(function(a){a.collapseAll()})};goog.ui.tree.BaseNode.prototype.collapseAll=function(){this.collapseChildren();this.collapse()};goog.ui.tree.BaseNode.prototype.expandChildren=function(){this.forEachChild(function(a){a.expandAll()})};
goog.ui.tree.BaseNode.prototype.expandAll=function(){this.expandChildren();this.expand()};goog.ui.tree.BaseNode.prototype.reveal=function(){var a=this.getParent();a&&(a.setExpanded(!0),a.reveal())};goog.ui.tree.BaseNode.prototype.setIsUserCollapsible=function(a){(this.isUserCollapsible_=a)||this.expand();this.getElement()&&this.updateExpandIcon()};goog.ui.tree.BaseNode.prototype.isUserCollapsible=function(){return this.isUserCollapsible_};
goog.ui.tree.BaseNode.prototype.toSafeHtml=function(){var a=this.getTree(),b=!a.getShowLines()||a==this.getParent()&&!a.getShowRootLines()?this.config_.cssChildrenNoLines:this.config_.cssChildren;a=this.getExpanded()&&this.hasChildren();b={"class":b,style:this.getLineStyle()};var c=[];a&&this.forEachChild(function(a){c.push(a.toSafeHtml())});a=goog.html.SafeHtml.create("div",b,c);return goog.html.SafeHtml.create("div",{"class":this.config_.cssItem,id:this.getId()},[this.getRowSafeHtml(),a])};
goog.ui.tree.BaseNode.prototype.getPixelIndent_=function(){return Math.max(0,(this.getDepth()-1)*this.config_.indentWidth)};goog.ui.tree.BaseNode.prototype.getRowSafeHtml=function(){var a={};a["padding-"+(this.isRightToLeft()?"right":"left")]=this.getPixelIndent_()+"px";a={"class":this.getRowClassName(),style:a};var b=[this.getExpandIconSafeHtml(),this.getIconSafeHtml(),this.getLabelSafeHtml()];return goog.html.SafeHtml.create("div",a,b)};
goog.ui.tree.BaseNode.prototype.getRowClassName=function(){var a=this.isSelected()?" "+this.config_.cssSelectedRow:"";return this.config_.cssTreeRow+a};goog.ui.tree.BaseNode.prototype.getLabelSafeHtml=function(){var a=goog.html.SafeHtml.create("span",{"class":this.config_.cssItemLabel,title:this.getToolTip()||null},this.getSafeHtml());return goog.html.SafeHtml.concat(a,goog.html.SafeHtml.create("span",{},this.getAfterLabelSafeHtml()))};goog.ui.tree.BaseNode.prototype.getAfterLabelHtml=function(){return goog.html.SafeHtml.unwrap(this.getAfterLabelSafeHtml())};
goog.ui.tree.BaseNode.prototype.getAfterLabelSafeHtml=function(){return this.afterLabelHtml_};goog.ui.tree.BaseNode.prototype.setAfterLabelSafeHtml=function(a){this.afterLabelHtml_=a;var b=this.getAfterLabelElement();b&&goog.dom.safe.setInnerHtml(b,a)};goog.ui.tree.BaseNode.prototype.getIconSafeHtml=function(){return goog.html.SafeHtml.create("span",{style:{display:"inline-block"},"class":this.getCalculatedIconClass()})};goog.ui.tree.BaseNode.prototype.getCalculatedIconClass=goog.abstractMethod;
goog.ui.tree.BaseNode.prototype.getExpandIconSafeHtml=function(){return goog.html.SafeHtml.create("span",{type:"expand",style:{display:"inline-block"},"class":this.getExpandIconClass()})};
goog.ui.tree.BaseNode.prototype.getExpandIconClass=function(){var a=this.getTree(),b=!a.getShowLines()||a==this.getParent()&&!a.getShowRootLines(),c=this.config_,d=new goog.string.StringBuffer;d.append(c.cssTreeIcon," ",c.cssExpandTreeIcon," ");if(this.hasChildren()){var e=0;a.getShowExpandIcons()&&this.isUserCollapsible_&&(e=this.getExpanded()?2:1);b||(e=this.isLastSibling()?e+4:e+8);switch(e){case 1:d.append(c.cssExpandTreeIconPlus);break;case 2:d.append(c.cssExpandTreeIconMinus);break;case 4:d.append(c.cssExpandTreeIconL);
break;case 5:d.append(c.cssExpandTreeIconLPlus);break;case 6:d.append(c.cssExpandTreeIconLMinus);break;case 8:d.append(c.cssExpandTreeIconT);break;case 9:d.append(c.cssExpandTreeIconTPlus);break;case 10:d.append(c.cssExpandTreeIconTMinus);break;default:d.append(c.cssExpandTreeIconBlank)}}else b?d.append(c.cssExpandTreeIconBlank):this.isLastSibling()?d.append(c.cssExpandTreeIconL):d.append(c.cssExpandTreeIconT);return d.toString()};
goog.ui.tree.BaseNode.prototype.getLineStyle=function(){var a=this.getExpanded()&&this.hasChildren();return goog.html.SafeStyle.create({"background-position":this.getBackgroundPosition(),display:a?null:"none"})};goog.ui.tree.BaseNode.prototype.getBackgroundPosition=function(){return(this.isLastSibling()?"-100":(this.getDepth()-1)*this.config_.indentWidth)+"px 0"};
goog.ui.tree.BaseNode.prototype.getElement=function(){var a=goog.ui.tree.BaseNode.superClass_.getElement.call(this);a||(a=this.getDomHelper().getElement(this.getId()),this.setElementInternal(a));return a};goog.ui.tree.BaseNode.prototype.getRowElement=function(){var a=this.getElement();return a?a.firstChild:null};goog.ui.tree.BaseNode.prototype.getExpandIconElement=function(){var a=this.getRowElement();return a?a.firstChild:null};
goog.ui.tree.BaseNode.prototype.getIconElement=function(){var a=this.getRowElement();return a?a.childNodes[1]:null};goog.ui.tree.BaseNode.prototype.getLabelElement=function(){var a=this.getRowElement();return a&&a.lastChild?a.lastChild.previousSibling:null};goog.ui.tree.BaseNode.prototype.getAfterLabelElement=function(){var a=this.getRowElement();return a?a.lastChild:null};goog.ui.tree.BaseNode.prototype.getChildrenElement=function(){var a=this.getElement();return a?a.lastChild:null};
goog.ui.tree.BaseNode.prototype.setIconClass=function(a){this.iconClass_=a;this.isInDocument()&&this.updateIcon_()};goog.ui.tree.BaseNode.prototype.getIconClass=function(){return this.iconClass_};goog.ui.tree.BaseNode.prototype.setExpandedIconClass=function(a){this.expandedIconClass_=a;this.isInDocument()&&this.updateIcon_()};goog.ui.tree.BaseNode.prototype.getExpandedIconClass=function(){return this.expandedIconClass_};goog.ui.tree.BaseNode.prototype.setText=function(a){this.setSafeHtml(goog.html.SafeHtml.htmlEscape(a))};
goog.ui.tree.BaseNode.prototype.getText=function(){return goog.string.unescapeEntities(goog.html.SafeHtml.unwrap(this.html_))};goog.ui.tree.BaseNode.prototype.setSafeHtml=function(a){this.html_=a;var b=this.getLabelElement();b&&goog.dom.safe.setInnerHtml(b,a);(a=this.getTree())&&a.setNode(this)};goog.ui.tree.BaseNode.prototype.getHtml=function(){return goog.html.SafeHtml.unwrap(this.getSafeHtml())};goog.ui.tree.BaseNode.prototype.getSafeHtml=function(){return this.html_};
goog.ui.tree.BaseNode.prototype.setToolTip=function(a){this.toolTip_=a;var b=this.getLabelElement();b&&(b.title=a)};goog.ui.tree.BaseNode.prototype.getToolTip=function(){return this.toolTip_};goog.ui.tree.BaseNode.prototype.updateRow=function(){var a=this.getRowElement();a&&(a.className=this.getRowClassName())};
goog.ui.tree.BaseNode.prototype.updateExpandIcon=function(){var a=this.getExpandIconElement();a&&(a.className=this.getExpandIconClass());if(a=this.getChildrenElement())a.style.backgroundPosition=this.getBackgroundPosition()};goog.ui.tree.BaseNode.prototype.updateIcon_=function(){this.getIconElement().className=this.getCalculatedIconClass()};
goog.ui.tree.BaseNode.prototype.onMouseDown=function(a){"expand"==a.target.getAttribute("type")&&this.hasChildren()?this.isUserCollapsible_&&this.toggle():(this.select(),this.updateRow())};goog.ui.tree.BaseNode.prototype.onClick_=goog.events.Event.preventDefault;goog.ui.tree.BaseNode.prototype.onDoubleClick_=function(a){"expand"==a.target.getAttribute("type")&&this.hasChildren()||this.isUserCollapsible_&&this.toggle()};
goog.ui.tree.BaseNode.prototype.onKeyDown=function(a){var b=!0;switch(a.keyCode){case goog.events.KeyCodes.RIGHT:if(a.altKey)break;this.hasChildren()&&(this.getExpanded()?this.getFirstChild().select():this.setExpanded(!0));break;case goog.events.KeyCodes.LEFT:if(a.altKey)break;if(this.hasChildren()&&this.getExpanded()&&this.isUserCollapsible_)this.setExpanded(!1);else{var c=this.getParent(),d=this.getTree();c&&(d.getShowRootNode()||c!=d)&&c.select()}break;case goog.events.KeyCodes.DOWN:(c=this.getNextShownNode())&&
c.select();break;case goog.events.KeyCodes.UP:(c=this.getPreviousShownNode())&&c.select();break;default:b=!1}b&&(a.preventDefault(),(d=this.getTree())&&d.clearTypeAhead());return b};goog.ui.tree.BaseNode.prototype.getLastShownDescendant=function(){return this.getExpanded()&&this.hasChildren()?this.getLastChild().getLastShownDescendant():this};
goog.ui.tree.BaseNode.prototype.getNextShownNode=function(){if(this.hasChildren()&&this.getExpanded())return this.getFirstChild();for(var a=this,b;a!=this.getTree();){b=a.getNextSibling();if(null!=b)return b;a=a.getParent()}return null};goog.ui.tree.BaseNode.prototype.getPreviousShownNode=function(){var a=this.getPreviousSibling();if(null!=a)return a.getLastShownDescendant();a=this.getParent();var b=this.getTree();return!b.getShowRootNode()&&a==b||this==b?null:a};
goog.ui.tree.BaseNode.prototype.getClientData=goog.ui.tree.BaseNode.prototype.getModel;goog.ui.tree.BaseNode.prototype.setClientData=goog.ui.tree.BaseNode.prototype.setModel;goog.ui.tree.BaseNode.prototype.getConfig=function(){return this.config_};goog.ui.tree.BaseNode.prototype.setTreeInternal=function(a){this.tree!=a&&(this.tree=a,a.setNode(this),this.forEachChild(function(b){b.setTreeInternal(a)}))};
goog.ui.tree.BaseNode.defaultConfig={indentWidth:19,cssRoot:"goog-tree-root goog-tree-item",cssHideRoot:"goog-tree-hide-root",cssItem:"goog-tree-item",cssChildren:"goog-tree-children",cssChildrenNoLines:"goog-tree-children-nolines",cssTreeRow:"goog-tree-row",cssItemLabel:"goog-tree-item-label",cssTreeIcon:"goog-tree-icon",cssExpandTreeIcon:"goog-tree-expand-icon",cssExpandTreeIconPlus:"goog-tree-expand-icon-plus",cssExpandTreeIconMinus:"goog-tree-expand-icon-minus",cssExpandTreeIconTPlus:"goog-tree-expand-icon-tplus",
cssExpandTreeIconTMinus:"goog-tree-expand-icon-tminus",cssExpandTreeIconLPlus:"goog-tree-expand-icon-lplus",cssExpandTreeIconLMinus:"goog-tree-expand-icon-lminus",cssExpandTreeIconT:"goog-tree-expand-icon-t",cssExpandTreeIconL:"goog-tree-expand-icon-l",cssExpandTreeIconBlank:"goog-tree-expand-icon-blank",cssExpandedFolderIcon:"goog-tree-expanded-folder-icon",cssCollapsedFolderIcon:"goog-tree-collapsed-folder-icon",cssFileIcon:"goog-tree-file-icon",cssExpandedRootIcon:"goog-tree-expanded-folder-icon",
cssCollapsedRootIcon:"goog-tree-collapsed-folder-icon",cssSelectedRow:"selected"};goog.ui.tree.TreeNode=function(a,b,c){goog.ui.tree.BaseNode.call(this,a,b,c)};goog.inherits(goog.ui.tree.TreeNode,goog.ui.tree.BaseNode);goog.ui.tree.TreeNode.prototype.getTree=function(){if(this.tree)return this.tree;var a=this.getParent();return a&&(a=a.getTree())?(this.setTreeInternal(a),a):null};
goog.ui.tree.TreeNode.prototype.getCalculatedIconClass=function(){var a=this.getExpanded(),b=this.getExpandedIconClass();if(a&&b)return b;b=this.getIconClass();if(!a&&b)return b;b=this.getConfig();if(this.hasChildren()){if(a&&b.cssExpandedFolderIcon)return b.cssTreeIcon+" "+b.cssExpandedFolderIcon;if(!a&&b.cssCollapsedFolderIcon)return b.cssTreeIcon+" "+b.cssCollapsedFolderIcon}else if(b.cssFileIcon)return b.cssTreeIcon+" "+b.cssFileIcon;return""};goog.structs={};goog.structs.getCount=function(a){return a.getCount&&"function"==typeof a.getCount?a.getCount():goog.isArrayLike(a)||goog.isString(a)?a.length:goog.object.getCount(a)};goog.structs.getValues=function(a){if(a.getValues&&"function"==typeof a.getValues)return a.getValues();if(goog.isString(a))return a.split("");if(goog.isArrayLike(a)){for(var b=[],c=a.length,d=0;d<c;d++)b.push(a[d]);return b}return goog.object.getValues(a)};
goog.structs.getKeys=function(a){if(a.getKeys&&"function"==typeof a.getKeys)return a.getKeys();if(!a.getValues||"function"!=typeof a.getValues){if(goog.isArrayLike(a)||goog.isString(a)){var b=[];a=a.length;for(var c=0;c<a;c++)b.push(c);return b}return goog.object.getKeys(a)}};
goog.structs.contains=function(a,b){return a.contains&&"function"==typeof a.contains?a.contains(b):a.containsValue&&"function"==typeof a.containsValue?a.containsValue(b):goog.isArrayLike(a)||goog.isString(a)?goog.array.contains(a,b):goog.object.containsValue(a,b)};goog.structs.isEmpty=function(a){return a.isEmpty&&"function"==typeof a.isEmpty?a.isEmpty():goog.isArrayLike(a)||goog.isString(a)?goog.array.isEmpty(a):goog.object.isEmpty(a)};
goog.structs.clear=function(a){a.clear&&"function"==typeof a.clear?a.clear():goog.isArrayLike(a)?goog.array.clear(a):goog.object.clear(a)};goog.structs.forEach=function(a,b,c){if(a.forEach&&"function"==typeof a.forEach)a.forEach(b,c);else if(goog.isArrayLike(a)||goog.isString(a))goog.array.forEach(a,b,c);else for(var d=goog.structs.getKeys(a),e=goog.structs.getValues(a),f=e.length,g=0;g<f;g++)b.call(c,e[g],d&&d[g],a)};
goog.structs.filter=function(a,b,c){if("function"==typeof a.filter)return a.filter(b,c);if(goog.isArrayLike(a)||goog.isString(a))return goog.array.filter(a,b,c);var d=goog.structs.getKeys(a),e=goog.structs.getValues(a),f=e.length;if(d){var g={};for(var h=0;h<f;h++)b.call(c,e[h],d[h],a)&&(g[d[h]]=e[h])}else for(g=[],h=0;h<f;h++)b.call(c,e[h],void 0,a)&&g.push(e[h]);return g};
goog.structs.map=function(a,b,c){if("function"==typeof a.map)return a.map(b,c);if(goog.isArrayLike(a)||goog.isString(a))return goog.array.map(a,b,c);var d=goog.structs.getKeys(a),e=goog.structs.getValues(a),f=e.length;if(d){var g={};for(var h=0;h<f;h++)g[d[h]]=b.call(c,e[h],d[h],a)}else for(g=[],h=0;h<f;h++)g[h]=b.call(c,e[h],void 0,a);return g};
goog.structs.some=function(a,b,c){if("function"==typeof a.some)return a.some(b,c);if(goog.isArrayLike(a)||goog.isString(a))return goog.array.some(a,b,c);for(var d=goog.structs.getKeys(a),e=goog.structs.getValues(a),f=e.length,g=0;g<f;g++)if(b.call(c,e[g],d&&d[g],a))return!0;return!1};
goog.structs.every=function(a,b,c){if("function"==typeof a.every)return a.every(b,c);if(goog.isArrayLike(a)||goog.isString(a))return goog.array.every(a,b,c);for(var d=goog.structs.getKeys(a),e=goog.structs.getValues(a),f=e.length,g=0;g<f;g++)if(!b.call(c,e[g],d&&d[g],a))return!1;return!0};goog.structs.Trie=function(a){this.value_=void 0;this.childNodes_={};a&&this.setAll(a)};goog.structs.Trie.prototype.set=function(a,b){this.setOrAdd_(a,b,!1)};goog.structs.Trie.prototype.add=function(a,b){this.setOrAdd_(a,b,!0)};
goog.structs.Trie.prototype.setOrAdd_=function(a,b,c){for(var d=this,e=0;e<a.length;e++){var f=a.charAt(e);d.childNodes_[f]||(d.childNodes_[f]=new goog.structs.Trie);d=d.childNodes_[f]}if(c&&void 0!==d.value_)throw Error('The collection already contains the key "'+a+'"');d.value_=b};goog.structs.Trie.prototype.setAll=function(a){var b=goog.structs.getKeys(a);a=goog.structs.getValues(a);for(var c=0;c<b.length;c++)this.set(b[c],a[c])};
goog.structs.Trie.prototype.getChildNode_=function(a){for(var b=this,c=0;c<a.length;c++){var d=a.charAt(c);b=b.childNodes_[d];if(!b)return}return b};goog.structs.Trie.prototype.get=function(a){return(a=this.getChildNode_(a))?a.value_:void 0};goog.structs.Trie.prototype.getKeyAndPrefixes=function(a,b){var c=this,d={};b=b||0;void 0!==c.value_&&(d[b]=c.value_);for(;b<a.length;b++){var e=a.charAt(b);if(!(e in c.childNodes_))break;c=c.childNodes_[e];void 0!==c.value_&&(d[b]=c.value_)}return d};
goog.structs.Trie.prototype.getValues=function(){var a=[];this.getValuesInternal_(a);return a};goog.structs.Trie.prototype.getValuesInternal_=function(a){void 0!==this.value_&&a.push(this.value_);for(var b in this.childNodes_)this.childNodes_[b].getValuesInternal_(a)};goog.structs.Trie.prototype.getKeys=function(a){var b=[];if(a){for(var c=this,d=0;d<a.length;d++){var e=a.charAt(d);if(!c.childNodes_[e])return[];c=c.childNodes_[e]}c.getKeysInternal_(a,b)}else this.getKeysInternal_("",b);return b};
goog.structs.Trie.prototype.getKeysInternal_=function(a,b){void 0!==this.value_&&b.push(a);for(var c in this.childNodes_)this.childNodes_[c].getKeysInternal_(a+c,b)};goog.structs.Trie.prototype.containsKey=function(a){return void 0!==this.get(a)};goog.structs.Trie.prototype.containsPrefix=function(a){return 0==a.length?!this.isEmpty():!!this.getChildNode_(a)};
goog.structs.Trie.prototype.containsValue=function(a){if(this.value_===a)return!0;for(var b in this.childNodes_)if(this.childNodes_[b].containsValue(a))return!0;return!1};goog.structs.Trie.prototype.clear=function(){this.childNodes_={};this.value_=void 0};
goog.structs.Trie.prototype.remove=function(a){for(var b=this,c=[],d=0;d<a.length;d++){var e=a.charAt(d);if(!b.childNodes_[e])throw Error('The collection does not have the key "'+a+'"');c.push([b,e]);b=b.childNodes_[e]}a=b.value_;for(delete b.value_;0<c.length;)if(e=c.pop(),b=e[0],e=e[1],b.childNodes_[e].isEmpty())delete b.childNodes_[e];else break;return a};goog.structs.Trie.prototype.clone=function(){return new goog.structs.Trie(this)};goog.structs.Trie.prototype.getCount=function(){return goog.structs.getCount(this.getValues())};
goog.structs.Trie.prototype.isEmpty=function(){return void 0===this.value_&&goog.object.isEmpty(this.childNodes_)};goog.ui.tree.TypeAhead=function(){this.nodeMap_=new goog.structs.Trie;this.buffer_="";this.matchingNodes_=this.matchingLabels_=null;this.matchingNodeIndex_=this.matchingLabelIndex_=0};goog.ui.tree.TypeAhead.Offset={DOWN:1,UP:-1};
goog.ui.tree.TypeAhead.prototype.handleNavigation=function(a){var b=!1;switch(a.keyCode){case goog.events.KeyCodes.DOWN:case goog.events.KeyCodes.UP:a.ctrlKey&&(this.jumpTo_(a.keyCode==goog.events.KeyCodes.DOWN?goog.ui.tree.TypeAhead.Offset.DOWN:goog.ui.tree.TypeAhead.Offset.UP),b=!0);break;case goog.events.KeyCodes.BACKSPACE:a=this.buffer_.length-1;b=!0;0<a?(this.buffer_=this.buffer_.substring(0,a),this.jumpToLabel_(this.buffer_)):0==a?this.buffer_="":b=!1;break;case goog.events.KeyCodes.ESC:this.buffer_=
"",b=!0}return b};goog.ui.tree.TypeAhead.prototype.handleTypeAheadChar=function(a){var b=!1;a.ctrlKey||a.altKey||(a=String.fromCharCode(a.charCode||a.keyCode).toLowerCase(),goog.string.isUnicodeChar(a)&&(" "!=a||this.buffer_)&&(this.buffer_+=a,b=this.jumpToLabel_(this.buffer_)));return b};
goog.ui.tree.TypeAhead.prototype.setNodeInMap=function(a){var b=a.getText();if(b&&!goog.string.isEmptyOrWhitespace(goog.string.makeSafe(b))){b=b.toLowerCase();var c=this.nodeMap_.get(b);c?c.push(a):this.nodeMap_.set(b,[a])}};
goog.ui.tree.TypeAhead.prototype.removeNodeFromMap=function(a){var b=a.getText();if(b&&!goog.string.isEmptyOrWhitespace(goog.string.makeSafe(b))){b=b.toLowerCase();var c=this.nodeMap_.get(b);if(c){for(var d=a.getChildCount(),e=0;e<d;e++)this.removeNodeFromMap(a.getChildAt(e));goog.array.remove(c,a);c.length||this.nodeMap_.remove(b)}}};
goog.ui.tree.TypeAhead.prototype.jumpToLabel_=function(a){var b=!1;(a=this.nodeMap_.getKeys(a))&&a.length&&(this.matchingLabelIndex_=this.matchingNodeIndex_=0,b=this.nodeMap_.get(a[0]),b=this.selectMatchingNode_(b))&&(this.matchingLabels_=a);return b};
goog.ui.tree.TypeAhead.prototype.jumpTo_=function(a){var b=!1,c=this.matchingLabels_;if(c){b=null;var d=!1;if(this.matchingNodes_){var e=this.matchingNodeIndex_+a;0<=e&&e<this.matchingNodes_.length?(this.matchingNodeIndex_=e,b=this.matchingNodes_):d=!0}b||(e=this.matchingLabelIndex_+a,0<=e&&e<c.length&&(this.matchingLabelIndex_=e),c.length>this.matchingLabelIndex_&&(b=this.nodeMap_.get(c[this.matchingLabelIndex_])),b&&b.length&&d&&(this.matchingNodeIndex_=a==goog.ui.tree.TypeAhead.Offset.UP?b.length-
1:0));if(b=this.selectMatchingNode_(b))this.matchingLabels_=c}return b};goog.ui.tree.TypeAhead.prototype.selectMatchingNode_=function(a){if(a){if(this.matchingNodeIndex_<a.length){var b=a[this.matchingNodeIndex_];this.matchingNodes_=a}b&&(b.reveal(),b.select())}return!!b};goog.ui.tree.TypeAhead.prototype.clear=function(){this.buffer_=""};goog.ui.tree.TreeControl=function(a,b,c){goog.ui.tree.BaseNode.call(this,a,b,c);this.setExpandedInternal(!0);this.setSelectedInternal(!0);this.selectedItem_=this;this.typeAhead_=new goog.ui.tree.TypeAhead;this.focusHandler_=this.keyHandler_=null;this.logger_=goog.log.getLogger("this");this.focused_=!1;this.focusedNode_=null;this.showRootLines_=this.showRootNode_=this.showExpandIcons_=this.showLines_=!0;if(goog.userAgent.IE)try{document.execCommand("BackgroundImageCache",!1,!0)}catch(d){goog.log.warning(this.logger_,
"Failed to enable background image cache")}};goog.inherits(goog.ui.tree.TreeControl,goog.ui.tree.BaseNode);goog.ui.tree.TreeControl.prototype.getTree=function(){return this};goog.ui.tree.TreeControl.prototype.getDepth=function(){return 0};goog.ui.tree.TreeControl.prototype.reveal=function(){};goog.ui.tree.TreeControl.prototype.handleFocus_=function(a){this.focused_=!0;goog.dom.classlist.add(goog.asserts.assert(this.getElement()),"focused");this.selectedItem_&&this.selectedItem_.select()};
goog.ui.tree.TreeControl.prototype.handleBlur_=function(a){this.focused_=!1;goog.dom.classlist.remove(goog.asserts.assert(this.getElement()),"focused")};goog.ui.tree.TreeControl.prototype.hasFocus=function(){return this.focused_};goog.ui.tree.TreeControl.prototype.getExpanded=function(){return!this.showRootNode_||goog.ui.tree.TreeControl.superClass_.getExpanded.call(this)};
goog.ui.tree.TreeControl.prototype.setExpanded=function(a){this.showRootNode_?goog.ui.tree.TreeControl.superClass_.setExpanded.call(this,a):this.setExpandedInternal(a)};goog.ui.tree.TreeControl.prototype.getExpandIconSafeHtml=function(){return goog.html.SafeHtml.EMPTY};goog.ui.tree.TreeControl.prototype.getIconElement=function(){var a=this.getRowElement();return a?a.firstChild:null};goog.ui.tree.TreeControl.prototype.getExpandIconElement=function(){return null};
goog.ui.tree.TreeControl.prototype.updateExpandIcon=function(){};goog.ui.tree.TreeControl.prototype.getRowClassName=function(){return goog.ui.tree.TreeControl.superClass_.getRowClassName.call(this)+(this.showRootNode_?"":" "+this.getConfig().cssHideRoot)};
goog.ui.tree.TreeControl.prototype.getCalculatedIconClass=function(){var a=this.getExpanded(),b=this.getExpandedIconClass();if(a&&b)return b;b=this.getIconClass();if(!a&&b)return b;b=this.getConfig();return a&&b.cssExpandedRootIcon?b.cssTreeIcon+" "+b.cssExpandedRootIcon:!a&&b.cssCollapsedRootIcon?b.cssTreeIcon+" "+b.cssCollapsedRootIcon:""};
goog.ui.tree.TreeControl.prototype.setSelectedItem=function(a){if(this.selectedItem_!=a){var b=!1;this.selectedItem_&&(b=this.selectedItem_==this.focusedNode_,this.selectedItem_.setSelectedInternal(!1));if(this.selectedItem_=a)a.setSelectedInternal(!0),b&&a.select();this.dispatchEvent(goog.events.EventType.CHANGE)}};goog.ui.tree.TreeControl.prototype.getSelectedItem=function(){return this.selectedItem_};
goog.ui.tree.TreeControl.prototype.setShowLines=function(a){this.showLines_!=a&&(this.showLines_=a,this.isInDocument()&&this.updateLinesAndExpandIcons_())};goog.ui.tree.TreeControl.prototype.getShowLines=function(){return this.showLines_};
goog.ui.tree.TreeControl.prototype.updateLinesAndExpandIcons_=function(){function a(e){var f=e.getChildrenElement();if(f){var g=!c||b==e.getParent()&&!d?e.getConfig().cssChildrenNoLines:e.getConfig().cssChildren;f.className=g;if(f=e.getExpandIconElement())f.className=e.getExpandIconClass()}e.forEachChild(a)}var b=this,c=b.getShowLines(),d=b.getShowRootLines();a(this)};
goog.ui.tree.TreeControl.prototype.setShowRootLines=function(a){this.showRootLines_!=a&&(this.showRootLines_=a,this.isInDocument()&&this.updateLinesAndExpandIcons_())};goog.ui.tree.TreeControl.prototype.getShowRootLines=function(){return this.showRootLines_};goog.ui.tree.TreeControl.prototype.setShowExpandIcons=function(a){this.showExpandIcons_!=a&&(this.showExpandIcons_=a,this.isInDocument()&&this.updateLinesAndExpandIcons_())};goog.ui.tree.TreeControl.prototype.getShowExpandIcons=function(){return this.showExpandIcons_};
goog.ui.tree.TreeControl.prototype.setShowRootNode=function(a){if(this.showRootNode_!=a){this.showRootNode_=a;if(this.isInDocument()){var b=this.getRowElement();b&&(b.className=this.getRowClassName())}!a&&this.getSelectedItem()==this&&this.getFirstChild()&&this.setSelectedItem(this.getFirstChild())}};goog.ui.tree.TreeControl.prototype.getShowRootNode=function(){return this.showRootNode_};
goog.ui.tree.TreeControl.prototype.initAccessibility=function(){goog.ui.tree.TreeControl.superClass_.initAccessibility.call(this);var a=this.getElement();goog.asserts.assert(a,"The DOM element for the tree cannot be null.");goog.a11y.aria.setRole(a,"tree");goog.a11y.aria.setState(a,"labelledby",this.getLabelElement().id)};
goog.ui.tree.TreeControl.prototype.enterDocument=function(){goog.ui.tree.TreeControl.superClass_.enterDocument.call(this);var a=this.getElement();a.className=this.getConfig().cssRoot;a.setAttribute("hideFocus","true");this.attachEvents_();this.initAccessibility()};goog.ui.tree.TreeControl.prototype.exitDocument=function(){goog.ui.tree.TreeControl.superClass_.exitDocument.call(this);this.detachEvents_()};
goog.ui.tree.TreeControl.prototype.attachEvents_=function(){var a=this.getElement();a.tabIndex=0;var b=this.keyHandler_=new goog.events.KeyHandler(a),c=this.focusHandler_=new goog.events.FocusHandler(a);this.getHandler().listen(c,goog.events.FocusHandler.EventType.FOCUSOUT,this.handleBlur_).listen(c,goog.events.FocusHandler.EventType.FOCUSIN,this.handleFocus_).listen(b,goog.events.KeyHandler.EventType.KEY,this.handleKeyEvent).listen(a,goog.events.EventType.MOUSEDOWN,this.handleMouseEvent_).listen(a,
goog.events.EventType.CLICK,this.handleMouseEvent_).listen(a,goog.events.EventType.DBLCLICK,this.handleMouseEvent_)};goog.ui.tree.TreeControl.prototype.detachEvents_=function(){this.keyHandler_.dispose();this.keyHandler_=null;this.focusHandler_.dispose();this.focusHandler_=null};
goog.ui.tree.TreeControl.prototype.handleMouseEvent_=function(a){goog.log.fine(this.logger_,"Received event "+a.type);var b=this.getNodeFromEvent_(a);if(b)switch(a.type){case goog.events.EventType.MOUSEDOWN:b.onMouseDown(a);break;case goog.events.EventType.CLICK:b.onClick_(a);break;case goog.events.EventType.DBLCLICK:b.onDoubleClick_(a)}};
goog.ui.tree.TreeControl.prototype.handleKeyEvent=function(a){var b;(b=this.typeAhead_.handleNavigation(a)||this.selectedItem_&&this.selectedItem_.onKeyDown(a)||this.typeAhead_.handleTypeAheadChar(a))&&a.preventDefault();return b};goog.ui.tree.TreeControl.prototype.getNodeFromEvent_=function(a){for(var b=a.target;null!=b;){if(a=goog.ui.tree.BaseNode.allNodes[b.id])return a;if(b==this.getElement())break;b=b.parentNode}return null};
goog.ui.tree.TreeControl.prototype.createNode=function(a){return new goog.ui.tree.TreeNode(a||goog.html.SafeHtml.EMPTY,this.getConfig(),this.getDomHelper())};goog.ui.tree.TreeControl.prototype.setNode=function(a){this.typeAhead_.setNodeInMap(a)};goog.ui.tree.TreeControl.prototype.removeNode=function(a){this.typeAhead_.removeNodeFromMap(a)};goog.ui.tree.TreeControl.prototype.clearTypeAhead=function(){this.typeAhead_.clear()};goog.ui.tree.TreeControl.defaultConfig=goog.ui.tree.BaseNode.defaultConfig;var IshorizontalLayout=!1;Blockly.Toolbox=function(a){this.workspace_=a;this.iconic_=!1;this.RTL=a.options.RTL;this.horizontalLayout_=a.options.horizontalLayout;this.toolboxPosition=a.options.toolboxPosition};Blockly.Toolbox.prototype.width=310;Blockly.Toolbox.prototype.height=0;Blockly.Toolbox.prototype.selectedItem_=null;
Blockly.Toolbox.prototype.init=function(){var a=this.workspace_,b=this.workspace_.getParentSvg();this.HtmlDiv=(IshorizontalLayout=a.horizontalLayout)?goog.dom.createDom("DIV","blocklyToolboxDiv4"):goog.dom.createDom("DIV","blocklyToolboxDiv");this.HtmlDiv.setAttribute("dir",a.RTL?"RTL":"LTR");b.parentNode.insertBefore(this.HtmlDiv,b);Blockly.bindEventWithChecks_(this.HtmlDiv,"mousedown",this,function(a){this.workspace_.cancelCurrentGesture();Blockly.utils.isRightButton(a)||a.target==this.HtmlDiv?
Blockly.hideChaff(!1):Blockly.hideChaff(!0);Blockly.Touch.clearTouchIdentifier()},!1,!0);this.createFlyout_();this.categoryMenu_=new Blockly.Toolbox.CategoryMenu(this,this.HtmlDiv);this.populate_(a.options.languageTree);this.position();this.refreshSelection()};Blockly.Toolbox.prototype.dispose=function(){this.flyout_.dispose();this.categoryMenu_.dispose();this.categoryMenu_=null;goog.dom.removeNode(this.HtmlDiv);this.lastCategory_=this.workspace_=null};
Blockly.Toolbox.prototype.createFlyout_=function(){var a=this.workspace_,b={disabledPatternId:a.options.disabledPatternId,parentWorkspace:a,RTL:a.RTL,oneBasedIndex:a.options.oneBasedIndex,horizontalLayout:a.horizontalLayout,toolboxPosition:a.options.toolboxPosition,stackGlowFilterId:a.options.stackGlowFilterId};this.flyout_=a.horizontalLayout?new Blockly.HorizontalFlyout(b):new Blockly.VerticalFlyout(b);this.flyout_.setParentToolbox(this);goog.dom.insertSiblingAfter(this.flyout_.createDom("svg"),
this.workspace_.getParentSvg());this.flyout_.init(a)};Blockly.Toolbox.prototype.populate_=function(a){this.categoryMenu_.populate(a);this.showAll_();this.setSelectedItem(this.categoryMenu_.categories_[0],!1)};
Blockly.Toolbox.prototype.showAll_=function(){for(var a=[],b=0;b<this.categoryMenu_.categories_.length;b++){var c=this.categoryMenu_.categories_[b],d=Blockly.Xml.textToDom('<xml><label text="'+c.name_+'" id="'+c.id_+'" category-label="true" showStatusButton="'+c.showStatusButton_+'" web-class="categoryLabel"></label></xml>');a.push(d.firstChild);a=a.concat(c.getContents())}this.flyout_.show(a)};Blockly.Toolbox.prototype.getWidth=function(){return this.width};Blockly.Toolbox.prototype.getHeight=function(){return 0};
Blockly.Toolbox.prototype.position=function(){var a=this.HtmlDiv;if(a){var b=this.workspace_.getParentSvg();Blockly.svgSize(b);this.horizontalLayout_?(a.style.bottom=this.flyout_.height_+"px",a.style.width="100%"):(this.toolboxPosition==Blockly.TOOLBOX_AT_RIGHT?a.style.right="0":a.style.left="0",a.style.height="100%");this.flyout_.position()}};Blockly.Toolbox.prototype.clearSelection=function(){this.setSelectedItem(null)};
Blockly.Toolbox.prototype.addStyle=function(a){Blockly.utils.addClass(this.HtmlDiv,a)};Blockly.Toolbox.prototype.removeStyle=function(a){Blockly.utils.removeClass(this.HtmlDiv,a)};
Blockly.Toolbox.prototype.getClientRect=function(){if(!this.HtmlDiv)return null;if(!this.flyout_.autoClose)return this.flyout_.getClientRect();var a=this.HtmlDiv.getBoundingClientRect(),b=a.left,c=a.top,d=a.width,e=a.height;return this.toolboxPosition==Blockly.TOOLBOX_AT_LEFT?new goog.math.Rect(-1E7,-1E7,1E7+b+d,2E7):this.toolboxPosition==Blockly.TOOLBOX_AT_RIGHT?new goog.math.Rect(a.right-d,-1E7,1E7+d,2E7):this.toolboxPosition==Blockly.TOOLBOX_AT_TOP?new goog.math.Rect(-1E7,-1E7,2E7,1E7+c+e):new goog.math.Rect(0,
c,2E7,1E7)};Blockly.Toolbox.prototype.refreshSelection=function(){this.showAll_()};Blockly.Toolbox.prototype.getSelectedItem=function(){return this.selectedItem_};Blockly.Toolbox.prototype.getSelectedCategoryName=function(){return this.selectedItem_.name_};Blockly.Toolbox.prototype.getSelectedCategoryId=function(){return this.selectedItem_.id_};
Blockly.Toolbox.prototype.getCategoryScrollOffset=function(){var a=this.getCategoryPositionById(this.getSelectedCategoryId());return this.flyout_.getScrollPos()-a};Blockly.Toolbox.prototype.getCategoryPositionByName=function(a){for(var b=this.flyout_.categoryScrollPositions,c=0;c<b.length;c++)if(a===b[c].categoryName)return b[c].position};Blockly.Toolbox.prototype.getCategoryPositionById=function(a){for(var b=this.flyout_.categoryScrollPositions,c=0;c<b.length;c++)if(a===b[c].categoryId)return b[c].position};
Blockly.Toolbox.prototype.getCategoryLengthByName=function(a){for(var b=this.flyout_.categoryScrollPositions,c=0;c<b.length;c++)if(a===b[c].categoryName)return b[c].length};Blockly.Toolbox.prototype.getCategoryLengthById=function(a){for(var b=this.flyout_.categoryScrollPositions,c=0;c<b.length;c++)if(a===b[c].categoryId)return b[c].length};Blockly.Toolbox.prototype.setFlyoutScrollPos=function(a){this.flyout_.setScrollPos(a)};
Blockly.Toolbox.prototype.setSelectedItem=function(a,b){"undefined"===typeof b&&(b=!0);this.selectedItem_&&this.selectedItem_.setSelected(!1);this.selectedItem_=a;null!=this.selectedItem_&&(this.selectedItem_.setSelected(!0),a=a.id_,b&&this.scrollToCategoryById(a))};Blockly.Toolbox.prototype.setSelectedCategoryByName=function(a){this.selectCategoryByName(a);this.scrollToCategoryByName(a)};Blockly.Toolbox.prototype.setSelectedCategoryById=function(a){this.selectCategoryById(a);this.scrollToCategoryById(a)};
Blockly.Toolbox.prototype.scrollToCategoryByName=function(a){for(var b=this.flyout_.categoryScrollPositions,c=0;c<b.length;c++)if(a===b[c].categoryName){this.flyout_.setVisible(!0);this.flyout_.scrollTo(b[c].position);break}};Blockly.Toolbox.prototype.scrollToCategoryById=function(a){for(var b=this.flyout_.categoryScrollPositions,c=0;c<b.length;c++)if(a===b[c].categoryId){this.flyout_.setVisible(!0);this.flyout_.scrollTo(b[c].position);break}};
Blockly.Toolbox.prototype.getCategoryByIndex=function(a){return this.categoryMenu_.categories_?this.categoryMenu_.categories_[a]:null};Blockly.Toolbox.prototype.selectCategoryByName=function(a){for(var b=0;b<this.categoryMenu_.categories_.length;b++){var c=this.categoryMenu_.categories_[b];a===c.name_&&(this.selectedItem_.setSelected(!1),this.selectedItem_=c,this.selectedItem_.setSelected(!0))}};
Blockly.Toolbox.prototype.selectCategoryById=function(a){for(var b=0;b<this.categoryMenu_.categories_.length;b++){var c=this.categoryMenu_.categories_[b];a===c.id_&&(this.selectedItem_.setSelected(!1),this.selectedItem_=c,this.selectedItem_.setSelected(!0))}};Blockly.Toolbox.prototype.setSelectedItemFactory=function(a){return function(){this.workspace_.isDragging()||(this.setSelectedItem(a),Blockly.Touch.clearTouchIdentifier())}};
Blockly.Toolbox.CategoryMenu=function(a,b){this.parent_=a;this.height_=0;this.parentHtml_=b;this.createDom();this.categories_=[]};Blockly.Toolbox.CategoryMenu.prototype.getHeight=function(){return this.height_};Blockly.Toolbox.CategoryMenu.prototype.createDom=function(){this.table=goog.dom.createDom("div",this.parent_.horizontalLayout_?"scratchCategoryMenuHorizontal":"scratchCategoryMenu");this.parentHtml_.appendChild(this.table)};
Blockly.Toolbox.CategoryMenu.prototype.populate=function(a){if(a){this.dispose();this.createDom();for(var b=[],c=0,d;d=a.childNodes[c];c++)d.tagName&&"CATEGORY"==d.tagName.toUpperCase()&&b.push(d);for(c=0;c<b.length;c++)d=b[c],a=goog.dom.createDom("div","scratchCategoryMenuRow"),this.table.appendChild(a),d&&this.categories_.push(new Blockly.Toolbox.Category(this,a,d));this.height_=this.table.offsetHeight}};
Blockly.Toolbox.CategoryMenu.prototype.dispose=function(){for(var a=0,b;b=this.categories_[a];a++)b.dispose();this.categories_=[];this.table&&(goog.dom.removeNode(this.table),this.table=null)};
Blockly.Toolbox.Category=function(a,b,c){this.parent_=a;this.parentHtml_=b;this.horizontalLayoutCategory=this.parent_.horizontalLayout;this.name_=c.getAttribute("name");this.id_=c.getAttribute("id");this.setColour(c);this.custom_=c.getAttribute("custom");this.iconURI_=c.getAttribute("iconURI");this.showStatusButton_=c.getAttribute("showStatusButton");this.contents_=[];this.custom_||this.parseContents_(c);this.createDom()};
Blockly.Toolbox.Category.prototype.dispose=function(){this.item_&&(goog.dom.removeNode(this.item_),this.item=null);this.contents_=this.parentHtml_=this.parent_=null};Blockly.Toolbox.Category.prototype.getMenuItemClassName_=function(a){var b=[IshorizontalLayout?"scratchCategoryMenuItem":"scratchCategoryMenuItemVertical","scratchCategoryId-"+this.id_];a&&b.push("categorySelected");return b.join(" ")};
Blockly.Toolbox.Category.prototype.createDom=function(){var a=this.parent_.parent_;this.item_=goog.dom.createDom("div",{"class":this.getMenuItemClassName_()});this.label_=goog.dom.createDom("div",{"class":IshorizontalLayout?"scratchCategoryMyLabelHorizontal":"scratchCategoryMyLabel"},Blockly.utils.replaceMessageReferences(this.name_));this.iconURI_?(this.bubble_=goog.dom.createDom("div",{"class":"scratchCategoryItemIcon"}),this.bubble_.style.backgroundImage="url("+this.iconURI_+")"):(this.bubble_=
goog.dom.createDom("div",{"class":IshorizontalLayout?"scratchCategoryItemBubbleHorizontal":"scratchCategoryItemBubble"}),this.bubble_.style.backgroundColor=this.colour_,this.bubble_.style.text="this.colour",this.bubble_.style.backgroundColor=this.colour_,this.bubble_.style.borderColor=this.secondaryColour_);this.item_.appendChild(this.bubble_);this.item_.appendChild(this.label_);this.parentHtml_.appendChild(this.item_);Blockly.bindEvent_(this.item_,"mouseup",a,a.setSelectedItemFactory(this))};
Blockly.Toolbox.Category.prototype.setSelected=function(a){this.item_.className=this.getMenuItemClassName_(a)};Blockly.Toolbox.Category.prototype.parseContents_=function(a){for(var b=0,c;c=a.childNodes[b];b++)if(c.tagName)switch(c.tagName.toUpperCase()){case "BLOCK":case "SHADOW":case "LABEL":case "BUTTON":case "SEP":case "TEXT":this.contents_.push(c)}};Blockly.Toolbox.Category.prototype.getContents=function(){return this.custom_?this.custom_:this.contents_};
Blockly.Toolbox.Category.prototype.setColour=function(a){var b=a.getAttribute("colour");a=a.getAttribute("secondaryColour");goog.isString(b)?(b.match(/^#[0-9a-fA-F]{6}$/)?this.colour_=b:this.colour_=Blockly.hueToRgb(b),a.match(/^#[0-9a-fA-F]{6}$/)?this.secondaryColour_=a:this.secondaryColour_=Blockly.hueToRgb(a),this.hasColours_=!0):this.secondaryColour_=this.colour_="#000000"};Blockly.Css={};Blockly.Css.Cursor={OPEN:"handopen",CLOSED:"handclosed",DELETE:"handdelete"};Blockly.Css.currentCursor_="";Blockly.Css.styleSheet_=null;Blockly.Css.mediaPath_="";
Blockly.Css.inject=function(a,b){if(!Blockly.Css.styleSheet_){var c=".blocklyDraggable {}\n";a&&(c+=Blockly.Css.CONTENT.join("\n"),Blockly.FieldDate&&(c+=Blockly.FieldDate.CSS.join("\n")));Blockly.Css.mediaPath_=b.replace(/[\\\/]$/,"");c=c.replace(/<<<PATH>>>/g,Blockly.Css.mediaPath_);for(var d in Blockly.Colours)Blockly.Colours.hasOwnProperty(d)&&(c=c.replace(new RegExp("\\$colour\\_"+d,"g"),Blockly.Colours[d]));a=document.createElement("style");document.head.insertBefore(a,document.head.firstChild);
c=document.createTextNode(c);a.appendChild(c);Blockly.Css.styleSheet_=a.sheet}};Blockly.Css.setCursor=function(a){console.warn("Deprecated call to Blockly.Css.setCursor.See https://github.com/google/blockly/issues/981 for context")};
Blockly.Css.CONTENT=[".blocklySvg {","background-color: $colour_workspace;","outline: none;","overflow: hidden;","position: absolute;","display: block;","}",".blocklyRelativeWrapper {","position: relative;","width: 100%;","height: 100%;","}",".blocklyWidgetDiv {","display: none;","position: absolute;","z-index: 99999;","}",".injectionDiv {","height: 100%;","position: relative;","overflow: hidden;","touch-action: none","}",".blocklyNonSelectable {","user-select: none;","-moz-user-select: none;","-webkit-user-select: none;",
"-ms-user-select: none;","}",".blocklyWidgetDiv.fieldTextInput {","overflow: hidden;","border: 1px solid;","box-sizing: border-box;","transform-origin: 0 0;","-ms-transform-origin: 0 0;","-moz-transform-origin: 0 0;","-webkit-transform-origin: 0 0;","}",".blocklyWidgetDiv.fieldTextInput.removableTextInput {","overflow: visible;","}",".blocklyTextDropDownArrow {","position: absolute;","}",".blocklyTextRemoveIcon {","position: absolute;","width: 24px;","height: 24px;","top: -40px;","left: 50%;","margin-left: -12px;",
"cursor: pointer;","}",".blocklyNonSelectable {","user-select: none;","-moz-user-select: none;","-webkit-user-select: none;","-ms-user-select: none;","}",".blocklyWsDragSurface {","display: none;","position: absolute;","top: 0;","left: 0;","}",".blocklyWsDragSurface.blocklyOverflowVisible {","overflow: visible;","}",".blocklyBlockDragSurface {","display: none;","position: absolute;","top: 0;","left: 0;","right: 0;","bottom: 0;","overflow: visible !important;","z-index: 50;","}",".blocklyTooltipDiv {",
"background-color: #ffffc7;","border: 1px solid #ddc;","box-shadow: 4px 4px 20px 1px rgba(0,0,0,.15);","color: #000;","display: none;",'font-family: "Helvetica Neue", Helvetica, sans-serif;',"font-size: 9pt;","opacity: 0.9;","padding: 2px;","position: absolute;","z-index: 100000;","}",".blocklyDropDownDiv {","position: fixed;","left: 0;","top: 0;","z-index: 1000;","display: none;","border: 1px solid;","border-radius: 4px;","box-shadow: 0px 0px 8px 1px "+Blockly.Colours.dropDownShadow+";","padding: 4px;",
"-webkit-user-select: none;","min-height: 15px","}",".blocklyDropDownContent {","max-height: 480px;","overflow: auto;","background-color: inherit;","border-color: inherit;","}",".blocklyDropDownContentMatrix {","overflow: auto;","background-color: inherit;","border-color: inherit;","}",".blocklyDropDownArrow {","position: absolute;","left: 0;","top: 0;","width: 16px;","height: 16px;","z-index: -1;","background-color: inherit;","border-color: inherit;","}",".blocklyDropDownButton {","display: inline-block;",
"float: left;","padding: 0;","margin: 4px;","border-radius: 4px;","outline: none;","border: 1px solid;","transition: box-shadow .1s;","cursor: pointer;","}",".blocklyDropDownButtonHover {","box-shadow: 0px 0px 0px 4px "+Blockly.Colours.fieldShadow+";","}",".blocklyDropDownButton:active {","box-shadow: 0px 0px 0px 6px "+Blockly.Colours.fieldShadow+";","}",".blocklyDropDownButton > img {","width: 80%;","height: 80%;","margin-top: 5%","}",".myDownButtonOk {","text-align: center;","float: right;","padding: 0;",
"margin: 4px;","border-radius: 30px;","outline: none;","border: 1px solid;","transition: box-shadow .1s;","cursor: pointer;","align-items: center;","position: absolute;","left: 690px;","top: 410px","}",".myDownButtonOkHover {","box-shadow: 0px 0px 0px 4px "+Blockly.Colours.fieldShadow+";","}",".myDownButtonOk:active {","box-shadow: 0px 0px 0px 6px "+Blockly.Colours.fieldShadow+";","}",".myDownButtonOk > img {","width: 80%;","height: 80%;","margin-top: 5%","}",".myDownButtonFill {","text-align: center;",
"float: right;","padding: 0;","margin: 4px;","border-radius: 30px;","outline: none;","border: 1px solid;","transition: box-shadow .1s;","cursor: pointer;","align-items: center;","position: absolute;","left: 670px;","top: 100px","}",".myDownButtonFillHover {","box-shadow: 0px 0px 0px 4px "+Blockly.Colours.fieldShadow+";","}",".myDownButtonFill:active {","box-shadow: 0px 0px 0px 6px "+Blockly.Colours.fieldShadow+";","}",".myDownButtonFill > img {","width: 80%;","height: 80%;","margin-top: 5%","}",".myDownButtonClean {",
"text-align: center;","float: right;","padding: 0;","margin: 4px;","border-radius: 30px;","outline: none;","border: 1px solid;","transition: box-shadow .1s;","cursor: pointer;","align-items: center;","position: absolute;","left: 670px;","top: 15px","}",".myDownButtonCleanHover {","box-shadow: 0px 0px 0px 4px "+Blockly.Colours.fieldShadow+";","}",".myDownButtonClean:active {","box-shadow: 0px 0px 0px 6px "+Blockly.Colours.fieldShadow+";","}",".myDownButtonClean > img {","width: 80%;","height: 80%;",
"margin-top: 5%","}",".myDownButtonSave {","text-align: center;","float: right;","padding: 0;","margin: 4px;","border-radius: 30px;","outline: none;","border: 1px solid;","transition: box-shadow .1s;","cursor: pointer;","align-items: center;","position: absolute;","left: 670px;","top: 185px","}",".myDownButtonSaveHover {","box-shadow: 0px 0px 0px 4px "+Blockly.Colours.fieldShadow+";","}",".myDownButtonSave:active {","box-shadow: 0px 0px 0px 6px "+Blockly.Colours.fieldShadow+";","}",".myDownButtonSave > img {",
"width: 80%;","height: 80%;","margin-top: 5%","}",".myDownButton4 {","text-align: center;","float: right;","padding: 0;","margin: 4px;","border-radius: 30px;","outline: none;","border: 1px solid;","transition: box-shadow .1s;","cursor: pointer;","align-items: center;","position: absolute;","left: 670px;","top: 230px","}",".myDownButton4Hover {","box-shadow: 0px 0px 0px 4px "+Blockly.Colours.fieldShadow+";","}",".myDownButton4:active {","box-shadow: 0px 0px 0px 6px "+Blockly.Colours.fieldShadow+";",
"}",".myDownButton4 > img {","width: 80%;","height: 80%;","margin-top: 5%","}",".myscrooldiv {","width: 650px;","height: 60px;","overflow-x: auto;","white-space: nowrap;","}",".mycontainer {","margin-left: 30px;","margin-right: 50px;","margin-top: 5px;","margin-bottom: 5px;","width: 590px;","height: 110px;","white-space: nowrap;","display: flex;","flex-wrap: nowrap;","overflow: auto;","flex: 0 0 auto;","scrollbar-width: none;","overflow-x: hidden;","-ms-overflow-style: none;","}",".mycontainerTemplate {",
"margin-left: 30px;","margin-right: 50px;","margin-top: 5px;","margin-bottom: 5px;","width: 590px;","height: 250px;","white-space: nowrap;","display: flex;","flex-wrap: nowrap;","overflow: auto;","flex: 0 0 auto;","scrollbar-width: none;","overflow-x: hidden;","-ms-overflow-style: none;","}",".item {","border: 2px solid #B85B14;","background-color: #FC7307;","width: 120px;","margin: 8px;","}",".flex {","}",".flex-item  {","flex: 0 0 auto;","}",".parent {","width: 650px;","height: 60px;","overflow-x: auto;",
"white-space: nowrap;","}",".child {","width:300px;","border:5px solid red;","overflow-x: hidden; ","float:left;","}","#bottomButonZone {","}",".myButtonRightTemplate {","text-align: center;","float: right;","padding: 0;","margin: 4px;","border-radius: 30px;","outline: none;","border: 1px solid;","transition: box-shadow .1s;","cursor: pointer;","align-items: center;","position: absolute;","left: 630px;","top: 15px","}",".myButtonRightTemplateHover {","box-shadow: 0px 0px 0px 4px "+Blockly.Colours.fieldShadow+
";","}",".myButtonRightTemplate:active {","box-shadow: 0px 0px 0px 6px "+Blockly.Colours.fieldShadow+";","}",".myButtonRightTemplate > img {","width: 80%;","height: 80%;","margin-top: 5%","}",".myButtonLeftTemplate {","text-align: center;","float: left;","padding: 0;","margin: 4px;","border-radius: 30px;","outline: none;","border: 1px solid;","transition: box-shadow .1s;","cursor: pointer;","align-items: center;","position: absolute;","left: 5px;","top: 15px","}",".myButtonLeftTemplateHover {","box-shadow: 0px 0px 0px 4px "+
Blockly.Colours.fieldShadow+";","}",".myButtonLeftTemplate:active {","box-shadow: 0px 0px 0px 6px "+Blockly.Colours.fieldShadow+";","}",".myButtonLeftTemplate > img {","width: 80%;","height: 80%;","margin-top: 5%","}",".myButtonRight {","text-align: center;","float: right;","padding: 0;","margin: 4px;","border-radius: 30px;","outline: none;","border: 1px solid;","transition: box-shadow .1s;","cursor: pointer;","align-items: center;","position: absolute;","left: 630px;","top: 370px","}",".myButtonRightHover {",
"box-shadow: 0px 0px 0px 4px "+Blockly.Colours.fieldShadow+";","}",".myButtonRight:active {","box-shadow: 0px 0px 0px 6px "+Blockly.Colours.fieldShadow+";","}",".myButtonRight > img {","width: 80%;","height: 80%;","margin-top: 5%","}",".myButtonLeft {","text-align: center;","float: left;","padding: 0;","margin: 4px;","border-radius: 30px;","outline: none;","border: 1px solid;","transition: box-shadow .1s;","cursor: pointer;","align-items: center;","position: absolute;","left: 5px;","top: 370px","}",
".myButtonLeftHover {","box-shadow: 0px 0px 0px 4px "+Blockly.Colours.fieldShadow+";","}",".myButtonLeft:active {","box-shadow: 0px 0px 0px 6px "+Blockly.Colours.fieldShadow+";","}",".myButtonLeft > img {","width: 80%;","height: 80%;","margin-top: 5%","}",".blocklyDropDownPlaceholder {","display: inline-block;","float: left;","padding: 0;","margin: 4px;","}",".blocklyNumPadButton {","display: inline-block;","float: left;","padding: 0;","width: 48px;","height: 48px;","margin: 4px;","border-radius: 4px;",
"background: $colour_numPadBackground;","color: $colour_numPadText;","outline: none;","border: 1px solid $colour_numPadBorder;","cursor: pointer;","font-weight: 600;",'font-family: "Helvetica Neue", Helvetica, sans-serif;',"font-size: 30pt;","-webkit-tap-highlight-color: rgba(0,0,0,0);","}",".blocklyNumPadButton > img {","margin-top: 10%;","width: 80%;","height: 80%;","}",".blocklyNumPadButton:active {","background: $colour_numPadActiveBackground;","-webkit-tap-highlight-color: rgba(0,0,0,0);","}",
".arrowTop {","border-top: 1px solid;","border-left: 1px solid;","border-top-left-radius: 4px;","border-color: inherit;","}",".arrowBottom {","border-bottom: 1px solid;","border-right: 1px solid;","border-bottom-right-radius: 4px;","border-color: inherit;","}",".valueReportBox {","min-width: 50px;","max-width: 300px;","max-height: 200px;","overflow: auto;","word-wrap: break-word;","text-align: center;",'font-family: "Helvetica Neue", Helvetica, sans-serif;',"font-size: .8em;","}",".blocklyResizeSE {",
"cursor: se-resize;","fill: #aaa;","}",".blocklyResizeSW {","cursor: sw-resize;","fill: #aaa;","}",".blocklyResizeLine {","stroke: #888;","stroke-width: 1;","}",".blocklyHighlightedConnectionPath {","fill: none;","stroke: #fc3;","stroke-width: 4px;","}",".blocklyPath {","stroke-width: 1px;","}",".blocklySelected>.blocklyPath {","}",".blocklySelected>.blocklyPathLight {","display: none;","}",".blocklyDraggable {",'cursor: url("<<<PATH>>>/handopen.cur"), auto;',"cursor: grab;","cursor: -webkit-grab;",
"cursor: -moz-grab;","}",".blocklyDragging {",'cursor: url("<<<PATH>>>/handclosed.cur"), auto;',"cursor: grabbing;","cursor: -webkit-grabbing;","cursor: -moz-grabbing;","}",".blocklyDraggable:active {",'cursor: url("<<<PATH>>>/handclosed.cur"), auto;',"cursor: grabbing;","cursor: -webkit-grabbing;","cursor: -moz-grabbing;","}",".blocklyBlockDragSurface .blocklyDraggable {",'cursor: url("<<<PATH>>>/handclosed.cur"), auto;',"cursor: grabbing;","cursor: -webkit-grabbing;","cursor: -moz-grabbing;","}",
".blocklyDragging.blocklyDraggingDelete {",'cursor: url("<<<PATH>>>/handdelete.cur"), auto;',"}",".blocklyDragging.blocklyDraggingMouseThrough {","pointer-events: none;","}",".blocklyToolboxDelete {",'cursor: url("<<<PATH>>>/handdelete.cur"), auto;',"}",".blocklyToolboxGrab {",'cursor: url("<<<PATH>>>/handclosed.cur"), auto;',"cursor: grabbing;","cursor: -webkit-grabbing;","}",".blocklyDragging>.blocklyPath,",".blocklyDragging>.blocklyPathLight {","fill-opacity: 1.0;","stroke-opacity: 1.0;","}",".blocklyDragging>.blocklyPath {",
"}",".blocklyDisabled>.blocklyPath {","fill-opacity: .5;","stroke-opacity: .5;","}",".blocklyInsertionMarker>.blocklyPath {","stroke: none;","}",".blocklyText {","fill: #fff;",'font-family: "Helvetica Neue", Helvetica, sans-serif;',"font-size: 14pt;","font-weight: 500;","}",".blocklyText2{","fill: #fff;",'font-family: "Ztech Regular";',"font-size: 60pt;","font-weight: 500;","}",".blocklyTextTruncated {","font-size: 11pt;","}",".blocklyNonEditableText>text {","pointer-events: none;","fill: $fff;",
"}",".blocklyEditableText>text {","fill: $colour_text;","}",".blocklyEditableText>.blocklyEditableLabel {","fill: #fff;","}",".blocklyDropdownText {","fill: #fff !important;","}",".blocklyBubbleText {","fill: $colour_text;","}",".blocklyFlyout {","position: absolute;","z-index: 20;","}",".blocklyFlyoutButton {","fill: none;","pointer-events: all;","}",".blocklyFlyoutButtonBackground {","stroke: #c6c6c6;","}",".blocklyFlyoutButton .blocklyText {","fill: $colour_text;","}",".blocklyFlyoutButtonShadow {",
"fill: transparent;","}",".blocklyFlyoutButton:hover {","fill: white;","cursor: pointer;","}",".blocklyFlyoutLabel {","cursor: default;","}",".blocklyFlyoutLabelBackground {","opacity: 0;","}",".blocklyTouchTargetBackground {","fill: transparent;","cursor: pointer;","}",".blocklyFlyoutLabelText {",'font-family: "Ztech Regular";',"font-size: 14pt;","fill: #575E75;","font-weight: bold;","}",".nullblocklyFlyoutLabelText {",'font-family: "Ztech Regular";',"font-size: 0pt;","fill: #575E75;","font-weight: bold;",
"}",".blocklySvg text, .blocklyBlockDragSurface text, .blocklyFlyout text, .blocklyToolboxDiv .blocklyToolboxDiv4 text {","user-select: none;","-moz-user-select: none;","-webkit-user-select: none;","cursor: inherit;","}",".blocklyHidden {","display: none;","}",".blocklyFieldDropdown:not(.blocklyHidden) {","display: block;","}",".blocklyIconGroup {","cursor: default;","}",".blocklyIconGroup:not(:hover),",".blocklyIconGroupReadonly {","opacity: .6;","}",".blocklyIconShape {","fill: #00f;","stroke: #fff;",
"stroke-width: 1px;","}",".blocklyIconSymbol {","fill: #fff;","}",".blocklyMinimalBody {","margin: 0;","padding: 0;","}",".blocklyCommentForeignObject {","position: relative;","z-index: 0;","}",".blocklyCommentRect {","fill: #E7DE8E;","stroke: #bcA903;","stroke-width: 1px","}",".blocklyCommentTarget {","fill: transparent;","stroke: #bcA903;","}",".blocklyCommentTargetFocused {","fill: none;","}",".blocklyCommentHandleTarget {","fill: none;","}",".blocklyCommentHandleTargetFocused {","fill: transparent;",
"}",".blocklyFocused>.blocklyCommentRect {","fill: #B9B272;","stroke: #B9B272;","}",".blocklySelected>.blocklyCommentTarget {","stroke: #fc3;","stroke-width: 3px;","}",".blocklyCommentTextarea {","background-color: #fef49c;","border: 0;","outline: 0;","margin: 0;","padding: 3px;","resize: none;","display: block;","overflow: hidden;","}",".blocklyCommentDeleteIcon {","cursor: pointer;","fill: #000;","display: none","}",".blocklySelected > .blocklyCommentDeleteIcon {","display: block","}",".blocklyDeleteIconShape {",
"fill: #000;","stroke: #000;","stroke-width: 1px;","}",".blocklyDeleteIconShape.blocklyDeleteIconHighlighted {","stroke: #fc3;","}",".scratchCommentForeignObject {","position: relative;","}",".scratchCommentBody {","background-color: #fef49c;","border-radius: 4px;","}",".scratchCommentRect {","fill: #fef49c;","}",".scratchCommentTarget {","fill: transparent;","}",".scratchWorkspaceCommentBorder {","stroke: #bcA903;","stroke-width: 1px;","}",".scratchCommentTargetFocused {","fill: none;","}",".scratchCommentTopBar {",
"fill: #000000;","fill-opacity: 0.1","}",".scratchCommentText {",'font-family: "Ztech Regular";',"font-size: 12pt;","font-weight: 400;","}",".scratchCommentTextarea {","background-color: #fef49c;","border: 0;","outline: 0;","padding: 0;","resize: none;","overflow: hidden;","}",".scratchCommentTextarea::placeholder {","color: rgba(0,0,0,0.5);","font-style: italic;","}",".scratchCommentResizeSE {","cursor: se-resize;","fill: transparent;","}",".scratchCommentResizeSW {","cursor: sw-resize;","fill: transparent;",
"}",".blocklyHtmlInput {","border: none;",'font-family: "Ztech Regular";',"font-size: 12pt;","height: 100%;","margin: 0;","outline: none;","box-sizing: border-box;","width: 100%;","text-align: center;","color: $colour_text;","font-weight: 500;","}",".blocklyMainBackground {","stroke-width: 1;","stroke: #c6c6c6;","}",".blocklyMutatorBackground {","fill: #fff;","stroke: #ddd;","stroke-width: 1;","}",".blocklyFlyoutBackground {","fill: $colour_flyout;","fill-opacity: .8;","}",".blocklyMainWorkspaceScrollbar {",
"z-index: 20;","}",".blocklyFlyoutScrollbar {","z-index: 30;","}",".blocklyScrollbarHorizontal, .blocklyScrollbarVertical {","position: absolute;","outline: none;","}",".blocklyScrollbarBackground {","opacity: 0;","}",".blocklyScrollbarHandle {","fill: $colour_scrollbar;","}",".blocklyScrollbarBackground:hover+.blocklyScrollbarHandle,",".blocklyScrollbarHandle:hover {","fill: $colour_scrollbarHover;","}",".blocklyZoom>image {","opacity: 1;","}",".blocklyFlyout .blocklyScrollbarHandle {","fill: #bbb;",
"}",".blocklyFlyout .blocklyScrollbarBackground:hover+.blocklyScrollbarHandle,",".blocklyFlyout .blocklyScrollbarHandle:hover {","fill: #aaa;","}",".blocklyInvalidInput {","background: #faa;","}",".blocklyAngleCircle {","stroke: "+Blockly.Colours.motion.tertiary+";","stroke-width: 1;","fill: "+Blockly.Colours.motion.secondary+";","}",".blocklyAngleCenterPoint {","stroke: #fff;","stroke-width: 1;","fill: #fff;","}",".blocklyAngleDragHandle {","stroke: #fff;","stroke-width: 5;","stroke-opacity: 0.25;",
"fill: #fff;","cursor: pointer;","}",".blocklyAngleDragArrow {","pointer-events: none","}",".blocklyAngleMarks {","stroke: #fff;","stroke-width: 1;","stroke-opacity: 0.5;","}",".blocklyAngleGauge {","fill: #fff;","fill-opacity: 0.20;","}",".blocklyAngleLine {","stroke: #fff;","stroke-width: 1;","stroke-linecap: round;","pointer-events: none;","}",".blocklyContextMenu {","border-radius: 4px;","max-height: 100%;","}",".blocklyDropdownMenu {","padding: 0 !important;","}",".blocklyDropDownNumPad {","background-color: $colour_numPadBackground;",
"}",".blocklyWidgetDiv .goog-option-selected .goog-menuitem-checkbox,",".blocklyWidgetDiv .goog-option-selected .goog-menuitem-icon {","background: url(<<<PATH>>>/sprites.png) no-repeat -48px -16px !important;","}",".blocklyToolboxDiv {","background-color: $colour_toolbox;","color: $colour_toolboxText;","overflow-x: visible;","overflow-y: auto;","position: absolute;",'font-family: "Ztech Regular";',"z-index: 40;","-webkit-tap-highlight-color: transparent;","}",".blocklyToolboxDiv4 {","background-color: $colour_toolbox;",
"color: $colour_toolboxText;","position: absolute;",'font-family: "Ztech Regular";',"z-index: 40;","-webkit-tap-highlight-color: transparent;","}",".blocklyTreeRoot {","padding: 4px 0;","}",".blocklyTreeRoot:focus {","outline: none;","}",".blocklyTreeRow {","height: 22px;","line-height: 22px;","margin-bottom: 3px;","padding-right: 8px;","white-space: nowrap;","}",".blocklyHorizontalTree {","margin: 1px 5px 8px 0;","}",".blocklyHorizontalTreeRtl {","margin: 1px 0 8px 5px;","}",'.blocklyToolboxDiv[dir="RTL"] .blocklyTreeRow {',
"margin-left: 8px;","}",".blocklyTreeRoot {","padding: 4px 0;","}",".blocklyTreeRoot:focus {","outline: none;","}",".blocklyTreeRow {","height: 22px;","line-height: 22px;","margin-bottom: 3px;","padding-right: 8px;","white-space: nowrap;","}",".blocklyHorizontalTree {","float: left;","margin: 1px 5px 8px 0;","}",".blocklyHorizontalTreeRtl {","float: right;","margin: 1px 0 8px 5px;","}",'.blocklyToolboxDiv[dir="RTL"] .blocklyTreeRow {',"margin-left: 8px;","}",".blocklyTreeRow:not(.blocklyTreeSelected):hover {",
"background-color: #e4e4e4;","}",".blocklyTreeSeparator {","border-bottom: solid #e5e5e5 1px;","height: 0;","margin: 5px 0;","}",".blocklyTreeSeparatorHorizontal {","border-right: solid #e5e5e5 1px;","width: 0;","padding: 5px 0;","margin: 0 5px;","}",".blocklyTreeIcon {","background-image: url(<<<PATH>>>/sprites.png);","height: 16px;","vertical-align: middle;","width: 16px;","}",".blocklyTreeIconClosedLtr {","background-position: -32px -1px;","}",".blocklyTreeIconClosedRtl {","background-position: 0px -1px;",
"}",".blocklyTreeIconOpen {","background-position: -16px -1px;","}",".blocklyTreeSelected>.blocklyTreeIconClosedLtr {","background-position: -32px -17px;","}",".blocklyTreeSelected>.blocklyTreeIconClosedRtl {","background-position: 0px -17px;","}",".blocklyTreeSelected>.blocklyTreeIconOpen {","background-position: -16px -17px;","}",".blocklyTreeIconNone,",".blocklyTreeSelected>.blocklyTreeIconNone {","background-position: -48px -1px;","}",".blocklyTreeLabel {","cursor: default;",'font-family: "Ztech Regular";',
"font-size: 16px;","padding: 0 3px;","vertical-align: middle;","}",".blocklyToolboxDelete .blocklyTreeLabel {",'cursor: url("<<<PATH>>>/handdelete.cur"), auto;',"}",".blocklyTreeSelected .blocklyTreeLabel {","color: #fff;","}",".blocklyDropDownDiv .goog-slider-horizontal {","margin: 8px;","height: 22px;","width: 150px;","position: relative;","outline: none;","border-radius: 11px;","margin-bottom: 20px;","}",".blocklyDropDownDiv .goog-slider-horizontal .goog-slider-thumb {","width: 26px;","height: 26px;",
"top: -1px;","position: absolute;","background-color: white;","border-radius: 100%;","-webkit-box-shadow: 0 0 0 4px rgba(0, 0, 0, 0.15);","-moz-box-shadow: 0 0 0 4px rgba(0, 0, 0, 0.15);","box-shadow: 0 0 0 4px rgba(0, 0, 0, 0.15);","}",".scratchEyedropper {","background: none;","outline: none;","border: none;","width: 100%;","text-align: center;","border-top: 1px solid #ddd;","padding-top: 5px;","cursor: pointer;","}",".scratchColourPickerLabel {",'font-family: "Ztech Regular";',"font-size: 0.65rem;",
"color: $colour_toolboxText;","margin: 8px;","}",".scratchColourPickerLabelText {","font-weight: bold;","}",".scratchColourPickerReadout {","margin-left: 10px;","}",".scratchMatrixButtonDiv {","width: 180px;","float:left;","font-size:15px;",'font-family: "Ztech Regular";',"padding:15px;","cursor: pointer;","}",".scratchMatrixButtonDiv5 {","width: 180px;","float:left;","font-size:15px;",'font-family: "Ztech Regular";',"padding:15px;","cursor: pointer;","}",".scratchMatrixButtonDiv2 {","width: 20%;",
"text-align: center;","float: right;","display: flex;","}",".scratchNotePickerKeyLabel {",'font-family: "Helvetica Neue", Helvetica, sans-serif;',"font-size: 0.75rem;","fill: $colour_text;","pointer-events: none;","}",".scratchSliderDiv {","align-items: center;","display: flex;","height: 32px;","justify-content: center;","width: 150px;","}",".scratchFieldSlider {","-webkit-appearance: none;","background: transparent; /* override white in chrome */","margin: 4px;","padding: 0;","width: 100%;","}",
".scratchFieldSlider::-webkit-slider-runnable-track {","background: #ddd;","border-radius: 5px;","height: 10px;","}",".scratchFieldSlider::-webkit-slider-thumb {","-webkit-appearance: none;","background: #fff;","border-radius: 50%;","box-shadow: 0 0 0 4px rgba(255,255,255,.15);","cursor: pointer;","height: 24px;","margin-top: -7px;","width: 24px;","}",".blocklyWidgetDiv .goog-menu {","background: #fff;","border-color: #ccc #666 #666 #ccc;","border-style: solid;","border-width: 1px;","cursor: default;",
'font: normal 13px "Helvetica Neue", Helvetica, sans-serif;',"margin: 0;","outline: none;","padding: 4px 0;","position: absolute;","overflow-y: auto;","overflow-x: hidden;","z-index: 20000;","}",".blocklyDropDownDiv .goog-menu {","cursor: default;",'font: normal 13px "Helvetica Neue", Helvetica, sans-serif;',"outline: none;","z-index: 20000;","}",".blocklyWidgetDiv .goog-menuitem {","color: #000;",'font: normal 13px "Helvetica Neue", Helvetica, sans-serif;',"list-style: none;","margin: 0;","padding: 4px 7em 4px 28px;",
"white-space: nowrap;","}",".blocklyDropDownDiv .goog-menuitem {","color: #fff;",'font: normal 13px "Helvetica Neue", Helvetica, sans-serif;',"font-weight: bold;","list-style: none;","margin: 0;","min-height: 24px;","padding: 4px 7em 4px 28px;","white-space: nowrap;","}",".blocklyWidgetDiv .goog-menuitem.goog-menuitem-rtl, ",".blocklyDropDownDiv .goog-menuitem.goog-menuitem-rtl {","padding-left: 7em;","padding-right: 28px;","}",".blocklyWidgetDiv .goog-menu-nocheckbox .goog-menuitem,",".blocklyWidgetDiv .goog-menu-noicon .goog-menuitem, ",
".blocklyDropDownDiv .goog-menu-nocheckbox .goog-menuitem,",".blocklyDropDownDiv .goog-menu-noicon .goog-menuitem { ","padding-left: 12px;","}",".blocklyWidgetDiv .goog-menu-noaccel .goog-menuitem, ",".blocklyDropDownDiv .goog-menu-noaccel .goog-menuitem {","padding-right: 20px;","}",".blocklyWidgetDiv .goog-menuitem-content ",".blocklyDropDownDiv .goog-menuitem-content {","color: #000;",'font: normal 13px "Helvetica Neue", Helvetica, sans-serif;',"}",".blocklyWidgetDiv .goog-menuitem-disabled .goog-menuitem-accel,",
".blocklyWidgetDiv .goog-menuitem-disabled .goog-menuitem-content, ",".blocklyDropDownDiv .goog-menuitem-disabled .goog-menuitem-accel,",".blocklyDropDownDiv .goog-menuitem-disabled .goog-menuitem-content {","color: #ccc !important;","}",".blocklyWidgetDiv .goog-menuitem-disabled .goog-menuitem-icon, ",".blocklyDropDownDiv .goog-menuitem-disabled .goog-menuitem-icon {","opacity: 0.3;","-moz-opacity: 0.3;","filter: alpha(opacity=30);","}",".blocklyWidgetDiv .goog-menuitem-highlight,",".blocklyWidgetDiv .goog-menuitem-hover {",
"background-color: #d6e9f8;","border-color: #d6e9f8;","border-style: dotted;","border-width: 1px 0;","padding-bottom: 3px;","padding-top: 3px;","}",".blocklyDropDownDiv .goog-menuitem-highlight,",".blocklyDropDownDiv .goog-menuitem-hover {","background-color: rgba(0, 0, 0, 0.2);","}",".blocklyWidgetDiv .goog-menuitem-checkbox,",".blocklyWidgetDiv .goog-menuitem-icon, ",".blocklyDropDownDiv .goog-menuitem-checkbox,",".blocklyDropDownDiv .goog-menuitem-icon {","background-repeat: no-repeat;","height: 16px;",
"left: 6px;","position: absolute;","right: auto;","vertical-align: middle;","width: 16px;","}",".blocklyWidgetDiv .goog-option-selected .goog-menuitem-checkbox,",".blocklyWidgetDiv .goog-option-selected .goog-menuitem-icon,",".blocklyDropDownDiv .goog-option-selected .goog-menuitem-checkbox,",".blocklyDropDownDiv .goog-option-selected .goog-menuitem-icon {","background: url(<<<PATH>>>/sprites.png) no-repeat -48px -16px !important;","position: static;","float: left;","margin-left: -24px;","}",".blocklyWidgetDiv .goog-menuitem-rtl .goog-menuitem-checkbox,",
".blocklyWidgetDiv .goog-menuitem-rtl .goog-menuitem-icon,",".blocklyDropDownDiv .goog-menuitem-rtl .goog-menuitem-checkbox,",".blocklyDropDownDiv .goog-menuitem-rtl .goog-menuitem-icon {","float: right;","margin-right: -24px;","}",".blocklyWidgetDiv .goog-menuitem-accel, ",".blocklyDropDownDiv .goog-menuitem-accel {","color: #999;","direction: ltr;","left: auto;","padding: 0 6px;","position: absolute;","right: 0;","text-align: right;","}",".blocklyWidgetDiv .goog-menuitem-rtl .goog-menuitem-accel, ",
".blocklyDropDownDiv .goog-menuitem-rtl .goog-menuitem-accel {","left: 0;","right: auto;","text-align: left;","}",".blocklyWidgetDiv .goog-menuitem-mnemonic-hint, ",".blocklyDropDownDiv .goog-menuitem-mnemonic-hint {","text-decoration: underline;","}",".blocklyWidgetDiv .goog-menuitem-mnemonic-separator, ",".blocklyDropDownDiv .goog-menuitem-mnemonic-separator {","color: #999;","font-size: 12px;","padding-left: 4px;","}",".blocklyWidgetDiv .goog-menuseparator, ",".blocklyDropDownDiv .goog-menuseparator {",
"border-top: 1px solid #ccc;","margin: 4px 0;","padding: 0;","}",".blocklyFlyoutCheckbox {","fill: white;","stroke: #c8c8c8;","}",".checked > .blocklyFlyoutCheckbox {","fill: "+Blockly.Colours.motion.primary+";","stroke: "+Blockly.Colours.motion.tertiary+";","}",".blocklyFlyoutCheckboxPath {","fill: transparent;","stroke: white;","stroke-width: 3;","stroke-linecap: round;","stroke-linejoin: round;","}",".scratchCategoryMyLabel {","cursor: pointer;","font-size: 10px;","text-align: center;","opacity: 1;",
"}",".scratchCategoryMyLabelHorizontal {","cursor: pointer;","font-size: 12px;","text-align: center;","opacity: 1;","}",".scratchCategoryMenu {","width: 60px;","background: $colour_toolbox;","color: $colour_toolboxText;","font-size: .7rem;","user-select: none;","-webkit-user-select: none;","-moz-user-select: none;","-ms-user-select: none;","}",".scratchCategoryMenuHorizontal {","width: 100%;","height: 50px;","background: $colour_toolbox;","color: $colour_toolboxText;","font-size: .7em;","user-select: none;",
"-webkit-user-select: none;","-moz-user-select: none;","-ms-user-select: none;","}",".scratchCategoryMenuHorizontal .scratchCategoryMenuRow {","float: left;","margin: 3px;","}",".scratchCategoryMenuRow {","}",".scratchCategoryMenuItem {","padding: 0.375rem 0px;","cursor: pointer;","text-align: center;","opacity: 0.5;","}"," .scratchCategoryMenuItem {","padding: 6px 5px;","}",".scratchCategoryMenuItem.categorySelected {","background: $colour_toolboxSelected;","opacity: 1;","border-radius: 10%;","}",
".scratchCategoryMenuItemVertical {","padding: 0.375rem 0px;","cursor: pointer;","text-align: center;","opacity: 1;","}"," .scratchCategoryMenuItemVertical {","padding: 6px 5px;","}",".scratchCategoryMenuItemVertical.categorySelected {","background: $colour_toolboxSelected;","opacity: 1;","padding: 8px 1px 8px 1px;","border-radius: 20%;","margin: 10px 4px 10px 4px;","}",".scratchCategoryItemBubble {","width: 1.25rem;","height: 1.25rem;","border: 1px solid;","border-radius: 100%;","margin: 0 auto 0.125rem;",
"}",".scratchCategoryItemBubbleHorizontal {","width: 3.75rem;","height: 1.25rem;","border: 1px solid;","border-radius: 1.25rem;","margin: 0 auto 0.125rem;","}",".scratchCategoryItemIcon {","width: 1.25rem;","height: 1.25rem;","margin: 0 auto 0.125rem;","background-size: 100%;","}",".scratchCategoryMenuItem:hover {","color: $colour_toolboxHover !important;","}",".scratchCategoryMenuItemVertical:hover {","color: $colour_toolboxHover !important;","}",""];Blockly.WidgetDiv={};Blockly.WidgetDiv.DIV=null;Blockly.WidgetDiv.owner_=null;Blockly.WidgetDiv.dispose_=null;Blockly.WidgetDiv.disposeAnimationFinished_=null;Blockly.WidgetDiv.disposeAnimationTimer_=null;Blockly.WidgetDiv.disposeAnimationTimerLength_=0;Blockly.WidgetDiv.createDom=function(){Blockly.WidgetDiv.DIV||(Blockly.WidgetDiv.DIV=goog.dom.createDom("DIV","blocklyWidgetDiv"),document.body.appendChild(Blockly.WidgetDiv.DIV))};
Blockly.WidgetDiv.show=function(a,b,c,d,e){Blockly.WidgetDiv.hide();Blockly.WidgetDiv.owner_=a;Blockly.WidgetDiv.dispose_=c;Blockly.WidgetDiv.disposeAnimationFinished_=d;Blockly.WidgetDiv.disposeAnimationTimerLength_=e;a=goog.style.getViewportPageOffset(document);Blockly.WidgetDiv.DIV.style.top=a.y+"px";Blockly.WidgetDiv.DIV.style.direction=b?"rtl":"ltr";Blockly.WidgetDiv.DIV.style.display="block"};
Blockly.WidgetDiv.repositionForWindowResize=function(){if(Blockly.WidgetDiv.owner_&&Blockly.WidgetDiv.owner_.getScaledBBox_&&Blockly.WidgetDiv.owner_.getSize){var a=Blockly.WidgetDiv.owner_.getScaledBBox_(),b=Blockly.WidgetDiv.owner_.getSize();Blockly.WidgetDiv.positionInternal_(a.left,a.top,b.height)}else Blockly.WidgetDiv.hide()};
Blockly.WidgetDiv.hide=function(a){Blockly.WidgetDiv.disposeAnimationTimer_?(window.clearTimeout(Blockly.WidgetDiv.disposeAnimationTimer_),Blockly.WidgetDiv.disposeAnimationFinished_&&Blockly.WidgetDiv.disposeAnimationFinished_(),Blockly.WidgetDiv.disposeAnimationFinished_=null,Blockly.WidgetDiv.disposeAnimationTimer_=null,Blockly.WidgetDiv.owner_=null,Blockly.WidgetDiv.hideAndClearDom_()):Blockly.WidgetDiv.isVisible()&&(Blockly.WidgetDiv.dispose_&&Blockly.WidgetDiv.dispose_(),Blockly.WidgetDiv.dispose_=
null,Blockly.WidgetDiv.disposeAnimationFinished_&&!a?Blockly.WidgetDiv.disposeAnimationTimer_=window.setTimeout(Blockly.WidgetDiv.hide,1E3*Blockly.WidgetDiv.disposeAnimationTimerLength_):(Blockly.WidgetDiv.disposeAnimationFinished_&&Blockly.WidgetDiv.disposeAnimationFinished_(),Blockly.WidgetDiv.disposeAnimationFinished_=null,Blockly.WidgetDiv.owner_=null,Blockly.WidgetDiv.hideAndClearDom_()))};
Blockly.WidgetDiv.hideAndClearDom_=function(){Blockly.WidgetDiv.DIV.style.display="none";Blockly.WidgetDiv.DIV.style.left="";Blockly.WidgetDiv.DIV.style.top="";Blockly.WidgetDiv.DIV.style.height="";goog.dom.removeChildren(Blockly.WidgetDiv.DIV)};Blockly.WidgetDiv.isVisible=function(){return!!Blockly.WidgetDiv.owner_};Blockly.WidgetDiv.hideIfOwner=function(a){Blockly.WidgetDiv.owner_==a&&Blockly.WidgetDiv.hide()};
Blockly.WidgetDiv.position=function(a,b,c,d,e){b<d.y&&(b=d.y);e?a>c.width+d.x&&(a=c.width+d.x):a<d.x&&(a=d.x);Blockly.WidgetDiv.positionInternal_(a,b,c.height)};Blockly.WidgetDiv.positionInternal_=function(a,b,c){Blockly.WidgetDiv.DIV.style.left=a+"px";Blockly.WidgetDiv.DIV.style.top=b+"px";Blockly.WidgetDiv.DIV.style.height=c+"px"};
Blockly.WidgetDiv.positionWithAnchor=function(a,b,c,d){var e=Blockly.WidgetDiv.calculateY_(a,b,c);a=Blockly.WidgetDiv.calculateX_(a,b,c,d);0>e?Blockly.WidgetDiv.positionInternal_(a,0,c.height+e):Blockly.WidgetDiv.positionInternal_(a,e,c.height)};Blockly.WidgetDiv.calculateX_=function(a,b,c,d){if(d)return b=Math.max(b.right-c.width,a.left),Math.min(b,a.right-c.width);b=Math.min(b.left,a.right-c.width);return Math.max(b,a.left)};
Blockly.WidgetDiv.calculateY_=function(a,b,c){return b.bottom+c.height>=a.bottom?b.top-c.height:b.bottom};Blockly.inject=function(a,b){goog.isString(a)&&(a=document.getElementById(a)||document.querySelector(a));if(!goog.dom.contains(document,a))throw"Error: container is not in current document.";b=new Blockly.Options(b||{});var c=goog.dom.createDom("div","injectionDiv");a.appendChild(c);Blockly.Field.startCache();a=Blockly.createDom_(c,b);var d=new Blockly.BlockDragSurfaceSvg(c);c=new Blockly.WorkspaceDragSurfaceSvg(c);b=Blockly.createMainWorkspace_(a,b,d,c);Blockly.init_(b);Blockly.mainWorkspace=b;Blockly.svgResize(b);
return b};
Blockly.createDom_=function(a,b){a.setAttribute("dir","LTR");goog.ui.Component.setDefaultRightToLeft(b.RTL);Blockly.Css.inject(b.hasCss,b.pathToMedia);a=Blockly.utils.createSvgElement("svg",{xmlns:"http://www.w3.org/2000/svg","xmlns:html":"http://www.w3.org/1999/xhtml","xmlns:xlink":"http://www.w3.org/1999/xlink",version:"1.1","class":"blocklySvg"},a);var c=Blockly.utils.createSvgElement("defs",{},a),d=String(Math.random()).substring(2),e=Blockly.utils.createSvgElement("filter",{id:"blocklyStackGlowFilter"+d,
height:"160%",width:"180%",y:"-30%",x:"-40%"},c);b.stackGlowBlur=Blockly.utils.createSvgElement("feGaussianBlur",{"in":"SourceGraphic",stdDeviation:Blockly.Colours.stackGlowSize},e);var f=Blockly.utils.createSvgElement("feComponentTransfer",{result:"outBlur"},e);Blockly.utils.createSvgElement("feFuncA",{type:"table",tableValues:"0"+goog.string.repeat(" 1",16)},f);Blockly.utils.createSvgElement("feFlood",{"flood-color":Blockly.Colours.stackGlow,"flood-opacity":Blockly.Colours.stackGlowOpacity,result:"outColor"},
e);Blockly.utils.createSvgElement("feComposite",{"in":"outColor",in2:"outBlur",operator:"in",result:"outGlow"},e);Blockly.utils.createSvgElement("feComposite",{"in":"SourceGraphic",in2:"outGlow",operator:"over"},e);var g=Blockly.utils.createSvgElement("filter",{id:"blocklyReplacementGlowFilter"+d,height:"160%",width:"180%",y:"-30%",x:"-40%"},c);Blockly.utils.createSvgElement("feGaussianBlur",{"in":"SourceGraphic",stdDeviation:Blockly.Colours.replacementGlowSize},g);f=Blockly.utils.createSvgElement("feComponentTransfer",
{result:"outBlur"},g);Blockly.utils.createSvgElement("feFuncA",{type:"table",tableValues:"0"+goog.string.repeat(" 1",16)},f);Blockly.utils.createSvgElement("feFlood",{"flood-color":Blockly.Colours.replacementGlow,"flood-opacity":Blockly.Colours.replacementGlowOpacity,result:"outColor"},g);Blockly.utils.createSvgElement("feComposite",{"in":"outColor",in2:"outBlur",operator:"in",result:"outGlow"},g);Blockly.utils.createSvgElement("feComposite",{"in":"SourceGraphic",in2:"outGlow",operator:"over"},g);
f=Blockly.utils.createSvgElement("pattern",{id:"blocklyDisabledPattern"+d,patternUnits:"userSpaceOnUse",width:10,height:10},c);Blockly.utils.createSvgElement("rect",{width:10,height:10,fill:"#aaa"},f);Blockly.utils.createSvgElement("path",{d:"M 0 0 L 10 10 M 10 0 L 0 10",stroke:"#cc0"},f);b.stackGlowFilterId=e.id;b.replacementGlowFilterId=g.id;b.disabledPatternId=f.id;b.gridPattern=Blockly.Grid.createDom(d,b.gridOptions,c);return a};
Blockly.createMainWorkspace_=function(a,b,c,d){b.parentWorkspace=null;var e=new Blockly.WorkspaceSvg(b,c,d);e.scale=b.zoomOptions.startScale;a.appendChild(e.createDom("blocklyMainBackground"));!b.hasCategories&&b.languageTree&&(c=e.addFlyout_("svg"),Blockly.utils.insertAfter(c,a));e.translate(0,0);Blockly.mainWorkspace=e;b.readOnly||b.hasScrollbars||e.addChangeListener(function(){if(!e.isDragging()){var a=e.getMetrics(),c=a.viewLeft+a.absoluteLeft,d=a.viewTop+a.absoluteTop;if(a.contentTop<d||a.contentTop+
a.contentHeight>a.viewHeight+d||a.contentLeft<(b.RTL?a.viewLeft:c)||a.contentLeft+a.contentWidth>(b.RTL?a.viewWidth:a.viewWidth+c))for(var k=e.getTopBlocks(!1),l=0,m;m=k[l];l++){var n=m.getRelativeToSurfaceXY(),p=m.getHeightWidth(),q=d+25-p.height-n.y;0<q&&m.moveBy(0,q);q=d+a.viewHeight-25-n.y;0>q&&m.moveBy(0,q);q=25+c-n.x-(b.RTL?0:p.width);0<q&&m.moveBy(q,0);n=c+a.viewWidth-25-n.x+(b.RTL?p.width:0);0>n&&m.moveBy(n,0)}}});Blockly.svgResize(e);Blockly.WidgetDiv.createDom();Blockly.DropDownDiv.createDom();
Blockly.Tooltip.createDom();return e};
Blockly.init_=function(a){var b=a.options,c=a.getParentSvg();Blockly.bindEventWithChecks_(c.parentNode,"contextmenu",null,function(a){Blockly.utils.isTargetInput(a)||a.preventDefault()});c=Blockly.bindEventWithChecks_(window,"resize",null,function(){Blockly.hideChaffOnResize(!0);Blockly.svgResize(a)});a.setResizeHandlerWrapper(c);Blockly.inject.bindDocumentEvents_();b.languageTree&&(a.toolbox_?a.toolbox_.init(a):a.flyout_&&(a.flyout_.init(a),a.flyout_.show(b.languageTree.childNodes),a.flyout_.scrollToStart(),
b.horizontalLayout?(a.scrollY=a.flyout_.height_,b.toolboxPosition==Blockly.TOOLBOX_AT_BOTTOM&&(a.scrollY*=-1)):(a.scrollX=a.flyout_.width_,b.toolboxPosition==Blockly.TOOLBOX_AT_RIGHT&&(a.scrollX*=-1)),a.translate(a.scrollX,a.scrollY)));b.hasScrollbars&&(a.scrollbar=new Blockly.ScrollbarPair(a),a.scrollbar.resize());b.hasSounds&&Blockly.inject.loadSounds_(b.pathToMedia,a)};
Blockly.inject.bindDocumentEvents_=function(){Blockly.documentEventsBound_||(Blockly.bindEventWithChecks_(document,"keydown",null,Blockly.onKeyDown_),Blockly.bindEvent_(document,"touchend",null,Blockly.longStop_),Blockly.bindEvent_(document,"touchcancel",null,Blockly.longStop_),goog.userAgent.IPAD&&Blockly.bindEventWithChecks_(window,"orientationchange",document,function(){Blockly.svgResize(Blockly.getMainWorkspace())}));Blockly.documentEventsBound_=!0};
Blockly.inject.loadSounds_=function(a,b){var c=b.getAudioManager();c.load([a+"click.mp3",a+"click.wav",a+"click.ogg"],"click");c.load([a+"delete.mp3",a+"delete.ogg",a+"delete.wav"],"delete");var d=[];a=function(){for(;d.length;)Blockly.unbindEvent_(d.pop());c.preload()};d.push(Blockly.bindEventWithChecks_(document,"mousemove",null,a,!0));d.push(Blockly.bindEventWithChecks_(document,"touchstart",null,a,!0))};
Blockly.updateToolbox=function(a){console.warn("Deprecated call to Blockly.updateToolbox, use workspace.updateToolbox instead.");Blockly.getMainWorkspace().updateToolbox(a)};var Custom={FieldCustomMatrixJr:{}};arrcustommatrix=[];
Blockly.FieldMatrixTemplate=function(a){Blockly.FieldMatrixTemplate.superClass_.constructor.call(this,a);this.addArgType("matrix");this.ledThumbNodes_=[];this.ledButtons_=[];this.stopDragging=this.startDragging=this.addEventListener_=this.mycustommatrixtemplate_=this.mycustommatrix_=this.matrix_="";this.mymatrix1_="00001111111100000000000000110000000000000110000000000000110000000000000110000000000000110000000000000110000000000000111111110000";this.mymatrix2_="00000000000000000011111001111100001000100100010000100010010001000010001001000100001000100100010000111110011111000000000000000000";
this.mymatrix3_="00000000000000000000110000110000000111100111100000011110011110000001111001111000000111100111100000001100001100000000000000000000";this.mymatrix4_="00000000000000000000000000000000011111100111111000111100001111000001100000011000000000000000000000000000000000000000000000000000";this.mymatrix5_="00000000000000000000000000000000001111100111110000001100000110000001000000100000001111100111110000000000000000000000000000000000";this.matrixReleaseWrapper_=this.matrixMoveWrapper_=this.matrixTouchWrapper_=
this.mycustomButtonDragger_=this.mycustomButtonWrapper_=this.fillButtonWrapper_=this.mytemplatematrix1ButtonWrapper_=this.mouseDownWrapper_=this.paintStyle_=this.arrow_=this.matrixStage_=null};goog.inherits(Blockly.FieldMatrixTemplate,Blockly.Field);Blockly.FieldMatrixTemplate.fromJson=function(a){return new Blockly.FieldMatrixTemplate(a.matrix)};Blockly.FieldMatrixTemplate.THUMBNAIL_SIZE=12;Blockly.FieldMatrixTemplate.THUMBNAIL_NODE_SIZE=2.8;Blockly.FieldMatrixTemplate.THUMBNAIL_NODE_PAD=.7;
Blockly.FieldMatrixTemplate.ARROW_SIZE=7;Blockly.FieldMatrixTemplate.MATRIX_NODE_SIZE=32;Blockly.FieldMatrixTemplate.MATRIX_NODE_RADIUS=4;Blockly.FieldMatrixTemplate.MATRIX_NODE_PAD=8;Blockly.FieldMatrixTemplate.ZEROS="00000000000000000000000001111111111111111111111111000000000000000000000000011111111111111111111111111111111111111111111111111000";Blockly.FieldMatrixTemplate.ONES="00000000000000000000000001111111111111111111111111000000000000000000000000011111111111111111111111111111111111111111111111111000";
Blockly.FieldMatrixTemplate.CLEARMATRIX="00000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000";Blockly.FieldMatrixTemplate.FILLMATRIX="11111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111";
Blockly.FieldMatrixTemplate.prototype.init=function(){if(!this.fieldGroup_){this.fieldGroupImage_=Blockly.utils.createSvgElement("g",{},null);this.visible_||(this.fieldGroupImage_.style.display="none");this.imageElement_=Blockly.utils.createSvgElement("image",{x:5,y:-45,height:"40px",width:"40px"},this.fieldGroupImage_);this.setValueImage(Blockly.mainWorkspace.options.pathToMedia+"icons/z-ledmatrix/select_template.svg");this.sourceBlock_.getSvgRoot().appendChild(this.fieldGroupImage_);if(this.sourceBlock_.getParent()){var a=
this.sourceBlock_.getParent();this.sourceBlock_.setColour(a.getColour(),a.getColourSecondary(),a.getColourTertiary())}this.fieldGroup_=Blockly.utils.createSvgElement("g",{},null);this.fieldGroup2_=Blockly.utils.createSvgElement("g",{},null);this.size_.width=Blockly.FieldMatrixTemplate.THUMBNAIL_SIZE+Blockly.FieldMatrixTemplate.ARROW_SIZE+1.5*Blockly.BlockSvg.DROPDOWN_ARROW_PADDING+40;this.size_.height=60;this.sourceBlock_.getSvgRoot().appendChild(this.fieldGroup_);this.arrowSize_=12;this.arrowX_=
0;this.arrowY_=11;this.arrow_=Blockly.utils.createSvgElement("image",{x:18,y:10,height:this.arrowSize_+"px",width:this.arrowSize_+"px"});this.arrow_.setAttributeNS("http://www.w3.org/1999/xlink","xlink:href",Blockly.mainWorkspace.options.pathToMedia+"dropdown-arrow.svg");this.sourceBlock_.getSvgRoot().appendChild(this.arrow_);Blockly.utils.createSvgElement("g",{transform:"translate("+(Blockly.BlockSvg.DROPDOWN_ARROW_PADDING/2+2)+", "+((this.size_.height-Blockly.FieldMatrixTemplate.THUMBNAIL_SIZE)/
2-8)+")","pointer-events":"bounding-box",cursor:"pointer"},this.fieldGroup_);this.ledThumbNodes_=[];this.mouseDownWrapper_=Blockly.bindEventWithChecks_(this.getClickTarget_(),"mousedown",this,this.onMouseDown_)}};Blockly.FieldMatrixTemplate.prototype.setValueImage=function(a){null!==a&&(this.src_=a,this.imageElement_&&this.imageElement_.setAttributeNS("http://www.w3.org/1999/xlink","xlink:href",a||""))};
Blockly.FieldMatrixTemplate.prototype.setValue=function(a,b){a&&a!==this.matrix_&&(this.sourceBlock_&&Blockly.Events.isEnabled()&&Blockly.Events.fire(new Blockly.Events.Change(this.sourceBlock_,"field",this.name,this.matrix_,a)),this.matrix_=a+=Blockly.FieldMatrixTemplate.ZEROS.substr(0,128-a.length),this.updateMatrix_())};Blockly.FieldMatrixTemplate.prototype.getValue=function(){return String(this.matrix_)};
Blockly.FieldMatrixTemplate.prototype.showEditor_=function(){Blockly.DropDownDiv.hideWithoutAnimation();Blockly.DropDownDiv.clearContent();var a=Blockly.DropDownDiv.getContentDiv();this.ledButtons_=[];var b=document.createElement("button");b.setAttribute("id",":"+c);b.setAttribute("role","menuitem");b.setAttribute("class","myDownButtonFill");buttonDiv=document.createElement("div");buttonDiv.setAttribute("class","mycontainer");buttonDiv.setAttribute("id","bottomButonZone");arrcustommatrix=Blockly.Block.custommatrixget();
for(var c=0;c<arrcustommatrix.length;c++){b=document.createElement("div");b.className="scratchMatrixButtonDiv5";var d=this.createCustomTemplateButton_(c);d.setAttribute("id","mybtn"+c);b.setAttribute("id","mybtn"+c);d.setAttribute("name",c);b.appendChild(d);buttonDiv.appendChild(b);Blockly.bindEvent_(b,"click",this,this.myfuncMatrixCustom_);Blockly.bindEvent_(d,"click",this,this.myfuncMatrixCustom_)}var e=!1,f,g,h=function(a){e=!0;var b=document.getElementById("bottomButonZone");f=a.pageX-b.offsetLeft;
g=b.scrollLeft},k=function(a){e=!1};Blockly.FieldMatrixTemplate.prototype.myclearMatrix2_=function(a){var b=document.getElementById("bottomButonZone");b.addEventListener("mousedown",h,!1);b.addEventListener("mouseup",k,!1);b.addEventListener("mouseleave",k,!1);a.preventDefault();e&&(b.scrollLeft=g-(a.pageX-b.offsetLeft-f))};Blockly.FieldMatrixTemplate.prototype.startDragging2_=function(a){};Blockly.FieldMatrixTemplate.prototype.stopDragging2_=function(a){0==a.button&&(e=!1)};Blockly.bindEvent_(buttonDiv,
"mousemove",this,this.myclearMatrix2_);b=document.createElement("button");b.setAttribute("id",":"+c);b.setAttribute("role","menuitem");b.setAttribute("class","myButtonRightTemplate");b.title="fill";b.style.width="30px";b.style.height="80px";var l=this.sourceBlock_.getColour();l=this.sourceBlock_.getColourTertiary();b.setAttribute("aria-selected","true");b.style.backgroundColor=l;b.style.borderColor=this.sourceBlock_.getColourTertiary();Blockly.bindEvent_(b,"click",this,this.myscrollright_);l=document.createElement("img");
l.src=Blockly.mainWorkspace.options.pathToMedia+"sagok.png";var m=Blockly.mainWorkspace.options.pathToMedia+"sagok.png";b.setAttribute("data-value",m);l.setAttribute("data-value",m);b.appendChild(l);d=document.createElement("button");d.setAttribute("id",":"+c);d.setAttribute("role","menuitem");d.setAttribute("class","myButtonLeftTemplate");d.title="fill";d.style.width="30px";d.style.height="80px";l=this.sourceBlock_.getColour();l=this.sourceBlock_.getColourTertiary();d.setAttribute("aria-selected",
"true");d.style.backgroundColor=l;d.style.borderColor=this.sourceBlock_.getColourTertiary();this.clearButtonWrapper_=Blockly.bindEvent_(d,"click",this,this.myscrollleft_);l=document.createElement("img");l.src=Blockly.mainWorkspace.options.pathToMedia+"oksol.png";m=Blockly.mainWorkspace.options.pathToMedia+"oksol.png";d.setAttribute("data-value",m);l.setAttribute("data-value",m);d.appendChild(l);buttonDiv.appendChild(d);buttonDiv.appendChild(b);c=document.createElement("div");a.appendChild(buttonDiv);
a.appendChild(c);Blockly.DropDownDiv.setColour(this.sourceBlock_.getColour(),this.sourceBlock_.getColourTertiary());Blockly.DropDownDiv.setCategory(this.sourceBlock_.getCategory());Blockly.DropDownDiv.showPositionedByBlock(this,this.sourceBlock_);this.updateMatrix_()};this.nodeCallback_=function(a,b){};
Blockly.FieldMatrixTemplate.prototype.createButton_=function(a){for(var b=Blockly.utils.createSvgElement("svg",{xmlns:"http://www.w3.org/2000/svg","xmlns:html":"http://www.w3.org/1999/xhtml","xmlns:xlink":"http://www.w3.org/1999/xlink",version:"1.1",height:Blockly.FieldMatrixTemplate.MATRIX_NODE_SIZE/5*13+"px",width:Blockly.FieldMatrixTemplate.MATRIX_NODE_SIZE/5*25+"px"}),c=Blockly.FieldMatrixTemplate.MATRIX_NODE_SIZE/5,d=Blockly.FieldMatrixTemplate.MATRIX_NODE_SIZE/16,e=0,f=0,g=0;8>g;g++){this.mycustommatrix_=
arrmatrix[a];f++;for(var h=0;16>h;h++)Blockly.utils.createSvgElement("rect",{x:(c+d)*h+d,y:(c+d)*g+d,width:c,height:c,rx:d,ry:d,fill:"0"===this.mycustommatrix_[e]?this.sourceBlock_.colourSecondary_:"#FFFFFF"},b),e++}return b};
Blockly.FieldMatrixTemplate.prototype.createCustomTemplateButton_=function(a){var b=Blockly.utils.createSvgElement("svg",{xmlns:"http://www.w3.org/2000/svg","xmlns:html":"http://www.w3.org/1999/xhtml","xmlns:xlink":"http://www.w3.org/1999/xlink",version:"1.1",height:Blockly.FieldMatrixTemplate.MATRIX_NODE_SIZE/5*13+"px",width:Blockly.FieldMatrixTemplate.MATRIX_NODE_SIZE/5*25+"px"}),c=Blockly.FieldMatrixTemplate.MATRIX_NODE_SIZE/5,d=Blockly.FieldMatrixTemplate.MATRIX_NODE_SIZE/16,e=0,f=0;b.setAttribute("id",
"mybtn"+a);for(var g=0;8>g;g++){this.mycustommatrixtemplate_=arrcustommatrix[a];f++;for(var h=0;16>h;h++){var k=Blockly.utils.createSvgElement("rect",{x:(c+d)*h+d,y:(c+d)*g+d,width:c,height:c,rx:d,ry:d,fill:"0"===this.mycustommatrixtemplate_[e]?this.sourceBlock_.colourSecondary_:"#FFFFFF"},b);k.setAttribute("id","mybtn"+a);Blockly.bindEvent_(k,"click",this,this.myfuncMatrixCustom_);e++}}Blockly.bindEvent_(b,"click",this,this.myfuncMatrixCustom_);return b};
Blockly.FieldMatrixTemplate.prototype.updateMatrix_=function(){for(var a=0;a<this.matrix_.length;a++)"0"===this.matrix_[a]?(this.fillMatrixNode_(this.ledButtons_,a,this.sourceBlock_.colourSecondary_),this.fillMatrixNode_(this.ledThumbNodes_,a,this.sourceBlock_.colour_)):(this.fillMatrixNode_(this.ledButtons_,a,"#FFFFFF"),this.fillMatrixNode_(this.ledThumbNodes_,a,"#FFFFFF"))};
Blockly.FieldMatrixTemplate.prototype.updateImageToMatrix_=function(){this.setValueImage("");var a=Blockly.utils.createSvgElement("g",{transform:"translate("+(Blockly.BlockSvg.DROPDOWN_ARROW_PADDING/2+2)+", "+((this.size_.height-Blockly.FieldMatrixTemplate.THUMBNAIL_SIZE)/2-8)+")","pointer-events":"bounding-box",cursor:"pointer"},this.fieldGroup_);this.ledThumbNodes_=[];for(var b=Blockly.FieldMatrixTemplate.THUMBNAIL_NODE_SIZE,c=Blockly.FieldMatrixTemplate.THUMBNAIL_NODE_PAD,d=0;8>d;d++){for(var e=
0;16>e;e++)this.ledThumbNodes_.push(Blockly.utils.createSvgElement("rect",{x:(b+c)*e+c,y:(b+c)*d+c-40,width:b,height:b,rx:c,ry:c},a));a.style.cursor="default";this.updateMatrix_()}};Blockly.FieldMatrixTemplate.prototype.myfuncMatrix1_=function(a){0==a.button&&(this.updateImageToMatrix_(),this.setValue(this.mymatrix1_))};Blockly.FieldMatrixTemplate.prototype.myfuncMatrixCustom_=function(a){this.updateImageToMatrix_();a=a.srcElement.id.slice(5,6);a=parseInt(a);this.setValue(arrcustommatrix[a])};
Blockly.FieldMatrixTemplate.prototype.clearMatrix_=function(a){0==a.button&&this.setValue(Blockly.FieldMatrixTemplate.ZEROS)};Blockly.FieldMatrixTemplate.prototype.myclearMatrix_=function(a){0==a.button&&this.setValue(Blockly.FieldMatrixTemplate.CLEARMATRIX)};Blockly.FieldMatrixTemplate.prototype.myfillMatrix_=function(a){0==a.button&&this.setValue(Blockly.FieldMatrixTemplate.FILLMATRIX)};Blockly.FieldMatrixTemplate.prototype.myfuncOk_=function(a){0==a.button&&Blockly.DropDownDiv.hideWithoutAnimation()};
Blockly.FieldMatrixTemplate.prototype.mysaveMatrix_=function(a){if(0==a.button){a=Blockly.utils.createSvgElement("svg",{xmlns:"http://www.w3.org/2000/svg","xmlns:html":"http://www.w3.org/1999/xhtml","xmlns:xlink":"http://www.w3.org/1999/xlink",version:"1.1",height:Blockly.FieldMatrixTemplate.MATRIX_NODE_SIZE/5*13+"px",width:Blockly.FieldMatrixTemplate.MATRIX_NODE_SIZE/5*25+"px"});var b=Blockly.FieldMatrixTemplate.MATRIX_NODE_SIZE/5,c=Blockly.FieldMatrixTemplate.MATRIX_NODE_SIZE/16,d=0;arrcustommatrix.push(this.matrix_);
var e=arrcustommatrix.length-1;a.setAttribute("id","mybtn"+e);for(var f=0;8>f;f++){for(var g=0;16>g;g++){var h=Blockly.utils.createSvgElement("rect",{x:(b+c)*g+c,y:(b+c)*f+c,width:b,height:b,rx:c,ry:c,fill:"0"===this.matrix_[d]?this.sourceBlock_.colourSecondary_:"#FFFFFF"},a);h.setAttribute("id","mybtn"+e);d++}Blockly.bindEvent_(h,"click",this,this.myfuncMatrixCustom_)}Blockly.bindEvent_(a,"click",this,this.myfuncMatrixCustom_);h=document.createElement("div");h.className="scratchMatrixButtonDiv";
h.appendChild(a);buttonDiv.appendChild(h);var k=document.getElementById("bottomButonZone"),l=0,m=setInterval(function(){k.scrollLeft+=50;l+=10;350<=l&&window.clearInterval(m)},40)}};Blockly.FieldMatrixTemplate.prototype.myscrollright_=function(a){if(0==a.button)var b=document.getElementById("bottomButonZone"),c=0,d=setInterval(function(){b.scrollLeft+=48;c+=10;110<=c&&window.clearInterval(d)},50)};
Blockly.FieldMatrixTemplate.prototype.myscrollleft_=function(a){if(0==a.button)var b=document.getElementById("bottomButonZone"),c=0,d=setInterval(function(){b.scrollLeft-=48;c+=10;110<=c&&window.clearInterval(d)},50)};Blockly.FieldMatrixTemplate.prototype.fillMatrix_=function(a){0==a.button&&this.setValue(Blockly.FieldMatrixTemplate.ONES)};Blockly.FieldMatrixTemplate.prototype.mycustomMatrix_=function(a){0==a.button&&this.setValue(this.mymatrix3_)};
Blockly.FieldMatrixTemplate.prototype.fillMatrixNode_=function(a,b,c){a&&a[b]&&c&&a[b].setAttribute("fill",c)};Blockly.FieldMatrixTemplate.prototype.setLEDNode_=function(a,b){0>a||127<a||(a=this.matrix_.substr(0,a)+b+this.matrix_.substr(a+1),this.setValue(a))};Blockly.FieldMatrixTemplate.prototype.fillLEDNode_=function(a){0>a||127<a||this.setLEDNode_(a,"1")};Blockly.FieldMatrixTemplate.prototype.clearLEDNode_=function(a){0>a||127<a||this.setLEDNode_(a,"0")};
Blockly.FieldMatrixTemplate.prototype.toggleLEDNode_=function(a){0>a||127<a||("0"===this.matrix_.charAt(a)?this.setLEDNode_(a,"1"):this.setLEDNode_(a,"0"))};
Blockly.FieldMatrixTemplate.prototype.onMouseDown=function(a){this.matrixMoveWrapper_=Blockly.bindEvent_(document.body,"mousemove",this,this.onMouseMove);this.matrixReleaseWrapper_=Blockly.bindEvent_(document.body,"mouseup",this,this.onMouseUp);a=this.checkForLED_(a);-1<a?("0"===this.matrix_.charAt(a)?this.paintStyle_="fill":this.paintStyle_="clear",this.toggleLEDNode_(a),this.updateMatrix_()):this.paintStyle_=null};
Blockly.FieldMatrixTemplate.prototype.onMouseUp=function(){Blockly.unbindEvent_(this.matrixMoveWrapper_);Blockly.unbindEvent_(this.matrixReleaseWrapper_);this.paintStyle_=null};Blockly.FieldMatrixTemplate.prototype.onMouseMove=function(a){a.preventDefault();this.paintStyle_&&(a=this.checkForLED_(a),0>a||("clear"===this.paintStyle_?this.clearLEDNode_(a):"fill"===this.paintStyle_&&this.fillLEDNode_(a)))};Blockly.FieldMatrixTemplate.prototype.checkForLED_=function(a){};
Blockly.FieldMatrixTemplate.prototype.dispose_=function(){var a=this;return function(){Blockly.FieldMatrixTemplate.superClass_.dispose_.call(a)();a.mouseDownWrapper_&&Blockly.unbindEvent_(a.mouseDownWrapper_);a.matrixTouchWrapper_&&Blockly.unbindEvent_(a.matrixTouchWrapper_);a.matrixReleaseWrapper_&&Blockly.unbindEvent_(a.matrixReleaseWrapper_);a.matrixMoveWrapper_&&Blockly.unbindEvent_(a.matrixMoveWrapper_);a.clearButtonWrapper_&&Blockly.unbindEvent_(a.clearButtonWrapper_);a.fillButtonWrapper_&&
Blockly.unbindEvent_(a.fillButtonWrapper_);a.mycustomButtonWrapper_&&Blockly.unbindEvent_(a.mycustomMatrix_);a.mytemplatematrix1ButtonWrapper_&&Blockly.unbindEvent_(a.mytemplatematrix1ButtonWrapper_)}};Blockly.Field.register("field_custommatrixjr",Blockly.FieldMatrixTemplate);var CLOSURE_DEFINES={"goog.DEBUG":!1};Blockly.mainWorkspace=null;Blockly.selected=null;Blockly.draggingConnections_=[];Blockly.clipboardXml_=null;Blockly.clipboardSource_=null;Blockly.cache3dSupported_=null;Blockly.hueToRgb=function(a){return goog.color.hsvToHex(a,Blockly.HSV_SATURATION,255*Blockly.HSV_VALUE)};Blockly.svgSize=function(a){return{width:a.cachedWidth_,height:a.cachedHeight_}};Blockly.resizeSvgContents=function(a){a.resizeContents()};
Blockly.svgResize=function(a){for(;a.options.parentWorkspace;)a=a.options.parentWorkspace;var b=a.getParentSvg(),c=b.parentNode;if(c){var d=c.offsetWidth;c=c.offsetHeight;b.cachedWidth_!=d&&(b.setAttribute("width",d+"px"),b.cachedWidth_=d);b.cachedHeight_!=c&&(b.setAttribute("height",c+"px"),b.cachedHeight_=c);a.resize()}};
Blockly.onKeyDown_=function(a){if(!(Blockly.mainWorkspace.options.readOnly||Blockly.utils.isTargetInput(a)||Blockly.mainWorkspace.rendered&&!Blockly.mainWorkspace.isVisible())){var b=!1;if(27==a.keyCode)Blockly.hideChaff(),Blockly.DropDownDiv.hide();else if(8==a.keyCode||46==a.keyCode){a.preventDefault();if(Blockly.mainWorkspace.isDragging())return;Blockly.selected&&Blockly.selected.isDeletable()&&(b=!0)}else if(a.altKey||a.ctrlKey||a.metaKey){if(Blockly.mainWorkspace.isDragging())return;Blockly.selected&&
Blockly.selected.isDeletable()&&Blockly.selected.isMovable()&&(67==a.keyCode?(Blockly.hideChaff(),Blockly.copy_(Blockly.selected)):88!=a.keyCode||Blockly.selected.workspace.isFlyout||(Blockly.copy_(Blockly.selected),b=!0));86==a.keyCode?Blockly.clipboardXml_&&(Blockly.Events.setGroup(!0),a=Blockly.clipboardSource_,a.isFlyout&&(a=a.targetWorkspace),a.paste(Blockly.clipboardXml_),Blockly.Events.setGroup(!1)):90==a.keyCode&&(Blockly.hideChaff(),Blockly.mainWorkspace.undo(a.shiftKey))}b&&!Blockly.selected.workspace.isFlyout&&
(Blockly.Events.setGroup(!0),Blockly.hideChaff(),Blockly.selected.dispose(!0,!0),Blockly.Events.setGroup(!1))}};Blockly.copy_=function(a){if(a.isComment)var b=a.toXmlWithXY();else{b=Blockly.Xml.blockToDom(a);var c=a.getRelativeToSurfaceXY();b.setAttribute("x",a.RTL?-c.x:c.x);b.setAttribute("y",c.y)}Blockly.clipboardXml_=b;Blockly.clipboardSource_=a.workspace};
Blockly.duplicate_=function(a){var b=Blockly.clipboardXml_,c=Blockly.clipboardSource_;Blockly.copy_(a);a.workspace.paste(Blockly.clipboardXml_);Blockly.clipboardXml_=b;Blockly.clipboardSource_=c};Blockly.onContextMenu_=function(a){Blockly.utils.isTargetInput(a)||a.preventDefault()};Blockly.hideChaff=function(a){Blockly.hideChaffInternal_(a);Blockly.WidgetDiv.hide(!0)};Blockly.hideChaffOnResize=function(a){Blockly.hideChaffInternal_(a);Blockly.WidgetDiv.repositionForWindowResize()};
Blockly.hideChaffInternal_=function(a){Blockly.Tooltip.hide();Blockly.DropDownDiv.hideWithoutAnimation();a||(a=Blockly.getMainWorkspace(),a.toolbox_&&a.toolbox_.flyout_&&a.toolbox_.flyout_.autoClose&&a.toolbox_.clearSelection())};Blockly.getMainWorkspace=function(){return Blockly.mainWorkspace};Blockly.alert=function(a,b){window.alert(a);b&&b()};Blockly.confirm=function(a,b){b(window.confirm(a))};Blockly.prompt=function(a,b,c,d,e){c(window.prompt(a,b))};
Blockly.statusButtonCallback=function(a){window.alert("status button was pressed for "+a)};Blockly.refreshStatusButtons=function(a){a=a.getFlyout().buttons_;for(var b=0;b<a.length;b++)a[b]instanceof Blockly.FlyoutExtensionCategoryHeader&&a[b].refreshStatus()};Blockly.jsonInitFactory_=function(a){return function(){this.jsonInit(a)}};
Blockly.defineBlocksWithJsonArray=function(a){for(var b=0;b<a.length;b++){var c=a[b];if(c){var d=c.type;null==d||""===d?console.warn("Block definition #"+b+" in JSON array is missing a type attribute. Skipping."):(Blockly.Blocks[d]&&console.warn("Block definition #"+b+' in JSON array overwrites prior definition of "'+d+'".'),Blockly.Blocks[d]={init:Blockly.jsonInitFactory_(c)})}else console.warn("Block definition #"+b+" in JSON array is "+c+". Skipping.")}};
Blockly.bindEventWithChecks_=function(a,b,c,d,e,f){var g=!1,h=function(a){var b=!e;a=Blockly.Touch.splitEventByTouches(a);for(var f=0,h;h=a[f];f++)if(!b||Blockly.Touch.shouldHandleEvent(h))Blockly.Touch.setClientFromTouch(h),c?d.call(c,h):d(h),g=!0};a.addEventListener(b,h,!1);var k=[[a,b,h]];if(b in Blockly.Touch.TOUCH_MAP)for(var l=function(a){h(a);var b=!f;g&&b&&a.preventDefault()},m=0,n;n=Blockly.Touch.TOUCH_MAP[b][m];m++)a.addEventListener(n,l,!1),k.push([a,n,l]);return k};
Blockly.bindEvent_=function(a,b,c,d){var e=function(a){c?d.call(c,a):d(a)};a.addEventListener(b,e,!1);var f=[[a,b,e]];if(b in Blockly.Touch.TOUCH_MAP)for(var g=function(a){if(1==a.changedTouches.length){var b=a.changedTouches[0];a.clientX=b.clientX;a.clientY=b.clientY}e(a);a.preventDefault()},h=0,k;k=Blockly.Touch.TOUCH_MAP[b][h];h++)a.addEventListener(k,g,!1),f.push([a,k,g]);return f};Blockly.unbindEvent_=function(a){for(;a.length;){var b=a.pop(),c=b[2];b[0].removeEventListener(b[1],c,!1)}return c};
Blockly.isNumber=function(a){return!!a.match(/^\s*-?\d+(\.\d+)?\s*$/)};goog.global.console||(goog.global.console={log:function(){},warn:function(){}});goog.global.Blockly||(goog.global.Blockly={});goog.global.Blockly.getMainWorkspace=Blockly.getMainWorkspace;


/*** EXPORTS FROM exports-loader ***/
exports["Blockly"] = (Blockly);
exports["goog"] = (goog);
}.call(window));