const GAME_DESTROY_JR = 'scratch-gui/GAME_DESTROY_JR';
const GAME_DESTROY_NORMAL = 'scratch-gui/GAME_DESTROY_NORMAL';


const initialState = true;

const reducer = function (state, action) {
    

    if (typeof state === 'undefined') state = initialState;
    switch (action.type) {
    case GAME_DESTROY_JR:
        return true;
    case GAME_DESTROY_NORMAL:
        return false;
    default:
        return state;
    }
};

const setDestroyJr = () => ({
    type: GAME_DESTROY_JR
});

const setDestroyNormal = () => ({
    type: GAME_DESTROY_NORMAL
});



export {
    reducer as default,
    initialState as DestroyInitialState,
    setDestroyJr,
    setDestroyNormal
};
