const useGameSelection = (selectedGame) => {
    switch (selectedGame) {
        case "water-map":
            return {
                set: "water-set",
                map: "water-map",
                scale: 0.4,
                coins: [
                    { x: 4, y: 7 },
                    { x: 7, y: 7 },
                    { x: 5, y: 1 },
                ],
                start: { x: 0, y: 7 },
                facing: "down-right",
                finish: { x: 7, y: 0 },
            };
        case "desert-map":
            return {
                set: "desert-set",
                map: "desert-map",
                scale: 0.4,
                coins: [
                    { x: 4, y: 7 },
                    { x: 7, y: 7 },
                    { x: 5, y: 1 },
                ],
                start: { x: 0, y: 7 },
                facing: "down-right",
            };
        case "oyun_alanı":
            return {
                set: "kids-set",
                map: "oyun_alanı",
                scale: 0.38,
                coins: [],
                start: { x: 4, y: 7 },
                facing: "up-right",
                finish: { x: 4, y: 0 },
                teleportCoords: {
                    enter: { x: 6, y: 6 },
                    exit: { x: 2, y: 4 },
                },
            };
        case "first-map":
            return {
                set: "first-set",
                map: "first-map",
                scale: 1.6,
                coins: [{ x: 2, y: 7 }],
                start: { x: 0, y: 7 },
                facing: "down-right",
            };
        
        case "kids_hayvanlar_1":
            return {
                set: "kids-set",
                map: "kids_hayvanlar_1",
                scale: 0.4,
                coins: [],
                start: { x: 1, y: 5 },
                facing: "down-right",
                finish: { x: 1, y: 1 },
            };

        case "kids_hayvanlar_2":
            return {
                set: "kids-set",
                map: "kids_hayvanlar_2",
                scale: 0.4,
                coins: [],
                start: { x: 4, y: 6 },
                facing: "up-right",
                finish: { x: 2, y: 0 },
            };
        case "kids_hayvanlar_3":
            return {
                set: "kids-set",
                map: "kids_hayvanlar_3",
                scale: 0.4,
                coins: [],
                start: { x: 1, y: 6 },
                facing: "up-right",
                finish: { x: 6, y: 6 },
            };
        case "kids_hayvanlar_4":
            return {
                set: "kids-set",
                map: "kids_hayvanlar_4",
                scale: 0.4,
                coins: [],
                start: { x: 6, y: 6 },
                facing: "up-left",
                finish: { x: 6, y: 1 },
            };
        case "kids_hayvanlar_5":
            return {
                set: "kids-set",
                map: "kids_hayvanlar_5",
                scale: 0.4,
                coins: [],
                start: { x: 6, y: 0 },
                facing: "down-left",
                finish: { x: 2, y: 4 },
            };
        case "kids_hayvanlar_6":
            return {
                set: "kids-set",
                map: "kids_hayvanlar_6",
                scale: 0.4,
                coins: [],
                start: { x: 1, y: 6 },
                facing: "up-right",
                finish: { x: 6, y: 1 },
            };
        case "kids_hayvanlar_7":
            return {
                set: "kids-set",
                map: "kids_hayvanlar_7",
                scale: 0.4,
                coins: [],
                start: { x: 0, y: 1 },
                facing: "down-right",
                finish: { x: 6, y: 5 },
            };
        case "kids_hayvanlar_8":
            return {
                set: "kids-set",
                map: "kids_hayvanlar_8",
                scale: 0.4,
                coins: [],
                start: { x: 0, y: 0 },
                facing: "down-right",
                finish: { x: 6, y: 6 },
            };
        case "kids_hayvanlar_9":
            return {
                set: "kids-set",
                map: "kids_hayvanlar_9",
                scale: 0.4,
                coins: [],
                start: { x: 6, y: 6 },
                facing: "up-left",
                finish: { x: 1, y: 1 },
            };
        case "kids_hayvanlar_10":
            return {
                set: "kids-set",
                map: "kids_hayvanlar_10",
                scale: 0.4,
                coins: [],
                start: { x: 6, y: 1 },
                facing: "down-left",
                finish: { x: 1, y: 1 },
            };
        case "kids_renk_1":
            return {
                set: "kids-set",
                map: "kids_renk_1",
                scale: 0.4,
                coins: [],
                start: { x: 2, y: 3 },
                facing: "down-right",
                finish: { x: 4, y: 3 },
            };
        case "kids_renk_2":
            return {
                set: "kids-set",
                map: "kids_renk_2",
                scale: 0.4,
                coins: [],
                start: { x: 2, y: 3 },
                facing: "down-right",
                finish: { x: 5, y: 3 },
            };
        case "kids_renk_3":
            return {
                set: "kids-set",
                map: "kids_renk_3",
                scale: 0.4,
                coins: [],
                start: { x: 2, y: 3 },
                facing: "down-right",
                finish: { x: 5, y: 4 },
            };
        case "kids_renk_4":
            return {
                set: "kids-set",
                map: "kids_renk_4",
                scale: 0.4,
                coins: [],
                start: { x: 4, y: 5 },
                facing: "up-right",
                finish: { x: 2, y: 3 },
            };
        case "kids_renk_5":
            return {
                set: "kids-set",
                map: "kids_renk_5",
                scale: 0.4,
                coins: [],
                start: { x: 3, y: 6 },
                facing: "up-right",
                finish: { x: 6, y: 2 },
            };
        case "kids_sekil_1":
            return {
                set: "kids-set",
                map: "kids_sekil_1",
                scale: 0.4,
                coins: [],
                start: { x: 6, y: 4 },
                facing: "up-left",
                finish: { x: 1, y: 4 },
            };
        case "kids_sekil_2":
            return {
                set: "kids-set",
                map: "kids_sekil_2",
                scale: 0.4,
                coins: [],
                start: { x: 4, y: 6 },
                facing: "up-right",
                finish: { x: 4, y: 0 },
            };
        case "kids_sekil_3":
            return {
                set: "kids-set",
                map: "kids_sekil_3",
                scale: 0.4,
                coins: [],
                start: { x: 1, y: 4 },
                facing: "down-right",
                finish: { x: 6, y: 3 },
            };
        case "kids_sekil_4":
            return {
                set: "kids-set",
                map: "kids_sekil_4",
                scale: 0.4,
                coins: [],
                start: { x: 5, y: 0 },
                facing: "up-left",
                finish: { x: 0, y: 5 },
            };
        case "kids_sekil_5":
            return {
                set: "kids-set",
                map: "kids_sekil_5",
                scale: 0.4,
                coins: [],
                start: { x: 3, y: 5 },
                facing: "up-right",
                finish: { x: 4, y: 5 },
            };
        case "kids_uzay_1":
            return {
                set: "kids-set",
                map: "kids_uzay_1",
                scale: 0.4,
                coins: [],
                start: { x: 0, y: 0 },
                facing: "down-left",
                finish: { x: 0, y: 6 },
            };
        case "kids_uzay_2":
            return {
                set: "kids-set",
                map: "kids_uzay_2",
                scale: 0.4,
                coins: [],
                start: { x: 6, y: 6 },
                facing: "up-right",
                finish: { x: 3, y: 3 },
            };
        case "kids_uzay_3":
            return {
                set: "kids-set",
                map: "kids_uzay_3",
                scale: 0.4,
                coins: [],
                start: { x: 6, y: 0 },
                facing: "up-left",
                finish: { x: 6, y: 6 },
            };
        case "kids_uzay_4":
            return {
                set: "kids-set",
                map: "kids_uzay_4",
                scale: 0.4,
                coins: [],
                start: { x: 0, y: 1 },
                facing: "down-right",
                finish: { x: 6, y: 1 },
            };
        case "kids_uzay_5":
            return {
                set: "kids-set",
                map: "kids_uzay_5",
                scale: 0.4,
                coins: [],
                start: { x: 0, y: 5 },
                facing: "up-right",
                finish: { x: 7, y: 2 },
            };
        case "ileri_portal_1":
            return {
                set: "kids-set",
                map: "ileri_portal_1",
                scale: 0.38,
                coins: [],
                start: { x: 1, y: 2 },
                facing: "down-right",
                finish: { x: 5, y: 6 },
                teleportCoords: [{
                    enter: { x: 4, y: 2 },
                    exit: { x: 1, y: 6 },
                }]
            };
        case "ileri_portal_2":
            return {
                set: "kids-set",
                map: "ileri_portal_2",
                scale: 0.38,
                coins: [],
                start: { x: 1, y: 1 },
                facing: "down-left",
                finish: { x: 7, y: 4 },
                teleportCoords: [{
                    enter: { x: 1, y: 5 },
                    exit: { x: 3, y: 7 },
                }]
            };
        case "ileri_portal_3":
            return {
                set: "kids-set",
                map: "ileri_portal_3",
                scale: 0.38,
                coins: [],
                start: { x: 0, y: 5 },
                facing: "up-right",
                finish: { x: 5, y: 4 },
                teleportCoords: [{
                    enter: { x: 0, y: 1 },
                    exit: { x: 2, y: 5 },
                }]
            };
        case "ileri_portal_4":
            return {
                set: "kids-set",
                map: "ileri_portal_4",
                scale: 0.38,
                coins: [],
                start: { x: 7, y: 3 },
                facing: "up-left",
                finish: { x: 2, y: 2 },
                teleportCoords: [{
                    enter: { x: 4, y: 2 },
                    exit: { x: 3, y: 7 },
                }]
            };
        case "ileri_portal_5":
            return {
                set: "kids-set",
                map: "ileri_portal_5",
                scale: 0.38,
                coins: [],
                start: { x: 0, y: 0 },
                facing: "down-right",
                finish: { x: 3, y: 5 },
                teleportCoords: [{
                    enter: { x: 3, y: 3 },
                    exit: { x: 0, y: 7 },
                }]
            };
        case "ileri_portal_6":
            return {
                set: "kids-set",
                map: "ileri_portal_6",
                scale: 0.38,
                coins: [],
                start: { x: 0, y: 2 },
                facing: "down-right",
                finish: { x: 0, y: 6 },
                teleportCoords: [
                    {
                    enter: { x: 7, y: 2 },
                    exit: { x: 0, y: 4 },
                    },
                    {
                    enter: { x: 7, y: 4 },
                    exit: { x: 7, y: 6 },
                    }
                ]
            };
        case "ileri_portal_7":
            return {
                set: "kids-set",
                map: "ileri_portal_7",
                scale: 0.38,
                coins: [],
                start: { x: 6, y: 7 },
                facing: "up-right",
                finish: { x: 1, y: 0 },
                teleportCoords: [
                    {
                    enter: { x: 3, y: 4 },
                    exit: { x: 6, y: 2 },
                    },
                    {
                    enter: { x: 3, y: 0 },
                    exit: { x: 3, y: 6 },
                    }
                ]
            };
        case "ileri_portal_8":
            return {
                set: "kids-set",
                map: "ileri_portal_8",
                scale: 0.38,
                coins: [],
                start: { x: 6, y: 1 },
                facing: "up-left",
                finish: { x: 5, y: 3 },
                teleportCoords: [
                    {
                    enter: { x: 1, y: 1 },
                    exit: { x: 3, y: 3 },
                    },
                    {
                    enter: { x: 1, y: 6 },
                    exit: { x: 7, y: 6 },
                    }
                ]
            };
        case "ileri_portal_9":
            return {
                set: "kids-set",
                map: "ileri_portal_9",
                scale: 0.38,
                coins: [],
                start: { x: 7, y: 7 },
                facing: "up-left",
                finish: { x: 0, y: 7 },
                teleportCoords: [
                    {
                    enter: { x: 7, y: 1 },
                    exit: { x: 4, y: 1 },
                    },
                    {
                    enter: { x: 3, y: 7 },
                    exit: { x: 0, y: 0 },
                    }
                ]
            };
        case "ileri_portal_10":
            return {
                set: "kids-set",
                map: "ileri_portal_10",
                scale: 0.38,
                coins: [],
                start: { x: 0, y: 2 },
                facing: "down-right",
                finish: { x: 7, y: 2 },
                teleportCoords: [
                    {
                    enter: { x: 2, y: 0 },
                    exit: { x: 1, y: 4 },
                    },
                    {
                    enter: { x: 5, y: 0 },
                    exit: { x: 2, y: 6 },
                    }
                ]
            };
        case "ileri_muzik_1":
            return {
                set: "kids-set",
                map: "ileri_muzik_1",
                scale: 0.38,
                coins: [],
                start: { x: 0, y: 5 },
                facing: "down-right",
                finish: { x: 7, y: 5 },
                teleportCoords: []
            };
        case "ileri_muzik_2":
            return {
                set: "kids-set",
                map: "ileri_muzik_2",
                scale: 0.38,
                coins: [],
                start: { x: 0, y: 5 },
                facing: "down-right",
                finish: { x: 7, y: 5 },
                teleportCoords: []
            };
        case "ileri_muzik_3":
            return {
                set: "kids-set",
                map: "ileri_muzik_3",
                scale: 0.38,
                coins: [],
                start: { x: 4, y: 7 },
                facing: "up-right",
                finish: { x: 4, y: 0 },
                teleportCoords: []
            };
        case "ileri_muzik_4":
            return {
                set: "kids-set",
                map: "ileri_muzik_4",
                scale: 0.38,
                coins: [],
                start: { x: 6, y: 0 },
                facing: "up-left",
                finish: { x: 6, y: 6 },
                teleportCoords: []
            };
        case "ileri_muzik_5":
            return {
                set: "kids-set",
                map: "ileri_muzik_5",
                scale: 0.38,
                coins: [],
                start: { x: 0, y: 0 },
                facing: "down-right",
                finish: { x: 7, y: 7 },
                teleportCoords: []
            };
        case "ileri_engel_1":
            return {
                set: "kids-set",
                map: "ileri_engel_1",
                scale: 0.38,
                coins: [],
                start: { x: 1, y: 7 },
                facing: "up-right",
                finish: { x: 7, y: 1 },
                teleportCoords: []
            };
        case "ileri_engel_2":
            return {
                set: "kids-set",
                map: "ileri_engel_2",
                scale: 0.38,
                coins: [],
                start: { x: 1, y: 1 },
                facing: "down-left",
                finish: { x: 6, y: 1 },
                teleportCoords: []
            };
        case "ileri_engel_3":
            return {
                set: "kids-set",
                map: "ileri_engel_3",
                scale: 0.38,
                coins: [],
                start: { x: 1, y: 3 },
                facing: "up-right",
                finish: { x: 6, y: 1 },
                teleportCoords: [
                    {
                        enter: { x: 2, y: 1 },
                        exit: { x: 4, y: 5 },
                        },
                        {
                        enter: { x: 3, y: 7 },
                        exit: { x: 5, y: 3 },
                        }
                ]
            };
        case "ileri_engel_4":
            return {
                set: "kids-set",
                map: "ileri_engel_4",
                scale: 0.38,
                coins: [],
                start: { x: 1, y: 7 },
                facing: "up-right",
                finish: { x: 4, y: 4 },
                teleportCoords: []
            };
        case "ileri_engel_5":
            return {
                set: "kids-set",
                map: "ileri_engel_5",
                scale: 0.38,
                coins: [],
                start: { x: 7, y: 1 },
                facing: "up-left",
                finish: { x: 7, y: 5 },
                teleportCoords: []
            };

        default:
            return {
                set: "kids-set",
                map: "oyun_alanı",
                scale: 0.38,
                coins: [],
                start: { x: 4, y: 7 },
                facing: "up-right",
                finish: { x: 4, y: 0 },
                teleportCoords: [{
                    enter: { x: 6, y: 6 },
                    exit: { x: 2, y: 4 },
                }]
            };
    }
};

export default useGameSelection;
