const blockSeparator = '<sep gap="36"/>'; // At default scale, about 28px
const categorySeparator = '<sep gap="36"/>';


export const control = function (isInitialSetup, isStage) {
    return `
    <category name="%{BKY_CATEGORY_CONTROL}" id="control" colour="#FFAB19" secondaryColour="#CF8B17">
        <block type="control_wait">
            <value name="DURATION">
                <shadow type="math_positive_number">
                    <field name="NUM">1</field>
                </shadow>
            </value>
        </block>
        ${blockSeparator}
        <block type="control_repeat">
            <value name="TIMES">
                <shadow type="math_whole_number">
                    <field name="NUM">10</field>
                </shadow>
            </value>
        </block>
        <block id="forever" type="control_forever"/>
     
        ${blockSeparator}
        <block type="control_stop"/>
        ${blockSeparator}
       
        ${categorySeparator}
    </category>
    `;
};