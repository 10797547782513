import React from "react";
import classNames from "classnames";
import styles from "../stage-modal.css";
import { FormattedMessage } from "react-intl";


const Kids = ({ setSelected,setKidsSubMenu }) => {
    console.log("Kids", );

    return (
        <div>
            
        <div className={classNames(styles.container, styles.menuContainer)}>
            {/* <div
                className={classNames(styles.selectBtn, styles.menuItemTriple)}
                onClick={() => {
                    setKidsSubMenu(1);
                }}
            >
                <p className={classNames(styles.selectBtnText)}>
                    Hareket
                </p>
            </div> */}
            <div
                className={classNames(styles.selectBtn, styles.menuItemDouble)}
                onClick={() => {
                    setKidsSubMenu(1);
                }}
            >
                <p className={classNames(styles.selectBtnText)}>
                <FormattedMessage
                    defaultMessage="Colors"
                    description="Colors menu title"
                    id="gui.game.modal.kidsColors"
                />
                </p>
                <div className={classNames(styles.colorBox)}>
                    <span className={classNames(styles.colorBoxText)}>5</span>
                </div>
            </div>
            <div
                className={classNames(styles.selectBtn, styles.menuItemDouble)}
                onClick={() => {
                    setKidsSubMenu(2);
                }}
            >
                <p className={classNames(styles.selectBtnText)}>
                <FormattedMessage
                                    defaultMessage="Shapes"
                                    description="Shapes menu title"
                                    id="gui.game.modal.kidsShapes"
                                />
                </p>
                <div className={classNames(styles.colorBox)}>
                    <span className={classNames(styles.colorBoxText)}>5</span>
                </div>
            </div>
            <div
                className={classNames(styles.selectBtn, styles.menuItemDouble)}
                onClick={() => {
                    setKidsSubMenu(3);
                }}
            >
                <p className={classNames(styles.selectBtnText)}>
                <FormattedMessage
                                    defaultMessage="Animals"
                                    description="Animals menu title"
                                    id="gui.game.modal.kidsAnimals"
                                />
                </p>
                <div className={classNames(styles.colorBox)}>
                    <span className={classNames(styles.colorBoxText)}>10</span>
                </div>
            </div>
            <div
                className={classNames(styles.selectBtn, styles.menuItemDouble)}
                onClick={() => {
                    setKidsSubMenu(4);
                }}
            >
                <p className={classNames(styles.selectBtnText)}>
                <FormattedMessage
                                    defaultMessage="Space"
                                    description="Space menu title"
                                    id="gui.game.modal.kidsSpace"
                                />
                </p>
                <div className={classNames(styles.colorBox)}>
                    <span className={classNames(styles.colorBoxText)}>5</span>
                </div>
            </div>
            {/* <p className={styles.title}>Kids</p> */}
        </div>
        
        
        </div>
    );
};

export default Kids;
