const { set } = require("core-js/core/dict");

const SET_USER_DATA = 'scratch-gui/user-data/setUserData';

const initialState = {
    fullname: '',
    token: '',
    id: ''
}

const reducer = function (state, action) {
    
    if (typeof state === 'undefined') state = initialState;
    
    switch (action.type) {
        case SET_USER_DATA:
            return Object.assign({}, state, {
                fullname: action.fullname,
                token: action.token,
                id: action.id
            });
        default:
            return state;
    }
};

const setUserData = function (data) {
    console.log("setUserData", data)
    return {
        type: SET_USER_DATA,
        fullname: data.fullName,
        token: data.token,
        id: data.id
    };
}

export {
    reducer as default,
    initialState as userDataInitialState,
    setUserData
};


