import Modal from "react-modal";
import React, { useEffect } from "react";

import BackIcon from "./img/circle-left-solid.svg";
import classNames from "classnames";
import styles from "./stage-modal.css";
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { useState } from "react";
import MainPage from "./modal-pages/MainPage.jsx";
import Ilerleme from "./modal-pages/Ilerleme.jsx";
import Kids from "./modal-pages/Kids.jsx";
import { useCallback } from "react";
import KidsSubmenu from "./modal-pages/KidsSubmenu.jsx";

import mainBg from "./img/anasayfa.png";
import kidsBg from "./img/KidsBg.png";
import kidsHayvanlarBg from "./img/KidsHayvanlarBg.png";
import kidsRenklerBg from "./img/KidsRenklerBg.png";
import kidsSekillerBg from "./img/KidsSekillerBg.png";
import kidsUzayBg from "./img/KidsUzayBg.png";
import nextBtn from "./img/next.svg";
import prevBtn from "./img/prev.svg";

import { FormattedMessage } from "react-intl";


Modal.setAppElement("body");

const getBgImage = (subMenuShow, kidsSubMenu) => {
    switch (subMenuShow) {
        case "kids":
            switch (kidsSubMenu) {
                case 1:
                    return kidsRenklerBg;
                case 2:
                    return kidsSekillerBg;
                case 3:
                    return kidsHayvanlarBg;
                case 4:
                    return kidsUzayBg;
                default:
                    return kidsBg;
            }
        default:
            return mainBg;
    }
};

function StageModalComponent({
    changeStage,
    modalIsOpen,
    setIsOpen,
    setMapModalTrigger,
    activeLevel,
    completedLevels,
    levelCounts,
    isjr,
}) {
    // function afterOpenModal() {
    //     // references are now sync'd and can be accessed.
    //     subtitle.style.color = "#f00";
    // }
    const [selected, setSelected] = useState(0);
    const [subMenuShow, setSubMenuShow] = useState(null);
    const [portalSayfa, setPortalSayfa] = useState(0);
    const [muzikSayfa, setMuzikSayfa] = useState(0);
    const [engelSayfa, setEngelSayfa] = useState(0);
    const [ilerlemeSubMenu, setIlerlemeSubMenu] = useState(0);
    const [kidsSubMenu, setKidsSubMenu] = useState(0);
    const [kidsRenkSayfa, setKidsRenkSayfa] = useState(0);
    const [kidsSekilSayfa, setKidsSekilSayfa] = useState(0);
    const [kidsHayvanlarSayfa, setKidsHayvanlarSayfa] = useState(0);
    const [kidsUzaySayfa, setKidsUzaySayfa] = useState(0);
    // const [kidsStages, setKidsStages] = useState([
    //     {
    //         page:0,
    //         completedLevels: completedLevels["kids_renk"],
    //         category:"kids_renk"
    //     },
    //     {
    //         page:0,
    //         completedLevels: completedLevels["kids_sekil"],
    //         category:"kids_sekil"
    //     },
    //     {
    //         page:0,
    //         completedLevels: completedLevels["kids_hayvanlar"],
    //         category:"kids_hayvanlar"
    //     },
    //     {
    //         page:0,
    //         completedLevels: completedLevels["kids_uzay"],
    //         category:"kids_uzay"
    //     },
    // ])

    //     kidsSayfa={kidsRenkSayfa}
    // completedLevels={completedLevels["kids_renk"]}
    //                                     category="kids_renk"


    const isActive = (level, category) => {
        console.log("level", level)
        console.log("category", category, completedLevels)
        // console.log("isactive", completedLevels[category].includes((level - 1).toString()))
        //! (category !== "kids_renk" || category !== "ilerleme_portal") // iki farklı kategoride 1. map açılacaksa buradaki ve aşağıdaki kontroller uygulanmalı "category undefined çözümü"
        if (level === 1 && category !== "kids_renk") {
            const prevCategory = levelCounts[levelCounts.findIndex( mainCtg => mainCtg.category === category) - 1];
            console.log("prevCategory", prevCategory)
            
            // return completedLevels[prevCategory.category].includes((prevCategory.count).toString());
            return true; // for test first maps
        }
        // const categoryLevelCount = levelCounts.find((levelCount) => levelCount.category === category)?.count;
        // if (level === categoryLevelCount) {
            
            //! if (category !== "ilerleme_portal") { // iki farklı kategoride 1. map açılacaksa buradaki ve yukarıdaki kontroller uygulanmalı "category undefined çözümü"
            return completedLevels[category]?.includes((level - 1).toString()) ||  level === 1;
        return true; // for test last maps
    }

    const customStyles = {
        content: {
            top: "50%",
            left: "50%",
            right: "auto",
            bottom: "auto",
            marginRight: "-50%",
            transform: "translate(-50%, -50%)",
            backgroundImage: `url(${getBgImage(subMenuShow, kidsSubMenu)})`,
            backgroundSize: "cover",
            backgroundRepeat: "no-repeat",
            backgroundPosition: "center",
            borderRadius: 20,
            backgroundColor: "transparent",
            border: "none",
            boxSizing: "border-box",
            width: 900,
            height: 700,
            overflow: "hidden",
        },
        overlay: {
            zIndex: 1000,
            position: "fixed",
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            backgroundColor: "rgba(255, 255, 255, 0.75)",
        },
    };

    function closeModal() {
        setIsOpen(false);
        setMapModalTrigger(false);
        // setSubMenuShow(null);
        setIlerlemeSubMenu(0);
        setPortalSayfa(0);
        setMuzikSayfa(0);
        setEngelSayfa(0);
        setKidsSubMenu(0);
        if (activeLevel?.split("_")[0] === "kids") {
            setSubMenuShow("kids");
        } else if (activeLevel?.split("_")[0] === "ilerleme") {
            setSubMenuShow("ilerleme");
        } else {
            setSubMenuShow(null);
        }
        // setSesIsikSayfa(0);
        // setSensorlerSayfa(0);
        // setParkurlarSayfa(0);
        setKidsRenkSayfa(0);
        setKidsSekilSayfa(0);
        setKidsHayvanlarSayfa(0);
        setKidsUzaySayfa(0);
        setSelected(0);
    }

    useEffect(() => {
        if (activeLevel?.split("_")[0] === "kids") {
            setSubMenuShow("kids");
            console.log(activeLevel?.split("_")[1]);
            switch (activeLevel?.split("_")[1]) {
                case "renk":
                    setKidsSubMenu(1);
                    break;
                case "sekil":
                    setKidsSubMenu(2);
                    break;
                case "hayvanlar":
                    setKidsSubMenu(3);
                    break;
                case "uzay":
                    setKidsSubMenu(4);
                    break;
                default:
                    setKidsSubMenu(0);
                    break;
            }
        } else if (activeLevel?.split("_")[0] === "ilerleme") {
            setSubMenuShow("ilerleme");
            switch (activeLevel?.split("_")[1]) {
                case "portal":
                    setIlerlemeSubMenu(1);
                    break;
                case "muzik":
                    setIlerlemeSubMenu(2);
                    break;
                
                default:
                    setIlerlemeSubMenu(0);
                    break;
            }
        } else {
            setSubMenuShow(null);
        }
    }, [activeLevel]);

    const handleSubMenu = (submenu) => {
        setSubMenuShow(submenu);
    };

    const renderSubMenu = useCallback(() => {
        switch (subMenuShow) {
            case "ilerleme":
                return (
                    <Carousel
                        width={850}
                        selectedItem={ilerlemeSubMenu}
                        axis="horizontal"
                        showIndicators={false}
                        showThumbs={false}
                        showStatus={false}
                        labels={{
                            leftArrow: "Geri",
                            rightArrow: "İleri",
                        }}
                        showArrows={false}
                    >
                        <div className={classNames(styles.wrapper)}>
                            <div
                                src={BackIcon}
                                alt="back"
                                className={classNames(styles.backIcon)}
                                onClick={() => {
                                    setSubMenuShow(null);
                                    setIlerlemeSubMenu(0);
                                    setPortalSayfa(0);
                                    setMuzikSayfa(0);
                                    setEngelSayfa(0);
                                    console.log("back");
                                }}
                            />
                            <h2 className={classNames(styles.subTitle)}>
                                İlerleme
                            </h2>
                            <Ilerleme
                                setSelected={setSelected}
                                styles={styles}
                                setIlerlemeSubMenu={setIlerlemeSubMenu}
                            />
                        </div>

                        <div className={classNames(styles.subMenuContainer)}>
                            <div
                                src={BackIcon}
                                alt="back"
                                className={classNames(styles.backIcon)}
                                onClick={() => {
                                    setIlerlemeSubMenu(0);
                                    setPortalSayfa(0);
                                }}
                            />
                            <h2 className={classNames(styles.subTitle)}>
                                <FormattedMessage
                                    defaultMessage="Portal"
                                    description="Portal menu title"
                                    id="gui.game.modal.ilerlemePortal"
                                />
                            </h2>
                            <Carousel
                                width={"auto"}
                                selectedItem={portalSayfa}
                                axis="vertical"
                                showThumbs={false}
                                onChange={(e) => {
                                    setPortalSayfa(e);
                                }}
                                showStatus={false}
                                showIndicators={false}
                                labels={{
                                    leftArrow: "Yukarı",
                                    rightArrow: "Aşağı",
                                }}
                                renderArrowPrev={(
                                    onClickHandler,
                                    hasPrev,
                                    label
                                ) =>
                                    hasPrev && (
                                        <div
                                            type="div"
                                            onClick={onClickHandler}
                                            title={label}
                                            className={classNames(
                                                styles.arrowDown,
                                                styles.arrow,
                                                styles.prev
                                            )}
                                        >
                                            <img src={prevBtn} alt="yukarı" />
                                        </div>
                                    )
                                }
                                renderArrowNext={(
                                    onClickHandler,
                                    hasNext,
                                    label
                                ) =>
                                    hasNext && (
                                        <div
                                            type="div"
                                            onClick={onClickHandler}
                                            title={label}
                                            className={classNames(
                                                styles.arrowDown,
                                                styles.arrow,
                                                styles.next
                                            )}
                                        >
                                            <img src={nextBtn} alt="aşağı" />
                                        </div>
                                    )
                                }
                            >
                                {[...Array(3)].map((e, i) => (
                                    <KidsSubmenu
                                        key={i}
                                        kidsSayfa={portalSayfa}
                                        changeStage={changeStage}
                                        closeModal={closeModal}
                                        // completedLevels={
                                        //     completedLevels["ileri_portal"]
                                        // }
                                        category="ileri_portal"
                                        isActive={isActive}
                                    />
                                ))}
                            </Carousel>
                        </div>
                        <div className={classNames(styles.subMenuContainer)}>
                            <div
                                src={BackIcon}
                                alt="back"
                                className={classNames(styles.backIcon)}
                                onClick={() => {
                                    setIlerlemeSubMenu(0);
                                    setMuzikSayfa(0);
                                }}
                            />
                            <h2 className={classNames(styles.subTitle)}>
                                <FormattedMessage
                                    defaultMessage="Müzik"
                                    description="Müzik menu title"
                                    id="gui.game.modal.ilerlemeMuzik"
                                />
                            </h2>
                            <Carousel
                                width={"auto"}
                                selectedItem={muzikSayfa}
                                axis="vertical"
                                showThumbs={false}
                                onChange={(e) => {
                                    setMuzikSayfa(e);
                                }}
                                showStatus={false}
                                showIndicators={false}
                                labels={{
                                    leftArrow: "Yukarı",
                                    rightArrow: "Aşağı",
                                }}
                                renderArrowPrev={(
                                    onClickHandler,
                                    hasPrev,
                                    label
                                ) =>
                                    hasPrev && (
                                        <div
                                            type="div"
                                            onClick={onClickHandler}
                                            title={label}
                                            className={classNames(
                                                styles.arrowDown,
                                                styles.arrow,
                                                styles.prev
                                            )}
                                        >
                                            <img src={prevBtn} alt="yukarı" />
                                        </div>
                                    )
                                }
                                renderArrowNext={(
                                    onClickHandler,
                                    hasNext,
                                    label
                                ) =>
                                    hasNext && (
                                        <div
                                            type="div"
                                            onClick={onClickHandler}
                                            title={label}
                                            className={classNames(
                                                styles.arrowDown,
                                                styles.arrow,
                                                styles.next
                                            )}
                                        >
                                            <img src={nextBtn} alt="aşağı" />
                                        </div>
                                    )
                                }
                            >
                                {[...Array(3)].map((e, i) => (
                                    <KidsSubmenu
                                        key={i}
                                        kidsSayfa={muzikSayfa}
                                        changeStage={changeStage}
                                        closeModal={closeModal}
                                        // completedLevels={
                                        //     completedLevels["ileri_portal"]
                                        // }
                                        category="ileri_muzik"
                                        isActive={isActive}
                                    />
                                ))}
                            </Carousel>
                        </div>
                        <div className={classNames(styles.subMenuContainer)}>
                            <div
                                src={BackIcon}
                                alt="back"
                                className={classNames(styles.backIcon)}
                                onClick={() => {
                                    setIlerlemeSubMenu(0);
                                    setEngelSayfa(0);
                                }}
                            />
                            <h2 className={classNames(styles.subTitle)}>
                                <FormattedMessage
                                    defaultMessage="Engel"
                                    description="Engel menu title"
                                    id="gui.game.modal.ilerlemeEngel"
                                />
                            </h2>
                            <Carousel
                                width={"auto"}
                                selectedItem={engelSayfa}
                                axis="vertical"
                                showThumbs={false}
                                onChange={(e) => {
                                    setEngelSayfa(e);
                                }}
                                showStatus={false}
                                showIndicators={false}
                                labels={{
                                    leftArrow: "Yukarı",
                                    rightArrow: "Aşağı",
                                }}
                                renderArrowPrev={(
                                    onClickHandler,
                                    hasPrev,
                                    label
                                ) =>
                                    hasPrev && (
                                        <div
                                            type="div"
                                            onClick={onClickHandler}
                                            title={label}
                                            className={classNames(
                                                styles.arrowDown,
                                                styles.arrow,
                                                styles.prev
                                            )}
                                        >
                                            <img src={prevBtn} alt="yukarı" />
                                        </div>
                                    )
                                }
                                renderArrowNext={(
                                    onClickHandler,
                                    hasNext,
                                    label
                                ) =>
                                    hasNext && (
                                        <div
                                            type="div"
                                            onClick={onClickHandler}
                                            title={label}
                                            className={classNames(
                                                styles.arrowDown,
                                                styles.arrow,
                                                styles.next
                                            )}
                                        >
                                            <img src={nextBtn} alt="aşağı" />
                                        </div>
                                    )
                                }
                            >
                                {[...Array(3)].map((e, i) => (
                                    <KidsSubmenu
                                        key={i}
                                        kidsSayfa={engelSayfa}
                                        changeStage={changeStage}
                                        closeModal={closeModal}
                                        // completedLevels={
                                        //     completedLevels["ileri_portal"]
                                        // }
                                        category="ileri_engel"
                                        isActive={isActive}
                                    />
                                ))}
                            </Carousel>
                        </div>

{/*                         
                        <div className={classNames(styles.subMenuContainer)}>
                            <div
                                src={BackIcon}
                                alt="back"
                                className={classNames(styles.backIcon)}
                                onClick={() => {
                                    setIlerlemeSubMenu(0);
                                    setPortalSayfa(0);
                                    console.log("back");
                                }}
                            />
                            <h2 className={classNames(styles.subTitle)}>
                                Ses / Işık Gösterileri
                            </h2>
                            <Carousel
                                width={"auto"}
                                selectedItem={portalSayfa}
                                axis="vertical"
                                showThumbs={false}
                                onChange={(e) => {
                                    console.log(e);
                                    setSesIsikSayfa(e);
                                }}
                                labels={{
                                    leftArrow: "Geri",
                                    rightArrow: "İleri",
                                }}
                                showStatus={false}
                                showIndicators={false}
                                renderArrowPrev={(
                                    onClickHandler,
                                    hasPrev,
                                    label
                                ) =>
                                    hasPrev && (
                                        <div
                                            type="div"
                                            onClick={onClickHandler}
                                            title={label}
                                            className={classNames(
                                                styles.arrowDown,
                                                styles.arrow,
                                                styles.prev
                                            )}
                                        >
                                            <img src={prevBtn} alt="Geri" />
                                        </div>
                                    )
                                }
                                renderArrowNext={(
                                    onClickHandler,
                                    hasNext,
                                    label
                                ) =>
                                    hasNext && (
                                        <div
                                            type="div"
                                            onClick={onClickHandler}
                                            title={label}
                                            className={classNames(
                                                styles.arrowDown,
                                                styles.arrow,
                                                styles.next
                                            )}
                                        >
                                            <img src={nextBtn} alt="ileri" />
                                        </div>
                                    )
                                }
                            >
                                <SesIsik sesIsikSayfa={sesIsikSayfa} />
                                <SesIsik sesIsikSayfa={sesIsikSayfa} />
                                <SesIsik sesIsikSayfa={sesIsikSayfa} />
                            </Carousel>
                        </div>
                        <div className={classNames(styles.subMenuContainer)}>
                            <div
                                src={BackIcon}
                                alt="back"
                                className={classNames(styles.backIcon)}
                                onClick={() => {
                                    setIlerlemeSubMenu(0);
                                    setSensorlerSayfa(0);
                                }}
                            />
                            <h2 className={classNames(styles.subTitle)}>
                                Sensörler
                            </h2>
                            <Carousel
                                width={"auto"}
                                selectedItem={portalSayfa}
                                axis="vertical"
                                showThumbs={false}
                                onChange={(e) => {
                                    console.log(e);
                                    setSensorlerSayfa(e);
                                }}
                                labels={{
                                    leftArrow: "Yukarı",
                                    rightArrow: "Aşağı",
                                }}
                                showStatus={false}
                                showIndicators={false}
                                renderArrowPrev={(
                                    onClickHandler,
                                    hasPrev,
                                    label
                                ) =>
                                    hasPrev && (
                                        <div
                                            type="div"
                                            onClick={onClickHandler}
                                            title={label}
                                            className={classNames(
                                                styles.arrowDown,
                                                styles.arrow,
                                                styles.prev
                                            )}
                                        >
                                            <img src={prevBtn} alt="Yukarı" />
                                        </div>
                                    )
                                }
                                renderArrowNext={(
                                    onClickHandler,
                                    hasNext,
                                    label
                                ) =>
                                    hasNext && (
                                        <div
                                            type="div"
                                            onClick={onClickHandler}
                                            title={label}
                                            className={classNames(
                                                styles.arrowDown,
                                                styles.arrow,
                                                styles.next
                                            )}
                                        >
                                            <img src={nextBtn} alt="aşağı" />
                                        </div>
                                    )
                                }
                            >
                                <Sensorler sensorlerSayfa={sensorlerSayfa} />
                                <Sensorler sensorlerSayfa={sensorlerSayfa} />
                                <Sensorler sensorlerSayfa={sensorlerSayfa} />
                            </Carousel>
                        </div>
                        <div className={classNames(styles.subMenuContainer)}>
                            <div
                                src={BackIcon}
                                alt="back"
                                className={classNames(styles.backIcon)}
                                onClick={() => {
                                    setIlerlemeSubMenu(0);
                                    setParkurlarSayfa(0);
                                    console.log("back");
                                }}
                            />
                            <h2 className={classNames(styles.subTitle)}>
                                Parkurlar
                            </h2>
                            <Carousel
                                width={"auto"}
                                selectedItem={portalSayfa}
                                axis="vertical"
                                showThumbs={false}
                                onChange={(e) => {
                                    console.log(e);
                                    setParkurlarSayfa(e);
                                }}
                                labels={{
                                    leftArrow: "Yukarı",
                                    rightArrow: "Aşağı",
                                }}
                                showStatus={false}
                                showIndicators={false}
                                renderArrowPrev={(
                                    onClickHandler,
                                    hasPrev,
                                    label
                                ) =>
                                    hasPrev && (
                                        <div
                                            type="div"
                                            onClick={onClickHandler}
                                            title={label}
                                            className={classNames(
                                                styles.arrowDown,
                                                styles.arrow,
                                                styles.prev
                                            )}
                                        >
                                            <img src={prevBtn} alt="yukarı" />
                                        </div>
                                    )
                                }
                                renderArrowNext={(
                                    onClickHandler,
                                    hasNext,
                                    label
                                ) =>
                                    hasNext && (
                                        <div
                                            type="div"
                                            onClick={onClickHandler}
                                            title={label}
                                            className={classNames(
                                                styles.arrowDown,
                                                styles.arrow,
                                                styles.next
                                            )}
                                        >
                                            <img src={nextBtn} alt="aşağı" />
                                        </div>
                                    )
                                }
                            >
                                <Parkurlar parkurlarSayfa={parkurlarSayfa} />
                                <Parkurlar parkurlarSayfa={parkurlarSayfa} />
                                <Parkurlar parkurlarSayfa={parkurlarSayfa} />
                            </Carousel>
                        </div> */}
                    </Carousel>
                );
            case "kids":
                return (
                    <Carousel
                        width={850}
                        selectedItem={kidsSubMenu}
                        axis="horizontal"
                        showThumbs={false}
                        showStatus={false}
                        showIndicators={false}
                        showArrows={false}
                    >
                        <div className={classNames(styles.wrapper)}>
                            <div className={classNames(styles.subMenuHeader)}>
                                <button
                                    // src={BackIcon}
                                    alt="back"
                                    className={classNames(styles.backIcon)}
                                    onClick={() => {
                                        setSubMenuShow(null);
                                        setKidsSubMenu(0);
                                        setPortalSayfa(0);
                                        console.log("back");
                                    }}
                                />
                                <h2 className={classNames(styles.subTitle)}>
                                    <FormattedMessage
                                        defaultMessage="Kids"
                                        description="Kids menu title"
                                        id="gui.game.modal.kidsBeginner"
                                    />
                                </h2>
                                <div></div>
                            </div>
                            <Kids
                                setSelected={setSelected}
                                styles={styles}
                                setKidsSubMenu={setKidsSubMenu}
                            />
                        </div>

                        <div className={classNames(styles.subMenuContainer)}>
                            <div
                                src={BackIcon}
                                alt="back"
                                className={classNames(styles.backIcon)}
                                onClick={() => {
                                    setKidsSubMenu(0);
                                    setKidsRenkSayfa(0);
                                    console.log("back");
                                }}
                            />
                            <h2 className={classNames(styles.subTitle)}>
                                <FormattedMessage
                                    defaultMessage="Colors"
                                    description="Colors menu title"
                                    id="gui.game.modal.kidsColors"
                                />
                            </h2>
                            <Carousel
                                width={"auto"}
                                selectedItem={kidsRenkSayfa}
                                axis="vertical"
                                showThumbs={false}
                                onChange={(e) => {
                                    console.log(e);
                                    setKidsRenkSayfa(e);
                                }}
                                showStatus={false}
                                showIndicators={false}
                                labels={{
                                    leftArrow: "Yukarı",
                                    rightArrow: "Aşağı",
                                }}
                                renderArrowPrev={(
                                    onClickHandler,
                                    hasPrev,
                                    label
                                ) =>
                                    hasPrev && (
                                        <div
                                            type="div"
                                            onClick={onClickHandler}
                                            title={label}
                                            className={classNames(
                                                styles.arrowDown,
                                                styles.arrow,
                                                styles.prev
                                            )}
                                        >
                                            <img src={prevBtn} alt="yukarı" />
                                        </div>
                                    )
                                }
                                renderArrowNext={(
                                    onClickHandler,
                                    hasNext,
                                    label
                                ) =>
                                    hasNext && (
                                        <div
                                            type="div"
                                            onClick={onClickHandler}
                                            title={label}
                                            className={classNames(
                                                styles.arrowDown,
                                                styles.arrow,
                                                styles.next
                                            )}
                                        >
                                            <img src={nextBtn} alt="aşağı" />
                                        </div>
                                    )
                                }
                            >
                                {[...Array(3)].map((e, i) => (
                                    <KidsSubmenu
                                        key={i}
                                        kidsSayfa={kidsRenkSayfa}
                                        changeStage={changeStage}
                                        closeModal={closeModal}
                                        // completedLevels={
                                        //     completedLevels["kids_renk"]
                                        // }
                                        category="kids_renk"
                                        isActive={isActive}
                                    />
                                    // <KidsRenk
                                    //     key={i}
                                    //     kidsRenkSayfa={kidsRenkSayfa}
                                    //     changeStage={changeStage}
                                    //     closeModal={closeModal}
                                    //     completedLevels={completedLevels["kids_renk"]}
                                    //     category="kids_renk"
                                    //     isActive={isActive}
                                    // />
                                ))}
                            </Carousel>
                        </div>
                        <div className={classNames(styles.subMenuContainer)}>
                            <div
                                src={BackIcon}
                                alt="back"
                                className={classNames(styles.backIcon)}
                                onClick={() => {
                                    setKidsSubMenu(0);
                                    setKidsSekilSayfa(0);
                                    console.log("back");
                                }}
                            />
                            <h2 className={classNames(styles.subTitle)}>
                                <FormattedMessage
                                    defaultMessage="Shapes"
                                    description="Shapes menu title"
                                    id="gui.game.modal.kidsShapes"
                                />
                            </h2>
                            <Carousel
                                width={"auto"}
                                selectedItem={kidsSekilSayfa}
                                axis="vertical"
                                showThumbs={false}
                                onChange={(e) => {
                                    console.log(e);
                                    setKidsSekilSayfa(e);
                                }}
                                labels={{
                                    leftArrow: "Yukarı",
                                    rightArrow: "Aşağı",
                                }}
                                showStatus={false}
                                showIndicators={false}
                                renderArrowPrev={(
                                    onClickHandler,
                                    hasPrev,
                                    label
                                ) =>
                                    hasPrev && (
                                        <div
                                            type="div"
                                            onClick={onClickHandler}
                                            title={label}
                                            className={classNames(
                                                styles.arrowDown,
                                                styles.arrow,
                                                styles.prev
                                            )}
                                        >
                                            <img src={prevBtn} alt="yukarı" />
                                        </div>
                                    )
                                }
                                renderArrowNext={(
                                    onClickHandler,
                                    hasNext,
                                    label
                                ) =>
                                    hasNext && (
                                        <div
                                            type="div"
                                            onClick={onClickHandler}
                                            title={label}
                                            className={classNames(
                                                styles.arrowDown,
                                                styles.arrow,
                                                styles.next
                                            )}
                                        >
                                            <img src={nextBtn} alt="aşağı" />
                                        </div>
                                    )
                                }
                            >
                                {[...Array(3)].map((e, i) => (
                                    <KidsSubmenu
                                        key={i}
                                        kidsSayfa={kidsSekilSayfa}
                                        changeStage={changeStage}
                                        closeModal={closeModal}
                                        // completedLevels={
                                        //     completedLevels["kids_sekil"]
                                        // }
                                        category="kids_sekil"
                                        isActive={isActive}
                                    />
                                ))}
                            </Carousel>
                        </div>
                        <div className={classNames(styles.subMenuContainer)}>
                            <div
                                src={BackIcon}
                                alt="back"
                                className={classNames(styles.backIcon)}
                                onClick={() => {
                                    setKidsSubMenu(0);
                                    setKidsHayvanlarSayfa(0);
                                    console.log("back");
                                }}
                            />
                            <h2 className={classNames(styles.subTitle)}>
                                <FormattedMessage
                                    defaultMessage="Animals"
                                    description="Animals menu title"
                                    id="gui.game.modal.kidsAnimals"
                                />
                            </h2>
                            <Carousel
                                width={"auto"}
                                selectedItem={kidsHayvanlarSayfa}
                                axis="vertical"
                                showThumbs={false}
                                showStatus={false}
                                showIndicators={false}
                                labels={{
                                    leftArrow: "Yukarı",
                                    rightArrow: "Aşağı",
                                }}
                                onChange={(e) => {
                                    console.log(e);
                                    setKidsHayvanlarSayfa(e);
                                }}
                                renderArrowPrev={(
                                    onClickHandler,
                                    hasPrev,
                                    label
                                ) =>
                                    hasPrev && (
                                        <div
                                            type="div"
                                            onClick={onClickHandler}
                                            title={label}
                                            className={classNames(
                                                styles.arrowDown,
                                                styles.arrow,
                                                styles.prev
                                            )}
                                        >
                                            <img src={prevBtn} alt="yukarı" />
                                        </div>
                                    )
                                }
                                renderArrowNext={(
                                    onClickHandler,
                                    hasNext,
                                    label
                                ) =>
                                    hasNext && (
                                        <div
                                            type="div"
                                            onClick={onClickHandler}
                                            title={label}
                                            className={classNames(
                                                styles.arrowDown,
                                                styles.arrow,
                                                styles.next
                                            )}
                                        >
                                            <img src={nextBtn} alt="aşağı" />
                                        </div>
                                    )
                                }
                            >
                                {[...Array(3)].map((x, i) => (
                                    <KidsSubmenu
                                        key={i}
                                        kidsSayfa={kidsHayvanlarSayfa}
                                        changeStage={changeStage}
                                        closeModal={closeModal}
                                        // completedLevels={
                                        //     completedLevels["kids_hayvanlar"]
                                        // }
                                        category="kids_hayvanlar"
                                        isActive={isActive}
                                    />
                                    // <KidsHayvanlar
                                    //     kidsHayvanlarSayfa={kidsHayvanlarSayfa}
                                    //     changeStage={changeStage}
                                    //     closeModal={closeModal}
                                    //     key={i}
                                    //     completedLevels={completedLevels["kids_hayvanlar"]}
                                    //     category="kids_hayvanlar"
                                    // />
                                ))}
                            </Carousel>
                        </div>
                        <div className={classNames(styles.subMenuContainer)}>
                            <div
                                src={BackIcon}
                                alt="back"
                                className={classNames(styles.backIcon)}
                                onClick={() => {
                                    setKidsSubMenu(0);
                                    setKidsUzaySayfa(0);
                                    console.log("back");
                                }}
                            />
                            <h2 className={classNames(styles.subTitle)}>
                                <FormattedMessage
                                    defaultMessage="Space"
                                    description="Space menu title"
                                    id="gui.game.modal.kidsSpace"
                                />
                            </h2>
                            <Carousel
                                width={"auto"}
                                selectedItem={kidsUzaySayfa}
                                axis="vertical"
                                showThumbs={false}
                                onChange={(e) => {
                                    console.log(e);
                                    setKidsUzaySayfa(e);
                                }}
                                labels={{
                                    leftArrow: (
                                        <FormattedMessage
                                            defaultMessage="Up"
                                            description="Label for up button"
                                            id="gui.game.modal.up"
                                        />
                                    ),
                                    rightArrow: (
                                        <FormattedMessage
                                            defaultMessage="Down"
                                            description="Label for down button"
                                            id="gui.game.modal.down"
                                        />
                                    ),
                                }}
                                showStatus={false}
                                showIndicators={false}
                                renderArrowPrev={(
                                    onClickHandler,
                                    hasPrev,
                                    label
                                ) =>
                                    hasPrev && (
                                        <div
                                            type="div"
                                            onClick={onClickHandler}
                                            title={label}
                                            className={classNames(
                                                styles.arrowDown,
                                                styles.arrow,
                                                styles.prev
                                            )}
                                        >
                                            <img
                                                src={prevBtn}
                                                alt={
                                                    <FormattedMessage
                                                        defaultMessage="Up"
                                                        description="Label for up button"
                                                        id="gui.game.modal.up"
                                                    />
                                                }
                                            />
                                        </div>
                                    )
                                }
                                renderArrowNext={(
                                    onClickHandler,
                                    hasNext,
                                    label
                                ) =>
                                    hasNext && (
                                        <div
                                            type="div"
                                            onClick={onClickHandler}
                                            title={label}
                                            className={classNames(
                                                styles.arrowDown,
                                                styles.arrow,
                                                styles.next
                                            )}
                                        >
                                            <img
                                                src={nextBtn}
                                                alt={
                                                    <FormattedMessage
                                                        defaultMessage="Down"
                                                        description="Label for down button"
                                                        id="gui.game.modal.down"
                                                    />
                                                }
                                            />
                                        </div>
                                    )
                                }
                            >
                                {[...Array(3)].map((x, i) => (
                                    <KidsSubmenu
                                        key={i}
                                        kidsSayfa={kidsUzaySayfa}
                                        changeStage={changeStage}
                                        closeModal={closeModal}
                                        // completedLevels={
                                        //     completedLevels["kids_uzay"]
                                        // }
                                        category="kids_uzay"
                                        isActive={isActive}
                                    />
                                    // <KidsUzay
                                    //     kidsUzaySayfa={kidsUzaySayfa}
                                    //     changeStage={changeStage}
                                    //     closeModal={closeModal}
                                    //     key={i}
                                    //     completedLevels={completedLevels["kids_uzay"]}
                                    //     category="kids_uzay"
                                    // />
                                ))}
                            </Carousel>
                        </div>
                    </Carousel>
                );
            default:
                return (
                    <div className={classNames(styles.wrapper)}>
                        <h2 className={classNames(styles.subTitle)}>
                            <FormattedMessage
                                defaultMessage="Map Menu"
                                description="Map menü modal for select a new game stage"
                                id="gui.game.modal.title"
                            />
                        </h2>
                        <Carousel
                            showArrows={true}
                            width={850}
                            selectedItem={selected}
                            showThumbs={false}
                            onChange={(e) => {
                                console.log(e);
                                setSelected(e);
                            }}
                            labels={{
                                leftArrow: (
                                    <FormattedMessage
                                        defaultMessage="Backward"
                                        description="Label for backward button"
                                        id="gui.game.modal.backward"
                                    />
                                ),
                                rightArrow: (
                                    <FormattedMessage
                                        defaultMessage="Forward"
                                        description="Label for forward button"
                                        id="gui.game.modal.forward"
                                    />
                                ),
                            }}
                            showStatus={false}
                            showIndicators={false}
                            renderArrowPrev={(onClickHandler, hasPrev, label) =>
                                hasPrev && (
                                    <div
                                        type="div"
                                        onClick={onClickHandler}
                                        title={label}
                                        className={classNames(
                                            styles.arrow,
                                            styles.prev
                                        )}
                                    >
                                        <img
                                            src={prevBtn}
                                            alt={
                                                <FormattedMessage
                                                    defaultMessage="Backward"
                                                    description="Label for backward button"
                                                    id="gui.game.modal.backward"
                                                />
                                            }
                                        />
                                    </div>
                                )
                            }
                            renderArrowNext={(onClickHandler, hasNext, label) =>
                                hasNext && (
                                    <div
                                        type="div"
                                        onClick={onClickHandler}
                                        title={label}
                                        className={classNames(
                                            styles.arrow,
                                            styles.next
                                        )}
                                    >
                                        <img
                                            src={nextBtn}
                                            alt={
                                                <FormattedMessage
                                                    defaultMessage="Forward"
                                                    description="Label for forward button"
                                                    id="gui.game.modal.forward"
                                                />
                                            }
                                        />
                                    </div>
                                )
                            }
                        >
                            {[...Array(isjr ? 1 : 3)].map((e, i) => (
                                <MainPage
                                    key={i}
                                    setSelected={setSelected}
                                    styles={styles}
                                    selected={selected}
                                    handleSubMenu={handleSubMenu}
                                    closeModal={closeModal}
                                    changeStage={changeStage}
                                    isjr={isjr}
                                />
                            ))}
                        </Carousel>
                    </div>
                );
        }
    }, [
        subMenuShow,
        ilerlemeSubMenu,
        portalSayfa,
        muzikSayfa,
        // sesIsikSayfa,
        // sensorlerSayfa,
        // parkurlarSayfa,
        selected,
        kidsSubMenu,
        kidsHayvanlarSayfa,
        kidsRenkSayfa,
        kidsUzaySayfa,
        kidsSekilSayfa,
        changeStage,
        activeLevel,
    ]);

    // useEffect(() => {
    //     setIlerleme(0);
    // }, [selected]);

    return (
        <div>
            <Modal
                isOpen={modalIsOpen}
                // onAfterOpen={afterOpenModal}
                onRequestClose={closeModal}
                style={customStyles}
                contentLabel="Stage Modal"
            >
                {renderSubMenu()}
            </Modal>
        </div>
    );
}

export default StageModalComponent;

{
    /* <div
                className={classNames(styles.modalHeader)}
                >
                    
                    <div 
                    onClick={closeModal}
                    className={classNames(styles.closeBtn)}
                    >
                        X
                    </div>
                </div>
                <div className={classNames(styles.container)}>
                    <div
                        onClick={() => {
                            changeStage("firstGame");
                            closeModal();
                        }}
                        className={classNames(styles.selectBtn)}
                    >
                        Boş Harita
                        
                    </div>
                    <div
                        onClick={() => {
                            changeStage("secondGame");
                            closeModal();
                        }}
                        className={classNames(styles.selectBtn)}
                    >
                        İlerleme Haritalari
                    </div>
                    <div
                        onClick={() => {
                            changeStage("thirdGame");
                            closeModal();
                        }}
                        className={classNames(styles.selectBtn)}
                    >
                        Kids
                    </div>

                    <div
                        onClick={() => {
                            changeStage("fourthGame");
                            closeModal();
                        }}
                        className={classNames(styles.selectBtn)}
                    >
                        Alan 1
                    </div>
                    <div
                        onClick={() => {
                            changeStage("fourthGame");
                            closeModal();
                        }}
                        className={classNames(styles.selectBtn)}
                    >
                        Alan 2
                    </div>
                    <div
                        onClick={() => {
                            changeStage("fourthGame");
                            closeModal();
                        }}
                        className={classNames(styles.selectBtn)}
                    >
                        Alan 3
                    </div>
                </div> */
}
