const Cast = require('../util/cast');
const MathUtil = require('../util/math-util');
const Timer = require('../util/timer');
const Oyun = require('../util/gameArea');
const ConnectedDeviceClass = require('../util/connectedDevice');
const DeviceSensorData = require('../util/deviceSensorData');


/*
Bu sayfada  GameLedBlocks oluşturdum. 
Burada yapmak istediğim bloklar ile oyun simülasyonunu hareket ettirmek.
Burada hareket fonksiyonlarını yazdım.

*/

var runtimefonk1= false;
var emre =0;
class GameLedBlocks {
    constructor (runtime) {
        /**
         * The runtime instantiating this block package.
         * @type {Runtime}
         */
        this.runtime = runtime;
    }

    /**
     * Retrieve the block primitives implemented by this package.
     * @return {object.<string, Function>} Mapping of opcode to Function.
     */
    getPrimitives () {
        return {
            light_onoff:  this.light_onoff,
            on_light_off:  this.light_off,

            lights_off:  this.lights_off,
            rgb_lights:  this.rgb_lights,
           
            on_led_yak:  this.on_led_yak,
            on_led_kapat:  this.on_led_kapat,
            arka_ledleri_yak:  this.arka_ledleri_yak,
            arka_led_kapat:  this.arka_led_kapat,
            arka_led_saniyeli:  this.arka_led_saniyeli,
            arka_led_255:  this.arka_led_255

        };
    }

    async light_onoff (args,util) {

        const color = args.ICON;
        console.log('gelen color: '+color);
        console.log(args);
        var currentClass =  Oyun.getClass();
 
        var device = ConnectedDeviceClass.getDevice();     

        if(color == 'red'){
          await device.write("<< 5 d led_light 1 >>\n");
  
          await currentClass.robotLightRed();
         }
         else if(color == 'green'){
          await device.write("<< 5 d led_light 2 >>\n");
  
          await currentClass.robotLightGreen();
         }
         else if(color == 'blue'){
          await device.write("<< 5 d led_light 3 >>\n");
  
          await currentClass.robotLightBlue();
         }
         else if(color == 'magenta'){
          await device.write("<< 5 d led_light 4 >>\n");
  
          await currentClass.robotLightMagenta();
         }
         else if(color == 'cyan'){
          await device.write("<< 5 d led_light 5 >>\n");
  
          await currentClass.robotLightCyan();
         }
         else if(color == 'yellow'){
          await device.write("<< 5 d led_light 6 >>\n");
  
          await currentClass.robotLightYellow();
         }
         else if(color == 'white'){
          await device.write("<< 5 d led_light 7 >>\n");
  
          await currentClass.robotLightWhite();
         }else{
          await device.write("<< 5 d led_light 1 >>\n");
  
          await currentClass.robotLightRed();
         }
      // await currentClass.listForwordForeverPush();

     }

     async  light_off (args,util) {

      // const steps = Cast.toNumber(args.repeat);
      // console.log('step hareket_geri gelen: '+steps);

       var currentGame = Oyun.getGame();
     

       var currentClass =  Oyun.getClass();

       var device = ConnectedDeviceClass.getDevice();     
       await device.write("<< 5 d led_off  >>\n");
      // await currentClass.listBackwardForeverPush();

   }

     async  lights_off (args,util) {

       // const steps = Cast.toNumber(args.repeat);
       // console.log('step hareket_geri gelen: '+steps);

        var currentGame = Oyun.getGame();
      

        var currentClass =  Oyun.getClass();
        var device = ConnectedDeviceClass.getDevice();     
        await device.write("<< 5 d leds_off  >>\n");
      //  await currentClass.listBackwardForeverPush();

    }


    async rgb_lights (args,util) {

        const color = args.COLOR;
        console.log('hız ileri gelen: '+color);
        var currentGame =  Oyun.getGame();
        var currentClass =  Oyun.getClass();
 
      //  var device = ConnectedDeviceClass.getDevice();
       // await device.write("<155>");
        console.log('////////////////////////////////');
        console.log(color);
        var device = ConnectedDeviceClass.getDevice();     
       if(color == 'red'){
        await device.write("<< 5 d leds_light 1 >>\n");
        if(currentClass){
          await currentClass.robotLightRed();

        }
       }
       else if(color == 'green'){
        await device.write("<< 5 d leds_light 2 >>\n");

        await currentClass.robotLightGreen();
       }
       else if(color == 'blue'){
        await device.write("<< 5 d leds_light 3 >>\n");

        await currentClass.robotLightBlue();
       }
       else if(color == 'magenta'){
        await device.write("<< 5 d leds_light 4 >>\n");

        await currentClass.robotLightMagenta();
       }
       else if(color == 'cyan'){
        await device.write("<< 5 d leds_light 5 >>\n");

        await currentClass.robotLightCyan();
       }
       else if(color == 'yellow'){
        await device.write("<< 5 d leds_light 6 >>\n");

        await currentClass.robotLightYellow();
       }
       else if(color == 'white'){
        await device.write("<< 5 d leds_light 7 >>\n");

        await currentClass.robotLightWhite();
       }
      
     }


     async on_led_yak(args,util){
      console.log(args);
      const color = args.VALUE;

      var device = ConnectedDeviceClass.getDevice();     
      if(color == 'red'){
        await device.write("<< 5 d led_light 1 >>\n");
       }
       else if(color == 'green'){
        await device.write("<< 5 d led_light 2 >>\n");
       }
       else if(color == 'blue'){
        await device.write("<< 5 d led_light 3 >>\n");
       }
       else if(color == 'magenta'){
        await device.write("<< 5 d led_light 4 >>\n");
       }
       else if(color == 'cyan'){
        await device.write("<< 5 d led_light 5 >>\n");
       }
       else if(color == 'yellow'){
        await device.write("<< 5 d led_light 6 >>\n");
       }
       else if(color == 'white'){
        await device.write("<< 5 d led_light 7 >>\n");
       }
    }

    async on_led_kapat(args,util){
      console.log(args);
      var device = ConnectedDeviceClass.getDevice();     
      await device.write("<< 5 d led_off  >>\n");
    }

    async arka_ledleri_yak(args,util){
     // console.log(args);
      const color = args.VALUE;

      var device = ConnectedDeviceClass.getDevice();     
      if(color == 'red'){
        await device.write("<< 5 d leds_light 1 >>\n");
       }
       else if(color == 'green'){
        await device.write("<< 5 d leds_light 2 >>\n");
       }
       else if(color == 'blue'){
        await device.write("<< 5 d leds_light 3 >>\n");
       }
       else if(color == 'magenta'){
        await device.write("<< 5 d leds_light 4 >>\n");
       }
       else if(color == 'cyan'){
        await device.write("<< 5 d leds_light 5 >>\n");
       }
       else if(color == 'yellow'){
        await device.write("<< 5 d leds_light 6 >>\n");
       }
       else if(color == 'white'){
        await device.write("<< 5 d leds_light 7 >>\n");
       }
    }
    

    async arka_led_kapat(args,util){
      console.log(args);
      var device = ConnectedDeviceClass.getDevice();     
      await device.write("<< 5 d leds_off  >>\n");
    }

    async arka_led_saniyeli(args,util){
      
      var device = ConnectedDeviceClass.getDevice(); 
      const color = args.COLOR;
      const seconds = args.VALUE;

      var result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(color);
      var r = parseInt(result[1], 16);
      var g = parseInt(result[2], 16);
      var b = parseInt(result[3], 16);  
      await device.write(`<< 5 d leds_light_by_value ${r} ${g} ${b} ${seconds} >>\n`);
    }

    async arka_led_255(args,util){
      console.log(args);
      const color = args.COLOR;
      const seconds = args.VALUE;
      var data=1;
      var device = ConnectedDeviceClass.getDevice(); 
      if(color == 'red'){
        data=1;
       }
       else if(color == 'green'){
        data=2;
       }
       else if(color == 'blue'){
        data=3;
       }
       else if(color == 'magenta'){
        data=4;
       }
       else if(color == 'cyan'){
        data=5;
       }
       else if(color == 'yellow'){
        data=6;
       }
       else if(color == 'white'){
        data=7;
       }    
      await device.write(`<< 5 d leds_light_brightness ${data} ${seconds} >>\n`);
    }
  
   
}

module.exports = GameLedBlocks;
