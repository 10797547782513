import classNames from 'classnames';
import {connect} from 'react-redux';
import {compose} from 'redux';
import {defineMessages, FormattedMessage, injectIntl, intlShape} from 'react-intl';
import PropTypes from 'prop-types';
import bindAll from 'lodash.bindall';
import bowser from 'bowser';
import React from 'react';
import VM from '../../../mymodule/ztech-vm';
import Box from '../box/box.jsx';
import ConnectButtonHOC from '../../containers/menu-bar-hoc.jsx';
import {
    openConnectionModal
} from '../../reducers/modals';
import {
    getIsShowingProject,
} from '../../reducers/project-state';
import {
    languageMenuOpen,
    loginMenuOpen
} from '../../reducers/menus';
import {setRealtimeConnection, clearConnectionModalPeripheralName} from '../../reducers/connection-modal';
import styles from './connectButon.css';
import connectIcon from "./link_80px.png";


class ConnectButton extends React.Component {
    constructor (props) {
        super(props);
        bindAll(this, [
            'handleConnectionMouseUp',
        ]);
    }
    componentDidMount () {
        document.addEventListener('keydown', this.handleKeyPress);
        this.props.vm.on('PERIPHERAL_DISCONNECTED', this.props.onDisconnect);
    }
    componentWillUnmount () {
        document.removeEventListener('keydown', this.handleKeyPress);
        this.props.vm.removeListener('PERIPHERAL_DISCONNECTED', this.props.onDisconnect);
    }
  
    handleConnectionMouseUp () {
        if (this.props.deviceId) {
            this.props.onOpenConnectionModal();
        } else {
            this.props.onDeviceIsEmpty();
        }
    }
   
   
    render () {
      
       
        return (
            <Box
            className={
                this.props.isjr ?
                styles.connectDeviceButtonContainer : styles.connectDeviceButtonContainerNormal
            }
        >
            <button
                className={
                    styles.extensionButton
                }
              
                onClick={() => {
                  this.handleConnectionMouseUp();
                }}
            >
                <img
                    className={
                        styles.gameButtonIcon
                    }
                    draggable={false}
                    src={connectIcon}
                />
            </button>

            <div className={
                    styles.connectDeviceButtonText
                }
                onMouseUp={() => {
                    this.handleConnectionMouseUp();
                }}

                >
            
            </div>

            
            

        </Box>
        );
    }
}

ConnectButton.propTypes = {
    onOpenConnectionModal: PropTypes.func,
    onDisconnect: PropTypes.func.isRequired,

};



const mapStateToProps = (state, ownProps) => {
    const loadingState = state.scratchGui.projectState.loadingState;
    const user = state.session && state.session.session && state.session.session.user;
    return {
        isRtl: state.locales.isRtl,
        isShowingProject: getIsShowingProject(loadingState),
        isSupportSwitchMode: state.scratchGui.programMode.isSupportSwitchMode,
        isToolboxUpdating: state.scratchGui.toolbox.isToolboxUpdating,
        languageMenuOpen: languageMenuOpen(state),
        locale: state.locales.locale,
        loginMenuOpen: loginMenuOpen(state),
        projectTitle: state.scratchGui.projectTitle,
        realtimeConnection: state.scratchGui.connectionModal.realtimeConnection,
        vm: state.scratchGui.vm,
        peripheralName: state.scratchGui.connectionModal.peripheralName,
        deviceId: state.scratchGui.device.deviceId,
        deviceName: state.scratchGui.device.deviceName,
        isjr: state.scratchGui.isjr

    };
};

const mapDispatchToProps = dispatch => ({
    onOpenConnectionModal: () => dispatch(openConnectionModal()),
    onDisconnect: () => {
        dispatch(clearConnectionModalPeripheralName());
        dispatch(setRealtimeConnection(false));
    },
});

export default compose(
    injectIntl,
    ConnectButtonHOC,
    connect(
        mapStateToProps,
        mapDispatchToProps
    )
)(ConnectButton);
