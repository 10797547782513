const blockSeparator = '<sep gap="36"/>'; // At default scale, about 28px
const categorySeparator = '<sep gap="36"/>';


export const events = function (isInitialSetup, isStage) {
    return `
    <category name="%{BKY_CATEGORY_EVENTS}" id="events" colour="#FFD500" secondaryColour="#CC9900">

        
    <block type="game_event_whenflagclicked"/>

    <block type="game_event_whenbroadcastreceived">
    <value name="option">
    <shadow type="game_dropdown_whenbroadcast">
    <field name="option">blue</field>
    </shadow>
    </value>
    </block>
   

    <block type="game_event_broadcast">
    <value name="option">
    <shadow type="game_dropdown_broadcast">
    <field name="option">blue</field>
    </shadow>
    </value>
    </block>
    
    
    
    
    <block type="game_when_shake_robot"></block>

    <block type="game_detect_sound_intensity">
        <value name="sound_intensity">
            <shadow type="dropdown_detect_sound_intensity">
                <field name="sound_intensity">100</field>
            </shadow>
        </value>
    </block>

    <block type="game_when_detect_light">
        <value name="light_intensity">
            <shadow type="dropdown_detect_light_intensity">
                <field name="light_intensity">100</field>
            </shadow>
        </value>
    </block>


    


    <block type="game_when_detect_color">
        <value name="detect_color">
            <shadow type="dropdown_detect_color">
                <field name="detect_color">Red</field>
            </shadow>
        </value>
    </block>

    <block type="game_when_detect_barrier">
        <value name="detect_barrier">
            <shadow type="dropdown_detect_barrier">
                <field name="detect_barrier">barrier</field>
            </shadow>
        </value>
    </block>

    
    <block type="game_when_keypress_game">
        <value name="click">
            <shadow type="dropdown_press">
                <field name="click">forward</field>
            </shadow>
        </value>
    </block>


        ${blockSeparator}

      
        ${categorySeparator}
    </category>
    `;
};