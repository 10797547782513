

import {events} from "./xml-categories/events";
import {motion} from "./xml-categories/motions";
import {lights} from "./xml-categories/lights";
import {looks} from "./xml-categories/looks";
import {sound} from "./xml-categories/sounds";
import {control} from "./xml-categories/controls";
import {sensing} from "./xml-categories/sensing";
import {operators} from "./xml-categories/operators";
import {ztech} from "./xml-categories/ztech";


// ztech-gui\src\lib\make-toolbox-xml.js
const categorySeparator = '<sep gap="36"/>';

const blockSeparator = '<sep gap="36"/>'; // At default scale, about 28px

/* eslint-disable no-unused-vars */


const xmlEscape = function (unsafe) {
    return unsafe.replace(/[<>&'"]/g, c => {
        switch (c) {
        case '<': return '&lt;';
        case '>': return '&gt;';
        case '&': return '&amp;';
        case '\'': return '&apos;';
        case '"': return '&quot;';
        }
    });
};

const variables = function () {
    return `
    <category
        name="%{BKY_CATEGORY_VARIABLES}"
        id="variables"
        colour="#FF8C1A"
        secondaryColour="#DB6E00"
        custom="VARIABLE">
    </category>
    `;
};

const myBlocks = function () {
    return `
    <category
        name="%{BKY_CATEGORY_MYBLOCKS}"
        id="myBlocks"
        colour="#FF6680"
        secondaryColour="#FF4D6A"
        custom="PROCEDURE">
    </category>
    `;
};

/* eslint-enable no-unused-vars */

const xmlOpen = '<xml style="display: none">';
const xmlClose = '</xml>';

/**
 * @param {!boolean} isInitialSetup - Whether the toolbox is for initial setup. If the mode is "initial setup",
 * blocks with localized default parameters (e.g. ask and wait) should not be loaded. (LLK/scratch-gui#5445)
 * @param {?object} device - Full data of current selected deivce.
 * @param {?boolean} isStage - Whether the toolbox is for a stage-type target. This is always set to true
 * when isInitialSetup is true.
 * @param {?string} targetId - The current editing target
 * @param {?Array.<object>} categoriesXML - optional array of `{id,xml}` for categories. This can include both core
 * and other extensions: core extensions will be placed in the normal Scratch order; others will go at the bottom.
 * @property {string} id - the extension / category ID.
 * @property {string} xml - the `<category>...</category>` XML for this extension / category.
 * @param {?boolean} isRealtimeMode - Current program mode.
 * @param {?string} costumeName - The name of the default selected costume dropdown.
 * @param {?string} backdropName - The name of the default selected backdrop dropdown.
 * @param {?string} soundName -  The name of the default selected sound dropdown.
 * @returns {string} - a ScratchBlocks-style XML document for the contents of the toolbox.
 */
const nullDeviceHorizontalToolboxXML = function (isInitialSetup, device = null, isStage = true, targetId, categoriesXML = [],
    isRealtimeMode = true,
    isjr=true,
    costumeName = '', backdropName = '', soundName = '') {
    isStage = isInitialSetup || isStage;
    const gap = [categorySeparator];

    costumeName = xmlEscape(costumeName);
    backdropName = xmlEscape(backdropName);
    soundName = xmlEscape(soundName);
    categoriesXML = categoriesXML.slice();
    const moveCategory = categoryId => {
        const index = categoriesXML.findIndex(categoryInfo => categoryInfo.id === categoryId);
        if (index >= 0) {
            // remove the category from categoriesXML and return its XML
            const [categoryInfo] = categoriesXML.splice(index, 1);
            return categoryInfo.xml;
        }
        // return `undefined`
    };

    let everything = [xmlOpen];

    if (device) {
        const baseToolboxXml = isjr ? device.baseToolBoxXml(isInitialSetup, isStage, targetId, isRealtimeMode,costumeName, backdropName, soundName) :
        device.baseToolBoxXmlVertical(isInitialSetup, isStage, targetId, isRealtimeMode,costumeName, backdropName, soundName);

        everything = everything.concat(baseToolboxXml);
    } else {
        const ztechXML = moveCategory('ztech') || ztech(isInitialSetup, isStage, targetId, soundName);
        const motionXML = moveCategory('motion') || motion(isInitialSetup, isStage, targetId);
        const lightsXML = moveCategory('lights') || lights(isInitialSetup, isStage, targetId);
        const looksXML = moveCategory('looks') || looks(isInitialSetup, isStage, targetId, costumeName, backdropName);
        const soundXML = moveCategory('sound') || sound(isInitialSetup, isStage, targetId, soundName);
        const eventsXML = moveCategory('event') || events(isInitialSetup, isStage, targetId);
        const controlXML = moveCategory('control') || control(isInitialSetup, isStage, targetId);
        const sensingXML = moveCategory('sensing') || sensing(isInitialSetup, isStage, targetId);
        const operatorsXML = moveCategory('operators') || operators(isInitialSetup, isStage, targetId);
        const variablesXML = moveCategory('data') || variables(isInitialSetup, isStage, targetId);
        const myBlocksXML = moveCategory('procedures') || myBlocks(isInitialSetup, isStage, targetId);

        everything.push(
            eventsXML, gap,
            motionXML, gap,
            controlXML, gap,
            soundXML, gap,
            lightsXML,gap,
            looksXML,gap
        );
    }

    for (const extensionCategory of categoriesXML) {
        everything.push(gap, extensionCategory.xml);
    }

    everything.push(xmlClose);
    return everything.join('\n');
};

export default nullDeviceHorizontalToolboxXML;
