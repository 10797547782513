// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "@-webkit-keyframes mic-indicator_popIn_2A2Un {\n    from {-webkit-transform: scale(0.5);transform: scale(0.5)}\n    to {-webkit-transform: scale(1);transform: scale(1)}\n}\n\n@keyframes mic-indicator_popIn_2A2Un {\n    from {-webkit-transform: scale(0.5);transform: scale(0.5)}\n    to {-webkit-transform: scale(1);transform: scale(1)}\n}\n\n.mic-indicator_mic-img_Su3yZ {\n    margin: 10px;\n    -webkit-transform-origin: center;\n        -ms-transform-origin: center;\n            transform-origin: center;\n    -webkit-animation: mic-indicator_popIn_2A2Un 0.1s ease-in-out;\n            animation: mic-indicator_popIn_2A2Un 0.1s ease-in-out;\n}\n", ""]);
// Exports
exports.locals = {
	"mic-img": "mic-indicator_mic-img_Su3yZ",
	"micImg": "mic-indicator_mic-img_Su3yZ",
	"popIn": "mic-indicator_popIn_2A2Un"
};
module.exports = exports;
