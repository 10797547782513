// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".alerts_alerts-inner-container_1KTuF {\n    min-width: 80px;\n    max-width: 578px;\n}\n", ""]);
// Exports
exports.locals = {
	"alerts-inner-container": "alerts_alerts-inner-container_1KTuF",
	"alertsInnerContainer": "alerts_alerts-inner-container_1KTuF"
};
module.exports = exports;
