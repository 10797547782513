import React from "react";
import Box from "../box/box.jsx";
import classNames from "classnames";
import styles from "./gui.css";
import playIcon from "./play_60px.png";
import stopIcon from "./stop_48px.png";
const ReadDeviceData = require('../../../mymodule/ztech-vm/src/util/deviceDataRead.js');

const Oyun = require("../../../mymodule/ztech-vm/src/util/gameArea");

export const PlayBtn = ({ isjr, vm, isFullScreen }) => {
    return (
        <Box

            className={classNames(!isFullScreen && (isjr ? styles.gamePlayButtonContainer : styles.gamePlayButtonContainerNormal), {[styles.isFullScreen]: isFullScreen})}               
            
        >
            <button
                className={styles.extensionButton}
                onClick={() => {
                    var currentClass = Oyun.getClass();
                    console.log(currentClass);
                    if(currentClass != null){
                        currentClass.playPressed();
                    }

                    vm.gamePlay();
                    vm.start();
                    ReadDeviceData.setRunStep(false);
                }}
            >
                <img
                    className={styles.gameButtonIcon}
                    draggable={false}
                    src={playIcon}
                />
            </button>
        </Box>
    );
};

export const StopBtn = ({ isjr, vm, isFullScreen }) => {
    return (
        <Box
        className={classNames(!isFullScreen && (isjr ? styles.gameStopButtonContainer : styles.gameStopButtonContainerNormal), {[styles.isFullScreen]: isFullScreen})}
            
        >
            <button
                className={styles.extensionButton}
                onClick={() => {
                    console.log("stop buton");
                    vm.stopAll();
                    ReadDeviceData.setRunStep(false);
                }}
            >
                <img
                    className={styles.stopButtonIcon}
                    draggable={false}
                    src={stopIcon}
                />
            </button>
        </Box>
    );
};
