import React from "react";
import { urlContains } from "selenium-webdriver/lib/until";
import DeviceSensorData from '../../../mymodule/ztech-vm/src/util/deviceSensorData';
import styles from './oyun.css';
import classNames from 'classnames';


const Slider = ({ range, setRange, title }) => {
    return (
        <div
            style={{
                display: "flex",
            }}
        >
            <div
            className={classNames(styles.sliderContainer)}                
            >
               <input
                    type="range"
                    min="0"
                    max="100"
                    value={range}
                    onChange={(e) => {
                      setRange(e.target.value);
                      if (title === "Light Level") {
                        console.log("light level changed"+e.target.value);
                        DeviceSensorData.setLightLevel(parseInt(e.target.value));
                      }
                      if (title === "Sound Level") {
                        console.log("Sound Level changed"+e.target.value);
                        DeviceSensorData.setSoundLevel(parseInt(e.target.value));
                      }
                      if (title === "Shake Level") {
                        console.log("Shake Level"+e.target.value);
                        DeviceSensorData.setShakeLevel(parseInt(e.target.value));
                      }
                    }}
                    id="myRange"
                    // orient="vertical"
                    step={1}
                    color="red"
                    className={classNames(styles.slider)}
                    style={{
                        background: `linear-gradient(to right, #008e2f 0%, #008e2f ${range}%, #ffffff ${range}%, #ffffff 100%)`,
                    }}
                />
            </div>
        </div>
    );
};

export default Slider;
