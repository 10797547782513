const Cast = require('../util/cast');
const MathUtil = require('../util/math-util');
const Timer = require('../util/timer');
const Oyun = require('../util/gameArea');
const ConnectedDeviceClass = require('../util/connectedDevice');
const DeviceSensorData = require('../util/deviceSensorData');


/*
Bu sayfada  GameMotionBlocks oluşturdum. 
Burada yapmak istediğim bloklar ile oyun simülasyonunu hareket ettirmek.
Burada hareket fonksiyonlarını yazdım.

*/

var runtimefonk1= false;
var emre =0;
class GameMotionBlocks {
    constructor (runtime) {
        /**
         * The runtime instantiating this block package.
         * @type {Runtime}
         */
        this.runtime = runtime;
    }

    /**
     * Retrieve the block primitives implemented by this package.
     * @return {object.<string, Function>} Mapping of opcode to Function.
     */
    getPrimitives () {
        return {
            hareket_speed:  this.hareket_speed,
            hareket_speed_back:  this.hareket_speed_back,
            hareket_dur:  this.hareket_dur,
            hareket_ileri_sonsuz:  this.hareket_ileri_sonsuz,
            hareket_geri_sonsuz: this.hareket_geri_sonsuz,
            hareket_ileri:  this.hareket_ileri,
            hareket_geri: this.hareket_geri,
            hareket_saga_don: this.hareket_saga_don,
            hareket_sola_don: this.hareket_sola_don,
            click_Play: this.click_Play,
            bot_sound_volume: this.bot_sound_volume,
            hareket_cizgi_izleme:this.hareket_cizgi_izleme,
            //////DİKEY BASLANGIC
            left_right_clock_second:this.left_right_clock_second,
            left_right_clock_power:this.left_right_clock_power,
            forward_second:this.forward_second,
            backward_second:this.backward_second,
            turnleft_until:this.turnleft_until,
            turnright_until:this.turnright_until,
            hareket_menu_git:this.hareket_menu_git,
            hareket_sol_sag_guc:this.hareket_sol_sag_guc,
            hareket_durdur:this.hareket_durdur

        };
    }

    async hareket_ileri_sonsuz (args,util) {

        const steps = Cast.toNumber(args.SPEED);
        console.log('step ileri gelen: '+steps);
        var currentGame =  Oyun.getGame();
        var currentClass =  Oyun.getClass();
       var device = ConnectedDeviceClass.getDevice();     
       await device.write(`<< 9 d motor_forward_at_power 50 >>\n`);
      
       await currentClass.listForwordForeverPush();

     }

     async  hareket_geri_sonsuz (args,util) {

        const steps = Cast.toNumber(args.repeat);
        console.log('step hareket_geri gelen: '+steps);

        var currentClass =  Oyun.getClass();

        var device = ConnectedDeviceClass.getDevice();     
        await device.write(`<< 9 d motor_backward_at_power 50 >>\n`);
        await currentClass.listBackwardForeverPush();

    }


    async hareket_speed (args,util) {

        const steps = Cast.toNumber(args.VALUE);
        console.log('hız ileri gelen: '+steps);
        
        var currentGame =  Oyun.getGame();
        var currentClass =  Oyun.getClass();
 
     
       var device = ConnectedDeviceClass.getDevice();     
       if(steps == 1){
        await device.write("<< 9 d set_speed 1 >>\n");
       }
       else if(steps == 2){
        await device.write("<< 9 d set_speed 2 >>\n");
       }
       else if(steps == 3){
        await device.write("<< 9 d set_speed 3 >>\n");
       }
       else if(steps == 4){
        await device.write("<< 9 d set_speed 4 >>\n");
       }

     }
  
     async hareket_speed_back (args,util) {
        const speed = Cast.toNumber(args.VALUE);
        console.log('hız geri gelen: '+speed);
        var currentGame =  Oyun.getGame();
        var currentClass =  Oyun.getClass();
 
      //  var device = ConnectedDeviceClass.getDevice();
       // await device.write("<155>");
        if(steps == 0){
         //   await currentClass.moveSpeedDown(speed);
        }else{
        
        }
       
     }

     async hareket_dur (args,util) {

        console.log('hız dur');
        var currentGame =  Oyun.getGame();
        var currentClass =  Oyun.getClass();
        await currentClass.moveStop();
  

       var device = ConnectedDeviceClass.getDevice();     
       await device.write("<< 9 d stop_motors  >>\n");
     }
   
     async hareket_ileri (args,util) {

       const steps = Cast.toNumber(args.SPEED);
       console.log('step ileri gelen: '+steps);
       var currentGame =  Oyun.getGame();
       var currentClass =  Oyun.getClass();

     //  var device = ConnectedDeviceClass.getDevice();
      // await device.write("<155>");
      var device = ConnectedDeviceClass.getDevice();     
      await device.write("<< 9 d forward_motors 150 2 >>\n");

       if(steps == 0){

          await currentClass.downRightPush();
       }else{
        for (let i = 0; i < steps; i++) {
            await currentClass.downRightPush();
          }
       }
    }

    

   

    async  hareket_geri (args,util) {

        const steps = Cast.toNumber(args.repeat);
        console.log('step hareket_geri gelen: '+steps);

        var currentGame = Oyun.getGame();
      

        var currentClass =  Oyun.getClass();
        var device = ConnectedDeviceClass.getDevice();     
        await device.write("<< 9 d backward_motors 150 2 >>\n");
        if(steps == 0){
            await currentClass.upLeftPush();
        }else{
            for (let i = 0; i < steps; i++) {
                await currentClass.upLeftPush();
              }
        }
    }

    async hareket_saga_don (args,util) {

        const steps = Cast.toNumber(args.repeat);
       console.log('step hareket_saga_don gelen: '+steps);

        var currentClass =  Oyun.getClass();
        var device = ConnectedDeviceClass.getDevice();     
        await device.write("<< 9 d turn 1 90 >>\n");
        if(steps == 1){
            await currentClass.downLeftPush();
        }else{
            for (let i = 1; i < steps; i++) {
                await device.write("<< 9 d turn 1 90 >>\n");
                await currentClass.downLeftPush();
              }
        }
       
    }

    async hareket_sola_don (args,util) {
        const steps = Cast.toNumber(args.repeat);
        console.log('step hareket_sola_don gelen: '+steps);

        var currentClass =  Oyun.getClass();
        var device = ConnectedDeviceClass.getDevice();     
        await device.write("<< 9 d turn 0 90 >>\n");
        if(steps == 1){
            await currentClass.upRightPush();
        }else{
            for (let i = 1; i < steps; i++) {
                await currentClass.upRightPush();
                await device.write("<< 9 d turn 0 90 >>\n");

              }
        }

    }
    
    async left_right_clock_second (args,util) {
        console.log(args);
        const direction = args.DIRECTION;
        const clock = args.CLOCK;
        const seconds = Cast.toNumber(args.X);
        var data=1;
        var direct=1;

        console.log(direction);
        console.log(clock);
        console.log(seconds);
        var device = ConnectedDeviceClass.getDevice();   
        if(clock=="clockwise"){
            data=1;
        }else{
            data=0;
        }
        if(direction=="left"){
            direct=1;
        }else{
            direct=0;
        }
        await device.write(`<< 9 d motor_direction ${direct} ${data} ${seconds} >>\n`);
    }

    async left_right_clock_power(args,util) {
        console.log(args);
        const direction = args.DIRECTION;
        const clock = args.CLOCK;
        const power = Cast.toNumber(args.X);
        var data=1;
        var direct=1;

        var device = ConnectedDeviceClass.getDevice();   
        if(clock=="clockwise"){
            data=1;
        }else{
            data=0;
        }

        if(direction=="sol"){
            direct=1;
        }else{
            direct=0;
        }


        await device.write(`<< 9 d motor_power ${direct} ${data} ${power} >>\n`);
    }

    async forward_second(args,util) {
        console.log(args);
        const power = Cast.toNumber(args.power);
        const seconds = Cast.toNumber(args.Y);
        var device = ConnectedDeviceClass.getDevice();     
        await device.write(`<< 9 d motor_forward_at_power ${power} ${seconds} >>\n`);
    }

    async backward_second(args,util) {
        console.log(args);
        const power = Cast.toNumber(args.X);
        const seconds = Cast.toNumber(args.Y);
        var device = ConnectedDeviceClass.getDevice();     
        await device.write(`<< 9 d motor_backward_at_power ${power} ${seconds} >>\n`);
    }

    async turnleft_until(args,util) {
        console.log(args);
        const angle = Cast.toNumber(args.X);
        var device = ConnectedDeviceClass.getDevice();     
        await device.write(`<< 9 d turn 0 ${angle} >>\n`);
    }

    async turnright_until(args,util) {
        console.log(args);
        const angle = Cast.toNumber(args.X);
        var device = ConnectedDeviceClass.getDevice();     
        await device.write(`<< 9 d turn 1 ${angle} >>\n`);
    }

    async hareket_menu_git (args,util) {
        console.log(args);

        const direction = args.VALUE;
        const power = Cast.toNumber(args.SPEED);

        var device = ConnectedDeviceClass.getdev();   
        if(direction=="forward"){
            await device.write(`<< 9 d motor_forward_at_power ${power} >>\n`);
        }else{
            await device.write(`<< 9 d motor_backward_at_power ${power} >>\n`);
        }
     }

    async hareket_sol_sag_guc(args,util) {
        console.log(args);
        const left = Cast.toNumber(args.X);
        const right = Cast.toNumber(args.Y);

        var device = ConnectedDeviceClass.getDevice();     
        await device.write(`<< 9 d seperated_motor_control 1 ${left} ${right} >>\n`);
    }

    async hareket_durdur(args,util) {
        console.log(args);
        var device = ConnectedDeviceClass.getDevice();     
        await device.write(`<< 9 d stop_motors  >>\n`);
        
    }


    async click_Play (args,util) {
       //console.log('event_whenflagclicked');
        var currentClass =  Oyun.getClass();
        currentClass.playPressed();

    }

    async bot_sound_volume (args,util) {
        console.log("bot_sound_volume 40 okuyor sensör");
        return 40;
 
     }

     async hareket_cizgi_izleme (args,util) {
        var device = ConnectedDeviceClass.getDevice();     
        await device.write(`<< 18 d follow_line >>\n`);
 
     }
     
   
}

module.exports = GameMotionBlocks;
