const VM = require('../../src/engine/runtime');
const Buffer = require('buffer').Buffer;


let libraries = 0;

let BatteryVoltaj=0;
let BatteryPercent=0;
let SoundIntensity=0;
let SoundLevel=0;
let LightIntensity=0;
let ShakingIntensity=0;
let TiltAngle=0;
let SideTilt=0;
let DetectBarrier=0;
let IsShake=0;
let ForwardButton=0;
let LeftButton=0;
let RightButton=0;
let BackwardButton=0;
let ZButton=0;
let LineRightBlack=0;
let LineRightWhite=0;
let LineLeftWhite=0;
let LineLeftBlack=0;
let DetectColor=false;
let RunStep=false;




class ReadDeviceData {
    constructor (runtime) {
        this._onOpen = null;
        this._onClose = null;
        this._runtime = runtime;
        this._bufferData = null;
        this._bufferColorData = null;

        

      //  VM.addListener('PERIPHERAL_RECIVE_DATA', this.onReciveData);
    }

   
    static getData () {
        VM.addListener('PERIPHERAL_RECIVE_DATA', this.onReciveData);

        console.log(this._recivceBuffer);
        return this._recivceBuffer;
    }

    parseButtonData(){
        if(this._bufferData){
          //  console.log(this._bufferData);//sol sağ side_tilt  sol -60  sağ +60
           // console.log(this._bufferData[25]);//ön arka direct_tilt ön -55 arka +55
            var buttonx = this._bufferData[2];
            var buttonz = this._bufferData[3];
            var buttoncircle = this._bufferData[4];
            var buttonsquare = this._bufferData[5];
            var buttontriangle = this._bufferData[6];
            var lineRightBlack = this._bufferData[7];
            var lineRightWhite = this._bufferData[8];
            var lineLeftBlack = this._bufferData[9];
            var lineLeftWhite = this._bufferData[10];
            var accX = this._bufferData[11];
            var accY = this._bufferData[12];
            var accZ = this._bufferData[13];
            var gyrX = this._bufferData[14];
            var gyrY = this._bufferData[15];
            var gyrZ = this._bufferData[16];
            var temperature = this._bufferData[17];
            var orientation_6d = this._bufferData[18];
            var isshake = this._bufferData[19];
            var shaking_intensity = this._bufferData[20];
            var direct_tilt = this._bufferData[21];
            var side_tilt = this._bufferData[22];
            var lightIntensity = this._bufferData[23];
            var detectBarrier = this._bufferData[24];
            var detectSound = this._bufferData[25];
            var show_level = this._bufferData[26];

            var batteryVolt = this._bufferData[27];
            var batteryPercent = this._bufferData[28];
           // console.log();
           // << 3 detect_color 0 >>
            var detectColor = this._bufferData[29];
           //console.log(shaking_intensity);

         //  << RA 9 9 9 9 9 1 0 1 0 -9.95 0.37 0.33 -0.01 -0.02 0.03 32.6 1 0 48 41 -2 32 0 2 50 4.17 97.82 >>
          
           
           // console.log(lightIntensity);
            if(buttonx == "1"){
                this._runtime.startHats('game_when_keypress_game', {
                    click: 'upArrow'
                });
            }
            if(buttontriangle == "1"){
                this._runtime.startHats('game_when_keypress_game', {
                    click: 'downArrow'
                });
            }
            if(buttoncircle == "1"){
                this._runtime.startHats('game_when_keypress_game', {
                    click: 'rightArrow'
                });
            }
            if(buttonsquare == "1"){
                this._runtime.startHats('game_when_keypress_game', {
                    click: 'leftArrow'
                });
            }
            if(buttonz == "1"){
                this._runtime.startHats('game_when_keypress_game', {
                    click: 'zButton'
                });
            }
            if(isshake == "1"){
              //  this._runtime.startHats('game_when_shake_robot');
            }
            if(detectBarrier == "0"){
                this._runtime.startHats('game_when_detect_barrier', {
                    detect_barrier: 'barrier'
                });
            }else{
                this._runtime.startHats('game_when_detect_barrier', {
                    detect_barrier: 'no_barrier'
                });
            }
            var datasendColor ="None";
            
            if(detectColor != "0" && detectColor!= "None"){
                if(detectColor == "1"){
                    datasendColor ="blue";
                }
                if(detectColor == "4"){
                    datasendColor ="green";
                }
                if(detectColor == "5"){
                    datasendColor ="red";
                }
                if(detectColor == "2"){
                    datasendColor ="white";
                }
                if(detectColor == "1"){
                    datasendColor ="blue";
                }
                if(detectColor == "1"){
                    datasendColor ="blue";
                }
                if(detectColor == "1"){
                    datasendColor ="blue";
                }

                this._runtime.startHats('game_when_detect_color', {
                    detect_color:datasendColor
                });

              /*
                this._runtime.startHats('game_when_detect_color_vertical', {
                    KEY_OPTION:datasendColor
                });
*/
                
            }
            
            /*
            if(detectColor == "1"){
                this._runtime.startHats('game_when_detect_color', {
                    detect_color: 'white'
                });
            }
            */

            
            if(buttonx == "1"){
                this._runtime.startHats('game_when_detect_robot_button', {
                    KEY_OPTION: 'upArrow'
                });
            }
            if(buttontriangle == "1"){
                this._runtime.startHats('game_when_detect_robot_button', {
                    KEY_OPTION: 'downArrow'
                });
            }
            if(buttoncircle == "1"){
                this._runtime.startHats('game_when_detect_robot_button', {
                    KEY_OPTION: 'rightArrow'
                });
            }
            if(buttonsquare == "1"){
                this._runtime.startHats('game_when_detect_robot_button', {
                    KEY_OPTION: 'leftArrow'
                });
            }
            if(buttonz == "1"){
                this._runtime.startHats('game_when_detect_robot_button', {
                    KEY_OPTION: 'z'
                });
            }
            this._runtime.startHats('game_detect_sound_intensity', {
                sound_intensity: detectSound
            });
            
            this._runtime.startHats('game_when_detect_light', {
                light_intensity: lightIntensity
            });

            /*
            if(TiltAngle >= -55){
                this._runtime.startHats('game_when_detect_tilted_vertical', {
                    KEY_OPTION: 'left'
                });
            }
            if(TiltAngle >= 55){
                this._runtime.startHats('game_when_detect_tilted_vertical', {
                    KEY_OPTION: 'right'
                });
            }
            */
           
            if(direct_tilt < -13){
                this._runtime.startHats('game_when_detect_tilted_vertical', {
                    KEY_OPTION: 'forward'
                });
            }
            if(direct_tilt > 0 && direct_tilt < 65){
                this._runtime.startHats('game_when_detect_tilted_vertical', {
                    KEY_OPTION: 'backward'
                });
            }
            if(side_tilt < 0 && side_tilt > -77){
                this._runtime.startHats('game_when_detect_tilted_vertical', {
                    KEY_OPTION: 'left'
                });
            }
            if(side_tilt > 0 && side_tilt < 80){
                this._runtime.startHats('game_when_detect_tilted_vertical', {
                    KEY_OPTION: 'right'
                });
            }

          
            if(detectBarrier == "0"){
                this._runtime.startHats('game_when_detect_barrier_vertical', {
                    KEY_OPTION: 'no'
                });
            }else{
                console.log("barrier var");
                this._runtime.startHats('game_when_detect_barrier_vertical', {
                    KEY_OPTION: 'yes'
                });
            }

            if(shaking_intensity > 50){
                this._runtime.startHats('game_when_shake_robot');
            }
            

            this._runtime.startHats('game_when_detect_light_vertical', {
                name: 'sound',
                value:detectSound
            });

            this._runtime.startHats('game_when_detect_light_vertical', {
                name: 'light',
                value:lightIntensity
            });

            this._runtime.startHats('game_when_detect_light_vertical', {
                name: 'shake',
                value:shaking_intensity
            });


            BatteryVoltaj=batteryVolt;
            BatteryPercent=batteryPercent;
            SoundIntensity=detectSound;
            SoundLevel=show_level;
            LightIntensity=lightIntensity;
            ShakingIntensity=shaking_intensity;
            TiltAngle=direct_tilt;
            SideTilt=side_tilt;
            DetectBarrier=detectBarrier;
            IsShake=isshake;
            ForwardButton=buttonx;
            LeftButton=buttoncircle;
            RightButton=buttonsquare;
            BackwardButton=buttontriangle;
            ZButton=buttonz;
            LineRightBlack=lineRightBlack;
            LineRightWhite=lineRightWhite;
            LineLeftBlack=lineLeftBlack;
            LineLeftWhite=lineLeftWhite;
            /*
            console.log("lineRightBlack: "+lineRightBlack);
            console.log("lineRightWhite: "+lineRightWhite);
            console.log("lineLeftBlack: "+lineLeftBlack);
            console.log("lineLeftWhite: "+lineLeftWhite);
*/
            DetectColor=detectColor;
        }
        
    }

    static getBatteryVolt(){
        return BatteryVoltaj;
    }

    static getBatteryVolt(){
        return BatteryPercent;
    }

    static getSoundIntensity(){
        return SoundIntensity;
    }

    static getSoundLevel(){
        return SoundLevel;
    }

    static getLightIntensity(){
        return LightIntensity;
    }

    static getShakingIntensity(){
        return ShakingIntensity;
    }

    static getTiltAngle(){
        return TiltAngle;
    }

    static getSideTilt(){
        return SideTilt;
    }

    static getDetectBarrier(){
        return DetectBarrier == "1" ? true: false;
    }

    static getIsShake(){
        return IsShake == "1" ? true: false;
    }

    static getForwardButton(){
        return ForwardButton == "1" ? true: false;
    }

    static getLeftButton(){
        return LeftButton == "1" ? true: false;
    }

    static getRightButton(){
        return RightButton == "1" ? true: false;
    }

    static getBackwardButton(){
        return BackwardButton == "1" ? true: false;
    }

    static getZButton(){
        return ZButton == "1" ? true: false;
    }

    static getLineRightBlack(){
       // console.log(LineRightWhite);

        return LineRightBlack == "1" ? true: false;
    }

    static getLineRightWhite(){
       // console.log(LineRightWhite);

        return LineRightWhite == "1" ? true: false;
    }

    static getLineLeftBlack(){
       // console.log(LineLeftWhite);

        return LineLeftBlack == "1" ? true: false;
    }

    static getLineLeftWhite(){
        //console.log(LineLeftWhite);
        return LineLeftWhite == "1" ? true: false;
    }

    static getDetectColor(){
        return DetectColor;
    }
   
    static getRunStep(){
       
        return RunStep;
    }

    static setRunStep(data){
       console.log("run step : "+data);
        RunStep=data;
    }


    setStepRun (data) {
        console.log("run step : "+data);
        RunStep=data;
     }



    setData (data) {
       // var tt = new TextDecoder('utf-8').decode(data);
       // var stateData =  data.split(' ');
       //console.log(stateData);
        this.parseButtonData();

        this._bufferData=data;
    }

    setColor (data) {
       
         this.parseColorData();
 
         this._bufferColorData=data;
     }


     parseColorData(){
        if(this._bufferColorData){
            console.log(this._bufferData);//sol sağ side_tilt  sol -60  sağ +60
         //   console.log(this._bufferColorData[3]);//ön arka direct_tilt ön -55 arka +55
          
           // << 3 detect_color 0 >>
            var detectColor = this._bufferColorData[3];
           //console.log(shaking_intensity);


       
            var datasendColor ="None";
            if(detectColor != "0" && detectColor!= "None"){
                
                if(detectColor == "1"){
                    datasendColor ="blue";
                }
                if(detectColor == "4"){
                    datasendColor ="green";
                }
                if(detectColor == "5"){
                    datasendColor ="red";
                }
                if(detectColor == "2"){
                    datasendColor ="white";
                }
                if(detectColor == "1"){
                    datasendColor ="blue";
                }
                if(detectColor == "1"){
                    datasendColor ="blue";
                }
                if(detectColor == "1"){
                    datasendColor ="blue";
                }
                
                this._runtime.startHats('game_when_detect_color', {
                    detect_color:datasendColor
                });

           
        }
        DetectColor=datasendColor;

        
    }
     }

}

module.exports = ReadDeviceData;

