const formatMessage = require("format-message");

const ArgumentType = require("../../extension-support/argument-type");
const BlockType = require("../../extension-support/block-type");
const ProgramModeType = require("../../extension-support/program-mode-type");

const CommonPeripheral = require("../common/common-peripheral");
const Oyun = require('../../util/gameArea');
const ConnectedDeviceClass = require('../../util/connectedDevice');
const {
    iconURI,
    DEVICE_OPT,
    PNPID_LIST,
    SERIAL_CONFIG,
    Pins,
    Level,
    Channels,
    SerialNo,
    Buadrate,
    Eol,
    Mode,
    InterrupMode,
    DataType,

} = require("./constants");

/**
 * Manage communication with a Arduino esp32 peripheral over a OpenBlock Link client socket.
 */
class ArduinoEsp32 extends CommonPeripheral {
    /**
     * Construct a Arduino communication object.
     * @param {Runtime} runtime - the OpenBlock runtime
     * @param {string} deviceId - the id of the extension
     * @param {string} originalDeviceId - the original id of the peripheral, like xxx_arduinoUno
     */
    constructor(runtime, deviceId, originalDeviceId) {
        super(
            runtime,
            deviceId,
            originalDeviceId,
            PNPID_LIST,
            SERIAL_CONFIG,
            DEVICE_OPT
        );
    }
}

/**
 * OpenBlock blocks to interact with a Arduino esp32 peripheral.
 */
class OpenBlockArduinoEsp32Device {
    /**
     * @return {string} - the ID of this extension.
     */
    static get DEVICE_ID() {
        return "arduinoEsp32";
    }

    get PINS_MENU() {
        return [
            {
                text: "IO0",
                value: Pins.IO0,
            },
            {
                text: "IO1",
                value: Pins.IO1,
            },
            {
                text: "IO2",
                value: Pins.IO2,
            },
            {
                text: "IO3",
                value: Pins.IO3,
            },
            {
                text: "IO4",
                value: Pins.IO4,
            },
            {
                text: "IO5",
                value: Pins.IO5,
            },
            // Pins 6 to 11 are used by the ESP32 Flash, not recommended for general use.
            // {
            //     text: 'IO6',
            //     value: Pins.IO6
            // },
            // {
            //     text: 'IO7',
            //     value: Pins.IO7
            // },
            // {
            //     text: 'IO8',
            //     value: Pins.IO8
            // },
            // {
            //     text: 'IO9',
            //     value: Pins.IO9
            // },
            // {
            //     text: 'IO10',
            //     value: Pins.IO10
            // },
            // {
            //     text: 'IO11',
            //     value: Pins.IO11
            // },
            {
                text: "IO12",
                value: Pins.IO12,
            },
            {
                text: "IO13",
                value: Pins.IO13,
            },
            {
                text: "IO14",
                value: Pins.IO14,
            },
            {
                text: "IO15",
                value: Pins.IO15,
            },
            {
                text: "IO16",
                value: Pins.IO16,
            },
            {
                text: "IO17",
                value: Pins.IO17,
            },
            {
                text: "IO18",
                value: Pins.IO18,
            },
            {
                text: "IO19",
                value: Pins.IO19,
            },
            {
                text: "IO21",
                value: Pins.IO21,
            },
            {
                text: "IO22",
                value: Pins.IO22,
            },
            {
                text: "IO23",
                value: Pins.IO23,
            },
            {
                text: "IO25",
                value: Pins.IO25,
            },
            {
                text: "IO26",
                value: Pins.IO26,
            },
            {
                text: "IO27",
                value: Pins.IO27,
            },
            {
                text: "IO32",
                value: Pins.IO32,
            },
            {
                text: "IO33",
                value: Pins.IO33,
            },
            {
                text: "IO34",
                value: Pins.IO34,
            },
            {
                text: "IO35",
                value: Pins.IO35,
            },
            {
                text: "IO36",
                value: Pins.IO36,
            },
            {
                text: "IO39",
                value: Pins.IO39,
            },
        ];
    }

    get OUT_PINS_MENU() {
        return [
            {
                text: "IO0",
                value: Pins.IO0,
            },
            {
                text: "IO1",
                value: Pins.IO1,
            },
            {
                text: "IO2",
                value: Pins.IO2,
            },
            {
                text: "IO3",
                value: Pins.IO3,
            },
            {
                text: "IO4",
                value: Pins.IO4,
            },
            {
                text: "IO5",
                value: Pins.IO5,
            },
            // Pins 6 to 11 are used by the ESP32 Flash, not recommended for general use.
            // {
            //     text: 'IO6',
            //     value: Pins.IO6
            // },
            // {
            //     text: 'IO7',
            //     value: Pins.IO7
            // },
            // {
            //     text: 'IO8',
            //     value: Pins.IO8
            // },
            // {
            //     text: 'IO9',
            //     value: Pins.IO9
            // },
            // {
            //     text: 'IO10',
            //     value: Pins.IO10
            // },
            // {
            //     text: 'IO11',
            //     value: Pins.IO11
            // },
            {
                text: "IO12",
                value: Pins.IO12,
            },
            {
                text: "IO13",
                value: Pins.IO13,
            },
            {
                text: "IO14",
                value: Pins.IO14,
            },
            {
                text: "IO15",
                value: Pins.IO15,
            },
            {
                text: "IO16",
                value: Pins.IO16,
            },
            {
                text: "IO17",
                value: Pins.IO17,
            },
            {
                text: "IO18",
                value: Pins.IO18,
            },
            {
                text: "IO19",
                value: Pins.IO19,
            },
            {
                text: "IO21",
                value: Pins.IO21,
            },
            {
                text: "IO22",
                value: Pins.IO22,
            },
            {
                text: "IO23",
                value: Pins.IO23,
            },
            {
                text: "IO25",
                value: Pins.IO25,
            },
            {
                text: "IO26",
                value: Pins.IO26,
            },
            {
                text: "IO27",
                value: Pins.IO27,
            },
            {
                text: "IO32",
                value: Pins.IO32,
            },
            {
                text: "IO33",
                value: Pins.IO33,
            },
        ];
    }

    get MODE_MENU() {
        return [
            {
                text: formatMessage({
                    id: "arduinoEsp32.modeMenu.input",
                    default: "input",
                    description: "label for input pin mode",
                }),
                value: Mode.Input,
            },
            {
                text: formatMessage({
                    id: "arduinoEsp32.modeMenu.output",
                    default: "output",
                    description: "label for output pin mode",
                }),
                value: Mode.Output,
            },
            {
                text: formatMessage({
                    id: "arduinoEsp32.modeMenu.inputPullup",
                    default: "input-pullup",
                    description: "label for input-pullup pin mode",
                }),
                value: Mode.InputPullup,
            },
            {
                text: formatMessage({
                    id: "arduinoEsp32.modeMenu.inputPulldown",
                    default: "input-pulldown",
                    description: "label for input-pulldown pin mode",
                }),
                value: Mode.InputPulldown,
            },
        ];
    }

    get ANALOG_PINS_MENU() {
        return [
            {
                text: "IO0",
                value: Pins.IO0,
            },
            {
                text: "IO2",
                value: Pins.IO2,
            },
            {
                text: "IO4",
                value: Pins.IO4,
            },
            {
                text: "IO12",
                value: Pins.IO12,
            },
            {
                text: "IO13",
                value: Pins.IO13,
            },
            {
                text: "IO14",
                value: Pins.IO14,
            },
            {
                text: "IO15",
                value: Pins.IO15,
            },
            {
                text: "IO25",
                value: Pins.IO25,
            },
            {
                text: "IO26",
                value: Pins.IO26,
            },
            {
                text: "IO27",
                value: Pins.IO27,
            },
            {
                text: "IO32",
                value: Pins.IO32,
            },
            {
                text: "IO33",
                value: Pins.IO33,
            },
            {
                text: "IO34",
                value: Pins.IO34,
            },
            {
                text: "IO35",
                value: Pins.IO35,
            },
            {
                text: "IO36",
                value: Pins.IO36,
            },
            {
                text: "IO39",
                value: Pins.IO39,
            },
        ];
    }

    get LEVEL_MENU() {
        return [
            {
                text: formatMessage({
                    id: "arduinoEsp32.levelMenu.high",
                    default: "high",
                    description: "label for high level",
                }),
                value: Level.High,
            },
            {
                text: formatMessage({
                    id: "arduinoEsp32.levelMenu.low",
                    default: "low",
                    description: "label for low level",
                }),
                value: Level.Low,
            },
        ];
    }

    get LEDC_CHANNELS_MENU() {
        return [
            {
                text: "CH0 (LT0)",
                value: Channels.CH0,
            },
            {
                text: "CH1 (LT0)",
                value: Channels.CH1,
            },
            {
                text: "CH2 (LT1)",
                value: Channels.CH2,
            },
            {
                text: "CH3 (LT1)",
                value: Channels.CH3,
            },
            {
                text: "CH4 (LT2)",
                value: Channels.CH4,
            },
            {
                text: "CH5 (LT2)",
                value: Channels.CH5,
            },
            {
                text: "CH6 (LT3)",
                value: Channels.CH6,
            },
            {
                text: "CH7 (LT3)",
                value: Channels.CH7,
            },
            {
                text: "CH8 (HT0)",
                value: Channels.CH8,
            },
            {
                text: "CH9 (HT0)",
                value: Channels.CH9,
            },
            {
                text: "CH10 (HT1)",
                value: Channels.CH10,
            },
            {
                text: "CH11 (HT1)",
                value: Channels.CH11,
            },
            {
                text: "CH12 (HT2)",
                value: Channels.CH12,
            },
            {
                text: "CH13 (HT2)",
                value: Channels.CH13,
            },
            {
                text: "CH14 (HT3)",
                value: Channels.CH14,
            },
            {
                text: "CH15 (HT3)",
                value: Channels.CH15,
            },
        ];
    }

    get DAC_PINS_MENU() {
        return [
            {
                text: "IO25",
                value: Pins.IO25,
            },
            {
                text: "IO26",
                value: Pins.IO26,
            },
        ];
    }

    get TOUCH_PINS_MENU() {
        return [
            {
                text: "IO0",
                value: Pins.IO0,
            },
            {
                text: "IO2",
                value: Pins.IO2,
            },
            {
                text: "IO4",
                value: Pins.IO4,
            },
            {
                text: "IO12",
                value: Pins.IO12,
            },
            {
                text: "IO13",
                value: Pins.IO13,
            },
            {
                text: "IO14",
                value: Pins.IO14,
            },
            {
                text: "IO15",
                value: Pins.IO15,
            },
            {
                text: "IO27",
                value: Pins.IO27,
            },
            {
                text: "IO32",
                value: Pins.IO32,
            },
            {
                text: "IO33",
                value: Pins.IO33,
            },
        ];
    }

    get INTERRUP_MODE_MENU() {
        return [
            {
                text: formatMessage({
                    id: "arduinoEsp32.InterrupModeMenu.risingEdge",
                    default: "rising edge",
                    description: "label for rising edge interrup",
                }),
                value: InterrupMode.Rising,
            },
            {
                text: formatMessage({
                    id: "arduinoEsp32.InterrupModeMenu.fallingEdge",
                    default: "falling edge",
                    description: "label for falling edge interrup",
                }),
                value: InterrupMode.Falling,
            },
            {
                text: formatMessage({
                    id: "arduinoEsp32.InterrupModeMenu.changeEdge",
                    default: "change edge",
                    description: "label for change edge interrup",
                }),
                value: InterrupMode.Change,
            },
            {
                text: formatMessage({
                    id: "arduinoEsp32.InterrupModeMenu.low",
                    default: "low level",
                    description: "label for low level interrup",
                }),
                value: InterrupMode.LowLevel,
            },
            {
                text: formatMessage({
                    id: "arduinoEsp32.InterrupModeMenu.high",
                    default: "high level",
                    description: "label for high level interrup",
                }),
                value: InterrupMode.HighLevel,
            },
        ];
    }

    get SERIAL_NO_MENU() {
        return [
            {
                text: "0",
                value: SerialNo.Serial0,
            },
            // Usually IO9/10 is reserved for flash chip.
            // {
            //     text: '1',
            //     value: SerialNo.Serial1
            // },
            {
                text: "2",
                value: SerialNo.Serial2,
            },
        ];
    }

    get BAUDTATE_MENU() {
        return [
            {
                text: "4800",
                value: Buadrate.B4800,
            },
            {
                text: "9600",
                value: Buadrate.B9600,
            },
            {
                text: "19200",
                value: Buadrate.B19200,
            },
            {
                text: "38400",
                value: Buadrate.B38400,
            },
            {
                text: "57600",
                value: Buadrate.B57600,
            },
            {
                text: "76800",
                value: Buadrate.B76800,
            },
            {
                text: "115200",
                value: Buadrate.B115200,
            },
        ];
    }

    get EOL_MENU() {
        return [
            {
                text: formatMessage({
                    id: "arduinoEsp32.eolMenu.warp",
                    default: "warp",
                    description: "label for warp print",
                }),
                value: Eol.Warp,
            },
            {
                text: formatMessage({
                    id: "arduinoEsp32.eolMenu.noWarp",
                    default: "no-warp",
                    description: "label for no warp print",
                }),
                value: Eol.NoWarp,
            },
        ];
    }

    get DATA_TYPE_MENU() {
        return [
            {
                text: formatMessage({
                    id: "arduinoEsp32.dataTypeMenu.integer",
                    default: "integer",
                    description: "label for integer",
                }),
                value: DataType.Integer,
            },
            {
                text: formatMessage({
                    id: "arduinoEsp32.dataTypeMenu.decimal",
                    default: "decimal",
                    description: "label for decimal number",
                }),
                value: DataType.Decimal,
            },
            {
                text: formatMessage({
                    id: "arduinoEsp32.dataTypeMenu.string",
                    default: "string",
                    description: "label for string",
                }),
                value: DataType.String,
            },
        ];
    }

    /**
     * Construct a set of Arduino blocks.
     * @param {Runtime} runtime - the OpenBlock runtime.
     * @param {string} originalDeviceId - the original id of the peripheral, like xxx_arduinoUno
     */
    constructor(runtime, originalDeviceId) {
        /**
         * The OpenBlock runtime.
         * @type {Runtime}
         */
        this.runtime = runtime;

        // Create a new Arduino esp32 peripheral instance
        this._peripheral = new ArduinoEsp32(
            this.runtime,
            OpenBlockArduinoEsp32Device.DEVICE_ID,
            originalDeviceId
        );

        ConnectedDeviceClass.setDevice(this._peripheral);

    }

    /**
     * @returns {Array.<object>} metadata for this extension and its blocks.
     */
    getInfo() {
       
        return [
             /*
            {
                id: "Emre2",
                name: formatMessage({
                    id: "esp32Arduino.category.deneme",
                    default: "Deneme",
                    description:
                        "The name of the esp32 arduino device pin category",
                }),
                color1: "#4C97FF",
                color2: "#3373CC",
                color3: "#3373CC",
                showStatusButton: true,
                programMode: [ProgramModeType.REALTIME],
                blocks: [
                    /*
                    {
                        opcode: "exampleWithInlineImage",
                        blockType: BlockType.COMMAND,
                        text: "[CLOCKWISE] block with image  inline",
                        arguments: {
                            CLOCKWISE: {
                                type: ArgumentType.IMAGE,
                                dataURI: iconURI,
                                width: 80,
                                height: 80,
                            },
                        },
                    },
                    {
                        opcode: "showImage",
                        text: formatMessage({
                            id: "microbit.display.showImage22522",
                            default: "[MATRIX]",
                            description: "microbit show image",
                        }),
                        blockType: BlockType.COMMAND,
                        arguments: {
                            MATRIX: {
                                type: ArgumentType.MATRIXJR,
                                defaultValue:
                                    "00000000000000000000000000000000001111100111110000001100000110000001000000100000001111100111110000000000000000000000000000000000",
                            },
                        },
                    },
                    {
                        opcode: "moveForward",
                        blockType: BlockType.COMMAND,
                        text: "[NUM] kare ileri git",
                        arguments: {
                            NUM: {
                                type: ArgumentType.NUMBER,
                                defaultValue: 1,
                            },
                        },
                    },
                    {
                        opcode: "deneme",
                        blockType: BlockType.HAT,
                        isEdgeActivated: false,
                        arguments: {
                            int: {
                                type: ArgumentType.STRING,
                                defaultValue: "deneme",
                            },
                        },
                        text: "label [int] ",
                    },
                   
                    {
                        opcode: 'showImage22',
                        text: formatMessage({
                            id: 'microbit.display.showImage',
                            default: 'show image [VALUE]',
                            description: 'microbit show image'
                        }),
                        blockType: BlockType.COMMAND,
                        arguments: {
                            VALUE: {
                                type: ArgumentType.MATRIX,
                                defaultValue: '00000000000000000000000000000000001111100111110000001100000110000001000000100000001111100111110000000000000000000000000000000000'
                            }
                        }
                    },
                   
                ],
                menus: {
                    pins: {
                        items: this.PINS_MENU,
                    },
                    outPins: {
                        items: this.OUT_PINS_MENU,
                    },
                    mode: {
                        items: this.MODE_MENU,
                    },
                    analogPins: {
                        items: this.ANALOG_PINS_MENU,
                    },
                    level: {
                        acceptReporters: true,
                        items: this.LEVEL_MENU,
                    },
                    ledcChannels: {
                        items: this.LEDC_CHANNELS_MENU,
                    },
                    dacPins: {
                        items: this.DAC_PINS_MENU,
                    },
                    touchPins: {
                        items: this.TOUCH_PINS_MENU,
                    },
                    interruptMode: {
                        items: this.INTERRUP_MODE_MENU,
                    },
                },
            },
             */
        ];
       
    }

    /**
     * Set pin mode.
     * @param {object} args - the block's arguments.
     * @return {Promise} - a Promise that resolves after the set pin mode is done.
     */
    setPinMode(args) {
        const degrees = Cast.toNumber(args.DEGREES);
        const response = util.target.setDirection(
            util.target.direction + degrees
        );

        response === 1 ? this._peripheral.write("<155>") : null;
        //  return Promise.resolve();
    }

    showImage(args) {}
   async exampleWithInlineImage() {
        var currentGame =  Oyun.getGame();
        await  currentGame.events.emit("moveEvent2", "down-right");

    }

    turnLeft(args, util) {
        const degrees = Cast.toNumber(args.DEGREES);
        util.target.setDirection(util.target.direction - degrees);
    }
    /**
     * Set pin digital out level.
     * @param {object} args - the block's arguments.
     * @return {Promise} - a Promise that resolves after the set pin digital out level is done.
     */
    setDigitalOutput(args) {
        this._peripheral.write("<150,1>");
    }

    /**
     * Set pin pwm out value.
     * @param {object} args - the block's arguments.
     * @return {Promise} - a Promise that resolves after the set pin pwm out value is done.
     */
    setPwmOutput(args) {
        alert("dışarda");
        let okunan = this._peripheral.readData();
    }

    /**
     * Read pin digital level.
     * @param {object} args - the block's arguments.
     * @return {boolean} - true if read high level, false if read low level.
     */
    readDigitalPin(args) {
        return this._peripheral.readDigitalPin(args.PIN);
    }

    /**
     * Read analog pin.
     * @param {object} args - the block's arguments.
     * @return {number} - analog value fo the pin.
     */
    readAnalogPin(args) {
        let okunan = this._peripheral.readData(args.PIN);
    }
    getForce() {
        return this._peripheral.readData();
    }

    sensor1() {
        return this._peripheral.sensor1();
    }
    sensor2() {
        return this._peripheral.sensor2();
    }
    sensor3() {
        return this._peripheral.sensor3();
    }
    sensor4() {
        return this._peripheral.sensor4();
    }
    sensor5() {
        return this._peripheral.sensor5();
    }
    sensor6() {
        return this._peripheral.sensor6();
    }
    sensor7() {
        return this._peripheral.sensor7();
    }
    sensor8() {
        return this._peripheral.sensor8();
    }

    deneme1() {
        return this._peripheral.readData();
    }
    deneme2() {
        return 20;
        return this._peripheral.readDigitalPin(36);
    }
    /**
     * Set servo out put.
     * @param {object} args - the block's arguments.
     * @return {Promise} - a Promise that resolves after the set servo out value is done.
     */
    setServoOutput(args) {
        this._peripheral.setServoOutput(args.PIN, args.OUT);
        return Promise.resolve();
    }
}

module.exports = OpenBlockArduinoEsp32Device;
