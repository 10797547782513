const blockSeparator = '<sep gap="36"/>'; // At default scale, about 28px
const categorySeparator = '<sep gap="36"/>';

export const sound = function (isInitialSetup, isStage, targetId, soundName) {
    return `
    <category name="%{BKY_CATEGORY_SOUND}" id="sound" colour="#D65CD6" secondaryColour="#BD42BD">

    <block type="sound_melodi">
    <value name="data">
        <shadow type="dropdown_sound_melodi">
            <field name="data"></field>
        </shadow>
    </value>
</block>

<block type="sound_melodi">
    <value name="data">
        <shadow type="note">
            <field name="NOTE">60</field>
        </shadow>  
    </value>
</block>

<block type="sound_off">
  
</block>


<block type="custom_sound">
    <value name="value">
        <shadow type="dropdown_customsound">
            <field name="value"></field>
        </shadow>
    </value>
</block>

<block type="play_sound">
    <value name="SOUND">
        <shadow type="dropdown_sound">
            <field name="SOUND"></field>
        </shadow>
    </value>
</block>



${categorySeparator}
        </category>
        `;
    };
    
        // <block id="${targetId}_sound_playuntildone" type="sound_playuntildone">
        //     <value name="SOUND_MENU">
        //         <shadow type="sound_sounds_menu">
        //             <field name="SOUND_MENU">${soundName}</field>
        //         </shadow>
        //     </value>
        // </block>
        // <block id="${targetId}_sound_play" type="sound_play">
        //     <value name="SOUND_MENU">
        //         <shadow type="sound_sounds_menu">
        //             <field name="SOUND_MENU">${soundName}</field>
        //         </shadow>
        //     </value>
        // </block>
        // <block type="sound_stopallsounds"/>
        // ${blockSeparator}
        // <block type="sound_changeeffectby">
        //     <value name="VALUE">
        //         <shadow type="math_number">
        //             <field name="NUM">10</field>
        //         </shadow>
        //     </value>
        // </block>
        // <block type="sound_seteffectto">
        //     <value name="VALUE">
        //         <shadow type="math_number">
        //             <field name="NUM">100</field>
        //         </shadow>
        //     </value>
        // </block>
        // <block type="sound_cleareffects"/>
        // ${blockSeparator}
        // <block type="sound_changevolumeby">
        //     <value name="VOLUME">
        //         <shadow type="math_number">
        //             <field name="NUM">-10</field>
        //         </shadow>
        //     </value>
        // </block>
        // <block type="sound_setvolumeto">
        //     <value name="VOLUME">
        //         <shadow type="math_number">
        //             <field name="NUM">100</field>
        //         </shadow>
        //     </value>
        // </block>
        // <block id="${targetId}_volume" type="sound_volume"/>