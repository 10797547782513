
let workspace_ = null;

class MyWorkspaceClass {
   
    static setWorkspace (works) {
        workspace_ = works;
        }
    
    static getWorkspace () {
            return workspace_; 
    }

}

module.exports = MyWorkspaceClass;
