import PropTypes from 'prop-types';
import React from 'react';
import keyMirror from 'keymirror';
import classNames from "classnames";
import {
    defineMessages,
    FormattedMessage,
    injectIntl,
    intlShape,
} from "react-intl";
import Box from '../box/box.jsx';
import Modal from '../../containers/modal.jsx';
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import tabStyles from "react-tabs/style/react-tabs.css";

import ScanningStep from '../../containers/scanning-step.jsx';
import AutoScanningStep from '../../containers/auto-scanning-step.jsx';
import AutoScanningStepSerial from '../../containers/auto-scanning-step-serial.jsx';
import ConnectingStep from './connecting-step.jsx';
import ConnectedStep from './connected-step.jsx';
import ConnectedStepSerial from './connected-step-serial.jsx';
import ErrorStep from './error-step.jsx';
import UnavailableStep from './unavailable-step.jsx';
import UnavailableStepSerial from './unavailable-step.-serial.jsx';


import styles from './connection-modal.css';

const PHASES = keyMirror({
    scanning: null,
    connecting: null,
    connected: null,
    error: null,
    unavailable: null
});

const tabClassNames = {
    tabs: styles.tabs,
    tab: classNames(tabStyles.reactTabsTab, styles.tab),
    tabList: classNames(tabStyles.reactTabsTabList, styles.tabList),
    tabPanel: classNames(tabStyles.reactTabsTabPanel, styles.tabPanel),
    tabPanelSelected: classNames(
        tabStyles.reactTabsTabPanelSelected,
        styles.isSelected
    ),
    tabSelected: classNames(
        tabStyles.reactTabsTabSelected,
        styles.isSelected
    ),
};
 
 
const ConnectionModalComponent = props => (
    <Modal
        className={styles.modalContent}
        contentLabel={props.name}
        headerClassName={styles.header}
        headerImage={props.connectionSmallIconURL}
        id="connectionModal"
        onHelp={props.onHelp}
        onRequestClose={props.onCancel}
    >
        <Box className={styles.body}>
        {
            props.phase === PHASES.connected && props.vm.runtime._device.search("ble")  == -1 ? <ConnectedStepSerial {...props} /> :  
            props.phase === PHASES.connected && props.vm.runtime._device.search("ble")  != -1 ?    props.phase === PHASES.connected && <ConnectedStep {...props} /> : 
             
            <Tabs
                    className={tabClassNames.tabs}
                    selectedTabClassName={
                    tabClassNames.tabSelected
                    }
                    selectedTabPanelClassName={
                        tabClassNames.tabPanelSelected
                    }
                
                    onSelect={(index)=>{
                        console.log(props);
                    console.log("zbot connect:");
                    console.log(props.vm.runtime._device);

                            var id = index == 0 ? props.title : props.title + "ble";
                            var deviceType = props.vm.runtime._deviceType;
                            var pnpidList = props.vm.runtime._pnpIdList;
                            var isBle = index == 0 ? false : true;
                        


                            props.vm.extensionManager.loadDeviceURL(id, deviceType, pnpidList,isBle).then(() => {
                                props.vm.extensionManager.getDeviceExtensionsList().then(() => {
                                    // TODO: Add a event for install device extension
                                    // the large extensions will take many times to load
                                    // A loading interface should be launched.
                                // props.vm.installDeviceExtensions(Object.assign([], deviceExtensions));
                                });
                            
                            });

                        }
                    }

                    >
                <TabList
                    className={tabClassNames.tabList}
                >
                                                
                    <Tab className={tabClassNames.tab}>
                        <FormattedMessage
                            defaultMessage="USB"
                            description="Button to get to the code panel"
                            id="gui.connectionModal.serialName"
                    />
                                                
                    </Tab>
                        <Tab className={tabClassNames.tab}>                       
                        <FormattedMessage
                            defaultMessage="Bluetooth"
                            description="Button to get to the code panel"
                            id="gui.connectionModal.bleName"
                        />
                    </Tab>
                                            
                    </TabList>


                    <TabPanel>
                        {props.phase === PHASES.scanning && !props.useAutoScan && <ScanningStep {...props} />}
                        {props.phase === PHASES.scanning && props.useAutoScan && <AutoScanningStepSerial {...props} />}
                        {props.phase === PHASES.connecting && <ConnectingStep {...props} />}
                        {props.phase === PHASES.connected && <ConnectedStepSerial {...props} />}
                        {props.phase === PHASES.error && <ErrorStep {...props} />}
                        {props.phase === PHASES.unavailable && <UnavailableStepSerial {...props} />}
                    </TabPanel>

                    <TabPanel>
                        {props.phase === PHASES.scanning && !props.useAutoScan && <ScanningStep {...props} />}
                        {props.phase === PHASES.scanning && props.useAutoScan && <AutoScanningStep {...props} />}
                        {props.phase === PHASES.connecting && <ConnectingStep {...props} />}
                        {props.phase === PHASES.connected && <ConnectedStep {...props} />}
                        {props.phase === PHASES.error && <ErrorStep {...props} />}
                        {props.phase === PHASES.unavailable && <UnavailableStep {...props} />}

                    
                    </TabPanel>

            </Tabs>
        } 
          
        </Box>

       
    </Modal>
);




ConnectionModalComponent.propTypes = {
    connectingMessage: PropTypes.node.isRequired,
    connectionSmallIconURL: PropTypes.string,
    connectionTipIconURL: PropTypes.string,
    name: PropTypes.node,
    onCancel: PropTypes.func.isRequired,
    onHelp: PropTypes.func.isRequired,
    phase: PropTypes.oneOf(Object.keys(PHASES)).isRequired,
    title: PropTypes.string.isRequired,
    useAutoScan: PropTypes.bool.isRequired
};

ConnectionModalComponent.defaultProps = {
    connectingMessage: 'Connecting'
};

export {
    ConnectionModalComponent as default,
    PHASES
};