/*** IMPORTS FROM imports-loader ***/
var Blockly = require("../shim/blocks_compressed_vertical");
var goog = require("../shim/blockly_compressed_vertical.goog");

/**
 * @license
 * Visual Blocks Language
 *
 * Copyright 2012 Google Inc.
 * https://developers.google.com/blockly/
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *   http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

/**
 * @fileoverview English strings.
 * @author ascii@media.mit.edu (Andrew Sliwinski)
 *
 * After modifying this file, run `npm run translate` from the root directory
 * to regenerate `./msg/json/en.json`.
 * IMPORTANT:
 * All message strings must use single quotes for the scripts to work properly
 */
'use strict';

goog.provide('Blockly.Msg.tr');

goog.require('Blockly.Msg');

// Control blocks
Blockly.Msg.CONTROL_FOREVER = 'forever';
Blockly.Msg.CONTROL_REPEAT = 'repeat %1';
Blockly.Msg.CONTROL_IF = 'if %1 then';
Blockly.Msg.CONTROL_ELSE = 'else';
Blockly.Msg.CONTROL_STOP = 'stop';
Blockly.Msg.CONTROL_STOP_ALL = 'all';
Blockly.Msg.CONTROL_STOP_THIS = 'this script';
Blockly.Msg.CONTROL_STOP_OTHER = 'other scripts in sprite';
Blockly.Msg.CONTROL_WAIT = 'wait %1 seconds';
Blockly.Msg.CONTROL_WAITUNTIL = 'wait until %1';
Blockly.Msg.CONTROL_REPEATUNTIL = 'repeat until %1';
Blockly.Msg.CONTROL_WHILE = 'while %1';
Blockly.Msg.CONTROL_FOREACH = 'for each %1 in %2';
Blockly.Msg.CONTROL_STARTASCLONE = 'when I start as a clone';
Blockly.Msg.CONTROL_CREATECLONEOF = 'create clone of %1';
Blockly.Msg.CONTROL_CREATECLONEOF_MYSELF = 'myself';
Blockly.Msg.CONTROL_DELETETHISCLONE = 'delete this clone';
Blockly.Msg.CONTROL_COUNTER = 'counter';
Blockly.Msg.CONTROL_INCRCOUNTER = 'increment counter';
Blockly.Msg.CONTROL_CLEARCOUNTER = 'clear counter';
Blockly.Msg.CONTROL_ALLATONCE = 'all at once';

// Data blocks
Blockly.Msg.DATA_SETVARIABLETO = 'set %1 to %2';
Blockly.Msg.DATA_CHANGEVARIABLEBY = 'change %1 by %2';
Blockly.Msg.DATA_SHOWVARIABLE = 'show variable %1';
Blockly.Msg.DATA_HIDEVARIABLE = 'hide variable %1';
Blockly.Msg.DATA_ADDTOLIST = 'add %1 to %2';
Blockly.Msg.DATA_DELETEOFLIST = 'delete %1 of %2';
Blockly.Msg.DATA_DELETEALLOFLIST = 'delete all of %1';
Blockly.Msg.DATA_INSERTATLIST = 'insert %1 at %2 of %3';
Blockly.Msg.DATA_REPLACEITEMOFLIST = 'replace item %1 of %2 with %3';
Blockly.Msg.DATA_ITEMOFLIST = 'item %1 of %2';
Blockly.Msg.DATA_ITEMNUMOFLIST = 'item # of %1 in %2';
Blockly.Msg.DATA_LENGTHOFLIST = 'length of %1';
Blockly.Msg.DATA_LISTCONTAINSITEM = '%1 contains %2?';
Blockly.Msg.DATA_SHOWLIST = 'show list %1';
Blockly.Msg.DATA_HIDELIST = 'hide list %1';
Blockly.Msg.DATA_INDEX_ALL = 'all';
Blockly.Msg.DATA_INDEX_LAST = 'last';
Blockly.Msg.DATA_INDEX_RANDOM = 'random';

// Event blocks
Blockly.Msg.EVENT_WHENARDUINOBEGIN = 'when Pybot begin';
Blockly.Msg.EVENT_WHENMICROBITBEGIN = 'when Microbit begin';
Blockly.Msg.EVENT_WHENMICROBITBUTTONPRESSED = 'when button %1 pressed';
Blockly.Msg.EVENT_WHENMICROBITPINBEINGTOUCHED = 'when pin %1 being touched';
Blockly.Msg.EVENT_WHENMICROBITGESTURE = 'when the gestrue %1';
Blockly.Msg.EVENT_WHENFLAGCLICKED = 'when %1 clicked';
Blockly.Msg.EVENT_WHENTHISSPRITECLICKED = 'when this sprite clicked';
Blockly.Msg.EVENT_WHENSTAGECLICKED = 'when stage clicked';
Blockly.Msg.EVENT_WHENTOUCHINGOBJECT = 'when this sprite touches %1';
Blockly.Msg.EVENT_WHENBROADCASTRECEIVED = 'when I receive %1';
Blockly.Msg.EVENT_WHENBACKDROPSWITCHESTO = 'when backdrop switches to %1';
Blockly.Msg.EVENT_WHENGREATERTHAN = 'when %1 > %2';
Blockly.Msg.EVENT_WHENGREATERTHAN_TIMER = 'timer';
Blockly.Msg.EVENT_WHENGREATERTHAN_LOUDNESS = 'loudness';
Blockly.Msg.EVENT_BROADCAST = 'broadcast %1';
Blockly.Msg.EVENT_BROADCASTANDWAIT = 'broadcast %1 and wait';
Blockly.Msg.EVENT_WHENKEYPRESSED = 'when %1 key pressed';
Blockly.Msg.EVENT_WHENMICROBITGESTURE_SHAKEN = 'shaken';
Blockly.Msg.EVENT_WHENMICROBITGESTURE_TILTEDUPWARD = 'tilted upward';
Blockly.Msg.EVENT_WHENMICROBITGESTURE_TILTEDDOWNWARD = 'tilted downward';
Blockly.Msg.EVENT_WHENMICROBITGESTURE_TILTEDDLEFTWARD = 'tilted leftward';
Blockly.Msg.EVENT_WHENMICROBITGESTURE_TILTEDDRIGHTWARD = 'tilted rightward';
Blockly.Msg.EVENT_WHENMICROBITGESTURE_FACEUP = 'face up';
Blockly.Msg.EVENT_WHENMICROBITGESTURE_FACEDOWN = 'face down';
Blockly.Msg.EVENT_WHENMICROBITGESTURE_FREEFALL = 'freefall';
Blockly.Msg.EVENT_WHENKEYPRESSED_SPACE = 'space';
Blockly.Msg.EVENT_WHENKEYPRESSED_LEFT = 'left arrow';
Blockly.Msg.EVENT_WHENKEYPRESSED_RIGHT = 'right arrow';
Blockly.Msg.EVENT_WHENKEYPRESSED_DOWN = 'down arrow';
Blockly.Msg.EVENT_WHENKEYPRESSED_UP = 'up arrow';
Blockly.Msg.EVENT_WHENKEYPRESSED_ANY = 'any';

// Looks blocks
Blockly.Msg.LOOKS_SAYFORSECS = 'say %1 for %2 seconds';
Blockly.Msg.LOOKS_SAY = 'say %1';
Blockly.Msg.LOOKS_HELLO = 'Hello!';
Blockly.Msg.LOOKS_THINKFORSECS = 'think %1 for %2 seconds';
Blockly.Msg.LOOKS_THINK = 'think %1';
Blockly.Msg.LOOKS_HMM = 'Hmm...';
Blockly.Msg.LOOKS_SHOW = 'show';
Blockly.Msg.LOOKS_HIDE = 'hide';
Blockly.Msg.LOOKS_HIDEALLSPRITES = 'hide all sprites';
Blockly.Msg.LOOKS_EFFECT_COLOR = 'color';
Blockly.Msg.LOOKS_EFFECT_FISHEYE = 'fisheye';
Blockly.Msg.LOOKS_EFFECT_WHIRL = 'whirl';
Blockly.Msg.LOOKS_EFFECT_PIXELATE = 'pixelate';
Blockly.Msg.LOOKS_EFFECT_MOSAIC = 'mosaic';
Blockly.Msg.LOOKS_EFFECT_BRIGHTNESS = 'brightness';
Blockly.Msg.LOOKS_EFFECT_GHOST = 'ghost';
Blockly.Msg.LOOKS_CHANGEEFFECTBY = 'change %1 effect by %2';
Blockly.Msg.LOOKS_SETEFFECTTO = 'set %1 effect to %2';
Blockly.Msg.LOOKS_CLEARGRAPHICEFFECTS = 'clear graphic effects';
Blockly.Msg.LOOKS_CHANGESIZEBY = 'change size by %1';
Blockly.Msg.LOOKS_SETSIZETO = 'set size to %1 %';
Blockly.Msg.LOOKS_SIZE = 'size';
Blockly.Msg.LOOKS_CHANGESTRETCHBY = 'change stretch by %1';
Blockly.Msg.LOOKS_SETSTRETCHTO = 'set stretch to %1 %';
Blockly.Msg.LOOKS_SWITCHCOSTUMETO = 'switch costume to %1';
Blockly.Msg.LOOKS_NEXTCOSTUME = 'next costume';
Blockly.Msg.LOOKS_SWITCHBACKDROPTO = 'switch backdrop to %1';
Blockly.Msg.LOOKS_GOTOFRONTBACK = 'go to %1 layer';
Blockly.Msg.LOOKS_GOTOFRONTBACK_FRONT = 'front';
Blockly.Msg.LOOKS_GOTOFRONTBACK_BACK = 'back';
Blockly.Msg.LOOKS_GOFORWARDBACKWARDLAYERS = 'go %1 %2 layers';
Blockly.Msg.LOOKS_GOFORWARDBACKWARDLAYERS_FORWARD = 'forward';
Blockly.Msg.LOOKS_GOFORWARDBACKWARDLAYERS_BACKWARD = 'backward';
Blockly.Msg.LOOKS_BACKDROPNUMBERNAME = 'backdrop %1';
Blockly.Msg.LOOKS_COSTUMENUMBERNAME = 'costume %1';
Blockly.Msg.LOOKS_NUMBERNAME_NUMBER = 'number';
Blockly.Msg.LOOKS_NUMBERNAME_NAME = 'name';
Blockly.Msg.LOOKS_SWITCHBACKDROPTOANDWAIT = 'switch backdrop to %1 and wait';
Blockly.Msg.LOOKS_NEXTBACKDROP_BLOCK = 'next backdrop';
Blockly.Msg.LOOKS_NEXTBACKDROP = 'next backdrop';
Blockly.Msg.LOOKS_PREVIOUSBACKDROP = 'previous backdrop';
Blockly.Msg.LOOKS_RANDOMBACKDROP = 'random backdrop';

// Motion blocks
Blockly.Msg.MOTION_MOVESTEPS = 'move %1 steps';
Blockly.Msg.MOTION_TURNLEFT = 'turn %1 %2 degrees';
Blockly.Msg.MOTION_TURNRIGHT = 'turn %1 %2 degrees';
Blockly.Msg.MOTION_POINTINDIRECTION = 'point in direction %1';
Blockly.Msg.MOTION_POINTTOWARDS = 'point towards %1';
Blockly.Msg.MOTION_POINTTOWARDS_POINTER = 'mouse-pointer';
Blockly.Msg.MOTION_POINTTOWARDS_RANDOM = 'random direction';
Blockly.Msg.MOTION_GOTO = 'go to %1';
Blockly.Msg.MOTION_GOTO_POINTER = 'mouse-pointer';
Blockly.Msg.MOTION_GOTO_RANDOM = 'random position';
Blockly.Msg.MOTION_GOTOXY = 'go to x: %1 y: %2';
Blockly.Msg.MOTION_GLIDESECSTOXY = 'glide %1 secs to x: %2 y: %3';
Blockly.Msg.MOTION_GLIDETO = 'glide %1 secs to %2';
Blockly.Msg.MOTION_GLIDETO_POINTER = 'mouse-pointer';
Blockly.Msg.MOTION_GLIDETO_RANDOM = 'random position';
Blockly.Msg.MOTION_CHANGEXBY = 'change x by %1';
Blockly.Msg.MOTION_SETX = 'set x to %1';
Blockly.Msg.MOTION_CHANGEYBY = 'change y by %1';
Blockly.Msg.MOTION_SETY = 'set y to %1';
Blockly.Msg.MOTION_IFONEDGEBOUNCE = 'if on edge, bounce';
Blockly.Msg.MOTION_SETROTATIONSTYLE = 'set rotation style %1';
Blockly.Msg.MOTION_SETROTATIONSTYLE_LEFTRIGHT = 'left-right';
Blockly.Msg.MOTION_SETROTATIONSTYLE_DONTROTATE = 'don\'t rotate';
Blockly.Msg.MOTION_SETROTATIONSTYLE_ALLAROUND = 'all around';
Blockly.Msg.MOTION_XPOSITION = 'x position';
Blockly.Msg.MOTION_YPOSITION = 'y position';
Blockly.Msg.MOTION_DIRECTION = 'direction';
Blockly.Msg.MOTION_SCROLLRIGHT = 'scroll right %1';
Blockly.Msg.MOTION_SCROLLUP = 'scroll up %1';
Blockly.Msg.MOTION_ALIGNSCENE = 'align scene %1';
Blockly.Msg.MOTION_ALIGNSCENE_BOTTOMLEFT = 'bottom-left';
Blockly.Msg.MOTION_ALIGNSCENE_BOTTOMRIGHT = 'bottom-right';
Blockly.Msg.MOTION_ALIGNSCENE_MIDDLE = 'middle';
Blockly.Msg.MOTION_ALIGNSCENE_TOPLEFT = 'top-left';
Blockly.Msg.MOTION_ALIGNSCENE_TOPRIGHT = 'top-right';
Blockly.Msg.MOTION_XSCROLL = 'x scroll';
Blockly.Msg.MOTION_YSCROLL = 'y scroll';
Blockly.Msg.MOTION_STAGE_SELECTED = 'Stage selected: no motion blocks';

// Operators blocks
Blockly.Msg.OPERATORS_ADD = '%1 + %2';
Blockly.Msg.OPERATORS_SUBTRACT = '%1 - %2';
Blockly.Msg.OPERATORS_MULTIPLY = '%1 * %2';
Blockly.Msg.OPERATORS_DIVIDE = '%1 / %2';
Blockly.Msg.OPERATORS_RANDOM = 'pick random %1 to %2';
Blockly.Msg.OPERATORS_GT = '%1 > %2';
Blockly.Msg.OPERATORS_LT = '%1 < %2';
Blockly.Msg.OPERATORS_EQUALS = '%1 = %2';
Blockly.Msg.OPERATORS_AND = '%1 and %2';
Blockly.Msg.OPERATORS_OR = '%1 or %2';
Blockly.Msg.OPERATORS_NOT = 'not %1';
Blockly.Msg.OPERATORS_JOIN = 'join %1 %2';
Blockly.Msg.OPERATORS_JOIN_APPLE = 'elma';
Blockly.Msg.OPERATORS_JOIN_BANANA = 'muz';
Blockly.Msg.OPERATORS_LETTEROF = 'letter %1 of %2';
Blockly.Msg.OPERATORS_LETTEROF_APPLE = 'a';
Blockly.Msg.OPERATORS_LENGTH = 'length of %1';
Blockly.Msg.OPERATORS_CONTAINS = '%1 contains %2?';
Blockly.Msg.OPERATORS_MOD = '%1 mod %2';
Blockly.Msg.OPERATORS_ROUND = 'round %1';
Blockly.Msg.OPERATORS_MATHOP = '%1 of %2';
Blockly.Msg.OPERATORS_MATHOP_ABS = 'abs';
Blockly.Msg.OPERATORS_MATHOP_FLOOR = 'floor';
Blockly.Msg.OPERATORS_MATHOP_CEILING = 'ceiling';
Blockly.Msg.OPERATORS_MATHOP_SQRT = 'sqrt';
Blockly.Msg.OPERATORS_MATHOP_SIN = 'sin';
Blockly.Msg.OPERATORS_MATHOP_COS = 'cos';
Blockly.Msg.OPERATORS_MATHOP_TAN = 'tan';
Blockly.Msg.OPERATORS_MATHOP_ASIN = 'asin';
Blockly.Msg.OPERATORS_MATHOP_ACOS = 'acos';
Blockly.Msg.OPERATORS_MATHOP_ATAN = 'atan';
Blockly.Msg.OPERATORS_MATHOP_LN = 'ln';
Blockly.Msg.OPERATORS_MATHOP_LOG = 'log';
Blockly.Msg.OPERATORS_MATHOP_EEXP = 'e ^';
Blockly.Msg.OPERATORS_MATHOP_10EXP = '10 ^';

// Procedures blocks
Blockly.Msg.PROCEDURES_DEFINITION = 'define %1';

// Sensing blocks
Blockly.Msg.SENSING_TOUCHINGOBJECT = 'touching %1?';
Blockly.Msg.SENSING_TOUCHINGOBJECT_POINTER = 'mouse-pointer';
Blockly.Msg.SENSING_TOUCHINGOBJECT_EDGE = 'edge';
Blockly.Msg.SENSING_TOUCHINGCOLOR = 'touching color %1?';
Blockly.Msg.SENSING_COLORISTOUCHINGCOLOR = 'color %1 is touching %2?';
Blockly.Msg.SENSING_DISTANCETO = 'distance to %1';
Blockly.Msg.SENSING_DISTANCETO_POINTER = 'mouse-pointer';
Blockly.Msg.SENSING_ASKANDWAIT = 'ask %1 and wait';
Blockly.Msg.SENSING_ASK_TEXT = 'What\'s your name?';
Blockly.Msg.SENSING_ANSWER = 'answer';
Blockly.Msg.SENSING_KEYPRESSED = 'key %1 pressed?';
Blockly.Msg.SENSING_MOUSEDOWN = 'mouse down?';
Blockly.Msg.SENSING_MOUSEX = 'mouse x';
Blockly.Msg.SENSING_MOUSEY = 'mouse y';
Blockly.Msg.SENSING_SETDRAGMODE = 'set drag mode %1';
Blockly.Msg.SENSING_SETDRAGMODE_DRAGGABLE = 'draggable';
Blockly.Msg.SENSING_SETDRAGMODE_NOTDRAGGABLE = 'not draggable';
Blockly.Msg.SENSING_LOUDNESS = 'loudness';
Blockly.Msg.SENSING_LOUD = 'loud?';
Blockly.Msg.SENSING_TIMER = 'timer';
Blockly.Msg.SENSING_RESETTIMER = 'reset timer';
Blockly.Msg.SENSING_OF = '%1 of %2';
Blockly.Msg.SENSING_OF_XPOSITION = 'x position';
Blockly.Msg.SENSING_OF_YPOSITION = 'y position';
Blockly.Msg.SENSING_OF_DIRECTION = 'direction';
Blockly.Msg.SENSING_OF_COSTUMENUMBER = 'costume #';
Blockly.Msg.SENSING_OF_COSTUMENAME = 'costume name';
Blockly.Msg.SENSING_OF_SIZE = 'size';
Blockly.Msg.SENSING_OF_VOLUME = 'volume';
Blockly.Msg.SENSING_OF_BACKDROPNUMBER = 'backdrop #';
Blockly.Msg.SENSING_OF_BACKDROPNAME = 'backdrop name';
Blockly.Msg.SENSING_OF_STAGE = 'Stage';
Blockly.Msg.SENSING_CURRENT = 'current %1';
Blockly.Msg.SENSING_CURRENT_YEAR = 'year';
Blockly.Msg.SENSING_CURRENT_MONTH = 'month';
Blockly.Msg.SENSING_CURRENT_DATE = 'date';
Blockly.Msg.SENSING_CURRENT_DAYOFWEEK = 'day of week';
Blockly.Msg.SENSING_CURRENT_HOUR = 'hour';
Blockly.Msg.SENSING_CURRENT_MINUTE = 'minute';
Blockly.Msg.SENSING_CURRENT_SECOND = 'second';
Blockly.Msg.SENSING_DAYSSINCE2000 = 'days since 2000';
Blockly.Msg.SENSING_USERNAME = 'username';
Blockly.Msg.SENSING_USERID = 'user id';

// Sound blocks
Blockly.Msg.SOUND_PLAY = 'start sound %1';
Blockly.Msg.SOUND_PLAYUNTILDONE = 'play sound %1 until done';
Blockly.Msg.SOUND_STOPALLSOUNDS = 'stop all sounds';
Blockly.Msg.SOUND_SETEFFECTO = 'set %1 effect to %2';
Blockly.Msg.SOUND_CHANGEEFFECTBY = 'change %1 effect by %2';
Blockly.Msg.SOUND_CLEAREFFECTS = 'clear sound effects';
Blockly.Msg.SOUND_EFFECTS_PITCH = 'pitch';
Blockly.Msg.SOUND_EFFECTS_PAN = 'pan left/right';
Blockly.Msg.SOUND_CHANGEVOLUMEBY = 'change volume by %1';
Blockly.Msg.SOUND_SETVOLUMETO = 'set volume to %1%';
Blockly.Msg.SOUND_VOLUME = 'volume';
Blockly.Msg.SOUND_RECORD = 'record...';

// Category labels
Blockly.Msg.CATEGORY_MOTION = 'Hareket';
Blockly.Msg.CATEGORY_LOOKS = 'Ekran';
Blockly.Msg.CATEGORY_SOUND = 'Ses';
Blockly.Msg.CATEGORY_EVENTS = 'Olaylar';
Blockly.Msg.CATEGORY_ENTERTAINMENT  = 'Eğlence';
Blockly.Msg.CATEGORY_CONTROL = 'Kontrol';
Blockly.Msg.CATEGORY_SENSING = 'Sensörler';
Blockly.Msg.CATEGORY_OPERATORS = 'Operatörler';
Blockly.Msg.CATEGORY_VARIABLES = 'Değişkenkler';
Blockly.Msg.CATEGORY_LIGHTING = 'Aydınlatma';
Blockly.Msg.CATEGORY_SPEAKER = 'Hoparlör';
Blockly.Msg.CATEGORY_NETWORK = 'Ağ';
Blockly.Msg.CATEGORY_OLED = 'Oled';
Blockly.Msg.CATEGORY_LIGHTS = 'Aydınlatma';


// Context menus
Blockly.Msg.DUPLICATE = 'Çoğalt';
Blockly.Msg.DELETE = 'Sil';
Blockly.Msg.ADD_COMMENT = 'Yorum Ekle';
Blockly.Msg.REMOVE_COMMENT = 'Yorum Sil';
Blockly.Msg.DELETE_BLOCK = 'Blok Sil';
Blockly.Msg.DELETE_X_BLOCKS = '%1 Blok Sil';
Blockly.Msg.DELETE_ALL_BLOCKS = ' %1 adet blok silinsin mi?';
Blockly.Msg.CLEAN_UP = 'Blokları düzenle';
Blockly.Msg.HELP = 'Yardım';
Blockly.Msg.UNDO = 'Geri';
Blockly.Msg.REDO = 'İleri';
Blockly.Msg.EDIT_PROCEDURE = 'Düzenle';
Blockly.Msg.SHOW_PROCEDURE_DEFINITION = 'Go to definition';
Blockly.Msg.WORKSPACE_COMMENT_DEFAULT_TEXT = 'Say something...';

// Color
Blockly.Msg.COLOUR_HUE_LABEL = 'Renkler';
Blockly.Msg.COLOUR_SATURATION_LABEL = 'Saturation';
Blockly.Msg.COLOUR_BRIGHTNESS_LABEL = 'Brightness';

// Variables
// @todo Remove these once fully managed by Scratch VM / Scratch GUI
Blockly.Msg.CHANGE_VALUE_TITLE = 'Change value:';
Blockly.Msg.RENAME_VARIABLE = 'Rename variable';
Blockly.Msg.RENAME_VARIABLE_TITLE = 'Rename all "%1" variables to:';
Blockly.Msg.RENAME_VARIABLE_MODAL_TITLE = 'Rename Variable';
Blockly.Msg.NEW_VARIABLE = 'Make a Variable';
Blockly.Msg.NEW_VARIABLE_TITLE = 'New variable name:';
Blockly.Msg.VARIABLE_MODAL_TITLE = 'New Variable';
Blockly.Msg.VARIABLE_ALREADY_EXISTS = 'A variable named "%1" already exists.';
Blockly.Msg.VARIABLE_ALREADY_EXISTS_FOR_ANOTHER_TYPE = 'A variable named "%1" already exists for another variable of type "%2".';
Blockly.Msg.DELETE_VARIABLE_CONFIRMATION = 'Delete %1 uses of the "%2" variable?';
Blockly.Msg.CANNOT_DELETE_VARIABLE_PROCEDURE = 'Can\'t delete the variable "%1" because it\'s part of the definition of the function "%2"';
Blockly.Msg.DELETE_VARIABLE = 'Delete the "%1" variable';

// Custom Procedures
// @todo Remove these once fully managed by Scratch VM / Scratch GUI
Blockly.Msg.NEW_PROCEDURE = 'Make a Block';
Blockly.Msg.PROCEDURE_ALREADY_EXISTS = 'A procedure named "%1" already exists.';
Blockly.Msg.PROCEDURE_DEFAULT_NAME = 'block name';
Blockly.Msg.PROCEDURE_USED = 'To delete a block definition, first remove all uses of the block';

// Lists
// @todo Remove these once fully managed by Scratch VM / Scratch GUI
Blockly.Msg.NEW_LIST = 'Make a List';
Blockly.Msg.NEW_LIST_TITLE = 'New list name:';
Blockly.Msg.LIST_MODAL_TITLE = 'New List';
Blockly.Msg.LIST_ALREADY_EXISTS = 'A list named "%1" already exists.';
Blockly.Msg.RENAME_LIST_TITLE = 'Rename all "%1" lists to:';
Blockly.Msg.RENAME_LIST_MODAL_TITLE = 'Rename List';
Blockly.Msg.DEFAULT_LIST_ITEM = 'thing';
Blockly.Msg.DELETE_LIST = 'Delete the "%1" list';
Blockly.Msg.RENAME_LIST = 'Rename list';

// Broadcast Messages
// @todo Remove these once fully managed by Scratch VM / Scratch GUI
Blockly.Msg.NEW_BROADCAST_MESSAGE = 'New message';
Blockly.Msg.NEW_BROADCAST_MESSAGE_TITLE = 'New message name:';
Blockly.Msg.BROADCAST_MODAL_TITLE = 'New Message';
Blockly.Msg.DEFAULT_BROADCAST_MESSAGE_NAME = 'message1';



/////////////////////////////////////

Blockly.Msg.BOT_DATA_SETVARIABLETO = 'set %1 to %2';
Blockly.Msg.BOT_DATA_CHANGEVARIABLEBY = 'change %1 by %2';
Blockly.Msg.BOT_DATA_SHOWVARIABLE = 'show variable %1';
Blockly.Msg.BOT_DATA_HIDEVARIABLE = 'hide variable %1';
Blockly.Msg.BOT_DATA_ADDTOLIST = 'add %1 to %2';
Blockly.Msg.BOT_DATA_DELETEOFLIST = 'delete %1 of %2';
Blockly.Msg.BOT_DATA_DELETEALLOFLIST = 'delete all of %1';
Blockly.Msg.BOT_DATA_INSERTATLIST = 'insert %1 at %2 of %3';
Blockly.Msg.BOT_DATA_REPLACEITEMOFLIST = 'replace item %1 of %2 with %3';
Blockly.Msg.BOT_DATA_ITEMOFLIST = 'item %1 of %2';
Blockly.Msg.BOT_DATA_ITEMNUMOFLIST = 'item # of %1 in %2';
Blockly.Msg.BOT_DATA_LENGTHOFLIST = 'length of %1';
Blockly.Msg.BOT_DATA_LISTCONTAINSITEM = '%1 contains %2?';
Blockly.Msg.BOT_DATA_SHOWLIST = 'show list %1';
Blockly.Msg.BOT_DATA_HIDELIST = 'hide list %1';
Blockly.Msg.BOT_DATA_INDEX_ALL = 'all';
Blockly.Msg.BOT_DATA_INDEX_LAST = 'last';
Blockly.Msg.BOT_DATA_INDEX_RANDOM = 'random';


Blockly.Msg.HEFABOT_CONTROL_WAIT= 'wait %1 seconds';
Blockly.Msg.HEFABOT_CONTROL_FOREVER= 'forever';
Blockly.Msg.HEFABOT_CONTROL_REPEAT='repeat %1';
Blockly.Msg.HEFABOT_CONTROL_IF='if %1 then';
Blockly.Msg.BOT_CONTROL_IF = 'if %1 then';
Blockly.Msg.BOT_CONTROL_ELSE = 'else';
Blockly.Msg.BOT_CONTROL_WAITUNTIL='wait until %1';
Blockly.Msg.BOT_CONTROL_REPEATUNTIL = 'repeat until %1';
Blockly.Msg.BOT_CONTROL_WHILE = 'while %1';
Blockly.Msg.BOT_CONTROL_FOREACH = 'for each %1 in %2';
Blockly.Msg.BOT_CONTROL_STARTASCLONE = 'when I start as a clone';
Blockly.Msg.BOT_CONTROL_CREATECLONEOF = 'create clone of %1';
Blockly.Msg.BOT_CONTROL_CREATECLONEOF_MYSELF = 'myself';

Blockly.Msg.BOT_OPERATORS_ADD = '%1 + %2';
Blockly.Msg.BOT_OPERATORS_SUBTRACT = '%1 - %2';
Blockly.Msg.BOT_OPERATORS_MULTIPLY = '%1 * %2';
Blockly.Msg.BOT_OPERATORS_DIVIDE = '%1 / %2';
Blockly.Msg.BOT_OPERATORS_RANDOM = 'pick random %1 to %2';
Blockly.Msg.BOT_OPERATORS_GT = '%1 > %2';
Blockly.Msg.BOT_OPERATORS_LT = '%1 < %2';
Blockly.Msg.BOT_OPERATORS_EQUALS = '%1 = %2';
Blockly.Msg.BOT_OPERATORS_AND = '%1 and %2';
Blockly.Msg.BOT_OPERATORS_OR = '%1 or %2';
Blockly.Msg.BOT_OPERATORS_NOT = 'not %1';
Blockly.Msg.BOT_OPERATORS_JOIN = 'join %1 %2';
Blockly.Msg.BOT_OPERATORS_JOIN_APPLE = 'elma';
Blockly.Msg.BOT_OPERATORS_JOIN_BANANA = 'muz';
Blockly.Msg.BOT_OPERATORS_LETTEROF = 'letter %1 of %2';
Blockly.Msg.BOT_OPERATORS_LETTEROF_APPLE = 'a';
Blockly.Msg.BOT_OPERATORS_LENGTH = 'length of %1';
Blockly.Msg.BOT_OPERATORS_CONTAINS = '%1 contains %2?';
Blockly.Msg.BOT_OPERATORS_MOD = '%1 mod %2';
Blockly.Msg.BOT_OPERATORS_ROUND = 'round %1';
Blockly.Msg.BOT_OPERATORS_MATHOP = '%1 of %2';
Blockly.Msg.BOT_OPERATORS_MATHOP_ABS = 'abs';
Blockly.Msg.BOT_OPERATORS_MATHOP_FLOOR = 'floor';
Blockly.Msg.BOT_OPERATORS_MATHOP_CEILING = 'ceiling';
Blockly.Msg.BOT_OPERATORS_MATHOP_SQRT = 'sqrt';
Blockly.Msg.BOT_OPERATORS_MATHOP_SIN = 'sin';
Blockly.Msg.BOT_OPERATORS_MATHOP_COS = 'cos';
Blockly.Msg.BOT_OPERATORS_MATHOP_TAN = 'tan';
Blockly.Msg.BOT_OPERATORS_MATHOP_ASIN = 'asin';
Blockly.Msg.BOT_OPERATORS_MATHOP_ACOS = 'acos';
Blockly.Msg.BOT_OPERATORS_MATHOP_ATAN = 'atan';
Blockly.Msg.BOT_OPERATORS_MATHOP_LN = 'ln';
Blockly.Msg.BOT_OPERATORS_MATHOP_LOG = 'log';
Blockly.Msg.BOT_OPERATORS_MATHOP_EEXP = 'e ^';
Blockly.Msg.BOT_OPERATORS_MATHOP_10EXP = '10 ^';
//
Blockly.Msg.BOT_SENSING_TOUCHINGOBJECT = 'touching %1?';
Blockly.Msg.BOT_SENSING_TOUCHINGOBJECT_POINTER = 'mouse-pointer';
Blockly.Msg.BOT_SENSING_TOUCHINGOBJECT_EDGE = 'edge';
Blockly.Msg.BOT_SENSING_TOUCHINGCOLOR = 'touching color %1?';
Blockly.Msg.BOT_SENSING_COLORISTOUCHINGCOLOR = 'color %1 is touching %2?';
Blockly.Msg.BOT_SENSING_DISTANCETO = 'distance to %1';
Blockly.Msg.BOT_SENSING_DISTANCETO_POINTER = 'mouse-pointer';
Blockly.Msg.BOT_SENSING_ASKANDWAIT = 'ask %1 and wait';
Blockly.Msg.BOT_SENSING_ASK_TEXT = 'What\'s your name?';
Blockly.Msg.BOT_SENSING_ANSWER = 'answer';
Blockly.Msg.BOT_SENSING_KEYPRESSED = 'key %1 pressed?';
Blockly.Msg.BOT_SENSING_MOUSEDOWN = 'mouse down?';
Blockly.Msg.BOT_SENSING_MOUSEX = 'mouse x';
Blockly.Msg.BOT_SENSING_MOUSEY = 'mouse y';
Blockly.Msg.BOT_SENSING_SETDRAGMODE = 'set drag mode %1';
Blockly.Msg.BOT_SENSING_SETDRAGMODE_DRAGGABLE = 'draggable';
Blockly.Msg.BOT_SENSING_SETDRAGMODE_NOTDRAGGABLE = 'not draggable';
Blockly.Msg.BOT_SENSING_LOUDNESS = 'loudness';
Blockly.Msg.BOT_SENSING_LOUD = 'loud?';
Blockly.Msg.BOT_SENSING_TIMER = 'timer';
Blockly.Msg.BOT_SENSING_RESETTIMER = 'reset timer';
Blockly.Msg.BOT_SENSING_OF = '%1 of %2';
Blockly.Msg.BOT_SENSING_OF_XPOSITION = 'x position';
Blockly.Msg.BOT_SENSING_OF_YPOSITION = 'y position';
Blockly.Msg.BOT_SENSING_OF_DIRECTION = 'direction';
Blockly.Msg.BOT_SENSING_OF_COSTUMENUMBER = 'costume #';
Blockly.Msg.BOT_SENSING_OF_COSTUMENAME = 'costume name';
Blockly.Msg.BOT_SENSING_OF_SIZE = 'size';
Blockly.Msg.BOT_SENSING_OF_VOLUME = 'volume';
Blockly.Msg.BOT_SENSING_OF_BACKDROPNUMBER = 'backdrop #';
Blockly.Msg.BOT_SENSING_OF_BACKDROPNAME = 'backdrop name';
Blockly.Msg.BOT_SENSING_OF_STAGE = 'Stage';
Blockly.Msg.BOT_SENSING_CURRENT = 'current %1';
Blockly.Msg.BOT_SENSING_CURRENT_YEAR = 'year';
Blockly.Msg.BOT_SENSING_CURRENT_MONTH = 'month';
Blockly.Msg.BOT_SENSING_CURRENT_DATE = 'date';
Blockly.Msg.BOT_SENSING_CURRENT_DAYOFWEEK = 'day of week';
Blockly.Msg.BOT_SENSING_CURRENT_HOUR = 'hour';
Blockly.Msg.BOT_SENSING_CURRENT_MINUTE = 'minute';
Blockly.Msg.BOT_SENSING_CURRENT_SECOND = 'second';
Blockly.Msg.BOT_SENSING_DAYSSINCE2000 = 'days since 2000';
Blockly.Msg.BOT_SENSING_USERNAME = 'username';
Blockly.Msg.BOT_SENSING_USERID = 'user id';

Blockly.Msg.BOT_SOUND_PLAY = 'start sound %1';
Blockly.Msg.BOT_SOUND_PLAYUNTILDONE = 'play sound %1 until done';
Blockly.Msg.BOT_SOUND_STOPALLSOUNDS = 'stop all sounds';
Blockly.Msg.BOT_SOUND_SETEFFECTO = 'set %1 effect to %2';
Blockly.Msg.BOT_SOUND_CHANGEEFFECTBY = 'change %1 effect by %2';
Blockly.Msg.BOT_SOUND_CLEAREFFECTS = 'clear sound effects';
Blockly.Msg.BOT_SOUND_EFFECTS_PITCH = 'pitch';
Blockly.Msg.BOT_SOUND_EFFECTS_PAN = 'pan left/right';
Blockly.Msg.BOT_SOUND_CHANGEVOLUMEBY = 'change volume by %1';
Blockly.Msg.BOT_SOUND_SETVOLUMETO = 'set volume to %1%';
Blockly.Msg.BOT_SOUND_VOLUME = 'volume';
Blockly.Msg.BOT_SOUND_RECORD = 'record...';

/////// oled

Blockly.Msg.OLED_CATEGORY = 'OLED';
Blockly.Msg.OLED_INIT = '%1 %2 init oled';
Blockly.Msg.OLED_DRAWLINE = '%1 %2 draw line x0: %3 y0: %4, x1: %5 y1：%6 color %7';
Blockly.Msg.OLED_COLOR_WHITE = 'white';
Blockly.Msg.OLED_COLOR_BLACK = 'black';
Blockly.Msg.OLED_COLOR_INVERSE = 'inverse';
Blockly.Msg.OLED_DRAWRECT = '%1 %2 draw rect x: %3 y: %4 width %5 height %6 color %7';
Blockly.Msg.OLED_FILLRECT = '%1 %2 fill rect x: %3 y: %4 width %5 height %6 color %7';
Blockly.Msg.OLED_DRAWCIRCLE = '%1 %2 draw circle x: %3 y: %4 radius %5 color %6';
Blockly.Msg.OLED_FILLCIRCLE = '%1 %2 fill circle x: %3 y: %4 radius %5 color %6';
Blockly.Msg.OLED_DRAWROUNDRECT = '%1 %2 draw round rect x: %3 y: %4 width %5 height %6 radius %7 color %8';
Blockly.Msg.OLED_FILLROUNDRECT = '%1 %2 fill round rect x: %3 y: %4 width %5 height %6 radius %7 color %8';
Blockly.Msg.OLED_DRAWTRIANGLE = '%1 %2 draw triangle x0: %3 y0: %4, x1: %5 y1: %6, x2: %7 y2: %8 color %9';
Blockly.Msg.OLED_FILLTRIANGLE = '%1 %2 fill triangle x0: %3 y0: %4, x1: %5 y1: %6, x2: %7 y2: %8 color %9';
Blockly.Msg.OLED_SETTEXT = '%1 %2 set text size %3 color %4 background color %5';
Blockly.Msg.OLED_SETCURSOR = '%1 %2 set cursor x: %3 y: %4';
Blockly.Msg.OLED_PRINT = '%1 %2 print %3 %4';
Blockly.Msg.OLED_EOL_WARP = 'warp';
Blockly.Msg.OLED_EOL_NOWARP = 'no-warp';
Blockly.Msg.OLED_CLEAR = '%1 %2 clear oled';
Blockly.Msg.OLED_REFRESH = '%1 %2 refresh oled';
Blockly.Msg.OLED_STARTSCROLL = '%1 %2 start scroll %3 y0: %4 y1: %5';
Blockly.Msg.OLED_SCROLL_RIGHT = 'right';
Blockly.Msg.OLED_SCROLL_LEFT = 'left';
Blockly.Msg.OLED_SCROLL_DIAGRIGHT = 'diag right';
Blockly.Msg.OLED_SCROLL_DIAGLEFT = 'diag left';
Blockly.Msg.OLED_STOPSCROLL = '%1 %2 stop scroll';


///aydinlatma
Blockly.Msg.RGBLEDSTRIP_INIT =  'init RGB LED strip length %1 pin %2';
Blockly.Msg.RGBLEDSTRIP_SETPIXELCOLOR =  '%1 %2 set pixel %3 color %4';
Blockly.Msg.RGBLED_LIGHTSUP =  '%1 %2 RGB LED lights up %3';
Blockly.Msg.RGBLEDSTRIP_FILL =  '%1 %2 fill from pixel %3 count %4 with color %5';
Blockly.Msg.RGBLEDSTRIP_COLOR =  '%1 %2 RGB LED strip color R %3 G %4 B %5';
Blockly.Msg.RGBLEDSTRIP_SETBRIGHTNESS =  '%1 %2 set brightness %3';
Blockly.Msg.RGBLEDSTRIP_CLEAR =  '%1 %2 clear all';
Blockly.Msg.RGBLEDSTRIP_SHOW =  'refresh';
Blockly.Msg.RGBLED_YAK = '%1 %2 RGB Led Light %3 %4 %5';
Blockly.Msg.RGBLED_SONDUR= '%1 %2 Turn off all leds'; 



// hoperlör

Blockly.Msg.SES_CAL =  '%1 %2 ses çal %3';
Blockly.Msg.SES_CAL_BITENE_KADAR = "%1 %2 sel çal %3 bitene kadar";
Blockly.Msg.TUM_SESLERI_DURDUR = "%1 %2 tüm sesleri durdur";
Blockly.Msg.NOTA_CAL = "%1 %2 nota %3 çal %4 vuruş";
Blockly.Msg.SUS_VURUS = "%1 %2 sus %3 vuruş";
Blockly.Msg.FREKANSDA_CAL = "%1 %2 %3 Hz frekansında ses çal %4 saniye";
Blockly.Msg.SES_SEVIYESINI_DEGİSTİR = "%1 %2 ses seviyesini değiştir %3";
Blockly.Msg.SES_SEVIYESI_YUZDE = "%1 %2 ses seviyesi %3 %";
Blockly.Msg.SES_SEVIYESI = "%1 %2 ses seviyesi";
Blockly.Msg.BUZER_AC = "%1 %2 Buzzer Aç";
Blockly.Msg.BUZER_KAPAT = "%1 %2 Buzzer Kapat";
Blockly.Msg.FREKANSDA_SES_CAL = "%1 %2 %3 Hz frekansında ses çal %4 vuruş";


/// HAREKET

Blockly.Msg.ILERI_GIT = "%1 %2 ileri git %3 % güçte %4 saniye";
Blockly.Msg.GERI_GIT = "geri git %1 % güçte %2 saniye";
Blockly.Msg.SOLA_GIT = "%1 %2 sola dön %3 % güçte %4 saniye";
Blockly.Msg.SAGA_GIT = "%1 %2 sağa dön %3 % güçte %4 saniye";
Blockly.Msg.GERIYE_GIT = "%1 %2 geriye git %3 % güçte %4 saniye";
Blockly.Msg.SOLA_DON_DERECE_BITIMI = "%1 %2 sola dön %3 derece bitene kadar";
Blockly.Msg.SAGA_DON_DERECE_BITIMI = "%1 %2 saga dön %3 derece bitene kadar";
Blockly.Msg.SEC_GIT = "%1 %2 %3  %4 % güçte";
Blockly.Msg.SEC_GIT_RPM = "%1 %2 %3 %4 % rpm";
Blockly.Msg.SOLA_SAGA_DERECELI_GIT_RPM = "%1 %2 sol tekerlik %3 % rpm sağ tekerlik %4 % rpm";
Blockly.Msg.SOLA_SAGA_DERECELI_GIT_POWER = "%1 %2 sol tekerlik %3 % güçte sağ tekerlik %4 % güçte";
Blockly.Msg.HAREKETI_DURDUR = "%1 %2 hareketi durdur %3";
Blockly.Msg.RESET_ENCODER_MOTOR="%1 %2  %3 encoder motoru sıfırla";

Blockly.Msg.MOTOR_MOVES_CM="%1 %2 hareket %3 rotates at %4 cm kadar git";
Blockly.Msg.MOTOR_ROTATES_AT_TIME="%1 %2 encoder motor %3 rotates at %4 %5 rpm %6 saniye";
Blockly.Msg.MOTOR_ACIYA_GORE_DON="%1 %2 encoder motor %3  %4 derece dön";
Blockly.Msg.MOTOR_ROTATED_ANGLE="%1 %2 encoder motor %3 at %4 açı";
Blockly.Msg.MOTOR_ACI="%1 %2 encoder motor %3 at %4 saniye";
Blockly.Msg.RPM_SANIYE_YON="%1 %2 encoder motor %3 at %4 rpm %5 saniye";
Blockly.Msg.GUC_SANIYE_YON="%1 %2 encoder motor %3 at %4 güc %5 saniye";
Blockly.Msg.MOTOR_ROTATES_AT="%1 %2 encoder motor %3 rotates at %4 %5";


Blockly.Msg.ILERI_GIT_TEXT="ileri git";
Blockly.Msg.GERI_GIT_TEXT="geri git";
Blockly.Msg.SOLA_GIT_TEXT="sola dön";
Blockly.Msg.SAGA_GIT_TEXT="sağa dön";
Blockly.Msg.DOKSAN_DERECE="90 derece";
Blockly.Msg.YUZSEKSEN_DERECE="180 derece";
Blockly.Msg.IKIYUZYETMİS_DERECE="270 derece";
Blockly.Msg.LEFT_WHELL="left wheel EM(1)";
Blockly.Msg.RIGHT_WHELL="right wheel EM(2)";
Blockly.Msg.ALL_WHELL="all";
Blockly.Msg.RPM_TEXT="rotationel speed(RPM)";
Blockly.Msg.POWER_TEXT="power(%)";
Blockly.Msg.SAG_TEXT="sağ";
Blockly.Msg.SOL_TEXT="sol";
Blockly.Msg.HEPSI_TEXT="hepsi";
Blockly.Msg.KIRMIZI="kırmızı";
Blockly.Msg.CAMGOBEGI="camgöbeği";
Blockly.Msg.MAVI="mavi";
Blockly.Msg.YESIL="yeşil";
Blockly.Msg.SARI="sarı";
Blockly.Msg.BEYAZ="beyaz";
Blockly.Msg.SIYAH="siyah";
Blockly.Msg.GALIBARDA="galibarda";

Blockly.Msg.ONE_EGIK="öne eğik";
Blockly.Msg.GERIYE_EGIK="geriye doğru eğik";
Blockly.Msg.SOLA_EGIK="sola eğik";
Blockly.Msg.SAGA_EGIK="sağa eğik";
Blockly.Msg.YUZU_YUKARI_DONUK="yüzü yukarı dönük";
Blockly.Msg.YUZU_ASAGI_DONUK="yüzü aşağı dönük";

Blockly.Msg.ILERI_EGIK_TEXT="ileri eğik";
Blockly.Msg.GERI_EGIK_TEXT="geri eğik";
Blockly.Msg.SOLA_EGIK_TEXT="sola eğik";
Blockly.Msg.SAGA_EGIK_TEXT="sağa eğik";

Blockly.Msg.SENSOR1="sensör-1";
Blockly.Msg.SENSOR2="sensör-2";
Blockly.Msg.SENSOR3="sensör-3";
Blockly.Msg.SENSOR4="sensör-4";
Blockly.Msg.SENSOR5="sensör-5";














//sensörler

Blockly.Msg.DUGME_BASILDIMI = "%1 %2  button is pressed?";
Blockly.Msg.CIHAZ_BAGLANDIMI = "%1 %2 cihaz bağlandı mı?";
Blockly.Msg.POTANSIYOMETRE_DEGERI =  "%1 %2 dişli potansiyometre değeri";
Blockly.Msg.SES_SIDDETI =  "ses şiddeti";
Blockly.Msg.PIL_SEVIYESI =  "pil seviyesi";
Blockly.Msg.CIHAZ_SALLANIYORMU = "zbot sallanıyor mu?";
Blockly.Msg.SALLANMA_SIDDETI = "sallanma şiddeti";
Blockly.Msg.CIHAZ_EGIKMI = "%1 %2 pybot %3 eğik?";
Blockly.Msg.CIHAZ_KONUMU = "%1 %2 pybot %3 konumlandırılması";
Blockly.Msg.YATIS_ACISI = "yatış açısı";
Blockly.Msg.EGIM_ACISI = "eğim açısı";
Blockly.Msg.X_ETRAFINDA_DONUS_ACISI = "%1 %2 x etrafında dönüş açısı";
Blockly.Msg.Y_ETRAFINDA_DONUS_ACISI = "%1 %2 y etrafında dönüş açısı";
Blockly.Msg.Z_ETRAFINDA_DONUS_ACISI = "%1 %2 z etrafında dönüş açısı";
Blockly.Msg.SIFIRLA_DONDURME_ACISI = "%1 %2 sıfırla %3 döndürme açısı";
Blockly.Msg.ZAMANLAYICI = "%1 %2 zamanlayıcı";
Blockly.Msg.ZAMANLAYICI_SIFIRLA = "%1 %2 zamanlayıcıyı sıfırla";
Blockly.Msg.ONUNDEKI_ENGELLER = "engel algılandı?";
Blockly.Msg.ALGILANAN_RENK = "algılanan renk %1 ?";
Blockly.Msg.RENK_DEGER_TESPITI = "%1 %2 %3 renk değeri tespit edildi";
Blockly.Msg.RENK_ALGILAYICI_ORTAM_ISIK_YOGUNLUGU = "%1 %2 renk algılayıcı ortam ışık yoğunluğu";
Blockly.Msg.RENK_ALGILAYICI_YANSIYAN_ISIK_YOGUNLUGU = "%1 %2 renk algılayıcı yansıyan ışık yoğunluğu";
Blockly.Msg.RENK_ALGILAYICI_YANSIYAN_KIZILOTESI_ISIK_YOGUNLUGU = "%1 %2 renk algılayıcı yansıyan kızılötesi ışık yoğunluğu";
Blockly.Msg.RENK_ALGILAYICI_GRI_OLCEK = "%1 %2 renk algılayıcı gri-ölçek değeri";
Blockly.Msg.MESAFE_VE_ISIK = "%1 %2 Mesafe ve ışık siddeti başlat";
Blockly.Msg.PROMIXITY_UZAKLIK_SENSORU = "%1 %2 uzaklık değeri";
Blockly.Msg.DISLI_POTANSIYOMETRI_DEGERI = "%1 %2 dişli potansiyometre değeri %3";
Blockly.Msg.ORTAM_ISIK_SIDDETI =  "ortam ışık şiddeti";
Blockly.Msg.YESIL_ISIK_SIDDETI_UPLOAD = "%1 %2 yeşil ışık şiddeti";
Blockly.Msg.MAVI_ISIK_SIDDETI_UPLOAD = "%1 %2 mavi ışık şiddeti";
Blockly.Msg.KIRMIZI_ISIK_SIDDETI_UPLOAD = "%1 %2 kırmızı ışık şiddeti";
Blockly.Msg.START_MOTION_SENSOR = "%1 %2 Hareket sensörünü başlat";
Blockly.Msg.START_LINE_FOLLOWER = "%1 %2 Çizgi izleme sensörünü başlat";
Blockly.Msg.START_LINE_FONKS = "%1 %2 Çizgi İzleme fonksiyonlarını oku %3";
Blockly.Msg.READ_LINE_NEW = "%1 %2 read line %3 %4";
Blockly.Msg.EGIM_ACISI_DURUMLU = "%1 %2  %3 eğim açısı";
Blockly.Msg.X_Y_Z_ACISI_DURUMLU = "%1 %2  %3 eğim açısı";
Blockly.Msg.X_Y_Z_HIZ_DURUMLU = "%1 %2  %3 hızı m/s^2";
Blockly.Msg.SOUNDINTENSITY = "%1 %2 ses şiddetini ölç %3",
Blockly.Msg.TEMPATURE_NEM_INIT_PYBOARD = "%1 %2 Sıcaklık nem sensörünü başlat  %3",
Blockly.Msg.TEMPATURE_READ_PYBOT = "%1 %2 Sıcaklık oku %3",
Blockly.Msg.RELATIVE_HUMIDITY_READ_PYBOT = "%1 %2 bağıl nem oku %3",
Blockly.Msg.WATERPROOF_TEMPATURE_INIT_PYBOARD = "%1 %2 Su geçirmez sıcaklık sensörünü başlat  %3",
Blockly.Msg.WATERPROOF_TEMPATURE_READ_PYBOARD = "%1 %2 Su geçirmez sensörden sıcaklık oku %3",

Blockly.Msg.TOPRAK_NEM_INIT_PYBOARD = "%1 %2 Toprak nem sensörünü başlat %3",
Blockly.Msg.TOPRAK_NEM_READ_PYBOARD = "%1 %2 Toprak nemi oku  %3",



Blockly.Msg.ORTAM_ISIK_SIDDETI_PYBOARD =  "%1 %2 ortam ışık şiddeti soket %3";
Blockly.Msg.YESIL_ISIK_SIDDETI_UPLOAD_PYBOARD = "%1 %2 yeşil ışık şiddeti soket %3";
Blockly.Msg.MAVI_ISIK_SIDDETI_UPLOAD_PYBOARD = "%1 %2 mavi ışık şiddeti soket %3";
Blockly.Msg.KIRMIZI_ISIK_SIDDETI_UPLOAD_PYBOARD = "%1 %2 kırmızı ışık şiddeti soket %3";

Blockly.Msg.ULTRASONIKINITPYBOARD = "%1 %2 ultrasonik sensör başlat %3";
Blockly.Msg.DISTANCEVALUEULTRASONIK = "%1 %2 ultrasonik sensör ölçülen mesafe"; 

Blockly.Msg.GETTIMEPYBOARD = '%1 %2  %3 zaman dilimindeki tarihi getir %4';









// Görünüm

Blockly.Msg.RESIM_GOSTER_DELAY = '%1 %2 resim göster %3 de %4 saniye';
Blockly.Msg.RESIM_GOSTER = '%1 %2 resim göster %3';
Blockly.Msg.RESIM_GOSTER_X_Y = '%1 %2 resim göster %3 de x: %4';
Blockly.Msg.EKRANI_KAPAT = '%1 %2 ekranı kapat';
Blockly.Msg.GOSTER = '%1 %2 göster %3';
Blockly.Msg.GOSTER_KAYDIRILANA_KADAR = '%1 %2 göster %3 kaydırılana kadar';
Blockly.Msg.GOSTER_X_Y = '%1 %2 göster %3 x: %4 y: %5';
Blockly.Msg.ISIK_YAK_X_Y = '%1 %2 ışık yak x: %3 y: %4';
Blockly.Msg.ISIK_KAPAT_X_Y = '%1 %2 ışık kapat x: %3 y: %4';
Blockly.Msg.ISIK_YAK_SONDUR_X_Y = '%1 %2 ışık yak söndür x: %3 y: %4';


// WİFİ
Blockly.Msg.WIFI_CONNECT_SSID = '%1 %2 Wİ-Fİ ye bağlan %3 şifre %4';
Blockly.Msg.WIFI_BAGLANDIMI = '%1 %2 Wİ-Fİ bağlımı?';
Blockly.Msg.CONNECTED_WIFI_AGAIN = '%1 %2 Wİ-Fİ ye tekrar bağlan';
Blockly.Msg.DISCONNECTED_WIFI_AGAIN = '%1 %2 Wİ-Fİ bağlantısını kes';
Blockly.Msg.BUGUNUN_TARIHINI_GETIR =  '%1 %2  %3 zaman dilimindeki tarihi getir %4';

// TEMPATURE
Blockly.Msg.TEMPATURE_INIT = '%1 %2 Tempature init  %3';
Blockly.Msg.TEMPATURE_READ = '%1 %2 Tempature read %3';
Blockly.Msg.TOPRAK_NEM_INIT = '%1 %2 Toprak nem baslat %3';
Blockly.Msg.TOPRAK_NEM_OKU = '%1 %2 Toprak nem oku';
Blockly.Msg.RELATIVE_HUMIDITY_INIT =  '%1 %2 Relative Humidity init %3';
Blockly.Msg.RELATIVE_HUMIDITY_OKU =  '%1 %2 bağıl nem oku';
Blockly.Msg.SU_GECIRMEZ_SICAKLIK_INIT =  '%1 %2 Su geçirmez sıcaklık sensörü başlat %3';
Blockly.Msg.SU_GECIRMEZ_SICAKLIK_OKU =  '%1 %2 Su geçirmez sıcaklık sensörü değer oku %3';
Blockly.Msg.TOPRAK_INIT =  '%1 %2 Toprak nem sensörünü başlat %3';
Blockly.Msg.TOPRAK_READ =  '%1 %2 toprak nemini oku';



//Category
Blockly.Msg.TOPRAK_CATEGORY =  'su geçirmez sıcaklık sensörü';
Blockly.Msg.DHT_CATEGORY =  'sıcaklık ve nem sensörü';
Blockly.Msg.SU_GECİRMEZ_CATEGORY =  'Su geçirmez sensör';

Blockly.Msg.TEMPATURE_DHT= '%1 %2 Sıcaklık ve Bağıl nem sensörünü başlat block %3',
Blockly.Msg.TEMPATURE_READ_DHT= '%1 %2 Sıcaklık oku block %3',

//AYDINLATMA

Blockly.Msg.RGB_LED_YAK_SURELI = '%1 %2 Led %3 renk %4 saniye %5 boyunca yak';
Blockly.Msg.RGB_LED_YAK_SURESIZ = '%1 %2 Led %3 renk %4 yak';
Blockly.Msg.RGB_LED_KAPAT = '%1 %2 Led %3 kapat';
Blockly.Msg.RGB_LED_BASLAT = '%1 %2 Led başlat';


Blockly.Msg.RGB_LED_YAK_SURELI_PYBOARD = '%1 %2 Led %3 renk %4 saniye %5 boyunca yak soket %6';
Blockly.Msg.RGB_LED_YAK_SURESIZ_PYBOARD = '%1 %2 Led %3 renk %4 yak soket %5';
Blockly.Msg.RGB_LED_KAPAT_PYBOARD = '%1 %2 Led %3 kapat soket %4';
Blockly.Msg.RGBLED_YAK_PYBOARD = '%1 %2 RGB Led Light %3 %4 %5  soket %6';
Blockly.Msg.RGBLED_SONDUR_PYBOARD = '%1 %2 Turn off all leds soket %3'; 

Blockly.Msg.BKY_CATEGORY_LIGHTS = 'ışık';

/*** EXPORTS FROM exports-loader ***/
module.exports = Blockly;
