export const iconURI = "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAFAAAABQCAMAAAC5zwKfAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAACpQTFRF////fIel5ufolZ62/2YavsPS+YZOkJmy9/j53+Hk6+zs6N/b6dfO////tDhMHAAAAA50Uk5T/////////////////wBFwNzIAAAA6ElEQVR42uzX2w6DIBAEUGDVtlr//3dLaLwgiwUd2z7MJPJg5EQWiGhGcAxBggQJEiT436CIfqXJPTn3MKNYYMSDFpoAmp24OaYgvwKnFgL2zvVTCwHrMoMi+nUQLFthaNCCa0iwclLkDgYVsQp0mzxuqXgK1MRzoCLWgkPXNN2wI/q6Kvt7u/cX0HtejN8x2sXpnpb8J8D3b0Keuhh3X975M+i0xNVbg3s1TIasgK21bQyGO+s2PykaGMYbge8KrNrssvkOWDXkErB8UuBHETjoYLkKBA8ZfuDkbwVBggQJEiR4MC8BBgDTtMZLx2nFCQAAAABJRU5ErkJggg==";



/**
 * The list of USB device filters.
 * @readonly
 */
 export const PNPID_LIST = [
    // CH340
    "USB\\VID_1A86&PID_7523",
    // CH9102
    "USB\\VID_1A86&PID_55D4",
    // CP2102
    "USB\\VID_10C4&PID_EA60",
];

/**
 * Configuration of serialport
 * @readonly
 */
 export const SERIAL_CONFIG = {
    baudRate: 57600,
    dataBits: 8,
    stopBits: 1,
};

/**
 * Configuration for arduino-cli.
 * @readonly
 */
 export const DEVICE_OPT = {
    type: "microbit",
    fqbn: "esp32:esp32:esp32:UploadSpeed=921600",
    firmware: 'arduinoUno.standardFirmata.ino.hex'
};

export const Pins = {
    IO0: "0",
    IO1: "1",
    IO2: "2",
    IO3: "3",
    IO4: "4",
    IO5: "5",
    IO6: "6",
    IO7: "7",
    IO8: "8",
    IO9: "9",
    IO10: "10",
    IO11: "11",
    IO12: "12",
    IO13: "13",
    IO14: "14",
    IO15: "15",
    IO16: "16",
    IO17: "17",
    IO18: "18",
    IO19: "19",
    IO21: "21",
    IO22: "22",
    IO23: "23",
    IO25: "25",
    IO26: "26",
    IO27: "27",
    IO32: "32",
    IO33: "33",
    IO34: "34",
    IO35: "35",
    IO36: "36",
    IO39: "39",
};

export const Level = {
    High: "HIGH",
    Low: "LOW",
};

export const Channels = {
    CH0: "0",
    CH1: "1",
    CH2: "2",
    CH3: "3",
    CH4: "4",
    CH5: "5",
    CH6: "6",
    CH7: "7",
    CH8: "8",
    CH9: "9",
    CH10: "10",
    CH11: "11",
    CH12: "12",
    CH13: "13",
    CH14: "14",
    CH15: "15",
};

export const SerialNo = {
    Serial0: "0",
    Serial1: "1",
    Serial2: "2",
};

export const Buadrate = {
    B4800: "4800",
    B9600: "9600",
    B19200: "19200",
    B38400: "38400",
    B57600: "57600",
    B76800: "76800",
    B115200: "115200",
};

export const Eol = {
    Warp: "warp",
    NoWarp: "noWarp",
};

export const Mode = {
    Input: "INPUT",
    Output: "OUTPUT",
    InputPullup: "INPUT_PULLUP",
    InputPulldown: "INPUT_PULLDOWN",
};

export const InterrupMode = {
    Rising: "RISING",
    Falling: "FALLING",
    Change: "CHANGE",
    LowLevel: "LOW",
    HighLevel: "HIGH",
};

export const DataType = {
    Integer: "INTEGER",
    Decimal: "DECIMAL",
    String: "STRING",
};