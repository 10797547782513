import React from 'react';
import PropTypes from 'prop-types';
import Box from '../box/box.jsx';
import Button from '../button/button.jsx';
import classNames from 'classnames';
import {defineMessages, FormattedMessage, intlShape} from 'react-intl';

import {STAGE_SIZE_MODES} from '../../lib/layout-constants';

import styles from './code-upload-button.css';
import downloadIcon from "./download_48px.png";


const messages = defineMessages({
    uploadMessage: {
        defaultMessage: 'Upload',
        description: 'Button to upload program',
        id: 'gui.hardwareHeader.upload'
    },
    largeStageSizeMessage: {
        defaultMessage: 'Switch to large stage',
        description: 'Button to upload code to device alt',
        id: 'gui.stageHeader.codeStageSizeLarge'
    },
    smallStageSizeMessage: {
        defaultMessage: 'Switch to small stage',
        description: 'Button to change stage size to small',
        id: 'gui.stageHeader.codeStageSizeSmall'
    },
    hideStageSizeMessage: {
        defaultMessage: 'Hide stage',
        description: 'Button to hide stage',
        id: 'gui.stageHeader.hideStage'
    }
});

const CodeUploadButton = props => {
    const {
        onSetStageLarge,
        onSetStageSmall,
        onSetStageHide,
        onUpload,
        stageSizeMode,
        isjr
    } = props;
    return (
        <Box
        className={
           styles.downloadButtonContainer
        }
    >
        <button
            className={
                styles.extensionButton
            }
        
              onClick={onUpload}
        >
            <img
                className={
                    styles.downloadButtonIcon
                }
                draggable={false}
                src={downloadIcon}
            />
        </button>
    </Box>
    );
};

CodeUploadButton.propTypes = {
    intl: intlShape,
    onUpload: PropTypes.func.isRequired,
    onSetStageLarge: PropTypes.func.isRequired,
    onSetStageSmall: PropTypes.func.isRequired,
    onSetStageHide: PropTypes.func.isRequired,
    stageSizeMode: PropTypes.oneOf(Object.keys(STAGE_SIZE_MODES))
};

CodeUploadButton.defaultProps = {
    stageSizeMode: STAGE_SIZE_MODES.large
};

export default CodeUploadButton;
