const blockSeparator = '<sep gap="36"/>'; // At default scale, about 28px
const categorySeparator = '<sep gap="36"/>';
import ScratchBlocks from '../../../../mymodule/ztech-blocks/dist/horizontal.js';


export const looks = function (isInitialSetup, isStage, targetId, costumeName, backdropName) {
    const hello = ScratchBlocks.ScratchMsgs.translate('LOOKS_HELLO', 'Hello!');
    const hmm = ScratchBlocks.ScratchMsgs.translate('LOOKS_HMM', 'Hmm...');
    return `
    <category name="%{BKY_CATEGORY_LOOKS}" id="looks" colour="#9966FF" secondaryColour="#774DCB">
        ${isStage ? '' : `

       
      
        `}
         ${categorySeparator}
         <block type="ztech_ledmatrix" id="ztech_ledmatrix">
         <value name="MATRIX"> 
             <shadow type="matrixjr"> 
                 <field name="MATRIX">00000000000000000000000000000000001111100111110000001100000110000001000000100000001111100111110000000000000000000000000000000000</field> 
             </shadow> 
         </value>
     </block>
 
     
     <block type="heart_emotion">
         <value name="value">
             <shadow type="dropdown_emotion">
                 <field name="value"></field>
             </shadow>
         </value>
     </block>
 
        
 
         <block type="aa_text">
         <value name="data">
             <shadow type="text">
                 <field name="TEXT">Hi</field>
             </shadow>
         </value>
     </block>
 
         <block type="off"></block>
    </category>
    `;
};