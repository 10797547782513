class GameMethods {
    constructor(moveList,moveStepsList,gameArea) {
        this.moveList = moveList;
        this.moveStepsList = moveStepsList;
        this.gameArea = gameArea;
        this.isPermissionAction = true;
      }

     

   actionPermissionFalse = async () => {
      this.isPermissionAction = false;
    };

    actionPermissionTrue = async () => {
      this.isPermissionAction = true;
    };
   

     downRightPush = async () => {
        console.log('downRightPush puslandı:  '+this.isPermissionAction);
        if(this.isPermissionAction){
         this.moveList.push('downRight');
        }
     };

     listForwordForeverPush = async () => {
      console.log('listForwordForeverPush puslandı:  '+this.isPermissionAction);
      if(this.isPermissionAction){
       this.moveList.push('moveForwordForever');
      }
   };

      listBackwardForeverPush = async () => {
         console.log('upLeftPush puslandı');

        this.moveList.push('moveBackwardForever');
     };
     
     downLeftPush = async () => {
      console.log('downLeftPush puslandı');
        this.moveList.push('downLeft');
     };

     upLeftPush = async () => {
      console.log('upLeftPush puslandı');

        this.moveList.push('upLeft');
     };

     upRightPush = async () => {
      console.log('upRightPush puslandı');

        this.moveList.push('upRight');
     };

     ///////////////////////////////////////////////////
     /*
     downRightMultiPush = async (steps) => {
        this.moveList.push('multiDownRight');
        this.moveStepsList.push(steps);
        //console.log('game methods gelen steps: '+steps);
     };
     
     downLeftMultiPush = async (steps) => {
        this.moveList.push('multiDownLeft');
        this.moveStepsList.push(steps);
     };

     upLeftMultiPush = async (steps) => {
        this.moveList.push('multiUpLeft');
        this.moveStepsList.push(steps);
     };

     upRightMultiPush = async (steps) => {
        this.moveList.push('multiUpRight');
        this.moveStepsList.push(steps);
     };
    */
    /////////////////////////////////////////////////////////

    moveStop = async () => {
      console.log('moveDownRight komutu gönderildi');
     // alert('method stop');

      this.gameArea.events.emit("moveStopEvent");
    };

    moveStart = async () => {
      console.log('moveDownRight komutu gönderildi');
     // alert('method start');

      this.gameArea.events.emit("moveStartEvent");
    };

     moveForwordForever = async () => {
      console.log('moveDownRight komutu gönderildi');
      this.gameArea.events.emit("moveStartEvent");

      this.gameArea.events.emit("moveForwardEvent", true);
    };

    moveBackwardForever = async () => {
      console.log('moveDownRight komutu gönderildi');
      this.gameArea.events.emit("moveStartEvent");

      this.gameArea.events.emit("moveBackwardEvent",true);
    };

    moveDownRight = async () => {
      console.log('moveDownRight komutu gönderildi');

      //  this.gameArea.events.emit("moveEvent", "down-right"); 
      this.gameArea.events.emit("moveStartEvent");
   
      this.gameArea.events.emit("moveForwardEvent");
    };

     moveDownLeft = async () => {
      console.log('moveDownLeft komutu gönderildi');
      //this.gameArea.events.emit("moveEvent", "down-left");   
      //this.gameArea.events.emit("turnClockwiseEvent", "turnCounterClockwise");    
      this.gameArea.events.emit("moveStartEvent");

     this.gameArea.events.emit("turnClockwiseEvent", "turnClockwise");    

     // this.gameArea.events.emit("turnClockwiseEvent", "turnCounterClockwise"); 

    };

     moveUpLeft = async () => {
      console.log('moveUpLeft komutu gönderildi');
       // this.gameArea.events.emit("moveEvent", "up-left");     
       this.gameArea.events.emit("moveStartEvent");
   
       this.gameArea.events.emit("moveBackwardEvent");
      };

     moveUpRight = async () => {
      console.log('moveUpRight komutu gönderildi');
      //  this.gameArea.events.emit("moveEvent", "up-right");    
      this.gameArea.events.emit("moveStartEvent");

      this.gameArea.events.emit("turnClockwiseEvent", "turnCounterClockwise"); 
    };

   moveSpeedUp = async (data) => {
      console.log('speed up komutu gönderildi');
       // this.gameArea.events.emit("moveEvent", "up-left");        
       this.gameArea.events.emit("speedEvent");
   };

   moveSpeedDown = async (data) => {
      console.log('speed up komutu gönderildi');
       // this.gameArea.events.emit("moveEvent", "up-left");        
       this.gameArea.events.emit("speedEvent");
   };

   
        
    //////////////////////////////////////////////////

    /*
    moveDownRightMulti = async (steps) => {
        this.gameArea.events.emit("moveToEvent", {
            dir: "down-right",
            step: steps,
        });
     };
     
     moveDownLeftMulti = async (steps) => {
        this.gameArea.events.emit("moveToEvent", {
            dir: "down-left",
            step: steps,
        });
     };

     moveUpLeftMulti = async (steps) => {
        this.gameArea.events.emit("moveToEvent", {
            dir: "up-left",
            step: steps,
        });
     };

     moveUpRightMulti = async (steps) => {
        this.gameArea.events.emit("moveToEvent", {
            dir: "up-right",
            step: steps,
        });
     };
     */
     /////////////////////////////////////////////

     moveListShift = async () => {
      //console.log('silme çalıştı: count: '+this.moveList.length);
        this.moveList.shift();
     };

     playPressed = async () => {
   //   //console.log('playPressed çalıştı');
      this.gameArea.events.emit("startEvent", "play_button");  
     };

     moveStepsListShift = async () => {
        this.moveStepsList.shift();
     };

   isBlockRun = async (color) => {
      this.gameArea.events.emit("isBlockRunEvent", "deneme");
   };

   robotLightGreen = async () => {
      this.gameArea.events.emit("changePartEvent", "robotLightGreen");  
   };

   robotLightBlue = async () => {
      this.gameArea.events.emit("changePartEvent", "robotLightBlue");  
   };

   robotLightMagenta = async () => {
      this.gameArea.events.emit("changePartEvent", "robotLightMagenta");  
   };

   robotLightCyan = async () => {
      this.gameArea.events.emit("changePartEvent", "robotLightCyan");  
   };

     robotLightYellow = async () => {
      this.gameArea.events.emit("changePartEvent", "robotLightYellow");  
   };

      robotLightWhite = async () => {
      this.gameArea.events.emit("changePartEvent", "robotLightWhite");  
   };

    
}

export default GameMethods;