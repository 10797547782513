const DeviceTrue = 'scratch-gui/DeviceTrue';
const DeviceFalse = 'scratch-gui/DeviceFalse';


const initialState = true;

const reducer = function (state, action) {

    if (typeof state === 'undefined') state = initialState;

    switch (action.type) {
    case DeviceTrue:
        return true;
    case DeviceFalse:
        return false;
    default:
        return state;
    }
};

const setDeviceTrue = function () {
    //alert('device true');
    return {
        type: DeviceTrue,
    };
};

const setDeviceFalse = function () {
   // alert('device false');

    return {
        type: DeviceFalse,
    };
};


export {
    reducer as default,
    initialState as DeviceChangeInitialState,
    setDeviceTrue,
    setDeviceFalse
};
