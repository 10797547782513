import classNames from "classnames";
import bindAll from "lodash.bindall";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { compose } from "redux";
import React from "react";
import { defineMessages, injectIntl, intlShape } from "react-intl";
import VM from "../../../mymodule/ztech-vm";

import Modal from "../../containers/modal.jsx";
import { setjrMode, setNormalMode } from "../../reducers/jrchange";
import { setDestroyJr, setDestroyNormal } from "../../reducers/game-destroy";
import deviceChange, {
    setDeviceTrue,
    setDeviceFalse,
} from "../../reducers/device-change";

import {setRealtimeConnection, clearConnectionModalPeripheralName} from '../../reducers/connection-modal';
import {
    openUploadProgress,
 
} from '../../reducers/modals';

import style from "./computerless-coding.css";

import spaceTravel from "./space-travel.png";

import { FormattedMessage } from "react-intl";
import {showAlertWithTimeout} from '../../reducers/alerts';



class ComputerlessCodingComponent extends React.Component {
    constructor(props) {
        super(props);
        bindAll(this, [
            "handleClose",
            "handleuploadComputerlessCoding"
           
        ]);
        this.state = {
            loaded: false,
        };
    }
    componentDidMount() {
        // Allow the spinner to display before loading the content
        setTimeout(() => {
            this.setState({ loaded: true });
        });
    }
    componentDidUpdate(prevProps, prevState) {}

    handleClose() {
        console.log(this.props);

        this.props.onRequestClose();
    }

    handleuploadComputerlessCoding (type) {
       console.log(this.props);
       console.log(this.props.deviceId);
     
        if (this.props.deviceId) {
            this.props.vm.uploadComputerlessCodingToPeripheral(this.props.deviceId,type);
            this.props.onSetRealtimeConnection(false);
            this.props.onOpenUploadProgress();
        } else {
            this.props.onNoPeripheralIsConnected();
        }
    }

    render() {
        return (
            <Modal
                smallContent
                id={this.props.id}
                contentLabel={this.props.title}
                headerClassName={style.title}
                onRequestClose={this.handleClose}
            >
                <div className={style.content}>

                <div className={style.element}
                 onClick={() => {
                    this.handleuploadComputerlessCoding("uzay");
                    if (this.props.peripheralName) {   
                      //  this.handleuploadComputerlessCoding("uzay");
                    }
                    else
                    {
                      //  this.props.onNoPeripheralIsConnected();
                    }

                    }}
                >
                   
                    <img  className={style.img} src={spaceTravel}/>
                    <div className={style.text}>
                        <FormattedMessage
                        defaultMessage="Uzay"
                        description="Button to get to the Horizontal Blocks"
                        id="gui.computerless.space"
                        />
                    </div>
                </div>
                

                <div className={style.element}>
                    <img  className={style.img} src={spaceTravel}/>
                    <div className={style.text}>
                        <FormattedMessage
                        defaultMessage="Uzay"
                        description="Button to get to the Horizontal Blocks"
                        id="gui.computerless.space"
                        />
                    </div>
                </div>


                <div className={style.element}>
                    <img  className={style.img} src={spaceTravel}/>
                    <div className={style.text}>
                        <FormattedMessage
                        defaultMessage="Uzay"
                        description="Button to get to the Horizontal Blocks"
                        id="gui.computerless.space"
                        />
                    </div>
                </div>


                <div className={style.element}>
                    <img  className={style.img} src={spaceTravel}/>
                    <div className={style.text}>
                        <FormattedMessage
                        defaultMessage="Uzay"
                        description="Button to get to the Horizontal Blocks"
                        id="gui.computerless.space"
                        />
                    </div>
                </div>



                <div className={style.element}>
                    <img  className={style.img} src={spaceTravel}/>
                    <div className={style.text}>
                        <FormattedMessage
                        defaultMessage="Uzay"
                        description="Button to get to the Horizontal Blocks"
                        id="gui.computerless.space"
                        />
                    </div>
                </div>


                <div className={style.element}>
                    <img  className={style.img} src={spaceTravel}/>
                    <div className={style.text}>
                        <FormattedMessage
                        defaultMessage="Uzay"
                        description="Button to get to the Horizontal Blocks"
                        id="gui.computerless.space"
                        />
                    </div>
                </div>

                
                
                <div>

                </div>
                  


                   
                </div>
            </Modal>
        );
    }
}

ComputerlessCodingComponent.propTypes = {
    autoClose: PropTypes.bool,
    data: PropTypes.arrayOf(
        /* eslint-disable react/no-unused-prop-types, lines-around-comment */
        // An item in the library
        PropTypes.shape({
            // @todo remove md5/rawURL prop from library, refactor to use storage
            md5: PropTypes.string,
            name: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
            rawURL: PropTypes.string,
        })
        /* eslint-enable react/no-unused-prop-types, lines-around-comment */
    ),
    id: PropTypes.string.isRequired,
    intl: intlShape.isRequired,
    onRequestClose: PropTypes.func,
    title: PropTypes.string.isRequired,
    vm: PropTypes.instanceOf(VM).isRequired,
    onSetRealtimeConnection: PropTypes.func.isRequired,
    onNoPeripheralIsConnected: PropTypes.func.isRequired,

};

ComputerlessCodingComponent.defaultProps = {
    autoClose: true,
    isUnloadble: false,
    filterable: true,
    showPlayButton: false,
};
const mapStateToProps = (state, ownProps) => {
    return {
        vm: state.scratchGui.vm,
        isjr: state.scratchGui.isjr,
        gameDestroy: state.scratchGui.destroyGame,
    };
};
const mapDispatchToProps = (dispatch) => ({
    onSetJrchange: () => dispatch(setjrMode()),
    onSetNormalchange: () => dispatch(setNormalMode()),
    onSetDestroyJr: () => dispatch(setDestroyJr()),
    onSetDestroyNormal: () => dispatch(setDestroyNormal()),
    onSetDeviceFalse: () => dispatch(setDeviceFalse()),
    onSetRealtimeConnection: state => dispatch(setRealtimeConnection(state)),
    onOpenUploadProgress: () => dispatch(openUploadProgress()),
    onNoPeripheralIsConnected: () => showAlertWithTimeout(dispatch, 'connectAPeripheralFirst'),

    onUpdateToolboxState: (toolboxXML) => {
        dispatch(updateToolbox(toolboxXML));
    },
});

export default compose(
    injectIntl,
    connect(mapStateToProps, mapDispatchToProps)
)(ComputerlessCodingComponent);
