import ScratchBlocks from "../../../../../mymodule/ztech-blocks";


const categorySeparator = '<sep gap="36"/>';

const blockSeparator = '<sep gap="36"/>'; // At default scale, about 28px

/* eslint-disable no-unused-vars */


const xmlEscape = function (unsafe) {
    return unsafe.replace(/[<>&'"]/g, c => {
        switch (c) {
        case '<': return '&lt;';
        case '>': return '&gt;';
        case '&': return '&amp;';
        case '\'': return '&apos;';
        case '"': return '&quot;';
        }
    });
};

const variables = function () {
    return `
    <category
        name="%{BKY_CATEGORY_VARIABLES}"
        id="variables"
        colour="#FF8C1A"
        secondaryColour="#DB6E00"
        custom="VARIABLE">
    </category>
    `;
};

const myBlocks = function () {
    return `
    <category
        name="%{BKY_CATEGORY_MYBLOCKS}"
        id="myBlocks"
        colour="#FF6680"
        secondaryColour="#FF4D6A"
        custom="PROCEDURE">
    </category>
    `;
};

const ztech = function () {
    return `
    <category name="ztech" id="ztech" colour="#D65CD6" secondaryColour="#BD42BD">
        
        <block type="ztech_deneme_1"></block>
        <block type="ztech_deneme_2"></block>
        <block type="ztech_deneme_3"></block>
        <block type="ztech_deneme_4"></block>

       



        ${categorySeparator}
        </category>
    `;
};


const motion = function (isInitialSetup, isStage, targetId,isRealtimeMode) {
    const stageSelected = ScratchBlocks.ScratchMsgs.translate(
        'MOTION_STAGE_SELECTED',
        'Stage selected: no motion blocks'
    );
    return `
    <category name="%{BKY_CATEGORY_MOTION}" id="motion" colour="#4C97FF" secondaryColour="#3373CC">
    ${isStage ? `
    <label text="${stageSelected}"></label>
    ` : `


    <block type="hareket_ileri">
    <value name="SPEED">
        <shadow type="math_positive_number">
            <field name="NUM">1</field>
        </shadow>
    </value>       
</block>
<block type="hareket_geri">
    <value name="repeat">
        <shadow type="math_positive_number">
            <field name="NUM">1</field>
        </shadow>
    </value>   
</block>

<block type="hareket_saga_don">
    <value name="repeat">
        <shadow type="math_positive_number">
            <field name="NUM">1</field>
        </shadow>
    </value>
</block>
<block type="hareket_sola_don">
    <value name="repeat">
        <shadow type="math_positive_number">
            <field name="NUM">1</field>
        </shadow>
    </value>
</block>



        <block type="hareket_speed">
            <value name="VALUE">
                <shadow type="dropdown_speed">
                    <field name="VALUE">1</field>
                </shadow>
            </value>       
        </block>

        

        <block type="hareket_ileri_sonsuz"> </block>
        <block type="hareket_geri_sonsuz"> </block>

       

        <block type="hareket_cizgi_izleme">
            <value name="VALUE">
                <shadow type="dropdown_hareket_cizgi_izleme">
                    <field name="VALUE">1</field>
                </shadow>
            </value>       
        </block>

        <block type="hareket_dur">
</block>
   
`}
        ${categorySeparator}

       

        ${categorySeparator}
    </category>
    `;
};

const lights = function (isInitialSetup, isStage, targetId) {
    const stageSelected = ScratchBlocks.ScratchMsgs.translate(
        'LIGHTS_STAGE_SELECTED',
        'Stage selected: no light blocks'
    );
    return `
    <category name="%{BKY_CATEGORY_LIGHTING}" id="lights" colour="#5dc8f6" secondaryColour="#3373CC">
    ${isStage ? `
    <label text="${stageSelected}"></label>
    ` : `
     
        <block type="light_onoff">
            <value name="ICON">
                <shadow type="dropdown_onoff">
                    <field name="ICON">on</field>
                </shadow>
            </value>
        </block>

        <block type="on_light_off">
        </block>
        
        <block type="rgb_lights">
            <value name="COLOR">
                <shadow type="dropdown_rgb">
                    <field name="COLOR">green</field>
                </shadow>
            </value>
        </block>

        <block type="lights_off">
        </block>
        
`}
        ${categorySeparator}
    </category>
    `;
};


const looks = function (isInitialSetup, isStage, targetId, costumeName, backdropName) {
    const hello = ScratchBlocks.ScratchMsgs.translate('LOOKS_HELLO', 'Hello!');
    const hmm = ScratchBlocks.ScratchMsgs.translate('LOOKS_HMM', 'Hmm...');
    return `
    <category name="%{BKY_CATEGORY_LOOKS}" id="looks" colour="#9966FF" secondaryColour="#774DCB">
    ${isStage ? '' : `

   
  
    `}
     ${categorySeparator}

     <block type="ztech_ledmatrix" id="ztech_ledmatrix">
        <value name="MATRIX"> 
            <shadow type="matrixjr"> 
                <field name="MATRIX">00000000000000000000000000000000001111100111110000001100000110000001000000100000001111100111110000000000000000000000000000000000</field> 
            </shadow> 
        </value>
    </block>

    
    <block type="heart_emotion">
        <value name="value">
            <shadow type="dropdown_emotion">
                <field name="value"></field>
            </shadow>
        </value>
    </block>

       

        <block type="aa_text">
        <value name="data">
            <shadow type="text">
                <field name="TEXT">Hi</field>
            </shadow>
        </value>
    </block>

        <block type="off"></block>

 
</category>
    `;
};



const sound = function (isInitialSetup, isStage, targetId, soundName) {
    return `
    <category name="%{BKY_CATEGORY_SOUND}" id="sound" colour="#D65CD6" secondaryColour="#BD42BD">

    <block type="sound_melodi_list">
        <value name="data">
            <shadow type="dropdown_sound_melodi">
                <field name="data"></field>
            </shadow>
        </value>
    </block>

    <block type="sound_melodi">
        <value name="data">
            <shadow type="note">
                <field name="NOTE">60</field>
            </shadow>  
        </value>
    </block>

    <block type="sound_off">
      
    </block>


    <block type="custom_sound">
        <value name="value">
            <shadow type="dropdown_customsound">
                <field name="value"></field>
            </shadow>
        </value>
    </block>

    <block type="play_sound">
    <value name="SOUND">
        <shadow type="dropdown_sound">
            <field name="SOUND"></field>
        </shadow>
    </value>
</block>


    ${categorySeparator}
    </category>
    `;
};


const eventsRealTime = function (isInitialSetup, isStage) {
    return `
    <category name="%{BKY_CATEGORY_EVENTS}" id="events" colour="#FFD500" secondaryColour="#CC9900">

    <block type="game_event_whenflagclicked"/>

    <block type="game_when_keypress_game">
    <value name="click">
        <shadow type="dropdown_press">
            <field name="click">forward</field>
        </shadow>
    </value>
</block>

<block type="game_when_shake_robot"></block>

<block type="game_detect_sound_intensity">
<value name="sound_intensity">
    <shadow type="dropdown_detect_sound_intensity">
        <field name="sound_intensity">100</field>
    </shadow>
</value>
</block>

<block type="game_when_detect_light">
<value name="light_intensity">
    <shadow type="dropdown_detect_light_intensity">
        <field name="light_intensity">100</field>
    </shadow>
</value>
</block>

<block type="game_when_detect_color">
<value name="detect_color">
    <shadow type="dropdown_detect_color">
        <field name="detect_color">Red</field>
    </shadow>
</value>
</block>

<block type="game_when_detect_barrier">
<value name="detect_barrier">
    <shadow type="dropdown_detect_barrier">
        <field name="detect_barrier">barrier</field>
    </shadow>
</value>
</block>

    <block type="game_event_whenbroadcastreceived">
    <value name="option">
    <shadow type="game_dropdown_whenbroadcast">
    <field name="option">blue</field>
    </shadow>
    </value>
    </block>
   

    <block type="game_event_broadcast">
    <value name="option">
    <shadow type="game_dropdown_broadcast">
    <field name="option">blue</field>
    </shadow>
    </value>
    </block>

    



    
   

    

        
</category>
    `;
};


const events = function (isInitialSetup, isStage) {
    return `
    <category name="%{BKY_CATEGORY_EVENTS}" id="events" colour="#FFD500" secondaryColour="#CC9900">

    <block type="game_event_whenflagclicked"/>

    <block type="game_when_keypress_game">
    <value name="click">
        <shadow type="dropdown_press">
            <field name="click">forward</field>
        </shadow>
    </value>
</block>

    <block type="game_when_shake_robot"></block>

    <block type="game_detect_sound_intensity">
        <value name="sound_intensity">
            <shadow type="dropdown_detect_sound_intensity">
                <field name="sound_intensity">100</field>
            </shadow>
        </value>
    </block>

    <block type="game_when_detect_light">
        <value name="light_intensity">
            <shadow type="dropdown_detect_light_intensity">
                <field name="light_intensity">100</field>
            </shadow>
        </value>
    </block>

    <block type="game_when_detect_color">
        <value name="detect_color">
            <shadow type="dropdown_detect_color">
                <field name="detect_color">Red</field>
            </shadow>
        </value>
    </block>

    <block type="game_when_detect_barrier">
        <value name="detect_barrier">
            <shadow type="dropdown_detect_barrier">
                <field name="detect_barrier">barrier</field>
            </shadow>
        </value>
    </block>

    
   

        
</category>
    `;
};



const control = function (isInitialSetup, isStage) {
    return `
    <category name="%{BKY_CATEGORY_CONTROL}" id="control" colour="#FFAB19" secondaryColour="#CF8B17">
        <block type="control_wait">
            <value name="DURATION">
                <shadow type="math_positive_number">
                    <field name="NUM">1</field>
                </shadow>
            </value>
        </block>
        ${blockSeparator}
        <block type="control_repeat">
            <value name="TIMES">
                <shadow type="math_whole_number">
                    <field name="NUM">10</field>
                </shadow>
            </value>
        </block>
        <block id="forever" type="control_forever"/>
     
        ${blockSeparator}
        <block type="control_stop"/>
        ${blockSeparator}
       
        ${categorySeparator}
    </category>
    `;
};

const uploadControl = function (isInitialSetup, isStage) {
    return `
    <category name="%{BKY_CATEGORY_CONTROL}" id="control" colour="#FFAB19" secondaryColour="#CF8B17">
        <block type="control_wait">
            <value name="DURATION">
                <shadow type="math_positive_number">
                    <field name="NUM">1</field>
                </shadow>
            </value>
        </block>
        ${blockSeparator}
        <block type="control_repeat">
            <value name="TIMES">
                <shadow type="math_whole_number">
                    <field name="NUM">10</field>
                </shadow>
            </value>
        </block>

        <block id="control_forever" type="control_forever"/>
     
        ${categorySeparator}
    </category>
    `;
};

/* eslint-enable no-unused-vars */

const xmlOpen = '<xml style="display: none">';
const xmlClose = '</xml>';

/**
 * @param {!boolean} isInitialSetup - Whether the toolbox is for initial setup. If the mode is "initial setup",
 * blocks with localized default parameters (e.g. ask and wait) should not be loaded. (LLK/scratch-gui#5445)
 * @param {?object} device - Full data of current selected deivce.
 * @param {?boolean} isStage - Whether the toolbox is for a stage-type target. This is always set to true
 * when isInitialSetup is true.
 * @param {?string} targetId - The current editing target
 * @param {?Array.<object>} categoriesXML - optional array of `{id,xml}` for categories. This can include both core
 * and other extensions: core extensions will be placed in the normal Scratch order; others will go at the bottom.
 * @property {string} id - the extension / category ID.
 * @property {string} xml - the `<category>...</category>` XML for this extension / category.
 * @param {?boolean} isRealtimeMode - Current program mode.
 * @param {?string} costumeName - The name of the default selected costume dropdown.
 * @param {?string} backdropName - The name of the default selected backdrop dropdown.
 * @param {?string} soundName -  The name of the default selected sound dropdown.
 * @returns {string} - a ScratchBlocks-style XML document for the contents of the toolbox.
 */
 const getXML = function (
    isInitialSetup,
    isStage,
    targetId,
    isRealtimeMode,
    costumeName,
    backdropName,
    soundName
) {
    const gap = [categorySeparator];
    const motionXML = motion(isInitialSetup, isStage, targetId,isRealtimeMode);
    const lightsXML =  lights(isInitialSetup, isStage, targetId);
    const looksXML =  looks(isInitialSetup, isStage, targetId, costumeName, backdropName);
    const soundXML =  sound(isInitialSetup, isStage, targetId, soundName);
    const eventsXML =  events(isInitialSetup, isStage, targetId);
    const eventsRealTimeXML =  eventsRealTime(isInitialSetup, isStage, targetId);
    const controlXML =  control(isInitialSetup, isStage, targetId);
    const controluploadControlXML =  uploadControl(isInitialSetup, isStage, targetId);

    let baseToolboxXml = [];

    if (isRealtimeMode === true) {
        baseToolboxXml = [
            eventsRealTimeXML, gap,
            motionXML, gap,
            controlXML, gap,
            lightsXML, gap,
            looksXML, gap,
            soundXML, gap,
        ];
    } else {
        baseToolboxXml = [
            eventsXML, gap,
            motionXML, gap,
            controluploadControlXML, gap,
            lightsXML, gap,
            looksXML, gap,
            soundXML, gap,
        ];
    }

    return baseToolboxXml;
};


export default getXML;
