const Cast = require('../util/cast');
const MathUtil = require('../util/math-util');
const Timer = require('../util/timer');
const Oyun = require('../util/gameArea');
const ConnectedDeviceClass = require('../util/connectedDevice');
const DeviceSensorData = require('../util/deviceSensorData');
const ReadDeviceData = require('../util/deviceDataRead');


/*
Bu sayfada  GameLedBlocks oluşturdum. 
Burada yapmak istediğim bloklar ile oyun simülasyonunu hareket ettirmek.
Burada hareket fonksiyonlarını yazdım.

*/

class GameSensorsBlocks {
    constructor (runtime) {
        /**
         * The runtime instantiating this block package.
         * @type {Runtime}
         */
        this.runtime = runtime;
    }

    /**
     * Retrieve the block primitives implemented by this package.
     * @return {object.<string, Function>} Mapping of opcode to Function.
     */
    getPrimitives () {
        return {
            dugme_basildimi:this.dugme_basildimi,
            cihaz_sallaniyormu:this.cihaz_sallaniyormu,
            algilanan_renk:this.algilanan_renk,
            onundeki_engeller:this.onundeki_engeller,
            cizgi_algilama_renk:this.cizgi_algilama_renk,
            ses_siddeti:this.ses_siddeti,
            sallanma_siddeti:this.sallanma_siddeti,
            ortam_isik_siddeti:this.ortam_isik_siddeti,
            pil_seviyesi:this.pil_seviyesi,
            egim_acisi:this.egim_acisi,
            yatis_acisi:this.yatis_acisi

        };
    }

    async dugme_basildimi(args,util){
        //console.log(args);
        var device = ConnectedDeviceClass.getDevice();     

        if(args.KEY_OPTION == "upArrow"){
            return ReadDeviceData.getForwardButton();
        }
        if(args.KEY_OPTION == "downArrow"){
            return ReadDeviceData.getBackwardButton();
        }
        if(args.KEY_OPTION == "rightArrow"){
            return ReadDeviceData.getRightButton();
        }
        if(args.KEY_OPTION == "leftArrow"){
            return ReadDeviceData.getLeftButton();
        }
        if(args.KEY_OPTION == "z"){
            return ReadDeviceData.getZButton();
        }
    }

    async cihaz_sallaniyormu(args,util){
        //console.log(args);
        return ReadDeviceData.getIsShake();
    }

    async algilanan_renk(args,util){
       // console.log(args);
        var device = ConnectedDeviceClass.getDevice();     

        await device.write("<< 3 r 0 detect_color >>\n");
        /*
        if(args.KEY_OPTION == "WHITE"){
           
        }
        if(args.KEY_OPTION == "turquoise"){
            await device.write("<< 3 r 0 detect_turquoise >>\n");
        }
        if(args.KEY_OPTION == "green"){
            await device.write("<< 3 r 0 detect_green >>\n");
        }
        if(args.KEY_OPTION == "red"){
            await device.write("<< 3 r 0 detect_red >>\n");
        }
*/
        let deviceColor = ReadDeviceData.getDetectColor();
        console.log("device color: "+deviceColor);
        if(deviceColor == "blue" && args.KEY_OPTION == "blue"){
            return true;
        }
        if(deviceColor == "4" & args.KEY_OPTION == "green"){
            return true;
        }
        if(deviceColor == "1" & args.KEY_OPTION == "red"){
            return true;
        }
        if(deviceColor == "white" & args.KEY_OPTION == "WHITE"){
           return true;
        }
        if(deviceColor == "turquoise" & args.KEY_OPTION == "turquoise"){
            return true;
        }

        return false;
              
    }

    async cizgi_algilama_renk(args,util){
        // console.log(args);
         var device = ConnectedDeviceClass.getDevice();     
 
         if(args.DIRECTION == "left"){
            if(args.VALUE=="black"){
              var data = ReadDeviceData.getLineLeftBlack();
              var data2 =  ReadDeviceData.getLineLeftWhite();
             // console.log(data);

              if(data=="1" && data2=="1"){
                return true;
              }else{
                return false;
              }
            }else{
                var data =  ReadDeviceData.getLineLeftWhite();
                var data2 =  ReadDeviceData.getLineRightBlack();
            //    console.log(data);

                if(data=="1" && data2=="1"){
                  return true;
                }else{
                  return false;
                }
            }
         }
         if(args.DIRECTION == "right"){
            if(args.VALUE=="black"){
                var data =  ReadDeviceData.getLineRightBlack();
                var data2 =  ReadDeviceData.getLineLeftWhite();

               // console.log(data);
               if(data=="1" && data2=="1"){
                  return true;
                }else{
                  return false;
                }
              }else{
                  var data =  ReadDeviceData.getLineRightWhite();
                  var data2 = ReadDeviceData.getLineLeftBlack();

                //  console.log(data);

                if(data=="1" && data2=="1"){
                    return true;
                  }else{
                    return false;
                  }
              }
         }

         if(args.DIRECTION == "all"){
            if(args.VALUE=="black"){
                var data =  ReadDeviceData.getLineRightBlack();
                var data2 = ReadDeviceData.getLineLeftBlack();

               // console.log(data);
                if(data=="1" && data2 == "1"){
                  return true;
                }else{
                  return false;
                }
              }else{
                  var data =  ReadDeviceData.getLineRightWhite();
                  var data2 =  ReadDeviceData.getLineLeftWhite();

                //  console.log(data);

                  if(data=="1" && data2 == "1"){
                    return true;
                  }else{
                    return false;
                  }
              }
         }
       
         
     }

    async onundeki_engeller(args,util){
       // console.log(args);
        return ReadDeviceData.getDetectBarrier();
    }

    async ses_siddeti(args,util){
       // console.log(args);
        return ReadDeviceData.getSoundIntensity();
    }

    async sallanma_siddeti(args,util){
        //console.log(args);
        return ReadDeviceData.getShakingIntensity();
    }

    async ortam_isik_siddeti(args,util){
       // console.log(args);
        return ReadDeviceData.getLightIntensity();
    }

    async pil_seviyesi(args,util){
       // console.log(args);
        return ReadDeviceData.getBatteryVolt();
    }

    async egim_acisi(args,util){
        //console.log(args);
        return ReadDeviceData.getTiltAngle();
    }

    async yatis_acisi(args,util){
        //console.log(args);
        return ReadDeviceData.getSideTilt();

    }
   
   
}

module.exports = GameSensorsBlocks;
